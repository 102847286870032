/* eslint-disable no-param-reassign */

export default (params) => (
  Object.keys(params).reduce((result, key) => {
    if (Array.isArray(params[key]) && params[key].length) {
      result[key] = JSON.stringify(params[key]);
    } else {
      result[key] = params[key];
    }
    return result;
  }, {})
);
