import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import LoaderFullScreen from '@/common/components/LoaderFullScreen';
import Checkbox from '@/common/components/Checkbox';

import AdminConfirmModal from '../../../../../Modals/ConfirmModal';
import {
  AdminMainPopupContent,
  AdminMainPopupHeader,
  StyledButton,
} from '../../../../components/MainPopupComponents';
import { useBaseContext } from '../BaseContext';

const OPTIONS_TYPES = {
  news: 'news',
  notify: 'notify',
};

const OPTIONS_LABELS = {
  [OPTIONS_TYPES.news]: 'Создавать новость',
  [OPTIONS_TYPES.notify]: 'Отправлять уведомление пользователям в ЛК',
};

const INITIAL_OPTIONS = {
  [OPTIONS_TYPES.news]: false,
  [OPTIONS_TYPES.notify]: false,
};

const BaseImportCsv = () => {
  const history = useHistory();
  const {
    getImportData,
    handleValidateData,
    handleImportData,
    importData,
    validatedData,
    isLoading,
  } = useBaseContext();

  const [isSyncModal, setIsSyncModal] = useState(false);
  const [options, setOptions] = useState(INITIAL_OPTIONS);

  return (
    <Container>
      {isLoading && <LoaderFullScreen />}
      <AdminConfirmModal
        isOpen={isSyncModal}
        onClose={() => setIsSyncModal(false)}
        handleSubmit={() => { setIsSyncModal(false); handleImportData(options); }}
        content="Вы действительно хотите импортировать IPTV каналы и группы?"
      />
      <AdminMainPopupHeader
        title="Импорт IPTV пакетов и групп"
        linkText="Вернуться “Каналы. Базовые настройки”"
        handleClose={() => history.push('/admin/iptv/base')}
      />
      <AdminMainPopupContent descr="Данные для импорта:">
        {importData.groups.length ? (
          <>
            # Список IPTV групп
            {' '}
            <br />
            {importData.groups.map((item) => Object.values(item).map((group, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={`AIpTV-B-ICSV-g--${index}`}>
                {group}
                ;
                {index + 1 === Object.keys(item).length ? <br /> : null}
              </span>
            )))}
          </>
        ) : null}
        {importData.channels.length ? (
          <>
            # Список каналов
            {' '}
            <br />
            {importData.channels.map((item) => Object.values(item).map((channel, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={`AIpTV-B-ICSV-c--${index}`}>
                {channel}
                ;
                {index + 1 === Object.keys(item).length ? <br /> : null}
              </span>
            )))}
          </>
        ) : null}
      </AdminMainPopupContent>
      <Wrapper flex>
        <StyledButton
          isBoldText
          isUpperCase
          onClick={getImportData}
        >
          Получить данные для импорта
        </StyledButton>
        <StyledButton
          isBoldText
          isUpperCase
          isOutline
          disabled={!importData.channels.length || !importData.groups.length}
          onClick={handleValidateData}
        >
          Проверка данных
        </StyledButton>
      </Wrapper>
      {Object.keys(validatedData).length ? (
        <>
          <AdminMainPopupContent descr="Данные для импорта:">
            {Object.keys(validatedData).map((itemName) => {
              const item = validatedData[itemName];
              return (
                <div key={`AIpTV-B-ICSV-i--${itemName}`}>
                  {itemName}
                  :
                  {item.cnt}
                  <br />
                  {item?.groups
                    ? item.groups.map((group) => Object.keys(group).map((groupName) => {
                      const currentGroup = item.groups[groupName];
                      return (
                        <span key={`AIpTV-B-ICSV-ig--${groupName}`}>
                          {groupName}
                          :
                          {currentGroup}
                          ;
                          <br />
                        </span>
                      );
                    }))
                    : item.channels.map((channel) => Object.keys(channel).map((channelName) => {
                      const currentChannel = item.channels[channelName];
                      return (
                        <div key={`AIpTV-B-ICSV-c--${channelName}`}>
                          {channelName}
                          :
                          {currentChannel}
                          ;
                          <br />
                        </div>
                      );
                    }))}
                </div>
              );
            })}
          </AdminMainPopupContent>
          <Wrapper flex>
            <StyledButton
              isBoldText
              isUpperCase
              onClick={() => setIsSyncModal(true)}
              disabled={isLoading}
            >
              Выполнить синхронизацию
            </StyledButton>
            <OptionsContainer>
              {Object.keys(OPTIONS_TYPES).map((opt) => (
                <Checkbox
                  key={`AIpTV-B-ICSV-opt--${opt}`}
                  checked={options[opt]}
                  label={OPTIONS_LABELS[opt]}
                  onChange={(v) => setOptions((prevState) => ({ ...prevState, [opt]: v }))}
                  disabled={isLoading}
                />
              ))}
            </OptionsContainer>
          </Wrapper>
        </>
      ) : null}
    </Container>
  );
};
export default BaseImportCsv;

const Container = styled.div``;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;

const OptionsContainer = styled.div`
  margin-left: 2rem;
`;
