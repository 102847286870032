export default {
  moneyGeneral: (filter) => `/api/v1/user/admin/statistics/money/general${(filter?.length || 0) > 0 ? `?${filter}` : ''}`,
  moneyPaymentTransactions: (filter) => `/api/v1/user/admin/statistics/money/payment-transactions${(filter?.length || 0) > 0 ? `?${filter}` : ''}`,
  moneyUserBalance: (filter) => `/api/v1/user/admin/statistics/money/balance-users${(filter?.length || 0) > 0 ? `?${filter}` : ''}`,

  userGeneral: (filter) => `/api/v1/user/admin/statistics/users/general${(filter?.length || 0) > 0 ? `?${filter}` : ''}`,
  userUsers: (filter) => `/api/v1/user/admin/statistics/users/all${(filter?.length || 0) > 0 ? `?${filter}` : ''}`,
  userReferral: (filter) => `/api/v1/user/admin/statistics/users/referral${(filter?.length || 0) > 0 ? `?${filter}` : ''}`,
  userDevices: (filter) => `/api/v1/user/admin/statistics/users/devices${(filter?.length || 0) > 0 ? `?${filter}` : ''}`,

  financeData: (filter) => `/api/v1/user/admin/statistics/finance${(filter?.length || 0) > 0 ? `?${filter}` : ''}`,
};
