import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { borderSecondary, primary } from '@/common/constants/theme';

import Checkbox from '@/common/components/Checkbox';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import EditIcon from '@/common/components/icons/EditIcon';

import DeleteIcon from '../../../../../icons/DeleteIcon';
import AdminDeleteModal from '../../../../../Modals/DeleteModal';
import ServersTransferModal from './TransferModal';

import { useServersContext } from '../ServersContext';

const SIZES = [
  '40px',
  '100px',
  '240px',
  '130px',
  '240px',
  '60px',
];

const ServersTable = () => {
  const {
    servers,
    getServers,
    filters,
  } = useServersContext();
  const { page } = filters;
  const history = useHistory();

  const [deletedServer, setDeletedServer] = useState(-1);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isTransferModal, setIsTransferModal] = useState(false);

  useEffect(() => {
    getServers();
  }, []);

  return (
    <Container>
      <AdminDeleteModal
        content={deletedServer >= 0 && servers[deletedServer].name}
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        handleDelete={() => {
          setIsDeleteModal(false);
          setIsTransferModal(true);
        }}
      />
      {isTransferModal && (
        <ServersTransferModal
          onClose={() => {
            setIsTransferModal(false);
            setDeletedServer(-1);
          }}
          deletedServer={deletedServer}
        />
      )}
      <Table
        cellSizes={SIZES}
        dataLength={servers.length}
        page={page}
        limit={50}
        textAlign="left"
        adaptiveSize={25}
      >
        <TableHead>
          <HeadItem>
            Вирт.
          </HeadItem>
          <HeadItem>
            DNS
          </HeadItem>
          <HeadItem>
            Название
          </HeadItem>
          <HeadItem>
            Количество дней архива
          </HeadItem>
          <HeadItem>
            Описание
          </HeadItem>
        </TableHead>
        <TableBody minRows={17} rowHeight={42}>
          {servers.map((item, i) => (
            <React.Fragment key={`servers--${item.id}`}>
              <RowItem>
                <Checkbox checked={item.is_virtual} disabled />
              </RowItem>
              <RowItem>
                {item.dns}
              </RowItem>
              <RowItem title={item.name}>
                {item.name.length > 34
                  ? `${item.name.slice(0, 34)}...`
                  : item.name}
              </RowItem>
              <RowItem>
                {item.archive_days}
              </RowItem>
              <RowItem title={item.description}>
                {item.description.length > 50
                  ? `${item.description.slice(0, 50)}...`
                  : item.description}
              </RowItem>
              <RowItem flex right>
                <Icon onClick={() => {
                  history.push(`/admin/iptv/servers/edit/${item.id}`);
                }}
                >
                  <EditIcon />
                </Icon>
                <Icon onClick={() => {
                  setDeletedServer(i);
                  setIsDeleteModal(true);
                }}
                >
                  <DeleteIcon />
                </Icon>
              </RowItem>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default ServersTable;

const Container = styled.div`
  & .table-head {
    &::after {
      top: 5px;
    }
  }

  & .table-rows {
    border: 1px solid ${borderSecondary};
    border-radius: 4px;

    &__row {
      border-bottom: 1px solid ${borderSecondary};

      &__item:last-child {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;

        margin-right: 10px;
      }
    }
  }
`;

const HeadItem = styled.div``;

const RowItem = styled.div`
  ${({ flex }) => flex && ('display: flex;')}

  & div[type] {
    border-color: ${primary};
  }
`;

const Icon = styled.div`
  width: 12px;
  margin-left: 13px;

  cursor: pointer;

  &:first-child {
    margin-left: 5px;
  }
`;
