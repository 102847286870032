import React from 'react';
import MainUserTransactions from './MainUserInfo/components/MainUserTransactions';
import MainUserReplenishments from './MainUserInfo/components/MainUserReplenishments';
import MainUserLogs from './MainUserInfo/components/MainUserLogs';
import ReferralWithdrawTable from '../../../../Referrals/ReferralWithdrawTable';
import MainUserRefLinksFollows from './MainUserInfo/components/MainUserRefLinksFollows';
import MainUserRefTransactions from './MainUserInfo/components/MainUserRefTransactions';

const StatTabs = (userId) => ({
  accountMovements: {
    label: 'Движение по счёту',
    component: <MainUserTransactions />,
  },
  refillAttempt: {
    label: 'Попытки пополнения',
    component: <MainUserReplenishments />,
  },
  logs: {
    label: 'Логирование действий',
    component: <MainUserLogs />,
  },
  withdraw: {
    label: 'Запросы на вывод',
    component: <ReferralWithdrawTable id={userId} />,
  },
  referralRegs: {
    label: 'Рефералы. Регистрации',
    component: <MainUserRefLinksFollows />,
  },
  referralPayments: {
    label: 'Рефералы. Пополнения',
    component: <MainUserRefTransactions />,
  },
});
export default StatTabs;
