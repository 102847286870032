import React from 'react';

export default ({ fill, width, height }) => {
  const w = width || 18;
  const h = height || 18;
  const f = fill || '#6B7DD0';
  return (
    <svg width={w} height={h} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.25 14.542H15.2527C14.928 13.4612 13.935 12.667 12.75 12.667C11.565 12.667 10.5728 13.4612 10.2473 14.542H0.749988C0.335988 14.542 0 14.878 0 15.292C0 15.706 0.335988 16.042 0.749988 16.042H10.2472C10.572 17.1227 11.565 17.917 12.75 17.917C13.935 17.917 14.9272 17.1227 15.2527 16.042H17.25C17.6647 16.042 18 15.706 18 15.292C18 14.878 17.6647 14.542 17.25 14.542ZM12.75 16.417C12.1297 16.417 11.625 15.9123 11.625 15.292C11.625 14.6717 12.1297 14.167 12.75 14.167C13.3703 14.167 13.875 14.6717 13.875 15.292C13.875 15.9123 13.3702 16.417 12.75 16.417Z" fill={f} />
      <path d="M17.25 2.54198H15.2527C14.9272 1.46124 13.935 0.666992 12.75 0.666992C11.565 0.666992 10.5728 1.46124 10.2472 2.54198H0.749988C0.335988 2.54198 0 2.87797 0 3.29197C0 3.70597 0.335988 4.04196 0.749988 4.04196H10.2472C10.5728 5.12273 11.565 5.91698 12.75 5.91698C13.935 5.91698 14.9273 5.12273 15.2527 4.04199H17.25C17.6647 4.04199 18 3.706 18 3.292C18 2.878 17.6647 2.54198 17.25 2.54198ZM12.75 4.41697C12.1297 4.41697 11.625 3.91223 11.625 3.29197C11.625 2.67171 12.1297 2.16697 12.75 2.16697C13.3703 2.16697 13.875 2.67171 13.875 3.29197C13.875 3.91223 13.3702 4.41697 12.75 4.41697Z" fill={f} />
      <path d="M17.25 8.54198H7.75276C7.42725 7.46124 6.435 6.66699 5.25002 6.66699C4.06505 6.66699 3.07276 7.46124 2.74729 8.54198H0.749988C0.335988 8.54198 0 8.87797 0 9.29197C0 9.70597 0.335988 10.042 0.749988 10.042H2.74725C3.07276 11.1227 4.06501 11.9169 5.24999 11.9169C6.43496 11.9169 7.42725 11.1227 7.75273 10.042H17.25C17.6647 10.042 18 9.70597 18 9.29197C18 8.87797 17.6647 8.54198 17.25 8.54198ZM5.24999 10.417C4.62973 10.417 4.12499 9.91223 4.12499 9.29197C4.12499 8.67171 4.62973 8.16697 5.24999 8.16697C5.87025 8.16697 6.37499 8.67171 6.37499 9.29197C6.37499 9.91223 5.87025 10.417 5.24999 10.417Z" fill={f} />
    </svg>
  );
};
