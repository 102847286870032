import React from 'react';
import { EditorState } from 'draft-js';

import ToolbarButton from './ToolbarButton';
import UndoIcon from '@/assets/icons/TextEditor/undo.svg';
import RedoIcon from '@/assets/icons/TextEditor/redo.svg';

const HistoryControls = ({
  editorState,
  onEditorStateChange,
}) => {
  const undoDisabled = editorState.getUndoStack().size === 0;
  const redoDisabled = editorState.getRedoStack().size === 0;

  const toggleHistory = (action) => {
    const newState = EditorState[action](editorState);
    if (newState) {
      onEditorStateChange(newState);
    }
  };

  return (
    <>
      <ToolbarButton
        className="rdw-option-wrapper"
        disabled={undoDisabled}
        label="Отменить"
        icon={UndoIcon}
        onToggle={() => {
          toggleHistory('undo');
        }}
      />
      <ToolbarButton
        className="rdw-option-wrapper"
        disabled={redoDisabled}
        label="Вернуть"
        icon={RedoIcon}
        onToggle={() => {
          toggleHistory('redo');
        }}
      />
    </>
  );
};

export default HistoryControls;
