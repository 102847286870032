import React from 'react';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';
import { useTranslation } from 'react-i18next';
import {
  white,
  text03,
} from '@/common/constants/theme';
import Tooltip from '@/common/components/Tooltip';
import Gift from '../../../icons/GiftIcon';

export default ({ color, discount, children }) => {
  const { t } = useTranslation('translations');
  return (
    <Column>
      <MarkContent color={color}>{children}</MarkContent>
      {((discount || 0) > 0) && (
      <Tooltip
        title={`
        ${t('subscriptionsContent.discount')} ${discount}
        ${t('subscriptionsContent.discountTooltipText1')} ${children}
        ${t('subscriptionsContent.discountTooltipText2')}`}
        width={270}
        position="left"
        verticalPosition="bottom"
        trigger="hover"
      >
        <AdditionalInfoRow>
          <Discount color={color}>{`-${discount}%`}</Discount>
          <Icon color={color}><Gift /></Icon>
        </AdditionalInfoRow>
      </Tooltip>
      )}
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const MarkContent = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  color: ${text03};
  border-radius: 2px;
  padding: 2px 4px;
  ${({ color }) => color && `
      color: ${white};
      background: ${color};
  `}
`;

const AdditionalInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
`;

const Discount = styled(MarkContent)`
  ${({ color }) => color && `
    color: ${color};
    background: ${white};
    font-weight: 600;
  `}
`;

const Icon = styled.div`
  display: flex;
  margin-bottom: 2px;
  margin-left: 2px;
  path {
    fill: ${({ color }) => color};
  }
`;
