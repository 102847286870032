import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  borderSecondary,
  activeTertiary,
  text01,
} from '@/common/constants/theme';

import { CURRENCY_SYMBOLS } from '@/common/constants/financial';

import { Radio } from '@/common/components/RadioButton';
import DatePickerNew from '@/common/components/DatePickerNew';
import ColorPicker from './components/ColorPicker';
import { BarChartWrapper, LineChartWrapper } from './components/Charts';
import ExpandedBlock from './components/ExpandedBlock';
import Loader from '@/common/components/Loader';

import MoneyIcon from '../../icons/MoneyIcon';

import {
  useAdminContext,
} from '../../../AdminContext';
import {
  CHART_COLORS,
  DATA_TYPES,
  useStatisticsContext,
} from './StatisticsContext';

import { format } from 'date-fns';

const userBalanceField = {
  key: 'user_balance',
  title: 'Баланс',
  color: 'SlateBlue',
};

const MoneyContainer = () => {
  const {
    formatCurrencySummValue,
    // getDateFromDateFilter,
  } = useAdminContext();

  const {
    paymentSystems,
    isMoneyStatsProcessing,
    moneyStatsFilter,
    setMoneyStatsFilter,
    moneyGeneralStats,
    moneyPaymentsStats,
    moneyUserBalanceStats,
    filterDatesToLocal,
    filterDatesFromLocal,
  } = useStatisticsContext();

  // eslint-disable-next-line no-unused-vars
  const [localDates] = useState(filterDatesToLocal(moneyStatsFilter));
  const [localPaymentsStats, setLocalPaymentsStats] = useState([]);
  const [localUserBalanceStats, setLocalUserBalanceStats] = useState([]);
  const [localUserBalance, setLocalUserBalance] = useState(0);
  const [selectedDataType, setSelectedDataType] = useState(DATA_TYPES.moneyPayments);

  const [fields, setFields] = useState(JSON.parse(localStorage.getItem('moneyChartFields')) || null);
  const [fieldsDisplayed, setFieldsDisplayed] = useState([]);
  const [fieldsUserBalance, setFieldsUserBalance] = useState(JSON.parse(localStorage.getItem('moneyChartFieldsUserBalance')) || null);

  const changeColor = (field, newColor) => setFields(
    [...fields.filter((item) => item.key !== field.key), { ...field, color: newColor }],
  );

  const changeColorUserBalance = (newColor) => {
    setFieldsUserBalance([{ ...userBalanceField, color: newColor }]);
  };

  const changeDataType = (e) => setSelectedDataType(DATA_TYPES[e.target.value]);

  useEffect(() => {
    if (((paymentSystems?.length || 0) > 0)
    && ((moneyPaymentsStats?.length || 0) > 0)) {
      let colorIndex = -1;
      const paymentsStatsFields = paymentSystems.map((ps) => {
        colorIndex += 1; colorIndex = colorIndex > CHART_COLORS.length ? 0 : colorIndex;
        return {
          key: ps.id,
          title: ps.title,
          color: CHART_COLORS[colorIndex],
        };
      });
      const { date, ...psSums } = moneyPaymentsStats[0];
      moneyPaymentsStats.forEach((dayStats) => Object.entries(dayStats)
        .forEach(([psId, daySum]) => { if (psId !== 'date') psSums[psId] += daySum; }));
      const usedPSs = [];
      Object.entries(psSums).forEach(
        ([psId, psSum]) => { if (psSum > 0) usedPSs.push(parseInt(psId, 10)); },
      );
      setFields(paymentsStatsFields);
      setFieldsDisplayed(paymentsStatsFields.filter((p) => usedPSs.includes(p.key)));
      setLocalPaymentsStats(moneyPaymentsStats);
      localStorage.setItem('moneyChartFields', JSON.stringify(paymentsStatsFields));
    }
  }, [paymentSystems, moneyPaymentsStats]);

  useEffect(() => {
    setLocalUserBalanceStats(moneyUserBalanceStats);
    setLocalUserBalance((moneyUserBalanceStats || []).slice(-1)?.[0]?.user_balance || 0);

    setFieldsUserBalance([userBalanceField]);
    localStorage.setItem('moneyChartFieldsUserBalance', JSON.stringify([userBalanceField]));
  }, [moneyUserBalanceStats]);

  return (
    <ExpandedBlock
      titleComponent={() => (
        <Label>
          <MoneyIcon />
          Деньги
        </Label>
      )}
      rightComponent={() => (
        <Actions>
          <DatePickerNew
            values={localDates}
            onSubmit={(interval) => {
              const newDates = {
                ...localDates,
                ...filterDatesFromLocal({
                  d_begin: interval?.dateStart ? format(interval.dateStart, 'yyyy-MM-dd') : '',
                  d_end: interval?.dateEnd ? format(interval.dateEnd, 'yyyy-MM-dd') : '',
                }),
              };
              setMoneyStatsFilter((prevState) => ({ ...prevState, ...newDates }));
            }}
          />
        </Actions>
      )}
    >
      {isMoneyStatsProcessing && <Loader />}
      <Block>
        <BorderedRow justify="space-between">
          <RadioWrapper>
            <Radio
              value={DATA_TYPES.moneyPayments}
              checked={selectedDataType === DATA_TYPES.moneyPayments}
              onChange={changeDataType}
            />
            <RadioLabel>
              <Text>Заработано за период</Text>
              <Sum isActive={selectedDataType === DATA_TYPES.moneyPayments}>
                {`${moneyGeneralStats?.currency ? CURRENCY_SYMBOLS[moneyGeneralStats.currency] : ''}
                ${moneyGeneralStats?.earned_amount ? formatCurrencySummValue(moneyGeneralStats.earned_amount || 0) : 0}`}
              </Sum>
            </RadioLabel>
          </RadioWrapper>
          <RadioWrapper>
            <Radio
              value={DATA_TYPES.moneyUserBalance}
              checked={selectedDataType === DATA_TYPES.moneyUserBalance}
              onChange={changeDataType}
            />
            <RadioLabel>
              <Text>Баланс всех пользователей</Text>
              <Sum isActive={selectedDataType === DATA_TYPES.moneyUserBalance}>
                {`${moneyGeneralStats?.currency ? CURRENCY_SYMBOLS[moneyGeneralStats.currency] : ''}
                ${formatCurrencySummValue(localUserBalance)}`}
              </Sum>
            </RadioLabel>
          </RadioWrapper>
        </BorderedRow>
        {(selectedDataType === DATA_TYPES.moneyPayments)
          && (localPaymentsStats?.length || 0) > 0
          && fields && (
          <>
            <BarChartWrapper
              statisticArray={localPaymentsStats}
              fields={fields}
              usedFieldsKeys={fieldsDisplayed.map((f) => f.key)}
            />
            <Legend>
              {fieldsDisplayed.map((field) => (
                <LegendItem key={`${field.key}--chartLegendItem`}>
                  <ColorPicker
                    color={field.color}
                    label={field.title}
                    onApply={(newColor) => changeColor(field, newColor)}
                  />
                  <span>{field.title}</span>
                </LegendItem>
              ))}
            </Legend>
          </>
        )}
        {(selectedDataType === DATA_TYPES.moneyUserBalance)
          && (localUserBalanceStats?.length || 0) > 0
          && (
          <>
            <LineChartWrapper
              statisticArray={localUserBalanceStats}
              fields={fieldsUserBalance}
            />
            <Legend>
              <LegendItem key={`${userBalanceField.key}--chartLegendItem`}>
                <ColorPicker
                  color={userBalanceField.color}
                  label={userBalanceField.title}
                  onApply={(newColor) => changeColorUserBalance(newColor)}
                />
                <span>{userBalanceField.title}</span>
              </LegendItem>
            </Legend>
          </>
          )}
      </Block>
    </ExpandedBlock>
  );
};

export default MoneyContainer;

const Block = styled.div`
  padding: 16px 0;
`;

const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
  padding: 0 20px;
  justify-content: ${({ justify }) => justify};
`;

const BorderedRow = styled(Row)`
  padding-bottom: 20px;
  border-bottom: 1px solid ${borderSecondary};
`;

const Label = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${activeTertiary};
  svg {
    margin-right: 9px;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RadioLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const Sum = styled(Text)`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  ${({ isActive }) => isActive && `
    font-weight: 600;
    font-size: 32px;
  `}
`;

const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
