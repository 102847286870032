import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  primary, secondary, white, text01,
} from '@/common/constants/theme';
import IconClose from '@/assets/icons/closeGray.svg';
import IconIntro from '@/assets/icons/man.svg';
import step1 from '@/assets/icons/step1.svg';
import step2 from '@/assets/icons/step2.svg';
import step3 from '@/assets/icons/step3.svg';
import step4 from '@/assets/icons/step4.svg';
import step5 from '@/assets/icons/step5.svg';
import { useDispatch, useSelector } from 'react-redux';
import { closeDevicesWelcome, getDevicesWelcome } from '@/store/user/selectors';

const WelcomeBlock = () => {
  const dispatch = useDispatch();
  const closed = useSelector(getDevicesWelcome);
  const toggleVisibilityBlock = () => {
    dispatch(closeDevicesWelcome());
  };
  const { t } = useTranslation(['translations']);
  const steps = [
    { stepNumber: t('steps.step1.stepNumber'), text: t('steps.step1.description'), iconSrc: step1 },
    { stepNumber: t('steps.step2.stepNumber'), text: t('steps.step2.description'), iconSrc: step2 },
    { stepNumber: t('steps.step3.stepNumber'), text: t('steps.step3.description'), iconSrc: step3 },
    { stepNumber: t('steps.step4.stepNumber'), text: t('steps.step4.description'), iconSrc: step4 },
    { stepNumber: t('steps.step5.stepNumber'), text: t('steps.step5.description'), iconSrc: step5 },
  ];

  return (
    <Block isVisibleBlock={!closed}>
      <Title>
        {t('stepBanner.titleBlack')}
        <span>{t('stepBanner.titleOrange')}</span>
      </Title>
      <Close onClick={toggleVisibilityBlock} />
      <Img />
      <Subtitle>
        {t('stepBanner.subTitle')}
      </Subtitle>
      <Steps>
        {steps.map((step, index) => (
          <Item key={step.text}>
            <ItemTitle>
              {step.stepNumber}
              <Icon src={step.iconSrc} alt="" withMargin={index === 3} />
            </ItemTitle>
            <ItemSubTitle>{step.text}</ItemSubTitle>
          </Item>
        ))}
      </Steps>
    </Block>
  );
};
export default WelcomeBlock;

const Block = styled.div`
    background: ${white};
    margin: 3rem;
    margin-left: 0;
    border: 1px dashed #adb7e7;
    border-radius: 16px;
    padding: 30px 0;
    position:relative;
    margin-bottom: 45px;
    display: ${({ isVisibleBlock }) => (isVisibleBlock ? 'block;' : 'none;')};
`;

const Img = styled.div`
  background: ${primary};
  position: relative;
  height: 50px;
  &:after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -31px;
      width: 62px;
      height: 70px;
      top: -40px;
      background: url(${IconIntro}) center center no-repeat;
  }
`;

const Close = styled.div`
  position: absolute;
  right: 24px;
  width: 10px;
  height: 10px;
  top: 20px;
  cursor: pointer;
  background: url(${IconClose}) center center no-repeat;
`;

const Title = styled.h3`
  font-family: Gilroy;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 50px;
  color: ${text01};
  span {
    color: ${secondary};
    margin-left: .5rem;
  }
`;

const Subtitle = styled.h4`
  font-family: Gilroy;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  display: block;
  color: ${primary};
  padding: 0;
  margin-bottom: 0;
`;

const Steps = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1rem 1.5rem;
`;

const Item = styled.div`
  display: inline-flex;
  width: 148px;
  flex-wrap: wrap;
  align-items: center;
  min-height: 148px;
  background: ${white};
  box-shadow: 0px 4px 24px rgba(160, 160, 160, 0.25);
  border-radius: 4px;
  padding: 1rem .75rem;
  margin-left: 15px;
  flex-direction: column;
  justify-content: flex-start;
  &:first-child {
    margin-left: 0;
  }
  &:nth-child(2n) {
    &>header {
      color: ${primary};
    }
  }
`;

const ItemTitle = styled.header`
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    color: ${secondary};
    font-family: Gilroy;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
`;

const Icon = styled.img`
    cursor: pointer;
    ${({ withMargin }) => withMargin && 'margin-top: 5px;'}
`;

const ItemSubTitle = styled.p`
    width: 100%;
    font-family: Gilroy;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${text01};
    white-space: pre-wrap;
`;
