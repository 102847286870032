import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  primary, green,
  text01, text02, text04, secondary,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';

import Table from '../../components/Table/Table';
import TableBody from '../../components/Table/components/TableBody';
import TablePagination from '../../components/Table/components/TablePaginationAlt';

import ThreadsTableHeader from './ThreadsTableHeader';

import { usePersonalPageContext } from '../../../../PersonalPageContext';

import {
  THREADS_TABLE_SIZES,
  ACTIONS,
  useSupportContext, THREAD_STATUS,
} from '../SupportContext';

import MailOpenIcon from '@/screens/PersonalPageScreen/components/icons/MailOpenIcon';
import MailSuccessIcon from '@/screens/PersonalPageScreen/components/icons/MailSuccessIcon';

const ThreadsList = () => {
  const { t } = useTranslation('translations');

  const { getDateTimeToDisplay } = usePersonalPageContext();
  const {
    loaders,
    threads,
    threadsCount,
    loadThreads,
    loadThread,
    selectedThread,
    setSelectedThread,
    threadsFilter,
    setThreadsFilter,
    setSelectedAction,
    isThreadsLoaded,
  } = useSupportContext();

  const [localThreads, setLocalThreads] = useState([]);

  const handleOpenThread = (th) => {
    setSelectedThread(th);
    setSelectedAction(ACTIONS.viewThread);
    loadThread(th.case_id);
  };

  const handleConfirmThread = (th) => {
    setSelectedThread(th);
    setSelectedAction(ACTIONS.confirmThread);
  };

  useEffect(() => {
    if (threads) {
      setLocalThreads(threads);
    } else {
      loadThreads();
    }
  }, [threads]);

  return (
    <Container>
      {loaders?.threads && <Loader />}
      <Table>
        <ThreadsTableHeader />
        {((localThreads || []).length > 0) ? (
          <TableBody
            rowHeight={29}
            minRows={threadsFilter?.limit || 10}
            noRowItemMargin
          >
            {localThreads.map((th) => (
              <Row
                key={`PSupTR--${th.case_id}`}
              >
                <RowCell width={THREADS_TABLE_SIZES[0]} widthSub="20px">
                  <Title
                    isUnread={th.is_unread}
                    onClick={() => handleOpenThread(th)}
                    isLink
                    isBold={selectedThread?.case_id === th.case_id}
                  >
                    {th.subject}
                  </Title>
                </RowCell>
                <RowCell width={THREADS_TABLE_SIZES[1]}>
                  <Title>{th.case_number}</Title>
                </RowCell>
                <RowCell width={THREADS_TABLE_SIZES[2]}>
                  <Title>{t(`personalPage.support.thread.status.${th?.status || 'undefined'}`)}</Title>
                </RowCell>
                <RowCell width={THREADS_TABLE_SIZES[3]}>
                  <Title>{getDateTimeToDisplay(th.dt_create)}</Title>
                </RowCell>
                <RowCell width={THREADS_TABLE_SIZES[4]}>
                  <Title>{getDateTimeToDisplay(th.dt_update)}</Title>
                </RowCell>
                <RowCell width={THREADS_TABLE_SIZES[5]}>
                  <ActionsList>
                    <ActionOpen title={t('personalPage.support.thread.open')}>
                      <Icon onClick={() => handleOpenThread(th)}>
                        <MailOpenIcon />
                      </Icon>
                    </ActionOpen>
                    {(th.status !== THREAD_STATUS.closed) && (
                      <ActionClose title={t('personalPage.support.thread.close')}>
                        <Icon onClick={() => handleConfirmThread(th)}>
                          <MailSuccessIcon />
                        </Icon>
                      </ActionClose>
                    )}
                  </ActionsList>
                </RowCell>
              </Row>
            ))}
          </TableBody>
        ) : (
          <NoData>{!isThreadsLoaded ? '' : t('table.noResults')}</NoData>
        )}
        <TablePagination
          quantity={threadsCount || 0}
          setFilters={setThreadsFilter}
          filters={threadsFilter}
        />
      </Table>
    </Container>
  );
};

export default ThreadsList;

const Container = styled.div`
  position: relative;
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 15px;
  letter-spacing: 0.16px;
  color: ${text02};
`;

const Row = styled.div`
`;

const RowCell = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  padding: 0 .625rem;
  margin: .75rem 0;
  display: flex;
  width: ${({ width }) => (width || '100%')};
  justify-content: ${({ justify }) => justify};
  ${({ leftPadding }) => leftPadding && 'padding-left: 1.4rem;'}
  ${({ disabled }) => disabled && `
    opacity: .5;
    pointer-events: none;
    path {
      fill: ${text04};
    }
  `}
  @media (max-width: 1360 px) and (min-width: 1199 px) { }
`;

const Title = styled.span`
  position: relative;
  display: inline-block;
  font-family: Gilroy, sans-serif;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${({ isLink }) => (isLink ? primary : text01)};
  white-space: nowrap;
  width: calc(100% - ${({ isUnread }) => (isUnread ? 3 : 2)}rem);
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'default')};

  ${({ isLink }) => isLink && `
     &:hover {
      color: ${secondary};
     }
  `}

  ${({ isUnread }) => isUnread && `
    padding-left: 1rem;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: 5px;
      left: 0;
      background: ${primary};
      box-shadow: 0px 2px 4px rgba(92, 112, 207, 0.3);
    }
  `}
`;

const ActionsList = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;

  & div:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ActionOpen = styled(Action)`
  & svg {
    fill: ${({ isDisabled }) => (isDisabled ? text04 : primary)};
  }
`;

const ActionClose = styled(Action)`
  & svg {
    fill: ${({ isDisabled }) => (isDisabled ? text04 : green)};
  }
`;

const Icon = styled.div`
  height: 24px;
`;
