import auth from './auth.service';
import authUrls from './routes/authorization.api';

const baseUrl = process.env.REACT_APP_API_URL || 'https://api.arlekino.tv';

export default async function request2Service(endpoint, opt) {
  const options = { ...opt };
  const token = auth.get('token');
  const headers = { 'Content-Type': 'application/json', ...options.headers };
  if (token) {
    headers.Authorization = `Token ${token}`;
  }
  options.headers = headers;
  const url = `${baseUrl}${endpoint}`;
  if (options.body) {
    options.body = JSON.stringify(options.body);
  }
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(async (response) => {
        if (!response?.headers.get('content-type')?.includes('application/json')) {
          const text = await response.text();
          return reject(new Error(`No content-type:app/json header: ${url} ${response.status}: ${response.statusText} ${text.substr(0, 200)}`));
        }
        return response.json()
          .then((json) => {
            if (!response.ok) {
              // eslint-disable-next-line no-param-reassign
              json.status = response.status;
              return reject(json);
            }
            if (json.errors) {
              return reject(json.errors);
            }
            return resolve(json);
          });
      })
      .catch((err) => {
        const isAuthError = (endpoint === authUrls.user);
        if (isAuthError) {
          auth.clear('token'); window.location.reload();
        }
        return isAuthError ? null : err;
      });
  });
}
