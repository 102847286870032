/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import {
  background01, greyBorder, greyText, primary, red, text01, text02,
} from '@/common/constants/theme';

import Frame from 'react-frame-component';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import ReactHtmlParser from 'react-html-parser';

import WarningIcon from '@/common/components/icons/WarningIcon';

import { useAppContext } from '@/AppContext';

const CONTENT_VARIABLES_TEMPLATE = '{{V}}';

const TEST_CONTENT = '';
const HTML_BLOCK_HEIGHT = 280;
const HTML_BLOCKS_TYPES = {
  edit: 'edit',
  view: 'view',
};

const TemplateHtmlEditBlock = ({
  style, fields,
  content, contentVariables,
  onChangeContent,
}) => {
  const [showHtmlBlocks, setShowHtmlBlocks] = useState({
    [HTML_BLOCKS_TYPES.view]: true, [HTML_BLOCKS_TYPES.edit]: true,
  });
  const [isVarsHasTitle, setIsVarsHasTitle] = useState(false);

  const isCurrentContentHasVariable = (variable) => (content?.html_content || '')
    .includes(variable?.name || '');

  const {
    copyText,
  } = useAppContext();

  const getVariableText = (contentVar) => {
    let retVal = '';
    if ((contentVar?.name || '').length > 0) {
      retVal = CONTENT_VARIABLES_TEMPLATE.replace(/V/g, contentVar.name);
    }
    return retVal;
  };

  const handleShowBlock = (bType) => setShowHtmlBlocks(
    (prevState) => ({ ...prevState, [bType]: !prevState[bType] }),
  );

  useEffect(() => {
    setIsVarsHasTitle(((contentVariables || []).length > 0)
      && contentVariables[0]?.title);
  }, [contentVariables]);
  return (
    <Block
      mt="0"
      mb="0"
      isActive
      style={style}
    >
      <FlexBox>
        <LabelSwitcher
          mr="2em"
          isActive={!!showHtmlBlocks.edit}
          onClick={() => handleShowBlock(HTML_BLOCKS_TYPES.edit)}
        >
          {fields.html_content}
        </LabelSwitcher>
        <LabelSwitcher
          isActive={!!showHtmlBlocks.view}
          onClick={() => handleShowBlock(HTML_BLOCKS_TYPES.view)}
        >
          Просмотр
        </LabelSwitcher>
      </FlexBox>
      {content && (
        <FlexBox>
          <RichTextWrapper
            isHidden={!showHtmlBlocks.edit}
            noMr={!(showHtmlBlocks.view && showHtmlBlocks.edit)}
            width={!showHtmlBlocks.edit ? 0 : (showHtmlBlocks.view ? 50 : 100)}
          >
            <CodeMirror
              key={`html-edit--${content.componentId}`}
              value={content.html_content || TEST_CONTENT}
              extensions={[html({ autoCloseTags: true })]}
              height={`${HTML_BLOCK_HEIGHT}px`}
              onChange={(value) => onChangeContent('html_content', value)}
            />
          </RichTextWrapper>
          <RichTextWrapper
            isHidden={!showHtmlBlocks.view}
            width={!showHtmlBlocks.view ? 0 : (showHtmlBlocks.edit ? 50 : 100)}
          >
            <FrameStyled
              key={`html-view--${content.componentId}`}
              height={`${HTML_BLOCK_HEIGHT}px`}
            >
              {ReactHtmlParser(content.html_content || TEST_CONTENT)}
            </FrameStyled>
          </RichTextWrapper>
        </FlexBox>
      )}
      <FlexBox
        style={{ marginTop: '2rem' }}
        align={isVarsHasTitle ? 'start' : 'center'}
        isColumn={isVarsHasTitle}
      >
        {(contentVariables || []).length > 0 ? (
          <>
            <Label>Переменные шаблона: </Label>
            <ContentVariables
              isVertical={isVarsHasTitle}
            >
              {(contentVariables || []).map((v) => (
                <ContentVariableContainer
                  key={`A-PET-TDI-V--${v.name || Math.random()}`}
                  className="contentVariableContainer"
                >
                  <ContentVariable
                    title="Скопировать переменную в буфер"
                    onClick={() => copyText(getVariableText(v))}
                  >
                    {getVariableText(v)}
                    {v.required && (
                      <ContentVariableRequired
                        title="Обязательная переменная"
                      >
                        <WarningIcon
                          width={13}
                          height={13}
                          fill={!isCurrentContentHasVariable(v) ? red : greyText}
                        />
                      </ContentVariableRequired>
                    )}
                  </ContentVariable>
                  {v.title && (
                    <ContentVariableLabel>
                      {v.title}
                    </ContentVariableLabel>
                  )}
                </ContentVariableContainer>
              ))}
            </ContentVariables>
          </>
        ) : (
          <Label>Для шаблона не определено переменных</Label>
        )}
      </FlexBox>
    </Block>
  );
};

export default TemplateHtmlEditBlock;

const Block = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: ${({ mode }) => (mode || 'column')};
  background: ${background01};
  border-radius: 2px;
  margin: ${({
    mt, mr, mb, ml,
  }) => `${(mt || 20)}px ${(mr || 0)}px ${(mb || 20)}px ${(ml || 0)}px`};
  padding: 20px;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: ${({ align }) => (align || 'flex-start')};
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  width: 100%;
  flex:1;

  &>*:not(:last-child) {
    margin-right: 1.5em;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width || 'auto')};
`;

const RichTextWrapper = styled(InputWrapper)`
  width: ${({ width }) => ((width === undefined) ? 100 : width)}%;
  overflow: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  margin-top: 16px;
  ${({ noMr }) => (noMr && 'margin-right: 0 !important;')}
  transition: all .15s ease-out 0s;
`;

const FrameStyled = styled(Frame)`
  border: solid 1px ${greyBorder};
`;

const ContentVariables = styled.div`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
  align-items: flex-start;
  margin-top: ${({ isVertical }) => (isVertical ? '.5rem' : '0')};

  .contentVariableContainer:not(:last-child) {
    ${({ isVertical }) => (isVertical ? `
      margin-bottom: .25rem;
    ` : `
      margin-right: 2em;
    `)}
  }
  .contentVariableContainer > div{
    ${({ isVertical }) => (isVertical && `
      display: inline-block;
    `)}
  }
`;

const ContentVariableContainer = styled.div`
`;

const ContentVariable = styled.div`
  cursor: pointer;
  color: ${primary};
`;

const ContentVariableLabel = styled.div`
  &:before{
    content: '-';
    display: inline-block;
    margin: 0 .5rem;
  }
`;

const ContentVariableRequired = styled.div`
  position: relative;
  display: inline-block;
  margin-left: .25rem;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;
  ${({ mr }) => (mr && `margin-right: ${mr};`)}
`;
const LabelSwitcher = styled(Label)`
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? text01 : greyText)};

  &:hover{
    color: ${text02};
  }
`;
