import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

import styled from 'styled-components';
import {
  text01, greyText,
  secondary, white, inputDisabled,
} from '@/common/constants/theme';

import { useTranslation } from 'react-i18next';

import { useAppContext } from '@/AppContext';
import { useSupportContext } from '../SupportContext';

import Plus from '@/assets/icons/plus.svg';
import Loader from '@/common/components/Loader';
import ModalNew from '@/common/components/ModalNew';

import CreateMessage from './CreateMessage';

const CreateThread = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation('translations');

  const [subject, setSubject] = useState('');
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const { userInfo } = useAppContext();

  const {
    loaders,
    selectedMessage,
    addThread,
  } = useSupportContext();

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => addThread(
    subject,
    userInfo.email,
    () => setIsOpen(false),
  );

  useEffect(() => {
    setIsSubmitEnabled(((subject || '').length > 0)
      && ((selectedMessage?.text || '').length > 0));
  }, [subject, selectedMessage.text]);

  return (
    <ModalNew
      type="normal"
      width="600px"
      isOpen={isOpen}
      icon={Plus}
      title={t('personalPage.support.thread.new.title')}
      onCloseModal={handleCloseModal}
      centered
    >
      <Container>
        {(loaders?.newThread || false) && <Loader />}
        <InputContainer>
          <InputLabel>{t('personalPage.support.thread.new.subjectLabel')}</InputLabel>
          <StyledInput
            value={subject}
            onChange={({ target }) => setSubject(target.value)}
            placeholder={t('personalPage.support.thread.new.subjectPlaceholder')}
          />
        </InputContainer>
        <CreateMessage />
        <Actions>
          <Button
            disabled={!isSubmitEnabled}
            onClick={handleSubmit}
          >
            {t('personalPage.support.thread.new.submitButton')}
          </Button>
        </Actions>
      </Container>
    </ModalNew>
  );
};

export default CreateThread;

const Container = styled.div`
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  height: 2.4rem;
  width: 100%;
  margin-bottom: 1.8rem;
  &.ant-input:focus {
    border-color: ${secondary};
    box-shadow: none;
  }
  &.ant-input:hover {
    border-color: ${secondary};
    box-shadow: none;
  }
`;

const InputLabel = styled.div`
  width: 100%;
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin-bottom: .5rem;
`;

const Button = styled.button`
  border: none;
  background: ${({ disabled }) => (disabled ? inputDisabled : secondary)};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ disabled }) => (disabled ? greyText : white)};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.14));
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
`;
