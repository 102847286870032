import RequestService from '../request.service';
import urls from '../routes/dns.api';

export const getDnsAction = (limit, offset) => RequestService(urls.dnsList(limit, offset));

export const getAllDnsAction = () => RequestService(
  urls.dnsAllList,
  { method: 'GET', params: { limit: 100 } },
);

export const createDnsAction = (item) => (
  RequestService(urls.dnsAllList, {
    method: 'POST',
    body: item,
  })
);

export const getServersAction = () => (
  RequestService(urls.servers)
);

export const getDomainsAction = () => RequestService(
  urls.domains, { method: 'GET', params: { limit: 100 } },
);

export const getDefaultDomainAction = () => (
  RequestService(urls.defaultDomain)
);

export const deleteDNSAction = (id) => RequestService(urls.dnsItem(id), { method: 'DELETE' });

export const editDNSAction = (id, value) => RequestService(urls.dnsItem(id), {
  method: 'PUT',
  body: value,
});

export const addDNSAction = (value) => {
  const {
    inputValue,
    dropDown,
    serverDropDown,
  } = value;

  return { name: inputValue, domen: dropDown, server: serverDropDown };
};
