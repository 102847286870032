import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import {
  secondary,
  white, red,
  text01, text04,
} from '@/common/constants/theme';
import Dropdown from '@/common/components/Dropdown';

import {
  THREAD_STATUS,
  INITIAL_THREADS_FILTER,
  THREADS_TABLE_SIZES,
  useSupportContext,
} from '../SupportContext';
import CloseIcon from '../../../icons/CloseIcon';

const TIMEOUT_PAUSE = 1000 * 0.25;

const ThreadsFilters = () => {
  const { t } = useTranslation(['translations']);
  const {
    threadsFilter,
    setThreadsFilter,
  } = useSupportContext();

  const [searchText, setSearchText] = useState({});
  const [searchTimeouts, setSearchTimeouts] = useState({});

  const threadTypesOptions = Object.entries(THREAD_STATUS).map(([, key]) => ({
    value: key, title: t(`personalPage.support.threadsTypes.${key}`), key,
  }));

  const [selectedThreadTypeOption, setSelectedThreadTypeOption] = useState(THREAD_STATUS.all);

  const changeFilters = (field, value) => {
    setThreadsFilter({ ...threadsFilter, [field]: value, page: 1 });
  };

  const handleSearchInput = (type, text) => {
    setSearchText({ ...searchText, [type]: text || '' });
    if (searchTimeouts?.[type]) {
      clearTimeout(searchTimeouts[type]);
    }
    setSearchTimeouts({
      ...searchTimeouts,
      [type]: setTimeout(() => {
        changeFilters('search', { ...threadsFilter.search, [type]: text });
      }, TIMEOUT_PAUSE),
    });
  };

  const clearSearchFilter = (type) => {
    setSearchText({ ...searchText, [type]: '' });
    changeFilters('search', { ...threadsFilter.search, [type]: '' });
  };

  useEffect(() => {
    setSearchText(threadsFilter?.search || INITIAL_THREADS_FILTER.search);
    const useType = threadTypesOptions.find(
      (tto) => tto.value === (threadsFilter?.search?.status || THREAD_STATUS.all),
    );
    setSelectedThreadTypeOption(useType);
  }, [threadsFilter]);
  return (
    <TableFilter>
      <HeaderCell width={THREADS_TABLE_SIZES[0]}>
        <SearchContainer>
          <StyledInput
            placeholder={t('personalPage.support.filters.searchTitlePlaceholder')}
            value={searchText.title || ''}
            onChange={({ target }) => handleSearchInput('title', target.value)}
          />
          {(threadsFilter?.search?.title || '').length > 0 && (
            <Close onClick={() => clearSearchFilter('title')}>
              <CloseIcon />
            </Close>
          )}
        </SearchContainer>
      </HeaderCell>
      <HeaderCell width={THREADS_TABLE_SIZES[1]}>
        <SearchContainer>
          <StyledInput
            placeholder={t('personalPage.support.filters.searchNumberPlaceholder')}
            value={searchText.number || ''}
            onChange={({ target }) => handleSearchInput('number', target.value)}
          />
          {(threadsFilter?.search?.number || '').length > 0 && (
            <Close onClick={() => clearSearchFilter('number')}>
              <CloseIcon />
            </Close>
          )}
        </SearchContainer>
      </HeaderCell>
      <HeaderCell width={THREADS_TABLE_SIZES[2]}>
        <Dropdown
          placeholder={t('personalPage.support.filters.threadTypePlaceholder')}
          options={threadTypesOptions}
          value={selectedThreadTypeOption}
          onSelect={(item) => {
            setThreadsFilter((prevState) => (
              { ...prevState, search: { ...prevState.search, status: item.value } }
            ));
            setSelectedThreadTypeOption(item);
          }}
        />
      </HeaderCell>
      <HeaderCell width={THREADS_TABLE_SIZES[3]} withoutBorder />
      <HeaderCell width={THREADS_TABLE_SIZES[4]} withoutBorder />
    </TableFilter>
  );
};

export default ThreadsFilters;

const TableFilter = styled.div`
  display: flex;
  padding: 0 1rem;
  background: ${white};
`;

const HeaderCell = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: ${text01};
  padding: 0 .652rem;
  margin: .75rem 0 1rem;
  border-right: 1px solid #E0DEE8;
  display: flex;
  align-items: center;
  ${({ withoutBorder }) => withoutBorder && `
    border: none;
  `};
  width: ${({ width }) => (width || '100%')};
  justify-content: ${({ justify }) => justify};
  @media (max-width: 1360px) and (min-width: 1199px) { }
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled(Input)`
  height: 1.75rem;
  font-size: 13px;
  &.ant-input:focus {
    border-color: ${secondary};
    box-shadow: none;
  }
  &.ant-input:hover {
    border-color: ${secondary};
    box-shadow: none;
  }
`;

const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 0.25rem;
  top: 0;
  height: 100%;
  padding: 0 0.125rem;
  cursor: pointer;

  path {
    fill: ${red};
  }

  ${({ disabled }) => disabled && `
    path {
      fill: ${text04};
    }
  `}
`;
