import React from 'react';

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null
        && contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback,
  );
}

const Link = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { url, target } = props.contentState.getEntity(props.entityKey).getData();
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <a href={url} target={target}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </a>
  );
};

export default {
  strategy: findLinkEntities,
  component: Link,
};
