import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Loader from '@/common/components/Loader';
import AdminConfirmModal from '../../../../../Modals/ConfirmModal';
import {
  AdminMainPopupContent,
  AdminMainPopupHeader,
  StyledButton,
} from '../../../../components/MainPopupComponents';
import { useGroupsContext } from '../GroupsContext';

const GroupsImportCsv = () => {
  const {
    importData,
    getImportData,
    handleValidateData,
    handleImportData,
    validatedData,
    isLoading,
  } = useGroupsContext();

  const history = useHistory();

  const [isSyncModal, setIsSyncModal] = useState(false);

  return (
    <Container>
      {isLoading && <Loader />}
      <AdminConfirmModal
        isOpen={isSyncModal}
        onClose={() => setIsSyncModal(false)}
        handleSubmit={() => handleImportData().then(() => setIsSyncModal(false))}
        content="Вы действительно хотите импортировать IPTV каналы и группы?"
      />
      <AdminMainPopupHeader
        title="Импорт IPTV пакетов и групп"
        linkText="Вернуться “Группы”"
        handleClose={() => history.push('/admin/iptv/groups')}
      />
      <AdminMainPopupContent descr="Данные для импорта:">
        {importData.groups.length ? (
          <>
            # Список IPTV групп
            {' '}
            <br />
            {importData.groups.map((item) => Object.values(item).map((group, index) => (
              <>
                {group}
                ;
                {index + 1 === Object.keys(item).length ? <br /> : null}
              </>
            )))}
          </>
        ) : null}
        {importData.channels.length ? (
          <>
            # Список каналов
            {' '}
            <br />
            {importData.channels.map((item) => Object.values(item).map((channel, index) => (
              <>
                {channel}
                ;
                {index + 1 === Object.keys(item).length ? <br /> : null}
              </>
            )))}
          </>
        ) : null}
      </AdminMainPopupContent>
      <Wrapper flex>
        <StyledButton
          isBoldText
          isUpperCase
          onClick={getImportData}
        >
          Получить данные для импорта
        </StyledButton>
        <StyledButton
          isBoldText
          isUpperCase
          isOutline
          disabled={!importData.channels.length || !importData.groups.length}
          onClick={handleValidateData}
        >
          Проверка данных
        </StyledButton>
      </Wrapper>

      {Object.keys(validatedData).length ? (
        <>
          <AdminMainPopupContent descr="Данные для импорта:">
            {Object.keys(validatedData).map((itemName) => {
              const item = validatedData[itemName];

              return (
                <>
                  {itemName}
                  :
                  {item.cnt}
                  <br />
                  {item?.groups
                    ? item.groups.map((group) => Object.keys(group).map((groupName) => {
                      const currentGroup = item.groups[groupName];
                      return (
                        <>
                          {groupName}
                          :
                          {currentGroup}
                          ;
                          <br />
                        </>
                      );
                    }))
                    : item.channels.map((channel) => Object.keys(channel).map((channelName) => {
                      const currentChannel = item.channels[channelName];
                      return (
                        <>
                          {channelName}
                          :
                          {currentChannel}
                          ;
                          <br />
                        </>
                      );
                    }))}
                </>
              );
            })}
          </AdminMainPopupContent>
          <Wrapper>
            <StyledButton
              isBoldText
              isUpperCase
              onClick={() => setIsSyncModal(true)}
            >
              Выполнить синхронизацию
            </StyledButton>
          </Wrapper>
        </>
      ) : null}
    </Container>
  );
};
export default GroupsImportCsv;

const Container = styled.div``;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;
