import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { getMenu, getPermissions } from '@/store/admin/selectors';

import {
  activeTertiary,
  background01,
  borderSecondary,
  text01,
  white,
} from '@/common/constants/theme';

import { useAdminContext } from '../../AdminContext';

import NavigationItem from './components/NavigationItem';

const Navigation = () => {
  const [localMenu, setLocalMenu] = useState({});

  const loadedMenu = useSelector(getMenu);
  const userPermissions = useSelector(getPermissions);

  const { loadMenu } = useAdminContext();

  useEffect(() => {
    if (!loadedMenu) {
      loadMenu();
    } else if ((userPermissions?.length || 0) > 0
      && (Object.keys(loadedMenu)?.length || 0) > 0) {
      const userMenu = {};
      Object.entries(loadedMenu).forEach(([k, v]) => {
        const mItems = v.links.filter((itm) => userPermissions.includes(itm.href));
        if (mItems.length > 0) {
          userMenu[k] = { ...v, links: mItems };
        }
      });
      setLocalMenu(userMenu);
    }
  }, [userPermissions, loadedMenu]);

  return (
    <Container>
      {Object.entries(localMenu).map(([categoryName, category]) => (
        <Wrapper key={`${Math.random()}-${category.title}--nav`}>
          <Title>{category.title}</Title>
          <Links>
            {category.links.map((link) => (
              <NavigationItem
                key={`${Math.random()}-${categoryName}-${link.href}--nav`}
                href={link.href}
              >
                {link.title}
              </NavigationItem>
            ))}
          </Links>
        </Wrapper>
      ))}
    </Container>
  );
};

export default Navigation;

const Container = styled.div`
  flex-shrink: 0;

  width: 234px;
  min-height: 800px;
  padding: 14px 8px 8px 8px;
  margin-right: 8px;

  background: ${white};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.11);
  border: 1px solid ${borderSecondary};
  border-top: 2px solid ${activeTertiary};
  border-radius: 2px;

  @media (max-width: 1360px) {
    width: 190px;
  }

  @media (max-width: 1198px) {
    width: 234px;
  }
`;

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;

const Title = styled.div`
  margin-bottom: 14px;
  margin-left: 9px;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${text01};
  text-transform: uppercase;
  letter-spacing: 0.16px;
  line-height: 20px;

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 14px;
  }
`;

const Links = styled.div`
  background: ${background01};
  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  border-radius: 2px;
`;
