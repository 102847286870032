import React from 'react';
import { ProfileContextProvider } from './ProfileContext';
import Profile from './Profile';

const ProfileContainer = () => (
  <ProfileContextProvider>
    <Profile />
  </ProfileContextProvider>
);

export default ProfileContainer;
