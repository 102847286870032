import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { text01, red, green } from '@/common/constants/theme';

import { useTranslation } from 'react-i18next';

import Button from '@/common/components/Button';
import CompareResultPanel from './CompareResultPanel';
import CompareResultsInfo from './CompareResultsInfo';

import { useMinistraContext } from '../../../MinistraContext';

const SyncPanel = ({
  data,
  syncCallback,
  isSyncing,
  isInSync,
  isError,
  statusTextExt,
}) => {
  const dataStub = { groups: {}, channels: {} };
  const infoStub = { info: [], caption: '' };

  const [syncData, setSyncData] = useState(dataStub);
  const [isSyncEnabled, setIsSyncEnabled] = useState(false);
  const [statusText, setStatusText] = useState('');
  const [selectedResultItemKey, setSelectedResultItemKey] = useState('');
  const [displayInfo, setDisplayInfo] = useState(infoStub);
  const [statusColor, setStatusColor] = useState(null);

  const { selectedMinistra } = useMinistraContext();

  const { t } = useTranslation(['translations']);

  const sections = ['add', 'change', 'delete'];

  const getDataCount = (processedData) => {
    let count = 0;
    // eslint-disable-next-line max-len
    sections.forEach((s) => { count += processedData[s]?.count || 0; });
    return count;
  };

  useEffect(() => {
    const inData = data;
    const channelsCount = getDataCount(inData?.channels || {});
    const groupsCount = getDataCount(inData?.groups || {});
    const isDataGetted = channelsCount + groupsCount > 0;
    setIsSyncEnabled(isDataGetted);
    setStatusText(statusTextExt || (isDataGetted ? t('admin.iptv.ministra.compared') : t('admin.iptv.ministra.comparing')));
    if (channelsCount > 0) {
      inData.channels.all = channelsCount;
    }
    if (groupsCount > 0) {
      inData.groups.all = groupsCount;
    }
    setSyncData(inData);
    setSelectedResultItemKey('');
    setDisplayInfo(infoStub);
    let stColor = null;
    if (isInSync) {
      stColor = green;
    } else if (isError) {
      stColor = red;
    }
    setStatusColor(stColor);
  }, [data, statusTextExt, isInSync]);

  const openSubmitSyncWindow = () => {
    if (syncCallback) {
      syncCallback(selectedMinistra);
    }
  };

  return (
    <>
      <CompareResultsWrapper>
        <CompareResultsListsWrapper>
          {!isSyncing
            && (
            <TopWrapper>
              <CompareResultsList>
                {syncData?.groups
                  && (
                  <CompareResultPanel
                    type="groups"
                    sections={sections}
                    data={syncData.groups}
                    selectedResultItemKey={selectedResultItemKey}
                    setSelectedResultItemKey={setSelectedResultItemKey}
                    setDisplayInfo={setDisplayInfo}
                  />
                  )}
              </CompareResultsList>
              <CompareResultsList>
                {syncData?.channels
                  && (
                    <CompareResultPanel
                      type="channels"
                      sections={sections}
                      data={syncData.channels}
                      selectedResultItemKey={selectedResultItemKey}
                      setSelectedResultItemKey={setSelectedResultItemKey}
                      setDisplayInfo={setDisplayInfo}
                    />
                  )}
              </CompareResultsList>
            </TopWrapper>
            )}
          <BottomWrapper>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isSyncEnabled && !isSyncing && !isInSync
              ? (
                <SyncButton disabled={isSyncing} onClick={() => openSubmitSyncWindow()}>
                  {isSyncing ? t('admin.iptv.ministra.syncing') : t('admin.iptv.ministra.doSync')}
                </SyncButton>
              ) : (
                <Text setColor={statusColor}>
                  {statusText}
                </Text>
              )}
          </BottomWrapper>
        </CompareResultsListsWrapper>
        <CompareResultsInfoWrapper>
          {/* eslint-disable-next-line max-len */}
          {(syncData?.channels || syncData?.groups) && <CompareResultsInfo displayInfo={displayInfo} />}
        </CompareResultsInfoWrapper>
      </CompareResultsWrapper>
    </>
  );
};

export default SyncPanel;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 175px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-width: 175px;
`;

const BottomWrapper = styled.div`
  margin: 15px 0 0 0;
  text-align: center;
  min-width: 175px;
`;

const CompareResultsWrapper = styled(Wrapper)`

  &::after{
    display: block;
    content: '';
    clear: both;
  }
`;

const CompareResultsListsWrapper = styled.div`
  display: flow-root;
  width: 25%;
  margin-top: -35px;
`;

const CompareResultsInfoWrapper = styled.div`
  width: 73%;
  margin-left: 2%;
`;

const CompareResultsList = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  &:empty{
    display: none;
  }

  &:last-of-type{
    margin-bottom: 0;
  }

  &:last-of-type::after{
    display: block;
    content: '';
    clear: both;
  }
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  color: ${({ setColor }) => (setColor || text01)};
  letter-spacing: 0.16px;
  line-height: 15px;
  margin-top: 15px;
`;

const SyncButton = styled(Button)`
  width: calc(100% - 2em);
  padding: 0 2em;
`;
