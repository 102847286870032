import React from 'react';
import styled from 'styled-components';

import {
  background01,
  text01,
  white,
  primary,
} from '@/common/constants/theme';

import Button from '@/common/components/Button';
import ArrowRightPrimary from '../../icons/ArrowRightPrimary';

export const AdminMainPopupHeader = ({ title, linkText, handleClose }) => (
  <Wrapper flex flexSb>
    <Title>
      {title}
    </Title>
    <Wrapper
      flex
      topMargin
      pointer
      onClick={() => handleClose()}
    >
      <PrimaryText>
        {linkText}
      </PrimaryText>
      <Icon>
        <ArrowRightPrimary />
      </Icon>
    </Wrapper>
  </Wrapper>
);

export const AdminMainPopupContent = ({ children, descr }) => (
  <MainContent>
    <Text bottomMargin>
      {descr}
    </Text>
    <Data>
      {children}
    </Data>
  </MainContent>
);

export const MainContent = styled.div`
  margin: 20px 0;
  padding: 14px 18px 30px;

  background: ${background01};
  border-radius: 2px;

  &::after{
      content: '';
      display: block;
      clear: both;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}

  ${({ topMargin }) => topMargin && ('margin-top: 15px;')}

  ${({ pointer }) => pointer && ('cursor: pointer;')}
`;

export const Data = styled.div`
  height: 221px;
  padding: 10px 19px;

  overflow: auto;
  background: ${white};
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  white-space: pre-wrap;
  letter-spacing: 0.16px;
  line-height: 24px;
`;

export const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 30px;')}

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 15px;
`;

export const Title = styled(Text)`
  margin-top: 12px;
  margin-left: 18px;

  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
`;

const PrimaryText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${primary};
  line-height: 20px;
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;
  top: 2px;
`;

export const StyledButton = styled(Button)`
  width: auto;
  min-width: 260px;
  padding: 0 17px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));

  white-space: nowrap;

  &:first-child {
    margin-right: 42px;
    margin-left: 18px;
  }

  &[disabled]:hover {
    background-color: transparent;

    color: ${text01};
  }
`;
