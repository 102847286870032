import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  white,
  text01,
  secondary,
} from '@/common/constants/theme';

import Button from '@/common/components/Button';

import {
  useAppContext,
} from '@/AppContext';

const HomeSubscriptionCards = ({ isUserExist }) => {
  const { t } = useTranslation('translations');

  const { prices, discounts, getFromPublicLink } = useAppContext();

  const SubscribeLinkData = getFromPublicLink('/personal-page/subscription', isUserExist);

  const [cards, setCards] = useState([
    {
      title: t('homePage.subscription.subscr.cards.card1'),
      daysCount: 360,
    },
    {
      title: t('homePage.subscription.subscr.cards.card2'),
      daysCount: 180,
    },
    {
      title: t('homePage.subscription.subscr.cards.card3'),
      daysCount: 90,
    },
    {
      title: t('homePage.subscription.subscr.cards.card4'),
      daysCount: 30,
    },
  ]);

  const getCardPriceDiscount = ({ daysCount }) => {
    let discount = 0;
    discounts.forEach((d) => {
      if (d.daysCount <= daysCount) {
        discount = d.percent;
      }
    });
    return {
      price: ((1 - discount / 100) * prices.dayPrice * daysCount)
        .toFixed(2).replace('.00', ''),
      discount,
    };
  };

  useEffect(() => {
    if (!prices || !discounts) {
      return;
    }
    const useCards = [...cards]
      .map((c) => ({ ...c, ...getCardPriceDiscount(c) }));
    setCards(useCards);
  }, [prices, discounts]);

  const renderCard = ({ title, price, discount }) => (
    <Card key={`${Math.random()}--home-subscr-card`}>
      <Text>
        {t('homePage.subscription.subscr.cards.title')}
      </Text>
      <Wrapper flex>
        <BoldText>{title}</BoldText>
        <BoldText margin>/</BoldText>
        <BoldOrangeText>
          {price}
          $
        </BoldOrangeText>
      </Wrapper>
      <BoldOrangeText
        fontSizeLittle
      >
        {discount > 0 ? `-${discount}%` : t('homePage.subscription.subscr.cards.noDiscount')}
      </BoldOrangeText>
      <Link to={SubscribeLinkData}>
        <Button
          isBoldText
          isUpperCase
          isOutline
          width="small"
        >
          {t('homePage.subscription.subscr.cards.btn')}
        </Button>
      </Link>
    </Card>
  );

  return (
    <Container>
      {cards.map(renderCard)}
    </Container>
  );
};

export default HomeSubscriptionCards;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: calc(50% - 7px);
  height: 182px;
  margin-bottom: 15px;
  padding: 20px 0;

  background: ${white};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const Text = styled.div`
  margin-bottom: 5px;

  font-family: Gilroy, sans-serif;
  color: ${text01};
  font-size: 14px;
  line-height: 20px;
`;

const BoldText = styled(Text)`
  margin-bottom: 12px;
  ${({ margin }) => margin && ('margin: 0 5px;')}

  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
`;

const BoldOrangeText = styled(BoldText)`
  color: ${secondary};

  ${({ fontSizeLittle }) => fontSizeLittle && (`
    font-size: 14px;
    line-height: 17px;
  `)}
`;
