import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import GeoTable from './components/Table';
import { GeoContextProvider } from './GeoContext';
import { useAdminContext } from '../../../../../AdminContext';

import { getCountriesRaw } from '@/store/admin/selectors';

const GeoContainer = () => {
  const { loadCountries, getGroups } = useAdminContext();

  const storedCountriesRaw = useSelector(getCountriesRaw);

  useEffect(() => {
    if (!storedCountriesRaw) { loadCountries(); }
  }, [storedCountriesRaw]);

  useEffect(() => {
    getGroups(0);
  }, []);

  return (
    <GeoContextProvider>
      <Container>
        <GeoTable />
      </Container>
    </GeoContextProvider>
  );
};

export default GeoContainer;

const Container = styled.div``;
