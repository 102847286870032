export const SET_SNACK = 'SET_SNACK';
export const SET_MENU = 'SET_MENU';
export const SET_MENU_ITEMS = 'SET_MENU_ITEMS';
export const SET_SERVERS = 'SET_SERVERS';
export const SET_OS_FORMATS = 'SET_OS_FORMATS';
export const SET_MINISTRA_SERVERS = 'SET_MINISTRA_SERVERS';
export const SET_WITHDRAWAL_SYSTEMS = 'SET_WITHDRAWAL_SYSTEMS';
export const SET_PAYMENT_SYSTEMS = 'SET_PAYMENT_SYSTEMS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_RAW_COUNTRIES = 'SET_RAW_COUNTRIES';
export const SET_PASSWORD_PIN_CHECK_COUNT = 'SET_PASSWORD_PIN_CHECK_COUNT';
export const SET_PASSWORD_PIN_CHECK_EXPIRE_DATE = 'SET_PASSWORD_PIN_CHECK_EXPIRE_DATE';
