import React from 'react';

import { PlayerContextProvider } from './PlayerContext';
import PlayerPage from './PlayerPage';

const PlayerContainer = () => (
  <PlayerContextProvider>
    <PlayerPage />
  </PlayerContextProvider>
);

export default PlayerContainer;
