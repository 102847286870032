export default {
  playlistList: '/api/v1/iptv/playlist/list/',
  playlistItem: (id) => `/api/v1/iptv/playlist/${id}/`,

  groupsList: (playlistId) => `/api/v1/iptv/playlist/${playlistId}/groups/`,
  groupItem: (playlistId, groupId) => `/api/v1/iptv/playlist/${playlistId}/groups/${groupId}/`,
  groupDragAndDrop: (playlistId, groupId) => `/api/v1/iptv/playlist/${playlistId}/groups/${groupId}/drag-and-drop/`,
  groupsHide: (playlistId) => `/api/v1/iptv/playlist/${playlistId}/groups/hide/`,
  groupHide: (playlistId, groupId) => `/api/v1/iptv/playlist/${playlistId}/groups/${groupId}/hide/`,

  channelDragAndDrop: (playlistId, groupId, channelId) => `/api/v1/iptv/playlist/${playlistId}/groups/${groupId}/channels/${channelId}/drag-and-drop/`,
  channelMove: (playlistId, groupId, channelId) => `/api/v1/iptv/playlist/${playlistId}/groups/${groupId}/channels/${channelId}/move`,
  channelsHide: (playlistId, groupId) => `/api/v1/iptv/playlist/${playlistId}/groups/${groupId}/channels/hide/`,
  channelHide: (playlistId, groupId, channelId) => `/api/v1/iptv/playlist/${playlistId}/groups/${groupId}/channels/${channelId}/hide`,
  allAvailableChannels: '/api/v1/iptv/playlist/channels/',

  playlistOs: '/api/v1/iptv/playlist-os/',
};
