import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import EditIcon from '../../../../../../common/components/icons/EditIcon';
import CloseIcon from '../../../../../../common/components/icons/CloseIcon';
import AddIcon from '../../../../../../assets/icons/bordered-plus.svg';
import { useDNSContext } from '../DNSContext';
import Table from '../../components/Table/Table';
import TableHead from '../../../../../../common/components/Table/components/TableHead';
import TableBody from '../../components/Table/components/TableBody';
import TablePagination from '../../components/Table/components/TablePagination';

import pageSizes from '../../../../../../common/constants/pageSizes';

const rowHeight = 54;

const DNSTable = ({
  deleteHandler,
  addHandler,
  editHandler,
}) => {
  const {
    dns,
    servers,
    dnsCount,
    dnsFilters,
    setDnsFilters,
  } = useDNSContext();
  const { t } = useTranslation('translations');

  const { limit, page } = dnsFilters;

  const changeFiltersHandler = (field, value) => {
    setDnsFilters({ ...dnsFilters, [field]: value });
  };

  const headers = [
    t('DNS.table.title'),
    t('DNS.table.server'),
  ];

  const sizes = [
    '45%',
    '55%',
  ];

  return (
    <Container>
      <Table
        pageLimit={limit}
        cellSizes={sizes}
        page={page}
        dataLength={dnsCount}
        textAlign="left"
      >
        <TableHead>
          {headers.map((item, i) => (
            <HeadItem
              /* eslint-disable-next-line react/no-array-index-key */
              key={`${item}-${i}--personalPageDnsTableHeader`}
              flex={i === 0}
            >
              {item}
              {i === 0 && (
                <Icon>
                  <Img
                    src={AddIcon}
                    alt=""
                    onClick={addHandler}
                  />
                </Icon>
              )}
            </HeadItem>
          ))}
        </TableHead>
        <TableBody
          rowHeight={rowHeight}
          autoRowHeight
          minRows={dns.length > pageSizes[0]
            ? dns.length : pageSizes[0]}
        >
          {dns.filter((item) => item.id > 0).map((item, i) => (
            <div
              /* eslint-disable-next-line react/no-array-index-key */
              key={`${item}-${i}--personalPageDnsTableRow`}
            >
              <TableRow title={item.name}>
                {item.name.length >= 20
                  ? `${item.name.slice(0, 20)}...`
                  : item.name}
              </TableRow>
              <TableRow flex>
                <Wrapper>
                  {servers.find((server) => server.id === item.serverId)?.title || ''}
                </Wrapper>
                <Wrapper flex>
                  <Icon onClick={() => editHandler(item)}>
                    <EditIcon />
                  </Icon>
                  <Icon onClick={() => deleteHandler(item)}>
                    <CloseIcon />
                  </Icon>
                </Wrapper>
              </TableRow>
            </div>
          ))}
        </TableBody>
        <TablePagination
          page={page}
          setPage={(v) => changeFiltersHandler('page', v)}
          pageCount={Math.ceil(dnsCount / limit)}
          pageLimit={limit}
          setPageLimit={(v) => {
            const maxPage = Math.ceil(dnsCount / v);
            if (page > maxPage) {
              setDnsFilters((prevState) => ({
                ...prevState,
                limit: v,
                page: maxPage,
              }));
            } else {
              changeFiltersHandler('limit', v);
            }
          }}
        />
      </Table>
    </Container>
  );
};

export default DNSTable;

const Container = styled.div`
  & .table-main-head {
    padding-left: 27px;
  }

  & .table-rows__row {
    padding-left: 27px;
    padding-right: 46px;

    &__item {
      &:last-child {
        margin-left: 55px;
      }
    }
  }
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;

const HeadItem = styled.div`
  ${({ flex }) => flex && (`
    display: flex;
    justify-content: space-between;

    width: 100%;
  `)}
`;

const TableRow = styled.div`
  ${({ flex }) => flex && (`
    display: flex;
    justify-content: space-between;

    width: 100%;
  `)}
`;

const Icon = styled.div`
  margin-right: 10px;

  cursor: pointer;
`;

const Img = styled.img``;
