import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { secondary } from '@/common/constants/theme';

import PageContainer from '@/common/components/PageContainer';
import getWordDeclension, { ENDINGS } from '@/common/utils/humanization.util';

import BgImgSrc from '@/assets/images/webp/home-adv-bg_full.webp';

import TVPlayIcon from '../../../../PersonalPageScreen/components/icons/TVPlayIcon';
import TVIcon from '../../../../PersonalPageScreen/components/icons/TVIcon';
import HomeAdvCard from './components/HomeAvdCard';

import { TV_ARC_DAYS, useAppContext } from '@/AppContext';

const DISCOUNTS_BONUSES_LEFT_MARGIN = 20;
const DISCOUNTS_BONUSES_TOP_MARGIN = 5;

const HomeAdv = () => {
  const { t } = useTranslation('translations');

  const { discounts, systemSettings } = useAppContext();

  const displayDiscount = Object.values((discounts || []))
    .find((d) => d.daysCount === 360)?.percent || 0;

  const allDiscountsNBonuses = (discounts || []).map((d) => d.percent);
  const rplBonus = systemSettings?.bonus_replenishment?.percent || 0;
  if (rplBonus > 0) {
    allDiscountsNBonuses.push(rplBonus);
  }

  const tvArcDaysText = `${TV_ARC_DAYS} ${getWordDeclension(
    TV_ARC_DAYS, ENDINGS.map((e) => t(`common.declension.days.${e}`)),
  )}`;

  const renderDiscountsNBonuses = () => {
    const keyBase = 'HP-AB-DB-';
    const useData = (discounts || []).map((d) => d.percent) || [];
    const useReplenishmentBonus = systemSettings?.bonus_replenishment?.percent || 15;
    if (useReplenishmentBonus > 0) {
      useData.push(useReplenishmentBonus);
    }
    return (
      <DiscountsNBonuses>
        {useData.map((d, index) => (
          <DiscountsNBonusesValue
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${keyBase}${d}-${index}`}
            leftMargin={DISCOUNTS_BONUSES_LEFT_MARGIN}
            topMargin={DISCOUNTS_BONUSES_TOP_MARGIN}
          >
            {`${d}%`}
          </DiscountsNBonusesValue>
        ))}
      </DiscountsNBonuses>
    );
  };

  const advantages = [
    {
      mainTitle: '+1800',
      title: t('homePage.adv.adv01.title'),
      descr: t('homePage.adv.adv01.descr'),
    },
    {
      mainTitle: 28,
      title: t('homePage.adv.adv02.title'),
      descr: t('homePage.adv.adv02.descr'),
    },
    {
      mainTitle: '+300',
      title: t('homePage.adv.adv03.title'),
      descr: t('homePage.adv.adv03.descr'),
    },
    {
      mainTitle: 15,
      title: t('homePage.adv.adv04.title'),
      descr: t('homePage.adv.adv04.descr'),
    },
    {
      mainTitle: `-${displayDiscount}%`,
      customTitle: renderDiscountsNBonuses(),
      title: t('homePage.adv.adv05.title', { discountPercent: displayDiscount }),
      descr: t('homePage.adv.adv05.descr', { discountPercent: Math.max(...allDiscountsNBonuses) }),
    },
    {
      mainTitle: 10,
      title: t('homePage.adv.adv06.title'),
      descr: t('homePage.adv.adv06.descr'),
    },
    {
      mainTitle: (
        <>
          {TV_ARC_DAYS}
          <TVPlayIcon />
        </>
      ),
      title: t('homePage.adv.adv07.title', { daysCount: tvArcDaysText }),
      descr: t('homePage.adv.adv07.descr', { daysCount: tvArcDaysText }),
    },
    {
      mainTitle: <TVIcon />,
      title: t('homePage.adv.adv08.title'),
      descr: t('homePage.adv.adv08.descr'),
    },
  ];

  return (
    <Container>
      <Background>
        <Img
          src={BgImgSrc}
          alt=""
        />
      </Background>
      <PageContainer>
        <Wrapper>
          {advantages.map((item) => (
            <HomeAdvCard
              key={`${Math.random()}--home-adv`}
              title={item.title}
              mainTitle={item.mainTitle}
              customTitle={item.customTitle || null}
              descr={item.descr}
            />
          ))}
        </Wrapper>
      </PageContainer>
    </Container>
  );
};

export default HomeAdv;

const Container = styled.div`
  display: flex;
  justify-content: center;

  padding-top: 52px;
  padding-bottom: 50px;

  position: relative;

  overflow: hidden;
  background: linear-gradient(180deg, rgba(92, 112, 207, 0.93) 0%, rgba(63, 80, 159, 0.93) 100%);
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  position: relative;
`;

const Img = styled.img`
  max-width: 1920px;
`;

const Background = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;

  transform: translateX(-50%);
`;

const DiscountsNBonuses = styled.div`
`;

const DiscountsNBonusesValue = styled.div`
  display: block;
  position: absolute;

  font-family: Gilroy, sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 25px;
  color: ${secondary};

  &:nth-child(1) {
    top: ${({ topMargin }) => ((topMargin || 0) + 10)}%;
    left: ${({ leftMargin }) => ((leftMargin || 0) + 5)}%;
  }
  &:nth-child(2) {
    top: ${({ topMargin }) => ((topMargin || 0) + 15)}%;
    left: ${({ leftMargin }) => ((leftMargin || 0) + 35)}%;
  }
  &:nth-child(3) {
    top: ${({ topMargin }) => ((topMargin || 0) + 55)}%;
    left: ${({ leftMargin }) => ((leftMargin || 0) + 15)}%;
  }
`;
