import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

import styled from 'styled-components';
import {
  background01,
  borderSecondary,
  primary,
  white,
  red,
} from '@/common/constants/theme';

import Close from '@/assets/icons/close.svg';
import Edit from '@/assets/icons/edit.svg';
import Add from '@/assets/icons/bordered-plus.svg';

import Tooltip from '@/common/components/Tooltip';
import Dropdown from '@/common/components/Dropdown';
import SearchIcon from '../../../../../../icons/SearchIcon';
import ChannelsMainHeaderModals from './components/ChannelsMainHeaderModals';

import { useChannelsContext } from '../../../../ChannelsContext';

const ChannelsMainHeader = ({
  changeFiltersHandler,
}) => {
  const { t } = useTranslation('translations');
  const {
    loaders,
    playLists,
    channelsFilters,
    selectedPlaylist,
    handleSelectPlayList,
    defaultPlaylist,
  } = useChannelsContext();

  const {
    search,
  } = channelsFilters;

  const playListToOption = (p) => {
    const retValue = { ...(p || {}), key: p?.id, value: p?.id };
    if (p?.is_virtual) {
      retValue.color = red;
    }
    return (retValue);
  };

  const [currentModal, setCurrentModal] = useState('');
  const [playListOptions, setPlayListOptions] = useState([]);
  const [playListOptionsValue,
    setPlayListOptionsValue] = useState(playListToOption(selectedPlaylist || defaultPlaylist));

  const [isEditable, setIsEditable] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const getToolTipText = (key) => ReactHtmlParser(
    t(`steps.step4.content.body.controls.tooltips.${key}PlayList`)
      .replace(' ', '&nbsp;'),
  );

  useEffect(() => {
    setPlayListOptions(
      [...(playLists || []).map(playListToOption)],
    );
  }, [playLists]);

  useEffect(() => {
    if (playListOptions.length === 0) {
      return;
    }
    setPlayListOptionsValue(
      playListToOption(selectedPlaylist || defaultPlaylist),
    );
    setIsEditable(selectedPlaylist && (selectedPlaylist?.id !== defaultPlaylist?.id));
  }, [playListOptions, defaultPlaylist, selectedPlaylist]);

  useEffect(() => {
    setIsBusy((Object.values(loaders).filter((v) => v).length > 0));
  }, [loaders]);

  return (
    <Container>
      <Wrapper flex>
        <ChannelsMainHeaderModals
          currentModal={currentModal}
          setCurrentModal={setCurrentModal}
        />
        <StyledDropdown
          options={playListOptions}
          value={playListOptionsValue}
          onSelect={(item) => handleSelectPlayList(item.key)}
          isBoldText
          isUpperCase
          color={primary}
          menuClassName="channels-main-header-dropdown"
          isBusy={isBusy}
        />
        <Wrapper flex leftMargin={4}>
          <Tooltip
            title={getToolTipText('delete')}
            width="auto"
            isDisabled={selectedPlaylist?.is_virtual}
          >
            <Img
              src={Close}
              alt=""
              onClick={() => (playLists || []).length > 1
                && (isEditable && !isBusy && !selectedPlaylist?.is_virtual)
                && setCurrentModal('delete')}
              opacity={!(isEditable && !isBusy && !selectedPlaylist?.is_virtual) ? 'true' : 'false'}
            />
          </Tooltip>
          <Tooltip
            title={getToolTipText('edit')}
            width="auto"
          >
            <Img
              src={Edit}
              alt=""
              onClick={() => (isEditable && !isBusy)
                && setCurrentModal('edit')}
              opacity={!(isEditable && !isBusy) ? 'true' : 'false'}
            />
          </Tooltip>
          <Tooltip
            title={getToolTipText('create')}
            width="auto"
          >
            <Img
              src={Add}
              alt=""
              leftMargin
              onClick={() => !isBusy && setCurrentModal('create')}
              opacity={isBusy ? 'true' : 'false'}
            />
          </Tooltip>
        </Wrapper>
      </Wrapper>
      <Wrapper leftSep flex>
        <Icon>
          <SearchIcon />
        </Icon>
        <Input
          placeholder={t('steps.step4.content.body.searchChannels')}
          value={search}
          onChange={({ target }) => changeFiltersHandler('search', target.value)}
        />
      </Wrapper>
    </Container>
  );
};

export default ChannelsMainHeader;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 66px;
  padding-left: 20px;
  padding-right: 28px;

  position: relative;
  z-index: 1;

  background: ${background01};
  border: 1px solid ${borderSecondary};
  border-radius: 4px 4px 0 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ alignCenter }) => alignCenter && ('align-items: center;')}

  ${({ leftMargin }) => leftMargin && (`margin-left: ${leftMargin}px;`)}

  ${({ leftSep }) => leftSep && (`
    position: relative;

    &::after {
      content: '';

      height: 30px;
      width: 1px;

      position: absolute;
      left: -50px;
      top: 0;

      background-color: ${borderSecondary};
    }
  `)}
`;

const StyledDropdown = styled(Dropdown)`
  width: 225px;

  & input {
    width: 95%;

    font-size: 12px;
  }

  & .channels-main-header-dropdown {
    width: 286px;
    left: -20px;

    top: calc(100% + 22px);

    &::after,
    &::before {
      content: '';

      width: 0;
      height: 0;

      position: absolute;

      border-style: solid;
    }

    &::after {
      left: 19px;
      top: -21px;
      z-index: 12;

      border-width: 0 20.5px 23px 20.5px;
      border-color: transparent transparent ${white} transparent;
    }

    &::before {
      left: 18px;
      top: -22px;

      border-width: 0 21px 24px 21px;
      border-color: transparent transparent ${borderSecondary} transparent;
    }
  }
`;

const Img = styled.img`
  width: 15px;
  margin-left: 10px;
  ${({ leftMargin }) => leftMargin && ('margin-left: 14px;')}

  ${({ opacity }) => `
    opacity: ${opacity === 'true' ? 0.2 : 1};
    cursor: ${opacity === 'true' ? 'pointer' : 'default'};
  `}
  transition: 0.2s ease;
`;

const Icon = styled.div`
  position: relative;
  top: 3px;
`;

const Input = styled.input`
  width: 125px;
  margin-left: 15px;

  background-color: transparent;
  border: none;
  outline: none;
`;
