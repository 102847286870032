import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import PlusIcon from '@/assets/icons/plus.svg';
import { text01 } from '@/common/constants/theme';
import ModalNew from '@/common/components/ModalNew';
import Input from '@/common/components/AuthorizationInput';
import Button from '@/common/components/Button';
import { useServerContext } from '../ServerContext';
import Dropdown from '@/common/components/Dropdown';
import { fetchDeviceMAGServers, validatePasswordAction } from '@/services/actions/server.actions';

const ServerCreateLoginModal = ({
  device,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { validatePassword } = useServerContext();
  const { t } = useTranslation(['translations']);

  const [MAGServerValue, setMAGServerValue] = useState(null);
  const [loginValue, setLoginValue] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [passwordValue, setPasswordValue] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [confirmedPasswordValue, setConfirmedPasswordValue] = useState('');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState(null);
  const [MAGServers, setMAGServers] = useState([]);
  const [type, setType] = useState('create');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const clearData = () => {
    setLoginValue('');
    setPasswordValue('');
    setConfirmedPasswordValue('');
    setLoginError(null);
    setPasswordError(null);
    setConfirmedPasswordError(null);
    setMAGServerValue(null);
    setType('create');
  };

  useEffect(() => {
    if (isOpen) {
      if (device) {
        setLoginValue(device.ministra_login);
        setPasswordValue(device.ministra_password);
        setConfirmedPasswordValue(device.ministra_password);
      } else {
        clearData();
      }

      fetchDeviceMAGServers().then(({ results }) => {
        let MAGServerItem;

        if (device?.ministra_server) {
          const { id, title } = results.find((s) => s.id === device.ministra_server);
          MAGServerItem = {
            key: id,
            title,
            value: id,
          };
        } else {
          MAGServerItem = {
            key: results[0].id,
            title: results[0].title,
            value: results[0].id,
          };
        }

        setMAGServerValue(MAGServerItem);
        setMAGServers(results.map(({ id, title }) => ({
          key: id,
          title,
          value: id,
        })));
      });

      const isExisting = ((device?.ministra_login?.length || 0) > 0
        && (device?.ministra_password?.length || 0) > 0
        && (device?.ministra_server || 0) > 0);

      setType(isExisting ? 'change' : 'create');
    }
  }, [isOpen]);

  const checkIsPasswordsValuesIdent = () => {
    if (confirmedPasswordValue !== passwordValue && passwordValue && confirmedPasswordValue) {
      setConfirmedPasswordError(t('steps.step3.content.modal.login.errors.confirmPasswordError'));
    } else if (passwordValue && confirmedPasswordValue) {
      setConfirmedPasswordError('');
    }
  };

  const onBlurLogin = (value) => {
    if (value.length < 1) {
      setLoginError(t('steps.step3.content.modal.login.errors.emptyLogin'));
    } else {
      setLoginError('');
    }
  };

  const onBlurPassword = () => {
    if (validatePasswordAction(passwordValue || '')) {
      setPasswordError(t(`steps.step3.content.modal.login.errors.${validatePassword(passwordValue || '')}`));
    } else {
      setPasswordError(null);
    }
    checkIsPasswordsValuesIdent();
  };

  const onBlurConfirmedPassword = () => {
    checkIsPasswordsValuesIdent();
  };

  const isDataCorrect = () => (
    ((loginValue?.length || 0) > 0)
    && ((passwordValue?.length || 0) > 6)
    && (((confirmedPasswordValue?.length || 0) > 0)
      && (confirmedPasswordValue === passwordValue))
  );

  const isDataChanged = () => {
    const MAGId = MAGServerValue?.value || 0;
    return ((loginValue.length > 0) && (loginValue !== device.ministra_login))
      || ((passwordValue.length > 0) && (passwordValue !== device.ministra_password))
      || (((MAGId > 0) && (MAGId !== device.ministra_server)));
  };

  useEffect(() => {
    setIsSubmitDisabled(!isDataCorrect() || !isDataChanged());
  }, [passwordValue, confirmedPasswordValue, loginValue, MAGServerValue]);

  // eslint-disable-next-line consistent-return
  const submitHandler = () => {
    if ((loginValue?.length || 0) <= 0) {
      setLoginError(t('steps.step3.content.modal.login.errors.emptyLogin'));
      return false;
    }
    if ((passwordValue?.length || 0) < 6) {
      setLoginError(t('steps.step3.content.modal.login.errors.lengthError'));
      return false;
    }
    if ((confirmedPasswordValue?.length || 0) < 6
      || (confirmedPasswordValue !== passwordValue)) {
      setLoginError(t('steps.step3.content.modal.login.errors.confirmPasswordError'));
      return false;
    }

    onSubmit({
      ministra_server: MAGServerValue.value,
      ministra_login: loginValue,
      ministra_password: passwordValue,
    });
    clearData();
  };

  const closeHandler = () => {
    clearData();
    onClose();
  };

  return (
    <ModalNew
      title={t(`steps.step3.content.modal.login.title.${type}`)}
      isOpen={isOpen}
      onCloseModal={closeHandler}
      icon={PlusIcon}
    >
      <Container>
        <Wrapper>
          <Wrapper>
            <Text topMargin>
              {t('steps.step3.content.modal.login.server')}
            </Text>
            <StyledDropdown
              options={MAGServers}
              value={MAGServerValue}
              onSelect={setMAGServerValue}
              height="39px"
            />
          </Wrapper>
          <Wrapper>
            <Text topMargin>
              {t('steps.step3.content.modal.login.login')}
            </Text>
            <InputWrapper isError={loginError}>
              <Input
                withoutBorder
                placeholder={t('steps.step3.content.modal.login.loginPlaceholder')}
                value={loginValue}
                onChange={({ target }) => setLoginValue(target.value)}
                onBlur={({ target }) => onBlurLogin(target.value)}
                errorText={loginError}
              />
            </InputWrapper>
          </Wrapper>
          <Wrapper>
            <Text>
              {t('steps.step3.content.modal.login.password')}
            </Text>
            <InputWrapper isError={passwordError}>
              <Input
                withoutBorder
                isCopied
                placeholder={t('steps.step3.content.modal.login.passwordPlaceholder')}
                label={t('steps.step3.content.modal.login.passwordPlaceholder')}
                type="password"
                value={passwordValue}
                onChange={({ target }) => setPasswordValue(target.value)}
                onBlur={({ target }) => onBlurPassword(target.value)}
                errorText={passwordError}
              />
            </InputWrapper>
          </Wrapper>
          <Wrapper>
            <Text>
              {t('steps.step3.content.modal.login.repeatPassword')}
            </Text>
            <InputWrapper isError={confirmedPasswordError}>
              <Input
                withoutBorder
                placeholder={t('steps.step3.content.modal.login.repeatPasswordPlaceholder')}
                type="password"
                value={confirmedPasswordValue}
                onChange={({ target }) => setConfirmedPasswordValue(target.value)}
                onBlur={onBlurConfirmedPassword}
                errorText={confirmedPasswordError}
              />
            </InputWrapper>
          </Wrapper>
        </Wrapper>
        <FlexWrapper>
          <StyledButton
            isDisabled={isSubmitDisabled}
            isBoldText
            isUpperCase
            onClick={submitHandler}
          >
            {t(`steps.step3.content.modal.login.${type}`)}
          </StyledButton>
        </FlexWrapper>
      </Container>
    </ModalNew>
  );
};

export default ServerCreateLoginModal;

const Container = styled.div`
  padding-right: 8px;
  padding-left: 2px;
`;

const Wrapper = styled.div``;

const FlexWrapper = styled.div`
  display: flex;
`;

const Text = styled.div`
  ${({ topMargin }) => topMargin && ('margin-top: 3px;')};
  margin-bottom: 7px;

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: 39px;
  margin-bottom: ${({ isError }) => (isError ? '42px;' : '29px;')};

  & div {
    margin: 0;
  }

  & input {
    height: 39px;
    padding-top: 16px;
  }

  & img {
    margin-top: -10px;

    &:first-child {
      width: 16px;
    }

    &:last-child {
      width: 22px;
      margin-top: -7px;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  height: 39px;
`;

const StyledButton = styled(Button)`
  width: 100px;
  margin: 8px 0 18px auto;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
 `;
