import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { secondary, white } from '@/common/constants/theme';
import ModalNew from '@/common/components/ModalNew';
import request2Service from '@/services/request2.service';
import urls from '@/services/routes/devices.api';
import { loadAllDevicesRedux } from '@/store/user/selectors';
import { useDispatch } from 'react-redux';
import Arrow from '@/assets/icons/arrow-round.svg';

export default function DeviceAutoRenewDialog(props) {
  const {
    isVisible, toggleVisibility, dialogData,
  } = props;
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();

  const onClose = () => {
    toggleVisibility();
  };

  const handleSubmit = () => {
    // onSubmit(name, content);
    request2Service(urls.setAutoRenewDevice(dialogData.device.id), {
      method: 'POST',
      body: { auto_renew: !dialogData.device.subscription.auto_renew },
    })
      .then(() => {
        dispatch(loadAllDevicesRedux());
        toggleVisibility();
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  };

  return (
    <ModalNew
      isOpen={isVisible}
      icon={Arrow}
      title={dialogData.device?.subscription?.auto_renew ? t('devicesContent.autoRenewHeaderOn') : t('devicesContent.autoRenewHeaderOff')}
      onCloseModal={onClose}
      centered
    >
      {dialogData.device?.subscription?.auto_renew
        ? t('devicesContent.autoRenewContentOn', { deviceName: dialogData.device.name })
        : t('devicesContent.autoRenewContentOff', { deviceName: dialogData.device.name })}
      <Actions>
        <Button
          onClick={handleSubmit}
        >
          {dialogData.device.subscription.auto_renew ? t('devicesContent.buttonOff') : t('devicesContent.buttonOn')}
        </Button>
      </Actions>
    </ModalNew>
  );
}

const Button = styled.button`
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
`;
