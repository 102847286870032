import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Route, useHistory } from 'react-router-dom';

import { text01 } from '@/common/constants/theme';
import UsersMainTable from './components/MainTable';
import UsersMainUser from './components/MainUser/MainUser';

import { BASE_URL, useUsersMainContext } from './MainContext';

const UsersMain = () => {
  const history = useHistory();
  const { setSelectedUser, setUserDevices } = useUsersMainContext();

  useEffect(() => {
    setSelectedUser(null);
    setUserDevices([]);
  }, [history.location]);

  return (
    <Container>
      <Switch>
        <Route path={`${BASE_URL}/:id`} component={UsersMainUser} />
        <Route path={BASE_URL}>
          <Title>
            Пользователи
          </Title>
          <UsersMainTable />
        </Route>
        <Route
          path="/admin/support/users/:id"
          render={() => <UsersMainUser supportView />}
        />
        <Route path="/admin/support/users">
          <Title>
            Пользователи
          </Title>
          <UsersMainTable withoutBlock />
        </Route>
      </Switch>
    </Container>
  );
};

export default UsersMain;

const Container = styled.div``;

const Title = styled.div`
  margin-bottom: 18px;

  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: ${text01};
  line-height: 20px;
`;
