import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  secondary,
  text01,
  white,
} from '@/common/constants/theme';

import SearchIcon from '../../../PersonalPageScreen/components/icons/SearchIcon';
import CloseIcon from '@/common/components/icons/CloseIcon';

const FAQHeader = ({
  search,
  setSearch,
  topContent,
  rightContent,
  bottomContent,
  leftContent,
}) => {
  const { t } = useTranslation('translations');

  return (
    <Container>
      <Wrapper>
        <Wrapper flex flexCenter bottomMargin>
          <Title>
            {t('publicPages.FAQ.title')}
          </Title>
          <Title orange>
            {t('publicPages.FAQ.orangeTitle')}
          </Title>
        </Wrapper>
        {topContent}
        <ContentContainer>
          <PrependContent>{leftContent}</PrependContent>
          <CentralContent>
            <InputWrapper>
              <Icon>
                <SearchIcon />
              </Icon>
              <Input
                type="text"
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                placeholder={t('publicPages.FAQ.inputPlaceholder')}
              />
              {search && (
                <CloseControl
                  title={t('publicPages.FAQ.clearSearchTitle')}
                  onClick={() => setSearch('')}
                >
                  <CloseIcon width={16} height={16} />
                </CloseControl>
              )}
            </InputWrapper>
          </CentralContent>
          <AppendContent>{rightContent}</AppendContent>
        </ContentContainer>
        {bottomContent}
      </Wrapper>
    </Container>
  );
};

export default FAQHeader;

const Container = styled.div`
  display: flex;
  justify-content: center;

  max-width: 100%;
  margin-bottom: 60px;
`;

const Wrapper = styled.div`
  width: 100%;
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexCenter }) => flexCenter && ('justify-content: center;')}

  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 52px;')}
`;

const Title = styled.div`
  ${({ orange }) => orange && ('margin-left: 10px;')}

  font-family: Gilroy;
  font-weight: bold;
  font-size: 44px;
  line-height: 48px;
  color: ${({ orange }) => (orange ? secondary : text01)};
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PrependContent = styled.div`
  width: calc((100% - 600px) / 2);
`;

const CentralContent = styled.div`
  width: 600px;
`;

const CloseControl = styled.div`
  font-size: 0;
  cursor: pointer;
`;

const AppendContent = styled.div`
  width: calc((100% - 600px) / 2);
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 48px;
  padding-left: 12px;
  padding-right: 10px;

  background: ${white};
  border-radius: 2px;
  filter: drop-shadow(0px 1px 25px rgba(150, 150, 150, 0.24));
`;

const Input = styled.input`
  width: 100%;

  font-family: Gilroy, sans-serif;
  border: none;
  outline: none;
`;

const Icon = styled.div`
  margin-right: 24px;

  position: relative;
  top: 3px;
`;
