import React, { useState } from 'react';
import Input from '@/common/components/AuthorizationInput';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const EmailInput = ({
  setEmail,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation(['translations']);

  const [localValue, setLocalValue] = useState('');

  const isValid = (eml) => String(eml)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const handleEmailChange = ({ target }) => {
    const { value } = target;
    setLocalValue(value);
    if (!isValid(value)) {
      setErrors(t('authorization.emailContentError'));
    } else {
      setErrors('');
      setEmail(value);
    }
  };

  return (
    <InputWrapper>
      <Input
        isSuccess={!errors && (localValue.length > 0)}
        placeholder="your-new-email@server.com"
        type="email"
        label={t('authorization.email')}
        onKeyUp={handleEmailChange}
        onChange={handleEmailChange}
        errorText={errors}
      />
    </InputWrapper>
  );
};

export default EmailInput;

const InputWrapper = styled.div`
  width: 100%;
`;
