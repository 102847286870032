import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import {
  primary, secondary,
  secondaryHover, primaryHover,
} from '@/common/constants/theme';

// import pageSizes from '@/common/constants/pageSizes';
import Loader from '@/common/components/Loader';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import { useStatisticAggregatorsContext } from '../AggregatorsContext';
import TableBody from '@/common/components/Table/components/TableBody';
import AdminTablePagination from '../../../components/Table/components/TablePagination';
import Checkbox from '@/common/components/Checkbox';
import EditIcon from '@/common/components/icons/EditIcon';

const SIZES = ['100px', '650px', '150px', '250px'];

const StatisticAggregatorsTable = ({ editPath }) => {
  const {
    isBusy,
    paymentSystems,
    psCount,
    filters, setFilters,

    onOffPaySystem,
  } = useStatisticAggregatorsContext();

  const [localPaySystems, setLocalPaySystems] = useState([]);
  const [countHidden, setCountHidden] = useState(0);
  const [isDisabledHidden, setIsDisabledHidden] = useState(true);

  useEffect(() => {
    const psTmp = (paymentSystems || []);
    const usePS = isDisabledHidden
      ? psTmp.filter((ps) => !ps.is_disabled)
      : psTmp;
    setLocalPaySystems(usePS);
    setCountHidden(psTmp.length - usePS.length);
  }, [paymentSystems, isDisabledHidden]);

  return paymentSystems && (
    <Container>
      <Table
        cellSizes={SIZES}
        limit={filters.limit}
        page={filters.page}
        dataLength={paymentSystems.length}
        textAlign="left"
      >
        {isBusy && <Loader size="small" />}
        <TableHead>
          <HeadItem>
            Позиция
          </HeadItem>
          <HeadItem>
            Платежная система
          </HeadItem>
          <HeadItem>
            Бонус
            <br />
            за пополнение
          </HeadItem>
          <HeadItem>
            <Checkbox
              checked={isDisabledHidden}
              onChange={() => setIsDisabledHidden((prevState) => !prevState)}
              label="Скрыть отключенные"
            />
          </HeadItem>
        </TableHead>
        <TableBody
          rowHeight={42}
          minRows={Math.max(localPaySystems.length, 10)}
        >
          {localPaySystems.map((item, i) => (
            <div key={`${item.id}--PSTRow`}>
              <RowItem>
                {i + 1 + (((filters?.page || 1) - 1) * filters.limit)}
              </RowItem>
              <RowItem>
                {item.title}
              </RowItem>
              <RowItem>
                {`${item.bonus_percent}%`}
              </RowItem>
              <RowItem flex>
                <ActionText
                  secondaryColor={item.is_disabled}
                  onClick={() => onOffPaySystem(item.id, item.is_disabled)}
                >
                  {item.is_disabled ? 'Включить' : 'Отключить'}
                </ActionText>
                <StyledLink to={`${editPath}/${item.id}`}>
                  <EditIcon />
                </StyledLink>
              </RowItem>
            </div>
          ))}
        </TableBody>
        <AdminTablePagination
          // pageSizes={pageSizes}
          page={filters?.page || 1}
          setPage={(newPage) => { setFilters((prevState) => ({ ...prevState, page: newPage })); }}
          pageCount={Math.ceil(psCount / filters.limit)}
          recordsCount={psCount}
          customLeftBlock={countHidden > 0 ? `Скрыто: ${(paymentSystems || []).length - localPaySystems.length}` : ''}
        />
      </Table>
    </Container>
  );
};

export default StatisticAggregatorsTable;

const Container = styled.div``;

const HeadItem = styled.div``;

const RowItem = styled.div`
  ${({ flex }) => flex && 'display: flex;'}
`;

const ActionText = styled.div`
  margin-left: 4px;
  margin-right: 10px;

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${({ secondaryColor }) => (secondaryColor ? secondary : primary)};
  line-height: 20px;

  transition: all .25s ease-in-out 0s;

  &:hover {
    color: ${({ secondaryColor }) => (secondaryColor ? primaryHover : secondaryHover)};
  }
`;

const StyledLink = styled(Link)`
`;
