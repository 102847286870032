/* eslint-disable no-shadow, no-console */
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getCountries,
  getPaymentSystems,
  getUsers,
  getMinistraServers,
  getServers, getOsFormats, getUserGroups,
  getPasswordPinCheckCount,
  getPasswordPinCheckExpDate,
} from '@/store/admin/selectors';

import {
  setPasswordPinCheckCount,
  setPasswordPinCheckExpDate,
} from '@/store/admin/actions/common';

import {
  TASK_STATUSES,
  updateUserAction,
  getUserActiveBlockAction,
  addUserBlockAction,
  changeUserBlockAction,
  changeUserBlockDescriptionAction,
  removeUserBlockAction,
  getUserDevicesAction, getUserPlayListsAction, updateUserDeviceAction,
  getDeviceHistoryAction,
  resetDeviceThemeAction, regenOttIdAction,
  userGroupAttachByEmail, userGroupDetach, userGroupAttach,
  changeUserBalanceAction, changeUserDiscountAction,
  setChangeUserBalanceReplenishmentAction,
  setUserBalanceReplenishmentByBonusAction,
  setActiveUserPaymentSystemsAction,
  getUserActivityLogAction, updateUserDeviceResultAction,
  getUserReferralsLinkFollowsAction, getUserReferralsTransactionsAction,
  getUserPasswordAction, changeUserBonusAction,
} from '@/services/actions/admin/users-main.actions';

import {
  createSubscriptionAction,
  setSubscriptionAction,
  cancelSubscriptionAction,
} from '@/services/actions/admin/subscription.actions';

import { getPaymentsAction } from '@/services/actions/admin/finance.actions';

import { format } from 'date-fns';

import {
  DATE_FORMAT,
  useAdminContext,
} from '../../../../AdminContext';

export const BASE_URL = '/admin/users/main';

export const SUBSCRIPTION_ACTIONS = {
  add: 'add',
  edit: 'edit',
  cancel: 'cancel',
};

export const SUBSCRIPTION_CANCEL_TYPES = {
  refund: 'refund',
  cancel: 'cancel',
  all: 'all',
};

export const SUBSCRIPTION_CANCEL_METHODS = {
  withCurrent: 'withCurrent',
  onlyFuture: 'onlyFuture',
};

export const DATA_TYPES = {
  countries: 'countries',
  user: 'user',
  users: 'users',
  userGroups: 'userGroups',
  paymentSystems: 'paymentSystems',
  userBlock: 'userBlock',
  userInfo: 'userInfo',
  userDevices: 'userDevices',
  ottId: 'ottId',
  userPlayLists: 'userPlayLists',
  ministraSevers: 'ministraSevers',
  servers: 'servers',
  osFormats: 'osFormats',
  userStats: 'userStats',
};

export const REFERRALS_LINKS_TYPES = {
  transition: 'transition',
  registration: 'registration',
};

const NO_SELECTED_USER = 'Пользователь не выбран!';
export const PASSWORD_PIN_CHECK_COUNT_EXHAUSTED_TEXT = 'Исчерпаны попытки проверки PIN';

export const UsersMainContext = createContext({});

export const USERS_PAGE_SIZES = [20, 50, 100];

const initialFilters = {
  page: 1,
  limit: localStorage.getItem('admin_users_main_limit') || USERS_PAGE_SIZES[0],
  search: '',
  order: '',
};

export const UsersMainContextProvider = ({ children }) => {
  const [filters, setFilters] = useState({ ...initialFilters });
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDevices, setUserDevices] = useState([]);
  const [userPlayLists, setUserPlayLists] = useState([]);
  const [paymentSystems, setPaymentSystems] = useState([]);
  const [countries, setCountries] = useState([]);
  const [servers, setServers] = useState([]);
  const [ministraServers, setMinistraServers] = useState([]);
  const [osFormats, setOsFormats] = useState([]);

  const [isDataLoaded, setIsDataLoaded] = useState([]);
  const [dataProcessing, setDataProcessing] = useState({});

  const [passwordPinCheckCountMax, setPasswordPinCheckCountMax] = useState(99);

  const dispatch = useDispatch();

  const {
    loadCountries,
    loadUsers,
    loadUserGroups,
    loadPaymentSystems,
    loadServers,
    loadMinistraServers,
    loadOsFormats,
    sendEmail,
    setAdminSnack,
    SnackTypes,
  } = useAdminContext();

  const filtersHandler = (field, value) => {
    const currentFilter = { ...filters, [field]: value };
    if (field === 'limit') {
      localStorage.setItem('admin_users_main_limit', value);
    }
    setFilters(currentFilter);
  };

  const filtersBulkHandler = (updates) => {
    const currentFilter = { ...filters, ...updates };
    if (updates?.limit) {
      localStorage.setItem('admin_users_main_limit', updates.limit);
    }
    setFilters(currentFilter);
  };

  const storedCountries = useSelector(getCountries);
  const storedUsers = useSelector(getUsers);
  const storedUserGroups = useSelector(getUserGroups);
  const storedPaymentSystems = useSelector(getPaymentSystems);
  const storedMinistraServers = useSelector(getMinistraServers);
  const storedServers = useSelector(getServers);
  const storedOsFormats = useSelector(getOsFormats);
  const storedPasswordPinCheckCount = useSelector(getPasswordPinCheckCount);
  const storedPasswordPinCheckExpDate = useSelector(getPasswordPinCheckExpDate);

  const updateLoadedUsers = (userId, setLoader = false) => {
    if (users.map((u) => u.id).includes(userId)) {
      if (setLoader) {
        setDataProcessing(
          (prevState) => ({ ...prevState, [DATA_TYPES.users]: true }),
        );
      }
      return loadUsers(filters)
        .then((res) => {
          setUsersCount(res?.count || 0);
          setDataProcessing(
            (prevState) => ({ ...prevState, [DATA_TYPES.users]: false }),
          );
          return res;
        });
    }
    return Promise.resolve();
  };

  const getUserActiveBlock = (userId) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userBlock]: true }));
    return getUserActiveBlockAction(userId)
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userBlock]: false }));
      });
  };

  const getUserPassword = (pin) => {
    if (!selectedUser) {
      return Promise.reject(new Error(NO_SELECTED_USER));
    }
    return getUserPasswordAction(selectedUser.id, pin)
      .then((res) => {
        dispatch(setPasswordPinCheckCount(0));
        return res;
      })
      .catch((err) => {
        let passCheckCount = 1;
        if (err?.data?.message_type === 'raw_password_decode_pin_attempts_count_is_exhausted') {
          setPasswordPinCheckCountMax(parseInt(err.data.message[1], 10) || 99);
          passCheckCount = parseInt(err.data.message[0], 10) || 1;
        }
        dispatch(setPasswordPinCheckCount(passCheckCount));
        // setAdminSnack(err, SnackTypes.error);
        return err;
      });
  };
  const blockUser = (userId, reason, blockDates) => {
    const dayDuration = 1000 * 60 * 60 * 24;
    const now = (new Date()).getTime();
    const startDate = blockDates?.d_start || new Date();
    const endDate = blockDates?.d_end || new Date(Date.now() + dayDuration * 30);

    setDataProcessing((prevState) => (
      { ...prevState, [DATA_TYPES.userBlock]: true, [DATA_TYPES.users]: true }
    ));
    const blockData = {
      reason: ((reason?.length || 0) > 0) ? reason : 'admin',
      canceled: false,
      block_at: format(startDate.getTime() < now ? now : startDate, DATE_FORMAT),
      expires_at: format(endDate.getTime() < startDate.getTime()
        ? new Date(startDate.getTime() + dayDuration * 7)
        : endDate, DATE_FORMAT),
    };
    return addUserBlockAction(userId, blockData)
      .then((res) => {
        setAdminSnack({
          // content: `Блокировка добавлена (id ${res.id})`,
          content: `Пользователь Id[${userId}] заблокирован`,
          type: SnackTypes.success,
        });
        updateLoadedUsers(userId);
        return res;
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userBlock]: false }));
      });
  };
  const setBlockUserReason = (userId, blockId, reason) => {
    setDataProcessing((prevState) => (
      { ...prevState, [DATA_TYPES.userBlock]: true, [DATA_TYPES.users]: true }
    ));
    return changeUserBlockDescriptionAction(userId, blockId, reason)
      .then((res) => {
        setAdminSnack({
          content: `Причина блокировка (id ${res.id}) изменена`,
          type: SnackTypes.success,
        });
        updateLoadedUsers(userId);
        return res;
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userBlock]: false }));
      });
  };
  const changeBlockUser = (userId, blockId, blockData) => {
    setDataProcessing((prevState) => (
      { ...prevState, [DATA_TYPES.userBlock]: true, [DATA_TYPES.users]: true }
    ));
    return changeUserBlockAction(userId, blockId, blockData)
      .then((res) => {
        setAdminSnack({
          content: `Причина блокировка (id ${res.id}) изменена`,
          type: SnackTypes.success,
        });
        updateLoadedUsers(userId);
        return res;
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userBlock]: false }));
      });
  };
  const unBlockUser = (userId, blockId, reason) => {
    setDataProcessing((prevState) => (
      { ...prevState, [DATA_TYPES.userBlock]: true, [DATA_TYPES.users]: true }
    ));
    return removeUserBlockAction(userId, blockId, reason)
      .then((res) => {
        setAdminSnack({
          content: `Блокировка (id ${res.id}) снята`,
          type: SnackTypes.success,
        });
        updateLoadedUsers(userId);
        return res;
      })
      .catch((err) => { setAdminSnack(err, SnackTypes.error); return err; })
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userBlock]: false }));
      });
  };

  const updateUser = (userId, data) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: true }));
    return updateUserAction(userId, data)
      .then((res) => {
        setAdminSnack({
          content: 'Данные изменены',
          type: SnackTypes.success,
        });
        updateLoadedUsers(userId);
        return res;
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }));
      })
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }));
      });
  };

  const getUserDevices = (userId) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: true }));
    return getUserDevicesAction(userId)
      .then((res) => setUserDevices(res?.results || []))
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
      });
  };
  const getUserDeviceHistory = (deviceId, filterString) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: true }));
    return getDeviceHistoryAction(selectedUser.id, deviceId, filterString)
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
      });
  };
  const getUserPlayLists = (userId) => {
    if (!userId && !selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userPlayLists]: true }));
    return getUserPlayListsAction(userId || selectedUser.id)
      .then((res) => setUserPlayLists(res || []))
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userPlayLists]: false }));
      });
  };

  const updateUserBalance = (userId, newBalance, reason = null) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: true }));
    return changeUserBalanceAction(
      userId, newBalance, reason,
    )
      .then(() => {
        setAdminSnack({
          content: 'Баланс пользователя установлен',
          type: SnackTypes.success,
        });
        updateLoadedUsers(userId);
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }));
      });
  };

  const updateUserDiscount = (userId, value) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: true }));
    return changeUserDiscountAction(
      userId, value,
    )
      .then(() => {
        setAdminSnack({
          content: 'Скидка пользователя установлена',
          type: SnackTypes.success,
        });
        updateLoadedUsers(userId);
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }));
      });
  };

  const updateUserBonus = (userId, value) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: true }));
    return changeUserBonusAction(
      userId, value,
    )
      .then(() => {
        setAdminSnack({
          content: 'Бонус пользователя установлен',
          type: SnackTypes.success,
        });
        updateLoadedUsers(userId)
          .then(() => setDataProcessing(
            (prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }),
          ));
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }));
      });
  };

  const updateDevice = (device) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: true }));
    const { id: deviceId, ...deviceData } = device;
    return updateUserDeviceAction(selectedUser.id, deviceId, deviceData)
      .then(({ status, task_id: taskId }) => {
        if (taskId && (status === TASK_STATUSES.processed)) {
          const requestsInterval = 1000 * 2;
          const checkTaskInterval = setInterval(() => updateUserDeviceResultAction(
            selectedUser.id, deviceId, taskId,
          )
            .then((tRes) => {
              if (tRes?.status === TASK_STATUSES.success) {
                clearInterval(checkTaskInterval);
                setTimeout(() => {
                  setAdminSnack({ type: SnackTypes.success, content: 'Устройство обновлено успешно' });
                  setDataProcessing((prevState) => (
                    { ...prevState, [DATA_TYPES.userDevices]: false }));
                  getUserDevices(selectedUser.id);
                }, requestsInterval * 2);
              } else if (tRes?.status !== TASK_STATUSES.processed) {
                setDataProcessing((prevState) => (
                  { ...prevState, [DATA_TYPES.userDevices]: false }));
                setAdminSnack({ type: SnackTypes.error, content: `Не удалось обновить устройство: ${JSON.stringify(tRes)}` }, SnackTypes.error);
                clearInterval(checkTaskInterval);
              }
            }), requestsInterval);
        } else if (status === TASK_STATUSES.success) {
          setDataProcessing((prevState) => (
            { ...prevState, [DATA_TYPES.userDevices]: false }));
          setAdminSnack({ type: SnackTypes.success, content: 'Устройство обновлено успешно' });
          getUserDevices(selectedUser.id);
        } else {
          setDataProcessing((prevState) => (
            { ...prevState, [DATA_TYPES.userDevices]: false }));
          setAdminSnack({ type: SnackTypes.error, content: 'Не удалось обновить устройство: не удалось создать задачу на обновление!' }, SnackTypes.error);
        }
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
      });
  };

  const setNewUserGroups = (removedGroups, addedGroups, userGroupsBusy) => {
    if (userGroupsBusy.length === 0) {
      loadUsers(filters)
        .then(
          () => setDataProcessing(
            (prevState) => ({ ...prevState, [DATA_TYPES.userBlock]: false }),
          ),
        );
    }
  };
  const updateUserGroups = (userId, newGroupsIds, oldGroupsIds, userEmail = '') => {
    let userGroupsBusy = [...newGroupsIds, ...oldGroupsIds];
    const removedGroups = [];
    const addedGroups = [];

    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userBlock]: true }));
    [...userGroupsBusy].forEach((ug) => {
      if (oldGroupsIds.includes(ug) && !newGroupsIds.includes(ug)) {
        userGroupDetach(userId, ug)
          .then(() => removedGroups.push(ug))
          .catch((err) => setAdminSnack(err, SnackTypes.error))
          .finally(() => {
            userGroupsBusy = userGroupsBusy.filter((ugb) => ugb !== ug);
            setNewUserGroups(removedGroups, addedGroups, userGroupsBusy);
          });
      } else if (newGroupsIds.includes(ug) && !oldGroupsIds.includes(ug)) {
        const attachFunc = userEmail.length > 0
          ? userGroupAttachByEmail(userEmail, ug)
          : userGroupAttach(userId, ug);
        attachFunc
          .then(() => addedGroups.push(ug))
          .catch((err) => setAdminSnack(err, SnackTypes.error))
          .finally(() => {
            userGroupsBusy = userGroupsBusy.filter((ugb) => ugb !== ug);
            setNewUserGroups(removedGroups, addedGroups, userGroupsBusy);
          });
      } else {
        userGroupsBusy = userGroupsBusy.filter((ugb) => ugb !== ug);
        setNewUserGroups(removedGroups, addedGroups, userGroupsBusy);
      }
    });
  };

  const setUserReplenishmentStatus = (status) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: true }));
    return setChangeUserBalanceReplenishmentAction(selectedUser.id, status)
      .then(() => {
        updateLoadedUsers(selectedUser.id)
          .then(() => setDataProcessing(
            (prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }),
          ));
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }));
      });
  };
  const setUserReplenishmentByBonusStatus = (status) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: true }));
    return setUserBalanceReplenishmentByBonusAction(selectedUser.id, status)
      .then(() => {
        updateLoadedUsers(selectedUser.id)
          .then(() => setDataProcessing(
            (prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }),
          ));
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }));
      });
  };

  const setActiveUserPaymentSystems = (psIds) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: true }));
    return setActiveUserPaymentSystemsAction(selectedUser.id, psIds)
      .then(() => {
        updateLoadedUsers(selectedUser.id)
          .then(() => setDataProcessing(
            (prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }),
          ));
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }));
      });
  };

  const resetDeviceTheme = (deviceId) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: true }));

    return resetDeviceThemeAction(selectedUser.id, deviceId)
      .then((res) => {
        const { status, task_id: taskId } = res;
        if (taskId && (status === TASK_STATUSES.processed)) {
          const checkInterval = 1000 * 1;
          const resetCheckInterval = setInterval(
            () => resetDeviceThemeAction(selectedUser.id, deviceId, taskId)
              .then((tRes) => {
                if (tRes?.status === TASK_STATUSES.success) {
                  clearInterval(resetCheckInterval);
                  setDataProcessing((prevState) => (
                    { ...prevState, [DATA_TYPES.userDevices]: false }));
                  setAdminSnack(
                    { type: SnackTypes.success, content: 'Тема устройства сброшена успешно' },
                  );
                } else if (tRes?.status !== TASK_STATUSES.processed) {
                  clearInterval(resetCheckInterval);
                  setDataProcessing((prevState) => (
                    { ...prevState, [DATA_TYPES.userDevices]: false }));
                  setAdminSnack(
                    { type: SnackTypes.error, content: `Тема устройства не сброшена: ${JSON.stringify(tRes)}` }, SnackTypes.error,
                  );
                }
              })
              .catch((tErr) => {
                clearInterval(resetCheckInterval);
                setDataProcessing((prevState) => (
                  { ...prevState, [DATA_TYPES.userDevices]: false }));
                setAdminSnack(tErr, SnackTypes.error);
              }), checkInterval,
          );
        } else if (status === TASK_STATUSES.success) {
          setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
          setAdminSnack(
            { type: SnackTypes.success, content: 'Тема устройства сброшена успешно' },
          );
        } else {
          setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
          setAdminSnack(
            { type: SnackTypes.error, content: `Тема устройства не сброшена: ${JSON.stringify(res)}` }, SnackTypes.error,
          );
        }
      })
      .catch((err) => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
        setAdminSnack(err, SnackTypes.error);
      });
  };
  const regenOttId = async (deviceId) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: true }));
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.ottID]: true }));

    return regenOttIdAction(selectedUser.id, deviceId)
      .then((res) => {
        setAdminSnack(
          { type: SnackTypes.success, content: 'OttId для устройства обновлен' },
          SnackTypes.success,
        );
        getUserDevices(selectedUser.id);
        return res;
      })
      .catch((err) => {
        if (err?.response?.payload?.then) {
          err.response.payload.then(
            (errRes) => {
              setAdminSnack(
                {
                  type: SnackTypes.error,
                  content: errRes?.message || 'Ошибка обновления ott ID, попробуйте позже',
                },
                SnackTypes.error,
              );
              return errRes;
            },
          );
        }
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
      })
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.ottId]: false }));
      });
  };

  const createSubscription = ({
    deviceId,
    toDate = null,
    usePremium = false,
    payFromUserBalance = false,
  }) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: true }));
    const setDateTo = toDate && (typeof toDate.getMonth === 'function')
      ? toDate
      : new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
    return createSubscriptionAction(
      selectedUser.id, deviceId, {
        date_end: format(setDateTo, DATE_FORMAT),
        is_premium: usePremium,
        is_withdraw_from_user_balance: payFromUserBalance,
      },
    )
      .then(() => {
        setAdminSnack({ type: SnackTypes.success, content: 'Подписка успешно создана' });
        getUserDevices(selectedUser.id);
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
      });
  };
  const setSubscription = (deviceId, toDate = null) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: true }));
    const setDateTo = toDate && (typeof toDate.getMonth === 'function')
      ? toDate
      : new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
    return setSubscriptionAction(selectedUser.id, deviceId, format(setDateTo, DATE_FORMAT))
      .then(() => {
        setAdminSnack({ type: SnackTypes.success, content: 'Подписка успешно изменена' });
        getUserDevices(selectedUser.id);
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
      });
  };
  const cancelSubscription = (deviceId, type = null, method = null) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: true }));

    const cancelMethodsValues = {
      [SUBSCRIPTION_CANCEL_METHODS.withCurrent]: 'active_and_future',
      [SUBSCRIPTION_CANCEL_METHODS.onlyFuture]: 'only_future',
    };

    return cancelSubscriptionAction(
      selectedUser.id, deviceId,
      type || SUBSCRIPTION_CANCEL_TYPES.cancel,
      cancelMethodsValues[method || SUBSCRIPTION_CANCEL_METHODS.onlyFuture],
    )
      .then(() => {
        setAdminSnack({ type: SnackTypes.success, content: 'Подписки успешно отменены' });
        getUserDevices(selectedUser.id);
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
      });
  };

  const sendEmailToUser = (data) => {
    if (!selectedUser) return null;
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: true }));
    return sendEmail(selectedUser.id, data)
      .then(() => {
        setAdminSnack({ type: SnackTypes.success, content: 'Письмо успешно отослано' }, SnackTypes.success);
      })
      .catch((err) => setAdminSnack(
        { content: err?.response?.statusText, type: SnackTypes.error }, SnackTypes.error,
      ))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userInfo]: false }));
      });
  };

  const getUserActivityLog = (filterString) => {
    if (!selectedUser?.id) {
      setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      return null;
    }
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: true }));
    return getUserActivityLogAction(selectedUser.id, filterString)
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      });
  };

  const getUserReplenishments = (filterString) => {
    if (!selectedUser?.id) {
      setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      return null;
    }
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: true }));
    const userFilterString = `${(filterString.length > 0 ? `${filterString}&` : '')}transaction_type=replenishment&user_id=${selectedUser.id}`;
    return getPaymentsAction(userFilterString)
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      });
  };

  const getUserTransactions = (filterString) => {
    if (!selectedUser?.id) {
      setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      return null;
    }
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: true }));
    const userFilterString = `${(filterString.length > 0 ? `${filterString}&` : '')}user_id=${selectedUser.id}`;
    return getPaymentsAction(userFilterString)
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      });
  };

  const getUserReferralsLinksFollows = (filterString) => {
    if (!selectedUser?.id) {
      setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      return null;
    }
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: true }));
    return getUserReferralsLinkFollowsAction(selectedUser.id, filterString)
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      });
  };

  const getUserReferralsTransactions = (filterString) => {
    if (!selectedUser?.id) {
      setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      return null;
    }
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: true }));
    return getUserReferralsTransactionsAction(selectedUser.id, filterString)
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.userStats]: false }));
      });
  };

  const setPasswordPinCheckExpireDate = (date = null) => {
    const dateString = (date || new Date()).toISOString();
    dispatch(setPasswordPinCheckExpDate(dateString));
  };

  useEffect(() => {
    if (!storedCountries && !isDataLoaded?.[DATA_TYPES.countries]) {
      loadCountries();
      setIsDataLoaded((prevState) => ({ ...prevState, [DATA_TYPES.countries]: true }));
    } else {
      setCountries(storedCountries);
    }
  }, [storedCountries]);

  useEffect(() => {
    if (storedUsers) {
      setUsers(storedUsers);
    }
  }, [storedUsers]);

  useEffect(() => {
    loadUsers(filters)
      .then((res) => {
        setUsersCount(res?.count || 0);
      })
      .finally(() => {
        setDataProcessing(
          (prevState) => ({ ...prevState, [DATA_TYPES.users]: false }),
        );
      });
  }, [filters]);

  useEffect(() => {
    if (!storedUserGroups && !isDataLoaded?.[DATA_TYPES.userGroups]) {
      loadUserGroups();
      setIsDataLoaded((prevState) => ({ ...prevState, [DATA_TYPES.userGroups]: true }));
    } else {
      setUserGroups(storedUserGroups);
    }
  }, [storedUserGroups]);

  useEffect(() => {
    if (!storedPaymentSystems && !isDataLoaded?.[DATA_TYPES.paymentSystems]) {
      loadPaymentSystems();
      setIsDataLoaded((prevState) => ({ ...prevState, [DATA_TYPES.paymentSystems]: true }));
    } else {
      setPaymentSystems(storedPaymentSystems);
    }
  }, [storedPaymentSystems]);

  useEffect(() => {
    if (!storedServers && !isDataLoaded?.[DATA_TYPES.servers]) {
      loadServers();
      setIsDataLoaded((prevState) => ({ ...prevState, [DATA_TYPES.servers]: true }));
    } else {
      setServers(storedServers);
    }
  }, [storedServers]);

  useEffect(() => {
    if (!storedOsFormats && !isDataLoaded?.[DATA_TYPES.osFormats]) {
      loadOsFormats();
      setIsDataLoaded((prevState) => ({ ...prevState, [DATA_TYPES.osFormats]: true }));
    } else {
      setOsFormats(storedOsFormats);
    }
  }, [storedOsFormats]);

  useEffect(() => {
    if (!storedMinistraServers && !isDataLoaded?.[DATA_TYPES.ministraSevers]) {
      loadMinistraServers();
      setIsDataLoaded((prevState) => ({ ...prevState, [DATA_TYPES.ministraSevers]: true }));
    } else {
      setMinistraServers(storedMinistraServers);
    }
  }, [storedMinistraServers]);

  const value = {
    countries,
    users,
    usersCount,
    userGroups,
    userDevices,
    setUserDevices,
    userPlayLists,
    filters,
    filtersHandler,
    filtersBulkHandler,
    paymentSystems,
    servers,
    ministraServers,
    osFormats,

    dataProcessing,
    setDataProcessing,
    selectedUser,
    setSelectedUser,

    getUserPassword,
    getUserActiveBlock,
    blockUser,
    setBlockUserReason,
    changeBlockUser,
    unBlockUser,
    updateUser,
    updateUserGroups,
    updateDevice,
    updateUserBalance,
    updateUserDiscount,
    updateUserBonus,

    resetDeviceTheme,
    regenOttId,

    setUserReplenishmentStatus,
    setUserReplenishmentByBonusStatus,
    setActiveUserPaymentSystems,

    createSubscription,
    setSubscription,
    cancelSubscription,

    sendEmailToUser,

    getUserDevices,
    getUserPlayLists,
    getUserDeviceHistory,

    getUserActivityLog,
    getUserReplenishments,
    getUserTransactions,
    getUserReferralsLinksFollows,
    getUserReferralsTransactions,

    passwordPINCheckCountMax: passwordPinCheckCountMax,
    storedPasswordPinCheckCount,
    storedPasswordPinCheckExpDate,
    setPasswordPinCheckExpireDate,
  };

  return (
    <UsersMainContext.Provider value={value}>
      {children}
    </UsersMainContext.Provider>
  );
};

export const useUsersMainContext = () => useContext(UsersMainContext);
