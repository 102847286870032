/**
 * Генерация строки из случайных символов
 * @param length Макс. длина слова
 * @param wordsCount Число слов в строке
 * @param useDigits Использовать ли цифры
 * @param useCyrillic Использовать ли кириллицу
 * @returns {string}
 */
export const generateRandomString = (
  wordMaxLength = 20,
  wordsCount = 1,
  useDigits = true,
  useCyrillic = true,
) => {
  const minWordLength = 1;
  const chars = useCyrillic ? 'ЙЦУКЕНГШЩЗХФЫВАПРОЛДЖЭЯЧСМИТБЮйцукенгшщзхфывапролджэячсмитбю' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const characters = `${chars}${useDigits && '0123456789'}`;
  const charactersLength = characters.length;
  const length = Math.floor(Math.random() * (wordMaxLength - minWordLength) + minWordLength);

  const result = [];
  for (let wc = 0; wc < wordsCount; wc += 1) {
    let word = '';
    for (let i = 0; i < length; i += 1) {
      word += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    result.push(word);
  }
  return result.join(' ');
};
