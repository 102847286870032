import React from 'react';
import styled from 'styled-components';

import { greyWhite, white, grey } from '@/common/constants/theme';
import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';

const AdminModal = ({
  isDisabled,
  isOpen,
  onClose,
  color = 'success',
  children,
  onSubmit,
  topTitle,
  title,
  buttonDescr,
}) => (
  <ModalWithoutContent isOpen={isOpen} onCloseModal={onClose} color={color}>
    <Container>
      {isDisabled && <Loader />}
      <Text>
        {topTitle}
      </Text>
      <Title>
        {title}
      </Title>
      <Text>
        {children}
      </Text>
      <Wrapper>
        <Text>
          {buttonDescr}
        </Text>
        <CancelButton
          disabled={isDisabled}
          onClick={onClose}
        >
          Отмена
        </CancelButton>
        <StyledButton
          isDisabled={isDisabled}
          onClick={() => {
            onSubmit();
            onClose();
          }}
        >
          Да
        </StyledButton>
      </Wrapper>
    </Container>
  </ModalWithoutContent>
);

export default AdminModal;

const Container = styled.div`
  width: 272px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 26px;
`;

const CancelButton = styled.button`
  margin-right: 24px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ disabled }) => (disabled ? grey : greyWhite)};
  text-transform: uppercase;
  text-decoration: underline;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  width: 54px;
`;

const Text = styled.div`
  margin-bottom: 6px;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${white};
  line-height: 15px;
  text-align: left;
`;

const Title = styled(Text)`
  margin-bottom: 10px;

  font-size: 20px;
  color: ${white};
  line-height: 20px;
`;
