/* eslint-disable camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import {
  secondary,
  white, red,
  text01, text04,
} from '@/common/constants/theme';
import Dropdown from '@/common/components/Dropdown';
import {
  usePersonalPageContext,
} from '../../../../../PersonalPageContext';
import {
  subscribeTypes,
} from '@/store/user/selectors';
import CloseIcon from '../../../../icons/CloseIcon';

const TIMEOUT_PAUSE = 1000 * 0.5;

const DeviceTableFilters = ({
  cellSizes,
  setFilters,
  filters,
}) => {
  const { t } = useTranslation(['translations']);
  const { servers } = usePersonalPageContext();
  const { search, subscription_type, server_id } = filters;

  const [searchInputText, setSearchInputText] = useState(search);
  const [searchInputTimeout, setSearchInputTimeout] = useState(null);

  const changeFilters = (field, value) => {
    setFilters({ ...filters, [field]: value, page: 1 });
  };

  const subscribeOptions = Object.entries(subscribeTypes).map(([, key]) => ({
    value: key, title: t(`table.subscribeTypes.${key}`), key,
  }));

  const serversToDropdownList = (items) => {
    const options = items.map(({ name, id }) => ({ key: id, value: id, title: name }));
    return [subscribeOptions.find((i) => i.key === subscribeTypes.All)].concat(options);
  };

  const handleSearchInput = (searchText) => {
    setSearchInputText(searchText);
    if (searchInputTimeout) {
      clearTimeout(searchInputTimeout);
    }
    setSearchInputTimeout(setTimeout(() => {
      changeFilters('search', searchText);
    }, TIMEOUT_PAUSE));
  };

  const clearSearchFilter = () => {
    setSearchInputText('');
    changeFilters('search', '');
  };

  return (
    <TableFilter>
      <HeaderCell width={cellSizes[0] + cellSizes[1]}>
        <SearchContainer>
          <StyledInput
            placeholder={t('table.deviceInputPlaceholder')}
            value={searchInputText}
            onChange={({ target }) => handleSearchInput(target.value)}
          />
          {(search || '').length > 0 && (
            <Close onClick={() => clearSearchFilter()}>
              <CloseIcon />
            </Close>
          )}
        </SearchContainer>
      </HeaderCell>
      <HeaderCell width={cellSizes[2]}>
        <Dropdown
          placeholder={t('table.subscribeInputPlaceholder')}
          options={subscribeOptions}
          value={subscribeOptions.find((item) => item.key === subscription_type)}
          onSelect={(item) => changeFilters('subscription_type', item.key)}
        />
      </HeaderCell>
      <HeaderCell width={cellSizes[3]} withoutBorder>
        <Dropdown
          placeholder={t('table.serverInputPlaceholder')}
          options={serversToDropdownList(servers)}
          value={serversToDropdownList(servers).find((item) => item.key === server_id)}
          onSelect={(item) => changeFilters('server_id', item.key || '')}
          showSearch={false}
        />
      </HeaderCell>
      <HeaderCell width={cellSizes[4]} withoutBorder />
    </TableFilter>
  );
};

export default DeviceTableFilters;

const TableFilter = styled.div`
  display: flex;
  padding: 0 1rem;
  background: ${white};
`;

const HeaderCell = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: ${text01};
  padding: 0 .652rem;
  margin: .75rem 0 1rem;
  border-right: 1px solid #E0DEE8;
  display: flex;
  align-items: center;
  ${({ withoutBorder }) => withoutBorder && `
    border: none;
  `};
  width: ${({ width }) => width}rem;
  justify-content: ${({ justify }) => justify};
  @media (max-width: 1360px) and (min-width: 1199px) {
    width: ${({ width }) => width - 2}rem;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled(Input)`
  height: 1.75rem;
  font-size: 13px;
  &.ant-input:focus {
    border-color: ${secondary};
    box-shadow: none;
  }
  &.ant-input:hover {
    border-color: ${secondary};
    box-shadow: none;
  }
`;

const Close = styled.div`
  cursor: pointer;
  display: flex;
  position: absolute;
  right: .5rem;
  top: .5rem;
  align-items: center;
  path {
    fill: ${red};
  }

  ${({ disabled }) => disabled && `
    path {
      fill: ${text04};
    }
  `}
`;
