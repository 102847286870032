import RequestService from '../request.service';

import urls from '../routes/player.api';

const EPG_SVC_PARAMS = {
  method: 'GET',
};

export const getEPGAction = (id) => (
  RequestService(urls.epg(id), EPG_SVC_PARAMS, true, true)
);

export const getEPGCurrent = () => RequestService(urls.epgCurrent, EPG_SVC_PARAMS, true, true);
