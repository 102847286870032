import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  text01,
  text02,
} from '@/common/constants/theme';
import Button from '../../../../../common/components/Button';
import PeriodDatePicker from '../components/PeriodDatePicker';
import Dropdown from '@/common/components/Dropdown';

const LogsTableFilters = ({
  startDateValue,
  endDateValue,
  applyHandler,
  dropdownOptions,
  dropdownValue,
}) => {
  const { t } = useTranslation('translations');

  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedDropdownValue, setSelectedDropdownValue] = useState();
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const applyFilter = () => {
    applyHandler({
      startFilterDate: selectedStartDate,
      endFilterDate: selectedEndDate,
      groupIdFilter: selectedDropdownValue.key,
    });
  };

  useEffect(() => {
    setSelectedStartDate(startDateValue);
  }, [startDateValue]);

  useEffect(() => {
    setSelectedEndDate(endDateValue);
  }, [endDateValue]);

  useEffect(() => {
    setSelectedDropdownValue(dropdownValue);
  }, [dropdownValue]);

  useEffect(() => {
    setIsFilterChanged((selectedStartDate !== startDateValue)
      || (selectedEndDate !== endDateValue)
      || (dropdownValue.key !== selectedDropdownValue.key));
  }, [
    startDateValue, endDateValue, dropdownValue,
    selectedStartDate, selectedEndDate, selectedDropdownValue,
  ]);

  return (
    <Main>
      <Body>
        <Wrapper>
          <Text rightMargin opacity>
            {t('table.period')}
          </Text>
        </Wrapper>
        <Wrapper>
          <PeriodDatePicker
            onDatesChanged={(v) => {
              setSelectedStartDate(v.start);
              setSelectedEndDate(v.end);
            }}
            startDateValue={startDateValue}
            endDateValue={endDateValue}
            editableDates
          />
        </Wrapper>
        <StyledDropDown
          options={dropdownOptions}
          value={dropdownValue}
          onSelect={setSelectedDropdownValue}
          isBoldText
        />
      </Body>
      <StyledButton
        isUpperCase
        isBoldText
        onClick={applyFilter}
        disabled={!isFilterChanged}
      >
        {t('table.apply')}
      </StyledButton>
    </Main>
  );
};

export default LogsTableFilters;

const Main = styled.div``;

const Text = styled.div`
  ${({ rightMargin }) => rightMargin && ('margin-right: 20px;')};
  ${({ rightMarginLittle }) => rightMarginLittle && ('margin-right: 11px;')};
  padding-bottom: 10px;

  ${({ topMargin }) => topMargin && (`
    position: relative;
    top: 5px;
  `)}

  font-family: Gilroy;
  font-size: 14px;
  color: ${({ opacity }) => (opacity ? text02 : text01)};
  line-height: 20px;
  letter-spacing: 0.16px;

  ${({ input }) => input && (`
    font-size: 13px;
    line-height: 15px;
    color: ${text01};
  `)}
`;

const Body = styled.div`
  margin-top: 20px;
  margin-bottom: 25px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDropDown = styled(Dropdown)`
  width: 287px;
  height: 32px;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  width: 116px;
  padding: 0;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
