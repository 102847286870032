import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ModalNew from '@/common/components/ModalNew';
import {
  text01,
} from '@/common/constants/theme';
import Button from '@/common/components/Button';
import EnvelopeIcon from '@/assets/icons/envelope.svg';

const ModalInfo = ({
  title,
  text,
  children,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation(['translations']);

  const submitHandler = () => onSubmit();

  const closeHandler = () => onClose();

  return (
    <ModalNew
      isOpen={isOpen}
      onCloseModal={closeHandler}
      title={title ?? ''}
      icon={EnvelopeIcon}
      isHidden={false}
    >
      {children
        && (
        <Wrapper>
          <Text bottomMargin>
            {children}
          </Text>
        </Wrapper>
        )}
      {text && text.length > 0
        && (
        <Wrapper>
          <Text bottomMargin>
            {text}
          </Text>
        </Wrapper>
        )}
      <FlexWrapper>
        <StyledButton
          isBoldText
          isUpperCase
          onClick={submitHandler}
        >
          {t('common.button.ok')}
        </StyledButton>
      </FlexWrapper>
    </ModalNew>
  );
};

export default ModalInfo;

const Wrapper = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 29px;')};
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 7px;')};

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-weight: normal;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const StyledButton = styled(Button)`
  width: auto;
  margin-left: auto;
  margin-top: 36px;
  margin-bottom: 18px;
  padding: 0 2rem;

  ${({ isDisabled }) => isDisabled && (`
    background-color: ${text01};
    opacity: 0.2;

    &:hover {
      background-color: ${text01};
      opacity: 0.2;
    }

    &:active {
      background-color: ${text01};
      opacity: 0.2;
    }
  `)}
`;
