import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ModalNew from '@/common/components/ModalNew';
import {
  text01,
  white,
  inputBorder,
  red,
} from '@/common/constants/theme';
import Button from '@/common/components/Button';
import CreateSrc from '@/assets/icons/plus.svg';

import { useChannelsContext } from '../../../../ChannelsContext';

const ChannelsMainCreateModal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  descr,
  button,
  icon,
  error,
}) => {
  const { t } = useTranslation('translations');

  const { playLists } = useChannelsContext();

  const [value, setValue] = useState('');
  const [isError, setIsError] = useState(false);
  const [valueError, setValueError] = useState('');

  const checkValue = (v) => {
    let err = '';
    // check value length
    if ((v || '').length <= 0) {
      err = t('steps.step4.content.body.modal.errors.minLength');
    }
    // check value is unique
    if (err.length === 0) {
      const valueLC = v.toLowerCase().trim();
      if ((playLists || [])
        .filter((pl) => (pl.title.toLowerCase().trim() === valueLC)).length > 0) {
        err = t('steps.step4.content.body.modal.errors.notUniqueName');
      }
    }
    setIsError(err.length > 0);
    setValueError(err);
  };

  useEffect(() => {
    checkValue(value);
  }, [value, playLists]);

  return (
    <Container>
      <ModalNew
        isOpen={isOpen}
        onCloseModal={onClose}
        title={title || t('steps.step4.content.body.modal.createTitle')}
        icon={icon || CreateSrc}
      >
        <Wrapper>
          <Text>
            {descr || t('steps.step4.content.body.modal.descr')}
          </Text>
          <Input
            value={value}
            onChange={({ target }) => setValue(target.value)}
            placeholder={t('steps.step4.content.body.modal.placeholder')}
            onBlur={() => checkValue(value)}
          />
          {((value || '').length > 0)
            && isError
            && (
              <ErrorText>
                {error || valueError}
              </ErrorText>
            )}
          <StyledButton
            isBoldText
            isUpperCase
            isDisabled={isError}
            onClick={() => {
              onSubmit(value);
              setValue('');
              onClose();
            }}
          >
            {button || t('steps.step4.content.body.modal.create')}
          </StyledButton>
        </Wrapper>
      </ModalNew>
    </Container>
  );
};

export default ChannelsMainCreateModal;

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 3px 8px 18px 2px;
`;

const Text = styled.div`
  margin-bottom: 8px;

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const ErrorText = styled(Text)`
  margin-top: 8px;

  font-size: 13px;
  line-height: 20px;
  color: ${red};
`;

const Input = styled.input`
  width: 100%;
  height: 39px;
  padding: 0 13px;

  background: ${white};
  border: 1px solid ${inputBorder};
  box-sizing: border-box;
  border-radius: 2px;
`;

const StyledButton = styled(Button)`
  display: block;

  width: 116px;
  margin-left: auto;
  margin-top: 36px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
