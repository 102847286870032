/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import SnackBarWindow from '@/common/components/SnackBarWindow';
import ChannelsMainCreateModal from '../../MainModals/ChannelsMainCreateModal';
import ChannelsMainDeleteModal from '../../MainModals/ChannelsMainDeleteModal';
import ChannelsMainEditModal from '../../MainModals/ChannelsMainEditModal';

import { useChannelsContext } from '../../../../../ChannelsContext';

const ChannelsMainHeaderModals = ({
  currentModal,
  setCurrentModal,
}) => {
  const { t } = useTranslation('translations');
  const {
    handleCreatePlaylist,
    handleDeletePlaylist,
    handleEditPlaylist,
    selectedPlaylist,
    playLists,
  } = useChannelsContext();

  const [isDeleteFinal, setIsDeleteFinal] = useState(false);
  const [isEditFinal, setIsEditFinal] = useState(false);
  const [isCreateFinal, setIsCreateFinal] = useState(false);
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    setPlaylists(playLists || []);
  }, [playLists]);
  return (
    <Container>
      <ChannelsMainCreateModal
        isOpen={(currentModal === 'save' || currentModal === 'create') && currentModal !== 'delete'}
        onClose={() => setCurrentModal('')}
        isSave={currentModal === 'save'}
        onSubmit={(title) => {
          handleCreatePlaylist(title);
          setIsCreateFinal(true);
        }}
        title={currentModal === 'save'
          ? t('steps.step4.content.body.modal.saveTitle')
          : t('steps.step4.content.body.modal.createTitle')}
        descr={t('steps.step4.content.body.modal.descr')}
        button={currentModal === 'save'
          ? t('steps.step4.content.body.modal.save')
          : t('steps.step4.content.body.modal.create')}
      />
      <ChannelsMainDeleteModal
        isOpen={currentModal === 'delete'}
        onClose={() => setCurrentModal('')}
        value={(playlists && playlists.find((item) => item.key === selectedPlaylist?.id || item.id === selectedPlaylist?.id)?.title) || ''}
        onSubmit={() => {
          // if (!playlists) return;
          // const found = playlists.find((item) => item.key === selectedPlaylist);
          handleDeletePlaylist(selectedPlaylist.id);
          setIsDeleteFinal(true);
        }}
      />
      <ChannelsMainEditModal
        isOpen={currentModal === 'edit'}
        onClose={() => setCurrentModal('')}
        value={(playlists && playlists.find((item) => item.key === selectedPlaylist?.id || item.id === selectedPlaylist?.id)?.title) || ''}
        onSubmit={(title) => {
          const list = playlists
            .find((item) => item.key === selectedPlaylist.id || item.id === selectedPlaylist.id);
          handleEditPlaylist({ ...list, title })
            .then(() => setIsEditFinal(true));
        }}
      />
      <SnackBarWindow
        isOpen={isDeleteFinal}
        onClose={() => setIsDeleteFinal(false)}
        type="success"
        content={t('steps.step4.content.body.modal.deleteSuccess')}
      />
      <SnackBarWindow
        isOpen={isEditFinal}
        onClose={() => setIsEditFinal(false)}
        type="success"
        content={t('steps.step4.content.body.modal.editSuccess')}
      />
      <SnackBarWindow
        isOpen={isCreateFinal}
        onClose={() => setIsCreateFinal(false)}
        type="success"
        content={t('steps.step4.content.body.modal.createSuccess')}
      />
    </Container>
  );
};

export default ChannelsMainHeaderModals;

const Container = styled.div``;
