import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  white,
  primary,
  secondary,
  text04,
  text01,
  text03,
  activePrimary,
  borderSecondary,
  inputBorder,
} from '@/common/constants/theme';
import ArrowLeft from '@/common/components/icons/ArrowLeft';
import CloseIcon from '@/assets/icons/close.svg';
import RemoveIcon from '../../../../icons/CloseIcon';

export default function Dropdown({
  value = [],
  defaultValue = {},
  options,
  onSelect,
  placeholder = 'Выберите опцию',
  allowClear = false,
  color,
  disabled,
}) {
  const { t } = useTranslation(['translations']);
  const [isOpen, setOpen] = useState(false);
  const [selectedValue, setValue] = useState(value || defaultValue);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          setOpen(false);
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onSelectValue = (object) => {
    let newValue = [];
    const isValueExist = selectedValue.find((el) => el.key === object.key);
    if (isValueExist) {
      newValue = selectedValue.filter((el) => el.key !== object.key);
    } else {
      newValue = [...selectedValue, object];
    }

    setValue(newValue);
    if (onSelect) {
      onSelect(newValue);
    }
    setOpen(false);
  };

  const clearAllValue = (e) => {
    e.stopPropagation();
    setValue([]);
    if (onSelect) {
      onSelect([]);
    }
  };

  const clearValue = (option, e) => {
    e.stopPropagation();
    const newValue = selectedValue.filter((el) => el.key !== option.key);
    setValue(newValue);
    if (onSelect) {
      onSelect(newValue);
    }
  };

  return (
    <Container disabled={disabled}>
      <FlexContainer ref={wrapperRef}>
        <InputWrapper
          onClick={(() => setOpen(!isOpen))}
          isMenuOpen={isOpen}
          color={color}
        >
          <Input
            color={color}
          >
            {!selectedValue.length && (
            <Placeholder>
              {placeholder}
            </Placeholder>
            )}
            {selectedValue.map((option) => (
              <SelectedOption>
                <ClearButton onClick={(e) => clearValue(option, e)}>
                  <RemoveIcon />
                </ClearButton>
                {option.title}
              </SelectedOption>
            ))}
          </Input>
          <ActionIcon>
            {allowClear && (
              <Icon
                src={CloseIcon}
                onClick={clearAllValue}
              />
            )}
            <ArrowIcon
              onClick={() => setOpen(!isOpen)}
              isOpen={isOpen}
              color={color}
            >
              <ArrowLeft />
            </ArrowIcon>
          </ActionIcon>
        </InputWrapper>
        {isOpen && (
          <Wrapper>
            <SelectorMenu>
              {options.length ? (
                options.map((option) => (
                  <Option
                    key={option.key}
                    selected={selectedValue.find((el) => el.key === option.key)}
                    onClick={() => onSelectValue(option)}
                  >
                    {option.title || option.value}
                  </Option>
                ))
              ) : (
                <NoData>{t('table.noResults')}</NoData>
              )}
            </SelectorMenu>
          </Wrapper>
        )}
      </FlexContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  margin-top: 12px;
  ${({ disabled }) => disabled && `
    pointer-events: none;
    opacity: .7;
  `}
`;

const ActionIcon = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: calc(100% + 0px);
  width: 100%;
`;

const InputWrapper = styled.div`
  background-color: ${white};
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 4px 11px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${({ height }) => height || '28px'};
  cursor: ${({ showSearch }) => (showSearch ? 'text' : 'pointer')};
  :hover {
    border-color: ${({ color }) => color || secondary};
  }
  :focus {
    border-color: ${({ color }) => color || secondary};
    outline: none;
  }
  :active {
    border-color: ${({ color }) => color || secondary};
  }
  ${({ isMenuOpen }) => isMenuOpen && `
    border-color: ${({ color }) => color || secondary};
  `}
`;

const Input = styled.div`
  font-family: Gilroy;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  padding-right: 11px;
  background: ${white};
  color: ${({ color }) => color || text01};
  ::placeholder {
    font-family: Gilroy;
    font-size: 13px;
    letter-spacing: 0.16px;
    color: ${text03};
    text-transform: capitalize;
    font-weight: 400;
  }
`;

const Icon = styled.img`
  cursor: pointer;
  transform: rotate(-90deg);
  transition: transform .25s;
  ${({ isOpen }) => isOpen && `
    transform: rotate(90deg);
  `}
  right: 35px;
  top: 10px;
  position: absolute;
`;

const ArrowIcon = styled.div`
  cursor: pointer;
  transform: rotate(-90deg);
  transition: transform .25s;
  position: absolute;
  right: 15px;
  top: 4px;
  ${({ isOpen }) => isOpen && `
  transform: rotate(90deg);
  `}
  path {
    fill: ${({ color }) => color || text01};
  }
`;

const SelectorMenu = styled.div`
  height: 100%;
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.11));
  background: ${white};
  display: flex;
  flex-direction: column;
  padding: .5rem 0;
  max-height: 10rem;
  overflow: auto;
`;

const Option = styled.div`
  padding: .25rem 1rem;
  transition: color .1s, background-color .1s;
  cursor: pointer;
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  text-transform: none;
  ${({ height }) => height && (`
    height: ${height};
    flex-shrink: 0;
    display: flex;
    align-items: center;
  `)}
  :hover {
    color: ${white};
    background: ${activePrimary};
  }
  ${({ selected }) => selected && `
    color: ${white};
    background: ${primary};
  `}
`;

const NoData = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: Gilroy;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text04};
`;

const SelectedOption = styled.div`
  display: flex;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  margin-right: 10px;
  white-space: nowrap;
`;

const ClearButton = styled.div`
  cursor: pointer;
  margin-right: 8px;
  path {
    fill: ${secondary};
  }
`;

const Placeholder = styled.span`
  color: rgba(0, 0, 0, 0.6);
`;
