export const FORM_INPUT_GUTTER_PERCENT = 3;
export const FOOTER_HEIGHT = 158;
export const MSG_RESPONSE_TYPES = {
  error: 'error',
  success: 'success',
};
export const ORDERING_DIRECTIONS = {
  up: 'up',
  down: 'down',
  none: 'none',
};
