export const fontSizes = [10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40];
export const getFontSizeKey = (fs) => `FONT_SIZE_${fs}`;
export const CustomInlineStylesMap = () => {
  const config = {
    color: {},
    bgcolor: {},
    fontSize: {},
    fontFamily: {},
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      // background: '#f1f1f1',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      wordWrap: 'break-word',
      fontSize: 16,
      padding: 2,
      // borderRadius: 3,
      // padding: '1px 3px',
    },
    SUPERSCRIPT: {
      fontSize: 11,
      position: 'relative',
      top: -8,
      display: 'inline-flex',
    },
    SUBSCRIPT: {
      fontSize: 11,
      position: 'relative',
      bottom: -8,
      display: 'inline-flex',
    },
  };

  fontSizes.forEach((fs) => { config[getFontSizeKey(fs)] = { fontSize: fs, lineHeight: `${(1.2 * fs)}px` }; });

  return config;
};
