import React from 'react';
import styled from 'styled-components';
import {
  text02,
  secondaryActive,
} from '@/common/constants/theme';
import ArrowComponet from '@/common/components/icons/ArrowRound';

export default function AutoRenewIcon(props) {
  const {
    width, height,
    title,
    active,
  } = props;
  return (
    <IconDiv isActive={active} width={width} height={height} title={title}>
      <ArrowComponet />
    </IconDiv>
  );
}

const IconDiv = styled.div`
  display: flex;
  transform: rotate(9.6deg);
  ${({ height }) => (height ? `height: ${height}` : '100%')};
  ${({ width }) => (width ? `width: ${width}` : '100%')};

  & svg {

    transition: all 0.15s ease-in 0s;
    fill: ${({ isActive }) => (isActive ? secondaryActive : text02)};
    stroke: ${({ isActive }) => (isActive ? secondaryActive : text02)};
  }
  &:hover svg {
    fill: ${secondaryActive};
    stroke: ${secondaryActive};
  }
`;
