import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import {
  background01,
  borderSecondary,
  text01,
  border,
  red,
  white,
  primary,
} from '@/common/constants/theme';
import EditIcon from '@/common/components/icons/EditIcon';
import CloseIcon from '@/common/components/icons/CloseIcon';

import Checkbox from '@/common/components/Checkbox';
import Tooltip from '@/common/components/Tooltip';
import DeleteIcon from '../../../../../../../icons/DeleteIcon';
import AdminDropdown from '../../../../../../components/Dropdown';
import AdminDeleteModal from '../../../../../../../Modals/DeleteModal';
import DisketteIcon from '../../../../../../../icons/DisketteIcon';
import SettingsIcon from '../../../../../../../icons/SettingsIcon';
import { usePlayersContext } from '../../../PlayersContext';

const DROPDOWN_OPTIONS = [
  {
    title: 'Редактировать',
    key: 'edit',
  },
  {
    title: 'Импорт из m3u-файла',
    key: 'import',
  },
  {
    title: 'Удалить',
    key: 'delete',
  },
];

const ALL_OPTION = { key: '-', title: 'Все' };

const PlayersTableHead = ({ sizes, values, setValues }) => {
  const {
    channelsGroups,
    selectedChannelGroup,
    setSelectedChannelGroup,
  } = usePlayersContext();

  const [dropdowns, setDropdowns] = useState(Array.from({ length: values.length }, () => false));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [deletedPlayer, setDeletedPlayer] = useState('');

  const dropdownWrapperRef = useRef(null);

  const getGroupOption = (group) => ({ key: group.id, title: group.name });

  const getGroupOptions = (groups) => ([
    ALL_OPTION,
    ...(groups || []).map((g) => getGroupOption(g)),
  ]);

  const handleValue = (i, field, value) => setValues((prevState) => {
    const state = [...prevState];
    state[i] = { ...state[i], [field]: value };
    return state;
  });

  const handleDropdown = (i, value) => setDropdowns((prevState) => {
    const state = [...prevState.map(() => false)];
    // eslint-disable-next-line no-param-reassign
    state[i] = value;
    return state;
  });

  return (
    <Container>
      <AdminDeleteModal
        isOpen={deletedPlayer}
        onClose={() => setDeletedPlayer('')}
        content={`плеер ${deletedPlayer}`}
        handleDelete={() => {
          setValues((prevState) => {
            const state = [...prevState];
            const id = state.findIndex((item) => item.value === deletedPlayer);

            return [...state.slice(0, id), ...state.slice(id + 1)];
          });
          setDeletedPlayer('');
        }}
      />
      <Wrapper width={sizes[0]}>
        <Text>ID</Text>
      </Wrapper>
      <Wrapper width={sizes[1]} longSeparator>
        <Text>Наименование</Text>
      </Wrapper>
      <Wrapper width={sizes[2]} longSeparator flex flexSb>
        <Text>Группа</Text>
        <Settings
          ref={dropdownWrapperRef}
          onClick={() => setIsDropdownOpen((prevState) => !prevState)}
        >
          <SettingsIcon />
          <AdminDropdown
            options={getGroupOptions(channelsGroups)}
            isOpen={isDropdownOpen}
            onClose={() => setIsDropdownOpen(false)}
            value={selectedChannelGroup && getGroupOption(selectedChannelGroup)}
            onSelect={(option) => setSelectedChannelGroup(
              channelsGroups.find((g) => g.id === option.key) || null,
            )}
            ref={dropdownWrapperRef}
          />
        </Settings>
      </Wrapper>
      <Wrapper width={sizes[3]} longSeparator>
        <Text>На английском</Text>
      </Wrapper>
      {values.map((item, i) => item.visible && (
        <Group width={280} notSeparator={i === 0} key={`${item.prevValue}--${item.isEdit}`}>
          <GroupItem flex bottomSeparator>
            {item.isEdit ? (
              <Input
                value={item.value}
                onChange={({ target }) => handleValue(i, 'value', target.value)}
              />
            ) : (
              <Text width title={item.value}>
                {item.value.length >= 15 ? (
                  `${item.value.slice(0, 15)}...`
                ) : item.value}
              </Text>
            )}
            <IconsWrapper>
              <Icon
                grey={dropdowns[i]}
                onClick={() => !item.isEdit && handleDropdown(i, !dropdowns[i])}
              >
                {item.isEdit ? (
                  <IconWrapper>
                    <Icon
                      rightMargin
                      top
                      onClick={() => {
                        handleValue(i, 'isEdit', false);
                        handleValue(i, 'prevValue', item.value);
                      }}
                    >
                      <DisketteIcon />
                    </Icon>
                    <Icon
                      rightMargin
                      onClick={() => {
                        handleValue(i, 'isEdit', false);
                        handleValue(i, 'value', item.prevValue);
                      }}
                    >
                      <CloseIcon />
                    </Icon>
                  </IconWrapper>
                ) : <EditIcon />}
              </Icon>
              <DropdownWrapper>
                <AdminDropdown
                  isOpen={dropdowns[i]}
                  onClose={() => handleDropdown(i, false)}
                  options={DROPDOWN_OPTIONS}
                  onSelect={(option) => {
                    handleDropdown(i, false);

                    if (option.key === 'delete') {
                      setDeletedPlayer(item.value);
                    } else if (option.key === 'edit') {
                      handleValue(i, 'isEdit', true);
                    }
                  }}
                  value=""
                  position="left"
                  optionClassName="admin-iptv-players-option"
                  triggerClassName="edit-icon-trigger"
                />
              </DropdownWrapper>
            </IconsWrapper>
          </GroupItem>
          <Wrapper flex notSeparator height>
            <Wrapper width={sizes[4]} notSeparator>
              <Text>
                <StyledCheckbox
                  isActive={item.name}
                  checked={item.name}
                  onChange={(checked) => handleValue(i, 'name', checked)}
                  label="Name"
                />
              </Text>
            </Wrapper>
            <Wrapper width={sizes[5]} separatorNear>
              <Text>
                <StyledCheckbox
                  isActive={item.tvg}
                  checked={item.tvg}
                  onChange={(checked) => handleValue(i, 'tvg', checked)}
                  label="TVG-ID"
                />
              </Text>
            </Wrapper>
          </Wrapper>
        </Group>
      ))}
      <Group width={sizes[6]}>
        <GroupItem flex bottomSeparator width={sizes[6]}>
          <More />
        </GroupItem>
        <Wrapper
          flex
          height
          notMargin
          notSeparator
        >
          <GroupItem flex width={sizes[6]}>
            <TooltipWrapper>
              <Tooltip title="Очистить выбранные столбцы" position="right" verticalPosition="bottom">
                <DeleteIcon />
              </Tooltip>
            </TooltipWrapper>
          </GroupItem>
        </Wrapper>
      </Group>
    </Container>
  );
};

export default PlayersTableHead;

const Container = styled.div`
  display: flex;
  align-items: center;

  min-width: 100%;
  width: fit-content;
  height: 73px;
  padding-left: 29px;

  position: relative;
  z-index: 2;

  background: ${background01};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
`;

const IconsWrapper = styled.div`
  position: relative;
`;

const DropdownWrapper = styled.div`
  & > div {
    left: -35px;
  }
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && (`
    display: flex;
    align-items: center;
  `)}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  flex-shrink: 0;

  width: ${({ width }) => width}px;
  ${({ height }) => height && ('height: 41px;')}

  position: relative;

  &:not(:first-child) {
    margin-left: 21px;
    ${({ notMargin }) => notMargin && ('margin: 0;')}

    &::after {
      content: '';

      ${({ notSeparator }) => notSeparator && ('display: none;')}

      width: 1px;
      height: ${({ longSeparator }) => (longSeparator ? 40 : 22)}px;

      position: absolute;
      top: ${({ longSeparator }) => (longSeparator ? -9 : 0)}px;
      left: -${({ separatorNear }) => (separatorNear ? 10 : 21)}px;

      background-color: ${borderSecondary};
      border-radius: 10px;

      ${({ separatorTop }) => separatorTop && ('top: -8px;')}
    }
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-shrink: 0;

  width: ${({ width }) => width}px;

  position: relative;

  &::after,
  &::before {
    content: '';

    width: 1px;

    position: absolute;
    top: 0;
    bottom: 0;

    background: ${borderSecondary};
    border-radius: 2px;
  }

  &::after {
    left: 0;
  }

  &::before {
    ${({ notSeparator }) => notSeparator && ('display: none;')}

    right: 0;
  }
`;

const GroupItem = styled.div`
  ${({ flex }) => flex && (`
    display: flex;
    justify-content: center;
    align-items: center;
  `)}
  flex-shrink: 0;

  width: ${({ width }) => `${width}px` || '100%'};

  ${({ bottomSeparator }) => bottomSeparator && (`
    height: 31px;

    border-bottom: 1px solid ${borderSecondary};
  `)}
  border-radius: 2px;
`;

const Text = styled.div`
  ${({ width }) => width && (`
    display: flex;
    justify-content: center;

    width: 85%;
  `)}

  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  color: ${text01};
  text-transform: uppercase;
  letter-spacing: 0.16px;
  line-height: 20px;

  @media (max-width: 1360px) {
    font-size: 12px;
  }

  @media (max-width: 1198px) {
    font-size: 12px;
  }

  & div[type] {
    border-color: ${primary};
  }
`;

const More = styled.div`
  width: 3px;
  height: 3px;

  position: relative;

  border-radius: 10px;
  background-color: ${text01};

  &::after,
  &::before {
    content: '';

    width: 3px;
    height: 3px;

    position: absolute;
    top: 0;

    background-color: ${text01};
    border-radius: 10px;
  }

  &::after {
    left: -6px;
  }

  &::before {
    left: 6px;
  }
`;

const Settings = styled.div`
  margin-right: 20px;

  position: relative;
  top: 3px;

  cursor: pointer;
`;

const Icon = styled.div`
  ${({ rightMargin }) => rightMargin && ('margin-right: 12px;')}

  cursor: pointer;

  position: relative;
  ${({ top }) => top && ('top: 2px;')}

  & svg path {
    ${({ grey }) => grey && (`fill: ${border};`)}

    transition: 0.2s ease;
  }

  & > div {
    left: -35px;
  }

  & .admin-iptv-players-option:last-child {
    border-top: 1px solid ${borderSecondary};

    font-weight: 600;
    color: ${red};

    &:hover {
      background-color: transparent;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const Input = styled.input`
  width: 65%;
  height: 25px;
  padding-left: 12px;
  margin-right: 12px;

  background: ${white};
  border: 1px solid ${border};
  border-radius: 2px;
  outline: none;
`;

const TooltipWrapper = styled.div`
  & div:last-child {
    bottom: -250%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  & div[type] {
    border-color: ${({ isActive }) => (isActive ? border : primary)};
  }
`;
