import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { border, borderSecondary } from '../../../../../../../../../common/constants/theme';

import Checkbox from '../../../../../../../../../common/components/Checkbox';
import BaseTableBody from './components/TableBody';
import AdminTablePagination from '../../../../../components/Table/components/TablePagination';
import { useBaseContext } from '../../BaseContext';
import AdminDropdown from '../../../../../components/Dropdown';
import SettingsIcon from '../../../../../../icons/SettingsIcon';
import { useAdminContext } from '../../../../../../../AdminContext';
import AdminTable from '../../../../../components/Table/Table';
import AdminTableHead from '../../../../../components/Table/components/TableHead';

const SIZES = [
  '50px',
  '140px',
  '70px',
  '70px',
  '150px',
  '80px',
  '45px',
  '30px',
  '40px',
  '40px',
  '40px',
];

const BaseTable = ({
  isCreateTab,
  setIsCreateTab,
}) => {
  const {
    channelsCount,
    channelsPage,
    getChannelsPageCount,
    setChannelsPage,
  } = useBaseContext();
  const {
    dropdownGroups,
    dropdownGroupsValue,
    setDropdownGroupsValue,
  } = useAdminContext();

  const [isAllArchivesChecked, setIsAllArchivesChecked] = useState(true);
  const [isDropdown, setIsDropdown] = useState(false);
  const [editedId, setEditedId] = useState(-1);
  const [deletedId, setDeletedId] = useState(-1);
  const wrapperRef = useRef(null);

  return (
    <Container>
      <AdminTable
        cellSizes={SIZES}
        page={channelsPage}
        setPage={setChannelsPage}
        pageCount={getChannelsPageCount()}
        recordsCount={channelsCount}
        isCreateTab={isCreateTab}
        setIsCreateTab={setIsCreateTab}
        editedId={editedId}
        setEditedId={setEditedId}
        deletedId={deletedId}
        setDeletedId={setDeletedId}
      >
        <AdminTableHead>
          <Text>ID</Text>
          <Text>Наименование</Text>
          <Text>Скрытый</Text>
          <Text>
            <Checkbox
              checked={isAllArchivesChecked}
              onChange={(checked) => setIsAllArchivesChecked(checked)}
              label="Архив"
            />
          </Text>
          <Wrapper flexSb>
            <Text>Группа</Text>
            <DropdownWrapper ref={wrapperRef}>
              <Icon onClick={() => setIsDropdown((prevState) => !prevState)}>
                <SettingsIcon />
                <AdminDropdown
                  options={dropdownGroups}
                  value={dropdownGroupsValue}
                  onSelect={(value) => setDropdownGroupsValue(value)}
                  isOpen={isDropdown}
                  onClose={() => setIsDropdown(false)}
                  ref={wrapperRef}
                />
              </Icon>
            </DropdownWrapper>
          </Wrapper>
          <Text>Tvg-logo</Text>
          <Text>Mode</Text>
          <Text>SID</Text>
          <Text>TSID</Text>
          <Text>ONID</Text>
          <Text>NS</Text>
        </AdminTableHead>
        <BaseTableBody />
        <AdminTablePagination />
      </AdminTable>
    </Container>
  );
};

export default BaseTable;

const Container = styled.div``;

const Text = styled.div`
  display: flex;

  & div[type] {
    border-color: ${border};

    &:hover {
      border-color: ${border};
    }
  }

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 13px;
  }
`;

const Wrapper = styled.div`
  ${({ flexSb }) => flexSb && (`
    display: flex;
    justify-content: space-between;
  `)}

  width: 100%;

  position: relative;

  &:not(:first-child) {
    margin-left: 21px;

    &::after {
      content: '';

      width: 1px;
      height: 22px;

      position: absolute;
      top: 0;
      left: -10px;

      background-color: ${borderSecondary};
      border-radius: 10px;

      ${({ separatorTop }) => separatorTop && ('top: -8px;')}
    }
  }
`;

const Icon = styled.div`
  margin-right: 10px;

  position: relative;
  top: 2px;

  cursor: pointer;
`;

const DropdownWrapper = styled.div``;
