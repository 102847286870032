import React from 'react';
import { darkRed, green } from '@/common/constants/theme';

export default ({ isActive }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 177.055 177.055"
    fill={isActive ? darkRed : green}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.001,88.527c0,48.814,39.713,88.527,88.527,88.527c48.813,0,88.526-39.713,88.526-88.527S137.341,0,88.528,0 C39.714,0,0.001,39.713,0.001,88.527z M88.528,24.304c35.413,0,64.224,28.811,64.224,64.224c0,13.324-4.081,25.712-11.055,35.983 L52.544,35.359C62.816,28.385,75.204,24.304,88.528,24.304z M124.511,141.696c-10.272,6.974-22.659,11.055-35.983,11.055 c-35.413,0-64.223-28.811-64.223-64.224c0-13.324,4.081-25.711,11.054-35.983L124.511,141.696z" />
  </svg>
);
