import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppContext } from '@/AppContext';

const LocationListener = ({ isUserExist }) => {
  const location = useLocation();

  const { setLastUserUrl } = useAppContext();

  useEffect(() => {
    setLastUserUrl(isUserExist ? [location.pathname, location.hash].join('') : '');
  }, [location.pathname, location.hash, isUserExist]);

  return <></>;
};

export default LocationListener;
