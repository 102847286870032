import RequestService from '../../request.service';
import urls from '../../routes/admin/faq.api';

export const getFaqListAction = (filter) => RequestService(
  urls.faqList,
  { method: 'GET', params: filter },
);

export const getFaqItemAction = (id) => RequestService(urls.faqItem(id));
export const addFaqItemAction = (data) => RequestService(
  urls.faqList,
  { method: 'POST', body: data },
);
export const updateFaqItemAction = (id, data) => RequestService(
  urls.faqItem(id),
  { method: 'PATCH', body: data },
);
export const removeFAQItemAction = (id) => RequestService(
  urls.faqItem(id), { method: 'DELETE' },
);

export const uploadImageAction = (file, Qid, tmpQid) => {
  const formData = new FormData();
  formData.append('image', file);
  if (Qid) {
    formData.append('faq', Qid);
  } else {
    formData.append('tmp_faq_id', tmpQid);
  }
  return RequestService(urls.image(), {
    method: 'POST',
    body: formData,
  }, false);
};

export const deleteImageAction = (id) => RequestService(
  urls.image(id), { method: 'DELETE' },
);

export const loadTagsListAction = () => RequestService(urls.tags);

export const loadTagAction = (id) => RequestService(urls.tag(id));
export const addTagAction = (title) => RequestService(
  urls.tags, { method: 'POST', body: { title } },
);
export const editTagAction = (id, title) => RequestService(
  urls.tags, { method: 'PUT', body: { title } },
);
export const deleteTagAction = (id) => RequestService(
  urls.tag(id), { method: 'DELETE' },
);
