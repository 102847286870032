import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import Loader from '@/common/components/Loader';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import { useStatisticAggregatorsContext } from '../AggregatorsContext';
import TableBody from '@/common/components/Table/components/TableBody';
import AdminTablePagination from '../../../components/Table/components/TablePagination';
import EditIcon from '@/common/components/icons/EditIcon';

const SIZES = ['100px', '750px', '200px'];

const StatisticWithdrawalTable = ({ editPath }) => {
  const {
    isBusy,
    withdrawalSystems,
    wsCount,
    wFilters, setWFilters,
  } = useStatisticAggregatorsContext();

  return withdrawalSystems && (
    <Container>
      <Table
        cellSizes={SIZES}
        limit={wFilters.limit}
        page={wFilters.page}
        dataLength={withdrawalSystems.length}
        textAlign="left"
      >
        {isBusy && <Loader size="small" />}
        <TableHead>
          <HeadItem>
            Позиция
          </HeadItem>
          <HeadItem>
            Платежные системы
          </HeadItem>
          <HeadItem />
        </TableHead>
        <TableBody
          rowHeight={42}
          minRows={Math.max(withdrawalSystems.length, 10)}
        >
          {withdrawalSystems.map((item, i) => (
            <div key={`${item.id}--WSTRow`}>
              <RowItem>
                {i + 1 + (((wFilters?.page || 1) - 1) * wFilters.limit)}
              </RowItem>
              <RowItem>
                {item.name}
              </RowItem>
              <RowItem flex>
                <StyledLink to={`${editPath}/${item.id}`}>
                  <EditIcon />
                </StyledLink>
              </RowItem>
            </div>
          ))}
        </TableBody>
        <AdminTablePagination
          page={wFilters?.page || 1}
          setPage={(newPage) => { setWFilters((prevState) => ({ ...prevState, page: newPage })); }}
          pageCount={Math.ceil(wsCount / wFilters.limit)}
          recordsCount={wsCount}
        />
      </Table>
    </Container>
  );
};

export default StatisticWithdrawalTable;

const Container = styled.div``;

const HeadItem = styled.div``;

const RowItem = styled.div`
  ${({ flex }) => flex && 'display: flex;'}
`;

const StyledLink = styled(Link)`
`;
