import RequestService from '@/services/request.service';
import urls from '@/services/routes/admin/finance.api';

export const getPaymentSystemAction = (id) => RequestService(urls.paymentSystem(id));

export const getPaymentSystemsAction = (filterString = '') => RequestService(urls.paymentSystems(filterString));
export const createPaymentSystemAction = (paymentSystem) => RequestService(
  urls.paymentSystemCreate,
  { method: 'POST', body: paymentSystem },
);
export const changePaymentSystemAction = (paymentSystemId, paymentSystem) => RequestService(
  urls.paymentSystem(paymentSystemId),
  { method: 'PATCH', body: paymentSystem },
);
export const deletePaymentSystemAction = (paymentSystemId) => RequestService(
  urls.paymentSystem(paymentSystemId),
  { method: 'DELETE' },
);

export const onOffPaymentSystemAction = (id, state) => RequestService(
  `${urls.paymentSystem(id)}${state ? 'enable' : 'disable'}/`,
  { method: 'POST' },
);

export const hideShowPaymentSystemAction = (id, state) => RequestService(
  `${urls.paymentSystem(id)}${state ? 'show' : 'hide'}/`,
  { method: 'POST' },
);

export const addUserBalanceAction = (payment) => RequestService(urls.addUserBalance, {
  method: 'POST',
  body: payment,
});
export const getPaymentsStatsAction = (filterString) => RequestService(
  urls.paymentsStats(filterString),
);
export const getPaymentsAction = (filterString) => RequestService(
  urls.paymentsList(filterString),
);
export const returnPaymentAction = (paymentId) => RequestService(
  urls.paymentReturn(paymentId), { method: 'POST' },
);

export const getWithdrawalSystemsAction = () => RequestService(urls.withdrawalSystems);
export const getWithdrawalSystemByIdAction = (id) => RequestService(urls.withdrawalSystem(id));
export const createWithdrawalSystemAction = (wSystem) => RequestService(
  urls.withdrawalSystems,
  { method: 'POST', body: wSystem },
);
export const changeWithdrawalSystemAction = (wSystemId, wSystem) => RequestService(
  urls.withdrawalSystem(wSystemId),
  { method: 'PATCH', body: wSystem },
);
export const deleteWithdrawalSystemAction = (id) => RequestService(
  urls.withdrawalSystem(id),
  { method: 'DELETE' },
);

export const getWithdrawalTransactionsAction = (filterString) => RequestService(
  urls.withdrawalTransactions(filterString),
);
export const approveWithdrawalTransactionAction = (wTransactionId, reason) => RequestService(
  urls.withdrawalApprove(wTransactionId), { method: 'POST', body: { reason: reason || 'admin' } },
);
export const rejectWithdrawalTransactionAction = (wTransactionId, reason) => RequestService(
  urls.withdrawalReject(wTransactionId), { method: 'POST', body: { reason: reason || 'admin' } },
);
