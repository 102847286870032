/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useContext, useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { getLogsAction } from '@/services/actions/logs.actions';
import request2Service from '@/services/request2.service';
import urls from '@/services/routes/logs.api';
import { setSnack } from '@/store/user/selectors';

import { REQUEST_DATE_FORMAT } from '../../../PersonalPageContext';

export const LogsContext = createContext({});

export const INITIAL_FILTER = {
  group: '',
  d_begin: '',
  d_end: '',
  q: '',
  page: 1,
  limit: 10,
};

export const LogsContextProvider = ({ children }) => {
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();

  const [groups, setGroups] = useState([]);
  const [allLogs, setAllLogs] = useState(null);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const logsCount = filteredLogs?.length ?? 0;

  const [startFilterDate, setStartFilterDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [groupIdFilter, setGroupIdFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [logsData, setLogsData] = useState([]);
  const [logsDataLen, setLogsDataLen] = useState(0);
  const [filterStd, setFilterStd] = useState(INITIAL_FILTER);

  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const pageCount = Math.ceil(logsCount / pageLimit);

  const getAllLogs = () => {
    if (isLoading || allLogs) { return; }

    let isRequested = false;
    setIsLoading(true);
    getLogsAction()
      .then((res) => {
        if (res.status === 'processed') {
          const timeout = 1000 * 1;
          const taskInterval = setInterval(() => {
            if (isRequested) return;
            isRequested = true;
            getLogsAction(res.task_id)
              .then((taskRes) => {
                if (taskRes.status !== 'processed') {
                  clearInterval(taskInterval);
                  setIsLoading(false);
                }
                setAllLogs(taskRes.status === 'success'
                  ? taskRes.results
                  : []);
              })
              .catch((taskErr) => {
                setSnack(taskErr);
                setAllLogs([]);
                setIsLoading(false);
              })
              .finally(() => {
                isRequested = false;
              });
          }, timeout);
        }
      }).catch((err) => {
        setSnack(err);
        setAllLogs([]);
        setIsLoading(false);
      });
  };

  const getLogsByFilterStd = () => {
    const useFilter = {
      ...filterStd,
      d_begin: filterStd.d_begin ? format(filterStd.d_begin, REQUEST_DATE_FORMAT) : '',
      d_end: filterStd.d_end ? format(filterStd.d_end, REQUEST_DATE_FORMAT) : '',
    };
    setIsLoading(true);
    getLogsAction(useFilter)
      .then((res) => {
        setLogsDataLen(res?.count || 0);
        setLogsData(res?.results || []);
      })
      .catch((err) => {
        dispatch(setSnack(err));
        setLogsDataLen(0);
        setLogsData([]);
      })
      .finally(() => setIsLoading(false));
  };

  function getFilteredLogs(items) {
    let result = items;
    if (groupIdFilter > 0) {
      result = result.filter((l) => l.group === groupIdFilter);
    }
    if (startFilterDate) {
      result = result.filter((l) => new Date(l.created_at) >= new Date(startFilterDate));
    }
    if (endFilterDate) {
      result = result.filter((l) => new Date(l.created_at) < new Date(endFilterDate));
    }
    if (searchFilter !== '' && searchFilter != null) {
      result = result.filter((l) => l.message.match(searchFilter));
    }
    return result;
  }

  const setFilter = (field, data) => {
    switch (field) {
      case 'startFilterDate':
        setStartFilterDate(data);
        break;
      case 'endFilterDate':
        setEndFilterDate(data);
        break;
      case 'groupIdFilter':
        setGroupIdFilter(data);
        break;
      case 'searchFilter':
        setSearchFilter(data);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(`Unknown filter field ${field}`);
    }
  };

  const resetFilter = () => {
    setFilterStd(INITIAL_FILTER);
  };

  const setFilterValues = (obj) => {
    setFilterStd((prevState) => ({ ...prevState, ...(obj || {}) }));
  };

  // useEffect(() => {
  //   getAllLogs();
  // }, [allLogs]);

  // useEffect(() => {
  //   if (page > pageCount) {
  //     setPage(1);
  //   }
  // }, [pageLimit, filteredLogs]);

  useEffect(() => {
    request2Service(urls.groups, {
      method: 'GET',
    })
      .then((res) => {
        setGroups(
          [{ title: t('profile.logs.allGroups'), key: 0 },
            ...res.results
              .filter((r) => r.public)
              .map((r) => ({ title: t(`profile.logs.${r.label}`), key: r.id }))],
        );
      })
      // eslint-disable-next-line no-console
      .catch(console.warn);
  }, []);

  // useEffect(() => {
  //   if ((allLogs?.length || 0) > 0) {
  //     setFilteredLogs(getFilteredLogs(allLogs));
  //   }
  // }, [allLogs, startFilterDate, endFilterDate, groupIdFilter, searchFilter]);

  useEffect(() => {
    getLogsByFilterStd();
  }, [filterStd]);

  const value = {
    isLoading,
    logs: logsData,
    logsCount: logsDataLen,
    page,
    setPage,
    pageCount,
    pageLimit,
    setPageLimit,
    groups,
    setStartFilterDate,
    setEndFilterDate,
    setGroupIdFilter,
    setSearchFilter,
    setFilter,
    resetFilter,
    getAllLogs,
    filter: filterStd,
    setFilterValues,
  };

  return (
    <LogsContext.Provider value={value}>
      {children}
    </LogsContext.Provider>
  );
};

export const useLogsContext = () => useContext(LogsContext);
