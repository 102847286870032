import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  background01,
  text01,
  secondary,
} from '@/common/constants/theme';
import Clock from '../../../../../../assets/images/webp/clock.webp';
import CloseIcon from '../../../icons/CloseIcon';

const TopBanner = ({ isFullWidth, type, closeHandler }) => {
  const { t } = useTranslation(['translations']);
  return (
    <Container
      isFullWidth={isFullWidth}
    >
      <TextBlock>
        <Title>
          {t('completion.bannerTitle')}
        </Title>
        <SubTitle>
          {type === 'stalker' ? t('completion.stalkerSubTitle') : t('completion.playlistSubTitle') }
        </SubTitle>
      </TextBlock>
      <Image src={Clock} />
      <CloseBlock
        title={t('personalPage.hide')}
        onClick={() => closeHandler && closeHandler()}
      >
        <CloseIcon />
      </CloseBlock>
    </Container>
  );
};

export default TopBanner;

const Container = styled.div`
  min-height: 287px;
  height: auto;
  background: ${background01};
  position: relative;
  ${({ isFullWidth }) => (isFullWidth ? `
    padding: 2rem 9rem 3rem 1rem;
    margin: 1.25rem 0 0 -1rem;
  ` : `
    padding: 2rem 9rem 3rem 1rem;
    margin: 1.25rem 1.1875rem 0 -1rem;
  `)}
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    min-height: 287px;
    height: 100%;
    top: 0;
    left: -100%;
    background: ${background01};
  }
`;

const Image = styled.img`
  width: 264px;
  height: 182px;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${text01};
  position: relative;
  margin-bottom: 33px;
  white-space: pre-wrap;

  &:after {
    content: '';
    height: 4px;
    position: absolute;
    width: 30px;
    bottom: -10px;
    left: 0;
    background: ${secondary};
  }
`;

const SubTitle = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: ${text01};
  white-space: pre-wrap;
`;

const CloseBlock = styled.div`
  position: absolute;
  cursor: pointer;
  top: .8rem;
  right: .8rem;

  & svg{
    width: 12px;
    height: 12px;

    path {
      stroke: red;
    }
  }
`;
