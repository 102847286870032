import React, { useState } from 'react';
import styled from 'styled-components';

import ArrowTable from '../../../../../../icons/ArrowTable';

export const SORT_DIRECTION_UP = 'up';
export const SORT_DIRECTION_DOWN = 'down';

const UsersMainUserFilterArrows = ({ sortTriggered, initDirection }) => {
  const [state, setState] = useState(initDirection || SORT_DIRECTION_DOWN);

  const isRotate = () => state === SORT_DIRECTION_DOWN;

  const sort = () => {
    const newState = state === SORT_DIRECTION_DOWN ? SORT_DIRECTION_UP : SORT_DIRECTION_DOWN;
    setState(newState);
    sortTriggered(newState);
  };

  return (
    <Container>
      <Icon rotate={isRotate()} pointer onClick={sort}>
        <ArrowTable />
      </Icon>
    </Container>
  );
};

export default UsersMainUserFilterArrows;

const Container = styled.div`
  display: flex;

  margin-left: 8px;
`;

const Icon = styled.div`
  ${({ leftMargin }) => leftMargin && 'margin-left: 2px;'}

  position: relative;
  ${({ top }) => top && 'top: 3px;'}
  cursor: pointer;

  ${({ rotate }) => (rotate ? 'transform: rotate(180deg);' : null)}
`;
