import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { white } from '@/common/constants/theme';

import SnackBarWindow from '../../../../../../../../../common/components/SnackBarWindow';
import ChannelsMainEditModal from '../MainModals/ChannelsMainEditModal';
import ChannelsMainGroupCards from './components/ChannelsMainGroupCards';
import ChannelsMainDeleteModal from '../MainModals/ChannelsMainDeleteModal';

import { useChannelsContext } from '../../../../ChannelsContext';

const ChannelsMainGroup = ({
  isFullWidth,
  setHoveredGroup,
  isDraggedChannel,
  setShowDraggedElem,
  changeFiltersHandler,
}) => {
  const { t } = useTranslation('translations');
  const {
    channelsFilters,
    handleDragEndGroup,
    handleEditGroup,
    handleDeleteGroup,
    selectedPlaylist,
    defaultPlaylist,
  } = useChannelsContext();

  const { isGroups } = channelsFilters;
  const [editedValue, setEditedValue] = useState(null);
  const [deletedValue, setDeletedValue] = useState(null);
  const [isFinalEdit, setIsFinalEdit] = useState(false);
  const [isFinalDelete, setIsFinalDelete] = useState(false);

  return (
    <Container
      isFullWidth={isFullWidth}
      isGroups={isGroups}
      onMouseEnter={() => setShowDraggedElem(true)}
      onMouseLeave={() => {
        setHoveredGroup(-1);
        setShowDraggedElem(false);
      }}
    >
      <ChannelsMainEditModal
        isOpen={editedValue}
        value={editedValue && editedValue.name}
        onClose={() => setEditedValue(null)}
        title={t('steps.step4.content.body.modal.editGroupTitle')}
        descr={t('steps.step4.content.body.modal.editGroupTitle')}
        onSubmit={(newTitle) => {
          handleEditGroup(editedValue && editedValue.id, newTitle);
          setIsFinalEdit(true);
        }}
        error={t('steps.step4.content.body.modal.errors.minGroupLength')}
      />
      <ChannelsMainDeleteModal
        isOpen={deletedValue}
        value={deletedValue && deletedValue.name}
        onClose={() => setDeletedValue(null)}
        title={t('steps.step4.content.body.modal.sureGroupDelete')}
        onSubmit={() => {
          handleDeleteGroup(deletedValue && deletedValue.id);
          setIsFinalDelete(true);
        }}
      />
      <SnackBarWindow
        isOpen={isFinalEdit}
        onClose={() => setIsFinalEdit(false)}
        content={t('steps.step4.content.body.modal.editGroupSuccess')}
        type="success"
      />
      <SnackBarWindow
        isOpen={isFinalDelete}
        onClose={() => setIsFinalEdit(false)}
        content={t('steps.step4.content.body.modal.deleteGroupSuccess')}
        type="success"
      />
      <ClonedGroup className="channels-cloned-group" />
      <ChannelsMainGroupCards
        shouldCancelStart={() => (
          (selectedPlaylist?.id === defaultPlaylist?.id)
        )}
        isEditable={(selectedPlaylist?.id !== defaultPlaylist?.id) && !selectedPlaylist?.is_virtual}
        isDraggable={(selectedPlaylist?.id !== defaultPlaylist?.id)}
        canChange={(selectedPlaylist?.id !== defaultPlaylist?.id)}
        changeFiltersHandler={changeFiltersHandler}
        isDraggedChannel={isDraggedChannel}
        setHoveredGroup={setHoveredGroup}
        setEditedValue={setEditedValue}
        setDeletedValue={setDeletedValue}
        onSortEnd={handleDragEndGroup}
        axis="y"
        lockAxis="y"
        distance={5}
        helperContainer={document.querySelector('.channels-cloned-group')}
      />
    </Container>
  );
};

export default ChannelsMainGroup;

const Container = styled.div`
  max-width: ${({ isFullWidth }) => (isFullWidth ? '362' : '302')}px;
  max-height: 456px;

  position: relative;

  overflow-y: auto;
  background: ${white};
  box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.18);
  border-radius: 0 4px 4px 0;
  cursor: grabbing;

  transition: 0.4s ease;

  ${({ isGroups, isFullWidth }) => !isGroups && (`
    margin-left: -${isFullWidth ? 370 : 310}px;
  `)}
`;

const ClonedGroup = styled.div`
  position: relative;
  z-index: 200;
`;
