import { taskIdAddon } from './utils';

export default {
  threads: (taskId) => `/api/v1/helpdesk/cases/${taskIdAddon(taskId)}`,
  thread: (id, taskId) => `/api/v1/helpdesk/cases/${id}/messages/${taskIdAddon(taskId)}`,
  markThreadRead: (id) => `/api/v1/helpdesk/cases/${id}/reading/`,
  threadClose: (id, taskId) => `/api/v1/helpdesk/cases/${id}/close/${taskIdAddon(taskId)}`,
  message: (id, messageID, taskId) => `/api/v1/helpdesk/cases/${id}/message/${messageID}/${taskIdAddon(taskId)}`,
  unreadThreadsCount: '/api/v1/helpdesk/cases/unread-message/',
};
