import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import modalClose from '../../assets/icons/closeWhite.svg';
import {
  green, white, primary,
} from '../constants/theme';

import { COLORS } from '../constants/modalColors';

const ModalNew = ({
  onCloseModal,
  children,
  title,
  icon,
  isOpen,
  isHidden = true,
  width,
  maxWidth,
  type,
  closeOnOutside,
  isAdmin = false,
  noOverFlow = false,
}) => {
  const onCloseModalHandler = () => {
    if (onCloseModal) onCloseModal();
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (closeOnOutside && ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          onCloseModal();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const maxHeight = () => {
    const mh = window.innerHeight * 0.9;
    return `${mh}px`;
  };

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <div ref={wrapperRef}>
            <ModalWindow
              isHidden={isHidden}
              width={width}
              maxWidth={maxWidth}
              maxHeight={maxHeight()}
            >
              <Title type={type} isAdmin={isAdmin}>
                <img src={icon} alt={icon} />
                <span>{title}</span>
                <ModalClose
                  modalClose={modalClose}
                  onClick={onCloseModalHandler}
                />
              </Title>
              <ModalContent noOverFlow={noOverFlow}>
                {children}
              </ModalContent>
            </ModalWindow>
          </div>
        </ModalOverlay>
      )}
    </>
  );
};

export default ModalNew;

const Title = styled.div`
  display: flex;
  background: ${({ type, isAdmin }) => (
    // eslint-disable-next-line no-nested-ternary
    isAdmin ? primary : (COLORS?.[type] || green)
  )};
  padding: 1.25rem 2rem;
  align-items: center;
  font-family: Gilroy, sans-serif;
  position: relative;
  span{
    color: ${white};
    margin-left: 20px;
    font-size: 15px;
  }
  img{
    max-width: 50px;
    max-height: 50px;
  }
`;

const ModalContent = styled.div`
  padding: 1rem 2rem;
  font-family: Gilroy, sans-serif;
  ${({ noOverFlow }) => (!noOverFlow && `
    overflow-y: auto;
    overflow-x: hidden;
  `)}
  white-space: pre-line;
`;

const ModalClose = styled.div`
  background-image: url(${(props) => props.modalClose});
  width: 11px;
  height: 11px;
  position: absolute;
  right: 13px;
  top: 13px;
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(110, 110, 110, .45);
  z-index: 1000;
  cursor: pointer;
`;

const ModalWindow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 4px;
  width: ${({ width }) => width || '500px'};
  max-width: ${({ maxWidth }) => maxWidth || '90%'};
  height: max-content;
  max-height: ${({ maxHeight }) => maxHeight || '60%'};
  display: flex;
  flex-direction: column;
  background-color: ${white};
  ${({ isHidden }) => isHidden && ('overflow: hidden;')};
  cursor: default;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
`;
