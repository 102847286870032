import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  background01,
  inputBorder,
  primary,
  // secondary,
  borderSecondary,
  text01, secondary,
} from '@/common/constants/theme';

import ModalNew from '@/common/components/ModalNew';
import alertIcon from '@/assets/icons/alertIcon.svg';
import starIcon from '@/assets/icons/starIcon.svg';
import Button from '@/common/components/Button';
import WalletIcon from '../../../icons/WalletIcon';

import { KNOWN_REPLENISH_BONUS_TYPES } from '../../../../PersonalPageContext';

const BalanceModals = ({
  modal,
  isOpen,
  replenishmentValue,
  bonus,
  onClose,
  handleSubmit,
}) => {
  const { t } = useTranslation(['translations']);
  const useBonusType = KNOWN_REPLENISH_BONUS_TYPES.includes(bonus?.type) ? bonus?.type : 'undefined';
  return (
    <ModalNew
      key={modal.title}
      title={modal.title}
      icon={alertIcon}
      isOpen={isOpen}
      onCloseModal={onClose}
    >
      <Text>{modal.subTitle}</Text>
      {modal?.prompt && (
        <Prompt>
          <Img src={starIcon} alt="" />
          <PromptWrapper>
            <Text>{modal?.prompt.title}</Text>
            <BoldText marginTop>{modal?.prompt?.warning}</BoldText>
          </PromptWrapper>
        </Prompt>
      )}
      <Text marginTopBottom>{modal?.contacts?.title}</Text>
      {modal?.contacts && (
        <Contacts>
          {modal?.contacts?.contacts.map((contact) => (
            <StyleLink
              key={`${contact.descr}-${Math.random()}`}
              href={contact.link}
            >
              <Icon src={contact.icon} alt="" />
              {contact.descr}
            </StyleLink>
          ))}
        </Contacts>
      )}
      <Text>{modal.warning}</Text>
      {modal?.isReplenishment && (
        <Replenishment>
          <Wrapper>
            <FlexWrapper>
              <Text>{t('balance.modal.replenishment')}</Text>
              <Bonus>
                <WalletIcon />
                <BoldText top largeFontSize marginLeft>
                  {`${parseFloat(replenishmentValue, 10).toFixed(2)}$`}
                </BoldText>
              </Bonus>
            </FlexWrapper>
          </Wrapper>
          <FlexWrapper>
            {(bonus?.value || 0) > 0 && (
              <FlexWrapper marginRight="2rem">
                <BoldText>{t(`balance.bonusTypes.${useBonusType}`)}</BoldText>
                <BonusText>
                  {`+${bonus.value}$`}
                </BonusText>
              </FlexWrapper>
            )}
            <StyledButton
              isBoldText
              isUpperCase
              onClick={handleSubmit}
            >
              {t('balance.modal.pay')}
            </StyledButton>
          </FlexWrapper>
        </Replenishment>
      )}
    </ModalNew>
  );
};

export default BalanceModals;

const Wrapper = styled.div``;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ marginTop }) => marginTop && ('margin-top: 15px;')};
  ${({ marginBottom }) => marginBottom && ('margin-bottom: 20px;')};
  ${({ marginRight }) => marginRight && (`margin-right: ${marginRight};`)};
`;

const Text = styled.div`
  ${({ marginTopBottom }) => marginTopBottom && ('margin: 8px 0;')};
  ${({ marginBottom }) => marginBottom && ('margin-bottom: 5px;')};

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const BoldText = styled(Text)`
  ${({ marginTop }) => marginTop && ('margin-top: 8px;')};
  ${({ marginLeft }) => marginLeft && ('margin-left: 15px;')};

  position: relative;
  ${({ top }) => top && ('top: 2px;')};

  font-weight: bold;
  ${({ largeFontSize }) => largeFontSize && ('font-size: 18px;')};
`;

const PromptWrapper = styled.div`
  margin-left: 8px;
`;

const Prompt = styled.div`
  display: flex;
  align-items: flex-start;

  padding: 17px 15px;
  margin-bottom: 20px;
  margin-top: 17px;

  background: ${background01};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.11);
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
`;

const StyleLink = styled.a`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${primary};
  line-height: 26px;
  letter-spacing: 0.16px;

  &:hover {
    color: ${primary};
    text-decoration: underline;
  }
`;

const Replenishment = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 35px;

  position: relative;

  &::after {
    content: '';

    height: 1px;

    position: absolute;
    top: -14px;
    left: -20%;
    right: -20%;

    background-color: ${borderSecondary};
  }
`;

const Bonus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-width: 102px;
  height: 44px;
  padding-left: 15px;
  padding-right: 22px;
  margin-left: 25px;

  background: ${background01};
  border: 1px solid ${inputBorder};
  border-radius: 4px;
`;

const BonusText = styled(BoldText)`
  margin-left: 1rem;

  font-size: 20px;
  color: ${secondary};
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 0 17px;
  margin: 12px 8px 18px auto;
`;

const Img = styled.img``;

const Icon = styled.img`
  width: 15px;
  margin-right: 15px;

  & svg path {
    fill: ${primary};
  }
`;
