/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import RequestService from '../request.service';
import Request2Service from '../request2.service';

import urls from '../routes/profile.api';
import urlsAuth from '../routes/authorization.api';

export const handleTzNotificationsAction = (tzNotifies) => (
  RequestService(urls.tzNotifications, {
    method: 'PATCH',
    body: tzNotifies,
  })
);

export const getLogsAction = async () => (
  RequestService(urls.logs, {
    method: 'GET',
  })
);

export const getTimezonesAction = () => (
  RequestService(urls.timezones, {
    method: 'GET',
  })
);

export const getWithdrawalSystemsAction = () => (
  RequestService(urls.withdrawalSystems, {
    method: 'GET',
    params: { page: 1, limit: 1000 },
  })
);

export const getWithdrawalWalletsAction = () => (
  RequestService(urls.withdrawalWallets, {
    method: 'GET',
  })
);

export const addWithdrawalWalletAction = (wsid, wsuid) => (
  Request2Service(urls.withdrawalWallets, {
    method: 'POST',
    body: {
      withdrawal_system_id: wsid,
      withdrawal_system_user_identifier: wsuid,
    },
  })
);

export const editWithdrawalWalletAction = (wid, wdata) => (
  RequestService(`${urls.withdrawalWallets}/${wid}`, {
    method: 'PATCH',
    body: wdata,
  })
);

export const deleteWithdrawalWalletAction = (wid) => (
  RequestService(`${urls.withdrawalWallets}/${wid}`, {
    method: 'DELETE',
  })
);

export const confirmWithdrawalWalletAction = (token) => (
  RequestService(`${urls.withdrawalWallets}/confirm`, {
    method: 'POST',
    body: {
      token,
    },
  })
);

export const getAccountsAction = () => (
  RequestService(urls.accounts, {
    method: 'GET',
  })
);

export const addAccountAction = (code, value) => (
  RequestService(urls.addAccount, {
    method: 'POST',
    body: {
      code_payment_system: code,
      account: value,
    },
  })
);

export const confirmAccountAction = (account) => (
  RequestService(urls.confirmAccount, {
    method: 'PATCH',
    body: {
      account,
    },
  })
);

export const editAccountAction = (account, newAccount) => (
  RequestService(urls.editAccount, {
    method: 'PATCH',
    body: {
      current_account: account,
      new_account: newAccount,
    },
  })
);

export const confirmChangeAccountAction = (account) => (
  RequestService(urls.confirmChangeAccount, {
    method: 'PATCH',
    body: {
      account,
    },
  })
);

export const deleteAccountAction = (account) => (
  RequestService(urls.deleteAccount, {
    method: 'DELETE',
    body: {
      account,
    },
  })
);

export const changeEmailRequestAction = () => RequestService(urls.changeEmailRequestCurrent, {
  method: 'POST',
});

export const resetPasswordAction = (onEmail) => RequestService(urlsAuth.forgetPassword, {
  method: 'POST',
  body: {
    email: onEmail,
  },
});

export const changePasswordAction = (password, confirmedPassword) => (
  RequestService(urls.changePassword, {
    method: 'PATCH',
    body: {
      password,
      repeat_password: confirmedPassword,
    },
  })
);

export const changeEmailAction = (email) => (
  RequestService(urls.changeEmail, {
    method: 'PATCH',
    body: {
      new_email: email,
    },
  })
);

export const confirmChangeEmailAction = (token) => (
  RequestService(urls.confirmEmail, {
    method: 'PATCH',
    body: {
      token,
    },
  })
);

export const deleteUserRequestAction = () => RequestService(urls.deleteUserRequest, { method: 'DELETE' });

export const confirmDeleteUserAction = (token) => RequestService(urls.deleteUser, {
  method: 'POST',
  body: {
    token,
  },
});

export const logoutFromAllAction = () => (
  RequestService(urlsAuth.signOut, {
    method: 'POST',
  })
);

export const unsubscribeAction = (token) => RequestService(
  urls.unsubscribe, { method: 'POST', body: { token } },
);
