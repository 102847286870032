import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  enUS as en,
  ru,
} from 'date-fns/locale';
import dayjs from 'dayjs';

import styled from 'styled-components';
import {
  text01,
  activePrimary,
  secondary,
  primary,
} from '@/common/constants/theme';

import { BASE_PATH } from '../NewsContext';

const NewsSmallCard = ({
  id,
  title,
  shortText,
  date,
  forumLink,
  details,
}) => {
  const { t, i18n } = useTranslation('translations');

  const locales = { en, ru };
  const selectedLocale = locales[i18n.language];
  const dateObj = date ? dayjs(date, 'YYYY-MM-DD', true).toDate() : null;
  const dateStr = dateObj ? format(dateObj, 'dd MMMM yyyy', { locale: selectedLocale }) : '';

  return (
    <Container>
      <Link to={`${BASE_PATH}/${id}`}>
        <Title bottomMargin={12}>{title}</Title>
        <Text
          bottomMargin={!forumLink && !details ? 12 : 2}
          dangerouslySetInnerHTML={{ __html: shortText }}
        />
        {forumLink && (
          <StyledLink>
            {t('news.forum')}
          </StyledLink>
        )}
        {details && (
          <StyledLink>
            {t('news.details')}
          </StyledLink>
        )}
        <Date>{dateStr}</Date>
      </Link>
    </Container>
  );
};

export default NewsSmallCard;

const Text = styled.div`
  max-width: 530px;
  ${({ bottomMargin }) => bottomMargin && (`margin-bottom: ${bottomMargin}px;`)}

  font-family: Gilroy;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const Date = styled(Text)`
  font-weight: 500;
  color: ${activePrimary};

  position: relative;

  &::before {
    content: '';

    width: 30px;
    height: 4px;

    position: absolute;
    bottom: -15px;
    left: 0;

    background-color: ${secondary};
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;

  transition: all 0.125s ease-in-out 0s;
`;

const Container = styled.div`
  width: 50%;
  margin-bottom: 75px;

  &:nth-child(3) ${Date}::before,
  &:nth-child(4) ${Date}::before {
    background-color: ${primary};
  }

  &:hover {
    ${Title} {
      color: ${secondary};
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  max-width: 530px;
  margin-bottom: 12px;

  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${secondary};

  &:hover {
    color: ${secondary};
  }

  &:active {
    color: ${secondary};
  }
`;
