import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { buyPremiumForDevice } from '@/store/user/selectors';

import styled from 'styled-components';
import { secondary, white } from '@/common/constants/theme';

import { CURRENCY_SYMBOLS } from '@/common/constants/financial';

// import Modal from '@/common/components/Modal';
import ModalNew from '@/common/components/ModalNew';
import Crown from '@/assets/icons/crown.svg';

import { useAppContext } from '@/AppContext';

const EMPTY_DATA = {
  id: 0,
  name: '',
  sum: 0,
  currency: 'usd',
};

const DeviceBuyPremiumModal = ({ doClose, result }) => {
  const { t } = useTranslation('translations');
  const { setUserInfo } = useAppContext();

  const [data, setData] = useState(EMPTY_DATA);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const { id } = data;
    dispatch(buyPremiumForDevice({ id }))
      .then((res) => {
        if (!res.error && res.id) {
          setUserInfo(res);
        }
      });
    doClose();
  };

  useEffect(() => {
    if (result) {
      setData(
        result
          ? {
            id: result?.items?.[0]?.device_id || 0,
            name: result?.items?.[0]?.device_name || '-',
            sum: result?.amount || 0,
            currency: CURRENCY_SYMBOLS?.[result?.currency] || result?.currency || '',
          }
          : EMPTY_DATA,
      );
    }
  }, [result]);

  return (
    <ModalNew
      isOpen
      icon={Crown}
      title={t('subscriptionsContent.buyPremiumSubscription')}
      onCloseModal={() => {
        doClose();
      }}
      centered
    >
      {t('subscriptionsContent.buyPremium', data)}
      <Actions>
        <Button
          onClick={handleSubmit}
        >
          {t('subscriptionsContent.submit')}
        </Button>
      </Actions>
    </ModalNew>
  );
};

export default DeviceBuyPremiumModal;

const Button = styled.button`
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
`;
