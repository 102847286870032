import React from 'react';

export default ({ size = 76 }) => (
  <svg width={size} height={size} viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70.4336 3.85938H5.56641C2.49702 3.85938 0 6.35639 0 9.42578V56.1836C0 59.253 2.49702 61.75 5.56641 61.75H28.2116L26.8716 66.4397C26.3336 68.323 24.2243 69.9141 22.2656 69.9141H14.9922C14.3774 69.9141 13.8789 70.4125 13.8789 71.0273C13.8789 71.6422 14.3774 72.1406 14.9922 72.1406H61.0078C61.6226 72.1406 62.1211 71.6422 62.1211 71.0273C62.1211 70.4125 61.6226 69.9141 61.0078 69.9141H53.7344C51.7757 69.9141 49.6664 68.323 49.1284 66.4397L47.7884 61.75H70.4336C73.503 61.75 76 59.253 76 56.1836V9.42578C76 6.35639 73.503 3.85938 70.4336 3.85938ZM46.9874 67.0514C47.2929 68.1208 47.9002 69.1034 48.6963 69.9141H27.3037C28.0998 69.1034 28.7071 68.1208 29.0126 67.0514L30.5272 61.75H45.4726L46.9874 67.0514ZM73.7734 56.1836C73.7734 58.0253 72.2753 59.5234 70.4336 59.5234H5.56641C3.72474 59.5234 2.22656 58.0253 2.22656 56.1836V9.42578C2.22656 7.58412 3.72474 6.08594 5.56641 6.08594H70.4336C72.2753 6.08594 73.7734 7.58412 73.7734 9.42578V56.1836Z" fill="url(#paint0_linear_1926_518)" />
    <path d="M71.5469 51.7305V9.42578C71.5469 8.81095 71.0484 8.3125 70.4336 8.3125H5.56641C4.95158 8.3125 4.45312 8.81095 4.45312 9.42578V28.3516C4.45312 28.9664 4.95158 29.4648 5.56641 29.4648C6.18123 29.4648 6.67969 28.9664 6.67969 28.3516V10.5391H69.3203V50.6172H6.67969V32.8047C6.67969 32.1899 6.18123 31.6914 5.56641 31.6914C4.95158 31.6914 4.45312 32.1899 4.45312 32.8047V51.7305C4.45312 52.3453 4.95158 52.8438 5.56641 52.8438H70.4336C71.0484 52.8438 71.5469 52.3453 71.5469 51.7305Z" fill="url(#paint1_linear_1926_518)" />
    <path d="M70.5352 57.293C71.15 57.293 71.6484 56.7945 71.6484 56.1797C71.6484 55.5648 71.15 55.0664 70.5352 55.0664C69.9203 55.0664 69.4219 55.5648 69.4219 56.1797C69.4219 56.7945 69.9203 57.293 70.5352 57.293Z" fill="url(#paint2_linear_1926_518)" />
    <path d="M66.082 57.293C66.6969 57.293 67.1953 56.7945 67.1953 56.1797C67.1953 55.5648 66.6969 55.0664 66.082 55.0664C65.4672 55.0664 64.9688 55.5648 64.9688 56.1797C64.9688 56.7945 65.4672 57.293 66.082 57.293Z" fill="url(#paint3_linear_1926_518)" />
    <path d="M23.8096 33.209C22.4341 26.6106 25.985 20.017 32.2529 17.5312C37.82 15.3231 44.0461 16.9432 47.8401 21.3552L45.0636 20.9437C44.5228 20.8657 44.0174 21.2374 43.9372 21.7786C43.8567 22.3201 44.2309 22.824 44.7728 22.9045L49.8851 23.6617C49.9335 23.6689 49.982 23.6725 50.0304 23.6725C50.2418 23.6725 50.4497 23.6047 50.6213 23.4775C50.8327 23.321 50.9733 23.087 51.0112 22.8265L51.7682 17.7182C51.8487 17.1767 51.4746 16.6727 50.9327 16.5926C50.3916 16.5149 49.8865 16.8863 49.8063 17.4281L49.4052 20.134C45.0731 15.0345 37.9161 13.153 31.5218 15.6888C24.3552 18.5308 20.2954 26.0694 21.8676 33.6131C21.9648 34.081 22.3774 34.4023 22.8374 34.4023C22.9044 34.4023 22.9728 34.3957 23.0408 34.3813C23.5775 34.2696 23.9218 33.7447 23.8099 33.209L23.8096 33.209ZM52.8606 27.1847C52.7488 26.6487 52.221 26.3052 51.6869 26.4166C51.1505 26.5284 50.8065 27.0533 50.9183 27.5895C52.2963 34.1896 48.7449 40.7843 42.4743 43.2694C36.91 45.4761 30.6854 43.8563 26.8906 39.4448L29.6569 39.8544C30.1977 39.9324 30.7031 39.5607 30.7833 39.0195C30.8638 38.478 30.4896 37.9741 29.9477 37.8936L24.8337 37.1364C24.5738 37.0983 24.3087 37.1646 24.0972 37.3209C23.886 37.478 23.7457 37.7119 23.7075 37.9719L22.9517 43.0813C22.8714 43.6228 23.2456 44.1268 23.7878 44.2069C23.8371 44.2141 23.8858 44.2177 23.9339 44.2177C24.4177 44.2177 24.8409 43.8637 24.9139 43.3712L25.3172 40.6493C28.3527 44.231 32.7764 46.2336 37.3576 46.2336C39.3112 46.2336 41.2934 45.8699 43.2064 45.1115C50.3751 42.271 54.4355 34.731 52.8605 27.1845L52.8606 27.1847Z" fill="#6D7EFF" />
    <defs>
      <linearGradient id="paint0_linear_1926_518" x1="76" y1="37.9527" x2="8.27324e-07" y2="37.9527" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint1_linear_1926_518" x1="71.5469" y1="30.5473" x2="4.45313" y2="30.5473" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint2_linear_1926_518" x1="71.6484" y1="56.1781" x2="69.4219" y2="56.1781" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint3_linear_1926_518" x1="67.1953" y1="56.1781" x2="64.9688" y2="56.1781" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
    </defs>
  </svg>
);
