import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { text02 } from '@/common/constants/theme';

import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';
import pageSizes from '@/common/constants/pageSizes';
import TableHead from '../../../../../common/components/Table/components/TableHead';
import TableBody from '../components/Table/components/TableBody';

import {
  SHOW_RIGHT_PANEL,
  usePersonalPageContext,
} from '../../../PersonalPageContext';
import { useLogsContext } from './LogsContext';

import Table from '../components/Table/Table';
import TablePagination from '../components/Table/components/TablePagination';
import LogsTableFilters from './LogsTableFilters';

const SEARCH_FILTER_TIMEOUT = 1000 * 0.75;

const Logs = () => {
  const { getDateTimeToDisplay } = usePersonalPageContext();
  const {
    isLoading,
    groups,
    logs,
    logsCount,
    filter,
    setFilterValues,
    resetFilter,
  } = useLogsContext();

  const { t } = useTranslation('translations');

  const [selectedGroupOption, setSelectedGroupOption] = useState({ key: 0, title: '-' });
  const [localSearch, setLocalSearch] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null);

  const headers = [
    t('table.date'),
    t('table.description'),
  ];

  const applyFilter = (filterObject) => {
    const useFilterObj = {
      d_begin: filterObject.startFilterDate || '',
      d_end: filterObject.endFilterDate || '',
      group: filterObject.groupIdFilter || '',
      page: 1,
    };
    setSelectedGroupOption(groups.find((g) => g.key === filterObject.groupIdFilter)
      || groups.find((x) => x.key === 0));
    setFilterValues(useFilterObj);
  };

  useEffect(() => {
    if ((filter?.q || '') !== localSearch) {
      setLocalSearch(filter?.q || '');
    }
    if (selectedGroupOption.key !== (filter?.group || 0)) {
      setSelectedGroupOption(groups.find((g) => g.key === filter?.group || 0));
    }
  }, [filter]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    if (localSearch.length > 0) {
      setSearchTimeout(setTimeout(() => setFilterValues({ q: localSearch, page: 1 }),
        SEARCH_FILTER_TIMEOUT));
    } else if ((filter?.q || '').length > 0) {
      setFilterValues({ q: '', page: 1 });
    }
  }, [localSearch]);
  return (
    <Container>
      {isLoading && <Loader />}
      <Table>
        <TableHeadWrapper>
          <TableHead
            isFilters
            isSearch
            searchValue={localSearch}
            searchHandler={(val) => setLocalSearch(val)}
            resetHandler={resetFilter}
            isClearFiltersBtn
            CustomFilters={(
              <LogsTableFilters
                startDateValue={filter?.d_begin || ''}
                endDateValue={filter?.d_end || ''}
                applyHandler={applyFilter}
                dropdownOptions={groups}
                dropdownValue={selectedGroupOption}
              />
            )}
          >
            {headers.map((item) => (
              <HeaderTitle
                key={`${item}--LogsTableHeaderItem`}
              >
                {item}
              </HeaderTitle>
            ))}
          </TableHead>
        </TableHeadWrapper>
        {logs && (
          <TableBody
            rowHeight={50}
            minRows={filter?.limit || pageSizes[0]}
            autoRowHeight
          >
            {logs.map((item, i) => (
              <div
                /* eslint-disable-next-line react/no-array-index-key */
                key={`${item.created_at}-${i}--LogsTableRow`}
              >
                <RowItem>
                  {getDateTimeToDisplay(item.created_at)}
                </RowItem>
                <RowItem>
                  {item.message}
                </RowItem>
              </div>
            ))}
          </TableBody>
        )}
        <TablePagination
          page={filter?.page || 1}
          setPage={(p) => setFilterValues({ page: p })}
          pageCount={Math.ceil(logsCount / (filter?.limit || 10))}
          pageLimit={(filter?.limit || 10)}
          setPageLimit={(v) => setFilterValues({ limit: v, page: 1 })}
          customPageSizes={[10, 30, 100]}
        />
      </Table>
      <Wrapper>
        <ButtonWrapper to="/personal-page/devices">
          <StyledButton
            isOutline
            isBoldText
            isUpperCase
          >
            {t('profile.logs.toDevices')}
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default Logs;

const Container = styled.div`
  position: relative;
  ${SHOW_RIGHT_PANEL ? `
    padding: 0 62px 0 0;
  ` : `
    padding: 0;
  `}

  & .table-rows__row__item {
    &:first-child {
      min-width: 110px;

      color: ${text02};
    }

    &:last-child {
      margin-left: 20px;
    }
  }
`;

const ButtonWrapper = styled(Link)``;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 80px;
  margin-bottom: 50px;
`;

const StyledButton = styled(Button)`
  padding: 0 17px;
  width: auto;
`;

const HeaderTitle = styled.div``;

const TableHeadWrapper = styled.div`
  & .table-head:last-child {
    margin-left: 94px;
  }
`;

const RowItem = styled.div``;
