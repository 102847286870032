import RequestService from '../../request.service';
import urls from '../../routes/admin/users-main.api';
import referrals from '../../routes/admin/finance.api';

export { referrals };

export const TASK_STATUSES = {
  success: 'success',
  processed: 'processed',
  error: 'error',
};

export const fetchUsersAction = async () => {
  const { results } = await RequestService(urls.users);
  return results;
};

export const getUserAction = (userId) => RequestService(urls.user(userId));

export const getUsersAction = (filters = {}) => RequestService(
  urls.users,
  { params: filters },
);

export const updateUserAction = (userId, data) => RequestService(
  urls.user(userId), {
    method: 'PATCH',
    body: data,
  },
);

export const addUserGroupAction = (groupName) => RequestService(urls.userGroups, {
  method: 'POST',
  body: {
    name: groupName,
    menu_items: [],
  },
});

export const removeUserGroupAction = (groupId) => RequestService(urls.userGroup(groupId), {
  method: 'DELETE',
});

export const getUserGroupsAction = () => RequestService(urls.userGroups);

export const getGroupUsersAction = (groupId, params = []) => RequestService(`${urls.userGroupAttach(groupId)}?${new URLSearchParams(params)}`);

export const userGroupAttach = (userId, groupId) => RequestService(
  urls.userGroupAttach(groupId), {
    method: 'POST',
    body: {
      id: userId,
    },
  },
);

export const userGroupAttachByEmail = (userEmail, groupId) => RequestService(
  urls.userGroupAttach(groupId), {
    method: 'POST',
    body: {
      email: userEmail,
    },
  },
);

export const userGroupDetach = (userId, groupId) => RequestService(
  urls.userGroupDetach(userId, groupId), {
    method: 'DELETE',
    body: {
      group_id: groupId,
      id: userId,
    },
  },
);

export const setUserGroupPermissionsAction = (groupId, permissionsList) => RequestService(
  urls.userGroup(groupId), {
    method: 'PATCH',
    body: {
      menu_items: permissionsList,
    },
  },
);

export const getUserActiveBlockAction = (userId) => RequestService(urls.userActiveBlock(userId));

export const addUserBlockAction = (userId, blockData) => RequestService(
  urls.userBlock(userId), {
    method: 'POST',
    body: blockData,
  },
);

export const changeUserBlockAction = (userId, blockId, data) => RequestService(
  urls.userBlock(userId, blockId), {
    method: 'PATCH',
    body: data,
  },
);

export const changeUserBlockDescriptionAction = (userId, blockId, newReason) => RequestService(
  urls.userBlock(userId, blockId), {
    method: 'PATCH',
    body: { reason: newReason },
  },
);

export const removeUserBlockAction = (userId, blockId, reason) => RequestService(
  urls.userBlock(userId, blockId), {
    method: 'PATCH',
    body: { canceled: true, cancel_reason: reason },
  },
);

export const getUserDevicesAction = (userId) => RequestService(urls.userDevices(userId));
export const updateUserDeviceAction = async (userId, deviceId, deviceData) => RequestService(
  urls.userDevices(userId, deviceId), {
    method: 'PUT',
    body: deviceData,
  },
);
export const updateUserDeviceResultAction = (userId, deviceId, taskId) => RequestService(
  urls.userDevices(userId, deviceId, taskId),
  { method: 'PUT' },
);
export const getDeviceHistoryAction = (userId, deviceId, filterString = '') => RequestService(
  urls.userDeviceHistory(userId, deviceId, filterString),
);

export const resetDeviceThemeAction = (userId, deviceId, taskId = null) => RequestService(
  urls.resetDeviceTheme(userId, deviceId, taskId),
  { method: 'POST' },
);

export const regenOttIdAction = (userId, deviceId) => RequestService(
  urls.regenOttId(userId, deviceId),
  { method: 'POST' },
);

export const getUserPlayListsAction = (userId) => RequestService(urls.userPlayList(userId));

export const addUserDeviceSubscription = (userId, deviceId, data) => RequestService(
  urls.userDeviceSubscription(userId, deviceId), {
    method: 'POST',
    body: data,
  },
);

export const changeUserBalanceAction = (userId, newBalance, reason = null) => RequestService(
  urls.userBalance(userId),
  { method: 'PATCH', body: { balance: newBalance, reason: reason || 'Admin' } },
);

export const changeUserDiscountAction = (userId, value) => RequestService(
  urls.user(userId),
  { method: 'PATCH', body: { personal_discount_percent: value } },
);

export const changeUserBonusAction = (userId, value) => RequestService(
  urls.user(userId),
  { method: 'PATCH', body: { personal_bonus_percent: value } },
);

export const setChangeUserBalanceReplenishmentAction = (userId, value) => RequestService(
  urls.user(userId),
  { method: 'PATCH', body: { is_balance_replenishment_disable: value } },
);

export const setUserBalanceReplenishmentByBonusAction = (userId, value) => RequestService(
  urls.user(userId),
  { method: 'PATCH', body: { is_balance_replenishment_with_bonus_disable: value } },
);

export const setActiveUserPaymentSystemsAction = (userId, psIds) => RequestService(
  urls.user(userId),
  { method: 'PATCH', body: { user_payment_systems: psIds } },
);

export const getUserActivityLogAction = (userId, filterString) => RequestService(
  urls.userActivityLog(userId, filterString),
);

export const getUserReferralsLinkFollowsAction = (userId, filterString) => RequestService(
  urls.userReferralsLinkFollows(userId, filterString),
);

export const getUserReferralsTransactionsAction = (userId, filterString) => RequestService(
  urls.userReferralsTransactions(userId, filterString),
);

export const getUserPasswordAction = (userId, pin) => RequestService(
  urls.userPassword(userId),
  { method: 'POST', body: { decode_pin: pin } },
);
