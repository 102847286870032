/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { activePrimary, white } from '../../constants/theme';
import PageContainer from '../PageContainer';
import Navigation from './components/Navigation';
import UserActions from './components/UserActions';

const BG_WHITE = 'linear-gradient(rgba(255, 255, 255, .85) 100%, rgba(255, 255, 255, .85) 0%)';
const BG_COLORED = 'linear-gradient(180deg, rgba(92, 112, 207, 0.93) 0%, rgba(63, 80, 159, 0.93) 100%)';

const HeaderShell = ({
  isUserExist, isUserAuth,
  height, minScroll = 0,
}) => {
  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);
  const [bgColor, setBgColor] = useState(BG_COLORED);
  const [fontColor, setFontColor] = useState(white);
  const [isBgShownAlways, setIsBgShownAlways] = useState(false);

  useEffect(() => {
    let bg;
    let font;
    let showBg;

    if (location?.pathname === '/') {
    // homepage
      bg = BG_COLORED;
      font = white;
      showBg = false;
    } else if (isUserExist) {
    // user exists other pages
      bg = BG_COLORED;
      font = white;
      showBg = true;
    } else {
    // user not exists
      bg = BG_WHITE;
      font = activePrimary;
      showBg = false;
    }

    setBgColor(bg);
    setFontColor(font);
    setIsBgShownAlways(showBg);
  }, [isUserExist, location?.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > minScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Header
      useHeight={height}
      className={isScrolled ? 'scrolled' : ''}
      bgColor={bgColor}
      fontColor={fontColor}
      alwaysShowBg={isBgShownAlways}
    >
      <HeaderBg />
      <PageContainer>
        <ContentWrapper>
          <Logo
            to="/"
            user={isUserExist}
          >
            Arlekino.tv
          </Logo>
          <NavigationWrapper>
            <Navigation isUserExist={isUserExist} isUserAuth={isUserAuth} />
            <UserActions isUserExist={isUserExist} isUserAuth={isUserAuth} />
          </NavigationWrapper>
        </ContentWrapper>
      </PageContainer>
    </Header>
  );
};

export default withRouter(HeaderShell);

const ContentWrapper = styled.div`
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  transition: .33s all ease-in-out 0s;
`;

const Logo = styled(Link)`
  background: ${white};
  border-radius: 0 0 9px 9px;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 38px;
  line-height: 36px;
  letter-spacing: -0.04em;
  color: #5378CF;
  padding: 1rem 2rem;
  ${({ user, path }) => ((!user || !path) && `
    box-shadow: 2px 4px 9px rgba(75, 92, 168, 0.2);
  `)};

  &:hover {
    color: #5378CF;
  }
`;

const NavigationWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const HeaderBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  transition: .15s all ease-in-out 0s;
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(${({ useHeight }) => (useHeight || '5rem')} + 20px);
  flex-flow: column nowrap;
  color: ${({ fontColor }) => (fontColor || white)};

  ${HeaderBg} {
    background: ${({ bgColor }) => (bgColor || BG_COLORED)};
    opacity: ${({ alwaysShowBg }) => (alwaysShowBg ? 1 : 0)};
  }

  &.scrolled{
    // height: calc(${({ useHeight }) => (useHeight || '5rem')} + 20px - 1.25rem);
    //
    // ${ContentWrapper} {
    //   padding-bottom: .75rem;
    // }

    ${HeaderBg} {
      opacity: 1;
    }
  }

  z-index: 1000;
  transition: .33s all ease-in-out 0s;
`;
