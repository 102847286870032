import RequestService from '../../request.service';
import urls from '../../routes/admin/groups.api';
import adminUrls from '../../routes/admin/admin.api';

export const getImportDataAction = () => RequestService(adminUrls.getImportData, { method: 'POST' });
export const handleValidateDataAction = (data) => RequestService(adminUrls.validateImportData, {
  method: 'POST',
  body: data,
});
export const handleImportDataAction = (data) => RequestService(adminUrls.importData, {
  method: 'POST',
  body: data,
});

// eslint-disable-next-line max-len
export const getGroupsAction = (offset, pageLimit) => RequestService(urls.groupsList(offset, pageLimit));

export const getAllGroupsAction = () => RequestService(urls.groupsAllList());

export const deleteGroupByIdAction = (id) => RequestService(urls.groupsItem(id), { method: 'DELETE' });

export const createGroupAction = (data) => RequestService(urls.groupsAllList(), {
  method: 'POST',
  body: data,
});

export const updateGroupByIdAction = (id, data) => RequestService(urls.groupsItem(id), {
  method: 'PUT',
  body: data,
});

export const deleteAllGroupsAction = () => RequestService(urls.groupsList(0), { method: 'DELETE' });

export const moveGroupAction = (id, prevGroupId) => RequestService(urls.groupsMove(id), {
  method: 'POST',
  body: { prev_group_id: prevGroupId },
});
