import React from 'react';

import styled from 'styled-components';

import { text01, white, red } from '@/common/constants/theme';

import ArrowIcon from '@/assets/icons/arrow-down-black.svg';

const EditServerFieldsBlock = (
  {
    fields,
    inputValues,
    errors,
    handleInput,
    handleInputLostFocus,
    selectOptionText,
    requiredText,
  },
) => (
  <ColumnWrapper>
    {fields.map(({
      title,
      key,
      required,
      isDropdown,
      options = [],
    }) => {
      if (isDropdown) {
        return (
          <Wrapper key={`${key}_dropdown`} title={`${title} ${required ? `(${requiredText})` : ''}`}>
            <RequiredSign show={required} title={requiredText} />
            <Text>{title}</Text>
            <Select
              value={inputValues[key]}
              onChange={({ target }) => { handleInput(key, target.value); }}
              onMouseUp={handleInputLostFocus}
              invalid={errors[key]}
            >
              <option disabled={inputValues[key]}>{selectOptionText}</option>
              {options.map(({ value, name }) => (
                <option key={value} value={value}>{name || value}</option>
              ))}
            </Select>
          </Wrapper>
        );
      }
      return (
        <Wrapper key={`${key}_input`} title={`${title} ${required ? `(${requiredText})` : ''}`}>
          <RequiredSign show={required} />
          <Text>{title}</Text>
          <Input
            value={inputValues[key]}
            onChange={({ target }) => handleInput(key, target.value)}
            onKeyUp={handleInputLostFocus}
            invalid={errors[key]}
          />
        </Wrapper>
      );
    })}
  </ColumnWrapper>
);

export default EditServerFieldsBlock;

const Wrapper = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const ColumnWrapper = styled.div`
  float: left;
  margin-right: 2rem;
`;

const Text = styled.div`
  margin-bottom: 12px;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: ${text01};
  letter-spacing: 0.16px;
`;

const Input = styled.input`
  height: 32px;
  width: 360px;
  padding: 0 10px;

  background: ${white};
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  outline: none;
  ${({ invalid }) => invalid && 'border: 1px solid #FF1744;'}
`;

const RequiredSign = styled.div`
  position: absolute;
  top: 24px;
  left: 3px;
  display: ${({ show }) => (show ? 'block' : 'none')};

  &::after{
    content: '*';
    width: 10px;
    height: 10px;
    color: ${red};
  }
`;

const Select = styled.select`
  height: 32px;
  width: 360px;
  padding: 0 10px;

  background: ${white};
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position-x: calc(100% - 13px);
  background-position-y: 13px;
  ${({ invalid }) => invalid && 'border: 1px solid #FF1744;'}
`;
