import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

import { useSelector } from 'react-redux';
import { getStoreWithdrawalSystems, getStoreWithdrawalWallets } from '@/store/user/selectors';

import {
  getReferralsStatisticsAction,
  balanceTransferAction,
  createReferralsWithdrawalAction,
} from '@/services/actions/referrals.actions';

export const ReferralsContext = createContext({});

export const ReferralsContextProvider = ({ children }) => {
  const prevMonth = new Date();
  prevMonth.setMonth(prevMonth.getMonth() - 1);
  prevMonth.setDate(prevMonth.getDate() + 1);

  const INIT_FILTERS = {
    startDate: prevMonth,
    endDate: new Date(),
    pageLimit: 10,
    page: 1,
  };

  const storedWithdrawalSystems = useSelector(getStoreWithdrawalSystems);
  const storedWallets = useSelector(getStoreWithdrawalWallets);

  const [datePageLimitFilter, setDatePageLimitFilter] = useState(INIT_FILTERS);
  const [isFilterSet, setIsFilterSet] = useState(false);

  const [referralsStatistics, setReferralsStatistics] = useState();
  const [withdrawalSystems, setWithdrawalSystems] = useState([]);
  const [withdrawalWallets, setWithdrawalWallets] = useState([]);

  const getPageCount = (referralsCount) => Math.max(Math.ceil(
    referralsCount / (datePageLimitFilter.pageLimit || 10),
  ), 1);

  const getReferralsStatistics = () => {
    const { startDate, endDate } = datePageLimitFilter;
    if (!startDate || !endDate) {
      return;
    }
    getReferralsStatisticsAction(startDate, endDate)
      .then((res) => {
        setReferralsStatistics(res);
      });
  };

  const applyFilter = ({ start, end }) => {
    const isDatesChanged = (datePageLimitFilter.startDate !== start)
      || (datePageLimitFilter.endDate !== end);
    setDatePageLimitFilter(
      (prevState) => ({
        ...prevState,
        startDate: start,
        endDate: end,
        page: isDatesChanged ? 1 : (prevState?.page || 1),
      }),
    );
    setIsFilterSet((start !== INIT_FILTERS.startDate) || (end !== INIT_FILTERS.endDate));
  };

  const resetFilter = () => {
    applyFilter({ start: INIT_FILTERS.startDate, end: INIT_FILTERS.endDate });
  };

  const setPageLimit = (v) => {
    const updData = { pageLimit: v };
    if (datePageLimitFilter.pageLimit < v) {
      updData.page = 1;
    }
    setDatePageLimitFilter((prevState) => ({ ...prevState, ...updData }));
  };

  const balanceTransfer = (amount) => (
    balanceTransferAction(amount)
  );

  const createReferralsWithdrawal = (withdrawalSystemId, walletId, walletIdentifier, amount) => (
    createReferralsWithdrawalAction(withdrawalSystemId, walletId, walletIdentifier, amount)
  );

  useEffect(() => {
    if (storedWithdrawalSystems) {
      setWithdrawalSystems(storedWithdrawalSystems);
    }
  }, [storedWithdrawalSystems]);

  useEffect(() => {
    if (storedWallets) {
      const useWallets = (storedWallets || [])
        .filter((w) => (!w.is_hidden && w.is_verified))
        .filter((w) => !w.withdrawal_system?.is_disabled);
      setWithdrawalWallets(useWallets);
    }
  }, [storedWallets]);

  useEffect(() => {
    getReferralsStatistics();
  }, [datePageLimitFilter]);

  const value = {
    datePageLimitFilter,
    getPageCount,
    setPageLimit,
    isFilterSet,
    applyFilter,
    resetFilter,
    referralsStatistics,
    getReferralsStatistics,
    balanceTransfer,
    withdrawalSystems,
    withdrawalWallets,
    createReferralsWithdrawal,
  };

  return (
    <ReferralsContext.Provider value={value}>
      {children}
    </ReferralsContext.Provider>
  );
};

export const useReferralsContext = () => useContext(ReferralsContext);
