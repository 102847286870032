import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  green,
  primary,
  text01,
  white,
} from '../../../../../../common/constants/theme';
import IconCopy from '../../../../../../assets/icons/copyicon.svg';

const ReferralsLinkCard = ({ title, link }) => {
  const { t } = useTranslation(['translations']);
  let currentLink = link;
  if (currentLink.length > 53) {
    currentLink = `${currentLink.slice(0, 46)}...`;
  }

  const copiedValue = `${currentLink.slice(0, 30)}...`;
  const linkId = `${Math.random()}-link`;

  const copyText = (elementId) => {
    const range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
  };

  return (
    <Container>
      <HiddenLink id={linkId}>{link}</HiddenLink>
      <Text>{title}</Text>
      {' '}
      <Wrapper>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link>{currentLink}</Link>
        <Tooltip
          autoAdjustOverflow
          arrowPointAtCenter
          placement="topRight"
          overlayClassName="green-tooltip"
          trigger={['click']}
          title={`${copiedValue} ${t('linkCopied')}`}
          color={green}
        >
          <Icon
            src={IconCopy}
            width={14}
            onClick={() => copyText(linkId)}
            alt=""
          />
        </Tooltip>
      </Wrapper>
    </Container>
  );
};

export default ReferralsLinkCard;

const Container = styled.div`
  width: 100%;
  padding: 14px 26px 20px 26px;

  background: ${white};
  border: 1px dashed rgba(92, 112, 207, 0.5);
  border-radius: 5px;
  box-shadow: 9px 11px 20px rgba(0, 0, 0, 0.11);
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.div`
  margin-bottom: 10px;

  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const Link = styled.div`
  cursor: default;

  font-family: Gilroy;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  color: ${primary};
  line-height: 15px;
  letter-spacing: 0.16px;
`;

const HiddenLink = styled.div`
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

const Icon = styled.img`
  display: block;
  margin-left: 10px;

  position: relative;
  bottom: 2px;

  cursor: pointer;
`;
