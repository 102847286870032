import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import styled from 'styled-components';
import { parse, isValid, format } from 'date-fns';
import { ru } from 'date-fns/locale';

import {
  text03,
  white, red,
  inputBorder,
  text01,
} from '@/common/constants/theme';
import DatePicker from '@/common/components/DatePicker';
import CalendarIcon from '../../icons/CalendarIcon';
import { useAppContext } from '@/AppContext';

const DATE_FORMAT = 'dd.MM.yyyy';
const DATE_REGEX_MASK = /(?<d>\d{1,2})\.(?<m>\d{1,2})\.(?<y>\d{2,4})/;
const DATE_INPUT_MASK = '99.99.9999';

const PeriodDatePicker = ({
  onDatesChanged,
  startDateValue,
  endDateValue,
  editableDates = false,
}) => {
  const { isDatesEqualByDate } = useAppContext();

  const [isStartCalendarOpened, setIsStartCalendarOpened] = useState(false);
  const [isEndCalendarOpened, setIsEndCalendarOpened] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedStartDateString, setSelectedStartDateString] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedEndDateString, setSelectedEndDateString] = useState();
  const [pickerStartValue, setPickerStartValue] = useState();
  const [pickerEndValue, setPickerEndValue] = useState();
  const [isChanged, setIsChanged] = useState(false);

  const dateToString = (d) => (d ? format(d, DATE_FORMAT) : '');
  const stringToDate = (s) => {
    let retVal = null;
    if (((s?.length || 0) > 0) && (new RegExp(DATE_REGEX_MASK)).test(s)) {
      retVal = parse(s, 'P', new Date(), { locale: ru });
    }
    return isValid(retVal) ? retVal : null;
  };

  const checkDateIsCorrect = (d) => {
    const retVal = ((d?.length || 0) > 0
      && (new RegExp(DATE_REGEX_MASK)).test(d)
      && (stringToDate(d)) !== null);
    return retVal;
  };

  const checkDatesEqual = () => setIsChanged(
    (selectedStartDate ? !isDatesEqualByDate(startDateValue, selectedStartDate, false) : false)
    || (selectedEndDate ? !isDatesEqualByDate(endDateValue, selectedEndDate, false) : false),
  );

  useEffect(() => {
    setSelectedStartDate(startDateValue);
    setSelectedStartDateString(dateToString(startDateValue));
    setPickerStartValue(startDateValue);
  }, [startDateValue]);

  useEffect(() => {
    setSelectedEndDate(endDateValue);
    setSelectedEndDateString(dateToString(endDateValue));
    setPickerEndValue(endDateValue);
  }, [endDateValue]);

  useEffect(() => {
    checkDatesEqual();
  }, [startDateValue, endDateValue, selectedEndDate, selectedStartDate]);

  useEffect(() => {
    if (onDatesChanged) {
      onDatesChanged({ isChanged, start: selectedStartDate, end: selectedEndDate });
    }
  }, [selectedEndDate, selectedStartDate]);

  return (
    <Wrapper flex>
      {editableDates ? (
        <StyledInputControl
          mask={DATE_INPUT_MASK}
          value={selectedStartDateString}
          onChange={({ target }) => {
            setSelectedStartDateString(target.value);
            if (checkDateIsCorrect(target.value)) {
              const tDate = stringToDate(target.value);
              setSelectedStartDate(tDate);
              setPickerStartValue(tDate);
            }
          }}
          isError={selectedStartDate && !checkDateIsCorrect(selectedStartDateString)}
        />
      ) : (
        <StyledDiv onClick={() => setIsStartCalendarOpened(true)}>
          {selectedStartDate
            ? format(new Date(selectedStartDate), 'd.MM.yyyy')
            : ''}
        </StyledDiv>
      )}
      <DatePicker
        isOpen={isStartCalendarOpened}
        value={pickerStartValue}
        maxDate={selectedEndDate}
        onChange={setPickerStartValue}
        onApply={(value) => {
          const vDate = new Date(value);
          setSelectedStartDate(vDate);
          setSelectedStartDateString(dateToString(vDate));
        }}
        onClose={() => setIsStartCalendarOpened(false)}
        applyButtonDisabled={isDatesEqualByDate(pickerStartValue, selectedStartDate, false)}
      />
      <Icon
        topMargin
        rightMargin
        onClick={() => setIsStartCalendarOpened(true)}
      >
        <CalendarIcon />
      </Icon>
      <Text rightMargin>-</Text>
      {editableDates ? (
        <StyledInputControl
          mask={DATE_INPUT_MASK}
          value={selectedEndDateString}
          onChange={({ target }) => {
            setSelectedEndDateString(target.value);
            if (checkDateIsCorrect(target.value)) {
              const tDate = stringToDate(target.value);
              setSelectedEndDate(tDate);
              setPickerEndValue(tDate);
            }
          }}
          isError={selectedEndDate && !checkDateIsCorrect(selectedEndDateString)}
        />
      ) : (
        <StyledDiv
          onClick={() => setIsEndCalendarOpened(true)}
        >
          {selectedEndDate
            ? format(new Date(selectedEndDate), 'd.MM.yyyy')
            : ''}
        </StyledDiv>
      )}
      <DatePicker
        isOpen={isEndCalendarOpened}
        value={pickerEndValue}
        minDate={selectedStartDate}
        onChange={setPickerEndValue}
        onApply={(value) => {
          const vDate = new Date(value);
          setSelectedEndDate(vDate);
          setSelectedEndDateString(dateToString(vDate));
        }}
        onClose={() => setIsEndCalendarOpened(false)}
        applyButtonDisabled={isDatesEqualByDate(pickerEndValue, selectedEndDate, false)}
      />
      <Icon
        topMargin
        onClick={() => setIsEndCalendarOpened(true)}
      >
        <CalendarIcon />
      </Icon>
    </Wrapper>
  );
};

export default PeriodDatePicker;

const Wrapper = styled.div`
  ${({ flex }) => flex && (`
    display: flex;
    align-items: center;
  `)}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}

  ${({ topMargin }) => topMargin && ('margin-top: 12px;')}
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 35px;')}
  ${({ bottomMarginLight }) => bottomMarginLight && ('margin-bottom: 9px;')}

  & .main-datepicker {
    left: -90px;
    bottom: -20px;
  }
`;

const Text = styled.div`
  ${({ rightMargin }) => rightMargin && ('margin-right: 11px;')};

  cursor: ${({ pointer, disabled }) => (pointer && !disabled ? 'pointer' : 'default')};

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 17px;
  color: ${text03};
`;

const Icon = styled.div`
  ${({ topMargin }) => topMargin && (`
    position: relative;
    top: 8px;
  `)};
  ${({ rightMargin }) => rightMargin && ('margin-right: 12px;')};
  margin-left: 10px;

  cursor: pointer;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 90px;
  height: 32px;

  background: ${white};
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: ${text01};
`;

const StyledInputControl = styled(InputMask)`
  width: 7rem !important;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  box-sizing: border-box;
  padding: .25rem .75rem;
  color: ${text01};
  background: ${white};
  cursor: pointer;
  height: 28px;
  border-radius: 2px;
  outline: none;
  border: 1px solid ${({ isError }) => (isError ? red : '#EFEEF3')};
  transition: all 0.15s ease-in 0s;

  &:read-only {
    opacity: 0.9;
    cursor: auto;
    border: 1px solid transparent;
  }

  &&:focus {
    box-shadow: none;
    border: 1px solid #c0c6e0;
  }
`;
