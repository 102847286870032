import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import styled from 'styled-components';
import { green, primary, red } from '@/common/constants/theme';

import ModalSubmit from '@/common/components/ModalSubmit';
import SnackBarWindow from '@/common/components/SnackBarWindow';

const OrderSubscriptionDialog = ({
  data,
  setData,
  onCloseSuccessWindow,
  onSubmit,
  isSubscriptionDone,
  subscriptionResultSum,
}) => {
  const { t } = useTranslation('translations');

  const renderTFootRow = (label, tFootData, color = null) => {
    const lStyle = { textAlign: 'right', paddingRight: '10px' };
    const rStyle = { textAlign: 'right', paddingRight: '20px' };
    if (color) {
      rStyle.color = color;
      lStyle.color = color;
    }
    return (
      <tr>
        <th colSpan={2} style={lStyle}>{label}</th>
        <th colSpan={2} style={rStyle}>{tFootData}</th>
      </tr>
    );
  };

  return (
    <>
      <ModalSubmit
        width="800px"
        isOpen={data?.status}
        type={data?.status || 'error'}
        title={t('subscriptionsContent.buySubscription')}
        onCloseModal={() => setData({})}
        onSubmit={onSubmit}
        submitButton={data?.status === 'success' ? t('subscriptionsContent.submit') : t('subscriptionsContent.close')}
      >
        {data?.errorText && (
          <ErrorText>
            {data.errorText}
          </ErrorText>
        )}
        {data?.items && (
          <div>
            <div>
              {t('subscriptionsContent.items')}
            </div>
            <table style={{ fontSize: '8pt', width: '100%' }}>
              <thead>
                <Tr style={{ borderBottom: '1px solid silver' }}>
                  <th>{t('subscriptionsContent.deviceName')}</th>
                  <th>{t('subscriptionsContent.deviceDateEnd')}</th>
                  <th>{t('subscriptionsContent.deviceDaysLeft')}</th>
                  <th style={{ textAlign: 'right', paddingRight: '20px' }}>{t('subscriptionsContent.deviceAmount')}</th>
                  <th>{t('subscriptionsContent.deviceMessage')}</th>
                  <th> </th>
                </Tr>
              </thead>
              <tbody>
                {data.items.map((item) => (
                  <Tr item={item} key={`${item.device_id}--SOL-item`}>
                    <td>{item.device_name}</td>
                    <td>
                      {item.date_end && dayjs(item.date_end).format('DD.MM.YYYY') }
                    </td>
                    <td>{item?.days_amount || ''}</td>
                    <td style={{ textAlign: 'right', paddingRight: '20px' }}>{item.amount.toFixed(2)}</td>
                    <td>{item.message_type && t(`subscriptionsContent.${item.message_type}`)}</td>
                    <td style={{ textAlign: 'center' }}>{!item.is_processed && t('subscriptionsContent.deviceBlock')}</td>
                  </Tr>
                ))}
              </tbody>
              <tfoot>
                {(((data?.promocode_discount || 0) > 0)
                  || ((data?.discount || 0) > 0)
                  || ((data?.personal_discount || 0) > 0)
                ) && renderTFootRow(
                  t('subscriptionsContent.total'),
                  (data.items.map((i) => i.amount).reduce((a, b) => a + b, 0))
                    .toFixed(2),
                )}
                {(data?.promocode_discount || 0) > 0
                  && renderTFootRow(
                    t('subscriptionsContent.discountPromo'),
                    data.promocode_discount.toFixed(2),
                    red,
                  )}
                {(data?.personal_discount || 0) > 0
                  && renderTFootRow(
                    t('subscriptionsContent.discountPersonal'),
                    data.personal_discount.toFixed(2),
                    primary,
                  )}
                {(data?.discount || 0) > 0
                  && renderTFootRow(
                    t('subscriptionsContent.discountFinal'),
                    data.discount.toFixed(2),
                    green,
                  )}
                {renderTFootRow(
                  t('subscriptionsContent.toPay'),
                  (data.amount > 0 ? data.amount : 0).toFixed(2),
                )}
              </tfoot>
            </table>
            <div style={{ color: data?.is_processed ? green : red, fontWeight: 'bold', padding: '10px 0' }}>
              {data?.message_type && t(`subscriptionsContent.${data?.message_type}`, data?.message_params)}
            </div>
          </div>
        )}
      </ModalSubmit>
      <SnackBarWindow
        type="success"
        isOpen={isSubscriptionDone}
        onClose={() => onCloseSuccessWindow()}
        content={t('subscriptionsContent.done', [subscriptionResultSum])}
      />
    </>
  );
};

export default OrderSubscriptionDialog;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2
  }
  th{
    text-align: left;
    width: 100px;
  }
  td:last-child {
    background-color: ${(props) => (props.item && !props.item.is_processed ? red : '')};
  }
`;

const ErrorText = styled.div`
  color: ${red};
  font-size: 14px;
  text-align: left;
`;
