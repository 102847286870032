import RequestService from '../../request.service';
import urls from '../../routes/admin/subscription.api';

export const createSubscriptionAction = (userId, deviceId, data) => RequestService(
  urls.userDeviceSubscriptionCreate(userId, deviceId),
  { method: 'POST', body: data },
);

export const setSubscriptionAction = (userId, deviceId, dateString) => RequestService(
  urls.userDeviceSubscriptionSet(userId, deviceId),
  { method: 'POST', body: { date_end: dateString } },
);

export const cancelSubscriptionAction = (userId, deviceId, type) => RequestService(
  urls.userDeviceSubscriptionCancel(userId, deviceId),
  { method: 'POST', body: { type } },
);
