/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  text01,
  activeTertiary,
  primary,
  borderSecondary,
  lightRed,
  white,
  brightGreen, grey,
} from '@/common/constants/theme';

import { sanitizeFloat } from '@/common/utils/sanitize.utils';

import EditIcon from '@/common/components/icons/EditIcon';
import LockIcon from '@/common/components/icons/LockIcon';
import envelopeIcon from '@/assets/icons/envelope.svg';
import walletIcon from '@/assets/icons/wallet.svg';
import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Checkbox from '@/common/components/Checkbox';
import DatePickerNew, { DATE_FIELDS } from '@/common/components/DatePickerNew';
import useOutsideAlerter from '@/common/utils/useOutsideAlerter.util';
import Dropdown from '@/common/components/Dropdown';
import DropdownCheckBoxed from '@/common/components/DropdownCheckBoxed';
import OkIcon from '../../../../../../../icons/OkIcon';
import UsersMainUserInfoMail from './components/MainUserInfoMail';
import Loader from '@/common/components/Loader';

import MainUserInfoBlock from './components/MainUserInfoBlock';

import {
  DATA_TYPES,
  useUsersMainContext,
} from '../../../../MainContext';

import {
  DATE_FORMAT,
  useAdminContext,
} from '../../../../../../../../AdminContext';

const ACTIONS = {
  blockUser: 'blockUser',
  changeBlock: 'changeBlock',
  unBlockUser: 'unBlockUser',
};

const REASON_TEXTS = {
  [ACTIONS.blockUser]: { label: 'Укажите причину блокировки', placeholder: 'Причина блокировки' },
  [ACTIONS.changeBlock]: { label: 'Скорректируйте причину блокировки', placeholder: 'Причина блокировки' },
  [ACTIONS.unBlockUser]: { label: 'Укажите причину разблокировки', placeholder: 'Причина разблокировки' },
};

const ACTION_TEXTS = {
  [ACTIONS.blockUser]: 'Заблокировать',
  [ACTIONS.changeBlock]: 'Изменить',
  [ACTIONS.unBlockUser]: 'Разблокировать',
};

const EDIT_FIELDS = {
  email: 'email',
  password: 'password',
  country: 'country',
  groups: 'groups',
  discount: 'discount',
  bonus: 'bonus',
};

const EDIT_TIMEOUT = 1000 * 1.5;

const NO_PASSWORD_GETTED = 'Пароль пользователя не получен';
const PASSWORD_PIN_CHECK_COUNT_EXHAUSTED = 'Попытки проверки PIN исчерпаны,\nсчетчик будет сброшен после\n{expDate}';

const NO_GROUP = 'Вне групп';
const DEFAULT_PASSWORD = '******';
const SUPER_ADMIN = 'Суперадминистратор';

const UsersMainUserInfo = ({ user, supportView, updateUserDataFn }) => {
  const { t } = useTranslation('translations');
  const { formatDateWithZone } = useAdminContext();

  const {
    countries,
    paymentSystems,
    userGroups,

    dataProcessing,

    getUserActiveBlock,
    blockUser,
    changeBlockUser,
    unBlockUser,
    updateUser,
    updateUserGroups,
    updateUserBalance,
    updateUserDiscount,
    updateUserBonus,
    setUserReplenishmentStatus,
    setUserReplenishmentByBonusStatus,
    setActiveUserPaymentSystems,
    setPasswordPinCheckExpireDate,

    getUserPassword,
    sendEmailToUser,

    storedPasswordPinCheckExpDate,
  } = useUsersMainContext();

  const [isUserBlockLoaded, setIsUserBlockLoaded] = useState(false);

  const [userGroupsOptions, setUserGroupsOptions] = useState([]);
  const [localUser, setLocalUser] = useState(user);
  const [action, setAction] = useState(null);
  const [actionText, setActionText] = useState('');
  const [userBlock, setUserBlock] = useState(null);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [isChangeBalanceModal, setIsChangeBalanceModal] = useState(false);
  const [balanceValue, setBalanceValue] = useState('');
  const [balanceDescr, setBalanceDescr] = useState('');
  const [isAllowBonus, setIsAllowBonus] = useState(false);
  const [disabledBlockDates, setDisabledBlockDates] = useState([]);
  const [blockDates, setBlockDates] = useState({
    [DATE_FIELDS.start]: new Date(),
    [DATE_FIELDS.end]: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
  });
  const [blockForever, setBlockForever] = useState(false);
  const [blockText, setBlockText] = useState(null);
  const [editedField, setEditedField] = useState('');
  const [editedValue, setEditedValue] = useState('');
  const [isBalanceBanned, setIsBalanceBanned] = useState(false);
  const [isBonusBanned, setIsBonusBanned] = useState(false);
  const [isMailModal, setIsMailModal] = useState(false);

  const [isPasswordShowModal, setIsPasswordShowModal] = useState(false);
  const [pin, setPin] = useState('');
  const [displayedPassword, setDisplayedPassword] = useState('');
  const [isPasswordGetted, setIsPasswordGetted] = useState(false);
  const [isPinChecking, setIsPinChecking] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isPinCheckEnabled, setIsPinCheckEnabled] = useState(true);
  const [pinCheckCounts, setPinCheckCounts] = useState(null);

  const [saveTimeout, setSaveTimeout] = useState(null);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const countryRef = useRef(null);
  const discountRef = useRef(null);
  const bonusRef = useRef(null);
  const roleRef = useRef(null);

  useOutsideAlerter(emailRef, () => setEditedField(''));
  useOutsideAlerter(passwordRef, () => setEditedField(''));
  useOutsideAlerter(countryRef, () => setEditedField(''));
  useOutsideAlerter(discountRef, () => setEditedField(''));
  useOutsideAlerter(bonusRef, () => setEditedField(''));
  useOutsideAlerter(roleRef, () => setEditedField(''));

  const getUserGroupTitle = () => {
    const maxDisplayGroups = 3;
    const userGroupsTitles = (localUser?.admin_group_ids || [])
      .map((ug) => ((userGroups.find((ugo) => ugo.id === ug) || {})?.name));
    const joinPart = userGroupsTitles.length > maxDisplayGroups
      ? userGroupsTitles.slice(0, maxDisplayGroups) : userGroupsTitles;
    const hidePartCount = userGroupsTitles.length > maxDisplayGroups
      ? userGroupsTitles.length - maxDisplayGroups : 0;
    return userGroupsTitles.length > 0
      ? `${joinPart.join(', ')}${hidePartCount > 0 ? '...' : ''}`
      : NO_GROUP;
  };

  const handleBlockUser = () => {
    const data = {};
    if ((blockText?.length || 0) === 0) { return; }
    switch (action) {
      case ACTIONS.blockUser:
        blockUser(localUser.id, blockText, blockDates)
          .then(() => {
            setAction(null);
            setBlockText(null);
          });
        break;
      case ACTIONS.changeBlock:
        data.reason = blockText;
        if (format(blockDates[DATE_FIELDS.start], DATE_FORMAT)
          !== localUser.block_actual?.block_at) {
          data.block_at = format(blockDates[DATE_FIELDS.start], DATE_FORMAT);
        }
        if (format(blockDates[DATE_FIELDS.end], DATE_FORMAT)
          !== localUser.block_actual?.expires_at) {
          data.expires_at = format(blockDates[DATE_FIELDS.start], DATE_FORMAT);
        }
        changeBlockUser(localUser.id, userBlock.id, data)
          .then(() => {
            setAction(null);
            setBlockText(null);
          });
        break;
      case ACTIONS.unBlockUser:
        unBlockUser(localUser.id, userBlock.id, blockText)
          .then(() => {
            setAction(null);
            setBlockText(null);
            setUserBlock(null);
          });
        break;
      default:
        break;
    }
  };

  const handleSubmitEditedField = () => {
    if (((editedValue?.length || 0) === 0) && !editedValue?.key) { return; }
    const updateData = {};
    let fieldValue = 0;
    switch (editedField) {
      case EDIT_FIELDS.email:
        updateData.email = editedValue;
        break;
      case EDIT_FIELDS.password:
        updateData.password = editedValue;
        break;
      case EDIT_FIELDS.country:
        updateData.country = editedValue.key;
        break;
      case EDIT_FIELDS.discount:
        fieldValue = parseFloat(editedValue.replace('%', ''));
        updateUserDiscount(
          localUser.id,
          fieldValue >= 0 ? fieldValue : 0,
        ).then(() => {
          setLocalUser((prevState) => ({ ...prevState, personal_discount_percent: fieldValue }));
        });
        return;
      case EDIT_FIELDS.bonus:
        fieldValue = parseFloat(editedValue.replace('%', ''));
        updateUserBonus(
          localUser.id,
          fieldValue >= 0 ? fieldValue : 0,
        ).then(() => {
          setLocalUser((prevState) => ({ ...prevState, personal_bonus_percent: fieldValue }));
        });
        return;
      default:
        return;
    }
    updateUser(localUser.id, updateData)
      .then(() => {
        setLocalUser((prevState) => ({ ...prevState, ...updateData }));
      });
  };

  const handleBalanceInputChanged = ({ target }) => {
    const { value } = target;
    let preparedValue = sanitizeFloat(value);
    const sign = preparedValue.startsWith('+') || preparedValue.startsWith('-') ? preparedValue.substr(0, 1) : '';
    preparedValue = preparedValue.replaceAll('+', '').replaceAll('-', '');
    setBalanceValue(`${sign}${preparedValue}`);
  };

  const handleChangeUserBalance = () => {
    const isValueSigned = balanceValue.replaceAll('+', '').replaceAll('-', '') !== balanceValue;
    // eslint-disable-next-line no-useless-escape
    const parsedValue = parseFloat(balanceValue.replace(/[^\d+-\.]/, ''));
    const newBalance = isValueSigned ? ((localUser?.balance || 0) + parsedValue) : parsedValue;
    updateUserBalance(localUser.id, newBalance > 0 ? newBalance : 0, balanceDescr)
      .then(() => updateUserDataFn({ balance: newBalance }));
  };

  const handleChangeUserGroups = (newGroupsOptions) => {
    setEditedField('');
    updateUserGroups(
      localUser.id,
      newGroupsOptions.map((go) => go.key),
      localUser?.admin_group_ids || [],
      localUser.email,
    );
  };

  const handleSendEMail = (data) => sendEmailToUser(data);

  const handleChangeUserBalanceRefillEnable = (checked) => {
    setUserReplenishmentStatus(checked)
      .then(setIsBalanceBanned(checked));
  };

  const handleChangeUserBalanceRefillByBonusEnable = (checked) => {
    setUserReplenishmentByBonusStatus(checked)
      .then(setIsBonusBanned(checked));
  };

  const handleChangePaymentSystemDisabled = (psId, enabled) => {
    localUser.user_payment_systems[psId] = enabled;
    if (saveTimeout) { clearTimeout(saveTimeout); }
    setSaveTimeout(setTimeout(
      () => { setActiveUserPaymentSystems(localUser.user_payment_systems); },
      EDIT_TIMEOUT,
    ));
  };

  const handleCheckPasswordPin = () => {
    setIsPinChecking(true);
    getUserPassword(pin)
      .then((res) => {
        const isPasswordGettedLocal = !!res?.raw_password;
        let resText;
        let expDate;
        if (isPasswordGettedLocal) {
          resText = res.raw_password;
          expDate = new Date((new Date()).getTime() - 1000 * 60 * 60);
        } else {
          if ((res?.data?.message_type === 'raw_password_decode_pin_attempts_count_is_exhausted')
          && (res?.data?.message || []).length === 2) {
            setPinCheckCounts({
              current: res.data.message[0],
              max: res.data.message[1],
            });
          }
          const messageParams = {
            ...(res?.data?.message_params || {}),
            resetDate: formatDateWithZone(),
          };
          resText = (res?.data?.message_type
            ? t(`admin.users.${res.data.message_type}`, messageParams) : NO_PASSWORD_GETTED);
          expDate = new Date((new Date()).getTime() + 1000 * 60 * 60 * 24);
        }
        setIsPasswordGetted(isPasswordGettedLocal);
        setDisplayedPassword(resText);
        setPasswordPinCheckExpireDate(expDate);
      })
      .finally(() => {
        setPin('');
        setIsPinChecking(false);
      });
  };

  const getPasswordCheckPinCountExhaustedMessage = () => PASSWORD_PIN_CHECK_COUNT_EXHAUSTED
    .replace(/{expDate}/, formatDateWithZone(storedPasswordPinCheckExpDate));

  const getPinCheckCountMessage = () => {
    const checksRemain = pinCheckCounts.max - pinCheckCounts.current;
    return checksRemain <= 0 ? 'Попыток не осталось' : `Попыток осталось: ${checksRemain}`;
  };

  useEffect(() => {
    setUserGroupsOptions(
      userGroups.map((ug) => ({
        key: ug.id,
        title: ug.name,
      })),
    );
  }, [userGroups]);

  useEffect(() => {
    if (user) {
      setLocalUser(user);
      getUserActiveBlock(user.id)
        .then((res) => {
          if (res) {
            setUserBlock(res);
            setBlockText(res?.reason);
          }
          setIsUserBlockLoaded(true);
        });
      setIsBalanceBanned(user.is_balance_replenishment_disable);
      setIsBonusBanned(user.is_balance_replenishment_with_bonus_disable);
    }
  }, [user]);

  useEffect(() => {
    setActionText(action ? ACTION_TEXTS[action] : '');
  }, [action]);

  useEffect(() => {
    setDisabledBlockDates(blockForever ? [DATE_FIELDS.end] : []);
    setBlockDates(
      (prevState) => (
        {
          ...prevState,
          [DATE_FIELDS.end]: blockForever
            // ? new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 100)
            ? null
            : '',
        }
      ),
    );
  }, [blockForever]);

  // Для проверки без кнопки (по вводу)
  // useEffect(() => {
  //   if ((pin || '').length === 0) { return; }
  //   if (pinCheckTimeout) { clearTimeout(pinCheckTimeout); }
  //   setPinCheckTimeout(setTimeout(() => handleCheckPasswordPin(), PIN_CHECK_TIMEOUT));
  // }, [pin]);

  useEffect(() => {
    // const isDateNotExp = (new Date()).getTime() - moment
    //   .parseZone(storedPasswordPinCheckExpDate).valueOf() > 0;
    // setIsPinCheckEnabled(isDateNotExp);
  }, [storedPasswordPinCheckExpDate]);

  const renderPasswordShow = () => (
    <>
      <Icon
        style={{ width: '19px' }}
        onClick={() => {
          setPin('');
          setDisplayedPassword('');
          setIsPasswordShowModal(true);
        }}
      >
        <LockIcon color={primary} />
      </Icon>
      <ModalWithoutContent
        color="normal"
        isOpen={isPasswordShowModal}
        onCloseModal={() => setIsPasswordShowModal(false)}
      >
        {!isPinCheckEnabled ? (
          <PasswordShowCountExhausted>
            {getPasswordCheckPinCountExhaustedMessage()}
          </PasswordShowCountExhausted>
        ) : (
          <>
            <PasswordShowCaption>
              {((displayedPassword || '').length === 0) ? 'Введите PIN' : 'Пароль'}
            </PasswordShowCaption>
            {pinCheckCounts && (
              <PasswordShowComment>
                {getPinCheckCountMessage()}
              </PasswordShowComment>
            )}
            <PasswordShowBody>
              {((displayedPassword || '').length === 0) ? (
                <>
                  <PinInput
                    value={pin}
                    onChange={({ target }) => setPin(target.value)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleCheckPasswordPin();
                      }
                    }}
                    disabled={isPinChecking}
                  />
                  <PinSubmitButton
                    disabled={((pin || '').length === 0) || isPinChecking}
                    onClick={() => handleCheckPasswordPin()}
                    style={{ marginTop: '1em' }}
                  >
                    Проверить
                  </PinSubmitButton>
                </>
              ) : (
                <PinText isError={!isPasswordGetted}>
                  {displayedPassword}
                </PinText>
              )}
            </PasswordShowBody>
          </>
        )}
      </ModalWithoutContent>
    </>
  );

  const renderTextField = (
    FieldName, FieldType, FieldRef, displayValue, additionalRender = null,
  ) => (
    <Wrapper flex alignCenter={editedField === FieldType} bottomMargin={14}>
      <Text bold rightMargin={10}>
        {`${FieldName}:`}
      </Text>
      {editedField === FieldType ? (
        <Wrapper ref={FieldRef} flex alignCenter>
          <Input
            value={editedValue}
            onChange={({ target }) => setEditedValue(target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmitEditedField();
              }
            }}
            rightMargin
            width={240}
          />
          <Icon
            onClick={handleSubmitEditedField}
          >
            <OkIcon />
          </Icon>
        </Wrapper>
      ) : (
        <Wrapper flex>
          <Text rightMargin={10}>
            {displayValue}
          </Text>
          <Icon onClick={() => {
            setEditedField(FieldType);
            setEditedValue(displayValue);
          }}
          >
            <EditIcon />
          </Icon>
          {additionalRender && additionalRender()}
        </Wrapper>
      )}
    </Wrapper>
  );

  return (
    <Container>
      <UsersMainUserInfoMail
        isOpen={isMailModal}
        onClose={() => setIsMailModal(false)}
        onSubmit={handleSendEMail}
      />
      <Wrapper flex alignCenter bottomMargin={10}>
        {dataProcessing?.[DATA_TYPES.userBlock] && <Loader />}
        {!supportView && isUserBlockLoaded && (
          <Wrapper>
            {!userBlock ? (
              <BlockButton
                onClick={() => {
                  setAction(ACTIONS.blockUser);
                  setBlockText('');
                  setIsBlockModal(true);
                }}
                className="users-block-trigger"
              >
                {ACTION_TEXTS[ACTIONS.blockUser]}
              </BlockButton>
            ) : (
              <UnblockControlContainer>
                <UnblockButton
                  onClick={() => {
                    setAction(ACTIONS.unBlockUser);
                    setBlockText(user?.block_actual?.cancel_reason || '');
                    setIsBlockModal(true);
                  }}
                  className="users-unblock-trigger"
                >
                  {ACTION_TEXTS[ACTIONS.unBlockUser]}
                </UnblockButton>
                <BlockReasonContainer>
                  <BlockReasonCaption>Заблокирован по причине:</BlockReasonCaption>
                  <BlockReasonValue>{userBlock?.reason || 'не указана'}</BlockReasonValue>
                  <Icon
                    noBottom
                    onClick={() => {
                      const now = new Date();
                      const blockAt = new Date(userBlock.block_at);
                      const expiredAt = new Date(userBlock.expires_at || '');
                      setAction(ACTIONS.changeBlock);
                      setBlockText(userBlock.reason);
                      setBlockDates({
                        [DATE_FIELDS.start]: blockAt,
                        [DATE_FIELDS.end]: expiredAt.getTime() < now.getTime()
                          ? new Date(now.getTime() + 1000 * 60 * 60 * 24 * 7)
                          : expiredAt,
                      });
                      setIsBlockModal(true);
                    }}
                    title={ACTION_TEXTS[ACTIONS.changeBlock]}
                  >
                    <EditIcon />
                  </Icon>
                </BlockReasonContainer>
              </UnblockControlContainer>
            )}
            {isBlockModal && (
              <MainUserInfoBlock
                left={action === ACTIONS.changeBlock ? 'calc(50% - 200px)' : '0'}
                onClose={() => setIsBlockModal(false)}
              >
                {[ACTIONS.blockUser, ACTIONS.changeBlock].includes(action) && (
                  <>
                    <Label marginTop={0.0001}>Период блокировки</Label>
                    <BlockPeriodContainer>
                      <BlockPeriodItem>
                        <DatePickerNew
                          onSubmit={(v) => setBlockDates(
                            {
                              [DATE_FIELDS.start]: v.dateStart,
                              [DATE_FIELDS.end]: v.dateEnd,
                            },
                          )}
                          values={blockDates}
                          disableInputs={disabledBlockDates}
                        />
                      </BlockPeriodItem>
                      <BlockPeriodItem>
                        <Checkbox
                          label="Навсегда"
                          checked={blockForever}
                          onChange={(v) => setBlockForever(v)}
                        />
                      </BlockPeriodItem>
                    </BlockPeriodContainer>
                  </>
                )}
                <Label>{REASON_TEXTS?.[action]?.label || '-'}</Label>
                <Textarea
                  value={blockText}
                  onChange={({ target }) => setBlockText(target.value)}
                  placeholder={REASON_TEXTS?.[action]?.placeholder || ''}
                />
                <Wrapper flex>
                  <Button
                    disabled={(
                      ((blockText?.length || 0) === 0)
                      || ((action === ACTIONS.changeBlock)
                        && (blockText === userBlock.reason))
                      || (action === ACTIONS.blockUser
                        && (!blockDates?.[DATE_FIELDS.start]?.getMonth
                        || !(blockDates?.[DATE_FIELDS.end]?.getMonth || blockForever)))
                    )}
                    color={lightRed}
                    onClick={() => {
                      setIsBlockModal(false);
                      handleBlockUser();
                    }}
                  >
                    {actionText}
                  </Button>
                </Wrapper>
              </MainUserInfoBlock>
            )}
          </Wrapper>
        )}
        {isUserBlockLoaded && !userBlock && (
          <Wrapper flex rightMargin={30}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {(localUser?.is_staff || false) || (localUser?.is_superuser || false) ? (
              <Text rightMargin={10}>
                {SUPER_ADMIN}
              </Text>
            ) : userGroupsOptions && editedField === EDIT_FIELDS.groups ? (
              <Wrapper ref={roleRef}>
                <DropdownCheckBoxed
                  options={userGroupsOptions}
                  onSelect={(option) => setEditedValue(option)}
                  value={editedValue || userGroupsOptions.filter(
                    (ugo) => localUser.admin_group_ids.includes(ugo.key),
                  )}
                  onSubmit={handleChangeUserGroups}
                />
              </Wrapper>
            ) : (
              <>
                <Text rightMargin={10}>
                  {getUserGroupTitle()}
                </Text>
                {!supportView && (
                  <Icon
                    onClick={() => {
                      setEditedField(EDIT_FIELDS.groups);
                      setEditedValue(userGroupsOptions.filter(
                        (ugo) => (localUser?.admin_group_ids || []).includes(ugo.key),
                      ));
                    }}
                  >
                    <EditIcon />
                  </Icon>
                )}
              </>
            )}
          </Wrapper>
        )}
        <Wrapper flex onClick={() => setIsMailModal(true)}>
          <Img
            width={30}
            height={20}
            src={envelopeIcon}
          />
          <Text
            bold
            primaryColor
            leftMargin={4}
            pointer
            upperCase
          >
            Написать
          </Text>
        </Wrapper>
      </Wrapper>
      <MainContent>
        <Wrapper>
          {dataProcessing?.[DATA_TYPES.userInfo] && <Loader />}
          <Wrapper flex bottomMargin={10}>
            <Text rightMargin={10}>
              Дата регистрации:
            </Text>
            <Text bold>
              {localUser?.date_joined ? formatDateWithZone(localUser.date_joined) : ''}
            </Text>
          </Wrapper>
          <Wrapper flex bottomMargin={30}>
            <Text rightMargin={10}>
              По реферальной ссылке:
            </Text>
            <Text primaryColor>
              {localUser.register_referral_link && <Link to={localUser.register_referral_link}>{`${window.location.origin}${localUser.register_referral_link}`}</Link>}
            </Text>
          </Wrapper>
          {renderTextField('Почта', EDIT_FIELDS.email, emailRef, localUser.email)}
          {renderTextField('Пароль', EDIT_FIELDS.password, passwordRef, localUser?.password || DEFAULT_PASSWORD, !supportView && renderPasswordShow)}
          <Wrapper flex alignCenter={editedField === EDIT_FIELDS.country}>
            <Text bold rightMargin={10}>
              Страна:
            </Text>
            {editedField === EDIT_FIELDS.country ? (
              <Wrapper ref={countryRef} flex alignCenter>
                <Wrapper rightMargin={10}>
                  <Dropdown
                    showSearch
                    options={countries}
                    value={editedValue}
                    onSelect={(option) => setEditedValue(option)}
                  />
                </Wrapper>
                <Icon
                  onClick={() => handleSubmitEditedField()}
                >
                  <OkIcon />
                </Icon>
              </Wrapper>
            ) : (
              <Wrapper flex>
                <Text rightMargin={10}>
                  {localUser.country}
                </Text>
                <Icon onClick={() => {
                  setEditedField(EDIT_FIELDS.country);
                  setEditedValue(countries.find((country) => country.key === localUser.country));
                }}
                >
                  <EditIcon />
                </Icon>
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
        <Wrapper topMargin={10}>
          <Wrapper flex bottomMargin={30}>
            <Img
              width={30}
              height={25}
              src={walletIcon}
            />
            <BalanceText bold leftMargin={10} rightMargin={10}>
              Баланс:
            </BalanceText>
            <BalanceText rightMargin={10}>
              {`$${(localUser?.balance || 0).toFixed(2)}`}
            </BalanceText>
            {isChangeBalanceModal ? (
              <MainUserInfoBlock onClose={() => setIsChangeBalanceModal(false)}>
                <Wrapper flex alignCenter bottomMargin={16}>
                  <Text rightMargin={8}>
                    Сумма
                  </Text>
                  <Input
                    value={balanceValue}
                    onChange={handleBalanceInputChanged}
                    onBlur={handleBalanceInputChanged}
                    title="Введите сумму. Сумма со знаком (+ или -) будет рассматриваться как относительное изменение текущего баланса."
                    placeholder="+5"
                  />
                </Wrapper>
                <Wrapper bottomMargin={16}>
                  <Textarea
                    value={balanceDescr}
                    onChange={({ target }) => setBalanceDescr(target.value)}
                    placeholder="Описание"
                  />
                </Wrapper>
                <Wrapper flex flexSb>
                  <Checkbox
                    label="Учитывать бонус"
                    checked={isAllowBonus}
                    onChange={(checked) => setIsAllowBonus(checked)}
                  />
                  <Button
                    color={brightGreen}
                    onClick={() => {
                      handleChangeUserBalance();
                      setIsChangeBalanceModal(false);
                    }}
                  >
                    Изменить баланс
                  </Button>
                </Wrapper>
              </MainUserInfoBlock>
            ) : (
              <Icon onClick={() => setIsChangeBalanceModal(true)}>
                <EditIcon />
              </Icon>
            )}
          </Wrapper>
          {renderTextField('Скидка', EDIT_FIELDS.discount, discountRef, `${localUser?.personal_discount_percent || 0}%`)}
          {renderTextField('Бонус', EDIT_FIELDS.bonus, bonusRef, `${localUser?.personal_bonus_percent || 0}%`)}
          {!supportView && (
            <>
              <Wrapper bottomMargin={12}>
                <Checkbox
                  label="Запретить пополнение баланса"
                  checked={isBalanceBanned}
                  onChange={handleChangeUserBalanceRefillEnable}
                />
              </Wrapper>
              <Wrapper>
                <Checkbox
                  label="Не пополнять баланс по бонусной программе"
                  checked={isBonusBanned}
                  onChange={handleChangeUserBalanceRefillByBonusEnable}
                />
              </Wrapper>
            </>
          )}
        </Wrapper>
        <Wrapper width={220}>
          {(paymentSystems || [])
            .filter((item) => !item.is_disabled)
            .map((item) => (
              <Checkbox
                key={`${item.id}--usersMainInfo-PaySystemCheckBox`}
                label={item.title}
                checked={localUser?.user_payment_systems?.[item.id] || false}
                onChange={(status) => handleChangePaymentSystemDisabled(item.id, status)}
              />
            ))}
        </Wrapper>
      </MainContent>
    </Container>
  );
};

export default UsersMainUserInfo;

const Container = styled.div`
  font-family: Gilroy, sans-serif !important;
  position: relative;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && 'display: flex;'}
  ${({ flexSb }) => flexSb && 'justify-content: space-between;'}
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`}
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}
  ${({ topMargin }) => topMargin && `margin-top: ${topMargin}px;`}
`;

const MainContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  padding: 20px 12px 10px 20px;

  border: 1px solid ${borderSecondary};
  border-radius: 4px;
`;

const Label = styled.div`
  margin: ${({ marginTop }) => (marginTop || 0.5)}rem 0 ${({ marginBottom }) => (marginBottom || 0.25)}rem;
`;

const BlockButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 32px;
  margin-right: 20px;

  position: relative;

  background-color: transparent;
  border: 1px solid ${activeTertiary};
  border-radius: 2px;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${activeTertiary};
  text-transform: uppercase;
  line-height: 24px;
`;

const UnblockButton = styled(BlockButton)`
  border: 1px solid ${lightRed};
  color: ${lightRed};
`;

const Text = styled.div`
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`}
  ${({ leftMargin }) => leftMargin && `margin-left: ${leftMargin}px;`}

  ${({ pointer }) => pointer && 'cursor: pointer;'}

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  ${({ bold }) => bold && 'font-weight: 600;'}
  color: ${({ primaryColor }) => (primaryColor ? primary : text01)};
  ${({ upperCase }) => upperCase && 'text-transform: uppercase;'}
  line-height: 15px;
`;

const BalanceText = styled(Text)`
  font-size: 20px;
`;

const Icon = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  position: relative;
  ${({ noBottom }) => (!noBottom && 'bottom: 2px;')}

  & svg {
    ${({ disabled }) => (disabled && `fill: ${grey}`)};
  }
`;

const Img = styled.img`
  cursor: pointer;

  position: relative;
  bottom: 3px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 0 12px;
  margin-bottom: 10px;

  background: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  outline: none;
  resize: none;

  color: ${text01};
`;

const Button = styled.button`
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: 0 27px;

  background-color: transparent;
  border: 1px solid ${({ color, disabled }) => (!disabled ? color : grey)};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ color, disabled }) => (!disabled ? color : grey)};
  text-transform: uppercase;
  line-height: 24px;

  transition: all .25s ease-in-out 0s;
`;

const Input = styled.input`
  width: ${({ width }) => width || 100}px;
  height: 32px;
  padding: 0 12px;
  ${({ rightMargin }) => rightMargin && 'margin-right: 10px;'}

  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  outline: none;
`;

const BlockPeriodContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

const BlockPeriodItem = styled.div`
  display: flex;
  align-items: center;
`;

const UnblockControlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const BlockReasonContainer = styled.div`
  display: flex;
  margin-right: 2rem;
`;

const BlockReasonCaption = styled.div`
  margin-right: .75rem;
  color: ${lightRed};
  font-weight: bold;
`;

const BlockReasonValue = styled.div`
  max-width: 380px;
  overflow-x: clip;
  white-space: nowrap;
  margin-right: 0.5rem;

  &::after{
    display: block;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2rem;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }
`;

const PasswordShowCaption = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 1.5rem;
  line-height: 1.25em;
`;

const PasswordShowComment = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: .85rem;
  line-height: 1.25em;
`;

const PasswordShowBody = styled.div`
  margin-top: .5rem;
  width: 100%;
  font-family: Gilroy, sans-serif;
  font-size: 2rem;
  line-height: 1.25em;
`;

const PinInput = styled.input`
  width: 100%;
  padding: .5em .75em;

  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  outline: none;

  text-align: center;
  color: ${text01};
`;

const PinText = styled.div`
  width: 100%;
  padding: .5em .75em;
  text-align: center;
  white-space: pre-line;
  ${({ isError }) => (isError && 'font-size: 1.1rem;')}
  line-height: 1.25em;
  color: ${white};
`;

const PasswordShowCountExhausted = styled.div`
  width: 100%;
  padding: .5em .75em;
  font-size: 1rem;
  text-align: center;
  color: ${white};
  white-space: pre-line;
`;
const PinSubmitButton = styled(Button)`
  ${({ disabled }) => (disabled && 'background: rgba(255,255,255,0.33);')}
`;
