import RequestService from '../../request.service';
import urls from '../../routes/admin/ministra.api';

export const getAllMinistraAction = () => RequestService(urls.ministraList);

export const createMinistraAction = (data) => RequestService(urls.ministraList, {
  method: 'POST',
  body: data,
});

export const getMinistraAction = (id) => RequestService(urls.ministraItem(id));

export const updateMinistraAction = (id, data) => RequestService(urls.ministraItem(id), {
  method: 'PUT',
  body: data,
});

export const deleteMinistraAction = (id, newServerId) => RequestService(urls.ministraItem(id), {
  method: 'DELETE',
  body: { server_id: newServerId },
});

export const ministraUpdateEPGAction = (id) => RequestService(urls.ministraUpdateEPG(id), {
  method: 'POST',
});

// eslint-disable-next-line max-len
export const ministraCompareChannelsAction = (id) => RequestService(
  urls.ministraCompareChannels(id), {
    method: 'POST',
  },
);

// eslint-disable-next-line max-len
export const ministraGetCompareChannelsResultAction = (id, taskId) => RequestService(
  urls.ministraCompareChannelsResult(id, taskId), {
    method: 'POST',
  },
);

export const ministraCompareChannelsGetStatusAction = (id, taskId) => RequestService(
  urls.ministraCompareChannels(id), {
    method: 'POST',
    body: { task_id: taskId },
  },
);

export const ministraSyncChannelsAction = (id) => RequestService(
  urls.ministraSyncChannels(id), {
    method: 'POST',
  },
);

export const ministraGetSyncChannelsResultAction = (id, taskId) => RequestService(
  urls.ministraSyncChannelsResult(id, taskId), {
    method: 'POST',
  },
);
