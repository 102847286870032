import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Loader from '@/common/components/Loader';
import UsersMainUserHeader from './components/MainUserHeader';
import UsersMainUserInfo from './components/MainUserInfo/MainUserInfo';
import MainUserDevices from './components/MainUserDevices/MainUserDevices';
import MainUserStats from './components/MainUserStats';

import { SnackTypes } from '@/AppContext';
import {
  useAdminContext,
} from '../../../../../../AdminContext';
import {
  BASE_URL,
  DATA_TYPES,
  useUsersMainContext,
} from '../../MainContext';
import { isNumber } from 'lodash';

const UsersMainUser = ({ supportView }) => {
  const { id } = useParams();
  const history = useHistory();

  const {
    loadUser,
    setAdminSnack,
  } = useAdminContext();

  const {
    dataProcessing,
    setDataProcessing,
    userDevices,
    getUserDevices,
    getUserPlayLists,
    setSelectedUser,
    // users,
  } = useUsersMainContext();

  const [selectedUserId, setSelectedUserId] = useState(0);
  const [user, setUser] = useState(null);

  const updateUserLocal = (data) => {
    if (!user) {
      return;
    }
    setUser((prevState) => ({ ...prevState, ...data }));
  };

  useEffect(() => {
    const parsedId = parseInt(id, 10);
    setSelectedUserId(isNumber(parsedId) ? parsedId : 0);
  }, [id]);

  useEffect(() => {
    if ((selectedUserId > 0)
      && (user?.id || 0) !== selectedUserId) {
      setDataProcessing(
        (prevState) => ({ ...prevState, [DATA_TYPES.user]: true }),
      );
      loadUser(selectedUserId)
        .then((res) => {
          setUser(res);
          setSelectedUser(res);
          getUserDevices(selectedUserId);
          getUserPlayLists(selectedUserId);
        })
        .catch((err) => {
          setAdminSnack({
            content: (err?.data?.errors?.detail
              || err?.data?.message_type
              || 'Неизвестная ошибка'),
            type: SnackTypes.error,
          });
          history.location.replace(BASE_URL);
        })
        .finally(() => setDataProcessing(
          (prevState) => ({ ...prevState, [DATA_TYPES.user]: false }),
        ));
    }
  }, [selectedUserId]);

  return (
    <Container>
      {dataProcessing?.[DATA_TYPES.users] && (<Loader />)}
      {user && (
        <>
          <UsersMainUserHeader email={user.email} />
          <UsersMainUserInfo
            user={user}
            supportView={supportView}
            updateUserDataFn={updateUserLocal}
          />
          <MainUserDevices devices={userDevices} />
          <MainUserStats user={user} />
        </>
      )}
    </Container>
  );
};

export default UsersMainUser;

const Container = styled.div`
  padding-bottom: 100px;
`;
