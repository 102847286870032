import React, {
  useEffect, useRef, useState,
} from 'react';
import { format, sub } from 'date-fns';
import { getProgress, getDuration, getCurrentTime } from '../utils';
import { useTranslation } from 'react-i18next';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';

import styled from 'styled-components';
import {
  text01,
  text03,
  primary,
  borderSecondary,
  secondaryHover,
  white,
  secondary,
} from '@/common/constants/theme';

import PlayIcon from '../../../icons/PlayIcon';
import EllipsesList2Icon from '../../../icons/EllipsesList2Icon';
import ProgressBar from './ProgressBar';
import TrackVisibility from './TrackVisibility';
import StreamIcon from '@/assets/icons/stream.svg';

import { useAppContext } from '@/AppContext';
import { usePlayerContext } from '../PlayerContext';

const locales = { ru, en };

// Ограничение вывода дней архива, 0 - не ограничивать.
const PROGRAM_HISTORY_DEPTH = 0;
// Число дней программы в постоянно видимой части
const VISIBLE_EPG_DAYS_COUNT = 4;
const DATE_INNER_FORMAT = 'yyyy-MM-dd';
const DATE_DISPLAY_FORMAT = 'EEEEEE. d MMMM';
const DAYS_ARCHIVED_WORDS = ['forNFDays', 'forNSDays', 'forNTDays'];
const DAYS_TYPES = {
  archived: 'archived',
  visible: 'visible',
  hidden: 'hidden',
};

const ProgramGuide = () => {
  const { t } = useTranslation('translations');
  const {
    userInfo,
    wordByNumber,
  } = useAppContext();
  const {
    playedProgram,
    changeProgram,
    selectedProgram,
    currentEpg,
  } = usePlayerContext();

  const [epgByDate, setEpgByDate] = useState({});
  const [daysArchived, setDaysArchived] = useState(0);
  const [daysCounts, setDaysCounts] = useState({
    [DAYS_TYPES.archived]: 0,
    [DAYS_TYPES.visible]: 0,
    [DAYS_TYPES.hidden]: 0,
  });
  const [visibleEpgByDatePart, setVisibleEpgByDatePart] = useState({});
  const [hiddenEpgByDatePart, setHiddenEpgByDatePart] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isHiddenListOpen, setIsHiddenListOpen] = useState(false);
  const [activeDate, setActiveDate] = useState(format(new Date(), DATE_INNER_FORMAT));
  const [selectedMoreDaysDate, setSelectedMoreDaysDate] = useState(null);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (!isHiddenListOpen) {
          return;
        }
        if ((ref.current && !ref.current.contains(event.target))
        ) {
          event.preventDefault();
          setIsHiddenListOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, isHiddenListOpen]);
  };
  const hiddenListRef = useRef(null);
  useOutsideAlerter(hiddenListRef);

  useEffect(() => {
    if (selectedProgram && Object.keys(epgByDate || {}).length) {
      const date = format(new Date(selectedProgram.time * 1000), DATE_INNER_FORMAT);
      const el = document.getElementById(`${date} - ${selectedProgram.time}`);
      const list = document.getElementById('list');
      list.scrollTop = el.offsetTop - list.offsetTop;
    }
  }, [epgByDate, selectedProgram]);

  useEffect(() => {
    setLoading(true);
    const data = {};
    (currentEpg || []).forEach((program) => {
      const programDate = format(new Date(program.time * 1000), DATE_INNER_FORMAT);
      if (data[programDate]) {
        data[programDate].push(program);
      } else {
        data[programDate] = [program];
      }
    });
    const currentDate = format(new Date(), DATE_INNER_FORMAT);
    if (data[currentDate]) {
      const dataForRender = { [currentDate]: [...data[currentDate]] };
      const dataVisiblePartEpg = { [currentDate]: [...data[currentDate]] };
      const dataHiddenPartEpg = { };
      const maxArchiveDays = PROGRAM_HISTORY_DEPTH > 0
        ? PROGRAM_HISTORY_DEPTH : Object.keys(data || {}).length;
      for (let i = 0; i < maxArchiveDays; i += 1) {
        const date = format(sub(new Date(), { days: 1 + i }), DATE_INNER_FORMAT);
        if (data[date]) {
          dataForRender[date] = [...data[date]];
          (i < VISIBLE_EPG_DAYS_COUNT
            ? dataVisiblePartEpg : dataHiddenPartEpg)[date] = dataForRender[date];
        }
      }
      setLoading(false);
      setEpgByDate(dataForRender);
      setVisibleEpgByDatePart(dataVisiblePartEpg);
      setHiddenEpgByDatePart(dataHiddenPartEpg);
      setDaysArchived(Object.keys(dataForRender).length - 1);

      setDaysCounts({
        [DAYS_TYPES.archived]: Object.keys(dataForRender).length - 1,
        [DAYS_TYPES.visible]: Object.keys(dataVisiblePartEpg).length,
        [DAYS_TYPES.hidden]: Object.keys(dataHiddenPartEpg).length,
      });
    }
  }, [currentEpg]);

  const getTime = (time) => format(new Date(time * 1000), 'HH:mm');

  const scrollTo = (date) => {
    setActiveDate(date);
    document.getElementById(date).scrollIntoView({ behavior: 'smooth' });
  };

  if (isLoading) {
    return null;
  }

  // const locale = localStorage.getItem('locale');
  const locale = userInfo?.frontend_language || 'en';
  const today = format(new Date(), DATE_INNER_FORMAT);
  return (
    <Container>
      <DatesRow>
        {daysCounts[DAYS_TYPES.archived] > 0 && (
          <Title>
            {`${t('player.archive')} ${daysCounts[DAYS_TYPES.archived] > 1
              ? t(`player.daysArchive.${wordByNumber(daysCounts[DAYS_TYPES.archived], DAYS_ARCHIVED_WORDS)}`, { count: daysCounts[DAYS_TYPES.archived] })
              : t('player.daysArchive.for1Day')}`}
          </Title>
        )}
        {daysCounts[DAYS_TYPES.hidden] === 1 && (
          <Day
            key={`nav-${Object.keys(hiddenEpgByDatePart || {})[0]}`}
            onClick={() => {
              setSelectedMoreDaysDate(Object.keys(hiddenEpgByDatePart || {})[0]);
              scrollTo(Object.keys(hiddenEpgByDatePart || {})[0]);
            }}
            isActive={activeDate === Object.keys(hiddenEpgByDatePart || {})[0]}
          >
            {format(new Date(Object.keys(hiddenEpgByDatePart)[0]),
              DATE_DISPLAY_FORMAT, { locale: locales[locale] })}
          </Day>
        )}
        {daysCounts[DAYS_TYPES.hidden] > 1 && (
          <MoreDays
            ref={hiddenListRef}
            title={t(`player.daysMore.${wordByNumber(daysCounts[DAYS_TYPES.hidden], DAYS_ARCHIVED_WORDS)}`, { count: daysCounts[DAYS_TYPES.hidden] })}
          >
            <MoreDaysLabelContainer
              onClick={() => setIsHiddenListOpen((prev) => !prev)}
            >
              <MoreDaysLabel
                isListOpen={isHiddenListOpen}
                isActive={hiddenEpgByDatePart[activeDate] || false}
                isSelected={selectedMoreDaysDate}
              >
                {selectedMoreDaysDate
                  ? format(new Date(selectedMoreDaysDate),
                    DATE_DISPLAY_FORMAT, { locale: locales[locale] })
                  : t(`player.daysMore.${wordByNumber(daysCounts[DAYS_TYPES.hidden], DAYS_ARCHIVED_WORDS)}`, { count: daysCounts[DAYS_TYPES.hidden] })}
              </MoreDaysLabel>
              <MoreDaysIcon>
                <EllipsesList2Icon
                  /* eslint-disable-next-line no-nested-ternary */
                  color={isHiddenListOpen ? secondary
                    : (hiddenEpgByDatePart[activeDate] ? text03 : primary)}
                />
              </MoreDaysIcon>
            </MoreDaysLabelContainer>
            <MoreDaysList
              isOpen={isHiddenListOpen}
            >
              {Object.keys(hiddenEpgByDatePart || {}).map((date) => (
                <Day
                  key={`nav-${date}`}
                  onClick={() => {
                    setSelectedMoreDaysDate(date);
                    scrollTo(date);
                    setIsHiddenListOpen(false);
                  }}
                  isActive={activeDate === date}
                >
                  {format(new Date(date), DATE_DISPLAY_FORMAT, { locale: locales[locale] })}
                </Day>
              ))}
            </MoreDaysList>
          </MoreDays>
        )}
        {Object.keys(visibleEpgByDatePart || {}).reverse().map((date) => (
          date === today ? (
            <Today
              key={`nav-${date}`}
              onClick={() => {
                setSelectedMoreDaysDate(null);
                scrollTo(date);
              }}
              isActive={activeDate === date}
              oneElement={daysArchived === 0}
            >
              {t('player.today')}
            </Today>
          ) : (
            <Day
              key={`nav-${date}`}
              onClick={() => scrollTo(date)}
              isActive={activeDate === date}
            >
              {format(new Date(date), DATE_DISPLAY_FORMAT, { locale: locales[locale] })}
            </Day>
          )
        ))}
      </DatesRow>
      <List id="list">
        {Object.keys(epgByDate || {}).reverse().map((date) => (
          <TrackVisibility
            key={`${date}-list`}
            cb={() => {
              setActiveDate(date);
              setSelectedMoreDaysDate(hiddenEpgByDatePart[date] ? date : null);
            }}
          >
            <DateWrapper id={date} key={date}>
              {format(new Date(date), DATE_DISPLAY_FORMAT, { locale: locales[locale] })}
            </DateWrapper>
            {(epgByDate[date] || []).map((program) => (
              <Programm
                key={`${date} - ${program?.time}`}
                id={`${date} - ${program?.time}`}
              >
                <Row>
                  <TitleContainer
                    isActive={playedProgram?.time === program?.time}
                    onClick={() => changeProgram(program)}
                    disabled={program?.time * 1000 > new Date()}
                  >
                    <IconWrapper>
                      {playedProgram?.time === program?.time ? (
                        <Icon src={StreamIcon} />
                      ) : (
                        <PlayIcon />
                      )}
                    </IconWrapper>
                    <Text>{`${getTime(program?.time)} - ${getTime(program?.time_to)}`}</Text>
                    <ProgrammTitle>{program?.name}</ProgrammTitle>
                  </TitleContainer>
                  {selectedProgram?.time === program?.time && (
                    <Text>
                      {t('player.timeOfDuration', [getCurrentTime(selectedProgram.time), getDuration(selectedProgram.duration)])}
                    </Text>
                  )}
                </Row>
                {selectedProgram?.time === program?.time && (
                  <StyledProgressBar
                    progress={getProgress(selectedProgram)}
                    isActive
                  />
                )}
                <Description>{program?.descr || ''}</Description>
              </Programm>
            ))}
          </TrackVisibility>
        ))}
      </List>
    </Container>
  );
};

export default ProgramGuide;

const List = styled.div`
  display: flex;
  flex-direction: column;
  height: 25rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 5rem;
`;

const Programm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 .5rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: .5rem;
  margin-bottom: .25rem;
`;

const IconWrapper = styled.div`
  margin-right: .5rem;
  margin-top: 1px;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
`;

const Icon = styled.img``;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${({ isActive }) => (isActive ? secondary : text01)};
  cursor: pointer;
  transition: all 0.25s ease;
  :hover {
    color: ${secondaryHover};
  }
  ${({ disabled }) => disabled && `
    ${IconWrapper} {
      display: none;
    }
    pointer-events: none;
    svg {
      opacity: .5;
    }
  `}
`;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  min-width: 5rem;
`;

const ProgrammTitle = styled(Text)`
  margin: 0 1rem;
`;

const Description = styled(Text)`
  font-weight: normal;
  line-height: 20px;
  color: ${text01}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
`;

const DatesRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .5rem 1.25rem;
  background: ${white};
  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 1.5rem;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  white-space: nowrap;
`;

const Day = styled(Title)`
  font-weight: 600;
  color: ${({ isActive }) => (isActive ? text03 : primary)};
  text-transform: capitalize;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const Today = styled(Day)`
  margin: 0;
  padding: 0 ${({ oneElement }) => (oneElement ? '.5' : '4.5')}rem;
  ${({ oneElement }) => (!oneElement && `border-left: 1px solid ${borderSecondary};`)}
  ${({ oneElement }) => (oneElement && 'text-align: left;')}
`;

const MoreDays = styled.div`
  position: relative;
`;
const MoreDaysLabelContainer = styled.div`
  display: flex;
  align-items: end;

  & > *:not(:last-child) {
    margin-right: 0.25rem;
  }
`;
const MoreDaysLabel = styled(Day)`
  cursor: pointer;
  ${({ isListOpen }) => (isListOpen && `color: ${secondary};`)}
  ${({ isActive }) => (!isActive && 'text-transform: none;')}
  ${({ isSelected, isActive }) => (isSelected && `
    font-weight: 600;
    ${!isActive && `color: ${primary};`}
  `)}
`;

const MoreDaysIcon = styled.div`
  cursor: pointer;
`;

const MoreDaysList = styled.div`
  position: absolute;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  left: -0.5rem;
  background: ${white};
`;

const DateWrapper = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${secondary};
  text-transform: capitalize;
  margin: .5rem;
  margin-bottom: 0;
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: .5rem;
`;
