import RequestService from '@/services/request.service';
import urls from '@/services/routes/admin/statistics.api';

export const getMoneyGeneralDataAction = (filterString) => RequestService(
  urls.moneyGeneral(filterString),
);

export const getMoneyPaymentTransactionsAction = (filterString) => RequestService(
  urls.moneyPaymentTransactions(filterString),
);

export const getMoneyUserBalanceAction = (filterString) => RequestService(
  urls.moneyUserBalance(filterString),
);

export const getUserGeneralDataAction = (filterString) => RequestService(
  urls.userGeneral(filterString),
);

export const getUserUsersDataAction = (filterString) => RequestService(
  urls.userUsers(filterString),
);

export const getUserReferralDataAction = (filterString) => RequestService(
  urls.userReferral(filterString),
);

export const getUserDevicesDataAction = (filterString) => RequestService(
  urls.userDevices(filterString),
);

export const getFinanceDataAction = (filterString) => RequestService(
  urls.financeData(filterString),
);
