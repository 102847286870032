import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';
import PayPalPaymentSystem from '@/screens/PersonalPageScreen/components/PageContent/Balance/components/PayPalPaymentSystem';

const Checked = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z"
      fill="#939393"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7C9.2 7 7 9.2 7 12C7 14.8 9.2 17 12 17C14.8 17 17 14.8 17 12C17 9.2 14.8 7 12 7ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z"
      fill="#F19D38"
    />
  </svg>
);
const UnChecked = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z"
      fill="#939393"
    />
  </svg>

);

const CustomAccordion = (
  {
    isChecked = true,
    handleChecked,
    title,
    text,
    icon,
  },
) => (
  <Accordion $checked={isChecked} onClick={handleChecked}>
    <Header>
      <WrapperTitle>
        {isChecked ? <Checked /> : <UnChecked />}
        <Title>{title}</Title>
      </WrapperTitle>
      <img src={icon} alt={title} />
    </Header>
    <Collapse isOpened={isChecked}>
      {title === 'paypal'
        ? (
          <PayPalPaymentSystem />
        ) : (
          <Text>
            {text}
            <SupportLink as="a" href="https://anypay.io/support">https://anypay.io/support</SupportLink>
          </Text>
        )}

    </Collapse>
  </Accordion>
);

const Accordion = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 8px;
  margin-bottom: 8px;
  ${({ $checked }) => ($checked ? `
     border: 1px solid #F19D38;
     background: #fff;
  ` : `
     border: none;
     background: #F5F5F5;
  `)};

  &:hover {
    cursor: pointer;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WrapperTitle = styled.div`
  display: flex;
  align-items: center;

`;

const Title = styled.div`
  color: #000;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 8px;
`;

const Text = styled.p`
  color: #000;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 2.063rem;
  margin: 12px 0 0;
`;

const SupportLink = styled.a`
  color: #606FC9;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
`;

export default CustomAccordion;
