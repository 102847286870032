import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import styled from 'styled-components';
import {
  secondary,
  // white,
  text01,
} from '@/common/constants/theme';

import ReCaptcha from '@/common/components/ReCaptcha';
import Input from '@/common/components/AuthorizationInput';
import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';
import AuthorizationLayout from '../components/AuthorizationLayout';

import { isEmailValid } from '@/common/utils/emailValidator.utils';

import { forgetPasswordAction } from '@/services/actions/authorization.actions';

function ForgotPasswordContainer() {
  const { t } = useTranslation('translations');
  const [isLoading, setLoading] = useState(false);
  const [isLinkSent, setLinkSent] = useState(false);
  const [email, setEmail] = useState('');
  const [prevEmail, setPrevEmail] = useState('');
  const [emailError, setEmailError] = useState(null);

  const [isHuman, setIsHuman] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');

  const [fingerPrint, setFingerPrint] = useState(null);

  const handleCaptcha = async (v) => {
    setIsHuman(!!v);
    setCaptchaToken(v);
  };

  const sendRequest = () => {
    if (!isHuman) {
      return;
    }
    if (!email) {
      setEmailError(t('authorization.emptyEmail'));
    } else {
      setLoading(true);
      setEmailError(null);

      const data = { email };
      if (captchaToken.length > 0) {
        data.captcha_token = captchaToken;
        data.fingerprint_token = fingerPrint;
      }

      setPrevEmail(email);
      forgetPasswordAction(data)
        .then(() => setLinkSent(true))
        .catch((res) => {
          setEmailError(t(`authorization.forgotPassword.errors.${res.message_type}`));
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!fingerPrint) {
      FingerprintJS.load()
        .then((fp) => fp.get())
        .then((result) => {
          setFingerPrint(result.visitorId || '-');
        })
        .catch(() => { setFingerPrint(null); });
    }
  }, [fingerPrint]);

  return (
    <AuthorizationLayout>
      {isLoading && <Loader />}
      {isLinkSent ? (
        <>
          <Title>
            <TitleText>{t('authorization.forgotPassword.contentTitleBlack1')}</TitleText>
            <TitleTextOrange>{t('authorization.forgotPassword.contentTitleOrange')}</TitleTextOrange>
            <TitleText>{t('authorization.forgotPassword.contentTitleBlack2')}</TitleText>
          </Title>
          <Form>
            <ContentTitle>{t('authorization.forgotPassword.contentTitle')}</ContentTitle>
            <ContentText>{t('authorization.forgotPassword.contentText')}</ContentText>
            <Button onClick={sendRequest}>
              {t('authorization.forgotPassword.contentButton')}
            </Button>
            {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
          </Form>
        </>
      ) : (
        <>
          <Title>
            <TitleText>{t('authorization.forgotPassword.requestTitleBlack1')}</TitleText>
            <TitleTextOrange>{t('authorization.forgotPassword.requestTitleOrange')}</TitleTextOrange>
            <TitleText>{t('authorization.forgotPassword.requestTitleBlack2')}</TitleText>
          </Title>
          <Form>
            <Input
              placeholder="Email"
              value={email}
              onKeyUp={({ target, keyCode }) => {
                if (keyCode === 13) {
                  setEmail(target.value);
                  if ((target.value?.length || 0) > 0) {
                    sendRequest();
                  }
                }
              }}
              onChange={({ target }) => {
                setEmail(target.value);
                if ((prevEmail.length > 0) && (emailError !== null)) {
                  setEmailError(null);
                }
              }}
              errorText={emailError}
            />
            <InputSubText>{t('authorization.forgotPassword.requestText')}</InputSubText>
            {isHuman ? (
              <StyledButton
                isDisabled={!isEmailValid(email) || !!emailError}
                onClick={sendRequest}
              >
                {t('authorization.forgotPassword.requestButton')}
              </StyledButton>
            ) : (
              <ReCaptcha
                appToken={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                onCheck={handleCaptcha}
              />
            )}
          </Form>
        </>
      )}
    </AuthorizationLayout>
  );
}

export default withRouter(ForgotPasswordContainer);

const Title = styled.div`
  margin-top: 10rem;
  width: 40rem;
`;

const TitleText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;
`;

const TitleTextOrange = styled(TitleText)`
  color: ${secondary}
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const ContentTitle = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${text01};
`;

const ContentText = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin: .75rem 0;
  max-width: 25rem;
  white-space: pre-wrap;
`;

const InputSubText = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: rgba(32, 32, 32, 0.6);
  white-space: pre-wrap;
  margin-top: -.5rem;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
  margin-top: 1rem;
`;
