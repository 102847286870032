import React, { useState } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { secondary } from '@/common/constants/theme';
import { MinistraContextProvider } from './MinistraContext';
// eslint-disable-next-line
import MinistraTable from './components/Table';
import MinistraEditServer from './components/EditServer';
import MinistraBottomWindowContainer from './components/BottomWindow/Container';

const INITIAL_VALUES = {
  title: '',
  dns: '',
  streaming_server_dns: '',
  streaming_server_archive_days: '',
  version: '',
  updated_at: '',
  web_login: '',
  web_password: '',
  api_login: '',
  api_password: '',
  custom_api_url: '',
  custom_api_secret: '',
};

const MinistraContainer = () => {
  const { t } = useTranslation(['translations']);

  const { ministraSyncDataStub } = MinistraContextProvider;

  const [inputValues, setInputValues] = useState({});
  const [editedServer, setEditedServer] = useState('');
  const [isCreate, setIsCreate] = useState(false);
  const [isEpgUpdate, setIsEpgUpdate] = useState(false);
  const [epgUpdateResult, setEpgUpdateResult] = useState('');
  const [isSync, setIsSync] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isInSync, setIsInSync] = useState(false);
  const [isBottomWindow, setIsBottomWindow] = useState(false);
  const [setTableHeightCallback, setSetTableHeightCallback] = useState(null);
  const [syncCallback, setSyncCallback] = useState(null);
  const [isSyncOk, setIsSyncOk] = useState(true);
  const [isRequest, setIsRequest] = useState(false);
  const [syncServerName, setSyncServerName] = useState('');
  const [syncData, setSyncData] = useState(ministraSyncDataStub);
  const [syncStatusText, setSyncStatusText] = useState('');
  const [isCloseBottomWindowEnabled, setIsCloseBottomWindowEnabled] = useState(false);

  return (
    <MinistraContextProvider>
      <Container>
        {editedServer || isCreate
          ? (
            <MinistraEditServer
              editedServer={editedServer}
              setEditedServer={setEditedServer}
              isCreate={isCreate}
              setIsCreate={setIsCreate}
              inputValues={inputValues}
              setInputValues={setInputValues}
            />
          ) : (
            <>
              <Text onClick={() => {
                setIsBottomWindow(false);
                setInputValues(INITIAL_VALUES);
                setIsCreate(true);
              }}
              >
                {t('admin.iptv.ministra.addServer')}
              </Text>
              <MinistraTable
                setEditedServer={setEditedServer}
                setInputValues={setInputValues}
                setIsEpgUpdate={setIsEpgUpdate}
                setEpgUpdateResult={setEpgUpdateResult}
                setSetTableHeightCallback={setSetTableHeightCallback}
                setIsSyncing={setIsSyncing}
                setIsSync={setIsSync}
                setSyncData={setSyncData}
                setIsBottomWindow={setIsBottomWindow}
                setSyncCallback={setSyncCallback}
                setSyncStatusText={setSyncStatusText}
                setIsRequest={setIsRequest}
                isSyncOk={isSyncOk}
                setIsSyncOk={setIsSyncOk}
                setSyncServerName={setSyncServerName}
                setIsCloseBottomWindowEnabled={setIsCloseBottomWindowEnabled}
                setIsInSync={setIsInSync}
              />
            </>
          )}
        <MinistraBottomWindowContainer
          title={syncServerName}
          isOpen={isBottomWindow}
          onClose={() => setIsBottomWindow(false)}
          isEpgUpdate={isEpgUpdate}
          epgUpdateResult={epgUpdateResult}
          isSyncProcess={isSync}
          setTableHeightCallback={setTableHeightCallback}
          syncCallback={syncCallback}
          syncData={syncData}
          isSyncing={isSyncing}
          isInSync={isInSync}
          isSyncError={!isSyncOk}
          isRequest={isRequest}
          isCloseEnabled={isCloseBottomWindowEnabled}
          disableSyncingButton={isInSync}
          syncStatusText={syncStatusText}
        />
      </Container>
    </MinistraContextProvider>
  );
};

export default MinistraContainer;

const Container = styled.div``;

const Text = styled.div`
  margin: 11px 0 21px 19px;

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${secondary};
  text-transform: capitalize;
  letter-spacing: 0.16px;
  line-height: 20px;
`;
