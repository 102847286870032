import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import {
  secondary,
  text01,
} from '@/common/constants/theme';
import CompareResultItem from './CompareResultItem';

const CompareResultPanel = ({
  type,
  sections,
  data,
  selectedResultItemKey,
  setSelectedResultItemKey,
  setDisplayInfo,
}) => {
  const { t } = useTranslation(['translations']);

  return (
    <CompareResultsBlock>
      <Wrapper topMargin>
        <Title>
          {`${t(`admin.iptv.ministra.compareResultsBlock_${type}`)}:`}
        </Title>
      </Wrapper>
      <Wrapper flex topMargin={15} bottomMargin={10}>
        <Text largeFontSize bottomMargin={10}>
          {`${t('common.totalText')}:`}
        </Text>
        <Text largeFontSize secondaryColor bold leftMargin>
          {data?.all}
        </Text>
      </Wrapper>
      <Wrapper>
        {sections.map((section) => (
          <CompareResultItem
            key={`${type}_${section}`}
            section={t(`admin.iptv.ministra.compareResult_${section}`)}
            data={data[section]}
            collapseText={t('common.collapseText')}
            moreText={t('common.moreText')}
            itemKey={`${type}_${section}`}
            setSelectedResultItemKey={setSelectedResultItemKey}
            setDisplayInfo={setDisplayInfo}
            isSelected={selectedResultItemKey === `${type}_${section}`}
          />
        ))}
      </Wrapper>
    </CompareResultsBlock>
  );
};

export default CompareResultPanel;

const CompareResultsBlock = styled.div`
  width: 100%;
  float: left;

  &:last-of-type {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-width: 175px;
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}

  ${({ topMargin }) => topMargin && (`margin-top: ${topMargin || 35}px;`)}
  ${({ bottomMargin }) => bottomMargin && (`margin-bottom: ${bottomMargin || 15}px;`)}
`;

const Text = styled.div`
  ${({ leftMargin }) => leftMargin && ('margin-left: 5px;')}

  font-family: Gilroy, sans-serif;
  font-size: ${({ largeFontSize }) => (largeFontSize ? 18 : 14)}px;
  ${({ bold }) => bold && ('font-weight: 600;')}
  color: ${({ secondaryColor }) => (secondaryColor ? secondary : text01)};
  letter-spacing: 0.16px;
  line-height: 15px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: left;
`;
