import React from 'react';
import styled from 'styled-components';

import Img01 from '@/assets/images/homeFooterEpg/hfi_01.webp';
import Img03 from '@/assets/images/homeFooterEpg/hfi_02.webp';
import Img02 from '@/assets/images/homeFooterEpg/hfi_03.webp';

const DATA = [
  {
    img: Img03,
    type: 'back',
  },
  {
    img: Img01,
    type: 'middle',
  },
  {
    img: Img02,
    type: 'top',
  },
];

const HomeFooterEPGAd = () => (
  <Container>
    {DATA.map((d) => (
      <ImgContainer key={`HFEPGI-I-${d.img}`} type={d.type}><Img src={d.img} alt="" /></ImgContainer>
    ))}
  </Container>
);

export default HomeFooterEPGAd;

const Container = styled.div`
  position: relative;
  width: 100%;

  &::after {
    display: block;
    content: '';
    clear: both;
  }
`;

const ImgContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  ${({ type }) => {
    const rules = [];
    switch (type) {
      case 'back':
        rules.push('z-index: 1;');
        rules.push('margin-top: -20px;');
        break;
      case 'middle':
        rules.push('z-index: 2;');
        rules.push('top: 137px;');
        rules.push('right: 12px;');
        break;
      case 'top':
        rules.push('z-index: 3;');
        rules.push('top: 290px;');
        rules.push('right: 53px;');
        break;
      default:
        break;
    }
    return rules.join('\n');
  }}
`;

const Img = styled.img`
`;
