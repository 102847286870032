import React, { useRef } from 'react';

import styled from 'styled-components';
import {
  white,
  text01,
  borderTertiary, borderSecondary,
} from '@/common/constants/theme';

import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import { RichUtils } from 'draft-js';
import '@draft-js-plugins/alignment/lib/plugin.css';
import createImagePlugin from '@draft-js-plugins/image';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';

import 'draft-js/dist/Draft.css';
import './ExtendedEditor.css';
import '@draft-js-plugins/focus/lib/plugin.css';

import Toolbar from '@/common/components/RichTextEditor/components/Toolbar/Toolbar';
import { CustomInlineStylesMap } from './Utils';
import customDecoratorPlugin from './Decorators';

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator,
);

const imagePlugin = createImagePlugin({ decorator });

const plugins = [
  blockDndPlugin,
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  imagePlugin,
  customDecoratorPlugin,
];
function getBlockStyle(block) {
  const blockAlignment = block.getData() && block.getData().get('text-align');
  if (blockAlignment) {
    return `rdw-${blockAlignment}-aligned-block`;
  }
  return '';
}

const handleKeyCommand = (onChange) => (command, editorState) => {
  const newState = RichUtils.handleKeyCommand(editorState, command);
  if (newState) {
    onChange(newState);
    return true;
  }
  return false;
};

const ExtendedEditor = ({
  placeholder = '',
  editorState,
  onEditorStateChange,
  setEditorState,
  uploadImage,
  simpleText = false,
}) => {
  const editorRef = useRef(null);

  return (
    <EditorWrapper className="rdw-editor-wrapper">
      <Toolbar
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        setEditorState={setEditorState}
        uploadImage={uploadImage}
        simpleText={simpleText}
      />
      <EditorMain
        className="rdw-editor-main"
        onClick={() => editorRef.current.focus()}
        simpleText={simpleText}
      >
        <Editor
          placeholder={placeholder}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          plugins={plugins}
          blockStyleFn={getBlockStyle}
          customStyleMap={CustomInlineStylesMap()}
          handleKeyCommand={handleKeyCommand(onEditorStateChange)}
          onChange={onEditorStateChange}
          spellCheck
          ref={editorRef}
        />
        <AlignmentTool />
      </EditorMain>
    </EditorWrapper>

  );
};

export default ExtendedEditor;

const EditorWrapper = styled.div`
    background: ${white};
    box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);

    box-sizing: border-box;
    border: 1px solid ${borderSecondary};
    border-radius: 4px;


    & .public-DraftStyleDefault-block {
        margin: .3rem 0;
    }
`;

const EditorMain = styled.div`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;

    font-family: Gilroy, sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: ${text01};
    padding: 15px;

    & code {
        background: ${borderTertiary};
    }

    ${({ simpleText }) => !simpleText && ('min-height: 200px;')}

`;
