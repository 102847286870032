import React from 'react';
import { BalanceContextProvider } from './BalanceContext';
import Balance from './Balance';

const BalanceContainer = ({
  setIsFinalModal,
  inputValue,
  setInputValue,
  bonus,
  setBonus,
}) => (
  <BalanceContextProvider>
    <Balance
      setIsFinalModal={setIsFinalModal}
      inputValue={inputValue}
      setInputValue={setInputValue}
      bonus={bonus}
      setBonus={setBonus}
    />
  </BalanceContextProvider>
);

export default BalanceContainer;
