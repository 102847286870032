/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  text02,
  white,
  borderSecondary,
} from '@/common/constants/theme';
import Tooltip from '@/common/components/Tooltip';
import IconCopy from '@/assets/icons/copyicon.svg';

const CopyInput = ({
  value,
  isFirst,
  isLast,
}) => {
  const { t } = useTranslation(['translations']);
  const copyText = (elementId) => {
    const range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
  };
  const id = Math.random();

  return (
    <Container isFirst={isFirst} isLast={isLast}>
      <Value id={id}>{value}</Value>
      <Tooltip
        title={`${t('link')} ${t('linkCopied')}`}
        type="success"
        position="right"
        trigger="click"
      >
        <Icon src={IconCopy} onClick={() => copyText(id)} />
      </Tooltip>
    </Container>
  );
};
export default CopyInput;

const Container = styled.div`
  background: ${white};
  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  ${({ isFirst }) => isFirst && `
      border-radius: 4px 4px 0 0;
  `}
  ${({ isLast }) => isLast && `
      border-radius: 0 0 4px 4px;
      border-top: none;
  `}
`;

const Value = styled.div`
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${text02}
`;

const Icon = styled.img`
  cursor: pointer;
`;
