/* eslint-disable no-param-reassign */
import RequestService from '../../request.service';
import urls from '../../routes/admin/servers.api';

export const getServersAction = () => RequestService(urls.serversList);

export const editServerAction = (id, item) => RequestService(urls.serversItem(id), {
  method: 'PUT',
  body: item,
});

export const createServerAction = (item) => RequestService(urls.serversList, {
  method: 'POST',
  body: item,
});

export const deleteServerAction = (id, newServerId) => RequestService(urls.serversItem(id), {
  method: 'DELETE',
  body: { new_server_id: newServerId },
});
