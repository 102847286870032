export const TOKEN_KEY = 'token';
export const TOKEN_EXPIRED_KEY = 'tokenExpired';
export const USER_INFO = 'userInfo';
export const LIMIT = 'limit';
export const DEVICES_LIST_LIMIT = 'deviceListLimit';
export const SELECTED_DEVICES = 'selectedDevices';
export const CHANNELS_LOGOS = 'channelsLogos';
export const DISABLED_PAGE_HEADERS = 'pageHeaderDisabled';
export const PASSWORD_PIN_CHECK_COUNT = 'passwordPinCheckCount';
export const PASSWORD_PIN_CHECK_EXPIRE_DATE = 'passwordPinCheckExpDate';
export const DEVICE_PLAYLIST_TYPE_DEFAULT = 1;
export const FINGERPRINT_PRO_KEY = process.env.REACT_APP_FINGERPRINT_KEY || '';
