import React from 'react';
import styled from 'styled-components';

import { greyWhite } from '@/common/constants/theme';

import ModalWithoutContentFilled from '@/common/components/ModalWithoutContentFilled';
import Button from '@/common/components/Button';

const SubmitWithdrawalModal = ({
  isOpen,
  setIsOpen,
  modalData,
  onSubmit,
  onCancel,
}) => (
  <ModalWithoutContentFilled
    color="success"
    onCloseModal={() => setIsOpen(false)}
    isOpen={isOpen}
  >
    <ModalText>
      Вы хотите подтвердить вывод средств
    </ModalText>
    <ModalBigText>
      {`$${modalData?.summ || 0} USD`}
    </ModalBigText>
    {modalData?.user
      && (
        <>
          <ModalText>
            пользователю
          </ModalText>
          <ModalBigText>
            {modalData?.user || ''}
          </ModalBigText>
        </>
      )}
    {modalData?.paySystem
      && (
        <>
          <ModalText>
            через платежную систему
          </ModalText>
          <ModalBigText>
            {modalData?.paySystem || ''}
          </ModalBigText>
        </>
      )}
    <Wrapper>
      <ModalText>
        Все верно?
      </ModalText>
      <CancelButton onClick={() => {
        if (onCancel) { onCancel(); }
        setIsOpen(false);
      }}
      >
        Отмена
      </CancelButton>
      <SubmitButton
        isUpperCase
        onClick={() => {
          if (onSubmit) { onSubmit(); }
          setIsOpen(false);
        }}
      >
        Да
      </SubmitButton>
    </Wrapper>
  </ModalWithoutContentFilled>
);

export default SubmitWithdrawalModal;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 26px;
`;

const CancelButton = styled.button`
  margin-right: 24px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${greyWhite};
  text-transform: uppercase;
  text-decoration: underline;
  line-height: 24px;
`;

const SubmitButton = styled(Button)`
  width: 54px;
`;

const ModalText = styled.div`
  margin-bottom: .25rem;

  font-family: Gilroy, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.16px;
  text-align: left;
`;

const ModalBigText = styled(ModalText)`
  margin-bottom: .75rem;

  font-size: 20px;
  line-height: 20px;
`;
