/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import styled from 'styled-components';
import {
  Checkbox, Select, Tooltip,
} from 'antd';
import {
  background01,
  primary,
  red,
  secondary,
  text01,
  text02,
  text03,
  text04,
  white,
  darkGrey,
} from '@/common/constants/theme';

import CloseIcon from '../../../../icons/CloseIcon';
import WarningIcon from '@/common/components/icons/WarningIcon';
import AutoRenewIcon from '../../../../icons/AutoRenewIcon';
import PremiumIcon from '../../../../icons/PremiumIcon';
import CellPhoneIcon from '@/common/components/icons/CellPhoneIcon';
import DeviceBuyPremiumModal from './DeviceBuyPremiumModal';
import ToolTip from '@/common/components/Tooltip';

import {
  setSnack, subscribeTypes,
} from '@/store/user/selectors';

import urls from '@/services/routes/devices.api';
import { editDeviceAction2 } from '@/services/actions/devices.actions';
import request2Service from '@/services/request2.service';

import { DeviceAppStatuses } from '@/AppContext';
import { usePersonalPageContext } from '../../../../../PersonalPageContext';

const { Option } = Select;

const DATA_TYPES = {
  name: 'name',
  description: 'description',
};

const DEVICE_PROBLEMS = {
  noServer: 'noServer',
  noPlayList: 'noPlayList',
  noSubscription: 'noSubscription',
};

const LAST_COLUMN_WIDTH = 2.5;

const DeviceTableRow = ({
  cellSizes,
  useDns,
  usePlayer,
  device,
  selected,
  selectDisabled,
  canBeDeleted,
  editDeviceServer,
  toggleModalDialog,
  syntheticServers = null,
  showPremium,
  servers,
}) => {
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();

  const {
    handleSelectDevice,
    handleSelectOneDevice,
    getPlayListDownloadLinks,
    updateDeviceParameters,
    goToPageWithScroll,
  } = usePersonalPageContext();

  const [isBusy, setIsBusy] = useState(() => {
    const x = {};
    Object.values(DATA_TYPES).forEach((dt) => {
      x[dt] = false;
    });
    return x;
  });
  const [isNameEditable, setNameEditable] = useState(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [deviceName, setDeviceName] = useState(device.name);
  const [isDescriptionEditable, setDescriptionEditable] = useState(false);
  const [deviceDescription, setDeviceDescription] = useState(device.description);
  const [isServerEditable, setServerEditable] = useState(false);
  const [buyPremiumShow, setBuyPremiumShow] = useState(false);
  const [buyPremiumResult, setBuyPremiumResult] = useState(null);
  const [problems, setProblems] = useState([]);

  const setInputFuncs = {
    [DATA_TYPES.name]: setDeviceName,
    [DATA_TYPES.description]: setDeviceDescription,
  };

  const setEditableFuncs = {
    [DATA_TYPES.name]: setNameEditable,
    [DATA_TYPES.description]: setDescriptionEditable,
  };

  const onServerChange = async (value) => {
    editDeviceServer(device.id, value)
      .then(() => {
        updateDeviceParameters(device.id, { server: value });
      })
      .catch((err) => dispatch(setSnack(err)))
      .finally(() => setServerEditable(false));
  };

  const goToServerSelect = async () => {
    handleSelectOneDevice(device);

    const isSubscribed = [subscribeTypes.Active, subscribeTypes.Ending]
      .includes(device.subscription_type);
    const gotoPage = `/personal-page/${isSubscribed ? 'server' : 'subscription'}`;

    goToPageWithScroll(gotoPage);
  };

  const onKeyUpInput = (type, e) => {
    if (e.keyCode === 27) {
      e.stopPropagation();
      e.preventDefault();
      setInputFuncs[type](device[type]);
      setEditableFuncs[type](false);
    }
  };

  const onChangeInput = (type, event) => {
    const releaseInput = () => {
      setEditableFuncs[type](false);
      setIsBusy((prevState) => ({ ...prevState, [type]: false }));
    };
    if (event.key === 'Enter') {
      event.preventDefault();
      const updData = {};
      switch (type) {
        case DATA_TYPES.name:
          updData[DATA_TYPES.name] = deviceName;
          break;
        case DATA_TYPES.description:
          updData[DATA_TYPES.description] = deviceDescription;
          break;
        default:
          break;
      }
      if (updData[type] === device[type]) {
        releaseInput();
        return;
      }
      editDeviceAction2(device.id, updData)
        .then((res) => updateDeviceParameters(res.id, { [type]: res?.[type] || '' }))
        .catch((err) => {
          dispatch(setSnack(err));
          setInputFuncs[type](device[type]);
        })
        .finally(() => releaseInput());
    }
  };

  function showModalDialog(dev, type) {
    toggleModalDialog({ device: dev, type });
    return '';
  }

  const buyPremiumSnackedErrorTypes = ['device_not_have_active_subscription', 'not_enough_money'];

  const renderServerSelect = () => {
    let selectValue;
    let selectOptions;
    let displayValue;
    let onSelectAction;
    if (syntheticServers) {
      const useSynthServers = { ...syntheticServers };

      if (![subscribeTypes.Active, subscribeTypes.Ending].includes(device.subscription_type)) {
        delete (useSynthServers.cdn);
        delete (useSynthServers.last);
        useSynthServers.select.name = t('devicesContent.serverSelectByTestAfterSubscribe');
      } else if (useSynthServers.last) {
        delete (useSynthServers.select);
      } else {
        useSynthServers.select.name = t('devicesContent.serverSelectByTest');
      }

      selectOptions = Object.entries(useSynthServers)
        .map(([k, v]) => ({
          ...v,
          type: k,
        }))
        .filter((o) => !!o.id);

      selectValue = !device.server
        ? t('devicesContent.serverNotSet')
        : (selectOptions.find((o) => o.id === device.server)?.id || t('devicesContent.serverSelectedByTest'));
      onSelectAction = (value) => (value > 0 ? onServerChange(value) : goToServerSelect(value));
      displayValue = device.server
        ? (selectOptions.find((o) => o.id === device.server)?.name || t('devicesContent.serverSelectedByTest'))
        : t('devicesContent.serverNotSet');
    } else {
      selectOptions = servers;
      selectValue = device.server;
      onSelectAction = onServerChange;
      displayValue = servers.find((s) => s.id === device.server)?.name
        || t('devicesContent.serverNotFound');
    }

    if (!isSubscriptionActive) {
      return <NoValueText>-</NoValueText>;
    }

    return (isServerEditable ? (
      <StyledSelect
        placeholder={t('table.serverInputPlaceholder')}
        showSearch
        popupClassName="normal"
        dropdownStyle={{ border: '1px solid rgb(239, 238, 243)' }}
        notFoundContent={<NothingFound>{t('table.noResults')}</NothingFound>}
        value={selectValue}
        onSelect={onSelectAction}
        onBlur={() => setServerEditable(false)}
        autoFocus
      >
        {selectOptions.map((item) => (
          <Option value={item.id} key={item.id}>{item.name}</Option>
        ))}
      </StyledSelect>
    ) : (
      <ServerName onClick={() => setServerEditable(true)}>
        {displayValue}
      </ServerName>
    ));
  };

  const renderSubscribeLink = () => (
    <Subscribe onClick={() => {
      const gotoPath = '/personal-page/subscription';
      handleSelectOneDevice(device).then(() => goToPageWithScroll(gotoPath));
    }}
    >
      {t('table.buySubscribe')}
    </Subscribe>
  );

  // eslint-disable-next-line no-unused-vars
  const renderSettingsLink = () => {
    const isSubscribeActive = [subscribeTypes.Active, subscribeTypes.Ending]
      .includes(device?.subscription_type) && device?.subscription;

    const handler = isSubscribeActive && handleSelectOneDevice
      ? () => handleSelectOneDevice(device).then(() => goToPageWithScroll('/personal-page/server'))
      : () => {};
    const text = isSubscribeActive ? t('devicesContent.settings') : '–';
    return (
      <HeaderCell
        width={cellSizes[4] - LAST_COLUMN_WIDTH}
        leftPadding
        onClick={handler}
      >
        <Settings isLink={isSubscribeActive}>{text}</Settings>
      </HeaderCell>
    );
  };

  const renderServerTypeSpan = () => {
    let linkText;
    let action = null;
    if (device.view_type === 'portal') {
      linkText = `${t('devicesContent.portal')}`;
      if (isSubscriptionActive) {
        action = () => {
          goToPageWithScroll(`/personal-page/playlist/#${device.id}`);
        };
      }
    } else if (device.view_type === 'playlist') {
      linkText = `${t('common.downloadText')} ${t('devicesContent.playlist').toLowerCase()}`;
      action = () => {
        const linksObjectFormVariant = 1;
        const { url } = getPlayListDownloadLinks(device, useDns, usePlayer, linksObjectFormVariant);
        // eslint-disable-next-line no-console
        console.log('url', url);
        window.open(url, '_blank');
      };
    }
    return isSubscriptionActive ? (
      <ServerType
        isClickable={!!action}
        onClick={() => (action && action())}
      >
        {linkText}
      </ServerType>
    ) : (
      <NoValueText>-</NoValueText>
    );
  };

  useEffect(() => {
    if (!device
      || (((servers || []).length === 0) && !syntheticServers)) {
      return;
    }
    const detectedProblems = [];
    if (!device.server) {
      detectedProblems.push(DEVICE_PROBLEMS.noServer);
    }
    if (![subscribeTypes.Active, subscribeTypes.Ending].includes(device.subscription_type)) {
      detectedProblems.push(DEVICE_PROBLEMS.noSubscription);
    }
    if (!device.playlist) {
      detectedProblems.push(DEVICE_PROBLEMS.noPlayList);
    }
    setProblems(detectedProblems);
  }, [device, servers, syntheticServers]);

  useEffect(() => {
    if (device) {
      setIsSubscriptionActive(device.subscription
        && ([subscribeTypes.Active, subscribeTypes.Ending].includes(device.subscription_type)));
    }
  }, [device?.subscription]);
  return (
    <Row>
      <HeaderCell width={cellSizes[0]} noPadding>
        {(problems || []).length > 0 && (
          <ToolTip
            trigger="hover"
            placement="topRight"
            color={white}
            overlayClassName="white-tooltip"
            overlayStyle={{ color: text01 }}
            displayType="inline-flex"
            contentContainerStyle={{ alignItems: 'start', paddingTop: '.15rem' }}
            title={(
              <DeviceProblemsList>
                {problems.map((pType) => (
                  <DeviceProblemItem
                    key={`device-${device.id}-problem-tt-content-${pType}`}
                  >
                    {t(`personalPage.devices.deviceProblems.${pType}`)}
                  </DeviceProblemItem>
                ))}
              </DeviceProblemsList>
            )}
          >
            <Warning animationDelay={device.id % 10} onClick={(e) => e.stopPropagation()}>
              <WarningIcon width={16} height={16} fill={red} />
            </Warning>
          </ToolTip>
        )}
      </HeaderCell>
      <HeaderCell width={cellSizes[1]}>
        <StyledCheckbox
          onClick={() => handleSelectDevice(device)}
          checked={selected}
          disabled={selectDisabled}
        />
        <TitleWrapper>
          {isNameEditable ? (
            <Input
              id={`name-${device.pk}`}
              name={device.name}
              value={deviceName || ''}
              onChange={({ target }) => setInputFuncs[DATA_TYPES.name](target.value)}
              onBlur={() => { onChangeInput(DATA_TYPES.name, new KeyboardEvent('keypress', { key: 'Enter' })); }}
              onKeyPress={(e) => onChangeInput(DATA_TYPES.name, e)}
              onKeyUp={(e) => onKeyUpInput(DATA_TYPES.name, e)}
              autoFocus
              spellcheck={false}
              disabled={isBusy?.[DATA_TYPES.name]}
            />
          ) : (
            <Title onClick={() => setNameEditable(true)}>
              <NameText>
                {device.name}
              </NameText>
            </Title>
          )}
          {isDescriptionEditable ? (
            <Input
              id={`content-${device.pk}`}
              name={device.description}
              value={deviceDescription}
              onChange={({ target }) => setInputFuncs[DATA_TYPES.description](target.value)}
              onBlur={() => { onChangeInput(DATA_TYPES.description, new KeyboardEvent('keypress', { key: 'Enter' })); }}
              onKeyPress={(e) => onChangeInput(DATA_TYPES.description, e)}
              onKeyUp={(e) => onKeyUpInput(DATA_TYPES.description, e)}
              autoFocus
              spellcheck={false}
              placeholder={t('table.contentInputPlaceholder')}
            />
          ) : (
            <Description onClick={() => setDescriptionEditable(true)}>
              {device.description || t('table.contentInputPlaceholder')}
            </Description>
          )}
        </TitleWrapper>
      </HeaderCell>
      <HeaderCell width={cellSizes[2]} leftPadding>
        {isSubscriptionActive
          ? (
            <div>
              <DateValue>
                {(device?.subscription?.date_start || false) ? format(new Date(device.subscription.date_start), 'dd.MM.yy') : ''}
              </DateValue>
              <DateValue margin>
                {device?.subscription ? '-' : t('devicesContent.noSubscription')}
              </DateValue>
              <DateValue
                isRed={device.subscription_type === subscribeTypes.Ending}
              >
                {(device?.subscription?.date_end || false) ? format(new Date(device.subscription.date_end), 'dd.MM.yy') : ''}
              </DateValue>
              <div style={{ fontSize: '10px', color: text02 }}>{(device?.subscription?.is_test || false) && t('devicesContent.testSubscription')}</div>
              {device.subscription_type === subscribeTypes.Active ? (
                <div style={{ display: 'flex' }}>
                  {buyPremiumShow && (
                    <DeviceBuyPremiumModal
                      doClose={() => {
                        setBuyPremiumShow(false);
                        setBuyPremiumResult(null);
                      }}
                      result={buyPremiumResult}
                    />
                  )}
                  {!!showPremium && !(device?.subscription?.is_test || false) && (
                    <Tooltip
                      autoAdjustOverflow
                      arrowPointAtCenter
                      placement="topRight"
                      title={(device?.subscription?.is_premium || false) ? t('devicesContent.premiumIsSet') : t('devicesContent.setPremium')}
                      color={white}
                      overlayClassName="white-tooltip"
                      overlayStyle={{ color: text01 }}
                    >
                      {/* eslint-disable-next-line max-len */}
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                      <span
                        onClick={() => {
                          if (device?.subscription?.is_premium) return;
                          request2Service(urls.setPremium(device.id), { method: 'POST' })
                            // eslint-disable-next-line consistent-return
                            .catch((err) => {
                              if (buyPremiumSnackedErrorTypes.includes(err.message_type)) {
                                dispatch(setSnack(err));
                              } else if (err.status_code === 406) {
                                setBuyPremiumResult(err);
                                setBuyPremiumShow(true);
                                // showModalDialog(device, 'premium');
                              } else {
                                return dispatch(setSnack(err));
                              }
                            });
                        }}
                        style={{ textDecoration: 'underline', cursor: (device?.subscription?.is_premium || false) ? 'default' : 'pointer' }}
                      >
                        <PremiumIcon width="20px" height="20px" gray={!(device?.subscription?.is_premium || false)} />
                      </span>
                    </Tooltip>
                  )}
                  <Tooltip
                    autoAdjustOverflow
                    arrowPointAtCenter
                    placement="topRight"
                    title={(device?.subscription?.auto_renew || false) ? t('devicesContent.autoRenewHeaderOn') : t('devicesContent.autoRenewHeaderOff')}
                    color={white}
                    overlayClassName="white-tooltip"
                    overlayStyle={{ color: text01 }}
                  >
                    <Settings onClick={() => showModalDialog(device, 'autoRenew')}>
                      <AutoRenewIcon active={device?.subscription?.auto_renew || false} width="20px" height="20px" />
                    </Settings>
                  </Tooltip>
                  <Tooltip
                    autoAdjustOverflow
                    arrowPointAtCenter
                    placement="topRight"
                    title={t(`devicesContent.appStatus.${device?.app_status || 'empty'}`)}
                    color={white}
                    overlayClassName="white-tooltip"
                    overlayStyle={{ color: text01 }}
                  >
                    <IconContainer
                      onClick={() => showModalDialog(device, 'appStatus')}
                    >
                      <CellPhoneIcon color={device?.app_status === DeviceAppStatuses.configured ? primary : darkGrey} width="20px" height="20px" />
                    </IconContainer>
                  </Tooltip>
                </div>
              ) : (
                renderSubscribeLink()
              )}
            </div>
          ) : (
            renderSubscribeLink()
          )}
      </HeaderCell>
      <HeaderCell width={cellSizes[3]} leftPadding>
        {(isSubscriptionActive && (((servers || []).length > 0) || syntheticServers)) ? (
          <ServerContainer>
            {renderServerSelect()}
          </ServerContainer>
        ) : (
          <NoValueText>—</NoValueText>
        )}
      </HeaderCell>
      <HeaderCell
        width={cellSizes[4] - LAST_COLUMN_WIDTH}
        leftPadding
      >
        {isSubscriptionActive ? (
          <ServerContainer>
            {renderServerTypeSpan()}
          </ServerContainer>
        ) : (
          <NoValueText>—</NoValueText>
        )}
      </HeaderCell>
      {/* renderSettingsLink() */}
      <HeaderCell width={LAST_COLUMN_WIDTH}>
        {canBeDeleted && (
          <Tooltip
            autoAdjustOverflow
            arrowPointAtCenter
            placement="topRight"
            title={t('devicesContent.deviceDelete')}
            color={white}
            overlayClassName="white-tooltip"
            overlayStyle={{ color: text01 }}
          >
            <Close onClick={() => showModalDialog(device, 'delete')}>
              <CloseIcon />
            </Close>
          </Tooltip>
        )}
      </HeaderCell>
    </Row>
  );
};
export default DeviceTableRow;

const Row = styled.div`
  display: flex;
  padding: 0 1rem;
  background: ${background01};

  :nth-child(even) {
    background-color: ${white};
  }
`;

const HeaderCell = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  padding: ${({ noPadding }) => (noPadding ? 'none' : '0 .652rem')};
  margin: .75rem 0;
  display: flex;
  width: ${({ width }) => width}rem;
  justify-content: ${({ justify }) => justify};
  ${({ leftPadding }) => leftPadding && 'padding-left: 1.4rem;'}
  ${({ disabled }) => disabled && `
    opacity: .5;
    pointer-events: none;
    path {
      fill: ${text04};
    }
  `} @media(max-width: 1360 px) and (min-width: 1199 px) {
  width: ${({ width }) => width - 2}rem;
}
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: .652rem;

  & .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${secondary};
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${white};
    border: 1px solid #d9d9d9;
  }

  & .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${secondary};
  }

  & .ant-checkbox-checked::after {
    border-color: ${secondary};
  }
`;

const Input = styled.input`
  outline: none;
  border: 1px solid #ECECEC;
  border-radius: 2px;
  font-size: 12px;
  width: 9rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleBlockText = styled.span`
  font-family: Gilroy, sans-serif;
  letter-spacing: 0.16px;
  white-space: nowrap;
  width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled(TitleBlockText)`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${text01};
  margin-bottom: 5px;
  height: 19px;
  overflow: unset;
`;

const NameText = styled(TitleBlockText)`
  overflow: hidden;
`;

const Description = styled(TitleBlockText)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${text03};
  margin-top: 5px;
  min-height: 19px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: .5rem;
  cursor: pointer;
`;

const Warning = styled.div`
  display: flex;
  align-items: center;
  margin-right: .25rem;
  z-index: 10;

  svg {
    transition: .2s;
    animation: blink 10s;
    animation-delay: ${({ animationDelay }) => (Math.random() * 10 + (animationDelay || 0))}s;
    animation-iteration-count: infinite;
  }

  @keyframes blink {
    0% { opacity: 1; }
    33% { opacity: 1; }
    48% { opacity: .85; }
    50% { opacity: .3; }
    54% { opacity: .85; }
    66% { opacity: 1; }
    100% { opacity: 1; }
  }
`;

const Close = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  path {
    fill: ${red};
  }

  ${({ disabled }) => disabled && `
    path {
      fill: ${text04};
    }
  `}
`;

const Subscribe = styled.div`
  display: inline-block;
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  color: ${primary};
  cursor: pointer;
  height: 14px;
  border-bottom: 1px solid ${primary};
  margin-top: .2rem;

  &:hover {
    color: ${primary};
  }

  &:active {
    color: ${primary};
  }
`;

const Settings = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: ${({ isLink }) => (isLink ? 500 : 600)};
  font-size: ${({ isLink }) => (isLink ? 13 : 15)}px;
  line-height: 15px;
  letter-spacing: 0.16px;
  color: ${({ isLink }) => (isLink ? primary : text01)};
  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'default')};
  margin-top: 2px;
  margin-left: .7rem;
`;

const DateValue = styled.span`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  color: ${({ isRed }) => (isRed ? red : text01)};
  margin: .25rem 0;
  cursor: pointer;
  ${({ margin }) => margin && 'margin: .25rem;'}
`;

const ServerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
`;

const ServerName = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.16px;
  margin-bottom: .5rem;
  cursor: pointer;
  color: ${primary};
`;

const ServerType = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.16px;
  color: ${text02};
  margin-top: .25rem;
  transition: all .25s ease-in-out 0s;
  ${({ isClickable }) => (isClickable && `
    cursor: pointer;
    &:hover {
     color: ${secondary};
    }
  `)}
`;

const NoValueText = styled(ServerType)`
  display: inline-block;
  width: 100%;
  min-width: 7rem;
  text-align: center;
  cursor: default;
  &:hover {
    color: inherit;
  }
`;

const StyledSelect = styled(Select)`
  margin-left: -.85rem;
  margin-top: -.25rem;
  text-transform: initial;
  font-family: Gilroy, sans-serif;
  font-weight: normal;
  font-size: 13px;
  color: ${text01};
  outline: none;

  path {
    fill: ${text01};
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${secondary};
    box-shadow: none;
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${secondary};
    box-shadow: none;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 1.75rem;
  }

  && .ant-select-selection-item {
    line-height: 26px;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 1.625rem;
  }

  & .ant-select-dropdown {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    border: 1px solid rgb(239, 238, 243);
  }

  &.ant-select-single.ant-select-open .ant-select-selection-item {
    color: ${text01};
  }

  &.ant-select-open .ant-select-arrow .anticon-down {
    transform: rotate(180deg);
  }
`;

const NothingFound = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: .75rem;
`;

const DeviceProblemItem = styled.div``;

const DeviceProblemsList = styled.div`
  display: inline-block;
`;
