/* eslint-disable no-param-reassign */
import RequestService from '../request.service';
import urls from '../routes/server.api';

export const validatePasswordAction = (password) => {
  let error = null;

  if (password.length < 6) {
    error = 'lengthError';
  }

  return error;
};

export const getServersAction = () => (
  RequestService(urls.servers, {
    method: 'GET',
    params: { page: 1, limit: 1000 },
  })
);

export const bindDeviceToServerAction = (deviceId, serverId) => RequestService(
  urls.bindDeviceToServer(deviceId),
  {
    method: 'POST',
    body: { server_id: serverId },
  },
);

export const unBindDeviceToServerAction = (deviceId) => RequestService(
  urls.bindDeviceToServer(deviceId), { method: 'DELETE' },
);

export const fetchDeviceMAGServers = () => RequestService(urls.magServers, {
  method: 'GET',
});

export const getMinistraAction = () => RequestService(urls.magServers, {
  method: 'GET',
});

export const checkDeviceByIdAction = (id) => RequestService(urls.checkDevice(id), {
  method: 'POST',
});

export const checkAllDevicesAction = (item) => RequestService(urls.checkAllDevices, {
  method: 'PATCH',
  body: item,
});

export const getSelectedDevicesAction = () => RequestService(urls.selectedDevices);
