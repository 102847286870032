import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  borderSecondary,
  primary,
  text01,
  text02,
  white,
} from '@/common/constants/theme';

const TableFilters = ({
  onClose = () => {},
  resetHandler,
  filtersTitle,
  isClearFiltersBtn,
  children,
}) => {
  const { t } = useTranslation('translations');

  const checkClassList = (classList) => {
    let isCorrect = true;

    for (let i = 0; i < classList.length; i += 1) {
      if (classList[i] === 'header-filter-trigger') {
        isCorrect = false;
      }
    }

    return isCorrect;
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current
          && !ref.current.contains(event.target)
          // eslint-disable-next-line max-len
          && (checkClassList(event.target.classList) && checkClassList(event.target.parentElement?.classList))
        ) {
          event.preventDefault();
          event.stopPropagation();
          onClose();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Container ref={wrapperRef}>
      <Triangle />
      <Header>
        <TextTitle opacity>
          {filtersTitle || t('table.extendedFilter')}
        </TextTitle>
        {isClearFiltersBtn && (
          <TextReset onClick={resetHandler}>
            {t('table.cleanFilter')}
          </TextReset>
        )}
      </Header>
      {children}
    </Container>
  );
};

export default TableFilters;

const Container = styled.div`
  min-width: 386px;
  padding: 16px 25px 25px 25px;

  position: absolute;
  top: 55px;
  right: 0;
  z-index: 30;

  background: ${white};
  border: 1px solid ${borderSecondary};
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.11));
  border-radius: 4px;

  &:after {
    content: '';

    position: absolute;
    left: 0;
    right: 0;
    top: 46px;

    height: 1px;
    background-color: #EFEEF3;
  }
`;

const Text = styled.div`
  ${({ rightMargin }) => rightMargin && ('margin-right: 20px;')};
  ${({ rightMarginLittle }) => rightMarginLittle && ('margin-right: 11px;')};
  padding-bottom: 10px;

  ${({ topMargin }) => topMargin && (`
    position: relative;
    top: 5px;
  `)}

  font-family: Gilroy;
  font-size: 14px;
  color: ${({ opacity }) => (opacity ? text02 : text01)};
  line-height: 20px;
  letter-spacing: 0.16px;

  ${({ input }) => input && (`
    font-size: 13px;
    line-height: 15px;
    color: ${text01};
  `)}
`;

const TextTitle = styled(Text)`
  font-weight: 600;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  position: relative;
`;

const TextReset = styled(Text)`
  cursor: pointer;

  font-size: 12px;
  font-weight: 600;
  color: ${primary};
`;

const Triangle = styled.div`
  width: 0;
  height: 0;

  position: absolute;
  top: -15px;
  right: 21px;
  z-index: 3;

  border-style: solid;
  border-width: 0 11px 15px 11px;
  border-color: transparent transparent ${borderSecondary} transparent;

  &::after {
    content: '';

    display: block;
    width: 0;
    height: 0;

    position: relative;
    right: 15px;
    top: 2px;
    z-index: 2;

    border-style: solid;
    border-width: 0 15px 20px 15px;
    border-color: transparent transparent ${white} transparent;
  }
`;
