import React, {
  createContext,
  useContext, useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import arrayMove from '@/common/utils/arrayMove.util';
import { sortObjectsByOrderingId } from '@/common/utils/sort.utils';

import {
  handleCreatePlaylistAction,
  handleDeletePlaylistAction,
  handleEditPlaylistAction,
  handleStarChannelAction,
  handleCreateGroupAction,
  handleEditGroupAction,
  handleDeleteGroupAction,
  getChannelsByGroupIdAction,
  getGroupsAction,
  groupHideAction,
  groupsHideAction,
  channelMoveEndAction,
  groupDragAndDropAction,
  channelDragAndDropAction,
  channelsHideAction,
  channelHideAction,
} from '@/services/actions/channels.actions';

import {
  updateDevicePlayList,
} from '@/services/actions/devices.actions';

import {
  getSelectedDevices,
  getPlayLists, setPlaylist,
  updateDevicesPlaylistRedux,
  getChannelsLogos, updateStoredDevice, setSnack,
  loadAllDevicesRedux,
} from '@/store/user/selectors';

import { SnackTypes } from '@/AppContext';
import { USE_HTTPS_FOR_CHANNELS_LOGO } from '../../../PersonalPageContext';

const ChannelsContext = createContext({});

const initialFilters = {
  search: '',
  mainDropdown: '',
  isGroups: true,
  isStalker: false,
  layout: 'column',
  activeGroup: 0,
};

const INIT_LOADERS = {
  playLists: false,
  channelGroups: false,
  channels: false,
  virtualPlaylist: false,
};

export const ChannelsContextProvider = ({ children }) => {
  const { t } = useTranslation('translations');

  const [loaders, setLoaders] = useState(INIT_LOADERS);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedDevicesIds, setSelectedDevicesIds] = useState('');
  const [playLists, setPlayLists] = useState([]);
  const [playListsNames, setPlayListsNames] = useState('');
  const [groups, setGroups] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channelsFilters, setChannelsFilters] = useState(initialFilters);
  const [defaultPlaylist, setDefaultPlaylist] = useState(null);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [createdPlaylist, setCreatedPlaylist] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(-1);

  // ARTV-390 changes: Для отложенной активации канала
  const [needShowChannel, setNeedShowChannel] = useState(0);

  const [channelsMessage, setChannelsMessage] = useState(null);

  const dispatch = useDispatch();

  const allPlayLists = useSelector(getPlayLists);
  const storedSelectedDevices = useSelector(getSelectedDevices);

  const newVirtualPlayList = (name) => ({
    id: 0,
    is_default: false,
    is_virtual: true,
    title: name,
  });

  const setChannelsLogoProto = (channel) => (USE_HTTPS_FOR_CHANNELS_LOGO
    ? { ...channel, url: channel.url.replace('http://', 'https://') } : channel);

  const setPlaylistDevice = async (id) => {
    setSelectedPlaylist((playLists || []).find((p) => p.id === id) || null);

    (selectedDevices || []).forEach(async (d) => {
      await updateDevicePlayList(d.id, id);
      dispatch(updateStoredDevice(d.id, { playlist: id }));
    });
  };

  /**
   * Получаем каналы группы плейлиста
   * @param groupId ИД группы
   * @param playlistId Ид плейлиста (к которому привязан группа)
   * Если playlistId не передан, берем ИД выбраноого
   * (или по умолчнию, если выбран виртуальный) плейлиста
   */
  const getChannelsByGroupId = (groupId, playlistId = null) => {
    // ARTV-390 changes
    const usePlayListId = playlistId || (
      selectedPlaylist?.is_virtual
        ? defaultPlaylist?.id
        : selectedPlaylist?.id);

    if (!usePlayListId || !groupId || loaders.channels) {
      return Promise.reject();
    }
    setLoaders((prevState) => ({ ...prevState, channels: true }));
    setChannels([]);

    return getChannelsByGroupIdAction(usePlayListId, groupId)
      .then((res) => {
        const loadedChannels = (res?.channels || []).map(setChannelsLogoProto);
        dispatch(getChannelsLogos(loadedChannels));
        const sortedChannels = loadedChannels.sort(sortObjectsByOrderingId);
        setChannels(sortedChannels);
        return sortedChannels;
      })
      .catch((err) => {
        dispatch(setSnack(err));
        return err;
      })
      .finally(() => setLoaders((prevState) => ({ ...prevState, channels: false })));
  };

  const getPlaylistGroups = (
    playlistId,
    skipSelectGroup = false,
    overrideSelectedGroup = null,
  ) => {
    if ((playLists || []).length === 0) { return Promise.resolve(); }

    setGroups([]);
    setChannels([]);
    setLoaders((prevState) => ({ ...prevState, channelGroups: true }));
    return getGroupsAction(playlistId)
      .then((res) => {
        const currentSelectedGroup = overrideSelectedGroup
          || (groups || []).find((g) => (g.id === selectedGroupId));

        const sortedGroups = (res?.results || [])
          .sort(sortObjectsByOrderingId);
        const selectLoadedGroup = currentSelectedGroup
          ? (sortedGroups || []).find((lg) => (
            (lg.name === currentSelectedGroup.name)
            && (lg.csv_group_id === currentSelectedGroup.csv_group_id)
          )) || null
          : null;

        if (!skipSelectGroup && selectLoadedGroup) {
          selectLoadedGroup.is_hidden = false;
        }

        setGroups(sortedGroups);

        if (sortedGroups.length > 0) {
          // find first not disabled group
          const notDisabledFirstGroup = sortedGroups.find((g) => !g.is_hidden);
          if (!skipSelectGroup) {
            setSelectedGroupId((selectLoadedGroup || notDisabledFirstGroup || sortedGroups[0]).id);
          }
        }
        return {
          createdPlayListGroups: (res?.results || []),
          newCurrentGroup: selectLoadedGroup || null,
        };
      })
      .catch((err) => {
        dispatch(setSnack(err));
        return Promise.reject(err);
      })
      .finally(() => setLoaders((prevState) => ({ ...prevState, channelGroups: false })));
  };

  const getDevicePlaylist = () => {
    const items = [];
    (storedSelectedDevices || []).forEach((device) => {
      items.push({ id: device.id, name: device.name, playlistId: device.playlist });
    });
    let usePlaylist = null;
    if (items.length > 0) {
      usePlaylist = (playLists || []).find((p) => p.id === items[0].playlistId) || null;
      setSelectedPlaylist(usePlaylist);
    }
    return usePlaylist;
  };

  const changeCurrentGroup = (item) => {
    if (selectedGroupId !== item.id) {
      setSelectedGroupId(item.id);
    }
  };

  const handleCreatePlaylist = (playlistName, isFromVirtual = false) => handleCreatePlaylistAction(
    playlistName, isFromVirtual,
  )
    .then((res) => {
      // ARTV-390 changes
      let usePlayLists;
      if (isFromVirtual) {
        usePlayLists = [...playLists];
        const virtualPlayListIndex = usePlayLists.findIndex((pl) => pl?.is_virtual);
        if (virtualPlayListIndex >= 0) {
          usePlayLists.splice(virtualPlayListIndex, 1, res);
        }
      } else {
        usePlayLists = [...playLists, { ...res }];
      }

      dispatch(setPlaylist(usePlayLists));
      setPlayLists(usePlayLists);
      return res;
    })
    .then((res) => {
      setCreatedPlaylist(res);
      return res;
    })
    .catch((err) => {
      dispatch(setSnack(err));
      setSelectedPlaylist(null);
    });

  const handleDeletePlaylist = (id) => handleDeletePlaylistAction(id)
    .then(() => {
      const currentPlaylists = [...playLists];
      const deletedPlaylistIndex = currentPlaylists.findIndex((pl) => pl.id === id);
      currentPlaylists.splice(deletedPlaylistIndex, 1);
      dispatch(setPlaylist(currentPlaylists));
      //  .then(() => dispatch(loadAllDevicesRedux()));

      dispatch(loadAllDevicesRedux());
      // replaced by device reload (need for load actual data on devices,
      // if deleted playlist was set on devices not from selected list:
      //
      // const playListToSet = defaultPlaylist || currentPlaylists.find((pl) => pl.is_default);
      // setPlaylistDevice(playListToSet.id);
    })
    .catch((err) => dispatch(setSnack(err)));

  const handleEditPlaylist = (playlist) => {
    const editedPlayListId = playlist?.id || playlist?.key || 0;
    if (editedPlayListId <= 0) {
      dispatch(setSnack({ content: `Playlist id not present in object: ${JSON.stringify(playlist)}`, type: SnackTypes.error }));
      return Promise.reject(new Error({ message_type: 'no_playlist_id' }));
    }
    const editedPlaylist = playLists.find((pl) => pl.id === editedPlayListId);
    if (!editedPlaylist) {
      dispatch(setSnack({ content: `Playlist with id [${editedPlayListId}] not present in list`, type: SnackTypes.error }));
      return Promise.reject(new Error({ message_type: 'no_playlist_in_list' }));
    }
    return handleEditPlaylistAction(playlist)
      .then((editResult) => {
        const changedPlaylistList = [...(playLists || [])];
        const editedPlaylistIndex = changedPlaylistList
          .findIndex((pl) => pl.id === editedPlayListId);
        const updatedPlaylist = {
          ...editedPlaylist,
          is_default: editResult.is_default,
          title: editResult.title,
        };
        changedPlaylistList.splice(editedPlaylistIndex, 1, updatedPlaylist);
        dispatch(setPlaylist(changedPlaylistList));
        setSelectedPlaylist(updatedPlaylist);
        if (selectedGroupId === groups[0].id) {
          getChannelsByGroupId(selectedGroupId);
        }
        return updatedPlaylist;
      });
  };

  // ARTV-390 changes
  const handleCreatePlaylistVirtual = (playListName) => handleCreatePlaylist(playListName, true)
    .then((res) => {
      const usePlayList = playLists.find((pl) => pl.id === res.id);
      if (usePlayList) {
        // bind devices to playlist
        if ((selectedDevices || []).length > 0 && usePlayList.id) {
          dispatch(updateDevicesPlaylistRedux(selectedDevices, usePlayList.id));
        }
      } else {
        dispatch(setSnack({ content: t('steps.step4.errors.playListNotFound'), type: SnackTypes.error }));
      }
      return res;
    })
    .finally(() => {
      setLoaders((prevState) => ({ ...prevState, [INIT_LOADERS.virtualPlaylist]: false }));
    });

  const handleCreateGroup = (name) => {
    handleCreateGroupAction(name, selectedPlaylist.id).then((res) => {
      setGroups(
        (prevState) => [...prevState, res],
      );
    });
  };

  const handleEditGroup = (groupId, newTitle) => {
    const selectedGroup = groups.find((g) => g.id === groupId);
    const updatedGroup = handleEditGroupAction(selectedPlaylist.id, selectedGroup, newTitle);
    //  .then((res) => setGroups(res.results));
    setGroups((prevState) => {
      const updatedGroups = [...prevState];
      const groupIndex = prevState.findIndex((g) => g.id === groupId);
      updatedGroups[groupIndex] = updatedGroup;
      return updatedGroups;
    });
  };

  const handleDeleteGroup = (groupId) => {
    handleDeleteGroupAction(selectedPlaylist.id, groupId);
    setGroups(groups.filter((g) => g.id !== groupId));
    if (groups.length > 0) {
      setSelectedGroupId(groups[0].id);
    }
  };

  const setGroupIsHidden = (groupId, isHidden, setSelectedCount = true) => {
    if (groupId > 0) {
      const updatedGroups = [...(groups || [])];
      const usedGroupIndex = updatedGroups.findIndex((g) => g.id === groupId);
      const usedGroup = {
        ...updatedGroups[usedGroupIndex],
        is_hidden: isHidden,
      };
      if (setSelectedCount) {
        usedGroup.count_selected = isHidden ? 0 : updatedGroups[usedGroupIndex].channels_count;
      }
      updatedGroups.splice(usedGroupIndex, 1, usedGroup);
      setGroups(updatedGroups);
    }
  };

  const setAllGroupsIsHidden = (isHidden) => {
    const updatedGroups = groups.map((g) => ({
      ...g,
      is_hidden: isHidden,
      count_selected: isHidden ? 0 : g.channels_count,
    }));
    setGroups(updatedGroups);
    return groupsHideAction(selectedPlaylist.id, isHidden)
      .then(() => getChannelsByGroupId(selectedGroupId))
      .catch((err) => dispatch(setSnack(err)));
  };

  const handleHideAllGroups = () => {
    if (!selectedPlaylist) return;
    setAllGroupsIsHidden(true);
  };

  const handleDisplayAllGroups = () => {
    if (!selectedPlaylist) return;
    setAllGroupsIsHidden(false);
  };

  const toggleCurrentGroupHidden = (isHidden) => {
    if (selectedGroupId >= 0) {
      groupHideAction(selectedPlaylist.id, selectedGroupId, isHidden)
        .then(() => setGroupIsHidden(selectedGroupId, isHidden));
    }
  };

  const handleHiddenGroup = (item, isHidden) => {
    if (!selectedPlaylist) return;
    // ARTV-390 changes
    // Если плейлист виртуальный,
    // создаем плейлист для устройства,
    // получаем его группы,
    // меняем выбранную группу на полученную по признакоу name + csv_group_id
    // меняем признак is_hidden на нужное значение
    // активируем группу
    if (selectedPlaylist.is_virtual) {
      handleCreatePlaylistVirtual(selectedPlaylist.title)
        .then((createdPlayList) => getPlaylistGroups(createdPlayList.id, true, item)
          .then(({ createdPlayListGroups, newCurrentGroup }) => {
            if (newCurrentGroup) {
              setSelectedGroupId(newCurrentGroup.id);
              const updatedGroups = [...createdPlayListGroups];
              const usedGroup = updatedGroups.find((g) => g.id === newCurrentGroup.id);
              usedGroup.is_hidden = isHidden;
              usedGroup.count_selected = isHidden ? 0 : newCurrentGroup.channels_count;
              setGroups(updatedGroups);
              groupHideAction(createdPlayList.id, newCurrentGroup.id, false)
                .then(() => getChannelsByGroupId(newCurrentGroup.id, createdPlayList.id));
            } else {
              dispatch(setSnack({ content: t('common.error.undefined'), type: SnackTypes.error }));
            }
          }));
    } else {
      const updatedGroups = [...groups];
      const usedGroup = updatedGroups.find((g) => g.id === item.id);
      usedGroup.is_hidden = isHidden;
      usedGroup.count_selected = isHidden ? 0 : usedGroup.channels_count;
      setGroups(updatedGroups);
      groupHideAction(selectedPlaylist.id, item.id, isHidden)
        .then(() => getChannelsByGroupId(item.id));
    }
  };

  const handleDragEndGroup = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    const updatedGroups = arrayMove(groups, oldIndex, newIndex);
    setGroups(updatedGroups);
    if (newIndex !== channelsFilters.activeGroup) {
      setChannelsFilters((prevState) => ({
        ...prevState,
        activeGroup: newIndex,
      }));

      const oldId = updatedGroups[newIndex].id;
      let prevGroupId = null;
      if (newIndex > 0) {
        prevGroupId = updatedGroups[newIndex - 1].id;
      }

      // ARTV-390 changes
      if (!selectedPlaylist?.is_virtual) {
        groupDragAndDropAction(selectedPlaylist.id, oldId, prevGroupId);
      }
      // handleRemoveSelectChannel();
    }
  };

  const handleStarChannel = (i, isStarred) => {
    setGroups((prevState) => [...handleStarChannelAction(
      prevState,
      i,
      isStarred,
      channelsFilters.activeGroup,
    )]);
  };

  const handleHideChannel = (
    i, isHidden,
    overridePlaylist = null, overrideGroups = null, overrideChannels = null,
  ) => {
    const usePlaylist = overridePlaylist || selectedPlaylist;
    const useGroups = overrideGroups || groups;
    const useChannels = overrideChannels || channels;
    if (useChannels?.[i]) {
      const updatedChannels = [...useChannels];
      updatedChannels[i].is_hidden = isHidden;
      setChannels(updatedChannels);

      const selectedCurrentGroup = groups.find((cg) => ((cg.id === selectedGroupId)));
      const useGroupId = (overrideGroups && selectedCurrentGroup)
        ? overrideGroups.find(
          (og) => (og.name === selectedCurrentGroup.name)
            && (og.csv_group_id === selectedCurrentGroup.csv_group_id),
        ).id
        : selectedGroupId;

      const updatedGroups = [...useGroups];
      const useGroupIndex = updatedGroups.findIndex((g) => g.id === useGroupId);
      const useGroup = updatedGroups[useGroupIndex];
      useGroup.count_selected += isHidden ? -1 : +1;
      updatedGroups.splice(useGroupIndex, 1, useGroup);
      setGroups(updatedGroups);

      channelHideAction(usePlaylist.id, useGroupId, useChannels[i].id, isHidden)
        .then((res) => {
          if (useGroup && (res?.group_is_hidden !== !!useGroup.is_hidden)) {
            setGroupIsHidden(useGroupId, res.group_is_hidden, false);
          }
        })
        .catch((err) => dispatch(setSnack(err)));
    }
  };

  const handleHideChannels = (isHidden) => {
    const updatedChannels = [...(channels || [])].map((c) => ({ ...c, is_hidden: isHidden }));
    channelsHideAction(selectedPlaylist.id, selectedGroupId, isHidden)
      .then(() => {
        setChannels(updatedChannels);
        toggleCurrentGroupHidden(isHidden);
      });

    const updatedGroups = [...groups];
    const useGroupIndex = updatedGroups.findIndex((g) => g.id === selectedGroupId);
    const useGroup = updatedGroups[useGroupIndex];
    useGroup.count_selected = isHidden ? 0 : useGroup.channels_count;
    updatedGroups.splice(useGroupIndex, 1, useGroup);
    setGroups(updatedGroups);
  };

  const handleDisplaySelectedChannels = async () => {
    const allChannels = [...(channels || [])];
    const selectedChannels = [...allChannels].filter((c) => c.isSelected);
    const selectedChannelsIds = selectedChannels.map((c) => c.id);

    if (selectedChannelsIds.length === 0) {
      return;
    }

    let grpHide = null;
    let useChannels = null;
    if (selectedChannels.length > (allChannels.length / 2)) {
      // select more than half: select then deselect
      grpHide = false;
      useChannels = allChannels.filter((c) => (!selectedChannelsIds.includes(c.id)));
    } else {
      // select less than half: deselect then select
      grpHide = true;
      useChannels = selectedChannels;
    }

    channelsHideAction(selectedPlaylist.id, selectedGroupId, grpHide)
      .then(() => {
        useChannels
          .forEach((c) => channelHideAction(selectedPlaylist.id, selectedGroupId, c.id, !grpHide));
      });

    // update channels list
    const updatedChannels = allChannels
      .map((c) => ({ ...c, isSelected: false, is_hidden: !selectedChannelsIds.includes(c.id) }));

    // update groups list
    const updatedGroups = [...groups];
    const useGroupIndex = updatedGroups.findIndex((g) => g.id === selectedGroupId);
    const useGroup = updatedGroups[useGroupIndex];
    if (useGroup.is_hidden) {
      toggleCurrentGroupHidden(false);
    } else {
      useGroup.count_selected = selectedChannelsIds.length;
      updatedGroups.splice(useGroupIndex, 1, useGroup);
      setGroups(updatedGroups);
    }
    setChannels(updatedChannels);
  };

  const handleHideAllChannels = () => handleHideChannels(true);

  const handleDisplayAllChannels = () => handleHideChannels(false);

  const handleSelectPlayList = (
    playListId, updateDevicesPlaylist = true, usePlayListsList = null,
  ) => {
    if ((selectedPlaylist?.id || -1) === playListId) {
      return;
    }

    if ((playListId > 0) && updateDevicesPlaylist) {
      dispatch(updateDevicesPlaylistRedux(selectedDevices
        .filter((sd) => sd.playlist !== playListId), playListId));
    }

    const usePlayList = (usePlayListsList || playLists || []).find((pl) => pl.id === playListId);

    if (usePlayList) {
      setSelectedPlaylist(usePlayList);
    }
  };

  const handleSelectChannel = (i, isSelected) => {
    setChannels((prevState) => {
      const currentState = [...prevState];
      currentState[i].isSelected = isSelected;
      return currentState;
    });
  };

  const handleRemoveSelectChannel = () => {
    // setCurrentGroup((prevState) => ({ ...handleRemoveSelectChannelAction(prevState) }));
  };

  const handleDragEndChannel = (oldIndex, newIndex) => {
    const channelId = channels[oldIndex].id;

    const updateChannels = arrayMove(channels, oldIndex, newIndex);
    setChannels(updateChannels);

    let prevChannelId = null;
    if (newIndex > 0) {
      prevChannelId = updateChannels[newIndex - 1].id;
    }
    // ARTV-390 changes
    if (!selectedPlaylist?.is_virtual) {
      channelDragAndDropAction(selectedPlaylist.id, selectedGroupId, channelId, prevChannelId);
    }
  };

  const handleDragEndChannelToGroup = (hoveredGroupIndex) => {
    const selectedChannels = channels.filter((c) => c.isSelected);
    setChannels(channels.filter((c) => !c.isSelected));

    const newGroupId = groups[hoveredGroupIndex].id;
    selectedChannels.forEach((c) => {
      channelMoveEndAction(selectedPlaylist.id, selectedGroupId, c.id, newGroupId);
    });

    setGroups(groups.map((g) => {
      if (g.id === newGroupId) {
        return { ...g, channels_count: g.channels_count + selectedChannels.length };
      }
      if (g.id === selectedGroupId) {
        return { ...g, channels_count: g.channels_count - selectedChannels.length };
      }
      return g;
    }));
  };

  useEffect(() => {
    const storedIds = (storedSelectedDevices || [])
      .map((sd) => `${sd.id}-${sd.playlist ? 'n' : 'v'}`).sort().join('|');
    const selectedIds = selectedDevices
      .map((sd) => `${sd.id}-${sd.playlist ? 'n' : 'v'}`).sort().join('|');
    if (storedIds !== selectedIds) {
      setSelectedDevices(storedSelectedDevices || []);
      setSelectedDevicesIds(storedIds);
    }
  }, [storedSelectedDevices]);

  useEffect(() => {
    if (allPlayLists && (selectedDevicesIds.length > 0)) {
      const usePlayLists = [...allPlayLists].map((pl) => ({ ...pl, is_virtual: false }));

      // ARTV-390 changes
      // Если у устройства еще не выбран никакой плейлист
      // - то в интерфейсе создается плейлист с названием "Плейлист {{имя устройства}}",
      // в котором все каналы отключены (is_virtual)
      // Если выбрано несколько устройств, среди которых есть невыбранный плейлист
      // - то при переходе создается плейлист с названием "Новый плейлист[ Х]",
      // где " Х" - или ничего или просто следующий порядковый номер
      const isAllDevicesWithPlaylists = selectedDevices
        .filter((sd) => !sd.playlist).length === 0;

      if (!isAllDevicesWithPlaylists) {
        let usePlayListName;
        if (selectedDevices.length === 1) {
          // ARTV-390 changes
          // Если у устройства еще не выбран никакой плейлист
          // - то в интерфейсе создается плейлист с названием "Плейлист {{имя устройства}}",
          // в котором все каналы отключены
          usePlayListName = t('steps.step4.content.virtualPlaylist.nameForDevice', selectedDevices[0]);
          const plUsedName = usePlayLists.filter(
            (pl) => (pl.title.startsWith(usePlayListName)),
          ) || [];
          if (plUsedName.length > 0) {
            usePlayListName += ` ${plUsedName.length + 1}`;
          }
        } else {
          const baseVirtualPlaylistName = t('steps.step4.content.virtualPlaylist.name');
          const num = allPlayLists.filter(
            (pl) => (pl.title.startsWith(baseVirtualPlaylistName)),
          ).length;
          usePlayListName = ((num === 0)
            ? baseVirtualPlaylistName
            : `${baseVirtualPlaylistName} ${num}`);
        }
        usePlayLists.push(newVirtualPlayList(usePlayListName));
      }

      setPlayLists(usePlayLists);

      const newPlaylistNames = [...usePlayLists].map((pl) => pl.title).sort().join('|');
      setPlayListsNames(newPlaylistNames);

      setDefaultPlaylist(allPlayLists.find((pl) => pl.is_default) || null);
      setLoaders((prevState) => ({ ...prevState, playLists: false }));
    } else {
      setLoaders((prevState) => ({ ...prevState, playLists: true }));
    }
  }, [allPlayLists, selectedDevicesIds]);

  useEffect(() => {
    if ((playListsNames.length > 0)) {
      const usePlayLists = [...playLists];
      const defPlayList = usePlayLists.find((pl) => pl.is_default);

      const virtualPlayList = usePlayLists.find((pl) => pl.is_virtual) || null;

      const devicesPlayListsIds = [...((new Set(selectedDevices
        .map((d) => d.playlist).filter((pl) => pl))) || [])];

      const devicesPlayListId = devicesPlayListsIds.length === 1
        ? devicesPlayListsIds[0] : (defPlayList?.id || 0);

      const usePlayList = playLists.find((pl) => pl.id === (
        selectedPlaylist ? selectedPlaylist?.id : devicesPlayListId));

      const usePlaylistId = virtualPlayList
        ? virtualPlayList.id
        : ((selectedPlaylist?.id || usePlayList?.id || defPlayList?.id || playLists[0]?.id || 0));

      handleSelectPlayList(usePlaylistId, virtualPlayList || false);

      setLoaders((prevState) => ({ ...prevState, playLists: false }));
    }
  }, [playListsNames]);

  useEffect(() => {
    if (selectedDevices) {
      const usePlaylistId = selectedPlaylist?.is_virtual
        ? (defaultPlaylist?.id || 0)
        : (selectedPlaylist?.id || 0);

      if (usePlaylistId > 0) {
        getPlaylistGroups(usePlaylistId);
      }
    }
  }, [selectedPlaylist?.id]);

  useEffect(() => {
    getChannelsByGroupId(selectedGroupId);
  }, [selectedGroupId]);

  useEffect(() => {
    if (createdPlaylist) {
      handleSelectPlayList(createdPlaylist.id);
      setCreatedPlaylist(null);
    }
  }, [createdPlaylist]);

  useEffect(() => {
    if ((needShowChannel > 0)
      && ((channels || []).length > 0)
      && channels[needShowChannel].is_hidden
    ) {
      handleHideChannel(needShowChannel, false);
      setNeedShowChannel(0);
    }
  }, [needShowChannel, channels]);
  const value = {
    loaders,
    groups,
    channels,
    selectedGroupId,
    changeCurrentGroup,
    playLists,
    channelsFilters,
    setChannelsFilters,
    getChannelsByGroupId,
    getPlaylistGroups,
    getDevicePlaylist,
    handleCreatePlaylist,
    handleDeletePlaylist,
    handleEditPlaylist,
    handleCreatePlaylistVirtual,
    handleCreateGroup,
    handleEditGroup,
    handleDeleteGroup,
    handleStarChannel,
    handleHideChannel,
    handleHideAllChannels,
    handleDisplaySelectedChannels,
    handleDisplayAllChannels,
    handleHiddenGroup,
    handleHideAllGroups,
    handleDisplayAllGroups,
    handleSelectChannel,
    handleSelectPlayList,
    handleRemoveSelectChannel,
    handleDragEndGroup,
    handleDragEndChannel,
    setPlaylistDevice,
    handleDragEndChannelToGroup,
    selectedPlaylist,
    defaultPlaylist,
    // getDevices,
    selectedDevices,
    // checkDeviceById,
    channelsMessage,
    setChannelsMessage,
    setNeedShowChannel,
  };

  return (
    <ChannelsContext.Provider value={value}>
      {children}
    </ChannelsContext.Provider>
  );
};

export const useChannelsContext = () => useContext(ChannelsContext);
