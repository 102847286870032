import React from 'react';
import styled from 'styled-components';
import Crown from '@/assets/icons/crown.svg';

export default function PremiumIcon(props) {
  const {
    width,
    height,
    gray,
    ...otherProps
  } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Icon width={width} height={height} src={Crown} gray={gray} {...otherProps} />;
}

const Icon = styled.img`
  max-height: ${(props) => props.height || '100%'};
  max-width: ${(props) => props.width || '100%'};
  filter: ${({ gray }) => gray && 'grayscale(100%)'}
`;
