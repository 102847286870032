export default {
  users: '/api/v1/user/admin/user',
  user: (userId) => `/api/v1/user/admin/user/${userId}`,
  userBlock: (userId, blockId = 0) => `/api/v1/user/admin/user/${userId}/block${blockId > 0 ? `/${blockId}` : ''}`,
  userActiveBlock: (userId) => `/api/v1/user/admin/user/${userId}/block/actual`,

  userDevices: (userId, deviceId, taskId = '') => `/api/v1/user/admin/user/${userId}/device${(deviceId || 0) > 0 ? `/${deviceId}` : ''}${(taskId?.length || 0) > 0 ? `?task_id=${taskId}` : ''}`,
  userPlayList: (userId) => `/api/v1/user/admin/user/${userId}/playlists`,

  userDeviceHistory: (userId, deviceId, filterString) => `/api/v1/user/admin/user/${userId}/device/${deviceId}/logs/${(filterString?.length || 0) > 0 ? `?${filterString}` : ''}`,

  userGroups: '/api/v1/permissions/admin/groups/',
  userGroup: (groupId) => `/api/v1/permissions/admin/groups/${groupId}/`,
  userGroupAttach: (groupId) => `/api/v1/permissions/admin/groups/${groupId}/users/`,
  userGroupDetach: (userId, groupId) => `/api/v1/permissions/admin/groups/${groupId}/users/${userId}/`,

  userBalance: (userId) => `/api/v1/user/admin/user/${userId}/balance/`,
  userDiscount: (userId) => `/api/v1/user/admin/user/${userId}/discount/`,
  userReplenishmentStatus: (userId) => `/api/v1/user/admin/user/${userId}/replenishment/`,
  userReplenishmentByBonusStatus: (userId) => `/api/v1/user/admin/user/${userId}/replenishment_by_bonus/`,
  userPassword: (userId) => `/api/v1/user/admin/user/${userId}/raw-password/`,

  userPaymentSystemsDisabled: (userId) => `/api/v1/user/admin/user/${userId}/disable_payment_systems/`,

  resetDeviceTheme: (userId, deviceId, taskId = '') => `/api/v1/user/admin/user/${userId}/device/${deviceId}/clear-ministra-user-theme/${(taskId?.length || 0) > 0 ? `?task_id=${taskId}` : ''}`,
  regenOttId: (userId, deviceId) => `/api/v1/user/admin/user/${userId}/device/${deviceId}/refresh-ott-id/`,

  userActivityLog: (userId, filterString = '') => `/api/v1/actions/admin/logs/user/${userId}${(filterString?.length || 0) > 0 ? `?${filterString}` : ''}`,

  userReferralsLinkFollows: (userId, filterString = '') => `/api/v1/user/admin/user/${userId}/referrals/link-click-throughs${(filterString?.length || 0) > 0 ? `?${filterString}` : ''}`,
  userReferralsTransactions: (userId, filterString = '') => `/api/v1/user/admin/user/${userId}/referrals/transactions${(filterString?.length || 0) > 0 ? `?${filterString}` : ''}`,
};
