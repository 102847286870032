import React from 'react';
import { RichUtils } from 'draft-js';
import {
  changeDepth,
  getBlockBeforeSelectedBlock,
  getSelectedBlock,
  isListBlock,
} from 'draftjs-utils';

import ToolbarButton from './ToolbarButton';
import UnorderedIcon from '@/assets/icons/TextEditor/unordered.svg';
import OrderedIcon from '@/assets/icons/TextEditor/ordered.svg';
import IndentIcon from '@/assets/icons/TextEditor/indent.svg';
import OutdentIcon from '@/assets/icons/TextEditor/outdent.svg';

const ListStyleControls = ({
  editorState,
  onEditorStateChange,
}) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const toggleBlockType = (blockStyle) => {
    onEditorStateChange(
      RichUtils.toggleBlockType(
        editorState,
        blockStyle,
      ),
    );
  };

  const currentBlock = editorState ? getSelectedBlock(editorState) : undefined;

  const isIndentDisabled = () => {
    const previousBlock = getBlockBeforeSelectedBlock(editorState);
    return !previousBlock
      || !isListBlock(currentBlock)
      || previousBlock.get('type') !== currentBlock.get('type')
      || previousBlock.get('depth') < currentBlock.get('depth');
  };

  const isOutdentDisabled = () => (
    !currentBlock
    || !isListBlock(currentBlock)
    || currentBlock.get('depth') <= 0
  );

  const adjustDepth = (adjustment) => {
    const newState = changeDepth(editorState, adjustment, 4);
    if (newState) {
      onEditorStateChange(newState);
    }
  };

  return (
    <>
      <ToolbarButton
        className="rdw-option-wrapper"
        active={blockType === 'unordered-list-item'}
        label="Неупорядоченный"
        icon={UnorderedIcon}
        onToggle={() => {
          toggleBlockType('unordered-list-item');
        }}
      />
      <ToolbarButton
        className="rdw-option-wrapper"
        active={blockType === 'ordered-list-item'}
        label="Упорядоченный"
        icon={OrderedIcon}
        onToggle={() => {
          toggleBlockType('ordered-list-item');
        }}
      />
      <ToolbarButton
        className="rdw-option-wrapper"
        disabled={isIndentDisabled()}
        label="Отступ"
        icon={IndentIcon}
        onToggle={() => {
          adjustDepth(1);
        }}
      />
      <ToolbarButton
        className="rdw-option-wrapper"
        disabled={isOutdentDisabled()}
        label="Выступ"
        icon={OutdentIcon}
        onToggle={() => {
          adjustDepth(-1);
        }}
      />
    </>
  );
};

export default ListStyleControls;
