import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UsersStatTabs from './StatTabs';

import {
  text01,
  borderSecondary,
  white,
  activeTertiary,
} from '@/common/constants/theme';

const UsersMainUserStats = ({ user }) => {
  const [userStatsTabs, setUserStatsTabs] = useState(null);
  const [activeTab, setActiveTab] = useState('withdraw');

  function selectStats(tab) {
    setActiveTab(tab);
  }

  useEffect(() => {
    if (!user?.id) { return; }
    setUserStatsTabs(UsersStatTabs(user.id));
  }, [user]);

  useEffect(() => {
    selectStats(activeTab);
  }, [activeTab]);

  return (
    <Container>
      <Title>
        Статистика пользователя:
      </Title>
      <Wrapper>
        {userStatsTabs && Object.keys(userStatsTabs).map((tab) => (
          <Tab
            key={tab}
            isActive={activeTab === tab}
            onClick={() => selectStats(tab)}
          >
            {userStatsTabs[tab].label}
          </Tab>
        ))}
      </Wrapper>
      {userStatsTabs && userStatsTabs?.[activeTab]?.component}
    </Container>
  );
};

export default UsersMainUserStats;

const Container = styled.div`
  margin-top: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Title = styled.div`
  margin-bottom: 20px;

  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${text01};
  line-height: 24px;
`;

const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 34px;
  padding: 0 18px;

  border: 1px solid ${borderSecondary};
  border-radius: 5px;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 16px;
  color: ${text01};
  line-height: 19px;

  transition: 0.2s ease;

  &:not(:last-child) {
    margin-right: 10px;
  }

  ${({ isActive }) => isActive && `
    background-color: ${activeTertiary};
    border-color: transparent;

    color: ${white};
  `}
`;
