import React from 'react';
import styled from 'styled-components';

import { white, secondary } from '@/common/constants/theme';

const HomeAdvCard = ({
  mainTitle,
  customTitle,
  title,
  descr,
}) => (
  <Container>
    <Wrapper flex>
      <Wrapper main className="home-adv-card-main">
        {customTitle || (
          <MainTitle>{mainTitle}</MainTitle>
        )}
      </Wrapper>
      <Wrapper leftMargin>
        <Title>{title}</Title>
        <Text>{descr}</Text>
      </Wrapper>
    </Wrapper>
  </Container>
);

export default HomeAdvCard;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 30px;

  width: calc(50% - 20px);
  height: 100px;
  margin-bottom: 20px;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 9px;

  &:not(:nth-child(2n)) {
    margin-right: 20px;

    & .home-adv-card-main {
      width: 160px;
    }
  }

  &:nth-child(2n) {
    & .home-adv-card-main {
      width: 60px;
      margin-left: 20px;
    }
  }

  @media (max-width: 1360px) {
    padding-right: 20px;
  }

  @media (max-width: 1198px) {
    padding-right: 30px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  ${({ flex }) => flex && ('display: flex;')}
  ${({ main }) => main && (`
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
  `)};
  ${({ leftMargin }) => leftMargin && ('margin-left: 25px;')}
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${white};
  opacity: 0.8;
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const Title = styled(Text)`
  margin-bottom: 2px;

  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  opacity: 1;
`;

const MainTitle = styled(Text)`
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  color: ${secondary};
  opacity: 1;

  & svg {
    margin-left: 10px;
  }
`;
