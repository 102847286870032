import React from 'react';
import styled from 'styled-components';

import { white } from '@/common/constants/theme';

const Table = ({
  children,
  page,
  limit,
  dataLength,
  onFiltersChange,
  cellSizes,
  textAlign,
  adaptiveSize,
}) => (
  <Container>
    {React.Children.map(children, (element) => element && React.cloneElement(element, {
      ...element.props,
      page,
      limit,
      dataLength,
      cellSizes,
      textAlign,
      pagesCount: Math.ceil(dataLength / limit),
      adaptiveSize,
      filtersHandler: (field, value) => onFiltersChange(field, value),
    }))}
  </Container>
);

export default Table;

const Container = styled.div`
  position: relative;
  width: 100%;

  background: ${white};
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;
`;
