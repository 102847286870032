import React, { useRef } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptcha = ({ appToken = '', onCheck = null, size = 'normal' }) => {
  const captchaRef = useRef(null);

  const handleChange = async (v) => {
    if (onCheck) { onCheck(v); }
  };

  return (
    <ReCAPTCHA
      sitekey={appToken}
      ref={captchaRef}
      onChange={handleChange}
      size={size}
    />
  );
};

export default ReCaptcha;
