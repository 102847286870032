/* eslint-disable no-shadow */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

import { useAdminContext } from '../../../../../AdminContext';

const PlayersContext = createContext({});

const initialFilters = {
  page: 1,
  limit: 50,
};

export const PlayersContextProvider = ({ children }) => {
  const [filters, setFilters] = useState(initialFilters);
  const [channels, setChannels] = useState(null);
  const [channelsCount, setChannelsCount] = useState(0);
  const [channelsGroups, setChannelsGroups] = useState(null);
  const [selectedChannelGroup, setSelectedChannelGroup] = useState([]);

  const {
    isChannelsBusy,
    isChannelsGroupsBusy,
    getChannels,
    getGroups,
  } = useAdminContext();

  useEffect(() => {
    if (!channelsGroups && !isChannelsGroupsBusy) {
      getGroups()
        .then((res) => {
          setChannelsGroups(res || []);
        });
    }
  }, [channelsGroups]);

  useEffect(() => {
    setFilters({ ...filters, channelGroup: selectedChannelGroup?.id || 0 });
  }, [selectedChannelGroup]);

  useEffect(() => {
    if (!isChannelsBusy) {
      getChannels(filters)
        .then((res) => {
          setChannelsCount(res?.count || 0);
          setChannels(res?.results || []);
        });
    }
  }, [filters]);

  const value = {
    filters,
    setFilters,
    channels,
    channelsCount,
    channelsGroups,
    selectedChannelGroup,
    setSelectedChannelGroup,
  };

  return (
    <PlayersContext.Provider value={value}>
      {children}
    </PlayersContext.Provider>
  );
};

export const usePlayersContext = () => useContext(PlayersContext);
