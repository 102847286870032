import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Button from './Button';
import modalClose from '../../assets/icons/closeWhite.svg';
import { green, red } from '../constants/theme';

const ModalSubmitAlt = ({
  onCloseModal,
  isOpen,
  isHidden = true,
  width,
  modalType,
  iconBg,
  submitButton,
  onSubmit,
  children,
}) => {
  const onCloseModalHandler = () => {
    if (onCloseModal) onCloseModal();
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          onCloseModal();
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalWindow
            isHidden={isHidden}
            width={width}
            iconBg={iconBg}
            type={modalType}
          >
            <ModalClose
              onClick={onCloseModalHandler}
            />
            <ModalContent>
              {children}
              {onSubmit
                && (
                  <Button
                    isUpperCase
                    onClick={onSubmit}
                    width="small"
                  >
                    {submitButton}
                  </Button>
                )}
            </ModalContent>
          </ModalWindow>
        </ModalOverlay>
      )}
    </>
  );
};

export default ModalSubmitAlt;

const ModalContent = styled.div`
  padding: 2rem 2rem;
  font-family: Gilroy, sans-serif;
  font-size: 130%;
  line-height: 1.15em;
  text-align: center;
  color: white;
`;

const ModalClose = styled.div`
  background-image: url(${modalClose});
  width: 11px;
  height: 11px;
  position: absolute;
  right: 13px;
  top: 13px;
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(110, 110, 110, .45);
  z-index: 100;
  cursor: pointer;
`;

const ModalWindow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 4px;
  width: ${({ width }) => width || '500px'};
  height: max-content;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.type && props.type === 'error' ? red : green)};
  background-image: ${(props) => (props.iconBg ? `url('${props.iconBg}')` : '')};
  background-position: 10px 10px;
  background-size: 15%;
  background-repeat: no-repeat;
  ${({ isHidden }) => isHidden && ('overflow: hidden;')};
  cursor: default;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
