import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  secondary, background01, text01, text04,
} from '@/common/constants/theme';
import pageSizes from '@/common/constants/pageSizes';
import ArrowLeft from '../../../../icons/ArrowLeft';
import ArrowRight from '../../../../icons/ArrowRight';
import ArrowToStart from '../../../../icons/ArrowToStart';
import ArrowToEnd from '../../../../icons/ArrowToEnd';

const INTERVAL_SYMBOLS = '...';

const TablePagination = ({
  quantity,
  setFilters,
  filters,
  customPageSizes = null,
}) => {
  const { t } = useTranslation('translations');
  const { limit, page } = filters;
  const usePageSize = customPageSizes || pageSizes;
  const pagesNumber = Math.ceil(quantity / limit);

  const [displayPages, setDisplayPages] = useState([]);

  const changeFilters = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const changePageSize = (value) => {
    localStorage.setItem('deviceListLimit', value);
    setFilters({ ...filters, limit: value, page: 1 });
  };

  const getFormatedPages = () => {
    if (pagesNumber > 7) {
      if ([1, 2].includes(page)) {
        return [1, 2, 3, INTERVAL_SYMBOLS, pagesNumber];
      }
      if (page === 3) {
        return [1, 2, 3, 4, INTERVAL_SYMBOLS, pagesNumber];
      }
      if ([pagesNumber, pagesNumber - 1].includes(page)) {
        return [1, INTERVAL_SYMBOLS, pagesNumber - 2, pagesNumber - 1, pagesNumber];
      }
      if ([pagesNumber - 2, pagesNumber - 3].includes(page)) {
        return [1, INTERVAL_SYMBOLS, pagesNumber - 3,
          pagesNumber - 2, pagesNumber - 1, pagesNumber];
      }
      return [1, INTERVAL_SYMBOLS, page - 1, page, page + 1, INTERVAL_SYMBOLS, pagesNumber];
    }
    return Array.from({ length: pagesNumber }, (_, i) => i + 1);
  };

  useEffect(() => {
    setDisplayPages(getFormatedPages(pagesNumber));
  }, [quantity, filters, customPageSizes]);

  return (
    <Container>
      <ViewFilter>
        {usePageSize && ((usePageSize?.length || 0) > 1) && (
          <>
            <Text>{t('table.pagination')}</Text>
            {usePageSize.map((item, index) => (
              <Number
                key={item}
                selected={+limit === item}
                onClick={() => changePageSize(item, index)}
              >
                {item}
              </Number>
            ))}
          </>
        )}
      </ViewFilter>
      <Pagination>
        <Arrows disabled={page === 1}>
          <ArrowToStart onClick={() => changeFilters('page', 1)} />
          <ArrowLeft onClick={() => changeFilters('page', page - 1)} />
        </Arrows>
        {displayPages.map((pageItem, index) => (
          <PageNumber
            /* eslint-disable-next-line react/no-array-index-key */
            key={`PDTP-I--${pageItem}-${index}`}
            selected={pageItem === page}
            onClick={() => {
              let useValue = pageItem;
              if (pageItem === INTERVAL_SYMBOLS) {
                const prevValue = parseInt((displayPages?.[index - 1] || 1), 10);
                const nextValue = parseInt((displayPages?.[index + 1] || pagesNumber), 10);
                useValue = Math.floor((nextValue + prevValue - 1) / 2);
              }
              changeFilters('page', useValue);
            }}
          >
            {pageItem}
          </PageNumber>
        ))}
        <Arrows disabled={page === pagesNumber}>
          <ArrowRight onClick={() => changeFilters('page', page + 1)} />
          <ArrowToEnd onClick={() => changeFilters('page', pagesNumber)} />
        </Arrows>
      </Pagination>
    </Container>
  );
};

export default TablePagination;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background: ${background01};
  box-shadow: 0 0 20px rgb(192 192 192 / 0.5);
  border-radius: 0 0 4px 4px;
  padding: 1rem;
  z-index: 1;
`;

const ViewFilter = styled.div``;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.16px;
  color: ${text01};
  margin: 0 1rem;
`;

const Number = styled.span`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  cursor: pointer;
  margin: 0 .5rem;
  color: ${({ selected }) => (selected ? text04 : secondary)};
`;

const PageNumber = styled(Number)`
  color: ${({ selected }) => (selected ? text01 : text04)};
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20rem;
`;

const Arrows = styled.div`
  margin: 0 .5rem;
  svg {
    margin: 0 .3rem;
    cursor: pointer;
  }
  path {
    fill: ${secondary};
  }
  ${({ disabled }) => disabled && `
  pointer-events: none;
  path {
    fill: ${text04};
  }
`}
`;
