import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import deleteIconSrc from '@/assets/icons/delete-trash.svg';
import alertIconSrc from '@/assets/icons/alertIcon.svg';
import ArrowIcon from '@/assets/icons/arrow-down-black.svg';
import { white } from '@/common/constants/theme';

import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Modal from '@/common/components/ModalNew';
import Button from '@/common/components/Button';
import { useMinistraContext } from '../MinistraContext';

const AdminDeleteModal = ({
  isOpen,
  onClose,
  serverName,
  handleDelete,
}) => {
  const {
    getAllMinistra,
    ministra,
  } = useMinistraContext();

  const avalibleMinistra = ministra.filter((min) => min.title !== serverName);

  const [step, setStep] = useState(0);
  const [serverId, setServerId] = useState(null);

  useEffect(() => {
    getAllMinistra();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalWithoutContent
        isOpen={isOpen && step === 0}
        onCloseModal={onClose}
        icon={deleteIconSrc}
      >
        <Container>
          <Text bottomMargin>
            Вы действительно хотите удалить
            {' '}
            <br />
            {serverName}
            ?
          </Text>
          <Text>
            ВНИМАНИЕ! Операция необратима!
          </Text>
          <StyledButton
            isBoldText
            isUpperCase
            onClick={() => {
              setStep(1);
              // handleDelete();
              // onClose();
            }}
          >
            Удалить
          </StyledButton>
        </Container>
      </ModalWithoutContent>
      <Modal
        onCloseModal={onClose}
        title="Сервер"
        icon={alertIconSrc}
        isOpen={isOpen && step !== 0}
      >
        <Wrapper>
          <SelectLabel>Выберите сервер для переноса пользователей:</SelectLabel>
          <Select
            value={serverId}
            onChange={({ target }) => setServerId(target.value)}
          >
            <option disabled={serverId}>Выберите опцию</option>
            {avalibleMinistra.map(({ id, title }) => (
              <option value={id}>{title}</option>
            ))}
          </Select>
          <Warning>
            {`ВНИМАНИЕ! Все пользователи удаленного сервера ${serverName} будут перенесены на выбранный сервер.`}
          </Warning>
          <StyledButton
            isBoldText
            isUpperCase
            disabled={!serverId}
            onClick={() => {
              handleDelete(serverId);
              onClose();
              setStep(0);
            }}
          >
            Применить
          </StyledButton>
        </Wrapper>
      </Modal>
    </>
  );
};

export default AdminDeleteModal;

const Container = styled.div``;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 15px;')}

  font-family: Gilroy;
  font-size: 15px;
  color: ${white};
  white-space: pre-wrap;
  text-align: center;
  letter-spacing: 0.16px;
  line-height: 20px;
`;

const StyledButton = styled(Button)`
  width: 100px;
  margin-top: 20px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const SelectLabel = styled.span`
  width: 100%;
  margin-bottom: 20px;
`;

const Warning = styled.span`
  margin-top: 23px;
  margin-bottom: 38px;
  text-align: center;
`;

const Select = styled.select`
  height: 32px;
  width: 100%;
  padding: 0 10px;

  background: ${white};
  border: none;
  border-radius: 2px;
  border: 1px solid #ECECEC;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position-x: calc(100% - 13px);
  background-position-y: 13px;
`;
