import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { AppContextProvider } from './AppContext';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';

import './i18n';

import './App.css';
import './index.css';
import 'antd/dist/antd.css';

Sentry.init({
  dsn: 'https://763838da2e9c4cdbb74423ac858d1538@o725232.ingest.sentry.io/5785170',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
});

document.addEventListener('DOMContentLoaded', async () => {
  ReactDOM.render(
    <Suspense fallback={null}>
      <React.StrictMode>
        <Router>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </Router>
      </React.StrictMode>
    </Suspense>,
    document.getElementById('root'),
  );
});
