import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  text01,
  primary,
  text02,
} from '@/common/constants/theme';

import Dropdown from '@/common/components/Dropdown';
import Loader from '@/common/components/ResponsiveLoader';
import InputProfile from '@/common/components/Input';
import EditIcon from '@/common/components/icons/EditIcon';
import CheckEmailModal from './CheckEmailModal';

import { setSnack } from '@/store/user/selectors';

import { useAppContext } from '@/AppContext';
import { useProfileContext } from '../ProfileContext';

const PersonalInfo = ({ history }) => {
  const { t } = useTranslation(['translations']);
  const dispatch = useDispatch();

  const [timezoneLoading, setTimezoneLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [isCheckEmailModalType, setCheckEmailModalType] = useState('');
  const [timezones, setTimezonesArray] = useState([]);
  const { userInfo: { timezone, email } } = useAppContext();
  const {
    getTimezones,
    handleTimeZone,
    changeEmailRequest,
    resetPassword,
    logoutFromAll,
  } = useProfileContext();

  useEffect(() => {
    getTimezones()
      .then((res) => {
        setTimezonesArray(
          res.map((item) => {
            const city = item.timezone.split('/')[1];
            const localizedCity = t(`timezone.cities.${city}`);
            return {
              value: item.timezone,
              title: `(GMT${item.offset}) ${localizedCity.startsWith('cities.') ? city : localizedCity} (${item.country})`,
              key: item.timezone,
            };
          }),
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRequestResetEmail = () => {
    setEmailLoading(true);
    changeEmailRequest()
      .then(() => {
        setCheckEmailModalType('email');
        setEmailLoading(false);
      });
  };

  const onRequestResetPassword = () => {
    setPasswordLoading(true);
    resetPassword(email)
      .then(() => {
        setCheckEmailModalType('password');
        setPasswordLoading(false);
      }).catch((err) => dispatch(setSnack(err)));
  };

  const logout = () => {
    logoutFromAll()
      .then(({ success }) => (
        success && history.push('/personal-page')
      ));
  };

  const onChangeTimeZone = (item) => {
    setTimezoneLoading(true);
    handleTimeZone(item.value).then(() => setTimezoneLoading(false));
  };

  return (
    <>
      <CheckEmailModal
        isOpen={!!isCheckEmailModalType}
        onClose={() => setCheckEmailModalType('')}
        email={email}
        type={isCheckEmailModalType}
      />
      <BlockTitle>
        {t('profile.personalInfo.title')}
      </BlockTitle>
      <Content>
        <InputContainer>
          <Label>E-mail</Label>
          <InputProfile disabled value={email} />
          {emailLoading ? (
            <LoaderContainer>
              <Loader size={3} />
            </LoaderContainer>
          ) : (
            <EditButton onClick={onRequestResetEmail}>
              <EditIcon />
            </EditButton>
          )}
        </InputContainer>
        <InputContainer>
          <Label>{t('profile.personalInfo.password')}</Label>
          <InputProfile disabled type="password" value="********" />
          {passwordLoading ? (
            <LoaderContainer>
              <Loader size={3} />
            </LoaderContainer>
          ) : (
            <EditButton onClick={onRequestResetPassword}>
              <EditIcon />
            </EditButton>
          )}
        </InputContainer>
        <InputContainer>
          <StyledDropdown
            placeholder={t('profile.personalInfo.timeZone')}
            options={timezones}
            isBoldText
            value={timezones.find((item) => item.key === timezone)}
            scrollToValue
            onSelect={onChangeTimeZone}
            height="2rem"
          />
          {timezoneLoading && <Loader size={3} />}
        </InputContainer>
        <Nav>
          <Button onClick={logout}>
            {t('profile.personalInfo.buttonText')}
          </Button>
        </Nav>
      </Content>
    </>
  );
};

export default withRouter(PersonalInfo);

const BlockTitle = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${text01};
`;

const Content = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const StyledDropdown = styled(Dropdown)`
  width: calc(100% - 6.5rem);
  margin: 0 1rem 0 4.5rem;
`;

const Nav = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: .75rem;
  margin-top: 2.5rem;
`;

const Button = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: ${primary};
  margin-right: 2rem;
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: ${text02};
  margin-right: 1.5rem;
  min-width: 3rem;
`;

const EditButton = styled.div`
  padding: .25rem .5rem;
  margin-left: .25rem;
  cursor: pointer;
  border-radius: .25rem;
  :hover {
    background: #eef1fb;
  }
`;

const LoaderContainer = styled.div`
  padding: .25rem .5rem;
  margin-left: .75rem;
`;
