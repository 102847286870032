import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { activePrimary, text01 } from '@/common/constants/theme';

const EpgUpdatePanel = (
  text,
) => {
  const { t } = useTranslation(['translations']);

  const { text: resultText } = text;

  return (
    <>
      {resultText !== '' ? (
        <ResultWrapper>
          <Text>{t('admin.iptv.ministra.updatedEpg')}</Text>
          <EpgUpdateResult>{ ReactHtmlParser(resultText) }</EpgUpdateResult>
        </ResultWrapper>
      ) : (
        <Wrapper>
          <Text>{t('admin.iptv.ministra.updatingEpg')}</Text>
          <LoaderWrapper>
            <Loader>
              <Load />
              <Load />
              <Load />
            </Loader>
          </LoaderWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default EpgUpdatePanel;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 175px;
  margin-top: 35px;
`;

const ResultWrapper = styled.div`
  min-width: 175px;
  margin-top: 35px;
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 15px;
`;

const LoaderWrapper = styled.div`
  width: calc(100% - 200px);

  & ${Text} {
    width: 200px;
  }
`;

const Load = styled.div`
  width: 12px;
  height: 12px;

  position: absolute;

  background-color: ${activePrimary};
  border-radius: 20px;
`;

const Loader = styled.div`
  width: 12px;
  margin-left: calc(50% - 100px);
  position: relative;

  & ${Load} {
    animation: load ease 1.5s infinite;

    &:first-child {
      left: -17px;
    }

    &:nth-child(2) {
      left: 0;

      animation-delay: 0.15s;
    }

    &:last-child {
      right: -17px;

      animation-delay: 0.3s;
    }
  }

  @keyframes load {
    0%,
    100% {
      transform: scale(0);
    }

    50% {
      transform: scale(1);
    }
  }
`;

const EpgUpdateResult = styled.div`

`;
