import _isEmpty from 'lodash/isEmpty';
import {
  TOKEN_KEY, TOKEN_EXPIRED_KEY,
  USER_INFO,
} from '@/store/constants';

const { parse } = JSON;
const { stringify } = JSON;

const auth = {
  refreshTokenInterval: 1000 * 60 * 60
    * (process?.env?.REACT_APP_TOKEN_REFRESH_INTERVAL_HOURS || 1),
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }

    return null;
  },

  clearAppStorage() {
    if (localStorage) {
      localStorage.clear();
    }

    if (sessionStorage) {
      sessionStorage.clear();
    }
  },

  clearToken(tokenKey = TOKEN_KEY) {
    return auth.clear(tokenKey);
  },

  // clearUserInfo(userInfo = USER_INFO) {
  //   return auth.clear(userInfo);
  // },

  get(key) {
    if (localStorage && localStorage.getItem(key)) {
      try {
        return parse(localStorage.getItem(key)) || null;
      } catch (e) {
        return null;
      }
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return parse(sessionStorage.getItem(key)) || null;
    }

    return null;
  },

  getToken(tokenKey = TOKEN_KEY) {
    return auth.get(tokenKey);
  },

  getTokenExpired() {
    return auth.get(TOKEN_EXPIRED_KEY);
  },

  getUserInfo(userInfo = USER_INFO) {
    return auth.get(userInfo);
  },

  set(value, key, isLocalStorage) {
    if (_isEmpty(value)) {
      return null;
    }

    if (isLocalStorage && localStorage) {
      return localStorage.setItem(key, stringify(value));
    }

    if (sessionStorage) {
      return sessionStorage.setItem(key, stringify(value));
    }

    return null;
  },

  setToken(value = '', isLocalStorage = true, tokenKey = TOKEN_KEY) {
    return auth.set(value, tokenKey, isLocalStorage);
  },

  setTokenExpired(value = '') {
    return auth.set(value, TOKEN_EXPIRED_KEY, true);
  },

  // setUserInfo(value = '', isLocalStorage = true, userInfo = USER_INFO) {
  //   return auth.set(value, userInfo, isLocalStorage);
  // },
};

export default auth;
