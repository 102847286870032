import React, { useState, useEffect, useRef } from 'react';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  white,
  primary,
  secondary,
  text04,
  text01,
  text03,
  activePrimary,
  borderSecondary,
} from '../constants/theme';

import ArrowLeft from './icons/ArrowLeft';
import CloseIcon from '../../assets/icons/close.svg';

export default function Dropdown({
  value,
  defaultValue = {},
  options,
  onSelect,
  onListOpen,
  onListClose,
  showSearch = false,
  placeholder = 'Выберите опцию',
  allowClear = false,
  isUpperCase = false,
  color,
  noResultsText,
  isBoldText,
  height,
  optionHeight,
  itemsShow = 5,
  className,
  menuClassName,
  isCapitalize = true,
  isDisabled,
  isBusy,
  scrollToValue = true,
}) {
  const { t } = useTranslation('translations');
  const [isOpen, setStateOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [isSearchMode, setSearchMode] = useState(false);
  const [isHoverIcon, setHoverIcon] = useState(false);
  const [selectedValue, setValue] = useState(value || defaultValue);
  const [useColor, setUseColor] = useState(color);

  const selectorMenuRef = useRef(null);

  const setOpen = (val) => {
    if (!isDisabled && !isBusy) {
      setStateOpen(val);
      const openHandler = val ? onListOpen : onListClose;
      if (openHandler) {
        openHandler();
      }
    }
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          setOpen(false);
          setQuery('');
          setSearchMode(false);
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onSelectValue = (object) => {
    setValue(object);
    if (onSelect) {
      onSelect(object);
    }
    setOpen(false);
    setQuery('');
    setSearchMode(false);
  };

  const onFocusInput = () => {
    setSearchMode(true);
    setOpen(true);
  };

  const clearValue = () => {
    setValue({});
    if (onSelect) {
      onSelect({});
    }
    setHoverIcon(false);
  };

  const optionsForRender = options.filter((option) => (
    option.title.toLowerCase().includes(query.toLowerCase())
  ));

  const onChangeInput = (event) => {
    if (event.key === 'Enter') {
      onSelectValue(optionsForRender[0]);
      setSearchMode(false);
      document.getElementById('input').blur();
    }
  };

  useEffect(() => {
    setValue(value || defaultValue);
  }, [value]);

  useEffect(() => {
    setUseColor((isBusy || isDisabled) ? text04 : (value?.color || color));
  }, [color, isBusy, isDisabled, value]);

  useEffect(() => {
    if (selectorMenuRef.current && isOpen && scrollToValue) {
      const selEl = selectorMenuRef.current.querySelector('.selected');
      if (selEl) {
        selectorMenuRef.current.scrollTop = selEl.offsetTop
          - selectorMenuRef.current.getBoundingClientRect().height / 2
          + selEl.getBoundingClientRect().height / 2;
      }
    }
  }, [isOpen, selectorMenuRef.current, scrollToValue, selectedValue]);

  return (
    <Container className={className}>
      <FlexContainer ref={wrapperRef}>
        <InputWrapper
          onClick={!showSearch && (() => setOpen(!isOpen))}
          showSearch={showSearch}
          isMenuOpen={isOpen}
          onMouseEnter={() => selectedValue.key && allowClear && setHoverIcon(true)}
          onMouseLeave={() => selectedValue.key && allowClear && setHoverIcon(false)}
          color={useColor}
          height={height}
          isBusy={!!isBusy}
          isDisabled={isDisabled}
        >
          <Input
            id="input"
            value={isSearchMode ? query : (selectedValue.title || selectedValue.value || '')}
            placeholder={placeholder}
            disabled={!showSearch}
            onFocus={onFocusInput}
            onChange={({ target }) => setQuery(target.value)}
            onKeyPress={onChangeInput}
            isUpperCase={isUpperCase}
            isBoldText={isBoldText}
            color={useColor}
            isCapitalize={isCapitalize}
            isBusy={!!isBusy}
          />
          <ActionIcon>
            {isHoverIcon ? (
              <Icon
                src={CloseIcon}
                onClick={clearValue}
              />
            ) : (
              <ArrowIcon
                onClick={() => setOpen(!isOpen)}
                isOpen={isOpen}
                color={useColor}
                isBusy={!!isBusy}
                isDisabled={isDisabled}
              >
                <ArrowLeft />
              </ArrowIcon>
            )}
          </ActionIcon>
        </InputWrapper>
        {isOpen && (
          <Wrapper
            className={menuClassName || ''}
          >
            <SelectorMenu
              ref={selectorMenuRef}
              optionHeight={optionHeight}
              itemsShow={itemsShow}
            >
              {optionsForRender.length ? (
                optionsForRender.map((option) => (
                  <Option
                    className={option.key === selectedValue.key && 'selected'}
                    key={option.key}
                    selected={option.key === selectedValue.key}
                    onClick={() => onSelectValue(option)}
                    height={optionHeight}
                    color={option.color || null}
                  >
                    {option.title || option.value}
                  </Option>
                ))
              ) : (
                <NoData>{noResultsText || t('table.noResults')}</NoData>
              )}
            </SelectorMenu>
          </Wrapper>
        )}
      </FlexContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
`;

const ActionIcon = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(100% + 7px);
  width: 100%;
`;

const InputWrapper = styled.div`
  background-color: ${white};
  border: 1px solid #ECECEC;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 4px 11px;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height || '28px'};
  cursor: ${({ showSearch, isDisabled, isBusy }) => {
    if (isDisabled || isBusy) return 'default';
    return (showSearch ? 'text' : 'pointer');
  }};
  ${({ isDisabled }) => isDisabled && `
    input {
      cursor: default;
    }
  `}
  ${({ isDisabled, color }) => !isDisabled && `
    :hover {
      border-color: ${color || secondary};
    }
  `}
  :focus {
    border-color: ${({ color }) => color || secondary};
    outline: none;
  }
  :active {
    border-color: ${({ color }) => color || secondary};
  }
  ${({ isMenuOpen }) => isMenuOpen && `
    border-color: ${({ color }) => color || secondary};
  `}

`;

const Input = styled.input`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  letter-spacing: 0.16px;
  outline: none;
  border: none;
  width: 100%;
  padding-right: 11px;
  background: ${white};
  color: ${({ color }) => color || text04};
  font-weight: ${({ isBoldText }) => (isBoldText ? 600 : 400)};
  text-transform: ${({ isUpperCase, isCapitalize }) => (isUpperCase ? 'uppercase' : isCapitalize && 'capitalize')};
  cursor: ${({ isBusy }) => (isBusy ? 'default' : 'pointer')};

  :disabled {
    background: ${white};
    // cursor: ${({ isBusy }) => (isBusy ? 'default' : 'pointer')};
  }
  ::placeholder {
    font-family: Gilroy, sans-serif;
    font-size: 13px;
    letter-spacing: 0.16px;
    color: ${text03};
    text-transform: ${({ isCapitalize }) => isCapitalize && 'capitalize'};
    font-weight: 400;
  }
`;

const Icon = styled.img`
  cursor: pointer;
  transform: rotate(-90deg);
  transition: transform .25s;
  ${({ isOpen }) => isOpen && `
    transform: rotate(90deg);
  `}
`;

const ArrowIcon = styled.div`
  cursor: ${({ isDisabled, isBusy }) => ((isDisabled || isBusy) ? 'default' : 'pointer')};
  transform: rotate(-90deg);
  transition: transform .25s;
  ${({ isOpen }) => isOpen && `
    transform: rotate(90deg);
  `}
  path {
    fill: ${({ color }) => color};
  }
`;

const SelectorMenu = styled.div`
  height: 100%;
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.11));
  background: ${white};
  display: flex;
  flex-direction: column;
  padding: .5rem 0;
  max-height: ${({ optionHeight, itemsShow }) => (itemsShow
    ? `calc(${itemsShow} * ${optionHeight ? `${isNumber(optionHeight) ? `${optionHeight}px` : optionHeight}` : '(0.5rem + 20px)'} + 1rem)`
    : '10rem')};

  overflow: auto;
`;

const Option = styled.div`
  padding: .25rem 1rem;
  transition: color .1s, background-color .1s;
  cursor: pointer;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${({ color }) => (color || text01)};
  text-transform: none;
  ${({ height }) => height && (`
    height: ${isNumber(height) ? `${height}px` : height};
    flex-shrink: 0;
    display: flex;
    align-items: center;
  `)}
  :hover {
    color: ${white};
    background: ${activePrimary};
  }
  ${({ selected }) => selected && `
    color: ${white};
    background: ${primary};
  `}
`;

const NoData = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: Gilroy, sans-serif;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text04};
`;
