import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  secondary, white, background01, text01, secondaryHover,
} from '@/common/constants/theme';

import { Checkbox, Tooltip } from 'antd';
import ActionMenu, { OPTION_DISPLAY_FLEX, Option } from '@/common/components/ActionMenu';
import AddIcon from '@/assets/icons/bordered-plus.svg';
import SettingsIcon from '../../../../icons/SettingsIcon';

import { usePersonalPageContext } from '../../../../../PersonalPageContext';
import { DeviceAppStatuses } from '@/AppContext';

const TableHeader = ({
  cellSizes,
  toggleCreateModal,
  handleFilters,
  allSelected,
  openEqSubscribeDialog,
  openUnpairDialog,
}) => {
  const { t } = useTranslation('translations');
  const history = useHistory();

  const {
    allDevices,
    filters,
    selectedDevices,
    handleSelectDevices,
  } = usePersonalPageContext();

  const [devicesCount, setDevicesCount] = useState();

  const isChangeServerAvailable = !!selectedDevices.length && selectedDevices
    .every((device) => device.server_id);
  const isChangeSubscribeAvailable = !!selectedDevices.length && selectedDevices
    .every((device) => device.purchase_date && !device.test_period);

  const renderMenuOptions = () => {
    const childrenArray = [];
    if (isChangeServerAvailable) {
      childrenArray.push(<Option>{t('devicesContent.changeServer')}</Option>);
    }
    childrenArray.push(
      <Option
        key="isChangeServerAvailable"
        withoutHover
        flex={OPTION_DISPLAY_FLEX.row}
      >
        <ActionButton
          onClick={() => history.push('/personal-page/subscription')}
        >
          {t('devicesContent.buySubscription')}
        </ActionButton>
        {(selectedDevices.length > 1)
          && (
            <ActionButton
              onClick={() => openEqSubscribeDialog()}
            >
              {t('devicesContent.equalizeSubscriptions')}
            </ActionButton>
          )}
      </Option>,
    );
    if ((selectedDevices.length > 1)
      && selectedDevices.filter(
        (d) => (d.app_status === DeviceAppStatuses.configured),
      ).length > 0) {
      childrenArray.push(
        <Option
          key="unpairDevicesOption"
          withoutHover
          flex={OPTION_DISPLAY_FLEX.row}
        >
          <ActionButton
            onClick={() => openUnpairDialog()}
            fullWidth
          >
            {t('devicesContent.appConfigureWindow.unConfigure.titleMulti')}
          </ActionButton>
        </Option>,
      );
    }
    if (isChangeSubscribeAvailable) {
      childrenArray.push(<Option key="isChangeSubscribeAvailable">{t('devicesContent.autoRenewActive')}</Option>);
    }
    return childrenArray;
  };

  useEffect(() => {
    const limit = filters?.limit || 8;
    const page = filters?.page || 1;
    const startIndex = (page - 1) * limit;
    const endIndex = page * limit;

    setDevicesCount((allDevices || []).slice(startIndex, endIndex).length);
  }, [allDevices, filters]);

  return (
    <Header>
      <HeaderCell width={cellSizes[0]} noPadding withoutBorder />
      <HeaderCell width={cellSizes[1]}>
        <StyledCheckbox
          onClick={() => handleSelectDevices(!allSelected)}
          checked={allSelected}
          disabled={devicesCount <= 1}
        />
        {t('table.devices')}
        <Tooltip
          autoAdjustOverflow
          arrowPointAtCenter
          placement="right"
          title={t('table.newDevice')}
          color={white}
          overlayClassName="white-tooltip"
          overlayStyle={{ color: text01, position: 'fixed' }}
        >
          <StyledAddIcon src={AddIcon} onClick={toggleCreateModal} />
        </Tooltip>
      </HeaderCell>
      <HeaderCell width={cellSizes[2]} leftPadding>
        {t('table.subscribes')}
      </HeaderCell>
      <HeaderCell width={cellSizes[3]} leftPadding>
        {t('table.server')}
      </HeaderCell>
      <HeaderCell width={cellSizes[4]} justify="space-between" withoutBorder leftPadding>
        <ActionMenu
          position="right"
          isBoldText
          label={t('table.choose')}
          disabled={(selectedDevices?.length || 0) === 0}
        >
          {renderMenuOptions()}
        </ActionMenu>
        <Icon onClick={handleFilters}>
          <SettingsIcon />
        </Icon>
      </HeaderCell>
    </Header>
  );
};

export default TableHeader;

const Header = styled.div`
  display: flex;
  width: 100%;
  background: ${background01};
  border: 1px solid #EFEEF3;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
  padding: 0 1rem;
  z-index: 1;
`;

const HeaderCell = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: ${text01};
  padding: ${({ noPadding }) => (noPadding ? 'none' : '0 .652rem')};
  margin: 1.3rem 0 1rem;
  border-right: 1px solid #E0DEE8;
  display: flex;
  align-items: center;
  ${({ withoutBorder }) => withoutBorder && `
    border: none;
  `}
  width: ${({ width }) => width}rem;
  justify-content: ${({ justify }) => justify};
  ${({ leftPadding }) => leftPadding && 'padding-left: 1.4rem;'}
  @media (max-width: 1360px) and (min-width: 1199px) {
    width: ${({ width }) => width - 2}rem;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: .652rem;
  & .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${secondary};
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${white};
    border: 1px solid #d9d9d9;
  }
  & .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${secondary};
  }
  & .ant-checkbox-checked::after {
    border-color: ${secondary};
  }
`;

const StyledAddIcon = styled.img`
  margin-left: 1rem;
  cursor: pointer;
`;

const Icon = styled.div`
  cursor: pointer;
`;

const ActionButton = styled.div`
  border-radius: 2px;
  padding: .25rem .5rem;
  background: ${secondary};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '8.5rem')};
  color: ${white};
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-align: center;

  &:hover {
    background: ${secondaryHover};
  }
`;
