import React from 'react';
import { PlaylistContextProvider } from './PlaylistContext';
import Playlist from './Playlist';

const PlaylistContainer = () => (
  <PlaylistContextProvider>
    <Playlist />
  </PlaylistContextProvider>
);

export default PlaylistContainer;
