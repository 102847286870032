import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { primary, text01 } from '@/common/constants/theme';
import ls from 'localstorage-slim';

import Gift from '@/assets/images/gift.svg';
import Ellipse from '@/assets/images/ellipse.svg';
import Close from '@/assets/icons/closeGray.svg';

import { usePersonalPageContext } from '../PersonalPageContext';

const Promo = () => {
  const { t } = useTranslation('translations');

  const { allDevices } = usePersonalPageContext();

  const [isVisibleBlock, setVisibilityBlock] = useState(false);

  const toggleVisibilityBlock = () => {
    setVisibilityBlock(!isVisibleBlock);
    ls.set('PromoBannerHide', true, { ttl: 60 * 60 * 24 * 7 });
  };

  useEffect(() => {
    const isTest = (allDevices || []).find((d) => d.is_test_period) != null;
    const hideBanner = ls.get('PromoBannerHide') ?? false;
    setVisibilityBlock(!hideBanner && isTest);
  }, [allDevices]);

  return (
    <Block isVisibleBlock={isVisibleBlock}>
      <Img />
      <Icon src={Close} onClick={toggleVisibilityBlock} />

      <Text>
        {t('promoBanner.text')}
      </Text>
      <ActionButton to="/personal-page">{t('promoBanner.buttonText')}</ActionButton>
    </Block>
  );
};
export default Promo;

const Block = styled.div`
  position: relative;
  display: ${({ isVisibleBlock }) => (isVisibleBlock ? 'flex;' : 'none;')};
  width: 100%;
  height: 115px;
  box-shadow: 0px 4px 10px 2px rgba(0,0,0,0.11);
  border-radius: 4px;
  background: url(${Ellipse}) -15px 0 no-repeat;
  overflow: hidden;
  margin-bottom: 1.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-left: 4rem;
  padding-right: 1rem;
`;

const Img = styled.div`
  position: absolute;
  width: 98px;
  height: 100px;
  left: 0;
  top: 0;
  background: url(${Gift}) 0 0 no-repeat;
  background-size: contain;
`;

const Icon = styled.img`
  cursor: pointer;
  position: absolute;
  right: .75rem;
  top: .75rem;
`;

const Text = styled.p`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  white-space: pre-wrap;
  margin: 1rem 2rem 0 2rem;
`;

const ActionButton = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.16px;
  color: ${primary};
  margin: 0 1rem;
  cursor: pointer;
  &&:hover {
    color: #ffab30;
  }
`;
