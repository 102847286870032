import React from 'react';

export default () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#plusIconClip0)">
      <path d="M13.3 6.51226H7.70004V0.72354C7.70004 0.324208 7.3864 0 6.99996 0C6.61364 0 6.3 0.324208 6.3 0.72354V6.51226H0.699957C0.313641 6.51226 0 6.83646 0 7.2358C0 7.63526 0.313641 7.95947 0.699957 7.95947H6.3V13.7481C6.3 14.1475 6.61364 14.4717 6.99996 14.4717C7.3864 14.4717 7.70004 14.1475 7.70004 13.7481V7.95947H13.3C13.6864 7.95947 14 7.63526 14 7.2358C14 6.83646 13.6864 6.51226 13.3 6.51226Z" fill="#FF9800" />
    </g>
    <defs>
      <clipPath id="plusIconClip0">
        <rect width="14" height="14.4717" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
