export default {
  getImportData: '/api/v1/iptv/admin/import-channel/get-data/',
  importData: '/api/v1/iptv/admin/import-channel/import/',
  exportData: (type) => `/api/v1/iptv/admin/export-channel/?export_type=${type}`,
  validateImportData: '/api/v1/iptv/admin/import-channel/validate/',
  countries: '/api/v1/user/countries/',
  menuItems: '/api/v1/permissions/admin/menu-items/',
  osFormats: '/api/v1/iptv/admin/playlist-types/?limit=1000&offset=0',
  sendMail: (userId) => `/api/v1/user/admin/user/${userId}/send-email`,
  sendTestMail: '/api/v1/db_templates/admin/templates/test-sending-email/',
};
