/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  primary,
  secondary,
  text04,
  white,
  text01,
  brightGreen,
} from '../constants/theme';

const COLORS = {
  primary: { main: secondary },
  secondary: { main: primary },
};

export const CHECKMARK_POSITION = {
  start: 'start',
  end: 'end',
};

export const RadioAlt = (props) => {
  const {
    isSuggested,
    onChange,
    value,
    labelText,
    checked,
    disabled,
    name,
    type = 'primary',
    position = 'horizontal',
    checkMarkPosition = CHECKMARK_POSITION.start,
    noPadding = false,
    noMargin = false,
  } = props;

  const RenderCheckMark = () => (
    <CheckmarkContainer>
      <Checkmark checked={checked} color={type} position={checkMarkPosition} />
    </CheckmarkContainer>
  );

  return (
    <RadioContainer
      onClick={() => {
        if (!checked && onChange) {
          onChange(value);
        }
      }}
      position={position}
      noPadding={noPadding}
      noMargin={noMargin}
    >
      {checkMarkPosition === CHECKMARK_POSITION.start && (
        RenderCheckMark()
      )}
      <Label isSuggested={isSuggested}>
        {labelText}
      </Label>
      <Input
        {...props}
        type="radio"
        onChange={onChange}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        aria-checked={checked}
      />
      {checkMarkPosition === CHECKMARK_POSITION.end && (
        RenderCheckMark()
      )}
    </RadioContainer>
  );
};

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${({ noPadding }) => (!noPadding && `
    padding-left: 1.35rem;
  `)}
  ${({ noMargin }) => (!noMargin && `
    margin-bottom: .75rem;
  `)}
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${({ isSuggested }) => (isSuggested ? brightGreen : text01)};
  ${({ isSuggested }) => (isSuggested ? 'font-weight: 600;' : '')};
  margin-left: .5rem;
  cursor: pointer;
  ${({ disabled }) => disabled && `
    color: ${text01};
    opacity: .3;
  `}
`;

const CheckmarkContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;

  @media (max-width: 1360px) {
    height: 14px;
    width: 14px;
  }
`;

const Checkmark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${white};
  border: 2px solid ${text04};
  border-radius: 50%;
  transition: all 0.20s ease;

  :hover {
    border-color: ${({ color }) => COLORS[color].main};
  }

  :after {
    display: block;
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${white};
    transition: all 0.20s ease;
  }

  ${({ checked, color }) => checked && `
    background-color: ${white};
    border-color: ${COLORS[color].main};
    :after {
      background: ${COLORS[color].main};
    }
  `}

  @media (max-width: 1360px) {
    border-width: 1px;

    :after {
      top: 3px;
      left: 3px;
      width: 6px;
      height: 6px;
    }
  }
}
`;
