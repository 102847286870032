import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  border,
  white,
  background01,
} from '../../../../../../../../common/constants/theme';

import { useGroupsContext } from '../GroupsContext';
import AdminTablePagination from '../../../../components/Table/components/TablePagination';
import AdminDeleteModal from '../../../../../Modals/DeleteModal';
import { useAdminContext } from '../../../../../../AdminContext';
import AdminTable from '../../../../components/Table/Table';
import AdminTableHead from '../../../../components/Table/components/TableHead';
import AdminTableBody from '../../../../components/Table/components/TableBody';
import DisketteIcon from '../../../../../icons/DisketteIcon';
import DeleteIcon from '../../../../../icons/DeleteIcon';

const SIZES = ['1054px'];

const GroupsTable = ({
  isCreateTab,
  setIsCreateTab,
}) => {
  const {
    groups,
    groupsCount,
    groupsPage,
    setGroupsPage,
    getGroupsPageCount,
    deleteGroupById,
    handleDragEndGroups,
    createGroup,
    handleSaveGroups,
  } = useGroupsContext();
  const {
    getPosition,
  } = useAdminContext();

  const [deletedId, setDeletedId] = useState(-1);
  const [editedId, setEditedId] = useState(-1);
  const [inputValues, setInputValues] = useState({});
  const [createPosition, setCreatePosition] = useState(null);

  useEffect(() => {
    if (editedId >= 0) {
      setInputValues({ ...groups[editedId] });
    } else {
      setInputValues({});
    }
  }, [editedId]);

  useEffect(() => {
    if (isCreateTab) {
      setInputValues({ name: '' });
    } else {
      setInputValues({});
    }
  }, [isCreateTab]);

  const numberRegExp = /[0-9]/g;

  const handleNewPosition = (event) => {
    let inputMatchedValue = [];
    inputMatchedValue = event.target.value.match(numberRegExp);
    if (inputMatchedValue) {
      setCreatePosition(Number(inputMatchedValue.join('')));
    } else {
      setCreatePosition(null);
    }
  };

  const createTab = () => (
    <Row create>
      <RowItem width="80px">
        <Input
          type="number"
          value={createPosition >= 0 ? createPosition : ''}
          onChange={handleNewPosition}
        />
      </RowItem>
      <RowItem width={SIZES[0]}>
        <Input
          value={inputValues.name}
          onChange={({ target }) => setInputValues((prevState) => ({
            ...prevState,
            name: target.value,
          }))}
        />
      </RowItem>
      <RowItem width="50px" flex leftMargin={27}>
        <Icon
          top
          onClick={() => {
            createGroup(inputValues, createPosition);
            setIsCreateTab(false);
          }}
        >
          <DisketteIcon />
        </Icon>
        <Icon onClick={() => setIsCreateTab(false)}>
          <DeleteIcon />
        </Icon>
      </RowItem>
    </Row>
  );

  return (
    <Container>
      <AdminDeleteModal
        isOpen={deletedId >= 0}
        onClose={() => setDeletedId(-1)}
        content={`позицию ${getPosition(groupsPage, deletedId)}`}
        handleDelete={() => deleteGroupById(groups[deletedId].id)}
      />
      <AdminTable
        cellSizes={SIZES}
        page={groupsPage}
        setPage={setGroupsPage}
        pageCount={getGroupsPageCount()}
        recordsCount={groupsCount}
        editedId={editedId}
        setEditedId={setEditedId}
        deletedId={deletedId}
        setDeletedId={setDeletedId}
        onSortEnd={({ oldIndex, newIndex }) => {
          handleDragEndGroups(oldIndex, newIndex);
        }}
        handleSave={(position) => {
          handleSaveGroups(editedId, inputValues, position);
        }}
        createTab={isCreateTab && createTab}
        title="Группы:"
      >
        <AdminTableHead>
          <HeadItem>Группа</HeadItem>
        </AdminTableHead>
        <AdminTableBody>
          {groups && groups.map((item, i) => (
            <Row key={item.id}>
              <RowItem width={SIZES[0]}>
                {editedId === i ? (
                  <Input
                    value={inputValues.name}
                    onChange={({ target }) => setInputValues((prevState) => ({
                      ...prevState,
                      name: target.value,
                    }))}
                  />
                ) : item.name}
              </RowItem>
            </Row>
          ))}
        </AdminTableBody>
        <AdminTablePagination />
      </AdminTable>
    </Container>
  );
};

export default GroupsTable;

const Container = styled.div``;

const HeadItem = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;

  width: fit-content;
  min-width: 100%;

  height: 42px;
  padding: 0 15px 0 23px;

  border-bottom: 1px solid ${borderSecondary};

  &:nth-child(2n) {
    background-color: ${background01};
  }

  ${({ create }) => create && (`
    margin-top: -5px;

    background-color: ${borderSecondary};
  `)}
`;

const RowItem = styled.div`
  ${({ flex }) => flex && ('display: flex;')}

  width: ${({ width }) => width};

  &:not(:first-child) {
    margin-left: 30px;

    ${({ leftMargin }) => leftMargin && (`margin-left: ${leftMargin}px;`)}
  }
`;

const Input = styled.input`
  width: 100%;
  height: 25px;
  padding: 0 10px;

  background: ${white};
  border: 1px solid ${border};
  border-radius: 2px;
  outline: none;
`;

const Icon = styled.div`
  position: relative;

  cursor: pointer;

  &:first-child {
    margin-right: 15px;
    margin-left: 0px;

    top: 2px;
  }
`;
