import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  secondary, green, red, black,
} from '@/common/constants/theme';

import SearchIcon from '../../../../../PersonalPageScreen/components/icons/SearchIcon';
import UsersMainUserFilterArrows from '../Main/components/MainUser/components/MainFilterArrows';
import AdminTablePagination from '../../components/Table/components/TablePagination';
import DatePickerDouble from '@/common/components/DatePickerDouble';

import RequestService from '@/services/request.service';
import { referrals } from '@/services/actions/admin/users-main.actions';

import SelectorContainer from '@/common/components/Selector';
import Loader from '@/common/components/Loader';
import SubmitWithdrawalModal from '../../../Modals/SubmitWithdrawalModal';
import CancelWithdrawalModal from '../../../Modals/CancelWithdrawalModal';

import { setSnack, setSnackFromErrorResponse } from '@/store/admin/actions/common';

import { useAdminContext } from '../../../../AdminContext';

const initialFilters = {
  page: 1,
  d_begin: '',
  d_end: '',
};

const emptyModalData = {
  summ: 0,
  user: '',
  paySystem: {},
};

const STATUS_COLORS = {
  created: black,
  rejected: red,
  approved: green,
};

const PAGE_CAPACITY = 10;

const defaultDateFilter = { d_begin: '', d_end: '' };
const defaultWithdrawalOption = { key: '-1', title: 'Платежные системы' };
const defaultStatusOption = { key: '-1', title: 'Статус' };

const ReferralWithdrawTable = (props) => {
  const dispatch = useDispatch();

  const {
    formatDateWithZone,
  } = useAdminContext();

  const [statList, setStatList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [recordsCount, setRecordsCount] = useState(0);

  const [withdrawalSystems, setWithdrawalSystems] = useState([]);

  const [withdrawalSystemsOptions, setWithdrawalSystemsOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const [
    selectedWithdrawalSystemOption, setSelectedWithdrawalSystemOption,
  ] = useState(defaultWithdrawalOption);
  const [selectedStatusOption, setSelectedStatusOption] = useState(defaultStatusOption);

  const [search, setSearch] = useState('');
  const [dateFilter, setDateFilter] = useState(defaultDateFilter);
  const [filters, setFilters] = useState(initialFilters);

  const [selectedItem, setSelectedItem] = useState(null);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const [isBusy, setIsBusy] = useState(false);
  const [modalData, setModalData] = useState(emptyModalData);

  const { id } = props;
  const { t } = useTranslation(['translations']);
  const statuses = ['created', 'approved', 'rejected'];

  const callApi = (url) => {
    setIsBusy(true);
    RequestService(url)
      .then((result) => {
        setStatList(result.results);
        setPagesCount(Math.ceil((result?.count || 0) / PAGE_CAPACITY));
        setRecordsCount(result?.count || 0);
      }).finally(() => setIsBusy(false));
  };
  const doSearch = () => {
    const queryParams = { ...filters, page: currentPage, user_id: id || '' };
    const query = new URLSearchParams(queryParams).toString();
    const url = referrals.withdrawalTransactions(query);
    callApi(url);
  };

  const sortByDate = (value) => {
    setStatList([...statList].sort((a, b) => {
      let retVal;
      const aD = new Date(a.dt_create).getTime();
      const bD = new Date(b.dt_create).getTime();
      if (aD === bD) {
        retVal = 0;
      } else {
        retVal = aD > bD && (value === 'down') ? 1 : -1;
      }
      return retVal;
    }));
  };

  // eslint-disable-next-line no-unused-vars
  const submitExecute = (item) => {
    setSelectedItem(item);

    setModalData({
      ...emptyModalData,
      summ: parseFloat(item.amount),
      user: item.user.email,
      paySystem: item.withdrawal_system.name,
    });

    setIsSubmitModalOpen(true);
  };

  // eslint-disable-next-line no-unused-vars
  const submitCancel = () => {
    setModalData({ ...emptyModalData });
    setIsCancelModalOpen(true);
  };

  const processApprove = () => {
    setIsBusy(true);
    RequestService(referrals.withdrawalApprove(selectedItem.id), { method: 'POST', body: { reason: 'admin' } })
      .then((res) => {
        doSearch();
        const snackValue = {
          status: 200,
          type: 'success',
          content: `Транзакция №${res?.id}\nна сумму ${res.amount} \nподтверждена`,
        };
        dispatch(setSnack(snackValue));
      }).catch((err) => {
        dispatch(setSnackFromErrorResponse(err));
        setIsBusy(false);
      });
  };

  const processReject = (cancelReason) => {
    setIsBusy(true);
    RequestService(referrals.withdrawalReject(selectedItem.id), { method: 'POST', body: { reason: cancelReason || 'admin' } })
      .then((res) => {
        doSearch();
        const snackValue = {
          status: 200,
          type: 'success',
          content: `Транзакция №${res?.id}\nна сумму ${res.amount} \nуспешно отменена`,
        };
        dispatch(setSnack(snackValue));
      }).catch((err) => {
        dispatch(setSnackFromErrorResponse(err));
        setIsBusy(false);
      });
  };

  useEffect(() => {
    if (withdrawalSystems.length === 0) {
      RequestService(referrals.withdrawalSystems, { method: 'GET' })
        .then((data) => setWithdrawalSystems(data.results));
    }
    if (statusOptions.length === 0) {
      setStatusOptions(statuses.map((key) => ({ key, title: t(`referrals.table.statuses.${key}`) })));
    }
  }, [props]);

  useEffect(() => {
    doSearch();
  }, [filters, currentPage]);

  useEffect(() => {
    setWithdrawalSystemsOptions(withdrawalSystems.map((s) => ({ key: s.id, title: s.name })));
  }, [withdrawalSystems]);

  const changeFilters = (filter) => {
    const filtersTmp = { ...filters };
    Object.keys(filter).forEach((key) => {
      filtersTmp[key] = filter[key];
    });
    setCurrentPage(1);

    setFilters(filtersTmp);
  };

  const textSearch = () => {
    changeFilters({
      user_name: search,
      // status: search,
      // withdrawal_system: search,
    });
  };

  const clearFilter = () => {
    setDateFilter(defaultDateFilter);
    setSelectedWithdrawalSystemOption(defaultWithdrawalOption);
    setSelectedStatusOption(defaultStatusOption);
    setSearch('');

    setFilters(initialFilters);
  };

  return (
    <div style={{ minHeight: '350px', position: 'relative' }}>
      {isBusy && <Loader />}
      <SubmitWithdrawalModal
        isOpen={isSubmitModalOpen}
        setIsOpen={setIsSubmitModalOpen}
        modalData={modalData}
        onSubmit={() => {
          processApprove();
        }}
      />
      <CancelWithdrawalModal
        isOpen={isCancelModalOpen}
        setIsOpen={setIsCancelModalOpen}
        isReasonNeeded
        onSubmit={(cancelReason) => {
          processReject(cancelReason);
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DatePickerDouble
          fields={dateFilter}
          changeFilters={(filter) => {
            changeFilters(filter);
            setDateFilter(filter);
          }}
        />
        <SelectorContainer
          value={selectedWithdrawalSystemOption}
          options={withdrawalSystemsOptions}
          onSelect={(value) => {
            changeFilters({ withdrawal_system: value.key });
            setSelectedWithdrawalSystemOption(value);
          }}
        />
        <SelectorContainer
          value={selectedStatusOption}
          options={statusOptions}
          onSelect={(value) => {
            changeFilters({ status: value.key });
            setSelectedStatusOption(value);
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '20px' }}>
          <Input
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            placeholder="Поиск"
          />
          <Icon onClick={() => textSearch()}>
            <SearchIcon />
          </Icon>
        </div>
        <ClearFilter onClick={clearFilter}>
          Сбросить фильтры
        </ClearFilter>
      </div>
      <Table>
        <thead>
          <TrHeadTr>
            <Th>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                Дата
                <UsersMainUserFilterArrows
                  sortTriggered={sortByDate}
                />
              </div>
            </Th>
            {!id && <Th>Пользователь </Th>}
            <Th>Платежные системы</Th>
            <Th>Кошелёк </Th>
            <Th>Сумма </Th>
            <Th>Статус</Th>
            <Th colSpan={2}> </Th>
          </TrHeadTr>
        </thead>
        <tbody>
          {statList.length === 0 && <tr><TdS colSpan={6}><NoData>Не найдено</NoData></TdS></tr>}
          {statList.map((item) => (
            <TbodyTr key={item.id}>
              <Td>
                {formatDateWithZone(item.dt_create)}
              </Td>
              {!id && (
                <Td>
                  <Link to={`/admin/users/main/${item.user.id}`}>{item.user.email}</Link>
                </Td>
              )}
              <Td>
                {item.withdrawal_system.name}
              </Td>
              <Td>
                {item.withdrawal_system_user_identifier}
              </Td>
              <Td>
                {item.amount}
              </Td>
              <Td>
                <StatusText useColor={item.status}>
                  {t(`referrals.table.statuses.${item.status}`)}
                </StatusText>
              </Td>
              <Td>
                <TableButton
                  visible={item.status === 'created'}
                  color={green}
                  onClick={() => submitExecute(item)}
                >
                  Выполнить
                </TableButton>
              </Td>
              <Td>
                <TableButton
                  visible={item.status === 'created'}
                  color={red}
                  onClick={() => {
                    setSelectedItem(item);
                    submitCancel();
                  }}
                >
                  Отменить
                </TableButton>
              </Td>
            </TbodyTr>
          ))}
        </tbody>
      </Table>
      <AdminTablePagination
        page={currentPage}
        setPage={setCurrentPage}
        pageCount={pagesCount}
        recordsCount={recordsCount || 0}
      />
    </div>
  );
};

export default ReferralWithdrawTable;

const NoData = styled.div`
`;

const ClearFilter = styled.div`
  width: auto;
  text-align: right;
  cursor: pointer;
  font-family: Gilroy, sans-serif;
  color: #5C70CF;
  font-size: 12px;
`;

const TdS = styled.td`
  text-align: center;
  height: 200px;
`;

const Table = styled.table`
  width: 100%;
`;

const Th = styled.th`
  font-family: Gilroy, sans-serif;
  text-transform: uppercase;
  text-align: center;
`;

const Td = styled.td`
  font-family: Gilroy, sans-serif;
  font-size: 11px;
  text-align: center;
  padding: 10px;
`;

const TbodyTr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2
  }
`;

const TrHeadTr = styled.tr`
  height: 53px;
  padding-left: 23px;
  padding-right: 20px;
  position: relative;
  z-index: 2;
  background: #FAFAFA;
  border: 1px solid #EFEEF3;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgb(0 0 0 / 15%);
  border-radius: 4px 4px 0 0;
`;

const TableButton = styled.div`
  font-weight: bold;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  color: ${(props) => props.color};
  cursor: pointer;
`;

const Input = styled.input`
  width: 200px;
  margin-left: auto;
  margin-right: 10px;
  padding-bottom: 4px;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${secondary};
  outline: none;
`;

const Icon = styled.div`
  position: relative;
  top: 2px;

  cursor: pointer;
`;

const StatusText = styled.span`
  color: ${({ useColor }) => (useColor && STATUS_COLORS[useColor] ? STATUS_COLORS[useColor] : black)};
`;
