import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { primary } from '@/common/constants/theme';

import Button from '../../../../../../common/components/Button';

import { useSelector } from 'react-redux';
import { getSelectedDevices } from '@/store/user/selectors';

import { usePersonalPageContext } from '@/screens/PersonalPageScreen/PersonalPageContext';

const Footer = () => {
  const history = useHistory();
  const { t } = useTranslation(['translations']);

  const selectedDevices = useSelector(getSelectedDevices);
  const { disabledStepperIdx } = usePersonalPageContext();

  const forwardHandler = () => {
    let url = '/personal-page/devices';
    if (selectedDevices.length) {
      url = (disabledStepperIdx === 3)
        ? '/personal-page/server'
        : '/personal-page/channels';
    }

    history.push({
      pathname: url,
      state: { scrollToStepsNav: true },
    });
  };

  return (
    <FooterRow>
      <Actions>
        <BackButton
          onClick={forwardHandler}
        >
          {t('completion.back')}
        </BackButton>
        <Button
          color="primary"
          width="medium"
          isOutline
          isUpperCase
          isBoldText
          onClick={() => (history.push({
            pathname: '/personal-page/devices',
            state: { scrollToStepsNav: true },
          }))}
        >
          {t('completion.complete')}
        </Button>
      </Actions>
    </FooterRow>
  );
};

export default Footer;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3.5rem;
`;

const FooterRow = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BackButton = styled.div`
  font-family: Gilroy, sans-serif, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: capitalize;
  color: ${primary};
  padding: 0 1rem;
  margin: 0 1rem;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  :hover {
    background: #eef1fb;
  }
`;
