import React, { useEffect } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import AdminHeader from './components/Header';
import Navigation from './components/Navigation/Navigation';
import MainContent from './components/PageContent/MainContent';
import { useAppContext } from '@/AppContext';
import { useAdminContext } from './AdminContext';

const Admin = ({ isUserExists }) => {
  const { isLoading } = useAppContext();
  const {
    setDropdownGroupsValue,
    dropdownGroupsValueInitState,
  } = useAdminContext();
  const history = useHistory();

  useEffect(() => {
    setDropdownGroupsValue(dropdownGroupsValueInitState);
  }, [history.location.pathname]);

  return (
    <Container>
      {!isLoading && (
        <>
          <AdminHeader isUserExists={isUserExists} />
          <MainWrapper>
            <Wrapper>
              <Navigation />
              <MainContent />
            </Wrapper>
          </MainWrapper>
        </>
      )}
    </Container>
  );
};

export default Admin;

const Container = styled.div``;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  width: 100%;
  max-width: 1600px;
  margin: 22px 8px 0 8px;
`;
