import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { primary, text01 } from '@/common/constants/theme';
import ArrowRightPrimary from '../../../../../../icons/ArrowRightPrimary';

import {
  BASE_URL,
} from '../../../MainContext';

const UsersMainUserHeader = ({ email }) => (
  <Container>
    <Wrapper>
      <Title>
        Пользователь
      </Title>
      <User>
        {email}
      </User>
    </Wrapper>
    <Wrapper>
      <StyledLink to={BASE_URL}>
        Вернуться к списку пользователей
        <Icon>
          <ArrowRightPrimary />
        </Icon>
      </StyledLink>
    </Wrapper>
  </Container>
);

export default UsersMainUserHeader;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: ${text01};
  line-height: 20px;
`;

const User = styled(Title)`
  margin-left: 20px;

  font-weight: normal;
`;

const StyledLink = styled(Link)`
  display: flex;

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${primary};
  line-height: 20px;

  &:hover,
  &:active {
    color: ${primary};
  }
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;
  top: 2px;
`;
