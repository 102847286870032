import React from 'react';

export default ({ color = '#010101', size = 23 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 20.51" width={size} height={20.51 * (23 / size)}>
    <g
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth={`${size / 12}px`}
    >
      <line y1="1" x2="23" y2="1" />
      <line y1="7.17" x2="18" y2="7.17" />
      <line y1="13.34" x2="13" y2="13.34" />
      <line y1="19.51" x2="8" y2="19.51" />
    </g>
  </svg>
);
