import React, { useState } from 'react';
import styled from 'styled-components';

import ArrowTable from './icons/ArrowTable';

const TableFilterArrows = ({ sortTriggered }) => {
  const [state, setState] = useState('');

  const isRotate = () => state === 'down';

  const sortOrder = ['', 'up', 'down'];

  const sort = () => {
    // eslint-disable-next-line no-nested-ternary
    const currentSortIndex = sortOrder.findIndex((itm) => itm === state);
    const newIndex = currentSortIndex >= sortOrder.length - 1 ? 0 : currentSortIndex + 1;

    setState(sortOrder[newIndex]);
    sortTriggered(sortOrder[newIndex]);
  };

  return (
    <Container>
      <Icon rotate={isRotate()} pointer onClick={sort} isColored={state !== ''}>
        <ArrowTable />
      </Icon>
    </Container>
  );
};

export default TableFilterArrows;

const Container = styled.div`
  display: flex;

  margin-left: 8px;
`;

const Icon = styled.div`
  ${({ leftMargin }) => leftMargin && 'margin-left: 2px;'}

  position: relative;
  ${({ top }) => top && 'top: 3px;'}
  cursor: pointer;

  ${({ rotate }) => (rotate ? 'transform: rotate(180deg);' : null)}
  opacity: ${({ isColored }) => (isColored ? 1 : 0.5)};
`;
