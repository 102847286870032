import React from 'react';
import { ReferralsTransactionsContextProvider } from './ReferralsTransactionsContext';
import ReferralsTransactionsTable from './ReferralsTransactionsTable';

const ReferralsTransactionsTableContainer = () => (
  <ReferralsTransactionsContextProvider>
    <ReferralsTransactionsTable />
  </ReferralsTransactionsContextProvider>
);

export default ReferralsTransactionsTableContainer;
