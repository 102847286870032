/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  secondary, background01, text01, text04, greyText,
} from '@/common/constants/theme';
import pageSizes from '@/common/constants/pageSizes';
import ArrowLeft from '../../../../icons/ArrowLeft';
import ArrowRight from '../../../../icons/ArrowRight';
import ArrowToStart from '../../../../icons/ArrowToStart';
import ArrowToEnd from '../../../../icons/ArrowToEnd';

const TablePagination = ({
  quantity,
  setFilters,
  filters,
  customPageSizes = null,
}) => {
  const { t } = useTranslation(['translations']);
  const { limit, page } = filters;
  const pagesNumber = Math.ceil(quantity / limit);

  const changeFilters = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const usePageSize = customPageSizes || pageSizes;

  const changePageSize = (value) => {
    setFilters({ ...filters, limit: value, page: 1 });
  };

  const getFormatedPages = () => {
    if (pagesNumber > 7) {
      if (page === 1 || page === 2) {
        return [1, 2, 3, '...', pagesNumber];
      }
      if (page === 3) {
        return [1, 2, 3, 4, '...', pagesNumber];
      }
      if (page === pagesNumber || page === pagesNumber - 1) {
        return [1, '...', pagesNumber - 2, pagesNumber - 1, pagesNumber];
      }
      if (page === pagesNumber - 2) {
        return [1, '...', pagesNumber - 3, pagesNumber - 2, pagesNumber - 1, pagesNumber];
      }
      return [1, '...', page - 1, page, page + 1, '...', pagesNumber];
    }
    return Array.from({ length: pagesNumber }, (_, i) => i + 1);
  };
  return (
    <Container>
      <ViewFilter>
        {usePageSize && ((usePageSize?.length || 0) > 1) && (
          <>
            <Text>{t('table.pagination')}</Text>
            {usePageSize.map((item) => (
              <Number
                key={item}
                selected={+limit === item}
                isDisabled={!quantity}
                onClick={() => changePageSize(item)}
              >
                {item}
              </Number>
            ))}
          </>
        )}
      </ViewFilter>
      <Pagination>
        <Arrows disabled={page === 1}>
          <ArrowToStart onClick={() => changeFilters('page', 1)} />
          <ArrowLeft onClick={() => changeFilters('page', page - 1)} />
        </Arrows>
        {getFormatedPages().map((pageItem) => (
          <PageNumber
            key={pageItem}
            selected={pageItem === page}
            onClick={() => changeFilters('page', pageItem)}
          >
            {pageItem}
          </PageNumber>
        ))}
        <Arrows disabled={(page === pagesNumber) || !quantity}>
          <ArrowRight onClick={() => changeFilters('page', page + 1)} />
          <ArrowToEnd onClick={() => changeFilters('page', pagesNumber)} />
        </Arrows>
      </Pagination>
    </Container>
  );
};

export default TablePagination;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background: ${background01};
  box-shadow: 0 0 20px rgb(192 192 192 / 0.5);
  border-radius: 0 0 4px 4px;
  padding: 1rem;
  z-index: 1;
`;

const ViewFilter = styled.div``;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.16px;
  color: ${text01};
  margin: 0 1rem;
`;

const Number = styled.span`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  cursor: ${({ selected, isDisabled }) => (isDisabled || selected ? 'default' : 'pointer')};
  margin: 0 .5rem;
  color: ${({ selected, isDisabled }) => (isDisabled ? greyText : (selected ? text04 : secondary))};
`;

const PageNumber = styled(Number)`
  color: ${({ selected }) => (selected ? text01 : text04)};
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20rem;
`;

const Arrows = styled.div`
  margin: 0 .5rem;
  svg {
    margin: 0 .3rem;
    cursor: pointer;
  }
  path {
    fill: ${secondary};
  }
  ${({ disabled }) => disabled && `
  pointer-events: none;
  path {
    fill: ${text04};
  }
`}
`;
