/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import Close from '../../assets/icons/closeWhite.svg';

const ModalWindow = ({
  children,
  uiType,
  isPopupVisible,
  footer = null,
  ...props
}) => {
  const widthModal = (uiType === 'bg') ? 466 : 340;
  return (
    <StyledModal
      visible={isPopupVisible}
      footer={footer}
      width={widthModal}
      closeIcon={<img src={Close} alt="" />}
      {...props}
      uiType={uiType}
    >
      {children}
    </StyledModal>
  );
};

export default ModalWindow;

const StyledModal = styled(Modal)`
  &&& {
    width: auto !important;
  }

  .ant-modal-body {
        padding: 0;
    }

  .ant-modal-close-x {
    width: 29px;
    height: 30px;
    position: absolute;
    z-index: 120;
    top: -13px;
    right: 5px;
  }
`;
