import React, { useRef } from 'react';
import { Radio } from 'antd';

import styled from 'styled-components';
import {
  primary, text01,
  background01, borderSecondary,
} from '@/common/constants/theme';

import AddIcon from '../../../icons/AddIcon';

const Playlists = ({
  list,
  preList = [],
  title,
  buttonText,
  withButtonIcon,
  defaultValue,
  disabled,
  buttonClicked,
  listItemClicked,
  listItemSelected,
  noAddButton,
  isBusy = false,
}) => {
  const rGroup = useRef(null);

  const renderOption = (item, isBold = false) => (
    <StyledRadio
      isBold={isBold}
      onClick={() => {
        rGroup.current.querySelectorAll('label').forEach((l) => {
          const inp = l.querySelector('span>input');
          if (inp && !inp.checked && (parseInt(inp.value, 10) === item.id)) {
            const L = l.querySelector('span:nth-of-type(2)');
            L.click();
          }
        });
        if (listItemClicked) listItemClicked(item.id);
      }}
      value={item.id}
      disabled={disabled || isBusy}
      key={item.id}
    >
      {item.title}
    </StyledRadio>
  );

  return (
    <Container>

      <Header>
        <Title>{title}</Title>
        {!noAddButton
          && (
            <ActionButton onClick={buttonClicked}>
              {buttonText}
              {withButtonIcon && (
                <Icon>
                  <AddIcon />
                </Icon>
              )}
            </ActionButton>
          )}
      </Header>

      <Content>
        <StyledRadioGroup
          ref={rGroup}
          name={`radiogroup-${title}`}
          value={defaultValue}
          defaultValue={defaultValue}
          onChange={({ target }) => listItemSelected && listItemSelected(target.value)}
          disabled={isBusy}
        >
          {preList.map((item) => renderOption(item, true))}
          {preList.length > 0 && (<ListDivider />)}
          {list.map((item) => renderOption(item, false))}
        </StyledRadioGroup>
      </Content>
    </Container>

  );
};

export default Playlists;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-top: 17px;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: ${text01};
`;

const ActionButton = styled(Title)`
  text-transform: capitalize;
  color: ${primary};
  cursor: pointer;
  display: flex;
  align-items: center;

  &&:hover {
    transition: all 0.45s ease-out;
    color: #ffab30;
  }
  &&:hover path {
    transition: all 0.45s ease-out;
    fill: #ffab30;
  }
`;

const Icon = styled.div`
  display: flex;
  margin-left: .5rem;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  min-height: 140px;
  border: 1px solid #efeef3;
  background: ${background01};
  border-radius: 4px;
  margin: 1rem 0;
  padding: 14px 1rem;
  overflow-y: auto;

  ::-webkit-scrollbar-thumb {
    border: 4px solid ${background01};
  }

`;

const StyledRadio = styled(Radio)`
  width: fit-content;
  font-family: Gilroy, sans-serif;
  ${({ isBold }) => (isBold && 'font-weight: bold;')}
  font-size: 13px;
  margin-bottom: 5px;
  letter-spacing: 0.16px;
  color: ${text01};
  & .ant-radio-checked .ant-radio-inner {
    /* border-color: #ffab30; */
    border: 1px solid #ffab30;

    :after {
      background-color: #ffab30;
      border: 1px solid #ffab30;
    }
  }
  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px #ffab3014;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListDivider = styled.div`
  border-top: solid 1px ${borderSecondary};
  margin-bottom: .5rem;
`;
