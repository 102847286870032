import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  text01, text02,
  secondary, primaryHover,
  greySecondary, darkGrey,
  borderTertiary,
  red, green, white,
  background02,
} from '@/common/constants/theme';

import CloseIcon from './icons/CloseIcon';
import ArrowLeft from './icons/ArrowLeftDouble';
import ArrowRight from './icons/ArrowRightDouble';

export const CONTAINER_MODES = {
  scrolled: 'scrolled',
  multiRow: 'multiRow',
};

const SCROLL_CONTROL_SIZE = 20;
const SCROLL_CONTROL_COLORS = {
  active: secondary,
  inActive: darkGrey,
};
const SCROLL_AMOUNT = 250;

const TagsList = ({
  tagsList,
  selectTagHandler,
  removeHandler,
  activeTagText,
  containerMode = CONTAINER_MODES.scrolled,
  showUsedCount = false,
}) => {
  const { t } = useTranslation('translations');

  const [canScroll, setCanScroll] = useState({
    prev: false,
    next: false,
    space: 0,
    scroll: 0,
  });

  const scrollRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const isScrollMode = containerMode === CONTAINER_MODES.scrolled;

  const initCanScroll = (force = false) => {
    const useSpace = force ? 0 : canScroll.space;
    if (!isScrollMode || (useSpace !== 0)) {
      return;
    }
    const retVal = {
      prev: false,
      next: false,
      space: 0,
      scroll: 0,
    };

    if (((tagsList || []).length === 0)
      || !scrollRef?.current) {
      return;
    }

    const lastEl = scrollRef.current.lastChild;

    if (!lastEl) {
      return;
    }

    const SC = scrollContainerRef.current.getBoundingClientRect();
    const ElS = lastEl.getBoundingClientRect();

    retVal.prev = !force || (canScroll?.scroll || 0) > 0;
    retVal.next = ElS.right > SC.right;
    retVal.space = ElS.right - SC.right
      + (removeHandler ? 40 : 0) + (force ? (canScroll?.scroll || 0) : 0);
    retVal.scroll = (canScroll?.scroll || 0);

    setCanScroll(() => retVal);
  };

  const calcCanScroll = (d) => {
    setCanScroll((prevState) => {
      let useD;
      if (d < 0) {
        useD = (prevState.scroll + d) >= 0 ? d : -prevState.scroll;
      } else {
        useD = (prevState.scroll + d) < prevState.space ? d : prevState.space - prevState.scroll;
      }
      return {
        ...prevState,
        prev: prevState.scroll + useD > 0,
        next: prevState.space > (prevState.scroll + useD),
        scroll: prevState.scroll + useD,
      };
    });
  };

  const getScrollControlColor = (isActive) => (
    isActive
      ? SCROLL_CONTROL_COLORS.active
      : SCROLL_CONTROL_COLORS.inActive
  );

  const handleScroll = (e, d) => {
    e.preventDefault();
    e.stopPropagation();

    calcCanScroll(d);
  };

  useEffect(() => {
    if ((tagsList?.length || 0) > 0) {
      initCanScroll(true);
    }
  }, [tagsList?.length]);

  const renderMainContent = (scrollMode = false) => (
    <FAQTagsList
      ref={scrollRef}
      isScrollMode={scrollMode}
      style={{ marginLeft: (scrollMode ? -canScroll.scroll : 0) }}
    >
      {(tagsList || []).map((tag) => (
        <FAQTag
          key={`FAQITL-TI-${tag.id}`}
          canRemove={!!removeHandler}
          isActive={((activeTagText || '').length > 0)
            && (activeTagText.toLowerCase() === tag.title.toLowerCase())}
          onClick={selectTagHandler && (() => selectTagHandler(tag))}
          canSelect={!!selectTagHandler}
        >
          <FAQTagTitle>
            {tag.title}
          </FAQTagTitle>
          {showUsedCount && ((tag.usedCount || 0) > 0) && (
            <TagUsedCount>{tag.usedCount}</TagUsedCount>
          )}
          <RemoveControl
            title={t('common.components.tagsList.removeTagTitle')}
            onClick={removeHandler && (() => removeHandler(tag))}
          >
            <CloseIcon />
          </RemoveControl>
        </FAQTag>
      ))}
    </FAQTagsList>
  );

  return (
    containerMode === CONTAINER_MODES.scrolled ? (
      <ScrollContainer
        isControlsVisible={canScroll.prev || canScroll.next}
      >
        <ScrollControl
          style={{ paddingRight: '.5rem' }}
          onClick={(e) => {
            if (!canScroll.prev) {
              return;
            }
            handleScroll(e, -SCROLL_AMOUNT);
          }}
          isActive={canScroll.prev}
        >
          <ArrowLeft
            size={SCROLL_CONTROL_SIZE}
            color={getScrollControlColor(canScroll.prev)}
          />
        </ScrollControl>
        <ScrollContent
          ref={scrollContainerRef}
        >
          {renderMainContent(isScrollMode)}
        </ScrollContent>
        <ScrollControl
          style={{ paddingLeft: '.5rem' }}
          onClick={(e) => {
            if (!canScroll.next) {
              return;
            }
            handleScroll(e, SCROLL_AMOUNT);
          }}
          isActive={canScroll.next}
        >
          <ArrowRight
            size={SCROLL_CONTROL_SIZE}
            color={getScrollControlColor(canScroll.next)}
          />
        </ScrollControl>
      </ScrollContainer>
    ) : renderMainContent()
  );
};

export default TagsList;

const ScrollControl = styled.div`
  font-size: 0;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
`;

const ScrollContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  ${({ isControlsVisible }) => (!isControlsVisible && `
    ${ScrollControl} {
      display: none;
    }
  `)}
`;

const ScrollContent = styled.div`
  width: 100%;
  overflow: hidden;
`;

const RemoveControl = styled.div`
  position: absolute;
  right: 0.25rem;
  top: calc(1px + 0.2rem);
  padding: 0.25rem;
  border: solid 1px ${red};
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  display: none;
`;

const TagUsedCount = styled.div`
  position: absolute;
  top: -0.5em;
  right: -0.25em;
  width: 1.5em;
  height: 1.5em;

  display: none;
  align-items: center;
  justify-content: center;

  background: ${background02};
  border: solid 1px ${borderTertiary};
  border-radius: 50%;

  font-size: .65em;
  color: ${text02};

`;

const FAQTagTitle = styled.div`
  margin: 0.25rem auto;
  line-height: 1em;
  height: 1em;
`;

const FAQTag = styled.div`
  position: relative;
  display: inline-flex;
  width: max-content;
  height: 1.85rem;
  padding: 0 .75rem ;
  margin-right: .25rem;
  border: solid 1px;
  border-color: ${({ isActive }) => (isActive ? green : greySecondary)};
  border-radius: 1em;
  color: ${({ isActive }) => (isActive ? secondary : text01)};
  cursor: ${({ canSelect, isActive }) => ((canSelect && !isActive) ? 'pointer' : 'default')};
  white-space: pre;
  align-items: center;

  ${({ isActive }) => (isActive && `
    ${TagUsedCount} {
      display: flex;
      color: ${white};
      background: ${green};
      border-color: ${green};
    }
  `)}

  &:hover {
    color: ${secondary};
    border-color: ${primaryHover};
    ${({ canRemove }) => (canRemove && `
      padding: 0 1.75rem 0 .75rem;
      ${RemoveControl} {
        display: block;
      }
    `)}

    & ${TagUsedCount} {
      display: flex;
    }
  }
`;

const FAQTagsList = styled.div`
  display: ${({ isScrollMode }) => (isScrollMode ? 'flex' : 'block')};
  margin: 0 .5rem;
  transition: all 0.15s ease-in 0s;
  ${({ isScrollMode }) => (isScrollMode ? '' : `
    text-align: center;
    & ${FAQTag} {
      margin-bottom: .33rem;
    }
  `)}
`;
