/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { text01 } from '@/common/constants/theme';

import getWordDeclension from '@/common/utils/humanization.util';
import ChannelsMainCard from './components/ChannelsMainCard';
import ChannelsMainDraggedElement from '../ChannelsMainDraggedElement';

import { usePersonalPageContext } from '../../../../../../../PersonalPageContext';
import { useChannelsContext } from '../../../../ChannelsContext';

const ChannelsMainCards = SortableContainer(({
  changeFiltersHandler,
  isDraggedChannel,
  coord,
  showDraggedElem,
  setShowDraggedElem,
  isEditable,
}) => {
  const { t } = useTranslation(['translations']);
  const { channelsLogos } = usePersonalPageContext();
  const {
    channels,
    channelsFilters,
    selectedPlaylist,
  } = useChannelsContext();

  const {
    search,
  } = channelsFilters;

  const elements = [
    t('steps.step4.content.body.drag02'),
    t('steps.step4.content.body.drag02FromTwoToFive'),
    t('steps.step4.content.body.drag02MoreThanSix'),
  ];
  const count = channels && channels.filter((item) => item.isSelected).length;

  // eslint-disable-next-line consistent-return
  const checkIsRender = (item) => {
    if (item.name.toLowerCase().includes(search.toLowerCase())) return true;
  };

  return (
    <Container>
      {isDraggedChannel && showDraggedElem && (
        <ChannelsMainDraggedElement
          count={count}
          x={coord[0]}
          y={coord[1]}
          onHover={() => {
            setShowDraggedElem(true);
          }}
        >
          <>
            <Text>
              {t('steps.step4.content.body.drag')}
            </Text>
            <Text margin>
              {count || 1}
            </Text>
            <Text>
              {getWordDeclension(count || 1, elements)}
            </Text>
          </>
        </ChannelsMainDraggedElement>
      )}
      <CardsContainer>
        {channels && channels.length > 0 && channels.map(
          (item, i) => (checkIsRender(item) && (
          <ChannelsMainCard
            disabled={!isEditable}
            isEditable={isEditable}
            key={item.id}
            title={item.name}
            src={channelsLogos?.[item.url] || item.url}
            isNew={item.is_new}
            isArchive={item.is_archived}
            isChecked={!selectedPlaylist?.is_virtual && !item.is_hidden}
            isStarred={item.isStarred}
            isSelected={item.isSelected}
            i={i}
            index={i}
            changeFiltersHandler={changeFiltersHandler}
          />
          )),
        )}
      </CardsContainer>
    </Container>
  );
});

export default ChannelsMainCards;

const Container = styled.div`
  max-height: 486px;
  padding: 20px 0 34px 0;
  margin-top: 12px;

  overflow-y: auto;
`;

const Text = styled.div`
  ${({ margin }) => margin && ('margin: 0 3px;')}

  font-family: Gilroy;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
