import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import {
  text01,
  red,
  primary,
  text02,
  borderSecondary,
  secondary,
} from '@/common/constants/theme';

import SmileIcon from '@/assets/icons/smile.svg';

import ServerTable from './Main/ServerTable';
import Button from '@/common/components/Button';
import DevicesSelected from '../../DevicesSelected';
import CloseIcon from '../../../icons/CloseIcon';

import { SHOW_VIEW_TYPE, useServerContext } from '../ServerContext';
import {
  SHOW_SELECTED_SERVER,
  SHOW_RIGHT_PANEL,
  usePersonalPageContext,
} from '../../../../PersonalPageContext';

const ServerMain = ({
  // selectedValue,
  bindDeviceToServer,
  forwardHandler,
  isStalker,
  isLogged,
  setIsLoginModalOpened,
  selectedType,
}) => {
  const history = useHistory();

  const {
    selectedDevices,
  } = usePersonalPageContext();

  const {
    servers,
    testingServer,
    isDeviceTypeBusy,
    isAutoServer,
    selectedServer,
    setSelectedServer,
  } = useServerContext();

  const { t } = useTranslation('translations');

  const checkIsDisabled = () => (
    isDeviceTypeBusy
    || testingServer
    || !isLogged
  );

  useEffect(() => {
    const usedServersIds = [];
    selectedDevices.forEach((sd) => {
      if (!usedServersIds.includes(sd.server)) { usedServersIds.push(sd.server); }
    });
    setSelectedServer(
      usedServersIds.length === 1 ? servers.find((s) => s.id === usedServersIds[0]) : null,
    );
  }, [selectedDevices, servers]);

  return (
    <Container>
      {!isAutoServer && (
        <Wrapper bottomMargin={32} flex>
          <Img
            src={SmileIcon}
            alt=""
          />
          <Text>
            {t('steps.step3.content.main.prompt.title')}
          </Text>
          <Text redText leftMarginLittle>
            {t('steps.step3.content.main.prompt.redTitle')}
          </Text>
        </Wrapper>
      )}
      {isAutoServer ? (
        <AutoServerTextWrapper>
          <AutoServerText>
            {t('steps.step3.content.main.autoServerText')}
          </AutoServerText>
        </AutoServerTextWrapper>
      ) : (
        <ServerTable
          isFullWidth={!SHOW_RIGHT_PANEL}
          selectedValue={selectedServer}
          setSelectedValue={bindDeviceToServer}
          isStalker={isStalker}
          isLogged={isLogged}
          setIsLoginModalOpened={setIsLoginModalOpened}
          selectedType={selectedType}
        />
      )}
      <Wrapper flex flexSb bottomMargin={10}>
        <SelectedValuesWrapper>
          {SHOW_VIEW_TYPE && selectedType && (
            <SelectedValues>
              <SelectedValuesTitle>
                {t('steps.step3.content.footer.connection')}
              </SelectedValuesTitle>
              <SelectedValue>
                {t(`steps.step3.deviceType.${selectedType}`)}
              </SelectedValue>
              <SelectedValuesIcon>
                <CloseIcon />
              </SelectedValuesIcon>
            </SelectedValues>
          )}
          {SHOW_SELECTED_SERVER && selectedServer && (
            <SelectedValues>
              <SelectedValuesTitle>
                {t('steps.step3.content.footer.server')}
              </SelectedValuesTitle>
              <SelectedValue>
                {selectedServer.dns}
              </SelectedValue>
              <SelectedValuesIcon>
                <CloseIcon />
              </SelectedValuesIcon>
            </SelectedValues>
          )}
        </SelectedValuesWrapper>
        <Wrapper flex>
          <StyledPrimaryButton
            isBoldText
            isDisabled={checkIsDisabled()}
            onClick={() => !checkIsDisabled() && history.push({
              pathname: '/personal-page/subscription',
              state: { scrollToStepsNav: true },
            })}
          >
            {t('steps.step3.content.footer.goBack')}
          </StyledPrimaryButton>
          <StyledButton
            isBoldText
            isUpperCase
            isOutline
            isDisabled={checkIsDisabled()}
            onClick={() => !checkIsDisabled()
            && forwardHandler()}
          >
            {t('steps.step3.content.footer.forward')}
          </StyledButton>
        </Wrapper>
      </Wrapper>
      <DevicesSelected text={t('steps.step3.content.footer.selected')} />
    </Container>
  );
};

export default ServerMain;

const Container = styled.div`
  margin-right: ${SHOW_RIGHT_PANEL ? '62px' : 0};
  margin-bottom: 126px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && `
    display: flex;
    align-items: center;
  `}
  ${({ flexWrap }) => flexWrap && 'flex-wrap: wrap;'}
  ${({ flexSb }) => flexSb && 'justify-content: space-between;'}

  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}
`;

const Text = styled.div`
  ${({ leftMarginLittle }) => leftMarginLittle && ('margin-left: 3px;')}

  font-family: Gilroy;
  font-size: 13px;
  font-weight: 500;
  color: ${({ redText }) => (redText ? `${red};` : `${text01};`)};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const AutoServerTextWrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  text-align: center;
`;

const AutoServerText = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const Img = styled.img`
  margin-right: 15px;
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 0 17px;

  ${({ isDisabled }) => isDisabled && (`
    cursor: default;
    opacity: 0.2;

    &:hover {
      color: ${text01};
      background-color: transparent;
      opacity: 0.2;
    }

    &:active {
      background-color: transparent;
      opacity: 0.2;
    }
  `)}
`;

const StyledPrimaryButton = styled(StyledButton)`
  margin-left: auto;
  margin-right: 20px;

  background-color: transparent;
  border: none;
  color: ${primary};

  transition: none;

  &:hover {
    background: #eef1fb;
  }

  &:active {
    background-color: transparent;
  }
`;

const SelectedValuesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedValues = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 40px;

    position: relative;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: -20px;

      width: 1px;
      height: 38px;

      background-color: ${borderSecondary};
    }
  }
`;

const SelectedValuesTitle = styled.div`
  margin-right: 16px;

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${text02};
  line-height: 17px;
`;

const SelectedValue = styled.div`
  margin-right: 16px;

  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: ${text01};
  line-height: 36px;
`;

const SelectedValuesIcon = styled.div`
  cursor: pointer;

  & svg path {
    fill: ${secondary};
  }
`;
