import React from 'react';

export default () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#closeIconClip0)">
      <path d="M6.1888 5.30682L9.69322 1.80229C9.92128 1.57434 9.92128 1.20577 9.69322 0.977813C9.46527 0.749858 9.0967 0.749858 8.86874 0.977813L5.36421 4.48234L1.85979 0.977813C1.63172 0.749858 1.26326 0.749858 1.0353 0.977813C0.807242 1.20577 0.807242 1.57434 1.0353 1.80229L4.53973 5.30682L1.0353 8.81134C0.807242 9.0393 0.807242 9.40787 1.0353 9.63582C1.14891 9.74953 1.29828 9.80666 1.44755 9.80666C1.59681 9.80666 1.74608 9.74953 1.85979 9.63582L5.36421 6.1313L8.86874 9.63582C8.98245 9.74953 9.13172 9.80666 9.28098 9.80666C9.43025 9.80666 9.57951 9.74953 9.69322 9.63582C9.92128 9.40787 9.92128 9.0393 9.69322 8.81134L6.1888 5.30682Z" fill="#FF1744" />
    </g>
    <defs>
      <clipPath id="closeIconClip0">
        <rect width="9" height="9" fill="white" transform="translate(0.864258 0.790985)" />
      </clipPath>
    </defs>
  </svg>
);
