/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';

import UsersGroupsHeader from './components/GroupsHeader';
import UsersGroupsMain from './components/GroupsMain/GroupsMain';

const UsersGroups = () => {
  const [isAddNewGroup, setIsAddNewGroup] = useState(false);

  return (
    <Container>
      <UsersGroupsHeader setIsAddNewGroup={setIsAddNewGroup} />
      <UsersGroupsMain isAddNewGroup={isAddNewGroup} setIsAddNewGroup={setIsAddNewGroup} />
    </Container>
  );
};

export default UsersGroups;

const Container = styled.div``;
