import {
  SET_SNACK, SET_MENU, SET_MENU_ITEMS,
  SET_MINISTRA_SERVERS, SET_SERVERS,
  SET_WITHDRAWAL_SYSTEMS, SET_PAYMENT_SYSTEMS,
  SET_COUNTRIES, SET_RAW_COUNTRIES, SET_OS_FORMATS,
  SET_PASSWORD_PIN_CHECK_COUNT, SET_PASSWORD_PIN_CHECK_EXPIRE_DATE,
} from '../types/common';

import {
  PASSWORD_PIN_CHECK_COUNT, PASSWORD_PIN_CHECK_EXPIRE_DATE,
} from '../../constants';

export const setMenu = (menuItems) => ({ type: SET_MENU, payload: menuItems });

export const setMenuItems = (menuItems) => ({ type: SET_MENU_ITEMS, payload: menuItems });

export const setSnack = (snack) => ({ type: SET_SNACK, payload: snack });

export const setSnackFromErrorResponse = (err) => (
  { type: SET_SNACK, payload: err?.response || {} }
);

export const setServers = (servers) => (
  { type: SET_SERVERS, payload: servers }
);

export const setMinistraServers = (ministraServers) => (
  { type: SET_MINISTRA_SERVERS, payload: ministraServers }
);

export const setWithdrawalSystems = (wSystems) => (
  { type: SET_WITHDRAWAL_SYSTEMS, payload: wSystems }
);

export const setOsFormats = (osFormats) => (
  { type: SET_OS_FORMATS, payload: osFormats }
);

export const setPaymentSystems = (pSystems) => (
  { type: SET_PAYMENT_SYSTEMS, payload: pSystems }
);

export const setCountries = (countries) => (
  { type: SET_COUNTRIES, payload: countries }
);

export const setCountriesRaw = (countries) => (
  { type: SET_RAW_COUNTRIES, payload: countries }
);

export const setPasswordPinCheckCount = (pinCheckCount) => {
  const ppcc = parseInt(pinCheckCount, 10);
  window.localStorage.setItem(PASSWORD_PIN_CHECK_COUNT, ppcc);
  return { type: SET_PASSWORD_PIN_CHECK_COUNT, payload: ppcc };
};

export const setPasswordPinCheckExpDate = (pinCheckExpDate) => {
  window.localStorage.setItem(PASSWORD_PIN_CHECK_EXPIRE_DATE, pinCheckExpDate);
  return { type: SET_PASSWORD_PIN_CHECK_EXPIRE_DATE, payload: pinCheckExpDate };
};
