import React from 'react';
import { ReferralsContextProvider } from './ReferralsContext';
import Referrals from './Referrals';

const ReferralsContainer = () => (
  <ReferralsContextProvider>
    <Referrals />
  </ReferralsContextProvider>
);

export default ReferralsContainer;
