/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  text01,
  white,
  primary,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';
import ResponsiveLoader from '@/common/components/ResponsiveLoader';
import Button from '@/common/components/Button';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import PageContentBlock from '../components/PageContentBlock';
import PersonalInfo from './components/PersonalInfo';
import WithdrawalWallets from './components/WithdrawalWallets';
import Notifications from './components/Notifications';
import UpdatePassword from './components/UpdatePassword';
import UpdateEmail from './components/UpdateEmail';
import DeleteUserModal from './components/DeleteUserModal';
import Logs from './components/Logs';

import { useAppContext } from '@/AppContext';
import { useProfileContext } from './ProfileContext';

const Profile = ({ history, match }) => {
  const { t } = useTranslation('translations');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteProfileEnabled, setIsDeleteProfileEnabled] = useState(true);
  const [deleteUserModal, setDeleteUserModal] = useState(false);

  const { userInfo } = useAppContext();
  const {
    getLogs,
    accounts,
    getAccounts,
    confirmAccount,
    confirmChangeAccount,
    confirmChangeEmail,
    deleteUserRequest,
    deleteUser,
    isLoading,
  } = useProfileContext();

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    const { type, param } = match.params;
    switch (type) {
      case 'confirm-an-account':
        confirmAccount(param)
          .then(({ errors }) => {
            !errors && setSuccessText(t('profile.accountsAndFinance.successEdit'));
            history.push('/personal-page/profile');
          });
        break;
      case 'confirm-change-an-account':
        confirmChangeAccount(param)
          .then(({ errors }) => {
            !errors && setSuccessText(t('profile.accountsAndFinance.successAdd'));
            history.push('/personal-page/profile');
          });
        break;
      case 'update-password':
        setChangePassword(true);
        break;
      case 'update-email':
        setChangeEmail(true);
        break;
      case 'user-complete-email':
        confirmChangeEmail(param)
          .then(({ success }) => {
            success && setSuccessText(t('profile.personalInfo.successChangeEmail'));
            history.push('/personal-page/profile');
          });
        break;

      case 'complete-user-delete':
        deleteUser(param)
          .then(({ success }) => {
            success && history.push('/personal-page/devices');
          });
        break;

      default:
        break;
    }
  }, [accounts]);

  useEffect(() => {
    getLogs();
  }, [userInfo?.id]);

  const deleteUserAction = () => {
    setDeleteLoading(true);
    deleteUserRequest()
      .then(() => {
        setDeleteLoading(false);
        setDeleteUserModal(true);
      })
      .catch((err) => {
        setIsDeleteProfileEnabled(false);
        setDeleteLoading(false);
        setErrorText(t(`profile.delete.errors.${t(`profile.delete.errors.${err?.response?.payload?.error_type}`)}`));
      });
  };

  if (isLoading || !userInfo) {
    return <Loader />;
  }

  return (
    <Container>
      <SnackBarWindow
        isOpen={!!successText}
        onClose={() => setSuccessText('')}
        content={successText}
        type="success"
      />
      <SnackBarWindow
        isOpen={!!errorText}
        onClose={() => setErrorText('')}
        content={errorText}
        type="error"
      />
      <UpdatePassword
        isOpen={changePassword}
        onClose={() => setChangePassword(false)}
      />
      <UpdateEmail
        isOpen={changeEmail}
        onClose={() => setChangeEmail(false)}
      />
      <DeleteUserModal
        isOpen={deleteUserModal}
        onClose={() => setDeleteUserModal(false)}
        email={userInfo.email}
      />
      <PageContentBlock>
        <Title>
          {t('profile.title')}
        </Title>

        <SubTitle>
          {t('profile.subTitle')}
        </SubTitle>
        <InfoContainer>
          <WhiteBlockWithRightMargin>
            <PersonalInfo />
          </WhiteBlockWithRightMargin>
          <WhiteBlock>
            <WithdrawalWallets setErrorText={setErrorText} setSuccessText={setSuccessText} />
          </WhiteBlock>
        </InfoContainer>
        <InfoContainer>
          <WhiteBlock>
            <Notifications />
          </WhiteBlock>
        </InfoContainer>
        <InfoContainer>
          <WhiteBlock>
            <Logs />
          </WhiteBlock>
        </InfoContainer>
        <Actions>
          {deleteLoading
            ? (
              <LoaderContainer>
                <ResponsiveLoader size={5} />
              </LoaderContainer>
            )
            : (
              <Button
                disabled={!isDeleteProfileEnabled}
                width="medium"
                isUpperCase
                isBoldText
                onClick={deleteUserAction}
              >
                {t('profile.deleteAccount')}
              </Button>
            )}

        </Actions>
      </PageContentBlock>
    </Container>
  );
};

export default withRouter(Profile);

const Container = styled.div``;

const LoaderContainer = styled.div`
  padding: 1rem;
`;

const SubTitle = styled.div`
    font-family: Gilroy, sans-serif;
    margin-bottom: 22px;
    font-size: 15px;
    line-height: 20px;
`;

const Title = styled.div`
    font-family: Gilroy, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.6;
    color: ${text01};
    position: relative;
    margin-bottom: 33px;
    &:after {
        content: '';
        height: 4px;
        position: absolute;
        width: 30px;
        bottom: -10px;
        left: 0;
        background: ${primary};
    }
`;

const InfoContainer = styled.div`
  display: flex;
`;

const WhiteBlock = styled.div`
  background: ${white};
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;
  padding: 1.625rem;
  margin: 1.25rem 0;
  min-height: 11rem; //REMOVE
`;

const WhiteBlockWithRightMargin = styled(WhiteBlock)`
  margin-right: 1.25rem;
`;

const Actions = styled.div`
  margin: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
`;
