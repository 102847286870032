import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DeleteIcon from '@/assets/icons/delete-trash.svg';
import { white } from '@/common/constants/theme';
import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Button from '@/common/components/Button';

const DNSDeleteModal = ({
  isOpen,
  onClose,
  data,
  onDelete,
}) => {
  const { t } = useTranslation(['translations']);

  const submitHandler = () => {
    onDelete();
  };

  return (
    <>
      <ModalWithoutContent
        isOpen={isOpen}
        onCloseModal={onClose}
        icon={DeleteIcon}
      >
        <Text>{t('DNS.modal.delete.title')}</Text>
        <Text title={(data || '')}>
          {(data || '').length >= 20
            ? `${(data || '').slice(0, 20)}...`
            : (data || '')}
        </Text>
        <Text topMargin bottomMargin>{t('DNS.modal.delete.warning')}</Text>
        <StyledButton
          onClick={submitHandler}
          isBoldText
          isUpperCase
        >
          {t('DNS.modal.delete.delete')}
        </StyledButton>
      </ModalWithoutContent>
    </>
  );
};

export default DNSDeleteModal;

const Text = styled.div`
  ${({ topMargin }) => topMargin && ('margin-top: 15px;')};
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 15px;')};

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${white};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const StyledButton = styled(Button)`
  width: 144px;
`;
