import React, {
  createContext, useContext,
  useState, useEffect,
} from 'react';

import {
  getTemplateDecorsListAction,
  getTemplateDecorsItemAction, addTemplateDecorsItemAction,
  removeTemplateDecorsItemAction, updateTemplateDecorsItemAction,
  uploadImageAction, deleteImageAction,
} from '@/services/actions/admin/templateDecors.actions';

import { SnackTypes, useAppContext } from '@/AppContext';
import { useAdminContext } from '../../../AdminContext';

export const BASE_PATH = '/admin/public/email-templates/';
export const DATA_TYPES = {
  tdList: 'tdList',
  tdItem: 'tdItem',
};

export const EMPTY_OPTION = { key: '-', title: 'Все' };
export const EMPTY_CONTENT_ITEM = {
  id: null,
  tmp_email_template_content_id: null,
  language: 0,
  html_content: '',
  componentId: `ecId-${Math.random()}`,
};
export const CONTENT_VARIABLES = [
  {
    name: 'content',
    required: true,
    title: 'Содержимое',
  },
  {
    name: 'unsubscribe_url',
    required: true,
    title: 'Полная ссылка для отписки от подписки',
  },
];

const TemplateDecorsContext = createContext({});

const initialFilters = {
  page: 1,
  limit: parseInt(localStorage.getItem('adminTemplateDecorsListLimit') || '10', 10),
  search: '',
  language: null,
  is_published: null,
};

export const TemplateDecorsContextProvider = ({ children }) => {
  const [dataProcessing, setDataProcessing] = useState({});
  const [filters, setFilters] = useState(initialFilters);
  const [tdDataList, setTdDataList] = useState([]);
  const [tdDataCount, setTdDataCount] = useState(0);
  const [selectedTdItem, setSelectedTdItem] = useState(null);

  const { siteLanguages } = useAppContext();
  const { setAdminSnack } = useAdminContext();

  const loadList = () => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdList]: true }));
    return getTemplateDecorsListAction(filters)
      .then((res) => {
        setTdDataList(res?.results || []);
        setTdDataCount(res?.count || 0);
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdList]: false }));
      });
  };

  const clearSelectedTdItem = () => {
    setSelectedTdItem(null);
  };

  const loadItem = async (id = null) => {
    const useId = id || 0;
    if (useId === 0) {
      const errMsg = 'ID элемента не передан';
      setAdminSnack({ type: SnackTypes.error, content: errMsg }, SnackTypes.error);
      return Promise.reject(new Error(errMsg));
    }
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdItem]: true }));
    return getTemplateDecorsItemAction(id)
      .then((res) => {
        // add empty lang content, if lang data not set
        const contentsData = res.contents || [];
        (siteLanguages || []).forEach((sl) => {
          if (!contentsData.find((cd) => cd.language === sl.id)) {
            contentsData.push({ ...EMPTY_CONTENT_ITEM, language: sl.id });
          }
        });

        const result = { ...res, contents: contentsData };
        setSelectedTdItem(result);

        return result;
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdItem]: false }));
      });
  };

  const addTdItem = (faqItem) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdItem]: true }));
    return addTemplateDecorsItemAction(faqItem)
      .then((res) => {
        loadList();
        return res;
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        return err;
      })
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdItem]: false }));
      });
  };

  const updateTdItem = (item) => {
    const useItem = item || selectedTdItem;
    if (!useItem) {
      setAdminSnack({ type: SnackTypes.error, content: 'Неверные данные для обновления' },
        SnackTypes.error);
      return Promise.reject();
    }
    const { id, ...data } = item;

    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdItem]: true }));
    return updateTemplateDecorsItemAction(id, data)
      .then((res) => {
        loadList();
        return res;
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdList]: false }));
        return err;
      });
  };

  const deleteTdItem = (id) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdList]: true }));
    return removeTemplateDecorsItemAction(id)
      .then(() => loadList())
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.tdList]: false }));
      });
  };

  const uploadImage = (file, id, tmpId) => uploadImageAction(file, id, tmpId)
    .then((res) => {
      if ((res?.id || 0) > 0) {
        const useContentIndex = selectedTdItem.contents.findIndex((c) => c.id === id);
        if (useContentIndex !== undefined) {
          const content = selectedTdItem.contents[useContentIndex];
          const newContents = [...selectedTdItem.contents];
          const imgs = [...content.images || []];
          imgs.push(res);
          newContents.splice(useContentIndex, 1, { ...content, images: imgs });
          setSelectedTdItem(((prevState) => ({ ...prevState, contents: newContents })));
        }
      }
    });

  const deleteImage = (id, content) => deleteImageAction(id)
    .then(() => {
      const newContents = [...selectedTdItem.contents];
      const contentIndex = newContents.findIndex((c) => c.id === content.id);
      const newContent = { ...content, images: content.images.filter((i) => i.id !== id) };
      newContents.splice(contentIndex, 1, newContent);

      setSelectedTdItem({
        ...selectedTdItem,
        contents: newContents,
      });
    })
    .catch((err) => setAdminSnack(err, SnackTypes.error));

  useEffect(() => {
    loadList();
  }, [filters]);

  useEffect(() => {
    localStorage.setItem('adminTdListLimit', filters?.limit || 10);
  }, [filters?.limit]);

  const value = {
    dataProcessing,
    tdDataList,
    tdDataCount,
    selectedTdItem,
    setSelectedTdItem,
    filters,
    setFilters,

    clearSelectedTdItem,
    loadItem,
    deleteTdItem,
    addTdItem,
    updateTdItem,
    uploadImage,
    deleteImage,
  };

  return (
    <TemplateDecorsContext.Provider value={value}>
      {children}
    </TemplateDecorsContext.Provider>
  );
};

export const useTemplateDecorsContext = () => useContext(TemplateDecorsContext);
