import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import Button from '@/common/components/Button';
import { AdminMainPopupHeader, MainContent } from '../../../../components/MainPopupComponents';
import { useMinistraContext } from '../MinistraContext';

import EditServerFieldsBlock from './EditServerFieldsBlock';

const EditServer = ({
  isCreate,
  setIsCreate,
  editedServer,
  setEditedServer,
  inputValues,
  setInputValues,
}) => {
  const { t } = useTranslation(['translations']);

  const {
    getMinistra,
    createMinistra,
    updateMinistra,
  } = useMinistraContext();

  useEffect(() => {
    if (editedServer) {
      getMinistra(editedServer)
        .then((res) => setInputValues({ ...res, server_id: res.server_id || '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tmpErrors, setTmpErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [initValues, setInitValues] = useState({});

  const SERVER_FIELDS = [[{
    key: 'title',
    title: t('admin.iptv.ministra.serverField_title'),
    required: true,
  }, {
    key: 'dns',
    title: t('admin.iptv.ministra.serverField_dns'),
    required: true,
  }, {
    key: 'streaming_server_dns',
    title: t('admin.iptv.ministra.serverField_streaming_server_dns'),
    required: true,
  }, {
    key: 'streaming_server_archive_days',
    title: t('admin.iptv.ministra.serverField_streaming_server_archive_days'),
    required: true,
  }, {
    key: 'web_login',
    title: t('admin.iptv.ministra.serverField_web_login'),
    required: true,
  }, {
    key: 'web_password',
    title: t('admin.iptv.ministra.serverField_web_password'),
    required: true,
  }, {
    key: 'version',
    title: t('admin.iptv.ministra.serverField_version'),
    isDropdown: true,
    options: [{ value: 'v5.3' }, { value: 'v5.6' }],
  }], [{
    key: 'custom_api_url',
    title: t('admin.iptv.ministra.serverField_custom_api_url'),
    required: true,
  }, {
    key: 'custom_api_secret',
    title: t('admin.iptv.ministra.serverField_custom_api_secret'),
    required: true,
  }]];

  const checkValues = () => {
    const errorsValue = {};
    for (let i = 0; i < SERVER_FIELDS.length; i += 1) {
      SERVER_FIELDS[i].forEach(({ key, required }) => {
        errorsValue[key] = required && (inputValues[key] === null || inputValues[key] === '');
      });
    }
    setTmpErrors(errorsValue);
    return Object.values(errorsValue).filter((v) => v).length === 0;
  };

  useEffect(() => {
    if (Object.values(initValues).length === 0) {
      setInitValues(inputValues);
    }
  }, []);

  const isValuesChanged = () => {
    let isChanged = false;
    Object.keys(initValues).every((key) => {
      if (inputValues[key] !== initValues[key]) {
        isChanged = true;
        return false;
      }
      return true;
    });
    return isChanged;
  };

  const handleInput = (field, value) => {
    setInputValues((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleInputLostFocus = () => {
    setIsDisabled(!checkValues() || !isValuesChanged());
  };

  const onSave = async () => {
    setErrors(tmpErrors);
    if (checkValues()) {
      if (isCreate) {
        await createMinistra(inputValues);
      } else {
        updateMinistra(editedServer, inputValues);
      }
      setEditedServer('');
      setIsCreate(false);
    }
  };

  let blockKey = 0;

  return (
    <Container>
      <AdminMainPopupHeader
        handleClose={() => {
          setEditedServer('');
          setIsCreate(false);
        }}
        linkText={t('admin.iptv.ministra.backLinkText')}
        title={editedServer ? t('admin.iptv.ministra.editServerWindowTitle') : isCreate && t('admin.iptv.ministra.addServerWindowTitle')}
      />
      <MainContent>
        {SERVER_FIELDS.map((fields) => {
          blockKey += 1;
          return (
            <EditServerFieldsBlock
              key={blockKey}
              fields={fields}
              inputValues={inputValues}
              errors={errors}
              handleInput={handleInput}
              handleInputLostFocus={handleInputLostFocus}
              selectOptionText={t('common.selectOption')}
              requiredText={t('common.requiredField')}
            />
          );
        })}

      </MainContent>
      <StyledButton
        disabled={isDisabled}
        isBoldText
        isUpperCase
        onClick={onSave}
      >
        {t('common.saveButton')}
      </StyledButton>
    </Container>
  );
};

export default EditServer;

const Container = styled.div``;

const StyledButton = styled(Button)`
  width: 116px;
  margin-left: 18px;
  margin-bottom: 20px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
