import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { enUS as en, ru } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  secondary, red, background01, text01, text03, text04, grey,
} from '@/common/constants/theme';

import ArrowLeft from '../icons/ArrowLeft';
import ArrowRight from '../icons/ArrowRight';
import CloseIcon from '@/common/components/icons/CloseIcon';

import { useNotificationsContext } from './NotificationsContext';

const MAX_CONSEQ_CLOSE_COUNT = 3;
const SHOW_CLOSE_ALL_NOTIFICATION_TIMEOUT = 1000 * 5;

const Notifications = () => {
  const { t, i18n } = useTranslation('translations');
  const {
    allNotifications,
    closeNotification,
    closeAllNotifications,
    getNotifications,
  } = useNotificationsContext();

  const [notificationIndex, setNotificationIndex] = useState(0);
  const [showCloseAllNotifications, setShowCloseAllNotifications] = useState(false);
  const [closedNotificationsCount, setClosedNotificationsCount] = useState(0);

  let showCloseAllNotificationsTimeout = useRef(null);

  const currentNotification = allNotifications[notificationIndex] ?? {};

  const closeNotificationHandle = (item) => {
    if (allNotifications.length === notificationIndex + 1) {
      setNotificationIndex(notificationIndex > 0 ? notificationIndex - 1 : 0);
    }
    setClosedNotificationsCount((prev) => prev + 1);
    closeNotification(item);
  };

  const closeAllNotificationsHandle = () => {
    if (allNotifications.length > 0) {
      closeAllNotifications();
    }
  };

  const dateToString = (jsonDate) => {
    const locales = { en, ru };
    const selectedLocale = locales[i18n.language];
    const dateObj = jsonDate ? dayjs(jsonDate, 'YYYY-MM-DD', true).toDate() : null;
    const dateStr = dateObj ? format(dateObj, 'dd MMMM yyyy', { locale: selectedLocale }) : '';
    return dateStr;
  };

  useEffect(() => {
    getNotifications();

    return () => {
      if (showCloseAllNotificationsTimeout) {
        clearTimeout(showCloseAllNotificationsTimeout);
      }
    };
  }, []);

  useEffect(() => {
    if ((closedNotificationsCount >= MAX_CONSEQ_CLOSE_COUNT)
      && (allNotifications.length > 2)) {
      setShowCloseAllNotifications(true);
      if (showCloseAllNotificationsTimeout) {
        clearTimeout(showCloseAllNotificationsTimeout);
      }
      showCloseAllNotificationsTimeout = setTimeout(
        () => { setShowCloseAllNotifications(false); },
        SHOW_CLOSE_ALL_NOTIFICATION_TIMEOUT,
      );
    }
  }, [closedNotificationsCount]);
  return (
    <Container>
      {allNotifications.length ? (
        <>
          <Header>
            {currentNotification.isUrgent && <UrgentMessage>{t('notifications.urgent')}</UrgentMessage>}
            <Title>{currentNotification.title}</Title>
            <CloseOneIconContainer
              title={t('notifications.readOne')}
              onClick={() => closeNotificationHandle(currentNotification)}
            >
              <CloseIcon width={12} height={12} />
            </CloseOneIconContainer>
            {showCloseAllNotifications && (
              <CloseAllContainer
                title={t('notifications.readAll')}
                onClick={() => closeAllNotificationsHandle()}
              >
                <StyledCloseIcon width={12} height={12} />
                <StyledCloseIcon marginRight={-2} width={12} height={12} />
              </CloseAllContainer>
            )}
          </Header>
          <Text
            dangerouslySetInnerHTML={{ __html: currentNotification.text }}
          />
          <Footer>
            <NavText isVisible={allNotifications.length > 1}>{t('common.oneOf', { X: notificationIndex + 1, N: allNotifications.length })}</NavText>
            <Date>{dateToString(currentNotification.created_date)}</Date>
            <Pagination>
              <IconButton
                disabled={notificationIndex === 0}
                onClick={() => setNotificationIndex(notificationIndex - 1)}
              >
                <ArrowLeft />
              </IconButton>
              <IconButton
                disabled={notificationIndex === allNotifications.length - 1}
                onClick={() => setNotificationIndex(notificationIndex + 1)}
              >
                <ArrowRight />
              </IconButton>
            </Pagination>
          </Footer>
        </>
      ) : (
        <>
          <Header>{t('notifications.title')}</Header>
          <Text withOpacity>{t('notifications.noData')}</Text>
        </>
      )}
    </Container>
  );
};

export default Notifications;

const Container = styled.div`
  width: 100%;
  padding: 1rem 1.25rem .85rem;
  margin-bottom: .5rem;
  background: ${background01};
  border: 1px solid #efeef3;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  height: 155px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  font-family: Gilroy, sans-serif;
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
`;

const CloseOneIconContainer = styled.div`
  position: absolute;
  right: .75rem;
  top: .75rem;
  font-size: 0;
  cursor: pointer;

  & path {
    fill: ${grey};
  }
  &:hover path {
    fill: ${red};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  ${({ marginRight }) => (marginRight && `
    margin-right: ${marginRight}px;
  `)}
`;

const CloseAllContainer = styled.div`
  position: absolute;
  top: 0;
  right: -3.4rem;

  padding: .7rem;
  border: 1px solid #efeef3;
  border-radius: 4px;
  background: ${background01};

  font-size: 0;
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  align-items: center;
`;

const UrgentMessage = styled.div`
  background: ${red};
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 3px 5px;
  margin-top: -3px;
  margin-right: .5rem;
`;

const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin-top: .5rem;
  ${({ withOpacity }) => withOpacity && 'opacity: 0.5;'}
  height: 4.5rem;
  white-space: pre-wrap;
  overflow: auto;
`;

const Footer = styled.div`
  display: flex;
  margin-top: .5rem;
  justify-content: space-between;
  align-items: center;
`;

const FooterText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: ${text03};
`;

const NavText = styled(FooterText)`
  ${({ isVisible }) => !isVisible && `
    visibility: hidden;
  `}
`;

const Date = styled(FooterText)`
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  path {
    fill: ${secondary};
  }
  ${({ disabled }) => disabled && `
    cursor: default;
    path {
      fill: ${text04};
    }
  `}
  :nth-child(1) {
    margin-right: .5rem;
  }
  :nth-child(2) {
    margin-left: .5rem;
  }
`;
