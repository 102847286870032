import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import {
  text01, secondary, primary, white, greyWhite,
} from '@/common/constants/theme';

import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import Tooltip from '@/common/components/Tooltip';
import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Loader from '@/common/components/Loader';
import Button from '@/common/components/Button';

import ArrowTable from '../../../../../../icons/ArrowTable';
import SearchIcon from '../../../../../../../../PersonalPageScreen/components/icons/SearchIcon';
import AdminTablePagination from '../../../../../components/Table/components/TablePagination';
import DeleteIcon from '../../../../../../icons/DeleteIcon';
import CloseIcon from '../../../../../../icons/CloseIcon';

import { useUsersGroupsContext } from '../../../GroupsContext';

const SIZES = ['70px', '600px', '300px'];
const ACTIONS = {
  notSelected: 'notSelected',
  deleteUser: 'deleteUser',
};
const SEARCH_FILTER_TIMEOUT = 500;
const SORT_DIRECTIONS = {
  notSetted: '-',
  asc: 'asc',
  desc: 'desc',
};

const UsersGroupsMainTable = () => {
  const {
    filters, setFilters,
    setSelectedGroup,
    removeUserFromGroup,
    selectedGroup,
    selectedGroupUsers,
    isUserTableBusy,
  } = useUsersGroupsContext();

  const [search, setSearch] = useState({});
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [sortByDate, setSortByDate] = useState(SORT_DIRECTIONS.notSetted);

  const [localTableData, setLocalTableData] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAction, setSelectedAction] = useState(ACTIONS.notSelected);

  const handleDeleteUser = (user) => {
    setSelectedAction(ACTIONS.deleteUser);
    setSelectedUser(user);
  };

  const deleteUser = () => {
    removeUserFromGroup(selectedUser.user.id);
    setSelectedAction(ACTIONS.notSelected);
    setSelectedUser(null);
  };

  const sorts = {
    asc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.asc],
    desc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.desc],
  };

  const changeSortByDateDirection = () => {
    const directionsKeys = Object.keys(SORT_DIRECTIONS);
    const directions = Object.values(SORT_DIRECTIONS);
    const currentSortIndex = directions.findIndex((s) => s === sortByDate) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;
    setSortByDate(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);
  };

  useEffect(() => {
    if ((selectedGroup?.id || -1) > -1) {
      setSelectedGroup(selectedGroup);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(setTimeout(() => Object.entries(search).forEach(([key, value]) => {
      if ((value?.length || 0) > 0) {
        setFilters({ ...filters, [key]: value });
      } else {
        setFilters(delete { ...filters }[key]);
      }
    }), SEARCH_FILTER_TIMEOUT));
  }, [search]);

  useEffect(() => {
    const tableData = sortByDate !== SORT_DIRECTIONS.notSetted
      ? [...selectedGroupUsers].sort((a, b) => {
        const aD = new Date(a);
        const bD = new Date(b);
        if (aD === bD) return 0;
        return (aD > bD ? 1 : -1) * (sortByDate === SORT_DIRECTIONS.asc ? 1 : -1);
      }) : [...selectedGroupUsers];
    setLocalTableData(tableData);
  }, [selectedGroupUsers, sortByDate]);

  return selectedGroupUsers && (
    <Container>
      {selectedUser
        && (
          <ModalWithoutContent
            color="danger"
            isOpen={selectedAction === ACTIONS.deleteUser}
            onCloseModal={() => setSelectedAction(ACTIONS.notSelected)}
          >
            <Text>
              Вы хотите удалить
            </Text>
            <Text largeFontSize>
              {selectedUser.name}
            </Text>
            <Text marginBottom={12}>
              {`${selectedUser?.user?.email}, ID ${selectedUser?.user?.id || 0}`}
            </Text>
            <Text>
              из группы
              {' '}
              {selectedGroup.title}
            </Text>
            <ButtonsWrapper>
              <CancelButton onClick={() => setSelectedAction(ACTIONS.notSelected)}>
                Отмена
              </CancelButton>
              <StyledButton
                isBoldText
                isUpperCase
                onClick={deleteUser}
              >
                Да
              </StyledButton>
            </ButtonsWrapper>
          </ModalWithoutContent>
        )}
      <Title>
        Пользователи в группе
        {' '}
        {selectedGroup.title}
      </Title>
      <Table
        dataLength={localTableData.length}
        cellSizes={SIZES}
        page={filters.page}
        limit={filters.limit}
        textAlign="left"
      >
        {isUserTableBusy && <Loader />}
        <TableHead>
          <HeadItem flex>
            Дата
            <IconsWrapper
              onClick={() => changeSortByDateDirection()}
            >
              {sorts.asc.includes(sortByDate)
                && (
                <Icon isRotate>
                  <ArrowTable />
                </Icon>
                )}
              {sorts.desc.includes(sortByDate)
                && (
                  <Icon leftMargin top>
                    <ArrowTable />
                  </Icon>
                )}
            </IconsWrapper>
          </HeadItem>
          <HeadItem>
            Email пользователя
          </HeadItem>
          <HeadItem flex>
            <Input
              value={search.email || ''}
              onChange={({ target }) => setSearch({ ...search, email: target.value })}
              placeholder="Поиск"
            />
            <Icon top>
              <SearchIcon />
            </Icon>
            {(search.email || '').length > 0
              && (
                <CloseIconContainer
                  onClick={() => setSearch({ ...search, email: '' })}
                >
                  <CloseIcon />
                </CloseIconContainer>
              )}
          </HeadItem>
        </TableHead>
        <TableBody>
          {localTableData.map((user) => (
            <div key={`AP-UG-ULT-R${user.id}`}>
              <RowItem>
                {user.created_at}
              </RowItem>
              <RowItem>
                <Tooltip trigger="hover" position="left" verticalPosition="bottom" title={user?.user?.email}>
                  <StyledLink to={`/admin/users/main/${user?.user?.id}`}>
                    {user.name}
                  </StyledLink>
                </Tooltip>
              </RowItem>
              <RowItem>
                <Icon top onClick={() => handleDeleteUser(user)}>
                  <DeleteIcon />
                </Icon>
              </RowItem>
            </div>
          ))}
        </TableBody>
        <AdminTablePagination
          page={filters.page || 1}
          setPage={(p) => setFilters({ ...filters, page: p })}
          pagesCount={Math.ceil(localTableData.length / filters.limit)}
          recordsCount={localTableData.length}
        />
      </Table>
    </Container>
  );
};

export default UsersGroupsMainTable;

const Container = styled.div`
  & .table-rows__row__item:last-child {
    justify-content: flex-end;
  }
`;

const Title = styled.div`
  margin-bottom: 16px;

  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${text01};
  line-height: 15px;
`;

const HeadItem = styled.div`
  ${({ flex }) => flex && `
    display: flex;

    width: 100%;
  `}
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
`;

const Icon = styled.div`
  ${({ leftMargin }) => leftMargin && 'margin-left: 2px;'}

  position: relative;
  ${({ top }) => top && 'top: 3px;'}

  cursor: pointer;
  ${({ isRotate }) => isRotate && 'transform: rotate(-180deg);'}
`;

const IconsWrapper = styled.div`
  display: flex;

  width: 18px;
  margin-left: 10px;
`;

const Input = styled.input`
  width: 230px;
  padding-bottom: 4px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${secondary};
  outline: none;
`;

const RowItem = styled.div``;

const StyledLink = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: ${primary};
  line-height: 16px;

  &:hover,
  &:focus {
    color: ${primary};
  }
`;

const Text = styled.div`
  margin-bottom: ${({ marginBottom }) => (marginBottom || 6)}px;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${white};
  text-align: left;
  line-height: 15px;

  ${({ largeFontSize }) => largeFontSize && `
    margin-bottom: 8px;

    font-size: 20px;
    line-height: 20px;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 280px;
  margin-top: 10px;
`;

const CancelButton = styled.button`
  margin-left: auto;

  background-color: transparent;
  border: none;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${greyWhite};
  text-decoration: underline;
  text-transform: uppercase;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  width: 54px;
  height: 48px;
  margin-left: 24px;
`;
