import React from 'react';
import styled from 'styled-components';

import {
  text01,
  borderSecondary,
  white,
  grey,
  brightGreen,
  secondary,
} from '@/common/constants/theme';
import CloseIcon from '@/common/components/icons/CloseIcon';

const COLORS = {
  disabled: grey,
  success: brightGreen,
  secondary,
};

const UsersGroupsMainInfoWindow = ({
  isClosable,
  onClose,
  onSubmit,
  title,
  buttonTitle,
  buttonHint,
  isButtonDisabled,
  color,
  children,
}) => (
  <Container>
    {isClosable && (
      <Icon onClick={onClose && onClose}>
        <CloseIcon />
      </Icon>
    )}
    <Title>
      {title}
    </Title>
    {children}
    <Button
      title={buttonHint}
      disabled={isButtonDisabled}
      color={color}
      onClick={() => {
        if (onSubmit) {
          onSubmit();
        }
        if (onClose) {
          onClose();
        }
      }}
    >
      {buttonTitle}
    </Button>
  </Container>
);

export default UsersGroupsMainInfoWindow;

const Container = styled.div`
  padding: 20px 20px 30px;

  position: relative;

  background-color: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
`;

const Title = styled.div`
  margin-bottom: 18px;

  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${text01};
  line-height: 24px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 42px;
  margin-top: 20px;

  background-color: transparent;
  border: 1px solid ${({ color, disabled }) => COLORS[disabled ? 'disabled' : color]};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ color, disabled }) => COLORS[disabled ? 'disabled' : color]};
  text-transform: uppercase;
  line-height: 24px;

  transition: all 0.2s ease-in 0s;
`;

const Icon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;

  & svg path {
    fill: ${text01};
  }
`;
