import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  text01, red,
} from '@/common/constants/theme';

import AuthorizationLayout from '../components/AuthorizationLayout';
import Loader from '@/common/components/Loader';
import Button from '@/common/components/Button';
import TitleWithOrange from '../components/TitleWithOrange';

import {
  unsubscribeAction,
} from '@/services/actions/profile.actions';

export default function UnsubscribeContainer({ match }) {
  const { t } = useTranslation('translations');

  const [errors, setErrors] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isUnsubscribeConfirmed, setIsUnsubscribeConfirmed] = useState(false);

  const texts = {
    firstPart: t('unsubscribe.texts.textBlack1'),
    orangePart: t('unsubscribe.texts.textOrange'),
    secondPart: t('unsubscribe.texts.textBlack2'),
    button: t('unsubscribe.button'),
  };

  const handleConfirm = () => {
    const { token } = match.params;

    setLoading(true);
    unsubscribeAction(token)
      .then(() => setIsUnsubscribeConfirmed(true))
      .catch(({ data }) => setErrors(t(`unsubscribe.errors.${data?.message_type || 'undefined'}`)))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {isLoading && <Loader />}
      <AuthorizationLayout>
        <TitleWithOrange
          textBlack1={texts.firstPart}
          textOrange={texts.orangePart}
          textBlack2={texts.secondPart}
        />
        <Form>
          {!isUnsubscribeConfirmed ? (
            <>
              <Text>{t('unsubscribe.texts.text')}</Text>
              <Button
                onClick={handleConfirm}
                color="primary"
                isUpperCase
                isDisabled={isLoading}
              >
                {t('unsubscribe.button')}
              </Button>
            </>
          ) : (
            <>
              <Text>{t('unsubscribe.texts.unsubscribedText')}</Text>
            </>
          )}
          {errors && (
            <div style={{ color: red, marginTop: '1em' }}>{ errors }</div>
          )}
        </Form>
      </AuthorizationLayout>
    </>
  );
}

const Form = styled.div`
  font-family: Gilroy, sans-serif;
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const Text = styled.span`
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.2em;
  color: ${text01};
  white-space: pre-wrap;
  margin-bottom: 1rem;
`;
