import React from 'react';
import styled from 'styled-components';
import {
  secondary,
  darkGrey,
  borderSecondary,
} from '@/common/constants/theme';

export default ({ progress, isActive, className }) => (
  <ProgressBar className={className}>
    <ActiveProgressBar style={{ width: `${progress}%` }} isActive={isActive} />
  </ProgressBar>
);

const ProgressBar = styled.div`
  height: 3px;
  width: 100%;
  background: ${borderSecondary};
`;

const ActiveProgressBar = styled.div`
  height: 3px;
  background: ${({ isActive }) => (isActive ? secondary : darkGrey)};
`;
