/* eslint no-console: 0 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { greyText } from '@/common/constants/theme';
import Loader from '@/common/components/Loader';
import AuthorizationLayout from '../components/AuthorizationLayout';
import { changeEmailRequestAction } from '@/services/actions/authorization.actions';
import TitleWithOrange from '../components/TitleWithOrange';
import EmailInput from '../components/EmailInput';
import Button from '@/common/components/Button';

export default function ChangeEmailContainer({ match }) {
  const { t } = useTranslation(['translations']);
  const [errors, setErrors] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [isRequestSended, setIsRequestSended] = useState(false);
  const [isEmailChangeRequested, setIsEmailChangeRequested] = useState(false);
  const [requestResult, setRequestResult] = useState('');

  const successChange = (text) => {
    setLoading(false);
    setIsEmailChangeRequested(true);
    setRequestResult(text);
  };

  const failureChange = (err) => {
    setLoading(false);
    setIsEmailChangeRequested(false);
    let msg = '';
    if (typeof err === 'string') {
      msg = err;
    } else if (typeof err === 'object') {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const m of Object.keys(err)) {
        msg += err.errors[m].join(' ');
      }
    }
    // eslint-disable-next-line no-console
    setErrors(msg);
    setNewEmail('');
  };

  const handleChangeEmail = () => {
    setLoading(true);
    const { uid, token } = match.params;
    setIsRequestSended(true);
    changeEmailRequestAction(uid, token, newEmail)
      .then((res) => {
        if (res.detail) {
          successChange(res.detail);
        } else {
          failureChange(res);
        }
      })
      .catch((err) => {
        const errType = (err?.response?.payload?.message_type || err?.data?.message_type)
          ? `confirm.changeEmail.errors.${err?.response?.payload?.message_type || err?.data?.message_type}`
          : 'common.error.request';
        failureChange(t(errType));
      });
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <AuthorizationLayout>
        <TitleWithOrange
          textBlack1={t('confirm.changeEmail.request.textBlack1')}
          textOrange={t('confirm.changeEmail.request.textOrange')}
          textBlack2={t('confirm.changeEmail.request.textBlack2')}
        />
        <Form>
          {isRequestSended && isEmailChangeRequested
            ? (
              <>
                <RequestResultBlock>{requestResult}</RequestResultBlock>
                <RequestSuccesBlock>
                  <Caption>{t('confirm.changeEmail.success.confirmEmailSended', { email: newEmail })}</Caption>
                </RequestSuccesBlock>
              </>
            ) : (
              <>
                <Caption>{t('confirm.changeEmail.caption')}</Caption>
                <EmailInput
                  email={newEmail}
                  setEmail={setNewEmail}
                  errors={errors}
                  setErrors={setErrors}
                />
                <Button
                  isUpperCase
                  disabled={newEmail.length === 0}
                  onClick={handleChangeEmail}
                >
                  {t('confirm.changeEmail.button')}
                </Button>
              </>
            )}
        </Form>
      </AuthorizationLayout>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 8rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const RequestResultBlock = styled.div`
  font-size: .85em;
  color: ${greyText};
`;

const RequestSuccesBlock = styled.div`
  font-size: 1.2em;
  line-height: 1.2em;
  width: 100%;
  padding: 0.5rem 0 0.5rem 0;
`;

const Caption = styled.div`
  font-size: 1.2em;
  width: 100%;
  padding: 0 0 .5rem 0;
`;
