import React, {
  createContext,
  useState,
  useContext, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RequestService from '@/services/request.service';

import urls from '@/services/routes/balance.api';

import { usePersonalPageContext } from '../../../PersonalPageContext';

export const BalanceContext = createContext({});

export const BalanceContextProvider = ({ children }) => {
  const location = useLocation();

  const { t } = useTranslation('translations');
  const { setUserSnack } = usePersonalPageContext();

  const [paymentResult, setPaymentResult] = useState({});

  const [activeModal, setActiveModal] = useState('');
  const [paymentSystems, setPaymentSystems] = useState([]);

  const getPaymentSystems = () => {
    RequestService(urls.paymentSystems)
      .then(({ results }) => setPaymentSystems(results))
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const handleAddBalance = ({
    isAgreement, paymentSystemId, count, isTest = true,
  }) => (
    RequestService(urls.addBalance, {
      method: 'POST',
      body: {
        payment_system_id: paymentSystemId,
        amount: count,
        policy_agreement: isAgreement,
        force: isTest,
      },
    }).then((res) => {
      const useRedirectUrl = res?.payment_url || '';
      const retVal = (useRedirectUrl.length > 0) ? null : res;
      if (!retVal) {
        window.location.replace(useRedirectUrl);
      }
      return retVal;
    }).catch((err) => {
      setUserSnack({
        status: true,
        type: 'error',
        content: t(`balance.errors.${err?.data?.message_type}`, err?.data?.message_params || {}),
      });
    })
  );

  const getPaymentAmountBonus = async (id) => {
    const x = {
      amount: 0,
      bonus: {
        type: '',
        value: 0,
      },
    };
    await RequestService(urls.paymentTransaction(id)).then((res) => {
      x.amount = parseFloat(res.amount);
      x.bonus.type = res.bonus_type;
      x.bonus.value = parseFloat(res.bonus_amount);
    });
    return x;
  };

  useEffect(() => {
    if (location?.state?.paymentResult) {
      setPaymentResult({
        type: location?.state?.paymentResult,
        ptId: location?.state?.ptId,
      });
    }
  }, [location?.state]);

  const value = {
    activeModal,
    setActiveModal,
    paymentSystems,
    paymentResult,
    getPaymentSystems,
    handleAddBalance,
    getPaymentAmountBonus,
  };

  return (
    <BalanceContext.Provider value={value}>
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalanceContext = () => useContext(BalanceContext);
