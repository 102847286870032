/* eslint-disable no-shadow, no-console */
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import moment from 'moment-timezone';

import {
  getChannelsAction,
  getCountriesAction,
  getMenuAction, moveChannelAction,
  sendEmailAction, updateChannelByIdAction,
} from '@/services/actions/admin/admin.actions';
import { getAllGroupsAction } from '@/services/actions/admin/groups.actions';

import {
  getUserAction, getUsersAction,
  getUserGroupsAction,
} from '@/services/actions/admin/users-main.actions';

import {
  getWithdrawalSystemsAction,
  getPaymentSystemsAction,
  createPaymentSystemAction,
  deletePaymentSystemAction,
} from '@/services/actions/admin/finance.actions';

import {
  setMenu,
  setMenuItems,
  setPaymentSystems,
  setSnack,
  setSnackFromErrorResponse, setWithdrawalSystems,
  setCountries, setCountriesRaw, setMinistraServers, setServers,
  setOsFormats,
} from '@/store/admin/actions/common';

import {
  setUserInfo,
  setUsers,
  setUserGroups,
  updateUser,
  setPermissions,
} from '@/store/admin/actions/users';

import { arrayEdit, arrayMove } from '@/common/utils/arrayMove.util';

import { getServersAction } from '@/services/actions/admin/servers.actions';
import { getAllMinistraAction } from '@/services/actions/admin/ministra.actions';
import { getOsFormatsAction } from '@/services/actions/admin/players.actions';

import {
  getMenuItems, getPermissions,
  getPaymentSystems,
} from '@/store/admin/selectors';

import {
  NOT_FOUND_URL_ADMIN,
  SnackTypes,
  useAppContext,
} from '@/AppContext';

const URL_REDIRECT_MASK = /(.*)\/(\d+|new|create\/)(\?.*)?$/i;

const AdminContext = createContext({});

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss';

export const SORT_DIRECTIONS = {
  notSetted: '-',
  asc: 'asc',
  desc: 'desc',
};

export const COMMON_TEMPLATE_VARIABLES = [
  {
    name: 'ui_url',
    required: false,
    title: `Хост (текущий ${window.location.protocol}//${window.location.hostname})`,
  },
  {
    name: 'media_url',
    required: false,
    title: `Путь к загруженным картинкам (текущий ${process.env.REACT_APP_API_URL}/mediafiles)`,
  },
  {
    name: 'support_url',
    required: false,
    title: 'Путь к странице ТП (текущий /personal-page/support)',
  },
  {
    name: 'support_email',
    required: false,
    title: 'Адрес службы ТП',
  },
];

const MENU_ITEMS_BLOCKED = [
  '/admin/iptv/dns',
];

let isMenuLoading = false;

export const AdminContextProvider = ({ children }) => {
  const { t } = useTranslation('translations');

  const dropdownGroupsValueInitState = { title: 'Все группы', key: 'all' };

  const [groups, setGroups] = useState(null);
  const [isChannelsGroupsBusy, setIsChannelsGroupsBusy] = useState(false);
  const [dropdownGroups, setDropdownGroups] = useState([]);
  const [dropdownGroupsValue, setDropdownGroupsValue] = useState(dropdownGroupsValueInitState);
  const [channels, setChannels] = useState([]);
  const [channelsCount, setChannelsCount] = useState(0);
  const [channelsPage, setChannelsPage] = useState(1);
  const [isChannelsBusy, setIsChannelsBusy] = useState(false);
  const [countries, setLocalCountries] = useState([]);

  const dispatch = useDispatch();

  const history = useHistory();

  const { userInfo } = useAppContext();

  const storedMenu = useSelector(getMenuItems);
  const permissions = useSelector(getPermissions);
  const paymentSystems = useSelector(getPaymentSystems);

  // Users, UserGroups, Menu
  const MENU_STRUCTURE = {
    public: {
      title: 'Новости',
      links: [
        {
          title: 'Новости',
          href: 'public/news',
        },
        {
          title: 'Шаблоны оформления',
          href: 'public/email-templates',
        },
        {
          title: 'Шаблоны писем',
          href: 'public/templates',
        },
      ],
    },
    faq: {
      title: 'Частые вопросы',
      links: [
        {
          title: 'Частые вопросы',
          href: 'public/faq',
        },
      ],
    },
    statistic: {
      title: 'Статистика',
      links: [
        {
          title: 'Статистика',
          href: 'public/statistics',
        },
        {
          title: 'Платежные агрегаторы',
          href: 'statistic/payment',
        },
      ],
    },
    finance: {
      title: 'Финансы',
      links: [
        {
          title: 'Управление',
          href: 'public/finance',
        },
      ],
    },
    users: {
      title: 'Пользователи',
      links: [
        {
          title: 'Управление пользователями',
          href: 'users/main',
        },
        {
          title: 'Группы пользователей',
          href: 'users/groups',
        },
        {
          title: 'Запросы на вывод (реф.)',
          href: 'users/withdrawals/referral',
        },
      ],
    },
    iptv: {
      title: 'iptv',
      links: [
        {
          title: 'Каналы. Базовые настройки',
          href: 'iptv/base',
        },
        {
          title: 'Каналы. Настройки плееров',
          href: 'iptv/players',
        },
        {
          title: 'Каналы. Настройки геодоступа',
          href: 'iptv/geo',
        },
        {
          title: 'Группы',
          href: 'iptv/groups',
        },
        {
          title: 'Сервера',
          href: 'iptv/servers',
        },
        {
          title: 'DNS',
          href: 'iptv/dns',
        },
        {
          title: 'Управление Ministra',
          href: 'iptv/ministra',
        },
        {
          title: t('admin.iptv.promocodes.nav.title'),
          href: 'iptv/promocodes',
        },
      ],
    },
    // support: {
    //   title: 'Support',
    //   links: [
    //     {
    //       title: 'Управление пользователями',
    //       href: 'support/users',
    //     },
    //     {
    //       title: 'Пользовательские DNS',
    //       href: 'support/dns',
    //     },
    //     {
    //       title: 'Группы пользователей',
    //       href: 'users/groups',
    //     },
    //   ],
    // },
  };

  const extendPermissions = (perms = []) => {
    const extPerms = {
      '/admin/statistic/payment': [
        'payments',
        'withdrawals',
      ],
    };
    let usePerms = [...perms || []];
    Object.entries(extPerms).forEach(([k, vs]) => {
      if (usePerms.includes(k)) {
        usePerms = usePerms.concat(vs.map((v) => (`${k}/${v}/`)));
      }
    });
    usePerms = usePerms.filter((v) => !MENU_ITEMS_BLOCKED.includes(v));
    return usePerms;
  };

  const getMenuItemUrl = (url) => `/admin/${url}`;
  const getMenuFromLoadedData = (loadedMenuItems) => {
    const activeMenu = {};
    Object.entries(MENU_STRUCTURE).forEach(([categoryName, category]) => {
      const activeItems = [];
      (category?.links || []).forEach((itm) => {
        const loadedItem = loadedMenuItems.find((lItm) => lItm.code === getMenuItemUrl(itm.href));
        if (loadedItem) {
          activeItems.push({
            id: loadedItem.id,
            title: loadedItem?.name || itm.title,
            href: getMenuItemUrl(itm.href),
          });
        }
      });
      if (activeItems.length > 0) {
        activeMenu[categoryName] = { ...category, links: activeItems };
      }
    });
    return activeMenu;
  };

  const isUrlGranted = () => {
    const usePermissions = permissions || [];
    if (
      (history.location.pathname !== NOT_FOUND_URL_ADMIN)
      && !usePermissions.includes(history.location.pathname)
      && (
        (usePermissions.filter((p) => history.location.pathname.startsWith(p)).length === 0)
        || !URL_REDIRECT_MASK.test(history.location.pathname)
      )
    ) {
      history.replace(usePermissions.length > 0 ? usePermissions[0] : '/');
    }
    return true;
  };

  /*
  * content: {content: string, type: 'error' | 'warning' | 'success'}
  * */
  const setAdminSnack = (content, type = SnackTypes.success) => {
    if (type === SnackTypes.error) {
      if (content?.response) {
        const response = { ...(content.response || {}), type: 'error' };
        dispatch(setSnackFromErrorResponse({ ...content, response }));
      } else {
        dispatch(setSnack(content));
      }
    } else {
      dispatch(setSnack(content));
    }
  };

  const getFilterString = (filterObject) => {
    const affectedFilter = {};
    Object.entries(filterObject).forEach(([key, value]) => {
      if (value) { affectedFilter[key] = value; }
    });
    return new URLSearchParams(affectedFilter).toString();
  };

  const getFilterStringCustomFormat = (filterObj, dateFormat = 'yyyy-MM-dd') => {
    const retVal = [];
    Object.entries(filterObj).forEach(([key, value]) => {
      if (!value) { return; }
      if (Array.isArray(value) && (value.length > 0)) {
        retVal.push(...value.map((v) => `${key}=${encodeURIComponent(v)}`));
      } else if (
        ((typeof value === 'object') && (typeof value.getMonth === 'function'))
      ) {
        retVal.push(`${key}=${format(value, dateFormat)}`);
      } else if (
        ((typeof value === 'string') && (value.length > 0))
        || (typeof value === 'number')
      ) {
        retVal.push(`${key}=${encodeURIComponent(value)}`);
      }
    });
    return retVal.join('&');
  };

  const loadUser = (userId) => getUserAction(userId)
    .then((res) => {
      if (res?.id === userId) {
        dispatch(updateUser(res));
      }
      return res;
    })
    .catch((err) => {
      setAdminSnack(err?.payload);
      return Promise.reject(err);
    });

  const loadUsers = (filters = {}) => {
    const useFilter = {
      q: filters.search || '',
      limit: filters.limit || 16,
      offset: ((filters.page || 1) - 1) * (filters.limit || 16),
      ordering: filters.order || 'email',
    };
    return getUsersAction(useFilter)
      .then((res) => { dispatch(setUsers(res?.results || [])); return res; })
      .catch((err) => { dispatch(setSnackFromErrorResponse(err?.payload)); return err; });
  };

  const loadUserGroups = () => getUserGroupsAction()
    .then((res) => { dispatch(setUserGroups(res?.results || [])); return res; })
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err?.payload));
      return err;
    });

  const loadMenu = () => {
    if (isMenuLoading) return;
    isMenuLoading = true;
    getMenuAction()
      .then((res) => {
        // temporary for dev !!!! console log
        const modres = [
          ...res,
        ];
        dispatch(setMenu(getMenuFromLoadedData(modres)));
        dispatch(setMenuItems(res || []));
      })
      .catch((err) => {
        dispatch(setSnackFromErrorResponse(err?.payload));
        history.replace('/');
      })
      .finally(() => {
        isMenuLoading = false;
      });
  };

  // Tools
  const isUrl = (str) => new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/).test(str);

  const pageLimit = 50;

  const getOffset = (page) => (page - 1) * pageLimit;

  const getPosition = (page, index) => getOffset(page) + index + 1;

  const getChannelsPageCount = () => Math.ceil(channelsCount / pageLimit);

  // Channels Groups
  const getGroups = () => {
    setIsChannelsGroupsBusy(true);

    return getAllGroupsAction()
      .then((res) => {
        const sortedGroups = res.results.sort((a, b) => a.ordering_id - b.ordering_id);
        setGroups(sortedGroups);
        const currentGroups = [dropdownGroupsValueInitState];
        res.results.forEach((item) => {
          currentGroups.push({ title: item.name, key: item.name });
        });
        setDropdownGroups(currentGroups);

        return sortedGroups;
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
      .finally(() => setIsChannelsGroupsBusy(false));
  };

  // Channels
  const getChannels = (useFilter) => {
    const groupId = dropdownGroupsValue.key === 'all' ? null : groups.find((group) => group.name === dropdownGroupsValue.title).id;
    const usePage = useFilter?.page || channelsPage || 1;
    const useLimit = useFilter?.limit || pageLimit;
    const useGroupId = useFilter?.channelGroup || groupId;

    setIsChannelsBusy(true);

    return getChannelsAction(getOffset(usePage), useLimit, useGroupId)
      .then((res) => {
        const sortedChannels = (res?.results || []).sort((a, b) => a.ordering_id - b.ordering_id);
        setChannels(sortedChannels);
        setChannelsCount(res.count);
        return { ...res, results: sortedChannels };
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
      .finally(() => setIsChannelsBusy(false));
  };

  const handleDragEndChannels = (oldIndex, newIndex) => {
    const updatedChannels = arrayMove(channels, oldIndex, newIndex);
    setChannels(updatedChannels);

    const { id } = updatedChannels[newIndex];
    let prevChannelId = null;
    if (newIndex > 0) {
      prevChannelId = updatedChannels[newIndex - 1].id;
    }

    moveChannelAction(id, prevChannelId)
      .then()
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const updateChannelById = (id, data) => {
    updateChannelByIdAction(id, data)
      .then(() => {
        const channelIndex = channels.findIndex((c) => c.id === id);
        setChannels((prevState) => arrayEdit(prevState, data, channelIndex));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const moveChannelToPosition = (id, newPosition) => {
    const groupId = dropdownGroupsValue.key === 'all' ? null : groups.find((group) => group.name === dropdownGroupsValue.title).id;
    if (newPosition !== 1) {
      getChannelsAction(newPosition - 1 - 1, 1, groupId).then((res) => {
        const prevChannelId = res.results[0].id;
        moveChannelAction(id, prevChannelId).then(() => {
          getChannels();
        });
      });
    } else {
      moveChannelAction(id, null, groupId).then(() => {
        getChannels();
      });
    }
  };

  const handleSaveChannels = (index, item, position) => {
    const { id } = channels[index];
    updateChannelById(id, item);

    const oldPosition = getPosition(channelsPage, index);
    let newPosition = position;
    if (newPosition > oldPosition) {
      newPosition += 1;
    }
    if (newPosition && oldPosition !== newPosition) {
      moveChannelToPosition(id, newPosition);
    }
  };

  // Countries
  const loadCountries = () => getCountriesAction()
    .then((res) => {
      const countries = [];
      Object.keys(res).forEach((itemName) => {
        const item = res[itemName];
        countries.push({ title: item, key: itemName });
      });

      setLocalCountries(countries);
      dispatch(setCountries(countries));
      dispatch(setCountriesRaw(res));
      return countries;
    })
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err?.payload));
      return err;
    });

  // Payment, Withdrawals
  const loadPaymentSystems = (filters = {}) => getPaymentSystemsAction(getFilterString(filters))
    .then((res) => { dispatch(setPaymentSystems(res?.results || [])); return res; })
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err?.payload));
      return err;
    });

  const addPaymentSystem = (psData) => createPaymentSystemAction(psData)
    .then((res) => {
      if (res.id) {
        dispatch(setPaymentSystems([...paymentSystems, res]));
      }
      return res;
    })
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err?.payload));
      return err;
    });

  const deletePaymentSystem = (paymentSystemId) => deletePaymentSystemAction(paymentSystemId)
    .then((res) => {
      dispatch(setPaymentSystems([...paymentSystems].filter((ps) => ps.id !== paymentSystemId)));
      return res;
    })
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err?.payload));
      return err;
    });

  const loadWithdrawalSystems = () => getWithdrawalSystemsAction()
    .then((res) => { dispatch(setWithdrawalSystems(res.results || [])); return res; })
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err?.payload));
      return err;
    });

  const loadServers = () => getServersAction()
    .then(({ results }) => dispatch(setServers(results || [])))
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err?.payload));
      return err;
    });

  const loadMinistraServers = () => getAllMinistraAction()
    .then(({ results }) => dispatch(setMinistraServers(results || [])))
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err?.payload));
      return err;
    });

  const loadOsFormats = () => getOsFormatsAction()
    .then(({ results }) => dispatch(setOsFormats(results || [])))
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err?.payload));
      return err;
    });

  const formatCurrencySummValue = (val) => (val || 0)
    .toFixed(2)
    .toString()
    .replace(/\d(?=(\d{3})+\.)/g, '$& ')
    .replace(/^(.+)\.00$/g, '$1');

  const formatUserName = (user) => {
    const name = `${user?.first_name || ''} ${user?.last_name || ''}`.trim();
    const emailName = (user?.email || '')?.split('@')?.[0] || `Пользователь id[${user?.id || 0}}]`;
    return name.length > 0 ? name : emailName;
  };

  const formatDate = (dateString) => {
    const D = new Date(dateString);
    const day = (D.getDate()).toString().padStart(2, '0');
    const month = (D.getMonth() + 1).toString().padStart(2, '0');
    return `${day}.${month}.${D.getFullYear()}\n${(D.getHours()).toString().padStart(2, '0')}:${(D.getMinutes()).toString().padStart(2, '0')}:${(D.getSeconds()).toString().padStart(2, '0')}`;
  };

  const formatDateWithZone = (D) => moment.parseZone(D).format('DD.MM.YYYY HH:mm:ss');

  const formatUserPageLink = (user) => `/admin/users/main/${user?.id || 0}/`;

  const fixUnicodeCurrencySymbol = (str, currencySymbol) => (str || '')
    .replace(/0x(\d{4})/gi, (m) => String.fromCharCode(parseInt(m, 16)))
    .replace(`${currencySymbol} `, `${currencySymbol}\u00A0`);

  const getDateFromDateFilter = (fd) => {
    let retDate = null;
    if ((fd?.length || 0) > 0) {
      const fdArr = fd.split('-');
      if (fdArr.length === 3) {
        retDate = new Date();
        retDate.setFullYear(parseInt(fdArr[0], 10));
        retDate.setMonth(parseInt(fdArr[1], 10));
        retDate.setDate(parseInt(fdArr[2], 10));
        retDate.setHours(0);
        retDate.setMinutes(0);
        retDate.setSeconds(0);
        retDate.setMilliseconds(0);
      }
    }
    return retDate;
  };

  const sendEmail = (userId, data) => sendEmailAction(userId, data);

  const value = {
    isUrl,
    isUrlGranted,
    SnackTypes,
    setAdminSnack,
    sendEmail,
    loadMenu,
    loadUser,
    loadUsers,
    loadUserGroups,
    loadPaymentSystems,
    loadWithdrawalSystems,
    loadServers,
    loadMinistraServers,
    loadOsFormats,
    addPaymentSystem,
    deletePaymentSystem,
    groups,
    setGroups,
    isChannelsGroupsBusy,
    dropdownGroups,
    setDropdownGroups,
    dropdownGroupsValue,
    setDropdownGroupsValue,
    dropdownGroupsValueInitState,
    channels,
    channelsPage,
    setChannelsPage,
    getChannelsPageCount,
    setChannels,
    channelsCount,
    isChannelsBusy,
    setIsChannelsBusy,
    handleDragEndChannels,
    handleSaveChannels,
    countries,
    getGroups,
    getChannels,
    loadCountries,
    getOffset,
    getPosition,
    pageLimit,
    formatCurrencySummValue,
    formatUserName,
    formatDate,
    formatDateWithZone,
    formatUserPageLink,
    fixUnicodeCurrencySymbol,
    getDateFromDateFilter,
    getFilterString,
    getFilterStringCustomFormat,
  };

  useEffect(() => {
    if (userInfo?.id) {
      dispatch(setUserInfo(userInfo));
      console.log('userInfo.admin_menu_items', userInfo.admin_menu_items);
      dispatch(setPermissions(extendPermissions(userInfo.admin_menu_items)));

      if ((userInfo?.admin_menu_items || []).length === 0) {
        history.push('/');
      } else if (!storedMenu) {
        loadMenu();
      }
    }
  }, [userInfo?.id]);

  useEffect(() => {
    if (userInfo?.id && (permissions || []).length > 0) {
      isUrlGranted();
    }
  }, [userInfo?.id, history?.location?.pathname, permissions]);

  return (
    <AdminContext.Provider value={value}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => useContext(AdminContext);
