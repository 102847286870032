import React from 'react';
import styled from 'styled-components';
import { secondary, text01 } from '@/common/constants/theme';

const NotFoundPage = () => (
  <>
    <Title>
      <TitleText>Не достаточно</TitleText>
      <TitleTextOrange>прав</TitleTextOrange>
    </Title>
  </>
);

export default NotFoundPage;

const Title = styled.div`
  margin: 10rem auto;
  text-align: center;
`;

const TitleText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;
  margin-right: 1rem;
`;

const TitleTextOrange = styled(TitleText)`
  color: ${secondary}
`;
