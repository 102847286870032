import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import {
  borderSecondary,
  primary,
  secondary,
  text01,
} from '@/common/constants/theme';

import Dropdown from '@/common/components/Dropdown';
import ArrowIcon from '@/assets/icons/double-arrow.svg';

import { usePlayerContext } from '../PlayerContext';

const EMPTY_OPTION = { title: '-', key: 0 };
const MAX_CHANNEL_GROUPS_DISPLAY = 3;

const Filters = () => {
  const [devicesOptions, setDevicesOptions] = useState([]);
  const [defaultPlaylist, setDefaultPlaylist] = useState(null);
  const [playlistOptions, setPlaylistOptions] = useState([]);

  const [channelsGroupsVisiblePart, setChannelsGroupsVisiblePart] = useState([]);
  const [channelsGroupsHiddenPart, setChannelsGroupsHiddenPart] = useState([]);

  const [isHiddenPartShowed, setIsHiddenPartShowed] = useState(false);

  const [isSelectorsDisabled, setIsSelectorsDisabled] = useState(true);

  const {
    localDevices,
    selectedDevice,
    setSelectedDevice,
    selectedPlaylist,
    setSelectedPlaylist,
    selectedChannelsGroup,
    setSelectedChannelsGroup,
    channelsPlaylists,
    channelsGroups,
    isChannelsGroupsBusy,
  } = usePlayerContext();

  const optionFromDevice = ({ name, id }) => ({ title: name, key: id });
  const optionFromPlaylist = ({ title, id }) => ({ title, key: id });
  const optionFromChannelsGroup = ({ name, id }) => ({ title: name, key: id });

  const deviceFromOption = ({ key }) => (localDevices || []).find((d) => d.id === key) || null;
  const playlistFromOption = ({ key }) => (channelsPlaylists || [])
    .find((p) => p.id === key) || null;
  const channelGroupFromOption = ({ key }) => (channelsGroups || [])
    .find((cg) => cg.id === key) || null;

  const getDevicesOptions = (data) => (data || []).map(optionFromDevice);
  const getPlaylistsOptions = (data) => (data || []).map(optionFromPlaylist);

  const handleSelectDevice = (v) => {
    if (selectedDevice.id === v.key) {
      return;
    }
    const usedDevice = deviceFromOption(v);
    setSelectedDevice(usedDevice);
    if ((channelsPlaylists || []).length > 0) {
      setSelectedPlaylist(
        channelsPlaylists.find((p) => p.id === usedDevice.playlist) || defaultPlaylist,
      );
    }
  };

  const handleSelectPlaylist = (v) => {
    if (selectedPlaylist.id === v.key) {
      return;
    }
    setSelectedPlaylist(playlistFromOption(v));
  };

  useEffect(() => {
    if ((playlistOptions.length === 0) || ((localDevices || []).length === 0)) {
      return;
    }
    setDevicesOptions(getDevicesOptions(localDevices || []));
    const useDevice = (localDevices?.[0]) || null;
    setSelectedDevice(useDevice);
    if (useDevice && defaultPlaylist) {
      const usePL = channelsPlaylists
        .find((p) => p.id === useDevice.playlist) || defaultPlaylist;
      setSelectedPlaylist(usePL);
    }
  }, [playlistOptions, localDevices]);

  useEffect(() => {
    if (((channelsPlaylists || []).length === 0) || !channelsPlaylists) {
      setIsSelectorsDisabled(true);
      return;
    }
    setPlaylistOptions(getPlaylistsOptions(channelsPlaylists));
    setDefaultPlaylist(channelsPlaylists.find((pl) => pl.is_default) || channelsPlaylists[0]);
    setIsSelectorsDisabled(false);
  }, [channelsPlaylists]);

  useEffect(() => {
    if (!channelsGroups) {
      return;
    }
    const maxItemsCount = ((channelsGroups || []).length || 0) >= MAX_CHANNEL_GROUPS_DISPLAY
      ? MAX_CHANNEL_GROUPS_DISPLAY
      : channelsGroups.length;
    const channelsGroupsItemsFirstPart = [
      ...(channelsGroups || []).slice(0, maxItemsCount)
        .map(optionFromChannelsGroup),
    ];
    setChannelsGroupsVisiblePart(channelsGroupsItemsFirstPart);

    if (maxItemsCount < (channelsGroups || []).length) {
      const channelsGroupsItemsHiddenPart = (channelsGroups || [])
        .slice(maxItemsCount)
        .map(optionFromChannelsGroup);
      setChannelsGroupsHiddenPart(channelsGroupsItemsHiddenPart);
    } else {
      setChannelsGroupsHiddenPart([]);
    }
  }, [channelsGroups]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (!isHiddenPartShowed) {
          return;
        }
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          setIsHiddenPartShowed(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, isHiddenPartShowed]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const renderChannelsGroupsOptions = (cgArray, hiddenItems = null) => {
    if ((cgArray || []).length === 0) { return null; }
    const hiddenItemsIds = (hiddenItems || []).map((hi) => hi?.key || -1);
    return (cgArray || [])
      .filter((cg) => !hiddenItemsIds.includes(cg.key))
      .map((cg) => (
        <FilterTitle
          key={`${cg?.key}--PFCG-item`}
          isActive={cg?.key === (
            selectedChannelsGroup ? selectedChannelsGroup?.id : 0
          )}
          isDisabled={isChannelsGroupsBusy}
          onClick={() => {
            setSelectedChannelsGroup(channelGroupFromOption(cg));
            setIsHiddenPartShowed(false);
          }}
        >
          {cg?.title}
        </FilterTitle>
      ));
  };

  return (
    <Container>
      <ChanelsFilter>
        {(channelsGroupsVisiblePart || []).length > 0
          && renderChannelsGroupsOptions(channelsGroupsVisiblePart || [])}
        {(channelsGroupsHiddenPart || []).length > 0 && (
          <>
            <HiddenChannelsGroupFirstItem
              onClick={() => {
                if (selectedChannelsGroup
                  && (channelsGroupsHiddenPart || [])
                    .map((cg) => cg.key).includes(selectedChannelsGroup?.id)) {
                  setIsHiddenPartShowed(true);
                }
              }}
            >
              {renderChannelsGroupsOptions([
                selectedChannelsGroup && (channelsGroupsHiddenPart || [])
                  .map((cg) => cg.key).includes(selectedChannelsGroup?.id)
                  ? optionFromChannelsGroup(selectedChannelsGroup) : channelsGroupsHiddenPart[0],
              ])}
            </HiddenChannelsGroupFirstItem>
            <Icon
              src={ArrowIcon}
              isVisible={!isHiddenPartShowed}
              isDisabled={isChannelsGroupsBusy}
              onClick={() => {
                if (isChannelsGroupsBusy) {
                  return;
                }
                setIsHiddenPartShowed(true);
              }}
            />
            <HiddenChannelsGroupsListBg
              isVisible={isHiddenPartShowed}
            />
            <HiddenChannelsGroupsList
              ref={wrapperRef}
              isVisible={isHiddenPartShowed}
            >
              {renderChannelsGroupsOptions(
                channelsGroupsHiddenPart,
                [
                  ((selectedChannelsGroup?.id || 0) > 0)
                  && channelsGroupsHiddenPart.map((cg) => cg.key)
                    .includes(selectedChannelsGroup?.id)
                    ? optionFromChannelsGroup(selectedChannelsGroup)
                    : channelsGroupsHiddenPart[0],
                ],
              )}
            </HiddenChannelsGroupsList>
          </>
        )}
      </ChanelsFilter>
      <Wrapper>
        <Dropdown
          options={devicesOptions}
          color={primary}
          isUpperCase
          isBoldText
          value={selectedDevice ? optionFromDevice(selectedDevice) : EMPTY_OPTION}
          defaultValue={devicesOptions?.[0] || EMPTY_OPTION}
          onSelect={handleSelectDevice}
          isBusy={isSelectorsDisabled}
        />
        <StyledDropdown
          options={playlistOptions}
          color={primary}
          isUpperCase
          isBoldText
          value={selectedPlaylist ? optionFromPlaylist(selectedPlaylist) : EMPTY_OPTION}
          defaultValue={playlistOptions[0] || EMPTY_OPTION}
          onSelect={handleSelectPlaylist}
          isBusy={isSelectorsDisabled}
        />
      </Wrapper>
    </Container>
  );
};

export default Filters;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
`;

const ChanelsFilter = styled.div`
  position: relative;
  display: flex;
`;

const FilterTitle = styled.div`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0 .75rem;
  color: ${({ isActive, isDisabled }) => (isActive && !isDisabled ? secondary : text01)};
  cursor: ${({ isActive, isDisabled }) => (isActive || isDisabled ? 'default' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.33 : 1)};

  transition: all 0.15s ease-out 0s;

  &:hover {
    color: ${secondary};
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

const StyledDropdown = styled(Dropdown)`
  width: 15rem;
  margin-left: .75rem;
`;

const Icon = styled.img`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  filter: saturate(${({ isDisabled }) => (isDisabled ? 0 : 1)});
`;

const HiddenChannelsGroupsList = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 0;

  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  max-height: 15rem;
  overflow-y: auto;

  background: white;
  padding: .5rem .25rem;
  border: solid 1px ${borderSecondary};

  z-index: 100;
`;

const HiddenChannelsGroupsListBg = styled.div`
  position: absolute;
  height: 50rem;
  top: 1.5rem;
  right: -40rem;
  left: 0;
  z-index: 10;

  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const HiddenChannelsGroupFirstItem = styled.div`
`;
