import React from 'react';

export default () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#exportIconClip0)">
      <path d="M9.46319 5.81125L8.93599 5.16161C8.79039 4.98231 8.53839 4.96498 8.37199 5.12176L6.79999 6.61333V0.433093C6.79999 0.194026 6.62079 0 6.39999 0H5.59999C5.37919 0 5.19999 0.194026 5.19999 0.433093V6.61333L3.62799 5.12176C3.46239 4.96498 3.21039 4.98231 3.06399 5.16161L2.53679 5.81038C2.39039 5.99055 2.40719 6.26513 2.57359 6.42277L5.73679 9.42064C5.88799 9.56357 6.11279 9.56357 6.26399 9.42064L9.42639 6.42277C9.59279 6.26513 9.60959 5.99055 9.46319 5.81125Z" fill="#5C70CF" />
      <path d="M10.4 11.2604H1.60001C1.37921 11.2604 1.20001 11.4545 1.20001 11.6935V12.5597C1.20001 12.7988 1.37921 12.9928 1.60001 12.9928H10.4C10.6208 12.9928 10.8 12.7988 10.8 12.5597V11.6935C10.8 11.4545 10.6208 11.2604 10.4 11.2604Z" fill="#5C70CF" />
    </g>
    <defs>
      <clipPath id="exportIconClip0">
        <rect width="12" height="12.9928" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
