import React, { useEffect, useRef } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  secondary,
  white,
  text01,
  greyFivefold,
  grey,
  borderSecondary,
  green,
} from '@/common/constants/theme';
import VisibilityIcon from '../../../../../../../icons/VisibilityIcon';
import VisibilityNotIcon from '../../../../../../../icons/VisibilityNotIcon';
import EditIcon from '../../../../../../../icons/EditIcon';
import CloseIcon from '../../../../../../../icons/CloseIcon';

import { useChannelsContext } from '../../../../../ChannelsContext';

const ChannelsMainGroupCard = SortableElement(({
  isActive,
  isVisible,
  children,
  onClick,
  visibleHandler,
  isNew,
  count,
  onEdit,
  onDelete,
  isDeleteable,
  isDraggable,
  isDraggedChannel,
  isEditable,
  canChange,
  onGroupClicked,
  setHoveredGroup,
  i,
}) => {
  const { t } = useTranslation(['translations']);
  const {
    channelsFilters,
    setChannelsMessage,
  } = useChannelsContext();
  const {
    isStalker,
  } = channelsFilters;

  let dragTimeout = useRef(null);

  const iconRef = useRef(null);
  const editIconRef = useRef(null);
  const closeIconRef = useRef(null);

  const checkIsClick = (event) => (
    iconRef.current && !iconRef.current.contains(event.target)
    && editIconRef.current && !editIconRef.current.contains(event.target)
  );

  useEffect(() => () => {
    if (dragTimeout) {
      clearTimeout(dragTimeout);
    }
  }, []);

  return (
    <Container
      isActive={isActive}
      isEditable={isEditable}
      onMouseDown={(event) => {
        if (isDraggable) {
          if (checkIsClick(event)) {
            onClick();
          }
        } else {
          dragTimeout = setTimeout(() => {
            setChannelsMessage(t('steps.step4.content.body.noGroupsDragAvailable'));
          }, 1000 * 0.25);
        }
      }}
      onMouseUp={() => {
        if (dragTimeout) {
          clearTimeout(dragTimeout);
        }
      }}
      onClick={onGroupClicked}
      onMouseEnter={() => {
        if (isDraggedChannel) {
          setHoveredGroup(i);
        }
      }}
    >
      <Wrapper>
        {!isStalker && (
          <SeeMore isActive={isActive} />
        )}
        <Icon
          isEditable={isEditable}
          onMouseUp={() => canChange && visibleHandler(isVisible)}
          isActive={isActive}
          ref={iconRef}
        >
          {isVisible ? <VisibilityIcon /> : <VisibilityNotIcon />}
        </Icon>
        <Text isActive={isActive} isVisible={isVisible}>
          {children}
        </Text>
        <Text leftMargin isActive={isActive} isVisible={isVisible}>
          {`(${count})`}
        </Text>
        {isNew && (
          <New isVisible={isVisible}>
            {t('steps.step4.content.body.new')}
          </New>
        )}
      </Wrapper>
      <Wrapper>
        <EditIconWrapper
          isActive={isActive}
          isEditable={isEditable}
          ref={editIconRef}
          onMouseUp={() => isEditable && onEdit()}
        >
          {!isStalker && (
            <EditIcon />
          )}
        </EditIconWrapper>
        {isDeleteable && (
          <EditIconWrapper
            isEditable
            ref={closeIconRef}
            onMouseUp={() => onDelete()}
          >
            {!isStalker && (
              <CloseIcon />
            )}
          </EditIconWrapper>
        )}
      </Wrapper>
    </Container>
  );
});

export default ChannelsMainGroupCard;

const Text = styled.div`
  ${({ leftMargin }) => leftMargin && ('margin-left: 3px;')}

  font-family: Gilroy;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  ${({ isVisible }) => !isVisible && ('opacity: 0.5;')}

  letter-spacing: 0.16px;
  color: ${({ isActive }) => (isActive ? white : text01)};
`;

const New = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 22px;
  height: 12px;
  margin-left: 1px;

  position: relative;
  bottom: 10px;

  background-color: ${green};
  border-radius: 2px;

  font-size: 8px;
  font-weight: bold;
  color: ${white};
  line-height: 10px;
  text-transform: uppercase;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 38px;
  padding-left: 14px;
  padding-right: 14px;

  position: relative;

  background: ${({ isActive }) => (isActive ? secondary : white)};
  border-radius: 0 4px 4px 0;
  cursor: ${({ isEditable }) => (isEditable ? 'grabbing' : 'pointer')};
  user-select: none;

  &::before {
    content: '';

    height: 1px;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: ${borderSecondary};
  }

  &:hover {
    ${({ isActive }) => !isActive && (`
      & ${Text} {
        color: ${secondary};
      }

      & ${New} {
        color: ${white};
      }
    `)}
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SeeMore = styled.div`
  width: 2px;
  height: 2px;
  margin-right: 12px;

  position: relative;

  background-color: ${({ isActive }) => (isActive ? white : greyFivefold)};
  border-radius: 100px;

  &::after,
  &::before {
    content: '';

    width: 2px;
    height: 2px;

    position: absolute;
    left: 0;

    background-color: ${({ isActive }) => (isActive ? white : greyFivefold)};
    border-radius: 100px;
  }

  &::after {
    top: -5px;
  }

  &::before {
    bottom: -5px;
  }
`;

const Icon = styled.div`
  position: relative;
  top: ${({ icon }) => (icon ? 1 : 2)}px;

  cursor: pointer;

  & svg {
    width: 22px !important;
  }

  & svg path {
    transition: 0.2s ease;
    ${({ isEditable }) => !isEditable && `
      opacity: 0.3;
    `}
  }

  ${({ isActive, isEditable, icon }) => (isActive ? `
    & svg path {
      ${icon ? `stroke: ${white};` : `fill: ${white};`}
    }
  ` : `
    & svg path {
      ${icon ? `stroke: ${grey};` : `fill: ${grey};`}
      opacity: ${isEditable ? '0.6' : '0.15'};
    }
  `)}
`;

const EditIconWrapper = styled.div`
  ${({ isEditable }) => (!isEditable) && ('opacity: 0.3;')}
  cursor: pointer;

  transition: 0.2s ease;

  margin-left: 10px;

  & svg path {
    transition: 0.2s ease;
  }

  ${({ isActive }) => (isActive && `
    & svg path {
      fill: ${white};
    }
  `)}
`;
