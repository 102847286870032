/* eslint-disable no-fallthrough */
import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  secondary,
  text01, text04,
} from '@/common/constants/theme';

import AttachesList from './AttachesList';

import {
  ATTACHES_MAX_COUNT,
  useSupportContext,
} from '../SupportContext';

const SHOW_TITLE = true;
const CAN_ATTACH_FILE = true;

const CreateMessage = ({ isDisabled }) => {
  const { t } = useTranslation('translations');

  const {
    selectedMessage,
    setSelectedMessage,
  } = useSupportContext();

  const handleAttachFile = () => {
    const El = document.createElement('input');
    El.type = 'file';
    El.onchange = (event) => {
      setSelectedMessage({
        ...selectedMessage,
        attaches: [...(selectedMessage?.attaches || []), event.target.files[0]],
      });
      El.remove();
    };
    El.click();
  };

  const handleRemoveAttach = (attacheIndex) => {
    if ((selectedMessage?.attaches || []).length >= attacheIndex) {
      setSelectedMessage({
        ...selectedMessage,
        attaches: [
          ...selectedMessage.attaches
            .filter((a, index) => (index !== attacheIndex)),
        ],
      });
    }
  };

  return (
    <Container>
      {SHOW_TITLE && <MessageTitle>{t('personalPage.support.message.title')}</MessageTitle>}
      <StyledTextArea
        disabled={isDisabled}
        value={selectedMessage.text}
        onChange={({ target }) => setSelectedMessage({ ...selectedMessage, text: target.value })}
        placeholder={t('personalPage.support.message.textPlaceholder')}
      />
      {!isDisabled && (
        <AttachesBlock>
          <AttachesTitle>{t('personalPage.support.message.attachesTitle')}</AttachesTitle>
          <AttachesList
            attaches={selectedMessage?.attaches || []}
            handleAttachFile={handleAttachFile}
            handleRemoveAttach={handleRemoveAttach}
            maxAttachesCount={ATTACHES_MAX_COUNT}
            canEditAttaches={CAN_ATTACH_FILE && !isDisabled}
          />
          {(ATTACHES_MAX_COUNT > (selectedMessage?.attaches || []).length) && (
            <AttachesComment>
              {t('personalPage.support.message.attachesLimit',
                { count: ATTACHES_MAX_COUNT - (selectedMessage?.attaches || []).length })}
            </AttachesComment>
          )}
        </AttachesBlock>
      )}
    </Container>
  );
};

export default CreateMessage;

const Container = styled.div`
`;

const MessageTitle = styled.div`
  color: ${text01};
`;

const AttachesBlock = styled.div`
`;

const AttachesTitle = styled.div`
`;

const AttachesComment = styled.div`
  margin-top: .1rem;
  font-size: .9rem;
  color: ${text04};
`;

const StyledTextArea = styled(Input.TextArea)`
  color: ${text01};
  width: 100%;
  margin-bottom: 1.8rem;

  && {
    height: 4rem;
  }
  &.ant-input:focus {
    border-color: ${secondary};
    box-shadow: none;
  }
  &.ant-input:hover {
    border-color: ${secondary};
    box-shadow: none;
  }
`;
