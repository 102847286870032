import React, {
  useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { greyBorder } from '@/common/constants/theme';

import SnackBarWindow from '@/common/components/SnackBarWindow';
import ServerHeader from './components/ServerHeader';
import ServerMain from './components/ServerMain';
import StepsIndicator from '../../StepsIndicator';
import ServerSelectControls from './components/ServerSelectControls';
import ServerCreateLoginModal from './components/ServerCreateLoginModal';

import { SHOW_SERVER_TABLE, usePersonalPageContext } from '../../../PersonalPageContext';
import { useServerContext } from './ServerContext';

const PAGE_ID = 'serverSelect';

const Server = () => {
  const { t } = useTranslation('translations');
  const {
    selectedDevices,
    setDisabledStepperIdx,
    isPageHeaderDisabled,
  } = usePersonalPageContext();

  const {
    selectedDeviceTypeValue,
    setSelectedDeviceTypeValue,
    changeDeviceType,
    selectedServer,
    bindDeviceToServer,
    message,
    clearMessage,
    isPortalSettingsInvalid,
    isDeviceTypeBusy,
    isAutoServer,
    testingServer,
    isGeneralTestError,
  } = useServerContext();

  const history = useHistory();

  const [isLoginModalOpened, setIsLoginModalOpened] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const isPortalCredentialsSetted = (device) => (
    (device?.ministra_server
      && device?.ministra_login
      && device?.ministra_password
      && !isPortalSettingsInvalid)
    || false
  );

  const forwardHandler = () => {
    history.push({
      pathname: (selectedDeviceTypeValue === 'playlist')
        ? '/personal-page/channels'
        : 'personal-page/playlist',
      state: { scrollToStepsNav: true },
    });
  };

  useEffect(() => {
    const usedDeviceTypes = [];
    (selectedDevices || []).forEach((d) => {
      if (!usedDeviceTypes.includes(d.view_type)) {
        usedDeviceTypes.push(d.view_type);
      }
    });
    setDisabledStepperIdx(usedDeviceTypes.includes('portal') ? 3 : null);
    if (!isLoaded && (selectedDevices.length > 0)) {
      if (usedDeviceTypes.length === 1) {
        setSelectedDeviceTypeValue(usedDeviceTypes[0]);
      }
      setIsLoaded(true);
    }
  }, [selectedDevices]);

  useEffect(() => {
    const firstSelectedDevice = (selectedDevices || [])?.[0] || {};
    if (selectedDeviceTypeValue === 'portal' && !isPortalCredentialsSetted(firstSelectedDevice)) {
      setSelectedDeviceTypeValue('playlist');
      setIsLoginModalOpened(true);
    } else {
      (selectedDevices || [])
        .forEach((d) => changeDeviceType(d, selectedDeviceTypeValue, false, true));
    }

    setDisabledStepperIdx(selectedDeviceTypeValue === 'playlist' ? null : 3);
  }, [selectedDeviceTypeValue, isPortalSettingsInvalid]);

  useEffect(() => {
    if ((selectedDeviceTypeValue === 'portal')
      && isPortalSettingsInvalid
      && !isDeviceTypeBusy) {
      setIsLoginModalOpened(true);
    }
  }, [isPortalSettingsInvalid]);

  const renderDeviceServerText = () => {
    const unServeredDevices = selectedDevices.filter((d) => !d.server).map((d) => d.name).join(', ');
    return (
      <ServerSelectedInfoText isHidden={testingServer}>
        { /* eslint-disable-next-line no-nested-ternary */ }
        {isGeneralTestError
          ? t('steps.step3.content.serverSelect.testError')
          : ((unServeredDevices.length > 0)
            ? t('steps.step3.content.serverSelect.serverNotSet',
              { names: unServeredDevices })
            : t(`steps.step3.content.serverSelect.${isAutoServer ? 'useCDN' : 'serverSet'}`))}
      </ServerSelectedInfoText>
    );
  };

  return (
    <Container>
      <SnackBarWindow
        type={message.type}
        content={message.text}
        isOpen={message.text !== ''}
        onClose={clearMessage}
      />
      <StepsIndicator
        isDisabled={isDeviceTypeBusy || !testingServer}
        disabledStepIdx={selectedDeviceTypeValue === 'portal' ? 3 : null}
      />
      {!process.env.REACT_APP_DEV && (SHOW_SERVER_TABLE || !isPageHeaderDisabled?.[PAGE_ID]) && (
        <ServerHeader
          headerPageId={PAGE_ID}
          isStalker={selectedDeviceTypeValue === 'portal'}
          showControlButtons={SHOW_SERVER_TABLE}
        />
      )}
      {SHOW_SERVER_TABLE ? (
        <ServerMain
          selectedValue={selectedServer?.dns || ''}
          selectedType={selectedDeviceTypeValue}
          bindDeviceToServer={bindDeviceToServer}
          forwardHandler={forwardHandler}
          isStalker={selectedDeviceTypeValue === 'portal'}
          isLogged={isPortalCredentialsSetted}
          setIsLoginModalOpened={setIsLoginModalOpened}
        />
      ) : (
        <>
          <ServerSelectControlsContainer>
            <ServerSelectControls
              isStalker={selectedDeviceTypeValue === 'portal'}
              justifyContent="center"
            />
          </ServerSelectControlsContainer>
          {((selectedDevices || []).length > 0) && renderDeviceServerText()}
        </>
      )}
      <ServerCreateLoginModal
        device={(selectedDevices || []).length > 0 ? selectedDevices[0] : {}}
        isOpen={isLoginModalOpened}
        onClose={() => {
          setIsLoginModalOpened(false);
        }}
        onSubmit={(form) => {
          const device = {
            ...((selectedDevices || []).length > 0 ? selectedDevices[0] : {}),
            ...form,
          };
          changeDeviceType(device, 'portal', false);
          setIsLoginModalOpened(false);
        }}
      />
    </Container>
  );
};

export default Server;

const Container = styled.div``;

const ServerSelectControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-top: 1rem;

  &::before {
    content: '';
    display: block;
    margin-top: .5rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 1px;
    background: ${greyBorder};
  }
`;

const ServerSelectedInfoText = styled.div`
  margin-top: 2rem;
  min-height: 4rem;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  justify-content: center;
  font-family: Gilroy, sans-serif;
  font-size: 1.2rem;
`;
