import React from 'react';
import { primary } from '@/common/constants/theme';
import { ORDERING_DIRECTIONS } from '@/common/utils/publicConstants.util';

export default ({
  direction = ORDERING_DIRECTIONS.none,
  size = 14,
  color = primary,
}) => {
  switch (direction) {
    case ORDERING_DIRECTIONS.up:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size * (111.29 / 252.9)} height={size} viewBox="0 0 111.29 252.9">
          <path fill={color} d="M57.78.43l-.33-.1a5.19,5.19,0,0,0-.7-.22A5.68,5.68,0,0,0,55.66,0a5.49,5.49,0,0,0-1.08.11,5.83,5.83,0,0,0-.71.22l-.32.1a6.1,6.1,0,0,0-.71.39c-.08.05-.16.08-.23.13a5.57,5.57,0,0,0-.84.7L1.67,52.84A5.66,5.66,0,0,0,.57,59.3a5.45,5.45,0,0,0,8.85,1.52L50.16,19.19v228a5.65,5.65,0,0,0,4.33,5.59,5.55,5.55,0,0,0,6.67-5.5V19.19l40.52,41.4a5.56,5.56,0,1,0,8-7.72L59.56,1.65a6.24,6.24,0,0,0-.84-.7c-.07,0-.15-.08-.23-.13A6.1,6.1,0,0,0,57.78.43Z" />
        </svg>
      );
    case ORDERING_DIRECTIONS.down:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size * (111.29 / 252.9)} height={size} viewBox="0 0 111.29 252.9">
          <path fill={color} d="M53.53,252.47c.18.08.37.12.55.18s.31.11.47.14a5.56,5.56,0,0,0,2.15,0c.17,0,.32-.1.47-.14a5.22,5.22,0,0,0,.56-.18c.18-.08.35-.19.53-.28a4.49,4.49,0,0,0,.42-.24,5.57,5.57,0,0,0,.84-.7L109.65,200a5.56,5.56,0,1,0-8-7.77L61.13,233.71V5.69A5.63,5.63,0,0,0,56.82.13a5.54,5.54,0,0,0-6.69,5.49V233.71L9.58,192.28a5.62,5.62,0,0,0-7.6-.54A5.7,5.7,0,0,0,1.61,200l50.13,51.22a5.12,5.12,0,0,0,.84.7,3.5,3.5,0,0,0,.42.24C53.17,252.28,53.34,252.39,53.53,252.47Z" />
        </svg>
      );
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size * (228.26 / 252.9)} height={size} viewBox="0 0 228.26 252.9">
          <g fill={color}>
            <path d="M53.53,252.47c.18.08.37.12.55.18s.31.11.47.14a5.56,5.56,0,0,0,2.15,0c.17,0,.32-.1.47-.14a5.22,5.22,0,0,0,.56-.18c.18-.08.35-.19.53-.28a4.49,4.49,0,0,0,.42-.24,5.57,5.57,0,0,0,.84-.7L109.65,200a5.56,5.56,0,1,0-8-7.77L61.13,233.71V5.69A5.63,5.63,0,0,0,56.82.13a5.54,5.54,0,0,0-6.69,5.49V233.71L9.58,192.28a5.62,5.62,0,0,0-7.6-.54A5.7,5.7,0,0,0,1.61,200l50.13,51.22a5.12,5.12,0,0,0,.84.7,3.5,3.5,0,0,0,.42.24C53.17,252.28,53.34,252.39,53.53,252.47Z" />
            <path d="M174.74.43l-.32-.1a4.82,4.82,0,0,0-3.58,0l-.32.1a6.23,6.23,0,0,0-.72.39c-.07.05-.15.08-.23.13a5,5,0,0,0-.83.7l-50.1,51.19a5.67,5.67,0,0,0-1.11,6.46,5.46,5.46,0,0,0,8.86,1.52l40.74-41.63v228a5.65,5.65,0,0,0,4.32,5.59,5.55,5.55,0,0,0,6.68-5.5V19.19l40.52,41.4a5.56,5.56,0,1,0,8-7.72L176.52,1.65a5.59,5.59,0,0,0-.83-.7c-.07,0-.16-.08-.23-.13A6.23,6.23,0,0,0,174.74.43Z" />
          </g>
        </svg>
      );
  }
};
