import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  background01, primary, secondary, text01, white,
} from '@/common/constants/theme';

import AddIcon from '@/assets/icons/bordered-plus.svg';
import SettingsCustomizeableIcon from '../../../icons/SettingsCustomizeableIcon';
import ReloadIcon from '../../../icons/ReloadIcon';

import ThreadsFilters from './ThreadsFilters';
import CreateThread from './CreateThread';

import {
  THREADS_TABLE_SIZES,
  useSupportContext,
} from '../SupportContext';
import { Tooltip } from 'antd';

const ThreadsTableHeader = () => {
  const location = useLocation();
  const { t } = useTranslation(['translations']);

  const { isFiltersSetted, loadThreads } = useSupportContext();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const handleCreateThread = () => {
    setIsCreateModalOpen((v) => !v);
  };

  const handleFilters = () => {
    setIsFiltersOpen((v) => !v);
  };

  const handleRefreshData = () => {
    loadThreads();
  };

  useEffect(() => {
    if (location?.state?.scrollToTop) {
      window.scroll(0, 0);
    }
    if (location?.state?.createThread) {
      setIsCreateModalOpen(true);
    }
  }, [location]);

  return (
    <>
      <CreateThread
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
      />
      <Header>
        <HeaderCell width={THREADS_TABLE_SIZES[0]}>
          {t('personalPage.support.table.title')}
          <StyledAddIcon src={AddIcon} onClick={handleCreateThread} />
        </HeaderCell>
        <HeaderCell width={THREADS_TABLE_SIZES[1]}>
          {t('personalPage.support.table.number')}
        </HeaderCell>
        <HeaderCell width={THREADS_TABLE_SIZES[2]}>
          {t('personalPage.support.table.status')}
        </HeaderCell>
        <HeaderCell width={THREADS_TABLE_SIZES[3]}>
          {t('personalPage.support.table.dateOpen')}
        </HeaderCell>
        <HeaderCell width={THREADS_TABLE_SIZES[4]} leftPadding>
          {t('personalPage.support.table.dateLastMessage')}
        </HeaderCell>
        <HeaderCell width={THREADS_TABLE_SIZES[5]} leftPadding withoutBorder>
          <Tooltip
            autoAdjustOverflow
            arrowPointAtCenter
            placement="top"
            title={t('personalPage.support.table.filter')}
            color={white}
            overlayClassName="white-tooltip"
            overlayStyle={{ color: text01, position: 'fixed' }}
          >
            <Icon
              mr=".5rem"
              onClick={handleFilters}
            >
              <SettingsCustomizeableIcon
                fill={isFiltersSetted ? secondary : primary}
              />
            </Icon>
          </Tooltip>
          <Tooltip
            autoAdjustOverflow
            arrowPointAtCenter
            placement="top"
            title={t('personalPage.support.table.reload')}
            color={white}
            overlayClassName="white-tooltip"
            overlayStyle={{ color: text01, position: 'fixed' }}
          >
            <Icon
              onClick={handleRefreshData}
              width={18}
              height={18}
              color={secondary}
            >
              <ReloadIcon />
            </Icon>
          </Tooltip>
        </HeaderCell>
      </Header>
      {isFiltersOpen && <ThreadsFilters />}
    </>
  );
};

export default ThreadsTableHeader;

const Header = styled.div`
  display: flex;
  width: 100%;
  background: ${background01};
  border: 1px solid #EFEEF3;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
  padding: 0 1rem;
  z-index: 1;
`;

const HeaderCell = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: ${text01};
  padding: 0 .652rem;
  margin: 1.3rem 0 1rem;
  border-right: 1px solid #E0DEE8;
  display: flex;
  align-items: center;
  ${({ withoutBorder }) => withoutBorder && `
    border: none;
  `}
  width: ${({ width }) => (width || '100%')};
  justify-content: ${({ justify }) => justify};
  ${({ leftPadding }) => leftPadding && 'padding-left: 1.4rem;'}
`;

const StyledAddIcon = styled.img`
  margin-left: 1rem;
  cursor: pointer;
`;

const Icon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  & svg * {
    transition: all 0.25s ease-out 0s;
  }

  ${({ width }) => (width && `
    width: ${width}px;

    & svg {
      width: ${width}px;
    }
  `)}
  ${({ height }) => (height && `
    height: ${height}px;

    & svg {
      height: ${height}px;
    }
  `)}
  ${({ mr }) => (mr && `margin-right: ${mr};`)}
  ${({ color }) => (color && `
    & svg  {
      fill: ${primary};
    }
  `)}
`;
