import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import SnackBarWindow from '@/common/components/SnackBarWindow';

import AdminIPTVContainer from './IPTV/IPTVContainer';
import NewsContainer from './News/NewsContaiter';
import FAQContainer from './FAQ/FAQContainer';
import TemplateDecorsContainer from './TemplateDecors/TemplateDecorsContainer';
import StatisticsContainer from './Statistics/StatisticsContainer';
import FinanceContainer from './Finance/FinanceContainer';
import UsersContainer from './Users/UsersContainer';
import StatisticContainer from './Statistic/StatisticContainer';
import Templates from './Templates/Templates';

import NotPermittedPage from './components/NotPermittedPage';

import { setSnack } from '@/store/admin/actions/common';

import { getSnack } from '@/store/admin/selectors';

import { useAppContext } from '@/AppContext';

const AdminMainContent = () => {
  const { userInfo } = useAppContext();

  const [isSnackWindowIsOpen, setIsSnackWindowIsOpen] = useState(false);

  const dispatch = useDispatch();

  const snack = useSelector(getSnack);

  useEffect(() => {
    setIsSnackWindowIsOpen((snack?.content || '').length > 0);
  }, [snack]);

  return (
    <Container>
      <SnackBarWindow
        content={snack?.content || ''}
        type={snack?.type || 'error'}
        isOpen={isSnackWindowIsOpen}
        setIsOpen={setIsSnackWindowIsOpen}
        onClose={() => { setIsSnackWindowIsOpen(false); dispatch(setSnack(null)); }}
      />
      {userInfo?.admin_menu_items && (
        <Switch>
          <Route path="/admin/iptv" component={AdminIPTVContainer} />
          <Route path="/admin/public/news" component={NewsContainer} />
          <Route path="/admin/public/faq" component={FAQContainer} />
          <Route path="/admin/public/email-templates" component={TemplateDecorsContainer} />
          <Route path="/admin/public/templates" component={Templates} />
          <Route path="/admin/public/statistics" component={StatisticsContainer} />
          <Route path="/admin/public/finance" component={FinanceContainer} />
          <Route path="/admin/users" component={UsersContainer} />
          <Route path="/admin/statistic" component={StatisticContainer} />
          <Route path="/admin/support/users" component={UsersContainer} />
          <Route path="/admin/403" component={NotPermittedPage} />
          <Redirect to={userInfo.admin_menu_items[0]} />
        </Switch>
      )}
    </Container>
  );
};

export default AdminMainContent;

const Container = styled.div`
  flex-grow: 1;
  width: calc(100% - 242px);
  max-width: 1342px;
  overflow: unset;
  margin-bottom: 26px;
`;
