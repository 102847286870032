import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, addDays } from 'date-fns';

import styled from 'styled-components';

import CloseIcon from '../../icons/CloseIcon';
import AutoRenewIcon from '../../icons/AutoRenewIcon';
import PremiumIcon from '../../icons/PremiumIcon';

import { switchDeviceSelected } from '@/store/user/selectors';

export default function SubscriptionDevicesTable({ isFullWidth, devices, subscriptionPeriod }) {
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();

  const canDelete = (devices || []).length > 1;

  const CELL_SIZES = isFullWidth ? [
    '24px', '160px', '60px', '60px', '32px',
  ] : [
    '16px', '160px', '40px', '40px', '32px',
  ];

  const DEVICE_NAME_MAX_LENGTH = isFullWidth ? 44 : 23;

  return (
    <Table>
      <thead>
        <tr style={{ fontSize: '.8em' }}>
          <td style={{ width: CELL_SIZES[0] }}>&nbsp;</td>
          <th style={{ width: CELL_SIZES[1] }}>{t('subscriptionsContent.name')}</th>
          <th style={{ width: CELL_SIZES[2], textAlign: 'center' }}>{t('subscriptionsContent.dateEnd')}</th>
          <th style={{ width: CELL_SIZES[3], textAlign: 'center' }}>{t('subscriptionsContent.dateNew')}</th>
          <td style={{ width: CELL_SIZES[4] }}>&nbsp;</td>
        </tr>
      </thead>
      <tbody>
        {devices.map((device) => (
          <DeviceTableRow key={device.id}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <DeviceTableCell
              onClick={() => canDelete && dispatch(switchDeviceSelected(device))}
              style={{ cursor: 'pointer' }}
              title={t('subscriptionsContent.controls.deselect', { name: ` ${t('subscriptionsContent.deviceName').toString().toLowerCase()} '${device.name}'` })}
            >
              {canDelete && <CloseIcon />}
            </DeviceTableCell>
            <DeviceTableCell title={device.name}>
              {device.name.length > DEVICE_NAME_MAX_LENGTH
                ? `${device.name.slice(0, DEVICE_NAME_MAX_LENGTH - 3)}...`
                : device.name}
            </DeviceTableCell>
            <DeviceTableCell
              title={t('subscriptionsContent.dateEnd')}
              isCentered
            >
              {device.subscription && format(new Date(device.subscription.new_date_end || device.subscription.date_end), 'dd.MM.yy')}
            </DeviceTableCell>
            <DeviceTableCell
              title={t('subscriptionsContent.dateNew')}
              isCentered
            >
              {device.subscription && format(
                addDays(
                  new Date(device.subscription.new_date_end || device.subscription.date_end),
                  subscriptionPeriod,
                ),
                'dd.MM.yy',
              )}
            </DeviceTableCell>
            <DeviceTableCell>
              <ControlsContainer>
                {device?.subscription?.auto_renew && <AutoRenewIcon width="12px" height="12px" title={t('subscriptionsContent.autoSubscriptionEnabled')} />}
                {device?.subscription?.is_premium && <PremiumIcon width="12px" height="12px" title={t('devicesContent.premiumIsSet')} />}
              </ControlsContainer>
            </DeviceTableCell>
          </DeviceTableRow>
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  text-align: left;
  width: calc(100% - 5px);
  font-family: Gilroy,sans-serif;
  font-size: 13px;
  margin: 0 0 0 5px;
  td {
    font-size: 11px;
    line-height: 20px;
  }
`;

const DeviceTableRow = styled.tr`
`;

const DeviceTableCell = styled.td`
  ${({ isCentered }) => isCentered && `
    text-align: center;
  `}
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: .25rem;
  }
`;
