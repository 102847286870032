import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { text01, secondary, white } from '@/common/constants/theme';

import AlbumIconSrc from '@/assets/icons/albumIcon.svg';
import LineLeftIconSrc from '@/assets/icons/lineLeftIcon.svg';
import LineIconSrc from '@/assets/icons/lineIcon.svg';
import LineRightIconSrc from '@/assets/icons/lineRightIcon.svg';

import Button from '@/common/components/Button';
import SmartphoneGiftIcon from '../../../../../PersonalPageScreen/components/icons/SmartphoneGiftIcon';
import PCIncludedIcon from '../../../../../PersonalPageScreen/components/icons/PCIncludedIcon';
import TabletGameIcon from '../../../../../PersonalPageScreen/components/icons/TabletGameIcon';

import { useAppContext } from '@/AppContext';

const HomeSubscriptionAdvCard = ({ isUserExist }) => {
  const { t } = useTranslation('translations');

  const { getFromPublicLink } = useAppContext();

  return (
    <Container>
      <Title>
        {t('homePage.subscription.adv.cardTitle')}
      </Title>
      <Wrapper bottomMargin={15}>
        <Wrapper flex flexCenter>
          <Img
            src={AlbumIconSrc}
            alt=""
            bottomMargin
          />
        </Wrapper>
        <Wrapper flex flexCenter bottomMargin={5}>
          <LineIcon>
            <Img
              src={LineLeftIconSrc}
              alt=""
            />
          </LineIcon>
          <LineIcon>
            <Img
              src={LineIconSrc}
              alt=""
            />
          </LineIcon>
          <LineIcon>
            <Img
              src={LineRightIconSrc}
              alt=""
            />
          </LineIcon>
        </Wrapper>
        <Wrapper flex flexSb minWidth="180px">
          <Icon>
            <SmartphoneGiftIcon />
          </Icon>
          <Icon>
            <PCIncludedIcon />
          </Icon>
          <Icon>
            <TabletGameIcon />
          </Icon>
        </Wrapper>
      </Wrapper>
      <Wrapper>
        <Wrapper>
          <Text>
            {t('homePage.subscription.adv.descr')}
          </Text>
          <Text bottomMargin>
            {t('homePage.subscription.adv.descr02')}
          </Text>
        </Wrapper>
        <Wrapper bottomMargin={20}>
          <Wrapper flex flexCenter bottomMargin={15}>
            <BoldText>
              {t('homePage.subscription.adv.boldDescr.title')}
            </BoldText>
            <BoldOrangeText>
              {t('homePage.subscription.adv.boldDescr.orangeTitle')}
            </BoldOrangeText>
            <BoldText>
              {t('homePage.subscription.adv.boldDescr.title02')}
            </BoldText>
          </Wrapper>
          <Text>
            {t('homePage.subscription.adv.playlist')}
          </Text>
        </Wrapper>
        <Wrapper flex flexCenter>
          <Link to={getFromPublicLink('/personal-page/playlist', isUserExist)}>
            <StyledButton
              isBoldText
              isUpperCase
            >
              {t('homePage.subscription.adv.btn')}
            </StyledButton>
          </Link>
        </Wrapper>
      </Wrapper>
    </Container>
  );
};

export default HomeSubscriptionAdvCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 620px;
  height: 379px;
  padding: 19px 0 21px 0;

  background: ${white};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexCenter }) => flexCenter && (`
    justify-content: center;
    align-items: center;
  `)}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  ${({ bottomMargin }) => bottomMargin && (`margin-bottom: ${bottomMargin}px;`)}
  ${({ minWidth }) => minWidth && (`min-width: ${minWidth};`)}
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 15px;')}

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  color: ${text01};
  text-align: center;
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const Title = styled(Text)`
  margin-bottom: 20px;

  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${secondary};
`;

const BoldText = styled(Text)`
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  color: #202020;
`;

const BoldOrangeText = styled(BoldText)`
  margin: 0 5px;

  color: ${secondary};
`;

const StyledButton = styled(Button)`
  width: 177px;
`;

const Img = styled.img`
  display: block;
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 5px;')}
`;

const Icon = styled.div`
  &:nth-child(2) {
    position: relative;
    left: 5px;
  }
`;

const LineIcon = styled.div`
  position: relative;
  right: 2px;

  &:not(:last-child) {
    margin-right: 38px;
  }

  &:last-child {
    bottom: 2px;
  }
`;
