import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  text05,
  text01,
  secondary,
  primary,
} from '@/common/constants/theme';

import IconComputer from '@/common/components/icons/FooterComputer';
import IconEpgList from '@/common/components/icons/FooterEpgList';

import PageContainer from '@/common/components/PageContainer';
import HomeFooterEPGAd from './components/HomeFooterEPGAd';

const RENDER = {
  signFontSize: 26,
};

const HomeFooter = () => {
  const { t } = useTranslation('translations');

  const DATA = [
    {
      icon: null,
      question: t('homePage.footer.body.faqs.faq1.question'),
      answer: t('homePage.footer.body.faqs.faq1.answer'),
    },
    {
      icon: <IconComputer />,
      question: t('homePage.footer.body.faqs.faq2.question'),
      answer: t('homePage.footer.body.faqs.faq2.answer'),
    },
    {
      icon: <IconEpgList />,
      question: t('homePage.footer.body.faqs.faq3.question'),
      answer: t('homePage.footer.body.faqs.faq3.answer'),
    },
  ];

  const renderQABlock = (data) => (
    <QABlock>
      {data.icon && (
        <QABlockIcon>{data.icon}</QABlockIcon>
      )}
      <QABlockText isIcon={!!data.icon}>
        <Question>{data.question}</Question>
        <Answer>{ReactHtmlParser(data.answer)}</Answer>
      </QABlockText>
    </QABlock>
  );
  return (
    <Container>
      <PageContainer>
        <Wrapper bottomMargin={40}>
          <Wrapper flex flexCenter>
            <Title>
              {t('homePage.footer.title')}
            </Title>
            <OrangeTitle>
              {t('homePage.footer.orangeTitle')}
            </OrangeTitle>
            <Title>
              {t('homePage.footer.title02')}
            </Title>
          </Wrapper>
          <Title>
            {t('homePage.footer.title03')}
          </Title>
        </Wrapper>
        <Wrapper flex flexSb padding>
          <TextPart>
            <CapText>{t('homePage.footer.body.title')}</CapText>
            {renderQABlock(DATA[0])}
            <br />
            <QAContainerBordered>
              {renderQABlock(DATA[1])}
              {renderQABlock(DATA[2])}
            </QAContainerBordered>
          </TextPart>
          <ImgPart>
            <HomeFooterEPGAd />
          </ImgPart>
        </Wrapper>
      </PageContainer>
    </Container>
  );
};

export default HomeFooter;

const Container = styled.div`
  display: flex;
  justify-content: center;

  padding-top: 70px;
  padding-bottom: 70px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexCenter }) => flexCenter && ('justify-content: center;')}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  ${({ bottomMargin }) => bottomMargin && (`margin-bottom: ${bottomMargin}px;`)}
  ${({ padding }) => padding && ('padding: 0 60px;')}
`;

const TextPart = styled.div`
`;

const ImgPart = styled.div`
  @media (max-width: 1360px) {
    margin-right: -70px;
    margin-top: -10px;
  }
`;

const QABlock = styled.div`
  display: flex;
  margin: 15px 0 34px;
`;
const QABlockText = styled.div`
  display: flex;
  flex-direction: column;
  ${({ isIcon }) => (isIcon && 'padding-top: 5px;')}
`;
const QABlockIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 76px;
  margin-right: 25px;
`;

const QAText = styled.div`
  display: flex;
  align-items: center;
  min-height: ${RENDER.signFontSize + 2}px;
  font-size: 15px;
  line-height: 20px;
  color: ${text05};

  &::before {
    display: flex;
    width: 15px;
    margin-right: 8px;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    font-size: ${RENDER.signFontSize}px;
    line-height: ${RENDER.signFontSize + 2}px;
    color: ${secondary};
  }
`;

const Question = styled(QAText)`
  font-weight: 700;
  &::before {
    content: '?';
  }
`;

const Answer = styled(QAText)`
  &::before {
    content: '–';
    align-self: start;
    line-height: unset;
  }
`;

const QAContainerBordered = styled.div`
  margin: 20px 0;
  padding: 27px 44px;
  border: ${primary} solid 1px;
  border-radius: 4px;
  width: 150%;
`;

const Text = styled.div`
  position: relative;

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${text05};
  line-height: 20px;
  letter-spacing: .16px;
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 34px;
  color: ${text01};
  line-height: 36px;
  text-align: center;
`;

const OrangeTitle = styled(Title)`
  margin: 0 5px;

  color: ${secondary};
`;

const CapText = styled.div`
  margin-top: 15px;
  margin-bottom: 9px;

  font-weight: 700;
  font-size: 26px;
  color: ${primary};
  line-height: 28px;
`;
