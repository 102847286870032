/* eslint no-console: 0 */
import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import {
  black,
  white,
  greyBorder,
  borderSecondary,
} from '@/common/constants/theme';
import CloseleIcon from '../../../../../icons/CloseIcon';
import PromoCodeInfo from './PromoCodeInfo';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import TablePagination from '@/common/components/Table/components/TablePagination';

import { usePromoCodesContext } from '../PromocodesContext';

const SIZES = ['30px', '50px', '100px', '100px', '100px', '100px', '60px'];

const PromoCodesTable = ({ selectedPromoCode }) => {
  const { t } = useTranslation(['translations']);

  const {
    transactionFilters,
    transactionListLength,
    transactionList,
    transactionShow,
    setTransactionShow,
    handleFiltersTransactionsChange,
  } = usePromoCodesContext();

  const tableTransactionCells = {
    id: 'id',
    discount: 'discount',
    dtActivate: 'dt_activate',
    dtCreate: 'dt_create',
    isBlocked: 'is_blocked',
    order: 'order',
    price: 'price',
  };

  const dateTypes = ['dt_activate', 'dt_create'];
  const boolTypes = ['is_blocked'];

  const processValue = (k, v) => {
    let retVal = v;
    if (dateTypes.includes(k)) {
      const D = new Date(v);
      retVal = D > 0 ? `${D.toLocaleDateString()} ${D.toLocaleTimeString()}` : '-';
    } else if (boolTypes.includes(k)) {
      retVal = t(`common.${v ? 'yesText' : 'noText'}`);
    }
    return retVal;
  };

  const getUserName = (user) => (user ? `${user.first_name} ${user.last_name}`.trim() : '');
  const getUserString = (user) => (`${getUserName(user)} (${user.email}) [${user.id}]`.trim());

  // console.log(selectedPromoCode);
  // console.log(transactionList);
  const {
    page, limit,
  } = transactionFilters;

  return (
    <>
      <Container isOpen={transactionShow}>
        <Wrapper>
          <ControlButton
            onClick={() => { setTransactionShow(false); }}
          >
            <CloseleIcon />
          </ControlButton>
          {selectedPromoCode && (
            <PromoCodeInfo selectedPromoCode={selectedPromoCode} />
          )}
        </Wrapper>
        <Table
          pagesCount={Math.ceil(transactionListLength / limit)}
          page={page}
          dataLength={transactionListLength}
          onFiltersChange={handleFiltersTransactionsChange}
          limit={limit}
          cellSizes={SIZES}
          textAlign="left"
          adaptiveSize={40}
        >
          <TableHead>
            {Object.keys(tableTransactionCells).map((item) => (
              <HeadItem key={`promo_ttable_head_${item}`}>
                {t(`admin.iptv.promocodes.transactions.table.${item}`)}
              </HeadItem>
            ))}
            <HeadItem key="promo_ttable_head_user">
              {t('admin.iptv.promocodes.transactions.table.user')}
            </HeadItem>
          </TableHead>
          <TableBody
            rowHeight={50}
            minRows={10}
            isBottomRowBorder
          >
            {transactionList.map((item) => (
              <RowContainer
                key={`promocode_transaction_${item.id}`}
              >
                {Object.values(tableTransactionCells).map((itemV) => (
                  <RowItem key={`promocode_transaction_${item.id}_v_${itemV}_${item[itemV]}`}>
                    {processValue(itemV, item[itemV])}
                  </RowItem>
                ))}
                <RowItem key={`promocode_transaction_${item.id}_user`}>
                  {getUserString(item.user)}
                </RowItem>
              </RowContainer>
            ))}
          </TableBody>
          <TablePagination />
        </Table>
      </Container>
      <Backdrop
        isOpen={transactionShow}
        onClick={() => setTransactionShow(false)}
      />
    </>
  );
};

export default PromoCodesTable;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;

const ControlButton = styled.div`
  padding: .33rem .5rem;
  cursor: pointer;
  opacity: .5;
  transition: all 0.2s ease-in 0s;

  &:hover{
    opacity: 1;
  }
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: ${({ isOpen }) => (isOpen ? 0 : '100%')};
  background: linear-gradient(90deg, rgba(0,0,0,0.25) 0%, ${black} 100%);
  opacity: ${({ isOpen }) => (isOpen ? '.1' : 0)};
  z-index: 49;
  transition: opacity 0.2s ease-in 0s;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  width: ${({ isOpen }) => (isOpen ? '75%' : 0)};
  padding: ${({ isOpen }) => (isOpen ? '.5rem 1rem' : 0)};
  background: ${white};
  z-index: 50;
  transition: all 0.15s ease-in-out 0s;
  box-shadow: -5px 2px 9px ${greyBorder};
  overflow-x: hidden;

  & .table-rows {
    border: 1px solid ${borderSecondary};
    border-radius: 4px 4px 0 0;
  }
`;

const HeadItem = styled.div``;

const RowContainer = styled.div``;

const RowItem = styled.div`
  position: relative;
`;
