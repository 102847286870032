export const LOAD_USER = 'LOAD_USER';
export const LOAD_WITHDRAWAL_WALLETS = 'LOAD_WITHDRAWAL_WALLETS';
export const LOAD_WITHDRAWAL_SYSTEMS = 'LOAD_WITHDRAWAL_SYSTEMS';
export const LOAD_PLAYLIST = 'LOAD_PLAYLIST';
export const LOAD_SERVERS = 'LOAD_SERVERS';
export const LOAD_MINISTRA = 'LOAD_MINISTRA';
export const LOAD_ALL_DEVICES = 'LOAD_ALL_DEVICES';
// export const LOAD_PLAYER_DEVICES = 'LOAD_PLAYER_DEVICES';
export const LOAD_DEVICES = 'LOAD_DEVICES';
export const LOAD_DEVICES_COUNT = 'LOAD_DEVICES_COUNT';
export const SET_RIGHT_DEVICE = 'SET_RIGHT_DEVICE';
export const SET_SNACK = 'SET_SNACK';
export const SET_PREMIUM = 'SET_PREMIUM';
export const SET_SELECTED_DEVICES = 'SET_SELECTED_DEVICES';
export const SET_WELCOME_CLOSED = 'SET_WELCOME_CLOSED';
export const SET_PAGE_HEADER_DISABLED = 'SET_PAGE_HEADER_DISABLED';
export const SET_CHANNELS_LOGO_CACHE = 'SET_CHANNELS_LOGO_CACHE';
