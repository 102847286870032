import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Collapse, Tooltip } from 'antd';
import { primary, green, grey } from '../constants/theme';
import ExpandIcon from '../../assets/icons/arrow-down-blue.svg';
import IconCopy from '../../assets/icons/copyicon.svg';

const LinkList = ({ linksObject, copyText }) => {
  const { t } = useTranslation('translations');
  return (
    <Container>
      {linksObject && (
        <Collapse expandIconPosition="start">
          {
            linksObject.map((link) => (
              <StyledPanel
                key={link.id}
                showArrow={false}
                header={(
                  <>
                    {link.title}
                    <i />
                  </>
                )}
              >
                {link.urls.map((url) => (
                  <LinkWrapper key={url}>
                    <a href={url} title={url} id={url}>{url}</a>
                    <Tooltip
                      autoAdjustOverflow
                      arrowPointAtCenter
                      placement="topRight"
                      trigger={['click']}
                      title={t('devicesInfo.copyTooltip')}
                      overlayClassName="green-tooltip"
                      color={green}
                    >
                      <Icon src={IconCopy} onClick={() => copyText(url)} />
                    </Tooltip>
                  </LinkWrapper>
                ))}
              </StyledPanel>
            ))
          }
        </Collapse>
      )}
    </Container>
  );
};

export default LinkList;

const Container = styled.div`
  width: 100%;
  .ant-collapse {
    background-color: transparent;
    border: none;
    border-bottom: 0;
    border-radius: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: .25rem 0;
    border: none;
    font-family: Gilroy, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${primary};
    align-items: center;
  }
  .ant-collapse > .ant-collapse-item {
    border: none;
    background: transparent;
  }

  .ant-collapse-content {
    overflow: hidden;
    color: inherit;
    background-color: transparent;
    border-top: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    display: flex;
    flex-direction: column;

    p,a {
        font-size: 12px;
        line-height: 12px;
        display: inline-block;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
  }
  .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header i {
      transform: rotate(180deg);
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
    content: '';
    display: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    display: none;
  }
`;

const StyledPanel = styled(Collapse.Panel)`
  border-radius: 0;
  box-shadow: none;
  border: none;
  background-color: transparent;
  position: relative;

  font-style: normal;
  font-weight: normal;
  line-height: 12px;
  color: ${grey};
  a {
      font-size: 12px;
      line-height: 12px;
      color: ${grey};
      padding: .25rem 0;
  }
  .ant-collapse-item-active {
      i {
        transform: rotate(180deg);
      }
  }
  i {
      cursor: pointer;
      position: relative;
      top: 0;
      width: 9px;
      height: 6px;
      display: inline-block;
      margin-left: 12px;
      background: url(${ExpandIcon}) center center no-repeat;
      background-size: contain;
  }
`;

const Icon = styled.img``;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;
