import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  background01, borderSecondary, text02, red,
} from '@/common/constants/theme';
import { Radio, RadioGroup } from '@/common/components/RadioButton';
import { usePersonalPageContext } from '../../../../../PersonalPageContext';
import { SHOW_VIEW_TYPE, useServerContext } from '../../ServerContext';
import Loader from '@/common/components/Loader';

const ServerCard = ({
  isStalker,
}) => {
  const { t } = useTranslation(['translations']);

  const { selectedDevices } = usePersonalPageContext();

  const {
    selectedDeviceTypeValue,
    setSelectedDeviceTypeValue,
    isDeviceTypeBusy,
  } = useServerContext();

  const RenderRadioGroup = () => {
    const radios = [];

    if (selectedDevices.length > 0) {
      radios.push({ value: 'playlist', text: t('steps.step3.deviceType.playlist') });
    }
    if (selectedDevices.length === 1) {
      radios.push({ value: 'portal', text: t('steps.step3.deviceType.portal') });
    }

    return (
      radios.length > 0 ? (
        <StyledRadioGroup
          selectedValue={selectedDeviceTypeValue}
          onClickRadioButton={
            (value) => (isDeviceTypeBusy ? null : setSelectedDeviceTypeValue(value))
          }
        >
          {radios.map((rItem) => (
            <Radio
              disabled={isDeviceTypeBusy}
              key={rItem.value}
              value={rItem.value}
              labelText={rItem.text}
            />
          ))}
        </StyledRadioGroup>
      ) : (
        <Text error bottomMargin>{t('steps.step3.noDevicesSelected')}</Text>
      )
    );
  };

  return (
    <Container isStub={!SHOW_VIEW_TYPE}>
      {isDeviceTypeBusy && <Loader />}
      {SHOW_VIEW_TYPE && (
        <>
          <Text bottomMargin>{t('steps.step3.content.header.card.title')}</Text>
          {RenderRadioGroup()}
          <Text topMargin>
            {isStalker
              ? t('steps.step3.content.header.card.stalkerDescr')
              : t('steps.step3.content.header.card.descr')}
          </Text>
        </>
      )}
    </Container>
  );
};

export default ServerCard;

const Container = styled.div`
  width: 381px;
  height: ${({ isStub }) => (!isStub ? 185 : 60)}px;
  padding: 20px 47px 35px 26px;

  position: relative;
  z-index: 2;

  ${({ isStub }) => (!isStub && `
    background: ${background01};
    border: 1px solid ${borderSecondary};
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  `)}
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 9px;')};
  ${({ topMargin }) => topMargin && ('margin-top: 2px;')};

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${({ error }) => (error ? red : text02)};
  font-weight: ${({ error }) => (error ? 'bold' : 'inherit')};
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;

  & span[color] {
    width: 20px !important;
    height: 20px !important;
  }

  & span {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }
`;
