import React, { useState, useEffect, createRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

import styled from 'styled-components';
import {
  background01, greyBorder,
  inputBorder,
  primary, secondary, text01, white,
} from '@/common/constants/theme';

import CloseIcon from '@/common/components/icons/CloseIcon';
import CalendarIcon from '../../../../../PersonalPageScreen/components/icons/CalendarIcon';

import RichTextEditor from '@/common/components/RichTextEditor/RichTextEditor';
import Checkbox from '@/common/components/Checkbox';
import DatePicker from '@/common/components/DatePicker';
import Dropdown from '@/common/components/Dropdown';
import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';
import { AdminMainPopupHeader } from '../../components/MainPopupComponents';

import TagsList from '@/common/components/TagsList';
import TagsAdd from '../../../common/TagsAdd';

import {
  SnackTypes,
  IMAGE_SIZE_MAX,
  ImageUploadErrorTypes,
  useAppContext,
} from '@/AppContext';
import { useAdminContext } from '../../../../AdminContext';
import {
  BASE_PATH,
  DATA_TYPES,
  EMPTY_LANGS_OPTION,
  useFAQContext,
} from '../FaqContext';

const DATE_FORMAT = 'dd.MM.yyyy';

const FAQ_SECTIONS = {
  question: 'question',
  answer: 'answer',
};

const CHANGE_CONTROL_FIELDS = {
  question_title: 'Вопрос',
  // question_text: 'Вопрос',
  is_published: 'Опубликован',
  order: 'Порядок показа',
  language: 'Язык',
};

const ORDER_DEFAULT = 100;

const SUN_EDITOR_EMPTY_TEXT = '<p><br></p>';
const INITIAL_DATA = {
  id: null,
  dt_create: '',
  dt_update: '',
  language: 2,
  order: ORDER_DEFAULT,
  is_published: false,
  question_title: '',
  question_text: '',
  answers: [SUN_EDITOR_EMPTY_TEXT],
  images: [],
  tags: [],
};

const FAQItem = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const { siteLanguages: languages } = useAppContext();
  const { setAdminSnack } = useAdminContext();
  const {
    dataProcessing,
    selectedFAQItem,
    clearSelectedFAQItem,
    loadFAQItem,
    addFAQItem,
    updateFAQItem,
    uploadImage,
    deleteImage,
    loadTagsList,
    addTag,
    deleteTag,
  } = useFAQContext();

  const [FAQItemObject, setFAQItemObject] = useState({
    ...INITIAL_DATA,
    answers: [''],
    images: [],
    tags: [],
  });
  const [FAQItemOriginal, setFAQItemOriginal] = useState({
    ...INITIAL_DATA,
    answers: [''],
    images: [],
    tags: [],
  });
  const [editors, setEditors] = useState([]);
  const [tmpFAQId, setTmpFAQId] = useState(null);
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const [activeSection, setActiveSection] = useState(FAQ_SECTIONS.question);
  const [activeAnswerIndex, setActiveAnswerIndex] = useState(0);
  const [isFormShown, setIsFormShown] = useState(false);
  const [langOptions, setLangOptions] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [changedTexts, setChangedTexts] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  const [tagsList, setTagsList] = useState(null);
  const [editedTagText, setEditedTagText] = useState('');

  const [canAddAnswers] = useState(Math.random() > 0);
  const [canEdit] = useState(Math.random() > 0);

  const langToOption = (lang) => ({
    key: lang?.id || EMPTY_LANGS_OPTION.key, title: lang?.name || EMPTY_LANGS_OPTION.title,
  });

  const isImagesEqual = () => {
    const currentImgIDs = FAQItemObject.images.map((i) => i.id).sort().join('|');
    const originalImgIDs = FAQItemOriginal.images.map((i) => i.id).sort().join('|');
    return currentImgIDs === originalImgIDs;
  };

  const updateImagesSaved = (imgs) => {
    setFAQItemObject((prevState) => ({ ...prevState, images: [...imgs || []] }));
    setFAQItemOriginal((prevState) => ({ ...prevState, images: [...imgs || []] }));
  };

  const sortById = (a, b) => {
    if (a.id === b.id) {
      return 0;
    }
    return a.id > b.id;
  };

  const isTagsEqual = () => {
    const currentTagsIds = FAQItemObject.tags.sort(sortById).join('|');
    const originalTagsIDs = FAQItemOriginal.tags.sort(sortById).join('|');
    return currentTagsIds === originalTagsIDs;
  };

  const checkIfFAQChanged = () => {
    const changed = [];

    const {
      answers,
      images,
      tags,
      ...data
    } = FAQItemObject;

    Object.entries(data).forEach(([field, value]) => {
      if (!Object.keys(CHANGE_CONTROL_FIELDS).includes(field)) {
        return;
      }
      if (FAQItemOriginal[field] !== value) {
        changed.push(`Поле [${CHANGE_CONTROL_FIELDS[field]}] изменено (${FAQItemOriginal[field]} -> ${value})`);
      }
    });
    if ((answers.length !== FAQItemOriginal.answers.length)
      || (answers.join() !== FAQItemOriginal.answers.join())) {
      changed.push('Ответы изменены');
    }
    if (!isImagesEqual()) {
      changed.push('Картинки изменены');
    }
    if (!isTagsEqual()) {
      changed.push('Теги изменены');
    }
    setChangedTexts(changed);

    return changed.length > 0;
  };

  const handleChange = (field) => (value) => {
    setFAQItemObject((prevNews) => ({ ...prevNews, [field]: value }));
  };

  const handleChangeAnswer = (index) => (value) => {
    const newAnswers = [...FAQItemObject.answers];
    const endTextData = value.substr(value.length - SUN_EDITOR_EMPTY_TEXT);
    newAnswers[index] = (endTextData === SUN_EDITOR_EMPTY_TEXT)
      ? value.substr(0, value.length - SUN_EDITOR_EMPTY_TEXT)
      : value;

    setFAQItemObject((prevNews) => ({ ...prevNews, answers: newAnswers }));
  };

  const handleRemoveAnswer = (index) => {
    const newAnswers = [...FAQItemObject.answers];
    newAnswers.splice(index, 1);
    setFAQItemObject({ ...FAQItemObject, answers: newAnswers });
    if (activeSection === FAQ_SECTIONS.answer) {
      if (newAnswers.length < 2) {
        setActiveSection(FAQ_SECTIONS.question);
        setActiveAnswerIndex(0);
      } else if (activeAnswerIndex >= index) {
        setActiveAnswerIndex((prevState) => prevState - 1);
      }
    }
  };

  const handleAddTag = async (text) => {
    let existTag = (tagsList || [])
      .find((tag) => tag.title.toLowerCase() === text.toLowerCase());
    if (!existTag) {
      setIsBusy(true);
      existTag = await addTag(text)
        .then((res) => {
          setTagsList((prevState) => ([...prevState, res]));
          setFAQItemObject((prevState) => ({ ...prevState, tags: [...prevState.tags, res.id] }));
        })
        .catch((err) => setAdminSnack(err))
        .finally(() => setIsBusy(false));
    } else {
      setFAQItemObject((prevState) => ({
        ...prevState,
        tags: [...prevState.tags, existTag.id],
      }));
    }
    return Promise.resolve(existTag);
  };

  const handleSelectTag = (tag) => {
    if (tag) {
      setFAQItemObject((prevState) => (
        { ...prevState, tags: [...prevState.tags, tag.id] }
      ));
    }
  };

  const handleRemoveTag = (tag) => {
    if (tag) {
      setFAQItemObject((prevState) => (
        {
          ...prevState,
          tags: [...prevState.tags.filter((tgId) => tgId !== tag.id)],
        }
      ));
    }
  };

  const handleDeleteTag = (tag) => {
    if (tag) {
      setIsBusy(true);
      deleteTag(tag.id)
        .then(() => {
          setFAQItemObject((prevState) => ({
            ...prevState,
            tags: prevState.tags
              .filter((tg) => tg.id !== tag.id),
          }));
          setFAQItemOriginal((prevState) => ({
            ...prevState,
            tags: prevState.tags
              .filter((tg) => tg.id !== tag.id),
          }));
          setTagsList((prevState) => (prevState.filter((tg) => tg.id !== tag.id)));
          setAdminSnack({ type: SnackTypes.success, content: `Тег '${tag.title} [${tag.id}] удалён'` });
        })
        .catch((err) => {
          setAdminSnack(err);
        })
        .finally(() => setIsBusy(false));
    }
  };

  const updateFAQItemOriginal = (data) => {
    if (!data?.id) {
      setAdminSnack({ type: SnackTypes.error, content: 'Неизвестная ошибка при обновлении' }, SnackTypes.error);
      setIsBusy(false);
      return;
    }
    setFAQItemOriginal({
      ...data,
      answers: [...data.answers],
      images: [...data.images],
    });
    setIsBusy(false);
  };
  const processSaveResult = (res) => {
    if (res?.id) {
      window.customData.faqId = res?.id;
      history.replace(`${BASE_PATH}${res.id}`);
    }
    setIsBusy(false);
  };

  // eslint-disable-next-line no-unused-vars
  const getUsedImages = () => {
    const getArtvIdFromSunImg = (el) => el?.dataset?.imgId || 0;

    let retVal = [];
    [...FAQItemOriginal.answers].forEach((a, index) => {
      if (editors[index].current) {
        const imagesIds = editors[index].current.getImages()
          .map((I) => getArtvIdFromSunImg(I.element))
          .filter((Id) => Id > 0);
        retVal = [...retVal, ...imagesIds];
      }
    });
    return retVal;
  };

  // eslint-disable-next-line no-unused-vars
  const deleteUploadedImages = () => {
    [...(uploadedImages || [])].forEach((img) => deleteImage(img.id));
    setUploadedImages([]);
  };

  const handleSave = () => {
    setIsBusy(true);
    const {
      dt_create: dtCreate,
      dt_update: dtUpdate,
      images,
      answers,
      ...data
    } = FAQItemObject;

    const preparedItem = {
      id: FAQItemObject.id,
      ...data,
      answers: answers.filter((a) => a.trim().length > 0),
    };

    setUploadedImages([]);
    const usedImagesIds = [];
    const usedImagesSrc = [];
    editors.forEach((E) => {
      E.current.getImages().forEach((I) => {
        if (I?.element?.dataset?.imgId) {
          usedImagesIds.push(parseInt(I.element.dataset.imgId, 10));
        }
        if ((I?.element?.src || '').length > 0) {
          const url = new URL(I.element.src);
          usedImagesSrc.push((url.pathname || '').length > 0 ? url.pathname : I.element.src);
        }
      });
    });

    // fix "revert" redactor button use
    const useImagesToDelete = imagesToDelete
      .filter((strImgId) => {
        const useId = parseInt(strImgId, 10);
        return !usedImagesIds.includes(useId);
      });

    // delete unlinked original images
    images
      .filter((I) => (!usedImagesIds.includes(I.id)
        && !usedImagesSrc.includes((new URL(I.image)).pathname)))
      .forEach((I) => deleteImage(I.id));

    preparedItem.images = [...images]
      .filter((I) => usedImagesIds.includes(I.id)
        || usedImagesSrc.includes((new URL(I.image)).pathname));

    // process deleted images
    [...(useImagesToDelete || [])].forEach((imgId) => deleteImage(imgId));
    setImagesToDelete([]);

    if (!preparedItem.id) {
      preparedItem.tmp_faq_id = tmpFAQId;
      addFAQItem(preparedItem)
        .then(processSaveResult)
        .then(() => updateImagesSaved(preparedItem.images));
    } else {
      updateFAQItem(preparedItem).then(() => {
        updateFAQItemOriginal(preparedItem);
        updateImagesSaved(preparedItem.images);
      });
    }
  };

  const handleRestore = () => {
    [...(imagesToDelete || [])].forEach((imgId) => deleteImage(imgId));
    setImagesToDelete([]);
    if (!FAQItemObject.id) {
      setFAQItemObject({
        ...INITIAL_DATA,
        answers: [...INITIAL_DATA.answers],
        images: [],
      });
      (editors || []).forEach((E) => {
        E.current.updateText('');
      });
    } else {
      setFAQItemObject({ ...FAQItemOriginal, answers: [...FAQItemOriginal.answers] });
      [...FAQItemOriginal.answers].forEach((a, index) => {
        if (editors[index].current) {
          // Костыль, но уже нет сил выяснять, чего ему с первого раза не нравится
          for (let i = 0; i < 2; i += 1) {
            editors[index].current.updateText(a);
          }
        }
      });
      if (((activeSection === FAQ_SECTIONS.answer)
        && (FAQItemOriginal.answers.length <= activeAnswerIndex))
        || (activeAnswerIndex === 0)) {
        setActiveSection(FAQ_SECTIONS.question);
      }
    }
    checkIfFAQChanged();
  };

  const InputDate = (date, field, params = {}) => (
    <CalendarWrapper>
      {params?.labelText && (
        <Label mr=".25em">{`${params.labelText}:`}</Label>
      )}
      <StyledInput
        disabled={!!params?.isDisabled}
        onClick={() => !params?.isDisabled && setIsCalendarOpened(true)}
      >
        {date ? format(date, DATE_FORMAT) : ''}
        {FAQItemObject[field] && !params?.isDisabled && (
          <Clickable onClick={(e) => {
            e.stopPropagation();
            handleChange(field)(null);
          }}
          >
            <CloseIcon />
          </Clickable>
        )}
      </StyledInput>
      <DatePicker
        isOpen={isCalendarOpened}
        noApplyButton
        value={date}
        onChange={(value) => {
          handleChange(field)(format(new Date(value), DATE_FORMAT));
          setIsCalendarOpened(false);
        }}
        onClose={() => setIsCalendarOpened(false)}
      />
      {!params?.isDisabled && (
        <Icon
          topMargin
          onClick={() => setIsCalendarOpened(true)}
        >
          <CalendarIcon />
        </Icon>
      )}
    </CalendarWrapper>
  );

  const onUploadImage = (file) => uploadImage(file, tmpFAQId).then((res) => {
    setUploadedImages((prevState) => ([...prevState, res]));
    return res;
  });

  const handleImageUploadError = (errType) => {
    const errTypesMap = {
      [ImageUploadErrorTypes.upload]: 'Неизвестная ошибка загрузки изображения на сервер',
      [ImageUploadErrorTypes.oversize]: `Размер изображения больше максимального (${IMAGE_SIZE_MAX})`,
      [ImageUploadErrorTypes.zerosize]: 'Изображение пустое',
      [ImageUploadErrorTypes.unknown]: 'Изображение пустое',
    };
    setAdminSnack(
      {
        type: SnackTypes.error,
        content: errTypesMap[errType] || errTypesMap[ImageUploadErrorTypes.unknown],
      },
      SnackTypes.error,
    );
  };

  const RTFFix = (text) => text.replace('auto%', '0%');

  const isFAQCanBeSaved = () => (
    (FAQItemObject.question_title.length > 0)
    // && (FAQItemObject.question_text.length > 0)
    && ((FAQItemObject.answers || []).join('')
      .trim().length > 0)
  );

  const getSaveButtonTitle = () => {
    const errors = [];
    if ((FAQItemObject?.question_title || '').length === 0) {
      errors.push('Не задана тема вопроса');
    }
    if ((FAQItemObject?.question_text || '').length === 0) {
      // отключено всвязи с отказом от тела вопроса
      // errors.push('Не заполнено тело вопроса');
    }
    if ((FAQItemObject?.answers || []).join('')
      .trim().length === 0) {
      errors.push('Не заполнено ни одного ответа');
    }

    return errors.join('\n');
  };

  useEffect(() => {
    clearSelectedFAQItem();
    if (id !== 'new') {
      loadFAQItem(id);
    } else {
      setTmpFAQId(uuidv4());
      window.customData = { faqId: null };
      setIsFormShown(true);
    }
  }, [id]);

  useEffect(() => {
    window.customData = { faqId: selectedFAQItem?.id || null };
    if (selectedFAQItem) {
      setFAQItemOriginal(selectedFAQItem);
      setFAQItemObject({
        ...selectedFAQItem,
        question_text: RTFFix(selectedFAQItem?.question_text || ''),
        answers: [...selectedFAQItem?.answers || ['']].map(RTFFix),
        images: [...selectedFAQItem?.images || []],
        tags: [...selectedFAQItem?.tags || []],
      });
      setIsFormShown(true);
    }
    setImagesToDelete([]);
  }, [selectedFAQItem]);

  useEffect(() => {
    if ((editors || []).length !== (FAQItemObject?.answers || ['']).length) {
      setEditors(Array((FAQItemObject?.answers || ['']).length)
        .fill()
        .map(() => createRef()));
    }
    checkIfFAQChanged();
  }, [FAQItemObject, FAQItemOriginal]);

  useEffect(() => {
    if (languages) {
      setLangOptions(languages.map(langToOption));
      if (id === 'new') {
        const useLangId = languages.find((l) => l.code === i18n.language).id || 0;
        setFAQItemObject((prevState) => ({
          ...prevState,
          language: useLangId,
        }));
        setFAQItemOriginal((prevState) => ({
          ...prevState,
          language: useLangId,
        }));
      }
    }
  }, [languages]);

  useEffect(() => {
    if (!tagsList) {
      setIsBusy(true);
      loadTagsList()
        .then((res) => setTagsList(res))
        .catch((err) => setAdminSnack(err))
        .finally(() => setIsBusy(false));
    }
  }, [tagsList]);

  return (
    <Container>
      {dataProcessing[DATA_TYPES.faqItem] && <Loader />}
      <AdminMainPopupHeader
        linkText="Вернуться к списку"
        title={`${id === 'new' ? 'Добавить' : 'Изменить'} вопрос`}
        handleClose={() => {
          clearSelectedFAQItem();
          history.push(BASE_PATH);
        }}
      />
      {isFormShown && (
        <>
          <FAQNavBar>
            <FAQNavItem
              onClick={() => setActiveSection(FAQ_SECTIONS.question)}
              isActive={activeSection === FAQ_SECTIONS.question}
            >
              Вопрос и первый ответ
            </FAQNavItem>
            {FAQItemObject.answers.slice(1).map((a, index) => (
              <FAQNavItem
                key={`FAQItem--Answer-Nav-${FAQItemObject.id + index}`}
                onClick={() => {
                  setActiveSection(FAQ_SECTIONS.answer);
                  setActiveAnswerIndex(index + 1);
                }}
                isActive={(activeSection === FAQ_SECTIONS.answer)
                  && (activeAnswerIndex === (index + 1))}
              >
                {`Ответ ${index + 2}`}
                <FAQNavItemCloseControl
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleRemoveAnswer(index + 1);
                  }}
                >
                  <CloseIcon />
                </FAQNavItemCloseControl>
              </FAQNavItem>
            ))}
            {canEdit && canAddAnswers && (
              <FAQNavItem
                onClick={() => {
                  setFAQItemObject((prevState) => ({
                    ...prevState,
                    answers: [...prevState.answers, SUN_EDITOR_EMPTY_TEXT],
                  }));
                  setActiveSection(FAQ_SECTIONS.answer);
                  setActiveAnswerIndex(FAQItemObject.answers.length);
                }}
              >
                Добавить ответ
              </FAQNavItem>
            )}
          </FAQNavBar>
          <FAQContent>
            {!canEdit && <EditDisabler />}
            <Block
              isActive={activeSection === FAQ_SECTIONS.question}
            >
              <Column>
                <Row>
                  <InputWrapper>
                    <Label>Язык:</Label>
                    <StyledDropdown
                      height="33px"
                      disabled={!canEdit}
                      value={langOptions.find((o) => o.key === FAQItemObject.language)
                        || EMPTY_LANGS_OPTION}
                      options={langOptions}
                      onSelect={(val) => setFAQItemObject(
                        (prevState) => ({ ...prevState, language: val.key }),
                      )}
                    />
                  </InputWrapper>
                  <InputWrapper
                    width="100%"
                  >
                    <Label>Вопрос:</Label>
                    <Input
                      disabled={!canEdit}
                      value={FAQItemObject.question_title}
                      onChange={({ target }) => handleChange('question_title')(target.value)}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Label>Порядок показа:</Label>
                    <Input
                      disabled={!canEdit}
                      value={FAQItemObject?.order || ORDER_DEFAULT}
                      onChange={({ target }) => {
                        const newValue = target.value.length > 0
                          ? parseInt(target.value, 10) : ORDER_DEFAULT;
                        handleChange('order')(newValue);
                      }}
                    />
                  </InputWrapper>
                </Row>
                <RichTextWrapper>
                  <Label>Содержимое первого ответа:</Label>
                  <RichTextEditor
                    ref={editors[0]}
                    text={FAQItemObject.answers[0] || ''}
                    onChange={handleChangeAnswer(0)}
                    uploadImage={onUploadImage}
                    setImagesToDelete={setImagesToDelete}
                    onImageError={handleImageUploadError}
                    useNewEditor
                  />
                </RichTextWrapper>
              </Column>
            </Block>
            {FAQItemObject.answers.slice(1).map((a, index) => (
              <Block
                key={`FAQItem--Answer-Block-${FAQItemObject.id + index}`}
                isActive={(activeSection === FAQ_SECTIONS.answer)
                  && (activeAnswerIndex === (index + 1))}
              >
                <RichTextWrapper>
                  <Label>Содержимое ответа:</Label>
                  <RichTextEditor
                    ref={editors[index + 1]}
                    text={a}
                    onChange={handleChangeAnswer(index + 1)}
                    uploadImage={onUploadImage}
                    setImagesToDelete={setImagesToDelete}
                    onImageError={handleImageUploadError}
                    useNewEditor
                  />
                </RichTextWrapper>
              </Block>
            ))}
          </FAQContent>
          {tagsList && (
            <FAQTagsBlock>
              <FAQTagsCaption>Теги:</FAQTagsCaption>
              <FAQTagsAddContainer
                title="Выбрать и добавить тег"
              >
                <TagsAdd
                  tagsList={tagsList.filter((tag) => !FAQItemObject.tags.includes(tag.id))}
                  addTag={handleAddTag}
                  deleteTag={handleDeleteTag}
                  selectTag={handleSelectTag}
                  setEditedTagText={setEditedTagText}
                />
              </FAQTagsAddContainer>
              <TagListContainer>
                <TagsList
                  tagsList={tagsList.filter((tag) => FAQItemObject.tags.includes(tag.id))}
                  removeHandler={handleRemoveTag}
                  activeTagText={editedTagText}
                />
              </TagListContainer>
            </FAQTagsBlock>
          )}
          <FAQAttributes>
            <LeftContent>
              <StyledCheckbox
                checked={FAQItemObject.is_published}
                onChange={handleChange('is_published')}
                type="secondary"
                label="Опубликован"
              />
              {FAQItemObject?.dt_create && (
                <DateContainer>
                  {InputDate(
                    moment.parseZone(FAQItemObject.dt_create).toDate(),
                    'dt_create',
                    {
                      labelText: 'Создан',
                      isDisabled: true,
                    },
                  )}
                </DateContainer>
              )}
              {FAQItemObject?.dt_update && (
                <DateContainer>
                  {InputDate(
                    moment.parseZone(FAQItemObject.dt_update).toDate(),
                    'dt_update',
                    {
                      labelText: 'Изменен',
                      isDisabled: true,
                    },
                  )}
                </DateContainer>
              )}
              <ViewsContainer>
                <ViewsLabel>
                  Просмотрен:
                </ViewsLabel>
                <ReadonlyValue>
                  {FAQItemObject?.number_of_views || 0}
                </ReadonlyValue>
              </ViewsContainer>
            </LeftContent>
          </FAQAttributes>
          <FAQActions>
            <Button
              isDisabled={(changedTexts.length === 0) || isBusy}
              isBoldText
              isUpperCase
              width="small"
              onClick={() => {
                handleRestore();
                handleRestore();
              }}
              title={(changedTexts || []).join('\n')}
            >
              Восстановить
            </Button>
            <Button
              title={getSaveButtonTitle()}
              isDisabled={(!(isFAQCanBeSaved() && (changedTexts.length > 0))) || isBusy}
              isBoldText
              isUpperCase
              width="small"
              onClick={handleSave}
            >
              Сохранить
            </Button>
          </FAQActions>
        </>
      )}
    </Container>
  );
};

export default FAQItem;

const Container = styled.div`
  font-family: Gilroy, sans-serif;
`;

const FAQNavBar = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
  padding: 0.75rem;
`;

const FAQNavItem = styled.div`
  position: relative;
  padding: 0.25rem calc(0.25rem + 16px) 0.25rem 0.5rem;
  color: ${({ isActive }) => (isActive ? secondary : primary)};
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  ${({ isActive }) => (isActive && 'font-size: 1em;')};
  ${({ isActive }) => (isActive && 'font-weight: bold;')};

  transition: all 0.25s ease-out 0s;

  &:hover {
    color: ${secondary};
  }

  &:not(:last-child) {
    border-right: 1px solid ${greyBorder};
  }
`;

const FAQNavItemCloseControl = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
`;

const FAQContent = styled.div`
  position: relative;
`;

const EditDisabler = styled.div`
`;

const Block = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  background: ${background01};
  border-radius: 2px;
  margin: 20px 0;
  padding: 20px;
`;

const FAQAttributes = styled.div`
  padding: 0 20px;
`;

const FAQTagsBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.3rem;
`;

const FAQTagsCaption = styled.div`
  margin-right: 1rem;
`;

const FAQTagsAddContainer = styled.div`
  width: 230px;
  margin-right: 1rem;
`;

const TagListContainer = styled.div`
  width: calc(100% - 290px);
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;

  & > *:not(:last-child) {
    margin-right: 1.5em;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width || 'auto')};
`;

const RichTextWrapper = styled(InputWrapper)`
  width: 100%;
  margin-top: 16px;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;
  ${({ mr }) => (mr && `margin-right: ${mr};`)}
`;

const Input = styled.input`
  flex: 1;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;
  border: none;
  outline: none;
  margin-top: 12px;
  padding: 9px 15px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;
`;

const StyledInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 102px;
  height: 24px;

  background: ${white};
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: ${text01};
`;

const StyledDropdown = styled(Dropdown)`
  margin-top: 12px;
`;

const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}

  height: 24px;
  margin-top: 16px;

  & .main-datepicker {
    left: -90px;
    bottom: -20px;
  }
`;

const Clickable = styled.span`
  cursor: pointer;
  margin-left: 8px;
`;

const Icon = styled.div`
  ${({ topMargin }) => topMargin && (`
    position: relative;
    top: 6px;
  `)};
  margin-left: 10px;
  cursor: pointer;
`;

const LeftContent = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;

  & > * {
    :not(:last-child) {
      margin-right: 3em;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ withoutMargin }) => !withoutMargin && `
    margin-top: 16px
  `}
`;

const FAQActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;

  & > * {
    margin-left: 1rem;
  }
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ViewsContainer = styled(DateContainer)`
  flex-direction: row;
  align-items: center;
  height: 24px;
  margin-top: 16px;
`;

const ViewsLabel = styled.div`
  margin-right: 0.25rem;
`;

const ReadonlyValue = styled.div`
  padding: 0 1rem;
  border: solid 1px ${greyBorder};
  text-align: center;
`;
