import React from 'react';

export default () => (
  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="2" r="2" fill="#FF9800" />
    <circle cx="16" cy="9" r="2" fill="#FF9800" />
    <circle cx="9" cy="2" r="2" fill="#FF9800" />
    <circle cx="2" cy="2" r="2" fill="#FF9800" />
    <circle cx="2" cy="9" r="2" fill="#FF9800" />
    <circle cx="9" cy="9" r="2" fill="#FF9800" />
  </svg>
);
