/* eslint-disable no-unused-vars */
import React, {
  forwardRef, useImperativeHandle,
  useState, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import {
  EditorState,
} from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import ExtendedEditor from './components/ExtendedEditor';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { IMAGE_SIZE_MAX, ImageUploadErrorTypes } from '@/AppContext';

const sunEditorOptions = {
  historyStackDelayTime: 0,
  defaultStyle: 'font-family: Gilroy; font-size: 15px',
  font: ['Gilroy', 'Arial', 'Comic Sans MS', 'Courier New', 'Impact', 'Georgia', 'tahoma', 'Trebuchet MS', 'Verdana'],
  buttonList: [
    // ['template', 'undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    // '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    [
      'table', 'link', 'image',
      // 'video', 'audio',
    ],
    /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
    ['fullScreen', 'showBlocks', 'codeView'],
    // ['preview', 'print'],
  ],
  templates: [
    {
      name: 'Template-1',
      html: '<div><h1>Template 1</h1><p>Your content</p></div>',
    },
    {
      name: 'Template-2',
      html: '<div><h1>Template 2</h1><p style="text-align: center;">Your content</p></div>',
    },
  ],
  previewTemplate: `<div style='width:auto; max-width:1080px; margin:auto;'>
    <h1>Preview Template</h1>
    {{contents}}
    <div>_Footer_</div>
</div>`,
  icons: {
    link: '<svg width="15" height="15" xmlns="http://www.w3.org/2000/svg"><path d="M13.967.95A3.226 3.226 0 0 0 11.67.002c-.87 0-1.686.337-2.297.948L7.105 3.218A3.247 3.247 0 0 0 6.24 6.24a3.225 3.225 0 0 0-3.022.865L.95 9.373a3.253 3.253 0 0 0 0 4.594 3.226 3.226 0 0 0 2.297.948c.87 0 1.686-.336 2.298-.948L7.812 11.7a3.247 3.247 0 0 0 .865-3.023 3.225 3.225 0 0 0 3.022-.865l2.268-2.267a3.252 3.252 0 0 0 0-4.595zM7.105 10.993L4.837 13.26a2.233 2.233 0 0 1-1.59.655 2.233 2.233 0 0 1-1.59-.655 2.252 2.252 0 0 1 0-3.18l2.268-2.268a2.232 2.232 0 0 1 1.59-.655c.43 0 .841.12 1.195.343L4.772 9.438a.5.5 0 1 0 .707.707l1.939-1.938c.545.868.442 2.03-.313 2.785zm6.155-6.155l-2.268 2.267a2.233 2.233 0 0 1-1.59.655c-.431 0-.841-.12-1.195-.343l1.938-1.938a.5.5 0 1 0-.707-.707L7.499 6.71a2.252 2.252 0 0 1 .313-2.785l2.267-2.268a2.233 2.233 0 0 1 1.59-.655 2.233 2.233 0 0 1 2.246 2.245c0 .603-.232 1.168-.655 1.59z" fill-rule="evenodd"/></svg>',
    image: '<svg width="15" height="14" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M14.741 0H.26C.116 0 0 .136 0 .304v13.392c0 .168.116.304.259.304H14.74c.143 0 .259-.136.259-.304V.304C15 .136 14.884 0 14.741 0zm-.258 13.391H.517V.61h13.966V13.39z"/><path d="M4.138 6.738c.794 0 1.44-.76 1.44-1.695s-.646-1.695-1.44-1.695c-.794 0-1.44.76-1.44 1.695 0 .934.646 1.695 1.44 1.695zm0-2.781c.509 0 .923.487.923 1.086 0 .598-.414 1.086-.923 1.086-.509 0-.923-.487-.923-1.086 0-.599.414-1.086.923-1.086zM1.81 12.174c.06 0 .122-.025.171-.076L6.2 7.728l2.664 3.134a.232.232 0 0 0 .366 0 .343.343 0 0 0 0-.43L7.987 8.969l2.374-3.06 2.912 3.142c.106.113.27.105.366-.02a.343.343 0 0 0-.016-.43l-3.104-3.347a.244.244 0 0 0-.186-.08.245.245 0 0 0-.18.1L7.622 8.537 6.394 7.094a.232.232 0 0 0-.354-.013l-4.4 4.56a.343.343 0 0 0-.024.43.243.243 0 0 0 .194.103z"/></g></svg>',
    bold: '<svg width="12" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M6.236 0c1.652 0 2.94.298 3.866.893.925.595 1.388 1.485 1.388 2.669 0 .601-.173 1.139-.516 1.61-.343.474-.844.83-1.499 1.068.843.167 1.474.523 1.895 1.071.419.55.63 1.183.63 1.903 0 1.245-.444 2.187-1.33 2.825-.886.641-2.144.961-3.769.961H0v-2.167h1.494V2.167H0V0h6.236zM4.308 5.446h2.024c.752 0 1.33-.143 1.734-.43.405-.285.608-.701.608-1.25 0-.6-.204-1.044-.612-1.33-.408-.286-1.016-.427-1.826-.427H4.308v3.437zm0 1.804V11h2.593c.747 0 1.314-.152 1.707-.452.39-.3.588-.745.588-1.334 0-.636-.168-1.124-.5-1.46-.336-.335-.864-.504-1.582-.504H4.308z" fill-rule="evenodd"/></svg>',
    italic: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M7 3V2h4v1H9.753l-3 10H8v1H4v-1h1.247l3-10H7z"/></svg>',
    underline: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M6.045 2v.992L4.785 3v5.172c0 .859.243 1.512.727 1.957s1.124.668 1.918.668c.836 0 1.509-.221 2.019-.664.511-.442.766-1.096.766-1.961V3l-1.26-.008V2H13v.992L11.739 3v5.172c0 1.234-.398 2.181-1.195 2.84-.797.659-1.835.988-3.114.988-1.242 0-2.248-.329-3.017-.988-.769-.659-1.152-1.605-1.152-2.84V3L2 2.992V2h4.045zM2 13h11v1H2z"/></svg>',
    list_bullets: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 381.39 381.39"><g><path d="M365.499 174.804h-254.26c-8.772 0-15.891 7.119-15.891 15.891s7.119 15.891 15.891 15.891h254.26c8.74 0 15.891-7.119 15.891-15.891s-7.151-15.891-15.891-15.891zM111.239 79.456h254.26c8.74 0 15.891-7.119 15.891-15.891s-7.151-15.891-15.891-15.891h-254.26c-8.772 0-15.891 7.119-15.891 15.891s7.119 15.891 15.891 15.891zM365.499 301.934h-254.26c-8.772 0-15.891 7.151-15.891 15.891s7.119 15.891 15.891 15.891h254.26c8.74 0 15.891-7.151 15.891-15.891s-7.151-15.891-15.891-15.891z"/><circle cx="15.891" cy="63.565" r="15.891"/><circle cx="15.891" cy="190.695" r="15.891"/><circle cx="15.891" cy="317.825" r="15.891"/></g></svg>',
    list_number: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 367.85 367.85"><g><path d="M351.959 168.018H97.699c-8.772 0-15.891 7.119-15.891 15.891S88.927 199.8 97.699 199.8h254.26c8.74 0 15.891-7.119 15.891-15.891s-7.119-15.891-15.891-15.891zM351.959 40.888H97.699c-8.772 0-15.891 7.119-15.891 15.891S88.927 72.67 97.699 72.67h254.26c8.74 0 15.891-7.119 15.891-15.891s-7.119-15.891-15.891-15.891zM351.959 295.116H97.699c-8.772 0-15.891 7.183-15.891 15.891 0 8.74 7.119 15.891 15.891 15.891h254.26c8.74 0 15.891-7.151 15.891-15.891 0-8.708-7.119-15.891-15.891-15.891zM33.499 95.967V17.592h-9.058L7.31 26.745l2.066 8.073 13.635-7.342h.222v68.491zM8.295 208.509L0 216.55v6.515h50.026v-8.74H14.811v-.254l6.261-5.784c16.527-15.923 27.015-27.619 27.015-42.08 0-11.219-7.119-22.788-23.996-22.788-9.058 0-16.749 3.369-22.184 7.977l3.369 7.469c3.623-3.019 9.535-6.643 16.654-6.643 11.696 0 15.542 7.342 15.542 15.319-.096 11.792-9.154 21.93-29.177 40.968zM21.326 341.678c-7.342 0-14.334-3.051-17.353-4.926l-2.892 8.168c3.846 2.543 11.569 5.339 20.373 5.339 18.815 0 28.572-10.997 28.572-23.519 0-10.997-7.85-18.18-17.48-20.182v-.223c9.662-3.464 14.461-10.361 14.461-18.497 0-9.503-6.992-18.688-22.438-18.688-8.454 0-16.4 3.051-20.5 6.007l2.765 7.755c3.369-2.415 9.408-5.212 15.669-5.212 9.757 0 13.762 5.594 13.762 11.855 0 9.249-9.757 13.222-17.48 13.222h-5.912v7.946h5.912c10.266 0 20.15 4.672 20.245 15.669.158 6.609-4.069 15.286-17.704 15.286z"/></g></svg>',
    align_justify: '<svg width="15" height="15" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M14.62 14.888H.325a.326.326 0 0 1 0-.652H14.62a.326.326 0 0 1 0 .652zM14.62 10.162H.325a.326.326 0 0 1 0-.652H14.62a.326.326 0 0 1 0 .652zM14.62 5.436H.325a.326.326 0 0 1 0-.652H14.62a.326.326 0 0 1 0 .652zM14.62.71H.325a.326.326 0 0 1 0-.653H14.62a.326.326 0 0 1 0 .653z"/></g></svg>',
    align_left: '<svg width="15" height="15" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M8.493 14.887H.326a.326.326 0 0 1 0-.652h8.167a.326.326 0 0 1 0 .652zM14.618 10.162H.326a.326.326 0 0 1 0-.653h14.292a.326.326 0 0 1 0 .653zM8.493 5.435H.326a.326.326 0 0 1 0-.652h8.167a.326.326 0 0 1 0 .652zM14.618.709H.326a.326.326 0 0 1 0-.652h14.292a.326.326 0 0 1 0 .652z"/></g></svg>',
    align_right: '<svg width="15" height="15" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M14.618 14.887H6.45a.326.326 0 0 1 0-.652h8.167a.326.326 0 0 1 0 .652zM14.618 10.162H.326a.326.326 0 0 1 0-.653h14.292a.326.326 0 0 1 0 .653zM14.618 5.435H6.45a.326.326 0 0 1 0-.652h8.167a.326.326 0 0 1 0 .652zM14.618.709H.326a.326.326 0 0 1 0-.652h14.292a.326.326 0 0 1 0 .652z"/></g></svg>',
    align_center: '<svg width="15" height="15" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M11.556 14.887H3.388a.326.326 0 0 1 0-.652h8.167a.326.326 0 0 1 0 .652zM14.618 10.162H.326a.326.326 0 0 1 0-.653h14.292a.326.326 0 0 1 0 .653zM11.556 5.435H3.388a.326.326 0 0 1 0-.652h8.167a.326.326 0 0 1 0 .652zM14.618.709H.326a.326.326 0 0 1 0-.652h14.292a.326.326 0 0 1 0 .652z"/></g></svg>',
    indent: '<svg width="17" height="14" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M5.716 3.211H17v1.197H5.716zM0 .02h17v1.197H0zM0 12.783h17v1.197H0zM5.716 9.593H17v1.197H5.716zM5.716 6.402H17v1.197H5.716zM.187 9.491L2.52 7 .187 4.509z"/></g></svg>',
    outdent: '<svg width="16" height="14" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M5.396 3.193h10.573V4.39H5.396zM.039.003h15.93V1.2H.039zM.039 12.766h15.93v1.197H.039zM5.396 9.575h10.573v1.197H5.396zM5.396 6.384h10.573v1.197H5.396zM2.187 4.491L0 6.983l2.187 2.491z"/></g></svg>',
    table: '<svg width="15px" height="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.611 201.611"><g><path d="M163.722,0h-6.861h-32.074h-6.875H85.839h-6.871H0v201.611h201.611V0H163.722z M156.861,6.875    v31.787h-32.074V6.875H156.861z M124.787,155.161v-30.27h32.074v30.266h-32.074V155.161z M117.912,84.543v33.477H85.839V84.543    C85.839,84.543,117.912,84.543,117.912,84.543z M85.839,77.683V45.537h32.074v32.145H85.839z M117.912,124.891v30.266H85.839    v-30.266H117.912z M124.787,118.02V84.543h32.074v33.477H124.787z M124.787,77.683V45.537h32.074v32.145H124.787z M90.416,6.875    h27.496v31.787H85.839V6.875H90.416z M47.141,6.875h31.823v31.787H47.141V6.875z M47.141,45.537h31.823v32.145H47.141V45.537z     M47.141,84.543h31.823v33.477H47.141V84.543z M47.141,124.891h31.823v30.266H47.141V124.891z M40.266,194.736H6.875v-32.704    h33.394v32.704H40.266z M40.266,155.161H6.875v-30.27h33.394v30.27H40.266z M40.266,118.02H6.875V84.543h33.394v33.477H40.266z     M40.266,77.683H6.875V45.537h33.394v32.145H40.266z M40.266,38.662H6.875V6.875h33.394v31.787H40.266z M47.141,162.032h31.823    v32.704H47.141V162.032z M194.736,194.736H85.839v-32.704h108.897C194.736,162.032,194.736,194.736,194.736,194.736z     M194.736,155.161h-31.014v-30.27h31.014C194.736,124.891,194.736,155.161,194.736,155.161z M194.736,118.02h-31.014V84.543    h31.014C194.736,84.543,194.736,118.02,194.736,118.02z M194.736,77.683h-31.014V45.537h31.014    C194.736,45.537,194.736,77.683,194.736,77.683z M194.736,38.662h-31.014V6.875h31.014    C194.736,6.875,194.736,38.662,194.736,38.662z"/></g></svg>',
  },
};

const objectToHTML = (data) => convertToHTML({
  blockToHTML: () => { },
  styleToHTML: (style) => {
    switch (style) {
      case 'BOLD':
        return <b />;
      case 'ITALIC':
        return <i />;
      default:
        return null;
    }
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'LINK') {
      return (
        <a
          href={entity.data.url}
          target={entity.data.target}
          rel="noopener noreferrer"
        >
          {originalText}
        </a>
      );
    }
    if (entity.type === 'IMAGE') {
      const { width, src } = entity.data;
      return <img width={`${width}%`} src={src} alt="" />;
    }
    return originalText;
  },
})(data);

const htmlToObject = convertFromHTML({
  htmlToBlock: (nodeName) => {
    if (nodeName === 'figure') {
      return 'atomic';
    }
    return null;
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'img') {
      return createEntity(
        'IMAGE',
        'IMMUTABLE',
        {
          src: node.src,
          width: node.width ?? 'auto',
          height: node.height ?? 'auto',
        },
      );
    }
    return null;
  },
});

const getDefaultEditorState = (html) => {
  const state = htmlToObject(html || '');
  if (!state) {
    return EditorState.createEmpty();
  }

  return EditorState.createWithContent(state);
};

const EMPTY_IMAGE_DATA = {
  url: '',
  el: null,
  imgId: 0,
  loading: false,
};

const RichTextEditor = forwardRef(({
  placeholder = '',
  text = '',
  onChange = () => {},
  uploadImage = null,
  setImagesToDelete = null,
  simpleText = false,
  useNewEditor = false,
  onImageError = null,
}, ref) => {
  const sunEditor = useRef(null);
  const [editorState, setEditorState] = useState(() => getDefaultEditorState(text));
  const [image, setImage] = useState({ ...EMPTY_IMAGE_DATA });

  // data for image delete purposes
  const sI = [];

  const updateImages = (data) => {
    setImage((prevState) => ({ ...prevState, ...data }));
  };

  const getSunImagesData = (sunImagesInfo) => {
    sI.length = 0;
    sunImagesInfo.forEach(({ index, element }) => {
      sI.push({ index, id: element?.dataset?.imgId });
    });
  };

  const sunEditorProps = {
    lang: 'ru',
    height: '320px',
    defaultValue: text,
    onLoad: (reload) => {
      if (!reload) {
        getSunImagesData(sunEditor.current.getImagesInfo());
      }
    },
    onChange: (newHtml) => {
      if (onChange) {
        onChange(newHtml);
      }
      return true;
    },
    onSave: (newHtml) => {
      getSunImagesData(sunEditor.current.getImagesInfo());
      onChange(newHtml);
      return true;
    },
    onImageUploadBefore: (files) => {
      let result = true;
      if (uploadImage) {
        const isError = (files[0].size > IMAGE_SIZE_MAX) || (files[0].size <= 0);
        if (onImageError && (IMAGE_SIZE_MAX < files[0].size)) {
          onImageError(ImageUploadErrorTypes.oversize);
        }
        if (onImageError && (files[0].size <= 0)) {
          onImageError(ImageUploadErrorTypes.zerosize);
        }
        if (isError) {
          return false;
        }
        updateImages({ loading: true });
        uploadImage(files[0])
          .then((uploadResult) => {
            result = !!uploadResult?.isError;
            updateImages({ url: uploadResult?.image || '', imgId: uploadResult?.id || 0, loading: false });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log('img upload err', err);
            onImageError(ImageUploadErrorTypes.upload);
            result = false;
          });
      }
      return result;
    },
    onImageUpload: (
      targetImgElement, index, state, imageInfo, remainingFilesCount,
    ) => {
      if (state === 'delete' && setImagesToDelete) {
        const currentIndexes = sunEditor.current.getImagesInfo().map((I) => I.index);
        const deletedItems = sI.filter((I) => !currentIndexes.includes(I.index));
        const deleteIds = deletedItems.map((I) => I.id);
        setImagesToDelete((prevState) => (
          [...(prevState || []).filter((pIId) => !deleteIds.includes(pIId)), ...deleteIds]
        ));
        return true;
      }
      if (state !== 'create') {
        return true;
      }
      updateImages({ el: targetImgElement });
      return remainingFilesCount === 0;
    },
    onImageUploadError: (errorMessage, result) => {
      // eslint-disable-next-line no-console
      console.log('sunEditorProps onImageUploadError', errorMessage, result);
      return false;
    },
  };

  useImperativeHandle(ref, () => ({
    updateText: (newText) => {
      if (useNewEditor) {
        if (sunEditor.current) {
          sunEditor.current.setContents(newText);
          getSunImagesData(sunEditor.current.getImagesInfo());
        }
      } else {
        setEditorState(getDefaultEditorState(newText));
      }
    },
    getImages: () => (
      sunEditor.current
        ? sunEditor.current.getImagesInfo() : []
    ),
  }));

  const handleEditorChange = (state) => {
    const currentContentAsHTML = objectToHTML(state.getCurrentContent());
    onChange(state.getCurrentContent().hasText() ? currentContentAsHTML : '');
    setEditorState(state);
  };

  // const logState = () => {
  //   const contentState = editorState.getCurrentContent().toJS();
  //   console.log(contentState);
  // };
  //
  // const logStateRaw = () => {
  //   const contentState = editorState.getCurrentContent();
  //   const rawContentState = convertToRaw(contentState);
  //   console.log(JSON.stringify(rawContentState));
  // };

  const getSunEditorInstance = (instance) => {
    sunEditor.current = instance;
  };

  // useEffect(() => {
  //   if (useNewEditor && sunEditor.current) {
  //     setSunImages(sunEditor.current.getFilesInfo('image'));
  //   }
  // }, [sunEditor.current, text]);

  useEffect(() => {
    if ((image.url.length > 0) && image.el && !image.loading) {
      image.el.src = image.url;
      image.el.dataset.imgId = image.imgId;
      sunEditor.current.save();
      setImage({ ...EMPTY_IMAGE_DATA });
    }
  }, [image]);

  return (
    <StyledEditor>
      {useNewEditor ? (
        <SunEditor
          getSunEditorInstance={getSunEditorInstance}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...sunEditorProps}
          setOptions={sunEditorOptions}
        />
      ) : (
        <ExtendedEditor
          placeholder={placeholder}
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          uploadImage={uploadImage}
          simpleText={simpleText}
        />
      )}
    </StyledEditor>
  );
});

export default RichTextEditor;

const StyledEditor = styled.div`
  margin-top: 12px;
`;
