import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAdminDNSContext } from './DNSContext';
import AdminDNSFilters from './components/Filters';
import AdminDNSTable from './components/Table';
import AdminDNSUsersTable from './components/UsersTable';
import AdminDNSCreateModal from './components/CreateModal';
import AdminDeleteModal from '../../../../Modals/DeleteModal';
import AdminDNSTransferDataModal from './components/TransferDataModal';

const AdminDNS = () => {
  const { domains, getDomains, createDomain } = useAdminDNSContext();

  const [searchValue, setSearchValue] = useState('');
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [deletedId, setDeletedId] = useState(-1);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isTransferModal, setIsTransferModal] = useState(false);
  const [activeRow, setActiveRow] = useState(-1);
  const [localDomains, setLocalDomains] = useState([]);

  useEffect(() => {
    getDomains();
  }, []);

  useEffect(() => {
    setLocalDomains([...domains]);
  }, [domains]);

  useEffect(() => {
    const domainsLength = domains
      .filter((domain) => domain.url.toLowerCase().includes(searchValue.toLowerCase())).length;
    if (activeRow > domainsLength - 1) {
      if (domainsLength === 0) {
        setActiveRow(-1);
        return;
      }
      setActiveRow(domainsLength - 1);
    }
  }, [searchValue]);

  function submit(value) {
    if (!value.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/)) return false;
    createDomain({ name: value, url: value });
    return true;
  }

  return (
    <Container>
      <AdminDNSCreateModal
        isOpen={isCreateModal}
        onClose={() => setIsCreateModal(false)}
        handleSubmit={(v) => submit(v)}
      />
      <AdminDeleteModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        content={deletedId >= 0 && domains[deletedId].name}
        handleDelete={() => {
          setIsDeleteModal(false);
          setIsTransferModal(true);
        }}
      />
      <AdminDNSTransferDataModal
        isOpen={isTransferModal}
        onClose={() => setIsTransferModal(false)}
        deletedId={deletedId}
        setDeletedId={setDeletedId}
        setIsTransferModal={setIsTransferModal}
      />
      <AdminDNSFilters
        searchValue={searchValue}
        searchHandler={setSearchValue}
        handleCreate={() => setIsCreateModal(true)}
      />
      <TablesWrapper>
        <AdminDNSTable
          setDeletedId={setDeletedId}
          setIsDeleteModal={setIsDeleteModal}
          activeRow={activeRow}
          setActiveRow={setActiveRow}
          search={searchValue}
          domains={localDomains}
        />
        <AdminDNSUsersTable activeRow={activeRow} search={searchValue} />
      </TablesWrapper>
    </Container>
  );
};

export default AdminDNS;

const Container = styled.div``;

const TablesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
