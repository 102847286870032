import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import {
  borderSecondary,
  primary, secondary,
  greyText, text01,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';
import Dropdown from '@/common/components/Dropdown';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import EditIcon from '@/common/components/icons/EditIcon';
import TableBody from '@/common/components/Table/components/TableBody';
import AdminTablePagination from '../../components/Table/components/TablePagination';
import AdminDeleteModal from '../../../Modals/DeleteModal';
import SearchIcon from '../../../icons/SearchIcon';
import DeleteIcon from '../../../icons/DeleteIcon';

import {
  BASE_PATH,
  DATA_TYPES,
  EMPTY_OPTION,
  useTemplateDecorsContext,
} from '../TemplateDecorsContext';

const SIZES = ['80px', '900px', '160px', '120px'];

const TemplateDecorsList = () => {
  const history = useHistory();
  const {
    dataProcessing,
    tdDataList,
    tdDataCount,
    filters,
    setFilters,
    clearSelectedTdItem,
    deleteTdItem,
  } = useTemplateDecorsContext();

  const [deletedTdItem, setDeletedTdItem] = useState(null);

  const activityList = [
    EMPTY_OPTION,
    { key: false, title: 'Отключен' },
    { key: true, title: 'Активен' },
  ];

  const getPageCount = () => {};

  const activityToOption = (isActive) => activityList
    .find((o) => o.key === isActive) || EMPTY_OPTION;

  const getActivityText = (item) => activityList.find((a) => a.key === item.is_active).title;

  const handleSearch = (val) => {
    setFilters((prevState) => ({ ...prevState, search: val }));
  };

  const handleChangePage = (val) => {
    setFilters((prevState) => ({ ...prevState, page: val }));
  };

  const handleChangeActivityFilter = (val) => {
    setFilters((prevState) => ({ ...prevState, is_active: val.key }));
  };

  return (
    <Container>
      <AdminDeleteModal
        content={`Вопрос "${deletedTdItem?.question_title}" (ID ${deletedTdItem?.id})`}
        isOpen={deletedTdItem}
        onClose={() => setDeletedTdItem(null)}
        handleDelete={() => deleteTdItem(deletedTdItem?.id)}
      />
      {dataProcessing?.[DATA_TYPES.tdList] && <Loader />}
      <Actions>
        <Button
          color={primary}
          onClick={() => {
            clearSelectedTdItem();
            history.push(`${BASE_PATH}new`);
          }}
        >
          Добавить шаблон оформления
        </Button>
      </Actions>
      <Table
        dataLength={tdDataCount}
        limit={10}
        page={filters?.page || 1}
        cellSizes={SIZES}
        textAlign="left"
      >
        <TableHead>
          <HeadItem>
            ID
          </HeadItem>
          <HeadItem flex>
            Название
            <InputWrapper>
              <Input
                title="Поиск по названию"
                placeholder="Поиск"
                onChange={({ target }) => handleSearch(target.value)}
              />
              <Icon>
                <SearchIcon />
              </Icon>
            </InputWrapper>
          </HeadItem>
          <HeadItem
            title="Статус"
          >
            <Dropdown
              value={activityToOption(filters?.is_active)}
              options={activityList}
              onSelect={handleChangeActivityFilter}
              placeholder="Статус"
            />
          </HeadItem>
          <HeadItem>
            Управление
          </HeadItem>
        </TableHead>
        <TableBody rowHeight={60} isBottomRowBorder>
          {(tdDataList || []).map((item) => (
            <Row key={`A-TDT-R--${item.id}`}>
              <RowItem>
                {item.id}
              </RowItem>
              <RowItem>
                {item.name}
              </RowItem>
              <RowItem>
                <TextField
                  color={!item?.is_active ? greyText : text01}
                >
                  {getActivityText(item)}
                </TextField>
              </RowItem>
              <RowItem>
                <Wrapper
                  flex
                  justify="center"
                >
                  <Icon
                    onClick={() => history.push(`${BASE_PATH}${item.id}`)}
                  >
                    <EditIcon />
                  </Icon>
                  <Icon
                    onClick={() => setDeletedTdItem(item)}
                  >
                    <DeleteIcon />
                  </Icon>
                </Wrapper>
              </RowItem>
            </Row>
          ))}
        </TableBody>
        <AdminTablePagination
          page={filters?.page || 1}
          setPage={handleChangePage}
          pageCount={getPageCount()}
          recordsCount={tdDataCount}
          title="Шаблонов: "
        />
      </Table>
    </Container>
  );
};

export default TemplateDecorsList;

const Container = styled.div`
  font-family: Gilroy, sans-serif;
`;

const Actions = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const HeadItem = styled.div`
  width: ${({ flex }) => (flex ? '100%' : 'auto')};
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  ${({ flex }) => (flex && 'justify-content: space-between;')};
`;

const Icon = styled.div`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer;')};

  &:not(:last-of-type) {
    margin-right: .75em;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  line-height: 18px;
  margin-right: 20px;
  path {
    stroke: ${secondary};
    stroke-opacity: 1;
  }
`;

const Input = styled.input`
  width: 205px;
  margin-left: 15px;
  margin-right: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(255 152 0 / 50%);;
  outline: none;
`;

const Row = styled.div`
`;

const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const TextField = styled.div`
  flex: 1;
  margin-right: 30px;
  color: ${({ color }) => (color || text01)}
`;

const Wrapper = styled.div`
  width: 100%;
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flex, justify }) => (flex && justify && `justify-content: ${justify};`)}
  ${({ flex, align }) => (flex && align && `align-items: ${align};`)}
`;

const Button = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin: 10px 0;
  padding: 0 20px;
  color: ${({ color }) => color};
  cursor: pointer;
  border-right: 1px solid ${borderSecondary};
  :last-of-type {
    border: none;
  }
  transition: all 0.25s ease-out 0s;

  &:hover {
    color: ${({ color }) => (color === primary ? secondary : primary)};
  }
`;
