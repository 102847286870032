import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  text01,
} from '@/common/constants/theme';
import Modal from '@/common/components/ModalNew';
import Button from '@/common/components/Button';
import TrashIcon from '@/assets/icons/delete-trash.svg';

const DeleteUserModal = ({
  isOpen,
  onClose,
  email,
}) => {
  const { t } = useTranslation(['translations']);

  return (
    <Modal
      onCloseModal={onClose}
      title={t('profile.delete.modalTitle')}
      icon={TrashIcon}
      isOpen={isOpen}
    >
      <Text>{t('profile.delete.modalText', { email })}</Text>
      <Action>
        <Button
          width="small"
          isUpperCase
          isBoldText
          onClick={onClose}
        >
          {t('profile.accountsAndFinance.okButton')}
        </Button>
      </Action>
    </Modal>
  );
};

export default DeleteUserModal;

const Action = styled.div`
  margin: 2rem 0 1rem 0;
  display: flex;
  justify-content: flex-end;
`;

const Text = styled.p`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  white-space: pre;
`;
