import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import deleteIconSrc from '@/assets/icons/delete-trash.svg';
import { white } from '@/common/constants/theme';

import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Button from '@/common/components/Button';

const AdminDeleteModal = ({
  isOpen,
  onClose,
  content,
  handleDelete,
  color,
}) => {
  const { t } = useTranslation('translations');

  return (
    <ModalWithoutContent
      isOpen={isOpen}
      onCloseModal={onClose}
      color={color}
      icon={deleteIconSrc}
    >
      <Container>
        <Text bottomMargin>
          {t('admin.modals.delete.captionText')}
          {' '}
          <br />
          {content}
          ?
        </Text>
        <Text>
          {t('admin.modals.delete.warningText')}
        </Text>
        <StyledButton
          isBoldText
          isUpperCase
          onClick={() => {
            handleDelete();
            onClose();
          }}
        >
          {t('common.button.delete')}
        </StyledButton>
      </Container>
    </ModalWithoutContent>
  );
};

export default AdminDeleteModal;

const Container = styled.div``;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 15px;')}

  font-family: Gilroy;
  font-size: 15px;
  color: ${white};
  white-space: pre-wrap;
  text-align: center;
  letter-spacing: 0.16px;
  line-height: 20px;
`;

const StyledButton = styled(Button)`
  width: 100px;
  margin-top: 20px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
