import React from 'react';
import styled from 'styled-components';

import PlayersTableHead from './components/TableHead';
import PlayersTableBody from './components/TableBody';
import AdminTablePagination from '../../../../../components/Table/components/TablePagination';
import Loader from '@/common/components/Loader';
import { usePlayersContext } from '../../PlayersContext';
import { useAdminContext } from '../../../../../../../AdminContext';

const PlayersTable = ({ values, setValues }) => {
  const SIZES = [50, 150, 150, 140, 120, 120, 85];

  const {
    isChannelsBusy,
    handleDragEndChannels,
    getOffset,
  } = useAdminContext();

  const {
    filters,
    setFilters,
    channelsCount,
  } = usePlayersContext();
  const { page, limit } = filters;

  return (
    <Container>
      {isChannelsBusy && <Loader />}
      <Wrapper>
        <PlayersTableHead
          values={values}
          setValues={setValues}
          sizes={SIZES}
        />
        <PlayersTableBody
          values={values}
          sizes={SIZES}
          axis="y"
          lockAxis="y"
          distance={5}
          onSortEnd={(res) => handleDragEndChannels(res, getOffset(page))}
        />
      </Wrapper>
      <AdminTablePagination
        page={page}
        pageCount={Math.ceil((channelsCount || 0) / (limit || 1))}
        setPage={(v) => setFilters({ ...filters, page: v })}
        recordsCount={channelsCount}
      />
    </Container>
  );
};

export default PlayersTable;

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  min-width: 100%;
  max-width: fit-content;

  overflow-x: auto;
`;
