/* eslint-disable react/button-has-type,jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  borderSecondary,
  white,
} from '@/common/constants/theme';
import Checkbox from '@/common/components/Checkbox';
import Button from '@/common/components/Button';

import { RichUtils, EditorState } from 'draft-js';
import { getEntityRange, getSelectionEntity } from 'draftjs-utils';

import ToolbarButton from './ToolbarButton';
import LinkIcon from '@/assets/icons/TextEditor/link.svg';
import UnlinkIcon from '@/assets/icons/TextEditor/unlink.svg';

const defaultLinkTargetOption = '_self'; // _self or _blank

const LinkControl = ({
  editorState,
  onEditorStateChange,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [linkTarget, setLinkTarget] = useState('');
  const [linkTargetOption, setLinkTargetOption] = useState(defaultLinkTargetOption);

  let link = null;

  const isUrl = (str) => new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/).test(str);

  const getCurrentLink = () => {
    const currentEntity = getSelectionEntity(editorState);
    const contentState = editorState.getCurrentContent();
    if (currentEntity
      && contentState.getEntity(currentEntity).get('type') === 'LINK') {
      link = contentState.getEntity(currentEntity).get('data').url;
    }
  };
  getCurrentLink();

  const removeLink = () => {
    const currentEntity = getSelectionEntity(editorState);
    let selection = editorState.getSelection();
    if (currentEntity) {
      const entityRange = getEntityRange(editorState, currentEntity);
      const isBackward = selection.getIsBackward();
      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start,
        });
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end,
        });
      }
      onEditorStateChange(RichUtils.toggleLink(editorState, selection, null));
    }
  };

  const showEditLinkModal = () => {
    setShowModal(true);
    setLinkTarget('');
    setLinkTargetOption(defaultLinkTargetOption);
  };

  const addLink = (e) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {
        url: linkTarget,
        target: linkTargetOption,
      },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

    const newState = RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey,
    );
    onEditorStateChange(newState);
    setShowModal(false);
  };

  const updateTargetOption = (checked) => {
    setLinkTargetOption(checked ? '_blank' : '_self');
  };

  const renderAddLinkModal = () => (
    <LinkModal
      className="rdw-link-modal"
      onClick={(event) => { event.stopPropagation(); }}
    >
      <Label>Адрес ссылки</Label>
      <Input
        onChange={({ target }) => setLinkTarget(target.value)}
        onBlur={({ target }) => setLinkTarget(target.value)}
        value={linkTarget}
      />
      <Checkbox
        checked={linkTargetOption === '_blank'}
        onChange={updateTargetOption}
        type="secondary"
        label="Открыть ссылку в новом окне"
      />
      <span className="rdw-link-modal-buttonsection">
        <StyledButton
          onClick={addLink}
          disabled={!linkTarget || !isUrl(linkTarget)}
          isBoldText
          isUpperCase
        >
          Добавить
        </StyledButton>
        <StyledButton
          onClick={() => {
            setShowModal(false);
          }}
          isBoldText
          isUpperCase
        >
          Отмена
        </StyledButton>
      </span>
    </LinkModal>
  );

  return (
    <>
      <ToolbarButton
        className="rdw-option-wrapper"
        label="Ссылка"
        icon={LinkIcon}
        onToggle={() => {
          showEditLinkModal();
        }}
      />
      <ToolbarButton
        className="rdw-option-wrapper"
        disabled={!link}
        label="Убрать ссылку"
        icon={UnlinkIcon}
        onToggle={() => {
          removeLink();
        }}
      />
      {showModal ? renderAddLinkModal() : undefined}
    </>
  );
};

export default LinkControl;

const LinkModal = styled.div`

    position: absolute;
    top: 35px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    z-index: 100;

    right: 3rem;
    left: unset;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3);
    background-color: ${white};
    height: 250px;
    width: 450px;

    .rdw-link-modal-buttonsection {
        display: flex;
        justify-content: flex-end;
        margin-top: 28px;
    }


`;

const StyledButton = styled(Button)`
  margin-left: 26px;
`;

const Label = styled.span`
  font-family: Gilroy;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;
`;

const Input = styled.input`
  outline: none;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 9px 15px;
  font-family: Gilroy;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;

  background-color: transparent;
  border: 1px solid ${borderSecondary};
  border-radius: 2px;
`;
