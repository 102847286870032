import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import SaveSrc from '@/assets/icons/disketteIcon.svg';
import ModalNew from '@/common/components/ModalNew';
import {
  inputBorder,
  text01,
  white,
  red,
} from '@/common/constants/theme';
import Button from '@/common/components/Button';

const ChannelsMainEditModal = ({
  isOpen,
  onClose,
  onSubmit,
  value,
  title,
  descr,
  error,
}) => {
  const { t } = useTranslation(['translations']);
  const [currentValue, setCurrentValue] = useState(value);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <Container>
      <ModalNew
        isOpen={isOpen}
        onCloseModal={onClose}
        icon={SaveSrc}
        title={title || t('steps.step4.content.body.modal.editTitle')}
      >
        <Wrapper padding>
          <Text>
            {descr || t('steps.step4.content.body.modal.descr')}
          </Text>
          <Input
            value={currentValue}
            onChange={({ target }) => setCurrentValue(target.value)}
            onBlur={() => (currentValue.length === 0 ? setIsError(true) : setIsError(false))}
          />
          {isError && (
            <ErrorText>
              {error || t('steps.step4.content.body.modal.errors.minLength')}
            </ErrorText>
          )}
          <Wrapper flex>
            <StyledButton
              isBoldText
              isUpperCase
              width="small"
              onClick={() => {
                if (currentValue.length > 0) {
                  onSubmit(currentValue);
                  setCurrentValue('');
                  onClose();
                } else {
                  setIsError(true);
                }
              }}
            >
              {t('steps.step4.content.body.modal.edit')}
            </StyledButton>
          </Wrapper>
        </Wrapper>
      </ModalNew>
    </Container>
  );
};

export default ChannelsMainEditModal;

const Container = styled.div``;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  
  ${({ padding }) => padding && ('padding: 3px 8px 18px 2px;')}
`;

const Text = styled.div`
  margin-bottom: 8px;

  font-family: Gilroy;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const ErrorText = styled(Text)`
  margin-top: 8px;

  font-size: 13px;
  line-height: 20px;
  color: ${red};
`;

const Input = styled.input`
  width: 100%;
  height: 39px;
  padding: 0 13px;

  background: ${white};
  border: 1px solid ${inputBorder};
  box-sizing: border-box;
  border-radius: 2px;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  margin-top: 36px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
