import React, { useEffect, useState, useRef } from 'react';
import { add, differenceInCalendarDays } from 'date-fns';
import { round } from 'lodash/math';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  brightSecondary, secondary,
  white,
} from '@/common/constants/theme';

import {
  CHECKBOX_DISABLED, CHECKBOX_ENABLED, CHECKBOX_UNDEFINED,
} from '@/common/constants/controls';

import VipIcon from '@/assets/icons/crown.svg';
import Tooltip from '@/common/components/Tooltip';
import getWordDeclension from '@/common/utils/humanization.util';
import DatePicker from '@/common/components/DatePicker';
import Slider from './Slider';
import CalendarIcon from '../../../icons/CalendarIcon';
import Toggle from './Toggle';
import ToggleTriState from './ToggleTriState';

// import { deviceOrder } from '@/services/actions/devices.actions';

import { useAppContext } from '@/AppContext';
import {
  SHOW_SUBSCRIBE_PREMIUM_CONTROLS,
  usePersonalPageContext,
} from '../../../../PersonalPageContext';
import {
  MONTH_LENGTH,
  useSubscriptionContext,
} from '../SubscriptionContext';

const INCORRECT_MESSAGE_SHOW_TIME = 1000 * 3;
const GET_PRICE_ON_SLIDER_CHANGE_TIMEOUT = 1000 * 0.3;

const INIT_TRISTATE = {
  text: 'onState',
  checkbox: CHECKBOX_UNDEFINED,
  gScale: 66,
  value: null,
};

const Subscription = ({
  subscriptionPeriod,
  setSubscriptionPeriod,
  devices,
  isVip,
  setVip,
  autoSubscription,
}) => {
  const { t } = useTranslation('translations');

  const { discounts, prices } = useAppContext();

  const { buySubscription } = usePersonalPageContext();

  const {
    setOrderAmount,
    orderAmount,
    promoTransaction,
    discount,
    setDiscount,
    personalDiscount,
    setOrderDiscount,
    setOrderPromoDiscount,
    isPromoActivated,
  } = useSubscriptionContext();

  let incorrectMessageTimeout = useRef(null);
  let getPriceTimeout = useRef(null);

  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [calendarValue, setCalendarValue] = useState(null);
  const [displayIncorrectMessage, setDisplayIncorrectMessage] = useState(false);

  const [triStateValue, setTriStateValue] = useState(INIT_TRISTATE);

  const getValueOnTriState = (v) => {
    let retVal = null;
    switch (v) {
      case CHECKBOX_DISABLED:
        retVal = false;
        break;
      case CHECKBOX_ENABLED:
        retVal = true;
        break;
      default:
        break;
    }
    return retVal;
  };

  const getDiscount = (period) => {
    let retVal = 0;
    if (isPromoActivated || (personalDiscount > 0)) {
      return 0;
    }
    (discounts || []).forEach((d) => {
      if (d.daysCount <= period) {
        retVal = d.percent;
      }
    });
    return retVal;
  };

  const getCorrectPeriodValue = (period = 30) => {
    let retVal = period;
    if (retVal < 30) {
      retVal = 30;
    } else if (retVal > 360) {
      retVal = 360;
    }
    return retVal;
  };

  const getPriceForPeriod = (period, vip = isVip) => {
    const params = {
      deviceIds: devices.map((d) => d.id),
      period,
      isPremium: vip,
      isAutoSubscr: autoSubscription,
      promoTransactionId: promoTransaction,
    };

    buySubscription(params)
      .then((response) => {
        const res = response?.data || {};
        setOrderAmount(res.amount);
        setOrderDiscount(res.discount);
        setOrderPromoDiscount(res.promocode_discount);
        if (setSubscriptionPeriod
          && (subscriptionPeriod !== period)
        ) {
          setSubscriptionPeriod(period);
        }
      })
      .catch((errResponse) => {
        const err = errResponse?.data || {};
        setOrderAmount(err?.amount || 0);
        setOrderDiscount(err?.discount || 0);
        setOrderPromoDiscount(err?.promocode_discount || 0);
        if (setSubscriptionPeriod
          && (subscriptionPeriod !== period)
        ) {
          setSubscriptionPeriod(period);
        }
      });
  };

  const showIncorrectMessage = () => {
    setDisplayIncorrectMessage(true);
    incorrectMessageTimeout = setTimeout(() => {
      setDisplayIncorrectMessage(false);
    }, INCORRECT_MESSAGE_SHOW_TIME);
  };

  const onChange = (newPeriod) => {
    const correctedValue = getCorrectPeriodValue(newPeriod);
    if (newPeriod !== correctedValue) {
      showIncorrectMessage();
    }
    setSubscriptionPeriod(correctedValue);
  };

  const onBlur = () => {
    const correctedValue = getCorrectPeriodValue(subscriptionPeriod);
    if (subscriptionPeriod !== correctedValue) {
      showIncorrectMessage();
      setSubscriptionPeriod(correctedValue);
    }
  };

  const onChangeCalendar = (date) => {
    const result = differenceInCalendarDays(
      new Date(date),
      new Date(),
    );
    getPriceForPeriod(
      result - 1 > 360 ? 360 : result - 1,
    );
  };

  const days = [
    t('subscriptionsContent.day'),
    t('subscriptionsContent.fromTwoToFiveDays'),
    t('subscriptionsContent.moreThenSixDays'),
  ];

  const getSubscrPrice = (dayPrice) => {
    const cost = (devices.length * dayPrice * subscriptionPeriod
      * ((100 - discount) / 100)).toFixed(2);
    setOrderAmount(cost);
    return cost;
  };

  useEffect(() => {
    const useDiscount = getDiscount(subscriptionPeriod);
    setDiscount(useDiscount);
    setCalendarValue(add(new Date(), { days: subscriptionPeriod + 1 }));

    if (getPriceTimeout) {
      clearTimeout(getPriceTimeout);
    }
    getPriceTimeout = setTimeout(() => {
      getPriceForPeriod(subscriptionPeriod);
    }, GET_PRICE_ON_SLIDER_CHANGE_TIMEOUT);

    return () => {
      if (getPriceTimeout) {
        clearTimeout(getPriceTimeout);
      }
    };
  }, [subscriptionPeriod, devices]);

  useEffect(() => {
    const useDiscount = getDiscount(subscriptionPeriod);
    setDiscount(useDiscount);
  }, [isPromoActivated]);

  useEffect(() => () => {
    clearTimeout(incorrectMessageTimeout);
  }, []);

  useEffect(() => {
    const data = { ...INIT_TRISTATE, value: isVip };
    switch (isVip) {
      case true:
        data.text = 'vip';
        data.checkbox = CHECKBOX_ENABLED;
        data.gScale = 0;
        break;
      case false:
        data.text = 'noVip';
        data.checkbox = CHECKBOX_DISABLED;
        data.gScale = 100;
        break;
      default:
        break;
    }
    setTriStateValue(data);
  }, [isVip]);

  return (
    <SubscriptionSection>
      <Slider
        value={subscriptionPeriod}
        onChange={onChange}
        // onAfterChange={getPriceForPeriod}
      />
      <PriceRow>
        <InputContainer>
          <Input
            value={subscriptionPeriod}
            onChange={({ target }) => setSubscriptionPeriod(target.value)}
            onBlur={onBlur}
            type="number"
            min={30}
            max={360}
          />
          <InputHint isError={displayIncorrectMessage}>
            {displayIncorrectMessage ? t('subscriptionsContent.from30DaysWarning') : t('subscriptionsContent.from30Days') }
          </InputHint>
          <Days>{getWordDeclension(subscriptionPeriod, days)}</Days>
          <Icon onClick={() => setCalendarOpen(true)}>
            <CalendarIcon />
          </Icon>
          <DatePicker
            isOpen={isCalendarOpen}
            onClose={() => setCalendarOpen(false)}
            onApply={onChangeCalendar}
            minDate={add(new Date(), { days: 31 })}
            value={calendarValue}
          />
        </InputContainer>
        <Price>
          {!!orderAmount && `$ ${orderAmount}`}
          {!orderAmount && (prices?.dayPrice && prices?.dayPricePremium && `$ ${(isVip
            ? getSubscrPrice(prices.dayPricePremium)
            : getSubscrPrice(prices.dayPrice))}`)}
        </Price>
      </PriceRow>
      {!!discount && (
        <Discount>
          {`${t('subscriptionsContent.yourDiscount')} ${discount}%`}
        </Discount>
      )}
      {SHOW_SUBSCRIBE_PREMIUM_CONTROLS && (
        <CardRow>
          <VipBG />
          <Img src={VipIcon} gScale={triStateValue.gScale} />
          <Tooltip
            title={t('subscriptionsContent.vipTooltipText',
              { summ: round(MONTH_LENGTH * prices?.dayPricePremium, 2) })}
            width={270}
            position="left"
            trigger="hover"
          >
            <VipText isVip={triStateValue.value}>{t(`subscriptionsContent.premiumToggle.${triStateValue.text}`)}</VipText>
          </Tooltip>
          <Tooltip
            title={devices.length > 1
              ? t(`subscriptionsContent.controls.triState.tooltipText.${triStateValue.text}`)
              : t(`subscriptionsContent.controls.triState.tooltipText.${isVip ? 'vip' : 'noVip'}`)}
            trigger="hover"
          >
            {devices.length === 1 ? (
              <Toggle
                checked={isVip}
                onChange={({ target }) => {
                  const useV = target.checked;
                  setVip(useV);
                  getPriceForPeriod(subscriptionPeriod, useV);
                }}
              />
            ) : (
              <ToggleTriState
                state={triStateValue.checkbox}
                onChange={(v) => {
                  const useV = getValueOnTriState(v);
                  setVip(useV);
                  getPriceForPeriod(subscriptionPeriod, useV);
                }}
              />
            )}
          </Tooltip>
        </CardRow>
      )}
    </SubscriptionSection>
  );
};

export default Subscription;

const Discount = styled.span`
    font-family: Gilroy, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: ${brightSecondary};
    position: absolute;
    bottom: 2.5rem;
    left: 2.5rem;
`;

const CardRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 2.5rem;
`;

const VipBG = styled.div`
  background: linear-gradient(269.1deg, #E4D8E4 5.14%, rgba(228, 216, 228, 0) 99.2%);
  opacity: 0.2;
  width: 230px;
  height: 2.5rem;
  position: absolute;
  right: -35px;
`;

const Img = styled.img`
  margin-right: .5rem;
  ${({ gScale }) => (gScale && `filter: grayscale(${gScale}%);`)}
`;

const VipText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${white};
  opacity: 0.6;
  margin-right: 1rem;
  ${({ isVip }) => isVip && `
    color: ${secondary};
    opacity: 1;
  `}
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Input = styled.input`
  background: #FAFAFA;
  opacity: 0.8;
  border: 1px solid #EFEEF3;
  box-sizing: border-box;
  border-radius: 2px;
  width: 3.5rem;
  padding: 6px 14px;
  outline: none;
  -moz-appearance: textfield !important;
`;

const InputHint = styled.span`
  position: absolute;
  top: 2.5rem;
  font-family: Gilroy, sans-serif;
  font-size: ${({ isError }) => (isError ? 14 : 12)}px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${white};
  ${({ isError }) => isError && `
    font-weight: 600;
  `};
  opacity: ${({ isError }) => (isError ? 1 : 0.8)};
`;

const Days = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${white};
  margin-left: 1rem;
  width: 3rem;
`;

const Price = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${white};
  opacity: 0.6;
`;

const SubscriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 22px 35px 40px 35px;
  position: relative;
`;

const Icon = styled.div`
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  cursor: pointer;
`;
