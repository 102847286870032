import React from 'react';
import { ServerContextProvider } from './ServerContext';
import Server from './Server';

const ServerContainer = () => (
  <ServerContextProvider>
    <Server />
  </ServerContextProvider>
);

export default ServerContainer;
