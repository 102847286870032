import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';

import {
  primary,
  secondary,
  text01,
  text04,
  white,
  borderSecondary, grey,
} from '../constants/theme';
import CloseIcon from './icons/CloseIcon';

const locales = { ru, en };

const CALENDAR_POSITIONS = {
  vertical: {
    higher: 'bottom',
    below: 'top',
  },
  horizontal: {
    left: 'right',
    right: 'left',
  },
};

const CALENDAR_SIZE = {
  width: 250,
  height: 250,
};

const DatePicker = ({
  isOpen,
  onClose,
  value,
  onChange,
  onApply,
  minDate,
  maxDate,
  position,
  noApplyButton,
  initiator,
  applyButtonDisabled,
}) => {
  const { t } = useTranslation(['translations']);
  const [componentValue, setComponentValue] = useState(value || new Date());

  const [calendarPosition, setCalendarPosition] = useState({
    vertical: CALENDAR_POSITIONS.vertical.higher,
    horizontal: CALENDAR_POSITIONS.horizontal.right,
  });

  const getCalendarPosition = (element) => {
    const posVertical = CALENDAR_SIZE.height < element.getBoundingClientRect().top
      ? CALENDAR_POSITIONS.vertical.higher
      : CALENDAR_POSITIONS.vertical.below;
    const posHorizontal = CALENDAR_SIZE.width
      + element.getBoundingClientRect().x <= window.innerWidth
      ? CALENDAR_POSITIONS.horizontal.right
      : CALENDAR_POSITIONS.horizontal.left;
    setCalendarPosition({
      vertical: posVertical,
      horizontal: posHorizontal,
    });
  };

  useEffect(() => {
    setComponentValue(value);
  }, [value]);

  useEffect(() => {
    if (!isOpen || !initiator) { return; }

    getCalendarPosition(initiator);
  }, [isOpen, initiator]);

  const onChangeHandler = (newValue) => {
    setComponentValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const applyHandler = () => {
    if (onApply) { onApply(componentValue); }
    if (onClose) { onClose(); }
  };

  useEffect(() => {
    setComponentValue(value || new Date());
  }, [value]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          if (onClose) { onClose(); }
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Container>
      {isOpen && (
        <Wrapper
          noApplyButton={noApplyButton}
          position={position}
          calendarPos={calendarPosition}
          ref={wrapperRef}
          className="main-datepicker"
        >
          <StyledCalendar
            onChange={onChangeHandler}
            value={componentValue}
            minDate={minDate}
            maxDate={maxDate}
            locale="ru"
            formatMonthYear={(locale, date) => (
              <>
                {format(date, 'LLLL', { locale: locales[locale] })}
                <Year>
                  {format(date, 'YYY', { locale: locales[locale] })}
                </Year>
              </>
            )}
          />
          <Icon onClick={(e) => onClose && onClose(e)}>
            <CloseIcon />
          </Icon>
          {!noApplyButton && (
            <Text
              topMargin
              pointer
              onClick={(e) => !applyButtonDisabled && applyHandler(e)}
              disabled={applyButtonDisabled}
            >
              {t('table.apply')}
            </Text>
          )}
        </Wrapper>
      )}
    </Container>
  );
};

export default DatePicker;

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  width: 243px;
  height: ${({ noApplyButton }) => (!noApplyButton ? '245px' : '225px')};
  padding: 8px 33px 15px 23px;

  position: ${(props) => props.position || 'absolute'};
  z-index: 20;

  ${({ calendarPos }) => (`${calendarPos.vertical}: calc(100% ${calendarPos.vertical === CALENDAR_POSITIONS.vertical.higher ? '+ 0.75' : '- 3'}rem);`)}
  ${({ calendarPos }) => (`${calendarPos.horizontal}: -2rem;`)}

  background-color: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 3px;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;

  transition: all .15s ease-in-out 0s;
`;

const Icon = styled.div`
  position: absolute;
  top: 7px;
  right: 13px;

  cursor: pointer;

  svg path {
    fill: ${text04};
  }
`;

const Text = styled.div`
  display: inline-block;
  float: right;
  padding: 0 1rem;
  ${({ topMargin }) => topMargin && ('margin-top: 10px;')};
  ${({ pointer, disabled }) => pointer && (`cursor: ${disabled ? 'default' : 'pointer'};`)};
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? grey : primary)};
  text-align: right;
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const StyledCalendar = styled(Calendar)`
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button {
    padding-left: 5px !important;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    cursor: pointer;
  }

  .react-calendar__navigation__next-button {
    left: 2.5rem;
  }

  .react-calendar__tile {
    width: 24px;
    max-width: 24px !important;
    height: 24px;
    padding: 0;
    margin-right: 2px;

    background-color: transparent;
    border: none;
    border-radius: 100px;
    cursor: pointer;

    font-family: Gilroy, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: ${text01};
    text-align: center;
    line-height: 14px;
    outline: none;
  }

  .react-calendar__tile[disabled] {
    color: ${text04};
  }

  .react-calendar__tile--active {
    border: 1px solid ${primary};
  }

  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label {
    background-color: transparent;
    border: none;
    border-radius: 100px;
    position: absolute;
    top: .55rem;
  }

  .react-calendar__navigation__label {
    font-family: Gilroy, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${text01};
    text-transform: uppercase;
    left: 4.5rem;
    top: 1.25rem;
    pointer-events: none;
  }

  .react-calendar__month-view__weekdays {
    display: none !important;
  }

  .react-calendar__navigation__arrow {
    padding: 0 12px;
    font-size: 24px;
    color: ${secondary};
    outline: none;
    height: 37px;
    :disabled {
      color: ${text04};
    }
  }

  .react-calendar__month-view__days {
    align-items: center;

    min-height: 144px;
  }
  .react-calendar__viewContainer {
    margin-top: 3rem;
  }
`;

const Year = styled.span`
  line-height: 17px;
  color: ${secondary};
  margin-left: 1rem;
`;
