import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  secondary,
  text01,
  white,
} from '@/common/constants/theme';
import pageSizes from '@/common/constants/pageSizes';
import ArrowToStart from '../../../../screens/PersonalPageScreen/components/icons/ArrowToStart';
import ArrowLeft from '../../../../screens/PersonalPageScreen/components/icons/ArrowLeft';
import ArrowRight from '../../../../screens/PersonalPageScreen/components/icons/ArrowRight';
import ArrowToEnd from '../../../../screens/PersonalPageScreen/components/icons/ArrowToEnd';

const TablePagination = ({
  pagesCount,
  page,
  filtersHandler,
  dataLength,
  limit,
}) => {
  const { t } = useTranslation(['translations']);

  const getFormattedPages = () => {
    if (pagesCount > 7) {
      if (page === 1 || page === 2) {
        return [1, 2, 3, '...', pagesCount];
      }
      if (page === 3) {
        return [1, 2, 3, 4, '...', pagesCount];
      }
      if (page === pagesCount || page === pagesCount - 1) {
        return [1, '...', pagesCount - 2, pagesCount - 1, pagesCount];
      }
      if (page === pagesCount - 2) {
        return [1, '...', pagesCount - 3, pagesCount - 2, pagesCount - 1, pagesCount];
      }
      return [1, '...', page - 1, page, page + 1, '...', pagesCount];
    }
    return Array.from({ length: pagesCount }, (_, i) => i + 1);
  };

  const checkActive = () => dataLength > 1 && page !== pagesCount;
  return (
    <>
      {pagesCount > 1 && (
        <Container className="table__pagination">
          <Wrapper />
          <Wrapper>
            <Text rightMargin>
              {t('table.pagination')}
            </Text>
            {pageSizes.map((item) => (
              <TextButton
                key={Math.random()}
                mainBtns
                isActive={+limit === +item}
                onClick={() => filtersHandler('limit', item)}
              >
                {item}
              </TextButton>
            ))}
          </Wrapper>
          <Wrapper>
            <Button isActive={page !== 1} onClick={() => page !== 1 && filtersHandler('page', 1)}>
              <ArrowToStart />
            </Button>
            <Button isActive={page !== 1} onClick={() => page !== 1 && filtersHandler('page', page - 1)}>
              <ArrowLeft />
            </Button>
            {getFormattedPages().map((pageItem) => (
              <PageNumber
                key={Math.random()}
                selected={pageItem === page}
                onClick={() => pageItem !== '...' && filtersHandler('page', pageItem)}
              >
                {pageItem}
              </PageNumber>
            ))}
            <Button
              isActive={dataLength > 1 && page !== pagesCount}
              onClick={() => checkActive() && filtersHandler('page', page + 1)}
            >
              <ArrowRight />
            </Button>
            <Button
              isActive={dataLength > 1 && page !== pagesCount}
              onClick={() => checkActive() && filtersHandler('page', pagesCount)}
            >
              <ArrowToEnd />
            </Button>
          </Wrapper>
        </Container>
      )}
    </>
  );
};

export default TablePagination;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 47px;
  padding: 0 45px;

  position: relative;
  z-index: 2;

  background: ${white};
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 0 0 4px 4px;
`;

const Text = styled.div`
  margin-right: ${({ rightMargin }) => rightMargin && 24}px;

  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  line-height: 14px;
  letter-spacing: 0.16px;

  color: ${text01};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 50px;
`;

const Button = styled(Text)`
  margin-right: 10px;

  cursor: ${({ isActive }) => isActive && ('pointer')};

  svg path {
    fill: ${({ isActive }) => (isActive ? secondary : '#bcbcbc')};

    transition: 0.2s ease-in-out;
  }
`;

const PageNumber = styled(Text)`
  margin-right: 8px;

  opacity: ${({ selected }) => (selected ? 1 : 0.4)};
  cursor: ${({ selected }) => !selected && ('pointer')};

  font-weight: 600;
  color: ${text01};

  transition: 0.2s ease-in-out;
`;

const TextButton = styled(Text)`
  margin-right: ${({ mainBtns }) => mainBtns && 15}px;

  opacity: ${({ isActive }) => (isActive ? 0.4 : 1)};
  cursor: ${({ isActive }) => !isActive && ('pointer')};

  font-weight: 600;
  color: ${({ isActive }) => (isActive ? text01 : secondary)};

  transition: 0.2s ease-in-out;
`;
