import React from 'react';

import Admin from './Admin';
import { AdminContextProvider } from './AdminContext';

const AdminContainer = ({ isUserExists }) => (
  <AdminContextProvider>
    <Admin isUserExists={isUserExists} />
  </AdminContextProvider>
);

export default AdminContainer;
