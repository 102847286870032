import styled from 'styled-components';

const PageContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 85rem;
  padding: 0 2rem;


  ${({ isCentered }) => (isCentered && 'text-align: center;')} @media(max-width: 1360 px) {
  width: 74rem;
}

  @media (max-width: 1198px) {
    width: 85rem;
  }
`;

export default PageContainer;
