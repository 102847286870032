import React from 'react';
import styled from 'styled-components';
import { StatisticsContextProvider } from './StatisticsContext';
import MoneyContainer from './MoneyContainer';
import UserContainer from './UserContainer';
import FinanceContainer from './FinanceContainer';

const StatisticsContainer = () => (
  <StatisticsContextProvider>
    <Container>
      <Title>Статистика</Title>
      <MoneyContainer />
      <UserContainer />
      <FinanceContainer />
    </Container>
  </StatisticsContextProvider>
);

export default StatisticsContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  margin-top: 16px;
`;
