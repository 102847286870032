import React, { useState } from 'react';
import styled from 'styled-components';
import { SortableContainer } from 'react-sortable-hoc';

import { borderSecondary, text02 } from '@/common/constants/theme';

import { useAdminContext } from '../../../../../../../../AdminContext';
import PlayersTableBodyItem from './TableBodyItem';

const PlayersTableBody = SortableContainer(({ sizes, values }) => {
  const { channels } = useAdminContext();
  const [editedId, setEditedId] = useState(-1);
  const [inputValues, setInputValues] = useState({});

  return (
    <Container>
      <Wrapper grabbing={channels?.length}>
        {channels?.length ? channels.map((item, i) => (
          <PlayersTableBodyItem
            editedId={editedId}
            setEditedId={setEditedId}
            inputValues={inputValues}
            setInputValues={setInputValues}
            item={item}
            i={i}
            sizes={sizes}
            values={values}
            index={i}
          />
        )) : <NoData>Не найдено</NoData>}
      </Wrapper>
    </Container>
  );
});

export default PlayersTableBody;

const Container = styled.div`
  min-width: 100%;
  width: fit-content;
  height: 650px;

  position: relative;
  z-index: 0;

  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid ${borderSecondary};
`;

const Wrapper = styled.div`
  ${({ grabbing }) => grabbing && 'cursor: grabbing;'}
`;

const NoData = styled.div`
  margin-top: 100px;

  font-family: Gilroy;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${text02};
  text-align: center;
  line-height: 15px;
  letter-spacing: 0.16px;

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 13px;
  }
`;
