import RequestService from '../../request.service';
import urls from '../../routes/public/faq.api';

export const getTagsListAction = () => RequestService(urls.tagsList);

export const getFaqListAction = (params) => {
  const {
    tag,
    search,
    ...useParams
  } = params;
  if ((tag || 0) > 0) {
    useParams.tag = tag;
  }
  if ((search || '').length > 0) {
    useParams.search = search;
  }
  return RequestService(
    urls.faqList, { method: 'GET', params: useParams },
  );
};

export const getFaqItemAction = (id) => RequestService(urls.faqItem(id));

export const askUserQuestionAction = ({ taskId, ...data }) => RequestService(
  urls.askQuestion(taskId),
  { method: 'POST', body: taskId ? null : data },
);

export const increaseViewsCountAction = (id) => RequestService(
  urls.increaseViews(id), { method: 'POST' },
);
