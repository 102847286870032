import React from 'react';
import ArrowRightPrimary from '../../../icons/ArrowRightPrimary';
import styled from 'styled-components';
import { text01, primary } from '@/common/constants/theme';
import { Link } from 'react-router-dom';
import ReferralWithdrawTable from './ReferralWithdrawTable';

const WithdrawList = () => (
  <>
    <Container>
      <Wrapper>
        <Title>
          Запросы на вывод реферальных вознаграждений
        </Title>
      </Wrapper>
      <Wrapper>
        <StyledLink to="/admin/users/main">
          Вернуться к списку пользователей
        </StyledLink>
        <Icon>
          <ArrowRightPrimary />
        </Icon>
      </Wrapper>
    </Container>
    <ReferralWithdrawTable />
  </>
);

export default WithdrawList;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 16px;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: ${text01};
  line-height: 20px;
`;
const StyledLink = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${primary};
  line-height: 20px;

  &:hover,
  &:focus {
    color: ${primary};
  }
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;
  top: 3px;
  cursor: pointer;
`;
