import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ModalNew from '@/common/components/ModalNew';
import {
  primary,
  red,
  text01, text02,
} from '@/common/constants/theme';
import Input from '@/common/components/Input';
import Button from '@/common/components/Button';
import WalletIcon from '@/assets/icons/walletIcon.svg';
import Dropdown from '@/common/components/Dropdown';

const WithdrawalWalletModal = ({
  wallet,
  modalType,
  isOpen,
  onClose,
  onSubmit,
  withdrawalSystems,
}) => {
  const { t } = useTranslation('translations');

  const [selectedWithdrawalSystem, setSelectedWithdrawalSystem] = useState(null);
  const [walletValue, setWalletValue] = useState('');
  const [isWalletError, setIsWalletError] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);

  const options = (withdrawalSystems || [])
    .filter((ws) => !ws.is_disabled)
    .map((item) => ({
      key: item.id,
      title: item.name,
      item,
    }));

  const titles = {
    add: {
      window: 'profile.accountsAndFinance.addAccountTitle',
      input: 'profile.accountsAndFinance.addWalletInputTitle',
      submit: 'profile.accountsAndFinance.addAccountButton',
    },
    edit: {
      window: 'profile.accountsAndFinance.editAccountTitle',
      input: 'profile.accountsAndFinance.editWalletInputTitle',
      submit: 'profile.accountsAndFinance.editAccountButton',
    },
    delete: {
      window: 'stub',
      input: 'stub',
      submit: 'stub',
    },
  };

  useEffect(() => {
    setWalletValue(wallet && selectedWithdrawalSystem && (wallet.withdrawal_system.id === selectedWithdrawalSystem.id) ? wallet.withdrawal_system_user_identifier : '');
  }, [isOpen, selectedWithdrawalSystem]);

  const validateWallet = (val) => {
    if (selectedWithdrawalSystem == null) {
      return false;
    }
    const re = new RegExp(selectedWithdrawalSystem.user_identifier_regexp);
    return re.test(val);
  };

  const checkError = () => {
    if (walletValue !== '' && !validateWallet(walletValue)) {
      setIsWalletError(true);
    } else {
      setIsWalletError(false);
    }
  };

  useEffect(() => {
    if (wallet != null) {
      // eslint-disable-next-line max-len
      setSelectedWithdrawalSystem(withdrawalSystems.find((ws) => ws.id === wallet.withdrawal_system.id));
      setWalletValue(wallet.withdrawal_system_user_identifier);
    }
  }, [isOpen]);

  useEffect(() => {
    checkError();
    setIsValueChanged(walletValue !== (wallet ? wallet.withdrawal_system_user_identifier : ''));
  }, [walletValue]);

  const checkIsValid = () => !!validateWallet(walletValue);

  const isSubmitEnabled = {
    add: () => walletValue.length > 0,
    edit: () => walletValue.length > 0 && isValueChanged,
  };

  const submitHandler = () => {
    if (!isWalletError && isSubmitEnabled[modalType]()) {
      onSubmit({ walletValue, withdraw_system: selectedWithdrawalSystem });
    }
  };

  const closeHandler = () => {
    setWalletValue('');
    setSelectedWithdrawalSystem(null);
    onClose();
  };

  // eslint-disable-next-line no-nested-ternary
  const modalTitle = () => (selectedWithdrawalSystem !== null ? selectedWithdrawalSystem.name : (wallet !== null ? wallet.withdrawal_system.name : ''));

  return (
    <ModalNew
      isOpen={isOpen}
      onCloseModal={closeHandler}
      title={`${modalType && t(titles[modalType].window)} ${modalTitle()}`}
      icon={WalletIcon}
      isHidden={false}
      noOverFlow
    >
      {modalType === 'add'
        && (
        <Wrapper bottomMargin>
          <Text bottomMargin>{t('referrals.modal.selectSystem')}</Text>
          <StyledDropdown
            options={options}
            defaultValue={wallet ? options.find((o) => o.key === wallet.withdrawal_system.id) : 0}
            /* eslint-disable-next-line max-len */
            onSelect={(item) => setSelectedWithdrawalSystem(withdrawalSystems.find((ws) => item.key === ws.id))}
            isBoldText
            isUpperCase
            height="39px"
            optionHeight="39px"
            color={primary}
          />
        </Wrapper>
        )}
      {selectedWithdrawalSystem
        && (
        <>
          <Wrapper>
            <Text bottomMargin>
              {`${modalType && t(titles[modalType].input)} ${selectedWithdrawalSystem && selectedWithdrawalSystem.user_identifier_name}`}
            </Text>
          </Wrapper>
          <Wrapper>
            <StyledInput
              value={walletValue}
              onChange={(event) => setWalletValue(event.target.value)}
              height="39px"
              width={310}
              /* eslint-disable-next-line max-len */
              placeholder={selectedWithdrawalSystem && selectedWithdrawalSystem.user_identifier_example}
            />
            <SmallLabel>
              {`${t('profile.accountsAndFinance.walletInputFormatLabel')} ${selectedWithdrawalSystem && selectedWithdrawalSystem.user_identifier_example}`}
            </SmallLabel>
            <ErrorText>{isWalletError && t('referrals.modal.errors.wrongNumber')}</ErrorText>
          </Wrapper>
        </>
        )}
      <FlexWrapper>
        <StyledButton
          isBoldText
          isUpperCase
          isDisabled={!checkIsValid() || !isValueChanged}
          onClick={submitHandler}
        >
          {modalType && t(titles[modalType].submit)}
        </StyledButton>
      </FlexWrapper>
    </ModalNew>
  );
};

export default WithdrawalWalletModal;

const Wrapper = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 29px;')};
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 7px;')};

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-weight: normal;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const ErrorText = styled(Text)`
  ${({ maxWidth }) => maxWidth && (`max-width: ${maxWidth}px;`)}

  min-height: 20px;
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${red};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const StyledInput = styled(Input)`
  width: ${({ width }) => `${width}px`};
`;

const StyledButton = styled(Button)`
  width: 170px;
  margin-left: auto;
  margin-top: 36px;
  margin-bottom: 18px;

  ${({ isDisabled }) => isDisabled && (`
    background-color: ${text01};
    opacity: 0.2;
    cursor: default;

    &:hover {
      background-color: ${text01};
      opacity: 0.2;
    }

    &:active {
      background-color: ${text01};
      opacity: 0.2;
    }
  `)}
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;

const SmallLabel = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: ${text02};
  margin: .5rem 0;
  min-width: 4.5rem;
`;
