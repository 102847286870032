import React from 'react';
import styled from 'styled-components';
import {
  Link, Redirect, Route, Switch,
} from 'react-router-dom';

import { secondary } from '@/common/constants/theme';

import { ServersContextProvider } from './ServersContext';
import ServersTable from './components/Table';
import ServersEditServer from './components/EditServer';

const ServersContainer = () => (
  <ServersContextProvider>
    <Switch>
      <Route path="/admin/iptv/servers/edit/:id" component={ServersEditServer} />
      <Route path="/admin/iptv/servers/create" render={() => <ServersEditServer isCreate />} />
      <Route path="/admin/iptv/servers" exact>
        <Container>
          <Link to="/admin/iptv/servers/create">
            <Text>
              Создать Сервер
            </Text>
          </Link>
          <ServersTable />
        </Container>
      </Route>
      <Redirect to="/admin/iptv/servers" />
    </Switch>
  </ServersContextProvider>
);

export default ServersContainer;

const Container = styled.div``;

const Text = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;

  cursor: pointer;

  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  color: ${secondary};
  letter-spacing: 0.16px;
  line-height: 20px;
  text-transform: capitalize;
`;
