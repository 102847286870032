/* eslint-disable import/prefer-default-export,import/no-unresolved */
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  BarChart,
  Bar,
} from 'recharts';
import Loader from '@/common/components/Loader';
import { borderSecondary, text01 } from '@/common/constants/theme';
import CustomTooltip from './CustomTooltip';

const locales = { ru, en };

const CustomizedLabel = ({
  x, y, index, value, statisticArray,
}) => {
  if (value === +statisticArray[index].amount) {
    return (
      <Amount x={x} y={y} dy={-4}>
        {`$${statisticArray[index].amount}`}
      </Amount>
    );
  }
  return null;
};

const timeStringToTime = (t) => {
  const mask = /(\d{1,2})[^\d](\d{1,2})[^\d](\d{2,4})/;
  if (mask.test(t)) {
    const M = t.match(mask).map((v) => parseInt(v, 10));
    return new Date(M[3], M[1] - 1, M[2]);
  }
  return null;
};

const locale = localStorage.getItem('locale');

const formatLabel = (lbl) => {
  const dt = timeStringToTime(lbl);
  return dt
    ? format(dt, 'd MMM yyyy', { locale: locales[locale] })
    : '-';
};

export const LineChartWrapper = ({
  statisticArray, fields,
}) => {
  const [width, setWidth] = useState(null);
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setWidth(node.offsetWidth);
    }
  }, []);
  return (
    <ChartWrapper
      ref={measuredRef}
      key="LineChartWrapper"
      id="LineChartWrapper"
    >
      {width ? (
        <LineChart
          width={statisticArray.length > 13
            ? statisticArray.length * 100
            : width}
          height={250}
          data={statisticArray}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickLine={false}
            padding={{ left: 20, right: 20 }}
            tickMargin={10}
            tickFormatter={formatLabel}
          />
          <YAxis tickLine={false} />
          <Tooltip
            content={
              <CustomTooltip key="LineChartTooltip" fields={fields} />
              }
            cursor={{ fill: borderSecondary, opacity: 0.6 }}
            labelFormatter={formatLabel}
          />
          {fields.map((field) => (
            <Line
              key={`${field.key}--LineChartLine`}
              type="monotone"
              dataKey={field.key}
              stroke={field.color}
            />
          ))}
        </LineChart>
      ) : (
        <Loader />
      )}
    </ChartWrapper>
  );
};

export const BarChartWrapper = ({
  statisticArray, fields, usedFieldsKeys,
}) => {
  const [width, setWidth] = useState(null);
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setWidth(node.offsetWidth);
    }
  }, []);
  return (
    <ChartWrapper
      ref={measuredRef}
      key="BarChartWrapper"
      id="BarChartWrapper"
    >
      {((statisticArray?.length || 0) > 0)
        && ((fields?.length || 0) > 0)
        && width ? (
          <BarChart
            width={statisticArray.length > 13
              ? statisticArray.length * 100
              : width}
            height={250}
            barSize={60}
            data={statisticArray}
          >
            <CartesianGrid
              strokeDasharray="8"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={formatLabel}
            />
            <YAxis hide />
            <Tooltip
              content={<CustomTooltip key="BarChartToolTip" fields={fields.filter((f) => (usedFieldsKeys || fields.map((mf) => mf.key)).includes(f.key))} sign="$" labelFormatter={formatLabel} />}
              cursor={{ fill: borderSecondary, opacity: 0.6 }}
              labelFormatter={formatLabel}
            />
            {fields.map((field) => (
              <Bar
                key={`${field.key}--BarChartBar`}
                dataKey={field.key}
                fill={field.color}
                stackId="amount"
                label={<CustomizedLabel statisticArray={statisticArray} />}
              />
            ))}
          </BarChart>
        ) : (
          <Loader />
        )}
    </ChartWrapper>
  );
};

export const SimpleBarChartWrapper = ({
  statisticArray, fields,
}) => {
  const [width, setWidth] = useState(null);
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setWidth(node.offsetWidth);
    }
  }, []);

  return (
    <ChartWrapper
      ref={measuredRef}
      key="SimpleBarChartWrapper"
    >
      {width ? (
        <BarChart
          width={statisticArray.length > 5
            ? statisticArray.length * 300
            : width}
          height={250}
          barSize={60}
          data={statisticArray}
        >
          <CartesianGrid
            strokeDasharray="8"
            vertical={false}
          />
          <XAxis
            dataKey="date"
            tickFormatter={formatLabel}
          />
          <YAxis tickLine={false} />
          <Tooltip
            content={<CustomTooltip key="SimpleBarChartToolTip" fields={fields} />}
            cursor={{ fill: borderSecondary, opacity: 0.6 }}
            labelFormatter={formatLabel}
          />
          {fields.map((field) => (
            <Bar
              key={`${field.key}--SimpleBarChartBar`}
              dataKey={field.title}
              fill={field.color}
              label={<CustomizedLabel statisticArray={statisticArray} />}
            />
          ))}
        </BarChart>
      ) : (
        <Loader />
      )}
    </ChartWrapper>
  );
};

const ChartWrapper = styled.div`
  height: 300px;
  margin-top: 20px;
  padding-top: 35px;
  max-width: calc(100% - 20px);
  overflow: auto;
`;

const Amount = styled.text`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.16px;
  color: ${text01};
`;
