import RequestService from '../../request.service';
import urls from '../../routes/admin/templateDecors.api';

export const getTemplateDecorsListAction = (filter) => RequestService(
  urls.tdList,
  { method: 'GET', params: filter },
);

export const getTemplateDecorsItemAction = (id) => RequestService(urls.tdItem(id));
export const addTemplateDecorsItemAction = (data) => RequestService(
  urls.tdList,
  { method: 'POST', body: data },
);
export const updateTemplateDecorsItemAction = (id, data) => RequestService(
  urls.tdItem(id),
  { method: 'PATCH', body: data },
);
export const removeTemplateDecorsItemAction = (id) => RequestService(
  urls.tdItem(id), { method: 'DELETE' },
);

export const uploadImageAction = (file, Qid, tmpQid) => {
  const formData = new FormData();
  formData.append('image', file);
  if (!tmpQid) {
    formData.append('email_template_content', Qid);
  } else {
    formData.append('tmp_email_template_content_id', tmpQid);
  }
  return RequestService(urls.image(), {
    method: 'POST',
    body: formData,
  }, false);
};

export const deleteImageAction = (id) => RequestService(
  urls.image(id), { method: 'DELETE' },
);
