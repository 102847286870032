import React from 'react';
import styled from 'styled-components';
import { background4, text03 } from '@/common/constants/theme';
import { useTranslation } from 'react-i18next';

import Loader from '@/common/components/Loader';

import { usePlayerContext } from './PlayerContext';

export default () => {
  const {
    selectedProgram,
    activeChannel,
    channelUrl,
    isChannelBusy,
  } = usePlayerContext();

  const { t } = useTranslation('translations');

  return (
    <Container key={channelUrl || 'noUrl'}>
      {isChannelBusy && <Loader />}
      {!isChannelBusy
        && activeChannel?.id && channelUrl ? (
          <Player
            src={channelUrl}
            title="player"
            allowFullScreen
          />
        ) : (
          <PlayerStub>
            <PlayerStubInner>
              <PlayerStubMessageContainer>
                <PlayerStubMessageText>
                  {!selectedProgram ? t('player.noProgramSelected') : t('player.noData')}
                </PlayerStubMessageText>
              </PlayerStubMessageContainer>
            </PlayerStubInner>
          </PlayerStub>
        )}
    </Container>
  );
};

const Container = styled.div`
  position: relative
`;

const Player = styled.iframe`
  border-radius: 5px;
  border: none;
  width: 1000px;
  height: 564px;
  @media (min-width: 1198px) and  (max-width: 1360px){
    width: 850px;
    height: 480px;
  }
`;

const PlayerStub = styled.div`
  border-radius: 5px;
  border: none;
  width: 1000px;
  height: 564px;

  background: ${background4};

  @media (min-width: 1198px) and  (max-width: 1360px){
    width: 850px;
    height: 480px;
  }
`;

const PlayerStubInner = styled.div`
  width: 100%;
  height: 100%;
`;

const PlayerStubMessageContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayerStubMessageText = styled.div`
  display: flex;
  width: 60%;
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 2rem;
  color: ${text03};
`;
