import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { secondary, text01, white } from '@/common/constants/theme';

import {
  getSnackContent,
  loadWithdrawalSystems, loadWithdrawalWallets,
  setMinistra,
  setSnack,
} from '@/store/user/selectors';
import { getMinistraAction } from '@/services/actions/server.actions';

import PageContainer from '../../common/components/PageContainer';
import InfoBlock from './components/InfoBlock';
import PageNavigation from './components/PageNavigation';
import Promo from './components/Promo';
import DevicesFullInfoBlock from './components/DevicesFullInfo/DevicesFullInfoBlock';
import PlayerContainer from './components/PageContent/Player/PlayerContainer';
import SupportContainer from './components/PageContent/Support/SupportContainer';
import MainContent from './components/PageContent/MainContent';
import DeviceChoose from '@/screens/PersonalPageScreen/components/PageContent/DeviceChoose';
import SnackBarWindow from '@/common/components/SnackBarWindow';

import { SnackTypes } from '@/AppContext';
import { PersonalPageContextProvider, SHOW_RIGHT_PANEL } from './PersonalPageContext';

export default function PersonalPageContainer() {
  const { t } = useTranslation('translations');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadWithdrawalWallets());
    dispatch(loadWithdrawalSystems());

    getMinistraAction()
      .then((res) => {
        dispatch(setMinistra(res.results));
      });
  }, []);

  const snackBar = useSelector(getSnackContent);
  return (
    <PersonalPageContextProvider>
      <SnackBarWindow
        type={snackBar.type}
        isOpen={snackBar.status}
        onClose={() => dispatch(setSnack({}))}
        content={snackBar.content || t(snackBar.type === SnackTypes.error
          ? `personalPage.errors.${snackBar.message_type}`
          : `personalPage.${snackBar.message_type}`,
        snackBar.message_params)}
        key={snackBar.key}
      />
      <Container>
        <PageContainer>
          <PageTop>
            <PageHeader>
              <HeaderTitle>{t('pageTitle.textBlack')}</HeaderTitle>
              <HeaderTitleOrange>{t('pageTitle.textOrange')}</HeaderTitleOrange>
            </PageHeader>
            <InfoBlock />
          </PageTop>
        </PageContainer>
        <PageNavigation marginBottom="0" />
        <BackgroundOverlay>
          <PageContainer>
            <Switch>
              <Route exact path="/personal-page/player" component={PlayerContainer} />
              <Route exact path="/personal-page/support" component={SupportContainer} />
              <PageContent>
                <LeftContent>
                  {process.env.REACT_APP_DEV && <DeviceChoose />}
                  <MainContent />
                </LeftContent>
                {SHOW_RIGHT_PANEL && (
                <RightContent>
                  <Promo />
                  <DevicesFullInfoBlock />
                </RightContent>
                )}
              </PageContent>
            </Switch>
          </PageContainer>
        </BackgroundOverlay>
      </Container>
    </PersonalPageContextProvider>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${white};
  min-height: 82vh;
`;

const PageTop = styled.div`
  display: flex;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const HeaderTitle = styled.span`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
  color: ${text01};
  margin: 0 .5rem;
`;

const HeaderTitleOrange = styled(HeaderTitle)`
  color: ${secondary}
`;

const BackgroundOverlay = styled.div`
  background-color: #F3F5FF;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PageContent = styled.div`
  display: flex;
  margin-top: 1.25rem;
`;

const LeftContent = styled.div`
  flex: 1;
  width: 50rem;
`;

const RightContent = styled.div`
  width: 22rem;
  margin-bottom: 5rem;
`;
