export default {
  logs: '/api/v1/actions/user/logs/?limit=6',
  tzNotifications: '/api/v1/user/profile/',
  timezones: '/api/v1/user/timezone/',
  withdrawalSystems: '/api/v1/user/withdrawal/systems',
  withdrawalWallets: '/api/v1/user/profile/withdrawal-wallets',
  accounts: '/api/v1/billing/payment/system/list/',
  addAccount: '/api/billing/make-an-account/',
  confirmAccount: '/api/billing/confirm-an-account/',
  editAccount: '/api/billing/change-an-account/',
  confirmChangeAccount: '/api/billing/confirm-change-an-account/',
  deleteAccount: '/api/billing/delete-an-account/',
  changePassword: '/api/billing/change-password/',
  changeEmailRequestCurrent: '/api/v1/user/profile/change-email-request-current',
  changeEmailRequestNew: '/api/v1/user/profile/change-email-request-new',
  changeEmail: '/api/v1/user/profile/change-email',
  confirmEmail: '/api/billing/user-confirm-email/',
  deleteUserRequest: '/api/v1/user/profile/',
  deleteUser: '/api/v1/user/profile/delete-account',
  logoutFromAll: '/api/billing/logout-from-all-devices/',
  languageSwitch: '/api/v1/user/frontend-language/',
  unsubscribe: '/api/v1/user/profile/unsubscribe-mail-list',
};
