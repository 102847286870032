/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import auth from '@/services/auth.service';
import {
  validatePasswordAction,
  validateEmailAction,
} from '@/services/actions/authorization.actions';

import {
  getLogsAction,
  getTimezonesAction,
  addWithdrawalWalletAction,
  editWithdrawalWalletAction,
  deleteWithdrawalWalletAction,
  getAccountsAction,
  addAccountAction,
  confirmAccountAction,
  editAccountAction,
  confirmChangeAccountAction,
  deleteAccountAction,
  changeEmailRequestAction,
  resetPasswordAction,
  changePasswordAction,
  changeEmailAction,
  confirmChangeEmailAction,
  deleteUserRequestAction,
  confirmDeleteUserAction,
  logoutFromAllAction,
  handleTzNotificationsAction,
} from '@/services/actions/profile.actions';

import {
  loadUser,
  getStoreWithdrawalSystems, loadWithdrawalSystems,
  getStoreWithdrawalWallets, loadWithdrawalWallets,
} from '@/store/user/selectors';

import { useAppContext } from '@/AppContext';

export const ProfileContext = createContext({});

export const ProfileContextProvider = ({ children }) => {
  const { userInfo, setUserInfo } = useAppContext();

  const [logs, setLogs] = useState([]);
  const [withdrawalSystems, setWithdrawalSystems] = useState([]);
  const [wallets, setWithdrawalWallets] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLogsLoading, setIsLogsLoading] = useState(false);

  const dispatch = useDispatch();

  const wWalets = useSelector(getStoreWithdrawalWallets);
  const wSystems = useSelector(getStoreWithdrawalSystems);

  const getUser = () => {
    dispatch(loadUser());
  };

  const getWithdrawalSystems = () => {
    dispatch(loadWithdrawalSystems());
  };

  const getWithdrawalWallets = async () => {
    dispatch(loadWithdrawalWallets());
  };

  useEffect(() => {
    setWithdrawalWallets(wWalets);
  }, [wWalets]);

  useEffect(() => {
    setWithdrawalSystems((wSystems || []));
  }, [wSystems]);

  const getLogs = () => {
    setIsLogsLoading(true);
    getLogsAction()
      .then(({ results }) => setLogs(results))
      .finally(() => setIsLogsLoading(false));
  };

  const setTzNotifications = (tzNotifications) => {
    setUserInfo({
      ...userInfo,
      timezone: tzNotifications.timezone,
      is_balance_notifications: tzNotifications.is_balance_notifications,
      is_get_news_notifications: tzNotifications.is_get_news_notifications,
      is_notify_subscription_expiration: tzNotifications.is_notify_subscription_expiration,
      is_subscribe_mail_list: tzNotifications.is_subscribe_mail_list,
      is_system_events_notifications: tzNotifications.is_system_events_notifications,
      is_referral_notifications: tzNotifications.is_referral_notifications,
    });
    getLogs();
    return tzNotifications;
  };

  const handleBalanceNotifications = () => handleTzNotificationsAction(
    { is_balance_notifications: !userInfo.is_balance_notifications },
  )
    .then(setTzNotifications)
    .catch((err) => console.log(err));

  const handleNewsNotifications = () => handleTzNotificationsAction(
    { is_get_news_notifications: !userInfo.is_get_news_notifications },
  )
    .then(setTzNotifications)
    .catch((err) => console.log(err));

  const handleSubscriptionNotifications = () => handleTzNotificationsAction(
    { is_notify_subscription_expiration: !userInfo.is_notify_subscription_expiration },
  )
    .then(setTzNotifications)
    .catch((err) => console.log(err));

  const handleMailSubscription = () => handleTzNotificationsAction(
    { is_subscribe_mail_list: !userInfo.is_subscribe_mail_list },
  )
    .then(setTzNotifications)
    .catch((err) => console.log(err));

  const handleSystemEventsChanges = () => handleTzNotificationsAction(
    { is_system_events_notifications: !userInfo.is_system_events_notifications },
  )
    .then(setTzNotifications)
    .catch((err) => console.log(err));

  const handleReferralNotificationsChanges = () => handleTzNotificationsAction(
    { is_referral_notifications: !userInfo.is_referral_notifications },
  )
    .then(setTzNotifications)
    .catch((err) => console.log(err));

  const handleTimeZone = (tzValue) => handleTzNotificationsAction(
    { timezone: tzValue },
  )
    .then((res) => { getLogs(); return res; })
    .catch(() => getUser);

  const getTimezones = () => (
    getTimezonesAction()
      .then((res) => res)
  );

  const addWithdrawalWallet = (wsid, wwuid) => (
    addWithdrawalWalletAction(wsid, wwuid)
      .then((res) => { getWithdrawalWallets(); return res; })
      .catch((err) => { console.log(err); return err; })
  );

  const editWithdrawalWallet = (wwid, wwdata) => (
    editWithdrawalWalletAction(wwid, wwdata)
      .then((res) => { getWithdrawalWallets(); return res; })
      .catch((err) => { console.log(err); return err; })
  );

  const deleteWithdrawalWallet = (wwid) => (
    deleteWithdrawalWalletAction(wwid)
      .then((res) => { getWithdrawalWallets(); return res; })
      .catch((err) => { console.log(err); return err; })
  );

  const getAccounts = () => (
    getAccountsAction()
      .then((res) => {
        setAccounts(res.results);
        return res.results;
      })
  );

  const addAccount = (code, value) => (
    addAccountAction(code, value)
      .then((res) => {
        getAccounts();
        return res;
      })
      .catch((err) => console.log(err))
  );

  const confirmAccount = (account) => (
    confirmAccountAction(account)
      .then((res) => {
        getAccounts();
        return res;
      })
      .catch((err) => console.log(err))
  );

  const editAccount = (account, newAccount) => (
    editAccountAction(account, newAccount)
      .then((res) => {
        getAccounts();
        return res;
      })
      .catch((err) => console.log(err))
  );

  const confirmChangeAccount = (account) => (
    confirmChangeAccountAction(account)
      .then((res) => {
        getAccounts();
        return res;
      })
      .catch((err) => console.log(err))
  );

  const deleteAccount = (account) => (
    deleteAccountAction(account)
      .then((res) => {
        getAccounts();
        return res;
      })
      .catch((err) => console.log(err))
  );

  const changeEmailRequest = () => (
    changeEmailRequestAction()
      .catch((err) => console.log(err))
  );

  const resetPassword = (onEmail) => (
    resetPasswordAction(onEmail)
      .catch((err) => console.log(err))
  );

  const validatePassword = (password) => validatePasswordAction(password)
    .catch((err) => console.log(err));

  const changePassword = (password, confirmedPassword) => (
    changePasswordAction(password, confirmedPassword)
      .then((res) => { getLogs(); return res; })
      .catch((err) => console.log(err))
  );

  const validateEmail = (email) => validateEmailAction(email)
    .catch((err) => console.log(err));

  const changeEmail = (email) => (
    changeEmailAction(email)
      .then((res) => { getLogs(); return res; })
      .catch((err) => console.log(err))
  );

  const confirmChangeEmail = (token) => (
    confirmChangeEmailAction(token)
      .then((res) => {
        getUser();
        return res;
      })
      .catch((err) => console.log(err))
  );

  const deleteUserRequest = () => (
    deleteUserRequestAction()
      .catch((err) => console.log(err))
  );

  const deleteUser = (token) => {
    setLoading(true);
    return confirmDeleteUserAction(token)
      .then((res) => {
        if (res.success) {
          auth.clearToken();
        }
        setLoading(false);
        return res;
      })
      .catch((err) => console.log(err));
  };

  const logoutFromAll = () => (
    logoutFromAllAction()
      .then((res) => {
        if (res.user_id) {
          auth.clearToken();
          window.location.replace('/sign-in');
        }
        return res;
      })
      .catch((err) => console.log(err))
  );

  const value = {
    userInfo,
    getUser,
    handleBalanceNotifications,
    handleNewsNotifications,
    handleSubscriptionNotifications,
    handleMailSubscription,
    handleSystemEventsChanges,
    handleReferralNotificationsChanges,
    getLogs,
    logs,
    getTimezones,
    handleTimeZone,
    accounts,
    getAccounts,
    addAccount,
    confirmAccount,
    editAccount,
    confirmChangeAccount,
    deleteAccount,
    wallets,
    getWithdrawalWallets,
    addWithdrawalWallet,
    editWithdrawalWallet,
    deleteWithdrawalWallet,
    withdrawalSystems,
    getWithdrawalSystems,
    changeEmailRequest,
    resetPassword,
    validatePassword,
    changePassword,
    validateEmail,
    changeEmail,
    confirmChangeEmail,
    deleteUserRequest,
    deleteUser,
    logoutFromAll,
    isLoading,
    isLogsLoading,
    setWithdrawalWallets,
  };

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => useContext(ProfileContext);
