/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  text01,
  background01,
  borderSecondary,
  border,
  white,
} from '../../../../../../../../../../common/constants/theme';

import Checkbox from '../../../../../../../../../../common/components/Checkbox';
import Dropdown from '../../../../../../../../../../common/components/Dropdown';
import DisketteIcon from '../../../../../../../icons/DisketteIcon';
import DeleteIcon from '../../../../../../../icons/DeleteIcon';
import { useBaseContext } from '../../../BaseContext';
import DeleteModal from '../../../../../../../Modals/DeleteModal';
import AdminTableBody from '../../../../../../components/Table/components/TableBody';
import { useAdminContext } from '../../../../../../../../AdminContext';
import { tableSortableConfig } from '../../../../../../components/Table/Table';

const BaseTableBody = ({
  cellSizes,
  page,
  isCreateTab,
  setIsCreateTab,
  editedId,
  setEditedId,
  deletedId,
  setDeletedId,
  recordsCount,
}) => {
  const {
    handleHidden,
    handleArchive,
    createChannel,
    channels,
    handleDragEndChannels,
    deleteChannelById,
    handleSaveChannels,
  } = useBaseContext();
  const {
    groups,
    getPosition,
    getOffset,
    dropdownGroups,
    dropdownGroupsValue,
  } = useAdminContext();

  const initialCreateInputValues = {
    id: '',
    name: '',
    is_hidden: false,
    is_archived: true,
    group: dropdownGroups[1],
    disabled_countries: [],
    tvg: '',
    mode: '',
    sid: '',
    tsid: '',
    onid: '',
    ns: '',
  };

  const [inputValues, setInputValues] = useState({});
  const [createInputValues, setCreateInputValues] = useState({ ...initialCreateInputValues });
  const [createPosition, setCreatePosition] = useState(null);
  const [option, setOption] = useState({});

  useEffect(() => {
    if (editedId >= 0) {
      setInputValues({ ...channels[editedId] });
      setOption(dropdownGroups.find((opt) => opt.title === channels[editedId].group_name));
    } else {
      setInputValues({});
      setOption({});
    }
  }, [editedId]);

  useEffect(() => {
    if (dropdownGroups.length) {
      setCreateInputValues({ ...initialCreateInputValues });
    }
  }, [dropdownGroups]);

  const handleCreateInput = (field, value) => setCreateInputValues(
    (prevState) => ({ ...prevState, [field]: value }),
  );

  const handleEdit = (i) => {
    setEditedId(i);
    setInputValues(channels[i]);
  };

  const handleInput = (field, value) => setInputValues(
    (prevState) => ({ ...prevState, [field]: value }),
  );

  const renderInput = (field, i) => (editedId === i
    ? (
      <Input
        value={inputValues[field]}
        onChange={({ target }) => handleInput(field, target.value)}
        type="text"
      />
    )
    : (
      <Text>
        {channels[i][field]?.length > 14
          ? `${channels[i][field].slice(0, 14)}...`
          : channels[i][field]}
      </Text>
    )
  );

  const numberRegExp = /[0-9]/g;

  const handleCreatePosition = (event) => {
    let inputMatchedValue = [];
    inputMatchedValue = event.target.value.match(numberRegExp);
    if (inputMatchedValue) {
      setCreatePosition(Number(inputMatchedValue.join('')));
    } else {
      setCreatePosition(null);
    }
  };

  return (
    <Container>
      <DeleteModal
        isOpen={deletedId >= 0}
        onClose={() => setDeletedId(-1)}
        content={deletedId >= 0 && `позицию ${getPosition(page, deletedId)}`}
        handleDelete={() => deleteChannelById(channels[deletedId].id)}
      />
      {isCreateTab && (
        <Row create>
          <Wrapper width="80px">
            <Input
              type="number"
              value={createPosition >= 0 ? createPosition : ''}
              onChange={handleCreatePosition}
            />
          </Wrapper>
          <Wrapper width={cellSizes[0]}>
            <Input
              value={createInputValues.id}
              onChange={({ target }) => handleCreateInput('id', target.value)}
              type="text"
            />
          </Wrapper>
          <Wrapper width={cellSizes[1]}>
            <Input
              value={createInputValues.name}
              onChange={({ target }) => handleCreateInput('name', target.value)}
              type="text"
            />
          </Wrapper>
          <Wrapper width={cellSizes[2]}>
            <Checkbox
              checked={createInputValues.is_hidden}
              onChange={(checked) => handleCreateInput('is_hidden', checked)}
              type="secondary"
            />
          </Wrapper>
          <Wrapper width={cellSizes[3]}>
            <Checkbox
              checked={createInputValues.is_archived}
              onChange={(checked) => handleCreateInput('is_archived', checked)}
            />
          </Wrapper>
          <Wrapper width={cellSizes[4]}>
            <Dropdown
              options={dropdownGroups.slice(1)}
              value={createInputValues.group}
              onSelect={(opt) => handleCreateInput('group', opt)}
            />
          </Wrapper>
          <Wrapper width={cellSizes[5]}>
            <Input
              value={createInputValues.tvg_logo}
              onChange={({ target }) => handleCreateInput('tvg_logo', target.value)}
              type="text"
            />
          </Wrapper>
          <Wrapper width={cellSizes[6]}>
            <Input
              value={createInputValues.mode}
              onChange={({ target }) => handleCreateInput('mode', target.value)}
              type="text"
            />
          </Wrapper>
          <Wrapper width={cellSizes[7]}>
            <Input
              value={createInputValues.sid}
              onChange={({ target }) => handleCreateInput('sid', target.value)}
              type="text"
            />
          </Wrapper>
          <Wrapper width={cellSizes[8]}>
            <Input
              value={createInputValues.tsid}
              onChange={({ target }) => handleCreateInput('tsid', target.value)}
              type="text"
            />
          </Wrapper>
          <Wrapper width={cellSizes[9]}>
            <Input
              value={createInputValues.onid}
              onChange={({ target }) => handleCreateInput('onid', target.value)}
              type="text"
            />
          </Wrapper>
          <Wrapper width={cellSizes[10]}>
            <Input
              value={createInputValues.ns}
              onChange={({ target }) => handleCreateInput('ns', target.value)}
              type="text"
            />
          </Wrapper>
          <Wrapper width={cellSizes[11]} flex leftMargin={27}>
            <Icon
              top
              onClick={() => {
                createInputValues.group_id = groups.find(
                  (group) => group.name === createInputValues.group.title,
                ).id;
                createChannel(createInputValues, createPosition);
                setIsCreateTab(false);
                setCreateInputValues({ ...initialCreateInputValues });
              }}
            >
              <DisketteIcon />
            </Icon>
            <Icon onClick={() => {
              setIsCreateTab(false);
              setCreateInputValues({ ...initialCreateInputValues });
            }}
            >
              <DeleteIcon />
            </Icon>
          </Wrapper>
        </Row>
      )}
      <AdminTableBody
        recordsCount={recordsCount}
        cellSizes={cellSizes}
        onDoubleClick={(i) => handleEdit(i)}
        page={page}
        editedId={editedId}
        setEditedId={setEditedId}
        setDeletedId={setDeletedId}
        handleSave={(position) => {
          const currentValues = { ...inputValues };
          currentValues.group_id = groups.find((item) => item.name === option.title).id;
          currentValues.group_name = option.title;
          handleSaveChannels(editedId, currentValues, position);
        }}
        onSortEnd={({ oldIndex, newIndex }) => {
          handleDragEndChannels(oldIndex, newIndex);
        }}
        helperContainer={document.querySelector('.table-cloned-body-item')}
        {...tableSortableConfig}
        shouldCancelStart={() => (dropdownGroupsValue.key === 'all')}
        disableDragAndDrop={dropdownGroupsValue.key === 'all'}
      >
        {channels.length && channels.map((item, i) => (
          <Row key={item.id} onDoubleClick={() => handleEdit(i)}>
            {renderInput('id', i)}
            {renderInput(item.name ? 'name' : 'name_translit', i)}
            <Checkbox
              checked={item.is_hidden}
              onChange={(checked) => handleHidden(i, checked, getOffset(page))}
              type="secondary"
            />
            <Checkbox
              checked={item.is_archived}
              onChange={(checked) => handleArchive(i, checked, getOffset(page))}
            />
            {editedId === i
              ? (
                <Dropdown
                  options={dropdownGroups.slice(1)}
                  value={option}
                  onSelect={(opt) => setOption(opt)}
                />
              )
              : (
                <Text>
                  {item.group_name.length > 14
                    ? `${item.group_name.slice(0, 14)}...`
                    : item.group_name}
                </Text>
              )}
            {renderInput('tvg_logo', i)}
            {renderInput('mode', i)}
            {renderInput('sid', i)}
            {renderInput('tsid', i)}
            {renderInput('onid', i)}
            {renderInput('ns', i)}
          </Row>
        ))}
      </AdminTableBody>
    </Container>
  );
};

export default BaseTableBody;

const Container = styled.div``;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}

  width: ${({ width }) => width};

  &:not(:first-child) {
    margin-left: 30px;

    ${({ leftMargin }) => leftMargin && (`margin-left: ${leftMargin}px;`)}
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  height: 42px;
  padding-left: 23px;
  padding-right: 15px;

  &:nth-child(2n) {
    background: ${background01};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${borderSecondary};
  }

  ${({ create }) => create && (`background: ${borderSecondary};`)}
`;

const Text = styled.div`
  font-family: Gilroy;
  font-size: 13px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 16px;

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 13px;
  }
`;

const Icon = styled.div`
  cursor: pointer;

  position: relative;
  ${({ top }) => top && ('top: 2px;')}

  &:first-child {
    margin-right: 15px;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 25px;
  padding: 0 2px;

  background: ${white};
  border: 1px solid ${border};
  border-radius: 2px;
  outline: none;
`;
