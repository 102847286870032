import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDevices, switchDeviceSelected } from '@/store/user/selectors';

export default function DeviceChoose() {
  const devices = useSelector(getAllDevices);
  const dispatch = useDispatch();

  if (!devices.length) return (<div />);
  return (
    <div>
      {devices.map((device) => (
        <span key={`PP-DS-dev--${device.id}`}>
          <input type="checkbox" onClick={() => dispatch(switchDeviceSelected(device))} checked={device.checked} />
          {device.name}
        </span>
      ))}
    </div>
  );
}
