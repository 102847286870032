/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  text01,
  background01,
  text02,
  borderSecondary,
} from '@/common/constants/theme';

const TableBody = ({
  children,
  rowHeight,
  customHeight,
  minRows,
  dataLength,
  cellSizes,
  cellMargin,
  rowPadding,
  textAlign,
  adaptiveSize,
  limit,
  onDoubleClick,
  onClick,
  isBottomRowBorder,
  autoRowHeight = false,
}) => {
  const { t } = useTranslation('translations');

  return (
    <Container className="table-rows">
      <MainWrapper
        height={minRows === 'auto' ? minRows : minRows && rowHeight * minRows}
        customHeight={customHeight}
      >
        {dataLength > 0 ? (
          <>
            {React.Children.map(children, (element, i) => (
              <Wrapper
                height={rowHeight}
                limit={limit}
                border={isBottomRowBorder}
                className={`table-rows__row ${autoRowHeight ? 'auto' : 'fixed'}`}
                onDoubleClick={() => onDoubleClick && onDoubleClick(i)}
                onClick={(event) => onClick && onClick(event, i)}
                autoHeight={autoRowHeight}
                rowPadding={rowPadding}
              >
                {React.Children.map(element.props.children, (item, index) => (
                  <Item
                    width={cellSizes && cellSizes[index]}
                    className="table-rows__row__item"
                    textAlign={textAlign}
                    adaptive={adaptiveSize}
                    cellMargin={cellMargin}
                  >
                    {item}
                  </Item>
                ))}
              </Wrapper>
            ))}
          </>
        ) : (
          <NoData>
            {t('table.noResults')}
          </NoData>
        )}
      </MainWrapper>
    </Container>
  );
};
export default TableBody;

const Container = styled.div`
  margin-top: -5px;
  padding-top: 10px;
`;

const MainWrapper = styled.div`
  height: ${({ height }) => (height ? `calc(${height}px + 10px);` : '500px;')};
  height: ${({ customHeight }) => (customHeight)};

  overflow-y: auto;
`;

const Wrapper = styled.div`
  display: flex;

  ${({ height, autoHeight }) => (autoHeight ? (`
    min-height: ${height ? `${height}px;` : '50px'};
    height: auto;
  `) : (`
    height: ${height ? `${height}px;` : '50px'};
  `))}
  padding-left: ${({ rowPadding }) => (rowPadding || 23)}px;
  padding-right: ${({ rowPadding }) => (rowPadding || 20)}px;

  ${({ border }) => border && (`border-bottom: 1px solid ${borderSecondary};`)}

  &:nth-child(2n) {
    background-color: ${background01};
  }

  &:last-child {
    ${({ height, autoHeight }) => (autoHeight ? (`
    min-height: ${height ? `${height}px;` : '50px'};
    height: auto;
  `) : (`
    height: ${height ? `${height + 10}px;` : '50px'};
  `))}
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign }) => (textAlign
    === 'left' ? 'flex-start' : textAlign === 'right'
      ? 'flex-end' : 'center')};

  ${({ width }) => width && (`width: ${width};`)};

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;

  &:not(:first-child) {
    margin-left: ${({ cellMargin }) => (cellMargin || 30)}px;
  }

  @media (max-width: 1360px) {
    ${({ width, adaptive }) => width && (`width: calc(${width} - ${adaptive || 10}px);`)};

    font-size: 11px;
  }

  @media (max-width: 1198px) {
    ${({ width }) => width && (`width: ${width};`)};

    font-size: 13px;
  }
`;

const NoData = styled.div`
  margin-top: 100px;

  font-family: Gilroy, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${text02};
  text-align: center;
  line-height: 15px;
  letter-spacing: 0.16px;
`;
