import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import styled from 'styled-components';

import { BASE_PATH } from './FaqContext';

import FAQList from './Components/FAQList';
import FAQItem from './Components/FAQItem';

const FAQ = () => (
  <Container>
    <Switch>
      <Route exact path={BASE_PATH}>
        <FAQList />
      </Route>
      <Route exact path={`${BASE_PATH}:id`}>
        <FAQItem />
      </Route>
      <Redirect to={BASE_PATH} />
    </Switch>
  </Container>
);

export default FAQ;

const Container = styled.div``;
