import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { BASE_PATH, PaymentContextProvider } from './PaymentContext';

const PaymentContainer = () => (
  <Switch>
    <Route exact path={`${BASE_PATH}:type`}>
      <PaymentContextProvider />
    </Route>
    <Redirect to="/" />
  </Switch>
);

export default PaymentContainer;
