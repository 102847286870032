import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  secondary,
  text01,
} from '@/common/constants/theme';

import StepsIndicator from '../../StepsIndicator';
import WelcomeBlock from '../../WelcomeBlock';
import DevicesTable from './DevicesTable/DevicesTable';
import DevicesSelected from '../DevicesSelected';
import OrderSubscriptionDialog from '../components/OrderSubscriptionDialog';
import PageContentBlock from '../components/PageContentBlock';

import { useAppContext } from '@/AppContext';
import { usePersonalPageContext } from '../../../PersonalPageContext';

import { subscribeTypes } from '@/store/user/selectors';

const Devices = () => {
  const { t } = useTranslation('translations');

  const { updateUserInfo } = useAppContext();
  const {
    equalizeSubscription,
    processBuySubscribtionResult,
    updateDeviceParameters,
    allDevices,
  } = usePersonalPageContext();

  const [eqSubscriptionData, setEqSubscriptionData] = useState(null);
  const [subscriptionDone, setSubscriptionDone] = useState(false);
  const [subscriptionResultSum, setSubscriptionResultSum] = useState(0);

  const processEqResult = (eqData) => {
    setEqSubscriptionData(processBuySubscribtionResult(eqData));
  };

  const onSubmitEqSubscription = () => {
    if (eqSubscriptionData.status === 'error') {
      setEqSubscriptionData(null);
      return;
    }
    equalizeSubscription(false)
      .then((eqRes) => {
        setEqSubscriptionData(null);
        setSubscriptionDone(true);
        setSubscriptionResultSum(eqRes?.amount || 0);

        (eqRes?.items || []).forEach((itm) => {
          const usedDevice = allDevices.find((d) => d.id === itm.device_id);

          const updateParams = {
            subscription: {
              ...(usedDevice?.subscription || {
                id: 0,
                days_amount: 0,
                is_active: true,
                is_future: false,
                is_premium: itm.is_premium,
                is_premium_last: itm.is_premium,
                is_test: false,
              }),
              ...{
                date_start: itm.date_start,
                date_end: itm.date_end,
                // is_premium: itm.is_premium,
                auto_renew: itm.auto_renew,
              },
            },
            subscription_type: usedDevice?.subscription
              ? (usedDevice?.subscription_type || subscribeTypes.Active)
              : subscribeTypes.Active,
          };

          updateDeviceParameters(itm.device_id, updateParams);
          updateUserInfo({ balance: eqRes?.balance_amount || 0 });
        });
      })
      .catch(processEqResult);
  };

  const openEqSubscribeDialog = () => {
    equalizeSubscription()
      .then(processEqResult)
      .catch(processEqResult);
  };

  const openUnpairDialog = () => {

  };

  const handleCloseSuccessEqSubscription = () => {
    setSubscriptionDone(false);
    setSubscriptionResultSum(0);
  };

  return (
    <>
      <StepsIndicator />
      <WelcomeBlock />
      <Container>
        <PageContentBlock>
          <Title>
            {t('devicesContent.title')}
          </Title>
          <SubTitle>
            {t('devicesContent.subTitle')}
          </SubTitle>
          <DevicesTable
            openEqSubscribeDialog={openEqSubscribeDialog}
            openUnpairDialog={openUnpairDialog}
          />
        </PageContentBlock>
        <SelectedDevicesContainer>
          <DevicesSelected />
        </SelectedDevicesContainer>
        <OrderSubscriptionDialog
          data={eqSubscriptionData}
          setData={setEqSubscriptionData}
          onCloseSuccessWindow={handleCloseSuccessEqSubscription}
          onSubmit={onSubmitEqSubscription}
          isSubscriptionDone={subscriptionDone}
          subscriptionResultSum={subscriptionResultSum}
        />
      </Container>
    </>
  );
};

export default Devices;

const Container = styled.div``;

const SelectedDevicesContainer = styled.div`
  margin-bottom: 126px;
`;

const SubTitle = styled.div`
  font-family: Gilroy, sans-serif;
  margin-bottom: 22px;
  font-size: 15px;
  line-height: 20px;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.6;
  color: ${text01};
  position: relative;
  margin-bottom: 33px;

  &:after {
    content: '';
    height: 4px;
    position: absolute;
    width: 30px;
    bottom: -10px;
    left: 0;
    background: ${secondary};
  }
`;
