import React from 'react';
import styled from 'styled-components';
import { secondary, borderSecondary } from '@/common/constants/theme';
import { useHistory } from 'react-router-dom';
import NewsTable from './components/NewsTable';

const Base = () => {
  const history = useHistory();
  return (
    <Container>
      <Actions>
        <Button
          color={secondary}
          onClick={() => history.push('/admin/public/news/new')}
        >
          Добавить новость
        </Button>
      </Actions>
      <NewsTable />
    </Container>

  );
};

export default Base;

const Container = styled.div``;

const Actions = styled.div`
  display: flex;
`;

const Button = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin: 10px 0;
  padding: 0 20px;
  color: ${({ color }) => color};
  cursor: pointer;
  border-right: 1px solid ${borderSecondary};
  :last-of-type {
    border: none;
  }
`;
