/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import RequestService from '../request.service';
import RequestService2 from '../request2.service';

import urls from '../routes/authorization.api';
import urlsProfile from '../routes/profile.api';

export const getCountriesAction = () => RequestService(urls.countries);

export const getUserMetaAction = () => RequestService(urls.userMeta);

export const validateEmailAction = (email) => (new Promise((resolve, reject) => (
  RequestService2(urls.emailValidation, {
    method: 'POST',
    body: { email },
  })
    .then(resolve)
    .catch(reject)
))
);

export const validatePasswordAction = (password) => (
  RequestService(urls.passwordValidation, {
    method: 'POST',
    body: { password },
  })
);

export const validateReferralTokenAction = (token, url = null) => (
  RequestService(urls.referralTokenValidation, {
    method: 'POST',
    body: {
      referral_token: token,
      referer_url: url,
    },
  })
);

export const validatePasswordChangeToken = (uid, token) => RequestService(
  urls.validateChangePasswordToken, { method: 'POST', body: { uid, token } },
);

// eslint-disable-next-line max-len
export const signUpAction = (data) => new Promise((resolve, reject) => (
  RequestService2(urls.signUp, { method: 'POST', body: data })
    .then(resolve)
    .catch(reject)
));

export const confirmEmailAction = (token) => (new Promise((resolve, reject) => (
  RequestService2(urls.confirmEmail(token), {}, true, true)
    .then(resolve)
    .catch(reject)
))
);

export const resendEmailAction = (email) => (new Promise((resolve, reject) => (
  RequestService2(urls.resendEmail, {
    method: 'POST',
    body: { email },
  })
    .then(resolve)
    .catch(reject)
))
);

export const forgetPasswordAction = (data) => (
  new Promise((resolve, reject) => (
    RequestService2(
      urls.forgetPassword,
      { method: 'POST', body: data },
    )
      .then(resolve)
      .catch(reject)
  ))
);

export const changePasswordAction = (uid, token, password, confirmedPassword) => (
  new Promise((resolve, reject) => (
    RequestService2(urls.changePassword(uid, token), {
      method: 'POST',
      body: {
        new_password1: password,
        new_password2: confirmedPassword,
        uid,
        token,
      },
    })
      .then(resolve)
      .catch(reject)
  ))
);

// eslint-disable-next-line max-len
export const changeEmailRequestAction = (uid, token, newEmail) => RequestService(urlsProfile.changeEmailRequestNew, {
  method: 'POST',
  body: {
    email: newEmail,
    uid,
    token,
  },
});

export const confirmNewEmailAction = (token) => RequestService(urlsProfile.changeEmail, {
  method: 'POST',
  body: {
    token,
  },
});

export const signInAction = (data) => new Promise((resolve, reject) => (
  RequestService2(urls.signIn, {
    method: 'POST',
    body: data,
  })
    .then(resolve)
    .catch(reject)
));

export const getUserAction = async () => new Promise(
  (resolve, reject) => RequestService2(urls.user)
    .then(resolve).catch(reject),
);

export const enterEmailAction = (type, clientId, email) => (
  RequestService(urls.enterEmail, {
    method: 'POST',
    body: {
      type,
      client_id: clientId,
      email,
    },
  })
);

export const signOutAction = () => RequestService(urls.signOut,
  { method: 'POST' });

export const refreshTokenAction = () => RequestService(urls.tokenRefresh,
  { method: 'POST' });
