import React from 'react';

export default () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#tabletIconClip0)">
      <path d="M47.8129 0H12.187C9.35083 0 7.04346 2.30738 7.04346 5.14353V8.23675C7.04346 8.72206 7.4369 9.1155 7.92221 9.1155C8.40752 9.1155 8.80097 8.72206 8.80097 8.23675V5.14353C8.80097 3.27647 10.3199 1.75751 12.187 1.75751H47.8129C49.68 1.75751 51.1989 3.27647 51.1989 5.14353V54.8565C51.1989 56.7235 49.68 58.2425 47.8129 58.2425H12.187C10.3199 58.2425 8.80097 56.7235 8.80097 54.8565V11.7518C8.80097 11.2665 8.40752 10.873 7.92221 10.873C7.4369 10.873 7.04346 11.2665 7.04346 11.7518V54.8565C7.04346 57.6926 9.35083 60 12.187 60H47.8129C50.649 60 52.9564 57.6926 52.9564 54.8565V5.14353C52.9564 2.30738 50.649 0 47.8129 0Z" fill="url(#paint0_linear)" />
      <path d="M10.5425 7.19858V40.4012C10.5425 40.8865 10.9359 41.28 11.4212 41.28C11.9065 41.28 12.3 40.8865 12.3 40.4012V8.07734H47.6998V20.1285C47.6998 20.6138 48.0932 21.0072 48.5785 21.0072C49.0638 21.0072 49.4573 20.6138 49.4573 20.1285V7.19858C49.4573 6.71327 49.0638 6.31982 48.5785 6.31982H11.4212C10.9358 6.31982 10.5425 6.71327 10.5425 7.19858Z" fill="url(#paint1_linear)" />
      <path d="M10.5425 50.6187C10.5425 51.104 10.9359 51.4975 11.4212 51.4975H48.5785C49.0638 51.4975 49.4573 51.104 49.4573 50.6187V23.6435C49.4573 23.1582 49.0638 22.7648 48.5785 22.7648C48.0932 22.7648 47.6998 23.1582 47.6998 23.6435V49.74H12.3V43.9164C12.3 43.4311 11.9065 43.0376 11.4212 43.0376C10.9359 43.0376 10.5425 43.4311 10.5425 43.9164V50.6187Z" fill="url(#paint2_linear)" />
      <path d="M27.3228 54.662C27.3228 56.1381 28.5236 57.339 29.9998 57.339C31.476 57.339 32.6768 56.1381 32.6768 54.662C32.6768 53.1859 31.4759 51.9849 29.9998 51.9849C28.5236 51.9849 27.3228 53.1859 27.3228 54.662ZM30.9193 54.662C30.9193 55.1691 30.5068 55.5815 29.9998 55.5815C29.4927 55.5815 29.0803 55.1691 29.0803 54.662C29.0803 54.155 29.4927 53.7424 29.9998 53.7424C30.5068 53.7424 30.9193 54.155 30.9193 54.662Z" fill="url(#paint3_linear)" />
      <path d="M33.7966 4.89948C34.2819 4.89948 34.6754 4.50603 34.6754 4.02072C34.6754 3.53542 34.2819 3.14197 33.7966 3.14197H29.7914C29.306 3.14197 28.9126 3.53542 28.9126 4.02072C28.9126 4.50603 29.306 4.89948 29.7914 4.89948H33.7966Z" fill="url(#paint4_linear)" />
      <path d="M26.4373 4.89576C26.9227 4.89576 27.3161 4.50232 27.3161 4.017C27.3161 3.53168 26.9227 3.13824 26.4373 3.13824C25.952 3.13824 25.5586 3.53168 25.5586 4.017C25.5586 4.50232 25.952 4.89576 26.4373 4.89576Z" fill="url(#paint5_linear)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear" x1="52.9564" y1="29.9584" x2="7.04346" y2="29.9584" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="49.4573" y1="23.7757" x2="10.5425" y2="23.7757" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="49.4573" y1="37.1112" x2="10.5425" y2="37.1112" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="32.6768" y1="54.6582" x2="27.3228" y2="54.6582" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint4_linear" x1="34.6754" y1="4.0195" x2="28.9126" y2="4.0195" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint5_linear" x1="27.3161" y1="4.01578" x2="25.5586" y2="4.01578" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <clipPath id="tabletIconClip0">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
