import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { primary, secondary, text01 } from '@/common/constants/theme';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '@/common/components/CustomAccordion';
import { useBalanceContext } from '@/screens/PersonalPageScreen/components/PageContent/Balance/BalanceContext';
import BalanceModals from '@/screens/PersonalPageScreen/components/PageContent/Balance/components/BalanceModals';
import { SnackTypes, useAppContext } from '@/AppContext';
import { usePersonalPageContext } from '@/screens/PersonalPageScreen/PersonalPageContext';
import { useDispatch } from 'react-redux';
import { loadUser } from '@/store/user/selectors';
import { TYPE_FAIL, TYPE_SUCCESS } from '@/screens/PersonalPageScreen/PaymentPage/PaymentContext';
import Input from '@/common/components/Input';
import Checkbox from '@/common/components/Checkbox';
import Button from '@/common/components/Button';
import { useHistory } from 'react-router-dom';

const infoIcon = require('../../../../../assets/icons/info.svg');

const payPal = require('../../../../../assets/icons/PayPal.svg');
const enot = require('../../../../../assets/icons/enot.svg');
const freeKassa = require('../../../../../assets/icons/free-kassa.svg');

const icons = {
  paypal: payPal,
  enot,
  freekassa: freeKassa,
};

const Balance = ({
  setIsFinalModal,
  inputValue,
  setInputValue,
  bonus,
  setBonus,
}) => {
  const history = useHistory();
  const { t } = useTranslation('translations');

  const { setUserInfo } = useAppContext();
  const { setUserSnack } = usePersonalPageContext();
  const {
    paymentSystems,
    getPaymentSystems,
    handleAddBalance,
    paymentResult,
    getPaymentAmountBonus,
  } = useBalanceContext();

  const [isChecked, setIsChecked] = useState(false);
  const [selectedPayments, setPayments] = useState({ id: 0, name: '' });
  let inputMatchedValue = [];
  const [selectedPaySystemId, setSelectedPaySystemId] = useState('');
  const [selectedBalanceModal, setSelectedBalanceModal] = useState(null);
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);

  const dispatch = useDispatch();

  const modals = [
    {
      modalTitle: 'paypal',
      title: t('balance.modal.paypal.title'),
      subTitle: t('balance.modal.paypal.subTitle'),
      // warning: t('balance.modal.paypal.warning'),
      isReplenishment: true,
      isNotAvailable: true,
      // contacts: {
      //   title: t('balance.modal.paypal.contacts.title'),
      //   contacts: [
      //     {
      //       descr: t('balance.modal.paypal.contacts.mail'),
      //       icon: EnvelopeIcon,
      //       link: 'mailto:171santa@mail.ru',
      //     },
      //     {
      //       descr: t('balance.modal.paypal.contacts.skype'),
      //       icon: SkypeIcon,
      //       link: 'https://join.skype.com/invite/nZgPSu3Img4q',
      //     },
      //     {
      //       descr: t('balance.modal.paypal.contacts.telegram'),
      //       icon: TelegramIcon,
      //       link: 'https://t.me/santa171',
      //     },
      //   ],
      // },
    },
    {
      modalTitle: 'freekassa',
      title: t('balance.modal.freekassa.title'),
      subTitle: t('balance.modal.freekassa.subTitle'),
      warning: t('balance.modal.freekassa.warning'),
      isReplenishment: true,
      prompt: {
        title: t('balance.modal.freekassa.prompt.title'),
        warning: t('balance.modal.freekassa.prompt.warning'),
      },
    },
    {
      modalTitle: 'sprypay',
      title: t('balance.modal.sprypay.title'),
      subTitle: t('balance.modal.sprypay.subTitle'),
      warning: t('balance.modal.sprypay.warning'),
      isReplenishment: true,
      prompt: {
        title: t('balance.modal.sprypay.prompt.title'),
        warning: t('balance.modal.sprypay.prompt.warning'),
      },
    },
    {
      modalTitle: 'enot',
      title: t('balance.modal.enot.title'),
      subTitle: t('balance.modal.enot.subTitle'),
      warning: t('balance.modal.enot.warning'),
      isReplenishment: true,
      prompt: {
        title: t('balance.modal.enot.prompt.title'),
        warning: t('balance.modal.enot.prompt.warning'),
      },
    },
  ];

  const getSelectedModal = (psId) => modals.find(
    (m) => m.modalTitle === paymentSystems
      .find((ps) => ps.id === psId)?.title,
  ) || null;

  const handleSelectedPaySystem = (id) => {
    setSelectedPaySystemId(id);
  };

  const openBalanceModal = () => {
    const selectedPsModal = getSelectedModal(selectedPaySystemId);
    if (selectedPsModal) {
      handleAddBalance({
        isAgreement: isChecked,
        paymentSystemId: selectedPaySystemId,
        count: inputValue,
        isTest: true,
      }).then((res) => {
        if (res) {
          setBonus({
            value: res?.bonus || 0,
            type: res?.bonus_type || 'undefined',
          });
          setSelectedBalanceModal(selectedPsModal);
          setIsBalanceModalOpen(true);
        }
      });
    }
  };

  const checkIsDisable = () => !inputValue || !isChecked || selectedPayments.name === 'paypal';

  // const onChangeCheckbox = (value) => {
  //   setIsChecked(value);
  // };

  const numberRegExp = /[^0-9.,]/g;

  const inputHandler = (value) => {
    inputMatchedValue = value
      .replace(numberRegExp, '')
      .replace(',', '.');

    const dotParts = inputMatchedValue.split('.');
    if (dotParts.length > 2) {
      inputMatchedValue = `${dotParts.slice(0, 2).join('.')}${dotParts.slice(2).join('')}`;
    }

    if (inputMatchedValue) {
      setInputValue(inputMatchedValue);
    } else {
      setInputValue(0);
    }
  };

  const nextBtnHandler = () => {
    if (!checkIsDisable()) {
      openBalanceModal();
    }
  };

  const onModalClose = () => {
    setIsBalanceModalOpen(false);
  };

  const handleSubmit = () => handleAddBalance({
    isAgreement: isChecked,
    paymentSystemId: selectedPaySystemId,
    count: inputValue,
    isTest: false,
  })
    .then((res) => {
      setInputValue(res.amount);
      setBonus({
        value: res?.bonus || 0,
        type: res?.bonus_type || 'undefined',
      });
      dispatch(loadUser()).then((updUInfo) => {
        setUserInfo(updUInfo);
        setIsFinalModal(true);
      });
      setIsBalanceModalOpen(false);
      setIsChecked(false);
      setSelectedPaySystemId(paymentSystems[0].title);
    })
    .catch((err) => {
      setUserSnack({
        status: 400,
        type: SnackTypes.error,
        message_type: `balance.${err?.data?.message_type || 'unknown'}`,
        message_params: err?.message_params || {},
      });
    });

  useEffect(() => {
    setSelectedBalanceModal(getSelectedModal(selectedPaySystemId));
  }, [selectedPaySystemId]);

  useEffect(() => {
    if (paymentSystems?.length) {
      setSelectedPaySystemId(paymentSystems[0].id);
    } else {
      setInputValue(0);
      getPaymentSystems();
    }
  }, [paymentSystems]);

  useEffect(() => {
    switch (paymentResult?.type) {
      case TYPE_SUCCESS:
        if ((paymentResult?.ptId || 0) > 0) {
          getPaymentAmountBonus(paymentResult.ptId).then((pAB) => {
            setInputValue(pAB.amount);
            setBonus(pAB.bonus);
            setIsFinalModal(true);
          });
        } else {
          setUserSnack({
            status: 400,
            type: SnackTypes.error,
            message_type: 'balance.payment_error',
            message_params: {},
          });
        }
        break;
      case TYPE_FAIL:
        setUserSnack({
          status: 400,
          type: SnackTypes.error,
          message_type: 'balance.payment_error',
          message_params: {},
        });
        break;
      default:
        break;
    }
  }, [paymentResult]);

  return (
    <Container>
      <Wrapper>
        <Title>{t('balance.title')}</Title>
        <Label>{t('balance.chooseDescr')}</Label>
        <Accordions>
          {paymentSystems.map((item) => (
            <CustomAccordion
              key={item.id}
              isChecked={selectedPayments.id === item.id}
              handleChecked={() => {
                const elem = {
                  id: selectedPayments.id === item.id
                    ? 0 : item.id,
                  name: item.title,
                };
                setPayments(elem);
                handleSelectedPaySystem(item.id);
              }}
              title={item.title}
              icon={icons[item.title]}
              text="Доступна оплата картами Мир переводом, минимальная сумма платежа 10 USD, так же доступен ADVcash самый дешевый вариант пополнения картами EU VISA/Mastercard.
При не поступления платежа просьба обращаться к ним в поддержку"
            />
          ))}
        </Accordions>
        {
          selectedPayments.name !== 'paypal'
            ? (
              <CalculateWrapper>
                <Label>{t('balance.sumDescr')}</Label>
                <Calculate>
                  <InputWrapper>
                    <StyledInput
                      value={inputValue || ''}
                      onChange={({ target }) => inputHandler(target.value)}
                      placeholder="0"
                    />
                    <Dollar>$</Dollar>
                  </InputWrapper>
                  <OutputText>
                    {`Будет зачислено: ${inputValue} $`}
                    {' '}
                    {bonus.value > 0 ? ` + бонус ${bonus.value} $` : ''}
                  </OutputText>
                </Calculate>
                <Agreement>
                  <Checkbox
                    checked={isChecked}
                    label=""
                    style={{
                      borderColor: '#d9d9d9',
                    }}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <AgreementBody>
                    <AgreementLabel>{t('balance.agreement')}</AgreementLabel>
                    <AgreementText>{t('balance.agreementDescr')}</AgreementText>
                  </AgreementBody>
                </Agreement>

                <InfoWrapper>
                  <img src={infoIcon} alt="info" />
                  <InfoText>
                    Оплачивать нужно ровно столько, сколько указано в счете.
                    Иначе деньги не зачислятся в автоматическом режиме.
                  </InfoText>
                </InfoWrapper>
              </CalculateWrapper>
            ) : null
        }
        <Buttons>
          <StyledButton
            className="forward"
            isOutline
            isBoldText
            isUpperCase
            disabled={checkIsDisable()}
            onClick={nextBtnHandler}
          >
            {t('balance.forward')}
          </StyledButton>
          <StyledButton
            className="backward"
            isBoldText
            onClick={() => {
              setInputValue(0);
              history.goBack();
            }}
          >
            {t('balance.cancel')}
          </StyledButton>
        </Buttons>

      </Wrapper>
      <BalanceModals
        modal={selectedBalanceModal || {}}
        isOpen={isBalanceModalOpen}
        bonus={bonus}
        replenishmentValue={inputValue}
        onClose={onModalClose}
        handleSubmit={handleSubmit}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #ffffff;
  padding: 2.5rem;
  width: 900px;
  margin-bottom: 2.8rem;
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const Title = styled(Text)`
  margin-bottom: 24px;

  position: relative;

  font-size: 24px;
  font-weight: 600;
  color: ${text01};
  line-height: 36px;
`;

const Label = styled.p`
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #939393;
  line-height: 20px; /* 125% */
  letter-spacing: 0.16px;
  margin: 0;
`;

const Accordions = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 240px;
`;

const Dollar = styled.span`
  opacity: 1;
  position: absolute;
  right: 16px;
  color: #939393;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.16px;
  text-transform: uppercase;
`;

const StyledInput = styled(Input)`
  width: 240px;
  margin-right: 20px;
  position: absolute;


  & input {
    padding-right: 32px;
    font-size: 14px;
    border-color: #d9d9d9;
  }
`;

const CalculateWrapper = styled.div`
  margin-bottom: 2rem;
`;

const Calculate = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 8px;
`;

const OutputText = styled.div`
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-left: 1.5rem;
`;

const Agreement = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const AgreementBody = styled.div`
`;
const AgreementLabel = styled.p`
  color: #000;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.16px;
  margin: 0;
`;

const AgreementText = styled.span`
  display: inline-block;
  margin-top: 0.5rem;
  color: #000;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #FFF6D4;
  padding: 0.5rem 1rem;
  border-radius: 4px;
`;

const InfoText = styled.p`
  color: #000;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  padding-left: 0.5rem;
`;

const Buttons = styled.div`
  display: inline-flex;
  width: 242px;
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 0 17px;
  background-color: #ff9800;
  color: #ffffff;
  text-transform: uppercase;

  &:disabled {
    border-color: ${secondary};
  }

  &.backward {
    margin-left: auto;
    margin-right: 15px;

    border: none;
    background-color: transparent;

    color: ${primary};

    transition: none;

    &:hover {
      background-color: #eef1fb;

      color: ${primary};
    }
  }
`;
export default Balance;
