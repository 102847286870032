export default {
  balanceTransfer: '/api/v1/user/referrals/balance-transfer',
  referralsUsersList: (page = 1, limit = 10, fromDate, toDate) => `/api/v1/user/referrals/?page=${page}&limit=${limit}&d_begin=${fromDate}&d_end=${toDate}`,
  referralsLinksList: (page = 1, limit = 10, fromDate, toDate) => `/api/v1/user/referrals/link-click-throughs?page=${page}&limit=${limit}&d_begin=${fromDate}&d_end=${toDate}`,
  referralsWithdrawalList: (page = 1, limit = 10, fromDate, toDate) => `/api/v1/user/referrals/balance/withdrawal-transactions?page=${page}&limit=${limit}&d_begin=${fromDate}&d_end=${toDate}`,
  referralsWithdrawalListNew: (page = 1, limit = 10, fromDate, toDate) => `/api/v1/user/referrals/balance/transactions?page=${page}&limit=${limit}&d_begin=${fromDate}&d_end=${toDate}`,
  referralsWithdrawalItem: (id) => `/api/v1/user/referrals/balance/withdrawal-transactions/${id}`,
  referralsWithdrawal: '/api/v1/user/referrals/balance/withdrawal-transactions',
  referralsTransactionsList: (page = 1, limit = 10, fromDate, toDate) => `/api/v1/user/referrals/transactions?page=${page}&limit=${limit}&d_begin=${fromDate}&d_end=${toDate}`,
  referralsStatistics: (fromDate, toDate) => `/api/v1/user/referrals/statistics?d_begin=${fromDate}&d_end=${toDate}`,
  withdrawalSystems: '/api/v1/user/withdrawal/systems',
};
