import React, {
  createContext,
  useState,
  useContext,
} from 'react';

import {
  getNewsAction,
  deleteAction,
  createNewsAction,
  getNewsByIdAction,
  updateNewsByIdAction,
  uploadImageAction,
} from '@/services/actions/admin/news.actions';

const BaseContext = createContext({});

export default ({ children }) => {
  // Tools
  const pageLimit = 50;
  const getOffset = (page) => (page - 1) * pageLimit;
  const getPosition = (page, index) => getOffset(page) + index + 1;

  const [news, setNews] = useState([]);
  const [newsCount, setNewsCount] = useState(0);
  const [newsPage, setNewsPage] = useState(1);

  const getNewsPageCount = () => Math.ceil(newsCount / pageLimit);

  const getNews = (orderFilter, searchFilter) => {
    getNewsAction(newsPage, pageLimit, orderFilter, searchFilter)
      .then((res) => {
        setNews(res.results);
        setNewsCount(res.count);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const deleteNewsById = (newsId) => {
    deleteAction(newsId)
      .then(() => getNews())
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const getNewsById = (newsId) => getNewsByIdAction(newsId)
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

  const updateNewsById = (data, newsId) => updateNewsByIdAction(newsId, data)
    .then(() => getNews())
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

  const createNews = (data) => createNewsAction(data)
    .then(() => getNews())
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

  const uploadImage = (file, newsId, tmpNewsId) => uploadImageAction(file, newsId, tmpNewsId);

  const value = {
    getNews,
    news,
    newsCount,
    getPosition,
    newsPage,
    setNewsPage,
    getNewsPageCount,
    deleteNewsById,
    getNewsById,
    updateNewsById,
    createNews,
    uploadImage,
  };

  return (
    <BaseContext.Provider value={value}>
      {children}
    </BaseContext.Provider>
  );
};

export const useBaseContext = () => useContext(BaseContext);
