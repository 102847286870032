import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import {
  secondary, white, text01,
} from '@/common/constants/theme';
import ModalNew from '@/common/components/ModalNew';
import Plus from '@/assets/icons/plus.svg';

const AddDeviceModal = ({ isVisible, toggleVisibility, onSubmit }) => {
  const { t } = useTranslation(['translations']);
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [isTouched, setTouched] = useState(false);

  const onClose = () => {
    toggleVisibility();
    setName('');
  };

  const handleSubmit = () => {
    if (name) {
      onSubmit(name, content);
      setName('');
      setContent('');
      toggleVisibility();
    } else {
      setTouched(true);
    }
  };

  return (
    <ModalNew
      isOpen={isVisible}
      icon={Plus}
      title={t('addModal.title')}
      onCloseModal={onClose}
      centered
    >
      <InputLabel>{t('addModal.nameLabel')}</InputLabel>
      <StyeledInput
        value={name}
        onChange={({ target }) => setName(target.value)}
        placeholder={t('addModal.namePlaceholder')}
      />
      {!name && isTouched && <ValidationText>{t('addModal.nameError')}</ValidationText>}
      <InputLabel>{t('addModal.contentLabel')}</InputLabel>
      <StyeledTextArea
        value={content}
        onChange={({ target }) => setContent(target.value)}
        placeholder={t('addModal.contentPlaceholder')}
      />
      <Actions>
        <Button
          onClick={handleSubmit}
        >
          {t('addModal.submitButton')}
        </Button>
      </Actions>
    </ModalNew>
  );
};

export default AddDeviceModal;

const StyeledInput = styled(Input)`
  height: 2.4rem;
  width: 24.5rem;
  margin-bottom: 1.8rem;
  &.ant-input:focus {
    border-color: ${secondary};
    box-shadow: none;
  }
  &.ant-input:hover {
    border-color: ${secondary};
    box-shadow: none;
  }
`;

const StyeledTextArea = styled(Input.TextArea)`
  width: 24.5rem;
  margin-bottom: 1.8rem;
  && {
    height: 4rem;
  }
  &.ant-input:focus {
    border-color: ${secondary};
    box-shadow: none;
  }
  &.ant-input:hover {
    border-color: ${secondary};
    box-shadow: none;
  }
`;

const InputLabel = styled.span`
  font-family: Gilroy;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin-bottom: .5rem;
`;

const Button = styled.button`
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const ValidationText = styled.span`
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: red;
  opacity: 0.6;
  margin-top: -1.5rem;
  margin-bottom: .34rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
`;
