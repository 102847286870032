import React from 'react';

export default ({ color }) => (
  <svg viewBox="0 0 92 78" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <g style={{
      stroke: 'none',
      strokeWidth: 0,
      strokeDasharray: 'none',
      strokeLinecap: 'butt',
      strokeLinejoin: 'miter',
      strokeMiterlimit: 10,
      fill: 'none',
      fillRule: 'nonzero',
      opacity: 1,
      transform: 'translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)',
    }}
    >
      <path
        d="M 0 11.755 v 66.489 h 90 V 11.755 H 0 z M 45 50.49 L 7.138 15.755 h 75.724 L 45 50.49 z M 33.099 45 L 4 71.695 V 18.304 L 33.099 45 z M 36.058 47.714 L 45 55.918 l 8.943 -8.204 l 28.919 26.53 H 7.138 L 36.058 47.714 z M 56.901 45 L 86 18.304 v 53.392 L 56.901 45 z"
        style={{
          stroke: 'none',
          strokeWidth: 1,
          strokeDasharray: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          fill: `${color || 'rgb(0,0,0)'}`,
          fillRule: 'nonzero',
          opacity: 1,
          transform: ' matrix(1 0 0 1 0 0) ',
        }}
      />
    </g>
  </svg>
);
