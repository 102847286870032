import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  secondary, white,
} from '@/common/constants/theme';
import ModalNew from '@/common/components/ModalNew';
import trash from '@/assets/icons/delete-trash.svg';

export default function DeviceDeleteModal(props) {
  const {
    isVisible, toggleVisibility, onSubmit, error,
  } = props;
  const { t } = useTranslation(['translations']);
  const [name, setName] = useState('');
  const [content, setContent] = useState('');

  const onClose = () => {
    toggleVisibility();
    setName('');
  };

  const handleSubmit = () => {
    onSubmit(name, content);
    setName('');
    setContent('');
    toggleVisibility();
  };

  return (
    <ModalNew
      isOpen={isVisible}
      icon={trash}
      title={error || t('devicesContent.deleteDeviceHead')}
      onCloseModal={onClose}
      centered
    >

      {t('devicesContent.deleteDeviceContent')}
      <Actions>
        <Button
          onClick={handleSubmit}
        >
          {t('devicesContent.buttonDelete')}
        </Button>
      </Actions>

    </ModalNew>
  );
}

const Button = styled.button`
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
`;
