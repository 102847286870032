import React from 'react';
import { ChannelsContextProvider } from './ChannelsContext';
import Channels from './Channels';

const ChannelsContainer = () => (
  <ChannelsContextProvider>
    <Channels />
  </ChannelsContextProvider>
);

export default ChannelsContainer;
