import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  brightGreen,
  text01,
  white,
  grey,
  red,
} from '@/common/constants/theme';
import CloseIcon from '../../../icons/CloseIcon';
import Checkbox from '@/common/components/Checkbox';
import { Radio, RadioGroup } from '@/common/components/RadioButton';
import Search from '../../components/Search';
import { useFinanceContext } from '../FinanceContext';
import AdminModal from '../../components/AdminModal';
import { useAdminContext } from '@/screens/Admin/AdminContext';
import ArrowDownIcon from '../../../icons/ArrowDownIcon';

const PAYMENT_SYSTEM_TEXT_TEMPLATE = 'Пополнение баланса через {paymentSystem}';
const PAYMENT_SYSTEM_CUSTOM = {
  id: 0,
  title: 'Добавить новый вид пополнения',
};

const FinanceUser = () => {
  const {
    SnackTypes,
    setAdminSnack,
    addPaymentSystem,
    deletePaymentSystem,
  } = useAdminContext();
  const {
    users,
    paymentSystems,
    addUserBalance,
    selectedUser,
    setSelectedUser,
  } = useFinanceContext();
  const [isVisible, setIsVisible] = useState(true);

  const [searchValue, setSearchValue] = useState('');
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [sum, setSum] = useState('');

  const [isBonus, setIsBonus] = useState(false);

  const [selectedPaymentSystem, setSelectedPaymentSystem] = useState(null);
  const [newPaySystemName, setNewPaySystemName] = useState('');

  const [isPaymentReady, setIsPaymentReady] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isDeletePSModal, setIsDeletePSModal] = useState(false);
  const [isPSProcessing, setIsPSProcessing] = useState(false);
  const [isBalanceProcessing, setIsBalanceProcessing] = useState(false);
  const [isPaymentSystemProcessing, setIsPaymentSystemProcessing] = useState(false);
  const [isPaymentSystemNameExists, setIsPaymentSystemNameExists] = useState(false);

  const getUserSearchableText = (user) => {
    const name = `${user?.first_name || ''} ${user?.last_name || ''}`.trim();
    const email = user?.email || '';
    return `${email} ${name}`.trim();
  };

  const getUserText = (user) => {
    const name = `${user?.first_name || ''} ${user?.last_name || ''}`.trim();
    const email = user?.email || '';
    return `${name.length > 0 ? name : email.split('@')[0]} (${email})`.trim();
  };

  const getPaymentWindowTitle = () => {
    const text = newPaySystemName.length > 0 ? newPaySystemName : (selectedPaymentSystem?.title || '');
    return PAYMENT_SYSTEM_TEXT_TEMPLATE.replace(/{paymentSystem}/gi, text);
  };

  const addBalance = (paymentSystem) => {
    setIsBalanceProcessing(true);
    addUserBalance(
      selectedUser.id,
      paymentSystem.id,
      sum,
      isBonus,
    )
      .then(() => {
        // setSelectedUser(null);
        setSearchValue('');
        setSum('');
      })
      .finally(() => {
        setIsConfirmModal(false);
        setIsBalanceProcessing(false);
      });
  };

  const handleAddBalance = () => {
    if (selectedPaymentSystem.id === 0) {
      setIsPaymentSystemProcessing(true);
      addPaymentSystem({
        title: newPaySystemName,
      }).then((res) => {
        if ((res?.id || 0) > 0) {
          setSelectedPaymentSystem(paymentSystems.find((ps) => ps.id === res));
          setNewPaySystemName(false);
          addBalance(res);
        }
      }).finally(() => {
        setIsPaymentSystemProcessing(false);
      });
    } else {
      addBalance(selectedPaymentSystem);
    }
  };

  const handleDeletePaymentSystem = () => {
    setIsPSProcessing(true);
    deletePaymentSystem(selectedPaymentSystem.id)
      .then(() => {
        setAdminSnack({
          type: 'success',
          content: `Платежная система\n${selectedPaymentSystem.title}\nудалена`,
        }, SnackTypes.success);
        setSelectedPaymentSystem(null);
      })
      .finally(() => {
        setIsPSProcessing(false);
      });
  };

  useEffect(() => {
    if (users.length > 0) {
      const preparedUsers = users.map((u) => ({ ...u, key: u.id, name: getUserText(u) }));
      setSuggestedUsers(searchValue.length === 0
        ? preparedUsers
        : preparedUsers.filter((u) => getUserSearchableText(u).includes(searchValue)));
    }
  }, [searchValue, users]);

  useEffect(() => {
    setIsPaymentReady(
      ((selectedUser?.id || 0) > 0)
      && (selectedPaymentSystem
        && (
          (selectedPaymentSystem.id > 0)
          || ((newPaySystemName.length > 0) && !isPaymentSystemNameExists)
        )
      )
      && (sum > 0),
    );
  }, [selectedUser, selectedPaymentSystem, sum]);

  useEffect(() => {
    setIsPaymentSystemNameExists(
      newPaySystemName.length > 0
      && paymentSystems.filter(
        (ps) => ps.title.toLowerCase() === newPaySystemName.toLowerCase(),
      ).length > 0,
    );
  }, [newPaySystemName]);

  useEffect(() => {
    if (selectedPaymentSystem && selectedPaymentSystem.id > 0) {
      setNewPaySystemName('');
    }
  }, [selectedPaymentSystem]);

  return (
    <Container isOpen={isVisible}>
      {selectedPaymentSystem && (
        <>
          <AdminModal
            isDisabled={isPSProcessing}
            isOpen={isDeletePSModal}
            onClose={() => setIsDeletePSModal(false)}
            color="danger"
            onSubmit={() => handleDeletePaymentSystem()}
            topTitle="Вы хотите удалить платежную систему"
            title={selectedPaymentSystem.title}
          />
          <AdminModal
            isDisabled={isBalanceProcessing}
            isOpen={isConfirmModal}
            onClose={() => setIsConfirmModal(false)}
            topTitle={getPaymentWindowTitle()}
            title={`$${sum} USD`}
            onSubmit={handleAddBalance}
            color="success"
            buttonDescr="Все верно?"
          >
            будет зачислено пользователю
            <ModalTitle>
              {selectedUser && selectedUser.name}
            </ModalTitle>
          </AdminModal>
        </>
      )}
      <Search
        isDisabled={users.length === 0}
        value={selectedUser?.name || searchValue}
        onChange={setSearchValue}
        onSelect={setSelectedUser}
        options={suggestedUsers}
        width="300px"
      />
      {selectedUser && (
        <>
          <FlexWrapper pointer alignCenter onClick={() => setIsVisible((prevState) => !prevState)}>
            <Text>
              {isVisible ? 'Свернуть' : 'Развернуть'}
            </Text>
            <CollapseIcon rotate={!isVisible}>
              <ArrowDownIcon />
            </CollapseIcon>
          </FlexWrapper>
          <Title>
            {selectedUser.name}
            <Icon
              top={-4}
              right={-14}
              onClick={() => {
                setSelectedUser(null);
                setSearchValue('');
              }}
            >
              <CloseIcon />
            </Icon>
          </Title>
          {isVisible && (
            <Block>
              <Wrapper flex flexSb bottomMargin={20}>
                <Wrapper flex>
                  <Text>
                    Сумма
                  </Text>
                  <Input
                    value={sum}
                    onChange={({ target }) => {
                      const value = target.value.match(/[0-9]/g);
                      if (!value) {
                        setSum('');
                        return;
                      }
                      setSum(value.join(''));
                    }}
                    placeholder="Введите сумму пополнения, например 5.00..."
                  />
                </Wrapper>
                <Wrapper>
                  <Checkbox
                    checked={isBonus}
                    onChange={(checked) => setIsBonus(checked)}
                    label="Учитывать бонус"
                  />
                </Wrapper>
              </Wrapper>
              <Wrapper bottomMargin={16}>
                <StyledRadioGroup
                  disabled={isPaymentSystemProcessing}
                  selectedValue=""
                  onClickRadioButton={() => false}
                >
                  {[...paymentSystems, PAYMENT_SYSTEM_CUSTOM].map((item) => (
                    <Wrapper key={`${item.id}--payment-system-selector`}>
                      <Radio
                        isSuggested={
                          newPaySystemName.length > 0
                          && (item.title.toLowerCase() === newPaySystemName.toLowerCase())
                        }
                        disabled={isPaymentSystemProcessing}
                        labelText={
                          item.id > 0
                            ? PAYMENT_SYSTEM_TEXT_TEMPLATE.replace(/{paymentSystem}/gi, item.title)
                            : item.title
                        }
                        onChange={
                          () => (
                            setSelectedPaymentSystem(paymentSystems.find((ps) => ps.id === item.id)
                              || PAYMENT_SYSTEM_CUSTOM)
                          )
                        }
                        checked={item.id === selectedPaymentSystem?.id}
                      />
                      {!isPaymentSystemProcessing && (item.id > 0) && (
                        <Icon
                          top={4}
                          right={-20}
                          onClick={() => {
                            setSelectedPaymentSystem(item);
                            setIsDeletePSModal(true);
                          }}
                        >
                          <CloseIcon />
                        </Icon>
                      )}
                    </Wrapper>
                  ))}
                </StyledRadioGroup>
                {selectedPaymentSystem && (selectedPaymentSystem.id === 0) && (
                  <Textarea
                    isError={isPaymentSystemNameExists}
                    title={() => (isPaymentSystemNameExists ? 'Система пополнения с таким именем уже есть!' : '')}
                    disabled={isPaymentSystemProcessing}
                    value={newPaySystemName}
                    onChange={({ target }) => setNewPaySystemName(target.value)}
                    placeholder="Чтобы добавить новый вид пополнения напишите здесь нименование системы пополнения. Например “Stripe”"
                  />
                )}
                {selectedPaymentSystem
                  && (selectedPaymentSystem.id === 0)
                  && isPaymentSystemNameExists
                  && (
                    <Text isError>Выбранное название уже используется</Text>
                  )}
              </Wrapper>
              <Wrapper flex flexCenter>
                <Button
                  disabled={!isPaymentReady}
                  title={!isPaymentReady ? 'Выберите систему и сумму для пополенения' : 'Можно пополнять'}
                  onClick={() => ((selectedPaymentSystem?.id || -1) === 0
                    ? newPaySystemName && sum && setIsConfirmModal(true)
                    : sum && setIsConfirmModal(true))}
                >
                  Пополнить
                </Button>
              </Wrapper>
            </Block>
          )}
        </>
      )}
    </Container>
  );
};

export default FinanceUser;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  min-height: 2rem;
  margin-bottom: 3rem;
  margin-top: 16px;
  transition:  all .5s ease-in 0s;

  ${({ isOpen }) => !isOpen && (`
      &::after {
        content: '';

        height: 1px;

        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;

        background-color: ${text01};
      }
    `)}
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && `
    display: flex;
    align-items: center;
  `}
  ${({ flexSb }) => flexSb && 'justify-content: space-between;'}
  ${({ flexCenter }) => flexCenter && 'justify-content: center;'}
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}

  position: relative;
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  color: ${({ isError }) => (isError ? red : text01)};
  line-height: 15px;
`;

const Title = styled(Text)`
  margin-top: 30px;
  margin-bottom: 24px;

  position: relative;

  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: flex-start;

  cursor: pointer;

  position: absolute;
  top: ${({ top }) => top}px;
  right: ${({ right }) => right}px;

`;

const Block = styled.div`
  width: 380px;
  min-height: 70px;
  padding: 20px 30px 30px;

  background: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 3px;
`;

const Input = styled.input`
  width: 100px;
  height: 32px;
  padding: 0 12px;
  margin-left: 4px;

  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  outline: none;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 42px;
  padding: 0 50px;

  background-color: transparent;
  border: 1px solid ${({ disabled }) => (disabled ? grey : brightGreen)};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ disabled }) => (disabled ? grey : brightGreen)};
  text-transform: uppercase;
  line-height: 24px;

  transition: all .2s ease-in 0s;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: block;

  & label {
    width: fit-content;
  }

  & div[type] {
    width: fit-content;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 66px;
  padding: 10px 12px;

  background-color: transparent;
  border: 1px solid ${({ isError }) => (isError ? red : borderSecondary)};
  border-radius: 2px;
  outline: none;
  resize: none;

  transition: all .33s ease-in 0s;

  &::placeholder {
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    color: ${grey};
    line-height: 16px;
  }
`;

const ModalTitle = styled.div`
  margin-top: 6px;

  font-size: 20px;
  line-height: 20px;
`;

const CollapseIcon = styled.div`
  margin-left: 10px;

  position: relative;

  cursor: pointer;
  transform: rotate(${({ rotate }) => (rotate ? 180 : 0)}deg);

  transition: 0.2s ease;
`;

const FlexWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50px;
  right: 0;

  ${({ alignCenter }) => alignCenter && 'align-items: center;'}

  ${({ pointer }) => pointer && 'cursor: pointer;'}
`;
