/* eslint-disable camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { secondary, white, text01 } from '@/common/constants/theme';
import Input from '@/common/components/AuthorizationInput';
import Loader from '@/common/components/Loader';
import { useAuthorizationContext } from './AuthorizationContext';
import AuthorizationLayout from './components/AuthorizationLayout';

function EnterEmailContainer({ match, history }) {
  const { t } = useTranslation('translations');
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const { enterEmail } = useAuthorizationContext();

  const sendRequest = () => {
    if (!email) {
      setEmailError(t('authorization.emptyEmail'));
    } else {
      setLoading(true);
      const { type, clientId } = match.params;
      enterEmail(type, clientId, email)
        .then(({ errors, confirm_letter }) => {
          setLoading(false);
          if (confirm_letter) {
            history.push(`/confirm-email/${email}`);
          } else if (errors) {
            setEmailError(t('authorization.emailDoesNotError'));
          }
        });
    }
  };

  return (
    <AuthorizationLayout>
      {isLoading && <Loader />}
      <Title>
        <TitleText>{t('authorization.enterEmail.textBlack')}</TitleText>
        <TitleTextOrange>
          {t('authorization.enterEmail.textOrange')}
        </TitleTextOrange>
      </Title>
      <Form>
        <ContentTitle>{t('authorization.enterEmail.info')}</ContentTitle>
        <ContentText>
          {t('authorization.enterEmail.enterEmailText')}
        </ContentText>
        <Input
          placeholder="Email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          errorText={emailError}
        />
        <Button onClick={sendRequest}>
          {t('authorization.enterEmail.button')}
        </Button>
      </Form>
    </AuthorizationLayout>
  );
}

export default withRouter(EnterEmailContainer);

const Title = styled.div`
  margin-top: 10rem;
  width: 40rem;
`;

const TitleText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;
`;

const TitleTextOrange = styled(TitleText)`
  color: ${secondary}
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const ContentTitle = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${text01};
`;

const ContentText = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin: .75rem 0;
  max-width: 25rem;
  white-space: pre-wrap;
`;

const Button = styled.button`
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
  margin-top: 1rem;
`;
