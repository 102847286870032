import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import RequestService from '@/services/request.service';
import urls from '@/services/routes/subscription.api';

import { useAppContext } from '@/AppContext';

export const MONTH_LENGTH = 30;

const SubscriptionContext = createContext({});

export const SubscriptionContextProvider = ({ children }) => {
  const { t } = useTranslation('translations');

  const { userInfo } = useAppContext();

  const PROMO_TYPES = { percent: 'percent', amount: 'amount' };

  const promoDiscountInit = {
    discount: 0,
    promoType: PROMO_TYPES.amount,
  };

  // eslint-disable-next-line no-unused-vars
  const [isPromoActivated, setIsPromoActivated] = useState(false);
  const [isPromoChecking, setIsPromoChecking] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  // Объект скидки по промокоду
  const [promoDiscount, setPromoDiscount] = useState(promoDiscountInit);
  // eslint-disable-next-line no-unused-vars
  const [orderAmount, setOrderAmount] = useState(0);
  // Скидка, приходящая с бэкенда после рассчета стоимости
  const [orderDiscount, setOrderDiscount] = useState(0);
  // Скидка по промокоду, приходящая с бэкенда после рассчета стоимости
  const [orderPromoDiscount, setOrderPromoDiscount] = useState(0);
  // Скидка за число дней
  const [discount, setDiscount] = useState(0);
  // Сумма отображаемой скидки
  const [discountValue, setDiscountValue] = useState(0);
  // Текст отображаемой скидки
  const [discountText, setDiscountText] = useState('');
  // Размер личной скидки пользователя (в %)
  const [personalDiscount, setPersonalDisount] = useState(0);
  const [promoTransaction, setPromoTransaction] = useState(null);
  const [error, setError] = useState('');
  const [promoError, setPromoError] = useState('');

  const calcDiscountValue = (data) => {
    let value = 0;

    const orderAmountValue = parseFloat(orderAmount);
    if (orderDiscount === 0) {
      const xdata = data || promoDiscount;
      switch (xdata.promoType) {
        case PROMO_TYPES.amount:
          value = (xdata.discount < orderAmountValue ? xdata.discount : orderAmountValue)
            .toFixed(2);
          break;
        case PROMO_TYPES.percent:
          value = (orderAmountValue * (
            (xdata.discount > 100 ? 100 : xdata.discount) / 100
          )).toFixed(2);
          break;
        default:
          break;
      }
    } else {
      value = orderDiscount;
    }

    setDiscountValue(value);
    setDiscountText(`$${value}`);
  };

  const handleSubmitPromo = (code) => {
    let isOk = false;
    setIsPromoChecking(true);
    setPromoError('');
    setPromoTransaction(null);

    RequestService(urls.promo, {
      method: 'POST',
      body: {
        code,
      },
    })
      .then((res) => {
        if (res?.transaction_id) {
          const newPromoDiscount = { discount: res.discount, promoType: res.promocode_type };
          setPromoDiscount(newPromoDiscount);
          setPromoTransaction(res.transaction_id);
          calcDiscountValue(newPromoDiscount);
          isOk = true;
        } else {
          setPromoDiscount(promoDiscountInit);
          setPromoError(t('steps.step2.promoErrors.common'));
        }
      })
      .catch((err) => {
        setPromoError(t(`steps.step2.promoErrors.${err?.response?.payload?.message_type || err?.data?.message_type}`));
      })
      .finally(() => {
        setIsPromoChecking(false);
        setIsPromoActivated(isOk);
      });
  };

  const clearPromo = () => {
    setIsPromoActivated(false);
    setPromoCode('');
    setPromoTransaction(null);
    setPromoDiscount(promoDiscountInit);
    setDiscountValue(0);
    setOrderDiscount(0);
    setPromoError('');
    setIsPromoChecking(false);
  };

  useEffect(() => {
    setPersonalDisount(userInfo?.personal_discount_percent || 0);
  }, [userInfo?.id]);

  useEffect(() => {
    calcDiscountValue();
  }, [orderDiscount, orderAmount, promoDiscount]);

  const value = {
    PROMO_TYPES,
    promoCode,
    setPromoCode,
    isPromoActivated,
    isPromoChecking,
    error,
    setError,
    handleSubmitPromo,
    promoTransaction,
    setPromoTransaction,
    promoDiscount,
    promoError,
    discountValue,
    discountText,
    personalDiscount,
    clearPromo,
    orderAmount,
    setOrderAmount,
    calcDiscountValue,
    discount,
    setDiscount,
    orderDiscount,
    setOrderDiscount,
    orderPromoDiscount,
    setOrderPromoDiscount,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => useContext(SubscriptionContext);
