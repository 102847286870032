const getErrorSnackContent = (snack) => {
  if (snack === null) return '';
  const retValArray = [];
  const details = snack?.payload?.errors?.detail || snack?.data?.errors || '';
  const errors = snack?.payload?.errors || snack?.data?.errors || {};
  const message = snack?.payload?.message || snack?.data?.message || '';
  if (details.length > 0) {
    retValArray.push(details);
  } else if ((Object.keys(errors)?.length || 0) > 0) {
    Object.entries(errors).forEach(([key, value]) => retValArray.push(`Поле '${key}': ${value}`));
  } else if (message.length > 0) {
    retValArray.push(message);
  }
  return retValArray.length > 0
    ? ['Ошибка!\n', ...retValArray].join(' ')
    : (snack?.payload?.message || snack?.payload?.data?.message || 'Неизвестная ошибка');
};

/* user */
export const getUsers = ({ users: state }) => state?.users;
export const getUserGroups = ({ users: state }) => state?.userGroups;
export const getPermissions = ({ users: state }) => state?.permissions;

/* common */
export const getMenu = ({ common: state }) => state?.menu;
export const getMenuItems = ({ common: state }) => state?.menuItems;
export const getSnack = ({ common: state }) => {
  if ((state?.snack || null) === null) { return {}; }
  return {
    status: state?.snack?.status || 400,
    type: state?.snack?.type || (state?.snack?.status !== 200 ? 'error' : 'success'),
    message_type: state?.snack?.message_type || state?.snack?.data?.message_type || 'error',
    message_params: state?.snack?.message_params || state?.snack?.data?.message_params || '',
    content: state?.snack?.content || getErrorSnackContent(state?.snack),
    key: Math.random(),
  };
};
export const getServers = ({ common: state }) => state?.servers;
export const getMinistraServers = ({ common: state }) => state?.ministraServers;
export const getPaymentSystems = ({ common: state }) => state?.paymentSystems;
export const getWithdrawalSystems = ({ common: state }) => state?.withdrawalSystems;
export const getCountries = ({ common: state }) => state?.countries;
export const getCountriesRaw = ({ common: state }) => state?.countriesRaw;
export const getOsFormats = ({ common: state }) => state?.osFormats;
export const getPasswordPinCheckCount = ({ common: state }) => (
  state?.passwordPinCheckCount
  || parseInt(window.localStorage.getItem('passwordPinCheckCount') || '0', 10)
);
export const getPasswordPinCheckExpDate = ({ common: state }) => (
  state?.passwordPinCheckExpDate
  || window.localStorage.getItem('passwordPinCheckExpDate')
  || (new Date((new Date()).getTime() - 1000 * 60 * 60)).toISOString()
);
