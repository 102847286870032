/* eslint no-console: 0 */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { text01, primary, borderSecondary } from '@/common/constants/theme';
import EditIcon from '@/common/components/icons/EditIcon';
import Loader from '@/common/components/Loader';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import DeleteIcon from '../../../../../icons/DeleteIcon';
import { useMinistraContext } from '../MinistraContext';
import AdminTablePagination from '../../../../components/Table/components/TablePagination';
import AdminDeleteModal from './DeleteModal';
import MinistraSyncModal from './SyncModal';

const SIZES = ['250px', '170px', '80px', '220px', '300px'];

const MinistraTable = ({
  setEditedServer,
  setInputValues,
  setIsEpgUpdate,
  setEpgUpdateResult,
  setSyncCallback,
  setSyncStatusText,
  setIsSync,
  setIsSyncing,
  setSyncData,
  setIsInSync,
  setIsBottomWindow,
  setSyncServerName,
  setIsCloseBottomWindowEnabled,
  setIsRequest,
  setIsSyncOk,
  setSetTableHeightCallback,
}) => {
  const { t } = useTranslation(['translations']);

  const {
    ministra,
    ministraLength,
    filters,
    setFilters,
    deleteMinistra,
    selectedMinistra,
    setSelectedMinistra,
    ministraUpdateEPG,
    ministraSyncDataStub,
    ministraCompareChannels,
    ministraGetCompareChannelsResult,
    ministraSyncChannels,
    ministraGetSyncChannelsResults,
  } = useMinistraContext();

  const getTaskIdTimeout = 30 * 1000;
  const dataCheckIntervalTime = 5 * 1000;
  let dataCheckInterval = null;

  const { page } = filters;

  const tableHeights = {
    init: '900px',
    withRequest: '550px',
    withResult: '360px',
  };

  const [tableHeight, setTableHeight] = useState(tableHeights.init);
  const [tableHeightValues, setTableHeightValues] = useState(tableHeights);

  const [isInfoNeeded, setIsInfoNeeded] = useState(false);
  const [infoType, setInfoType] = useState('success');
  const [infoText, setInfoText] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [deletedServer, setDeletedServer] = useState(null);
  const [endedSync, setEndedSync] = useState('');

  const [showUpdateEpgCompleted, setShowUpdateEpgCompleted] = useState(false);
  const [isEpgUpdatedSuccessfully, setIsEpgUpdatedSuccessfully] = useState(false);
  const [epgUpdateStatusText, setEpgUpdateStatusText] = useState('');

  const [isSubmitSyncRequestWindow, setIsSubmitSyncRequestWindow] = useState(false);
  const [isSyncCallBackSet, setIsSyncCallBackSet] = useState(false);
  const [isSetTableHeightCallbackSet, setIsSetTableHeightCallbackSet] = useState(false);

  const setInfo = (type, text) => {
    setInfoType(type);
    setInfoText(text);
    setIsInfoNeeded(true);
  };

  const badSyncEnd = () => {
    setIsInSync(false);
    setIsSyncing(false);
    setIsSyncOk(false);
    setIsBusy(false);
    setIsBottomWindow(false);
    setIsCloseBottomWindowEnabled(true);
    setSyncData(ministraSyncDataStub);
    clearInterval(dataCheckInterval);
    setIsRequest(false);
    setTableHeight(tableHeightValues.init);
  };

  const getSyncResult = (id, taskId) => {
    const startTime = Date.now();
    ministraGetSyncChannelsResults(id, taskId)
      .then((res) => {
        const { status } = res;
        if (status === 'success') {
          clearInterval(dataCheckInterval);
          setSyncData(res.results);
          setIsSync(true);

          setTableHeight(tableHeights.withResult);

          // show bottom panel
          setSyncStatusText(t('admin.iptv.ministra.synced'));
          setIsCloseBottomWindowEnabled(true);
          setIsBottomWindow(true);
          setIsBusy(false);
          setIsSyncing(false);
          setIsInSync(true);
          setIsSyncOk(true);
          setIsRequest(false);
        } else if (status === 'error') {
          // show error snakBar
          // eslint-disable-next-line max-len
          // setInfo('error', res?.exceptions_type || t('admin.iptv.ministra.errors.sync.undefined'));
          clearInterval(dataCheckInterval);
          setIsBusy(false);
          setTableHeight(tableHeightValues.withRequest);
          setSyncStatusText(t(`admin.iptv.ministra.errors.sync.${res?.exceptions_type}`));

          setIsInSync(true);
          setIsSyncing(false);
          setIsSyncOk(false);
          setIsRequest(false);
          setIsCloseBottomWindowEnabled(true);
          setIsBottomWindow(true);
        } else {
          console.log(`getSyncResult status [${status}], wait for results...`);
        }
      })
      .catch((err) => {
        console.log('getSyncResult err:');
        console.log(err);
        setSyncStatusText(t('admin.iptv.ministra.synced'));
        badSyncEnd();
      })
      .finally(() => {
        if (Date.now() - startTime > getTaskIdTimeout) {
          setSyncStatusText(t('admin.iptv.ministra.errors.sync.undefined'));
          badSyncEnd();
          clearInterval(dataCheckInterval);
        }
      });
  };

  const syncChannelsGroups = (usedMinistra) => {
    if (!usedMinistra) {
      setInfoType('error');
      setInfoText(t('admin.iptv.ministra.errors.sync.ministra_not_selected'));
      setIsInfoNeeded(true);
      return;
    }
    // open submit sync request window (MinistraSyncModal)
    setIsSubmitSyncRequestWindow(true);
  };

  const getSyncChannelsGroupsTaskId = (usedMinistra) => {
    setIsSubmitSyncRequestWindow(false);
    setTableHeight(tableHeightValues.withRequest);
    setSyncStatusText(t('admin.iptv.ministra.syncing'));
    setIsInSync(false);
    // setSyncData(ministraSyncDataStub);
    setIsRequest(true);
    setIsSyncing(true);
    setIsBusy(true);
    setIsCloseBottomWindowEnabled(false);
    ministraSyncChannels(usedMinistra.id)
      .then((res) => {
        const taskId = res?.task_id || '';
        if (taskId !== '') {
          dataCheckInterval = setInterval(() => {
            getSyncResult(usedMinistra.id, taskId);
          }, dataCheckIntervalTime);
        } else {
          setIsBusy(false);
          setSelectedMinistra(null);
          setIsSync(false);
          setIsBottomWindow(false);
          setTableHeight(tableHeightValues.init);

          setIsRequest(false);

          setInfoType('error');
          setInfoText(t('admin.iptv.ministra.errors.sync.empty_task_id'));
          setIsInfoNeeded(true);
        }
      })
      .catch((err) => {
        console.log('ministraSyncChannels err');
        console.log(err);
        setIsBusy(false);
        setTableHeight(tableHeightValues.init);
        setIsRequest(false);
      });
  };

  const updateEPG = (id, title) => {
    setTableHeight(tableHeights.withRequest);
    setIsBusy(true);
    setShowUpdateEpgCompleted(false);
    setEpgUpdateStatusText('');
    setSyncServerName(title);
    setIsEpgUpdate(true);
    setIsSync(false);
    setIsRequest(true);
    setEpgUpdateResult('');
    setIsBottomWindow(true);
    setIsCloseBottomWindowEnabled(false);
    ministraUpdateEPG(id)
      .then((res) => {
        const isSuccess = res?.status === 'success';
        setEpgUpdateResult(res?.msg || t('admin.iptv.ministra.errors.undefined'));
        setIsEpgUpdatedSuccessfully(isSuccess);
        setEpgUpdateStatusText(isSuccess ? t('admin.iptv.ministra.updatedEpg') : t('admin.iptv.ministra.errorTryMore'));
        setTableHeight(isSuccess ? tableHeights.withResult : tableHeights.init);
      })
      .catch((err) => {
        setIsEpgUpdatedSuccessfully(false);
        setEpgUpdateStatusText(t(`admin.iptv.ministra.errors.epgUpdate.${err?.response?.payload?.message_type}`));
        setIsEpgUpdate(false);
        setIsBottomWindow(false);
        setTableHeight(tableHeights.init);
      })
      .finally(() => {
        setIsBusy(false);
        setIsCloseBottomWindowEnabled(true);
        setShowUpdateEpgCompleted(true);
        setIsRequest(false);
      });
  };

  const getCompareResult = (id, taskId) => {
    const startTime = Date.now();
    ministraGetCompareChannelsResult(id, taskId)
      .then((res) => {
        const { status } = res;
        if (status === 'success') {
          clearInterval(dataCheckInterval);
          setSyncData(res.results);
          setIsSync(true);

          setTableHeight(tableHeights.withResult);

          if (!isSyncCallBackSet) {
            setIsSyncCallBackSet(true);
            setSyncCallback(() => syncChannelsGroups);
          }

          // show bottom panel
          setSyncStatusText(t('admin.iptv.ministra.compared'));
          setIsRequest(false);
          setIsCloseBottomWindowEnabled(true);
          setIsBottomWindow(true);
          setIsBusy(false);
        } else if (status === 'error') {
          setIsBusy(false);
          console.log('error, stop requests');
          // show error snakBar
          setIsRequest(false);
          setInfo('error', res?.exceptions_type || t('admin.iptv.ministra.errors.sync.undefined'));
        } else {
          console.log(`getCompareResult status [${status}], wait for results...`);
        }
      })
      .catch((err) => {
        console.log('ministraCompareChannelsResult err:');
        console.log(err);
        setIsCloseBottomWindowEnabled(true);
        clearInterval(dataCheckInterval);
        setIsRequest(false);
      })
      .finally(() => {
        if (Date.now() - startTime > getTaskIdTimeout) {
          setIsCloseBottomWindowEnabled(true);
          clearInterval(dataCheckInterval);
        }
      });
  };

  const getCompareTaskId = (id) => {
    setIsEpgUpdate(false);
    setIsBottomWindow(false);
    ministraCompareChannels(id)
      .then((res) => {
        const taskId = res?.task_id || '';
        if (taskId !== '') {
          // eslint-disable-next-line max-len
          dataCheckInterval = setInterval(() => { getCompareResult(id, taskId); }, dataCheckIntervalTime);
        } else {
          setIsBusy(false);
          setSelectedMinistra(null);
          setIsSync(false);
        }
      })
      .catch((err) => {
        console.log('ministraCompareChannels err');
        console.log(err);
        setIsBusy(false);
        setSelectedMinistra(null);
        setIsSync(false);
      });
  };

  const compareChannelsGroups = (item) => {
    setSyncStatusText(t('admin.iptv.ministra.comparing'));
    setIsBusy(true);
    setSelectedMinistra(item);
    setSyncData();
    getCompareTaskId(item.id);
    setSyncServerName(item.title);
    setIsSync(true);
    setIsInSync(false);
    setIsRequest(true);
    setIsCloseBottomWindowEnabled(false);
    setIsBottomWindow(true);
    setTableHeight(tableHeights.withRequest);
  };

  const setTableHeightInit = (heightType) => {
    setTableHeight(tableHeights[heightType] || tableHeights.init);
  };

  useEffect(() => {
    if (!isSetTableHeightCallbackSet) {
      const topElementsHeight = 277;
      const requestPanelHeight = 150;
      const resultPanelHeight = 386;

      const initHeight = window.innerHeight - topElementsHeight;
      tableHeights.init = `${initHeight}px`;
      tableHeights.withRequest = `${initHeight - requestPanelHeight}px`;
      tableHeights.withResult = `${initHeight - resultPanelHeight}px`;

      setTableHeightValues(tableHeights);

      setIsSetTableHeightCallbackSet(true);
      setTableHeightInit(tableHeights.init);
      setSetTableHeightCallback(() => setTableHeightInit);
    }
  }, [isSetTableHeightCallbackSet]);

  return (
    <Container>
      <SnackBarWindow
        isOpen={isInfoNeeded}
        setIsOpen={setIsInfoNeeded}
        type={infoType}
        content={<SnackBarText>{infoText}</SnackBarText>}
      />
      <SnackBarWindow
        isOpen={showUpdateEpgCompleted}
        setIsOpen={setShowUpdateEpgCompleted}
        type={isEpgUpdatedSuccessfully ? 'success' : 'error'}
        content={<SnackBarText>{epgUpdateStatusText}</SnackBarText>}
      />
      <SnackBarWindow
        isOpen={endedSync}
        onClose={() => setEndedSync('')}
        type="success"
        content={`${t('admin.iptv.ministra.sync')} ${endedSync === 'channels' ? t('admin.iptv.ministra.ofChannels') : t('admin.iptv.ministra.ofGenres')} ${t('admin.iptv.ministra.isDone')}.`}
      />
      <AdminDeleteModal
        isOpen={deletedServer}
        serverName={deletedServer?.title}
        onClose={() => setDeletedServer(null)}
        handleDelete={(newServerId) => deleteMinistra(deletedServer.id, newServerId)}
      />
      <MinistraSyncModal
        isOpen={isSubmitSyncRequestWindow}
        onClose={() => { setIsSubmitSyncRequestWindow(false); }}
        titleContent={t('admin.iptv.ministra.sync')}
        content={`${t('admin.iptv.ministra.doSync')}?`}
        submitContent={`${t('common.button.do')}?`}
        onSubmit={() => { getSyncChannelsGroupsTaskId(selectedMinistra); }}
      />
      {isBusy && <Loader />}
      <Table
        dataLength={ministraLength}
        limit={50}
        page={page}
        cellSizes={SIZES}
        textAlign="left"
        adaptiveSize={40}
      >
        <TableHead>
          <HeadItem>
            {t('admin.iptv.ministra.tableColName')}
          </HeadItem>
          <HeadItem>
            {t('admin.iptv.ministra.tableColDns')}
          </HeadItem>
          <HeadItem>
            {t('admin.iptv.ministra.tableColVer')}
          </HeadItem>
          <HeadItem>
            {t('admin.iptv.ministra.tableColLastSync')}
          </HeadItem>
          <HeadItem>
            <More />
          </HeadItem>
        </TableHead>
        <TableBody
          rowHeight={55}
          isBottomRowBorder
          customHeight={tableHeight}
        >
          {ministra.map((item) => (
            <>
              <RowItem>
                {item.title}
              </RowItem>
              <RowItem>
                {item.dns}
              </RowItem>
              <RowItem>
                {item.version}
              </RowItem>
              <RowItem>
                {/* eslint-disable-next-line max-len */}
                {(item?.dt_last_synchronization?.length || 0) > 0 ? new Date(Date.parse(item.dt_last_synchronization)).toLocaleString() : ''}
              </RowItem>
              <RowItem>
                <Wrapper flex>
                  <Icon onClick={() => {
                    setInputValues({ ...item });
                    setEditedServer(item.id);
                  }}
                  >
                    <EditIcon />
                  </Icon>
                  <Icon onClick={() => setDeletedServer(item)}>
                    <DeleteIcon />
                  </Icon>
                  <Text margin>/</Text>
                  <Text onClick={() => updateEPG(item.id, item.title)}>
                    {t('admin.iptv.ministra.updateEpg')}
                  </Text>
                </Wrapper>
                <Wrapper flex>
                  <Text onClick={() => compareChannelsGroups(item)}>
                    {t('admin.iptv.ministra.checkSyncAll')}
                  </Text>
                </Wrapper>
              </RowItem>
            </>
          ))}
        </TableBody>
        <AdminTablePagination
          filters={filters}
          setFilters={setFilters}
        />
      </Table>
    </Container>
  );
};

export default MinistraTable;

const Container = styled.div`
  position: relative;

  & .table-rows {
    border: 1px solid ${borderSecondary};
    border-radius: 4px 4px 0 0;

    &__row__item {
      align-items: flex-start;

      padding-top: 10px;
    }
  }
`;

const HeadItem = styled.div``;

const RowItem = styled.div``;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;

const More = styled.div`
  margin-left: 10px;

  width: 3px;
  height: 3px;

  position: relative;

  border-radius: 10px;
  background-color: ${text01};

  &::after,
  &::before {
    content: '';

    width: 3px;
    height: 3px;

    position: absolute;
    top: 0;

    background-color: ${text01};
    border-radius: 10px;
  }

  &::after {
    left: -6px;
  }

  &::before {
    left: 6px;
  }
`;

const Text = styled.div`
  ${({ margin }) => margin && ('margin: 0 4px;')}

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${primary};
  letter-spacing: 0.16px;
  line-height: 20px;
`;

const Icon = styled.div`
  margin-right: 5px;

  cursor: pointer;
`;

const SnackBarText = styled.div`
  padding: 23px 0;
`;
