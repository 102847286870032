import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { text01, white, primary } from '@/common/constants/theme';
import Checkbox from '@/common/components/Checkbox';
import Button from '@/common/components/Button';
import { AdminMainPopupHeader, MainContent } from '../../../../components/MainPopupComponents';
import ServersNumberInput from './NumberInput';
import { useServersContext } from '../ServersContext';

const ServersEditServer = ({ match, isCreate }) => {
  const {
    editServer,
    createServer,
    servers,
    getServers,
  } = useServersContext();
  let id;
  if (!isCreate && match?.params) {
    id = match.params.id;
  }
  const history = useHistory();

  const [inputValues, setInputValues] = useState({});

  const handleInput = (field, value) => {
    setInputValues((prevState) => ({ ...prevState, [field]: value }));
  };

  useEffect(() => {
    if (servers.length && id) {
      const useId = parseInt(id, 10);
      const useServer = servers.find((s) => s.id === useId);
      setInputValues({ ...useServer });
    }
  }, [servers, id]);

  useEffect(() => {
    getServers();
  }, []);

  return (
    <Container>
      <AdminMainPopupHeader
        handleClose={() => history.push('/admin/iptv/servers')}
        title={isCreate ? 'Создание IPTV сервера' : 'Редактирование IPTV сервера'}
        linkText="Вернуться “Серверы”"
      />
      <MainContent>
        <Wrapper bottomMargin={19}>
          <Wrapper flex>
            <Checkbox
              checked={inputValues.is_virtual}
              type="secondary"
              onChange={(checked) => handleInput('is_virtual', checked)}
              label="Виртуальный сервер"
            />
          </Wrapper>
        </Wrapper>
        <Wrapper>
          <Text>
            DNS сервера:
          </Text>
          <Input
            value={inputValues.dns}
            onChange={({ target }) => handleInput('dns', target.value)}
          />
        </Wrapper>
        <Wrapper>
          <Text>
            Имя сервера:
          </Text>
          <Input
            value={inputValues.name}
            onChange={({ target }) => handleInput('name', target.value)}
          />
        </Wrapper>
        <Wrapper>
          <Text>
            Кол-во дней архива телепередач на сервере:
          </Text>
          <ServersNumberInput
            value={inputValues.archive_days}
            onChange={(value) => value >= 0 && handleInput('archive_days', value)}
          />
        </Wrapper>
        <Wrapper>
          <Text>
            Описание:
          </Text>
          <TextArea
            value={inputValues.description}
            onChange={({ target }) => handleInput('description', target.value)}
          />
        </Wrapper>
        <Wrapper>
          <Text>
            Speedtest domain:
          </Text>
          <Input
            value={inputValues.speedtest_dns}
            onChange={({ target }) => handleInput('speedtest_dns', target.value)}
          />
        </Wrapper>
        <Wrapper>
          <Text>
            Ping URL:
          </Text>
          <Input
            value={inputValues.speedtest_ping}
            onChange={({ target }) => handleInput('speedtest_ping', target.value)}
          />
        </Wrapper>
        <Wrapper bottomMargin={19}>
          <Text>
            Download URL:
          </Text>
          <Input
            value={inputValues.speedtest_download}
            onChange={({ target }) => handleInput('speedtest_download', target.value)}
          />
        </Wrapper>
        <Wrapper>
          <Wrapper flex>
            <Checkbox
              checked={inputValues.manual_disable_sale}
              type="secondary"
              onChange={(checked) => handleInput('manual_disable_sale', checked)}
              label="Ручная блокировка продаж"
            />
          </Wrapper>
        </Wrapper>
      </MainContent>
      <StyledButton
        isBoldText
        isUpperCase
        onClick={() => {
          if (isCreate) {
            createServer(inputValues);
          } else {
            editServer(id, inputValues);
          }
        }}
      >
        Сохранить
      </StyledButton>
    </Container>
  );
};
export default ServersEditServer;

const Container = styled.div``;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}

  ${({ topMargin }) => topMargin && (`margin-top: ${topMargin}px;`)}
  ${({ bottomMargin }) => bottomMargin && (`margin-bottom: ${bottomMargin}px;`)}

  & div[type] {
    border-color: ${primary};
  }
`;

const Text = styled.div`
  margin-bottom: 12px;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 15px;
`;

const Input = styled.input`
  width: 360px;
  height: 32px;
  padding: 0 10px;
  margin-bottom: ${({ bottomMargin }) => bottomMargin || 15}px;

  background: ${white};
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  outline: none;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 24px;
`;

const TextArea = styled.textarea`
  display: flex;
  align-items: flex-start;

  width: 580px;
  height: 120px;
  padding: 5px 10px;
  margin-bottom: 15px;

  background: ${white};
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  resize: none;
  outline: none;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  width: 116px;
  margin-left: 18px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
