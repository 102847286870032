import React, {
  useEffect,
  useState,
} from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  primary,
  text01,
  text03,
} from '@/common/constants/theme';

import Button from '@/common/components/Button';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import ReferralsHeader from './components/ReferralsHeader';
import ReferralsLinkCard from './components/ReferralsLinkCard';
import ReferralsModalTransfer from './components/ReferralsModalTransfer';
import ReferralsModalRequest from './components/ReferralsModalRequest';
import ReferralsTransactionsTableContainer from './components/ReferralsTransactions/ReferralsTransactionsTableContainer';
import ReferralsLinksTableContainer from './components/ReferralsLinks/ReferralsLinksTableContainer';
import ReferralsRequestsTableContainer from './components/ReferralsRequests/ReferralsRequestsTableContainer';

import { getUserAction } from '@/services/actions/authorization.actions';
import { setUserInfo } from '@/store/user/selectors';

import { useAppContext } from '@/AppContext';
import { SHOW_RIGHT_PANEL } from '../../../PersonalPageContext';
import { useReferralsContext } from './ReferralsContext';

const NAV_LEFT_MARGIN_STANDARD = '20px';
const NAV_LEFT_MARGIN_LARGE = '40px';

const Referrals = () => {
  const {
    startFiltersDate,
    endFiltersDate,
    resetFilter,
    getReferralsStatistics,
    balanceTransfer,
    withdrawalSystems,
    createReferralsWithdrawal,
  } = useReferralsContext();

  const {
    userReferralLink,
    userReferralCurrentMonth,
    getUser,
  } = useAppContext();
  const history = useHistory();
  const path = history.location.pathname.slice(1);
  const { t } = useTranslation('translations');

  const [isModalTransfer, setIsModalTransfer] = useState(false);
  const [isFinalModalTransfer, setIsFinalModalTransfer] = useState(false);
  const [transferValue, setTransferValue] = useState();

  const [isModalRequest, setIsModalRequest] = useState(false);
  const [isFinalModalRequest, setIsFinalModalRequest] = useState(false);
  const [isFinalModalError, setIsFinalModalError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    resetFilter();
  }, []);

  useEffect(() => {
    getReferralsStatistics();
  }, [startFiltersDate, endFiltersDate]);

  const referralLink = window.location.origin + userReferralLink;

  const transferHandler = () => {
    setIsModalTransfer(true);
  };

  const requestHandler = () => {
    setIsModalRequest(true);
  };

  const submitTransferHandler = (amount) => {
    setIsModalTransfer(false);
    balanceTransfer(amount).then(() => {
      setTransferValue(amount);
      setIsFinalModalTransfer(true);
      getUserAction().then((res) => {
        dispatch(setUserInfo(res));
      });
      getUser();
    }).catch(() => {
      setIsFinalModalError(true);
    });
  };

  const submitRequestHandler = ({
    withdrawalSystemId, walletId, walletIdentifier, amount,
  }) => {
    setIsModalRequest(false);
    createReferralsWithdrawal(withdrawalSystemId, walletId, walletIdentifier, amount).then(() => {
      setIsFinalModalRequest(true);
      getUserAction().then((res) => {
        dispatch(setUserInfo(res));
      });
    }).catch(() => {
      setIsFinalModalError(true);
    });
  };

  return (
    <>
      <SnackBarWindow
        isOpen={isFinalModalTransfer}
        onClose={() => setIsFinalModalTransfer(false)}
        content={`${t('referrals.modal.success.title')} ${transferValue}.`}
        type="success"
      />
      <SnackBarWindow
        isOpen={isFinalModalRequest}
        onClose={() => setIsFinalModalRequest(false)}
        content={t('referrals.modal.successReq.title')}
        type="success"
      />
      <SnackBarWindow
        isOpen={isFinalModalError}
        onClose={() => setIsFinalModalError(false)}
        content={t('referrals.modal.errors.request')}
        type="error"
      />

      <ReferralsModalTransfer
        isOpen={isModalTransfer}
        onClose={() => {
          setIsModalTransfer(false);
        }}
        onSubmit={submitTransferHandler}
      />
      <ReferralsModalRequest
        isOpen={isModalRequest}
        onClose={() => {
          setIsModalRequest(false);
        }}
        onSubmit={submitRequestHandler}
        withdrawalSystems={withdrawalSystems}
      />

      <ReferralsHeader
        isFullWidth={!SHOW_RIGHT_PANEL}
        userReferralCurrentMonth={userReferralCurrentMonth}
        requestHandler={requestHandler}
        transferHandler={transferHandler}
      />
      <Container>
        <Wrapper bottomMargin>
          <ReferralsLinkCardWrapper>
            <ReferralsLinkCard
              title={t('referrals.link.yourLink')}
              link={referralLink}
            />
          </ReferralsLinkCardWrapper>
          <ReferralsLinkCard
            title={t('referrals.link.forumsLink')}
            link={`[url=${referralLink}]${window.location.origin}[/url]`}
          />
        </Wrapper>
        <Wrapper bottomMarginLittle>
          <NavItem
            leftmargin={NAV_LEFT_MARGIN_STANDARD}
            to="/personal-page/referrals/transactions"
            isactive={(path === 'personal-page/referrals/transactions') ? 'true' : 'false'}
          >
            {t('referrals.table.mainFilter.period')}
          </NavItem>
          <NavItem
            leftmargin={NAV_LEFT_MARGIN_LARGE}
            to="/personal-page/referrals/links"
            isactive={(path === 'personal-page/referrals/links') ? 'true' : 'false'}
          >
            {t('referrals.table.mainFilter.links')}
          </NavItem>
          <NavItem
            leftmargin={NAV_LEFT_MARGIN_LARGE}
            to="/personal-page/referrals/requests"
            isactive={(path === 'personal-page/referrals/requests') ? 'true' : 'false'}
          >
            {t('referrals.table.mainFilter.requests')}
          </NavItem>
        </Wrapper>
        <Switch>
          <Route exact path="/personal-page/referrals/transactions" component={ReferralsTransactionsTableContainer} />
          <Route exact path="/personal-page/referrals/links" component={ReferralsLinksTableContainer} />
          <Route exact path="/personal-page/referrals/requests" component={ReferralsRequestsTableContainer} />
          <Redirect to="/personal-page/referrals/transactions" />
        </Switch>
        <Wrapper>
          <StyledButton
            isBoldText
            onClick={history.goBack}
          >
            {t('table.back')}
          </StyledButton>
        </Wrapper>
      </Container>
    </>
  );
};

export default Referrals;

const Container = styled.div`
  margin-right: ${SHOW_RIGHT_PANEL ? 62 : 0}px;
  margin-top: 1.25rem;
`;

const Wrapper = styled.div`
  display: flex;

  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 39px;')};
  ${({ bottomMarginLittle }) => bottomMarginLittle && ('margin-bottom: 20px;')};
`;

const ReferralsLinkCardWrapper = styled.div`
  width: 100%;
  margin-right: 22px;
`;

const NavItem = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;

  ${({ leftmargin }) => (leftmargin && `margin-left: ${leftmargin};`)};

  font-size: 15px;
  ${({ isactive }) => (isactive !== 'true' ? (`
    cursor: pointer;
    color: ${primary};
    cursor: pointer;
    &&:hover {
      color: ${primary};
    }
  `) : (`
    cursor: default;
    color: ${text03};
    &&:hover {
      color: ${text03};
    }
  `))};
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 0 17px;
  margin: 80px 0 120px auto;

  background-color: transparent;

  color: ${primary};

  transition: none;

  &:hover {
    background-color: #eef1fb;
  }

  &:active {
    background-color: transparent;
  }
`;
