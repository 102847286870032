import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { text01 } from '@/common/constants/theme';

import Checkbox from '@/common/components/Checkbox';
import ModalNew from '@/common/components/ModalNew';
import Button from '@/common/components/Button';

import { useAppContext } from '@/AppContext';
import { useProfileContext } from '../ProfileContext';

const Notifications = () => {
  const { t } = useTranslation('translations');
  const { userInfo } = useAppContext();
  const {
    handleBalanceNotifications,
    handleNewsNotifications,
    handleSubscriptionNotifications,
    handleMailSubscription,
    handleSystemEventsChanges,
    handleReferralNotificationsChanges,
  } = useProfileContext();

  const {
    is_balance_notifications: balanceNotifications,
    is_get_news_notifications: newsNotifications,
    is_notify_subscription_expiration: subscriptionExpiration,
    is_subscribe_mail_list: subscribeToMail,
    is_system_events_notifications: systemEvents,
    is_referral_notifications: referralNotifications,
  } = userInfo;

  const [isBalanceNotifyChanges, setIsBalanceNotifyChanges] = useState(false);
  const [isNewsNotifyChanges, setIsNewsNotifyChanges] = useState(false);
  const [isSubscriptionsNotifyChanges, setIsSubscriptionsNotifyChanges] = useState(false);
  const [isMailSubscriptionChanges, setIsMailSubscriptionChanges] = useState(false);
  const [isSystemEventsChanges, setIsSystemEventsChanges] = useState(false);
  const [isReferralNotificationsSubscribe, setIsReferralNotificationsSubscibe] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ModalNew
        isOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        type="warning"
        title={t('profile.notifications.unsubscribeModal.title')}
      >
        <UnsubscribeWindowContent>
          {t('profile.notifications.unsubscribeModal.text')}
        </UnsubscribeWindowContent>
        <UnsubscribeWindowActions>
          <Button
            color="grey"
            isUpperCase
            onClick={() => {
              setIsMailSubscriptionChanges(true);
              setIsModalOpen(false);
              handleMailSubscription()
                .then(() => {
                  setIsMailSubscriptionChanges(false);
                });
            }}
          >
            {t('profile.notifications.unsubscribeModal.submitButtonText')}
          </Button>
          <Button
            color="primary"
            isUpperCase
            onClick={() => setIsModalOpen(false)}
          >
            {t('profile.notifications.unsubscribeModal.cancelButtonText')}
          </Button>
        </UnsubscribeWindowActions>
      </ModalNew>
      <BlockTitle>
        {t('profile.notifications.title')}
      </BlockTitle>
      <Content>
        <StyledCheckbox
          disabled={isBalanceNotifyChanges}
          checked={balanceNotifications}
          onChange={() => {
            setIsBalanceNotifyChanges(true);
            handleBalanceNotifications().then(setIsBalanceNotifyChanges(false));
          }}
          type="secondary"
          label={t('profile.notifications.balance')}
        />
        <StyledCheckbox
          disabled={isNewsNotifyChanges}
          checked={newsNotifications}
          onChange={() => {
            setIsNewsNotifyChanges(true);
            handleNewsNotifications().then(setIsNewsNotifyChanges(false));
          }}
          type="secondary"
          label={t('profile.notifications.news')}
        />
        <StyledCheckbox
          disabled={isSubscriptionsNotifyChanges}
          checked={subscriptionExpiration}
          onChange={() => {
            setIsSubscriptionsNotifyChanges(true);
            handleSubscriptionNotifications().then(setIsSubscriptionsNotifyChanges(false));
          }}
          type="secondary"
          label={t('profile.notifications.subscription')}
        />
        <StyledCheckbox
          disabled={isMailSubscriptionChanges}
          checked={subscribeToMail}
          onChange={() => {
            if (subscribeToMail) {
              setIsModalOpen(true);
            } else {
              setIsMailSubscriptionChanges(true);
              handleMailSubscription()
                .then(setIsMailSubscriptionChanges(false));
            }
          }}
          type="secondary"
          label={t('profile.notifications.mail')}
        />
        <StyledCheckbox
          disabled={isSystemEventsChanges}
          checked={systemEvents}
          onChange={() => {
            setIsSystemEventsChanges(true);
            handleSystemEventsChanges().then(setIsSystemEventsChanges(false));
          }}
          type="secondary"
          label={t('profile.notifications.systemEvents')}
        />
        <StyledCheckbox
          disabled={isReferralNotificationsSubscribe}
          checked={referralNotifications}
          onChange={() => {
            setIsReferralNotificationsSubscibe(true);
            handleReferralNotificationsChanges().then(setIsReferralNotificationsSubscibe(false));
          }}
          type="secondary"
          label={t('profile.notifications.referralNotifications')}
        />
      </Content>
    </>
  );
};

export default Notifications;

const BlockTitle = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${text01};
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 25rem;
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  &&& {
    margin: .5rem 0;
    :nth-child(2n+1) {
      margin-right: 2.5rem;
     }
  }
`;

const UnsubscribeWindowContent = styled.div`
  padding: 0 2em;
  text-align: center;
  font-size: 1.2em;
  white-space: pre-line;
`;

const UnsubscribeWindowActions = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 2rem;

  & > button:not(:last-of-type){
    margin-right: 1rem;
  }
`;
