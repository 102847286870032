import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';
import { text01, secondary } from '@/common/constants/theme';

import NewsBgSrc from '@/assets/images/news-bg.svg';
import PageContainer from '@/common/components/PageContainer';
import NewsFullCard from './components/NewsFullCard';

import { getNewsDetailsAction } from '@/services/actions/news.actions';

import { BASE_PATH } from './NewsContext';

const NewsDetails = () => {
  const { t } = useTranslation('translations');
  const history = useHistory();

  const [news, setNews] = useState({});
  const { id } = useParams();

  const getNewsDetails = (newsId) => {
    getNewsDetailsAction(newsId)
      .then((res) => {
        setNews(res);
      })
      .catch(() => {
        history.push(BASE_PATH);
      });
  };

  useEffect(() => {
    if (id == null) {
      return;
    }
    getNewsDetails(id);
  }, [id]);

  return (
    <Container>
      <PageContainer>
        <Wrapper>
          <Wrapper flex flexCenter bottomMargin>
            <Wrapper>
              <Title
                title={t('news.titleHint')}
                onClick={() => history.push(BASE_PATH)}
              >
                {t('news.title')}
              </Title>
            </Wrapper>
          </Wrapper>
          <Wrapper flex flexCenter>
            <NewsFullCard
              id={news.id}
              title={news.title}
              shortText={news.short_text}
              text={news.text}
              date={news.created_date}
              forumLink={news.isForum}
            />
          </Wrapper>
          <Background>
            <Img
              src={NewsBgSrc}
              alt=""
            />
          </Background>
        </Wrapper>
      </PageContainer>
    </Container>
  );
};

export default NewsDetails;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 63px;
  margin-bottom: 110px;
  position: relative;
  overflow: hidden;

  min-height: 460px
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexCenter }) => flexCenter && ('justify-content: center;')}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  flex-wrap: wrap;

  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 60px;')}

  position: relative;
`;

const Title = styled.div`
  ${({ orange }) => orange && ('margin-right: 10px;')}

  font-family: Gilroy;
  font-weight: bold;
  font-size: 44px;
  line-height: 48px;
  text-align: center;
  color: ${({ orange }) => (orange ? secondary : text01)};

  cursor: pointer;
  transition: all 0.125s ease-in-out 0s;

  &:hover {
    color: ${secondary};
  }
`;

const Background = styled.div`
  position: absolute;
  left: -215px;
  top: 225px;
  z-index: -1;
`;

const Img = styled.img``;
