/* eslint no-unused-vars: 0 */
/* eslint no-console: 0 */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  grey,
  text01,
  text02,
} from '@/common/constants/theme';

import EditIcon from '@/common/components/icons/EditIcon';
import CloseIcon from '@/common/components/icons/CloseIcon';
import iconBg from '@/assets/icons/delete-trash.svg';
import InputProfile from '@/common/components/Input';
import ModalSubmitAlt from '@/common/components/ModalSubmitAlt';
import ModalInfo from '@/common/components/ModalInfo';
import WithdrawalWalletModal from './WithdrawalWalletModal';

import { useAppContext } from '@/AppContext';
import { useProfileContext } from '../ProfileContext';

const WithdrawalWallets = ({
  setErrorText,
  setSuccessText,
}) => {
  const emptyResponse = {
    text: '', title: '', email: '', isShown: false,
  };

  const [selectedWallet, setSelectedWallet] = useState(null);
  const [action, setAction] = useState('');
  const [response, setResponse] = useState(emptyResponse);
  const [walletContainerScroll, setWalletContainerScroll] = useState(0);
  const [toolTipPosition, setToolTipPosition] = useState('top');

  const { t } = useTranslation('translations');

  const { userInfo } = useAppContext();
  const {
    wallets,
    withdrawalSystems,
    addWithdrawalWallet,
    editWithdrawalWallet,
    deleteWithdrawalWallet,
  } = useProfileContext();

  const setModal = (wallet, type) => {
    setSelectedWallet(wallet);
    setAction(type);
  };

  const clearWalletModal = () => {
    setSelectedWallet(null);
    setAction('');
  };

  const clearResponse = () => {
    setResponse(emptyResponse);
  };

  const addWalletHandler = (walletData) => {
    addWithdrawalWallet(walletData.withdraw_system.id, walletData.walletValue)
      .then((res) => {
        if (res.id) {
          setResponse({
            isShown: true, title: `${t('profile.accountsAndFinance.confirmedAccountTitle')} ${walletData.withdraw_system.name}`, text: '', email: res.user.email,
          });
        } else {
          setErrorText(t(`profile.accountsAndFinance.${res.message_type}`));
        }
        clearWalletModal();
      }).catch(() => {
        setErrorText(t('common.error.request'));
      });
  };

  const editWalletHandler = (walletData) => {
    editWithdrawalWallet(selectedWallet.id, {
      withdrawal_system_id: selectedWallet.withdrawal_system.id,
      withdrawal_system_user_identifier: walletData.walletValue,
      currency: selectedWallet.currency,
    })
      .then(() => {
        setResponse({
          isShown: true, title: `${t('profile.accountsAndFinance.confirmWallet')} ${walletData.withdraw_system.name}`, text: '', email: userInfo.email,
        });
        clearWalletModal();
      }).catch(() => {
        setErrorText(t('common.error.request'));
      });
  };

  const deleteWalletHandler = () => {
    deleteWithdrawalWallet(selectedWallet.id)
      .then(() => {
        setSuccessText(t('profile.accountsAndFinance.confirmedDelete'));
        clearWalletModal();
      }).catch(() => {
        setErrorText(t('common.error.request'));
      });
  };

  const mouseScrollWalletContainer = (e) => {
    setWalletContainerScroll(e.currentTarget.scrollTop);
  };

  const mouseEnterWalletInput = (e) => {
    const minTopDiff = 290;
    setToolTipPosition(e.currentTarget.offsetTop - walletContainerScroll > minTopDiff ? 'top' : 'bottom');
  };

  return (
    <>
      <ModalSubmitAlt
        isOpen={action === 'delete'}
        onCloseModal={clearWalletModal}
        submitButton={t('profile.accountsAndFinance.deleteButton')}
        onSubmit={deleteWalletHandler}
        width="370px"
        iconBg={iconBg}
      >
        <p>{`${t('profile.accountsAndFinance.deleteMessage')} ${selectedWallet && selectedWallet.withdrawal_system.name}`}</p>
        <p><b>{selectedWallet && selectedWallet.withdrawal_system_user_identifier}</b></p>
        <p>{t('profile.accountsAndFinance.deleteMessageWarning')}</p>
      </ModalSubmitAlt>
      <ModalInfo
        isOpen={response.isShown}
        title={response.title}
        onClose={clearResponse}
        onSubmit={clearResponse}
      >
        <p>
          {t('profile.accountsAndFinance.confirmedEmailText')}
          <ModalInfoEmail>{response.email}</ModalInfoEmail>
        </p>
        <p>{t('profile.accountsAndFinance.confirmedAccount')}</p>
      </ModalInfo>
      <WithdrawalWalletModal
        modalType={action}
        isOpen={['add', 'edit'].includes(action)}
        onClose={clearWalletModal}
        /* eslint-disable-next-line no-nested-ternary */
        onSubmit={action === 'add' ? addWalletHandler : (action === 'edit' ? editWalletHandler : null)}
        wallet={selectedWallet}
        withdrawalSystems={withdrawalSystems}
      />
      <BlockTitle>
        {t('profile.accountsAndFinance.title')}
      </BlockTitle>
      <Content>
        <WalletsListContainer onScroll={mouseScrollWalletContainer}>
          {wallets.map((wallet) => (
            <InputContainer key={wallet.id} onMouseEnter={mouseEnterWalletInput}>
              <Label>{wallet.withdrawal_system.name}</Label>
              <StyledInputProfile
                error={!wallet.is_verified}
                disabled
                value={wallet.withdrawal_system_user_identifier}
                errorMessage={t('profile.accountsAndFinance.walletNotConfirmed')}
                tooltipVerticalPosition={toolTipPosition}
              />
              {wallet.is_verified
                && (
                <EditButton
                  isDisabled={!!wallet.withdrawal_system.is_disabled}
                  onClick={() => {
                    if (!wallet.withdrawal_system.is_disabled) {
                      setModal(wallet, 'edit');
                    }
                  }}
                >
                  <EditIcon />
                </EditButton>
                )}
              <CloseButton
                onClick={() => { setModal(wallet, 'delete'); }}
              >
                <CloseIcon />
              </CloseButton>
            </InputContainer>
          ))}
          <InputContainer>
            <Label />
            <StyledInputProfile
              disabled
              value=""
              errorMessage={t('profile.accountsAndFinance.walletNotConfirmed')}
              onAdd={() => setModal(null, 'add')}
            />
          </InputContainer>
        </WalletsListContainer>
      </Content>
    </>
  );
};

export default WithdrawalWallets;

const BlockTitle = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${text01};
`;

const Content = styled.div`
  margin-top: 2rem;
  margin-right: -1rem;
  display: flex;
  flex-direction: column;
`;

const WalletsListContainer = styled.div`
  height: auto;
  max-height: 218px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: .5rem;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: ${text02};
  margin-right: 1.5rem;
  min-width: 4.5rem;
`;

const StyledInputProfile = styled(InputProfile)`
  width: 16rem;
`;

const ModalInfoEmail = styled.b`
  margin-left: .5em;
`;

const EditButton = styled.div`
  padding: .25rem .5rem;
  margin-left: .25rem;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  border-radius: .25rem;
  :hover {
    background: ${({ isDisabled }) => (isDisabled ? 'default' : '#eef1fb')};
  }
  ${({ isDisabled }) => (isDisabled && `
    svg path {
      fill: ${grey};
    }
  `)}
`;

const CloseButton = styled.div`
  padding: .25rem .5rem;
  margin-left: .25rem;
  cursor: pointer;
  border-radius: .25rem;
  :hover {
    background: #ff164414;
  }
`;
