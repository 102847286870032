import React from 'react';

export default ({ size = 72 }) => (
  <svg width={size * 0.81944444444444} height={size} viewBox="0 0 59 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.92365 0.0016453H54.9519C56.036 0.0016453 57.0203 0.444266 57.7336 1.15743C58.4468 1.87059 58.8894 2.85494 58.8894 3.93915V68.078C58.8894 69.1574 58.4488 70.1385 57.7383 70.8489C57.0278 71.5594 56.0475 72 54.9674 72H3.92201C2.84256 72 1.86147 71.5594 1.15109 70.8489C0.440615 70.1384 0 69.1581 0 68.078V3.92201C0 2.84256 0.440615 1.86147 1.15109 1.15109C1.86156 0.440615 2.84191 0 3.92201 0L3.92365 0.0016453ZM54.9519 2.2516H3.92365C3.46495 2.2516 3.04711 2.43978 2.74377 2.74378C2.44043 3.04712 2.25159 3.46497 2.25159 3.92367V68.0797C2.25159 68.5384 2.43976 68.9562 2.74377 69.2595C3.04711 69.5629 3.46495 69.7517 3.92365 69.7517H54.969C55.4277 69.7517 55.8456 69.5636 56.1489 69.2595C56.4522 68.9562 56.6411 68.5384 56.6411 68.0797V3.94081C56.6411 3.4781 56.4509 3.05622 56.1442 2.75021C55.8382 2.44418 55.4163 2.25335 54.9536 2.25335L54.9519 2.2516Z" fill="black" />
    <path d="M3.92365 0.0016453H54.9519C56.036 0.0016453 57.0203 0.444266 57.7336 1.15743C58.4468 1.87059 58.8894 2.85494 58.8894 3.93915V68.078C58.8894 69.1574 58.4488 70.1385 57.7383 70.8489C57.0278 71.5594 56.0475 72 54.9674 72H3.92201C2.84256 72 1.86147 71.5594 1.15109 70.8489C0.440615 70.1384 0 69.1581 0 68.078V3.92201C0 2.84256 0.440615 1.86147 1.15109 1.15109C1.86156 0.440615 2.84191 0 3.92201 0L3.92365 0.0016453ZM54.9519 2.2516H3.92365C3.46495 2.2516 3.04711 2.43978 2.74377 2.74378C2.44043 3.04712 2.25159 3.46497 2.25159 3.92367V68.0797C2.25159 68.5384 2.43976 68.9562 2.74377 69.2595C3.04711 69.5629 3.46495 69.7517 3.92365 69.7517H54.969C55.4277 69.7517 55.8456 69.5636 56.1489 69.2595C56.4522 68.9562 56.6411 68.5384 56.6411 68.0797V3.94081C56.6411 3.4781 56.4509 3.05622 56.1442 2.75021C55.8382 2.44418 55.4163 2.25335 54.9536 2.25335L54.9519 2.2516Z" fill="url(#paint0_linear_1926_532)" />
    <path d="M1.13281 47.5332H57.7701V49.7832H1.13281V47.5332Z" fill="black" />
    <path d="M1.13281 47.5332H57.7701V49.7832H1.13281V47.5332Z" fill="url(#paint1_linear_1926_532)" />
    <path d="M1.13281 22.2202H57.7701V24.4702H1.13281V22.2202Z" fill="black" />
    <path d="M1.13281 22.2202H57.7701V24.4702H1.13281V22.2202Z" fill="url(#paint2_linear_1926_532)" />
    <path d="M20.4922 10.666C19.8707 10.666 19.3672 11.1696 19.3672 11.791C19.3672 12.4124 19.8707 12.916 20.4922 12.916H38.3976C39.019 12.916 39.5225 12.4124 39.5225 11.791C39.5225 11.1696 39.019 10.666 38.3976 10.666H20.4922Z" fill="black" />
    <path d="M20.4922 10.666C19.8707 10.666 19.3672 11.1696 19.3672 11.791C19.3672 12.4124 19.8707 12.916 20.4922 12.916H38.3976C39.019 12.916 39.5225 12.4124 39.5225 11.791C39.5225 11.1696 39.019 10.666 38.3976 10.666H20.4922Z" fill="url(#paint3_linear_1926_532)" />
    <path d="M20.4922 34.8765C19.8707 34.8765 19.3672 35.38 19.3672 36.0014C19.3672 36.6229 19.8707 37.1264 20.4922 37.1264H38.3976C39.019 37.1264 39.5225 36.6229 39.5225 36.0014C39.5225 35.38 39.019 34.8765 38.3976 34.8765H20.4922Z" fill="black" />
    <path d="M20.4922 34.8765C19.8707 34.8765 19.3672 35.38 19.3672 36.0014C19.3672 36.6229 19.8707 37.1264 20.4922 37.1264H38.3976C39.019 37.1264 39.5225 36.6229 39.5225 36.0014C39.5225 35.38 39.019 34.8765 38.3976 34.8765H20.4922Z" fill="url(#paint4_linear_1926_532)" />
    <path d="M20.4922 59.0869C19.8707 59.0869 19.3672 59.5905 19.3672 60.2119C19.3672 60.8333 19.8707 61.3369 20.4922 61.3369H38.3976C39.019 61.3369 39.5225 60.8333 39.5225 60.2119C39.5225 59.5905 39.019 59.0869 38.3976 59.0869H20.4922Z" fill="black" />
    <path d="M20.4922 59.0869C19.8707 59.0869 19.3672 59.5905 19.3672 60.2119C19.3672 60.8333 19.8707 61.3369 20.4922 61.3369H38.3976C39.019 61.3369 39.5225 60.8333 39.5225 60.2119C39.5225 59.5905 39.019 59.0869 38.3976 59.0869H20.4922Z" fill="url(#paint5_linear_1926_532)" />
    <defs>
      <linearGradient id="paint0_linear_1926_532" x1="58.8894" y1="35.9501" x2="6.41061e-07" y2="35.9501" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint1_linear_1926_532" x1="57.7701" y1="48.6566" x2="1.13281" y2="48.6567" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint2_linear_1926_532" x1="57.7701" y1="23.3436" x2="1.13281" y2="23.3437" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint3_linear_1926_532" x1="39.5225" y1="11.7894" x2="19.3672" y2="11.7894" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint4_linear_1926_532" x1="39.5225" y1="35.9999" x2="19.3672" y2="35.9999" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint5_linear_1926_532" x1="39.5225" y1="60.2103" x2="19.3672" y2="60.2103" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
    </defs>
  </svg>
);
