import React from 'react';
import styled from 'styled-components';

import { text01, white, borderSecondary } from '@/common/constants/theme';
import ArrowTopIcon from '../../../../../icons/ArrowTopIcon';

const ServersNumberInput = ({ value, onChange }) => (
  <Container>
    <Input value={value} onChange={({ target }) => onChange(target.value)} />
    <IconsWrapper>
      <Icon onClick={() => onChange(value || value === 0 ? +value + 1 : 0)}>
        <ArrowTopIcon />
      </Icon>
      <Icon rotate onClick={() => onChange(value || value === 0 ? +value - 1 : 0)}>
        <ArrowTopIcon />
      </Icon>
    </IconsWrapper>
  </Container>
);

export default ServersNumberInput;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 155px;
  height: 32px;
  padding-left: 10px;
  margin-bottom: 15px;

  background-color: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 2px;
`;

const Input = styled.input`
  width: 125px;
  height: 30px;
  padding: 0;

  background: ${white};
  border: none;
  outline: none;

  font-family: Gilroy;
  font-size: 13px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 24px;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 32px;
  padding: 0 5px;

  background-color: ${white};
  border: 1px solid ${borderSecondary};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Icon = styled.div`
  flex-shrink: 0;

  cursor: pointer;

  ${({ rotate }) => rotate && ('transform: rotate(180deg);')}

  &:first-child {
    margin-top: -5px;
    margin-bottom: -3px;
  }
`;
