import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line
import confirmIconSrc from '@/assets/icons/confirmIcon.svg';
import { white } from '@/common/constants/theme';

import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Button from '@/common/components/Button';

const AdminConfirmModal = ({
  isOpen,
  onClose,
  content,
  handleSubmit,
}) => (
  <ModalWithoutContent
    icon={confirmIconSrc}
    isOpen={isOpen}
    onCloseModal={onClose}
  >
    <Container>
      <Text bottomMargin upperCase>
        Импортировать данные?
      </Text>
      <Text>
        {content}
      </Text>
      <StyledButton isBoldText isUpperCase onClick={handleSubmit}>
        Импортировать
      </StyledButton>
    </Container>
  </ModalWithoutContent>
);

export default AdminConfirmModal;

const Container = styled.div``;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 10px;')}

  font-family: Gilroy;
  font-size: 16px;
  color: ${white};
  white-space: pre-wrap;
  letter-spacing: 0.16px;
  line-height: 20px;
  ${({ upperCase }) => upperCase && ('text-transform: uppercase;')}
`;

const StyledButton = styled(Button)`
  width: 155px;
  margin-top: 25px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
