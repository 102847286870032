import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import styled from 'styled-components';
import { primary } from '@/common/constants/theme';

import pageSizes from '@/common/constants/pageSizes';
import Table from '../../../components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '../../../components/Table/components/TableBody';
import TablePagination from '../../../components/Table/components/TablePagination';
import ArrowTopRightCorner from '@/common/components/icons/ArrowTopRightCorner';
import Loader from '@/common/components/Loader';

import ReferralsTableFilters from '../ReferralsTableFilters';
import { useReferralsContext } from '../../ReferralsContext';
import { useReferralsTransactionsContext } from './ReferralsTransactionsContext';

const ReferralsTransactionsTable = () => {
  const { t } = useTranslation('translations');
  const {
    datePageLimitFilter,
    setPageLimit,
    applyFilter,
    resetFilter,
  } = useReferralsContext();

  const {
    referrals,
    getReferrals,
    page,
    setPage,
    pageCount,
  } = useReferralsTransactionsContext();

  const [isBusy, setIsBusy] = useState(false);
  const [displayNoDataArrow, setDisplayNoDataArrow] = useState(false);

  useEffect(() => {
    if (page > pageCount) {
      setPage(1);
      return;
    }
    setIsBusy(true);
    getReferrals()
      .finally(() => setIsBusy(false));
  }, [page, datePageLimitFilter]);

  const headers = [
    t('referrals.table.header.transactions.date'),
    t('referrals.table.header.transactions.user'),
    t('referrals.table.header.transactions.replenishment'),
    // t('referrals.table.header.period.source'),
  ];

  const sizes = [
    '100px',
    '340px',
    '170px',
  ];

  const renderEmptyDataArrow = () => displayNoDataArrow
    && ((referrals || []).length === 0)
    && (
      <HelperArrowContainer>
        <ArrowTopRightCorner />
      </HelperArrowContainer>
    );

  const renderRefRows = () => (referrals || []).map((item) => (
    <div key={`${item.id}--PSRTL-Row`}>
      <RowItem rightMargin>
        {dayjs(item.dt_create).format('DD.MM.YYYY')}
      </RowItem>
      <RowItem
        bold
        title={item.username}
      >
        {item.username}
      </RowItem>
      <RowItem>
        {`${item.amount_replenishment} / ${item.amount}`}
      </RowItem>
    </div>
  ));

  return (
    <Container>
      {isBusy && <Loader />}
      <Table
        page={page}
        setPage={setPage}
        pageCount={pageCount}
        pageLimit={datePageLimitFilter.pageLimit}
        setPageLimit={setPageLimit}
        cellSizes={sizes}
        textAlign="left"
      >
        <TableHead
          isSearch={false}
          isFilters
          filtersTitle={t('table.choosePeriod')}
          CustomFilters={(
            <ReferralsTableFilters
              startDateValue={datePageLimitFilter.startDate}
              endDateValue={datePageLimitFilter.endDate}
              applyHandler={applyFilter}
              resetHandler={resetFilter}
              editableDates
            />
          )}
          onFiltersOpen={() => setDisplayNoDataArrow(false)}
          onFiltersClose={() => setDisplayNoDataArrow(true)}
        >
          {headers.map((item) => (
            <HeadItem key={`${item}--PSRTTH-item`}>
              {item}
            </HeadItem>
          ))}
        </TableHead>
        <TableBody
          minRows={Math.max(Math.min(
            datePageLimitFilter.pageLimit, (referrals || []).length,
          ), pageSizes[0])}
          rowHeight={48}
          noDataText={t('table.noResultsTryChangeDates')}
          renderNoDataContent={!isBusy && renderEmptyDataArrow()}
        >
          {renderRefRows()}
        </TableBody>
        <TablePagination />
      </Table>
    </Container>
  );
};

export default ReferralsTransactionsTable;

const Container = styled.div`
  position: relative;

  & .table-rows__row__item {
    &:nth-child(2) {
      margin-left: 17px;
    }
  }
`;

const HeadItem = styled.div``;

const RowItem = styled.div`
  ${({ rightMargin }) => rightMargin && ('margin-left: -14px;')}

  position: relative;
  left: 15px;

  ${({ bold }) => bold && ('font-weight: 600;')}
`;

const HelperArrowContainer = styled.div`
  position: absolute;
  top: 36px;
  right: 10px;
  z-index: 5;

  & svg {
    width: 150px;
    height: 150px;
    fill: ${primary};
  }
`;
