import React from 'react';
import { FinanceContextProvider } from './FinanceContext';
import Finance from './Finance';

const FinanceContainer = () => (
  <FinanceContextProvider>
    <Finance />
  </FinanceContextProvider>
);

export default FinanceContainer;
