import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import Button from '@/common/components/Button';
import { usePromoCodesContext } from '../PromocodesContext';
import { AdminMainPopupHeader, MainContent } from '../../../../components/MainPopupComponents';

import EditFieldsBlock from './EditFieldsBlock';

const EditPromoCode = ({
  isCreate,
  setIsCreate,
  editedPromoCodeId,
  setEditedPromoCodeId,
  inputValues,
  setInputValues,
}) => {
  const { t } = useTranslation('translations');

  const {
    getPromoCode,
    createPromoCode,
    updatePromoCode,
  } = usePromoCodesContext();

  useEffect(() => {
    if (editedPromoCodeId) {
      getPromoCode(editedPromoCodeId)
        .then((res) => setInputValues({ res }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tmpErrors, setTmpErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [initValues, setInitValues] = useState({});

  const FIELDS = [[{
    key: 'code',
    title: t('admin.iptv.promocodes.fields.code'),
    required: true,
  }, {
    key: 'promocode_type',
    title: t('admin.iptv.promocodes.fields.promocode_type'),
    required: true,
    isDropdown: true,
    options: [{ name: t('admin.iptv.promocodes.type.amount'), value: 'amount' }, { name: t('admin.iptv.promocodes.type.percent'), value: 'percent' }],
  }, {
    key: 'discount',
    title: t('admin.iptv.promocodes.fields.discount'),
    required: true,
  }, {
    key: 'max_transactions',
    title: t('admin.iptv.promocodes.fields.max_transactions'),
    required: true,
    defaultValue: 1,
  }, {
    key: 'dt_expire',
    title: t('admin.iptv.promocodes.fields.dt_expire'),
    isDate: true,
  }]];

  const checkValues = () => {
    const errorsValue = {};
    for (let i = 0; i < FIELDS.length; i += 1) {
      FIELDS[i].forEach(({ key, required }) => {
        errorsValue[key] = required && (inputValues[key] === null || inputValues[key] === '');
      });
    }
    setTmpErrors(errorsValue);
    return Object.values(errorsValue).filter((v) => v).length === 0;
  };

  useEffect(() => {
    if (Object.values(initValues).length === 0) {
      setInitValues(inputValues);
    }
  }, []);

  const isValuesChanged = () => {
    let isChanged = false;
    Object.keys(initValues).every((key) => {
      if (inputValues[key] !== initValues[key]) {
        isChanged = true;
        return false;
      }
      return true;
    });
    return isChanged;
  };

  const handleInput = (field, value) => {
    setInputValues((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleInputLostFocus = () => {
    setIsDisabled(!checkValues() || !isValuesChanged());
  };

  const onSave = async () => {
    let newValues = { ...inputValues };
    FIELDS.forEach((fBlock) => (fBlock || []).forEach(({ key, defaultValue }) => {
      if (!newValues[key] && defaultValue) {
        newValues = { ...newValues, [key]: defaultValue };
      }
    }));
    setInputValues(newValues);
    setErrors(tmpErrors);
    if (checkValues()) {
      if (isCreate) {
        await createPromoCode(newValues);
      } else {
        updatePromoCode(editedPromoCodeId, inputValues);
      }
      setEditedPromoCodeId('');
      setIsCreate(false);
    }
  };

  return (
    <Container>
      <AdminMainPopupHeader
        handleClose={() => {
          setEditedPromoCodeId('');
          setIsCreate(false);
        }}
        linkText={t('admin.iptv.promocodes.edit.backLinkText')}
        title={editedPromoCodeId ? t('admin.iptv.promocodes.edit.editWindowTitle') : isCreate && t('admin.iptv.promocodes.edit.addWindowTitle')}
      />
      <MainContent>
        {FIELDS.map((fields, index) => (
          <EditFieldsBlock
            /* eslint-disable-next-line react/no-array-index-key */
            key={`APC-EditFieldsBlock-${index}`}
            fields={fields}
            inputValues={inputValues}
            errors={errors}
            handleInput={handleInput}
            handleInputLostFocus={handleInputLostFocus}
            selectOptionText={t('common.selectOption')}
            requiredText={t('common.requiredField')}
          />
        ))}

      </MainContent>
      <StyledButton
        disabled={isDisabled}
        isBoldText
        isUpperCase
        onClick={onSave}
      >
        {t('common.saveButton')}
      </StyledButton>
    </Container>
  );
};

export default EditPromoCode;

const Container = styled.div``;

const StyledButton = styled(Button)`
  width: 116px;
  margin-left: 18px;
  margin-bottom: 20px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
