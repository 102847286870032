import React from 'react';
import { ReferralsRequestsContextProvider } from './ReferralsRequestsContext';
import ReferralsRequestsTable from './ReferralsRequestsTable';

const ReferralsRequestsTableContainer = () => (
  <ReferralsRequestsContextProvider>
    <ReferralsRequestsTable />
  </ReferralsRequestsContextProvider>
);

export default ReferralsRequestsTableContainer;
