import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import {
  borderSecondary,
  green,
  grey,
  secondary,
} from '@/common/constants/theme';
import DisableIcon from '../../../../../icons/DisabledIcon';
import DeleteIcon from '../../../../../icons/DeleteIcon';
import ListIcon from '../../../../../icons/ListIcon';
import Loader from '@/common/components/Loader';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import TablePagination from '@/common/components/Table/components/TablePagination';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import AdminDeleteModal from '../../../../../Modals/DeleteModal';
import TransactionsList from './TransactionsList';

import { useAppContext } from '@/AppContext';
import { usePromoCodesContext } from '../PromocodesContext';

import SearchIcon from '../../../../../icons/SearchIcon';
import TableFilterArrows from '../../../../../TableFilterArrows';
import SelectorContainer from '@/common/components/Selector';

const SIZES = ['40px', '330px', '100px', '130px', '150px', '80px', '150px', '50px'];

const initialFilters = {
  limit: localStorage.getItem('PromocodesContext_limit') || 1000,
  page: 1,
};

const columnsTypes = {
  id: 'number',
  code: 'text',
  promocode_type: 'select',
  is_active: 'bool',
  discount: 'number',
  count_transactions: 'number',
  max_transactions: 'number',
  dt_expire: 'date',
  dt_created: 'date',
};

const searchTimeoutPause = 1000 * 0.5;

const PromoCodesTable = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState(null);
  const [localFilters, setLocalFilters] = useState(initialFilters);
  const [localPromoCodes, setLocalPromoCodes] = useState([]);
  const [localPromoCodesLen, setLocalPromoCodesLen] = useState(0);
  const [localSort, setLocalSort] = useState({});
  const [searches, setSearches] = useState({});
  const [localActivityFilter, setLocalActivityFilter] = useState({});
  const [listChanges, setListChanges] = useState(false);

  const [searchTimeout, setSearchTimeout] = useState(null);

  const { t } = useTranslation('translations');

  const { copyText } = useAppContext();
  const {
    isBusy,
    setIsBusy,
    promoCodesList,
    // promoCodesListLength,
    // filters,
    // handleFiltersChange,
    infoShow,
    setInfoShow,
    infoType,
    infoText,
    getTransactionsList,
    blockPromoCode,
    deletePromoCode,
  } = usePromoCodesContext();

  const activityValues = [{ key: '-', title: t('admin.iptv.promocodes.isAnyActive') }, { key: 'Y', title: t('admin.iptv.promocodes.isActiveShort') }, { key: 'N', title: t('admin.iptv.promocodes.isInactiveShort') }];

  const {
    page, limit,
  } = localFilters;

  // eslint-disable-next-line max-len
  const isGlobalActive = (item) => (
    item.is_active
    && ((item.max_transactions === 0) || (item.count_transactions < item.max_transactions))
    && (((item?.dt_expire || '').length === 0) || ((Date.parse(item.dt_expire) > (new Date()).getTime())))
  );

  const search = () => {
    let data = promoCodesList.map((item) => ({ ...item, is_active: isGlobalActive(item) }));

    Object.entries(searches).forEach(([column, value]) => {
      if (value) {
        switch (columnsTypes[column]) {
          case 'text':
            data = data.filter((item) => item[column].toLowerCase().includes(value.toLowerCase()));
            break;
          case 'number':
            data = data.filter((item) => parseFloat(item[column]) >= parseFloat(value));
            break;
          case 'select':
            data = data.filter((item) => item[column].toLowerCase() === value.toLowerCase());
            break;
          case 'bool':
            data = data.filter((item) => (value === 'Y' ? item[column] : !item[column]));
            break;
          case 'date':
            data = data.filter((item) => new Date(item[column]) < new Date(value));
            break;
          default:
            break;
        }
      }
    });

    setLocalPromoCodes(data);
    setLocalPromoCodesLen(data.length);

    return data;
  };

  const doSort = () => {
    setIsBusy(true);
    let data = search();

    Object.entries(localSort).forEach(([column, direction]) => {
      let altColumn = '';
      switch (column) {
        case 'discount':
          data = data.sort((a, b) => {
            const fA = parseFloat(a[column]);
            const fB = parseFloat(b[column]);
            if (fA === fB) {
              return 0;
            }
            return direction === 'up' ? fA - fB : fB - fA;
          });
          break;
        case 'count_transactions':
          altColumn = 'max_transactions';
          data = data.sort((a, b) => {
            const fA = parseInt(a[column], 10);
            const fB = parseInt(b[column], 10);
            if (fA === fB) {
              const fA2 = parseInt(a[altColumn], 10);
              const fB2 = parseInt(b[altColumn], 10);
              if (fA2 === fB2) { return 0; }
              return direction === 'up' ? fA2 - fB2 : fB2 - fA2;
            }
            return direction === 'up' ? fA - fB : fB - fA;
          });
          break;
        case 'is_active':
          data = data.sort((a, b) => {
            const fA = a[column] ? 1 : 0;
            const fB = b[column] ? 1 : 0;
            return direction === 'up' ? fA - fB : fB - fA;
          });
          break;
        case 'dt_expire':
          data = data.sort((a, b) => {
            const fA = new Date(a[column]);
            const fB = new Date(b[column]);
            if (fA === fB) {
              return 0;
            }
            return direction === 'up' ? fA - fB : fB - fA;
          });
          break;
        default:
          break;
      }
    });

    setListChanges(false);
    setLocalPromoCodes(data);
    setLocalPromoCodesLen(data.length);
    setIsBusy(false);
  };

  const sort = (columnName, directionValue) => {
    setListChanges(true);
    if (localPromoCodes.length === 0) {
      return;
    }
    const sortData = ({ ...localSort, [columnName]: directionValue });
    if (directionValue === '') {
      delete sortData[columnName];
    }
    setLocalSort(sortData);
  };

  const handleLocalFiltersChange = () => {
    setListChanges(true);
    setLocalFilters();
  };

  const handleSearchLocalCode = ({ target }) => {
    setListChanges(true);
    setSearches({ ...searches, code: target.value });
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(setTimeout(() => sort(), searchTimeoutPause));
  };

  const handleSearchLocalActive = ({ key }) => {
    setListChanges(true);
    const data = JSON.parse(JSON.stringify(searches));
    delete data.is_active;
    if (key !== '-') {
      data.is_active = key;
    }
    setSearches(data);
  };

  useEffect(() => doSort(), [searches, localSort, listChanges]);

  useEffect(() => {
    setLocalPromoCodes(promoCodesList);
    setLocalPromoCodesLen(promoCodesList.length);
    setLocalActivityFilter(activityValues.find((itm) => itm.key === '-'));

    Object.entries(localSort).forEach(({ column, direction }) => sort(column, direction));
    doSort();
  }, [promoCodesList]);

  return (
    <Container>
      {selectedPromoCode && <TransactionsList selectedPromoCode={selectedPromoCode} />}
      <AdminDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => { setIsDeleteModalOpen(false); setSelectedPromoCode(null); }}
        content={selectedPromoCode ? t('admin.iptv.promocodes.deleteWarningText', selectedPromoCode) : ''}
        handleDelete={() => deletePromoCode(selectedPromoCode.id)}
        color="danger"
      />
      <SnackBarWindow
        isOpen={infoShow}
        setIsOpen={setInfoShow}
        type={infoType}
        timestamp={1000 * 15}
        content={<SnackBarText>{infoText}</SnackBarText>}
      />
      {isBusy && <Loader />}
      <Table
        pagesCount={Math.ceil(localPromoCodesLen / limit)}
        page={page}
        dataLength={localPromoCodesLen}
        onFiltersChange={handleLocalFiltersChange}
        limit={limit}
        cellSizes={SIZES}
        textAlign="left"
        adaptiveSize={40}
      >
        <TableHead>
          <HeadItem>
            {t('admin.iptv.promocodes.table.colId')}
          </HeadItem>
          <HeadItem flex>
            <Input
              placeholder={t('admin.iptv.promocodes.table.colCode')}
              onKeyUp={handleSearchLocalCode}
              onChange={handleSearchLocalCode}
            />
            <Icon top>
              <SearchIcon />
            </Icon>
          </HeadItem>
          <HeadItem>
            {t('admin.iptv.promocodes.table.colType')}
          </HeadItem>
          <HeadItem flex>
            {t('admin.iptv.promocodes.table.colDiscount')}
            <TableFilterArrows sortTriggered={(direction) => sort('discount', direction)} />
          </HeadItem>
          <HeadItem flex>
            {t('admin.iptv.promocodes.table.colTransactions')}
            <TableFilterArrows
              sortTriggered={(direction) => sort('count_transactions', direction)}
            />
          </HeadItem>
          <HeadItem flex>
            <SelectorContainer
              defaultValue={activityValues.find((item) => item.key === '-')}
              value={localActivityFilter}
              options={activityValues}
              onSelect={handleSearchLocalActive}
            />
          </HeadItem>
          <HeadItem flex>
            {t('admin.iptv.promocodes.table.colExpired')}
            <TableFilterArrows
              sortTriggered={(direction) => sort('dt_expire', direction)}
            />
          </HeadItem>
          <HeadItem>
            ...
          </HeadItem>
        </TableHead>
        <TableBody
          rowHeight={45}
          // minRows={5}
          customHeight="720px"
          isBottomRowBorder
        >
          {localPromoCodes.map((item) => (
            <RowContainer
              key={`promocode_${item.id}`}
            >
              <RowItem>
                {item.id}
              </RowItem>
              <RowItem
                pointerCursor
                onClick={() => copyText(item.code)}
                title="Скопировать промокод"
              >
                {item.codeDisplay}
              </RowItem>
              <RowItem>
                {t(`admin.iptv.promocodes.type.${item.promocode_type}`)}
              </RowItem>
              <RowItem>
                {item.discount}
              </RowItem>
              <RowItem>
                {`${item.count_transactions} / ${item.max_transactions}`}
              </RowItem>
              <RowItem>
                <ActivitySign IsActive={isGlobalActive(item)} title={t(`admin.iptv.promocodes.${isGlobalActive(item) ? 'isActive' : 'isInactive'}`)} />
              </RowItem>
              <RowItem>
                {(item?.dt_expire?.length || 0) > 0 ? new Date(Date.parse(item.dt_expire)).toLocaleString() : ''}
              </RowItem>
              <RowItem>
                <Wrapper flex>
                  {(item.count_transactions > 0) && (
                    <ControlButton
                      title={`${t('admin.iptv.promocodes.openTransactionsText')} (${item.count_transactions})`}
                      onClick={() => { setSelectedPromoCode(item); getTransactionsList(item.id); }}
                    >
                      <ListIcon />
                    </ControlButton>
                  )}
                  {(Date.parse(item.dt_expire) > (new Date()).getTime())
                    && (item.count_transactions < item.max_transactions) && (
                    <ControlButton
                      title={t(`admin.iptv.promocodes.${item.is_active ? 'blockText' : 'unblockText'}`)}
                      onClick={() => blockPromoCode(item.id, !item.is_active)}
                    >
                      <DisableIcon isActive={item.is_active} />
                    </ControlButton>
                  )}
                  {(item.count_transactions === 0) && (
                    <ControlButton
                      title={t('admin.iptv.promocodes.deleteText')}
                      onClick={() => { setSelectedPromoCode(item); setIsDeleteModalOpen(true); }}
                    >
                      <DeleteIcon />
                    </ControlButton>
                  )}
                </Wrapper>
              </RowItem>
            </RowContainer>
          ))}
        </TableBody>
        <TablePagination />
      </Table>
    </Container>
  );
};

export default PromoCodesTable;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;

const Container = styled.div`
  position: relative;

  & .table-rows {
    border: 1px solid ${borderSecondary};
    border-radius: 4px 4px 0 0;
  }
`;

const HeadItem = styled.div`
  ${({ flex }) => flex && 'display: flex;'}
`;

const RowContainer = styled.div``;

const RowItem = styled.div`
  position: relative;
  ${({ pointerCursor }) => (pointerCursor && `
    cursor: pointer;
    transition: all 0.125s ease-in 0s;

    &:hover {
      color: ${secondary};
    }
  `)}
`;

const ActivitySign = styled.div`
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: .5rem;
  background: ${({ IsActive }) => (IsActive ? green : grey)};
`;

const SnackBarText = styled.div`
  padding: 23px 0;
`;

const ControlButton = styled.div`
  padding: .33rem .5rem;
  cursor: pointer;
  opacity: .5;
  transition: all 0.2s ease-in 0s;

  &:hover{
    opacity: 1;
  }
`;

const Icon = styled.div`
  position: relative;
  top: 3px;
  ${({ pointer }) => pointer && 'cursor: pointer;'}
`;

const Input = styled.input`
  margin-right: 6px;
  padding-bottom: 4px;

  width: calc(100% - 16px - 6px);

  border: none;
  border-bottom: 1px solid ${secondary};
  background-color: transparent;
  outline: none;
`;
