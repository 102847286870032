import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { background01 } from '@/common/constants/theme';

import ImgSrc from '@/assets/images/webp/step3.webp';
import StalkerSrc from '@/assets/images/stalker.png';
import CloseIcon from '@/common/components/icons/CloseIcon';
import ServerTitle from './Header/ServerTitle';
import ServerCard from './Header/ServerCard';

import { USE_DEVICE_VIEW_TYPE } from '@/AppContext';
import { SHOW_RIGHT_PANEL, usePersonalPageContext } from '../../../../PersonalPageContext';

const ServerHeader = ({
  headerPageId,
  showControlButtons,
  isStalker,
}) => {
  const { t } = useTranslation('translations');
  const { handleClosePageHeader } = usePersonalPageContext();

  return (
    <Container isStalker={isStalker}>
      <ServerTitle
        isStalker={isStalker}
        showControlButtons={showControlButtons}
        isFullWidth={!SHOW_RIGHT_PANEL}
      />
      <Wrapper>
        {USE_DEVICE_VIEW_TYPE && (
          <ServerCard isStalker={isStalker} />
        )}
        <Img
          src={isStalker ? StalkerSrc : ImgSrc}
          alt=""
          isStalker={isStalker}
          width={isStalker ? 489 : 459}
        />
      </Wrapper>
      {!showControlButtons && (
        <CloseBlock
          title={t('personalPage.hide')}
          onClick={() => handleClosePageHeader(headerPageId)}
        >
          <CloseIcon />
        </CloseBlock>
      )}
    </Container>
  );
};

export default ServerHeader;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${SHOW_RIGHT_PANEL ? `
    margin: 20px 19px 22px 0;
  ` : `
    margin: 20px 0 22px 0;
  `}
  padding: 40px 65px ${({ isStalker }) => (isStalker ? '0' : '12px')} 0;

  position: relative;

  background-color: ${background01};

  &::after {
    content: '';

    width: 100%;

    position: absolute;
    left: -100%;
    top: 0;
    bottom: 0;

    background-color: ${background01};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Img = styled.img`
  margin-top: ${({ isStalker }) => (isStalker ? '-160' : '-16')}px;
  margin-right: ${({ isStalker }) => (isStalker ? '-45' : '13')}px;
`;

const CloseBlock = styled.div`
  position: absolute;
  cursor: pointer;
  top: .8rem;
  right: .8rem;

  & svg{
    width: 12px;
    height: 12px;

    path {
      stroke: red;
    }
  }
`;
