import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { white } from '@/common/constants/theme';

import UserActions from '@/common/components/HeaderShell/components/UserActions';

const AdminHeader = ({ isUserExists }) => (
  <Container>
    <MainWrapper>
      <Wrapper>
        <Logo to="/" user>
          Arlekino.tv
        </Logo>
      </Wrapper>
      <Title>
        Панель менеджера
      </Title>
      <UserActions isUserExist={isUserExists} />
    </MainWrapper>
  </Container>
);

export default AdminHeader;

const Container = styled.div`
  display: flex;
  justify-content: center;

  height: 67px;

  background: linear-gradient(180deg, rgba(92, 112, 207, 0.93) 0%, rgba(63, 80, 159, 0.93) 100%);
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 11px;
`;

const Wrapper = styled.div`
  align-self: flex-start;
  padding-left: 2rem;
`;

const Logo = styled(Link)`
  background: ${white};
  border-radius: 0 0 9px 9px;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 42px;
  letter-spacing: -0.04em;
  color: #5378CF;
  padding: 1.5rem 1.5rem .75rem;
  ${({ user, path }) => ((!user || !path) && `
    box-shadow: 2px 4px 9px rgba(75, 92, 168, 0.2);
  `)};

  &:hover {
    color: #5378CF;
  }
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 48px;

  color: ${white};
`;
