import React from 'react';
import styled from 'styled-components';
import {
  activePrimary,
  primary,
  secondaryHover,
} from '@/common/constants/theme';

export default ({ checked, onChange }) => (
  <Label className="switch">
    <Input
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    <Slider className="slider round" />
  </Label>
);

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 1.75rem;
  height: 1rem;
  input:checked + span {
    background: ${secondaryHover};
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.17);
  }

  input:checked + span:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${activePrimary};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  :before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 3px;
    bottom: 3px;
    background-color: ${primary};
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
`;
