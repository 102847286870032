/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

import { white } from '../../../../../../common/constants/theme';

export const tableSortableConfig = {
  axis: 'y',
  lockAxis: 'y',
  distance: 5,
  lockToContainerEdges: true,
};

const AdminTable = ({
  children,
  cellSizes,
  page,
  setPage,
  pageCount,
  recordsCount,
  isCreateTab,
  setIsCreateTab,
  editedId,
  setEditedId,
  deletedId,
  setDeletedId,
  onSortEnd,
  handleSave,
  createTab,
  title,
}) => (
  <Container>
    {React.Children.map(children, (element) => React.cloneElement(element, {
      cellSizes,
      page,
      setPage,
      pageCount,
      recordsCount,
      isCreateTab,
      setIsCreateTab,
      editedId,
      setEditedId,
      deletedId,
      setDeletedId,
      onSortEnd,
      handleSave,
      createTab,
      title,
      helperContainer: document.querySelector('.table-cloned-body-item'),
      ...element.props,
      ...tableSortableConfig,
    }))}
  </Container>
);

export default AdminTable;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  background: ${white};
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;
  overflow: hidden;
`;
