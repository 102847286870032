import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import {
  secondary,
  text01,
  text02,
  primary,
  white,
  grey,
  inputBorder,
} from '@/common/constants/theme';
import useOutsideAlerter from '../../../../../common/utils/useOutsideAlerter.util';
import SearchIcon from '../../icons/SearchIconAlt';

const Search = ({
  isDisabled,
  value,
  onChange,
  onSelect,
  options,
  width,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setIsFocused(false));

  return (
    <Container isDisabled={isDisabled}>
      <Wrapper ref={wrapperRef}>
        <Input
          disabled={isDisabled}
          value={value}
          onChange={({ target }) => onChange(target.value)}
          placeholder="Поиск"
          onFocus={() => setIsFocused(true)}
          width={width}
        />
        {isFocused && value && (
          <List center={!options.length}>
            {options.length ? options.map((item) => (
              <Item
                key={item?.key || `${item?.id || item}--search-item-key`}
                onClick={() => {
                  onSelect(item);
                  setIsFocused(false);
                }}
              >
                {item?.name || item}
              </Item>
            )) : <NoData>Нет результатов</NoData>}
          </List>
        )}
      </Wrapper>
      <Icon isDisabled={isDisabled}>
        <SearchIcon />
      </Icon>
    </Container>
  );
};

export default Search;

const Container = styled.div`
  display: flex;
  opacity: ${({ isDisabled }) => (isDisabled ? '.5' : '1')};
  transition: all .25s ease-in 0s;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: ${({ width }) => width || '230px'};
  margin-right: 2px;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${({ disabled }) => (disabled ? grey : secondary)};
  outline: none;
`;

const Icon = styled.div`
  cursor: pointer;
  & svg {
    fill: ${({ isDisabled }) => (isDisabled ? grey : secondary)};
  }
`;

const List = styled.div`
  ${({ center }) => center && `
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  height: 150px;

  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 4px);
  z-index: 999;

  background: ${white};
  border: 1px solid ${inputBorder};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

const Item = styled.div`
  display: flex;
  align-items: center;

  height: 28px;
  padding: 0 12px;

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  line-height: 17px;

  transition: 0.2s ease;

  &:hover {
    background-color: ${primary};

    color: ${white};
  }
`;

const NoData = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: ${text02};
  text-align: center;
  line-height: 15px;
  letter-spacing: 0.16px;
`;
