import RequestService from '../request.service';
import urls from '../routes/support.api';

export const getThreadsAction = ({ taskId }) => RequestService(urls.threads(taskId));

export const getThreadAction = ({ id, taskId }) => RequestService(urls.thread(id, taskId));

export const addThreadAction = ({ data, taskId }) => RequestService(
  urls.threads(taskId), { method: 'POST', body: !taskId ? data : null }, !!taskId,
);

export const markThreadReadAction = (threadId) => RequestService(
  urls.markThreadRead(threadId), { method: 'POST' },
);

export const closeThreadAction = ({ id, taskId }) => RequestService(
  urls.threadClose(id, taskId), { method: 'POST' },
);

export const addMessageAction = ({ threadId, data, taskId }) => RequestService(
  urls.thread(threadId, taskId), { method: 'POST', body: !taskId ? data : null }, !!taskId,
);

export const getUnreadThreadsCountAction = () => RequestService(urls.unreadThreadsCount);
