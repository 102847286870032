import React from 'react';

export default () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6562 17.9004C13.8872 17.9004 13.9174 15.9375 12.6562 15.9375C11.3889 15.9375 11.4303 17.9004 12.6562 17.9004Z" fill="url(#paint0_linear)" />
    <path d="M43.8281 0H16.1719C13.7164 0 11.7188 1.9977 11.7188 4.45312V13.2422C11.7188 13.7599 12.1385 14.1797 12.6562 14.1797C13.174 14.1797 13.5938 13.7599 13.5938 13.2422V4.45312C13.5938 3.03152 14.7503 1.875 16.1719 1.875H43.8281C45.2497 1.875 46.4062 3.03152 46.4062 4.45312V55.5469C46.4062 56.9685 45.2497 58.125 43.8281 58.125H16.1719C14.7503 58.125 13.5938 56.9685 13.5938 55.5469V50.1562H43.3594C43.8771 50.1562 44.2969 49.7365 44.2969 49.2188C44.2969 48.701 43.8771 48.2812 43.3594 48.2812H13.5938V20.874C13.5938 20.3563 13.174 19.9365 12.6562 19.9365C12.1385 19.9365 11.7188 20.3563 11.7188 20.874V55.5469C11.7188 58.0023 13.7164 60 16.1719 60H43.8281C46.2836 60 48.2812 58.0023 48.2812 55.5469V4.45312C48.2812 1.9977 46.2836 0 43.8281 0Z" fill="url(#paint1_linear)" />
    <path d="M26.8359 54.1406C26.8359 55.8853 28.2553 57.3047 30 57.3047C31.7447 57.3047 33.1641 55.8853 33.1641 54.1406C33.1641 52.3959 31.7447 50.9766 30 50.9766C28.2553 50.9766 26.8359 52.3959 26.8359 54.1406ZM31.2891 54.1406C31.2891 54.8514 30.7107 55.4297 30 55.4297C29.2893 55.4297 28.7109 54.8514 28.7109 54.1406C28.7109 53.4299 29.2893 52.8516 30 52.8516C30.7107 52.8516 31.2891 53.4299 31.2891 54.1406Z" fill="url(#paint2_linear)" />
    <path d="M32.6953 3.98438H27.3047C26.787 3.98438 26.3672 4.40414 26.3672 4.92188C26.3672 5.43961 26.787 5.85938 27.3047 5.85938H32.6953C33.213 5.85938 33.6328 5.43961 33.6328 4.92188C33.6328 4.40414 33.213 3.98438 32.6953 3.98438Z" fill="url(#paint3_linear)" />
    <path d="M22.3058 21.3684L20.4308 23.2434C20.0647 23.6096 20.0647 24.203 20.4308 24.5692C20.7971 24.9353 21.3905 24.9352 21.7567 24.5692L23.6317 22.6942C23.9978 22.328 23.9978 21.7346 23.6317 21.3684C23.2655 21.0024 22.672 21.0024 22.3058 21.3684Z" fill="url(#paint4_linear)" />
    <path d="M33.2629 18.7316L32.7062 19.2883C32.3401 19.6545 32.3401 20.2479 32.7062 20.6141C33.0725 20.9802 33.6659 20.9801 34.0321 20.6141L34.5887 20.0575C34.9548 19.6913 34.9548 19.0978 34.5887 18.7316C34.2225 18.3657 33.6291 18.3657 33.2629 18.7316Z" fill="url(#paint5_linear)" />
    <path d="M22.1886 31.1317C22.555 31.4978 23.1483 31.4977 23.5145 31.1317L31.4832 23.163C31.8493 22.7968 31.8493 22.2033 31.4832 21.8371C31.117 21.4711 30.5236 21.4711 30.1574 21.8371L22.1886 29.8058C21.8225 30.1721 21.8225 30.7655 22.1886 31.1317Z" fill="url(#paint6_linear)" />
    <path d="M36.5441 24.7082L32.6183 28.634C32.2522 29.0002 32.2522 29.5936 32.6183 29.9598C32.9846 30.3259 33.578 30.3258 33.9442 29.9598L37.87 26.0341C38.2361 25.6678 38.2361 25.0744 37.87 24.7082C37.5038 24.3422 36.9103 24.3422 36.5441 24.7082Z" fill="url(#paint7_linear)" />
    <path d="M32.3839 37.577C32.7503 37.9431 33.3436 37.943 33.7098 37.577L39.8036 31.4833C40.1696 31.1171 40.1696 30.5236 39.8036 30.1574C39.4373 29.7914 38.8439 29.7914 38.4777 30.1574L32.3839 36.2512C32.0179 36.6174 32.0179 37.2108 32.3839 37.577Z" fill="url(#paint8_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="13.5908" y1="16.9176" x2="11.7212" y2="16.9176" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="48.2813" y1="29.9584" x2="11.7188" y2="29.9584" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="33.1641" y1="54.1362" x2="26.8359" y2="54.1362" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="33.6328" y1="4.92058" x2="26.3672" y2="4.92058" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint4_linear" x1="23.9062" y1="22.9662" x2="20.1563" y2="22.9662" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint5_linear" x1="34.8633" y1="19.6712" x2="32.4316" y2="19.6712" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint6_linear" x1="31.7578" y1="26.4776" x2="21.9141" y2="26.4776" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint7_linear" x1="38.1445" y1="27.33" x2="32.3438" y2="27.33" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint8_linear" x1="40.0781" y1="33.8617" x2="32.1094" y2="33.8617" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
    </defs>
  </svg>
);
