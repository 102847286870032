import React from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  background01,
  text01,
  primary,
} from '@/common/constants/theme';
import { Link } from 'react-router-dom';
import { useAdminDNSContext } from '../DNSContext';

const AdminDNSUsersTable = ({ activeRow, search }) => {
  const { domains } = useAdminDNSContext();

  return (
    <Container isActive={activeRow >= 0}>
      <Header>
        <HeaderItem>
          Пользователи
        </HeaderItem>
      </Header>
      <Body>
        {domains.length ? activeRow >= 0 && domains
          .filter((domain) => domain.url.toLowerCase().includes(search.toLowerCase()))[activeRow]
          ?.dns_list.map((domain) => (
            <Row key={`${domain.dns}${domains[activeRow].url}`}>
              <RowItem>
                <StyledLink to={`/admin/users/main/${domain.user_id}`}>
                  {`${domain.dns}.${domains[activeRow].url}`}
                </StyledLink>
              </RowItem>
            </Row>
          )) : null}
      </Body>
    </Container>
  );
};

export default AdminDNSUsersTable;

const Container = styled.div`
  width: 60%;

  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  filter: drop-shadow(0px 0px 20px rgba(192, 192, 192, 0.25));
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  height: 58px;
  padding-left: 20px;
  padding-right: 40px;

  background: ${background01};
  border-bottom: 1px solid ${borderSecondary};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const HeaderItem = styled.div`
  width: ${({ width }) => width};

  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  color: ${text01};
  text-transform: uppercase;
  line-height: 20px;

  @media (max-width: 1360px) {
    ${({ width, adaptive }) => width && (`width: calc(${width} - ${adaptive || 10}px);`)};

    font-size: 11px;
  }

  @media (max-width: 1198px) {
    ${({ width }) => width && (`width: ${width};`)};

    font-size: 14px;
  }
`;

const Body = styled.div`
  min-height: 172px;
  max-height: 600px;
  overflow-y: auto;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  height: 43px;
  padding-left: 20px;
  padding-right: 40px;

  &:nth-child(2n) {
    background-color: ${background01};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${borderSecondary};
  }
`;

const RowItem = styled.div`
  width: ${({ width }) => width};

  font-family: Gilroy;
  font-size: 13px;
  color: ${text01};
  line-height: 15px;
`;

const StyledLink = styled(Link)`
  font-family: Gilroy;
  font-size: 14px;
  line-height: 20px;
  color: ${text01};

  &:hover {
    color: ${primary};
    text-decoration: underline;
  }
`;
