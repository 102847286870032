import React, { useEffect, useState, useRef } from 'react';
import { useTranslation, getI18n } from 'react-i18next';

import styled from 'styled-components';
import {
  text01,
  primary,
  background01,
  borderSecondary,
  activeTertiary,
  white,
} from '@/common/constants/theme';

import Tooltip from '@/common/components/Tooltip';
import Dropdown from '@/common/components/Dropdown';
import ReloadIcon from '@/assets/icons/reload.svg';
import DownloadIcon from '@/assets/icons/download.svg';
import FilledDocIcon from '@/assets/icons/filledDoc.svg';
import DocIcon from '@/assets/icons/doc.svg';
import { Radio } from '@/common/components/RadioButton';

import ContentContainer from './ContentContainer';
import DeviceSelector from './DeviceSelector';
import CredentionInput from './CredentionInput';
import Docs from './Docs';
import Links from './Links';

import { SHOW_DNS_CONTROLS, usePersonalPageContext } from '../../../../PersonalPageContext';
import { usePlaylistContext } from '../PlaylistContext';

import {
  bindDeviceToDnsAction,
  unbindDeviceToDnsAction,
} from '@/services/actions/devices.actions';
import { bindDeviceToServerAction } from '@/services/actions/server.actions';

const BLOCK_DOCS_ID = 'playlist_docs';

const PlaylistDetails = ({ isFullWidth }) => {
  const {
    allDns,
    getPlayListDownloadLinks,
    refreshOTTId,
    playlistOses,
    allPlayers,
    updateDeviceParameters,
    isPageHeaderDisabled,
    handleClosePageHeader,
  } = usePersonalPageContext();

  const {
    selectedDevice,
    setDevicePlaylistType,
    selectedDnsId,
    setSelectedDnsId,
  } = usePlaylistContext();

  const { t } = useTranslation('translations');

  const refContainer = useRef(null);

  const [selectedPlaylistOs, setSelectedPlaylistOs] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const [downloadLinks, setDownloadLinks] = useState([]);
  const [dnsDropdown, setDnsDropdown] = useState([]);
  const [isDnsDropdownDisabled, setIsDnsDropdownDisabled] = useState(false);
  const [disableDnsToolTip, setDisableDnsToolTip] = useState(false);

  const changeSelectedDns = (dnsId) => {
    const useDns = (allDns || []).find((item) => (item.id === dnsId));
    const selectedDns = (allDns || []).find((item) => (item.id === selectedDnsId));
    if (!useDns || !selectedDns) {
      return;
    }
    if ((dnsId === 0) && (selectedDns.id !== 0)) {
      unbindDeviceToDnsAction(selectedDevice.id);
    } else {
      bindDeviceToDnsAction(selectedDevice.id, dnsId);
    }
    if ((dnsId !== 0) && (useDns.serverId !== selectedDns.serverId)) {
      bindDeviceToServerAction(selectedDevice.id, useDns.serverId);
    }
    updateDeviceParameters(selectedDevice.id, { dns: dnsId });
    setSelectedDnsId(dnsId);
  };

  const changeDoc = (player) => {
    const currentLng = getI18n().language;
    const selectedLng = player.description.find((desc) => (desc.lang === currentLng)) ? currentLng : 'ru';
    const docItems = player.description.find((desc) => (desc.lang === selectedLng)).items;
    const result = {
      id: player.id,
      url: player.description_url,
      items: docItems,
    };
    setSelectedDoc(result);
  };

  const getLinks = (player, v = 1) => getPlayListDownloadLinks(
    selectedDevice,
    (allDns || []).find((o) => o.id === (selectedDnsId || 0)),
    player,
    v,
  );

  const downloadPlaylist = (player) => {
    const { url } = getLinks(player);
    // eslint-disable-next-line no-console
    console.log('downloadPlaylist url', url);
    window.open(url, '_blank');
  };

  const changePlaylistOs = (playlistOs) => {
    setSelectedPlaylistOs(playlistOs);
    const usePlaylistType = (playlistOs?.playlist_types || [])
      .find((plt) => plt.id === (selectedDevice?.playlist_type || -1))
      || null;
    if (usePlaylistType) {
      changeDoc(usePlaylistType);
    }
  };

  useEffect(() => {
    if ((allDns || []).length > 0) {
      setDnsDropdown(allDns.map((item) => ({ title: item.name, key: item.id })));
      setIsDnsDropdownDisabled(allDns.filter((item) => item.id !== 0).length === 0);
    }
  }, [allDns]);

  useEffect(() => {
    if (((playlistOses || []).length > 0) && !selectedPlaylistOs) {
      changePlaylistOs(playlistOses[0]);
    }
  }, [playlistOses]);

  useEffect(() => {
    const usePlayer = (allPlayers || []).find((p) => p.id === (selectedDevice?.playlist_type || 0));
    setDownloadLinks(usePlayer ? getLinks(usePlayer) : null);
  }, [
    allPlayers, selectedDnsId,
    selectedDevice?.id, selectedDevice?.ott_id, selectedDevice?.playlist_type,
  ]);

  useEffect(() => {
    if (selectedDevice && selectedPlaylistOs) {
      const usePlaylistType = selectedPlaylistOs.playlist_types
        .find((plt) => plt.id === (selectedDevice?.playlist_type || -1))
        || null;

      setSelectedPlayer(usePlaylistType);
    }
  }, [
    playlistOses, selectedDevice, selectedPlaylistOs,
  ]);

  const RenderHeader = () => (
    <HeaderRow>
      <HeaderCell>
        <DeviceSelector />
      </HeaderCell>
      <HeaderCell
        centerContent={SHOW_DNS_CONTROLS ? 'center' : 'end'}
      >
        <HeaderLabel>OTT ID</HeaderLabel>
        <Img
          src={ReloadIcon}
          onClick={() => refreshOTTId(selectedDevice.id)}
        />
        <CredentionInput
          value={selectedDevice?.ott_id || ''}
          label="ottId"
          disabled
          style={{ width: '152px' }}
        />
      </HeaderCell>
      {SHOW_DNS_CONTROLS && (
        <HeaderCell
          centerContent="end"
        >
          <Tooltip
            title={t(`steps.step5.content.dnsSelector.${isDnsDropdownDisabled ? 'inActiveTooltipText' : 'activeTooltipText'}`)}
            trigger="hover"
            width={150}
            position="right"
            isDisabled={disableDnsToolTip}
          >
            <StyledDropdown
              options={dnsDropdown}
              value={dnsDropdown?.find((item) => item.key === selectedDnsId)}
              onSelect={(item) => (changeSelectedDns(item.key))}
              onListOpen={() => setDisableDnsToolTip(true)}
              onListClose={() => setDisableDnsToolTip(false)}
              isBoldText
              isUpperCase
              color={primary}
              placeholder={t('completion.playlist.chooseDns')}
              isDisabled={isDnsDropdownDisabled}
              itemsShow={10}
            />
          </Tooltip>
        </HeaderCell>
      )}
    </HeaderRow>
  );

  const RenderFooter = () => {
    if (selectedDevice && selectedDevice.view_type === 'playlist') {
      return (
        <>
          {!isPageHeaderDisabled?.[BLOCK_DOCS_ID] && (
            <Docs
              doc={selectedDoc}
              isOpen={selectedDoc}
              onClose={() => {
                setSelectedDoc(null);
                handleClosePageHeader(BLOCK_DOCS_ID);
              }}
            />
          )}
          {downloadLinks && (
            <Links
              selectedDns={selectedDnsId}
              changeSelectedDns={changeSelectedDns}
              links={downloadLinks}
            />
          )}
        </>
      );
    }

    return null;
  };

  const RenderContent = () => (selectedDevice?.view_type === 'playlist') && (
    <TableBody>
      <Row>
        <Label>{t('completion.playlist.choosePlaylistType')}</Label>
        <TypesList>
          {(playlistOses || []).map((os) => (
            <TypeLabel
              key={`play_list_os_${os.name}`}
              isActive={os.name === selectedPlaylistOs?.name}
              onClick={() => changePlaylistOs(os)}
            >
              {os.name}
            </TypeLabel>
          ))}
        </TypesList>
      </Row>
      <PlayersSelect>
        <SelectedPlayerTypeLabel>
          {`${t('completion.playlist.playerFor')} ${selectedPlaylistOs?.name}`}
        </SelectedPlayerTypeLabel>
        <RadioGroupWrapper>
          {(selectedPlaylistOs?.playlist_types || []).map((player) => (
            <RadioWrapper key={player.id}>
              <Flex>
                <Radio
                  value={player.id}
                  name={player.id}
                  labelText={player.name}
                  onChange={() => {
                    setSelectedPlayer(player);
                    setDevicePlaylistType(selectedDevice.id, player.id);
                    changeDoc(player);
                  }}
                  checked={selectedPlayer?.id === player.id}
                />
                {player.is_archived && <Archive>{t('completion.playlist.archive')}</Archive>}
              </Flex>
              <Actions>
                <Img
                  title={t('completion.playlist.showPlaylistInfoTitle')}
                  src={selectedDoc?.id === player.id ? FilledDocIcon : DocIcon}
                  onClick={() => changeDoc(player)}
                />
                <Img
                  title={t('completion.playlist.downloadTitle')}
                  src={DownloadIcon}
                  onClick={() => downloadPlaylist(player)}
                />
              </Actions>
            </RadioWrapper>
          ))}
        </RadioGroupWrapper>
      </PlayersSelect>
    </TableBody>
  );

  return (
    <ContentContainer
      ref={refContainer}
      isFullWidth={isFullWidth}
    >
      <Text>
        {t('completion.playlist.title')}
      </Text>
      <Table>
        <TableHeader>
          {RenderHeader()}
        </TableHeader>
        {RenderContent()}
      </Table>
      {RenderFooter()}
    </ContentContainer>
  );
};

export default PlaylistDetails;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  white-space: pre-wrap;
  margin-bottom: 1rem;
`;

const Table = styled.div`
  border: 1px solid ${borderSecondary};
  border-radius: 4px 4px 0 0;
`;

const TableHeader = styled.div`
  background: ${background01};
  border: 1px solid ${borderSecondary};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
  margin: -1px;
`;

const HeaderRow = styled.div`
  display: flex;
  padding: .75rem 0;
  margin: 0 1.25rem;
`;

const HeaderLabel = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin-right: .25rem;
`;

const StyledDropdown = styled(Dropdown)`
  width: 13.5rem;
  @media (min-width: 1198px) and (max-width: 1360px) {
    width: unset;
  }
`;

const Img = styled.img`
  cursor: pointer;
  width: 14px;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${text01};
  white-space: pre-wrap;
`;

const Cell = styled.div`
  flex: 1;
`;

const HeaderCell = styled(Cell)`
  display: flex;
  align-items: center;
  ${({ centerContent }) => (centerContent && `justify-content: ${centerContent};`)}

  :not(:last-child) {
    border-right: 1px solid ${borderSecondary};
    margin-right: 1.25rem;
  }
`;

const TableBody = styled.div`
  padding-top: .5rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1.25rem;
  border-bottom: 1px solid ${borderSecondary};
`;

const TypesList = styled.div`
  display: flex;
`;

const TypeLabel = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: ${text01};
  border-radius: 2px;
  padding: 2px .5rem;
  margin: 0 .25rem;
  cursor: pointer;
  ${({ isActive }) => isActive && `
    background: ${activeTertiary};
    color: ${white};
  `}
`;

const PlayersSelect = styled.div``;

const SelectedPlayerTypeLabel = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: ${white};
  background: ${activeTertiary};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: .25rem 1.25rem;
  margin: 1.25rem 0;
  margin-left: -1px;
  width: fit-content;
`;

const RadioGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  & label {
    margin: .25rem 0;
  }
`;

const RadioWrapper = styled.div`
  padding: .25rem 1rem;
  width: 33%;
  border-right: 1px solid ${borderSecondary};
  display: flex;
  justify-content: space-between;

  & span {
    font-weight: 600;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :nth-child(3n) {
    border: none;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 2.25rem;
`;

const Flex = styled.div`
  display: flex;
`;

const Archive = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 11px;
  margin-top: -10px;
  margin-left: -3px;
  color: ${primary};
`;
