import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { secondary, text02, text03 } from '@/common/constants/theme';
import { ORDERING_DIRECTIONS } from '@/common/utils/publicConstants.util';

import {
  getTagsListAction,
  getFaqListAction,
  askUserQuestionAction,
  increaseViewsCountAction,
} from '@/services/actions/public/faq.actions';

import Loader from '@/common/components/Loader';
import TagsList, { CONTAINER_MODES } from '@/common/components/TagsList';
import PageContainer from '@/common/components/PageContainer';
import EyeIcon from '@/common/components/icons/EyeIcon';
import OrderingIcon from '@/common/components/icons/OrderingBarsIcon';
import FilterArrows from '@/common/components/icons/FilterArrows';

import FAQHeader from './components/FAQHeader';
import FAQMain from './components/FAQMain';
import AskForm from './components/AskForm';

import { useAppContext } from '@/AppContext';

// import MOCK_DATA from './mock.data';

const DEFAULT_ORDER = 100;
const DEFAULT_LANG_ID = 2;

// const PAGE_TITLE = 'FAQ page';
const PAGE_TITLE = '';

const ACTIVE_ORDER_COLOR = secondary;

const DATA_TYPES = {
  tags: 'tags',
  faqItems: 'faqItems',
};

const { none, ...USE_ORDERING_DIRECTIONS } = ORDERING_DIRECTIONS;

const ORDER_FIELDS = {
  number_of_views: 'number_of_views',
  order: 'order',
};

const ORDER_FIELDS_TYPES = {
  number_of_views: 'number',
  order: 'number',
  id: 'number',
  name: 'string',
};

const ORDER_ICONS = {
  [ORDER_FIELDS.number_of_views]: (isActive) => (
    <EyeIcon color={isActive ? ACTIVE_ORDER_COLOR : text03} />
  ),
  [ORDER_FIELDS.order]: (isActive) => (
    <OrderingIcon
      color={isActive ? ACTIVE_ORDER_COLOR : text02}
      size={18}
    />
  ),
};

const FILTER_FIELDS = {
  language: 'language',
  search: 'search',
  tag: 'tag',
  ordering: 'ordering',
  ordering_direction: 'ordering_direction',
};

const FAQ = () => {
  const { t, i18n } = useTranslation('translations');

  const [tagslist, setTagslist] = useState(null);
  const [FAQItems, setFAQItems] = useState(null);
  const [FAQItemsFiltered, setFAQItemsFiltered] = useState([]);

  const [isLoading, setIsLoading] = useState({
    [DATA_TYPES.tags]: false,
    [DATA_TYPES.faqItems]: false,
  });
  const [filters, setFilters] = useState({
    [FILTER_FIELDS.search]: '',
    [FILTER_FIELDS.tag]: 0,
    [FILTER_FIELDS.language]: DEFAULT_LANG_ID,
    [FILTER_FIELDS.ordering]: ORDER_FIELDS.number_of_views,
    [FILTER_FIELDS.ordering_direction]: ORDERING_DIRECTIONS.up,
  });
  const [isAskFormShow, setIsAskFormShow] = useState(false);

  const { setPageTitle, siteLanguages } = useAppContext();

  setPageTitle(PAGE_TITLE);

  const fixImgWidth = (item) => ({
    ...item,
    question_text: (item?.question_text || '').replace('img width="0%"', 'img width="auto"'),
  });

  const fixOrder = (item) => ({
    ...item,
    order: item.order || DEFAULT_ORDER,
  });

  const changeOrderingDirection = (currentDirection, isOrderTypeChanged = false) => {
    const oKeys = Object.keys(USE_ORDERING_DIRECTIONS);
    if (isOrderTypeChanged) {
      return oKeys[0];
    }
    const curIndex = oKeys.findIndex((od) => od === currentDirection);
    const setIndex = (curIndex + 1) >= oKeys.length ? 0 : curIndex + 1;
    return oKeys[setIndex];
  };

  const setLoader = (type, value) => {
    if (!DATA_TYPES[type]) {
      return;
    }
    setIsLoading((prevState) => (
      { ...prevState, [type]: value }
    ));
  };

  const setFilter = (type, value) => {
    if (!FILTER_FIELDS[type]) {
      return;
    }
    setFilters((prevState) => (
      {
        ...prevState,
        [type]: (
          type === FILTER_FIELDS.ordering_direction
            ? changeOrderingDirection(prevState[type])
            : value
        ),
      }
    ));
  };

  const setOrderFilter = (orderType) => {
    if (!ORDER_FIELDS[orderType]) {
      return;
    }
    setFilters((prevState) => (
      {
        ...prevState,
        [FILTER_FIELDS.ordering]: orderType,
        [FILTER_FIELDS.ordering_direction]: changeOrderingDirection(
          prevState[FILTER_FIELDS.ordering_direction],
          orderType !== prevState[FILTER_FIELDS.ordering],
        ),
      }
    ));
  };

  const loadTagsList = () => {
    setLoader(DATA_TYPES.tags, true);
    getTagsListAction()
      .then((res) => {
        const usedTagsIds = {};
        FAQItems.forEach((item) => {
          (item.tags || []).forEach((tagId) => {
            if (!usedTagsIds[tagId]) {
              usedTagsIds[tagId] = 0;
            }
            usedTagsIds[tagId] += 1;
          });
        });
        setTagslist(res.filter((tag) => usedTagsIds[tag.id])
          .map((tag) => ({
            ...tag,
            usedCount: usedTagsIds[tag.id],
          })));
      })
      .catch(() => setTagslist([]))
      .finally(() => setLoader(DATA_TYPES.tags, false));
  };

  const sortFAQItemsBy = (field, directn) => (a, b) => {
    const av = a?.[field] || '';
    const bv = b?.[field] || '';

    if (av === bv) { return 0; }

    let retVal = 0;
    switch (ORDER_FIELDS_TYPES[field]) {
      case 'number':
        retVal = (av > bv ? 1 : -1) * (directn === ORDERING_DIRECTIONS.down ? -1 : 1);
        break;
      case 'string':
        retVal = directn === ORDERING_DIRECTIONS.down
          ? bv.localeCompare(av)
          : av.localeCompare(bv);
        break;
      default:
        break;
    }
    return retVal;
  };

  const loadFAQItems = (filterValue) => {
    setLoader(DATA_TYPES.faqItems, true);
    getFaqListAction(filterValue)
      .then((res) => {
        setFAQItems(
          (res || [])
            .map(fixImgWidth)
            .map(fixOrder),
        );
      })
      .catch(() => {
        setFAQItems([]);
      })
      .finally(() => { setLoader(DATA_TYPES.faqItems, false); });
  };

  const filterFAQItems = (filterValue) => {
    if ((FAQItems || []).length === 0) {
      return [];
    }
    let filteredItems = FAQItems;
    // filter by tags
    if ((filterValue?.[FILTER_FIELDS.tag] || -1) > -1) {
      const st = filterValue?.[FILTER_FIELDS.tag] || -1;
      filteredItems = filteredItems.filter((item) => item.tags.includes(st));
    }

    // filter by search
    if ((filterValue?.[FILTER_FIELDS.search] || '').length > 0) {
      const sv = filterValue[FILTER_FIELDS.search].toLowerCase();
      filteredItems = filteredItems.filter((item) => item
        .question_title.toLowerCase().includes(sv));
    }
    // sort
    const orderField = filterValue?.[FILTER_FIELDS.ordering] || 'id';
    const orderDirection = filterValue?.[FILTER_FIELDS.ordering_direction]
      || ORDERING_DIRECTIONS.down;
    filteredItems = filteredItems.sort(sortFAQItemsBy(orderField, orderDirection));

    return [...filteredItems];
  };

  const handleIncreaseViewCount = (id) => {
    increaseViewsCountAction(id)
      .then(() => {
        const useItems = (FAQItems || []);
        if (useItems.length === 0) {
          return;
        }
        const listItemIndex = useItems.findIndex((item) => item.id === id);
        if (listItemIndex > -1) {
          const newItem = {
            ...useItems[listItemIndex],
            number_of_views: (useItems[listItemIndex]?.number_of_views || 0) + 1,
          };
          useItems.splice(listItemIndex, 1, newItem);
          setFAQItems(useItems);
        }
      });
  };

  const handleSelectTag = (tag) => {
    const useTagId = (filters[FILTER_FIELDS.tag] === (tag.id || 0)) ? 0 : tag.id;
    if (useTagId === filters[FILTER_FIELDS.tag]) {
      return;
    }
    setFilter(FILTER_FIELDS.tag, useTagId);
  };

  const handleFilterChanged = (filterValues) => {
    setFAQItemsFiltered(filterFAQItems(filterValues));
  };

  const handleSearch = (value) => {
    const updData = {
      [FILTER_FIELDS.search]: value,
    };
    if (((filters[FILTER_FIELDS.search] || '').length === 0) && ((value || '').length > 0)) {
      updData[FILTER_FIELDS.tag] = '';
    }
    setFilters((prevState) => ({ ...prevState, ...updData }));
  };

  useEffect(() => {
    if (!tagslist && FAQItems) {
      loadTagsList();
    }
  }, [tagslist, FAQItems]);

  useEffect(() => {
    if (siteLanguages) {
      const lang = (siteLanguages || []).find((l) => l.code === i18n.language);
      setFilter(FILTER_FIELDS.language, lang?.id || DEFAULT_LANG_ID);
      loadFAQItems({ [FILTER_FIELDS.language]: lang?.id || DEFAULT_LANG_ID });
    }
  }, [i18n.language, siteLanguages]);

  useEffect(() => {
    if (Object.keys(isLoading).filter((isl) => !isl).length > 0) {
      return;
    }
    handleFilterChanged(filters);
  }, [filters, FAQItems]);

  const renderTagsList = () => (
    <FAQTagsContainer>
      <TagsList
        tagsList={tagslist}
        selectTagHandler={handleSelectTag}
        activeTagText={((tagslist || []).find((tg) => tg.id === filters[FILTER_FIELDS.tag])?.title || '')}
        containerMode={CONTAINER_MODES.multiRow}
        showUsedCount
      />
    </FAQTagsContainer>
  );

  const renderOrderSelect = () => (
    <OrderSelect>
      <OrderTitle>{t('publicPages.FAQ.orderFields.caption')}</OrderTitle>
      {Object.keys(ORDER_FIELDS).map((of) => (
        <OrderField
          key={`PFAQ-OF-F-${of}`}
          title={`${t('publicPages.FAQ.orderFields.title')} ${t(`publicPages.FAQ.orderFields.${of}`).toLowerCase()}`}
          onClick={() => setOrderFilter(of)}
        >
          <OrderFieldIcons>
            {ORDER_ICONS[of](
              (of === filters[FILTER_FIELDS.ordering])
                && (filters[FILTER_FIELDS.ordering_direction] !== ORDERING_DIRECTIONS.none),
            )}
            <FilterArrows
              direction={
                (of === filters[FILTER_FIELDS.ordering])
                  ? filters[FILTER_FIELDS.ordering_direction]
                  : ORDERING_DIRECTIONS.none
              }
              size={16}
              color={
                (of === filters[FILTER_FIELDS.ordering])
                && (ORDERING_DIRECTIONS.none !== filters[FILTER_FIELDS.ordering_direction])
                  ? ACTIVE_ORDER_COLOR : text02
              }
            />
          </OrderFieldIcons>
        </OrderField>
      ))}
    </OrderSelect>
  );

  return (
    <Container>
      <PageContainerStyled>
        <FAQHeader
          search={filters[FILTER_FIELDS.search]}
          setSearch={handleSearch}
          bottomContent={renderTagsList()}
          leftContent={renderOrderSelect()}
        />
        <FAQMainContainer>
          {isLoading[DATA_TYPES.faqItems] ? (
            <Loader />
          ) : (
            (FAQItemsFiltered || []).length > 0 && (
              <FAQMain
                items={FAQItemsFiltered}
                openAskForm={() => setIsAskFormShow(true)}
                increaseViewCountHandler={handleIncreaseViewCount}
              />
            )
          )}
        </FAQMainContainer>
        {isAskFormShow && (
          <AskForm
            submitFormAction={askUserQuestionAction}
            setFormShown={setIsAskFormShow}
          />
        )}
      </PageContainerStyled>
    </Container>
  );
};

export default FAQ;

const PageContainerStyled = styled(PageContainer)`
  position: relative;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 63px;
  margin-bottom: 140px;
`;

const FAQMainContainer = styled.div`
  position: relative;
`;

const FAQTagsContainer = styled.div`
  margin-top: 1rem;
`;

const OrderFieldIcons = styled.div`
  display: flex;
  align-items: center;

  & :not(:last-child) {
    margin-right: 0.25rem;
  }
`;

const OrderField = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const OrderTitle = styled.div`
  margin-right: 1rem;
`;

const OrderSelect = styled.div`
  display: flex;
  align-items: center;

  ${OrderField} {
    margin-right: 1rem;
  }
`;
