import React from 'react';

export default () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#visNotIconClip0)">
      <path d="M10.4255 11.6343C9.27309 11.6343 8.33655 12.5708 8.33655 13.7232C8.33655 14.8757 9.27309 15.8122 10.4255 15.8122C11.5779 15.8122 12.5145 14.8757 12.5145 13.7232C12.5145 12.5708 11.5779 11.6343 10.4255 11.6343Z" fill="#797979" />
      <path d="M10.4255 8.50098C6.94388 8.50098 3.97058 10.6665 2.76593 13.7234C3.97058 16.7803 6.94388 18.9459 10.4255 18.9459C13.9106 18.9459 16.8805 16.7803 18.0851 13.7234C16.8805 10.6665 13.9106 8.50098 10.4255 8.50098ZM10.4255 17.205C8.50366 17.205 6.94388 15.6452 6.94388 13.7234C6.94388 11.8015 8.50366 10.2418 10.4255 10.2418C12.3474 10.2418 13.9072 11.8016 13.9072 13.7234C13.9072 15.6453 12.3474 17.205 10.4255 17.205Z" fill="#797979" />
    </g>
    <path d="M24.5 2L3 22.5" stroke="white" strokeWidth="4" />
    <path d="M22.5 4L1 24.5" stroke="#797979" strokeWidth="2" />
    <defs>
      <clipPath id="visNotIconClip0">
        <rect width="15.3191" height="15.3191" fill="white" transform="translate(2.76593 6.06396)" />
      </clipPath>
    </defs>
  </svg>
);
