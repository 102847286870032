import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

import { useGroupsContext } from './GroupsContext';
import GroupsTable from './components/Table';
import GroupsFilters from './components/Filters';
import AdminDeleteModal from '../../../../Modals/DeleteModal';
import GroupsImportCsv from './components/ImportCSV';

const Groups = () => {
  const {
    getGroups,
    deleteAllGroups,
    groupsPage,
  } = useGroupsContext();

  const [isCreateTab, setIsCreateTab] = useState(false);
  const [isDeletedAll, setIsDeletedAll] = useState(false);

  useEffect(() => {
    getGroups();
  }, [groupsPage]);

  return (
    <>
      <Route path="/admin/iptv/groups/import" component={GroupsImportCsv} />
      <Route path="/admin/iptv/groups" exact>
        <Container>
          <AdminDeleteModal
            isOpen={isDeletedAll}
            onClose={() => setIsDeletedAll(false)}
            handleDelete={() => deleteAllGroups()}
            content="все каналы и группы"
          />
          <GroupsFilters
            setIsCreateTab={setIsCreateTab}
            setIsDeletedAll={setIsDeletedAll}
          />
          <GroupsTable
            isCreateTab={isCreateTab}
            setIsCreateTab={setIsCreateTab}
          />
        </Container>
      </Route>
    </>
  );
};

export default Groups;

const Container = styled.div``;
