import RequestService from '../../request.service';
import urls from '../../routes/admin/templates.api';

export const getTemplatesLanguagesAction = () => RequestService(urls.templatesLanguagesList);

export const getTemplatesListAction = (
  typeFilter = null,
  queryFilter = null,
  page = null,
  limit = null,
) => {
  const params = {
    page,
    limit,
    type: typeFilter,
    q: queryFilter,
  };
  return RequestService(urls.templatesList, { params });
};

export const getTemplatesItemAction = (id) => RequestService(urls.templatesItem(id));

export const updateTemplateAction = (id, data) => RequestService(
  urls.templatesItem(id), { method: 'PATCH', body: data },
);

export const updateTemplatesContentAction = (id, data) => {
  const body = {
    html_content: data.html_content,
    text_content: data.text_content,
  };
  return RequestService(urls.templatesContent(id), {
    method: 'PATCH',
    body,
  });
};

export const uploadImageAction = (file, id) => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('template_content', id);

  return RequestService(urls.image(), {
    method: 'POST',
    body: formData,
  }, false);
};

export const deleteImageAction = (id) => RequestService(
  urls.image(id), { method: 'DELETE' },
);
