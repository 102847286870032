/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

import styled from 'styled-components';
import {
  primary, secondary,
  white, grey,
  text01, text02, text03,
} from '@/common/constants/theme';

import Tooltip from '@/common/components/Tooltip';
import Loader from '@/common/components/Loader';
import Button from '@/common/components/Button';
import ArrowLeftCustomizable from '@/common/components/icons/ArrowLeftCustomizable';
import CopyLinkIcon from '@/common/components/icons/CopyLinkIcon';

import FAQPagination from './FAQPagination';

import { getFaqItemAction } from '@/services/actions/public/faq.actions';

import { useAppContext } from '@/AppContext';

const ANSWERS_NAV_ARROW_SIZES = {
  height: 40,
  width: 24,
};

const ANSWERS_BLOCK_WIDTH = 716;
const QUESTIONS_BLOCK_MARGIN = 30;

const COPY_TOOLTIP_SHOW_TIME = 1000 * 1.5;

const FAQMain = ({
  items,
  openAskForm,
  increaseViewCountHandler,
}) => {
  const { pathname, hash } = useLocation();
  const history = useHistory();
  const mainContainerRef = useRef(null);

  let copyTooltipTimeout = useRef(null);

  const { t } = useTranslation('translations');
  const { copyText } = useAppContext();

  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
    limit: 6,
    count: 0,
  });

  const getBlocksWidths = () => ({
    q: (mainContainerRef.current
      ? mainContainerRef.current.offsetWidth - ANSWERS_BLOCK_WIDTH
      : ANSWERS_BLOCK_WIDTH) - QUESTIONS_BLOCK_MARGIN,
    a: ANSWERS_BLOCK_WIDTH,
  });

  const [useQuestionID, setQuestionID] = useState(0);
  const [selectedFAQItem, setSelectedFAQItem] = useState(null);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsCache, setQuestionsCache] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [blocksWidth, setBlocksWidth] = useState(getBlocksWidths());
  const [isCopyTooltipDisabled, setIsCopyTooltipDisabled] = useState(false);

  const getHash = (item) => (item.id || -1);

  const handleAnswerNav = (increment) => {
    if (!selectedFAQItem || ((selectedFAQItem.answers || []).length === 0)) return;

    let selectedAnswerIndexNew = selectedAnswerIndex + increment;
    if (selectedAnswerIndexNew > selectedFAQItem.answers.length - 1) {
      selectedAnswerIndexNew = selectedFAQItem.answers.length;
    }
    if (selectedAnswerIndexNew < 0) {
      selectedAnswerIndexNew = 0;
    }
    setSelectedAnswerIndex(selectedAnswerIndexNew);
  };

  const handlePaginationChange = (value) => {
    setPagination((prevState) => ({ ...prevState, page: value }));
  };

  const changeSelectedFaqItem = (Itm, setFaqItem = false) => {
    const currentPage = pagination.page || 1;
    let usePage = pagination.page || 1;

    const questionIndex = questions
      .findIndex((q) => q.id === selectedFAQItem.id);

    if (questionIndex > -1) {
      usePage = Math.ceil((questionIndex + 1) / (pagination.limit || 6));
    }

    if (currentPage !== usePage) {
      setPagination((prevState) => ({
        ...prevState,
        page: usePage,
      }));
    }

    if (!questionsCache[Itm.id]) {
      setIsLoading(true);
      getFaqItemAction(Itm.id)
        .then((res) => {
          const answers = (res?.answers || [])
            .map((a) => a.replace('img width="0%"', 'img width="auto"'));
          setQuestionsCache((prevState) => (
            { ...prevState, [Itm.id]: answers }
          ));
          setSelectedFAQItem((prevState) => ({ ...prevState, answers }));
          setSelectedAnswerIndex(answers.length > 0 ? 0 : null);
        })
        .catch(() => {
          setSelectedAnswerIndex(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const newSelectedItem = {
        ...Itm,
        answers: questionsCache[Itm.id],
      };
      const useAnswerIndex = (newSelectedItem.answers || []).length > 0 ? 0 : null;
      setSelectedFAQItem(newSelectedItem);
      setSelectedAnswerIndex(useAnswerIndex);
    }

    if (setFaqItem) { setSelectedFAQItem(Itm); }
  };

  useEffect(() => {
    if (items) {
      const useItems = items || [];
      setQuestions(useItems);
      if ((useItems.length > 0) && !selectedFAQItem) {
        setSelectedFAQItem(useItems[0]);
      }
      setPagination((prevState) => ({
        ...prevState,
        page: 1,
        pageCount: Math.ceil(useItems.length / prevState.limit),
        count: useItems.length,
      }));
    }
  }, [items]);

  useEffect(() => {
    if (((questions || []).length === 0)
      || (useQuestionID <= 0)) {
      return;
    }

    const useFaqItem = questions.find((itm) => itm.id === useQuestionID);
    changeSelectedFaqItem(useFaqItem, true);

    if ((useQuestionID > 0) && increaseViewCountHandler) {
      increaseViewCountHandler(useQuestionID);
    }

    setQuestionID(-1);
  }, [questions, useQuestionID]);

  useEffect(() => {
    const x = questions
      .slice(pagination.limit * (pagination.page - 1), pagination.limit * pagination.page)
      .map((item) => ({
        ...item,
        answers: questionsCache[item.id] || null,
      }));
    setDisplayedQuestions(x);
  }, [questions, pagination]);

  useEffect(() => {
    if ((questions?.length === 0)) {
      return;
    }
    if (selectedFAQItem?.id) {
      changeSelectedFaqItem(selectedFAQItem);
    }
  }, [selectedFAQItem?.id, questions?.length]);

  useEffect(() => {
    if ((mainContainerRef?.current?.offsetWidth || 0) > 0) {
      setBlocksWidth(getBlocksWidths());
    }
  }, [mainContainerRef?.current?.width]);

  useEffect(() => {
    if (hash.length > 0) {
      setQuestionID(hash.length > 0
        ? parseInt(hash.replace('#', ''), 10)
        : -1);
      history.replace(pathname);
    }
  }, [hash]);

  useEffect(() => () => {
    if (copyTooltipTimeout) {
      clearTimeout(copyTooltipTimeout);
    }
  }, [copyTooltipTimeout]);

  const renderNavArrow = (reversed) => {
    const isControlDisabled = reversed
      ? (selectedAnswerIndex >= (selectedFAQItem?.answers || []).length - 1)
      : (selectedAnswerIndex <= 0);
    return (
      <NavControl
        disabled={isControlDisabled}
        reversed={reversed}
        onClick={() => !isControlDisabled && handleAnswerNav(reversed ? 1 : -1)}
      >
        <ArrowLeftCustomizable
          height={ANSWERS_NAV_ARROW_SIZES.height}
          width={ANSWERS_NAV_ARROW_SIZES.width}
        />
      </NavControl>
    );
  };

  return (
    <Container>
      <Wrapper flex flexSb ref={mainContainerRef}>
        <QuestionsWrapper topMargin width={blocksWidth.q}>
          <Wrapper fullHeight>
            {displayedQuestions.length > 0 ? displayedQuestions.map((item) => (
              <Question
                key={`FAQ--Q-${item?.id || -1}`}
                active={selectedFAQItem?.id === item.id}
                onClick={() => {
                  if (selectedFAQItem?.id === item.id) {
                    return;
                  }
                  setSelectedFAQItem(item);
                  setSelectedAnswerIndex((item?.answers || []).length > 0
                    ? 0 : null);
                  if (increaseViewCountHandler) {
                    increaseViewCountHandler(item.id);
                  }
                }}
              >
                {item.question_title}
                <CopyLinkContainer>
                  <Tooltip
                    title={t('publicPages.FAQ.copyLinkSuccessText')}
                    type="success"
                    position="right"
                    width={170}
                    trigger="click"
                    isDisabled={isCopyTooltipDisabled}
                    stopPropagation
                  >
                    <CopyLink
                      title={t('publicPages.FAQ.copyLinkTitle')}
                      onClick={(e) => {
                        e.preventDefault();
                        copyText(
                          `${window.location.href}#${getHash(item)}`,
                        );
                        setIsCopyTooltipDisabled(false);
                        if (copyTooltipTimeout) {
                          clearTimeout(copyTooltipTimeout);
                        }
                        copyTooltipTimeout = setTimeout(
                          () => setIsCopyTooltipDisabled(true),
                          COPY_TOOLTIP_SHOW_TIME,
                        );
                      }}
                    >
                      <CopyLinkIcon
                        color={primary}
                      />
                    </CopyLink>
                  </Tooltip>
                </CopyLinkContainer>
              </Question>
            )) : (
              <EmptyQuestionMessage>
                {t('publicPages.FAQ.noQuestionsOnFilter')}
              </EmptyQuestionMessage>
            )}
          </Wrapper>
          <Wrapper flex alignCenter>
            <Button
              isUpperCase
              isBoldText
              color="secondary"
              onClick={() => openAskForm && openAskForm()}
            >
              {t('publicPages.FAQ.btn')}
            </Button>
            <Pagination>
              {(pagination.pageCount > 1) && (
                <FAQPagination
                  pageCount={pagination.pageCount}
                  page={pagination.page || 1}
                  pageHandler={handlePaginationChange}
                />
              )}
            </Pagination>
          </Wrapper>
        </QuestionsWrapper>
        <Wrapper flex width={blocksWidth.a} alignCenter>
          {isLoading && <Loader />}
          {(questionsCache?.[selectedFAQItem?.id] || []).length > 0 ? (
            <StepsContainer>
              <Text isQuestion>
                {ReactHtmlParser(questionsCache[selectedFAQItem.id][selectedAnswerIndex])}
              </Text>
              {((questionsCache?.[selectedFAQItem?.id] || []).length > 1) && (
                <ArrowContainer>
                  {renderNavArrow()}
                  {renderNavArrow(true)}
                </ArrowContainer>
              )}
            </StepsContainer>
          ) : (
            <StepsContainer>
              <Question
                useOpacity
              >
                {t('publicPages.FAQ.noResults')}
              </Question>
            </StepsContainer>
          )}
          {(questionsCache?.[selectedFAQItem?.id] || []).length > 1 && (
            <StepsIndicatorsContainer>
              {(questionsCache?.[selectedFAQItem?.id] || []).map((item, index) => (
                <StepIndicator
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`FAQ--SIC-item-${selectedFAQItem.id}-${index}`}
                  active={selectedAnswerIndex === index}
                  onClick={() => setSelectedAnswerIndex(index)}
                />
              ))}
            </StepsIndicatorsContainer>
          )}
        </Wrapper>
      </Wrapper>
    </Container>
  );
};

export default FAQMain;

const Container = styled.div``;

const Wrapper = styled.div`
  position: relative;
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  ${({ alignCenter }) => alignCenter && ('align-items: center;')}

  ${({ width }) => width && (`width: ${width}px;`)}
  ${({ fullHeight }) => (fullHeight && 'height: 100%;')}
  ${({ topMargin }) => topMargin && ('margin-top: 20px;')}
`;

const QuestionsWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Pagination = styled.div`
  flex: max-content;
`;

const StepsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 552px;
  padding: 26px 23px 40px 27px;

  border: 1px dashed rgba(92, 112, 207, 0.5);
  border-radius: 16px;
`;

const Text = styled.div`
  max-width: 100%;
  max-height: 485px;

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${text01};
  white-space: pre-wrap;
  line-height: 1.5;
  letter-spacing: 0.16px;

  ${({ isQuestion }) => (isQuestion && 'overflow-y: auto;')}

  & figure > img {
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    max-width: 100%;
  }

  p {
    display: block;
    margin: 0 0 10px;
    white-space: normal;
  }

  ul, ol {
    list-style-position: outside;
    display: block;
    margin-top: 0;
    margin-bottom: 1em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  ul > li, ol > li {
    display: list-item;
    text-align: -webkit-match-parent;
    margin-bottom: 5px;
  }

  ol {
    list-style-type: decimal;
  }
`;

const ArrowContainer = styled.div`
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavControl = styled.div`
  width: auto;
  font-size: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ reversed }) => (reversed && 'transform: rotate(180deg);')}

  & svg {
    fill: ${({ disabled }) => (disabled ? grey : primary)};
    opacity: ${({ disabled }) => (disabled ? 0.25 : 0.65)};
    transition: all 0.25s ease-in-out 0s;
  }

  &:hover svg {
    fill: ${({ disabled }) => (disabled ? grey : secondary)};
    opacity: ${({ disabled }) => (!disabled && 1)};
  }
`;

const CopyLinkContainer = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
`;

const CopyLink = styled.div`
  background: ${white};
  padding: 0.4rem 0.4rem 0.5rem 0.5rem;
  height: calc(24px + 1rem);
  // border: solid 1px ${primary};
  border-radius: 50%;
  opacity: 0;
  overflow: hidden;
  transition: all 0.125s ease-in 0s;
  cursor: pointer;

  &:hover svg {
    fill: ${secondary};
  }
`;

const Question = styled(Text)`
  position: relative;
  margin-bottom: 35px;

  cursor: ${({ active }) => (active ? 'default' : 'pointer')};

  font-weight: 600;
  font-size: 24px;
  ${({ active }) => active && (`color: ${secondary};`)}
  ${({ useOpacity }) => useOpacity && (`
    cursor: default;

    color: ${text02};
    text-align: center;
  `)}
  line-height: 28px;

  transition: color 0.2s ease;

  &:last-child {
    margin-bottom: 48px;
  }

  &:hover ${CopyLink} {
    opacity: 1;
  }
`;

const EmptyQuestionMessage = styled(Text)`
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 66%;
  height: 3em;
  margin: auto;

  font-size: 1.5em;
  text-align: center;
  font-weight: 500;
  color: ${text03};
`;

const StepsIndicatorsContainer = styled.div`
  margin-top: -100px;
  margin-left: -4px;

  position: relative;

  &::after {
    content: '';

    width: 20px;

    position: absolute;
    top: -30px;
    bottom: -30px;
    z-index: 0;

    background-color: ${white};
  }
`;

const StepIndicator = styled.div`
  width: 7px;
  height: 7px;
  margin-bottom: 14px;

  position: relative;
  z-index: 1;

  border: 1px solid ${text01};
  border-radius: 100px;
  box-sizing: border-box;
  opacity: 0.4;
  cursor: pointer;

  ${({ active }) => active && (`
    width: 10px;
    height: 10px;
    margin-left: -1.5px;

    border: 2px solid ${secondary};
    opacity: 1;
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`;
