import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LeftArrowSrc from '@/assets/icons/arrow-left-long.svg';
import {
  inputBorder,
  white,
  text02,
  secondary,
  borderSecondary,
  grey,
} from '@/common/constants/theme';
import Tooltip from '@/common/components/Tooltip';
import VisibilityIcon from '../../../../../icons/VisibilityIcon';
import PlusIcon from '../../../../../icons/PlusIcon';
import { useChannelsContext } from '../../../ChannelsContext';
import ChannelsMainCreateModal from './MainModals/ChannelsMainCreateModal';
import SnackBarWindow from '../../../../../../../../common/components/SnackBarWindow';

const ChannelsMainFilters = ({ isFullWidth, changeFiltersHandler }) => {
  const { t } = useTranslation('translations');
  const {
    groups,
    channelsFilters,
    handleHideAllGroups,
    handleDisplayAllGroups,
    handleCreateGroup,
    selectedPlaylist,
    defaultPlaylist,
  } = useChannelsContext();

  const { isGroups } = channelsFilters;

  const [isModal, setModal] = useState(false);
  const [isFinalModal, setIsFinalModal] = useState(false);

  const isEditable = selectedPlaylist
    && (selectedPlaylist?.id !== defaultPlaylist?.id)
    && !selectedPlaylist?.is_virtual;

  return (
    <Container>
      <ChannelsMainCreateModal
        isOpen={isModal}
        onClose={() => setModal(false)}
        onSubmit={(title) => {
          handleCreateGroup(title);
          setIsFinalModal(true);
        }}
        title={t('steps.step4.content.body.modal.createGroupTitle')}
        descr={t('steps.step4.content.body.modal.groupDescr')}
        error={t('steps.step4.content.body.modal.errors.minGroupLength')}
      />
      <SnackBarWindow
        isOpen={isFinalModal}
        onClose={() => setIsFinalModal(false)}
        type="success"
        content={t('steps.step4.content.body.modal.createGroupSuccess')}
      />
      <Wrapper flex alignCenter rightMargin>
        <VisibilityWrapper
          isFullWidth={isFullWidth}
          isGroups={isGroups}
          opacity={!isEditable ? 'true' : 'false'}
        >
          <Wrapper
            flex
            alignCenter
            onClick={isEditable ? handleDisplayAllGroups : () => {}}
          >
            <VisibilityIconWrapper
              isActive={groups.map((item) => !item.isHidden).find((item) => item)
                && !selectedPlaylist?.is_virtual}
            >
              <VisibilityIcon />
            </VisibilityIconWrapper>
            <Text
              color={(groups.map((item) => !item.isHidden).find((item) => item)
                && !selectedPlaylist?.is_virtual)
                ? secondary
                : text02}
            >
              {t('steps.step4.content.body.displayAll')}
            </Text>
          </Wrapper>
          <Text
            leftSep
            color={groups.map((item) => !item.isHidden).find((item) => item) ? text02 : secondary}
            onClick={isEditable ? handleHideAllGroups : () => {}}
          >
            {t('steps.step4.content.body.hideAll')}
          </Text>
        </VisibilityWrapper>
      </Wrapper>
      <Wrapper flex alignCenter>
        <Icon
          rightMargin
          top
          isGroups={isGroups}
          onClick={() => {
            if (isEditable && !selectedPlaylist?.is_virtual) {
              setModal(true);
            }
          }}
          opacity={!isEditable || selectedPlaylist?.is_virtual ? 'true' : 'false'}
        >
          <PlusIcon />
        </Icon>
        <TooltipWrapper isGroups={isGroups}>
          <Tooltip
            title={isGroups ? t('steps.step4.content.body.hideGroups')
              : t('steps.step4.content.body.displayGroups')}
            trigger="hover"
            width={180}
            position="left"
          >
            <Icon
              onClick={() => changeFiltersHandler('isGroups', !isGroups)}
              isGroups={isGroups}
              arrow
            >
              <Img
                src={LeftArrowSrc}
                alt=""
                isGroups={isGroups}
              />
            </Icon>
          </Tooltip>
        </TooltipWrapper>
      </Wrapper>
    </Container>
  );
};

export default ChannelsMainFilters;

const Container = styled.div`
  display: flex;

  padding: 15px 0 15px 20px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ alignCenter }) => alignCenter && ('align-items: center;')}
  ${({ rightMargin }) => rightMargin && ('margin-right: 11px;')}
`;

const VisibilityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: ${({ isFullWidth }) => (isFullWidth ? '282px' : '221px')};
  height: 32px;
  padding: 0 10px;

  background: ${white};
  border: 1px solid ${inputBorder};
  box-sizing: border-box;
  border-radius: 2px;

  transition: 0.4s ease;

  ${({ isGroups }) => !isGroups && ('margin-left: -300px;')}

  ${({ opacity }) => `
    opacity: ${opacity === 'true' ? 0.2 : 1};
    cursor: ${opacity === 'true' ? 'default' : 'pointer'};
  `}
`;

const Text = styled.div`
  ${({ pointer }) => pointer && ('cursor: pointer;')}

  font-family: Gilroy;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: ${({ color }) => color};

  transition: 0.2s ease;

  ${({ leftSep }) => leftSep && (`
    position: relative;

    &::after {
      content: '';

      height: 18px;
      width: 1px;

      position: absolute;
      top: 0px;
      left: -9px;

      background-color: ${borderSecondary};
    }
  `)}
`;

const TooltipWrapper = styled.div`
  ${({ isGroups }) => !isGroups && ('margin-left: 20px;')}
`;

const VisibilityIconWrapper = styled.div`
  margin-right: 5px;

  position: relative;
  top: 3px;

  & svg path {
    transition: 0.2s ease;
  }

  ${({ isActive }) => !isActive && (`
    & svg path {
      fill: ${grey};
      opacity: 0.6;
    }
  `)}
`;

const Icon = styled.div`
  ${({ rightMargin }) => rightMargin && ('margin-right: 14px;')}

  ${({ top }) => top && (`
    position: relative;
    top: 3px;
  `)}


  cursor: ${({ opacity }) => (opacity === 'true' ? 'default' : 'pointer')};

  transition: 0.4s ease;

  ${({ isGroups, arrow }) => !isGroups && (`margin-left: ${arrow ? 0 : -50}px;`)}

  opacity: ${({ opacity }) => (opacity === 'true' ? 0.2 : 1)};
`;

const Img = styled.img`
  transition: 0.4s ease;

  ${({ isGroups }) => !isGroups && ('transform: rotate(180deg);')}
`;
