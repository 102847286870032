import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import BaseContainer from './components/Base/BaseContainer';
import PlayersContainer from './components/Players/PlayersContainer';
import GeoContainer from './components/Geo/GeoContainer';
import GroupsContainer from './components/Groups/GroupsContainer';
import ServersContainer from './components/Servers/ServersContainer';
import DNSContainer from './components/DNS/DNSContainer';
import MinistraContainer from './components/Ministra/MinistraContainer';
import PcodesContainer from './components/PromoCodes/PcodesContainer';

const AdminIPTVContainer = () => (
  <Container>
    <Switch>
      <Route path="/admin/iptv/base" component={BaseContainer} />
      <Route path="/admin/iptv/players" component={PlayersContainer} />
      <Route path="/admin/iptv/geo" component={GeoContainer} />
      <Route path="/admin/iptv/groups" component={GroupsContainer} />
      <Route path="/admin/iptv/servers" component={ServersContainer} />
      <Route path="/admin/iptv/dns" component={DNSContainer} />
      <Route path="/admin/iptv/ministra" component={MinistraContainer} />
      <Route path="/admin/iptv/promocodes" component={PcodesContainer} />
      <Redirect to="/admin/iptv/base" />
    </Switch>
  </Container>
);

export default AdminIPTVContainer;

const Container = styled.div``;
