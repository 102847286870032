import React from 'react';
import styled from 'styled-components';

const ChannelsMainDraggedElement = ({
  x,
  y,
  children,
  onHover,
}) => (
  <Container
    x={x}
    y={y}
    onMouseEnter={onHover}
  >
    {children}
  </Container>
);

export default ChannelsMainDraggedElement;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 160px;
  height: 35px;

  position: absolute;
  ${({ x, y }) => x && y && (`
    left: ${x}px;
    top: ${y}px;
  `)}
  z-index: 999;

  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 152, 0, 0.9);
  box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.18);
  cursor: grabbing;
  user-select: none;
`;
