import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  activePrimary,
  white,
  secondary,
} from '@/common/constants/theme';

const ROOT = '/';

function Navigation({ isUserExist, isUserAuth, history }) {
  const { t } = useTranslation(['translations']);
  const path = history.location.pathname.slice(1).split('/')[0] || '';
  const color = isUserExist || !path ? white : activePrimary;

  const NAV_ITEMS = [
    {
      url: '',
      title: t('header.setup'),
    },
    {
      url: 'faq',
      title: t('header.FAQ'),
    },
    {
      url: 'news',
      title: t('header.news'),
      hide: !isUserAuth,
    },
    {
      url: 'copyrightHolders',
      title: t('header.holders'),
    },
  ];

  return (
    <Container>
      {NAV_ITEMS
        .filter((i) => !i.hide)
        .map((m) => (
          <NavItem
            key={`NAV-Item-${m.url}`}
            to={`${ROOT}${m.url}`}
            active={(path && (path === m.url)) ? 'true' : 'false'}
            color={color}
          >
            {m.title}
          </NavItem>
        ))}
    </Container>
  );
}

export default withRouter(Navigation);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const NavItem = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-decoration: none;
  color: ${({ color, active }) => (active === 'true' ? secondary : color)};
  margin: 0 1.5rem;
  font-weight: 500;

  &:hover {
      color: #ffab30;
    }
`;
