/* eslint-disable no-shadow */
import React, {
  createContext,
  useContext,
  useState,
} from 'react';

import {
  handleBlockCountryAction,
  handleUnblockCountryAction,
  handleUnblockAllCountriesAction,
} from '@/services/actions/admin/geo.actions';
import { useAdminContext } from '../../../../../AdminContext';

const GeoContext = createContext({});

const initialFilters = {
  page: 1,
};

export const GeoContextProvider = ({ children }) => {
  const { setChannels } = useAdminContext();
  const [filters, setFilters] = useState(initialFilters);

  const handleBlockCountry = (i, key) => setChannels(
    (prevState) => [...handleBlockCountryAction(prevState, i, key)],
  );

  const handleUnblockCountry = (i, key) => setChannels(
    (prevState) => [...handleUnblockCountryAction(prevState, i, key)],
  );

  const handleUnblockAllCountries = (i) => setChannels(
    (prevState) => [...handleUnblockAllCountriesAction(prevState, i)],
  );

  const value = {
    filters,
    setFilters,
    handleBlockCountry,
    handleUnblockCountry,
    handleUnblockAllCountries,
  };

  return (
    <GeoContext.Provider value={value}>
      {children}
    </GeoContext.Provider>
  );
};

export const useGeoContext = () => useContext(GeoContext);
