/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { createContext, useContext, useState } from 'react';
import {
  getCountriesAction,
  validateEmailAction,
  validatePasswordAction,
  validateReferralTokenAction,
  signUpAction,
  resendEmailAction,
  confirmEmailAction,
  forgetPasswordAction,
  changePasswordAction,
  signInAction,
  enterEmailAction,
  getUserMetaAction,
} from '@/services/actions/authorization.actions';
import auth from '@/services/auth.service';

export const AuthorizationContext = createContext({
  devices: [],
  filters: {},
});

export const AuthorizationContextProvider = ({ children }) => {
  const [countries, setCountries] = useState([]);
  const [userMeta, setUserMeta] = useState({});

  const getUserMeta = () => (
    getUserMetaAction()
      .then((res) => {
        setUserMeta(res);
        return res;
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
  );

  const getCountries = () => (
    getCountriesAction()
      .then((res) => {
        const countries = [];
        Object.keys(res).forEach((itemName) => {
          const item = res[itemName];
          countries.push({ key: itemName, title: item });
        });
        setCountries(countries);
        return countries;
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
  );

  const validateEmail = (email) => validateEmailAction(email);

  const validatePassword = (password) => validatePasswordAction(password);

  const validateReferralToken = (token, url) => (
    validateReferralTokenAction(token, url)
  );

  const signUp = (email, password, confirmedPassword, country, refToken, refClickId) => (
    signUpAction(email, password, confirmedPassword, country, refToken, refClickId)
  );

  const resendEmail = (email) => (
    resendEmailAction(email)
  );

  const confirmEmail = (token) => (
    confirmEmailAction(token)
  );

  const forgetPassword = (data) => forgetPasswordAction(data);

  const changePassword = (uid, token, password, confirmedPassword) => (
    changePasswordAction(uid, token, password, confirmedPassword)
  );

  const signIn = (email, password, remember) => {
    const data = { username: email, password };
    return signInAction(data)
      .then((res) => {
        if (res?.token) {
          auth.setToken(res.token, remember);
        }
        if (res?.token_expired) {
          auth.setTokenExpired(res.token_expired);
        }
        return res;
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const enterEmail = (type, clientId, email) => (
    enterEmailAction(type, clientId, email)
    // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
  );

  const value = {
    countries,
    userMeta,
    getCountries,
    getUserMeta,
    validateEmail,
    validatePassword,
    validateReferralToken,
    signUp,
    resendEmail,
    confirmEmail,
    forgetPassword,
    changePassword,
    signIn,
    enterEmail,
  };

  return (
    <AuthorizationContext.Provider value={value}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export const useAuthorizationContext = () => useContext(AuthorizationContext);
