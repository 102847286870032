import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  text01,
  white,
  primary,
  borderSecondary,
  text02,
} from '@/common/constants/theme';

import Dropdown from '@/common/components/Dropdown';
// eslint-disable-next-line import/no-named-default
import { BUTTON_SIZES, default as Button } from '@/common/components/Button';
import IconCopy from '@/assets/icons/copyicon.svg';
import Tooltip from '@/common/components/Tooltip';

const Links = ({
  dnsDropdown,
  selectedDns,
  changeSelectedDns,
  links,
}) => {
  const { t } = useTranslation('translations');

  const downloadUrl = (url) => {
    const urlUri = new URL(url);
    urlUri.protocol = window.location.protocol;
    window.open(urlUri.toString(), '_blank');
  };

  const otherLinks = [
    {
      id: '1',
      title: t('devicesInfo.directLinks'),
      urls: [
        links?.http || '',
        links?.https || '',
      ],
      customBlock: (
        <CustomBlockWrapper>
          <StyledLinksButton
            isUpperCase
            width={BUTTON_SIZES.medium}
            onClick={() => downloadUrl(links?.https || links?.http || '')}
          >
            {t('common.downloadText')}
          </StyledLinksButton>
        </CustomBlockWrapper>),
    },
    {
      id: '2',
      title: t('devicesInfo.sourcesOfEPG'),
      urls: links?.epgUrls ?? [],
    },
    {
      id: '3',
      title: t('devicesInfo.LITEsourcesOfEPG'),
      urls: links?.epgLiteUrls ?? [],
    },
    {
      id: '4',
      title: 'Picon',
      urls: links?.piconUrls ?? [],
    },
  ];

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

  const copyText = (elementId) => {
    const range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
  };

  let indexer = 0;

  return (
    <Container>
      <TitleRow>{t('completion.playlist.linksHelpText')}</TitleRow>
      <Wrapper>
        <LeftSide>
          {otherLinks.map((category, index) => (
            <Section
              key={category.id}
              isActive={selectedCategoryIndex === index}
              onClick={() => setSelectedCategoryIndex(index)}
            >
              {category.title}
            </Section>
          ))}
        </LeftSide>
        <RightSide>
          {dnsDropdown && (
            <DropdownWrapper>
              <StyledDropdown
                options={dnsDropdown}
                value={dnsDropdown?.find((item) => item.key === selectedDns)}
                onSelect={(item) => (changeSelectedDns(item.key))}
                isBoldText
                isUpperCase
                color={primary}
                placeholder={t('completion.playlist.chooseDns')}
                height="2rem"
              />
            </DropdownWrapper>
          )}
          <LinksWrapper>
            {/* eslint-disable-next-line no-return-assign */}
            {otherLinks[selectedCategoryIndex]?.urls.map((url) => (
              <UrlWrapper key={`play_list_links_item_${url || (indexer += 1)}`}>
                <Url id={`${url}-${selectedCategoryIndex.id}`}>{url}</Url>
                <Tooltip
                  title={`${t('link')} ${t('linkCopied')}`}
                  type="success"
                  position="right"
                  trigger="click"
                >
                  <Icon src={IconCopy} onClick={() => copyText(`${url}-${selectedCategoryIndex.id}`)} />
                </Tooltip>
              </UrlWrapper>
            ))}
            {otherLinks[selectedCategoryIndex].customBlock || ''}
          </LinksWrapper>
        </RightSide>
      </Wrapper>
    </Container>
  );
};

export default Links;

const Container = styled.div`
  margin-top: 30px;
  padding: 1.5rem 1.25rem;
  background: ${white};
  border: 1px dashed rgba(92, 112, 207, 0.5);
  box-shadow: 4px 4px 17px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: flex-end;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin: 0 .75rem .75rem;
`;

const Wrapper = styled.div`
  display: flex;
`;

const LeftSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Section = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${primary};
  border-radius: 4px;
  padding: .5rem .75rem;
  margin: .25rem 0;
  cursor: pointer;
  transition: all 0.25s ease;
  :hover {
    color: #9FAEF4;
  }
  ${({ isActive }) => isActive && `
    background: #9FAEF4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    color: ${white};
    :hover {
      color: ${white};
    }
  `}
`;

const RightSide = styled(LeftSide)`
  flex: 2;
  margin-left: 2rem;
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const LinksWrapper = styled.div`
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  min-height: 9.75rem;
`;

const CustomBlockWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  min-height: 60px;
  padding-right: 5px;
`;

const StyledLinksButton = styled(Button)`

`;

const UrlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1.25rem;
  border-bottom: 1px solid ${borderSecondary};
`;

const Url = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${text02}
  max-width: 29rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const StyledDropdown = styled(Dropdown)`
  width: 13.5rem;
`;
