import RequestService from '../request.service';
import urls from '../routes/referrals.api';
import dayjs from 'dayjs';

const normalizeDate = (date) => dayjs(date).format('YYYY-MM-DD');

export const getReferralsTransactionsAction = ({
  page, pageLimit, limit, startDate, endDate,
}) => {
  const fromDate = startDate ? normalizeDate(startDate) : '';
  const toDate = endDate ? normalizeDate(endDate) : '';
  return RequestService(urls.referralsTransactionsList(page, pageLimit || limit, fromDate, toDate));
};

export const getReferralsRequestsAction = (page, limit, pageLimit, startDate, endDate) => {
  const fromDate = startDate ? normalizeDate(startDate) : '';
  const toDate = endDate ? normalizeDate(endDate) : '';
  return RequestService(urls.referralsWithdrawalList(page, pageLimit || limit, fromDate, toDate));
};

export const getReferralsRequestsNewAction = ({
  page, limit, pageLimit, startDate, endDate,
}) => {
  const fromDate = startDate ? normalizeDate(startDate) : '';
  const toDate = endDate ? normalizeDate(endDate) : '';
  return RequestService(
    urls.referralsWithdrawalListNew(page, pageLimit || limit, fromDate, toDate),
  );
};

export const getReferralsLinksAction = ({
  page, limit, pageLimit, startDate, endDate,
}) => {
  const fromDate = startDate ? normalizeDate(startDate) : '';
  const toDate = endDate ? normalizeDate(endDate) : '';
  return RequestService(urls.referralsLinksList(page, pageLimit || limit, fromDate, toDate));
};

export const getReferralsStatisticsAction = (startDate, endDate) => {
  const fromDate = startDate ? normalizeDate(startDate) : '';
  const toDate = endDate ? normalizeDate(endDate) : '';
  return RequestService(urls.referralsStatistics(fromDate, toDate));
};

export const balanceTransferAction = (amount) => (
  RequestService(urls.balanceTransfer, {
    method: 'POST',
    body: { amount },
  })
);

export const getWithdrawalSystemsAction = () => RequestService(urls.withdrawalSystems);

export const createReferralsWithdrawalAction = (wsId, wId, wIdentifier, amount) => (
  RequestService(urls.referralsWithdrawal, {
    method: 'POST',
    body: {
      amount,
      withdrawal_system_id: wsId,
      withdrawal_wallet_id: wId,
      withdrawal_system_user_identifier: wIdentifier,
      currency: 'usd',
    },
  })
);
