import React from 'react';
import { SubscriptionContextProvider } from './SubscriptionContext';
import Subscription from './Subscription';

const SubscriptionContainer = () => (
  <SubscriptionContextProvider>
    <Subscription />
  </SubscriptionContextProvider>
);

export default SubscriptionContainer;
