import React from 'react';

export default () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M55.6055 3.04688H4.39453C1.97133 3.04688 0 5.0182 0 7.44141V44.3555C0 46.7787 1.97133 48.75 4.39453 48.75H22.2723L21.2145 52.4524C20.7896 53.9392 19.1244 55.1953 17.5781 55.1953H11.8359C11.3505 55.1953 10.957 55.5888 10.957 56.0742C10.957 56.5596 11.3505 56.9531 11.8359 56.9531H48.1641C48.6495 56.9531 49.043 56.5596 49.043 56.0742C49.043 55.5888 48.6495 55.1953 48.1641 55.1953H42.4219C40.8756 55.1953 39.2104 53.9392 38.7855 52.4524L37.7277 48.75H55.6055C58.0287 48.75 60 46.7787 60 44.3555V7.44141C60 5.0182 58.0287 3.04688 55.6055 3.04688ZM37.0954 52.9354C37.3365 53.7796 37.8159 54.5554 38.4444 55.1953H21.5556C22.1841 54.5554 22.6635 53.7796 22.9046 52.9354L24.1004 48.75H35.8995L37.0954 52.9354ZM58.2422 44.3555C58.2422 45.8094 57.0594 46.9922 55.6055 46.9922H4.39453C2.94059 46.9922 1.75781 45.8094 1.75781 44.3555V7.44141C1.75781 5.98746 2.94059 4.80469 4.39453 4.80469H55.6055C57.0594 4.80469 58.2422 5.98746 58.2422 7.44141V44.3555Z" fill="url(#paint0_linear)" />
    <path d="M56.4844 40.8398V7.44141C56.4844 6.95602 56.0909 6.5625 55.6055 6.5625H4.39453C3.90914 6.5625 3.51562 6.95602 3.51562 7.44141V22.3828C3.51562 22.8682 3.90914 23.2617 4.39453 23.2617C4.87992 23.2617 5.27344 22.8682 5.27344 22.3828V8.32031H54.7266V39.9609H5.27344V25.8984C5.27344 25.413 4.87992 25.0195 4.39453 25.0195C3.90914 25.0195 3.51562 25.413 3.51562 25.8984V40.8398C3.51562 41.3252 3.90914 41.7188 4.39453 41.7188H55.6055C56.0909 41.7188 56.4844 41.3252 56.4844 40.8398Z" fill="url(#paint1_linear)" />
    <path d="M55.6851 45.2313C56.1705 45.2313 56.564 44.8378 56.564 44.3524C56.564 43.8669 56.1705 43.4734 55.6851 43.4734C55.1997 43.4734 54.8062 43.8669 54.8062 44.3524C54.8062 44.8378 55.1997 45.2313 55.6851 45.2313Z" fill="url(#paint2_linear)" />
    <path d="M52.1694 45.2313C52.6548 45.2313 53.0483 44.8378 53.0483 44.3524C53.0483 43.8669 52.6548 43.4734 52.1694 43.4734C51.684 43.4734 51.2905 43.8669 51.2905 44.3524C51.2905 44.8378 51.684 45.2313 52.1694 45.2313Z" fill="url(#paint3_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="60" y1="29.9626" x2="6.53151e-07" y2="29.9626" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="56.4844" y1="24.1163" x2="3.51563" y2="24.1163" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="56.564" y1="44.3511" x2="54.8062" y2="44.3511" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="53.0483" y1="44.3511" x2="51.2905" y2="44.3511" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
    </defs>
  </svg>
);
