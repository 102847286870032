/* eslint-disable no-shadow */
import React, {
  useState, useEffect,
  createContext, useContext,
  Suspense,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAppContext } from '@/AppContext';

import LoaderFullScreen from '@/common/components/LoaderFullScreen';

export const BASE_PATH = '/payment/';

const FREEKASSA_TRANSACTION_ID_PARAMETER_NAME = 'MERCHANT_ORDER_ID';
export const TYPE_SUCCESS = 'success';
export const TYPE_FAIL = 'fail';

const PaymentContext = createContext({});

export const PaymentContextProvider = ({ children }) => {
  const history = useHistory();
  const { type } = useParams();

  const { userInfo, urlQuery } = useAppContext();

  if (((userInfo?.id || 0) > 0) && urlQuery?.[FREEKASSA_TRANSACTION_ID_PARAMETER_NAME]) {
    history.push({
      pathname: '/personal-page/balance',
      state: {
        paymentResult: type,
        ptId: urlQuery?.[FREEKASSA_TRANSACTION_ID_PARAMETER_NAME] || 0,
      },
    });
  }

  const [pageType, setPageType] = useState(TYPE_SUCCESS);

  useEffect(() => {
    if (![TYPE_SUCCESS, TYPE_FAIL].includes(type || '')) {
      history.replace('/');
    }
    setPageType(type);
  }, [type]);

  const value = {
    pageType,
  };

  return (
    <Suspense fallback={<LoaderFullScreen />}>
      <PaymentContext.Provider value={value}>
        {children}
      </PaymentContext.Provider>
    </Suspense>
  );
};

export const usePaymentContext = () => useContext(PaymentContext);
