import React from 'react';

export default () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#visibilityIconClip0)">
      <path d="M7.48931 7.6748C6.46493 7.6748 5.63245 8.50729 5.63245 9.53167C5.63245 10.556 6.46493 11.3885 7.48931 11.3885C8.51369 11.3885 9.34618 10.556 9.34618 9.53167C9.34618 8.50729 8.51366 7.6748 7.48931 7.6748Z" fill="#FF9800" />
      <path d="M7.48937 4.88965C4.39458 4.88965 1.75165 6.81458 0.680847 9.53182C1.75165 12.249 4.39458 14.174 7.48937 14.174C10.5872 14.174 13.2271 12.249 14.2979 9.53182C13.2271 6.81458 10.5872 4.88965 7.48937 4.88965ZM7.48937 12.6266C5.78105 12.6266 4.39458 11.2401 4.39458 9.53179C4.39458 7.82347 5.78105 6.43703 7.48937 6.43703C9.19769 6.43703 10.5842 7.8235 10.5842 9.53182C10.5842 11.2401 9.19769 12.6266 7.48937 12.6266Z" fill="#FF9800" />
    </g>
    <defs>
      <clipPath id="visibilityIconClip0">
        <rect width="13.617" height="13.617" fill="white" transform="translate(0.680847 2.72314)" />
      </clipPath>
    </defs>
  </svg>
);
