import React from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  primary,
  red,
  border, greyText,
} from '@/common/constants/theme';

const AdminFilters = ({ children, handleDeleteAll, isDisabled }) => (
  <Container>
    <Wrapper>
      {React.Children.map(children, (item) => <ListItem isDisabled={isDisabled}>{item}</ListItem>)}
    </Wrapper>
    <Wrapper>
      <DeleteText isDisabled={isDisabled} onClick={!isDisabled && handleDeleteAll}>
        Удалить все
      </DeleteText>
    </Wrapper>
  </Container>
);

export default AdminFilters;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 44px;
  padding-left: 21px;
  padding-right: 15px;
  margin-bottom: 11px;

  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ListItem = styled.div`
  margin-right: 21px;

  position: relative;

  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ isDisabled }) => (isDisabled ? greyText : primary)};
  user-select: none;
  text-transform: capitalize;
  letter-spacing: 0.16px;
  line-height: 20px;

  transition: 0.2s ease;

  & svg path {
    transition: 0.2s ease;
  }

  &:hover {
    color: ${border};

    & svg path {
      fill: ${border};
    }
  }

  &:not(:last-child) {
    &::before {
      content: '';

      width: 1px;

      position: absolute;
      right: -11px;
      top: 0;
      bottom: 0;

      background-color: ${borderSecondary};
      border-radius: 10px;
      cursor: default;
    }
  }

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 14px;
  }
`;

const DeleteText = styled.div`
  position: relative;

  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ isDisabled }) => (isDisabled ? greyText : red)};
  text-transform: capitalize;
  letter-spacing: 0.16px;
  line-height: 20px;

  &::after {
    content: '';

    width: 1px;

    position: absolute;
    left: -11px;
    top: 0;
    bottom: 0;

    background-color: ${borderSecondary};
    border-radius: 10px;
  }

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 14px;
  }
`;
