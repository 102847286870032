import React from 'react';

export default ({
  onClick, width = 6, height = 10,
}) => (
  <svg width={width} height={height} viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <path d="M5.45965 0.159839L5.78949 0.487403C5.89249 0.590493 5.94924 0.727648 5.94924 0.874234C5.94924 1.02074 5.89249 1.15806 5.78949 1.26115L2.05271 4.99776L5.79363 8.73869C5.89664 8.84162 5.95331 8.97893 5.95331 9.12544C5.95331 9.27195 5.89664 9.40934 5.79363 9.51235L5.46582 9.84C5.25265 10.0533 4.90541 10.0533 4.69224 9.84L0.222204 5.38598C0.119277 5.28305 0.0467553 5.1459 0.0467553 4.99809V4.99638C0.0467553 4.8498 0.119358 4.71264 0.222204 4.60971L4.68013 0.159839C4.78305 0.0567484 4.92436 0.000163078 5.07086 0C5.21745 0 5.3568 0.0567484 5.45965 0.159839Z" />
  </svg>

);
