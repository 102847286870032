/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import CheckboxOk from '../../assets/icons/checkboxOk.svg';
import CheckboxOkWhite from '../../assets/icons/checkboxOkWhite.svg';
import {
  primary,
  secondary,
  white,
  borderSecondary,
  text01,
} from '../constants/theme';

const COLORS = {
  primary: {
    main: secondary,
    background: white,
    border: borderSecondary,
  },
  secondary: {
    main: primary,
    background: primary,
    border: primary,
  },
};

export default ({
  className,
  checked,
  type = 'primary',
  label,
  onChange,
  disabled,
  ...props
}) => (
  <CheckboxContainer
    className={className}
    onClick={() => onChange(!checked)}
    disabled={disabled}
  >
    <StyledCheckbox
      checked={checked}
      type={type}
      disabled={disabled}
      {...props}
    >
      <Icon type={type} />
    </StyledCheckbox>
    <Label disabled={disabled}>
      {label}
    </Label>
  </CheckboxContainer>
);

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}
`;

const Icon = styled.div`
  ${({ type }) => (type === 'primary' ? `
    background-image: url(${CheckboxOk});
  ` : `
    background-image: url(${CheckboxOkWhite});
  `)}
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${({ checked, type }) => (checked && COLORS[type].background)};
  border: 1px solid ${({ type }) => COLORS[type].border};
  box-sizing: border-box;
  border-radius: 2px;
  transition: border-color .25s;
  cursor:pointer;
  border-color: ${({ checked, type }) => (checked && COLORS[type].main)};
  :hover{
    border-color: ${({ type }) => COLORS[type].main};
  }
  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
  ${({ disabled }) => disabled && `
    border-color: ${text01};
    opacity: .3;
  `}
`;

const Label = styled.span`
  font-family: Gilroy;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin-left: .5rem;
  cursor: pointer;
  ${({ disabled }) => disabled && `
    color: ${text01};
    opacity: .3;
  `}
`;
