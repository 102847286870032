import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import {
  white,
  secondary,
  greyBorder,
} from '@/common/constants/theme';

import Input from '@/common/components/Input';
import DeleteIcon from '../icons/DeleteIcon';
import DeleteModal from '../Modals/DeleteModal';

const TagsAdd = ({
  tagsList,
  addTag,
  deleteTag,
  selectTag,
  setEditedTagText,
}) => {
  const [currentTagsList, setCurrentTagsList] = useState([]);
  const [selectedTagsList, setSelectedTagsList] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [currentText, setCurrentText] = useState('');
  const [isTagListDisplayed, setIsTagListDisplayed] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState('');

  const [canAdd, setCanAdd] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (!isTagListDisplayed) {
          return;
        }
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          setIsTagListDisplayed(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, isTagListDisplayed]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleTagSelect = (tag) => {
    if (selectTag) {
      selectTag(tag);
    }
    setSelectedTagsList((prevState) => ([...prevState, tag]));
  };

  const addTagHandler = () => addTag(currentText)
    .then(() => {
      setCurrentText('');
    })
    .catch((err) => {
      setError(err);
    })
    .finally(() => setIsBusy(false));

  useEffect(() => {
    if (setEditedTagText) {
      setEditedTagText(currentText);
    }
    let useTagList = (tagsList || []);
    if (currentText.length > 0) {
      useTagList = useTagList
        .filter((tag) => tag.title.toLowerCase().includes(currentText.toLowerCase()));
    }
    setCurrentTagsList(useTagList);

    setCanAdd(
      (!!addTag
        && (currentText.length > 0)
        && !(tagsList || []).find((tg) => tg.title.toLowerCase() === currentText.toLowerCase())
        && !(selectedTagsList || []).find(
          (tg) => tg.title.toLowerCase() === currentText.toLowerCase(),
        )
      ),
    );
  }, [tagsList, selectedTagsList, currentText]);

  return (
    <>
      {selectedTag && (
        <DeleteModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setSelectedTag(null);
          }}
          content={`тег '${selectedTag.title}'`}
          handleDelete={() => deleteTag && deleteTag(selectedTag)}
          color="danger"
        />
      )}
      <Container
        ref={wrapperRef}
        onClick={() => setIsTagListDisplayed(true)}
      >
        <AddInput
          placeholder="Выберите или добавьте тег"
          onChange={({ target }) => {
            setCurrentText(target.value);
          }}
          error={error.length > 0}
          onAdd={canAdd ? () => addTagHandler() : null}
          errorMessage={error}
          onKeyUp={(e) => {
            if (!!addTag && (currentText.length > 0) && (e.key === 'Enter' || e.keyCode === 13)) {
              addTagHandler();
            }
          }}
          onKeyDown={() => {
            if (!isTagListDisplayed) {
              setIsTagListDisplayed(true);
            }
          }}
          disabled={isBusy}
          value={currentText}
        />
        {isTagListDisplayed && (
          <TagList>
            <TagListInner>
              {(currentTagsList.length === 0) && (
                <TagItem isStub>Нет подходящих тегов</TagItem>
              )}
              {currentTagsList.map((tag) => (
                <TagItem
                  key={`FAQTagsAdd-T-${tag.id}`}
                  isActive={(currentText || '').toLowerCase() === tag.title.toLowerCase()}
                >
                  <TagItemText
                    onClick={() => handleTagSelect(tag)}
                  >
                    {tag.title}
                  </TagItemText>
                  <TagItemControls>
                    {deleteTag && (
                      <DeleteTagButton
                        title="Удалить тег."
                        onClick={() => {
                          setSelectedTag(tag);
                          setShowDeleteModal(true);
                        }}
                      >
                        <DeleteIcon />
                      </DeleteTagButton>
                    )}
                  </TagItemControls>
                </TagItem>
              ))}
            </TagListInner>
          </TagList>
        )}
      </Container>
    </>
  );
};

export default TagsAdd;

const Container = styled.div`
  position: relative;
`;

const AddInput = styled(Input)`
  position: relative;
`;

const TagList = styled.div`
  position: absolute;
  left: -0.75rem;
  right: -0.75rem;

  padding: 0 0.75rem 0.5rem;

  background: ${white};
`;

const TagListInner = styled.div`
  position: relative;
  margin-top: -1px;
  padding: 0.5rem;
  border: solid ${greyBorder} 1px;
  max-height: 10em;
  overflow: auto;
`;

const TagItemText = styled.div`
  cursor: pointer;

  &:hover {
    color: ${secondary};
  }
`;

const TagItemControls = styled.div`
  font-size: 0;

   svg {
     opacity: 0.5;
   }

  &:hover svg {
    opacity: 1;
  }
`;

const DeleteTagButton = styled.div`
  cursor: pointer;
`;

const TagItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${({ isActive }) => (isActive && `
    ${TagItemText} {
      color: ${secondary};
    }
  `)}
`;
