import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  white,
  text01,
  primary,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';

import { useUsersGroupsContext } from '../../../GroupsContext';

const UsersGroupsMainList = () => {
  const [localGroups, setLocalGroups] = useState([]);

  const {
    groups,
    isUserGroupsBusy,
    selectedGroup,
    setSelectedGroup,
    setIsUserTableBusy,
  } = useUsersGroupsContext();

  useEffect(() => {
    setLocalGroups([...groups].sort((a, b) => a.title.localeCompare(b.title)));
  }, [groups]);

  return (
    <Container>
      {isUserGroupsBusy && <Loader />}
      {localGroups.map((group) => (
        <Item
          key={group.title}
          isActive={selectedGroup?.id === group.id}
          onClick={() => {
            if ((selectedGroup?.id || -1) !== group.id) {
              setIsUserTableBusy(true);
              setSelectedGroup(groups.find((g) => g.id === group.id));
            }
          }}
        >
          {group.title}
        </Item>
      ))}
    </Container>
  );
};

export default UsersGroupsMainList;

const Container = styled.div`
  position: relative;

  width: 400px;
  height: 500px;
  margin-right: 30px;

  background: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  overflow-y: auto;
`;

const Item = styled.div`
  display: flex;
  align-items: center;

  height: 34px;
  padding: 0 20px;

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 16px;
  color: ${text01};
  line-height: 19px;

  ${({ isActive }) => isActive && `
    background-color: ${primary};
    color: ${white};
  `}
`;
