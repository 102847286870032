import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  white, black,
  primary, secondary,
} from '../constants/theme';

import EmailIcon from '@/common/components/icons/EmailIcon';

import { useAppContext } from '@/AppContext';

import PageContainer from './PageContainer';

const MIN_SCREEN_WIDTH = 768;

export default () => {
  const { t } = useTranslation('translations');

  const getIsLargeScreen = () => window.matchMedia(`(min-width: ${MIN_SCREEN_WIDTH}px)`).matches;

  const { systemSettings } = useAppContext();

  const [isLargeScreen, setIsLargeScreen] = useState(getIsLargeScreen());

  const EMAIL_DATA = [
    {
      key: `F-CB-Email-${Math.random()}`,
      title: t('footer.contacts.company'),
      color: primary,
      email: systemSettings?.company_email || '',
    },
    {
      key: `F-CB-Email-${Math.random()}`,
      title: t('footer.contacts.techSupport'),
      color: secondary,
      email: systemSettings?.support_email || '',
    },
  ];

  const getLinkText = (link) => `<a href="${link.url}" target="_blank">${link.title}</a>`;

  const getReCaptchaText = () => t('footer.copyRights.recaptchaText', {
    PrivacyPolicy: getLinkText({ url: 'https://policies.google.com/privacy', title: t('footer.copyRights.privacyPolicyText') }),
    TermsOfService: getLinkText({ url: 'https://policies.google.com/terms', title: t('footer.copyRights.termsOfServiceText') }),
  });

  useEffect(() => {
    setIsLargeScreen(getIsLargeScreen());
  }, [window.innerWidth]);

  const renderEmailDataRow = (emailData) => (
    <EmailDataRow key={emailData.key}>
      <EmailDataIcon><EmailIcon color={emailData.color} /></EmailDataIcon>
      <EmailDataTitle>{emailData.title}</EmailDataTitle>
      <EmailDataEmail type="email" href={`mailto:${emailData.email}`}>{emailData.email}</EmailDataEmail>
    </EmailDataRow>
  );

  return (
    <Footer>
      <PageContainer>
        <Content
          isLarge={isLargeScreen}
        >
          <AddressBlock>{t('footer.address')}</AddressBlock>
          <ContactsBlock>
            <Emails>
              {EMAIL_DATA.map(renderEmailDataRow)}
            </Emails>
            {/* <PaymentProcessors /> */}
          </ContactsBlock>
          <CopyRightsBlock>
            <CopyRightsText>
              {t('footer.copyRights.copyRightText', { currentYear: new Date().getFullYear() })}
              <a href={`${window.location.protocol}//${window.location.host}`}>{window.location.hostname}</a>
            </CopyRightsText>
            <ReCaptchaText>
              {ReactHtmlParser(getReCaptchaText())}
            </ReCaptchaText>
          </CopyRightsBlock>
        </Content>
      </PageContainer>
    </Footer>
  );
};
const Footer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${black};
  padding: 1rem 0;
  z-index: 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${white};
  letter-spacing: 0.16px;
  font-family: Gilroy, sans-serif;

  & > div {
    max-width: ${({ isLarge }) => (isLarge ? 33 : 48)}%;
    width: auto;
  }
`;

const FooterBlock = styled.div`
`;

const AddressBlock = styled(FooterBlock)`
  white-space: pre-line;
`;

const ContactsBlock = styled(FooterBlock)`
  width: ${({ isLarge }) => (isLarge ? 30 : 48)}%;
  text-align: center;
`;

const CopyRightsBlock = styled(FooterBlock)`
  white-space: pre-line;
`;

const Emails = styled.div`
  display: inline-block;
`;

const EmailDataRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2em;
`;

const EmailDataIcon = styled.span`
  display: inline-flex;
  width: 1.5rem;
  height: auto;
  margin-right: .5rem;
`;

const EmailDataTitle = styled.span`
  &::after {
    content: ':';
    display: inline-block;
    margin-right: 0.25em;
  }
`;

const EmailDataEmail = styled.a`
  &:hover {
    color: ${secondary};
  }
`;

const CopyRightsText = styled.div`
  & > a {
    margin-left: .5rem;
  }
`;

const ReCaptchaText = styled.div``;
