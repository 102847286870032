/* eslint-disable no-shadow */
import React, {
  createContext, useContext,
  useState, useEffect, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import HtmlParser from 'react-html-parser';

import { longAction } from '@/services/actions/longAction.actions';
import {
  // createDeviceAction,
  editDeviceAction,
  // deleteDeviceAction,
  resetDefaultThemeAction, refreshDeviceOTTIdAction,
  deviceViewTypeChange,
  setDeviceCode,
} from '@/services/actions/devices.actions';
import {
  bindDeviceToServerAction,
} from '@/services/actions/server.actions';
import {
  getAllDnsAction,
  createDnsAction, deleteDNSAction, editDNSAction,
  getDomainsAction,
  // getDefaultDomainAction,
} from '@/services/actions/dns.actions';
import { getPlaylistOsAction } from '@/services/actions/channels.actions';
import { getUnreadThreadsCountAction } from '@/services/actions/support.actions';
import { buySubscriptionsAction } from '@/services/actions/subscription.actions';

import {
  subscribeTypes,
  loadAllDevicesRedux,
  deleteDeviceRedux,
  getPageHeaderDisabled,
  // loadDevicesRedux,
  // getDevices, getDevicesCount,
  getAllDevices,
  getSelectedDevices,
  getPlayLists,
  loadPlaylistsRedux,
  switchDeviceSelected,
  switchDeviceSelectedAll,
  getServers, loadServers,
  setSnack, setPageHeaderDisabled,
  getChannelsLogoCache,
  updateStoredDevice,
  // eslint-disable-next-line no-unused-vars
  updateStoredDevices,
  processLoadedDevice,
  selectOnlyDevices,
} from '@/store/user/selectors';

import { SnackTypes, useAppContext } from '@/AppContext';

export const KNOWN_REPLENISH_BONUS_TYPES = [
  'referral', 'personal', 'system', 'amount',
];
export const SHOW_SELECTED_DEVICES = false;
export const SHOW_SELECTED_SERVER = false;
export const SHOW_RIGHT_PANEL = false;
export const SHOW_SERVER_TABLE = false;
export const SHOW_SUBSCRIBE_PREMIUM_CONTROLS = false;
export const SHOW_DNS_CONTROLS = false;

export const REQUEST_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT = 'dd.MM.yy';
export const DATE_TIME_FORMAT = 'dd.MM.yy hh:ii:ss';
export const USE_HTTPS_FOR_CHANNELS_LOGO = process.env.REACT_APP_UPGRADE_CHANNELS_LOGO_PROTO
  || false;
const SUPPORT_UNREAD_THREADS_CHECK_INTERVAL = 1000 * 30;

export const PersonalPageContext = createContext({});

const initialFilters = {
  search: '',
  subscription_type: subscribeTypes.All,
  server_id: subscribeTypes.All,
  page: 1,
  limit: localStorage.getItem('deviceListLimit') || 8,
};

export const PersonalPageContextProvider = ({ children }) => {
  const history = useHistory();
  const { t } = useTranslation('translations');

  // const [devices, setDevices] = useState([]);
  // const [devicesCount, setDevicesCount] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isDevicesLoading, setIsDevicesLoading] = useState(false);
  const [servers, setServers] = useState([]);
  const [allDns, setAllDns] = useState(null);
  const [allDomains, setAllDomains] = useState(null);
  const [playlistOses, setPlaylistOses] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [serversFullData, setServersFullData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [disabledStepperIdx, setDisabledStepperIdx] = useState(null);
  const [isPageHeaderDisabled, setIsPageHeaderDisabled] = useState({});
  const [channelsLogos, setChannelsLogos] = useState(null);
  const [isSupportUnread, setIsSupportUnread] = useState(false);

  const supportUnreadIntervalHandler = useRef(null);

  const personalPageNavContainer = useRef(null);

  const { userInfo } = useAppContext();

  const dispatch = useDispatch();

  // const getDevicesLocal = () => {
  //   dispatch(loadDevicesRedux(filters, setIsDevicesLoading));
  // };

  const storeServers = useSelector(getServers);
  // const storeDevices = useSelector(getDevices);
  const storeAllDevices = useSelector(getAllDevices);
  const storeSelectedDevices = useSelector(getSelectedDevices);
  // const storeDevicesCount = useSelector(getDevicesCount);
  const playlists = useSelector(getPlayLists);
  const storedPageHeaderDisabled = useSelector(getPageHeaderDisabled);
  const storeChannelsLogos = useSelector(getChannelsLogoCache);

  const goToPageWithScroll = (pathName) => {
    history.push({ pathname: pathName, state: { scrollToTop: true } });
  };

  const setUserSnack = (data) => dispatch(setSnack(data));

  const setDisabledSteps = () => {
    const selectedDevicesList = (storeSelectedDevices || []).filter((d) => d.server !== null);
    const newDisabledId = (
      (selectedDevicesList.length === 0)
      || ((selectedDevicesList.length === 1) && (selectedDevicesList[0].view_type === 'portal'))
      || (selectedDevicesList.filter((sd) => sd.view_type === 'portal').length > 0)
    ) ? 3 : null;
    setDisabledStepperIdx(newDisabledId);
  };

  const handleClosePageHeader = (type) => {
    if ((type || '').length > 0) {
      dispatch(setPageHeaderDisabled({
        ...isPageHeaderDisabled,
        [type]: !isPageHeaderDisabled?.[type],
      }));
    }
  };

  const editDeviceServer = (deviceId, serverId) => (
    bindDeviceToServerAction(deviceId, serverId)
  );

  const editDevice = (deviceId, value, field) => {
    setIsDevicesLoading(true);
    const selectedDevice = allDevices.find((device) => device.id === deviceId);
    return editDeviceAction({ ...selectedDevice, [field]: value })
      .then((res) => {
        dispatch(updateStoredDevice(deviceId, processLoadedDevice(res)));
      })
      .catch((err) => dispatch(setSnack(err)))
      .finally(() => setIsDevicesLoading(false));
  };

  const deleteDevice = (pk) => {
    const useDevice = (allDevices || []).find((d) => d.id === pk) || null;
    if (useDevice) {
      setIsDevicesLoading(true);
      return dispatch(deleteDeviceRedux(useDevice))
        .finally(() => setIsDevicesLoading(false));
    }
    return Promise.reject();
  };

  const handleSelectDevice = async (device) => {
    dispatch(switchDeviceSelected(device));
    setDisabledSteps();
    return Promise.resolve();
  };

  const handleSelectOneDevice = async (device) => {
    dispatch(selectOnlyDevices([device]));
    setDisabledSteps();
    return Promise.resolve();
  };

  const handleSelectDevices = (checked) => {
    dispatch(switchDeviceSelectedAll(checked, filters.page, filters.limit)); setDisabledSteps();
  };

  const getAllDnsDefDomain = async () => {
    const dns = await getAllDnsAction()
      .then((res) => ({ data: res?.results || [], count: res?.count || 0 }))
      .catch((err) => {
        dispatch(setSnack(err));
        return [];
      });

    // ARTV-261 changes
    // let defDomain;
    // defDomain = await getDefaultDomainAction()
    //   .then((res) => (res?.name || ''))
    //   .catch((err) => {
    //     dispatch(setSnack(err));
    //     return '';
    //   });
    const defDomain = window.location.hostname;

    return { dns, defDomain };
  };

  const getPlaylistOs = () => {
    getPlaylistOsAction().then((result) => {
      setPlaylistOses(result);

      if ((result || []).length > 0) {
        if (allPlayers.length === 0) {
          const players = [];
          result.forEach((pl) => {
            pl.playlist_types.forEach((plt) => {
              players.push({ ...plt });
            });
          });
          setAllPlayers(players);
        }
      }
    });
  };

  const replaceDomain = (srcString, targetDomain) => {
    const srcUrl = new URL(srcString);
    srcUrl.hostname = targetDomain;
    return srcUrl.toString();
  };

  const getPlayListDownloadLinks = (selectedDevice, dns = null, player, v = 1) => {
    const dnsName = dns?.name || '';
    const playerDownloadUrl = player?.download_url || '';
    const playerFormatName = player?.format_name || '';
    const deviceOttId = selectedDevice?.ott_id || '';
    const retValV0 = {
      http: `http://${dnsName}${playerDownloadUrl.replace('{ott_id}', deviceOttId)}`,
      https: `https://${dnsName}${playerDownloadUrl.replace('{ott_id}', deviceOttId)}`,
      url: `${window.location.protocol}//${dnsName}${playerDownloadUrl.replace('{ott_id}', deviceOttId)}`,
      epgUrls: (player?.epg_urls || []).map((s) => replaceDomain(s, dnsName)),
      epgLiteUrls: (player?.epg_lite_urls || []).map((s) => replaceDomain(s, dnsName)),
      piconUrls: (player?.picon_urls || []).map((s) => replaceDomain(s, dnsName)),
    };
    const retValV1 = {
      http: `http://${dnsName}/p/${deviceOttId}/${playerFormatName}`,
      https: `https://${dnsName}/p/${deviceOttId}/${playerFormatName}`,
      url: `${window.location.protocol}//${dnsName}/p/${deviceOttId}/${playerFormatName}`,
      epgUrls: (player?.epg_urls || []).map((s) => replaceDomain(s, dnsName)),
      epgLiteUrls: (player?.epg_lite_urls || []).map((s) => replaceDomain(s, dnsName)),
      piconUrls: (player?.picon_urls || []).map((s) => replaceDomain(s, dnsName)),
    };
    return v === 1 ? retValV1 : retValV0;
  };

  const resetDefaultTheme = (device, taskId = '') => resetDefaultThemeAction(device.id, taskId);

  const refreshOTTId = (deviceId) => refreshDeviceOTTIdAction(deviceId)
    .then((res) => {
      dispatch(updateStoredDevice(deviceId, { ott_id: res.ott_id }));
    })
    .catch((err) => dispatch(setSnack(err)));

  const updateDeviceMinistraCredentials = (
    deviceID, data,
    successCallback, stopCallback,
  ) => {
    longAction(deviceViewTypeChange, stopCallback, successCallback, { id: deviceID, data });
  };

  const copyText = (value) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(value);
    }
    return document.execCommand('copy', true, value);
  };

  const updateDeviceParameters = (
    deviceId, parameters = {},
  ) => {
    dispatch(updateStoredDevice(deviceId, parameters));
  };

  const dnsProcessResult = (item) => ({
    id: item.id,
    dns: item.dns,
    name: item.dns_full,
    serverId: item.server_id,
    userId: item.user_id,
    domainId: item.domain_id,
  });

  const addDNS = (value) => (
    createDnsAction(value)
      .then((res) => {
        setAllDns((prevState) => ([dnsProcessResult(res), ...prevState]));
      })
      .catch((err) => setUserSnack(err))
  );

  const deleteDNS = (id) => (
    deleteDNSAction(id)
      .then(() => {
        setAllDns((prevState) => {
          const newState = [...prevState];
          const deletedIdIndex = newState.findIndex((i) => i.id === id);
          newState.splice(deletedIdIndex, 1);
          return newState;
        });
        return (allDns || []).filter((i) => i.id > 0).length - 1;
      })
      .catch((err) => setUserSnack(err))
  );

  const editDNS = (id, value) => (
    editDNSAction(id, value)
      .then((res) => {
        setAllDns((prevState) => {
          const newState = [...prevState];
          const replaceIndex = newState.findIndex((i) => i.id === res.id);
          newState.splice(replaceIndex, 1, dnsProcessResult(res));
          return newState;
        });
      })
      .catch((err) => setUserSnack(err))
  );

  const getSupportUnreadThreadsCount = () => {
    getUnreadThreadsCountAction()
      .then((res) => setIsSupportUnread(parseInt(res?.case_count || '0', 10) > 0))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('getSupportUnreadThreadsCount error', err);
        setIsSupportUnread(false);
      });
  };

  const getDateTimeToDisplay = (D) => moment.parseZone(D).format('DD.MM.YYYY HH:mm');

  const buySubscription = (
    {
      deviceIds = [],
      period,
      promoTransactionId = null,
      isPremium = null,
      isAutoSubscr = false,
      isEqualize = false,
    },
    isTest = true,
  ) => {
    const useDeviceIds = (deviceIds || []).length > 0
      ? deviceIds
      : (selectedDevices || []).map((d) => d.id);

    if (useDeviceIds.length === 0) {
      return Promise.reject(new Error({ message: 'no_device_ids' }));
    }

    const params = {
      device_ids: useDeviceIds,
      days_amount: period,
      is_premium: isPremium,
      is_auto_renew: isAutoSubscr,
      promocode_transaction_id: promoTransactionId,
      date_alignment: isEqualize,
      force: !isTest,
    };
    return buySubscriptionsAction(params);
  };

  const equalizeSubscription = (isTest) => buySubscription(
    {
      period: 30,
      isEqualize: true,
      isPremium: null,
      isAutoSubscr: null,
    },
    isTest,
  );

  const processBuySubscribtionResult = (response, discountValue = 0) => {
    const data = response?.data || {};
    const status = response?.status || 500;
    const dialog = { ...data };
    switch (status) {
      case 200:
      case 406:
        dialog.status = 'success';
        dialog.promoAmount = discountValue;
        break;
      default:
        dialog.status = 'error';
        dialog.errorText = t(`subscriptionsContent.errors.${data?.message_type || 'undefined'}`);
        break;
    }
    return dialog;
  };

  const setDevicesAppCode = (devices, code) => Promise.allSettled(
    (devices || []).map((d) => setDeviceCode(d.id, code)),
  )
    .then((res) => {
      const paired = res.filter((itm) => (
        (itm.status === 'fulfilled')
        && (itm?.value?.app_pairing_code || null)
        && (itm?.value?.app_status || null)
      ))
        .map((itm) => {
          const { id, app_pairing_code: appPCode, app_status: appS } = itm.value;
          return {
            id,
            app_pairing_code: appPCode,
            app_status: appS,
          };
        });

      if (paired.length > 0) {
        dispatch(updateStoredDevices(paired));
      }

      const type = paired.length === devices.length
        ? SnackTypes.success
        : SnackTypes.error;

      const content = (devices || []).map((d, index) => (
        res[index].status === 'fulfilled'
          ? t('devicesContent.appStatus.pairedSuccessfully', d)
          : t('devicesContent.appStatus.notPaired', {
            ...d,
            message: t(`devicesContent.appStatus.errors.${res[index]?.reason?.data?.message_type}`),
          })
      ));

      setUserSnack({
        type,
        status: true,
        content: HtmlParser(content.join('<br><br>')),
      });
    })
    .catch(() => {
      setUserSnack({
        type: SnackTypes.error,
        status: true,
        content: t('devicesContent.appStatus.errors.unknown'),
      });
    });

  const clearDevicesAppCode = (devices) => Promise.allSettled(
    (devices || []).map((d) => setDeviceCode(d.id, null)),
  )
    .then((res) => {
      const unPaired = res.filter(
        (itm) => (
          (itm.status === 'fulfilled')
          && ((itm?.value?.app_status || null) === 'not_configured')
        ),
      ).map((itm) => {
        const { id, name, app_status: appS } = itm.value;
        return {
          id,
          name,
          app_status: appS,
        };
      });

      if (unPaired.length > 0) {
        dispatch(updateStoredDevices(unPaired));
      }

      const content = unPaired.length > 0
        ? t(`devicesContent.appStatus.${
          unPaired.length > 1
            ? 'unpairedSuccessfullyMany'
            : 'unpairedSuccessfully'
        }`, { msg: unPaired.map((d) => `${d.name} (id ${d.id})`).join(', ') })
        : t(`devicesContent.appStatus.${devices.length > 1 ? 'notUnpairedMany' : 'notUnpaired'}`);

      setUserSnack({
        type: unPaired.length > 0 ? SnackTypes.success : SnackTypes.error,
        status: true,
        content: HtmlParser(content),
      });
    })
    .catch(() => {
      setUserSnack({
        type: SnackTypes.error,
        status: true,
        content: t('devicesContent.appStatus.errors.unknown'),
      });
    });

  useEffect(() => {
    if (userInfo?.id) {
      dispatch(loadPlaylistsRedux());
    }
  }, [userInfo?.id]);

  useEffect(() => {
    setIsPageHeaderDisabled(storedPageHeaderDisabled);
  }, [storedPageHeaderDisabled]);

  // useEffect(() => {
  //   dispatch(loadDevicesRedux(filters, setIsDevicesLoading));
  // }, [filters]);

  useEffect(() => {
    if (!storeServers) {
      dispatch(loadServers());
    } else {
      setServersFullData(storeServers);
      setServers(storeServers.map((s) => ({ id: s.id, name: s.name })));
    }
  }, [storeServers]);

  // useEffect(() => {
  //   if (!storeDevices) {
  //     dispatch(loadDevicesRedux(filters, setIsDevicesLoading));
  //   } else {
  //     setDevices(storeDevices);
  //     setDisabledSteps();
  //   }
  // }, [storeDevices]);

  useEffect(() => {
    if (!storeAllDevices) {
      dispatch(loadAllDevicesRedux());
      setDisabledSteps();
    } else {
      setAllDevices(storeAllDevices);
    }
  }, [storeAllDevices]);

  // useEffect(() => {
  //   setDevicesCount(storeDevicesCount);
  // }, [storeDevicesCount]);

  useEffect(() => {
    setSelectedDevices(storeSelectedDevices);
    setDisabledSteps();
  }, [storeSelectedDevices]);

  useEffect(() => {
    if (storeChannelsLogos) {
      setChannelsLogos(storeChannelsLogos);
    }
  }, [storeChannelsLogos]);

  useEffect(() => {
    if (!playlistOses) {
      getPlaylistOs();
    }
  }, [playlistOses]);

  useEffect(() => {
    if (!allDns) {
      getAllDnsDefDomain().then(({ dns, defDomain }) => {
        setAllDns([{
          id: 0,
          name: defDomain ?? '',
          serverId: null,
          userId: null,
          domainId: null,
        },
        ...dns.data
          .map(dnsProcessResult)]);
      });
    }
  }, [allDns]);

  useEffect(() => {
    if (!allDomains) {
      getDomainsAction()
        .then((res) => {
          setAllDomains(res.results || []);
        })
        .catch((err) => setUserSnack(err));
    }
  }, [allDomains]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (userInfo?.id) {
      getSupportUnreadThreadsCount();
      supportUnreadIntervalHandler.current = setInterval(
        () => getSupportUnreadThreadsCount(),
        SUPPORT_UNREAD_THREADS_CHECK_INTERVAL,
      );
      return () => clearInterval(supportUnreadIntervalHandler.current);
    }
  }, [userInfo?.id]);

  const value = {
    setUserSnack,
    // devices,
    allDevices,
    // devicesCount,
    selectedDevices,
    playlists,
    filters,
    setFilters,
    // getDevices: getDevicesLocal,
    editDevice,
    editDeviceServer,
    deleteDevice,
    allDomains,
    allDns,
    addDNS,
    editDNS,
    deleteDNS,
    getPlaylistOs,
    playlistOses,
    allPlayers,
    servers,
    serversFullData,
    handleSelectDevice,
    handleSelectOneDevice,
    handleSelectDevices,
    disabledStepperIdx,
    setDisabledStepperIdx,
    setDisabledSteps,
    resetDefaultTheme,
    refreshOTTId,
    copyText,
    getDateTimeToDisplay,
    isDevicesLoading,
    isPageHeaderDisabled,
    setIsPageHeaderDisabled,
    handleClosePageHeader,
    channelsLogos,
    updateDeviceMinistraCredentials,
    updateDeviceParameters,
    getPlayListDownloadLinks,
    getAllDnsDefDomain,
    isSupportUnread,
    setIsSupportUnread,
    buySubscription,
    processBuySubscribtionResult,
    equalizeSubscription,
    setDevicesAppCode,
    clearDevicesAppCode,
    personalPageNavContainer,
    goToPageWithScroll,
  };

  return (
    <PersonalPageContext.Provider value={value}>
      {children}
    </PersonalPageContext.Provider>
  );
};

export const usePersonalPageContext = () => useContext(PersonalPageContext);
