import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory, useLocation,
} from 'react-router-dom';

import { AuthorizationContextProvider } from './AuthorizationContext';
import NotFoundPage from './NotFoundPage';
import SignInContainer from './SignInScreen/SignInContainer';
import SignUpContainer from './SignUpScreen/SignUpContainer';
import SignUpCompleteContainer from './SignUpScreen/SignUpCompleteContainer';
import SignUpConfirmEmailContainer from './SignUpScreen/SignUpConfirmEmailContainer';
import ChangePasswordContainer from './ChangePasswordScreen/ChangePasswordContainer';
import ChangeEmailContainer from './ChangeEmailScreen/ChangeEmailContainer';
import ConfirmNewEmailContainer from './ConfirmNewEmailScreen/ConfirmNewEmailContainer';
import ConfirmWalletContainer from './ConfirmWalletScreen/ConfirmWalletContainer';
import ConfirmDeleteUserContainer from './ConfirmDeleteUserScreen/ConfirmDeleteUserContainer';
import ForgotPasswordContainer from './ChangePasswordScreen/ForgotPasswordContainer';
import UnsubscribeContainer from './UnsubscribeScreen/UnsubscribeContainer';
import EnterEmailContainer from './EnterEmailContainer';

export default function AuthorizationContainer() {
  const location = useLocation();
  const baseUrl = useHistory().location.pathname;

  useEffect(() => {
    if (location?.state?.scrollToTop) {
      window.scroll(0, 0);
    }
  }, [location]);

  return (
    <AuthorizationContextProvider>
      <Switch>
        <Route exact path="/sign-up" component={SignUpContainer} />
        <Route exact path="/sign-in" component={SignInContainer} />
        <Route exact path="/confirm-email/:email" component={SignUpConfirmEmailContainer} />
        <Route exact path="/forgot-password" component={ForgotPasswordContainer} />
        <Route exact path="/enter-email/:type/:clientId" component={EnterEmailContainer} />
        <Route exact path="/confirm/sign-up/:token" component={SignUpCompleteContainer} />
        <Route exact path="/confirm/change-email/:uid/:token" component={ChangeEmailContainer} />
        <Route exact path="/confirm/change-email/:token" component={ConfirmNewEmailContainer} />
        <Route exact path="/confirm/change-password/:uid/:token" component={ChangePasswordContainer} />
        <Route exact path="/confirm/change-wallet/:token" component={ConfirmWalletContainer} />
        <Route exact path="/confirm/delete-account/:token" component={ConfirmDeleteUserContainer} />
        <Route exact path="/unsubscribe/:token" component={UnsubscribeContainer} />
        <Route exact path="/not-found" component={NotFoundPage} />
        <Redirect
          to={{
            pathname: '/sign-in',
            state: { baseUrl },
          }}
        />
      </Switch>
    </AuthorizationContextProvider>
  );
}
