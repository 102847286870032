import React, { useState, useEffect, useRef } from 'react';

import { format } from 'date-fns';

import styled from 'styled-components';
import InputMask from 'react-input-mask';
// import { format } from 'date-fns';

import {
  primary, secondary, white,
  text01, text03,
  greyBorder, borderSecondary,
  background01, greyTertiary, primaryQuart,
} from '@/common/constants/theme';
import Checkbox from '@/common/components/Checkbox';
import { Radio, RadioGroup } from '@/common/components/RadioButton';
import SearchIcon from '../../../../../../../../../icons/SearchIconAlt';
import CloseIcon from '../../../../../../../../../icons/CloseIcon';
import CalendarIcon from '../../../../../../../../../icons/CalendarIcon';
import UsersMainUserDevicesInfo from '../MainUserDevicesInfo';
import UsersMainUserDevicesInfoWindow from '../MainUserDevicesInfoWindow';
import MainUserDevicesListHistory from './components/MainUserDevicesListHistory';
import DatePicker from '@/common/components/DatePicker';
import Loader from '@/common/components/Loader';

import {
  DATA_TYPES,
  SUBSCRIPTION_ACTIONS,
  SUBSCRIPTION_CANCEL_TYPES,
  SUBSCRIPTION_CANCEL_METHODS,
  useUsersMainContext,
} from '../../../../../../MainContext';

const SUBSCRIPTION_CANCEL_TYPES_TITLES = {
  [SUBSCRIPTION_CANCEL_TYPES.all]: 'Возврат всей сумммы',
  [SUBSCRIPTION_CANCEL_TYPES.refund]: 'С возвратом средств',
  [SUBSCRIPTION_CANCEL_TYPES.cancel]: 'Без возврата средств',
};

const SUBSCRIPTION_CANCEL_METHODS_TITLES = {
  [SUBSCRIPTION_CANCEL_METHODS.onlyFuture]: 'Только будущие',
  [SUBSCRIPTION_CANCEL_METHODS.withCurrent]: 'Текущую и последующие',
};

const DTPICKER_DATE_FORMAT = 'dd.MM.yyyy';

const UsersMainUserDevicesList = ({ devices }) => {
  const [localDevices, setLocalDevices] = useState(null);
  const [search, setSearch] = useState('');
  const [isOnlyActive, setIsOnlyActive] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [subscriptionAction, setSubscriptionAction] = useState('');
  const [cancelSubscriptionType, setCancelSubscriptionType] = useState('');
  const [cancelSubscriptionMethod, setCancelSubscriptionMethod] = useState('');
  const [usePremium, setUsePremium] = useState(false);
  const [payFromUserBalance, setPayFromUserBalance] = useState(false);
  const [isDtPickerShown, setIsDtPickerShown] = useState(false);

  const [submitSubscriptionEnabled, setSubmitSubscriptionEnabled] = useState(false);
  const [toDate, setToDate] = useState('');

  const dtRefChange = useRef(null);
  const dtRefAdd = useRef(null);

  const {
    dataProcessing,
    createSubscription,
    setSubscription,
    cancelSubscription,
    selectedUser,
  } = useUsersMainContext();

  const resetDeviceDependences = () => {
    setCancelSubscriptionType('');
    setPayFromUserBalance(false);
    setSubscriptionAction('');
  };

  const getDateFromInputString = (dateStr, maxTime = false) => {
    const d = dateStr.split('.');
    d[0] = (d?.[0] && Number.isInteger(+d[0])) ? parseInt(d[0], 10) : 0;
    d[1] = (d?.[1] && Number.isInteger(+d[1])) ? parseInt(d[1], 10) : 0;
    d[2] = (d?.[2] && Number.isInteger(+d[2])) ? parseInt(d[2], 10) : 0;
    if (
      (d[0] < 1 || d[0] > 31)
      || (d[1] < 1 || d[1] > 12)
      || d[2] < 2020
    ) {
      return null;
    }
    return new Date(
      d[2], d[1] - 1, d[0],
      maxTime ? 23 : 0,
      maxTime ? 59 : 0,
      maxTime ? 59 : 0,
      maxTime ? 999 : 0,
    );
  };

  const handleCreateSubscription = () => {
    const requestData = {
      deviceId: selectedDevice.id,
      toDate: getDateFromInputString(toDate),
      usePremium,
      payFromUserBalance,
    };
    createSubscription(requestData);
    setSubscriptionAction('');
  };

  const handleSetSubscription = () => {
    setSubscription(selectedDevice.id, getDateFromInputString(toDate));
    setSubscriptionAction('');
  };

  const handleCancelSubscription = () => {
    cancelSubscription(selectedDevice.id, cancelSubscriptionType, cancelSubscriptionMethod);
    setSubscriptionAction('');
  };

  useEffect(() => {
    setSelectedDevice(null);
    setLocalDevices(null);
  }, [selectedUser]);

  useEffect(() => {
    const dt = getDateFromInputString(toDate, true);
    const isDateTime = typeof dt?.getTime === 'function';
    switch (subscriptionAction) {
      case SUBSCRIPTION_ACTIONS.add:
        setSubmitSubscriptionEnabled(
          isDateTime ? (dt.getTime() > (new Date()).getTime()) : false,
        );
        break;
      case SUBSCRIPTION_ACTIONS.edit:
        setSubmitSubscriptionEnabled(isDateTime);
        break;
      default:
        break;
    }
  }, [toDate, subscriptionAction]);

  useEffect(() => {
    if (!devices) {
      setLocalDevices(null);
      return;
    }
    const filteredDevices = (search.length > 0
      ? (devices || []).filter((d) => (d.name.includes(search)))
      : (devices || []))
      .filter((d) => (isOnlyActive ? d?.subscription?.is_active : true));

    const prevSelectedDeviceId = selectedDevice?.id;

    if (filteredDevices.length === 0) {
      setSelectedDevice(null);
      resetDeviceDependences();
    } else if (filteredDevices.length === 1) {
      setSelectedDevice(filteredDevices[0]);
      resetDeviceDependences();
    } else if (selectedDevice && !filteredDevices.find((d) => d.id === selectedDevice.id)) {
      setSelectedDevice(null);
      resetDeviceDependences();
    }

    setLocalDevices(filteredDevices);

    if (prevSelectedDeviceId) {
      setSelectedDevice(filteredDevices.find((d) => d.id === prevSelectedDeviceId) || null);
    }
  }, [search, isOnlyActive, devices]);

  return (
    <Container>
      {dataProcessing?.[DATA_TYPES.userDevices] && <Loader />}
      <Wrapper flex bottomMargin={20}>
        <Wrapper flex rightMargin={30}>
          <Input
            placeholder="Поиск"
            value={search}
            onChange={({ target }) => {
              setSearch(target.value);
            }}
          />
          {search.length > 0 && (
            <CloseIconWrapper
              onClick={() => setSearch('')}
            >
              <CloseIcon />
            </CloseIconWrapper>
          )}
          <Icon>
            <SearchIcon />
          </Icon>
        </Wrapper>
        <Checkbox
          checked={isOnlyActive}
          onChange={(checked) => setIsOnlyActive(checked)}
          label="Только с активными подписками"
          type="secondary"
        />
      </Wrapper>
      <Wrapper flex alignStart>
        <List>
          {(localDevices || devices || [])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((device) => (
              <Item
                key={`${device.id}--userDevicesListItem`}
                isActive={device.id === selectedDevice?.id}
                onClick={() => {
                  if (device.id === selectedDevice?.id) {
                    return;
                  }
                  setToDate('');
                  setSelectedDevice(device);
                  resetDeviceDependences();
                }}
                title={`ID устройства ${device.id}`}
              >
                <Text whiteColor={device.id === selectedDevice?.id}>
                  {device.name}
                </Text>
              </Item>
            ))}
        </List>
        {selectedDevice && (
          <UsersMainUserDevicesInfo
            device={selectedDevice}
            setSubscriptionAction={setSubscriptionAction}
          />
        )}
        {subscriptionAction === SUBSCRIPTION_ACTIONS.cancel && (
          <UsersMainUserDevicesInfoWindow
            title="Отмена подписки"
            color="danger"
            buttonTitle="Отмена подписки"
            onClose={() => {
              setSubscriptionAction('');
              setCancelSubscriptionType('');
              setCancelSubscriptionMethod('');
            }}
            onSubmit={handleCancelSubscription}
            disableSubmit={(cancelSubscriptionType === '') || (cancelSubscriptionMethod === '')}
          >
            <CancelSubscriptionTitle>Что отменить</CancelSubscriptionTitle>
            <StyledRadioGroup
              selectedValue={cancelSubscriptionMethod}
              onClickRadioButton={(method) => setCancelSubscriptionMethod(method)}
              itemsCount={Object.entries(SUBSCRIPTION_CANCEL_METHODS_TITLES).length}
              radioMargin={12}
              bottomMargin={12}
              bottomBorder
            >
              {Object.entries(SUBSCRIPTION_CANCEL_METHODS_TITLES).map(([method, title]) => (
                <Radio
                  key={`${method}--userDeviceSubscriptionAction-Method`}
                  value={method}
                  labelText={title}
                />
              ))}
            </StyledRadioGroup>
            <CancelSubscriptionTitle>Как отменить</CancelSubscriptionTitle>
            <StyledRadioGroup
              selectedValue={cancelSubscriptionType}
              onClickRadioButton={(type) => setCancelSubscriptionType(type)}
              itemsCount={Object.entries(SUBSCRIPTION_CANCEL_TYPES_TITLES).length}
            >
              {Object.entries(SUBSCRIPTION_CANCEL_TYPES_TITLES).map(([type, title]) => (
                <Radio
                  key={`${type}--userDeviceSubscriptionAction-Type`}
                  value={type}
                  labelText={title}
                />
              ))}
            </StyledRadioGroup>
          </UsersMainUserDevicesInfoWindow>
        )}
        {subscriptionAction === SUBSCRIPTION_ACTIONS.edit && (
          <UsersMainUserDevicesInfoWindow
            title="Изменить"
            color="danger"
            buttonTitle="Применить"
            onClose={() => setSubscriptionAction('')}
            onSubmit={handleSetSubscription}
            disableSubmit={!submitSubscriptionEnabled}
          >
            <Text bottomMargin={12} smallSize>
              Дата окончания подписки
            </Text>
            <Wrapper
              flex
              bottomMargin={12}
            >
              <StyledInputMask
                value={toDate}
                onChange={({ target }) => setToDate(target.value)}
                mask="99.99.9999"
                alwaysShowMask
              />
              <CalendarIconWrapper
                ref={dtRefChange}
                onClick={() => {
                  setIsDtPickerShown(true);
                }}
              >
                <CalendarIcon />
              </CalendarIconWrapper>
            </Wrapper>
            <DatePicker
              isOpen={isDtPickerShown}
              initiator={dtRefChange.current}
              onClose={() => setIsDtPickerShown(false)}
              onApply={(v) => setToDate(format(v, DTPICKER_DATE_FORMAT))}
            />
          </UsersMainUserDevicesInfoWindow>
        )}
        {subscriptionAction === SUBSCRIPTION_ACTIONS.add && (
          <UsersMainUserDevicesInfoWindow
            title="Подписка"
            color="success"
            buttonTitle="Добавить"
            onClose={() => setSubscriptionAction('')}
            onSubmit={handleCreateSubscription}
            disableSubmit={toDate ? !submitSubscriptionEnabled : false}
          >
            <Text bottomMargin={12} smallSize>
              Дата окончания подписки
            </Text>
            <Wrapper>
              <Wrapper
                flex
                bottomMargin={12}
              >
                <StyledInputMask
                  value={toDate || format(new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    new Date().getDate(),
                    23, 59, 59, 999,
                  ), 'dd.MM.yyyy')}
                  onChange={({ target }) => setToDate(target.value)}
                  mask="99.99.9999"
                  alwaysShowMask
                />
                <CalendarIconWrapper
                  ref={dtRefAdd}
                  onClick={() => {
                    setIsDtPickerShown(true);
                  }}
                >
                  <CalendarIcon />
                </CalendarIconWrapper>
              </Wrapper>
              <DatePicker
                value={toDate}
                isOpen={isDtPickerShown}
                initiator={dtRefAdd.current}
                onClose={() => setIsDtPickerShown(false)}
                onApply={(v) => setToDate(format(v, DTPICKER_DATE_FORMAT))}
              />
            </Wrapper>
            <StyledCheckbox
              label="Включить premium"
              checked={usePremium}
              onChange={(checked) => setUsePremium(checked)}
              mb={10}
            />
            <StyledCheckbox
              label="Оплатить с баланса пользователя"
              checked={payFromUserBalance}
              onChange={(checked) => setPayFromUserBalance(checked)}
            />
          </UsersMainUserDevicesInfoWindow>
        )}
      </Wrapper>
      {(localDevices || []).map((d) => d.id).includes(selectedDevice?.id || 0) && (
        <MainUserDevicesListHistory device={selectedDevice} />
      )}
    </Container>
  );
};
export default UsersMainUserDevicesList;

const Container = styled.div`
  padding: 20px 20px 30px;

  background: ${background01};
  border-radius: 2px;
`;

const Wrapper = styled.div`
  position: relative;
  ${({ flex }) => flex && 'display: flex;'}
  ${({ alignStart }) => alignStart && 'align-items: flex-start;'}
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`}
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}
`;

const Input = styled.input`
  width: 220px;
  padding-bottom: 4px;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${secondary};
  outline: none;
`;

const Icon = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & svg{
    fill: ${secondary};
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  right: 1.75rem;
  top: 2px;

  cursor: pointer;
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}

  font-family: Gilroy;
  font-size: ${({ smallSize }) => (smallSize ? 13 : 16)}px;
  color: ${({ whiteColor }) => (whiteColor ? white : text01)};
  line-height: 19px;
`;

const List = styled.div`
  width: 400px;
  max-height: 500px;
  overflow-y: auto;
  margin-right: 30px;

  background-color: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  padding-left: 22px;

  cursor: pointer;

  &:hover {
    background-color: ${greyTertiary};
  }
  ${({ isActive }) => isActive && `
    background-color: ${primary};
    &:hover {
      background-color: ${primaryQuart};
      ${Text} {
        color: ${text01} !important;
      }
    }
  `}
`;

const CancelSubscriptionTitle = styled.div`
  color: ${text03};
  font-size: 1.15em;
  margin-bottom: .25rem;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: block;
  ${({ bottomBorder }) => (bottomBorder && `
    border-bottom: solid 1px ${greyBorder};
  `)}
  ${({ bottomMargin }) => (bottomMargin && `
    margin-bottom: ${bottomMargin}px;
  `)}

  & > label:nth-child(${({ itemsCount }) => itemsCount}) {
    margin-bottom: ${({ radioMargin }) => (radioMargin || 24)}px;
  }
`;

const StyledInputMask = styled(InputMask)`
  width: 100%;
  height: 32px;
  padding: 0 10px;

  background-color: transparent;
  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  outline: none;
`;

const CalendarIconWrapper = styled.div`
  margin-left: .25rem;
  cursor: pointer;
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ mb }) => (mb && `margin-bottom: ${mb}px;`)}
  & span {
    line-height: 100%;
  }
`;
