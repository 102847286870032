import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  text01,
  white,
  secondary,
} from '@/common/constants/theme';
import PCIcon from '../../../../../PersonalPageScreen/components/icons/PCIcon';
import LaptopIcon from '../../../../../PersonalPageScreen/components/icons/LaptopIcon';
import TabletIcon from '../../../../../PersonalPageScreen/components/icons/TabletIcon';
import SmartphoneIcon from '../../../../../PersonalPageScreen/components/icons/SmartphoneIcon';
import PCWithoutKeyboardIcon from '../../../../../PersonalPageScreen/components/icons/PCWithoutKeyboardIcon';
// import MinistraTVIcon from '../../../../../PersonalPageScreen/components/icons/MinistraTVIcon';
import RemoteControlIcon from '../../../../../PersonalPageScreen/components/icons/RemoteControlIcon';

const HomeBeginWatchDeviceCards = () => {
  const { t } = useTranslation('translations');

  const [hovered, setHovered] = useState(-1);

  const devicesDevices = {
    android: [
      'OttPlayer',
      'Kodi',
      'IPTVextreme',
      'Televizo',
      'SmartIPTV',
      'Halva',
      t('homePage.beginWatch.andMore'),
    ],
    apple: [
      'm3u',
      'm3u8',
      'OttPlayer',
      'VLCOnline',
      'Kodi',
    ],
  };

  const devices = [
    {
      title: t('homePage.beginWatch.devices.devices.pc'),
      icon: <PCIcon />,
      devices: [
        'OttPlayer',
        'MAG250',
        'IPTVplayer',
        'LazyIPTV',
        'DUNE',
      ],
    },
    {
      title: t('homePage.beginWatch.devices.devices.laptop'),
      icon: <LaptopIcon />,
      devices: [
        'OttPlayer',
        'MAG250',
        'IPTVplayer',
        'LazyIPTV',
        'DUNE',
      ],
    },
    {
      title: t('homePage.beginWatch.devices.devices.android'),
      icon: <TabletIcon />,
      devices: devicesDevices.android,
    },
    {
      title: t('homePage.beginWatch.devices.devices.apple'),
      icon: <SmartphoneIcon />,
      devices: devicesDevices.apple,
    },
    {
      title: t('homePage.beginWatch.devices.devices.tv'),
      icon: <PCWithoutKeyboardIcon />,
      devices: [
        'LG',
        'Samsung',
        'OttPlayer',
        'SmartIPTV',
      ],
    },
    {
      title: t('homePage.beginWatch.devices.devices.androidTv'),
      icon: <RemoteControlIcon />,
      devices: devicesDevices.android,
    },
    {
      title: t('homePage.beginWatch.devices.devices.appleTv'),
      icon: <RemoteControlIcon />,
      devices: devicesDevices.apple,
    },
  ];

  // eslint-disable-next-line no-shadow
  const renderCard = (title, icon, i, devicesArr) => (
    <Card
      key={`${i}-deviceCardsListItem`}
      hovered={hovered === i}
      onMouseEnter={() => setHovered(i)}
      onMouseLeave={() => setHovered(-1)}
    >
      {hovered === i ? (
        <HoveredContentContainer>
          {devicesArr.map((item, index) => (devices[i].devices.length
            === index + 1 ? (
              <Text
                key={`PubP-HBWDC-I-${item}`}
                whiteColor
                // leftMargin={index < 7}
                fontSizeSmall
              >
                {item}
              </Text>
            ) : (
              <Text
                key={`PubP-HBWDC-I-${item}`}
                whiteColor
                // leftMargin={index < 7}
                fontSizeSmall
              >
                {item}
                ,
              </Text>
            )))}
        </HoveredContentContainer>
      ) : (
        <>
          {icon}
          <Text topMargin>
            {title}
          </Text>
        </>
      )}
    </Card>
  );

  return (
    <Container>
      {devices.map((item, i) => renderCard(
        item.title,
        item.icon,
        i,
        item.devices,
      ))}
    </Container>
  );
};

export default HomeBeginWatchDeviceCards;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 40px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ hovered }) => (hovered ? 'flex-start' : 'center')};
  flex-wrap: wrap;
  align-items: ${({ hovered }) => (hovered ? 'flex-start' : 'center')};

  width: calc(12.5% - 9px);
  height: 148px;

  ${({ hovered }) => (hovered ? (`
    background: ${secondary};
  `) : (`
    background: ${white};
    box-shadow: 0px 4px 24px rgba(160, 160, 160, 0.25);
  `))}
  border-radius: 4px;

  transition: 0.3s ease-in-out;

  &:not(:last-child) {
    margin-right: 9px;
  }
`;

const HoveredContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    height: calc(100% - 10px);
    width: 8px;
    border: solid ${white} 1px;
  }
  &:after {
    left: 5px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-right: none !important;
  }
  &:before {
    right: 5px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-left: none !important;
  }
`;

const Text = styled.div`
  ${({ topMargin }) => topMargin && ('margin-top: 20px;')};
  ${({ leftMargin }) => leftMargin && ('margin-left: 16px')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${({ whiteColor }) => (whiteColor ? white : text01)};
  line-height: 24px;
  letter-spacing: 0.16px;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  text-align: center;

  ${({ fontSizeSmall }) => fontSizeSmall && (`
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
  `)}
`;
