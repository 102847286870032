import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import {
  background01,
  inputBorder,
  text01,
  white,
} from '@/common/constants/theme';
import Checkbox from '@/common/components/Checkbox';
import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';
import { useParams, useHistory } from 'react-router-dom';
import RichTextEditor from '@/common/components/RichTextEditor/RichTextEditor';
import { useBaseContext } from '../BaseContext';
import { useAdminContext } from '../../../../../AdminContext';
import { AdminMainPopupHeader } from '../../../components/MainPopupComponents';
import Dropdown from './Dropdown';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from '@/common/components/DatePicker';
import CalendarIcon from '@/screens/PersonalPageScreen/components/icons/CalendarIcon';
import dayjs from 'dayjs';
import CloseIcon from '@/common/components/icons/CloseIcon';
import { Disable } from 'react-disable';

import { getCountriesRaw } from '@/store/admin/selectors';

const INITIAL_DATA = {
  country: [],
  is_country: false,
  title: '',
  short_text: '',
  text: '',
};

const EditNews = () => {
  const [news, setNews] = useState(INITIAL_DATA);
  const [isLoading, setLoading] = useState(false);
  const {
    getNewsById,
    updateNewsById,
    createNews,
    uploadImage,
  } = useBaseContext();
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const [tmpNewsId, setTmpNewsId] = useState(null);
  const [disableEdit, setDisableEdit] = useState(false);

  const { countries, loadCountries } = useAdminContext();
  const history = useHistory();
  const { id } = useParams();
  const isTemplate = (item) => !item.is_news_pages && !item.is_pushes && !item.is_send_email;

  const storedCountriesRaw = useSelector(getCountriesRaw);

  useEffect(() => {
    if (!storedCountriesRaw) {
      setLoading(true);
      loadCountries();
    } else if (id !== 'new') {
      setLoading(true);
      getNewsById(id)
        .then((res) => {
          const countryArr = res.country
            .map((key) => storedCountriesRaw.find((country) => country.key === key));
          setNews({ ...res, country: countryArr });
          setDisableEdit(!isTemplate(res));
          setLoading(false);
        });
    } else {
      setTmpNewsId(uuidv4());
      setLoading(false);
    }
  }, [id, storedCountriesRaw]);

  const handleChange = (field) => (value) => {
    setNews((prevNews) => ({ ...prevNews, [field]: value }));
  };

  const handleSave = () => {
    const countryArr = news.country.map(({ key }) => key);
    if (id === 'new') {
      createNews({ ...news, country: countryArr, tmp_news_id: tmpNewsId })
        .then(() => history.push('/admin/public/news'));
    } else {
      updateNewsById({ ...news, country: countryArr }, id)
        .then(() => history.push('/admin/public/news'));
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const date = news.date_end
    ? dayjs(news.date_end, 'YYYY-MM-DD', true).toDate()
    : null;

  const InputDate = () => (
    <CalendarWrapper>
      <StyledInput onClick={() => setIsCalendarOpened(true)}>
        {date ? format(date, 'dd.MM.yyyy') : ''}
        {news.date_end && (
          <Clickable onClick={(e) => {
            e.stopPropagation();
            handleChange('date_end')(null);
          }}
          >
            <CloseIcon />
          </Clickable>
        )}
      </StyledInput>
      <DatePicker
        isOpen={isCalendarOpened}
        noApplyButton
        value={date}
        onChange={(value) => {
          handleChange('date_end')(format(new Date(value), 'yyyy-MM-dd'));
          setIsCalendarOpened(false);
        }}
        onClose={() => setIsCalendarOpened(false)}
      />
      <Icon
        topMargin
        onClick={() => setIsCalendarOpened(true)}
      >
        <CalendarIcon />
      </Icon>
    </CalendarWrapper>
  );

  const onUploadImage = async (file) => new Promise(
    (resolve, reject) => {
      uploadImage(file, id, tmpNewsId)
        .then(({ image }) => {
          resolve(image);
        }).catch((ex) => {
          reject(ex);
        });
    },
  );

  return (
    <Container>
      <AdminMainPopupHeader
        linkText="Вернуться “Управление Новостями”"
        title="Создать Новость"
        handleClose={() => history.push('/admin/public/news')}
      />
      <Block>
        <Column>
          <InputWrapper>
            <Label>Тема:</Label>
            <Input
              value={news.title}
              onChange={({ target }) => handleChange('title')(target.value)}
            />
          </InputWrapper>
          <RichTextWrapper>
            <Label>Короткое описание:</Label>
            <RichTextEditor
              text={news.short_text}
              onChange={handleChange('short_text')}
              simpleText
            />
          </RichTextWrapper>
          <RichTextWrapper>
            <Label>Содержимое:</Label>
            <RichTextEditor
              text={news.text}
              onChange={handleChange('text')}
              uploadImage={onUploadImage}
            />
          </RichTextWrapper>
        </Column>
      </Block>
      <Disable
        disabled={disableEdit}
      >
        <Block>
          <LeftContent>
            <Column>
              <Label>Отобразить новость:</Label>
              <StyledCheckbox
                checked={news.is_news_pages}
                onChange={handleChange('is_news_pages')}
                type="secondary"
                label="На странице новостей"
              />
              <StyledCheckbox
                checked={news.is_pushes}
                onChange={handleChange('is_pushes')}
                type="secondary"
                label="В уведомлениях"
              />
              <StyledCheckbox
                checked={news.is_send_email}
                onChange={handleChange('is_send_email')}
                type="secondary"
                label="Отправить на e-mail"
              />
            </Column>
            <LongColumn>
              <Label>Дата окончания действия:</Label>
              {InputDate()}
              <StyledCheckbox
                checked={news.is_country}
                onChange={handleChange('is_country')}
                type="secondary"
                label="Отображать новость только для данных стран:"
              />
              <Dropdown
                placeholder="Выберите опцию"
                options={countries}
                allowClear
                value={news.country}
                disabled={!news.is_country}
                onSelect={handleChange('country')}
              />
            </LongColumn>
          </LeftContent>
        </Block>
      </Disable>
      <StyledButton
        isBoldText
        isUpperCase
        width="small"
        onClick={handleSave}
      >
        {isTemplate(news) ? 'Сохранить' : 'Опубликовать' }
      </StyledButton>
    </Container>
  );
};

export default EditNews;

const Container = styled.div`
  margin-right: 10px;
`;

const Block = styled.div`
  display: flex;
  background: ${background01};
  border-radius: 2px;
  margin: 20px 0;
  padding: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LongColumn = styled(Column)`
  flex: 4;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RichTextWrapper = styled(InputWrapper)`
  margin-top: 16px;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;
`;

const Input = styled.input`
  flex: 1;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;
  border: none;
  outline: none;
  margin-top: 12px;
  padding: 9px 15px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;
`;

const LeftContent = styled.div`
  display: flex;
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ withoutMargin }) => !withoutMargin && `
    margin-top: 16px
  `}
`;

const StyledButton = styled(Button)`
  margin-left: 20px;
`;

const Icon = styled.div`
  ${({ topMargin }) => topMargin && (`
    position: relative;
    top: 6px;
  `)};
  margin-left: 10px;
  cursor: pointer;
`;

const StyledInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 102px;
  height: 24px;

  background: ${white};
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: ${text01};
`;

const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}

  height: 24px;
  margin-top: 16px;

  & .main-datepicker {
    left: -90px;
    bottom: -20px;
  }
`;

const Clickable = styled.span`
  cursor: pointer;
  margin-left: 8px;
`;
