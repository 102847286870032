import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  white,
  text01,
} from '@/common/constants/theme';
import CloseIcon from '@/common/components/icons/CloseIcon';

const checkIsBlockButton = (classNames) => {
  let isBlockButton = false;

  for (let i = 0; i < classNames.length; i += 1) {
    if (classNames[i] === 'users-block-trigger') {
      isBlockButton = true;
    }
  }

  return isBlockButton;
};

const UsersMainUserInfoBlock = ({ onClose, children, left }) => {
  const wrapperRef = useRef(null);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current
          && !ref.current.contains(event.target) && !checkIsBlockButton(event.target.classList)) {
          event.preventDefault();
          onClose();
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  return (
    <Container ref={wrapperRef} left={left}>
      <MainWrapper>
        <Icon onClick={onClose}>
          <CloseIcon />
        </Icon>
        {children}
      </MainWrapper>
    </Container>
  );
};

export default UsersMainUserInfoBlock;

const Container = styled.div`
  width: 400px;

  position: absolute;
  top: 35px;
  left: ${({ left }) => (left || 0)};
  z-index: 999;

  background: ${white};
  border: 1px solid ${borderSecondary};
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  cursor: default;
`;

const MainWrapper = styled.div`
  padding: 30px 20px 20px;

  position: relative;
`;

const Icon = styled.div`
  display: flex;
  align-items: flex-start;

  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;

  & svg path {
    fill: ${text01};
  }
`;
