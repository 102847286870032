import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import {
  borderSecondary,
  primary, red, secondary,
  greyText, text01,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';
import Dropdown from '@/common/components/Dropdown';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import EditIcon from '@/common/components/icons/EditIcon';
import TableBody from '@/common/components/Table/components/TableBody';
import AdminTablePagination from '../../components/Table/components/TablePagination';
// import TableFilterArrows from '../../../TableFilterArrows';
import AdminDeleteModal from '../../../Modals/DeleteModal';
import SearchIcon from '../../../icons/SearchIcon';
import DeleteIcon from '../../../icons/DeleteIcon';

import EyeIcon from '@/common/components/icons/EyeIcon';
import CloseIcon from '@/common/components/icons/CloseIcon';

import { useAppContext } from '@/AppContext';
import { useAdminContext } from '../../../../AdminContext';
import {
  BASE_PATH,
  DATA_TYPES,
  EMPTY_OPTION,
  EMPTY_LANGS_OPTION,
  useFAQContext,
} from '../FaqContext';

const SIZES = ['80px', '80px', '80px', '640px', '150px', '130px', '120px'];

const FAQList = () => {
  const history = useHistory();
  const { siteLanguages: languages } = useAppContext();
  const { formatDateWithZone } = useAdminContext();
  const {
    dataProcessing,
    FAQDataList,
    FAQDataCount,
    filters,
    setFilters,
    clearSelectedFAQItem,
    deleteFAQItem,
  } = useFAQContext();

  const [deletedFAQItem, setDeletedFAQItem] = useState(null);
  const [langOptionsList, setLangOptionsList] = useState(null);

  const publishedList = [
    EMPTY_OPTION,
    { key: false, title: 'Драфт' },
    { key: true, title: 'Опубликован' },
  ];

  const getPageCount = () => Math.ceil(FAQDataCount / filters.limit);

  const langToOption = (lang) => ({
    key: lang?.id || EMPTY_OPTION.key, title: lang?.name || EMPTY_OPTION.title,
  });
  const langFromOption = (langOpt) => (languages || []).find((l) => l.id === langOpt.key) || null;

  const publishedToOption = (isPublished) => publishedList
    .find((o) => o.key === isPublished) || EMPTY_OPTION;

  const getLangText = (item) => (languages || []).find((l) => l.id === item?.language)?.name || 'Не задан!';

  const getPublishedText = (item) => (item?.is_published ? 'Опубликован' : 'Драфт');

  const handleSearch = (val) => {
    setFilters((prevState) => ({ ...prevState, search: val, page: 1 }));
  };

  const handleChangePage = (val) => {
    setFilters((prevState) => ({ ...prevState, page: val }));
  };

  const handleChangeLangFilter = (val) => {
    setFilters((prevState) => ({ ...prevState, language: langFromOption(val)?.id || '' }));
  };

  const handleChangeIsPublishedFilter = (val) => {
    setFilters((prevState) => ({ ...prevState, is_published: val.key }));
  };

  useEffect(() => {
    if (languages) {
      setLangOptionsList([
        EMPTY_LANGS_OPTION,
        ...languages.map(langToOption),
      ]);
    }
  }, [languages]);
  return (
    <Container>
      <AdminDeleteModal
        content={`Вопрос "${deletedFAQItem?.question_title}" (ID ${deletedFAQItem?.id})`}
        isOpen={deletedFAQItem}
        onClose={() => setDeletedFAQItem(null)}
        handleDelete={() => deleteFAQItem(deletedFAQItem.id)}
      />
      {dataProcessing[DATA_TYPES.faqList] && <Loader />}
      <Actions>
        <Button
          color={primary}
          onClick={() => {
            clearSelectedFAQItem();
            history.push(`${BASE_PATH}new`);
          }}
        >
          Добавить вопрос
        </Button>
      </Actions>
      <Table
        dataLength={FAQDataCount}
        limit={10}
        page={filters?.page || 1}
        cellSizes={SIZES}
        textAlign="left"
      >
        <TableHead>
          <HeadItem>
            Дата
          </HeadItem>
          <HeadItem>
            Порядок
          </HeadItem>
          <HeadItem
            title="Просмотров"
            flex
            isCentered
          >
            <EyeIcon />
          </HeadItem>
          <HeadItem flex>
            Вопрос
            <InputWrapper>
              <Input
                title="Поиск по вопросам и ответам"
                placeholder="Поиск"
                value={filters?.search || ''}
                onChange={({ target }) => handleSearch(target.value)}
              />
              { (filters?.search || '').length > 0 && (
                <ClearSearchControl
                  onClick={() => handleSearch('')}
                >
                  <CloseIcon fill={red} />
                </ClearSearchControl>
              )}
              <Icon>
                <SearchIcon />
              </Icon>
            </InputWrapper>
          </HeadItem>
          <HeadItem
            title="Язык"
          >
            <Dropdown
              value={(langOptionsList || [])
                .find((o) => o.key === filters?.language) || EMPTY_LANGS_OPTION}
              options={langOptionsList || []}
              onSelect={handleChangeLangFilter}
              placeholder="Язык"
            />
          </HeadItem>
          <HeadItem
            title="Статус"
          >
            <Dropdown
              value={publishedToOption(filters?.is_published)}
              options={publishedList}
              onSelect={handleChangeIsPublishedFilter}
              placeholder="Статус"
            />
          </HeadItem>
          <HeadItem>
            Управление
          </HeadItem>
        </TableHead>
        <TableBody
          rowHeight={60}
          minRows={filters?.limit || 10}
          autoRowHeight
          isBottomRowBorder
        >
          {(FAQDataList || []).map((item) => (
            <Row key={`A-FAQT-R--${item.id}`}>
              <RowItem>
                {formatDateWithZone(item.dt_create)}
              </RowItem>
              <RowItem isCentered>
                {item?.order || 0}
              </RowItem>
              <RowItem isCentered>
                {item?.number_of_views || 0}
              </RowItem>
              <RowItem>
                {item.question_title}
              </RowItem>
              <RowItem>
                <TextField
                  color={!item?.language ? red : text01}
                >
                  {getLangText(item)}
                </TextField>
              </RowItem>
              <RowItem>
                <TextField
                  color={!item?.is_published ? greyText : text01}
                >
                  {getPublishedText(item)}
                </TextField>
              </RowItem>
              <RowItem>
                <Wrapper
                  flex
                  justify="center"
                >
                  <Icon
                    onClick={() => {
                      clearSelectedFAQItem();
                      history.push(`${BASE_PATH}${item.id}`);
                    }}
                  >
                    <EditIcon />
                  </Icon>
                  <Icon
                    onClick={() => setDeletedFAQItem(item)}
                  >
                    <DeleteIcon />
                  </Icon>
                </Wrapper>
              </RowItem>
            </Row>
          ))}
        </TableBody>
        <AdminTablePagination
          page={filters?.page || 1}
          setPage={handleChangePage}
          pageCount={getPageCount()}
          recordsCount={FAQDataCount}
          title="Вопросов: "
        />
      </Table>
    </Container>
  );
};

export default FAQList;

const Container = styled.div`
  font-family: Gilroy, sans-serif;
`;

const Actions = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const HeadItem = styled.div`
  width: ${({ flex }) => (flex ? '100%' : 'auto')};
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  ${({ flex }) => (flex && 'justify-content: space-between;')};
  ${({ isCentered, flex }) => (isCentered && (
    flex ? 'justify-content: center;' : 'text-align: center;'
  ))}
`;

const Icon = styled.div`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer;')};

  &:not(:last-of-type) {
    margin-right: .75em;
  }
`;

const ClearSearchControl = styled.div`
  position: absolute;

  top: 0;
  right: 22px;

  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  line-height: 18px;
  margin-right: 20px;

  & > :not(${ClearSearchControl}) path {
    stroke: ${secondary};
    stroke-opacity: 1;
  }
`;

const Input = styled.input`
  width: 205px;
  margin-left: 15px;
  margin-right: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(255 152 0 / 50%);;
  outline: none;
`;

const Row = styled.div`
`;

const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  ${({ isCentered }) => (isCentered && `
    text-align: center;
    justify-content: center;
  `)}
`;

const TextField = styled.div`
  flex: 1;
  margin-right: 30px;
  color: ${({ color }) => (color || text01)}
`;

const Wrapper = styled.div`
  width: 100%;
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flex, justify }) => (flex && justify && `justify-content: ${justify};`)}
  ${({ flex, align }) => (flex && align && `align-items: ${align};`)}
`;

const Button = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin: 10px 0;
  padding: 0 20px;
  color: ${({ color }) => color};
  cursor: pointer;
  border-right: 1px solid ${borderSecondary};
  :last-of-type {
    border: none;
  }
  transition: all 0.25s ease-out 0s;

  &:hover {
    color: ${({ color }) => (color === primary ? secondary : primary)};
  }
`;
