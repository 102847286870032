import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  background01,
  text01,
  primary,
} from '@/common/constants/theme';

import ImgSrc from '@/assets/images/webp/channels-header-img.webp';
import StalkerImgSrc from '@/assets/images/webp/channels-header-img-stalker.webp';

import CloseIcon from '../../../icons/CloseIcon';

import { useChannelsContext } from '../ChannelsContext';

const ChannelsHeader = ({ isFullWidth, disableThis }) => {
  const { t } = useTranslation('translations');
  const { channelsFilters } = useChannelsContext();
  const { isStalker } = channelsFilters;

  return (
    <Container isFullWidth={isFullWidth}>
      <Wrapper
        flex
      >
        <Wrapper
          isFullWidth={isFullWidth}
          rightMargin
          isStalker={isStalker}
        >
          <Title>
            {t('steps.step4.content.header.title')}
          </Title>
          <Text bottomMargin>
            {t('steps.step4.content.header.descr01')}
          </Text>
          <Text maxWidth>
            {t('steps.step4.content.header.descr02')}
          </Text>
        </Wrapper>
        <Wrapper>
          <Img
            src={isStalker ? StalkerImgSrc : ImgSrc}
            alt=""
            isStalker={isStalker}
          />
        </Wrapper>
      </Wrapper>
      <CloseBlock
        title={t('personalPage.hide')}
        onClick={() => disableThis && disableThis()}
      >
        <CloseIcon />
      </CloseBlock>
    </Container>
  );
};

export default ChannelsHeader;

const Container = styled.div`
  ${({ isFullWidth }) => (isFullWidth ? `
    margin: 0 0 1.25rem 0;
  ` : `
    margin: 0 19px 1.25rem 0;
  `)}


  position: relative;

  background: ${background01};

  &::after {
    content: '';

    width: 100%;

    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;

    background-color: ${background01};
  }
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ rightMargin, isStalker, isFullWidth }) => rightMargin && (`
    margin-right: ${(isFullWidth ? 65 : 0) + (isStalker ? 135 : 65)}px;
  `)}
`;

const Text = styled.div`
  max-width: ${({ maxWidth }) => (maxWidth ? 238 : 287)}px;
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 17px;')}

  font-family: Gilroy;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const Title = styled(Text)`
  margin-bottom: 33px;
  margin-top: 39px;

  position: relative;

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  &::before {
    content: '';

    width: 30px;
    height: 4px;

    position: absolute;
    bottom: -10px;
    left: 0;

    background-color: ${primary};
  }
`;

const Img = styled.img`
  width: ${({ isStalker }) => (isStalker ? 290 : 435)}px;
`;

const CloseBlock = styled.div`
  position: absolute;
  cursor: pointer;
  top: .8rem;
  right: .8rem;

  & svg{
    width: 12px;
    height: 12px;

    path {
      stroke: red;
    }
  }
`;
