import React, { useState } from 'react';
import styled from 'styled-components';

import Dropdown from '../../../../../../../../common/components/Dropdown';
import {
  borderSecondary,
  primary,
  greyQuaternary,
  secondary,
} from '@/common/constants/theme';
import { useChannelsContext } from '../../../ChannelsContext';
import GridRowIcon from '../../../../../icons/GridRowIcon';
import GridColumnIcon from '../../../../../icons/GridColumnIcon';
import ChannelsMainCards from './MainCards/ChannelsMainCards';

const ChannelsMainContent = ({
  mainDropdownOptions,
  changeFiltersHandler,
  isDraggedChannel,
  setIsDraggedChannel,
  hoveredGroup,
  setShowDraggedElem,
  showDraggedElem,
}) => {
  const {
    channelsFilters,
    handleDisplayAllChannels,
    handleDisplaySelectedChannels,
    handleHideAllChannels,
    handleDragEndChannel,
    handleDragEndChannelToGroup,
    handleSelectChannel,
    selectedPlaylist,
  } = useChannelsContext();
  const {
    mainDropdown,
    layout,
    isGroups,
  } = channelsFilters;
  const [coord, setCoord] = useState([0, 0]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (hoveredGroup >= 0) {
      handleDragEndChannelToGroup(hoveredGroup);
    } else {
      handleDragEndChannel(oldIndex, newIndex);
    }

    setIsDraggedChannel(false);
    setCoord([0, 0]);
  };

  const onSortMove = (event) => {
    setIsDraggedChannel(true);
    setCoord([event.pageX - 25, event.pageY + 7]);
  };

  const isEditable = !selectedPlaylist?.is_default;

  return (
    <Container>
      <Wrapper
        flex
        bottomSep
        padding
        alignCenter
      >
        <StyledDropdown
          isEditable={isEditable}
          isDisabled={!isEditable}
          options={mainDropdownOptions}
          value={mainDropdownOptions.find((item) => item.key === mainDropdown)}
          onSelect={(item) => {
            if (!isEditable) {
              return;
            }
            changeFiltersHandler('mainDropdown', item.key);
            switch (item.key) {
              case 'all':
                handleDisplayAllChannels();
                break;
              case 'selected':
                handleDisplaySelectedChannels();
                break;
              case 'hideAll':
                handleHideAllChannels();
                break;
              default:
                break;
            }
          }}
          isBoldText
          color={primary}
        />
        <Icon
          rightMargin={15}
          isActive={layout === 'row'}
          onClick={() => changeFiltersHandler('layout', 'row')}
        >
          <GridRowIcon />
        </Icon>
        <Icon
          isActive={layout === 'column'}
          onClick={() => changeFiltersHandler('layout', 'column')}
        >
          <GridColumnIcon />
        </Icon>
      </Wrapper>
      <Wrapper
        selfStart
        isGroups={isGroups}
        row={layout === 'row'}
        scroll
      >
        <ClonedChannelCard
          className="channels-cloned-channel-card"
          hidden={showDraggedElem}
        />
        <ChannelsMainCards
          changeFiltersHandler={changeFiltersHandler}
          isDraggedChannel={isDraggedChannel}
          showDraggedElem={showDraggedElem}
          onSortStart={({ index }) => handleSelectChannel(index, true)}
          onSortMove={onSortMove}
          onSortEnd={(result) => {
            onSortEnd(result);
          }}
          axis="xy"
          coord={coord}
          setShowDraggedElem={setShowDraggedElem}
          helperContainer={document.querySelector('.channels-cloned-channel-card')}
          distance={10}
          isEditable={isEditable}
        />
      </Wrapper>
    </Container>
  );
};

export default ChannelsMainContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ alignCenter }) => alignCenter && ('align-items: center;')}
  ${({ selfStart }) => selfStart && ('align-self: flex-start;')}

  ${({ padding }) => padding && ('padding: 22px 46px 0 0;')}

  ${({ bottomSep }) => bottomSep && (`
    position: relative;

    &::before {
      content: '';

      width: 504px;
      height: 1px;

      position: absolute;
      bottom: -12px;
      right: 0;

      background-color: ${borderSecondary};
    }
  `)}

  @media (max-width: 1360px) {
  ${({ isGroups, row }) => !isGroups && row && (`
    position: relative;
    right: 5px;
  `)}
  }

  @media (max-width: 1198px) {
    right: 0;
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 170px;
  margin-left: auto;
  margin-right: 28px;

  & input {
    width: 97%;
    margin-left: -2px;
    font-size: 12px;
  }

  ${({ isEditable }) => !isEditable && (`
    opacity: 0.2;
    cursor: default !important;
  `)}
`;

const Icon = styled.div`
  ${({ rightMargin }) => rightMargin && (`margin-right: ${rightMargin}px;`)}

  position: relative;
  top: 2px;

  cursor: pointer;

  & svg path {
    transition: 0.2s ease;
  }

  ${({ isActive }) => (isActive ? `
    & svg path {
      fill: ${secondary};
    }
  ` : `
    & svg path {
      fill: ${greyQuaternary};
    }
  `)}
`;

const ClonedChannelCard = styled.div`
  position: relative;
  z-index: 200;

  ${({ hidden }) => hidden && ('display: none;')}

  & .channels-main-card {
    border-color: ${secondary};
  }
`;
