import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { format } from 'date-fns';

import {
  text01,
  secondary,
  lightRed,
  brightGreen,
  primary, black, red, green,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import AdminTablePagination from '../../components/Table/components/TablePagination';

import ArrowDownIcon from '../../../icons/ArrowDownIcon';
import SearchIcon from '../../../../../PersonalPageScreen/components/icons/SearchIcon';

import SubmitWithdrawalModal from '../../../Modals/SubmitWithdrawalModal';
import CancelWithdrawalModal from '../../../Modals/CancelWithdrawalModal';
import DatePickerNew from '@/common/components/DatePickerNew';

import {
  SORT_DIRECTIONS,
  useAdminContext,
} from '../../../../AdminContext';

import {
  useFinanceContext,
  SEARCH_FILTER_TIMEOUT,
  WTRANSACTION_ACTIONS,
  WTRANSACTION_STATUSES,
} from '../FinanceContext';

import ArrowTable from '../../../icons/ArrowTable';
import CloseIcon from '../../../icons/CloseIcon';
import SelectorContainer from '@/common/components/Selector';

const SIZES = ['80px', '140px', '100px', '200px', '200px', '300px'];

const STATUS_COLORS = {
  created: black,
  rejected: red,
  approved: green,
};

const initDates = {
  d_begin: '',
  d_end: '',
};

const emptyModalData = {
  summ: 0,
  user: '',
  paySystem: {},
};

const defaultWithdrawalOption = { key: '-1', title: 'Платежные системы' };
const defaultStatusOption = { key: '-1', title: 'Статус' };

const FinanceTransfers = () => {
  const {
    getDateFromDateFilter,
    formatUserName,
    formatUserPageLink,
    formatDate,
  } = useAdminContext();

  const {
    withdrawalSystems,
    wTransactions,
    wTransactionsData,
    wTransactionsFilter,
    setWTransactionsFilter,
    isWTransactionsProcerssing,
    selectedWTransaction,
    setSelectedWTransaction,
    wTransactionAction,
    setWTransactionAction,
    approveWithdrawalTransaction,
    rejectWithdrawalTransaction,
  } = useFinanceContext();
  const [isVisible, setIsVisible] = useState(true);

  const [withdrawalSystemsOptions, setWithdrawalSystemsOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const [
    selectedWithdrawalSystemOption, setSelectedWithdrawalSystemOption,
  ] = useState(defaultWithdrawalOption);
  const [selectedStatusOption, setSelectedStatusOption] = useState(defaultStatusOption);

  const [localDates, setLocalDates] = useState(initDates);
  const [currentPage, setCurrentPage] = useState(wTransactionsData?.page || 1);
  const [modalData, setModalData] = useState(emptyModalData);

  const [searchTimeout, setSearchTimeout] = useState(null);

  const [search, setSearch] = useState({});
  const [sortByDate, setSortByDate] = useState(SORT_DIRECTIONS.notSetted);
  const [sortByAmount, setSortByAmount] = useState(SORT_DIRECTIONS.notSetted);

  const formatWithdrawalSystem = (withdrawalSystem) => (
    withdrawalSystem?.name || `Платежная система ID [${withdrawalSystem?.id || 0}]`
  );

  const sorts = {
    asc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.asc],
    desc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.desc],
  };

  const changeSortByDateDirection = () => {
    const directionsKeys = Object.keys(SORT_DIRECTIONS);
    const directions = Object.values(SORT_DIRECTIONS);
    const currentSortIndex = directions.findIndex((s) => s === sortByDate) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortByDate(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);
    setSortByAmount(SORT_DIRECTIONS.notSetted);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'dt_create';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setWTransactionsFilter((prevState) => ({ ...prevState, ordering: `${sign}${field}`.trim() }));
  };

  const changeSortByAmountDirection = () => {
    const directionsKeys = Object.keys(SORT_DIRECTIONS);
    const directions = Object.values(SORT_DIRECTIONS);
    const currentSortIndex = directions.findIndex((s) => s === sortByAmount) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortByAmount(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);
    setSortByDate(SORT_DIRECTIONS.notSetted);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'amount';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setWTransactionsFilter((prevState) => ({ ...prevState, ordering: `${sign}${field}`.trim() }));
  };

  const submitApprove = (item) => {
    setModalData({
      ...emptyModalData,
      summ: parseFloat(item.amount),
      user: item.user.email,
      paySystem: item.withdrawal_system.name,
    });
    setSelectedWTransaction(item);
    setWTransactionAction(WTRANSACTION_ACTIONS.approve);
  };

  const submitReject = (item) => {
    setModalData({ ...emptyModalData });
    setSelectedWTransaction(item);
    setWTransactionAction(WTRANSACTION_ACTIONS.reject);
  };

  const getSearchString = (searchObject) => {
    const retValArray = [];
    // eslint-disable-next-line no-unused-vars
    Object.entries(searchObject).forEach(([key, value]) => {
      if ((value?.length || 0) > 0) { retValArray.push(value); }
    });
    return retValArray.join(' ');
  };

  useEffect(() => {
    if ((withdrawalSystems?.length || 0) > 0) {
      setWithdrawalSystemsOptions([
        { key: -1, title: 'Все' },
        ...withdrawalSystems.map((s) => ({ key: s.id, title: s.name })),
      ]);
    }
    if (statusOptions.length === 0) {
      setStatusOptions([
        { key: -1, title: 'Все' },
        ...Object.entries(WTRANSACTION_STATUSES).map(([key, value]) => ({ key, title: value })),
      ]);
    }
  }, [withdrawalSystems]);

  useEffect(() => {
    setLocalDates({
      d_begin: getDateFromDateFilter(wTransactionsFilter.d_begin),
      d_end: getDateFromDateFilter(wTransactionsFilter.d_end),
    });
    setCurrentPage(wTransactionsFilter.page);
  }, [wTransactionsFilter]);

  useEffect(() => {
    setWTransactionsFilter((prevState) => ({ ...prevState, page: currentPage }));
  }, [currentPage]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(setTimeout(() => {
      setWTransactionsFilter((prevState) => (
        { ...prevState, q: getSearchString(search) }
      ));
    }, SEARCH_FILTER_TIMEOUT));
  }, [search]);

  return (
    <Container>
      <SubmitWithdrawalModal
        isOpen={selectedWTransaction && wTransactionAction === WTRANSACTION_ACTIONS.approve}
        setIsOpen={() => {
          setSelectedWTransaction(null);
          setWTransactionAction(WTRANSACTION_ACTIONS.notSet);
        }}
        modalData={modalData}
        onSubmit={approveWithdrawalTransaction}
      />
      <CancelWithdrawalModal
        isOpen={selectedWTransaction && wTransactionAction === WTRANSACTION_ACTIONS.reject}
        setIsOpen={() => {
          setSelectedWTransaction(null);
          setWTransactionAction(WTRANSACTION_ACTIONS.notSet);
        }}
        isReasonNeeded
        onSubmit={rejectWithdrawalTransaction}
      />
      <FlexWrapper flexSb bottomMargin isOpen={isVisible} bottomLine>
        <FlexWrapper alignCenter>
          <Title>
            Заявки на вывод
          </Title>
          {isVisible && (
            <DatePickerNew
              values={localDates}
              onSubmit={(interval) => {
                const newDates = {
                  ...localDates,
                  d_begin: interval?.dateStart ? format(interval.dateStart, 'yyyy-MM-dd') : '',
                  d_end: interval?.dateEnd ? format(interval.dateEnd, 'yyyy-MM-dd') : '',
                };
                setWTransactionsFilter((prevState) => ({ ...prevState, ...newDates, page: 1 }));
              }}
            />
          )}
        </FlexWrapper>
        <FlexWrapper pointer onClick={() => setIsVisible((prevState) => !prevState)}>
          <Text>
            {isVisible ? 'Свернуть' : 'Развернуть'}
          </Text>
          <Icon isRotate={!isVisible}>
            <ArrowDownIcon />
          </Icon>
        </FlexWrapper>
      </FlexWrapper>
      {isVisible && (
        <Table
          page={wTransactionsFilter?.page || 1}
          limit={wTransactionsFilter?.limit || 1}
          cellSizes={SIZES}
          textAlign="left"
          dataLength={wTransactionsData?.count || 1}
        >
          {isWTransactionsProcerssing && <Loader />}
          <TableHead>
            <HeadItem flex>
              Дата
              <IconsWrapper
                onClick={() => changeSortByDateDirection()}
              >
                {sorts.asc.includes(sortByDate)
                  && (
                    <IconSort isRotate>
                      <ArrowTable />
                    </IconSort>
                  )}
                {sorts.desc.includes(sortByDate)
                  && (
                    <IconSort leftMargin top>
                      <ArrowTable />
                    </IconSort>
                  )}
              </IconsWrapper>
            </HeadItem>
            <HeadItem>
              Пользователь
            </HeadItem>
            <HeadItem flex>
              Сумма
              <IconsWrapper
                onClick={() => changeSortByAmountDirection()}
              >
                {sorts.asc.includes(sortByAmount)
                  && (
                    <IconSort isRotate>
                      <ArrowTable />
                    </IconSort>
                  )}
                {sorts.desc.includes(sortByAmount)
                  && (
                    <IconSort leftMargin top>
                      <ArrowTable />
                    </IconSort>
                  )}
              </IconsWrapper>
            </HeadItem>
            <HeadItem>
              <SelectorContainer
                title="Система вывода"
                value={selectedWithdrawalSystemOption.key !== -1
                  ? selectedWithdrawalSystemOption : defaultWithdrawalOption}
                options={withdrawalSystemsOptions}
                onSelect={(value) => {
                  setWTransactionsFilter((prevState) => (
                    {
                      ...prevState,
                      withdrawal_system: value.key !== -1 ? value.key : '',
                      page: value.key !== selectedWithdrawalSystemOption.key
                        ? 1
                        : wTransactionsFilter.page,
                    }
                  ));
                  setSelectedWithdrawalSystemOption(value);
                }}
              />
            </HeadItem>
            <HeadItem>
              Кошелек
            </HeadItem>
            <HeadItem flex spaceAround>
              <StyledSelectorContainer
                title="Статус заявки"
                value={selectedStatusOption?.key === -1
                  ? defaultStatusOption : selectedStatusOption}
                options={statusOptions}
                onSelect={(value) => {
                  setWTransactionsFilter((prevState) => (
                    {
                      ...prevState,
                      status: value.key !== -1 ? value.key : '',
                      page: value.key !== selectedStatusOption.key ? 1 : wTransactionsFilter.page,
                    }
                  ));
                  setSelectedStatusOption(value);
                }}
                minWidth="9em"
                marginRight="2em"
              />
              <SearchContainer>
                <Input
                  value={search.email || ''}
                  onChange={({ target }) => setSearch({ ...search, email: target.value })}
                  placeholder="Поиск"
                />
                <Icon top>
                  <SearchIcon />
                </Icon>
                {(search.email || '').length > 0
                  && (
                    <CloseIconContainer
                      onClick={() => setSearch({ ...search, email: '' })}
                    >
                      <CloseIcon />
                    </CloseIconContainer>
                  )}
              </SearchContainer>
            </HeadItem>
          </TableHead>
          <TableBody>
            {wTransactions && wTransactions.map((item) => (
              <div key={`FWR-row--${item.id}`}>
                <RowItem>
                  {item?.dt_create && formatDate(item.dt_create)}
                </RowItem>
                <RowItem>
                  {item?.user && (
                    <StyledLink to={formatUserPageLink(item.user)}>
                      {formatUserName(item.user)}
                    </StyledLink>
                  )}
                </RowItem>
                <RowItem>
                  <Text greenColor bold>
                    {item?.amount || ''}
                  </Text>
                </RowItem>
                <RowItem>
                  {item?.withdrawal_system && formatWithdrawalSystem(item.withdrawal_system)}
                </RowItem>
                <RowItem>
                  {item?.withdrawal_system_user_identifier || ''}
                </RowItem>
                <RowItem flex>
                  {item.status === 'created' ? (
                    <>
                      <Text
                        greenColor
                        rightMargin
                        leftMarginAuto
                        bold
                        pointer
                        onClick={() => submitApprove(item)}
                      >
                        Выполнить
                      </Text>
                      <RedText
                        leftMarginNone
                        onClick={() => submitReject(item)}
                      >
                        Отменить
                      </RedText>
                    </>
                  ) : (
                    <StatusText useColor={item.status} leftMarginAuto>
                      {WTRANSACTION_STATUSES[item.status]}
                    </StatusText>
                  )}
                </RowItem>
              </div>
            ))}
          </TableBody>
          <AdminTablePagination
            page={currentPage}
            pageCount={
              Math.ceil((wTransactionsData?.count || 0) / (wTransactionsFilter?.limit || 1))
            }
            setPage={setCurrentPage}
            recordsCount={wTransactionsData?.count || 0}
          />
        </Table>
      )}
    </Container>
  );
};

export default FinanceTransfers;

const Container = styled.div`
  margin-bottom: 40px;

  & .table-head::after {
    bottom: 4px;
  }
`;

const Text = styled.div`
  ${({ rightMargin }) => rightMargin && 'margin-right: 30px;'}
  ${({ leftMarginAuto }) => leftMarginAuto && 'margin-left: auto;'}

  ${({ pointer }) => pointer && 'cursor: pointer;'}

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  ${({ bold }) => bold && 'font-weight: bold;'}
  line-height: 15px;
  color: ${({ greenColor }) => (greenColor ? brightGreen : text01)};
`;

const Title = styled(Text)`
  margin-right: 16px;

  font-weight: 600;
  font-size: 20px;
  line-height: 15px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ flexSb }) => flexSb && 'justify-content: space-between;'}
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}

  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 16px;'}

  position: relative;

  ${({ pointer }) => pointer && 'cursor: pointer;'}

  ${({ isOpen, bottomLine }) => bottomLine && !isOpen && (`
    &::after {
      content: '';

      height: 1px;

      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;

      background-color: ${text01};
    }
  `)}
`;

const IconsWrapper = styled.div`
  display: flex;

  width: 18px;
  margin-left: 10px;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;

  cursor: pointer;
  transform: rotate(${({ isRotate }) => (isRotate ? 180 : 0)}deg);

  transition: 0.2s ease;
`;

const IconSort = styled.div`
  ${({ leftMargin }) => leftMargin && 'margin-left: 2px;'}

  position: relative;
  ${({ top }) => top && 'top: 3px;'}

  cursor: pointer;
  ${({ isRotate }) => isRotate && 'transform: rotate(-180deg);'}`;

const HeadItem = styled.div`
  ${({ flex }) => flex && `
    display: flex;
    align-items: center;

    width: 100%;
  `}
  ${({ spaceAround }) => (spaceAround ? 'justify-content: space-around;' : '')}
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  max-width: 200px;
  padding-bottom: 4px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${secondary};
  outline: none;
`;

const RowItem = styled.div`
  ${({ flex }) => flex && `
    display: flex;
    justify-content: center;

    width: 100%;
  `}
`;

const RedText = styled(Text)`
  margin-left: ${({ leftMarginNone }) => (leftMarginNone ? '' : 'auto')};

  cursor: pointer;

  font-weight: 600;
  color: ${lightRed};
`;

const StyledLink = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: ${primary};
  line-height: 16px;

  &:hover,
  &:focus {
    color: ${primary};
  }
`;

const StyledSelectorContainer = styled(SelectorContainer)`
  min-width: 8em;
  margin-right: .75rem;
`;

const StatusText = styled(Text)`
  color: ${({ useColor }) => (useColor && STATUS_COLORS[useColor] ? STATUS_COLORS[useColor] : black)};
`;
