/* eslint-disable no-param-reassign,consistent-return */
import i18next from 'i18next';
import RequestService from '../request.service';

import urls from '../routes/logs.api';

// export const getLogsAction = () => new Promise((resolve, reject) => {
//   let retVal = null;
//   RequestService2(urls.logsAll)
//     .then(async (res) => {
//       const timeout = 1000 * 1;
//       const taskInterval = setInterval(() => {
//         RequestService2(urls.logsTask(res.task_id))
//           .then((res2) => {
//             if (res2.status !== 'processed') {
//               clearInterval(taskInterval);
//               retVal = res2;
//               return res2;
//             }
//           });
//       }, timeout);
//       if (retVal) {
//         await resolve(retVal);
//       }
//     })
//     .catch(reject);
// });
export const getAllLogsAction = (taskId = '') => RequestService(taskId.length === 0 ? urls.logsAll : urls.logsTask(taskId));

export const getLogsAction = (filter) => RequestService(
  urls.logs, { method: 'GET', params: filter },
);

const checkHeaderFilters = (item, startDate, endDate, dropdownValue) => {
  if (startDate && endDate && dropdownValue) {
    return (!!((new Date(item.created_at) > startDate
      && new Date(item.created_at) < endDate
      && item.group === dropdownValue)));
  } if (endDate && startDate) {
    return (!!(new Date(item.created_at) < endDate
      && new Date(item.created_at) > startDate));
  } if (endDate && dropdownValue) {
    return (!!(new Date(item.created_at) < endDate
      && item.group === dropdownValue));
  } if (startDate && dropdownValue) {
    return (!!(new Date(item.created_at) > startDate
      && item.group === dropdownValue));
  } if (startDate) {
    return ((new Date(item.created_at) > startDate));
  } if (endDate) {
    return ((new Date(item.created_at) < endDate));
  } if (dropdownValue) {
    return ((item.group === dropdownValue));
  }

  return true;
};

const searchHandler = (value, item) => (!!(
  `${i18next.t(`translations:profile.logs.${item.message.toLowerCase()}`)}${item.additional_part ? `/${item.additional_part}` : ''}`
    .toLowerCase()
    .includes(value.toLowerCase())));

export const filterLogsAction = (
  state,
  {
    dropdown,
    startDate,
    endDate,
    search,
    page,
    limit,
  },
) => {
  const currentState = [...state];
  const currentData = [];

  state.forEach((item, i) => {
    if ((dropdown || startDate || endDate)
      && !checkHeaderFilters(item, startDate, endDate, dropdown)) {
      delete currentState[i];
      return;
    }

    if (search && !searchHandler(search, item)) {
      delete currentState[i];
    }
  });

  currentState.forEach((item) => item && currentData.push(item));

  return {
    currentData: currentData
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .slice((page * limit) - limit, page * limit),
    logsCount: currentData.length,
  };
};

export const addTypesAction = (state) => {
  state.forEach((item) => {
    const message = item.message.toLowerCase();
    if (message === 'sign_in'
        || message === 'sign_in_via_google'
        || message === 'signin_via_vk'
        || message === 'signin_via_fb'
        || message === 'login user') {
      item.type = 'signin';
      return;
    }
    if (message === 'sign up user') {
      item.type = 'signup';
      return;
    }
    if (message === 'turn_balance_notifications_on'
        || message === 'turn_balance_notifications_off'
        || message === 'turn_get_news_notifications_on'
        || message === 'turn_get_news_notifications_off'
        || message === 'turn_notify_about_the_subscription_expiration_on'
        || message === 'turn_notify_about_the_subscription_expiration_off'
        || message === 'turn_subscribe_to_mail_list_on'
        || message === 'turn_subscribe_to_mail_list_off') {
      item.type = 'notification';
      return;
    }
    if (message === 'create device') {
      item.type = 'add';
      return;
    }
    if (message === 'удалено device') {
      item.type = 'delete';
      return;
    }
    if (message === 'user_confirm_email') {
      item.type = 'confirm';
      return;
    }
    if (message === 'selected_a_device'
        || message === 'unselected_a_device'
        || message === 'selected_all_devices') {
      item.type = 'select';
      return;
    }
    if (message === 'обновлено device'
        || message === 'patch device') {
      item.type = 'update';
    }
  });

  return [...state];
};
