import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ModalNew from '@/common/components/ModalNew';
import { text01 } from '@/common/constants/theme';
import Button from '@/common/components/Button';
import Dropdown from '@/common/components/Dropdown';
import alertIcon from '@/assets/icons/alertIcon.svg';
import { useServersContext } from '../ServersContext';

const ServersTransferModal = ({ onClose, deletedServer }) => {
  const { servers, deleteServer } = useServersContext();

  const [serversOptions, setServersOptions] = useState([]);
  const [selectedServer, setSelectedServer] = useState({});

  useEffect(() => {
    const options = [];
    servers.forEach((server) => {
      if (server.id !== servers[deletedServer].id) {
        options.push({ title: server.name, key: server.name, id: server.id });
      }
    });

    setServersOptions(options);
    setSelectedServer(options[0]);
  }, []);

  return (
    <ModalNew
      isOpen
      onCloseModal={onClose}
      title="Сервер"
      icon={alertIcon}
    >
      <Container>
        <Text bottomMargin>
          Выберите сервер для переноса данных:
        </Text>
        <StyledDropdown
          options={serversOptions}
          value={selectedServer}
          onSelect={(option) => setSelectedServer(option)}
          height={32}
        />
        <Text alignCenter>
          ВНИМАНИЕ! Все данные удаленного сервера
          {' '}
          {servers[deletedServer].name}
          {' '}
          будут перенесены на выбранный сервер.
        </Text>
        <Wrapper>
          <StyledButton
            isBoldText
            isUpperCase
            width="small"
            onClick={() => {
              deleteServer(servers[deletedServer].id, selectedServer.id);
              onClose();
            }}
          >
            Применить
          </StyledButton>
        </Wrapper>
      </Container>
    </ModalNew>
  );
};

export default ServersTransferModal;

const Container = styled.div`
  padding: 4px 8px 20px 2px;
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 20px;'}
  ${({ alignCenter }) => alignCenter && (`
    max-width: 350px;
    margin: 0 auto;
    
    text-align: center;
  `)}

  font-family: Gilroy;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: 6px;
  margin-top: 40px;
`;
