import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Step1ImgSrc from '@/assets/images/webp/home-step1.webp';
import Step2ImgSrc from '@/assets/images/webp/home-step2.webp';
import {
  text01,
  text02,
  secondary,
} from '@/common/constants/theme';
import PageContainer from '@/common/components/PageContainer';
import HomeBeginWatchStepCard from './components/HomeBeginWatchStepCard';
import HomeBeginWatchDeviceCards from './components/HomeBeginWatchDeviceCards';
import HomeBeginWatchAsk from './components/HomeBeginWatchAsk';
import HomeBeginBgIcon from '../../../../PersonalPageScreen/components/icons/HomeBeginBgIcon';

import { useAppContext } from '@/AppContext';

const HomeBeginWatch = ({ isUserExist }) => {
  const { t } = useTranslation('translations');

  const { getFromPublicLink } = useAppContext();

  const steps = [
    {
      step: t('homePage.beginWatch.steps.step1.step'),
      title: t('homePage.beginWatch.steps.step1.title'),
      descr: t('homePage.beginWatch.steps.step1.descr'),
      btn: t(`homePage.beginWatch.steps.step1.${isUserExist ? 'btnExists' : 'btn'}`),
      color: 'primary',
      href: getFromPublicLink('/personal-page/devices', isUserExist),
      imgSrc: Step1ImgSrc,
    },
    {
      step: t('homePage.beginWatch.steps.step2.step'),
      title: t('homePage.beginWatch.steps.step2.title'),
      descr: t('homePage.beginWatch.steps.step2.descr'),
      btn: t(`homePage.beginWatch.steps.step2.${isUserExist ? 'btnExists' : 'btn'}`),
      color: 'secondary',
      href: getFromPublicLink('/personal-page/subscription', isUserExist),
      imgSrc: Step2ImgSrc,
    },
  ];

  return (
    <Container>
      <PageContainer isCentered>
        <TitleContainer>
          <Title>{t('homePage.beginWatch.title')}</Title>
          <OrangeTitle>{t('homePage.beginWatch.orangeTitle')}</OrangeTitle>
          <Title>{t('homePage.beginWatch.title02')}</Title>
        </TitleContainer>
        <Wrapper flex>
          {steps.map((item) => (
            <HomeBeginWatchStepCard
              key={`${Math.random()}--home-begin-watch`}
              step={item.step}
              title={item.title}
              descr={item.descr}
              button={item.btn}
              color={item.color}
              imgSrc={item.imgSrc}
              href={item.href}
              isUserExist={isUserExist}
            />
          ))}
        </Wrapper>
        <Wrapper>
          <TitleContainer margin>
            <Title>{t('homePage.beginWatch.devices.title')}</Title>
          </TitleContainer>
          <HomeBeginWatchDeviceCards />
        </Wrapper>
        <Wrapper flex flexSb>
          <Wrapper leftMargin>
            <LittleTitle>
              {t('homePage.beginWatch.connect.title')}
            </LittleTitle>
            <Text greyText bottomMargin alignLeft>
              {t('homePage.beginWatch.connect.step1')}
            </Text>
            <Wrapper flex>
              <Text greyText alignLeft>
                {t('homePage.beginWatch.connect.step2')}
              </Text>
              <Link to="/faq">
                <BoldText alignLeft>
                  {t('homePage.beginWatch.connect.step2Orange')}
                </BoldText>
              </Link>
            </Wrapper>
          </Wrapper>
          <HomeBeginWatchAsk isUserExists={isUserExist} />
        </Wrapper>
      </PageContainer>
      <Background>
        <HomeBeginBgIcon />
      </Background>
    </Container>
  );
};
export default HomeBeginWatch;

const Container = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 63px;
  padding-bottom: 60px;

  position: relative;

  overflow: hidden;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  ${({ leftMargin }) => leftMargin && ('margin-left: 30px;')}
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 5px;')}

  font-family: Gilroy;
  font-size: 15px;
  line-height: 20px;
  color: ${({ greyText }) => (greyText ? text02 : text01)};
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
`;

const TitleContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  margin: 0 auto;

  margin-bottom: 110px;
  ${({ margin }) => margin && (`
    margin-top: 80px;
    margin-bottom: 50px;
  `)}

  position: relative;

  &::before {
    content: '';

    width: 80%;
    height: 2px;

    position: absolute;
    left: 50%;
    bottom: -15px;

    transform: translateX(-50%);
    background-color: ${secondary};
    opacity: 20%;
  }
`;

const Title = styled(Text)`
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  line-height: 36px;
`;

const LittleTitle = styled(Title)`
  margin-bottom: 19px;

  font-size: 24px;
  text-align: left;
  line-height: 28px;
`;

const BoldText = styled(Title)`
  margin-left: 7px;

  position: relative;
  top: 1px;

  font-size: 14px;
  color: ${secondary};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const OrangeTitle = styled(Title)`
  margin: 0 10px;

  color: ${secondary};
`;

const Background = styled.div`
  position: absolute;
  bottom: -5px;
  z-index: -1;
`;
