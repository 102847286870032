import React from 'react';

import TemplateDecors from './TemplateDecors';

import { TemplateDecorsContextProvider } from './TemplateDecorsContext';

const TemplateDecorsContainer = () => (
  <TemplateDecorsContextProvider>
    <TemplateDecors />
  </TemplateDecorsContextProvider>
);

export default TemplateDecorsContainer;
