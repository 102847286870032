import React from 'react';

import styled from 'styled-components';
import {
  white,
  secondary,
  text01,
  borderSecondary,
  background01,
  grey,
  darkGrey,
} from '@/common/constants/theme';

import BlockAlignmentStyleControls from './BlockAlignmentControl';
import InlineStyleControls from './InlineControl';
// import FontSizeControl from './FontSizeControl';
import HistoryControls from './HistoryControl';
import ListStyleControls from './ListControl';
import LinkControl from './LinkControl';
import ImageControls from './ImageControl';
// import TableControl from './TableControl';

const Toolbar = ({
  editorState,
  onEditorStateChange,
  uploadImage,
  simpleText,
}) => (
  <StyledToolbar className="rdw-editor-toolbar">
    <ControlsWrapper className="rdw-inline-wrapper">
      <InlineStyleControls
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </ControlsWrapper>
    {/* <ControlsWrapper className="rdw-inline-wrapper"> */}
    {/* <FontSizeControl */}
    {/*  editorState={editorState} */}
    {/*  onEditorStateChange={onEditorStateChange} */}
    {/* /> */}
    {/* </ControlsWrapper> */}
    <ControlsWrapper className="rdw-inline-wrapper">
      <HistoryControls
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </ControlsWrapper>
    {!simpleText && (
      <>
        <ControlsWrapper className="rdw-inline-wrapper">
          <ListStyleControls
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </ControlsWrapper>
        <ControlsWrapper className="rdw-inline-wrapper">
          <BlockAlignmentStyleControls
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </ControlsWrapper>
        <ControlsWrapper className="rdw-inline-wrapper">
          <LinkControl
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />
        </ControlsWrapper>
        {uploadImage && (
          <ControlsWrapper className="rdw-inline-wrapper">
            <ImageControls
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              uploadImage={uploadImage}
            />
          </ControlsWrapper>
        )}
      </>
    )}
  </StyledToolbar>
);

export default Toolbar;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;

  position: relative;
`;

const StyledToolbar = styled.div`

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 15px;
    user-select: none;

    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    border: none;
    border-bottom: 1px solid ${borderSecondary};


    & .rdw-image-modal {
        right: 2rem;
        left: unset;
        box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3);
        background-color: ${white};
        border: none;
        border-radius: 0;
        min-height: 250px;
        width: 450px;

        .rdw-image-modal-header {
            margin: 0;
        }

        .rdw-image-modal-header-option {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1rem;
            letter-spacing: 0.32px;
            color: ${grey};
            display: inline-block;
            vertical-align: baseline;
            margin-bottom: 0.25rem;
        }

        .rdw-image-modal-upload-option {
            color: ${darkGrey};
            background-color: ${background01};
            outline: 1px dashed ${darkGrey};
            outline-offset: 0;
            margin: .5rem 0;
            padding: 1.5rem 0;
            height: 100%;
            font-family: Gilroy, sans-serif;
            font-size: 13px;
            line-height: 20px;
        }

        .rdw-image-modal-btn-section {
            margin: 30px auto 0;
        }

        .rdw-image-modal-size, .rdw-image-modal-header-label-highlighted {
            display: none;
        }

        .rdw-image-modal-btn {
            background-color: ${secondary};
            border: none;
            border-radius: 2px;
            color: ${white};
            width: 122px;
            height: 48px;
            margin: 0;
            text-transform: uppercase;
            :last-of-type {
                display: none;
            }
            :disabled {
                background: ${text01};
                opacity: 0.2;
                pointer-events: none;
            }
        }
    }
`;
