import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { white, text01 } from '../constants/theme';

const StyledInput = styled(
  // eslint-disable-next-line react/jsx-props-no-spreading
  ({ ...props }) => <Input {...props} />,
)`
  && {
    font-family: Gilroy, sans-serif;
    font-size: 13px;
    box-sizing: border-box;
    padding: 0 11px 2px 2px;
    width: 100%;
    color: ${text01};
    background: ${white};
    cursor: pointer;
    height: 25px;
    border-radius: 2px;
    outline: none;
    border: 1px solid #EFEEF3;

    &:read-only {
        opacity: 0.9;
        cursor: auto;
        border: 1px solid transparent;
    }

    &&.ant-input:focus {
      box-shadow: none;
      border: 1px solid #c0c6e0;
    }
`;
export default StyledInput;
