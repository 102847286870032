/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import {
  green, white, text01, red, secondary,
} from '../constants/theme';
import Visibility from '../../assets/icons/visibility.svg';
import NoVisibility from '../../assets/icons/no-visibility.svg';
import IconCopy from '../../assets/icons/copyicon.svg';

export default function InputContainer({
  errorText = null,
  isSuccess,
  isCopied,
  type,
  label,
  value,
  withoutBorder,
  height,
  opacity,
  ...props
}) {
  const { t } = useTranslation(['translations']);
  const [isPasswordType, setPasswordType] = useState(type === 'password');
  const inputId = `${Math.random()}-input`;
  const copyText = (elementId) => {
    // setPasswordType(false);
    const oldType = document.getElementById(elementId).type;
    document.getElementById(elementId).type = 'text';
    const range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    document.getElementById(elementId).type = oldType;
  };

  return (
    <Container
      error={errorText}
    >
      <Input
        {...props}
        value={value}
        type={isPasswordType ? 'password' : 'text'}
        id={inputId}
        error={errorText}
        success={(errorText === '' && value) || (isSuccess && !errorText)}
        withoutBorder={withoutBorder}
        height={height}
        setOpacity={opacity}
      />
      {isCopied && (
        value ? (
          <Tooltip
            autoAdjustOverflow
            arrowPointAtCenter
            placement="topRight"
            overlayClassName="green-tooltip"
            trigger={['click']}
            title={`${label} ${t('copied')}`}
            color={green}
          >
            <Icon
              src={IconCopy}
              onClick={() => copyText(inputId)}
              height={height}
            />
          </Tooltip>
        ) : (
          <Icon src={IconCopy} height={height} />
        )
      )}
      {type === 'password' && (
        isPasswordType ? (
          <NoVisabilityIcon
            onClick={() => setPasswordType(!isPasswordType)}
            src={NoVisibility}
            height={height}
          />
        ) : (
          <VisabilityIcon
            onClick={() => setPasswordType(!isPasswordType)}
            src={Visibility}
            height={height}
          />
        ))}
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${({ error }) => (!error && 1.5)}rem;
  width: 100%;
  max-width: 35rem;
`;

const Input = styled.input`
  padding: 19px 16px 14px;
  line-height: 20px;
  background: ${white};
  box-shadow: ${({ withoutBorder }) => !withoutBorder && '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)'};
  border-radius: 2px;
  border: none;
  border-top: 2px solid ${secondary};
  outline: none !important;
  transition: box-shadow .2s, border-color .2s, -webkit-box-shadow .2s, opacity .2s;
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  letter-spacing: 0.16px;
  color: ${text01};

  ::placeholder {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.16px;
  }

  ${({ error }) => error && `border-color: ${red};`}
  ${({ success }) => success && `border-color: ${green};`}
  ${({ withoutBorder }) => withoutBorder && 'border: 1px solid #ECECEC;'}
  ${({ height }) => `height: ${height}`};
  ${({ setOpacity }) => setOpacity && `opacity: ${setOpacity};`}
`;

const VisabilityIcon = styled.img`
  position: absolute;
  right: 1rem;
  top: ${({ height }) => (height ? `${parseInt(height, 10) / 3.5}px` : '1rem')};
  cursor: pointer;
  width: 24px;
`;

const NoVisabilityIcon = styled(VisabilityIcon)`
  right: .95rem;
  top: ${({ height }) => (height ? `${parseInt(height, 10) / 3.7}px` : '.9rem')};
  width: 26px;
`;

const ErrorText = styled.span`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.16px;
  color: ${red};
  margin-top: .25rem;
  margin-bottom: 1rem;
`;

const Icon = styled.img`
  position: absolute;
  right: 3.25rem;
  top: ${({ height }) => (height ? `${parseInt(height, 10) / 4}px` : '1.25rem')};
  height: 21px;
  cursor: pointer;
`;
