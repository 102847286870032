import React from 'react';

export default () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#starIconClip0)">
      <path d="M11.4939 4.7565L11.4941 4.75704C11.5114 4.81045 11.4955 4.86824 11.4531 4.90538L11.4527 4.90573L8.83465 7.20129L8.61122 7.3972L8.677 7.68699L9.44893 11.0873C9.46144 11.1426 9.44019 11.1986 9.39478 11.2315L9.39473 11.2316C9.34931 11.2645 9.29036 11.2666 9.24384 11.2386L9.24267 11.2379L6.25615 9.45227L6.00011 9.29919L5.74405 9.45223L2.75642 11.2378L2.7562 11.238C2.73247 11.2522 2.70848 11.2582 2.68549 11.2582C2.65836 11.2582 2.6303 11.2499 2.6043 11.2312C2.55975 11.199 2.53804 11.1434 2.55065 11.0873C2.55067 11.0872 2.55068 11.0872 2.55069 11.0871L3.32257 7.68699L3.38835 7.39724L3.16496 7.20132L0.546944 4.9053L0.545998 4.90448C0.504877 4.8686 0.488491 4.81108 0.505979 4.75731C0.523397 4.70374 0.569885 4.66663 0.625594 4.66138C0.625678 4.66137 0.625762 4.66136 0.625846 4.66136L4.08911 4.34694L4.38581 4.32L4.50288 4.04604L5.8722 0.841565C5.87221 0.841533 5.87223 0.841501 5.87224 0.841469C5.89495 0.788617 5.94508 0.75636 6.00006 0.75636C6.05514 0.75636 6.10528 0.788722 6.12794 0.841047C6.12799 0.841166 6.12804 0.841285 6.12809 0.841404L7.49723 4.04601L7.61429 4.32L7.91101 4.34694L11.3745 4.66143L11.3749 4.66146C11.4304 4.66646 11.4768 4.70362 11.4939 4.7565Z" stroke="#FFC042" strokeWidth="0.998073" />
    </g>
    <defs>
      <clipPath id="starIconClip0">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
