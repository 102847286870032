import React from 'react';

export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.47391 29.5137H13.3675C13.3994 29.5137 13.43 29.5263 13.4525 29.5489C13.4751 29.5715 13.4878 29.6021 13.4878 29.634V30.2893C13.4878 30.7171 13.1409 31.064 12.713 31.064H10.1283C9.7004 31.064 9.35352 30.7171 9.35352 30.2892V29.6339C9.35358 29.5675 9.40747 29.5137 9.47391 29.5137Z" fill="white" />
    <path d="M11.9712 29.2875L12.5647 28.6297C13.259 27.6991 13.5331 26.5209 13.321 25.3794H12.0444C12.3437 26.6036 11.8743 27.8873 10.8559 28.6297L9.92315 29.2876C9.87979 29.3179 9.86041 29.3724 9.87483 29.4234C9.88924 29.4743 9.93436 29.5105 9.98717 29.5136H12.0118C11.9383 29.5136 11.9096 29.3547 11.9712 29.2875Z" fill="white" />
    <path d="M28.7334 1.09106H27.3701C28.3156 1.14545 29.0397 1.95313 28.991 2.89895V21.2453H30.5413V2.89895C30.5413 1.9005 29.7319 1.09106 28.7334 1.09106Z" fill="#A4C2F7" />
    <path d="M3.73956 1.09106H2.37624C1.3778 1.09106 0.56842 1.90044 0.568359 2.89895V21.2453H2.11868V2.89895C2.06993 1.95313 2.79404 1.14545 3.73956 1.09106Z" fill="white" />
    <path d="M2.11868 23.5716V21.2452H0.568359V23.5716C0.568359 24.051 0.758819 24.5109 1.09789 24.8499C1.43696 25.1889 1.89679 25.3795 2.37624 25.3795H3.73956C2.79404 25.3251 2.06993 24.5174 2.11868 23.5716Z" fill="white" />
    <path d="M28.991 21.2452V23.5716C29.0397 24.5174 28.3156 25.3251 27.3701 25.3795H28.7334C29.2129 25.3795 29.6728 25.189 30.0118 24.8499C30.3508 24.5109 30.5413 24.051 30.5413 23.5716V21.2452H28.991Z" fill="#A4C2F7" />
    <path d="M6.80738 18.1447H5.29642C4.71498 18.1441 4.24377 17.6729 4.24316 17.0914V15.5805C4.24371 14.999 4.71492 14.5278 5.29642 14.5272H6.80738C7.38881 14.5278 7.86002 14.999 7.86063 15.5805V17.0914C7.86008 17.6729 7.38887 18.1441 6.80738 18.1447ZM5.29642 15.5608L5.27673 17.0915L6.80738 17.1112C6.81822 17.1112 6.82706 17.1023 6.82706 17.0915V15.5805L5.29642 15.5608Z" fill="url(#paint0_linear)" />
    <path d="M21.1225 30.0305H9.98731C9.70977 30.0305 9.46474 29.8493 9.38347 29.5839C9.29778 29.3159 9.39722 29.0234 9.6285 28.8632L10.5581 28.2074C11.4032 27.5863 11.7913 26.5173 11.5414 25.4987L11.391 24.8628H19.7281L19.5676 25.505C19.32 26.5225 19.7088 27.5891 20.5532 28.2085L21.4851 28.8653C21.7144 29.027 21.812 29.3191 21.7258 29.5862C21.6436 29.8503 21.3992 30.0303 21.1225 30.0305ZM10.2256 29.7068L10.221 29.71L10.2256 29.7068ZM11.2296 28.997H19.8803C18.9233 28.2627 18.3947 27.1001 18.4705 25.8963H12.6399C12.7155 27.1002 12.1867 28.2628 11.2296 28.997Z" fill="url(#paint1_linear)" />
    <path d="M11.4011 18.1447H9.89017C9.30873 18.1441 8.83752 17.6729 8.83691 17.0914V15.5805C8.83746 14.999 9.30867 14.5278 9.89017 14.5272H11.4011C11.9826 14.5278 12.4538 14.999 12.4544 15.5805V17.0914C12.4538 17.6729 11.9826 18.1441 11.4011 18.1447ZM9.89017 15.5608L9.87048 17.0915L11.4011 17.1112C11.412 17.1112 11.4208 17.1023 11.4208 17.0915V15.5805L9.89017 15.5608Z" fill="url(#paint2_linear)" />
    <path d="M16.052 18.1447H14.541C13.9596 18.1441 13.4884 17.6729 13.4878 17.0914V15.5805C13.4884 14.999 13.9596 14.5278 14.541 14.5272H16.052C16.6334 14.5278 17.1046 14.999 17.1053 15.5805V17.0914C17.1047 17.6729 16.6335 18.1441 16.052 18.1447ZM14.541 15.5608L14.5214 17.0915L16.052 17.1112C16.0628 17.1112 16.0717 17.1023 16.0717 17.0915V15.5805L14.541 15.5608Z" fill="url(#paint3_linear)" />
    <path d="M20.7029 18.1447H19.1919C18.6105 18.1441 18.1393 17.6729 18.1387 17.0914V15.5805C18.1392 14.999 18.6104 14.5278 19.1919 14.5272H20.7029C21.2843 14.5278 21.7555 14.999 21.7561 15.5805V17.0914C21.7556 17.6729 21.2844 18.1441 20.7029 18.1447ZM19.1919 15.5608L19.1722 17.0915L20.7029 17.1112C20.7137 17.1112 20.7226 17.1023 20.7226 17.0915V15.5805L19.1919 15.5608Z" fill="url(#paint4_linear)" />
    <path d="M25.3542 18.1447H23.8433C23.2619 18.1441 22.7906 17.6729 22.79 17.0914V15.5805C22.7906 14.999 23.2618 14.5278 23.8433 14.5272H25.3542C25.9357 14.5278 26.4069 14.999 26.4075 15.5805V17.0914C26.407 17.6729 25.9357 18.1441 25.3542 18.1447ZM23.8433 15.5608L23.8236 17.0915L25.3542 17.1112C25.3651 17.1112 25.3739 17.1023 25.3739 17.0915V15.5805L23.8433 15.5608Z" fill="url(#paint5_linear)" />
    <path d="M21.7561 11.4267C21.619 11.4269 21.4875 11.3724 21.3908 11.2753L19.8404 9.72498C19.6417 9.52265 19.6433 9.19805 19.8437 8.99753C20.0442 8.79702 20.3688 8.79557 20.5711 8.99426L22.1215 10.5446C22.2692 10.6924 22.3134 10.9146 22.2334 11.1077C22.1535 11.3007 21.9651 11.4266 21.7561 11.4267Z" fill="url(#paint6_linear)" />
    <path d="M19.6891 10.9099C19.4037 10.9099 19.1724 10.6785 19.1724 10.3931V8.8428C19.1724 8.55739 19.4038 8.32605 19.6891 8.32605C19.9745 8.32605 20.2059 8.55745 20.2059 8.8428V10.3931C20.2059 10.6785 19.9745 10.9099 19.6891 10.9099Z" fill="url(#paint7_linear)" />
    <path d="M21.2394 9.35956H19.6891C19.4037 9.35956 19.1724 9.12816 19.1724 8.8428C19.1724 8.55745 19.4038 8.32605 19.6891 8.32605H21.2394C21.5249 8.32605 21.7562 8.55745 21.7562 8.8428C21.7562 9.12816 21.5248 9.35956 21.2394 9.35956Z" fill="url(#paint8_linear)" />
    <path d="M20.4144 31.5808H10.6956C9.66955 31.5798 8.838 30.7482 8.83691 29.7221C8.83734 29.3218 9.16176 28.9974 9.56211 28.9969H21.5479C21.9482 28.9974 22.2726 29.3218 22.2731 29.7221C22.272 30.7482 21.4405 31.5798 20.4144 31.5808ZM9.93032 30.0305C10.0565 30.3425 10.3591 30.5468 10.6957 30.5473H20.4144C20.751 30.5468 21.0536 30.3425 21.1798 30.0305H9.93032Z" fill="url(#paint9_linear)" />
    <path d="M31.0582 21.7621H0.0517578V10.393C0.0517578 10.1076 0.283156 9.87622 0.568512 9.87622C0.853869 9.87622 1.08527 10.1076 1.08527 10.393V20.7285H30.0247V19.1779C30.0247 18.8925 30.2561 18.6612 30.5414 18.6612C30.8268 18.6612 31.0582 18.8925 31.0582 19.1779V21.7621H31.0582Z" fill="url(#paint10_linear)" />
    <path d="M30.5415 15.0439C30.2561 15.0439 30.0247 14.8125 30.0247 14.5271V2.89898C30.024 2.18619 29.4463 1.60851 28.7335 1.60779H2.37652C1.66373 1.60851 1.08605 2.18619 1.08533 2.89898V6.25876C1.08533 6.54418 0.853929 6.77551 0.568573 6.77551C0.283216 6.77551 0.0517578 6.54412 0.0517578 6.25876V2.89898C0.0532112 1.61566 1.0932 0.575672 2.37652 0.574219H28.7335C30.0168 0.575672 31.0568 1.61566 31.0582 2.89898V14.5271C31.0582 14.8125 30.8269 15.0439 30.5415 15.0439Z" fill="url(#paint11_linear)" />
    <path d="M0.568512 8.84259C0.853908 8.84259 1.08527 8.61123 1.08527 8.32583C1.08527 8.04044 0.853908 7.80908 0.568512 7.80908C0.283117 7.80908 0.0517578 8.04044 0.0517578 8.32583C0.0517578 8.61123 0.283117 8.84259 0.568512 8.84259Z" fill="url(#paint12_linear)" />
    <path d="M30.5417 17.6279C30.2562 17.6279 30.0249 17.3966 30.0249 17.1111V16.5944C30.0249 16.309 30.2563 16.0776 30.5417 16.0776C30.8271 16.0776 31.0584 16.309 31.0584 16.5944V17.1111C31.0584 17.3966 30.8271 17.6279 30.5417 17.6279Z" fill="url(#paint13_linear)" />
    <path d="M28.7335 25.8961H2.37652C1.0932 25.8947 0.0532112 24.8547 0.0517578 23.5714V20.7284H31.0582V23.5714C31.0568 24.8547 30.0168 25.8947 28.7335 25.8961ZM1.08533 21.762V23.5714C1.08605 24.2842 1.66373 24.8619 2.37652 24.8626H28.7335C29.4463 24.8619 30.024 24.2842 30.0247 23.5714V21.762H1.08533Z" fill="url(#paint14_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="6.0519" y1="14.5272" x2="6.0519" y2="18.1447" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="15.5549" y1="24.8628" x2="15.5549" y2="30.0305" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="10.6456" y1="14.5272" x2="10.6456" y2="18.1447" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="15.2965" y1="14.5272" x2="15.2965" y2="18.1447" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint4_linear" x1="19.9474" y1="14.5272" x2="19.9474" y2="18.1447" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint5_linear" x1="24.5988" y1="14.5272" x2="24.5988" y2="18.1447" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint6_linear" x1="20.9826" y1="8.84619" x2="20.9826" y2="11.4267" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint7_linear" x1="19.6891" y1="8.32605" x2="19.6891" y2="10.9099" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint8_linear" x1="20.4643" y1="8.32605" x2="20.4643" y2="9.35956" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint9_linear" x1="15.555" y1="28.9969" x2="15.555" y2="31.5808" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint10_linear" x1="15.555" y1="9.87622" x2="15.555" y2="21.7621" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint11_linear" x1="15.555" y1="0.574219" x2="15.555" y2="15.0439" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint12_linear" x1="0.568512" y1="7.80908" x2="0.568512" y2="8.84259" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint13_linear" x1="30.5417" y1="16.0776" x2="30.5417" y2="17.6279" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint14_linear" x1="15.555" y1="20.7284" x2="15.555" y2="25.8961" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
    </defs>
  </svg>
);
