import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DeleteSrc from '@/assets/icons/delete-trash.svg';
import { white } from '@/common/constants/theme';
import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Button from '@/common/components/Button';

const ChannelsMainDeleteModal = ({
  isOpen,
  onClose,
  onSubmit,
  value,
  title,
  descr,
}) => {
  const { t } = useTranslation(['translations']);

  return (
    <Container>
      <ModalWithoutContent
        isOpen={isOpen}
        onCloseModal={onClose}
        icon={DeleteSrc}
      >
        <Text>
          { title || t('steps.step4.content.body.modal.sureDelete')}
          {` ${value}?`}
        </Text>
        <StyledButton
          isBoldText
          isUpperCase
          onClick={() => {
            onSubmit(value);
            onClose();
          }}
        >
          { descr || t('steps.step4.content.body.modal.delete')}
        </StyledButton>
      </ModalWithoutContent>
    </Container>
  );
};

export default ChannelsMainDeleteModal;

const Container = styled.div``;

const Text = styled.div`
  margin-bottom: 17px;

  font-family: Gilroy;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${white};
`;

const StyledButton = styled(Button)`
  width: 116px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
