export default [
  {
    id: 2,
    name: 'Русский',
    code: 'ru',
    is_default: true,
    is_active: true,
  },
  {
    id: 1,
    name: 'English',
    code: 'en',
    is_default: false,
    is_active: true,
  },
  {
    id: 3,
    name: 'Deutsch',
    code: 'de',
    is_default: false,
    is_active: true,
  },
  {
    id: 4,
    name: '德語',
    code: 'zh',
    is_default: false,
    is_active: false,
  },
];
