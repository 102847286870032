/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  white, primary, secondary, text01, activePrimary,
} from '../constants/theme';
import ArrowLeft from './icons/ArrowLeft';

export const OPTION_DISPLAY_FLEX = {
  none: 'none',
  row: 'row',
  column: 'column',
};

export default function ActionMenu({
  label = 'Выберите',
  showSearch = false,
  isUpperCase = false,
  color = primary,
  isBoldText,
  position = 'left',
  children,
  disabled,
}) {
  const [isOpen, setOpen] = useState(false);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          setOpen(false);
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Container>
      <FlexContainer ref={wrapperRef}>
        <InputWrapper
          onClick={!showSearch && (() => setOpen(!isOpen))}
          showSearch={showSearch}
          isMenuOpen={isOpen}
          color={color}
          disabled={disabled}
        >
          <Label
            isUpperCase={isUpperCase}
            isBoldText={isBoldText}
            color={color}
          >
            {label}
          </Label>
          <ActionIcon>
            <ArrowIcon
              onClick={() => setOpen(!isOpen)}
              isOpen={isOpen}
              color={color}
            >
              <ArrowLeft />
            </ArrowIcon>
          </ActionIcon>
        </InputWrapper>
        {isOpen && (
          <>
            <SelectorMenu position={position}>
              {children.map((element) => React.cloneElement(element, {
                ...element.props,
                onClose: () => setOpen(false),
              }))}
            </SelectorMenu>
            <ContainerArrow />
          </>
        )}
      </FlexContainer>
    </Container>
  );
}

export const Option = ({
  children, onClick, onClose,
  withoutHover = false,
  flex = OPTION_DISPLAY_FLEX.none,
  props,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    onClose();
  };
  return (
    <OptionContainer
      onClick={handleClick}
      noHover={withoutHover}
      flex={flex}
      {...props}
    >
      {children}
    </OptionContainer>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
`;

const ActionIcon = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  background-color: ${white};
  border: 1px solid #ececec;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 4px 11px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  cursor: ${({ showSearch }) => (showSearch ? 'text' : 'pointer')};
  :hover {
    border-color: ${({ color }) => color || secondary};
  }
  :focus {
    border-color: ${({ color }) => color || secondary};
    outline: none;
  }
  :active {
    border-color: ${({ color }) => color || secondary};
  }
  ${({ isMenuOpen }) => isMenuOpen && `
    border-color: ${({ color }) => color || secondary};
  `}
  ${({ disabled }) => disabled && `
    opacity: .5;
    pointer-events: none;
  `}
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  letter-spacing: 0.16px;
  width: 100%;
  padding-right: 11px;
  background: ${white};
  color: ${({ color }) => color || text01};
  font-weight: ${({ isBoldText }) => (isBoldText ? 600 : 400)};
  text-transform: ${({ isUpperCase }) => (isUpperCase ? 'uppercase' : 'capitalize')};
`;

const ArrowIcon = styled.div`
  cursor: pointer;
  transform: rotate(-90deg);
  transition: transform .25s;
  ${({ isOpen }) => isOpen && `
  transform: rotate(90deg);
  `}
  path {
    fill: ${({ color }) => color || text01};
  }
`;

const SelectorMenu = styled.div`
  position: absolute;
  z-index: 10;
  top: 3.55rem;
  border: 1px solid #EFEEF3;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.11));
  background: ${white};
  display: flex;
  flex-direction: column;
  padding: .75rem 1rem;
  max-height: 10rem;
  overflow: auto;
  width: auto;
  right: ${({ position }) => position === 'right' && '-1rem'};
  left: ${({ position }) => position === 'left' && 0};
`;

const ContainerArrow = styled.div`
  position: absolute;
  border: 1.25rem solid transparent;
  border-bottom: 1.25rem solid ${white};
  width: 1.25rem;
  height: 1.25rem;
  top: 1.25rem;
  left: 4.5rem;

  z-index: 11;
`;

const OptionContainer = styled.div`
  ${({ flex }) => (
    (flex || OPTION_DISPLAY_FLEX.none) !== OPTION_DISPLAY_FLEX && `
    display: flex;
    flex-direction: ${flex};
    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  `)}
  ${({ flex }) => (!flex && 'padding: .25rem 1rem;')}

  transition: color .1s, background-color .1s;
  cursor: pointer;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  text-transform: none;

  :not(:last-of-type) {
    margin-bottom: 0.33rem;
  }

  ${({ noHover }) => (!noHover && `
    :hover {
      color: ${white};
      background: ${activePrimary};
    }
  `)}
`;
