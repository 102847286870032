import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { borderSecondary, secondary } from '@/common/constants/theme';
import EditIcon from '@/common/components/icons/EditIcon';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import { useHistory } from 'react-router-dom';
import AdminTablePagination from '../../components/Table/components/TablePagination';
import { useTemplatesListContext } from './TemplatesListContext';
import SearchIcon from '../../../icons/SearchIcon';
import CloseIcon from '../../../icons/CloseIcon';
import SelectorContainer from '@/common/components/Selector';
import Loader from '@/common/components/Loader';

const SIZES = ['40px', '240px', '430px', '210px', '265px'];

const TemplatesTable = () => {
  const {
    isBusy,
    getTemplates,
    templates,
    templatesCount,
    templatesPage,
    setTemplatesPage,
    getTemplatesPageCount,
  } = useTemplatesListContext();
  const history = useHistory();

  const queryInputWaitTime = 750;

  // eslint-disable-next-line no-unused-vars
  const [typeFilter, setTypeFilter] = useState(null);
  const [queryFilter, setQueryFilter] = useState(null);
  const [queryFilterTimeout, setQueryFilterTimeout] = useState(null);
  const [localQueryFilter, setLocalQueryFilter] = useState(null);
  const [typeFilterValues, setTypeFilterValues] = useState([]);
  const [localTypeFilter, setLocalTypeFilter] = useState({});

  const { t } = useTranslation(['translations']);

  const emptyTypeFilterValue = { key: '-', title: t('admin.mailTemplates.anyType') };

  useEffect(() => {
    getTemplates(typeFilter, queryFilter);
  }, [typeFilter, queryFilter]);

  useEffect(() => {
    if (typeFilterValues.length === 0 && templates.length > 0) {
      const usedTypes = [emptyTypeFilterValue.key];
      const types = [emptyTypeFilterValue];
      templates.forEach((tmplate) => {
        if (!usedTypes.includes(tmplate.type)) {
          usedTypes.push(tmplate.type);
          types.push({ key: tmplate.type, title: `${t(`admin.mailTemplates.${tmplate.type}`)} (${tmplate.type})` });
        }
      });
      setTypeFilterValues(types);
    }
    if (!localTypeFilter?.key) {
      setLocalTypeFilter(emptyTypeFilterValue);
    }
  }, [templates]);

  const handleTypeFilter = (val) => {
    setTypeFilter(val.key === emptyTypeFilterValue.key ? null : val.key);
  };

  const handleSearch = (val) => {
    setLocalQueryFilter(val);
    if (val.length > 0) {
      if (queryFilterTimeout) {
        clearTimeout(queryFilterTimeout);
      }
      setQueryFilterTimeout(setTimeout(() => setQueryFilter(localQueryFilter), queryInputWaitTime));
    } else {
      setQueryFilter(null);
    }
  };

  const editItem = (item) => {
    history.push(`/admin/public/templates/${item.id}`);
  };

  const clearQueryFilter = () => {
    clearTimeout(queryFilterTimeout);
    setQueryFilter(null);
    setLocalQueryFilter(null);
  };

  return (
    <Container>
      {isBusy && <Loader />}
      <Table
        dataLength={templatesCount}
        limit={50}
        page={templatesPage}
        cellSizes={SIZES}
        textAlign="left"
        adaptiveSize={40}
      >
        <TableHead>
          <HeadItem>
            Id
            {/* <Icon onClick={handleTypeFiltered}> */}
            {/*  <OrderArrowsIcon /> */}
            {/* </Icon> */}
          </HeadItem>
          <HeadItem>
            Код
          </HeadItem>
          <HeadItem>
            Тема уведомления
          </HeadItem>
          <HeadItem>
            <SelectorContainer
              defaultValue={emptyTypeFilterValue}
              value={localTypeFilter}
              options={typeFilterValues}
              onSelect={handleTypeFilter}
            />
          </HeadItem>
          <HeadItem>
            <InputWrapper>
              <Input
                value={localQueryFilter || ''}
                placeholder="Поиск"
                onChange={({ target }) => handleSearch(target.value)}
              />
              <Icon>
                <SearchIcon />
              </Icon>
              {(queryFilter?.length || 0) > 0 && (
                <CloseIconContainer onClick={() => clearQueryFilter()}>
                  <CloseIcon />
                </CloseIconContainer>
              )}
            </InputWrapper>
          </HeadItem>

        </TableHead>
        <TableBody rowHeight={85} isBottomRowBorder customHeight="700px">
          {templates.map((item) => (
            <Row key={`ATT-R--${item.id}`}>
              <RowItem>
                {item.id}
              </RowItem>
              <RowItem
                onClick={() => editItem(item)}
              >
                {item.template_name}
              </RowItem>
              <RowItem>
                {item.name}
              </RowItem>
              <RowItem>
                {item.type}
              </RowItem>
              <RowItem>
                <Description />
                <Wrapper flex>
                  <Icon onClick={() => editItem(item)}>
                    <EditIcon />
                  </Icon>
                  {/* <Icon onClick={() => setDeletedNews(item)}> */}
                  {/*  <DeleteIcon /> */}
                  {/* </Icon> */}
                </Wrapper>
              </RowItem>
            </Row>
          ))}
        </TableBody>
        <AdminTablePagination
          page={templatesPage}
          setPage={setTemplatesPage}
          pageCount={getTemplatesPageCount()}
          recordsCount={templatesCount}
          title="Шаблонов:"
        />
      </Table>
    </Container>
  );
};

export default TemplatesTable;

const Container = styled.div`
  margin: 10px 0;
  & .table-rows {
    border: 1px solid ${borderSecondary};
    border-radius: 4px 4px 0 0;

    &__row__item {
      align-items: flex-start;
      padding-top: 10px;
      overflow: hidden;
      height: 67px;
    }
  }
`;

const HeadItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -10px;
`;

const Row = styled.div``;

const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  flex: 1;
`;

const Description = styled.div`
  flex: 1;
  margin-right: 30px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;

const Icon = styled.div`
  margin-right: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  line-height: 18px;
  margin-right: 20px;
  path {
    stroke: ${secondary};
    stroke-opacity: 1;
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 1px;
  right: 27px;
  path {
    stroke: none;
  }
  cursor: pointer;
  opacity: 0.75;

  &:hover{
    opacity: 1;
  }
`;

const Input = styled.input`
  width: 205px;
  margin-left: 15px;
  margin-right: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(255 152 0 / 50%);;
  outline: none;
`;
