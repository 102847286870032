import React from 'react';
import styled from 'styled-components';

import { white } from '../../../../../../common/constants/theme';

const Table = ({
  children,
  page,
  setPage,
  pageCount,
  pageLimit,
  setPageLimit,
  cellSizes,
  textAlign,
  adaptiveSize,
}) => (
  <Container>
    {React.Children.map(children, (element) => React.cloneElement(element, {
      page,
      setPage,
      pageCount,
      pageLimit,
      setPageLimit,
      cellSizes,
      textAlign,
      adaptiveSize,
      ...element.props,
    }))}
  </Container>
);

export default Table;

const Container = styled.div`
  position: relative;

  background: ${white};
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;
`;
