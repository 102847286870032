import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  text01,
} from '@/common/constants/theme';
import Modal from '@/common/components/ModalNew';
import Button from '@/common/components/Button';
import KeyIcon from '@/assets/icons/key.svg';
import EnvelopeIcon from '@/assets/icons/whiteEnvelope.svg';

const CheckEmailModal = ({
  isOpen,
  onClose,
  email,
  type,
}) => {
  const { t } = useTranslation(['translations']);

  return (
    <Modal
      onCloseModal={onClose}
      title={type === 'password'
        ? t('profile.personalInfo.passwordModalTitle')
        : t('profile.personalInfo.emailModalTitle')}
      icon={type === 'password' ? KeyIcon : EnvelopeIcon}
      isOpen={isOpen}
    >
      <Text>{`${t('profile.accountsAndFinance.confirmedEmailText')} ${email}`}</Text>
      <Text>
        {type === 'password'
          ? t('profile.personalInfo.passwordModalText')
          : t('profile.personalInfo.emailModalText')}
      </Text>
      <Action>
        <Button
          width="small"
          isUpperCase
          isBoldText
          onClick={onClose}
        >
          {t('profile.accountsAndFinance.okButton')}
        </Button>
      </Action>
    </Modal>
  );
};

export default CheckEmailModal;

const Action = styled.div`
  margin: 2rem 0 1rem 0;
  display: flex;
  justify-content: flex-end;
`;

const Text = styled.p`
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;
