import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';
import {
  primary,
  text01,
  background01,
  white, red,
} from '@/common/constants/theme';

import Checkbox from '@/common/components/Checkbox';
import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';
import ArrowRightPrimary from '../../../../icons/ArrowRightPrimary';

import { useStatisticAggregatorsContext } from '../AggregatorsContext';
import {
  useAdminContext,
} from '../../../../../AdminContext';

const StatisticWithdrawalCreate = ({ mainPath }) => {
  const { paySystemId } = useParams();

  const history = useHistory();

  const {
    SnackTypes,
    setAdminSnack,
  } = useAdminContext();

  const {
    isBusy, setIsBusy,
    getWithdrawalSystem,
    createWithdrawalSystem,
    changeWithdrawalSystem,
  } = useStatisticAggregatorsContext();

  const [paymentSystem, setPaymentSystem] = useState(null);
  const [name, setName] = useState('');
  const [uIDName, setUIDName] = useState('');
  const [uIDRegexp, setUIDRegexp] = useState('');
  const [uIDExample, setUIDExample] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [isDataOk, setIsDataOk] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [errors, setErrors] = useState({});

  const psFields = [
    {
      title: 'Название системы вывода',
      data: name,
      setData: setName,
    },
    {
      title: 'User identifier name',
      data: uIDName,
      setData: setUIDName,
    },
    {
      title: 'User identifier validation regular expession',
      data: uIDRegexp,
      setData: setUIDRegexp,
    },
    {
      title: 'User identifier example',
      data: uIDExample,
      setData: setUIDExample,
    },
  ];

  useEffect(() => {
    let isOk = true;
    let isChanged = !paymentSystem;

    isOk = isOk && (name?.length > 1) && !errors?.name;
    isOk = isOk && (uIDName?.length > 1) && !errors?.user_identifier_name;
    isOk = isOk && (uIDRegexp?.length > 1) && !errors?.user_identifier_regexp;
    isOk = isOk && (uIDExample?.length > 1) && !errors?.user_identifier_example;

    setIsDataOk(isOk);

    if (isOk && paymentSystem) {
      isChanged = isChanged || (name !== paymentSystem.name);
      isChanged = isChanged || (uIDName !== paymentSystem.user_identifier_name);
      isChanged = isChanged || (uIDRegexp !== paymentSystem.user_identifier_regexp);
      isChanged = isChanged || (uIDExample !== paymentSystem.user_identifier_example);
      isChanged = isChanged || (isDisabled !== paymentSystem.is_disabled);
    }
    setIsDataChanged(isChanged);
  }, [
    name, isDisabled,
    uIDName, uIDRegexp, uIDExample,
  ]);

  useEffect(() => {
    if (paySystemId === 'create') {
      setPaymentSystem(null);
    } else {
      const psId = parseInt(paySystemId, 10);
      if (psId > 0) {
        getWithdrawalSystem(psId)
          .then((res) => setPaymentSystem(res));
      } else {
        setAdminSnack({ content: '', type: SnackTypes.error }, SnackTypes.error);
      }
    }
  }, [paySystemId]);

  useEffect(() => {
    if (paymentSystem) {
      setEditMode(true);
      setName(paymentSystem.name);
      setUIDName(paymentSystem.user_identifier_name);
      setUIDRegexp(paymentSystem.user_identifier_regexp);
      setUIDExample(paymentSystem.user_identifier_example);
      setIsDisabled(paymentSystem.is_disabled);
    }
  }, [paymentSystem]);

  const handleCreatePs = () => {
    setIsBusy(true);
    const postObj = {
      name,
      user_identifier_name: uIDName,
      user_identifier_regexp: uIDRegexp,
      user_identifier_example: uIDExample,
      is_disabled: isDisabled,
    };
    (editMode ? changeWithdrawalSystem(paymentSystem.id, postObj) : createWithdrawalSystem(postObj))
      .then((res) => {
        if (res?.id) {
          history.push(mainPath);
        }
      })
      .catch((err) => Object.entries(err?.errors || {})
        .forEach(([k, v]) => setErrors((prevState) => ({ ...prevState, [k]: v }))))
      .finally(() => setIsBusy(false));
  };

  const renderField = ({
    title, data, setData, isUrlType,
  }) => (
    <Wrapper
      key={`wse--${title.replace(' ', '_')}--adminStats`}
      title={isUrlType ? ' https://...' : ''}
    >
      <Text>
        {`${title}:`}
      </Text>
      <Input
        isError={errors?.[data]}
        value={data}
        onChange={({ target }) => setData(target.value)}
      />
      {errors?.[data] && <ErrorText>{errors[data]}</ErrorText>}
    </Wrapper>
  );

  return (
    <Container>
      {isBusy && <Loader />}
      <Header>
        <Title>
          Добавление системы вывода
        </Title>
        <StyledLink to={mainPath}>
          Вернуться в “Настройки агрегаторов”
          <Icon>
            <ArrowRightPrimary />
          </Icon>
        </StyledLink>
      </Header>
      <MainContent>
        {psFields && psFields.map((ps) => renderField(ps))}
        <Wrapper flex bottomMargin={12}>
          <Checkbox
            checked={isDisabled}
            onChange={(checked) => setIsDisabled(checked)}
            label="Отключена"
            type="secondary"
          />
        </Wrapper>
      </MainContent>
      <StyledButton
        isBoldText
        isUpperCase
        width="small"
        isDisabled={!isDataOk || !isDataChanged}
        onClick={handleCreatePs}
        title={isDataOk ? '' : 'Заполните все поля правильно'}
      >
        {paymentSystem ? 'Обновить' : 'Сохранить'}
      </StyledButton>
    </Container>
  );
};

export default StatisticWithdrawalCreate;

const Container = styled.div`
  font-family: Gilroy, sans-serif !important;
`;

const Wrapper = styled.div`
  ${({ flex }) => (flex && `
    display: flex;
    align-items: center;
    jystify-content: start;
  `)}
  ${({ bottomMargin }) => (bottomMargin && `margin-bottom: ${bottomMargin}px;`)}

  &:last-child {
    margin-bottom: 6px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 16px;
  margin-bottom: 20px;
`;

const MainContent = styled.div`
  padding: 14px 20px 30px 20px;
  margin-bottom: 16px;

  background-color: ${background01};
  border-radius: 2px;
`;

const Text = styled.div`
  margin-bottom: ${({ noBM }) => (noBM ? 0 : 12)}px;

  ${({ p }) => `padding: ${p || 0};`}
  font-size: ${({ fs }) => (fs || 14)}px;
  color: ${text01};
  line-height: ${({ lh }) => (lh || 15)}px;
`;

const ErrorText = styled(Text)`
  color: ${red};
  margin-bottom: 12px
`;

const Title = styled(Text)`
  margin-left: 16px;
  margin-bottom: 0;

  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
`;

const StyledLink = styled(Link)`
  display: flex;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${primary};
  line-height: 20px;

  &:hover,
  &:focus {
    color: ${primary};
  }
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;
  top: 3px;

  cursor: pointer;
`;

const Input = styled.input`
  width: 466px;
  height: 32px;
  padding: 0 12px;
  margin-bottom: ${({ isError }) => (isError ? 6 : 16)}px;

  background: ${white};
  border: solid 1px ${({ isError }) => (isError ? red : 'transparent')};
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  outline: none;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
