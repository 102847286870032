import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';

import BaseTable from './components/Table/Table';
import BaseFilters from './components/Filters';
import BaseImportCsv from './components/ImportCSV';
import BaseRegenerateCache from './components/RegenerateCache';
import AdminDeleteModal from '../../../../Modals/DeleteModal';
import { useAdminContext } from '../../../../../AdminContext';
import { useBaseContext } from './BaseContext';

const Base = () => {
  const {
    getGroups,
    dropdownGroupsValue,
  } = useAdminContext();
  const {
    deleteAllChannels,
    channelsPage,
    getChannels,
  } = useBaseContext();

  const [isCreateTab, setIsCreateTab] = useState(false);
  const [isDeleteAll, setIsDeleteAll] = useState(false);

  useEffect(() => {
    getGroups(0);
  }, []);

  useEffect(() => {
    getChannels();
  }, [dropdownGroupsValue, channelsPage]);

  return (
    <>
      <AdminDeleteModal
        content="все каналы и группы"
        isOpen={isDeleteAll}
        onClose={() => setIsDeleteAll(false)}
        handleDelete={() => deleteAllChannels()}
      />
      <Route path="/admin/iptv/base/cache" exact component={BaseRegenerateCache} />
      <Route path="/admin/iptv/base/import" exact component={BaseImportCsv} />
      <Route path="/admin/iptv/base" exact>
        <Container>
          <BaseFilters setIsCreateTab={setIsCreateTab} setIsDeleteAll={setIsDeleteAll} />
          <BaseTable isCreateTab={isCreateTab} setIsCreateTab={setIsCreateTab} />
        </Container>
      </Route>
    </>
  );
};

export default Base;

const Container = styled.div``;
