/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  text01,
  background01,
  text02,
} from '@/common/constants/theme';

const TableBody = ({
  children,
  cellSizes,
  cellMargin,
  rowPadding,
  textAlign,
  adaptiveSize,
  minRows,
  rowHeight,
  noDataText,
  renderNoDataContent,
  noRowItemMargin,
  autoRowHeight = false,
}) => {
  const { t } = useTranslation('translations');

  return (
    <Container
      height={rowHeight * minRows + (autoRowHeight ? 0 : 4)}
      className={`table-rows ${autoRowHeight ? 'auto' : 'fixed'}`}
      autoHeight={autoRowHeight}
    >
      {children.length > 0 ? (
        <>
          {React.Children.map(children, (element) => (
            <Wrapper
              height={rowHeight}
              className={`table-rows__row ${autoRowHeight ? 'auto' : 'fixed'}`}
              autoHeight={autoRowHeight}
              rowPadding={rowPadding}
            >
              {React.Children.map(element.props.children, (item, i) => (
                <Item
                  width={cellSizes && cellSizes[i]}
                  className="table-rows__row__item"
                  textAlign={textAlign}
                  adaptive={adaptiveSize}
                  noMargin={noRowItemMargin}
                  cellMargin={cellMargin}
                >
                  {item}
                </Item>
              ))}
            </Wrapper>
          ))}
        </>
      ) : (
        <>
          {renderNoDataContent}
          <NoData>
            {noDataText || t('table.noResults')}
          </NoData>
        </>
      )}
    </Container>
  );
};
export default TableBody;

const Container = styled.div`
  ${({ renderNoDataContent }) => (renderNoDataContent ? 'position: relative;' : '')}
  ${({ height, autoHeight }) => (autoHeight ? (`
    min-height: ${height ? `${height}px;` : '500px'};
    height: auto;
  `) : (`
    height: ${height ? `${height}px;` : '500px'};
  `))}
  margin-top: 5px;
  overflow-y: ${({ autoHeight }) => (autoHeight ? 'hidden' : 'auto')};
`;

const Wrapper = styled.div`
  display: flex;
  padding-left: ${({ rowPadding }) => (rowPadding ? `${rowPadding}px` : '1rem')};
  padding-right: ${({ rowPadding }) => (rowPadding ? `${rowPadding}px` : '1rem')};

  ${({ height, autoHeight }) => (autoHeight ? (`
    min-height: ${height ? `${height}px;` : '50px'};
    height: auto;
  `) : (`
    height: ${height ? `${height}px;` : '50px'};
  `))}

  &:nth-child(2n) {
    background-color: ${background01};
  }

  &:last-child {
    ${({ height, autoHeight }) => (autoHeight ? (`
    min-height: ${height ? `${height}px;` : '50px'};
    height: auto;
  `) : (`
    height: ${height ? `${height}px;` : '50px'};
  `))}

  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign }) => (textAlign
    === 'left' ? 'flex-start' : textAlign === 'right'
      ? 'flex-end' : 'center')};

  ${({ width }) => width && (`width: ${width};`)};

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
  ${({ noMargin, cellMargin }) => (!noMargin && `
    &:not(:first-child) {
      margin-left: ${cellMargin || 30}px;
    }
  `)}

  @media (max-width: 1360px) {
    ${({ width, adaptive }) => width && (`width: calc(${width} - ${adaptive || 10}px);`)};

    font-size: 11px;
  }

  @media (max-width: 1198px) {
    ${({ width }) => width && (`width: ${width};`)};

    font-size: 13px;
  }
`;

const NoData = styled.div`
  margin-top: 100px;

  font-family: Gilroy, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${text02};
  text-align: center;
  line-height: 2rem;
  letter-spacing: 0.16px;

  white-space: pre;
`;
