/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  primary,
  secondary,
  text04,
  white,
  text01,
  brightGreen,
} from '../constants/theme';

const COLORS = {
  primary: { main: secondary },
  secondary: { main: primary },
};

export const RadioGroup = ({
  name,
  selectedValue,
  onClickRadioButton,
  children,
  type = 'primary',
  disabled,
  position,
  ...props
}) => (
  <RadioGroupContainer role="radiogroup" {...props} name={name} position={position}>
    {React.Children.map(children, (element) => React.cloneElement(element, {
      ...element.props,
      checked: (selectedValue === element.props.labelText)
        || (selectedValue === element.props.value),
      onChange: () => onClickRadioButton(element.props.value || element.props.labelText),
      name,
      type,
      position,
    }))}
  </RadioGroupContainer>
);

export const Radio = (props) => {
  const {
    isSuggested,
    onChange,
    value,
    labelText,
    checked,
    disabled,
    name,
    type = 'primary',
    position,
  } = props;

  return (
    <RadioContainer position={position}>
      <Label
        isSuggested={isSuggested}
      >
        {labelText}
      </Label>
      <Input
        {...props}
        type="radio"
        onChange={onChange}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        aria-checked={checked}
      />
      <Checkmark checked={checked} color={type} />
    </RadioContainer>
  );
};

const RadioGroupContainer = styled.div`
  display: flex;
  ${({ position }) => position === 'vertical' && 'flex-direction: column;'}
`;

const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 1.35rem;
  margin-bottom: .75rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${({ position }) => position === 'horizontal' && 'margin-right: 1rem;'}

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${({ isSuggested }) => (isSuggested ? brightGreen : text01)};
  ${({ isSuggested }) => (isSuggested ? 'font-weight: 500;' : '')};
  margin-left: .5rem;
  cursor: pointer;
  ${({ disabled }) => disabled && `
    color: ${text01};
    opacity: .3;
  `}
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background-color: ${white};
  border: 2px solid ${text04};
  border-radius: 50%;
  transition: all 0.20s ease;
  :hover {
    border-color: ${({ color }) => COLORS[color].main};
  }

  :after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${white};
    transition: all 0.20s ease;
  }
  ${({ checked, color }) => checked && `
    background-color: ${white};
    border-color: ${COLORS[color].main};
    :after {
      background: ${COLORS[color].main};
    }
  `}
`;
