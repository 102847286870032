import React from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  white,
  text01,
  brightGreen,
  lightRed,
  darkGrey,
} from '@/common/constants/theme';
import CloseIcon from '@/common/components/icons/CloseIcon';

const COLORS = {
  success: brightGreen,
  danger: lightRed,
};

const UsersMainUserDevicesInfoWindow = ({
  title,
  color,
  children,
  buttonTitle,
  disableSubmit,
  onClose,
  onSubmit,
}) => (
  <Container>
    <Title>
      {title}
    </Title>
    {children}
    <Button
      color={color}
      disabled={disableSubmit}
      onClick={onSubmit}
    >
      {buttonTitle}
    </Button>
    <Icon onClick={() => {
      onClose();
    }}
    >
      <CloseIcon />
    </Icon>
  </Container>
);

export default UsersMainUserDevicesInfoWindow;

const Container = styled.div`
  width: 230px;
  padding: 20px 20px 30px;

  position: relative;

  background: ${white};
  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Title = styled.div`
  margin-bottom: 16px;

  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${text01};
  line-height: 24px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 42px;
  margin-top: 16px;

  background-color: transparent;
  border: 1px solid ${({ color, disabled }) => (disabled ? darkGrey : COLORS[color])};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ color, disabled }) => (disabled ? darkGrey : COLORS[color])};
  text-transform: uppercase;
  line-height: 24px;

  opacity: ${({ disabled }) => (disabled ? '1' : '.75')};
  transition: all .25s ease-in-out 0s;

  &:hover{
    opacity: 1;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;

  & svg path {
    fill: ${text01};
  }
`;
