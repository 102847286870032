import React from 'react';

export default ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ enableBackground: 'new 0 0 64 64' }}
    viewBox="0 0 64 64"
  >
    <g fill={color} transform="translate(334.000000, 430.000000)">
      <path d="M-313.2-401.2h-3v-6.4c0-7.8,6.4-14.2,14.2-14.2c7.2,0,13.2,5.3,14.1,12.4l-2.9,0.4 c-0.7-5.6-5.5-9.8-11.2-9.8c-6.2,0-11.2,5-11.2,11.2V-401.2" />
      <path d="M-289.3-374.2h-25.5c-2.6,0-4.8-2.1-4.8-4.8v-19.1c0-2.6,2.1-4.8,4.8-4.8h25.5 c2.6,0,4.8,2.1,4.8,4.8v19.1C-284.5-376.3-286.6-374.2-289.3-374.2L-289.3-374.2z M-314.7-399.6c-0.9,0-1.6,0.7-1.6,1.6v19.1 c0,0.9,0.7,1.6,1.6,1.6h25.5c0.9,0,1.6-0.7,1.6-1.6v-19.1c0-0.9-0.7-1.6-1.6-1.6H-314.7L-314.7-399.6z" />
      <path d="M-298.8-393.3c0,1.8-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2 S-298.8-395-298.8-393.3" />
      <polyline points="-301.2,-393.3 -302.8,-393.3 -304.4,-383.7 -299.6,-383.7 -301.2,-393.3" />
    </g>
  </svg>
);
