import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { format } from 'date-fns';

import {
  text01,
  secondary,
  brightGreen,
} from '@/common/constants/theme';

import { CURRENCY_SYMBOLS } from '@/common/constants/financial';

import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import Loader from '@/common/components/Loader';
import ArrowTable from '../../../../../../../../icons/ArrowTable';
import CloseIcon from '../../../../../../../../icons/CloseIcon';
import SearchIcon from '../../../../../../../../icons/SearchIcon';
import AdminTablePagination from '../../../../../../../components/Table/components/TablePagination';
import DatePickerNew from '@/common/components/DatePickerNew';

import {
  SORT_DIRECTIONS,
  useAdminContext,
} from '../../../../../../../../../AdminContext';

import {
  PTRANSACTION_TYPES,
  SEARCH_FILTER_TIMEOUT,
} from '../../../../../../../Finance/FinanceContext';

import {
  DATA_TYPES,
  useUsersMainContext,
} from '../../../../../MainContext';

const SIZES = ['80px', '80px', '1080px'];

const initDates = {
  d_begin: '',
  d_end: '',
};

const initFilter = {
  ...initDates,
  page: 1,
  limit: 10,
  q: '',
};

const MainUserTransactions = () => {
  const {
    getDateFromDateFilter,
    getFilterString,
    formatDateWithZone,
    fixUnicodeCurrencySymbol,
  } = useAdminContext();
  const {
    dataProcessing,
    getUserTransactions,
  } = useUsersMainContext();

  const [paymentsData, setPaymentsData] = useState(null);
  const [localDates, setLocalDates] = useState(initDates);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentsFilter, setPaymentsFilter] = useState(initFilter);
  const [filterString, setFilterString] = useState('');

  const [searchTimeout, setSearchTimeout] = useState(null);

  const [search, setSearch] = useState({});
  const [sortByDate, setSortByDate] = useState(SORT_DIRECTIONS.notSetted);
  const [sortByAmount, setSortByAmount] = useState(SORT_DIRECTIONS.notSetted);

  const sorts = {
    asc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.asc],
    desc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.desc],
  };

  const directionsKeys = Object.keys(SORT_DIRECTIONS);
  const directions = Object.values(SORT_DIRECTIONS);

  const changeSortByDateDirection = () => {
    const currentSortIndex = directions.findIndex((s) => s === sortByDate) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortByDate(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);
    setSortByAmount(SORT_DIRECTIONS.notSetted);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'dt_create';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setPaymentsFilter((prevState) => ({ ...prevState, ordering: `${sign}${field}`.trim() }));
  };

  const changeSortByAmountDirection = () => {
    const currentSortIndex = directions.findIndex((s) => s === sortByAmount) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortByAmount(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);
    setSortByDate(SORT_DIRECTIONS.notSetted);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'amount';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setPaymentsFilter((prevState) => ({ ...prevState, ordering: `${sign}${field}`.trim() }));
  };

  const getSearchString = (searchObject) => {
    const searchStringArray = [];
    // eslint-disable-next-line no-unused-vars
    Object.entries(searchObject).forEach(([key, value]) => { searchStringArray.push(value); });
    return searchStringArray.join(' ');
  };

  useEffect(() => {
    setLocalDates({
      d_begin: getDateFromDateFilter(paymentsFilter.d_begin),
      d_end: getDateFromDateFilter(paymentsFilter.d_end),
    });
    setCurrentPage(paymentsFilter.page);
  }, [paymentsFilter]);

  useEffect(() => {
    setPaymentsFilter((prevState) => ({ ...prevState, page: currentPage }));
  }, [currentPage]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(setTimeout(() => {
      setPaymentsFilter((prevState) => ({ ...prevState, q: getSearchString(search), page: 1 }));
    }, SEARCH_FILTER_TIMEOUT));
  }, [search]);

  useEffect(() => {
    const fltrStr = getFilterString(paymentsFilter);
    if (fltrStr === filterString) { return; }
    setFilterString(fltrStr);
    getUserTransactions(fltrStr)
      .then((res) => setPaymentsData(res))
      .catch(() => setPaymentsData([]));
  }, [paymentsFilter]);

  const formatDescription = (payment) => {
    // const paymentSystemTemplate = 'Пополнение баланса через {psName}';
    const paymentSystemTemplate = '';
    const currencySymbol = (payment?.currency && payment?.currency_symbol) ? (CURRENCY_SYMBOLS?.[payment.currency] || '') : '';
    const descrs = [];
    descrs.push(fixUnicodeCurrencySymbol(
      (payment?.description || paymentSystemTemplate)
        .replace(/{psName}/gi, payment?.payment_system?.title || ''),
      currencySymbol,
    ));
    descrs.push(fixUnicodeCurrencySymbol(payment?.description_wallet || ''), currencySymbol);
    return descrs.join(' ').trim();
  };

  return (
    <Container>
      <FlexWrapper flexSb>
        <FlexWrapper alignCenter hidden>
          <DatePickerNew
            values={localDates}
            onSubmit={(interval) => {
              const newDates = {
                ...localDates,
                d_begin: interval?.dateStart ? format(interval.dateStart, 'yyyy-MM-dd') : '',
                d_end: interval?.dateEnd ? format(interval.dateEnd, 'yyyy-MM-dd') : '',
              };
              setPaymentsFilter((prevState) => ({ ...prevState, ...newDates, page: 1 }));
            }}
          />
        </FlexWrapper>
      </FlexWrapper>
      <Table
        page={paymentsFilter?.page || 1}
        limit={paymentsFilter?.limit || 1}
        cellSizes={SIZES}
        textAlign="left"
        dataLength={paymentsData?.count || 1}
      >
        {dataProcessing?.[DATA_TYPES.userStats] && <Loader />}
        <TableHead>
          <HeadItem flex>
            Дата
            <IconsWrapper
              onClick={() => changeSortByDateDirection()}
            >
              {sorts.asc.includes(sortByDate)
                && (
                  <IconSort isRotate>
                    <ArrowTable />
                  </IconSort>
                )}
              {sorts.desc.includes(sortByDate)
                && (
                  <IconSort leftMargin top>
                    <ArrowTable />
                  </IconSort>
                )}
            </IconsWrapper>
          </HeadItem>
          <HeadItem flex>
            Сумма
            <IconsWrapper
              onClick={() => changeSortByAmountDirection()}
            >
              {sorts.asc.includes(sortByAmount)
                && (
                  <IconSort isRotate>
                    <ArrowTable />
                  </IconSort>
                )}
              {sorts.desc.includes(sortByAmount)
                && (
                  <IconSort leftMargin top>
                    <ArrowTable />
                  </IconSort>
                )}
            </IconsWrapper>
          </HeadItem>
          <HeadItem flex spaceBetween>
            <HeadItemText>
              Описание
            </HeadItemText>
            <HeadItemControl>
              <Input
                value={search.email || ''}
                onChange={({ target }) => setSearch({ ...search, email: target.value })}
                placeholder="Поиск"
              />
              <Icon top>
                <SearchIcon />
              </Icon>
              {(search.email || '').length > 0
                && (
                  <CloseIconContainer
                    onClick={() => setSearch({ ...search, email: '' })}
                  >
                    <CloseIcon />
                  </CloseIconContainer>
                )}
            </HeadItemControl>
          </HeadItem>
        </TableHead>
        <TableBody>
          {(paymentsData?.results || []).map((item) => (
            <div key={`MUT-row--${item.id}`}>
              <RowItem>
                {item?.dt_create && formatDateWithZone(item.dt_create)}
              </RowItem>
              <RowItem>
                <Text
                  greenColor={item?.transaction_type === PTRANSACTION_TYPES.replenish}
                  bold
                >
                  {item?.amount || ''}
                </Text>
              </RowItem>
              <RowItem>
                {formatDescription(item)}
              </RowItem>
            </div>
          ))}
        </TableBody>
        <AdminTablePagination
          page={currentPage}
          pageCount={Math.ceil((paymentsData?.count || 0) / (paymentsFilter?.limit || 1))}
          setPage={setCurrentPage}
          recordsCount={paymentsData?.count || 0}
        />
      </Table>
    </Container>
  );
};

export default MainUserTransactions;

const Container = styled.div`
  margin-bottom: 40px;
`;

const IconsWrapper = styled.div`
  display: flex;

  width: 18px;
  margin-left: 10px;
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  ${({ bold }) => bold && 'font-weight: bold;'}
  line-height: 15px;
  color: ${({ greenColor }) => (greenColor ? brightGreen : text01)};
`;

const FlexWrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: center;
  ${({ flexSb }) => flexSb && 'justify-content: space-between;'}
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}

  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 16px;'}

  position: relative;

  ${({ pointer }) => pointer && 'cursor: pointer;'}

  ${({ isOpen, bottomLine }) => bottomLine && !isOpen && (`
    &::after {
      content: '';

      height: 1px;

      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;

      background-color: ${text01};
    }
  `)}
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  position: relative;

  cursor: pointer;
  transform: rotate(${({ isRotate }) => (isRotate ? 180 : 0)}deg);

  transition: 0.2s ease;
`;

const IconSort = styled.div`
  ${({ leftMargin }) => leftMargin && 'margin-left: 2px;'}

  position: relative;
  ${({ top }) => top && 'top: 3px;'}

  cursor: pointer;
  ${({ isRotate }) => isRotate && 'transform: rotate(-180deg);'}`;

const HeadItem = styled.div`
  ${({ flex, spaceAround, spaceBetween }) => flex && `
    display: flex;
    align-items: center;
    ${spaceAround ? 'justify-content: space-around;' : ''}
    ${spaceBetween ? 'justify-content: space-between;' : ''}
    width: 100%;
  `}
`;

const HeadItemText = styled.div``;

const HeadItemControl = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 200px;
  padding-bottom: 4px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${secondary};
  outline: none;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
`;

const RowItem = styled.div`
  ${({ flex }) => flex && `
    display: flex;

    width: 100%;
  `}
`;
