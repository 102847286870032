/* eslint-disable no-nested-ternary, no-console */
import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';

import styled from 'styled-components';
import {
  primary,
  borderSecondary,
  white,
  text01,
  background01,
  inputBorder,
  secondary,
  red,
  grey,
  greenTertiary,
} from '@/common/constants/theme';

import EditIcon from '@/common/components/icons/EditIcon';
import CloseIcon from '@/common/components/icons/CloseIcon';
import ResetThemeIcon from '../../../../../../../../icons/ResetThemeIcon';
import RestoreIcon from '../../../../../../../../icons/RestoreIcon';
import Dropdown from '@/common/components/Dropdown';
import Button from '@/common/components/Button';

import { USE_DEVICE_VIEW_TYPE } from '@/AppContext';

import {
  DATA_TYPES,
  SUBSCRIPTION_ACTIONS,
  useUsersMainContext,
} from '../../../../../MainContext';

const DATE_FORMAT = 'dd.MM.yyyy';

const CONNECT_TYPES = {
  playlist: {
    title: 'Playlist',
    key: 'playlist',
  },
  portal: {
    title: 'Portal',
    key: 'portal',
  },
};

const SELECT_EMPTY_ITEM = { key: -1, title: 'Не выбрано' };

const UsersMainUserDevicesInfo = ({ device, setSubscriptionAction }) => {
  const [serversOptions, setServersOptions] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  const [ministraServersOptions, setMinistraServersOption] = useState([]);
  const [selectedMinistraServer, setSelectedMinistraServer] = useState(null);
  const [ministraLogin, setMinistraLogin] = useState(null);
  const [ministraPass, setMinistraPass] = useState(null);
  const [ottdId, setOttdId] = useState(null);

  const [userPlayListOptions, setUserPlayListOptions] = useState([]);
  const [selectedPlayList, setSelectedPlayList] = useState(null);
  const [selectedViewType, setSelectedViewType] = useState(null);

  const [isDeviceNotChanged, setIsDeviceNotChanged] = useState(false);
  const [isPlayListDownloadEnabled, setIsPlayListDownloadEnabled] = useState(false);

  const [plFormatsOptions, setPlFormatsOptions] = useState([]);
  const [selectedPlFormat, setSelectedPlFormat] = useState(null);

  const {
    dataProcessing,
    servers,
    osFormats,
    ministraServers,
    userPlayLists,
    updateDevice,
    resetDeviceTheme,
    regenOttId,
  } = useUsersMainContext();

  const detectDeviceNotChanged = () => {
    let isNotChanged = true;
    if (device) {
      isNotChanged = isNotChanged
        && (!selectedMinistraServer || (selectedMinistraServer?.key === device.ministra_server));
      isNotChanged = isNotChanged
        && (USE_DEVICE_VIEW_TYPE
          && (!selectedViewType || (selectedViewType?.key === device.view_type)));
      isNotChanged = isNotChanged && (!ministraLogin || (ministraLogin === device.ministra_login));
      isNotChanged = isNotChanged && (!ministraPass || (ministraPass === device.ministra_password));
      isNotChanged = isNotChanged
        && (!selectedServer || (selectedServer?.key === device.server));
      isNotChanged = isNotChanged
        && (!selectedPlayList || (selectedPlayList?.key === device.playlist));
      // isNotChanged = isNotChanged && (!ottdId || (ottdId === device.ott_id));
    }
    setIsDeviceNotChanged(isNotChanged);
    return isNotChanged;
  };

  const detectDownloadPlayListEnabled = () => {
    // let isEnabled = true;
    let isEnabled = isDeviceNotChanged;

    isEnabled = isEnabled && selectedPlFormat;
    isEnabled = isEnabled && selectedPlayList;

    setIsPlayListDownloadEnabled(isEnabled);
    return isEnabled;
  };

  const handleDownloadPlayList = () => {
    const plFormat = osFormats.find((of) => of.id === selectedPlFormat.key);
    const url = `${window.location.origin}${plFormat.download_url.replace('{ott_id}', device.ott_id)}`;
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', plFormat.file_name);
    link.click();
    link.remove();
  };

  const handleResetTheme = () => resetDeviceTheme(device.id);

  const handleUpdateDevice = () => {
    console.log('selectedPlayList', selectedPlayList);
    updateDevice({
      ...device,
      server_id: selectedServer?.key || device?.server || null,
      view_type: selectedViewType?.key || device?.view_type || null,
      ott_id: ottdId || device?.ott_id || '',
      playlist_id: ((selectedPlayList?.key || -1) === -1
        ? null
        : (selectedPlayList?.key || device?.playlist || null)),
      ministra_server: selectedMinistraServer?.key || device?.ministra_server || null,
      ministra_login: ministraLogin || device.ministra_login || 'no-login',
      ministra_password: ministraPass || device.ministra_password || 'no-password',
    });
  };

  const handleRegenOTTId = () => {
    if (!device?.id) { return; }
    regenOttId(device.id)
      .then((res) => setOttdId(res?.ott_id || ''));
  };

  useEffect(() => {
    const fOpts = [];
    (osFormats || []).forEach(
      ({ id, name }) => {
        fOpts.push({ key: id, title: name });
      },
    );
    setPlFormatsOptions(fOpts);
  }, [osFormats]);

  useEffect(() => {
    const opts = (servers || []).map(
      ({ id, name }) => ({ key: id, title: name }),
    );
    setServersOptions(opts);
  }, [servers]);

  useEffect(() => {
    setMinistraServersOption(
      (ministraServers || []).map(
        ({ id, title }) => ({ key: id, title }),
      ),
    );
  }, [ministraServers]);

  useEffect(() => {
    setUserPlayListOptions(
      (userPlayLists || []).map(
        ({ id, title }) => ({ key: id, title }),
      ),
    );
  }, [userPlayLists]);

  useEffect(() => {
    if (device) {
      setSelectedViewType(CONNECT_TYPES?.[device.view_type] || CONNECT_TYPES.playlist);
      if (serversOptions.length > 0) {
        const activeS = serversOptions.find(
          (s) => s.key === device?.server,
        ) || SELECT_EMPTY_ITEM;
        setSelectedServer(activeS);
      }
      if (ministraServersOptions.length > 0) {
        const activeMS = ministraServersOptions.find(
          (ms) => ms.key === device?.ministra_server,
        ) || null;
        setSelectedMinistraServer(activeMS);
      }
      setMinistraLogin(null);
      setMinistraPass(null);
      setOttdId(null);
      setSelectedPlayList(userPlayListOptions.find(({ key }) => key === device.playlist) || null);
      setIsDeviceNotChanged(true);
    }
  }, [
    serversOptions, ministraServersOptions, userPlayListOptions,
    device,
  ]);

  useEffect(() => {
    detectDeviceNotChanged();
  }, [
    ottdId, selectedViewType,
    selectedServer, selectedPlayList,
    selectedMinistraServer, ministraLogin, ministraPass,
  ]);

  useEffect(() => {
    detectDownloadPlayListEnabled();
  }, [
    selectedPlayList, selectedPlFormat,
  ]);
  return (
    <Container>
      <Title>
        {device.name}
      </Title>
      <Wrapper flex bottomMargin={10}>
        <Text rightMargin={5}>
          Подписка:
        </Text>
        <Text
          greenColor={device?.subscription?.is_active}
          redColor={!device?.subscription?.is_active}
          bold
        >
          {device?.subscription?.is_active ? 'Активна' : 'Нет'}
        </Text>
      </Wrapper>
      <Wrapper flex flexCenter bottomMargin={20}>
        {device?.subscription?.is_active && (
          <>
            <DateBox>
              {format(new Date(device?.subscription?.date_start), DATE_FORMAT)}
            </DateBox>
            -
            <DateBox margin>
              {format(new Date(device?.subscription?.date_end), DATE_FORMAT)}
            </DateBox>
            <Icon onClick={() => setSubscriptionAction(SUBSCRIPTION_ACTIONS.edit)}>
              <EditIcon />
            </Icon>
          </>
        )}
        <ActionButton
          leftMargin={device?.subscription?.is_active}
          onClick={() => (device?.subscription?.is_active
            ? setSubscriptionAction(SUBSCRIPTION_ACTIONS.cancel)
            : setSubscriptionAction(SUBSCRIPTION_ACTIONS.add))}
        >
          {device?.subscription?.is_active ? 'Отменить' : 'Добавить подписку'}
        </ActionButton>
      </Wrapper>
      {USE_DEVICE_VIEW_TYPE && (
        <Wrapper bottomMargin={14}>
          <Text bottomMargin={12}>
            Тип подключения
          </Text>
          <StyledDropdown
            options={Object.values(CONNECT_TYPES)}
            height={32}
            value={selectedViewType || CONNECT_TYPES.playlist}
            onSelect={(option) => setSelectedViewType(option)}
          />
        </Wrapper>
      )}
      {selectedViewType && (
        (selectedViewType.key === CONNECT_TYPES.playlist.key) ? (
          <>
            <Wrapper bottomMargin={14}>
              <Text bottomMargin={12}>
                Выбранный сервер
              </Text>
              <StyledDropdown
                height={32}
                value={selectedServer}
                options={serversOptions}
                onSelect={(v) => setSelectedServer(v)}
              />
            </Wrapper>
            <Wrapper bottomMargin={14}>
              <Text bottomMargin={12}>
                IPV ott ID
              </Text>
              <Wrapper flex flexCenter>
                <Input
                  disabled
                  placeholder="IPV ott ID..."
                  value={ottdId || device?.ott_id || ''}
                />
                <RegenOttIDIcon
                  isDisabled={dataProcessing[DATA_TYPES.ottId] || !device?.subscription?.is_active}
                  onClick={!(dataProcessing[DATA_TYPES.ottId] || !device?.subscription?.is_active)
                    ? handleRegenOTTId : null}
                >
                  <RestoreIcon />
                </RegenOttIDIcon>
              </Wrapper>
            </Wrapper>
            <Wrapper bottomMargin={20}>
              <Wrapper flex>
                <Text bottomMargin={12}>
                  Плейлист
                </Text>
                {(selectedPlayList?.key !== SELECT_EMPTY_ITEM.key) && (
                  <CloseIconContainer
                    onClick={() => setSelectedPlayList(null)}
                  >
                    <CloseIcon />
                  </CloseIconContainer>
                )}
              </Wrapper>
              <StyledDropdown
                options={userPlayListOptions}
                value={selectedPlayList || SELECT_EMPTY_ITEM}
                onSelect={(v) => setSelectedPlayList(v)}
                height={32}
              />
            </Wrapper>
            <Wrapper bottomMargin={20}>
              <Text bottomMargin={12}>
                Скачать в формате
              </Text>
              <StyledDropdown
                options={plFormatsOptions}
                value={selectedPlFormat}
                onSelect={(v) => setSelectedPlFormat(v)}
                height={32}
              />
            </Wrapper>
          </>
        ) : (
          <>
            <Wrapper bottomMargin={14}>
              <Text bottomMargin={12}>
                Выбранный сервер Ministra
              </Text>
              <StyledDropdown
                height={32}
                value={selectedMinistraServer}
                options={ministraServersOptions}
                onSelect={(v) => setSelectedMinistraServer(v)}
              />
            </Wrapper>
            <Wrapper bottomMargin={14}>
              <Text bottomMargin={12}>
                Логин
              </Text>
              <Input
                placeholder="Ministra server login..."
                value={ministraLogin || device?.ministra_login || ''}
                onChange={({ target }) => setMinistraLogin(target.value)}
                onKeyUp={({ target }) => setMinistraLogin(target.value)}
              />
            </Wrapper>
            <Wrapper bottomMargin={20}>
              <Text bottomMargin={12}>
                Пароль
              </Text>
              <Input
                placeholder="Ministra server password..."
                value={ministraPass || device?.ministra_password || ''}
                onChange={({ target }) => setMinistraPass(target.value)}
                onKeyUp={({ target }) => setMinistraPass(target.value)}
              />
            </Wrapper>
          </>
        ))}
      <Wrapper flex>
        {selectedViewType?.key === CONNECT_TYPES.playlist.key ? (
          <DownloadButton
            disabled={!isPlayListDownloadEnabled}
            onClick={isPlayListDownloadEnabled ? handleDownloadPlayList : () => {}}
          >
            Скачать плейлист
          </DownloadButton>
        ) : (
          <ResetThemeButton
            onClick={handleResetTheme}
          >
            <Icon isRestTheme><ResetThemeIcon /></Icon>
            <span>Сбросить тему</span>
          </ResetThemeButton>
        )}
        <StyledButton
          isBoldText
          isUpperCase
          isDisabled={isDeviceNotChanged}
          onClick={handleUpdateDevice}
          width="small"
        >
          Сохранить
        </StyledButton>
      </Wrapper>
    </Container>
  );
};

export default UsersMainUserDevicesInfo;

const Container = styled.div`
  width: 400px;
  margin-right: 30px;
  padding: 20px 20px 30px 20px;

  background-color: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && `
    position: relative;
    display: flex;
    font-size: 0;
  `}
  ${({ flexCenter }) => flexCenter && 'align-items: center;'}
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`}
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}
`;

const Text = styled.div`
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`}
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  ${({ bold }) => bold && 'font-weight: bold;'}
  color: ${({ greenColor }) => (greenColor ? greenTertiary : text01)};
  line-height: 15px;
  ${({ redColor }) => redColor && `color: ${red};`}
`;

const Title = styled(Text)`
  margin-bottom: 14px;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const DateBox = styled.div`
  ${({ margin }) => margin && `
    margin-left: 6px;
    margin-right: 10px;
  `}

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 32px;

  background: ${background01};
  border: 1px solid ${inputBorder};
  border-radius: 2px;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: ${text01};
  line-height: 20px;

  &:first-child {
    margin-right: 6px;
  }
`;

const Icon = styled.div`
  cursor: pointer;
  ${({ isRestTheme }) => (isRestTheme && `
    width: 24px;
    height: 24px;
    transform: rotate(135deg);
    margin-right: .25rem;
  `)}

  position: relative;
  top: 2px;
`;

const ActionButton = styled.button`
  height: 32px;
  padding: 0 20px;
  ${({ leftMargin }) => leftMargin && 'margin-left: 16px;'}

  background: ${white};
  border: 1px solid ${secondary};
  border-radius: 2px;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${secondary};
  text-transform: uppercase;
  line-height: 24px;
`;

const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 48px;
  padding: 0 25px;
  margin-right: 22px;

  border: 1px solid ${({ disabled }) => (disabled ? grey : greenTertiary)};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ disabled }) => (disabled ? grey : greenTertiary)};
  text-transform: uppercase;
  line-height: 24px;

  transition: all .25s ease-in-out 0s;
  opacity: ${({ disabled }) => (disabled ? '1' : '.75')};

  &:hover{
    opacity: 1;
  }
`;

const ResetThemeButton = styled(DownloadButton)`
  border-color: ${({ disabled }) => (disabled ? grey : secondary)};
  color: ${({ disabled }) => (disabled ? grey : secondary)};
  ${({ isActive }) => (isActive ? `
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  ` : '')}
  & svg{
    fill: ${({ disabled }) => (disabled ? grey : secondary)};
  }
`;

const RegenOttIDIcon = styled.div`
  margin-left: .25rem;
  padding: 0 .25rem;
  font-size: 0;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  opacity: .75;
  transition: all .15s ease-in 0s;

  ${({ isDisabled }) => (!isDisabled && `
    &:hover{
      opacity: 1;
    }
  `)}

  & svg{
    fill: ${({ isDisabled }) => (isDisabled ? grey : primary)};
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  height: 32px;
  padding: 0 10px;

  background-color: transparent;
  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  outline: none;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: .25rem;
  right: .5rem;
  cursor: pointer;
`;
