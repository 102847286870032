import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';

import { borderSecondary, secondary } from '@/common/constants/theme';
import EditIcon from '@/common/components/icons/EditIcon';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '../../../../icons/DeleteIcon';
import OrderArrowsIcon from '../../../../icons/OrderArrowsIcon';
import AdminTablePagination from '../../../components/Table/components/TablePagination';
import AdminDeleteModal from '../../../../Modals/DeleteModal';
import { useBaseContext } from '../BaseContext';
import SearchIcon from '../../../../icons/SearchIcon';

const SIZES = ['160px', '330px', '690px'];
const locales = { ru, en };

const NewsTable = () => {
  const [deletedNews, setDeletedNews] = useState(null);
  const {
    getNews,
    news,
    newsCount,
    // getPosition,
    newsPage,
    setNewsPage,
    getNewsPageCount,
    deleteNewsById,
  } = useBaseContext();
  const history = useHistory();

  const [orderFilter, setOrderFilter] = useState('-created_date');
  const [searchFilter, setSearchFilter] = useState(null);

  useEffect(() => {
    getNews(orderFilter, searchFilter);
  }, [orderFilter, searchFilter]);

  const handleOrder = () => {
    if (orderFilter === 'created_date') {
      setOrderFilter('-created_date');
    } else {
      setOrderFilter('created_date');
    }
  };

  const handleSearch = (val) => {
    setSearchFilter(val);
  };

  const locale = localStorage.getItem('locale') ?? 'ru';
  return (
    <Container>
      <AdminDeleteModal
        content={`Новость "${deletedNews?.title}"`}
        isOpen={deletedNews}
        onClose={() => setDeletedNews(null)}
        handleDelete={() => deleteNewsById(deletedNews.id)}
      />
      <Table
        dataLength={newsCount}
        limit={50}
        page={newsPage}
        cellSizes={SIZES}
        textAlign="left"
        adaptiveSize={40}
      >
        <TableHead>
          <HeadItem>
            Дата
            <Icon onClick={handleOrder}>
              <OrderArrowsIcon />
            </Icon>
          </HeadItem>
          <HeadItem>
            Тема новости
          </HeadItem>
          <HeadItem>
            Описание новости
            <InputWrapper>
              <Input
                placeholder="Поиск"
                onChange={({ target }) => handleSearch(target.value)}
              />
              <Icon>
                <SearchIcon />
              </Icon>
            </InputWrapper>
          </HeadItem>
        </TableHead>
        <TableBody rowHeight={85} isBottomRowBorder customHeight="700px">
          {news.map((item) => (
            <Row key={`ANT-R--${item.id}`}>
              <RowItem>
                {format(new Date(item.created_date), 'd MMMM yyyy', { locale: locales[locale] })}
              </RowItem>
              <RowItem>
                {item.title}
              </RowItem>
              <RowItem>
                <Description dangerouslySetInnerHTML={{ __html: item.short_text }} />
                <Wrapper flex>
                  <Icon onClick={() => history.push(`/admin/public/news/${item.id}`)}>
                    <EditIcon />
                  </Icon>
                  <Icon onClick={() => setDeletedNews(item)}>
                    <DeleteIcon />
                  </Icon>
                </Wrapper>
              </RowItem>
            </Row>
          ))}
        </TableBody>
        <AdminTablePagination
          page={newsPage}
          setPage={setNewsPage}
          pageCount={getNewsPageCount()}
          recordsCount={newsCount}
          title="Новостей:"
        />
      </Table>
    </Container>
  );
};

export default NewsTable;

const Container = styled.div`
  margin: 10px 0;
  & .table-rows {
    border: 1px solid ${borderSecondary};
    border-radius: 4px 4px 0 0;

    &__row__item {
      align-items: flex-start;
      padding-top: 10px;
      overflow: hidden;
      height: 67px;
    }
  }
`;

const HeadItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -10px;
`;

const Row = styled.div``;

const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const Description = styled.div`
  flex: 1;
  margin-right: 30px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;

const Icon = styled.div`
  margin-right: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  line-height: 18px;
  margin-right: 20px;
  path {
    stroke: ${secondary};
    stroke-opacity: 1;
  }
`;

const Input = styled.input`
  width: 205px;
  margin-left: 15px;
  margin-right: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(255 152 0 / 50%);;
  outline: none;
`;
