export const getProgress = (programm) => {
  const currentTime = Math.round(new Date() / 1000) - (programm?.time || 0);
  return (currentTime * 100) / (programm?.duration || 1);
};

export const getDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - (hours * 3600)) / 60).toString();

  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
};

export const getCurrentTime = (time) => {
  const duration = Math.round(new Date() / 1000) - time;
  return getDuration(duration);
};
