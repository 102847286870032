export default {
  newsList: '/api/v1/news/list/',
  newsItem: (id) => `/api/v1/news/${id}/`,
  notifications: '/api/v1/user/notifications/',
  notificationsReading: (id) => `/api/v1/user/notifications/${id}/reading`,
  personalNotifications: '/api/v1/user/personal-notifications/',
  personalNotificationsReading: (id) => `/api/v1/user/personal-notifications/${id}/reading`,
  allNotificationsReading: '/api/v1/user/notifications/reading-all',
  allPersonalNotificationsReading: '/api/v1/user/personal-notifications/reading-all',
};
