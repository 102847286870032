import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useGeoContext } from '../GeoContext';
import AdminMultiSelect from '../../../../components/MultiSelect';
import AdminTablePagination from '../../../../components/Table/components/TablePagination';
import SettingsIcon from '../../../../../icons/SettingsIcon';
import AdminDropdown from '../../../../components/Dropdown';
import { useAdminContext } from '../../../../../../AdminContext';
import AdminTable from '../../../../components/Table/Table';
import AdminTableHead from '../../../../components/Table/components/TableHead';
import AdminTableBody from '../../../../components/Table/components/TableBody';

const SIZES = ['180px', '200px', '425px'];

const HEAD_ITEMS = [
  'Наименование',
  'Группа',
  'Заблокированные каналы',
];

const GeoTable = () => {
  const {
    countries,
    channels,
    dropdownGroups,
    dropdownGroupsValue,
    setDropdownGroupsValue,
    channelsCount,
    getChannels,
    updateChannelById,
    getOffset,
    getGroups,
    handleDragEndChannels,
  } = useAdminContext();
  const {
    filters,
    setFilters,
    handleBlockCountry,
    handleUnblockCountry,
    handleUnblockAllCountries,
  } = useGeoContext();
  const { page } = filters;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownWrapperRef = useRef(null);

  useEffect(() => {
    getGroups(0);
  }, []);

  useEffect(() => {
    getChannels(getOffset(page));
  }, [dropdownGroupsValue, page]);

  return (
    <Container>
      <AdminTable
        cellSizes={SIZES}
        dataLength={channelsCount}
        limit={50}
        page={page}
        textAlign="left"
        onSortEnd={(res) => handleDragEndChannels(res, getOffset(page))}
        editable={false}
      >
        <AdminTableHead>
          {HEAD_ITEMS.map((item, i) => (
            <HeaderItem flexSb={i === 1} key={item}>
              {item}
              {i === 1 && (
                <Icon
                  ref={dropdownWrapperRef}
                  onClick={() => setIsDropdownOpen((prevState) => !prevState)}
                >
                  <SettingsIcon />
                  <AdminDropdown
                    isOpen={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    options={dropdownGroups}
                    value={dropdownGroupsValue}
                    onSelect={(value) => setDropdownGroupsValue(value)}
                    ref={dropdownWrapperRef}
                  />
                </Icon>
              )}
            </HeaderItem>
          ))}
        </AdminTableHead>
        <AdminTableBody>
          {channels.map((item, i) => (
            <React.Fragment key={item.name_translit}>
              <RowItem>
                {item.name_translit}
              </RowItem>
              <RowItem>
                {item.group_name}
              </RowItem>
              <RowItem>
                <AdminMultiSelect
                  options={countries}
                  onSelect={(value, isBlock) => {
                    if (isBlock) {
                      handleBlockCountry(i, value.key);
                    } else {
                      handleUnblockCountry(i, value);
                    }

                    updateChannelById(item.id, item, getOffset(page));
                  }}
                  selectedValues={item.disabled_countries}
                  handleClear={() => {
                    handleUnblockAllCountries(i);
                    updateChannelById(item.id, item, getOffset(page));
                  }}
                />
              </RowItem>
            </React.Fragment>
          ))}
        </AdminTableBody>
        <AdminTablePagination
          setFilters={setFilters}
          filters={filters}
          numberOfPages={Math.ceil(channelsCount / 50)}
          channelsCount={channelsCount}
        />
      </AdminTable>
    </Container>
  );
};

export default GeoTable;

const Container = styled.div`
  & .admin-table-body-item {
    height: auto;
    min-height: 42px;
    padding: 4px 20px 4px 23px;
  }
`;

const HeaderItem = styled.div`
  ${({ flexSb }) => flexSb && (`
    display: flex;
    justify-content: space-between;
  `)}

  width: 100%;
`;

const RowItem = styled.div`
  width: 100%;
`;

const Icon = styled.div`
  position: relative;
  top: 2px;

  cursor: pointer;
`;
