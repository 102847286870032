import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import pageSizes from '@/common/constants/pageSizes';
import {
  green,
  grey, primary,
  red,
} from '@/common/constants/theme';
import Table from '../../../components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '../../../components/Table/components/TableBody';
import TablePagination from '../../../components/Table/components/TablePagination';
import ReferralsTableFilters from '../ReferralsTableFilters';
import ArrowTopRightCorner from '@/common/components/icons/ArrowTopRightCorner';
import Loader from '@/common/components/Loader';

import { useReferralsContext } from '../../ReferralsContext';
import { useReferralsRequestsContext } from './ReferralsRequestsContext';

const ReferralsRequestsTable = () => {
  const { t } = useTranslation('translations');
  const {
    datePageLimitFilter,
    setPageLimit,
    applyFilter,
    resetFilter,
  } = useReferralsContext();

  const {
    referrals,
    getReferrals,
    page,
    setPage,
    pageCount,
  } = useReferralsRequestsContext();

  const [isBusy, setIsBusy] = useState(false);
  const [displayNoDataArrow, setDisplayNoDataArrow] = useState(false);

  useEffect(() => {
    if (page > pageCount) {
      setPage(1);
      return;
    }
    setIsBusy(true);
    getReferrals()
      .finally(() => setIsBusy(false));
  }, [page, datePageLimitFilter]);

  const headers = [
    t('referrals.table.header.requests.date'),
    t('referrals.table.header.requests.system'),
    t('referrals.table.header.requests.purse'),
    t('referrals.table.header.requests.sum'),
    t('referrals.table.header.requests.status'),
    t('referrals.table.header.requests.done'),
  ];

  const sizes = [
    '70px',
    '100px',
    '170px',
    '70px',
    '80px',
    '90px',
  ];

  const getSystemName = (wsName) => {
    let displayName = wsName || '';
    if (displayName.length >= 23) {
      displayName = displayName.slice(0, 23);
    }
    return displayName;
  };

  const renderEmptyDataArrow = () => displayNoDataArrow
    && ((referrals || []).length === 0)
    && (
      <HelperArrowContainer>
        <ArrowTopRightCorner />
      </HelperArrowContainer>
    );

  const renderReqRows = () => (referrals || []).map((item) => (
    <div key={`${item.id}--PSRR-Row`}>
      <RowItem rightMargin>
        {dayjs(item.dt_create).format('DD.MM.YYYY')}
      </RowItem>
      <RowItem bold>
        {getSystemName(item.withdrawal_system_name)}
      </RowItem>
      <RowItem title={item.withdrawal_system_user_identifier}>
        {(item.withdrawal_system_user_identifier || '').length >= 23
          ? `${item.withdrawal_system_user_identifier.slice(0, 23)}...`
          : (item.withdrawal_system_user_identifier || '-')}
      </RowItem>
      <RowItem>
        {item.amount}
      </RowItem>
      <RowItem
        status={item.status}
      >
        {t(`referrals.table.statuses.${item.status}`)}
      </RowItem>
      <RowItem>
        {item.status !== 'created' ? dayjs(item.dt_update).format('DD.MM.YYYY') : ''}
      </RowItem>
    </div>
  ));

  return (
    <Container>
      {isBusy && <Loader />}
      <Table
        page={page}
        setPage={setPage}
        pageCount={pageCount}
        pageLimit={datePageLimitFilter.pageLimit}
        setPageLimit={setPageLimit}
        cellSizes={sizes}
        textAlign="left"
      >
        <TableHead
          isSearch={false}
          isFilters
          filtersTitle={t('table.choosePeriod')}
          CustomFilters={(
            <ReferralsTableFilters
              startDateValue={datePageLimitFilter.startDate}
              endDateValue={datePageLimitFilter.endDate}
              applyHandler={applyFilter}
              resetHandler={resetFilter}
              editableDates
            />
          )}
          onFiltersOpen={() => setDisplayNoDataArrow(false)}
          onFiltersClose={() => setDisplayNoDataArrow(true)}
        >
          {headers.map((item) => (
            <HeadItem key={`${item}--PSRRTH-item`}>
              {item}
            </HeadItem>
          ))}
        </TableHead>
        <TableBody
          minRows={Math.max(Math.min(
            datePageLimitFilter.pageLimit, (referrals || []).length,
          ), pageSizes[0])}
          rowHeight={48}
          noDataText={t('table.noResultsTryChangeDates')}
          renderNoDataContent={!isBusy && renderEmptyDataArrow()}
        >
          {renderReqRows()}
        </TableBody>
        <TablePagination />
      </Table>
    </Container>
  );
};

export default ReferralsRequestsTable;

const Container = styled.div`
  position: relative;

  & .table-rows__row__item {
    &:nth-child(2) {
      margin-left: 17px;
    }
  }
`;

const HeadItem = styled.div``;

const RowItem = styled.div`
  ${({ rightMargin }) => rightMargin && ('margin-left: -14px;')}

  position: relative;
  left: 15px;

  ${({ status }) => status && (`
    &::after {
      content: '';

      width: 10px;
      height: 10px;

      position: absolute;
      top: 5px;
      left: -15px;

      background: ${green};
      ${(status === 'approved') ? (`
        background: ${grey};
      `) : ('')}
      ${(status === 'rejected') ? (`
        background: ${red};
      `) : ('')}

      border: 1px solid #EFEEF3;
      box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
    }

    ${(status === 'approved') ? (`
      opacity: 0.5;
    `) : ('')}
  `)}

  ${({ bold }) => bold && ('font-weight: 600;')}
`;

const HelperArrowContainer = styled.div`
  position: absolute;
  top: 36px;
  right: 10px;
  z-index: 5;

  & svg {
    width: 150px;
    height: 150px;
    fill: ${primary};
  }
`;
