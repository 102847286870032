import React from 'react';

export default () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#starPaintedIconClip0)">
      <path d="M11.9687 4.60311C11.8902 4.36012 11.6746 4.18754 11.4197 4.16456L7.95614 3.85007L6.58656 0.64445C6.48558 0.409519 6.25559 0.257446 6.00006 0.257446C5.74453 0.257446 5.51454 0.409519 5.41356 0.644999L4.04399 3.85007L0.579908 4.16456C0.325385 4.18809 0.110414 4.36012 0.0314019 4.60311C-0.0476102 4.8461 0.0253592 5.11261 0.2179 5.28062L2.83592 7.57664L2.06392 10.9773C2.00744 11.2273 2.10448 11.4858 2.31195 11.6357C2.42346 11.7163 2.55393 11.7573 2.68549 11.7573C2.79893 11.7573 2.91145 11.7267 3.01244 11.6663L6.00006 9.88071L8.98659 11.6663C9.20513 11.7978 9.48062 11.7858 9.68763 11.6357C9.89518 11.4853 9.99214 11.2268 9.93565 10.9773L9.16366 7.57664L11.7817 5.28108C11.9742 5.11261 12.0477 4.84655 11.9687 4.60311Z" fill="#FFC042" />
    </g>
    <defs>
      <clipPath id="starPaintedIconClip0">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
