import React from 'react';

import { AdminDNSContextProvider } from './DNSContext';
import AdminDNS from './DNS';

const DNSContainer = () => (
  <AdminDNSContextProvider>
    <AdminDNS />
  </AdminDNSContextProvider>
);

export default DNSContainer;
