import React from 'react';

export default () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M59.9972 48.7575C59.9966 48.7487 59.9967 48.74 59.9959 48.7312C59.9927 48.6964 59.9877 48.6623 59.9805 48.6289C59.9798 48.6256 59.9788 48.6223 59.978 48.619C59.9711 48.5883 59.9625 48.5583 59.9524 48.5291C59.9497 48.5212 59.9468 48.5136 59.9439 48.5058C59.934 48.4792 59.9229 48.4533 59.9106 48.4282C59.9085 48.4237 59.9071 48.4191 59.9048 48.4147L55.2042 39.143V8.63913C55.2042 7.70093 54.441 6.93768 53.5028 6.93768H6.49699C5.55879 6.93768 4.79555 7.70093 4.79555 8.63913V39.1428L0.0950391 48.4146C0.0928125 48.419 0.0914063 48.4236 0.0892969 48.428C0.0769922 48.4532 0.0659766 48.4791 0.0560156 48.5056C0.0530859 48.5133 0.0501563 48.5211 0.0474609 48.5289C0.0375 48.5583 0.0288281 48.5885 0.0219141 48.6192C0.0212109 48.6223 0.0201562 48.6255 0.0194531 48.6287C0.0123047 48.6622 0.00726563 48.6963 0.00410156 48.7311C0.00328125 48.7398 0.00328125 48.7485 0.0028125 48.7572C0.00164063 48.7755 0 48.7935 0 48.8121V51.0417C0 52.156 0.906445 53.0625 2.02078 53.0625H57.9792C59.0936 53.0625 60 52.156 60 51.0417V48.8121C60 48.7935 59.9984 48.7755 59.9972 48.7575ZM6.55348 8.69573H53.4465V38.4741H6.55348V8.69573ZM6.21434 40.2318H53.7855L57.69 47.933H38.3798L37.4106 44.4187C37.2132 43.7033 36.5572 43.2036 35.8151 43.2036H24.1847C23.4425 43.2036 22.7864 43.7033 22.5892 44.4186L21.6199 47.933H2.30988L6.21434 40.2318ZM36.5564 47.9332H23.4436L24.2631 44.9615H35.7369L36.5564 47.9332ZM58.2422 51.0415C58.2422 51.1865 58.1242 51.3045 57.9792 51.3045H2.02078C1.87582 51.3045 1.75781 51.1865 1.75781 51.0415V49.6908H58.2422V51.0415Z" fill="url(#paint0_linear)" />
    <path d="M50.6929 25.0494C50.2073 25.0494 49.8139 25.4428 49.8139 25.9283V34.8411H10.1865V25.9283C10.1865 25.4428 9.79313 25.0494 9.30762 25.0494C8.82211 25.0494 8.42871 25.4428 8.42871 25.9283V35.2206C8.42871 35.9806 9.04688 36.5989 9.80695 36.5989H50.1936C50.9536 36.5989 51.5719 35.9807 51.5719 35.2206V25.9283C51.5718 25.4431 51.1784 25.0494 50.6929 25.0494Z" fill="url(#paint1_linear)" />
    <path d="M50.1935 10.5706H9.80695C9.04699 10.5706 8.42871 11.1887 8.42871 11.9488V21.2411C8.42871 21.7266 8.82211 22.12 9.30762 22.12C9.79312 22.12 10.1865 21.7266 10.1865 21.2411V12.3284H49.8139V21.2411C49.8139 21.7266 50.2073 22.12 50.6928 22.12C51.1784 22.12 51.5718 21.7266 51.5718 21.2411V11.9488C51.5718 11.1886 50.9536 10.5706 50.1935 10.5706Z" fill="url(#paint2_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="60" y1="29.9681" x2="6.53151e-07" y2="29.9681" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="51.5719" y1="30.8161" x2="8.42871" y2="30.8161" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="51.5718" y1="16.3373" x2="8.42871" y2="16.3373" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
    </defs>
  </svg>
);
