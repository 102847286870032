import React, { forwardRef } from 'react';
import styled from 'styled-components';
import CheckIcon from '../../assets/icons/check-green.svg';
import PlusIcon from '../../assets/icons/blue-plus.svg';
import ErrorIcon from '../../assets/icons/error.svg';
import Tooltip from './Tooltip';
import {
  background02,
  background03,
  inputBorder,
  white,
  text03,
} from '../constants/theme';

export default forwardRef(({
  onChange,
  onKeyUp,
  onKeyDown,
  placeholder,
  validation,
  error,
  onAdd,
  errorMessage,
  disabled,
  value,
  tooltipVerticalPosition,
  className,
}, ref) => (
  <InputContainer className={className}>
    <InputWrapper>
      <Input
        id={`${Math.random()}-input`}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        value={value}
        validation={validation}
        error={error}
        disabled={disabled}
        ref={ref}
      />
      {(validation && !error) && <IconCheck src={CheckIcon} alt={CheckIcon} />}
      {(error && !validation) && (
        <Error>
          <Tooltip
            title={errorMessage}
            type="caution"
            position="right"
            verticalPosition={tooltipVerticalPosition ?? 'top'}
          >
            <ErrorImg src={ErrorIcon} alt={ErrorIcon} />
          </Tooltip>
        </Error>
      )}
      {(onAdd && !error && !validation) && (
        <AddIcon src={PlusIcon} alt={PlusIcon} onClick={onAdd} />
      )}
    </InputWrapper>
  </InputContainer>
));

const InputContainer = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: 1px solid ${inputBorder};
  outline: none;
  padding: 0 30px 0 16px;
  font-size: 14px;
  font-family: Gilroy, sans-serif;
  box-sizing: border-box;
  background: ${({ validation }) => validation && background02};
  background: ${({ error }) => error && background03};
  :disabled {
    background: ${white};
    background: ${({ validation }) => validation && background02};
    background: ${({ error }) => error && background03};
    color: ${text03};
  }
`;

const Error = styled.div`
  position: absolute;
  right: 10px;
  top: 20%;
`;

const ErrorImg = styled.img``;

const AddIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 13px;
  cursor: pointer;
`;

const IconCheck = styled(AddIcon)`
  top: 1rem;
`;
