import React from 'react';

export default () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
    <g>
      <path d="M859.5,140.4C772.4,59.9,656.6,10,528.5,10C271.7,10,63.3,207.7,42.6,459.2c0,22.5,18.3,40.8,40.8,40.8s40.8-18.3,40.8-40.8C145,253.1,316.9,91.7,528.5,91.7c105.6,0,201.5,40.3,273.8,106l-139,139h239H990V10L859.5,140.4z" />
      <path d="M947.1,500c-22.5,0-40.8,18.3-40.8,40.8c-20.7,206-194.7,367.5-406.3,367.5c-112.8,0-214-46.5-287.8-120.5l124.5-124.5H126.1H38.5H10V990l144.1-144.1C242.6,934.7,364.7,990,500,990c256.8,0,467.1-197.7,488-449.2C988,518.3,969.7,500,947.1,500z" />
    </g>
  </svg>
);
