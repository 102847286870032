/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  background01,
  borderSecondary,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';

import ChannelsMainHeader from './components/MainHeader/ChannelsMainHeader';
import ChannelsMainFilters from './components/ChannelsMainFilters';
import { useChannelsContext } from '../../ChannelsContext';
import ChannelsMainContent from './components/ChannelsMainContent';
import ChannelsMainGroup from './components/MainGroup/ChannelsMainGroup';

const ChannelsMain = ({ isFullWidth }) => {
  const { t } = useTranslation('translations');
  const {
    loaders,
    channels,
    channelsFilters,
    setChannelsFilters,
    setPlaylistDevice,
  } = useChannelsContext();
  const {
    isGroups,
    layout,
  } = channelsFilters;
  const mainDropdownOptionsAll = [
    {
      value: 'none',
      title: t('steps.step4.content.body.dropdown.option00'),
      key: 'none',
    },
    {
      value: 'all',
      title: t('steps.step4.content.body.dropdown.option01'),
      key: 'all',
    },
    // {
    //   value: 'selected',
    //   title: t('steps.step4.content.body.dropdown.option02'),
    //   key: 'selected',
    // },
    {
      value: 'hideAll',
      title: t('steps.step4.content.body.dropdown.option03'),
      key: 'hideAll',
    },
  ];
  const [hoveredGroup, setHoveredGroup] = useState(-1);
  const [isDraggedChannel, setIsDraggedChannel] = useState(false);
  const [showDraggedElem, setShowDraggedElem] = useState(false);
  const [mainDropdownOptions, setMainDropdownOptions] = useState([...mainDropdownOptionsAll]);

  useEffect(() => {
    const selectedChannels = (channels || []).filter((c) => c.isSelected);
    const updatedDDOpts = (selectedChannels.length === 0)
      ? [...mainDropdownOptionsAll].filter((o) => o.key !== 'selected')
      : [...mainDropdownOptionsAll];
    setMainDropdownOptions(updatedDDOpts);
    setChannelsFilters({
      ...channelsFilters,
      mainDropdown: (selectedChannels.length === 0) ? 'none' : 'all',
    });
  }, [channels]);

  const changeFiltersHandler = (field, value) => {
    setChannelsFilters({ ...channelsFilters, [field]: value });
  };

  const changeSelectedPlaylist = (value) => {
    setPlaylistDevice(value);
  };

  return (
    <Container
      isFullWidth={isFullWidth}
      isDraggedChannel={isDraggedChannel}
    >
      <ChannelsMainHeader
        changeFiltersHandler={changeFiltersHandler}
        changeSelectedPlaylist={changeSelectedPlaylist}
      />
      <MainContent>
        <LeftContent isGroups={isGroups}>
          {loaders.channelGroups && <Loader isCentered />}
          <LeftContentFilters>
            <ChannelsMainFilters
              isFullWidth={isFullWidth}
              changeFiltersHandler={changeFiltersHandler}
            />
          </LeftContentFilters>
          <ChannelsMainGroup
            isFullWidth={isFullWidth}
            setHoveredGroup={setHoveredGroup}
            isDraggedChannel={isDraggedChannel}
            setShowDraggedElem={setShowDraggedElem}
            changeFiltersHandler={changeFiltersHandler}
          />
        </LeftContent>
        <RightContent isGroups={isGroups} row={layout === 'row'}>
          {loaders.channels && <Loader isCentered />}
          <ChannelsMainContent
            isDraggedChannel={isDraggedChannel}
            setIsDraggedChannel={setIsDraggedChannel}
            showDraggedElem={showDraggedElem}
            setShowDraggedElem={setShowDraggedElem}
            hoveredGroup={hoveredGroup}
            mainDropdownOptions={mainDropdownOptions}
            changeFiltersHandler={changeFiltersHandler}
          />
        </RightContent>
      </MainContent>
    </Container>
  );
};

export default ChannelsMain;

const Container = styled.div`
  ${({ isFullWidth }) => (isFullWidth ? `
    margin: 0 0 18px 0;
  ` : `
    margin: 0 62px 18px 0;
  `)}

  overflow: visible;
  ${({ isDraggedChannel }) => isDraggedChannel && ('cursor: grabbing;')}
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;

  height: 549px;

  background: ${background01};
  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  border-radius: 4px;
`;

const LeftContent = styled.div`
  position: relative;
  width: ${({ isGroups }) => (isGroups ? 40 : 0)}%;

  transition: 0.4s ease;
`;

const LeftContentFilters = styled.div`
  min-width: 200px;
`;

const RightContent = styled.div`
  position: relative;
  width: 95%;
  margin-left: ${({ isGroups, row }) => (isGroups ? 67 : row ? 30 : 42)}px;

  transition: 0.4s ease;

  @media (max-width: 1360px) {
    margin-left: ${({ isGroups, row }) => (isGroups ? 47 : row ? 38 : 22)}px;
  }

  @media (max-width: 1198px) {
    margin-left: ${({ isGroups, row }) => (isGroups ? 67 : row ? 30 : 42)}px;
  }
`;
