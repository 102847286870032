import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import styled from 'styled-components';

import {
  red, primary,
} from '@/common/constants/theme';

import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';
import PageContainer from '@/common/components/PageContainer';
import ReCaptcha from '@/common/components/ReCaptcha';

import { isEmailValid as checkIsEmailValid } from '@/common/utils/emailValidator.utils';
import {
  FORM_INPUT_GUTTER_PERCENT,
  FOOTER_HEIGHT,
  MSG_RESPONSE_TYPES,
} from '@/common/utils/publicConstants.util';

import { useAppContext } from '@/AppContext';

const PAGE_BLOCK_MARGIN_TOP = 40;
const PAGE_BLOCK_MARGIN_BOTTOM = 60;

const FIELDS = {
  email: 'email',
  captcha: 'captcha',
  message: 'message',
  name: 'name',
};

const CopyrightHolders = () => {
  const { t } = useTranslation('translations');

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [captchaToken, setCaptchaToken] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [messagePostResponseType, setMessagePostResponseType] = useState(null);
  const [messagePostResponse, setMessagePostResponse] = useState(null);
  const [responseBlockHeight, setResponseBlockHeight] = useState(300);
  const [caseId, setCaseId] = useState(null);

  const {
    userInfo,
    isCprhMessageProcessing,
    handleCopyRightHolderMessagePost,
  } = useAppContext();

  const responseBox = useRef(null);

  const getFormInputWidth = (partsCount, gutterPercent) => {
    const gutter = gutterPercent * (partsCount - 1);
    const pc = partsCount || 1;
    const w = (100 - (gutter || 0)) / pc;
    return `${w}%`;
  };

  const clearData = () => {
    setName(null);
    setEmail(null);
    setMessage(null);
    setCaptchaToken('');
    setIsEmailValid(false);
    setCaseId(null);
  };

  const handleCaptcha = (v) => {
    setCaptchaToken(v);
  };

  const getResponseBoxHeight = () => {
    if (!responseBox.current) {
      return 0;
    }
    let elmMargin;
    const elm = responseBox.current;
    const elmTop = elm.getBoundingClientRect().top;
    if (document.all) {
      elmMargin = parseInt(elm.currentStyle.marginTop, 10)
        + parseInt(elm.currentStyle.marginBottom, 10);
    } else {
      elmMargin = parseInt(
        document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'),
        10,
      ) + parseInt(
        document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'),
        10,
      );
    }
    elmMargin = 0;
    return window.innerHeight - elmMargin - elmTop - PAGE_BLOCK_MARGIN_BOTTOM - FOOTER_HEIGHT;
  };

  const getInputStatus = (f) => ((errors?.[f] || false) ? 'error' : '');

  const formMessageText = () => {
    const userAddText = (userInfo?.email && userInfo?.id)
      ? t('publicPages.copyrightHolders.postMessage.authenticatedUserCaption', userInfo) : '';
    return `${userAddText}${message}`;
  };

  const handleSubmit = () => {
    const data = {
      user_full_name: name,
      user_email: email,
      subject: t('publicPages.copyrightHolders.postMessage.defaultSubject'),
      content: formMessageText(),
      captcha_token: captchaToken,
    };
    handleCopyRightHolderMessagePost(data, setMessagePostResponse);
  };

  const handlePostAnotherMessage = () => {
    setMessagePostResponse(null);
    setMessagePostResponseType(null);
    clearData();
  };

  useEffect(() => {
    setIsSubmitDisabled(
      ((name || '').length === 0)
      || !isEmailValid
      || ((message || '').length === 0)
      || ((captchaToken || '').length === 0),
    );
  }, [
    name, email, message, captchaToken,
  ]);

  useEffect(() => {
    const isEmpty = ((email || '').length === 0);
    const isValid = !isEmpty && checkIsEmailValid(email);
    setIsEmailValid(isValid);
    setErrors((prevState) => ({ ...prevState, [FIELDS.email]: !isEmpty && !isValid }));
  }, [email]);

  useEffect(() => {
    [name, message].forEach((f) => {
      const isEmpty = ((f || '').length === 0);
      setErrors((prevState) => ({ ...prevState, [f]: !isEmpty }));
    });
  }, [name, message]);

  useEffect(() => {
    if (messagePostResponse) {
      const caseID = messagePostResponse?.case?.case_id || null;
      setCaseId(caseID);
      if (messagePostResponse?.task_id || caseID) {
        setMessagePostResponseType((caseID || 0) > 0
          ? MSG_RESPONSE_TYPES.success
          : MSG_RESPONSE_TYPES.error);
      } else {
        setMessagePostResponseType(MSG_RESPONSE_TYPES.error);
      }
    }
  }, [messagePostResponse]);

  useEffect(() => {
    if (responseBox?.current) {
      setResponseBlockHeight(getResponseBoxHeight());
    }
  }, [responseBox?.current]);

  return (
    <Container>
      <PageContainer>
        <CaptionBlock>{t('publicPages.copyrightHolders.titleText')}</CaptionBlock>
        {messagePostResponseType ? (
          <ResponseBlock
            ref={responseBox}
            type={messagePostResponseType}
            height={responseBlockHeight}
          >
            <ResponseContentPart>
              <ResponseCaption
                isError={messagePostResponseType === MSG_RESPONSE_TYPES.error}
              >
                {t(`publicPages.copyrightHolders.postResult.${messagePostResponseType}Caption`)}
              </ResponseCaption>
              <ResponseText>
                {t(`publicPages.copyrightHolders.postResult.${messagePostResponseType}Text`,
                  {
                    caseNumber: caseId || '',
                    errorText: t(`publicPages.copyrightHolders.postResult.errors.${messagePostResponse?.data?.message_type}`),
                  })}
              </ResponseText>
            </ResponseContentPart>
            <ResponseActionsPart>
              <CloseButton
                onClick={handlePostAnotherMessage}
              >
                {t('publicPages.copyrightHolders.postResult.closeButtonText')}
              </CloseButton>
            </ResponseActionsPart>
          </ResponseBlock>
        ) : (
          <RequestBlock>
            {isCprhMessageProcessing && <Loader />}
            <Description>{t('publicPages.copyrightHolders.descriptionText')}</Description>
            <RequestForm>
              <FormRow>
                <FormInputBlock width={getFormInputWidth(2, FORM_INPUT_GUTTER_PERCENT)}>
                  <FormLabel>{t('publicPages.copyrightHolders.form.nameText')}</FormLabel>
                  <InputStyled
                    value={name}
                    placeholder={t('publicPages.copyrightHolders.form.namePlaceholder')}
                    status={getInputStatus(FIELDS.name)}
                    onChange={({ target }) => setName(target.value)}
                  />
                </FormInputBlock>
                <FormInputBlock width={getFormInputWidth(2, FORM_INPUT_GUTTER_PERCENT)}>
                  <FormLabel>{t('publicPages.copyrightHolders.form.emailText')}</FormLabel>
                  <InputStyled
                    value={email}
                    placeholder={t('publicPages.copyrightHolders.form.emailPlaceholder')}
                    status={getInputStatus(FIELDS.email)}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                </FormInputBlock>
              </FormRow>
              <FormRow>
                <FormInputBlock width={getFormInputWidth(1, FORM_INPUT_GUTTER_PERCENT)}>
                  <FormLabel>{t('publicPages.copyrightHolders.form.messageText')}</FormLabel>
                  <TextAreaStyled
                    value={message}
                    placeholder={t('publicPages.copyrightHolders.form.messagePlaceholder')}
                    status={getInputStatus(FIELDS.message)}
                    onChange={({ target }) => setMessage(target.value)}
                  />
                </FormInputBlock>
              </FormRow>
              <FormRow
                isCentered={((captchaToken || '').length > 0)}
              >
                {((captchaToken || '').length === 0)
                  && (
                    <CaptchaBlock>
                      <ReCaptcha
                        appToken={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                        onCheck={handleCaptcha}
                      />
                    </CaptchaBlock>
                  )}
                <ButtonBlock>
                  <ButtonStyled
                    onClick={handleSubmit}
                    isDisabled={isSubmitDisabled}
                  >
                    {t('publicPages.copyrightHolders.form.buttonText')}
                  </ButtonStyled>
                </ButtonBlock>
              </FormRow>
            </RequestForm>
          </RequestBlock>
        )}
      </PageContainer>
    </Container>
  );
};

export default CopyrightHolders;

const Container = styled.div`
  font-family: Gilroy, sans-serif;
  display: flex;
  justify-content: center;

  margin-top: ${PAGE_BLOCK_MARGIN_TOP}px;
  margin-bottom: ${PAGE_BLOCK_MARGIN_BOTTOM}px;

  font-size: 1.2rem;
`;

const CaptionBlock = styled.div`
  text-align: center;
  font-size: 3em;
  margin: 1rem 0 2rem;
`;

const Description = styled.div`
  text-align: center;
  font-size: 1em;
`;

const RequestBlock = styled.div`
  display: block;
  position: relative;
  max-width: 700px;
  margin: 0 auto;
`;

const RequestForm = styled.div`
  padding: 2rem 1rem 1rem;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'space-between')};
  margin-bottom: 2rem;
`;

const FormInputBlock = styled.div`
  width: ${({ width }) => (width || '100%')};
`;

const FormLabel = styled.div`
  display: block;
  width: 100%;
  font-size: .85em;
  margin-bottom: .125rem;
`;

const InputStyled = styled(Input)`
  width: 100%;
  padding: .75em 1em .65em;
  border-radius: .33em;
`;

const TextAreaStyled = styled(Input.TextArea)`
  padding: .75em 1em .65em;
  line-height: 1.25em;
  border-radius: .33em;
  min-height: 6.4em !important;
`;

const CaptchaBlock = styled.div``;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonStyled = styled(Button)`
`;

const ResponseBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3em 0 0;
  height: ${({ height }) => (height || 300)}px;
`;

const ResponseContentPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResponseCaption = styled.div`
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 1rem;
  color: ${({ isError }) => (isError ? red : primary)};
`;

const ResponseActionsPart = styled.div`
  display: flex;
`;

const ResponseText = styled.div`

`;

const CloseButton = styled(Button)`
  align-self: end;
  justify-self: end;
  margin-bottom: 2em;
`;
