import React from 'react';

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8125 13.1875H5.1875C4.92862 13.1875 4.71875 13.3974 4.71875 13.6562C4.71875 13.9151 4.92862 14.125 5.1875 14.125H10.8125C11.0714 14.125 11.2812 13.9151 11.2812 13.6562C11.2812 13.3974 11.0714 13.1875 10.8125 13.1875Z" fill="#5C70CF" />
    <path d="M10.8125 9.4375H5.1875C4.92862 9.4375 4.71875 9.64737 4.71875 9.90625C4.71875 10.1651 4.92862 10.375 5.1875 10.375H10.8125C11.0714 10.375 11.2812 10.1651 11.2812 9.90625C11.2812 9.64737 11.0714 9.4375 10.8125 9.4375Z" fill="#5C70CF" />
    <path d="M10.8125 11.3125H5.1875C4.92862 11.3125 4.71875 11.5224 4.71875 11.7812C4.71875 12.0401 4.92862 12.25 5.1875 12.25H10.8125C11.0714 12.25 11.2812 12.0401 11.2812 11.7812C11.2812 11.5224 11.0714 11.3125 10.8125 11.3125Z" fill="#5C70CF" />
    <path d="M10.3438 0H3.78125V4.0625H10.3438V0Z" fill="#5C70CF" />
    <path d="M15.8627 2.63731L13.3627 0.137313C13.2748 0.0494063 13.1556 0 13.0312 0H11.2812V4.53125C11.2812 4.79012 11.0714 5 10.8125 5H3.3125C3.05363 5 2.84375 4.79012 2.84375 4.53125V0H0.46875C0.209875 0 0 0.209875 0 0.46875V15.5312C0 15.7901 0.209875 16 0.46875 16C0.613906 16 15.3363 16 15.5312 16C15.7901 16 16 15.7901 16 15.5312V2.96875C16 2.84444 15.9506 2.72522 15.8627 2.63731ZM12.2188 15.0625H3.78125V8.5H12.2188V15.0625Z" fill="#5C70CF" />
  </svg>
);
