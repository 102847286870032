export default {
  countries: '/api/v1/user/countries/',
  emailValidation: '/api/v1/auth/validate/email/',
  passwordValidation: '/api/v1/auth/validate/password/',
  referralTokenValidation: '/api/v1/auth/validate/referral-token/',
  signUp: '/api/v1/auth/sign-up/',
  signIn: '/api/v1/auth/sign-in/',
  signOut: '/api/v1/auth/sign-out/',
  resendEmail: '/api/v1/auth/confirm/resend/',
  confirmEmail: (key) => `/api/v1/auth/confirm/sign-up-complete/${key}/`,
  forgetPassword: '/api/v1/auth/password/reset/',
  changePassword: (uid, token) => `/api/v1/auth/password/change/${uid}/${token}/`,
  validateChangePasswordToken: '/api/v1/auth/validate/password-change-token/',
  user: '/api/v1/user/info/',
  userMeta: '/api/v1/user/meta/',
  tokenRefresh: '/api/v1/auth/token/refresh/',
};
