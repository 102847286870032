import React from 'react';
import { RichUtils } from 'draft-js';

import ToolbarButton from './ToolbarButton';
import BoldIcon from '@/assets/icons/TextEditor/bold.svg';
import ItalicIcon from '@/assets/icons/TextEditor/italic.svg';
import UnderlineIcon from '@/assets/icons/TextEditor/underline.svg';
import MonospaceIcon from '@/assets/icons/TextEditor/monospace.svg';

const INLINE_STYLES = [
  { label: 'Жирный', style: 'BOLD', icon: BoldIcon },
  { label: 'Курсив', style: 'ITALIC', icon: ItalicIcon },
  { label: 'Подчеркивание', style: 'UNDERLINE', icon: UnderlineIcon },
  { label: 'Monospace', style: 'CODE', icon: MonospaceIcon },
];

const InlineStyleControls = ({
  editorState,
  onEditorStateChange,
}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  const toggleInlineStyle = (inlineStyle) => {
    onEditorStateChange(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle,
      ),
    );
  };

  return (
    <>
      {INLINE_STYLES.map((type) => (
        <ToolbarButton
          className="rdw-option-wrapper"
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          onToggle={() => { toggleInlineStyle(type.style); }}
        />
      ))}
    </>
  );
};

export default InlineStyleControls;
