import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { secondary } from '@/common/constants/theme';
import ExportIcon from '../../../../../icons/ExportIcon';
import ImportIcon from '../../../../../icons/ImportIcon';

import AdminFilters from '../../../../../Filters';

const GroupsFilters = ({ setIsCreateTab, setIsDeletedAll }) => {
  const history = useHistory();

  return (
    <Container>
      <AdminFilters handleDeleteAll={() => setIsDeletedAll(true)}>
        <FilterItem
          secondaryColor
          onClick={() => {
            setIsCreateTab((prevState) => !prevState);
          }}
        >
          Создать Группу
        </FilterItem>
        <FilterItem>
          Экспорт каналов / групп в CSV
          <Icon>
            <ExportIcon />
          </Icon>
        </FilterItem>
        <FilterItem onClick={() => history.push('/admin/iptv/groups/import')}>
          Импорт каналов / групп из CSV
          <Icon>
            <ImportIcon />
          </Icon>
        </FilterItem>
      </AdminFilters>
    </Container>
  );
};

export default GroupsFilters;

const Container = styled.div``;

const FilterItem = styled.div`
  display: flex;
  align-items: center;

  ${({ secondaryColor }) => secondaryColor && (`color: ${secondary};`)}
`;

const Icon = styled.div`
  margin-left: 15px;
`;
