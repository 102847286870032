import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  text01,
  primary,
  background01,
  borderSecondary,
  secondary,
} from '@/common/constants/theme';

import { getMinistra } from '@/store/user/selectors';

import LoaderFullScreen from '@/common/components/LoaderFullScreen';
import ServerCreateLoginModal from '../../Server/components/ServerCreateLoginModal';
import ContentContainer from './ContentContainer';
import DeviceSelector from './DeviceSelector';
import CopyInput from './CopyInput';
import CredentionInput from './CredentionInput';

import EditIcon from '../../../icons/EditIcon';

import { SnackTypes } from '@/AppContext';
import { usePersonalPageContext } from '../../../../PersonalPageContext';
import { usePlaylistContext } from '../PlaylistContext';

const StalkerDetails = ({ isFullWidth }) => {
  const { t } = useTranslation('translations');
  const {
    setUserSnack,
    updateDeviceMinistraCredentials,
    updateDeviceParameters,
  } = usePersonalPageContext();

  const {
    selectedDevice,
  } = usePlaylistContext();

  const [isBusy, setIsBusy] = useState(false);
  const [isMinistraCredentialsModalOpen, setIsMinistraCredentialsModalOpen] = useState(false);

  const ministra = useSelector(getMinistra);
  const MAGServer = (ministra || []).find((s) => s.id === selectedDevice.ministra_server);

  const handleEditMinistraCredentials = () => {
    setIsMinistraCredentialsModalOpen(true);
  };

  const successUpdateAction = (res) => {
    updateDeviceParameters(selectedDevice.id, res?.device_data || {});
    setUserSnack({
      type: SnackTypes.success,
      status: 200,
      message_type: 'portal.credentialsUpdated',
    });
  };

  const stopUpdateAction = (res) => {
    if (res?.data?.message_type) {
      setUserSnack({
        type: SnackTypes.error,
        status: res.status,
        message_type: res.data.message_type,
      });
    }
    setIsBusy(false);
  };

  return (
    <ContentContainer
      isFullWidth={isFullWidth}
    >
      {isBusy && <LoaderFullScreen />}
      <ServerCreateLoginModal
        device={selectedDevice}
        isOpen={isMinistraCredentialsModalOpen}
        onClose={() => {
          setIsMinistraCredentialsModalOpen(false);
        }}
        onSubmit={(form) => {
          const {
            ministra_login: mLogin,
            ministra_password: mPass,
            ministra_server: mServer,
            view_type: vType,
          } = { ...selectedDevice, ...form };

          const updData = {
            ministra_login: mLogin,
            ministra_password: mPass,
            ministra_server: mServer,
            view_type: vType,
          };

          setIsBusy(true);
          updateDeviceMinistraCredentials(selectedDevice.id, updData,
            successUpdateAction, stopUpdateAction);

          setIsMinistraCredentialsModalOpen(false);
        }}
      />
      <Text>
        {t('completion.stalker.title')}
      </Text>
      <Text>{t('completion.stalker.subTitle')}</Text>
      <Table>
        <TableHeader>
          <HeaderRow>
            <HeaderCell>
              <DeviceSelector />
            </HeaderCell>
            <HeaderCell>
              <HeaderLabel>{t('completion.stalker.login')}</HeaderLabel>
              <CredentionInput
                label={t('completion.stalker.login')}
                value={selectedDevice.ministra_login}
                disabled
                style={{ width: '152px' }}
              />
            </HeaderCell>
            <HeaderCell>
              <HeaderLabel>{t('completion.stalker.password')}</HeaderLabel>
              <CredentionInput
                label={t('completion.stalker.password')}
                value={selectedDevice.ministra_password}
                disabled
                style={{ width: '152px' }}
              />
            </HeaderCell>
            <HeaderCell>
              <EditControl
                onClick={handleEditMinistraCredentials}
                title={t('completion.stalker.editCredentials')}
              >
                <EditIcon color={secondary} />
              </EditControl>
            </HeaderCell>
          </HeaderRow>
        </TableHeader>
        <TableBody>
          <Row>
            <Cell>
              <Label>AURA / MAG</Label>
            </Cell>
            <LongCell>
              <CopyInput value={MAGServer?.aura_url} isFirst />
              <CopyInput value={MAGServer?.mag_url} isLast />
            </LongCell>
          </Row>
          <Row>
            <Cell>
              <Label>{t('completion.stalker.emulators')}</Label>
            </Cell>
            <LongCell>
              <CopyInput value={MAGServer?.android_url} />
            </LongCell>
          </Row>
          <Row>
            <Cell>
              <Label>{t('completion.stalker.application')}</Label>
            </Cell>
            <LongCell>
              <CopyInput value={MAGServer?.stalker_url} />
            </LongCell>
          </Row>
        </TableBody>
      </Table>
    </ContentContainer>
  );
};

export default StalkerDetails;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  white-space: pre-wrap;
  margin-bottom: 1rem;
`;

const Table = styled.div`
  background: ${background01};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
`;

const TableHeader = styled.div`
  background: ${background01};
  border: 1px solid ${borderSecondary};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
  margin: -1px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .75rem 0;
  margin: 0 1.25rem;
`;

const HeaderLabel = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin-right: .25rem;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${primary};
  white-space: pre-wrap;
`;

const Cell = styled.div`
  flex: 1;
`;

const HeaderCell = styled(Cell)`
  display: flex;
  flex: 0;
  align-items: center;
  :not(:last-child) {
      padding-right: 1.25rem;
      border-right: 1px solid ${borderSecondary};
      margin-right: 1.25rem;
  }
`;

const EditControl = styled.div`
  cursor: pointer;
`;

const LongCell = styled(Cell)`
  flex: 2;
  margin-left: -1.25rem;
`;

const TableBody = styled.div`
  padding-top: .5rem;
`;

const Row = styled.div`
  display: flex;
  padding: .75rem 0;
  margin: 0 1.25rem;
  border-bottom: 1px solid ${borderSecondary};
`;
