import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  white,
  secondary,
} from '@/common/constants/theme';

import HomeScreenSrc from '@/assets/images/webp/home-main-screen.webp';

import PageContainer from '@/common/components/PageContainer';
import Button from '@/common/components/Button';

import { useAppContext } from '@/AppContext';

const HomeHeader = ({ isUserExist }) => {
  const { t } = useTranslation('translations');

  const { getFromPublicLink } = useAppContext();

  return (
    <Container>
      <PageContainer>
        <Wrapper flex>
          <Wrapper>
            <TitleContainer>
              <Wrapper flex>
                <Title>{t('homePage.header.title')}</Title>
                <OrangeTitle>{` ${t('homePage.header.orangeTitle')} `}</OrangeTitle>
                <Title>{t('homePage.header.title02')}</Title>
              </Wrapper>
              <Title>{t('homePage.header.title03')}</Title>
            </TitleContainer>
            <Wrapper bottomMargin>
              <Text>{t('homePage.header.descr')}</Text>
              <Text>{t('homePage.header.descr02')}</Text>
            </Wrapper>
            <Link to={getFromPublicLink('/personal-page/subscription', isUserExist)}>
              <Button
                isBoldText
                isUpperCase
              >
                {t('homePage.header.btn')}
              </Button>
            </Link>
          </Wrapper>
          <Wrapper>
            <Img
              src={HomeScreenSrc}
              alt=""
            />
          </Wrapper>
        </Wrapper>
      </PageContainer>
    </Container>
  );
};
export default HomeHeader;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 860px;
  margin-top: -100px;

  background: linear-gradient(180deg, rgba(92, 112, 207, 0.93) 0%, rgba(63, 80, 159, 0.93) 100%);
`;

const TitleContainer = styled.div`
  margin-bottom: 34px;

  position: relative;

  &::before {
    content: '';

    width: 80%;
    height: 2px;

    position: absolute;
    left: 0;
    bottom: -15px;

    background-color: ${white};
    opacity: 20%;
  }
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && (`
    display: flex;
    align-items: center;
  `)}

  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 30px;')}
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: .16px;
  white-space: nowrap;
  color: ${white};
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 44px;
  line-height: 48px;
`;

const OrangeTitle = styled(Title)`
  margin-left: 10px;
  margin-right: 10px;

  color: ${secondary};
`;

const Img = styled.img`
  width: 790px;

  @media (max-width: 1510px) {
    width: 540px;
  }

  @media (max-width: 1198px) {
    width: 790px;
  }
`;
