import React, { useState } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { secondary } from '@/common/constants/theme';
import { PromoCodesContextProvider } from './PromocodesContext';

import PromoCodesTable from './components/PromoCodesTable';
import EditPromoCode from './components/EditPromoCode';

const INITIAL_VALUES = {
  id: 0,
  code: '',
  promocode_type: '',
  discount: '',
  max_transactions: 0,
  is_active: true,
  dt_expire: '',
  count_transactions: '',
};

const PcodesContainer = () => {
  const { t } = useTranslation(['translations']);

  const [inputValues, setInputValues] = useState({});
  const [editedPromoCodeId, setEditedPromoCodeId] = useState('');
  const [isCreate, setIsCreate] = useState(false);

  return (
    <PromoCodesContextProvider>
      <Container>
        {editedPromoCodeId || isCreate
          ? (
            <EditPromoCode
              editedPromoCodeId={editedPromoCodeId}
              setEditedPromoCodeId={setEditedPromoCodeId}
              isCreate={isCreate}
              setIsCreate={setIsCreate}
              inputValues={inputValues}
              setInputValues={setInputValues}
            />
          ) : (
            <>
              <Text onClick={() => {
                setInputValues(INITIAL_VALUES);
                setIsCreate(true);
              }}
              >
                {t('admin.iptv.promocodes.addCodeText')}
              </Text>
              <PromoCodesTable />
            </>
          )}
      </Container>
    </PromoCodesContextProvider>
  );
};

export default PcodesContainer;

const Container = styled.div``;

const Text = styled.div`
  margin: 11px 0 21px 19px;

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${secondary};
  text-transform: capitalize;
  letter-spacing: 0.16px;
  line-height: 20px;
`;
