import React from 'react';

export default () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M57 46H55C53.346 46 52 47.346 52 49V57C52 58.654 53.346 60 55 60H57C58.654 60 60 58.654 60 57V49C60 47.346 58.654 46 57 46ZM58 57C58 57.552 57.552 58 57 58H55C54.448 58 54 57.552 54 57V49C54 48.448 54.448 48 55 48H57C57.552 48 58 48.448 58 49V57Z" fill="url(#paint0_linear)" />
    <path d="M47 46H3C1.346 46 0 47.346 0 49V57C0 58.654 1.346 60 3 60H47C48.654 60 50 58.654 50 57V49C50 47.346 48.654 46 47 46ZM48 57C48 57.552 47.552 58 47 58H3C2.448 58 2 57.552 2 57V49C2 48.448 2.448 48 3 48H47C47.552 48 48 48.448 48 49V57Z" fill="url(#paint1_linear)" />
    <path d="M6 50H5C4.447 50 4 50.447 4 51C4 51.553 4.447 52 5 52H6C6.553 52 7 51.553 7 51C7 50.447 6.553 50 6 50Z" fill="url(#paint2_linear)" />
    <path d="M6 54H5C4.447 54 4 54.447 4 55C4 55.553 4.447 56 5 56H6C6.553 56 7 55.553 7 55C7 54.447 6.553 54 6 54Z" fill="url(#paint3_linear)" />
    <path d="M11 52H12C12.553 52 13 51.553 13 51C13 50.447 12.553 50 12 50H11C10.447 50 10 50.447 10 51C10 51.553 10.447 52 11 52Z" fill="url(#paint4_linear)" />
    <path d="M30 54H11C10.447 54 10 54.447 10 55C10 55.553 10.447 56 11 56H30C30.553 56 31 55.553 31 55C31 54.447 30.553 54 30 54Z" fill="url(#paint5_linear)" />
    <path d="M17 52H18C18.553 52 19 51.553 19 51C19 50.447 18.553 50 18 50H17C16.447 50 16 50.447 16 51C16 51.553 16.447 52 17 52Z" fill="url(#paint6_linear)" />
    <path d="M23 52H24C24.553 52 25 51.553 25 51C25 50.447 24.553 50 24 50H23C22.447 50 22 50.447 22 51C22 51.553 22.447 52 23 52Z" fill="url(#paint7_linear)" />
    <path d="M30 50H29C28.447 50 28 50.447 28 51C28 51.553 28.447 52 29 52H30C30.553 52 31 51.553 31 51C31 50.447 30.553 50 30 50Z" fill="url(#paint8_linear)" />
    <path d="M36 50H35C34.447 50 34 50.447 34 51C34 51.553 34.447 52 35 52H36C36.553 52 37 51.553 37 51C37 50.447 36.553 50 36 50Z" fill="url(#paint9_linear)" />
    <path d="M36 54H35C34.447 54 34 54.447 34 55C34 55.553 34.447 56 35 56H36C36.553 56 37 55.553 37 55C37 54.447 36.553 54 36 54Z" fill="url(#paint10_linear)" />
    <path d="M41 52H44C44.553 52 45 51.553 45 51C45 50.447 44.553 50 44 50H41C40.447 50 40 50.447 40 51C40 51.553 40.447 52 41 52Z" fill="url(#paint11_linear)" />
    <path d="M45 54H41C40.447 54 40 54.447 40 55C40 55.553 40.447 56 41 56H45C45.553 56 46 55.553 46 55C46 54.447 45.553 54 45 54Z" fill="url(#paint12_linear)" />
    <path d="M57 0H3C1.346 0 0 1.346 0 3V27V31C0 32.654 1.346 34 3 34H23.18L24.78 42H21C20.447 42 20 42.447 20 43C20 43.553 20.447 44 21 44H26H34H39C39.553 44 40 43.553 40 43C40 42.447 39.553 42 39 42H35.22L36.82 34H57C58.654 34 60 32.654 60 31V27V3C60 1.346 58.654 0 57 0ZM33.181 42H26.82L25.22 34H34.781L33.181 42ZM58 31C58 31.552 57.552 32 57 32H36H24H3C2.448 32 2 31.552 2 31V28H58V31ZM58 26H2V3C2 2.448 2.448 2 3 2H57C57.552 2 58 2.448 58 3V26Z" fill="url(#paint13_linear)" />
    <path d="M54 5H46C45.447 5 45 5.447 45 6C45 6.553 45.447 7 46 7H54C54.553 7 55 6.553 55 6C55 5.447 54.553 5 54 5Z" fill="url(#paint14_linear)" />
    <path d="M42 5H41C40.447 5 40 5.447 40 6C40 6.553 40.447 7 41 7H42C42.553 7 43 6.553 43 6C43 5.447 42.553 5 42 5Z" fill="url(#paint15_linear)" />
    <path d="M29.979 37.9989C30.5197 37.9989 30.958 37.5606 30.958 37.0199C30.958 36.4792 30.5197 36.0409 29.979 36.0409C29.4383 36.0409 29 36.4792 29 37.0199C29 37.5606 29.4383 37.9989 29.979 37.9989Z" fill="url(#paint16_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="60" y1="52.9903" x2="52" y2="52.9903" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="50" y1="52.9903" x2="5.44293e-07" y2="52.9903" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="7" y1="50.9986" x2="4" y2="50.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="7" y1="54.9986" x2="4" y2="54.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint4_linear" x1="13" y1="50.9986" x2="10" y2="50.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint5_linear" x1="31" y1="54.9986" x2="10" y2="54.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint6_linear" x1="19" y1="50.9986" x2="16" y2="50.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint7_linear" x1="25" y1="50.9986" x2="22" y2="50.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint8_linear" x1="31" y1="50.9986" x2="28" y2="50.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint9_linear" x1="37" y1="50.9986" x2="34" y2="50.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint10_linear" x1="37" y1="54.9986" x2="34" y2="54.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint11_linear" x1="45" y1="50.9986" x2="40" y2="50.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint12_linear" x1="46" y1="54.9986" x2="40" y2="54.9986" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint13_linear" x1="60" y1="21.9695" x2="6.53151e-07" y2="21.9695" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint14_linear" x1="55" y1="5.99861" x2="45" y2="5.99861" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint15_linear" x1="43" y1="5.99861" x2="40" y2="5.99861" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
      <linearGradient id="paint16_linear" x1="30.958" y1="37.0185" x2="29" y2="37.0185" gradientUnits="userSpaceOnUse">
        <stop offset="0.0416667" stopColor="#8C9CEF" />
        <stop offset="1" stopColor="#6C7DFF" />
      </linearGradient>
    </defs>
  </svg>
);
