import React, { forwardRef, useRef } from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  white,
  text01,
  activePrimary,
  primary,
} from '../../../../../common/constants/theme';
import useOutsideAlerter from '../../../../../common/utils/useOutsideAlerter.util';

const AdminDropdown = forwardRef(({
  isOpen,
  onClose,
  options,
  value,
  onSelect,
  position = 'right',
  optionClassName,
}, ref) => {
  const wrapperRef = useRef();
  if (ref) {
    useOutsideAlerter(ref, onClose);
  } else {
    useOutsideAlerter(wrapperRef, onClose);
  }

  return (
    <>
      {isOpen && (
        <Container position={position} ref={wrapperRef}>
          <Wrapper>
            {options.map((option) => (
              <Option
                key={option.key}
                selected={option.key === value?.key}
                onClick={() => onSelect(option)}
                className={optionClassName}
              >
                {option.title}
              </Option>
            ))}
          </Wrapper>
        </Container>
      )}
    </>
  );
});

export default AdminDropdown;

const Container = styled.div`
  min-width: 225px;
  padding-top: 13px;

  position: absolute;
  top: 43px;
  ${({ position }) => (position === 'right'
    ? 'right: -30px;'
    : 'left: -10px;')}
  z-index: 200;

  background: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.11));
  cursor: default;

  &::after,
  &::before {
    content: '';

    width: 0;
    height: 0;

    position: absolute;

    border-style: solid;
  }

  &::after {
    right: 19px;
    top: -23px;
    z-index: 12;

    border-width: 0 20.5px 23px 20.5px;
    border-color: transparent transparent ${white} transparent;
  }

  &::before {
    right: 18px;
    top: -24px;

    border-width: 0 21px 24px 21px;
    border-color: transparent transparent ${borderSecondary} transparent;
  }

  ${({ position }) => position !== 'right' && (`
    &::after {
      right: auto;
      left: 19px;
    }

    &::before {
      right: auto;
      left: 18px;
    }
  `)}
`;

const Wrapper = styled.div`
  max-height: 465px;

  overflow: auto;
`;

const Option = styled.div`
  display: flex;
  align-items: center;

  height: 28px;
  padding-left: 25px;

  cursor: pointer;

  font-family: Gilroy;
  font-size: 13px;
  font-weight: normal;
  color: ${text01};
  text-transform: none;
  letter-spacing: 0.16px;
  line-height: 17px;

  transition: color 0.1s, background-color 0.1s;

  &:hover {
    color: ${white};
    background-color: ${activePrimary};
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  ${({ selected }) => selected && (`
    background-color: ${primary};

    font-weight: 600;
    color: ${white};
  `)}
`;
