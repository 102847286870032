import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { secondary, text02 } from '@/common/constants/theme';

import Table from '../../../../../../../components/Table/Table';
import TableHead from '../../../../../../../components/Table/components/TableHead';
import TableBody from '../../../../../../../components/Table/components/TableBody';
import AdminTablePagination from '../../../../../../../components/Table/components/TablePagination';
import ArrowTable from '../../../../../../../../icons/ArrowTable';
import SearchIcon from '../../../../../../../../icons/SearchIcon';
import CloseIcon from '../../../../../../../../icons/CloseIcon';
import Loader from '@/common/components/Loader';

import {
  DATA_TYPES,
  useUsersMainContext,
} from '../../../../../MainContext';

import {
  SORT_DIRECTIONS,
  useAdminContext,
} from '../../../../../../../../../AdminContext';

const SIZES = ['80px', '200px', '900px'];

const SEARCH_FILTER_TIMEOUT = 1000 * 1.5;

const initDataFilter = {
  page: 1,
  offset: 0,
  limit: 15,
  q: '',
};

const MainUserLogs = () => {
  const {
    formatDateWithZone,
  } = useAdminContext();

  const {
    dataProcessing,
    getUserActivityLog,
  } = useUsersMainContext();

  const sorts = {
    asc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.asc],
    desc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.desc],
  };

  const [search, setSearch] = useState({ query: '' });
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [sortByDate, setSortByDate] = useState(SORT_DIRECTIONS.notSetted);

  const [data, setData] = useState({});
  const [dataFilter, setDataFilter] = useState(initDataFilter);

  const [page, setPage] = useState(initDataFilter.page);
  const [filterString, setFilterString] = useState('');

  const changeSortByDateDirection = () => {
    const directionsKeys = Object.keys(SORT_DIRECTIONS);
    const directions = Object.values(SORT_DIRECTIONS);
    const currentSortIndex = directions.findIndex((s) => s === sortByDate) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortByDate(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'dt_create';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setDataFilter((prevState) => ({
      ...prevState,
      ordering: `${sign}${field}`.trim(),
    }));
  };

  const getFilterString = (filterObject) => {
    const filterArr = [];
    if (filterObject.limit) { filterArr.push(`limit=${filterObject.limit}`); }
    if (filterObject.limit && filterObject.page) { filterArr.push(`offset=${(filterObject.page - 1) * filterObject.limit}`); }
    if (filterObject.q) { filterArr.push(`q=${filterObject.q}`); }
    return filterArr.join('&');
  };

  const getSearchString = (searchObject) => {
    const retValArray = [];
    // eslint-disable-next-line no-unused-vars
    Object.entries(searchObject).forEach(([key, value]) => {
      if ((value?.length || 0) > 0) { retValArray.push(value); }
    });
    return retValArray.join(' ').trim();
  };

  useEffect(() => {
    if (dataProcessing?.[DATA_TYPES.userStats]) { return; }

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(setTimeout(() => {
      const newQuery = getSearchString(search);
      if (newQuery !== (dataFilter?.q || '')) {
        setDataFilter((prevState) => (
          { ...prevState, q: getSearchString(search) }
        ));
        setPage(1);
      }
    }, SEARCH_FILTER_TIMEOUT));
  }, [search]);

  useEffect(() => {
    const newFilterString = getFilterString(dataFilter);
    if ((newFilterString.length > 0)
      && (newFilterString !== filterString)) {
      setFilterString(newFilterString);
      getUserActivityLog(newFilterString)
        .then((res) => {
          setData(res || {});
        });
    }
  }, [dataFilter]);

  useEffect(() => {
    setDataFilter((prevState) => ({ ...prevState, page }));
  }, [page]);

  return (
    <Container>
      {dataProcessing?.[DATA_TYPES.userStats] && <Loader />}
      <Table
        dataLength={data?.count || 0}
        limit={dataFilter.limit}
        page={dataFilter.page}
        cellSizes={SIZES}
        textAlign="left"
      >
        <TableHead editable={false}>
          <HeadItem flex>
            Дата
            <IconsWrapper
              onClick={() => changeSortByDateDirection()}
            >
              {sorts.asc.includes(sortByDate)
                && (
                  <IconSort isRotate>
                    <ArrowTable />
                  </IconSort>
                )}
              {sorts.desc.includes(sortByDate)
                && (
                  <IconSort leftMargin top>
                    <ArrowTable />
                  </IconSort>
                )}
            </IconsWrapper>
          </HeadItem>
          <HeadItem>
            IP-адрес
          </HeadItem>
          <HeadItem
            flex
            spaceBetween
          >
            <TextContainer>
              Событие
            </TextContainer>
            <SearchContainer>
              <Input
                value={search?.query || ''}
                onChange={({ target }) => setSearch({ ...search, query: target.value })}
                placeholder="Поиск..."
              />
              <Icon top>
                <SearchIcon />
              </Icon>
              {(search?.query || '').length > 0
                && (
                  <CloseIconContainer
                    onClick={() => setSearch({ ...search, query: '' })}
                  >
                    <CloseIcon />
                  </CloseIconContainer>
                )}
            </SearchContainer>
          </HeadItem>
        </TableHead>
        <TableBody
          editable={false}
          disableDragAndDrop
          isBottomRowBorder
          page={page}
          pageSize={dataFilter.limit}
          rowHeight={25}
          height={25 * dataFilter.limit}
        >
          {(data?.results || []).map((item) => (
            <div key={`MainUserLogs-row--${item.id}`}>
              <RowItem column>
                {item?.created_at && formatDateWithZone(item.created_at)}
              </RowItem>
              <RowItem>
                {item?.user_ip}
              </RowItem>
              <RowItem>
                {item?.message || ''}
              </RowItem>
            </div>
          ))}
        </TableBody>
        <AdminTablePagination
          page={page}
          pageCount={
            Math.ceil((data?.count || 0) / (dataFilter?.limit || 1))
          }
          setPage={setPage}
          recordsCount={data?.count || 0}
        />
      </Table>
    </Container>
  );
};

export default MainUserLogs;

const Container = styled.div`
  position: relative;
  padding: 14px 0 0 0;

  & .table-rows__row__item {
    &:first-child {
      min-width: 110px;

      color: ${text02};
    }

    &:last-child {
      margin-left: 20px;
    }
  }
`;

const IconsWrapper = styled.div`
  display: flex;

  width: 18px;
  margin-left: 10px;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;

  cursor: pointer;
  transform: rotate(${({ isRotate }) => (isRotate ? 180 : 0)}deg);

  transition: 0.2s ease;
`;

const IconSort = styled.div`
  ${({ leftMargin }) => leftMargin && 'margin-left: 2px;'}

  position: relative;
  ${({ top }) => top && 'top: 3px;'}

  cursor: pointer;
  ${({ isRotate }) => isRotate && 'transform: rotate(-180deg);'}`;

const HeadItem = styled.div`
  ${({ flex, spaceAround, spaceBetween }) => flex && `
    display: flex;
    align-items: center;
    ${(spaceAround ? 'justify-content: space-around;' : '')}
    ${(spaceBetween ? 'justify-content: space-between;' : '')}
    width: 100%;
  `}
`;

const TextContainer = styled.div`
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  max-width: 200px;
  padding-bottom: 4px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${secondary};
  outline: none;
`;

const RowItem = styled.div`
  ${({ flex }) => flex && `
    display: flex;
    justify-content: center;

    width: 100%;
  `}
  ${({ color }) => color && `
    color: ${color};
  `}
  ${({ bold }) => bold && `
    font-weight: bold;
  `}
`;
