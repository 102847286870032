/* eslint no-console: 0 */
import React from 'react';
import styled from 'styled-components';
import { grey } from '@/common/constants/theme';

import { useTranslation } from 'react-i18next';

const PromoCodeInfo = ({ selectedPromoCode }) => {
  const { t } = useTranslation(['translations']);

  const {
    id,
    code,
    count_transactions: tCount,
    max_transactions: tMax,
    is_active: isActive,
    discount,
  } = selectedPromoCode;

  return (
    <Container>
      <Wrapper flex width="170px" br>
        <TextItemContainer>
          <Text>
            {t('admin.iptv.promocodes.info.caption')}
            <br />
            {`ID [${id}]`}
          </Text>
        </TextItemContainer>
      </Wrapper>
      <Wrapper flex>
        <Wrapper flex>
          <TextItemContainer mr="2rem">
            <Label>{t('admin.iptv.promocodes.table.colCode')}</Label>
            <Text>{code}</Text>
          </TextItemContainer>
          <TextItemContainer mr="2rem">
            <Label>{t('admin.iptv.promocodes.table.colStatus')}</Label>
            <Text>{t(`admin.iptv.promocodes.${isActive ? 'isActiveShort' : 'isInactiveShort'}`)}</Text>
          </TextItemContainer>
          <TextItemContainer mr="2rem">
            <Label>{t('admin.iptv.promocodes.table.colType')}</Label>
            <Text>{code}</Text>
          </TextItemContainer>
          <TextItemContainer mr="2rem">
            <Label>{t('admin.iptv.promocodes.table.colDiscount')}</Label>
            <Text>{discount}</Text>
          </TextItemContainer>
        </Wrapper>
        <Wrapper flex itemsLeft>
          <TextItemContainer mr="2rem">
            <Label>{t('admin.iptv.promocodes.table.colExpired')}</Label>
            <Text>{code}</Text>
          </TextItemContainer>
          <TextItemContainer mr="2rem">
            <Label>{t('admin.iptv.promocodes.table.colTransactions')}</Label>
            <Text>{`${tCount} / ${tMax}`}</Text>
          </TextItemContainer>
        </Wrapper>
      </Wrapper>
    </Container>
  );
};

export default PromoCodeInfo;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  align-items: center;
  justify-content: ${({ itemsLeft }) => (itemsLeft ? 'start' : 'space-between')};
  width: ${({ width }) => (width || 'auto')};
  ${({ br }) => (br ? `border-right: solid 1px ${grey};` : '')}
  ${({ br }) => (br ? 'margin-right: 1rem;' : '')}
`;

const Container = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 1rem;
`;

const TextItemContainer = styled.div`
  width: ${({ width }) => (width || 'auto')};
  ${({ mr }) => (mr ? `margin-right: ${mr};` : '')}
`;

const Text = styled.div`
  display: inline-block;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 1.25em;
`;

const Label = styled(Text)`
  margin-left: .5rem;
  font-weight: bold;

  &::after{
    content: ':';
    margin: 0 .5rem 0 .25rem;
  }
`;
