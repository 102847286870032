/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import {
  background01, greyBorder, inputBorder,
  primary, secondary,
  text01, text02, greyText,
  white, red,
} from '@/common/constants/theme';

import DeleteModal from '../../Modals/DeleteModal';

import DeleteIcon from '../../icons/DeleteIcon';
import LinkIcon from '../../icons/LinkIcon';

import { useAppContext } from '@/AppContext';

const TemplateImagesBlock = ({
  dataBlock, options,
  items, handleUpload, handleDelete,
}) => {
  const { copyText } = useAppContext();

  const imgBlockRef = useRef(null);

  const [confirmModal, setConfirmModal] = useState(null);
  const [imgBlockHeight, setImgBlockHeight] = useState(300);

  const handleCopyLink = (url) => copyText(url);

  useEffect(() => {
    if (!dataBlock || !imgBlockRef) { return; }
    setImgBlockHeight(dataBlock.current.getBoundingClientRect().bottom
      - imgBlockRef.current.getBoundingClientRect().top);
  }, [dataBlock?.current?.getBoundingClientRect(), imgBlockRef]);
  return (
    <>
      <DeleteModal
        content={confirmModal?.title || ''}
        color="danger"
        isOpen={confirmModal}
        onClose={() => setConfirmModal(null)}
        handleDelete={confirmModal?.submitAction}
      />
      <Container
        isVertical={!!options?.isVertical}
        width={options?.width || '150px'}
      >
        <Caption>Картинки шаблона</Caption>
        <Actions>
          <AddImage
            onClick={handleUpload}
          >
            Загрузить
          </AddImage>
        </Actions>
        <Images
          height={imgBlockHeight}
          ref={imgBlockRef}
          isVertical={!!options?.isVertical}
        >
          {(items || []).map((itm) => (
            <ImageContainer
              key={`A-ET-Imgs-I--${itm.id}`}
            >
              <ImageActions className="imageActions">
                <ImageAction
                  title="Скопировать ссылку"
                  onClick={() => handleCopyLink(itm.image)}
                >
                  <LinkIcon
                    width="30px"
                    height="30px"
                  />
                </ImageAction>
                {handleDelete && (
                  <ImageAction
                    title="Удалить"
                    onClick={() => setConfirmModal({
                      title: 'Подтвердите удаление изображения',
                      submitAction: () => handleDelete(itm.id),
                    })}
                  >
                    <DeleteIcon
                      width="20px"
                      height="25px"
                    />
                  </ImageAction>
                )}
              </ImageActions>
              <ImageContent
                href={itm.image}
                target="_blank"
              >
                <img src={itm.image} title={itm.image} alt={itm.image} />
              </ImageContent>
            </ImageContainer>
          ))}
        </Images>
      </Container>
    </>
  );
};

export default TemplateImagesBlock;

const Container = styled.div`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
  width: ${({ width }) => width || '100%'};
`;

const Caption = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const Actions = styled.div`
  display: flex;
`;

const AddImage = styled.a`
  display: inline-block;
  width: 100%;
  border-bottom: solid 1px ${greyBorder};
  padding: 0 0 0.25em 0.25em;
`;

const Images = styled.div`
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  margin-top: 1rem;
  overflow-y: auto;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 35px;

  &:not(:hover) {
    .imageActions {
      display: none;
    }
  }

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ImageActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  border-radius: 0 0 0 .5rem;
  padding: 3px 3px 5px 5px;
  font-size: 0;
  background: rgba(255, 255, 255, .75);
`;

const ImageAction = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: 0.5;
  transition: all 0.15s ease-out 0s;

  &:not(:last-child) {
    margin-right: .25rem;
  }

  &:hover {
    opacity: 1;
  }
`;

const ImageContent = styled.a`
  display: block;
  font-size: 0;
  width: 100%;

  img {
    max-width: 100%;
  }
`;
