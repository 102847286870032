import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  secondary, white, text01, red,
} from '@/common/constants/theme';
import Loader from '@/common/components/Loader';
import AuthorizationLayout from '../components/AuthorizationLayout';
import TitleWithOrange from '../components/TitleWithOrange';
import { confirmDeleteUserAction } from '@/services/actions/profile.actions';
import auth from '@/services/auth.service';

export default function ConfirmDeleteUserContainer({ history, match }) {
  const { t } = useTranslation(['translations']);
  const [errors, setErrors] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);

  const defaultTexts = () => ({
    firstPart: t('confirm.deleteUser.textBlack1'),
    orangePart: t('confirm.deleteUser.textOrange'),
    secondPart: t('confirm.deleteUser.textBlack2'),
    text: t('confirm.deleteUser.textConfirm'),
    button: t('confirm.deleteUser.button'),
  });

  const [texts, setTexts] = useState(defaultTexts);

  const successUrl = '/';

  const handleConfirmDeleteUser = () => {
    setLoading(true);
    const { token } = match.params;
    confirmDeleteUserAction(token)
      .then(() => {
        setTexts({
          firstPart: t('confirm.deleteUser.textBlack1'),
          orangePart: t('confirm.deleteUser.textOrange'),
          secondPart: t('confirm.deleteUser.textBlack2'),
          text: t('confirm.deleteUser.textSuccess'),
          button: t('confirm.deleteUser.button'),
        });
        setIsDeleteConfirmed(true);
        auth.clearToken();
        setTimeout(() => {
          history.push(successUrl);
          window.location.replace(successUrl);
        }, 15000);
        setLoading(false);
      })
      .catch((err) => {
        setErrors(`${t(`confirm.deleteUser.errors.${err?.response?.payload?.message_type || err?.data?.message_type}`)}`);
        setLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <AuthorizationLayout>
        <TitleWithOrange
          textBlack1={texts.firstPart}
          textOrange={texts.orangePart}
          textBlack2={texts.secondPart}
        />
        <Form>
          <Text>{texts.text}</Text>
          {!isDeleteConfirmed && (
            <>
              <Button onClick={handleConfirmDeleteUser}>
                {t('confirm.deleteUser.button')}
              </Button>
              <div style={{ color: red }}>{ errors }</div>
            </>
          )}
        </Form>
      </AuthorizationLayout>
    </>
  );
}

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.2em;
  color: ${text01};
  white-space: pre-wrap;
  margin-bottom: 1rem;
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 8rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const Button = styled.button`
  margin: .5rem 0;
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
