import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  white,
  secondary,
  inputDisabled, text03,
} from '@/common/constants/theme';

import ModalNew from '@/common/components/ModalNew';
import CellPhone from '@/assets/icons/cell-phone.svg';

import { usePersonalPageContext } from '../../../../../PersonalPageContext';

const CONFIGURE_MODES = {
  configure: 'configure',
  unConfigure: 'unConfigure',
};

const CODE_MAX_LEN = 6;

export default function DeviceConfigureDialog({
  isVisible, toggleVisibility, dialogData,
}) {
  const { t } = useTranslation('translations');

  const {
    selectedDevices,
    setDevicesAppCode, clearDevicesAppCode,
  } = usePersonalPageContext();

  const [mode, setMode] = useState(CONFIGURE_MODES.configure);
  const [code, setCode] = useState('');
  const [isCodeEntered, setIsCodeEntered] = useState(false);

  const handleCodeInputChange = (v) => {
    const useCode = v.replace(/[^a-zA-Z0-9]/gi, '').substring(0, CODE_MAX_LEN).toUpperCase();
    setCode(useCode);
    setIsCodeEntered(useCode.length === CODE_MAX_LEN);
  };

  const onClose = () => {
    toggleVisibility();
  };

  const handleSubmit = () => {
    switch (mode) {
      case CONFIGURE_MODES.configure:
        setDevicesAppCode([dialogData.device], code)
          .finally(() => onClose());
        break;
      case CONFIGURE_MODES.unConfigure:
        if (dialogData.device) {
          clearDevicesAppCode([dialogData.device])
            .finally(() => onClose());
        } else if ((selectedDevices || []).length > 0) {
          clearDevicesAppCode(selectedDevices)
            .finally(() => onClose());
        }
        break;
      default:
        onClose();
        break;
    }
  };

  useEffect(() => {
    if (dialogData.device) {
      setMode(
        dialogData.device.app_status !== 'configured'
          ? CONFIGURE_MODES.configure
          : CONFIGURE_MODES.unConfigure,
      );
    } else {
      setMode(CONFIGURE_MODES.unConfigure);
    }
  }, [dialogData?.device?.app_status]);

  useEffect(() => {
    setCode('');
    setIsCodeEntered(false);
  }, [dialogData?.device?.id]);

  return (
    <ModalNew
      isOpen={isVisible}
      icon={CellPhone}
      title={t(`devicesContent.appConfigureWindow.${mode}.${dialogData?.device ? 'title' : 'titleMulti'}`)}
      onCloseModal={onClose}
      centered
    >
      {t(`devicesContent.appConfigureWindow.${mode}.${dialogData.device ? 'description' : 'descriptionMulti'}`)}
      <Actions
        withCodeInput={mode === CONFIGURE_MODES.configure}
      >
        {(mode === CONFIGURE_MODES.configure) && (
          <CodeInput
            value={code}
            onChange={({ target }) => handleCodeInputChange(target.value)}
          />
        )}
        <Button
          disabled={(mode === CONFIGURE_MODES.configure) && !isCodeEntered}
          onClick={handleSubmit}
        >
          {t(`devicesContent.appConfigureWindow.${mode}.submit`)}
        </Button>
      </Actions>
    </ModalNew>
  );
}

const CodeInput = styled.input`
  font-size: 180%;
  padding: 0 0.5em;
  min-width: 3em;
  max-width: 7em;
  outline: none;
  border: none;
  border-bottom: solid grey 1px;
  text-align: center;
`;

const Button = styled.button`
  border: none;
  background: ${({ disabled }) => (disabled ? inputDisabled : secondary)};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ disabled }) => (disabled ? text03 : white)};
  padding: .75rem 1rem;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ withCodeInput }) => (withCodeInput ? 'space-between' : 'flex-end')};
  margin-top: .5rem;
`;
