import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { secondary, text01 } from '@/common/constants/theme';
import AuthorizationLayout from './components/AuthorizationLayout';

function NotFoundPage() {
  const { t } = useTranslation(['translations']);

  return (
    <AuthorizationLayout>
      <Title>
        <TitleText>{t('page')}</TitleText>
        <TitleTextOrange>{t('notFound')}</TitleTextOrange>
        <Form />
      </Title>
    </AuthorizationLayout>
  );
}

export default withRouter(NotFoundPage);

const Title = styled.div`
  margin-top: 10rem;
  width: 40rem;
`;

const TitleText = styled.span`
  font-family: Gilroy;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;
`;

const TitleTextOrange = styled(TitleText)`
  color: ${secondary}
`;

const Form = styled.div`
  min-height: 20rem;
  width: 28rem;
`;
