import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  background01, borderSecondary,
  text01,
} from '@/common/constants/theme';
import UsersGroupsMainList from './components/GroupsMainList';
import UsersGroupsMainInfo from './components/GroupsMainInfo';
import { useUsersGroupsContext } from '../../GroupsContext';
import UsersGroupsMainInfoWindow from './components/GroupsMainInfoWindow';
import UsersGroupsMainTable from './components/GroupsMainTable';

const NEW_GROUP_NAME_MIN_LEN = 3;
const NEW_GROUP_HINTS = {
  notSet: '',
  minLen: `Минимальная длина названия ${NEW_GROUP_NAME_MIN_LEN} символов`,
  exists: 'Группа с таким именем уже существует',
};

const UsersGroupsMain = ({ isAddNewGroup, setIsAddNewGroup }) => {
  const {
    groups,
    selectedGroup,
    addUserGroup,
    addUserToGroup,
  } = useUsersGroupsContext();
  const [email, setEmail] = useState('');
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupHint, setNewGroupHint] = useState(NEW_GROUP_HINTS.notSet);

  const isEmailCorrect = () => new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    .test((email || '').toLowerCase());

  const isGroupNameCorrect = () => (
    (newGroupName.length >= NEW_GROUP_NAME_MIN_LEN)
    && groups.filter((g) => g.title === newGroupName).length === 0
  );

  useEffect(() => {
    let newHint = NEW_GROUP_HINTS.notSet;
    if (newGroupName.length < NEW_GROUP_NAME_MIN_LEN) {
      newHint = NEW_GROUP_HINTS.minLen;
    } else if (groups.filter((g) => g.title === newGroupName).length > 0) {
      newHint = NEW_GROUP_HINTS.exists;
    }
    setNewGroupHint(newHint);
  }, [newGroupName]);

  return (
    <Container>
      <Wrapper>
        <UsersGroupsMainList />
        <UsersGroupsMainInfo isOpen={selectedGroup} group={selectedGroup} />
        {selectedGroup && !isAddNewGroup && (
        <UsersGroupsMainInfoWindow
          title="Добавить"
          color="success"
          buttonTitle="Добавить в группу"
          isButtonDisabled={!isEmailCorrect()}
          onSubmit={() => { addUserToGroup(email, selectedGroup.id || 0); setEmail(''); }}
        >
          <Text>
            EMAIL пользователя
          </Text>
          <Input
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            placeholder="test@gmail.com"
          />
        </UsersGroupsMainInfoWindow>
        )}
        {isAddNewGroup && (
        <UsersGroupsMainInfoWindow
          title="Новая группа"
          color="secondary"
          buttonTitle="Создать группу"
          buttonHint={newGroupHint}
          isButtonDisabled={!isGroupNameCorrect()}
          isClosable
          onSubmit={() => { addUserGroup(newGroupName); }}
          onClose={() => { setIsAddNewGroup(false); setNewGroupName(''); }}
        >
          <Input
            value={newGroupName}
            onChange={({ target }) => setNewGroupName(target.value)}
            placeholder="Название"
          />
        </UsersGroupsMainInfoWindow>
        )}
      </Wrapper>
      {selectedGroup && (
        <UsersGroupsMainTable />
      )}
    </Container>
  );
};

export default UsersGroupsMain;

const Container = styled.div`
  padding: 20px 20px 40px;

  background: ${background01};
  border-radius: 2px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  margin-bottom: 40px;
`;

const Text = styled.div`
  margin-bottom: 12px;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  line-height: 15px;
`;

const Input = styled.input`
  height: 32px;
  padding: 0 10px;

  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  outline: none;
`;
