import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  primary, text01,
} from '@/common/constants/theme';

import PageContentBlock from '../components/PageContentBlock';
import SupportModals from './Components/SupportModals';
import ThreadsList from './Components/ThreadsList';

import CloseIcon from '../../icons/CloseIcon';

import { usePersonalPageContext } from '../../../PersonalPageContext';
import { SupportContextProvider } from './SupportContext';

const PAGE_ID = 'support';

const SupportContainer = () => {
  const { t } = useTranslation('translations');

  const { isPageHeaderDisabled, handleClosePageHeader } = usePersonalPageContext();

  return (
    <SupportContextProvider>
      <SupportModals />
      <Container>
        {!isPageHeaderDisabled?.[PAGE_ID] && (
          <PageContentBlock isAutoHeight>
            <Title>{t('personalPage.support.title')}</Title>
            <SubTitle>{t('personalPage.support.subTitle')}</SubTitle>
            <CloseBlock
              title={t('personalPage.hide')}
              onClick={() => handleClosePageHeader(PAGE_ID)}
            >
              <CloseIcon />
            </CloseBlock>
          </PageContentBlock>
        )}
        <Content>
          <SupportWrapper>
            <ThreadsList />
          </SupportWrapper>
        </Content>
      </Container>
    </SupportContextProvider>
  );
};

export default SupportContainer;

const Container = styled.div`
  margin: 1.25rem 0 20px 0;
`;

const SubTitle = styled.div`
  font-family: Gilroy, sans-serif;
  margin-bottom: .75rem;
  font-size: 15px;
  line-height: 20px;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.6;
  color: ${text01};
  position: relative;
  margin-bottom: 33px;

  &:after {
    content: '';
    height: 4px;
    position: absolute;
    width: 30px;
    bottom: -10px;
    left: 0;
    background: ${primary};
  }
`;

const CloseBlock = styled.div`
  position: absolute;
  cursor: pointer;
  top: .8rem;
  right: .8rem;

  & svg{
    width: 12px;
    height: 12px;

    path {
      stroke: red;
    }
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
`;

const SupportWrapper = styled.div`
  flex: 1;
`;
