import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getPaymentSystems, getWithdrawalSystems } from '@/store/admin/selectors';
import { setPaymentSystems } from '@/store/admin/actions/common';

import {
  useAdminContext,
} from '../../../../AdminContext';

import {
  createPaymentSystemAction,
  changePaymentSystemAction,
  deletePaymentSystemAction,
  onOffPaymentSystemAction,
  hideShowPaymentSystemAction,
  getPaymentSystemAction,
  getWithdrawalSystemByIdAction,
  createWithdrawalSystemAction,
  changeWithdrawalSystemAction,
  deleteWithdrawalSystemAction,
} from '@/services/actions/admin/finance.actions';

// import pageSizes from '@/common/constants/pageSizes';

export const NEW_PAYSYSTEM_ID = 'create';

const StatisticAggregatorsContext = createContext({});

const initialFilters = {
  page: 1,
  limit: localStorage.getItem('admin-paySystems-limit') || 100,
};

export const StatisticAggregatorsContextProvider = ({ children }) => {
  const [isInit, setIsInit] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [isBusy, setIsBusy] = useState(false);
  const [psCount, setPsCount] = useState(0);
  const [isWInit, setIsWInit] = useState(true);
  const [wFilters, setWFilters] = useState(initialFilters);
  const [wsCount, setWsCount] = useState(0);

  const paymentSystems = useSelector(getPaymentSystems);
  const withdrawalSystems = useSelector(getWithdrawalSystems);

  const dispatch = useDispatch();
  const {
    SnackTypes,
    setAdminSnack,
    loadPaymentSystems,

    loadWithdrawalSystems,
  } = useAdminContext();

  const updateLocalPaysystemsList = (psId = 0, data = {}) => {
    if (psId <= 0) return;

    const updatedPsList = [...paymentSystems || []];
    const index = updatedPsList.findIndex((ps) => ps.id === psId);
    if (index < 0) return;

    updatedPsList.splice(index, 1, { ...updatedPsList[index], ...data });

    dispatch(setPaymentSystems(updatedPsList));
  };

  const getPaymentSystem = (id) => {
    setIsBusy(true);
    return getPaymentSystemAction(id)
      .then((res) => {
        if (!res?.id) { setAdminSnack({ content: 'Ошибка при получении платежной системы', type: SnackTypes.error }, SnackTypes.error); }
        return res;
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => setIsBusy(false));
  };

  const changePaySystem = (id, data) => {
    setIsBusy(true);
    return changePaymentSystemAction(id, data)
      .then((res) => {
        const snack = {
          content: res
            ? 'Система пополнения успешно изменена'
            : 'Система пополнения не изменена',
          type: res ? SnackTypes.success : SnackTypes.error,
        };
        if (res) {
          const { id: resId, ...resData } = res;
          updateLocalPaysystemsList(resId, resData);
        }
        setAdminSnack(snack);
        return res;
      })
      .catch((err) => { setAdminSnack(err); setIsBusy(false); });
  };

  const onOffPaySystem = (id, psState) => {
    setIsBusy(true);
    return onOffPaymentSystemAction(id, psState)
      .then((res) => {
        const snack = {
          content: `Система пополнения ${res ? '' : 'не '} ${psState ? 'включена' : 'отключена'}`,
          type: res ? SnackTypes.success : SnackTypes.error,
        };
        if (res) {
          loadPaymentSystems(filters)
            .then((resgd) => setPsCount(resgd?.count || 0))
            .finally(() => setIsBusy(false));
        }
        setAdminSnack(snack);
        return res;
      })
      .catch((err) => { setAdminSnack(err, SnackTypes.error); setIsBusy(false); });
  };

  const hideShowPaySystem = (id, psState) => {
    setIsBusy(true);
    return hideShowPaymentSystemAction(id, psState)
      .then((res) => {
        const snack = {
          content: `Система пополнения ${res ? '' : 'не '}${psState ? 'показана' : 'скрыта'}`,
          type: res ? SnackTypes.success : SnackTypes.error,
        };
        if (res) {
          loadPaymentSystems(filters)
            .then((resgd) => setPsCount(resgd?.count || 0))
            .finally(() => setIsBusy(false));
        }
        setAdminSnack(snack);
        return res;
      })
      .catch((err) => { setAdminSnack(err, SnackTypes.error); setIsBusy(false); });
  };

  const createPaySystem = (data) => {
    setIsBusy(true);
    return createPaymentSystemAction(data)
      .then((res) => {
        const snack = {
          content: res?.id
            ? 'Система пополнения успешно создана'
            : 'Система пополнения не создана',
          type: res?.id ? SnackTypes.success : SnackTypes.error,
        };
        if (res?.id) {
          loadPaymentSystems(filters)
            .then((resgd) => setPsCount(resgd?.count || 0))
            .finally(() => setIsBusy(false));
        }
        setAdminSnack(snack);
        return res;
      })
      .catch((err) => { setAdminSnack(err, SnackTypes.error); setIsBusy(false); return err; });
  };

  const deletePaySystem = (id) => {
    setIsBusy(true);
    return deletePaymentSystemAction(id)
      .then((res) => {
        const snack = {
          content: res
            ? 'Система пополнения успешно удалена'
            : 'Система пополнения не удалена',
          type: res ? SnackTypes.success : SnackTypes.error,
        };
        if (res) {
          loadPaymentSystems(filters)
            .then((resgd) => setPsCount(resgd?.count || 0))
            .finally(() => setIsBusy(false));
        }
        setAdminSnack(snack);
        return res;
      })
      .catch((err) => { setAdminSnack(err); setIsBusy(false); });
  };

  const loadPS = () => {
    setIsBusy(true);
    loadPaymentSystems(filters)
      .then((res) => { setPsCount(res?.count || 0); })
      .finally(() => setIsBusy(false));
  };

  const getWithdrawalSystem = (id) => {
    setIsBusy(true);
    return getWithdrawalSystemByIdAction(id)
      .then((res) => {
        if (!res?.id) { setAdminSnack({ content: 'Ошибка при получении системы вывода', type: SnackTypes.error }, SnackTypes.error); }
        return res;
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => setIsBusy(false));
  };

  const changeWithdrawalSystem = (id, data) => {
    setIsBusy(true);
    return changeWithdrawalSystemAction(id, data)
      .then((res) => {
        const snack = {
          content: res
            ? 'Система вывода успешно изменена'
            : 'Система вывода не изменена',
          type: res ? SnackTypes.success : SnackTypes.error,
        };
        if (res.results) {
          loadWithdrawalSystems(wFilters)
            .then((resgd) => setWsCount(resgd?.count || 0))
            .finally(() => setIsBusy(false));
        }
        setAdminSnack(snack);
        return res;
      })
      .catch((err) => { setAdminSnack(err); setIsBusy(false); });
  };

  const createWithdrawalSystem = (data) => {
    setIsBusy(true);
    return createWithdrawalSystemAction(data)
      .then((res) => {
        const snack = {
          content: res?.id
            ? 'Система вывода успешно создана'
            : 'Система вывода не создана',
          type: res?.id ? SnackTypes.success : SnackTypes.error,
        };
        if (res?.id) {
          loadWithdrawalSystems(wFilters)
            .then((resgd) => setWsCount(resgd?.count || 0))
            .finally(() => setIsBusy(false));
        }
        setAdminSnack(snack);
        return res;
      })
      .catch((err) => { setAdminSnack(err, SnackTypes.error); setIsBusy(false); return err; });
  };

  const deleteWithdrawalSystem = (id) => {
    setIsBusy(true);
    return deleteWithdrawalSystemAction(id)
      .then((res) => {
        const snack = {
          content: res
            ? 'Система вывода успешно удалена'
            : 'Система вывода не удалена',
          type: res ? SnackTypes.success : SnackTypes.error,
        };
        if (res) {
          loadWithdrawalSystems(wFilters)
            .then((resgd) => setWsCount(resgd?.count || 0))
            .finally(() => setIsBusy(false));
        }
        setAdminSnack(snack);
        return res;
      })
      .catch((err) => { setAdminSnack(err); setIsBusy(false); });
  };

  const loadWS = () => {
    setIsBusy(true);
    loadWithdrawalSystems(wFilters)
      .then((res) => { setWsCount(res?.count || 0); })
      .finally(() => setIsBusy(false));
  };

  useEffect(() => {
    if (!paymentSystems) { loadPS(); setIsInit(true); }
  }, [paymentSystems]);

  useEffect(() => {
    if (filters
      && isInit
      && !isBusy
    ) { loadPS(); }
  }, [filters]);

  useEffect(() => {
    if (!withdrawalSystems) { loadWS(); setIsWInit(true); }
  }, [withdrawalSystems]);

  useEffect(() => {
    if (wFilters
      && isWInit
      && !isBusy
    ) { loadWS(); }
  }, [wFilters]);

  const value = {
    psCount,
    paymentSystems,
    filters,
    setFilters,

    wsCount,
    withdrawalSystems,
    wFilters,
    setWFilters,

    isBusy,
    setIsBusy,

    getPaymentSystem,
    createPaySystem,
    changePaySystem,
    deletePaySystem,
    hideShowPaySystem,
    onOffPaySystem,

    getWithdrawalSystem,
    createWithdrawalSystem,
    changeWithdrawalSystem,
    deleteWithdrawalSystem,
  };

  return (
    <StatisticAggregatorsContext.Provider value={value}>
      {children}
    </StatisticAggregatorsContext.Provider>
  );
};

export const useStatisticAggregatorsContext = () => useContext(StatisticAggregatorsContext);
