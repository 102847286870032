import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  text01,
  red,
  green,
  greyBorder, primary,
} from '@/common/constants/theme';

import CloseIcon from '@/common/components/icons/CloseIcon';

import { useSubscriptionContext } from '../SubscriptionContext';

export default () => {
  const [promoInputTimeout, setPromoInputTimeout] = useState(null);
  const [localPromoValue, setLocalPromoValue] = useState('');

  const { t } = useTranslation('translations');

  const {
    personalDiscount,
    isPromoActivated,
    isPromoChecking,
    handleSubmitPromo,
    setPromoCode,
    promoCode,
    promoError,
    discountText,
    clearPromo,
  } = useSubscriptionContext();

  const promoInputCheckDelay = 1000 * 1.5;

  const promoChanging = (v) => {
    setPromoCode(v);
    setLocalPromoValue(v);
    if (v === '') {
      clearTimeout(promoInputTimeout);
      clearPromo();
      return;
    }
    if (promoInputTimeout) {
      clearTimeout(promoInputTimeout);
    }
    setPromoInputTimeout(setTimeout(() => {
      handleSubmitPromo(v);
    }, promoInputCheckDelay));
  };

  useEffect(() => {
    if (promoCode === '') {
      setLocalPromoValue('');
    }
  }, [promoCode]);

  return personalDiscount === 0 ? (
    <PromoRow>
      {isPromoActivated && (
        <CloseContainer
          title={t('steps.step2.removePromoText')}
          onClick={() => { setLocalPromoValue(''); clearPromo(); }}
        >
          <CloseIcon />
        </CloseContainer>
      )}
      <PromoInput
        disabled={isPromoChecking || isPromoActivated}
        placeholder={t('subscriptionsContent.enterPromoCode')}
        onKeyUp={({ target }) => promoChanging(target.value)}
        onChange={({ target }) => promoChanging(target.value)}
        success={isPromoActivated}
        value={localPromoValue}
      />
      <PromoResult>
        {promoError && (
          <Text isError>
            {promoError}
          </Text>
        )}
        {isPromoActivated && (
          <>
            <Text>
              {t('subscriptionsContent.promoCodeIsActivated')}
            </Text>
            <Text isResult>
              {`${t('subscriptionsContent.yourDiscount')}: ${discountText}`}
            </Text>
          </>
        )}
      </PromoResult>
    </PromoRow>
  ) : (
    <PromoRow>
      <PersonalDiscountText>
        {t('subscriptionsContent.activePersonalDiscount')}
        <PersonalDiscountValue>{`${personalDiscount}%`}</PersonalDiscountValue>
      </PersonalDiscountText>
    </PromoRow>
  );
};

const PromoRow = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 0;
  left: 8rem;
  cursor: pointer;
  opacity: .5;
  transition: all .1s ease-in 0s;
  z-index: 1;

  &:hover{
    opacity: 1;
  }
`;

const PromoInput = styled.input`
    background: transparent;
    border: 1px dashed ${({ success, disabled }) => {
    let borderColor = '#5c70cf80';
    if (success || disabled) {
      borderColor = success ? green : greyBorder;
    }
    return borderColor;
  }};
    border-radius: 3px;
    outline: none;
    height: 2.5rem;
    width: 9rem;
    padding: 12px;
    padding-bottom: 14px;
    ::placeholder {
        font-family: Gilroy, sans-serif;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.16px;
        color: ${text01};
    }
    ${({ success }) => success && `
        border-top: 2px solid ${green};
    `}
`;

const PromoResult = styled.div`
    margin-left: 0.75rem;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.16px;
`;

const Text = styled.span`
    color: ${({ isError }) => (isError ? red : green)};
    ${({ isResult }) => (isResult ? 'font-size: 16px; font-weight: bold;' : '')}
`;

const PersonalDiscountText = styled(Text)`
  color: ${primary};
`;
const PersonalDiscountValue = styled(PersonalDiscountText)`
  display: inline-block;
  margin-left: .25em;
  font-weight: bold;
  font-size: 1.2em;
`;
