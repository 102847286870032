import React from 'react';
import {
  setBlockData,
  getSelectedBlocksMetadata,
} from 'draftjs-utils';

import ToolbarButton from './ToolbarButton';
import AlignLeftIcon from '@/assets/icons/TextEditor/align-left.svg';
import AlignCenterIcon from '@/assets/icons/TextEditor/align-center.svg';
import AlignRightIcon from '@/assets/icons/TextEditor/align-right.svg';
import AlignJustifyIcon from '@/assets/icons/TextEditor/align-justify.svg';

const BLOCK_ALIGNMENT_STYLES = [
  { label: 'Слева', style: 'left', icon: AlignLeftIcon },
  { label: 'По центру', style: 'center', icon: AlignCenterIcon },
  { label: 'Справа', style: 'right', icon: AlignRightIcon },
  { label: 'По ширине', style: 'justify', icon: AlignJustifyIcon },
];

const BlockAlignmentStyleControls = ({
  editorState,
  onEditorStateChange,
}) => {
  const currentTextAlignment = getSelectedBlocksMetadata(editorState).get(
    'text-align',
  );

  const toggleAlignmentType = (value) => {
    if (currentTextAlignment !== value) {
      onEditorStateChange(setBlockData(editorState, { 'text-align': value }));
    } else {
      onEditorStateChange(setBlockData(editorState, { 'text-align': undefined }));
    }
  };

  return (
    <>
      {BLOCK_ALIGNMENT_STYLES.map((type) => (
        <ToolbarButton
          className="rdw-option-wrapper"
          key={type.style}
          active={type.style === currentTextAlignment}
          label={type.label}
          icon={type.icon}
          onToggle={() => { toggleAlignmentType(type.style); }}
        />
      ))}
    </>
  );
};

export default BlockAlignmentStyleControls;
