import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AlertIcon from '../../../../../../assets/icons/alertIcon.svg';
import ModalNew from '../../../../../../common/components/ModalNew';
import {
  text01,
} from '@/common/constants/theme';
import Input from '../../../../../../common/components/Input';
import Button from '../../../../../../common/components/Button';

const ReferralsModalTransfer = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation('translations');

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue('');
  }, [isOpen]);

  const checkIsValid = () => (inputValue > 0);

  const submitHandler = () => {
    onSubmit(inputValue);
  };

  const closeHandler = () => {
    onClose();
  };

  return (
    <ModalNew
      isOpen={isOpen}
      onCloseModal={closeHandler}
      title={t('referrals.modal.transferTitle')}
      icon={AlertIcon}
      isHidden={false}
    >
      <Wrapper bottomMargin>
        <Text bottomMargin>
          {t('referrals.modal.enterSum')}
        </Text>
        <FlexWrapper>
          <Wrapper>
            <StyledInput
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              height="39px"
              width={120}
              placeholder="0"
            />
          </Wrapper>
        </FlexWrapper>
      </Wrapper>
      <FlexWrapper>
        <StyledButton
          isBoldText
          isUpperCase
          isDisabled={!checkIsValid()}
          onClick={submitHandler}
        >
          {t('referrals.modal.transfer')}
        </StyledButton>
      </FlexWrapper>
    </ModalNew>
  );
};

export default ReferralsModalTransfer;

const Wrapper = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 29px;')};
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 7px;')};

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-weight: normal;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const StyledInput = styled(Input)`
  width: ${({ width }) => `${width}px`};
`;

const StyledButton = styled(Button)`
  width: 120px;
  margin-left: auto;
  margin-top: 36px;
  margin-bottom: 18px;

  ${({ isDisabled }) => isDisabled && (`
    background-color: ${text01};
    opacity: 0.2;

    &:hover {
      background-color: ${text01};
      opacity: 0.2;
    }

    &:active {
      background-color: ${text01};
      opacity: 0.2;
    }
  `)}
`;
