import React from 'react';
import styled from 'styled-components';
import { SortableElement } from 'react-sortable-hoc';

import { background01, borderSecondary, white } from '../../../../../../../common/constants/theme';

const AdminTableBodyItem = SortableElement(({
  children,
  width,
  onDoubleClick,
  index,
}) => (
  <Container
    width={width}
    onDoubleClick={onDoubleClick && onDoubleClick}
    isOdd={index % 2 === 1}
    className="admin-table-body-item"
  >
    {children}
  </Container>
));

export default AdminTableBodyItem;

const Container = styled.div`
  display: flex;

  width: fit-content;
  min-width: 100%;
  height: 42px;
  padding-left: 23px;
  padding-right: 20px;

  ${({ isOdd }) => (isOdd ? `background-color: ${background01};` : `background-color: ${white};`)}
  border-bottom: 1px solid ${borderSecondary};
`;
