import RequestService from '@/services/request.service';
import urls from '@/services/routes/admin/promocodes.api';

export const getPromocodesListAction = (filters) => RequestService(`${urls.promocodesList}${filters}`);

export const getPromocodeAction = (id) => RequestService(urls.promocode(id));

export const createPromoCodeAction = (data) => RequestService(urls.promocodesList, {
  method: 'POST',
  body: data,
});

export const blockPromoCodeAction = (id, state) => RequestService(`${urls.promocode(id)}${state ? 'enable' : 'disable'}`, { method: 'POST' });

export const deletePromoCodeActions = (id) => RequestService(urls.promocode(id), { method: 'DELETE' });

export const getTransactionsAction = (id, filters) => RequestService(`${urls.promocode(id)}transactions${filters}`);
