/* eslint-disable no-shadow */
import React, {
  useCallback,
  createContext, useContext,
  useState, useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';
import moment from 'moment-timezone';
import qs from 'qs';
import { useTranslation } from 'react-i18next';

import auth from './services/auth.service';

import {
  getSiteLanguagesAction,
  getSystemSettingsAction,
} from './services/actions/app.actions';
import {
  getUserAction,
  refreshTokenAction,
} from './services/actions/authorization.actions';

import { longAction } from '@/services/actions/longAction.actions';
import { postCopyrightHolderMessageAction } from '@/services/actions/copyright.actions';

import {
  TOKEN_KEY,
  TOKEN_EXPIRED_KEY,
  LIMIT,
  SELECTED_DEVICES,
  DEVICES_LIST_LIMIT,
  DISABLED_PAGE_HEADERS,
  PASSWORD_PIN_CHECK_COUNT,
} from '@/store/constants';

import langsMockData from '@/langsMockData';

const USER_LAST_URL_KEY = 'userLastUrl';

export const SIGN_IN_URL = '/sign-in';
export const SIGN_UP_URL = '/sign-up';
export const NOT_FOUND_URL_ADMIN = '/admin/403';
const COMMON_ADMIN_URLS = [
  NOT_FOUND_URL_ADMIN,
];

export const DeviceAppStatuses = {
  not_configured: 'not_configured',
  code_entered: 'code_entered',
  configured: 'configured',
};

export const SnackTypes = {
  error: 'error',
  success: 'success',
  warning: 'warning',
};

export const IMAGE_SIZE_MAX = 990000;
export const ImageUploadErrorTypes = {
  upload: 'upload',
  zerosize: 'zerosize',
  oversize: 'oversize',
  unknown: 'unknown',
};

export const TV_ARC_DAYS = 6;

const TITLE_PREFIX = 'ArlekinoTV';

// placeholder height for header in fixed mode
export const USE_HEADER_HEIGHT = '5rem';

// min scroll offset to set 'scrolled' class for header in fixed mode
export const USE_HEADER_MIN_SCROLL = 20;

export const USE_DEVICE_VIEW_TYPE = false;

export const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const { i18n } = useTranslation('translations');
  const history = useHistory();
  const { search } = useLocation();

  const [systemSettings, setSystemSettings] = useState(null);

  // запоминаем параметры, с которыми выполнен запрос (для оплат)
  const [urlQuery, setUrlQuery] = useState(null);
  // Строка или массив для установки title страницы
  const [pageTitle, setPageTitle] = useState(null);
  const [siteLanguages, setSiteLanguages] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [userReferralLink, setUserReferralLink] = useState('');
  const [userReferralBalance, setUserReferralBalance] = useState(0);
  const [userReferralCurrentMonth, setUserReferralCurrentMonth] = useState(0);
  const [prices, setPrices] = useState(null);
  // Скидки на подписку "по сроку"
  const [discounts, setDiscounts] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [checkAuthInterval, setCheckAuthInterval] = useState(null);

  const [isCprhMessageProcessing, setIsCprhMessageProcessing] = useState(false);

  const clipboard = useClipboard();

  const copyText = useCallback(
    (text) => clipboard.copy(text),
    [clipboard.copy],
  );

  const goToPageWithScroll = (pathName) => {
    history.push({ pathname: pathName, state: { scrollToTop: true } });
  };

  const getFromPublicLink = (url, isUserExist, addParams = {}) => (
    { pathname: (isUserExist ? url : SIGN_UP_URL), state: { scrollToTop: true, ...addParams } }
  );

  const getLastUserUrl = () => localStorage.getItem(USER_LAST_URL_KEY) || '';

  const setLastUserUrl = (url) => {
    localStorage.setItem(USER_LAST_URL_KEY, url);
  };

  const loadSiteLanguages = async () => {
    const langs = await getSiteLanguagesAction()
      .then((res) => res || [])
      .catch(() => langsMockData);
    setSiteLanguages(langs.filter((l) => l.is_active));
  };

  const correctUserInfo = (userInfo) => ({
    ...userInfo,
    isAdmin: (userInfo?.admin_menu_items || []).length > 0,
    admin_menu_items: userInfo.admin_menu_items.length > 0
      ? [...userInfo.admin_menu_items, ...COMMON_ADMIN_URLS]
      : [],
  });

  const clearUser = () => {
    setUserInfo({});
    setUserEmail('');
    setUserBalance(0);
    setUserReferralLink('');
    setLastUserUrl('');

    const clearData = userInfo?.isAdmin ? [
      TOKEN_KEY,
      TOKEN_EXPIRED_KEY,
      LIMIT,
      PASSWORD_PIN_CHECK_COUNT,
    ] : [
      TOKEN_KEY,
      TOKEN_EXPIRED_KEY,
      SELECTED_DEVICES,
      DEVICES_LIST_LIMIT,
      DISABLED_PAGE_HEADERS,
    ];
    clearData.forEach((key) => localStorage.removeItem(key));

    const useLang = navigator.language || (navigator.languages && navigator.languages[0]) || 'ru';

    i18n.changeLanguage(useLang);
    localStorage.setItem('locale', useLang);
  };

  const refreshToken = () => {
    refreshTokenAction()
      .then(({ token_expired: tokenExpired }) => {
        auth.setTokenExpired(tokenExpired);
      })
      .catch(() => {
        clearUser();
        goToPageWithScroll('/sign-in');
      });
  };

  const getUser = async (restoreUrl = '') => {
    if (checkAuthInterval) {
      clearInterval(checkAuthInterval);
    }

    const te = auth.getTokenExpired();
    const tokenExpired = te ? moment.parseZone(te).valueOf() : new Date().getTime();
    const tokenExpiredMargin = new Date().getTime() - auth.refreshTokenInterval;

    if (!auth.getToken()
      || (tokenExpired < tokenExpiredMargin)) {
      auth.clearToken();
      auth.clearToken(TOKEN_EXPIRED_KEY);
      return {};
    }

    setLoading(true);
    const userInfo = await getUserAction()
      .then((userInfo) => {
        const userInfoCorrected = correctUserInfo(userInfo);
        setUserInfo(userInfoCorrected);

        localStorage.setItem('locale', userInfo.frontend_language);
        if (i18n.language !== userInfo.frontend_language) {
          i18n.changeLanguage(userInfo.frontend_language);
        }
        setUserEmail(userInfo.email);
        setUserBalance(userInfo.balance);
        setUserReferralLink(userInfo.referral_link);
        setUserReferralBalance(userInfo.referral_balance);
        setUserReferralCurrentMonth(userInfo.referral_transactions_amount_current_month);

        refreshToken();
        setCheckAuthInterval(setInterval(() => refreshToken(), auth.refreshTokenInterval));

        if (restoreUrl.length > 0) {
          history.push(restoreUrl);
        }

        return userInfoCorrected;
      })
      .catch(() => ({}))
      .finally(() => { setLoading(false); });
    return userInfo;
  };

  const updateUserInfo = () => getUserAction()
    .then((userInfo) => setUserInfo(correctUserInfo(userInfo)));

  const postCopyrightHolderMessageActionError = () => {
    setIsCprhMessageProcessing(false);
  };

  const handleCopyRightHolderMessagePost = async (data, setResultDataFunc) => {
    setIsCprhMessageProcessing(true);
    longAction(
      postCopyrightHolderMessageAction,
      (err) => {
        postCopyrightHolderMessageActionError(err);
        setResultDataFunc(err);
      },
      (res) => {
        if (setResultDataFunc) {
          setResultDataFunc(res);
        }
      },
      { data },
    );
  };

  const isDatesEqualByDate = (D1, D2, useUTC = true) => (
    D1 && D2
    && (useUTC ? (
      D1.getUTCFullYear() === D2.getUTCFullYear()
      && D1.getUTCMonth() === D2.getUTCMonth()
      && D1.getUTCDate() === D2.getUTCDate()
    ) : (
      D1.getFullYear() === D2.getFullYear()
      && D1.getMonth() === D2.getMonth()
      && D1.getDate() === D2.getDate()
    ))
  );

  const getSaveFileType = (type) => {
    let retVal = 'text/plain';
    switch (type) {
      case 'json':
        retVal = 'application/json';
        break;
      case 'm3u':
        break;
      case 'csv':
        break;
      default:
        break;
    }
    return retVal;
  };

  const saveFile = ({ name, type, data }) => {
    const linkEl = document.createElement('A');
    linkEl.download = name || 'Неизвестный файл';
    linkEl.href = URL.createObjectURL(
      (data instanceof Blob)
        ? data
        : (new Blob([data || 'Нет данных'], { type: getSaveFileType(type) })),
    );
    document.head.append(linkEl);
    linkEl.click();
    linkEl.remove();
  };

  const wordByNumber = (
    number, words = ['число', 'числа', 'чисел'],
  ) => {
    const value = Math.abs(number) % 100;
    const num = value % 10;
    if ((value > 10) && (value < 20)) return words[2];
    if ((num > 1) && (num < 5)) return words[1];
    if (num === 1) return words[0];
    return words[2];
  };

  useEffect(() => {
    if (!siteLanguages) {
      loadSiteLanguages();
    }
  }, [siteLanguages]);

  useEffect(() => {
    if (!systemSettings) {
      getSystemSettingsAction()
        .then((res) => {
          setPrices({
            dayPrice: parseFloat((res?.day_price || 0).toString()),
            dayPricePremium: parseFloat((res?.premium_day_price || 0).toString()),
          });
          setDiscounts((res?.discounts || []).map((d) => (
            { daysCount: d?.days || 0, percent: d?.discount || 0 }
          )));
          setSystemSettings(res);
        })
        .catch(() => {
          setPrices({
            dayPrice: '0',
            dayPricePremium: '0',
          });
          setDiscounts({});
          setSystemSettings([]);
        });
    }
  }, [systemSettings]);

  useEffect(() => {
    const useTitleArray = Array.isArray(pageTitle) ? pageTitle : [pageTitle].filter((t) => (t || '').toString().length > 0);
    document.title = [TITLE_PREFIX, ...useTitleArray]
      .join('. ').trim();
  }, [pageTitle]);

  useEffect(() => {
    if (!urlQuery && ((search || '').length > 0)) {
      const usedQueryParams = qs.parse(search, { ignoreQueryPrefix: true });
      setUrlQuery(usedQueryParams);
    }
  }, [search]);

  const value = {
    urlQuery,
    getLastUserUrl,
    setLastUserUrl,
    setPageTitle,
    siteLanguages,
    userInfo,
    setUserInfo,
    userEmail,
    userBalance,
    setUserBalance,
    userReferralLink,
    userReferralBalance,
    userReferralCurrentMonth,
    systemSettings,
    prices,
    discounts,
    clearUser,
    getUser,
    updateUserInfo,
    isLoading,
    isDatesEqualByDate,
    saveFile,
    wordByNumber,
    isCprhMessageProcessing,
    handleCopyRightHolderMessagePost,
    getFromPublicLink,
    copyText,
    goToPageWithScroll,
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
