import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  secondary, text01, red, green,
} from '@/common/constants/theme';

import { resendEmailAction } from '@/services/actions/authorization.actions';

import Button from '@/common/components/Button';
import AuthorizationLayout from '../components/AuthorizationLayout';

export default function SignUpConfirmEmailContainer({ match }) {
  const { t } = useTranslation('translations');
  const { email } = match.params;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  return (
    <AuthorizationLayout>
      <Title>
        <TitleText>{t('authorization.signUp.confirmTitleBlack')}</TitleText>
        <TitleTextOrange>{t('authorization.signUp.confirmTitleOrange')}</TitleTextOrange>
      </Title>
      <Form>
        <ContentTitle>{t('authorization.signUp.confirmContentTitle')}</ContentTitle>
        <ContentText>{t('authorization.signUp.confirmContentText')}</ContentText>
        <StyledButton
          isBoldText
          isUpperCase
          onClick={() => resendEmailAction(email)
            .then(() => {
              setError('');
              setSuccess(t('authorization.signUp.success'));
            })
            .catch((err) => {
              setSuccess('');
              setError(err.message_type);
            })}
        >
          {t('authorization.signUp.confirmContentButton')}
        </StyledButton>
        {success && <SuccessText>{t('authorization.signUp.success')}</SuccessText>}
        {error && <ErrorText>{t(`authorization.signUp.${error}`)}</ErrorText>}
      </Form>
    </AuthorizationLayout>
  );
}

const Title = styled.div`
  margin-top: 10rem;
  width: 40rem;
`;

const TitleText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;
`;

const TitleTextOrange = styled(TitleText)`
  color: ${secondary}
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 7.5rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const ContentTitle = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${text01};

`;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: .16px;
`;

const ContentText = styled(Text)`
  color: ${text01};
  margin: .75rem 0;
  max-width: 23rem;
  white-space: pre-wrap;
`;

const StyledButton = styled(Button)`
  width: auto;
  margin-top: 16px;
  padding: 0.75rem 1rem;

  filter: drop-shadow(rgba(0, 0, 0, 0.14) 0px 2px 4px);
`;

const MsgText = styled(Text)`
  font-size: 13px;
  display: flex;
  margin-top: .25rem;
  margin-bottom: 1rem;
`;

const ErrorText = styled(MsgText)`
  color: ${red};
`;

const SuccessText = styled(MsgText)`
  color: ${green};
`;
