/* eslint-disable no-shadow */
import React, {
  useState,
  createContext,
  useContext,
} from 'react';

import { getNewsAction } from '@/services/actions/news.actions';

export const BASE_PATH = '/news';

const NewsContext = createContext({});

export const NewsContextProvider = ({ children }) => {
  const [news, setNews] = useState([]);
  const [newsCount, setNewsCount] = useState(0);
  const [newsPage, setNewsPage] = useState(1);

  const pageLimit = 6;
  const getNewsPageCount = () => Math.ceil(newsCount / pageLimit);
  // const getOffset = (page) => (page - 1) * pageLimit;

  const getNews = () => {
    getNewsAction(newsPage, pageLimit)
      .then((res) => {
        setNews(res.results);
        setNewsCount(res.count);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const value = {
    getNews,
    news,
    newsCount,
    newsPage,
    setNewsPage,
    getNewsPageCount,
  };

  return (
    <NewsContext.Provider value={value}>
      {children}
    </NewsContext.Provider>
  );
};

export const useNewsContext = () => useContext(NewsContext);
