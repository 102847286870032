import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  greySecondary,
  primary,
  secondary,
} from '@/common/constants/theme';

const ServerLoading = ({
  justifyContent,
  isStalker,
  width,
}) => {
  const { t } = useTranslation('translations');

  return (
    <Container isStalker={isStalker}>
      <Text
        bottomMargin
        textCenter={justifyContent === 'center'}
      >
        {t('steps.step3.content.header.process')}
      </Text>
      <Wrapper justifyContent={justifyContent}>
        <Loading>
          <LoadedIndicator style={{ width: `${width}%` }} />
        </Loading>
        <BoldText leftMargin>
          {`${width.toFixed(0)} %`}
        </BoldText>
      </Wrapper>
    </Container>
  );
};

export default ServerLoading;

const Container = styled.div`
  ${({ isStalker }) => isStalker && ('margin-bottom: 14px;')}
  margin-top: 2rem;
  min-height: 4rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ justifyContent }) => (justifyContent && `justify-content: ${justifyContent};`)}
`;

const Loading = styled.div`
  width: 269px;
  height: 4px;

  background: ${greySecondary};
  border-radius: 4px;

  @media (max-width: 1360px) {
    width: 136px;
  }

  @media (max-width: 1198px) {
    width: 269px;
  }
`;

const LoadedIndicator = styled.div`
  height: 4px;
  background: ${secondary};
  border-radius: 4px;
  transition: 0.2s ease;
`;

const Text = styled.div`
  ${({ topMargin }) => topMargin && ('margin-top: 19px;')};
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 7px;')};
  ${({ textCenter }) => textCenter && ('text-align: center;')};

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: ${primary};
  line-height: 20px;
`;

const BoldText = styled(Text)`
  min-width: 50px;
  ${({ leftMargin }) => leftMargin && ('margin-left: 14px;')};

  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.16px;
  color: ${secondary};

  @media (max-width: 1360px) {
    ${({ leftMargin }) => leftMargin && ('margin-left: 6px;')};
  }

  @media (max-width: 1198px) {
    ${({ leftMargin }) => leftMargin && ('margin-left: 14px;')};
  }
`;
