import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

import styled from 'styled-components';
import {
  primary, grey, white, primaryQuart,
  text02, greyText,
} from '@/common/constants/theme';

import {
  THREAD_SHOW_MESSAGE_AUTHOR,
  THREAD_MARK_READ_TIMEOUT,
  ATTACHES_MAX_COUNT,
  useSupportContext,
} from '../SupportContext';

import { usePersonalPageContext } from '../../../../PersonalPageContext';
import AttachesList from './AttachesList';

const MessagesList = ({ longList }) => {
  const { t } = useTranslation('translations');
  const { getDateTimeToDisplay } = usePersonalPageContext();
  const {
    messages,
    markThreadRead,
    isMessagesLoaded,
  } = useSupportContext();

  const [localMessages, setLocalMessages] = useState([]);

  const markThreadReadTimeoutHandler = useRef(null);

  useEffect(() => {
    if (markThreadReadTimeoutHandler.current) {
      clearTimeout(markThreadReadTimeoutHandler.current);
    }
    if (messages) {
      setLocalMessages(messages);
      markThreadReadTimeoutHandler.current = setTimeout(
        () => {
          markThreadRead();
        }, THREAD_MARK_READ_TIMEOUT,
      );
    }
    return () => {
      if (markThreadReadTimeoutHandler.current) {
        clearTimeout(markThreadReadTimeoutHandler.current);
      }
      clearTimeout(markThreadReadTimeoutHandler.current);
    };
  }, [messages]);

  const renderUserMessage = (m) => (
    <UserMessageContainer
      key={`PSML--M-${m.message_id}`}
      title={`${t('personalPage.support.message.byUser')}${m?.dt_create ? `, ${getDateTimeToDisplay(m.dt_create)}` : ''}`}
    >
      <UserMessageInfoBlock>
        {THREAD_SHOW_MESSAGE_AUTHOR && (
          <MessageAuthor>
            {t('personalPage.support.message.byUser')}
          </MessageAuthor>
        )}
        {m.isDate && (
          <MessageDate isUnread={m.is_unread}>{ getDateTimeToDisplay(m.dt_create) }</MessageDate>
        )}
      </UserMessageInfoBlock>
      <UserMessageText
        isEmptyData={!m.isContentHtml && (m?.content || '').length === 0}
      >
        {m.isContentHtml
          ? ReactHtmlParser(m.content_html)
          : (m?.content || t('personalPage.support.message.emptyContent'))}
      </UserMessageText>
      {(m?.attachments || []).length > 0 && (
        <UserAttachesListContainer>
          <AttachesList
            attaches={m?.attachments || []}
            maxAttachesCount={ATTACHES_MAX_COUNT}
          />
        </UserAttachesListContainer>
      )}
    </UserMessageContainer>
  );

  const renderSupportMessage = (m) => (
    <SupportMessageContainer
      key={`PSML--M-${m.message_id}`}
      title={`${t('personalPage.support.message.bySupport')}${m?.dt_create ? `, ${getDateTimeToDisplay(m.dt_create)}` : ''}`}
    >
      <SupportMessageInfoBlock>
        {m.isDate && (
          <MessageDate isUnread={m.is_unread}>{ getDateTimeToDisplay(m.dt_create) }</MessageDate>
        )}
        {THREAD_SHOW_MESSAGE_AUTHOR && (
          <MessageAuthor>
            {t('personalPage.support.message.bySupport')}
          </MessageAuthor>
        )}
      </SupportMessageInfoBlock>
      <SupportMessageText
        isEmptyData={!m.isContentHtml && (m?.content || '').length === 0}
      >
        <SupportMessageTextWrapper>
          {m.isContentHtml
            ? ReactHtmlParser(m.content_html)
            : (m?.content || t('personalPage.support.message.emptyContent'))}
        </SupportMessageTextWrapper>
      </SupportMessageText>
      {(m?.attachments || []).length > 0 && (
        <SupportAttachesListContainer>
          <AttachesList
            attaches={m?.attachments || []}
            maxAttachesCount={ATTACHES_MAX_COUNT}
          />
        </SupportAttachesListContainer>
      )}
    </SupportMessageContainer>
  );

  return (
    <Container
      isLong={longList || false}
    >
      {(localMessages || []).map((m) => {
        const isResponse = (m?.staff_id || 0) > 0;
        const mModified = {
          ...m,
          isContentHtml: (m?.content_html || '').length > 0,
          isDate: (m?.dt_create || '').length > 0,
        };
        return isResponse ? renderSupportMessage(mModified) : renderUserMessage(mModified);
      })}
      {((localMessages || []).length === 0) && (
        <NoData>{isMessagesLoaded ? t('personalPage.support.message.emptyList') : ''}</NoData>
      )}
    </Container>
  );
};

export default MessagesList;

const Container = styled.div`
  position: relative;
  max-height: ${({ isLong }) => (isLong ? 40 : 20)}rem;
  overflow-y: scroll;
`;

const MessageContainer = styled.div`
  position: relative;
  width: 80%;
`;

const UserMessageContainer = styled(MessageContainer)`
  position: relative;
  background: ${white};
  padding: 0.25rem 0.5rem;
  margin: 0.5rem 0 0.5rem auto;
  border-radius: 0 0 0 6px;
`;

const SupportMessageContainer = styled(MessageContainer)`
  position: relative;
  background: ${primaryQuart};
  padding: 0.25rem 0.5rem;
  margin: 0.5rem auto 0.5rem 0;
  border-radius: 0 0 6px 0;
`;

const MessageInfoBlock = styled.div`
  display: flex;
  align-items: flex-end;
  overflow: auto;

  & > div:not(:last-of-type) {
    margin-right: .5rem;
  }
`;

const UserMessageInfoBlock = styled(MessageInfoBlock)`
  justify-content: flex-end;
`;

const SupportMessageInfoBlock = styled(MessageInfoBlock)`
  justify-content: flex-start;
`;

const MessageAuthor = styled.div`
`;

const MessageDate = styled.div`
  position: relative;
  display: inline-block;
  font-size: 0.75rem;

  ${({ isUnread }) => isUnread && `
    /* padding-left: 1rem;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 5px;
      border-radius: 50%;
      top: 4px;
      left: 0;
      background: ${primary};
      box-shadow: 0px 2px 4px rgba(92, 112, 207, 0.3); */
    }
  `}
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 150px;
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 15px;
  letter-spacing: 0.16px;
  color: ${text02};
`;

const MessageText = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-line;

  ${({ isEmptyData }) => (isEmptyData && `color: ${greyText};`)}

  & img {
    width: auto;
    max-width: 100% !important;
    max-height: 600px;
  }
`;

const UserMessageText = styled(MessageText)`
  justify-content: flex-end;
  text-align: end;
`;

const SupportMessageText = styled(MessageText)`
  justify-content: flex-start;
  text-align: start;
`;

const SupportMessageTextWrapper = styled.div`

`;

const AttachesListContainer = styled.div`
  display: flex;
  width: 33%;
  padding: 0.125rem 0.125rem 0.125rem 0;
  margin: .5rem 0;
  border-top: solid 1px ${grey};
`;

const UserAttachesListContainer = styled(AttachesListContainer)`
  justify-content: flex-end;
  margin-left: auto;
`;

const SupportAttachesListContainer = styled(AttachesListContainer)`
  justify-content: flex-start;
  margin-right: auto;
`;
