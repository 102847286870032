import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import {
  inputBorder,
  white,
  text01,
  activePrimary,
  secondary,
  primary,
} from '../../../../../common/constants/theme';
import CloseIcon from '../../../../../common/components/icons/CloseIcon';
import ArrowLeft from '../../../../../common/components/icons/ArrowLeft';

import useOutsideAlerter from '../../../../../common/utils/useOutsideAlerter.util';

const AdminMultiSelect = ({
  options,
  onSelect,
  selectedValues,
  handleClear,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const listRef = useRef(null);
  useOutsideAlerter(listRef, () => setIsOpen(false));

  return (
    <Container>
      <Input>
        <Wrapper wrap>
          {selectedValues.length ? selectedValues.map((item) => (
            <Wrapper rightMargin={20} key={item}>
              <Icon
                secondaryColor
                rightMargin={15}
                onClick={() => onSelect(item, false)}
              >
                <CloseIcon />
              </Icon>
              <InputText>
                {options.find((option) => option.key === item)?.title}
              </InputText>
            </Wrapper>
          )) : null}
        </Wrapper>
        <Wrapper topMargin={6}>
          <Icon
            rightMargin={8}
            onClick={handleClear}
          >
            <CloseIcon />
          </Icon>
          <Wrapper ref={listRef}>
            <ArrowIcon
              isOpen={isOpen}
              onClick={() => setIsOpen((prevState) => !prevState)}
            >
              <ArrowLeft />
            </ArrowIcon>
            {isOpen && (
              <List>
                {options.map((item) => (
                  <Option
                    key={item.key}
                    isActive={selectedValues.find((value) => value === item.key)}
                    onClick={() => onSelect(item, !selectedValues
                      .find((value) => value === item.key))}
                  >
                    <Text>
                      {item.title}
                    </Text>
                  </Option>
                ))}
              </List>
            )}
          </Wrapper>
        </Wrapper>
      </Input>
    </Container>
  );
};

export default AdminMultiSelect;

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ wrap }) => wrap && 'flex-wrap: wrap;'}

  ${({ topMargin }) => topMargin && `margin-top: ${topMargin}px;`}
  ${({ rightMargin }) => rightMargin && (`margin-right: ${rightMargin}px;`)}
`;

const Text = styled.div`
  font-family: Gilroy;
  font-size: 13px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 17px;

  transition: 0.2s ease;
`;

const List = styled.div`
  width: 100%;
  max-height: 300px;

  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;

  overflow: auto;
  background: ${white};
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Option = styled.div`
  display: flex;
  align-items: center;

  height: 28px;
  padding-left: 12px;

  background-color: transparent;
  cursor: pointer;

  transition: 0.2s ease;

  &:hover {
    background-color: ${activePrimary};

    & ${Text} {
      color: ${white};
    }
  }

  ${({ isActive }) => isActive && (`
    background-color: ${primary};

    & ${Text} {
      font-weight: 600;
      color: ${white};
    }
  `)}
`;

const InputText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 36px;
`;

const Input = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  min-height: 32px;
  padding: 0 8px;

  background: ${white};
  border: 1px solid ${inputBorder};
  border-radius: 2px;
`;

const Icon = styled.div`
  ${({ rightMargin }) => rightMargin && (`margin-right: ${rightMargin}px;`)}

  cursor: pointer;

  ${({ secondaryColor }) => secondaryColor && (`
    & svg path {
      fill: ${secondary};
    }
  `)}
`;

const ArrowIcon = styled.div`
  transform: rotate(${({ isOpen }) => (isOpen ? 90 : -90)}deg);
  cursor: pointer;

  transition: transform 0.25s;
`;
