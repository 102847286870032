import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { format } from 'date-fns';

import styled from 'styled-components';
import {
  primary,
  text01,
  background01,
  white, red, borderSecondary,
} from '@/common/constants/theme';

import { sanitizeFloat } from '@/common/utils/sanitize.utils';

import DatePicker from '@/common/components/DatePicker';
import Checkbox from '@/common/components/Checkbox';
import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';
import ArrowRightPrimary from '../../../../icons/ArrowRightPrimary';
import CalendarIcon from '../../../../icons/CalendarIcon';

import {
  NEW_PAYSYSTEM_ID,
  useStatisticAggregatorsContext,
} from '../AggregatorsContext';
import {
  DATE_TIME_FORMAT, useAdminContext,
} from '../../../../../AdminContext';

const StatisticAggregatorsCreate = ({ mainPath }) => {
  const { paySystemId } = useParams();

  const history = useHistory();

  const {
    isUrl,
    SnackTypes,
    setAdminSnack,
  } = useAdminContext();

  const {
    isBusy, setIsBusy,
    getPaymentSystem,
    createPaySystem,
    changePaySystem,
  } = useStatisticAggregatorsContext();

  const [paymentSystem, setPaymentSystem] = useState(null);
  const [name, setName] = useState('');
  const [bonusPercent, setBonusPercent] = useState(0);
  const [payment, setPayment] = useState('');
  const [callback, setCallback] = useState('');
  const [status, setStatus] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [enableForAllUsers, setEnableForAllUsers] = useState(false);
  const [hide, setHide] = useState(false);
  const [dateToHide, setDateToHide] = useState(null);
  const [dateToHideStr, setDateToHideStr] = useState('');

  const [hideCalendarOpen, setHideCalendarOpen] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [isDataOk, setIsDataOk] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [errors, setErrors] = useState({});

  const psFields = [
    {
      title: 'Название агрегатора',
      data: name,
      setData: setName,
    },
    {
      title: 'Бонус за пополнение',
      data: bonusPercent,
      setData: setBonusPercent,
    },
    {
      title: 'Payment url',
      data: payment,
      setData: setPayment,
      isUrlType: true,
    },
    {
      title: 'Callback url',
      data: callback,
      setData: setCallback,
      isUrlType: true,
    },
    {
      title: 'Status url',
      data: status,
      setData: setStatus,
      isUrlType: true,
    },
    {
      title: 'Login',
      data: login,
      setData: setLogin,
    },
    {
      title: 'Password',
      data: password,
      setData: setPassword,
    },
  ];

  const formatDate = (dateValue, useUTC = false) => {
    const Y = dateValue[useUTC ? 'getUTCFullYear' : 'getFullYear']();
    const M = dateValue[useUTC ? 'getUTCMonth' : 'getMonth']() + 1;
    const D = dateValue[useUTC ? 'getUTCDate' : 'getDate']();

    return `${D.toString().padStart(2, '0')}.${M.toString().padStart(2, '0')}.${Y}`;
  };

  const getDateFromFormat = (str) => {
    if ((str?.length || 0) === 0) return null;
    let retVal = null;
    const dtPickerRegex = new RegExp('^(?<d>\\d{1,2})\\.(?<m>\\d{1,2})\\.(?<y>\\d{2,4})$', 'i');
    if (dtPickerRegex.test(str)) {
      const { groups } = dtPickerRegex.exec(str);
      retVal = new Date(
        parseInt(groups.y, 10),
        parseInt(groups.m, 10) - 1,
        parseInt(groups.d, 10),
        23,
        59,
        59,
      );
    } else {
      retVal = new Date(str);
    }
    return retVal;
  };

  const isDatesEqual = (D1, D2) => (D1.getUTCFullYear() === D2.getUTCFullYear())
    && (D1.getUTCMonth() === D2.getUTCMonth())
    && (D1.getUTCDate() === D2.getUTCDate());

  useEffect(() => {
    const sanitizedValue = sanitizeFloat(bonusPercent || '');
    if (bonusPercent !== sanitizedValue) {
      setBonusPercent(sanitizedValue);
    }
  }, [bonusPercent]);

  useEffect(() => {
    let isOk = true;
    let isChanged = !paymentSystem;

    isOk = isOk && (name?.length > 1) && !errors?.name;
    isOk = isOk && (payment?.length > 1) && isUrl(payment) && !errors?.payment;
    isOk = isOk && (callback?.length > 1) && isUrl(callback) && !errors?.callback;
    isOk = isOk && (status?.length > 1) && isUrl(status) && !errors?.status;
    isOk = isOk && (login?.length > 1) && !errors?.login;
    isOk = isOk && (!hide || dateToHide) && !errors?.is_hidden_date;
    isOk = isOk && (password?.length > 0) && !errors?.password;

    setIsDataOk(isOk);

    if (isOk && ((paySystemId === NEW_PAYSYSTEM_ID) || paymentSystem)) {
      isChanged = (paySystemId === NEW_PAYSYSTEM_ID);
      if (paymentSystem) {
        isChanged = isChanged || (name !== paymentSystem.title);
        isChanged = isChanged || (parseFloat(bonusPercent, 10) !== paymentSystem.bonus_percent);
        isChanged = isChanged || (payment !== paymentSystem.payment_url);
        isChanged = isChanged || (callback !== paymentSystem.callback_url);
        isChanged = isChanged || (status !== paymentSystem.status_url);
        isChanged = isChanged || (login !== paymentSystem.login);
        isChanged = isChanged || (password !== paymentSystem.password);
        isChanged = isChanged || (!enableForAllUsers !== paymentSystem.is_disabled);
        isChanged = isChanged || (hide !== paymentSystem.is_hidden);
        isChanged = isChanged || !isDatesEqual(new Date(paymentSystem.is_hidden_date), dateToHide);
      }
    }
    setIsDataChanged(isChanged);
  }, [
    name, login, password,
    payment, bonusPercent, callback, status,
    enableForAllUsers, hide, dateToHide,
  ]);

  useEffect(() => {
    if (paySystemId === NEW_PAYSYSTEM_ID) {
      setPaymentSystem(null);
    } else {
      const psId = parseInt(paySystemId, 10);
      if (psId > 0) {
        getPaymentSystem(psId)
          .then((res) => setPaymentSystem(res));
      } else {
        setAdminSnack({ content: '', type: SnackTypes.error }, SnackTypes.error);
      }
    }
  }, [paySystemId]);

  useEffect(() => {
    if (paymentSystem) {
      setEditMode(true);
      setName(paymentSystem.title);
      setBonusPercent(paymentSystem.bonus_percent);
      setPayment(paymentSystem.payment_url);
      setCallback(paymentSystem.callback_url);
      setStatus(paymentSystem.status_url);
      setLogin(paymentSystem.login);
      setPassword(paymentSystem.password);
      setEnableForAllUsers(!paymentSystem.is_disabled);
      setHide(paymentSystem.is_hidden);
      setDateToHide(new Date(paymentSystem.is_hidden_date));
      setDateToHideStr(formatDate(getDateFromFormat(paymentSystem.is_hidden_date), true));
    }
  }, [paymentSystem]);

  useEffect(() => {
    setDateToHide(getDateFromFormat(dateToHideStr));
  }, [dateToHideStr]);

  const handleCreatePs = () => {
    setIsBusy(true);
    const postObj = {
      title: name,
      bonus_percent: bonusPercent || 0,
      payment_url: payment,
      callback_url: callback,
      status_url: status,
      login,
      password,
      is_hidden: hide,
    };
    if (hide && dateToHide) {
      postObj.is_hidden_date = format(dateToHide, DATE_TIME_FORMAT);
    }
    (editMode ? changePaySystem(paymentSystem.id, postObj) : createPaySystem(postObj))
      .then((res) => {
        if (res?.id) {
          history.push(mainPath);
        }
      })
      .catch((err) => Object.entries(err?.errors || {})
        .forEach(([k, v]) => setErrors((prevState) => ({ ...prevState, [k]: v }))))
      .finally(() => setIsBusy(false));
  };

  const renderField = ({
    title, data, setData, isUrlType,
  }) => {
    const urlPHolder = `https://${title.replace(' ', '-')}-address.com`;
    const key = `${title.replace(' ', '_')}--sa-re-rf`;
    return (
      <Wrapper
        key={key}
        title={isUrlType ? urlPHolder : ''}
      >
        <Text>
          {`${title}:`}
        </Text>
        <Input
          isError={errors?.[data]}
          value={data}
          onChange={({ target }) => setData(target.value)}
          placeholder={isUrlType ? urlPHolder : ''}
        />
        {errors?.[data] && <ErrorText>{errors[data]}</ErrorText>}
      </Wrapper>
    );
  };

  return (
    <Container>
      {isBusy && <Loader />}
      <Header>
        <Title>
          Добавление платежного агрегагатора
        </Title>
        <StyledLink to={mainPath}>
          Вернуться “Настройки агрегаторов”
          <Icon>
            <ArrowRightPrimary />
          </Icon>
        </StyledLink>
      </Header>
      <MainContent>
        {psFields && psFields.map((ps) => renderField(ps))}
        <Wrapper flex bottomMargin={12}>
          <Checkbox
            checked={enableForAllUsers}
            onChange={(checked) => setEnableForAllUsers(checked)}
            label="Включить для всех пользователей"
            type="secondary"
          />
        </Wrapper>
        <Wrapper flex bottomMargin={12}>
          <Checkbox
            checked={hide}
            onChange={(checked) => setHide(checked)}
            label="Скрыть"
            type="secondary"
          />
          {hide && (
            <>
              <Text noBM fs={13} lh={24} p="0 .33rem">
                с даты:
              </Text>
              <StyledInputMask
                value={dateToHideStr}
                onChange={({ target }) => { setDateToHideStr(target.value); }}
                mask="99.99.9999"
                alwaysShowMask
              />
              <CalendarIconWrapper
                onClick={() => setHideCalendarOpen(true)}
              >
                <CalendarIcon />
              </CalendarIconWrapper>
              <DatePicker
                value={dateToHide}
                isOpen={hideCalendarOpen}
                onApply={(v) => { setDateToHide(v); setDateToHideStr(formatDate(v)); }}
                onClose={() => setHideCalendarOpen(false)}
              />
            </>
          )}
        </Wrapper>
      </MainContent>
      <StyledButton
        isBoldText
        isUpperCase
        width="small"
        isDisabled={!isDataOk || !isDataChanged}
        onClick={handleCreatePs}
        title={isDataOk ? '' : 'Заполните все поля правильно'}
      >
        {paymentSystem ? 'Обновить' : 'Сохранить'}
      </StyledButton>
    </Container>
  );
};

export default StatisticAggregatorsCreate;

const Container = styled.div``;

const Wrapper = styled.div`
  ${({ flex }) => (flex && `
    display: flex;
    align-items: center;
    jystify-content: start;
  `)}
  ${({ bottomMargin }) => (bottomMargin && `margin-bottom: ${bottomMargin}px;`)}

  &:last-child {
    margin-bottom: 6px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 16px;
  margin-bottom: 20px;
`;

const MainContent = styled.div`
  padding: 14px 20px 30px 20px;
  margin-bottom: 16px;

  background-color: ${background01};
  border-radius: 2px;
`;

const Text = styled.div`
  margin-bottom: ${({ noBM }) => (noBM ? 0 : 12)}px;

  ${({ p }) => `padding: ${p || 0};`}
  font-family: Gilroy, sans-serif;
  font-size: ${({ fs }) => (fs || 14)}px;
  color: ${text01};
  line-height: ${({ lh }) => (lh || 15)}px;
`;

const ErrorText = styled(Text)`
  color: ${red};
  margin-bottom: 12px
`;

const Title = styled(Text)`
  margin-left: 16px;
  margin-bottom: 0;

  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
`;

const StyledLink = styled(Link)`
  display: flex;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${primary};
  line-height: 20px;

  &:hover,
  &:focus {
    color: ${primary};
  }
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;
  top: 3px;

  cursor: pointer;
`;

const Input = styled.input`
  width: 466px;
  height: 32px;
  padding: 0 12px;
  margin-bottom: ${({ isError }) => (isError ? 6 : 16)}px;

  background: ${white};
  border: solid 1px ${({ isError }) => (isError ? red : 'transparent')};
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  outline: none;
`;

const StyledInputMask = styled(InputMask)`
  width: 100px;
  height: 24px;
  padding: 0 10px;

  background-color: transparent;
  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  outline: none;
`;

const CalendarIconWrapper = styled.div`
  margin-left: .25rem;
  cursor: pointer;
  height: 24px;
`;

const StyledButton = styled(Button)`
  margin-left: 16px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
