import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  activePrimary, greySecondary,
  primary,
  secondaryHover,
} from '@/common/constants/theme';

import {
  CHECKBOX_UNDEFINED, CHECKBOX_ENABLED, CHECKBOX_DISABLED,
} from '@/common/constants/controls';

const changeStateOrder = [
  CHECKBOX_DISABLED, CHECKBOX_UNDEFINED, CHECKBOX_ENABLED,
];

const ToggleTriState = ({ state, onChange }) => {
  const { t } = useTranslation('translations');
  const [sliderState, setSliderState] = useState(CHECKBOX_UNDEFINED);

  const titles = {
    [CHECKBOX_DISABLED]: t('subscriptionsContent.controls.triState.disabled'),
    [CHECKBOX_UNDEFINED]: t('subscriptionsContent.controls.triState.undefined'),
    [CHECKBOX_ENABLED]: t('subscriptionsContent.controls.triState.enabled'),
  };

  const onClick = () => {
    const currentIndex = changeStateOrder.findIndex((e) => e === state) || 0;
    const setIndex = currentIndex + 1;
    if (onChange) {
      onChange(changeStateOrder[setIndex >= changeStateOrder.length ? 0 : setIndex]);
    }
  };

  useEffect(() => {
    setSliderState(state || CHECKBOX_UNDEFINED);
  }, [state]);

  return (
    <Label
      className="switch"
      title={titles[sliderState]}
    >
      <Input
        type="text"
        value={sliderState}
        onClick={onClick}
      />
      <Slider
        className="slider round"
        currentState={sliderState}
      />
    </Label>
  );
};

export default ToggleTriState;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 1.75rem;
  height: 1rem;
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${activePrimary};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  :before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 3px;
    bottom: 3px;
    background-color: ${primary};
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  ${({ currentState }) => {
    let retVal = '';
    switch (currentState) {
      case CHECKBOX_UNDEFINED:
        retVal = `
          background: ${greySecondary};

          &:before {
            -webkit-transform: translateX(6px);
            -ms-transform: translateX(6px);
            transform: translateX(6px);
          }
        `;
        break;
      case CHECKBOX_ENABLED:
        retVal = `
          background: ${secondaryHover};
          box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.17);

          &:before {
            -webkit-transform: translateX(12px);
            -ms-transform: translateX(12px);
            transform: translateX(12px);
          }
        `;
        break;
      default:
        break;
    }
    return retVal;
  }}
`;
