import React, {
  createContext,
  useContext, useEffect,
  useState,
} from 'react';

import { usePersonalPageContext } from '../../../PersonalPageContext';

export const DNSContext = createContext({});

const initialFilters = {
  page: 1,
  limit: localStorage.getItem('limit') || 10,
};

export const DNSContextProvider = ({ children }) => {
  const [dns, setDns] = useState([]);
  const [dnsCount, setDnsCount] = useState(0);
  const [dnsFilters, setDnsFilters] = useState(initialFilters);
  const [servers, setServers] = useState([]);
  const [domains, setDomains] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const {
    serversFullData,
    allDns,
    allDomains,
  } = usePersonalPageContext();

  useEffect(() => {
    const options = [];
    serversFullData.forEach((server) => {
      options.push({
        title: server.name,
        url: server.dns,
        key: `${server.dns}-${server.id}`,
        id: server.id,
      });
    });
    setServers(options);
  }, [serversFullData]);

  useEffect(() => {
    const useDns = (allDns || []).filter((item) => item.id > 0);
    setDns(useDns.slice(
      (dnsFilters.page - 1) * dnsFilters.limit, dnsFilters.page * dnsFilters.limit,
    ));
    setDnsCount(useDns.length);
  }, [allDns, dnsFilters]);

  useEffect(() => {
    const options = [];
    (allDomains || []).forEach((domain) => {
      options.push({ title: domain.url, key: `${domain.name}-${domain.id}`, id: domain.id });
    });
    setDomains(options);
  }, [allDomains]);

  const value = {
    dns,
    dnsCount,
    dnsFilters,
    setDnsFilters,
    servers,
    domains,
    isEdit,
    setIsEdit,
    selectedValue,
    setSelectedValue,
  };

  return (
    <DNSContext.Provider value={value}>
      {children}
    </DNSContext.Provider>
  );
};

export const useDNSContext = () => useContext(DNSContext);
