/* eslint-disable no-shadow */
import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { text01 } from '@/common/constants/theme';
import Modal from '@/common/components/ModalNew';
import Input from '@/common/components/AuthorizationInput';
import Button from '@/common/components/Button';
import EnvelopeIcon from '@/assets/icons/whiteEnvelope.svg';
import { useProfileContext } from '../ProfileContext';

const UpdateEmail = ({
  isOpen,
  onClose,
  history,
}) => {
  const { t } = useTranslation(['translations']);
  const {
    validateEmail,
    changeEmail,
  } = useProfileContext();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [confirmedEmailError, setConfirmedEmailError] = useState(null);
  const [isConfirmedModalOpen, setConfirmedModalOpen] = useState(false);

  const onModalClose = () => {
    onClose();
    setEmail('');
    setEmailError(null);
    setConfirmedEmail('');
    setConfirmedEmailError(null);
  };

  const emailValidation = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const onBlurEmail = () => {
    if (emailValidation(email)) {
      validateEmail(email)
        .then(({ errors }) => {
          if (!errors) {
            setEmailError('');
          } else {
            setEmailError(t('authorization.emailExist'));
          }
        });
    } else {
      setEmailError(t('authorization.invalidEmail'));
    }
  };

  const onBlurConfirmedEmail = () => {
    if (confirmedEmail !== email && email && confirmedEmail) {
      setConfirmedEmailError(t('authorization.confirmPasswordError'));
    } else if (email && confirmedEmail) {
      setConfirmedEmailError('');
    }
  };

  const handleSubmit = () => {
    if (!email) {
      setEmailError(t('authorization.emptyEmail'));
    }
    if (!confirmedEmail) {
      setConfirmedEmailError(t('authorization.emptyEmail'));
    }
    if (email && confirmedEmail) {
      changeEmail(email)
        .then((res) => {
          if (!res.errors) {
            onModalClose();
            setConfirmedModalOpen(true);
          }
        });
    }
  };

  const onClosedConfirmedModal = () => {
    setConfirmedModalOpen(false);
    history.pushState('/personal-page/profile');
  };

  return (
    <>
      <Modal
        onCloseModal={onModalClose}
        title={t('profile.personalInfo.emailModalTitle')}
        icon={EnvelopeIcon}
        isOpen={isOpen}
      >
        <InputLabel>{t('profile.personalInfo.newEmail')}</InputLabel>
        <Input
          placeholder={t('profile.personalInfo.newEmail')}
          label={t('profile.personalInfo.newEmail')}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          onBlur={() => onBlurEmail(email)}
          errorText={emailError}
          withoutBorder
          height="40px"
        />
        <InputLabel>{t('profile.personalInfo.confirmedNewEmail')}</InputLabel>
        <Input
          placeholder={t('profile.personalInfo.confirmedNewEmail')}
          value={confirmedEmail}
          onChange={({ target }) => setConfirmedEmail(target.value)}
          onBlur={onBlurConfirmedEmail}
          errorText={confirmedEmailError}
          withoutBorder
          height="40px"
        />
        <Action>
          <Button
            width="small"
            isUpperCase
            isBoldText
            onClick={handleSubmit}
          >
            {t('profile.personalInfo.editButton')}
          </Button>
        </Action>
      </Modal>
      <Modal
        onCloseModal={onClosedConfirmedModal}
        title={t('profile.personalInfo.emailModalTitle')}
        icon={EnvelopeIcon}
        isOpen={isConfirmedModalOpen}
      >
        <Text>{`${t('profile.accountsAndFinance.confirmedEmailText')} ${email}`}</Text>
        <Text>{t('profile.personalInfo.emailModalText')}</Text>
        <Action>
          <Button
            width="small"
            isUpperCase
            isBoldText
            onClick={onClose}
          >
            {t('profile.accountsAndFinance.okButton')}
          </Button>
        </Action>
      </Modal>
    </>
  );
};

export default withRouter(UpdateEmail);

const InputLabel = styled.div`
  margin-bottom: .5rem;
  font-size: 15px;
`;

const Action = styled.div`
  margin: 2rem 0 1rem 0;
  display: flex;
  justify-content: flex-end;
`;

const Text = styled.p`
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;
