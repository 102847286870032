import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPlayLists, getServers,
  updateDeviceServerRedux,
  updateDevicesPlaylistRedux,
} from '@/store/user/selectors';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  green, text01,
} from '@/common/constants/theme';

import IconReload from '@/assets/icons/reload.svg';
import { Tooltip } from 'antd';
import IconCopy from '@/assets/icons/copyicon.svg';
import LinkList from '@/common/components/LinkList';
import PiconLinkList from './PlaylistComponents/PiconLinkList';
import Playlists from './CommonComponents/Playlists';

import { usePersonalPageContext } from '../../../PersonalPageContext';

// const piconLinks = [
//   { name: 'OTT Navigator (220x132)' },
//   { name: 'Enigma (220x132)' },
//   { name: 'Ministra / Codi (256x256)' },
//   { name: 'Halva (336x192)' },
// ];

const DevicePlaylist = ({
  device,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation('translations');

  const [playListTypes, setPlayListTypes] = useState([]);
  const [usePlayList, setUsePlayList] = useState(null);
  const [links, setLinks] = useState(null);
  const [picons, setPicons] = useState([]);
  const [isServerListBusy, setIsServerListBusy] = useState(false);
  const [isPlayListListBusy, setIsPlayListListBusy] = useState(false);

  const {
    allDns,
    refreshOTTId,
    playlistOses,
    getPlayListDownloadLinks,
  } = usePersonalPageContext();

  const storedPlaylists = useSelector(getPlayLists);
  const servers = useSelector(getServers);

  const copyText = (elementId) => {
    const range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
  };

  useEffect(() => {
    if ((playlistOses || []).length > 0) {
      const usePlaylistTypes = [];
      playlistOses.forEach((PO) => {
        PO.playlist_types.forEach((pt) => {
          if (!usePlaylistTypes[pt.id]) {
            usePlaylistTypes[pt.id] = pt;
          }
        });
      });
      setPlayListTypes(usePlaylistTypes.filter((pl) => !!pl?.id));
    }
  }, [playlistOses]);

  useEffect(() => {
    if (!device || (playListTypes.length === 0) || (allDns.length === 0)) {
      return;
    }

    const upl = playListTypes
      .find((plt) => (plt.id === device?.playlist_type)) || playListTypes[0];
    setUsePlayList(upl);

    const deviceDns = allDns.find((d) => (device.dns || -1) === d.id)
      || { name: window.location.hostname };
    const gL = getPlayListDownloadLinks(device, deviceDns, upl);
    const LData = {
      links: [],
      epgUrls: [],
      epgLiteUrls: [],
      piconUrls: [],
    };

    if ((gL?.http || '').length > 0) {
      LData.links.push(gL.http);
    }
    if ((gL?.https || '').length > 0) {
      LData.links.push(gL.https);
    }

    if ((gL?.epgLiteUrls || []).length > 0) {
      LData.epgLiteUrls = [...gL.epgLiteUrls];
    }

    if ((gL?.epgUrls || []).length > 0) {
      LData.epgUrls = [...gL.epgUrls];
    }

    if ((gL?.piconUrls || []).length > 0) {
      setPicons((gL.piconUrls || []).map((url) => {
        const path = url.split('/').slice(-1);
        return { name: path, url };
      }));
    }

    const linksObject = [
      {
        id: 1,
        title: t('devicesInfo.directLinks'),
        urls: LData.links,
      },
      {
        id: 3,
        title: t('devicesInfo.sourcesOfEPG'),
        urls: LData.epgUrls,
      },
      {
        id: 4,
        title: t('devicesInfo.LITEsourcesOfEPG'),
        urls: LData.epgLiteUrls,
      },
    ];

    setLinks(linksObject);
  }, [device, allDns, playListTypes]);

  return device && (
    <DeviceInfo>
      <Row>
        <OttIdWrapper>
          <IdTitle>OTT ID</IdTitle>
          <Icon
            src={IconReload}
            onClick={() => refreshOTTId(device.id)}
          />
        </OttIdWrapper>
        <OttIdWrapper>
          <IdValue id="ott-id">
            {device.ott_id}
          </IdValue>
          <Tooltip
            autoAdjustOverflow
            arrowPointAtCenter
            placement="topRight"
            overlayClassName="green-tooltip"
            trigger={['click']}
            title={t('devicesInfo.copyTooltip')}
            color={green}
          >
            <Icon src={IconCopy} onClick={() => copyText('ott-id')} />
          </Tooltip>

        </OttIdWrapper>
      </Row>
      <Row>
        {links && <LinkList linksObject={links} copyText={copyText} />}
      </Row>
      <Row>
        {usePlayList && <PiconLinkList links={picons} />}
      </Row>
      <Row>
        <Playlists
          isBusy={isPlayListListBusy}
          list={storedPlaylists || []}
          defaultValue={device.playlist}
          title={t('devicesInfo.playlists')}
          buttonText={t('devicesInfo.addButton')}
          withButtonIcon
          buttonClicked={() => history.push('/personal-page/channels')}
          listItemSelected={(playlistId) => {
            setIsPlayListListBusy(true);
            dispatch(updateDevicesPlaylistRedux([device], playlistId))
              .then(() => setIsPlayListListBusy(false));
          }}
        />
      </Row>
      <Row>
        <Playlists
          isBusy={isServerListBusy}
          preList={(servers || [])
            .filter((s) => s.is_virtual)
            .map((s) => ({ id: s.id, title: s.name }))}
          list={(servers || [])
            .filter((s) => !s.is_virtual)
            .map((s) => ({ id: s.id, title: s.name })) || servers[0]}
          defaultValue={device.server}
          title={t('devicesInfo.servers')}
          buttonText={t('devicesInfo.pickUpButton')}
          buttonClicked={() => history.push('/personal-page/server')}
          listItemClicked={(serverId) => {
            if (device.server !== serverId) {
              setIsServerListBusy(true);
              dispatch(updateDeviceServerRedux(device.id, serverId))
                .then(() => setIsServerListBusy(false));
            }
          }}
        />
      </Row>
    </DeviceInfo>
  );
};

export default DevicePlaylist;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;
  border-bottom: solid 1px #EFEEF3;
`;

const DeviceInfo = styled.div``;

const Title = styled.span`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin: .25rem 0;
`;

const OttIdWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IdTitle = styled(Title)`
  opacity: 0.6;
`;
const IdValue = styled(Title)`
  font-size: 12px;
  line-height: 24px;
`;

const Icon = styled.img`
  margin-left: .75rem;
  cursor: pointer;
`;
