import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';
import {
  getMenu,
  getUserGroups,
} from '@/store/admin/selectors';

import {
  addUserGroupAction,
  removeUserGroupAction,
  getGroupUsersAction,
  userGroupAttachByEmail,
  userGroupDetach, setUserGroupPermissionsAction,
} from '@/services/actions/admin/users-main.actions';

import { useAppContext } from '@/AppContext';
import { useAdminContext } from '../../../../AdminContext';

const UsersGroupsContext = createContext({});

const initialFilters = {
  page: 1,
  limit: localStorage.getItem('limit') || 10,
  email: '',
};

export const UsersGroupsContextProvider = ({ children }) => {
  const [groups, setGroups] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupUsers, setSelectedGroupUsers] = useState([]);
  const [isUserTableBusy, setIsUserTableBusy] = useState(false);
  const [isUserGroupsBusy, setIsUserGroupsBusy] = useState(false);
  const [isUserGroupPermissionsBusy, setIsUserGroupPermissionsBusy] = useState(false);

  const [sections, setSections] = useState(null);
  const [filters, setFilters] = useState(initialFilters);

  const loadedGroups = useSelector(getUserGroups);
  const loadedMenu = useSelector(getMenu);

  const changeFiltersHandler = (field, value) => {
    setFilters((prevState) => ({ ...prevState, [field]: value }));
  };

  const {
    SnackTypes,
    setAdminSnack,
    loadUserGroups,
    loadMenu,
  } = useAdminContext();

  const {
    updateUserInfo,
  } = useAppContext();

  const addUserGroup = (groupName) => {
    setIsUserGroupsBusy(true);
    addUserGroupAction(groupName)
      .then((res) => {
        setAdminSnack(
          {
            type: 'success',
            content: `Группа пользователей [${groupName}]\nуспешно добавлена\nне забудьте проставиьт права доступа`,
          },
          SnackTypes.success,
        );
        loadUserGroups()
          .then(() => setIsUserGroupsBusy(false));
        return res;
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setIsUserGroupsBusy(false);
        return err;
      });
  };

  const removeUserGroup = (groupId) => {
    setIsUserGroupsBusy(true);
    removeUserGroupAction(groupId)
      .then((res) => {
        setSelectedGroup(null);
        setAdminSnack(
          {
            type: 'success',
            content: `Группа пользователей '${groups.find((g) => g.id === groupId).title}' (ID[${groupId}])\nуспешно удалена`,
          },
          SnackTypes.success,
        );
        loadUserGroups()
          .then(() => setIsUserGroupsBusy(false));
        return res;
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setIsUserGroupsBusy(false);
        return err;
      });
  };

  const getGetGroupUsersFilterParams = () => {
    const page = filters?.page || 1;
    const limit = filters?.limit || 10;

    const urlParams = {
      limit,
      offset: (page - 1) * limit,
    };

    const searchFilters = {
      email: filters?.email || '',
    };
    return { ...urlParams, ...searchFilters };
  };

  const loadGroupUsers = (groupId) => getGroupUsersAction(groupId, getGetGroupUsersFilterParams())
    .then((res) => {
      setSelectedGroupUsers((res?.results || []).map((u) => {
        const uCAt = new Date(u.dt_create);
        const userName = `${(u?.user?.first_name || '')} ${(u?.user?.last_name || '')}`.trim();
        return {
          ...u,
          created_at: `${uCAt.getFullYear()}.${(uCAt.getMonth() + 1).toString().padStart(2, '0')}.${uCAt.getDate().toString().padStart(2, '0')}\n${uCAt.getHours().toString().padStart(2, '0')}:${uCAt.getMinutes().toString().padStart(2, '0')}`,
          name: userName.length > 0 ? userName : (u?.user?.email || 'email_not_set@server').split('@')[0],
        };
      }));
    })
    .catch((err) => {
      setAdminSnack({ ...err?.payload, type: 'error' }, SnackTypes.error);
    })
    .finally(() => {
      setIsUserTableBusy(false);
    });

  const addUserToGroup = (userEmail) => {
    setIsUserTableBusy(true);
    userGroupAttachByEmail(userEmail, selectedGroup.id)
      .then((res) => {
        setAdminSnack({
          type: 'success',
          content: `Пользователь с почтой '${userEmail}'\nдобавлен в группу '${selectedGroup.title}'`,
        }, SnackTypes.success);
        loadGroupUsers(selectedGroup.id);
        updateUserInfo();
        return res;
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
      })
      .finally(() => {
        setIsUserTableBusy(false);
      });
  };

  const removeUserFromGroup = (userId) => {
    setIsUserTableBusy(true);
    userGroupDetach(userId, selectedGroup.id || 0)
      .then((res) => {
        setAdminSnack({
          type: 'success',
          content: `Пользователь '${selectedGroupUsers.find((u) => u.user.id === userId)?.user?.email || 'no_set_email@server'}'\n удалён из группы '${selectedGroup.title}'`,
        }, SnackTypes.success);
        setSelectedGroupUsers(selectedGroupUsers.filter((u) => u.user.id !== userId));
        loadGroupUsers(selectedGroup.id);
        updateUserInfo();
        return res;
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setIsUserTableBusy(false);
      });
  };

  const prepareGroup = (group) => ({
    id: group.id,
    title: group.name,
    sections: group.menu_items,
  });

  const setUserGroupPermissions = (permissionsList) => {
    setIsUserGroupPermissionsBusy(true);
    setUserGroupPermissionsAction(selectedGroup.id, permissionsList)
      .then(() => {
        setAdminSnack(
          {
            type: 'success',
            content: 'Права группы успешно обновлены',
          },
          SnackTypes.success,
        );
        const newSelectedGroup = { ...selectedGroup, sections: permissionsList };
        setSelectedGroup(newSelectedGroup);
        setIsUserGroupsBusy(true);
        loadUserGroups()
          .then(() => setIsUserGroupsBusy(false));
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => { setIsUserGroupPermissionsBusy(false); });
  };

  useEffect(() => {
    if (!loadedGroups) {
      setIsUserGroupsBusy(true);
      loadUserGroups()
        .then(() => setIsUserGroupsBusy(false));
    } else if (loadedGroups.length > 0) {
      const preparedGroups = loadedGroups.map((lg) => prepareGroup(lg));
      setGroups(preparedGroups);
    }
  }, [loadedGroups]);

  useEffect(() => {
    if (!loadedMenu) {
      loadMenu();
    } else {
      setSections(loadedMenu);
    }
  }, [loadedMenu]);

  useEffect(() => {
    if ((selectedGroup?.id || 0) > 0) {
      setIsUserTableBusy(true);
      loadGroupUsers(selectedGroup.id);
    }
  }, [selectedGroup, filters]);

  const value = {
    setSelectedGroup,
    selectedGroup,
    selectedGroupUsers,
    setSelectedGroupUsers,
    groups,
    sections,
    filters,
    setFilters,
    changeFiltersHandler,
    addUserGroup,
    removeUserGroup,
    loadGroupUsers,
    addUserToGroup,
    removeUserFromGroup,
    isUserTableBusy,
    setIsUserTableBusy,
    isUserGroupsBusy,
    isUserGroupPermissionsBusy,
    setIsUserGroupPermissionsBusy,
    setUserGroupPermissions,
  };

  return (
    <UsersGroupsContext.Provider value={value}>
      {children}
    </UsersGroupsContext.Provider>
  );
};

export const useUsersGroupsContext = () => useContext(UsersGroupsContext);
