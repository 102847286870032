import React from 'react';
import styled from 'styled-components';
import { white } from '@/common/constants/theme';

const CustomTooltip = ({
  active, payload, label, fields, sign = '',
  labelFormatter,
}) => {
  if (active && payload && payload.length && fields) {
    const useLabel = labelFormatter ? labelFormatter(label) : label;
    const usePayload = (payload || [])
      .filter((item) => (parseFloat(item.value || '0') > 0))
      .filter((item) => (fields.find((field) => field.key === item.dataKey)));
    return (
      <StyledTooltip>
        <Value>{useLabel}</Value>
        {(usePayload.length > 0) ? (
          <ItemsWrapper>
            {usePayload.map((item) => {
              const itemLabel = fields.find((field) => field.key === item.dataKey)?.title;
              return (
                <TooltipValueWrapper>
                  <TooltipLabel>{`${itemLabel}:`}</TooltipLabel>
                  <Value>{`${sign}${item.value}`}</Value>
                </TooltipValueWrapper>
              );
            })}
          </ItemsWrapper>
        ) : (
          <TooltipLabel>Нет данных для отображения</TooltipLabel>
        )}
      </StyledTooltip>
    );
  }
  return null;
};

export default CustomTooltip;

const StyledTooltip = styled.div`
  background: #696969;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const Value = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: ${white};
`;

const TooltipLabel = styled(Value)`
  font-weight: 400;
  margin-right: 7px;
`;

const TooltipValueWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;

const ItemsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, auto);
`;
