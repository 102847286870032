import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactHtmlParser from 'react-html-parser';

import styled from 'styled-components';
import {
  inputDisabled,
  primary, secondary,
  greyBorder,
  white, greyWhite,
  greyText,
} from '@/common/constants/theme';

import { useTranslation } from 'react-i18next';

import CheckIconSvg from '@/assets/icons/check30.svg';
import SupportIconSvg from '@/assets/icons/supportIcon.svg';

import Loader from '@/common/components/LoaderSized';
import ModalSubmit from '@/common/components/ModalSubmit';
import ModalNew from '@/common/components/ModalNew';
import MessagesList from './MessagesList';
import CreateMessage from './CreateMessage';

import { usePersonalPageContext } from '../../../../PersonalPageContext';

import {
  EMPTY_MESSAGE,
  ACTIONS, THREAD_STATUS,
  useSupportContext,
} from '../SupportContext';

const portalElement = document.createElement('div');

const Portal = ({ children, element }) => {
  useEffect(() => {
    document.body.appendChild(element);

    return () => {
      document.body.removeChild(element);
    };
  }, [element]);

  return ReactDOM.createPortal(children, element);
};

const SupportModals = () => {
  const { t } = useTranslation('translations');

  const { getDateTimeToDisplay } = usePersonalPageContext();

  const {
    loaders,
    threadDescription,
    selectedThread,
    setSelectedThread,
    selectedMessage,
    setSelectedMessage,
    selectedAction,
    setSelectedAction,
    setMessages,
    setThreadDescription,
    closeThread,
    addMessage,
    isMessagesLoaded,
  } = useSupportContext();

  const [isAddMessageEnabled, setIsAddMessageEnabled] = useState(false);
  const [isThreadToBeClosed, setIsThreadToBeClosed] = useState(false);

  const closeModal = () => {
    setSelectedThread(null);
    setSelectedMessage(EMPTY_MESSAGE);
    setSelectedAction(null);
  };

  const handleCloseThread = () => {
    closeThread(selectedThread.case_id);
    closeModal();
  };

  const handleHideThreadWindow = () => {
    setMessages(null);
    setThreadDescription(null);
    closeModal();
  };

  const handleAddMessage = () => (
    isThreadToBeClosed
      ? setSelectedAction(ACTIONS.confirmThread)
      : addMessage(selectedMessage)
  );

  useEffect(() => {
    setIsThreadToBeClosed(false);
  }, [selectedThread]);

  useEffect(() => {
    setIsAddMessageEnabled(
      isThreadToBeClosed
      || (((selectedMessage?.text || '').length > 0)
      || ((selectedMessage?.attaches || []).length > 0)),
    );
  }, [isThreadToBeClosed, selectedMessage]);

  return (
    <Portal element={portalElement}>
      <ModalSubmit
        type="success"
        icon={CheckIconSvg}
        title={t('personalPage.support.thread.confirmClose.title', selectedThread)}
        isOpen={selectedThread && (selectedAction === ACTIONS.confirmThread)}
        submitButton={t('personalPage.support.thread.confirmClose.button')}
        onSubmit={handleCloseThread}
        onCloseModal={closeModal}
      >
        <CloseModalContent>
          {t('personalPage.support.thread.confirmClose.text')}
          <br />
          <b>{`«${selectedThread?.subject || ''}»`}</b>
        </CloseModalContent>
      </ModalSubmit>
      <ModalNew
        type="normal"
        width="90%"
        maxWidth="900px"
        icon={SupportIconSvg}
        title={t('personalPage.support.thread.confirmClose.title',
          {
            ...selectedThread,
            date: threadDescription?.dt_create
              ? ` от ${getDateTimeToDisplay(threadDescription.dt_create)}`
              : '',
          })}
        isOpen={selectedThread && (selectedAction === ACTIONS.viewThread)}
        onCloseModal={handleHideThreadWindow}
      >
        <ThreadModalContent>
          {(loaders?.messages || false) && <Loader isCentered />}
          {threadDescription ? (
            <ThreadDescription>
              <ThreadTextTitle>{t('personalPage.support.thread.descriptionTitle')}</ThreadTextTitle>
              <ThreadText>
                {(threadDescription?.content_html || '').length > 0
                  ? ReactHtmlParser(threadDescription.content_html)
                  : (threadDescription?.content || '')}
              </ThreadText>
            </ThreadDescription>
          ) : (
            <EmptyThreadDescription>
              {isMessagesLoaded ? t('personalPage.support.thread.emptyDescription') : ''}
            </EmptyThreadDescription>
          )}
          <MessagesList
            longList={selectedThread?.status === THREAD_STATUS.closed}
          />
          {(selectedThread?.status !== THREAD_STATUS.closed) && isMessagesLoaded && (
            <ReplyBlock>
              <ReplyTitle>
                {t('personalPage.support.thread.replyTitle')}
                <TextDivider>{t('common.or')}</TextDivider>
                <CloseThreadSwitch
                  isActive={isThreadToBeClosed}
                  onClick={() => setIsThreadToBeClosed((prevState) => !prevState)}
                >
                  {t('personalPage.support.thread.closeThreadTitle')}
                </CloseThreadSwitch>
              </ReplyTitle>
              <CreateMessage
                isDisabled={isThreadToBeClosed}
              />
              <ActionsBlock>
                <Button
                  disabled={!isAddMessageEnabled}
                  onClick={handleAddMessage}
                >
                  {isThreadToBeClosed
                    ? t('personalPage.support.message.closeThreadButton')
                    : t('personalPage.support.message.submitButton')}
                </Button>
              </ActionsBlock>
            </ReplyBlock>
          )}
        </ThreadModalContent>
      </ModalNew>
    </Portal>
  );
};

export default SupportModals;

const CloseModalContent = styled.div`
  font-size: 1.1rem;
  text-align: center;
  margin: .5rem 0 2rem 0;
`;

const ThreadModalContent = styled.div`
  position: relative;
`;

const ThreadDescription = styled.div`
  display: flex;
  background: ${greyWhite};
  margin: -1rem 0 1rem 0;
  border-radius: 0 0 4px 4px;
  padding: 0.5rem;
  width: calc(100% - 1rem);
  max-height: 100%;
  overflow-y: auto;

  &:empty {
    display: none;
  }

  & > *:not(:last-child) {
    margin-right: .5rem;
  }
`;

const EmptyThreadDescription = styled(ThreadDescription)`
  color: ${greyText};
`;

const ThreadTextTitle = styled.div`
  font-weight: bold;
`;

const ThreadText = styled.div`
  width: 100%;
  white-space: pre-line;
`;

const ReplyBlock = styled.div`
  margin: .75rem;
  padding: 0.25rem 0 0 0;
  border-top: solid 1px ${greyBorder};
`;

const TextDivider = styled.span`
  margin: 0 .5rem;
`;

const CloseThreadSwitch = styled.span`
  color: ${({ isActive }) => (!isActive ? primary : secondary)};
  cursor: pointer;
`;

const ReplyTitle = styled.div`
`;

const ActionsBlock = styled.div`
  position: relative;
  text-align: right;
`;

const Button = styled.button`
  border: none;
  background: ${({ disabled }) => (disabled ? inputDisabled : secondary)};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ disabled }) => (disabled ? greyText : white)};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.14));
`;
