import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UsersMainContainer from './Main/MainContainer';
import UsersGroupsContainer from './Groups/GroupsContainer';
import WithdrawList from './Referrals/WithdrawList';

const UsersContainer = () => (
  <Switch>
    <Route path="/admin/users/main" component={UsersMainContainer} />
    <Route path="/admin/users/groups" component={UsersGroupsContainer} />
    <Route path="/admin/support/users" component={UsersMainContainer} />
    <Route path="/admin/users/withdrawals/referral" component={WithdrawList} />
  </Switch>
);

export default UsersContainer;
