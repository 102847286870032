import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import {
  background01,
  borderSecondary,
  grey,
  greyBorder, inputBorder,
  primary, secondary,
  text01, text04,
  white,
  red,
} from '../constants/theme';
import CalendarIcon from '../../screens/PersonalPageScreen/components/icons/CalendarIcon';
import useOutsideAlerter from '../utils/useOutsideAlerter.util';
import CloseIcon from './icons/CloseIcon';
import ArrowLeftIcon from '../../screens/Admin/components/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../screens/Admin/components/icons/ArrowRightIcon';

const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
const YEARS = [];
for (let i = new Date().getFullYear() + 5; i >= 2013; i -= 1) {
  YEARS.push(i);
}

export const DATE_FIELDS = {
  start: 'd_start',
  end: 'd_end',
};

const PERIOD_ACTIVATORS = [
  'today',
  'week',
  'period',
];

const CALENDAR_POSITIONS = {
  vertical: {
    higher: 'bottom',
    below: 'top',
  },
  horizontal: {
    left: 'right',
    right: 'left',
  },
};

const CALENDAR_HEIGHT = 262;
const CALENDAR_WIDTH = 650;
const MANAGER_PANEL_HEIGHT = 67 + 10;

const DatePickerNew = ({
  onSubmit, values,
  showInputs = true, disableInputs = [],
}) => {
  const date = new Date();
  const { t } = useTranslation(['translations']);

  const formatDate = (dt) => (dt ? format(dt, 'dd.MM.yyyy') : '');

  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeType, setActiveType] = useState('period');
  const [selectedYear, setSelectedYear] = useState(date.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(MONTHS[0]);
  const [searchYearValue, setSearchYearValue] = useState('');
  const [dateStart, setDateStart] = useState(values?.[DATE_FIELDS.start] || '');
  const [dateEnd, setDateEnd] = useState(values?.[DATE_FIELDS.end] || '');
  const [dateStartInput, setDateStartInput] = useState(values?.[DATE_FIELDS.start] || '');
  const [dateEndInput, setDateEndInput] = useState(values?.[DATE_FIELDS.end] || '');
  const [dateStartDisabled, setDateStartDisabled] = useState(
    disableInputs.includes(DATE_FIELDS.start),
  );
  const [dateEndDisabled, setDateEndDisabled] = useState(
    disableInputs.includes(DATE_FIELDS.end),
  );

  const [calendarPosition, setCalendarPosition] = useState({
    vertical: CALENDAR_POSITIONS.vertical.higher,
    horizontal: CALENDAR_POSITIONS.horizontal.right,
  });

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setIsOpen(false));

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isOpen) {
      const onClick = () => {
        if (activeType === 'period') {
          setActiveType('month');
          return;
        }
        if (activeType === 'month') {
          setActiveType('year');
        }
      };
      const label = document.querySelector('.react-calendar__navigation__label__labelText');
      if (label) {
        label.addEventListener('click', onClick);
        return () => {
          label.removeEventListener('click', onClick);
        };
      }
    }
  }, [isOpen, activeType]);

  useEffect(() => {
    if (!YEARS.filter((year) => year.toString().includes(searchYearValue))
      .find((year) => year.toString() === selectedYear)) {
      setSelectedYear(YEARS.filter((year) => year.toString().includes(searchYearValue))[0] || '');
    }
  }, [searchYearValue]);

  useEffect(() => {
    setSelectedYear(date.getFullYear());
    setSelectedMonth(MONTHS[date.getMonth()]);
  }, [activeType]);

  const handleSubmit = () => {
    let startDate = dateStart;
    let endDate = dateEnd;

    const monthIndex = MONTHS.findIndex((month) => month === selectedMonth);
    switch (activeType) {
      case 'month':
        startDate = new Date(selectedYear, monthIndex, 1);
        endDate = new Date(
          selectedYear + (monthIndex === 11 ? 1 : 0), (monthIndex === 11 ? -1 : monthIndex) + 1, 1,
        );
        break;
      case 'year':
        startDate = new Date(selectedYear, 0, 1);
        endDate = new Date(selectedYear + 1, 0, 1);
        break;
      default:
        break;
    }
    setDateStartInput(startDate);
    setDateEndInput(endDate);
    setIsSubmitted(true);
    setIsOpen(false);
  };

  const getCalendarPosition = (element) => {
    const posVertical = MANAGER_PANEL_HEIGHT + CALENDAR_HEIGHT < element.getBoundingClientRect().top
      ? CALENDAR_POSITIONS.vertical.higher
      : CALENDAR_POSITIONS.vertical.below;
    const posHorizontal = CALENDAR_WIDTH + element.getBoundingClientRect().x <= window.innerWidth
      ? CALENDAR_POSITIONS.horizontal.right
      : CALENDAR_POSITIONS.horizontal.left;
    setCalendarPosition({
      vertical: posVertical,
      horizontal: posHorizontal,
    });
  };

  useEffect(() => {
    if (isSubmitted) {
      onSubmit({ dateStart: dateStartInput, dateEnd: dateEndInput });
      setIsSubmitted(false);
    }
  }, [isSubmitted]);

  useEffect(() => {
    setDateStartDisabled(disableInputs.includes(DATE_FIELDS.start));
    setDateEndDisabled(disableInputs.includes(DATE_FIELDS.end));
  }, [disableInputs]);

  return (
    <Container ref={wrapperRef}>
      <FlexWrapper alignCenter>
        {showInputs && (
          <>
            <PeriodInput
              isDisabled={dateStartDisabled}
            >
              {formatDate(dateStartInput)}
              {dateStartInput && (
                <ClearDate
                  isDisabled={dateStartDisabled}
                  onClick={() => {
                    if (dateStartDisabled) return;
                    setDateStartInput('');
                    setDateStart('');
                    setIsSubmitted(true);
                  }}
                >
                  <CloseIcon />
                </ClearDate>
              )}
            </PeriodInput>
            <Text rightMargin={4}>
              -
            </Text>
            <PeriodInput
              isDisabled={dateEndDisabled}
            >
              {formatDate(dateEndInput)}
              {dateEndInput && (
                <ClearDate
                  isDisabled={dateEndDisabled}
                  onClick={() => {
                    if (dateEndDisabled) return;
                    setDateEndInput('');
                    setDateEnd('');
                    setIsSubmitted(true);
                  }}
                >
                  <CloseIcon />
                </ClearDate>
              )}
            </PeriodInput>
          </>
        )}
        <Icon onClick={(e) => {
          getCalendarPosition(e.target);
          setIsOpen((prevState) => !prevState);
        }}
        >
          <CalendarIcon />
        </Icon>
      </FlexWrapper>
      {isOpen && (
        <CalendarContainer position={calendarPosition}>
          <CalendarTypesWrapper>
            <CalendarType
              onClick={() => setActiveType('period')}
              isActive={activeType === 'period'}
              isPeriod
            >
              {t('datepicker.period')}
            </CalendarType>
            <CalendarType
              onClick={() => {
                const dateValue = new Date();
                setDateStart(dateValue);
                setDateEnd(dateValue);
                setActiveType('today');
              }}
              isActive={activeType === 'today'}
            >
              {t('datepicker.today')}
            </CalendarType>
            <CalendarType
              onClick={() => {
                setDateStart(new Date((new Date()) - (7 * 24 * 60 * 60 * 1000)));
                setDateEnd(new Date());
                setActiveType('week');
              }}
              isActive={activeType === 'week'}
            >
              {t('datepicker.week')}
            </CalendarType>
            <CalendarType
              onClick={() => setActiveType('month')}
              isActive={activeType === 'month'}
            >
              {t('datepicker.month')}
            </CalendarType>
            <CalendarType
              onClick={() => setActiveType('year')}
              isActive={activeType === 'year'}
            >
              {t('datepicker.year')}
            </CalendarType>
          </CalendarTypesWrapper>
          <CalendarWrapper>
            <CloseIconWrapper onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </CloseIconWrapper>
            <FlexWrapper bottomMargin={26} flexCenter={activeType === 'month' || activeType === 'year'}>
              {PERIOD_ACTIVATORS.includes(activeType) && (
                <>
                  <StyledCalendarWrapper>
                    <StyledCalendar
                      value={dateStart}
                      maxDate={dateEnd}
                      onChange={(dt) => setDateStart(dt)}
                    />
                  </StyledCalendarWrapper>
                  <StyledCalendarWrapper>
                    <StyledCalendar
                      value={dateEnd}
                      minDate={dateStart}
                      // maxDate={date}
                      onChange={(dt) => setDateEnd(dt)}
                    />
                  </StyledCalendarWrapper>
                </>
              )}
              {activeType === 'month' && (
                <MainContentWrapper>
                  <FlexWrapper flexSb alignCenter bottomMargin={10}>
                    <Icon
                      secondaryColor={selectedYear > 2013}
                      disabled={selectedYear === 2013}
                      onClick={() => setSelectedYear(
                        (prevState) => (prevState > 2013 ? prevState - 1 : prevState),
                      )}
                      leftMargin={20}
                    >
                      <ArrowLeftIcon />
                    </Icon>
                    <Text secondaryColor>
                      {selectedYear}
                    </Text>
                    <Icon
                      secondaryColor={selectedYear < date.getFullYear()}
                      disabled={selectedYear === date.getFullYear()}
                      onClick={() => setSelectedYear(
                        (prevState) => (prevState < date.getFullYear() ? prevState + 1 : prevState),
                      )}
                      rightMargin={20}
                    >
                      <ArrowRightIcon />
                    </Icon>
                  </FlexWrapper>
                  <FlexWrapper flexSb>
                    {MONTHS.map((month, i) => (
                      <Month
                        key={month}
                        isActive={month === selectedMonth}
                        onClick={() => setSelectedMonth(month)}
                        disabled={selectedYear === date.getFullYear() && date.getMonth() < i}
                      >
                        <Text>
                          {t(`datepicker.months.${month}`)}
                        </Text>
                      </Month>
                    ))}
                  </FlexWrapper>
                </MainContentWrapper>
              )}
              {activeType === 'year' && (
                <MainContentWrapper>
                  <Input
                    value={searchYearValue}
                    onChange={({ target }) => setSearchYearValue(target.value)}
                    placeholder={t('datepicker.enterYear')}
                  />
                  <GridWrapper>
                    {YEARS.filter((year) => year.toString().includes(searchYearValue))
                      .map((year) => (
                        <Year
                          key={year}
                          isActive={year === selectedYear}
                          onClick={() => setSelectedYear(year)}
                        >
                          <Text>
                            {year}
                          </Text>
                        </Year>
                      ))}
                  </GridWrapper>
                </MainContentWrapper>
              )}
            </FlexWrapper>
            <ConfirmText onClick={handleSubmit}>
              {t('datepicker.apply')}
            </ConfirmText>
          </CalendarWrapper>
        </CalendarContainer>
      )}
    </Container>
  );
};

export default DatePickerNew;

const Container = styled.div`
  position: relative;
`;

const CalendarContainer = styled.div`
  display: flex;

  min-width: 650px;
  height: 262px;

  position: absolute;
  ${({ position }) => (`${position.vertical}: calc(100% + 5px);`)}
  ${({ position }) => (`${position.horizontal}: 0;`)}

  z-index: 100;

  background-color: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 3px;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.07);
`;

const CalendarWrapper = styled.div`
  width: 100%;
  padding: 16px 20px 10px 40px;
`;

const MainContentWrapper = styled.div`
  width: 290px;
  margin-top: 20px;
`;

const CalendarTypesWrapper = styled.div`
  width: 125px;
  padding-bottom: 16px;

  border-right: 1px solid ${greyBorder};
`;

const CalendarType = styled.div`
  display: flex;
  align-items: center;

  height: ${({ isPeriod }) => (isPeriod ? '80' : '41')}px;
  padding: 0 16px;
  margin-right: -1px;

  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: ${grey};
  line-height: 20px;

  ${({ isActive, isPeriod }) => isActive && (`
    background-color: ${white};
    ${!isPeriod ? `border-top-color: ${greyBorder};` : ''}
    border-bottom-color: ${greyBorder};

    color: ${text01};
  `)}
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 11px;

  cursor: pointer;

  & svg path {
    fill: ${text04};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ flexCenter }) => flexCenter && 'justify-content: center;'}
  ${({ flexSb }) => flexSb && 'justify-content: space-between;'}
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}

  width: 100%;
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}
`;

const ConfirmText = styled.div`
  position: absolute;
  right: 20px;
  bottom: 10px;

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${primary};
  line-height: 20px;
`;

const Icon = styled.div`
  ${({ leftMargin }) => leftMargin && `margin-left: ${leftMargin}px;`}
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`}
  padding: 0 6px;

  position: relative;
  top: 6px;

  cursor: pointer;

  ${({ secondaryColor }) => secondaryColor && `
    & svg path {
      fill: ${secondary};
    }
  `}

  ${({ disabled }) => disabled && `
    & svg path {
      fill: ${text04};
    }
  `}
`;

const Text = styled.div`
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`}

  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  color: ${({ secondaryColor }) => (secondaryColor ? secondary : text01)};
  text-transform: uppercase;
  line-height: 17px;
`;

const Month = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70px;
  height: 30px;
  padding: 0 8px;
  margin-bottom: 6px;

  border-radius: 10px;
  cursor: pointer;

  &:not(:nth-child(3n)) {
    margin-right: 36px;
  }

  ${({ isActive }) => isActive && `
    background-color: ${primary};

    & ${Text} {
      color: ${white};
    }
  `}

  ${({ disabled }) => disabled && `
    opacity: 0.6;
    pointer-events: none;
  `}
`;

const StyledCalendarWrapper = styled.div`
  width: 190px;

  position: relative;

  &:first-child {
    margin-right: 60px;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 32px;
  padding: 0 12px;
  margin-bottom: 10px;

  background-color: ${white};
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  outline: none;
`;

const Year = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 30px;
  padding: 0 8px;
  margin-bottom: 8px;

  border-radius: 10px;
  cursor: pointer;

  &:not(:nth-child(3n)) {
    margin-right: 60px;
  }

  ${({ isActive }) => isActive && `
    background-color: ${primary};

    & ${Text} {
      color: ${white};
    }
  `}
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  justify-content: space-between;

  max-height: 150px;

  overflow-y: auto;
`;

const PeriodInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  height: 24px;
  width: 100px;
  margin-right: 4px;

  background: ${background01};
  border: 1px solid ${inputBorder};

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: ${({ isDisabled }) => (isDisabled ? grey : text01)};;
  line-height: 20px;
`;

const ClearDate = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  padding: .25rem;

  & svg{
    stroke: ${({ isDisabled }) => (isDisabled ? grey : red)};
  }
`;

const StyledCalendar = styled(Calendar)`
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button {
    padding-left: 5px !important;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    cursor: pointer;
  }

  .react-calendar__navigation__next-button {
    left: 20px;
  }

  .react-calendar__tile {
    width: 24px;
    max-width: 24px !important;
    height: 24px;
    padding: 0;
    margin-right: 2px;

    background-color: transparent;
    border: none;
    border-radius: 100px;
    cursor: pointer;

    font-family: Gilroy, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: ${text01};
    text-align: center;
    line-height: 14px;
    outline: none;
  }

  .react-calendar__tile[disabled] {
    color: ${text04};
  }

  .react-calendar__tile--active {
    background-color: ${primary};
    color: ${white};
  }

  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label {
    background-color: transparent;
    border: none;
    border-radius: 100px;
    position: absolute;
    top: .55rem;
  }

  .react-calendar__navigation__label {
    cursor: pointer;

    font-family: Gilroy, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${text01};
    text-transform: uppercase;
    left: 50px;
    top: 1.25rem;
  }

  .react-calendar__month-view__weekdays {
    display: none !important;
  }

  .react-calendar__navigation__arrow {
    padding: 0 12px;
    font-size: 24px;
    color: ${secondary};
    outline: none;
    height: 37px;
    :disabled {
      color: ${text04};
    }
  }

  .react-calendar__month-view__days {
    align-items: center;

    min-height: 144px;
  }
  .react-calendar__viewContainer {
    margin-top: 3rem;
  }
`;
