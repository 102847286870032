import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ModalNew from '@/common/components/ModalNew';
import alertIcon from '@/assets/icons/alertIcon.svg';
import { text01 } from '@/common/constants/theme';
import Dropdown from '@/common/components/Dropdown';
import Button from '@/common/components/Button';
import { useAdminDNSContext } from '../DNSContext';

const AdminDNSTransferDataModal = ({
  isOpen,
  onClose,
  deletedId,
  setDeletedId,
  setIsTransferModal,
}) => {
  const { domains, deleteDomainById } = useAdminDNSContext();
  const [domainsOptions, setDomainsOptions] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState({});

  useEffect(() => {
    if (domains.length) {
      const options = [];
      domains.forEach((domain) => {
        if (deletedId < 0 || domains[deletedId].id !== domain.id) {
          options.push({ title: domain.name, key: domain.url, id: domain.id });
        }
      });
      setDomainsOptions(options);
      setSelectedDomain(options[0]);
    }
  }, [domains, deletedId]);

  return (
    <ModalNew
      isOpen={isOpen}
      onCloseModal={onClose}
      icon={alertIcon}
      title="Сервер"
      isHidden={false}
    >
      <Container>
        <Text>
          Выберите DNS для переноса пользователей:
        </Text>
        <StyledDropdown
          options={domainsOptions}
          value={selectedDomain}
          onSelect={(option) => setSelectedDomain(option)}
          height={32}
        />
        <Wrapper>
          <StyledButton
            isBoldText
            isUpperCase
            onClick={() => {
              deleteDomainById(
                domains[deletedId].id,
                domains.find((domain) => domain.id === selectedDomain.id).id,
              );
              setIsTransferModal(false);
              setDeletedId(-1);
            }}
          >
            Прменить
          </StyledButton>
        </Wrapper>
      </Container>
    </ModalNew>
  );
};

export default AdminDNSTransferDataModal;

const Container = styled.div`
  padding: 4px 8px 18px 2px;
`;

const Text = styled.div`
  margin-bottom: 20px;

  font-family: Gilroy;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  margin-bottom: 60px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 0 17px;
  margin-left: auto;
`;
