import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import i18n from '@/i18n';
import { useTranslation } from 'react-i18next';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import styled from 'styled-components';
import {
  secondary, activePrimary, red, white, text01,
} from '@/common/constants/theme';

import { Checkbox } from 'antd';
import ReCaptcha from '@/common/components/ReCaptcha';

import Loader from '@/common/components/LoaderFullScreen';
import Input from '@/common/components/AuthorizationInput';
import AuthorizationLayout from '../components/AuthorizationLayout';

import { signInAction } from '@/services/actions/authorization.actions';
import auth from '@/services/auth.service';

import { useAppContext } from '@/AppContext';

export default function SignInContainer({ history, location }) {
  const { t } = useTranslation('translations');
  const [isLoading, setLoading] = useState(false);
  const { getUser } = useAppContext();
  const [remember, setRemember] = useState(true);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);

  const [isHuman, setIsHuman] = useState(true);
  const [captchaToken, setCaptchaToken] = useState('');

  const [fingerPrint, setFingerPrint] = useState(null);

  const baseUrl = location.state?.baseUrl || '/personal-page/devices';

  const handleCaptcha = async (v) => {
    setIsHuman(!!v);
    setCaptchaToken(v);
  };

  const onSignIn = () => {
    if (!email) {
      setEmailError(t('authorization.emptyEmail'));
    }
    if (!password) {
      setPasswordError(t('authorization.emptyPassword'));
    }
    if (email && password) {
      setLoading(true);
      setPasswordError(null);
      setEmailError(null);

      const data = {
        username: email,
        password,
      };

      if (captchaToken.length > 0) {
        data.captcha_token = captchaToken;
        data.fingerprint_token = fingerPrint;
      }

      signInAction(data)
        .then((res) => {
          const lang = res.frontend_language;
          i18n.changeLanguage(lang);
          // localStorage.setItem('locale', lang);
          setLoading(false);
          if (res.token) {
            auth.setToken(res.token);
            getUser()
              .then(() => {
                const redirectUrl = sessionStorage.getItem('redirectUrl') || baseUrl;
                sessionStorage.removeItem('redirectUrl');
                history.push(redirectUrl);
                history.push('/personal-page');
              })
              .catch((err) => {
                setError(t(`authorization.signIn.${err.message_type}`));
                // eslint-disable-next-line no-console
                console.error(err);
              });
          }
        })
        .catch((err) => {
          let msgType = err.message_type;
          if (err?.errors?.is_captcha_required
            || err?.errors?.captcha_token) {
            setIsHuman(false);
            msgType = 'captchaNeeded';
          } else {
            setPasswordError(true);
            setEmailError(true);
          }
          setError(t(`authorization.signIn.${msgType}`));
          setLoading(false);
        })
        .finally(() => {
          if (captchaToken.length > 0) {
            setCaptchaToken('');
            setIsHuman(false);
          }
        });
    }
  };

  useEffect(() => {
    if (!fingerPrint) {
      FingerprintJS.load()
        .then((fp) => fp.get())
        .then((result) => {
          setFingerPrint(result.visitorId || '-');
        });
    }
  }, []);

  return (
    <AuthorizationLayout>
      {isLoading && <Loader />}
      <Title>
        <TitleText>{t('authorization.signIn.textBlack1')}</TitleText>
        <TitleTextOrange>{t('authorization.signIn.textOrange')}</TitleTextOrange>
        <TitleText>{t('authorization.signIn.textBlack2')}</TitleText>
      </Title>
      <Form>
        <ErrorMessage>{error}</ErrorMessage>
        <Input
          placeholder="Email"
          value={email}
          onKeyUp={({ target, keyCode }) => {
            if (keyCode === 13) {
              setEmail(target.value);
              if ((password?.length || 0) > 0 && (passwordError?.length || 0) === 0) {
                onSignIn();
              }
            }
          }}
          onChange={({ target }) => setEmail(target.value)}
          errorText={emailError}
        />
        <Input
          placeholder={t('authorization.password')}
          type="password"
          value={password}
          onKeyUp={({ target, keyCode }) => {
            if (keyCode === 13) {
              setPassword(target.value);
              if ((email?.length || 0) > 0 && (emailError?.length || 0) === 0) {
                onSignIn();
              }
            }
          }}
          onChange={({ target }) => setPassword(target.value)}
          errorText={passwordError}
        />
        <ForgotPassword to="/forgot-password">
          {t('authorization.signIn.forgotPassword')}
        </ForgotPassword>
        {isHuman ? (
          <>
            <StyledCheckbox
              checked={remember}
              onChange={({ target }) => setRemember(target.checked)}
            >
              {t('authorization.remember')}
            </StyledCheckbox>
            <Button onClick={onSignIn}>
              {t('authorization.signInButton')}
            </Button>
          </>
        ) : (
          <ReCaptcha
            appToken={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
            onCheck={handleCaptcha}
          />
        )}
        <SignUpContainer>
          <Text>{t('authorization.signIn.noAccaunt')}</Text>
          <SignUpLink to="/sign-up">{t('authorization.signUpButton')}</SignUpLink>
        </SignUpContainer>
      </Form>
    </AuthorizationLayout>
  );
}

const Title = styled.div`
  margin-top: 10rem;
  width: 40rem;
`;

const TitleText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;
`;

const TitleTextOrange = styled(TitleText)`
  color: ${secondary}
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const ForgotPassword = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${secondary};
  margin-top: -1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;

  &&:hover {
    color: #ffab30;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin: .5rem 0;

  & .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${activePrimary};
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${activePrimary};
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${activePrimary};
    border: 1px solid ${white};
  }

  & .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${white};
  }

  & .ant-checkbox-checked::after {
    border-color: ${activePrimary};
  }

  & .ant-checkbox + span {
    font-family: Gilroy, sans-serif;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: rgba(32, 32, 32, 0.6);
  }
`;

const Button = styled.button`
  margin: .5rem 0;
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const SignUpContainer = styled.div`
  margin: .5rem 0;
`;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: rgba(32, 32, 32, 0.6);
  margin-right: .5rem;
`;

const SignUpLink = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${secondary};

  &&:hover {
    color: #ffab30;
  }
`;

const ErrorMessage = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${red};
  margin-bottom: 1rem;
`;
