import React from 'react';

export default () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.43701 7.25C7.43904 7.25 9.06201 5.62703 9.06201 3.625C9.06201 1.62297 7.43904 0 5.43701 0C3.43498 0 1.81201 1.62297 1.81201 3.625C1.81201 5.62703 3.43498 7.25 5.43701 7.25Z" fill="#FF9800" />
    <path d="M23.562 7.25C25.564 7.25 27.187 5.62703 27.187 3.625C27.187 1.62297 25.564 0 23.562 0C21.56 0 19.937 1.62297 19.937 3.625C19.937 5.62703 21.56 7.25 23.562 7.25Z" fill="#FF9800" />
    <path d="M14.4995 7.25C16.5015 7.25 18.1245 5.62703 18.1245 3.625C18.1245 1.62297 16.5015 0 14.4995 0C12.4975 0 10.8745 1.62297 10.8745 3.625C10.8745 5.62703 12.4975 7.25 14.4995 7.25Z" fill="#FF9800" />
    <path d="M19.9316 18.9406L19.1069 10.6956C19.0145 9.76394 18.2387 9.0625 17.3053 9.0625H11.6956C10.7603 9.0625 9.98459 9.76394 9.89215 10.6938L9.06746 18.9406C9.04209 19.1962 9.12546 19.4481 9.29765 19.6403C9.46802 19.8288 9.71271 19.9375 9.96827 19.9375H10.9597L11.7028 27.3688C11.7953 28.2968 12.5728 29 13.5081 29H15.4928C16.428 29 17.2038 28.2968 17.2962 27.3688L18.0393 19.9375H19.0308C19.2882 19.9375 19.531 19.8288 19.7032 19.6403C19.8754 19.4499 19.9588 19.1962 19.9316 18.9406Z" fill="#FF9800" />
    <path d="M7.9564 20.8601C7.43621 20.2801 7.18609 19.5206 7.26221 18.7594L8.0869 10.5143C8.13765 10.0014 8.32434 9.54462 8.56177 9.12231C8.45483 9.10237 8.35515 9.0625 8.24277 9.0625H2.63309C1.69784 9.0625 0.922086 9.76394 0.829649 10.6938L0.00496109 18.9406C-0.0204139 19.1962 0.0629611 19.4481 0.235149 19.6403C0.405524 19.8288 0.650211 19.9375 0.905774 19.9375H1.89721L2.64034 25.5563C2.73277 26.4843 3.51034 27.1875 4.44559 27.1875H6.43027C7.36552 27.1875 8.14127 26.4843 8.23371 25.5563L8.77746 21.4491C8.47296 21.3005 8.1884 21.1156 7.9564 20.8601Z" fill="#FF9800" />
    <path d="M28.9943 18.937L28.1696 10.6956C28.0772 9.76394 27.3033 9.0625 26.368 9.0625H20.7583C20.6441 9.0625 20.5444 9.10237 20.4357 9.12231C20.6731 9.54463 20.8598 10.0014 20.9106 10.5143L21.7353 18.7594C21.8132 19.5134 21.5649 20.2783 21.0519 20.8492C20.8163 21.1084 20.5281 21.2951 20.2236 21.4455L20.7674 25.5526C20.8598 26.4824 21.6356 27.1839 22.5708 27.1839H24.5555C25.4908 27.1839 26.2665 26.4806 26.359 25.5526L27.1021 19.9339H28.0935C28.3509 19.9339 28.5938 19.8251 28.766 19.6366C28.9381 19.4463 29.0215 19.1926 28.9943 18.937Z" fill="#FF9800" />
  </svg>
);
