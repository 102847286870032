import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import {
  AdminMainPopupContent,
  AdminMainPopupHeader,
  StyledButton,
  Text,
} from '../../../../components/MainPopupComponents';

const data = `Перегенерация была успешно завершена 17.12.2020 в 16:55
Перегенерация была успешно завершена 16.12.2020 в 16:56
Перегенерация была успешно завершена 15.12.2020 в 16:57`;

const BaseRegenerateCache = () => {
  const history = useHistory();

  return (
    <Container>
      <AdminMainPopupHeader
        title="Перегенерация кеша плейлистов"
        linkText="Вернуться “Каналы. Базовые настройки”"
        handleClose={() => history.push('/admin/iptv/base')}
      />
      <AdminMainPopupContent
        descr="Лог перегенерации: время запуска - пользователем"
        isData={false}
        data=""
      />
      <Wrapper>
        <StyledButton isBoldText isUpperCase>
          Перегенерация кеша плейлистов
        </StyledButton>
      </Wrapper>
      <StyledText>{data}</StyledText>
    </Container>
  );
};

export default BaseRegenerateCache;

const Container = styled.div``;

const Wrapper = styled.div``;

const StyledText = styled(Text)`
  margin-top: 40px;
  margin-left: 18px;

  white-space: pre-wrap;
  line-height: 25px;
`;
