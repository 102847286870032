import React from 'react';
import ModalNew from './ModalNew';
import styled from 'styled-components';
import Button from './Button';

export default function ModalSubmit(props) {
  const {
    submitButton, onSubmit, children, ...rest
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ModalNew noCloseOnClick {...rest}>
      {children}
      <Actions>
        <Button onClick={onSubmit}>{submitButton}</Button>
      </Actions>
    </ModalNew>
  );
}

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
`;
