import React from 'react';

import { GroupsContextProvider } from './GroupsContext';
import Groups from './Groups';

const GroupsContainer = () => (
  <GroupsContextProvider>
    <Groups />
  </GroupsContextProvider>
);

export default GroupsContainer;
