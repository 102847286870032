import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  white,
  grey,
  secondary,
  red,
  greyWhite,
  text01,
} from '@/common/constants/theme';
import Checkbox from '@/common/components/Checkbox';
import DeleteIcon from '../../../../../../icons/DeleteIcon';
import RestoreIcon from '../../../../../../icons/RestoreIcon';
import { useUsersGroupsContext } from '../../../GroupsContext';

import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';

const ACTIONS = {
  notSelected: 'notSelected',
  deleteGroup: 'deleteGroup',
};

const UsersGroupsMainInfo = ({ isOpen, group }) => {
  const {
    removeUserGroup,
  } = useUsersGroupsContext();

  const [selectedAction, setSelectedAction] = useState(ACTIONS.notSet);
  const [localPermissions, setLocalPermissions] = useState([]);
  const [isPermissionsChanged, setIsPermissionsChanged] = useState(false);

  const {
    sections,
    isUserGroupPermissionsBusy,
    setUserGroupPermissions,
  } = useUsersGroupsContext();

  const deleteGroup = () => {
    setSelectedAction(ACTIONS.notSelected);
    removeUserGroup(group.id);
  };

  const checkIfPermissionsChanged = () => {
    if (!group?.sections) {
      return false;
    }
    const addedPermissions = localPermissions.filter((li) => !group.sections.includes(li));
    const removedPermissions = group.sections.filter((gi) => !localPermissions.includes(gi));
    const isChanged = (removedPermissions.length + addedPermissions.length) > 0;
    setIsPermissionsChanged(isChanged);
    return isChanged;
  };

  const selectSectionItem = (item, v) => {
    if (v && !localPermissions.includes(item)) {
      setLocalPermissions([...localPermissions, item]);
    } else if (!v && localPermissions.includes(item)) {
      setLocalPermissions([...localPermissions].filter((l) => l !== item));
    }
  };

  const selectSection = (section, v) => {
    const sectionChecked = section.links
      .filter((l) => localPermissions.includes(l.id))
      .map((i) => i.id);
    const sectionUnChecked = section.links
      .filter((l) => !localPermissions.includes(l.id))
      .map((i) => i.id);

    setLocalPermissions(v
      ? [...localPermissions, ...sectionUnChecked]
      : localPermissions.filter((lp) => !sectionChecked.includes(lp)));
  };

  const resetPermissions = () => {
    setLocalPermissions([...group.sections]);
  };

  useEffect(() => {
    if (group) {
      setLocalPermissions([...group.sections]);
    }
  }, [group]);

  useEffect(() => {
    checkIfPermissionsChanged();
  }, [localPermissions]);

  return (
    <>
      {isOpen && sections && (
        <Container>
          {(group.id > -1) && (
            <ModalWithoutContent
              color="danger"
              isOpen={selectedAction === ACTIONS.deleteGroup}
              onCloseModal={() => setSelectedAction(ACTIONS.notSelected)}
            >
              <TextModal>
                Вы хотите удалить группу
              </TextModal>
              <TextModal largeFontSize>
                {group.title}
              </TextModal>
              <TextModal marginBottom={12}>
                {`ID [${group.id || -1}]`}
              </TextModal>
              <ButtonsWrapper>
                <CancelButton onClick={() => setSelectedAction(ACTIONS.notSelected)}>
                  Отмена
                </CancelButton>
                <StyledButton
                  isBoldText
                  isUpperCase
                  onClick={deleteGroup}
                >
                  Да
                </StyledButton>
              </ButtonsWrapper>
            </ModalWithoutContent>
          )}
          <Header>
            <Wrapper flex flexSb alignCenter bottomMargin={6}>
              <Title>
                {group.title}
              </Title>
              {(group.id > 1) && (
                <Icon
                  onClick={() => setSelectedAction(ACTIONS.deleteGroup)}
                >
                  <DeleteIcon />
                </Icon>
              )}
            </Wrapper>
            <Wrapper flex flexSb alignCenter>
              <Text>
                Доступ к разделам:
              </Text>
              <ActionButtonsContainer>
                {isPermissionsChanged && (
                  <RestoreIconContainer
                    title="Сбросить несохранённые изменения"
                    onClick={() => resetPermissions()}
                  >
                    <RestoreIcon />
                  </RestoreIconContainer>
                )}
                <SaveButton
                  disabled={!isPermissionsChanged}
                  onClick={() => { setUserGroupPermissions(localPermissions); }}
                >
                  Сохранить
                </SaveButton>
              </ActionButtonsContainer>
            </Wrapper>
          </Header>
          <List isBusy={isUserGroupPermissionsBusy}>
            {isUserGroupPermissionsBusy && <Loader />}
            {Object.entries(sections).map(([sectionCode, section]) => (
              <CheckboxesWrapper key={`${sectionCode}--userRightsGroupCheckBox`}>
                <StyledCheckbox
                  onChange={(v) => selectSection(section, v)}
                  checked={!!section.links
                    .filter((item) => localPermissions.includes(item.id)).length}
                  label={section.title}
                  bold
                />
                {section.links.map((item) => (
                  <StyledCheckbox
                    key={`${item.id}--userRightsGroupCheckBoxItem`}
                    onChange={(v) => selectSectionItem(item.id, v)}
                    checked={localPermissions.includes(item.id)}
                    label={item.title}
                    leftMargin
                  />
                ))}
              </CheckboxesWrapper>
            ))}
          </List>
        </Container>
      )}
    </>
  );
};

export default UsersGroupsMainInfo;

const Container = styled.div`
  width: 400px;
  height: 500px;
  padding: 20px 20px 0;
  margin-right: 30px;

  background: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && 'display: flex;'}
  ${({ flexSb }) => flexSb && 'justify-content: space-between;'}
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RestoreIconContainer = styled.div`
  display: inline-block;
  height: 16px;
  margin-right: 0.5rem;
  cursor: pointer;

  opacity: .75;
  transition: all .25s ease-in 0s;

  &:hover{
    opacity: 1;
  }

  & svg{
    fill: ${red};
  }
`;

const SaveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 24px;
  height: 32px;

  background-color: transparent;
  border: 1px solid ${({ disabled }) => (!disabled ? secondary : grey)};
  border-radius: 2px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ disabled }) => (!disabled ? secondary : grey)};
  text-transform: uppercase;
  line-height: 24px;

  transition: all 0.25s ease-in 0s;
`;

const Icon = styled.div`
  cursor: pointer;
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  color: ${text01};
  line-height: 15px;
`;

const Title = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const Header = styled.div`
  margin-bottom: 30px;

  position: relative;

  &::after {
    content: '';

    height: 1px;

    position: absolute;
    bottom: -12px;
    left: -20px;
    right: -20px;

    background-color: ${borderSecondary};
  }
`;

const List = styled.div`
  position: relative;
  max-height: 386px;

  overflow-y: ${({ isBusy }) => (isBusy ? 'hidden' : 'auto')};
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ leftMargin }) => leftMargin && 'margin-left: 28px;'}
  margin-bottom: 8px;

  ${({ bold }) => bold && `
    & span {
      font-weight: bold;
    }
  `}
`;

const CheckboxesWrapper = styled.div`
  margin-bottom: 16px;
`;

const TextModal = styled.div`
  margin-bottom: ${({ marginBottom }) => (marginBottom || 6)}px;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${white};
  text-align: left;
  line-height: 15px;

  ${({ largeFontSize }) => largeFontSize && `
    margin-bottom: 8px;

    font-size: 20px;
    line-height: 20px;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 280px;
  margin-top: 10px;
`;

const CancelButton = styled.button`
  margin-left: auto;

  background-color: transparent;
  border: none;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${greyWhite};
  text-decoration: underline;
  text-transform: uppercase;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  width: 54px;
  height: 48px;
  margin-left: 24px;
`;
