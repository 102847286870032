import React, {
  createContext, useContext,
  useState, useEffect,
} from 'react';

import {
  getFaqListAction,
  getFaqItemAction, addFaqItemAction,
  removeFAQItemAction, updateFaqItemAction,
  uploadImageAction, deleteImageAction,
  loadTagsListAction, loadTagAction,
  addTagAction, deleteTagAction, editTagAction,
} from '@/services/actions/admin/faq.actions';

import { SnackTypes } from '@/AppContext';
import { useAdminContext } from '../../../AdminContext';

export const BASE_PATH = '/admin/public/faq/';
export const DATA_TYPES = {
  faqList: 'faqList',
  faqItem: 'faqItem',
  faqAnswer: 'faqAnswer',
};
export const EMPTY_LANGS_OPTION = { key: '-', title: 'Все' };
export const EMPTY_OPTION = { key: '-', title: 'Все' };

const FAQContext = createContext({});

const initialFilters = {
  page: 1,
  limit: parseInt(localStorage.getItem('adminFAQListLimit') || '10', 10),
  search: '',
  language: null,
  is_published: null,
};

export const FAQContextProvider = ({ children }) => {
  const [dataProcessing, setDataProcessing] = useState({});
  const [filters, setFilters] = useState(initialFilters);
  const [FAQDataList, setFAQDataList] = useState([]);
  const [FAQDataCount, setFAQDataCount] = useState(0);
  const [selectedFAQItem, setSelectedFAQItem] = useState(null);

  const {
    setAdminSnack,
  } = useAdminContext();

  const loadFAQList = () => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqList]: true }));
    return getFaqListAction(filters)
      .then((res) => {
        setFAQDataList((res?.results || []).sort((a, b) => {
          const aOrder = parseInt(a?.order || '0', 10);
          const bOrder = parseInt(b?.order || '0', 10);
          if (aOrder === bOrder) return 0;
          return aOrder - bOrder > 0 ? 1 : -1;
        }));
        setFAQDataCount(res?.count || 0);
      })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqList]: false }));
      });
  };

  const clearSelectedFAQItem = () => {
    setSelectedFAQItem(null);
  };

  const loadFAQItem = async (id = null) => {
    const useId = id || 0;
    if (useId === 0) {
      const errMsg = 'ID элемента не передан';
      setAdminSnack({ type: SnackTypes.error, content: errMsg }, SnackTypes.error);
      return Promise.reject(new Error(errMsg));
    }
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqItem]: true }));
    return getFaqItemAction(id)
      .then((res) => { setSelectedFAQItem(res); return res; })
      .catch((err) => setAdminSnack(err, SnackTypes.error))
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqItem]: false }));
      });
  };

  const addFAQItem = (faqItem) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqItem]: true }));
    const { images, ...data } = faqItem;
    return addFaqItemAction(data)
      .then((res) => {
        loadFAQList();
        return res;
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        return err;
      })
      .finally(() => {
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqItem]: false }));
      });
  };

  const updateFAQItem = (item) => {
    const useItem = item || selectedFAQItem;
    if (!useItem) {
      setAdminSnack({ type: SnackTypes.error, content: 'Неверные данные для обновления' },
        SnackTypes.error);
      return Promise.reject();
    }
    const { id, images, ...data } = item;

    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqList]: true }));
    return updateFaqItemAction(id, data)
      .then((res) => {
        loadFAQList();
        return res;
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqList]: false }));
        return err;
      });
  };

  const deleteFAQItem = (id) => {
    setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqList]: true }));
    return removeFAQItemAction(id)
      .then(() => {
        const { limit, page } = filters;
        if (FAQDataCount - 1 < limit * page) {
          setFilters((prevState) => ({ ...prevState, page: (prevState.page - 1) }));
        } else {
          loadFAQList();
        }
      })
      .catch((err) => {
        setAdminSnack(err, SnackTypes.error);
        setDataProcessing((prevState) => ({ ...prevState, [DATA_TYPES.faqList]: false }));
      });
  };

  const uploadImage = (file, tmpId) => uploadImageAction(
    file,
    window.customData.faqId || null,
    tmpId,
  ).then((res) => {
    if (selectedFAQItem) {
      setSelectedFAQItem((prevState) => (
        { ...prevState, images: [...(prevState?.images || []), res] }));
    }
    return res;
  });

  const deleteImage = (id) => (parseInt(id || '0', 10) > 0 ? deleteImageAction(id) : null);

  const loadTagsList = () => loadTagsListAction();
  const loadTag = (id) => loadTagAction(id);
  const addTag = (text) => addTagAction(text);
  const editTag = (id, newText) => editTagAction(id, newText);
  const deleteTag = (id) => deleteTagAction(id);

  useEffect(() => {
    loadFAQList();
  }, [filters]);

  useEffect(() => {
    localStorage.setItem('adminFAQListLimit', filters?.limit || 10);
  }, [filters?.limit]);

  const value = {
    dataProcessing,
    FAQDataList,
    FAQDataCount,
    selectedFAQItem,
    filters,
    setFilters,

    clearSelectedFAQItem,
    loadFAQItem,
    deleteFAQItem,
    addFAQItem,
    updateFAQItem,
    uploadImage,
    deleteImage,

    loadTagsList,
    loadTag,
    addTag,
    editTag,
    deleteTag,
  };

  return (
    <FAQContext.Provider value={value}>
      {children}
    </FAQContext.Provider>
  );
};

export const useFAQContext = () => useContext(FAQContext);
