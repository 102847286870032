/* eslint no-unused-vars: 0 */
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';

import { useSelector } from 'react-redux';
import {
  setSnackFromErrorResponse,
} from '@/store/admin/actions/common';
import {
  getPaymentSystems,
} from '@/store/admin/selectors';

import {
  getMoneyGeneralDataAction,
  getMoneyPaymentTransactionsAction,
  getMoneyUserBalanceAction,

  getUserGeneralDataAction,
  getUserUsersDataAction,
  getUserReferralDataAction,
  getUserDevicesDataAction,

  getFinanceDataAction,
} from '@/services/actions/admin/statistics.actions';

import { useAdminContext } from '../../../AdminContext';
import { format } from 'date-fns';

const StatisticsContext = createContext({});

const INIT_DAYS_COUNT = 7;
const DATE_FORMAT = 'yyyy-MM-dd';
const FILTER_DATE_FORMAT = 'MM.dd.yyyy';
const CHARTS_DATE_FORMAT = 'MM.dd.yyyy';

export const SEARCH_FILTER_TIMEOUT = 1000 * 1.5;

export const DATA_TYPES = {
  moneyGeneral: 'moneyGeneral',
  moneyPayments: 'moneyPayments',
  moneyUserBalance: 'moneyUserBalance',

  userGeneral: 'userGeneral',
  userUsers: 'userUsers',
  userReferral: 'userReferral',
  userDevices: 'userDevices',

  finance: 'finance',
};

export const CHART_COLORS = [
  '#6A4C93', '#FFCA3A', '#8AC926', '#478ECC',
  'DeepPink', 'LawnGreen', 'SlateBlue', 'Gold', 'DarkBlue',
  'Tomato', 'DarkOrchid', 'GreenYellow', 'Crimson',
];

const initMoneyStatsFilter = {
  d_begin: new Date((new Date()) - (1000 * 60 * 60 * 24 * INIT_DAYS_COUNT)),
  d_end: new Date(),
};

const initUserStatsFilter = {
  d_begin: new Date((new Date()) - (1000 * 60 * 60 * 24 * INIT_DAYS_COUNT)),
  d_end: new Date(),
};

const initFinanceStatsFilter = {
  d_begin: new Date((new Date()) - (1000 * 60 * 60 * 24 * INIT_DAYS_COUNT)),
  d_end: new Date(),
  page: 1,
  t_type: '',
  ordering: '',
  limit: 15,
};

const filterDatesToLocal = (v) => ({
  d_start: v.d_start || v.d_begin || new Date(),
  d_end: v.d_end || new Date(),
});

export const StatisticsContextProvider = ({ children }) => {
  const {
    getFilterString,
    getFilterStringCustomFormat,
    loadPaymentSystems,
  } = useAdminContext();

  const storedPaymentSystems = useSelector(getPaymentSystems);

  const [paymentSystems, setPaymentSystems] = useState([]);

  const [getDataFlags, setGetDataFlags] = useState({});

  const [isMoneyInit, setIsMoneyInit] = useState(true);
  const [moneyGeneralStats, setMoneyGeneralStats] = useState([]);
  const [moneyPaymentsStats, setMoneyPaymentsStats] = useState([]);
  const [moneyUserBalanceStats, setMoneyUserBalanceStats] = useState([]);
  const [moneyStatsFilter, setMoneyStatsFilter] = useState(initMoneyStatsFilter);
  const [isMoneyStatsProcessing, setIsMoneyStatsProcessing] = useState(false);

  const [isUserInit, setIsUserInit] = useState(true);
  const [userGeneralStats, setUserGeneralStats] = useState([]);
  const [userUsersStats, setUserUsersStats] = useState([]);
  const [userReferralStats, setUserReferralStats] = useState([]);
  const [userDevicesStats, setUserDevicesStats] = useState(initMoneyStatsFilter);
  const [userStatsFilter, setUserStatsFilter] = useState(initUserStatsFilter);
  const [isUserStatsProcessing, setIsUserStatsProcessing] = useState(false);

  const [isFinanceInit, setIsFinanceInit] = useState(true);
  const [financeStats, setFinanceStats] = useState([]);
  const [financeStatsFilter, setFinanceStatsFilter] = useState(initFinanceStatsFilter);
  const [financeStatsFilterString, setFinanceStatsFilterString] = useState('');

  const [isFinanceStatsProcessing, setIsFinanceStatsProcessing] = useState(false);

  const correctItem = (itm) => ({ ...itm, date: format(new Date(itm.date), CHARTS_DATE_FORMAT) });

  const filterDatesFromLocal = (localDates) => ({
    d_begin: localDates.d_begin || localDates.d_start || '',
    d_end: localDates.d_end || '',
  });

  useEffect(() => {
    if (storedPaymentSystems) {
      setPaymentSystems(storedPaymentSystems);
    } else {
      loadPaymentSystems();
    }
  }, [storedPaymentSystems]);

  useEffect(() => {
    if (isMoneyStatsProcessing) return;

    setGetDataFlags({
      [DATA_TYPES.moneyGeneral]: true,
      [DATA_TYPES.moneyPayments]: true,
      [DATA_TYPES.moneyUserBalance]: true,
    });

    const useFilterString = getFilterString(isMoneyInit
      ? {
        d_begin: format(initMoneyStatsFilter.d_begin, DATE_FORMAT),
        d_end: format(initMoneyStatsFilter.d_end, DATE_FORMAT),
      }
      : moneyStatsFilter);

    setIsMoneyStatsProcessing(true);
    getMoneyGeneralDataAction(useFilterString)
      .then((res) => {
        if (res?.currency) {
          setMoneyGeneralStats(res);
        }
      })
      .catch((err) => setSnackFromErrorResponse(err))
      .finally(() => {
        setGetDataFlags((prevState) => ({ ...prevState, [DATA_TYPES.moneyGeneral]: false }));
      });

    getMoneyPaymentTransactionsAction(useFilterString)
      .then((res) => {
        if (res?.currency) {
          setMoneyPaymentsStats((res?.statistic_array || []).map((s) => ({
            ...s,
            date: format(new Date(s.date), CHARTS_DATE_FORMAT),
          })));
        }
      })
      .catch((err) => setSnackFromErrorResponse(err))
      .finally(() => {
        setGetDataFlags((prevState) => ({ ...prevState, [DATA_TYPES.moneyPayments]: false }));
      });

    getMoneyUserBalanceAction(useFilterString)
      .then((res) => {
        if (res?.currency) {
          setMoneyUserBalanceStats((res?.statistic_array || []).map((s) => ({
            ...s,
            date: format(new Date(s.date), CHARTS_DATE_FORMAT),
          })));
        }
      })
      .catch((err) => setSnackFromErrorResponse(err))
      .finally(() => {
        setGetDataFlags((prevState) => ({ ...prevState, [DATA_TYPES.moneyUserBalance]: false }));
      });

    if (isMoneyInit) {
      setIsMoneyInit(false);
    }
  }, [moneyStatsFilter]);

  useEffect(() => {
    if (isUserStatsProcessing) return;

    setGetDataFlags({
      [DATA_TYPES.userGeneral]: true,
      [DATA_TYPES.userUsers]: true,
      [DATA_TYPES.userReferral]: true,
      [DATA_TYPES.userDevices]: true,
    });

    const useFilterString = getFilterString(isUserInit
      ? {
        d_begin: format(initUserStatsFilter.d_begin, DATE_FORMAT),
        d_end: format(initUserStatsFilter.d_end, DATE_FORMAT),
      }
      : userStatsFilter);

    setIsUserStatsProcessing(true);
    getUserGeneralDataAction(useFilterString)
      .then((res) => {
        if (res?.users_all) {
          setUserGeneralStats(res);
        }
      })
      .catch((err) => setSnackFromErrorResponse(err))
      .finally(() => {
        setGetDataFlags((prevState) => ({ ...prevState, [DATA_TYPES.userGeneral]: false }));
      });

    getUserUsersDataAction(useFilterString)
      .then((res) => {
        setUserUsersStats({
          countries: res?.countries || {},
          statisticArray: res?.statistic_array
            ? Object.values(res.statistic_array).map((itm) => correctItem(itm))
            : [],
        });
      })
      .catch((err) => setSnackFromErrorResponse(err))
      .finally(() => {
        setGetDataFlags((prevState) => ({ ...prevState, [DATA_TYPES.userUsers]: false }));
      });

    getUserReferralDataAction(useFilterString)
      .then((res) => {
        setUserReferralStats({
          countries: res?.countries || {},
          statisticArray: res?.statistic_array
            ? Object.values(res.statistic_array).map((itm) => correctItem(itm))
            : [],
        });
      })
      .catch((err) => setSnackFromErrorResponse(err))
      .finally(() => {
        setGetDataFlags((prevState) => ({ ...prevState, [DATA_TYPES.userReferral]: false }));
      });

    getUserDevicesDataAction(useFilterString)
      .then((res) => {
        setUserDevicesStats({
          countries: res?.countries || [],
          statisticArray: res?.statistic_array
            ? Object.values(res.statistic_array).map((itm) => correctItem(itm))
            : [],
        });
      })
      .catch((err) => setSnackFromErrorResponse(err))
      .finally(() => {
        setGetDataFlags((prevState) => ({ ...prevState, [DATA_TYPES.userDevices]: false }));
      });

    if (isUserInit) {
      setIsUserInit(false);
    }
  }, [userStatsFilter]);

  useEffect(() => {
    const useFilter = isFinanceInit
      ? {
        ...initFinanceStatsFilter,
        d_begin: format(initFinanceStatsFilter.d_begin, DATE_FORMAT),
        d_end: format(initFinanceStatsFilter.d_end, DATE_FORMAT),
      }
      : financeStatsFilter;
    const useFilterString = getFilterStringCustomFormat(useFilter, DATE_FORMAT);
    if (useFilterString !== financeStatsFilterString) {
      setFinanceStatsFilterString(useFilterString);
    }
  }, [financeStatsFilter]);

  useEffect(() => {
    if (isFinanceStatsProcessing || (financeStatsFilterString === '')) return;

    setGetDataFlags({
      [DATA_TYPES.finance]: true,
    });

    getFinanceDataAction(financeStatsFilterString)
      .then((res) => {
        const correctedRes = { ...res };
        if (res?.results) {
          correctedRes.results = res.results
            .map((d) => ({
              ...d,
              transaction_type: (d.transaction_type === 'withdrawal')
              && (d.transaction_type_title === 'Неуспешные платежи')
                ? 'unsuccessful'
                : d.transaction_type,
            }));
        }
        setFinanceStats(correctedRes);
        return correctedRes;
      })
      .catch((err) => setSnackFromErrorResponse(err))
      .finally(() => {
        setGetDataFlags((prevState) => ({ ...prevState, [DATA_TYPES.finance]: false }));
      });

    if (isFinanceInit) {
      setIsFinanceInit(false);
    }
  }, [financeStatsFilterString]);

  useEffect(() => {
    const moneyStatsData = [
      DATA_TYPES.moneyGeneral,
      DATA_TYPES.moneyPayments,
      DATA_TYPES.moneyUserBalance,
    ];
    let isMoneyStatsLoading = true;
    moneyStatsData.forEach((dt) => {
      isMoneyStatsLoading = isMoneyStatsLoading && !getDataFlags[dt];
    });
    setIsMoneyStatsProcessing(!isMoneyStatsLoading);

    const userStatsData = [
      DATA_TYPES.userGeneral,
      DATA_TYPES.userUsers,
      DATA_TYPES.userReferral,
      DATA_TYPES.userDevices,
    ];
    let isUsersStatsLoading = true;
    userStatsData.forEach((dt) => {
      isUsersStatsLoading = isUsersStatsLoading && !getDataFlags[dt];
    });
    setIsUserStatsProcessing(!isUsersStatsLoading);

    const financeStatsData = [
      DATA_TYPES.finance,
    ];
    let isFinanceStatsLoading = true;
    financeStatsData.forEach((dt) => {
      isFinanceStatsLoading = isFinanceStatsLoading && !getDataFlags[dt];
    });
    setIsFinanceStatsProcessing(!isFinanceStatsLoading);
  }, [getDataFlags]);

  const value = {
    paymentSystems,
    isMoneyStatsProcessing,
    moneyStatsFilter,
    setMoneyStatsFilter,
    moneyGeneralStats,
    moneyPaymentsStats,
    moneyUserBalanceStats,

    isUserStatsProcessing,
    userStatsFilter,
    setUserStatsFilter,
    userGeneralStats,
    userUsersStats,
    userReferralStats,
    userDevicesStats,

    isFinanceStatsProcessing,
    financeStats,
    financeStatsFilter,
    setFinanceStatsFilter,

    filterDatesToLocal,
    filterDatesFromLocal,
  };

  return (
    <StatisticsContext.Provider value={value}>
      {children}
    </StatisticsContext.Provider>
  );
};

export const useStatisticsContext = () => useContext(StatisticsContext);
