import React, {
  createContext,
  useContext, useEffect,
  useState,
} from 'react';

import { getReferralsTransactionsAction } from '@/services/actions/referrals.actions';
import { useReferralsContext } from '../../ReferralsContext';

export const ReferralsTransactionsContext = createContext({});

export const ReferralsTransactionsContextProvider = ({ children }) => {
  const {
    datePageLimitFilter,
    getPageCount,
  } = useReferralsContext();

  const [referrals, setReferrals] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const getReferrals = () => getReferralsTransactionsAction(
    { ...datePageLimitFilter, page },
  )
    .then((res) => {
      setReferrals(res.results);
      setPageCount(getPageCount(res.count));
    });

  useEffect(() => {
    if (!referrals) {
      getReferrals();
    }
  }, [referrals]);

  const value = {
    referrals,
    getReferrals,
    page,
    setPage,
    pageCount,
  };

  return (
    <ReferralsTransactionsContext.Provider value={value}>
      {children}
    </ReferralsTransactionsContext.Provider>
  );
};

export const useReferralsTransactionsContext = () => useContext(ReferralsTransactionsContext);
