import React, {
  createContext,
  useState,
  useContext,
} from 'react';
import {
  createDomainAction,
  deleteDomainByIdAction,
  getDomainsAction,
} from '@/services/actions/admin/dns.actions';

import { useAdminContext } from '../../../../../AdminContext';

const AdminDNSContext = createContext({});

export const AdminDNSContextProvider = ({ children }) => {
  const { setAdminSnack } = useAdminContext();

  const [domains, setDomains] = useState([]);
  const [users] = useState([]);

  const getDomains = () => (
    getDomainsAction()
      .then((res) => setDomains(res.domain_list))
      .catch((err) => setAdminSnack(err))
  );

  const createDomain = (item) => (
    createDomainAction(item)
      .then(getDomains)
      .catch((err) => setAdminSnack(err))
  );

  const deleteDomainById = (id, newDnsId) => (
    deleteDomainByIdAction(id, newDnsId)
      .then(getDomains)
      .catch((err) => setAdminSnack(err))
  );

  const value = {
    domains,
    users,
    getDomains,
    createDomain,
    deleteDomainById,
  };

  return (
    <AdminDNSContext.Provider value={value}>
      {children}
    </AdminDNSContext.Provider>
  );
};

export const useAdminDNSContext = () => useContext(AdminDNSContext);
