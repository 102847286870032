import { taskIdAddon } from './utils';

export default {
  orders: '/api/v1/orders/',
  devicesList: '/api/v1/user/device/list/',
  // selectedDevices: '/api/v1/user/device/list/?selected=true',
  addDevice: '/api/v1/user/device/list/',
  editDevice: (id) => `/api/v1/user/device/${id}/`,
  startDeviceViewTypeChange: (id) => `/api/v1/user/device/${id}/view_type/`,
  statusDeviceViewTypeChange: (id, taskId) => `/api/v1/user/device/${id}/view_type/?task_id=${taskId}`,
  deviceViewTypeChange: ({ id, taskId }) => `/api/v1/user/device/${id}/view_type/${taskIdAddon(taskId)}`,
  deleteDevice: (id) => `/api/v1/user/device/${id}/`,
  setPremium: (id) => `/api/v1/user/device/${id}/subscription_to_premium/`,
  checkedDevice: (id) => `/api/v1/user/device/${id}/checked/`,
  checkedAllDevices: '/api/v1/user/device/check_all/',
  setPremiumDevice: (id) => `/api/v1/user/device/TODO-SET-PREMIUM/${id}/`,
  setAutoRenewDevice: (id) => `/api/v1/user/device/${id}/subscription_auto_renew/`,
  deviceDns: (id) => `/api/v1/user/device/${id}/dns/`,
  resetTheme: (id, taskId = '') => `/api/v1/user/device/${id}/clear_portal_user_theme/${(taskId?.length || 0) > 0 ? `?task_id=${taskId}` : ''}`,
  setPlaylistType: (deviceId) => `/api/v1/user/device/${deviceId}/playlist-type/`,
};
