import React from 'react';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';

import { BASE_URL } from './constants';

import TemplatesEdit from './TemplatesEdit/TemplatesEdit';
import TemplatesList from './TemplatesList/TemplatesList';
import TemplatesListContextProvider from './TemplatesList/TemplatesListContext';

import TemplatesEditContextProvider from './TemplatesEdit/TemplatesEditContext';

const Templates = () => (
  <Switch>
    <Route exact path={BASE_URL}>
      <TemplatesListContextProvider>
        <TemplatesList />
      </TemplatesListContextProvider>
    </Route>
    <Route exact path={`${BASE_URL}:id`}>
      <TemplatesEditContextProvider>
        <TemplatesEdit />
      </TemplatesEditContextProvider>
    </Route>
    <Redirect to={BASE_URL} />
  </Switch>
);

export default Templates;
