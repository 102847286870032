import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { format } from 'date-fns';

import {
  text01,
  secondary,
  lightRed,
  brightGreen,
  primary,
} from '@/common/constants/theme';

import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import Loader from '@/common/components/Loader';
import ArrowDownIcon from '../../../icons/ArrowDownIcon';
import SearchIcon from '../../../../../PersonalPageScreen/components/icons/SearchIcon';
import AdminTablePagination from '../../components/Table/components/TablePagination';
import AdminModal from '../../components/AdminModal';
import DatePickerNew from '@/common/components/DatePickerNew';

import {
  SORT_DIRECTIONS,
  useAdminContext,
} from '../../../../AdminContext';

import {
  useFinanceContext,
  PAYMENT_ACTIONS,
  SEARCH_FILTER_TIMEOUT,
} from '../FinanceContext';
import ArrowTable from '../../../icons/ArrowTable';
import CloseIcon from '../../../icons/CloseIcon';

const SIZES = ['80px', '140px', '80px', '400px', '260px'];

const initDates = {
  d_begin: '',
  d_end: '',
};

const FinanceTransfers = () => {
  const {
    getDateFromDateFilter,
  } = useAdminContext();
  const {
    payments,
    paymentsFilter,
    setPaymentsFilter,
    paymentsData,
    isPaymentsProcerssing,
    selectedPayment,
    setSelectedPayment,
    paymentAction,
    setPaymentAction,
    returnPayment,
  } = useFinanceContext();

  const [isVisible, setIsVisible] = useState(true);
  const [isPaymentReturnEnabled] = useState(false);
  const [localDates, setLocalDates] = useState(initDates);
  const [currentPage, setCurrentPage] = useState(paymentsData?.page || 1);

  const [searchTimeout, setSearchTimeout] = useState(null);

  const [search, setSearch] = useState({});
  const [sortByDate, setSortByDate] = useState(SORT_DIRECTIONS.notSetted);
  const [sortByAmount, setSortByAmount] = useState(SORT_DIRECTIONS.notSetted);

  const sorts = {
    asc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.asc],
    desc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.desc],
  };

  const directionsKeys = Object.keys(SORT_DIRECTIONS);
  const directions = Object.values(SORT_DIRECTIONS);

  const changeSortByDateDirection = () => {
    const currentSortIndex = directions.findIndex((s) => s === sortByDate) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortByDate(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);
    setSortByAmount(SORT_DIRECTIONS.notSetted);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'dt_create';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setPaymentsFilter((prevState) => ({ ...prevState, ordering: `${sign}${field}`.trim() }));
  };

  const changeSortByAmountDirection = () => {
    const currentSortIndex = directions.findIndex((s) => s === sortByAmount) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortByAmount(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);
    setSortByDate(SORT_DIRECTIONS.notSetted);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'amount';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setPaymentsFilter((prevState) => ({ ...prevState, ordering: `${sign}${field}`.trim() }));
  };

  const getSearchString = (searchObject) => {
    const searchStringArray = [];
    // eslint-disable-next-line no-unused-vars
    Object.entries(searchObject).forEach(([_, value]) => { searchStringArray.push(value); });
    return searchStringArray.join(' ');
  };

  useEffect(() => {
    setLocalDates({
      d_begin: getDateFromDateFilter(paymentsFilter.d_begin),
      d_end: getDateFromDateFilter(paymentsFilter.d_end),
    });
    setCurrentPage(paymentsFilter.page);
  }, [paymentsFilter]);

  useEffect(() => {
    setPaymentsFilter((prevState) => ({ ...prevState, page: currentPage }));
  }, [currentPage]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(setTimeout(() => {
      setPaymentsFilter((prevState) => ({ ...prevState, q: getSearchString(search) }));
    }, SEARCH_FILTER_TIMEOUT));
  }, [search]);

  const formatUserName = (user) => {
    const name = `${user?.first_name || ''} ${user?.last_name || ''}`.trim();
    const emailName = (user?.email || '')?.split('@')?.[0] || `Пользователь id[${user?.id || 0}}]`;
    return name.length > 0 ? name : emailName;
  };

  const formatDate = (dateString) => {
    const D = new Date(dateString);
    return `${D.getFullYear()}.${(D.getMonth() + 1).toString().padStart(2, '0')}.${(D.getDate()).toString().padStart(2, '0')}\n${(D.getHours()).toString().padStart(2, '0')}:${(D.getMinutes()).toString().padStart(2, '0')}:${(D.getSeconds()).toString().padStart(2, '0')}`;
  };

  const formatUserPageLink = (user) => `/admin/users/main/${user?.id || 0}/`;

  const formatDescription = (payment) => {
    // const paymentSystemTemplate = 'Пополнение баланса через {psName}';
    const paymentSystemTemplate = '';
    return payment?.description
      || paymentSystemTemplate.replace(/{psName}/gi, payment.payment_system.title);
  };

  const handlePaymentReturn = () => {
    returnPayment();
  };

  return (
    <Container>
      <AdminModal
        isOpen={paymentAction === PAYMENT_ACTIONS.returnPayment && selectedPayment}
        onClose={() => setSelectedPayment(null)}
        onSubmit={handlePaymentReturn}
        topTitle="Возврат средств"
        title={`${selectedPayment?.sum || 0} ${selectedPayment?.currency || ''}`.trim()}
        color="danger"
        buttonDescr="Все верно?"
      >
        С баланса пользователя
        <ModalTitle>
          {formatUserName(selectedPayment?.user)}
        </ModalTitle>
      </AdminModal>
      <FlexWrapper flexSb bottomMargin isOpen={isVisible} bottomLine>
        <FlexWrapper alignCenter>
          <Title>
            Вывод реферальных вознаграждений
          </Title>
          {isVisible && (
            <DatePickerNew
              values={localDates}
              onSubmit={(interval) => {
                const newDates = {
                  ...localDates,
                  d_begin: interval?.dateStart ? format(interval.dateStart, 'yyyy-MM-dd') : '',
                  d_end: interval?.dateEnd ? format(interval.dateEnd, 'yyyy-MM-dd') : '',
                };
                setPaymentsFilter((prevState) => ({ ...prevState, ...newDates, page: 1 }));
              }}
            />
          )}
        </FlexWrapper>
        <FlexWrapper pointer onClick={() => setIsVisible((prevState) => !prevState)}>
          <Text>
            {isVisible ? 'Свернуть' : 'Развернуть'}
          </Text>
          <Icon isRotate={!isVisible}>
            <ArrowDownIcon />
          </Icon>
        </FlexWrapper>
      </FlexWrapper>
      {isVisible && (
        <Table
          page={paymentsFilter?.page || 1}
          limit={paymentsFilter?.limit || 1}
          cellSizes={SIZES}
          textAlign="left"
          dataLength={paymentsData?.count || 1}
        >
          {isPaymentsProcerssing && <Loader />}
          <TableHead>
            <HeadItem flex>
              Дата
              <IconsWrapper
                onClick={() => changeSortByDateDirection()}
              >
                {sorts.asc.includes(sortByDate)
                  && (
                    <IconSort isRotate>
                      <ArrowTable />
                    </IconSort>
                  )}
                {sorts.desc.includes(sortByDate)
                  && (
                    <IconSort leftMargin top>
                      <ArrowTable />
                    </IconSort>
                  )}
              </IconsWrapper>
            </HeadItem>
            <HeadItem>
              Пользователь
            </HeadItem>
            <HeadItem flex>
              Сумма
              <IconsWrapper
                onClick={() => changeSortByAmountDirection()}
              >
                {sorts.asc.includes(sortByAmount)
                  && (
                    <IconSort isRotate>
                      <ArrowTable />
                    </IconSort>
                  )}
                {sorts.desc.includes(sortByAmount)
                  && (
                    <IconSort leftMargin top>
                      <ArrowTable />
                    </IconSort>
                  )}
              </IconsWrapper>
            </HeadItem>
            <HeadItem>
              Описание
            </HeadItem>
            <HeadItem flex>
              <Input
                value={search.email || ''}
                onChange={({ target }) => setSearch({ ...search, email: target.value })}
                placeholder="Поиск"
              />
              <Icon top>
                <SearchIcon />
              </Icon>
              {(search.email || '').length > 0
                && (
                  <CloseIconContainer
                    onClick={() => setSearch({ ...search, email: '' })}
                  >
                    <CloseIcon />
                  </CloseIconContainer>
                )}
            </HeadItem>
          </TableHead>
          <TableBody>
            {payments && payments.map((item) => (
              <div key={`FT-row--${item.id}`}>
                <RowItem>
                  {item?.dt_create && formatDate(item.dt_create)}
                </RowItem>
                <RowItem>
                  {item?.user && (
                    <StyledLink to={formatUserPageLink(item.user)}>
                      {formatUserName(item.user)}
                    </StyledLink>
                  )}
                </RowItem>
                <RowItem>
                  <Text greenColor bold>
                    {item?.amount || ''}
                  </Text>
                </RowItem>
                <RowItem>
                  {formatDescription(item)}
                </RowItem>
                <RowItem flex>
                  {isPaymentReturnEnabled && (
                    <RedText onClick={() => {
                      setSelectedPayment(item);
                      setPaymentAction(PAYMENT_ACTIONS.returnPayment);
                    }}
                    >
                      Возврат платежа
                    </RedText>
                  )}
                </RowItem>
              </div>
            ))}
          </TableBody>
          <AdminTablePagination
            page={currentPage}
            pageCount={Math.ceil((paymentsData?.count || 0) / (paymentsFilter?.limit || 1))}
            setPage={setCurrentPage}
            recordsCount={paymentsData?.count || 0}
          />
        </Table>
      )}
    </Container>
  );
};

export default FinanceTransfers;

const Container = styled.div`
  margin-bottom: 40px;
`;

const IconsWrapper = styled.div`
  display: flex;

  width: 18px;
  margin-left: 10px;
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  ${({ bold }) => bold && 'font-weight: bold;'}
  line-height: 15px;
  color: ${({ greenColor }) => (greenColor ? brightGreen : text01)};
`;

const Title = styled(Text)`
  margin-right: 16px;

  font-weight: 600;
  font-size: 20px;
  line-height: 15px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ flexSb }) => flexSb && 'justify-content: space-between;'}
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}

  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 16px;'}

  position: relative;

  ${({ pointer }) => pointer && 'cursor: pointer;'}

  ${({ isOpen, bottomLine }) => bottomLine && !isOpen && (`
    &::after {
      content: '';

      height: 1px;

      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;

      background-color: ${text01};
    }
  `)}
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;

  cursor: pointer;
  transform: rotate(${({ isRotate }) => (isRotate ? 180 : 0)}deg);

  transition: 0.2s ease;
`;

const IconSort = styled.div`
  ${({ leftMargin }) => leftMargin && 'margin-left: 2px;'}

  position: relative;
  ${({ top }) => top && 'top: 3px;'}

  cursor: pointer;
  ${({ isRotate }) => isRotate && 'transform: rotate(-180deg);'}`;

const HeadItem = styled.div`
  ${({ flex }) => flex && `
    display: flex;

    width: 100%;
  `}
`;

const Input = styled.input`
  width: 200px;
  padding-bottom: 4px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${secondary};
  outline: none;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
`;

const RowItem = styled.div`
  ${({ flex }) => flex && `
    display: flex;

    width: 100%;
  `}
`;

const RedText = styled(Text)`
  margin-left: auto;

  cursor: pointer;

  font-weight: 600;
  color: ${lightRed};
`;

const StyledLink = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: ${primary};
  line-height: 16px;

  &:hover,
  &:focus {
    color: ${primary};
  }
`;

const ModalTitle = styled.div`
  margin-top: 6px;
  margin-bottom: 10px;

  font-size: 20px;
  line-height: 20px;
`;
