const LONG_ACTION_STATUS = {
  processed: 'processed',
  success: 'success',
};
const LONG_ACTION_TIMEOUT = 1000 * 0.5;

const longActionProcess = (
  action,
  stopAction,
  successAction,
  actionParams,
  resultStatus = LONG_ACTION_STATUS.processed,
  resultData = null,
) => {
  const { timeOut, ...useActionParams } = actionParams;
  let actionProcessResult = null;
  switch (resultStatus) {
    case LONG_ACTION_STATUS.processed:
      setTimeout(async () => {
        const actionResult = await action(useActionParams)
          .catch((err) => {
            if (stopAction && (typeof stopAction === 'function')) {
              stopAction(err);
            }
            return {};
          });
        const { status: actionResultStatus, ...actionResultData } = actionResult;
        actionProcessResult = longActionProcess(
          action,
          stopAction, successAction,
          actionParams,
          actionResultStatus, actionResultData,
        );
      }, timeOut || LONG_ACTION_TIMEOUT);
      break;
    case LONG_ACTION_STATUS.success:
      successAction(resultData);
    // eslint-disable-next-line no-fallthrough
    default:
      if (stopAction && (typeof stopAction === 'function')) {
        stopAction();
      }
  }
  return actionProcessResult;
};

export const longAction = async (action, stopAction, successAction, actionParams = null) => {
  if (!action || (typeof action !== 'function')) {
    return null;
  }

  let returnData = null;

  try {
    const startResult = await action(actionParams)
      .catch((err) => {
        if (stopAction && (typeof stopAction === 'function')) {
          stopAction(err);
        }
        return err;
      });
    const { status: startStatus, task_id: taskId, items } = startResult || {};

    switch (startStatus) {
      case LONG_ACTION_STATUS.processed:
        longActionProcess(action, stopAction, successAction, { ...actionParams, taskId });
        break;
      case LONG_ACTION_STATUS.success:
        returnData = items;
      // eslint-disable-next-line no-fallthrough
      default:
        if (stopAction && (typeof stopAction === 'function')) {
          stopAction(startResult);
        }
        break;
    }
  } catch (e) {
    if (stopAction && (typeof stopAction === 'function')) {
      stopAction(e);
    }
  }

  return returnData;
};
