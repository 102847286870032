/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  text02,
  white,
  borderSecondary,
  inputDisabled,
} from '@/common/constants/theme';
import IconCopy from '@/assets/icons/copyicon.svg';
import Visibility from '@/assets/icons/visibility.svg';
import NoVisibility from '@/assets/icons/no-visibility.svg';
import Tooltip from '@/common/components/Tooltip';

const CredentionInput = ({
  value,
  isFirst,
  isLast,
  separateIcon,
  type,
  label,
  ...inputProps
}) => {
  const { t } = useTranslation(['translations']);
  const [isPasswordType, setPasswordType] = useState(type === 'password');
  const copyText = (elementId) => {
    setPasswordType(false);
    document.getElementById(elementId).type = 'text';
    const range = document.createRange();
    range.selectNode(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
  };

  return (
    <Wrapper>
      <Input
        id={value}
        // defaultValue={value}
        value={value}
        type={isPasswordType ? 'password' : 'text'}
        {...inputProps}
      />
      {type === 'password' && (isPasswordType ? (
        <NoVisabilityIcon
          onClick={() => setPasswordType(!isPasswordType)}
          src={NoVisibility}
        />
      ) : (
        <VisabilityIcon
          onClick={() => setPasswordType(!isPasswordType)}
          src={Visibility}
        />
      ))}
      <Tooltip
        title={`${label} ${t('copied')}`}
        type="success"
        position="right"
        trigger="click"
      >
        <Icon src={IconCopy} onClick={() => copyText(value)} />
      </Tooltip>
    </Wrapper>
  );
};
export default CredentionInput;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const Input = styled.input`
  background: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  padding: .25rem .75rem;
  margin: 0 .5rem;
  color: ${text02};
  outline: none;
  max-width: 11rem;
  :disabled {
    background: ${inputDisabled};
  }
`;

const VisabilityIcon = styled.img`
  position: absolute;
  right: 1.75rem;
  top: 5px;
  cursor: pointer;
  width: 20px;
`;

const NoVisabilityIcon = styled(VisabilityIcon)`
  right: 1.75rem;
  top: 3px;
  width: 22px;
`;
