import React, { useEffect, useState } from 'react';
import i18n from '@/i18n';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  secondary, activePrimary, white, text01, secondaryHover, secondaryActive, red,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';
import AuthorizationLayout from '../components/AuthorizationLayout';
import TitleWithOrange from '../components/TitleWithOrange';
import Passwords from '@/screens/Authorization/components/Passwords';

import {
  validatePasswordChangeToken,
  changePasswordAction,
  signOutAction,
} from '@/services/actions/authorization.actions';
import auth from '@/services/auth.service';

export default function ChangePasswordContainer({ history, match }) {
  const { t } = useTranslation('translations');

  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [isTokenOk, setIsTokenOk] = useState(false);

  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);

  const defaultTexts = () => ({
    firstPart: t('authorization.changePassword.textBlack1'),
    orangePart: t('authorization.changePassword.textOrange'),
    secondPart: t('authorization.changePassword.textBlack2'),
    button: t('authorization.changePassword.button'),
  });

  const [texts, setTexts] = useState(defaultTexts);

  const handleChangePassword = async () => {
    if (success) {
      setLoading(true);
      const { uid, token } = match.params;
      // changePassword(uid, token, password, confirmedPassword)

      changePasswordAction(uid, token, password, password, i18n.language)
        .then(() => {
          setLoading(false);
          setTexts({
            firstPart: t('authorization.changePassword.textBlack1'),
            orangePart: t('authorization.changePassword.textOrange'),
            secondPart: t('authorization.changePassword.textBlack2'),
            button: t('authorization.changePassword.button'),
          });
          setPasswordChanged(true);
        })
        .catch((err) => {
          setLoading(false);
          let msg = '';
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const m of Object.keys(err.errors)) {
            msg += `${m}: ${err.errors[m].join(' ')}`;
          }
          // eslint-disable-next-line no-console
          setErrors(`${t(`authorization.changePassword.errors.${err?.message_type}`)} (${msg})`);
        });
    }
  };

  useEffect(() => {
    if (!isTokenVerified) {
      const { uid, token } = match.params;
      setLoading(true);
      validatePasswordChangeToken(uid, token)
        .then(() => setIsTokenOk(true))
        .finally(() => { setLoading(false); setIsTokenVerified(); });
    }
  }, [isTokenVerified]);

  return (
    <>
      {isLoading && <Loader />}
      <AuthorizationLayout>
        <TitleWithOrange
          textBlack1={texts.firstPart}
          textOrange={texts.orangePart}
          textBlack2={texts.secondPart}
        />
        {isTokenOk ? (
          <Form>
            {passwordChanged ? (
              <>
                <TitleText>{t('authorization.passwordChanged')}</TitleText>
                <LoginButton
                  path="/sign-in"
                  onClick={() => signOutAction()
                    .catch(() => ({}))
                    .finally(() => { auth.clearToken(); return history.push('/sign-in'); })}
                >
                  {t('header.signin')}
                </LoginButton>
              </>
            ) : (
              <>
                <Passwords
                  setPassword={setPassword}
                  setSuccess={setSuccess}
                />
                {success && (
                  <Button onClick={handleChangePassword}>
                    {t('authorization.changePassword.button')}
                  </Button>
                )}
                <div style={{ color: red }}>{ errors }</div>
              </>
            )}
          </Form>
        ) : (
          <VerifyFailedText>{ t('authorization.tokenValidation.error') }</VerifyFailedText>
        )}
      </AuthorizationLayout>
    </>
  );
}

const TitleText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 8rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const Button = styled.button`
  margin: .5rem 0;
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const LoginButton = styled.button`
  border: 2px solid ${secondary};
  border-radius: 2px;
  padding: .76rem 1rem;
  margin-left: 1.5rem;
  margin-top: 2rem;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.20s ease-in;
  outline: none;
  ${({ path }) => (path ? `
  background: ${white};
  color: ${activePrimary};
  :hover {
    background: #FFAB30;
    border-color: #FFAB30;
    color: ${white};
  }
  ` : `
  background: ${secondary};
  color: ${white};
  :hover {
    background: ${secondaryHover};
    border-color: ${secondaryHover};
    color: ${white};
  }
  :active {
    background: ${secondaryActive};
    border-color: ${secondaryActive};
  }
  `)}
`;

const VerifyFailedText = styled.div`
  height: 30rem;
  width: 28rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 10rem;
  margin: 0 4.5rem 0 8rem;
  -webkit-align-items: flex-start;
  align-items: flex-start;

  font-size: 200%;
`;
