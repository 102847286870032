import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  grey, primary, secondary, activePrimary, white, text01, text04, text02,
} from '../constants/theme';
import ArrowIcon from '../../assets/icons/arrow-down.svg';
import SearchIcon from '../../assets/icons/search.svg';

export default function SelectorContainer({
  title,
  disabled,
  value,
  defaultValue,
  options,
  onSelect = () => {},
  icon,
  uppercaseSelected = false,
  noResultsText,
  minWidth,
  marginRight,
}) {
  const { t } = useTranslation(['translations']);
  const [isOpen, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedValue, setValue] = useState(value || defaultValue || options[0]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          if (!disabled) { setOpen(false); }
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onSelectValue = (object) => {
    setValue(object);
    onSelect(object);
    setOpen(false);
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  const optionsForRender = options.filter((option) => (
    option.title.toLowerCase().includes(query.toLowerCase())
  ));

  return (
    <Container
      title={title || ''}
      minWidth={minWidth}
      marginRight={marginRight}
    >
      <Icon src={icon} />
      <div ref={wrapperRef}>
        <SelectedText
          disabled={disabled}
          ref={wrapperRef}
          uppercaseSelected={uppercaseSelected}
          onClick={() => {
            if (!disabled) { setOpen(!isOpen); }
          }}
        >
          {selectedValue.title}
        </SelectedText>
        <Arrow
          disabled={disabled}
          src={ArrowIcon}
          onClick={() => {
            if (!disabled) { setOpen(!isOpen); }
          }}
          isOpen={isOpen}
        />
        {isOpen && (
        <SelectorMenu>
          <SearchInput
            disabled={disabled}
            placeholder="Введите название"
            value={query}
            onChange={({ target }) => setQuery(target.value)}
          />
          <OptionsList>
            {optionsForRender.length ? (
              optionsForRender.map((option) => (
                <Option
                  key={option.key}
                  selected={option.key === selectedValue.key}
                  onClick={() => onSelectValue(option)}
                >
                  {option.title}
                </Option>
              ))
            ) : (
              <NoData>{noResultsText || t('table.noResults')}</NoData>
            )}
          </OptionsList>
        </SelectorMenu>
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight};` : '')}
  max-width: 18.25rem;
`;

const Icon = styled.img``;

const SelectedText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${({ disabled }) => (disabled ? grey : secondary)};
  margin: 0 .5rem;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ uppercaseSelected }) => uppercaseSelected && 'text-transform: uppercase;'}
`;

const Arrow = styled.img`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  filter: ${({ disabled }) => (disabled ? 'saturate(0)' : 'none')};
  transition: transform .25s;
  ${({ isOpen }) => isOpen && `
    transform: rotate(180deg);
  `}
`;

const SelectorMenu = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(100% + 7px);
  right: -3px;
  width: 290px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  background: ${white};
  display: flex;
  flex-direction: column;
`;

const SearchInput = styled.input`
  outline: none;
  flex: 1;
  border: none;
  border-bottom: 1px solid #EFEEF3;
  margin: .25rem;
  padding: .75rem 1rem .75rem 2.5rem;
  line-height: 24px;
  background: url(${SearchIcon}) no-repeat 11px center;
  transition: border-color .2s;
  ::placeholder {
    font-family: Gilroy, sans-serif;
    font-size: 15px;
    color: ${text02};
  }
`;

const OptionsList = styled.div`
  border-radius: 2px;
  background: ${white};
  display: flex;
  flex-direction: column;
  max-height: 20rem;
  overflow: auto;
`;

const Option = styled.div`
  padding: .75rem 1rem;
  transition: color .1s, background-color .1s;
  cursor: pointer;
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  :hover {
    color: ${white};
    background: ${activePrimary};
  }
  ${({ selected }) => selected && `
    color: ${white};
    background: ${primary};
  `}
`;

const NoData = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: Gilroy, sans-serif;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text04};
`;
