import {
  primary, secondary,
  red, darkRed,
  green,
} from '@/common/constants/theme';

export const COLORS = {
  success: green,
  error: red,
  danger: darkRed,
  warning: secondary,
  normal: primary,
};
