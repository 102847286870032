import {
  LOAD_ALL_DEVICES,
  LOAD_PLAYLIST, LOAD_SERVERS, LOAD_MINISTRA,
  LOAD_USER, LOAD_WITHDRAWAL_WALLETS, LOAD_WITHDRAWAL_SYSTEMS,
  SET_SELECTED_DEVICES, SET_RIGHT_DEVICE,
  SET_SNACK,
  SET_WELCOME_CLOSED, SET_PAGE_HEADER_DISABLED,
  SET_CHANNELS_LOGO_CACHE,
} from '@/store/user/types';

import {
  CHANNELS_LOGOS,
  DEVICE_PLAYLIST_TYPE_DEFAULT,
} from '../constants';

import { store } from '@/store/user';

import RequestService from '@/services/request.service';
import request2Service from '@/services/request2.service';
import urls from '@/services/routes/devices.api';
import urlsChannels from '@/services/routes/channels.api';

import { getAllDevicesAction, updateDevicePlayList } from '@/services/actions/devices.actions';
import { bindDeviceToServerAction, getServersAction } from '@/services/actions/server.actions';
import { getUserAction } from '@/services/actions/authorization.actions';
import {
  getWithdrawalWalletsAction, getWithdrawalSystemsAction,
} from '@/services/actions/profile.actions';

import i18n from '@/i18n';

const SUBSCRIPTION_ENDING_DAY_COUNT = process.env.REACT_APP_SUBSCTIPTION_ENDING_DAY_COUNT;
const SUBSCRIPTION_ENDING_TIME = SUBSCRIPTION_ENDING_DAY_COUNT * (1000 * 60 * 60 * 24);

export const NOT_FOUND_URL_ADMIN = '/admin/403';
const COMMON_ADMIN_URLS = [
  NOT_FOUND_URL_ADMIN,
];

export const subscribeTypes = {
  All: 'all', // Все, = ''
  Active: 'active', // Активные
  Inactive: 'inactive', // Неактивные
  Ending: 'ending', // Окончившиеся (?)
};

const getStoredSelectedDevices = () => {
  const storedSelectedDevicesString = localStorage.getItem('selectedDevices') || '';
  return (storedSelectedDevicesString.length > 0)
    ? JSON.parse(storedSelectedDevicesString)
    : null;
};
const getStoredPageHeaderDisabled = () => {
  const storedPageHeaderDisabledString = localStorage.getItem('pageHeaderDisabled') || '';
  return (storedPageHeaderDisabledString.length > 0)
    ? JSON.parse(storedPageHeaderDisabledString)
    : null;
};

const getStoredChannelsLogos = async () => {
  let data = [];
  if (caches) {
    data = await caches.open(CHANNELS_LOGOS)
      .then(async (cache) => {
        const x = await cache.matchAll();
        return x;
      });
  }
  const blobData = await Promise.all(data.map(async (resp) => ({
    url: resp.url,
    img: resp.status === 200 ? URL.createObjectURL(await resp.blob()) : null,
  })));

  const outData = {};
  blobData.forEach((bd) => {
    outData[bd.url] = bd.img;
  });
  return outData;
};

export const setUserInfo = (payload) => ({ type: LOAD_USER, payload });
export const setWithdrawalWallets = (payload) => ({ type: LOAD_WITHDRAWAL_WALLETS, payload });
export const setWithdrawalSystems = (payload) => ({ type: LOAD_WITHDRAWAL_SYSTEMS, payload });
export const setPlaylist = (payload) => ({ type: LOAD_PLAYLIST, payload });
export const setServers = (payload) => ({ type: LOAD_SERVERS, payload });
export const setMinistra = (payload) => ({ type: LOAD_MINISTRA, payload });
export const setAllDevices = (payload) => ({ type: LOAD_ALL_DEVICES, payload });
export const setRightDevice = (payload) => ({ type: SET_RIGHT_DEVICE, payload });
export const setSelectedDevices = (payload) => ({ type: SET_SELECTED_DEVICES, payload });
export const setSnack = (payload) => ({ type: SET_SNACK, payload });
export const setWelcomeClosed = (payload) => ({ type: SET_WELCOME_CLOSED, payload });
export const setPageHeaderDisabled = (payload) => ({ type: SET_PAGE_HEADER_DISABLED, payload });
export const setChannelsLogoCache = (payload) => ({ type: SET_CHANNELS_LOGO_CACHE, payload });

export const getStoreUserInfo = (state) => state?.userInfo || {};
export const getStoreWithdrawalWallets = (state) => state?.withdrawalWallets || [];
export const getStoreWithdrawalSystems = (state) => state?.withdrawalSystems || [];
export const getAllDevices = (state) => state?.allDevices || null;
export const getRightDevice = (state) => state?.rightDevice || {};
export const getSelectedDevices = (state) => state?.selectedDevices
  || getStoredSelectedDevices()
  || [];
export const getMinistra = (state) => state?.ministra || [];
export const getPlayLists = (state) => state?.playlists || null;
export const getServers = (state) => state?.servers || null;
export const getPageHeaderDisabled = (state) => state?.pageHeaderDisabled
  || getStoredPageHeaderDisabled()
  || {};
export const getChannelsLogoCache = (state) => state?.channelsLogos
  || null;

const setCorrectSubscriptionType = (d) => {
  const outD = { ...d };
  const subscriptionEndCount = new Date(d?.subscription?.date_end || '1970-01-01').getTime() - new Date().getTime();
  if (d.subscription_type === subscribeTypes.Active) {
    // eslint-disable-next-line no-nested-ternary
    outD.subscription_type = subscriptionEndCount < 0
      ? subscribeTypes.Inactive
      : (subscriptionEndCount <= SUBSCRIPTION_ENDING_TIME
        ? subscribeTypes.Ending : subscribeTypes.Active);
  } else if (d.subscription_type === subscribeTypes.Ending) {
    outD.subscription_type = subscribeTypes.Inactive;
  } else {
    outD.subscription_type = subscribeTypes.Inactive;
  }

  outD.subscription_least = subscriptionEndCount / (1000 * 60 * 60 * 24);

  return outD;
};

const fixDevicePlayListType = (d) => ({
  ...d,
  playlist_type: d.playlist_type || DEVICE_PLAYLIST_TYPE_DEFAULT,
});

export const processLoadedDevice = (d) => {
  let D = setCorrectSubscriptionType(d);
  D = fixDevicePlayListType(D);
  // temporary, for test pairing code process
  // const availStatuses = ['configured', 'code_entered'];
  // D.app_status = D.app_status === 'code_entered'
  //   ? availStatuses[Math.floor((Math.random() * 9) / 5)]
  //   : D.app_status;
  return D;
};

const rbAllowedSubscriptionTypes = [subscribeTypes.Active, subscribeTypes.Ending];

export const processDevicesForRightBlock = (d) => ((d.server !== null)
  && rbAllowedSubscriptionTypes.includes(d.subscription_type));

const correctUserInfo = (userInfo) => ({
  ...userInfo,
  isAdmin: (userInfo?.admin_menu_items || []).length > 0,
  admin_menu_items: userInfo.admin_menu_items.length > 0
    ? [...userInfo.admin_menu_items, ...COMMON_ADMIN_URLS]
    : [],
});

export const loadUser = () => (dispatch) => getUserAction().then((res) => {
  i18n.changeLanguage(res.frontend_language);
  const corrUserInfo = correctUserInfo(res);
  dispatch(setUserInfo(corrUserInfo));
  return corrUserInfo;
});

export const loadWithdrawalWallets = () => (dispatch) => getWithdrawalWalletsAction()
  .then((res) => dispatch(setWithdrawalWallets(res?.results || [])));

export const loadWithdrawalSystems = () => (dispatch) => getWithdrawalSystemsAction()
  .then((res) => dispatch(setWithdrawalSystems(res?.results || [])));

export const loadServers = () => (dispatch) => getServersAction()
  .then((res) => dispatch(setServers(
    (res?.results || []).map(
      (s) => ({ ...s, name: s?.name || '-', description: s?.description || '' }),
    ),
  )));

export const switchDeviceSelected = (dev) => (dispatch) => {
  const stateSelectedDevices = store.getState().selectedDevices;
  const currentSelectedDevices = [...(stateSelectedDevices || getStoredSelectedDevices() || [])];
  const selectedDevicesIds = currentSelectedDevices.map((d) => d.id);
  const selectedDevices = selectedDevicesIds.includes(dev.id)
    ? currentSelectedDevices.filter((d) => d.id !== dev.id)
    : [...currentSelectedDevices, { ...dev }];
  dispatch(setSelectedDevices(selectedDevices));
};

export const updateStoredSelectedDevices = (devices = null) => (dispatch) => {
  const useDevices = devices || getAllDevices(store.getState()) || [];
  const currentlySelectedDevicesIds = getSelectedDevices(store.getState()).map((d) => d.id);
  const newSelectedDevices = useDevices.length > 1
    ? useDevices.filter(
      (d) => currentlySelectedDevicesIds.includes(d.id),
    )
    : useDevices;
  dispatch(setSelectedDevices(newSelectedDevices));

  const rightDevice = getRightDevice(store.getState());
  // if (rightDevice?.id && currentlySelectedDevicesIds.includes(rightDevice.id)) {
  if (rightDevice?.id) {
    dispatch(
      setRightDevice(useDevices.find((d) => d.id === rightDevice.id) || {}),
    );
  }
};

export const loadAllDevicesRedux = (setIsDevicesLoading) => async (dispatch) => {
  let currentPage = 1;
  let allDevices = [];
  let isAllGetted = false;
  let isNextExist = false;

  if (setIsDevicesLoading) {
    setIsDevicesLoading(true);
  }

  while (!isAllGetted) {
    // eslint-disable-next-line no-await-in-loop
    const gettedPart = await getAllDevicesAction({ page: currentPage, limit: 100 })
      // eslint-disable-next-line no-loop-func
      .then(({ results, next }) => {
        isNextExist = !!next;
        return (results || []);
      })
      .catch((err) => {
        dispatch(setSnack(err));
        return [];
      });
    allDevices = allDevices.concat(gettedPart);

    currentPage += 1;
    isAllGetted = gettedPart.length === 0 || !isNextExist;
  }

  const processedDevices = allDevices.map(processLoadedDevice);
  const processedDevicesForRightBlock = processedDevices.filter(processDevicesForRightBlock);

  dispatch(setAllDevices(processedDevices));

  if (allDevices.length === 1) {
    dispatch(setSelectedDevices(processedDevices));
  }

  const rightDeviceOld = store.getState().rightDevice || processedDevicesForRightBlock[0] || {};
  const rightDeviceNew = processedDevices.find((d) => d.id === rightDeviceOld.id);
  dispatch(setRightDevice(rightDeviceNew || processedDevicesForRightBlock[0] || null));

  dispatch(updateStoredSelectedDevices(processedDevices));

  if (setIsDevicesLoading) {
    setIsDevicesLoading(false);
  }
};

export const createDeviceRedux = (
  name, description,
) => (dispatch) => request2Service(urls.addDevice, {
  method: 'POST',
  body: { name, description },
})
  .then((res) => {
    const processedResult = processLoadedDevice(res);
    // const devices = [...store.getState().devices];
    // devices.push(processedResult);
    // dispatch(setDevices(devices));
    // dispatch(setDevicesCount((store.getState().devicesCount || 0) + 1));

    const allDevices = [...store.getState().allDevices];
    allDevices.push(processedResult);
    dispatch(setAllDevices(allDevices));

    if (store.getState().devicesCount <= 1) {
      dispatch(switchDeviceSelected(processedResult));
      dispatch(setRightDevice(processedResult));
    }
    dispatch(updateStoredSelectedDevices(allDevices));
  })
  .catch((err) => {
    dispatch(setSnack(err));
  });

export const deleteDeviceRedux = (device) => (dispatch) => request2Service(
  urls.deleteDevice(device.id), {
    method: 'DELETE',
  },
)
  .then(() => {
    // const devices = [...store.getState().devices]
    //   .filter((d) => d.id !== device.id);
    // dispatch(setDevices(devices));
    // dispatch(setDevicesCount((store.getState().devicesCount || 1) - 1));

    const allDevices = [...(store.getState().allDevices || [])]
      .filter((d) => (d.id !== device.id));
    dispatch(setAllDevices(allDevices));

    const selectedDevices = (allDevices.length === 1) ? [...allDevices]
      : [...(store.getState().selectedDevices || [])].filter((d) => (d.id !== device.id));

    dispatch(updateStoredSelectedDevices(selectedDevices));
  })
  .catch((err) => {
    dispatch(setSnack(err));
  });

// export const loadPlayerDevices = () => (dispatch) => getAllDevicesAction(
//   { subscription_type: subscribeTypes.Active },
// )
//   .then((res) => {
//     dispatch(setPlayerDevices((res?.results || [])
//       .map(processLoadedDevice)
//       .filter((d) => (
//         [subscribeTypes.Active, subscribeTypes.Ending]
//           .includes(d.subscription_type)))
//       .filter((d) => !!d.server)));
//   })
//   .catch((err) => dispatch(setSnack(err)));

export const loadPlaylistsRedux = () => (dispatch) => RequestService(
  urlsChannels.playlistList,
  {
    method: 'GET',
    params: {
      limit: 1000,
      offset: 0,
    },
  },
).then((res) => {
  const plData = res.results;
  dispatch(setPlaylist(plData));
  return plData;
}).catch((err) => {
  dispatch(setSnack(err));
});

export const buyPremiumForDevice = (device) => (dispatch) => device
  && request2Service(urls.setPremium(device.id), {
    method: 'POST',
    body: { force: true },
  })
    .then(() => {
      dispatch(loadAllDevicesRedux());
      return dispatch(loadUser());
    })
    .catch((err) => {
      dispatch(setSnack(err));
      return { error: true, data: err };
    });

export const getSnackContent = (state) => {
  if (!state || !state.snack) return {};
  const useValue = {
    status: state?.snack?.status,
    // eslint-disable-next-line no-nested-ternary
    type: (state?.snack?.type && !state.snack?.data) ? state.snack.type : (state.snack.status === 200 ? 'success' : 'error'),
    content: state?.snack?.content || null,
    message_type: state.snack?.message_type || state.snack?.data?.message_type || 'undefined',
    message_params: state.snack?.message_params || state.snack?.data?.message_params || '',
    key: state?.snack?.key || Math.random(),
  };
  return useValue;
};

export const updateStoredDevice = (deviceId, updData) => (dispatch) => {
  const allDevices = [...(getAllDevices(store.getState()) || [])];
  // const playerDevices = getPlayerDevices(store.getState());
  // const devices = getDevices(store.getState());
  // const selectedDevices = getSelectedDevices(store.getState());
  // const rightDevice = getRightDevice(store.getState());

  // const useDeviceIndex = (devices || []).findIndex((d) => d.id === deviceId);
  // if (useDeviceIndex >= 0) {
  //   const updatedDevice = { ...devices[useDeviceIndex], ...updData };
  //   devices.splice(useDeviceIndex, 1, updatedDevice);
  //   dispatch(setDevices([...devices]));
  // }

  const useAllDeviceIndex = (allDevices || []).findIndex((d) => d.id === deviceId);
  if (useAllDeviceIndex >= 0) {
    const updatedDevice = { ...allDevices[useAllDeviceIndex], ...updData };
    allDevices.splice(useAllDeviceIndex, 1, updatedDevice);
    dispatch(setAllDevices([...allDevices]));
    dispatch(updateStoredSelectedDevices(allDevices));
  }

  // if (rightDevice?.id === deviceId) {
  //   const updatedDevice = { ...rightDevice, ...updData };
  //   dispatch(setRightDevice(updatedDevice));
  // }

  // const usePlayerDeviceIndex = (playerDevices || []).findIndex((d) => d.id === deviceId);
  // if (usePlayerDeviceIndex >= 0) {
  //   const updatedDevice = { ...usePlayerDeviceIndex[usePlayerDeviceIndex], ...updData };
  //   playerDevices.splice(usePlayerDeviceIndex, 1, updatedDevice);
  //   dispatch(setPlayerDevices([...playerDevices]));
  // }

  // const useSelectedDeviceIndex = (selectedDevices || []).findIndex((d) => d.id === deviceId);
  // if (useSelectedDeviceIndex >= 0) {
  //   const updatedDevice = { ...selectedDevices[useSelectedDeviceIndex], ...updData };
  //   selectedDevices.splice(useSelectedDeviceIndex, 1, updatedDevice);
  //   dispatch(setSelectedDevices([...selectedDevices]));
  // }
};

/**
 * Обновляем параметры устройств в хранилище
 * @param devicesData [{ id, ...updateParams }]
 * @returns {(function(*): void)|*}
 */
export const updateStoredDevices = (devicesData) => (dispatch) => {
  if ((devicesData || []).length === 0) {
    return;
  }

  const allDevices = [...(getAllDevices(store.getState()) || [])];

  (devicesData || []).forEach((dd) => {
    const { id, ...updData } = dd;
    const useAllDeviceIndex = allDevices.findIndex((d) => d.id === id);
    if (useAllDeviceIndex >= 0) {
      const updatedDevice = { ...allDevices[useAllDeviceIndex], ...updData };
      allDevices.splice(useAllDeviceIndex, 1, updatedDevice);
    }
  });

  dispatch(setAllDevices([...allDevices]));
  dispatch(updateStoredSelectedDevices(allDevices));
};

export const updateDevicesPlaylistRedux = (devices, playlistId) => (dispatch) => {
  const actions = [];
  (devices || []).forEach((d) => {
    actions.push(updateDevicePlayList(d.id, playlistId));
    // await updateDevicePlayList(d.id, playlistId)
    //   .then(() => dispatch(updateStoredDevice(d.id, { playlist: playlistId })))
    //   .catch((err) => {
    //     dispatch(setSnack(err));
    //   });
  });
  return Promise.all(actions)
    .then(() => {
      (devices || []).forEach((d) => dispatch(updateStoredDevice(d.id, { playlist: playlistId })));
    })
    .catch((errs) => dispatch(setSnack(errs[0])));
};

export const updateDeviceServerRedux = (deviceId, serverId) => (dispatch) => (
  bindDeviceToServerAction(deviceId, serverId)
    .then(() => {
      dispatch(updateStoredDevice(deviceId, { server: serverId }));
    }).catch((err) => {
      dispatch(setSnack(err));
    })
);

export const selectOnlyDevices = (devices = []) => (dispatch) => {
  dispatch(setSelectedDevices(devices));
};

export const switchDeviceSelectedAll = (checkState, page, limit) => (dispatch) => {
  const currentSelectedDevices = [...(store.getState().selectedDevices || [])];
  const storedDevies = (store.getState().allDevices || []);
  const devices = ((page || 0) > 0) && ((limit || 0) > 0)
    ? storedDevies.slice((page - 1) * limit, page * limit) : storedDevies;
  const devicesIds = devices.map((d) => d.id);
  const currentSelectedDevicesIds = currentSelectedDevices.map((d) => d.id);
  const allDevices = checkState
    // add current devices to selected
    ? [
      ...currentSelectedDevices,
      ...devices.filter((d) => !currentSelectedDevicesIds.includes(d.id)),
    ]
    // remove current devices from selected
    : [
      ...currentSelectedDevices.filter((csd) => !devicesIds.includes(csd.id)),
    ];
  dispatch(setSelectedDevices(allDevices));
};

export const closeDevicesWelcome = () => (dispatch) => {
  localStorage.setItem('welcomeClosed', 'yes');
  dispatch(setWelcomeClosed(true));
};

export const getDevicesWelcome = (state) => {
  const closed = localStorage.getItem('welcomeClosed');
  return closed || !state?.welcomeClosed;
};

export const setServerForSelected = (server) => async (dispatch) => {
  const devices = [...getSelectedDevices(store.getState())];
  const bindActions = [];
  devices.forEach((d) => bindActions.push(bindDeviceToServerAction(d.id, server.id)));
  Promise.allSettled(bindActions)
    .catch((err) => dispatch(setSnack(err)))
    .finally(() => dispatch(loadAllDevicesRedux()));
};

export const selectPremium = (state) => {
  if (!state) return false;
  return state.premium;
};

export const resetData = () => (dispatch) => {
  dispatch(setRightDevice({}));
  // dispatch(setPlayerDevices(null));
  dispatch(setAllDevices(null));
  // dispatch(setDevices(null));
  // dispatch(setDevicesCount(null));
  dispatch(setSelectedDevices([]));
};

/**
 * Get logos from url, store in memory 'n in store
 * @param logos [{ url }]
 */
let LogosLoaded = [];
export const getChannelsLogos = (logos) => async (dispatch) => {
  const currentCachedLogos = await getStoredChannelsLogos();
  const cachedLogosKeys = Object.keys(currentCachedLogos);
  LogosLoaded = LogosLoaded.length === 0 ? [...cachedLogosKeys] : LogosLoaded;

  // filter out loaded in session logos
  const filteredLogos = Object.values(logos)
    // .filter((l) => !cachedLogosKeys.includes(l.url));
    .filter((l) => !LogosLoaded.includes(l.url));

  if (caches) {
    const needToLoad = filteredLogos.map((fl) => fetch(fl.url));
    if ((needToLoad || []).length > 0) {
      await caches.open(CHANNELS_LOGOS).then(async (cache) => {
        await Promise.allSettled(needToLoad)
          .then((results) => {
            results.forEach((res) => {
              if ((res.status === 'fulfilled') && (res?.value?.status === 200)) {
                cache.put(res.value.url, res.value.clone());
                LogosLoaded.push(res.value.url);
              }
            });
          });
      });
    }
  }

  const cachedLogos = await getStoredChannelsLogos();
  dispatch(setChannelsLogoCache(cachedLogos));
};
