/* eslint no-unused-vars: 0 */
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  setSnack,
  setSnackFromErrorResponse,
} from '@/store/admin/actions/common';
import {
  getPaymentSystems,
  getUsers,
  getWithdrawalSystems,
} from '@/store/admin/selectors';

import {
  addUserBalanceAction,
  getPaymentsStatsAction,
  getPaymentsAction,
  returnPaymentAction,
  approveWithdrawalTransactionAction,
  rejectWithdrawalTransactionAction,
  getWithdrawalTransactionsAction,
} from '@/services/actions/admin/finance.actions';

import { useAdminContext } from '../../../AdminContext';

const FinanceContext = createContext({});

export const DATA_TYPES = {
  users: 'users',
};

const initStatsFilter = {
  d_begin: '',
  d_end: '',
  user_id: '',
};

const initPaymentsFilter = {
  transaction_type: '',
  d_begin: '',
  d_end: '',
  user_id: '',
  q: '',
  ordering: '',
  page: 1,
  limit: localStorage.getItem('limit') || 10,
};

const initWithdrawalTransactionsFilter = {
  status: '',
  withdrawal_system: '',
  d_begin: '',
  d_end: '',
  user_name: '',
  user_id: '',
  ordering: '',
  page: 1,
  limit: localStorage.getItem('limit') || 10,
};

const initPaymentsData = {
  count: 0,
};

const initWTransactionsData = {
  count: 0,
};

export const PAYMENT_ACTIONS = {
  notSet: 'notSet',
  returnPayment: 'returnPayment',
};

export const WTRANSACTION_ACTIONS = {
  notSet: 'notSet',
  approve: 'approve',
  reject: 'reject',
};

export const WTRANSACTION_STATUSES = {
  created: 'В обработке',
  approved: 'Выполнена',
  rejected: 'Отменена',
};

export const PTRANSACTION_TYPES = {
  replenish: 'replenishment',
};

export const SEARCH_FILTER_TIMEOUT = 500;

export const FinanceContextProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [paymentSystems, setPaymentSystems] = useState([]);

  const [stats, setStats] = useState([]);
  const [statsFilter, setStatsFilter] = useState(initStatsFilter);
  const [isStatsProcerssing, setIsStatsProcessing] = useState(false);

  const [payments, setPayments] = useState([]);
  const [paymentsData, setPaymentsData] = useState(initPaymentsData);
  const [paymentsFilter, setPaymentsFilter] = useState(initPaymentsFilter);
  const [isPaymentsProcerssing, setIsPaymentsProcessing] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [paymentAction, setPaymentAction] = useState(PAYMENT_ACTIONS.notSet);

  const [withdrawalSystems, setWithdrawalSystems] = useState([]);

  const [wTransactions, setWTransactions] = useState([]);
  const [wTransactionsData, setWTransactionsData] = useState(initWTransactionsData);
  const [wTransactionsFilter, setWTransactionsFilter] = useState(initWithdrawalTransactionsFilter);
  const [isWTransactionsProcerssing, setIsWTransactionsProcessing] = useState(false);
  const [selectedWTransaction, setSelectedWTransaction] = useState(null);
  const [wTransactionAction, setWTransactionAction] = useState(WTRANSACTION_ACTIONS.notSet);

  const {
    loadUsers,
    loadPaymentSystems,
    loadWithdrawalSystems,
  } = useAdminContext();

  const dispatch = useDispatch();

  const storedUsers = useSelector(getUsers);
  const storedPaymentSystems = useSelector(getPaymentSystems);
  const storedWithdrawalSystems = useSelector(getWithdrawalSystems);

  const addUserBalance = (userId, paymentSystemId, amount, isWithBonuses) => addUserBalanceAction({
    user_id: userId,
    payment_system_id: paymentSystemId,
    amount,
    is_used_bonuses: isWithBonuses,
  })
    .then((res) => {
      setStatsFilter({ ...statsFilter });
      return res;
    })
    .catch((err) => {
      dispatch(setSnackFromErrorResponse(err));
    });

  const getFilterString = (filterObject) => {
    const affectedFilter = {};
    Object.entries(filterObject).forEach(([key, value]) => {
      if (value) { affectedFilter[key] = value; }
    });
    return new URLSearchParams(affectedFilter).toString();
  };

  const getStats = () => getPaymentsStatsAction(getFilterString(statsFilter));

  const getPayments = () => getPaymentsAction(getFilterString(paymentsFilter));

  const returnPayment = () => {
    setIsPaymentsProcessing(true);
    returnPaymentAction(selectedPayment?.id || 0)
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);
        return res;
      })
      .catch((err) => {
        dispatch(setSnackFromErrorResponse(err));
      })
      .finally(() => setIsPaymentsProcessing(true));
  };

  const getWithdrawalTransactions = () => {
    setIsWTransactionsProcessing(true);
    return getWithdrawalTransactionsAction(
      getFilterString(wTransactionsFilter),
    )
      .then((res) => {
        setWTransactions(res?.results || []);
        setWTransactionsData((prevState) => ({ ...prevState, count: res?.count || 0 }));
        return res;
      })
      .finally(() => setIsWTransactionsProcessing(false));
  };

  const approveWithdrawalTransaction = () => {
    setIsWTransactionsProcessing(true);
    return approveWithdrawalTransactionAction(selectedWTransaction.id)
      .then((res) => {
        const snackValue = {
          status: 200,
          type: 'success',
          content: `Транзакция №${res?.id}\nна сумму ${res.amount} \nподтверждена`,
        };
        getWithdrawalTransactions();
        dispatch(setSnack(snackValue));
        return res;
      }).catch((err) => {
        dispatch(setSnackFromErrorResponse(err));
        return err;
      })
      .finally(setIsWTransactionsProcessing(false));
  };

  const rejectWithdrawalTransaction = (cancelReason) => {
    setIsWTransactionsProcessing(true);
    return rejectWithdrawalTransactionAction(selectedWTransaction.id, cancelReason)
      .then((res) => {
        const snackValue = {
          status: 200,
          type: 'success',
          content: `Транзакция №${res?.id}\nна сумму ${res.amount} \nуспешно отменена`,
        };
        getWithdrawalTransactions();
        dispatch(setSnack(snackValue));
      }).catch((err) => {
        dispatch(setSnackFromErrorResponse(err));
        return err;
      })
      .finally(setIsWTransactionsProcessing(false));
  };

  useEffect(() => {
    if (!storedUsers) {
      loadUsers();
    } else {
      setUsers(storedUsers);
    }
  }, [storedUsers]);

  useEffect(() => {
    if (!storedPaymentSystems) {
      loadPaymentSystems();
    } else {
      setPaymentSystems(storedPaymentSystems);
    }
  }, [storedPaymentSystems]);

  useEffect(() => {
    if (!storedWithdrawalSystems) {
      loadWithdrawalSystems();
    } else {
      setWithdrawalSystems(storedWithdrawalSystems);
    }
  }, [storedWithdrawalSystems]);

  useEffect(() => {
    setIsStatsProcessing(true);
    getStats()
      .then((res) => {
        setStats(res);
        return res;
      })
      .catch((err) => {
        dispatch(setSnackFromErrorResponse(err));
      })
      .finally(() => {
        setIsStatsProcessing(false);
      });
  }, [statsFilter]);

  useEffect(() => {
    const userParam = { user_id: selectedUser?.id || '' };
    setStatsFilter({ ...statsFilter, ...userParam });
    setWTransactionsFilter({ ...wTransactionsFilter, ...userParam });
    setPaymentsFilter({ ...paymentsFilter, ...userParam });
  }, [selectedUser]);

  useEffect(() => {
    setIsPaymentsProcessing(true);
    getPayments()
      .then((res) => {
        setPayments(res?.results || []);
        setPaymentsData((prevState) => ({ ...prevState, count: res?.count || 0 }));
      })
      .catch((err) => {
        dispatch(setSnackFromErrorResponse(err));
      })
      .finally(() => {
        setIsPaymentsProcessing(false);
      });
  }, [paymentsFilter]);

  useEffect(() => {
    getWithdrawalTransactions();
  }, [wTransactionsFilter]);

  const value = {
    users,
    paymentSystems,
    addUserBalance,
    selectedUser,
    setSelectedUser,
    stats,
    statsFilter,
    setStatsFilter,
    isStatsProcerssing,
    payments,
    paymentsFilter,
    setPaymentsFilter,
    returnPayment,
    paymentsData,
    isPaymentsProcerssing,
    setIsPaymentsProcessing,
    selectedPayment,
    setSelectedPayment,
    paymentAction,
    setPaymentAction,
    withdrawalSystems,
    wTransactions,
    wTransactionsData,
    wTransactionsFilter,
    setWTransactionsFilter,
    isWTransactionsProcerssing,
    selectedWTransaction,
    setSelectedWTransaction,
    wTransactionAction,
    setWTransactionAction,
    approveWithdrawalTransaction,
    rejectWithdrawalTransaction,
  };

  return (
    <FinanceContext.Provider value={value}>
      {children}
    </FinanceContext.Provider>
  );
};

export const useFinanceContext = () => useContext(FinanceContext);
