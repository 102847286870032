import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { secondary, text01, text02 } from '@/common/constants/theme';

import CloseIcon from '@/screens/PersonalPageScreen/components/icons/CloseIcon';

import { SHOW_SELECTED_DEVICES, usePersonalPageContext } from '../../PersonalPageContext';

export default function DevicesSelected() {
  const {
    allDevices,
    selectedDevices,
    handleSelectDevice,
  } = usePersonalPageContext();
  const { t } = useTranslation('translations');
  const history = useHistory();

  if (!SHOW_SELECTED_DEVICES) {
    return null;
  }

  if (selectedDevices.length === 0) {
    const url = '/personal-page/devices';
    if (history.location.pathname !== url) {
      history.push(url);
    }
    return (<div />);
  }

  const disableRemove = (allDevices || []).length <= 1;
  return (
    <SelectedDevices>
      <Text>{t('devicesContent.selected')}</Text>
      {selectedDevices.map((device) => (
        <DeviceContainer key={device.pk}>
          <DeviceName>{device.name}</DeviceName>
          {!disableRemove && (
            <StyledIcon onClick={() => handleSelectDevice(device)}>
              <CloseIcon />
            </StyledIcon>
          )}
        </DeviceContainer>
      ))}
    </SelectedDevices>
  );
}

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: ${text02};
`;

const SelectedDevices = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  max-height: 5rem;
  overflow: auto;
`;

const DeviceContainer = styled.div`
  display: flex;
  align-items: baseline;
  border-right: 1px solid #EFEEF3;
  padding: 0 .75rem;

  :last-of-type {
    border: none;
  }
`;

const DeviceName = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 36px;
  color: ${text01};
  margin: 0 .5rem;
`;

const StyledIcon = styled.div`
  margin: 3px .5rem 0;
  cursor: pointer;

  & path {
    fill: ${secondary}
  }
`;
