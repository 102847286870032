/* eslint no-console: 0 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { greyText } from '@/common/constants/theme';
import { useTranslation } from 'react-i18next';
import Loader from '@/common/components/Loader';
import AuthorizationLayout from '../components/AuthorizationLayout';
import { confirmNewEmailAction, signOutAction } from '@/services/actions/authorization.actions';
import auth from '@/services/auth.service';
import TitleWithOrange from '../components/TitleWithOrange';
import Button from '@/common/components/Button';

export default function ConfirmNewEmailContainer({ match }) {
  const { t } = useTranslation(['translations']);

  const [errors, setErrors] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  const { token } = match.params;

  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const failureRequest = (err) => {
    console.log('error');
    console.log(err);

    setLoading(false);
    let msg = '';
    if (typeof err === 'undefined') {
      msg = t('common.error.request');
    } else if (typeof err === 'string') {
      msg = err;
    } else if (typeof err === 'object') {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const m of Object.keys(err)) {
        msg += err.errors[m].join(' ');
      }
    }
    // eslint-disable-next-line no-console
    setErrors(msg);
  };

  const successRequest = (text) => {
    console.log('successChange');
    console.log(text);

    setLoading(false);
    setIsEmailConfirmed(true);

    signOutAction().then(() => {
      history.push(match.url);
    }).catch((err) => {
      failureRequest(t(`confirm.changeEmail.errors.${Object.values(err)[0]?.payload?.message_type}`));
    });
  };

  useEffect(() => {
    setLoading(true);
    confirmNewEmailAction(token)
      .then((res) => successRequest(res))
      .catch((err) => failureRequest(t(`confirm.changeEmail.errors.${Object.values(err)[0]?.payload?.message_type}`)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailChangeConfirmed = () => {
    auth.clearToken();
    history.push(match.url);
    window.location.href = '/sign-in';
  };

  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <AuthorizationLayout>
        <TitleWithOrange
          textBlack1={t('confirm.changeEmail.confirm.textBlack1')}
          textOrange={t('confirm.changeEmail.confirm.textOrange')}
          textBlack2={t('confirm.changeEmail.confirm.textBlack2')}
        />
        <Form>
          <RequestResultBlock>{t('confirm.changeEmail.confirmScreenCaption')}</RequestResultBlock>
          {isEmailConfirmed
            ? (
              <>
                <RequestSuccesBlock>
                  <Caption>{t('confirm.changeEmail.success.confirmedNewEmail')}</Caption>
                </RequestSuccesBlock>
                <Button
                  isUpperCase
                  onClick={handleEmailChangeConfirmed}
                >
                  {t('confirm.changeEmail.loginButton')}
                </Button>
              </>
            ) : (
              <>
                <ErrorsBlock>{errors}</ErrorsBlock>
                <Button
                  isUpperCase
                  onClick={handleRetry}
                >
                  {t('confirm.changeEmail.retryButton')}
                </Button>
              </>
            )}
        </Form>
      </AuthorizationLayout>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 8rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const RequestResultBlock = styled.div`
  font-size: .85em;
  color: ${greyText};
`;

const RequestSuccesBlock = styled.div`
  font-size: 1.2em;
  line-height: 1.2em;
  width: 100%;
  padding: 0.5rem 0 0.5rem 0;
`;

const Caption = styled.div`
  font-size: 1.2em;
  width: 100%;
  padding: 0 0 .5rem 0;
`;

const ErrorsBlock = styled.div`
  font-size: 1.2em;
  width: 100%;
  padding: 0 0 .5rem 0;
`;
