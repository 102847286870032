/* eslint-disable no-shadow */

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react';

import {
  getPromocodeAction,
  getPromocodesListAction,
  createPromoCodeAction,
  blockPromoCodeAction,
  deletePromoCodeActions,
  getTransactionsAction,
} from '@/services/actions/admin/promocodes.actions';

import { useTranslation } from 'react-i18next';

const PromocodesContext = createContext({});

const PROMOCODE_CODE_MAX_LEN = 37;

const initialFilters = {
  limit: localStorage.getItem('PromocodesContext_limit') || 10,
  page: 1,
};

const dataTypes = {
  promocodes: 'promocodes',
  transactions: 'promocodes.transactions',
};

export const PromoCodesContextProvider = ({ children }) => {
  const [isBusy, setIsBusy] = useState(true);

  const [infoShow, setInfoShow] = useState(false);
  const [infoType, setInfoType] = useState('success');
  const [infoText, setInfoText] = useState('inits');

  const [transactionList, setTransactionList] = useState([]);
  const [transactionListLength, setTransactionListLength] = useState(0);
  const [transactionShow, setTransactionShow] = useState(false);
  const [transactionFilters, setTransactionFilters] = useState(initialFilters);

  const [promoCodesList, setPromoCodesList] = useState([]);
  const [promoCodesListLength, setPromoCodesListLength] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [isListInitilized, setIsListInitilized] = useState(false);

  const { t } = useTranslation('translations');

  const getOffset = (filtersValue) => ((filtersValue.page - 1) * filtersValue.limit);

  const getFilterString = (filter) => (filter ? `?${Object.keys(filter).map((key) => (`${key}=${filter[key]}`)).join('&')}` : '');

  const collectErrors = (errors) => Object.entries(errors).map(([key, values]) => `${t('common.fieldText')}: "${key}", ${t('common.errorText')}: ${values.join(', ')}`).join('; ');

  const setError = (err, type = 'promocodes') => {
    setInfoType('error');
    const errors = err?.response?.payload?.errors || [];
    setInfoText(`${t(`admin.iptv.${type}.errors.${err?.response?.payload?.message_type}`)}: \n${errors.length > 0 ? collectErrors(errors) : t('common.error.request')}`);
  };

  const getPromoCodesList = (newFilters = null) => {
    setIsBusy(true);
    getPromocodesListAction(getFilterString(newFilters || filters))
      .then((res) => {
        setPromoCodesList((res?.results || [])
          .map((pc) => ({
            ...pc,
            codeDisplay: pc.code.length > PROMOCODE_CODE_MAX_LEN
              ? `${pc.code.substr(0, PROMOCODE_CODE_MAX_LEN)}...`
              : pc.code,
          })));
        setPromoCodesListLength(res.count);
      })
      .catch((err) => {
        setError(err);
        setInfoShow(true);
      })
      .finally(() => { setIsBusy(false); });
  };

  const getPromoCode = (id) => getPromocodeAction(id);

  const createPromoCode = (data) => {
    const xdata = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        xdata[key] = value;
      }
    });

    createPromoCodeAction(xdata)
      .then((res) => {
        if ((res?.code || '') === xdata.code) {
          getPromoCodesList();
          setInfoType('success');
          setInfoText(t('admin.iptv.promocodes.success.add'));
        } else {
          setInfoType('error');
          setInfoText(t('admin.iptv.promocodes.errors.add'));
        }
      })
      .catch((err) => setError(err))
      .finally(() => { setInfoShow(true); });
  };

  const blockPromoCode = (id, state) => {
    blockPromoCodeAction(id, state)
      .then((res) => {
        const result = (res?.id || -1) === id ? 'success' : 'errors';
        if (result === 'success') {
          getPromoCodesList();
        }
        setInfoType(result);
        setInfoText(t(`admin.iptv.promocodes.${result}.${state ? 'block_unset' : 'block_set'}`));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setError(err);
      })
      .finally(() => { setInfoShow(true); });
  };

  const deletePromoCode = (id) => {
    deletePromoCodeActions(id)
      .then(() => {
        getPromoCodesList();
        setInfoType('success');
        setInfoText(t('admin.iptv.promocodes.success.delete'));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setError(err);
      })
      .finally(() => { setInfoShow(true); });
  };

  const getTransactionsList = (id, newFilters = null) => {
    setIsBusy(true);
    setTransactionListLength(0);
    getTransactionsAction(id, getFilterString(newFilters || transactionFilters))
      .then((res) => {
        const results = res?.results || [];
        setTransactionList(results || []);
        if (results) {
          if (results.length === 0) {
            setTransactionShow(false);
            setInfoType('warning');
            setInfoText(t('admin.iptv.promocodes.transactions.emptyList'));
            setInfoShow(true);
          } else {
            setTransactionListLength(res.count);
            setTransactionShow(true);
          }
        } else {
          setTransactionShow(false);
          setInfoType('error');
          setInfoText(t('admin.iptv.promocodes.transactions.errors.getList'));
          setInfoShow(true);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setError(err, dataTypes.transactions);
      })
      .finally(() => setIsBusy(false));
  };

  const handleFiltersChange = (field, value) => {
    let newFilters = { ...filters, [field]: value };
    newFilters = { ...newFilters, offset: getOffset(newFilters) };

    setFilters(newFilters);

    getPromoCodesList(newFilters);
  };

  const handleFiltersTransactionsChange = (field, value) => {
    let newFilters = { ...transactionFilters, [field]: value };
    newFilters = { ...newFilters, offset: getOffset(newFilters) };

    setTransactionFilters(newFilters);

    getTransactionsList(newFilters);
  };

  useEffect(() => {
    if (!isListInitilized) {
      getPromoCodesList();
      setIsListInitilized(true);
      setIsBusy(false);
    }
  }, [isListInitilized]);

  const value = {
    isBusy,
    setIsBusy,
    infoShow,
    setInfoShow,
    infoType,
    setInfoType,
    infoText,
    setInfoText,
    promoCodesList,
    promoCodesListLength,
    filters,
    handleFiltersChange,
    getPromoCodesList,
    getPromoCode,
    createPromoCode,
    blockPromoCode,
    deletePromoCode,
    getTransactionsList,
    transactionList,
    transactionListLength,
    transactionShow,
    setTransactionShow,
    transactionFilters,
    handleFiltersTransactionsChange,
  };

  return (
    <PromocodesContext.Provider value={value}>
      {children}
    </PromocodesContext.Provider>
  );
};

export const usePromoCodesContext = () => useContext(PromocodesContext);
