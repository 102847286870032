import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import {
  white,
  text01,
  text02,
  secondary,
  primary,
} from '@/common/constants/theme';

import Button from '@/common/components/Button';

const COLORS = {
  primary: {
    main: secondary,
  },
  secondary: {
    main: primary,
  },
};

const HomeBeginWatchStepCard = ({
  step,
  title,
  descr,
  color,
  button,
  imgSrc,
  href,
}) => (
  <Container color={COLORS[color].main}>
    <Wrapper flex>
      <Wrapper>
        <Step>{step}</Step>
        <Title>{title}</Title>
        <Text bottomMargin>{descr}</Text>
        <Link to={href}>
          <StyledButton
            isBoldText
            isUpperCase
            color={color}
          >
            {button}
          </StyledButton>
        </Link>
      </Wrapper>
      <Wrapper>
        <Img
          src={imgSrc}
          alt=""
          className="home-img"
          draggable={false}
        />
      </Wrapper>
    </Wrapper>
  </Container>
);

export default HomeBeginWatchStepCard;

const Container = styled.div`
  width: 50%;
  height: 240px;
  padding: 29px 0 0 35px;

  background: ${white};
  box-shadow: 0 4px 24px rgba(160, 160, 160, 0.25);
  border-top: 2px solid ${({ color }) => color};
  border-radius: 4px;

  text-align: left;

  &:not(:last-child) {
    margin-right: 10px;

    & .home-img {
      bottom: 110px;
    }
  }
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 20px;')}

  font-family: Gilroy;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 15px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  @media (max-width: 1360px) {
    font-size: 20px;
  }

  @media (max-width: 1198px) {
    font-size: 24px;
  }
`;

const Step = styled(Text)`
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 14px;
  color: ${text02};
  line-height: 24px;
  text-transform: uppercase;

  @media (max-width: 1360px) {
    font-size: 10px;
  }

  @media (max-width: 1198px) {
    font-size: 14px;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: 30px;
`;

const Img = styled.img`
  width: 260px;

  position: relative;
  bottom: 113px;
`;
