import React, { useState } from 'react';
import styled from 'styled-components';

// import PlayersFilters from './components/Filters';
import PlayersTable from './components/Table/Table';
import { PlayersContextProvider } from './PlayersContext';

const PlayersContainer = () => {
  const [values, setValues] = useState([
    {
      prevValue: 'SIP',
      value: 'SIP',
      isEdit: false,
      name: true,
      tvg: true,
      visible: true,
      key: 'sip',
    },
    {
      prevValue: 'OTT',
      value: 'OTT',
      isEdit: false,
      name: true,
      tvg: true,
      visible: true,
      key: 'ott',
    },
  ]);

  return (
    <PlayersContextProvider>
      <Container>
        {/* <PlayersFilters */}
        {/*  values={values} */}
        {/*  setValues={setValues} */}
        {/* /> */}
        <PlayersTable
          values={values}
          setValues={setValues}
        />
      </Container>
    </PlayersContextProvider>
  );
};

export default PlayersContainer;

const Container = styled.div`
  margin-top: 5px;
`;
