import React, { useState, useEffect } from 'react';
import CalendarIcon from '@/screens/PersonalPageScreen/components/icons/CalendarIcon';
import styled from 'styled-components';
import { format } from 'date-fns';

import {
  inputBorder,
  text01,
} from '@/common/constants/theme';
import CloseIcon from './icons/CloseIcon';
import DatePicker from '@/common/components/DatePicker';

const defaultFileds = { d_begin: '', d_end: '' };

export default function DatePickerDouble({
  changeFilters,
  fields,
}) {
  const [show, setShow] = useState(false);
  const [type, setType] = useState('');
  const [filter, setFilter] = useState(fields || defaultFileds);

  function toggle(t) {
    setType(t);
    setShow(!show);
  }

  function changeFilter(value, t) {
    const f = { ...filter };
    if (value) {
      f[t] = format(value, 'dd.MM.yyyy');
    } else {
      f[t] = '';
    }
    changeFilters(f);
    setFilter(f);
    setShow(false);
  }

  useEffect(() => {
    setFilter(fields);
  }, [fields]);

  return (
    <div>
      <FlexWrapper>
        {Object.keys(fields)
          .map((key) => (
            <SearchForm
              key={key}
            >
              <PeriodInput>
                <Clickable onClick={() => toggle(key)}><CalendarIcon /></Clickable>
                {filter[key]}
                {filter[key] && <Clickable onClick={() => changeFilter('', key)}><CloseIcon /></Clickable>}
              </PeriodInput>
            </SearchForm>
          ))}
      </FlexWrapper>
      {show && (
        <StyledCalendarWrapper>
          <StyledCalendar onChange={(value) => changeFilter(value, type)} />
          <DatePicker
            position="inherit"
            noApplyButton
            onChange={(v) => {
              changeFilter(v, type);
            }}
            isOpen={show}
            onClose={() => toggle(false)}
            // onOpen={() => setIsCalendarOpened(true)}
          />
        </StyledCalendarWrapper>
      )}
    </div>
  );
}

const SearchForm = styled.div`
  display: flex;
  align-items: center;
`;

const Clickable = styled.span`
  cursor: pointer;
  &:first-child{
    transform: translateY(5px);

  }
`;

const PeriodInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 24px;
  width: 130px;
  margin-right: 4px;


  border: 1px solid ${inputBorder};

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: ${text01};
  line-height: 20px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;

`;

const StyledCalendarWrapper = styled.div`
  z-index: 999999999;
  position: absolute;
`;

const StyledCalendar = styled.div`
  position: relative;
`;
