import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { primary, text01 } from '@/common/constants/theme';
import Wallet from '@/assets/icons/wallet.svg';
import { useAppContext } from '@/AppContext';
import NotificationsContainer from './Notifications/NotificationsContainer';

export default function InfoBlock() {
  const { t } = useTranslation(['translations']);
  const { userInfo } = useAppContext();

  return (
    <Container>
      <TopPanel>
        <BalanceContainer>
          <WalletContainer>
            <Icon src={Wallet} alt="" />
            {userInfo && userInfo.balance && <BalanceAmount>{`$ ${userInfo.balance}`}</BalanceAmount>}
          </WalletContainer>
          <BalanceButton to="/personal-page/balance">{t('wallet.buttonText')}</BalanceButton>
        </BalanceContainer>
      </TopPanel>
      <NotificationsContainer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 22rem;
`;

const TopPanel = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: .75rem;
`;

const Icon = styled.img`
  margin: 0 1rem;
`;

const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const WalletContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BalanceAmount = styled.div`
  font-family: Gilroy;
  font-weight: 600;
  font-size: 20px;
  color: ${text01};
  line-height: 20px;
`;

const BalanceButton = styled(Link)`
  font-family: Gilroy;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: ${primary};
  margin: 0 1rem;
  cursor: pointer;
  &&:hover {
    color: #ffab30;
  }
`;
