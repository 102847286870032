import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SortableContainer } from 'react-sortable-hoc';

import ChannelsMainGroupCard from './ChannelsMainGroupCard';
import { useChannelsContext } from '../../../../../ChannelsContext';

const ChannelsMainGroupCards = SortableContainer(({
  changeFiltersHandler,
  isDraggedChannel,
  setHoveredGroup,
  setEditedValue,
  setDeletedValue,
  isDraggable,
  isEditable,
  canChange,
}) => {
  const {
    loaders,
    groups,
    selectedGroupId,
    handleHiddenGroup,
    changeCurrentGroup,
    selectedPlaylist,
  } = useChannelsContext();

  const [isDisabled, setIsDisabled] = useState(loaders?.channels);

  const getChannelsCounts = (group) => {
    const isAllVisible = (group.count_selected === group.channels_count);
    const isAllHidden = (group.count_selected === 0);
    return isAllVisible || isAllHidden ? group.channels_count : `${group.count_selected}/${group.channels_count}`;
  };

  const handleGroupClick = (index, item) => {
    if (isDisabled) {
      return;
    }
    changeFiltersHandler('activeGroup', index);
    changeCurrentGroup(item);
  };

  useEffect(() => {
    setIsDisabled(loaders?.channels);
  }, [loaders?.channels]);

  return (
    <Container>
      <Wrapper>
        {(groups || []).map((item, i) => (
          <ChannelsMainGroupCard
            onClick={() => handleGroupClick(i, item)}
            onGroupClicked={() => handleGroupClick(i, item)}
            canChange={canChange}
            isEditable={isEditable}
            isDraggable={isDraggable}
            isDeleteable={isEditable && (item.channels_count === 0)}
            key={item.id}
            isActive={selectedGroupId === item.id}
            isVisible={!item.is_hidden && !selectedPlaylist?.is_virtual}
            visibleHandler={(isVisible) => handleHiddenGroup(item, isVisible)}
            isNew={item.isNew}
            count={getChannelsCounts(item)}
            onEdit={() => setEditedValue(item)}
            onDelete={() => setDeletedValue(item)}
            isDraggedChannel={isDraggedChannel}
            setHoveredGroup={setHoveredGroup}
            index={i}
            i={i}
          >
            {item.name}
          </ChannelsMainGroupCard>
        ))}
      </Wrapper>
    </Container>
  );
});
export default ChannelsMainGroupCards;

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
