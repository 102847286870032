import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  primary,
  text01,
  white,
} from '@/common/constants/theme';

import {
  setUserInfo,
  loadAllDevicesRedux,
} from '@/store/user/selectors';

import Button from '@/common/components/Button';
import StepsIndicator from '../../StepsIndicator';
import RightContent from './components/RightContent';
import PromoRow from './components/PromoRow';
import OrderSubscriptionDialog from '../components/OrderSubscriptionDialog';
import PageContentBlock from '../components/PageContentBlock';
import DevicesSelected from '../DevicesSelected';
import SubscriptionDevicesTable from './SubscriptionDevicesTable';
import Checkbox from '@/common/components/Checkbox';

import { useAppContext } from '@/AppContext';
import { SHOW_RIGHT_PANEL, usePersonalPageContext } from '../../../PersonalPageContext';
import { useSubscriptionContext } from './SubscriptionContext';

const BALANCE_MIN_TO_DISPLAY_HINT = 10;

const ONE_DEVICE_REDIRECT_URL = '/personal-page/server';
const MANY_DEVICES_REDIRECT_URL = '/personal-page/devices';

const Subscription = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('translations');

  const { getUser, userInfo } = useAppContext();
  const {
    selectedDevices,
    buySubscription,
    processBuySubscribtionResult,
  } = usePersonalPageContext();

  const [modalDialog, setModalDialog] = useState({});
  const [autoSubscription, setAutoSubscription] = useState(false);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(30);
  const [subscriptionDone, setSubscriptionDone] = useState(false);
  const [subscriptionResultSum, setSubscriptionResultSum] = useState(0);

  const {
    promoTransaction,
    discountValue,
    clearPromo,
  } = useSubscriptionContext();

  const [isVip, setVip] = useState(null);

  function getSubmitData() {
    return {
      deviceIds: selectedDevices.map((device) => device.id),
      period: subscriptionPeriod,
      isPremium: isVip,
      isAutoSubscr: autoSubscription,
      promoTransactionId: promoTransaction,
    };
  }

  function setModalDialogContent(response) {
    setModalDialog(processBuySubscribtionResult(response, discountValue));
  }

  const handleSubmit = () => buySubscription(getSubmitData())
    .then(setModalDialogContent)
    .catch(setModalDialogContent);

  const handleCloseSuccessSubscription = () => {
    setSubscriptionResultSum(0);
  };

  const onDialogSubmit = () => {
    setModalDialog({});
    buySubscription(getSubmitData(true), false)
      .then((order) => {
        clearPromo();
        dispatch(loadAllDevicesRedux());
        getUser().then((res) => {
          setSubscriptionResultSum(order.amount);
          setSubscriptionDone(true);
          dispatch(setUserInfo(res));
        });
      });
  };

  useEffect(() => {
    if ((selectedDevices || []).length === 1) {
      let useVip;
      if (selectedDevices[0].subscription) {
        useVip = (selectedDevices[0].subscription.is_premium_last !== undefined)
          ? selectedDevices[0].subscription.is_premium_last : false;
      } else {
        useVip = false;
      }
      setVip(useVip);
    }
  }, [selectedDevices]);

  useEffect(() => {
    if (subscriptionDone && (subscriptionResultSum === 0)) {
      setSubscriptionDone(false);
      history.push({
        pathname: (selectedDevices || []).length > 1
          ? MANY_DEVICES_REDIRECT_URL : ONE_DEVICE_REDIRECT_URL,
        state: { scrollToStepsNav: true },
      });
    }
  }, [subscriptionDone, subscriptionResultSum]);

  return (
    <>
      <OrderSubscriptionDialog
        data={modalDialog}
        setData={setModalDialog}
        onCloseSuccessWindow={handleCloseSuccessSubscription}
        onSubmit={onDialogSubmit}
        isSubscriptionDone={subscriptionDone}
        subscriptionResultSum={subscriptionResultSum}
      />
      <StepsIndicator />
      <PageContentBlock>
        <Title>
          {t('subscriptionsContent.title')}
        </Title>
        {(userInfo?.balance || 0) <= BALANCE_MIN_TO_DISPLAY_HINT && (
          <SubTitle>
            {t('subscriptionsContent.subTitle')}
          </SubTitle>
        )}
        <Card>
          <DeviceSection>
            <DevicesTitle>
              {t('subscriptionsContent.selectedDevices')}
            </DevicesTitle>
            <DevicesListContainer>
              <SubscriptionDevicesTable
                isFullWidth={!SHOW_RIGHT_PANEL}
                devices={selectedDevices}
                subscriptionPeriod={subscriptionPeriod}
              />
            </DevicesListContainer>
          </DeviceSection>
          <RightContent
            subscriptionPeriod={subscriptionPeriod}
            setSubscriptionPeriod={setSubscriptionPeriod}
            autoSubscription={autoSubscription}
            isVip={isVip}
            setVip={setVip}
            devices={selectedDevices || []}
          />
        </Card>
        <SubCardRow>
          {!!(selectedDevices || []).filter((d) => !(d.subscription && d.subscription.auto_renew)
            || !d.subscription).length && (
            <Checkbox
              label={t('subscriptionsContent.autoSubscriptionTitle')}
              type="secondary"
              checked={autoSubscription}
              onChange={setAutoSubscription}
            />
          )}
          <SubscriptionEndText />
        </SubCardRow>
      </PageContentBlock>
      <FooterRow>
        <PromoRow />
        <Actions>
          <BackButton
            onClick={() => history.push({
              pathname: '/personal-page/devices',
              state: { scrollToStepsNav: true },
            })}
          >
            {t('subscriptionsContent.back')}
          </BackButton>
          <StyledButton
            color="primary"
            width="medium"
            isOutline
            isUpperCase
            isBoldText
            onClick={handleSubmit}
            disabled={(selectedDevices || []).length === 0}
          >
            {t('subscriptionsContent.buySubscriptionButton')}
          </StyledButton>
        </Actions>
      </FooterRow>
      <SelectedDevicesContainer>
        <DevicesSelected />
      </SelectedDevicesContainer>
    </>
  );
};

export default Subscription;

const SelectedDevicesContainer = styled.div`
  margin-bottom: 126px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4rem;
`;

const FooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;
`;

const BackButton = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: capitalize;
  color: ${primary};
  padding: 0 1rem;
  margin: 0 1rem;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  :hover {
    background: #eef1fb;
  }
`;

const SubCardRow = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-left: 1.5rem;
`;

const SubscriptionEndText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${primary};
  margin-left: 7rem;
  @media (min-width: 1199px) and  (max-width: 1360px){
    margin-left: 2rem;
  }
`;

const Card = styled.div`
  display: flex;
  background: linear-gradient(85.51deg, #5C70CF 22.88%, #7586D6 44.68%, #5C70CF 75.32%, #5C70CF 75.32%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  height: 297px;
`;

const DeviceSection = styled.div`
  background: ${white};
  box-shadow: 6px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 15px 0 0 15px;
  height: 297px;
  width: ${SHOW_RIGHT_PANEL ? '326px' : '45%'};
  @media (min-width: 1199px) and  (max-width: 1360px){
    width: ${SHOW_RIGHT_PANEL ? '202px' : '50%'};
  }
`;

const DevicesListContainer = styled.div`
  overflow-y: auto;
  padding: 0 5px;
  max-height: calc(100% - 52px);
  margin: 5px 5px 0 0;
`;

const SubTitle = styled.div`
  font-family: Gilroy, sans-serif;
  margin-bottom: 2.5rem;
  font-size: 15px;
  line-height: 20px;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.6;
  color: ${text01};
  position: relative;
  margin-bottom: 33px;

  &:after {
    content: '';
    height: 4px;
    position: absolute;
    width: 30px;
    bottom: -10px;
    left: 0;
    background: ${primary};
  }
`;

const DevicesTitle = styled.div`
  padding: 1rem 1.5rem .5rem;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 15px 0 0 0;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: ${text01}
`;

const StyledButton = styled(Button)`
  &:disabled:hover {
    background: transparent;
    color: ${text01};
  }
`;
