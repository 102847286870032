import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import {
  text01,
  secondary,
  brightGreen,
  lightRed,
  borderSecondary,
} from '@/common/constants/theme';

import { CURRENCY_SYMBOLS } from '@/common/constants/financial';

import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import Loader from '@/common/components/Loader';
import ArrowTable from '../../../../../../../../../../icons/ArrowTable';
import CloseIcon from '../../../../../../../../../../icons/CloseIcon';
import SearchIcon from '../../../../../../../../../../icons/SearchIcon';
import ArrowLeftIcon from '../../../../../../../../../../icons/ArrowLeftIcon';
import AdminTablePagination from '../../../../../../../../../components/Table/components/TablePagination';

import {
  SORT_DIRECTIONS,
  useAdminContext,
} from '../../../../../../../../../../../AdminContext';

import {
  DATA_TYPES,
  useUsersMainContext,
} from '../../../../../../../MainContext';

const SIZES = ['100px', '200px', '950px'];

const SEARCH_FILTER_TIMEOUT = 1000 * 1.5;

const initDates = {
  d_begin: '',
  d_end: '',
};

const initFilter = {
  ...initDates,
  status: '',
  page: 1,
  limit: 10,
  q: '',
};

const UsersMainUserDevicesListHistory = ({ device }) => {
  const {
    getFilterString,
  } = useAdminContext();

  const {
    dataProcessing,
    getUserDeviceHistory,
  } = useUsersMainContext();

  const [isDeviceChanged, setIsDeviceChanged] = useState(true);
  const [isOpened, setIsOpened] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(initFilter);
  const [filterString, setFilterString] = useState('');

  const [searchTimeout, setSearchTimeout] = useState(null);

  const [search, setSearch] = useState('');
  const [sortByDate, setSortByDate] = useState(SORT_DIRECTIONS.notSetted);
  const [sortBySumm, setSortBySumm] = useState(SORT_DIRECTIONS.notSetted);

  const sorts = {
    asc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.asc],
    desc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.desc],
  };

  const directionsKeys = Object.keys(SORT_DIRECTIONS);
  const directions = Object.values(SORT_DIRECTIONS);

  const changeSortByDateDirection = () => {
    const currentSortIndex = directions.findIndex((s) => s === sortByDate) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortBySumm(SORT_DIRECTIONS.notSetted);
    setSortByDate(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'dt_create';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setFilter((prevState) => ({ ...prevState, ordering: `${sign}${field}`.trim() }));
  };

  const changeSortBySummDirection = () => {
    const currentSortIndex = directions.findIndex((s) => s === sortBySumm) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortByDate(SORT_DIRECTIONS.notSetted);
    setSortBySumm(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'summ';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setFilter((prevState) => ({ ...prevState, ordering: `${sign}${field}`.trim() }));
  };

  const getSearchString = (searchObject) => {
    const searchStringArray = [];
    // eslint-disable-next-line no-unused-vars
    Object.entries(searchObject).forEach(([key, value]) => { searchStringArray.push(value); });
    return searchStringArray.join(' ');
  };

  useEffect(() => {
    setFilter((prevState) => ({ ...prevState, page: currentPage }));
  }, [currentPage]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(setTimeout(() => {
      setFilter((prevState) => ({ ...prevState, q: getSearchString(search), page: 1 }));
    }, SEARCH_FILTER_TIMEOUT));
  }, [search]);

  useEffect(() => {
    if (!device || !isDeviceChanged) { return; }
    setCurrentPage(filter.page);
    const fltrStr = getFilterString(filter);
    if (!isDeviceChanged && (fltrStr === filterString)) { return; }
    setFilterString(fltrStr);
    setIsDeviceChanged(false);
    getUserDeviceHistory(device.id, fltrStr)
      .then((res) => setData(res || {}))
      .catch(() => setData({}));
  }, [device, filter]);

  useEffect(() => {
    if (device) {
      setIsDeviceChanged(true);
    }
  }, [device]);

  const formatDate = (dateString) => {
    const D = new Date(dateString);
    return `${D.getFullYear()}.${(D.getMonth() + 1).toString().padStart(2, '0')}.${(D.getDate()).toString().padStart(2, '0')}\n${(D.getHours()).toString().padStart(2, '0')}:${(D.getMinutes()).toString().padStart(2, '0')}:${(D.getSeconds()).toString().padStart(2, '0')}`;
  };

  const renderSumm = (item) => {
    const summF = (item?.amount || 0) !== 0 ? item.amount.toFixed(2) : 0;
    return summF < 0
      ? (
        <SummDecrease bold>{summF}</SummDecrease>
      ) : (
        <Summ bold>{summF}</Summ>
      );
  };

  /**
   * Рендер описания с указанием баланса
   * @param item
   * @returns {*|JSX.Element}
   */
  // eslint-disable-next-line no-unused-vars
  const renderDescriptionAlt = (item) => {
    const balanceTemplate = '{currency} {balance}';
    return item?.description && (
      <>
        <Description>{item.description}</Description>
        {item?.balance && (
          <>
            [Баланс после операции:
            <BalanceAfter isPositive={(item?.balance || 0) > 0}>
              {balanceTemplate
                .replace(/{currency}/gi, CURRENCY_SYMBOLS?.[item.currency] || '')
                .replace(/{balance}/gi, item?.balance || 0)}
            </BalanceAfter>
            ]
          </>
        )}
      </>
    );
  };

  const renderDescription = (item) => {
    const dWallet = (item?.description_wallet || '')
      .replace(/0x(\d{4})/gi, (m) => String.fromCharCode(parseInt(m, 16)));
    return item?.description && (
      <>
        <Description>{item.description}</Description>
        {dWallet.length > 0 && (<DescriptionWallet>{dWallet}</DescriptionWallet>)}
      </>
    );
  };

  return (
    <Container>
      {device && (
        <>
          <DeviceHistory isOpen={isOpened}>
            <DeviceHistoryTitle>
              История устройства
            </DeviceHistoryTitle>
            <DeviceHistoryIcon
              onClick={() => setIsOpened((prevState) => !prevState)}
              rotate={isOpened}
            >
              <ArrowLeftIcon />
            </DeviceHistoryIcon>
          </DeviceHistory>
          {isOpened && (
            <Table
              page={filter?.page || 1}
              limit={filter?.limit || 1}
              dataLength={data?.count || 1}
              cellSizes={SIZES}
              textAlign="left"
            >
              {dataProcessing?.[DATA_TYPES.userDevices] && <Loader />}
              <TableHead>
                <HeadItem flex>
                  Дата
                  <IconsWrapper
                    onClick={() => changeSortByDateDirection()}
                  >
                    {sorts.asc.includes(sortByDate)
                      && (
                        <IconSort isRotate>
                          <ArrowTable />
                        </IconSort>
                      )}
                    {sorts.desc.includes(sortByDate)
                      && (
                        <IconSort leftMargin top>
                          <ArrowTable />
                        </IconSort>
                      )}
                  </IconsWrapper>
                </HeadItem>
                <HeadItem flex>
                  Сумма
                  <IconsWrapper
                    onClick={() => changeSortBySummDirection()}
                  >
                    {sorts.asc.includes(sortBySumm)
                      && (
                        <IconSort isRotate>
                          <ArrowTable />
                        </IconSort>
                      )}
                    {sorts.desc.includes(sortBySumm)
                      && (
                        <IconSort leftMargin top>
                          <ArrowTable />
                        </IconSort>
                      )}
                  </IconsWrapper>
                </HeadItem>
                <HeadItem flex spaceBetween>
                  <HeadItemText>
                    Описание
                  </HeadItemText>
                  <HeadItemControl>
                    <Input
                      value={search.email || ''}
                      onChange={({ target }) => setSearch({ ...search, email: target.value })}
                      placeholder="Поиск"
                    />
                    <Icon top>
                      <SearchIcon />
                    </Icon>
                    {(search.email || '').length > 0
                      && (
                        <CloseIconContainer
                          onClick={() => setSearch({ ...search, email: '' })}
                        >
                          <CloseIcon />
                        </CloseIconContainer>
                      )}
                  </HeadItemControl>
                </HeadItem>
              </TableHead>
              <TableBody>
                {(data?.results || []).map((item) => (
                  <div key={`UDHT-row--${item.id}`}>
                    <RowItem>
                      {item?.created_at && formatDate(item.created_at)}
                    </RowItem>
                    <RowItem>
                      {renderSumm(item)}
                    </RowItem>
                    <RowItem>
                      {renderDescription(item)}
                    </RowItem>
                  </div>
                ))}
              </TableBody>
              <AdminTablePagination
                page={currentPage}
                pageCount={Math.ceil((data?.count || 0) / (filter?.limit || 1))}
                setPage={setCurrentPage}
                recordsCount={data?.count || 0}
              />
            </Table>
          )}
        </>
      )}
    </Container>
  );
};

export default UsersMainUserDevicesListHistory;

const Container = styled.div`
  margin-top: 30px;
`;

const DeviceHistory = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;

  position: relative;

  ${({ isOpen }) => !isOpen && `
    &::after {
      content: '';

      height: 1px;

      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;

      background-color: ${borderSecondary};
    }
  `}
`;

const DeviceHistoryTitle = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${text01};
  line-height: 15px;
`;

const DeviceHistoryIcon = styled.div`
  & svg path {
    fill: ${text01};
  }

  transform: rotate(${({ rotate }) => (rotate ? '-90' : '90')}deg);
  cursor: pointer;

  transition: 0.2s ease;
`;

const IconsWrapper = styled.div`
  display: flex;

  width: 18px;
  margin-left: 10px;
`;

const HeadItem = styled.div`
  ${({ flex, spaceAround, spaceBetween }) => flex && `
    display: flex;
    align-items: center;
    ${spaceAround ? 'justify-content: space-around;' : ''}
    ${spaceBetween ? 'justify-content: space-between;' : ''}
    width: 100%;
  `}
`;

const HeadItemText = styled.div``;

const HeadItemControl = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 200px;
  padding-bottom: 4px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${secondary};
  outline: none;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
`;

const RowItem = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  line-height: 15px;

  ${({ flex }) => flex && `
    display: flex;

    width: 100%;
  `}
`;

const Summ = styled.span`
  ${({ bold }) => bold && 'font-weight: bold;'}
`;
const SummDecrease = styled(Summ)`
  color: ${lightRed};
`;

const Description = styled.div`
  margin-right: .5rem;
`;
const BalanceAfter = styled.span`
  color: ${({ isPositive }) => (isPositive ? brightGreen : lightRed)};
`;
const DescriptionWallet = styled(Description)`
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  position: relative;

  cursor: pointer;
  transform: rotate(${({ isRotate }) => (isRotate ? 180 : 0)}deg);

  transition: 0.2s ease;
`;

const IconSort = styled.div`
  ${({ leftMargin }) => leftMargin && 'margin-left: 2px;'}

  position: relative;
  ${({ top }) => top && 'top: 3px;'}

  cursor: pointer;
  ${({ isRotate }) => isRotate && 'transform: rotate(-180deg);'}
`;
