/* eslint-disable no-shadow */
import React, {
  createContext,
  useState,
  useContext,
} from 'react';

import {
  deleteGroupByIdAction,
  createGroupAction,
  updateGroupByIdAction,
  deleteAllGroupsAction,
  moveGroupAction,
  getImportDataAction,
  handleValidateDataAction,
  handleImportDataAction, getGroupsAction,
} from '../../../../../../../services/actions/admin/groups.actions';
import { useAdminContext } from '../../../../../AdminContext';
import arrayMove, { arrayEdit } from '../../../../../../../common/utils/arrayMove.util';

const GroupsContext = createContext({});

export const GroupsContextProvider = ({ children }) => {
  const {
    getPosition,
    getOffset,
    pageLimit,
  } = useAdminContext();

  const [groups, setGroups] = useState([]);
  const [groupsCount, setGroupsCount] = useState(0);
  const [groupsPage, setGroupsPage] = useState(1);
  const [importData, setImportData] = useState({ channels: [], groups: [] });
  const [validatedData, setValidatedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getGroupsPageCount = () => Math.ceil(groupsCount / pageLimit);

  const getGroups = () => {
    getGroupsAction(getOffset(groupsPage), pageLimit)
      .then((res) => {
        const sortedGroups = res.results.sort((a, b) => a.ordering_id - b.ordering_id);
        setGroups(sortedGroups);
        setGroupsCount(res.count);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const getImportData = () => {
    setIsLoading(true);

    return (
      getImportDataAction()
        .then((res) => setImportData(res))
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    );
  };

  const handleValidateData = () => {
    setIsLoading(true);
    return (
      handleValidateDataAction(importData)
        .then((res) => setValidatedData(res))
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    );
  };

  const handleImportData = () => {
    setIsLoading(true);

    return (
      handleImportDataAction(importData)
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    );
  };

  const handleDragEndGroups = (oldIndex, newIndex) => {
    const updatedGroups = arrayMove(groups, oldIndex, newIndex);
    setGroups(updatedGroups);

    const { id } = updatedGroups[newIndex];
    let prevGroupId = null;
    if (newIndex > 0) {
      prevGroupId = updatedGroups[newIndex - 1].id;
    }

    moveGroupAction(id, prevGroupId)
      .then()
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const updateGroupById = (id, data) => {
    updateGroupByIdAction(id, data)
      .then(() => {
        const groupIndex = groups.findIndex((c) => c.id === id);
        setGroups((prevState) => arrayEdit(prevState, data, groupIndex));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const moveGroupToPosition = (id, newPosition) => {
    if (newPosition !== 1) {
      getGroupsAction(newPosition - 1 - 1, 1).then((res) => {
        const prevGroupId = res.results[0].id;
        moveGroupAction(id, prevGroupId).then(() => {
          getGroups();
        });
      });
    } else {
      moveGroupAction(id, null).then(() => {
        getGroups();
      });
    }
  };

  const handleSaveGroups = (index, item, position) => {
    const { id } = groups[index];
    updateGroupById(id, item);

    const oldPosition = getPosition(groupsPage, index);
    let newPosition = position;
    if (newPosition > oldPosition) {
      newPosition += 1;
    }
    if (newPosition && oldPosition !== newPosition) {
      moveGroupToPosition(id, newPosition);
    }
  };

  const createGroup = (data, createPosition) => {
    createGroupAction(data)
      .then((res) => {
        if (createPosition) {
          const { id } = res;
          moveGroupToPosition(id, createPosition);
        } else {
          getGroups();
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const deleteGroupById = (id) => {
    deleteGroupByIdAction(id)
      .then(() => getGroups())
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const deleteAllGroups = () => {
    deleteAllGroupsAction()
      .then(() => getGroups())
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const value = {
    importData,
    validatedData,
    groups,
    getGroups,
    groupsPage,
    groupsCount,
    getGroupsPageCount,
    setGroupsPage,
    isLoading,
    deleteGroupById,
    createGroup,
    handleSaveGroups,
    deleteAllGroups,
    getImportData,
    handleDragEndGroups,
    handleValidateData,
    handleImportData,
  };

  return (
    <GroupsContext.Provider value={value}>
      {children}
    </GroupsContext.Provider>
  );
};

export const useGroupsContext = () => useContext(GroupsContext);
