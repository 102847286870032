import React, { useState } from 'react';

import styled from 'styled-components';
import { primary, green, text01 } from '@/common/constants/theme';
import IconCopy from '@/assets/icons/copyicon.svg';
import { Tooltip } from 'antd';

import { useTranslation } from 'react-i18next';
import Visibility from '@/assets/icons/visibility.svg';
import NoVisibility from '@/assets/icons/no-visibility.svg';

import { usePersonalPageContext } from '../../../../PersonalPageContext';

const MinistraCredentials = ({
  device,
  setIsMinistraServerModalOpen,
}) => {
  const hiddenPass = '*********';

  const [showPass, setShowPass] = useState(false);

  const { t } = useTranslation(['translations']);

  const { copyText } = usePersonalPageContext();

  return (
    <>
      <Row>
        <Title>
          {t('devicesInfo.portal.loginPlaceholder')}
        </Title>
        <Value>
          <ValueText>
            {device.ministra_login}
          </ValueText>
          <Tooltip
            autoAdjustOverflow
            arrowPointAtCenter
            placement="topRight"
            overlayClassName="green-tooltip"
            trigger={['click']}
            title={t('devicesInfo.portal.loginCopied')}
            color={green}
          >
            <Icon src={IconCopy} onClick={() => copyText(device.ministra_login)} />
          </Tooltip>
        </Value>
      </Row>
      <Row>
        <TitleContainer>
          <Title>
            {t('devicesInfo.portal.passPlaceholder')}
          </Title>
          <VisibilityControl
            onClick={() => setShowPass(!showPass)}
            src={showPass ? NoVisibility : Visibility}
          />
        </TitleContainer>
        <Value>
          <ValueText>
            {showPass ? device.ministra_password : hiddenPass}
          </ValueText>
          <Tooltip
            autoAdjustOverflow
            arrowPointAtCenter
            placement="topRight"
            overlayClassName="green-tooltip"
            trigger={['click']}
            title={t('devicesInfo.portal.passCopied')}
            color={green}
          >
            <Icon src={IconCopy} onClick={() => copyText(device.ministra_password)} />
          </Tooltip>
        </Value>
      </Row>
      <ChangePassRow bottomBorder>
        <ChangePassLink
          onClick={() => {
            setIsMinistraServerModalOpen(true);
          }}
        >
          {t('devicesInfo.portal.changePassText')}
        </ChangePassLink>
      </ChangePassRow>
    </>
  );
};

export default MinistraCredentials;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  ${({ bottomBorder }) => (bottomBorder ? 'border-bottom: solid 1px #EFEEF3;' : '')}

  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};

  &:first-of-type{
    padding-top: .5rem;
  }

  &:last-of-type{
    padding-bottom: .5rem;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  width: calc(4em + 22px);
`;

const Title = styled.span`
  margin: .25rem 0;
`;

const Value = styled.div`

`;

const ValueText = styled(Title)`

`;

const Icon = styled.img`
  margin-left: .75rem;
  margin-top: -4px;
  cursor: pointer;
`;

const VisibilityControl = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 22px;
  height: 20px;
`;

const ChangePassRow = styled(Row)`
  justify-content: end;

  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${primary};
`;

const ChangePassLink = styled(Title)`
  cursor: pointer;
`;
