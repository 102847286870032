import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

const defaultLocale = localStorage.getItem('locale');

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: defaultLocale || 'ru',
    fallbackLng: defaultLocale || 'ru',
    whitelist: ['ru', 'en', 'de'],

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
