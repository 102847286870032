import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  secondary, text01, white,
} from '@/common/constants/theme';

import Button from '@/common/components/Button';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import DNSModal from './components/DNSModal';
import DNSDeleteModal from './components/DNSDeleteModal';
import DNSTable from './components/DNSTable';
import PageContentBlock from '../components/PageContentBlock';

import { usePersonalPageContext } from '../../../PersonalPageContext';
import { useDNSContext } from './DNSContext';

const DNS = () => {
  const {
    addDNS,
    editDNS,
    deleteDNS,
  } = usePersonalPageContext();

  const {
    dnsFilters,
    setDnsFilters,
    isEdit,
    setIsEdit,
    selectedValue,
    setSelectedValue,
  } = useDNSContext();

  const { t } = useTranslation('translations');

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isFinalDeleteModal, setIsFinalDeleteModal] = useState(false);
  const [isErrorCreateModal, setIsErrorCreateModal] = useState(false);
  const [isErrorEditModal, setIsErrorEditModal] = useState(false);

  const deleteHandler = (value) => {
    setSelectedValue(value);
    setIsDeleteModalOpened(true);
  };

  const editHandler = (value) => {
    setIsEdit(true);
    setSelectedValue(value);
    setIsModalOpened(true);
  };

  const addHandler = () => {
    setIsEdit(false);
    setIsModalOpened(true);
  };

  const onSubmit = (inputValue, domainId, serverId) => {
    if (isEdit) {
      editDNS(selectedValue.id, {
        dns: inputValue,
        domain_id: domainId,
        server_id: serverId,
        user_id: selectedValue.userId,
      }).catch(() => {
        setIsErrorEditModal(true);
      });
    } else {
      addDNS({
        dns: inputValue,
        domain_id: domainId,
        server_id: serverId,
      }).then(() => setDnsFilters((prevState) => ({ ...prevState, page: 1 })))
        .catch(() => {
          setIsErrorCreateModal(true);
        });
    }

    setIsModalOpened(false);
    setIsDeleteModalOpened(false);
  };

  return (
    <Container>
      <PageContentBlock>
        <Title>{t('DNS.title')}</Title>
        <Text bottomMargin>{t('DNS.subTitle')}</Text>
        <DNSTable
          deleteHandler={deleteHandler}
          editHandler={editHandler}
          addHandler={addHandler}
        />
      </PageContentBlock>
      <Wrapper>
        <StyledLink to="/personal-page/devices">
          <StyledButton
            isBoldText
            isOutline
            isUpperCase
            width="small"
          >
            {t('profile.logs.toDevices')}
          </StyledButton>
        </StyledLink>
      </Wrapper>
      <DNSModal
        isOpen={isModalOpened}
        isEdit={isEdit}
        editedValue={selectedValue}
        onClose={() => setIsModalOpened(false)}
        onSubmit={onSubmit}
      />
      <DNSDeleteModal
        isOpen={isDeleteModalOpened}
        onClose={() => {
          setIsDeleteModalOpened(false);
          setSelectedValue({});
        }}
        data={selectedValue?.name}
        onDelete={() => {
          deleteDNS(selectedValue.id)
            .then((newDnsCount) => {
              if (dnsFilters.page > Math.ceil(newDnsCount / dnsFilters.limit)) {
                setDnsFilters((prevState) => ({ ...prevState, page: prevState.page - 1 }));
              }
              setIsFinalDeleteModal(true);
            });
          setSelectedValue({});
          setIsDeleteModalOpened(false);
        }}
      />
      <SnackBarWindow
        isOpen={isFinalDeleteModal}
        onClose={() => setIsFinalDeleteModal(false)}
        type="success"
        content={(
          <>
            <SnackbarText>{t('DNS.modal.delete.success.title')}</SnackbarText>
            <SnackbarText>{t('DNS.modal.delete.success.subTitle')}</SnackbarText>
          </>
        )}
      />
      <SnackBarWindow
        isOpen={isErrorCreateModal}
        onClose={() => setIsErrorCreateModal(false)}
        type="error"
        content={(
          <>
            <SnackbarText>{t('DNS.modal.create.error')}</SnackbarText>
          </>
        )}
      />
      <SnackBarWindow
        isOpen={isErrorEditModal}
        onClose={() => setIsErrorEditModal(false)}
        type="error"
        content={(
          <>
            <SnackbarText>{t('DNS.modal.edit.error')}</SnackbarText>
          </>
        )}
      />
    </Container>
  );
};

export default DNS;

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
`;

const StyledLink = styled(Link)`
  margin: 35px 65px 135px auto;
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 45px;')};

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const SnackbarText = styled(Text)`
  color: ${white};
`;

const Title = styled(Text)`
  margin-bottom: 25px;

  position: relative;

  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  &::before {
    content: '';

    width: 30px;
    height: 4px;

    position: absolute;
    bottom: -8px;
    left: 0;

    background-color: ${secondary};
  }
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 0 17px;
`;
