import React from 'react';
import { Route, Switch } from 'react-router-dom';

import StatisticAggregatorsContainer from './Aggregators/AggregatorsContainer';

const StatisticContainer = () => (
  <Switch>
    <Route path="/admin/statistic/" component={StatisticAggregatorsContainer} />
  </Switch>
);

export default StatisticContainer;
