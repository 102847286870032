import React from 'react';

export default () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 393.338 393.338">
    <path
      d="M72.813,250.348c0-4.418,3.582-8,8-8h1.983c4.418,0,8,3.582,8,8s-3.582,8-8,8h-1.983
    C76.396,258.348,72.813,254.766,72.813,250.348z M80.813,230.268h1.983c4.418,0,8-3.582,8-8s-3.582-8-8-8h-1.983
    c-4.418,0-8,3.582-8,8S76.396,230.268,80.813,230.268z M57.783,230.268h1.983c4.418,0,8-3.582,8-8s-3.582-8-8-8h-1.983
    c-4.418,0-8,3.582-8,8S53.365,230.268,57.783,230.268z M80.813,201.675h1.983c4.418,0,8-3.582,8-8s-3.582-8-8-8h-1.983
    c-4.418,0-8,3.582-8,8S76.396,201.675,80.813,201.675z M73.99,295.106h-7.215v-7.213c0-4.418-3.582-8-8-8s-8,3.582-8,8v7.213h-7.213
    c-4.418,0-8,3.582-8,8s3.582,8,8,8h7.213v7.214c0,4.418,3.582,8,8,8s8-3.582,8-8v-7.214h7.215c4.418,0,8-3.582,8-8
    S78.408,295.106,73.99,295.106z M57.783,258.348h1.983c4.418,0,8-3.582,8-8s-3.582-8-8-8h-1.983c-4.418,0-8,3.582-8,8
    S53.365,258.348,57.783,258.348z M393.338,26.767V256.22c0,4.418-3.582,8-8,8H219.101v27.947h51.435c4.418,0,8,3.582,8,8
    s-3.582,8-8,8h-59.259c-0.058,0.001-0.117,0.002-0.176,0.002s-0.118-0.001-0.176-0.002h-59.256c-4.418,0-8-3.582-8-8s3.582-8,8-8
    h51.432V264.22h-68.161c-4.418,0-8-3.582-8-8s3.582-8,8-8h242.398v-19.907H143.432c-4.418,0-8-3.582-8-8s3.582-8,8-8h233.906V70.672
    H44.867v16.634c0,4.418-3.582,8-8,8s-8-3.582-8-8V26.767c0-4.418,3.582-8,8-8h348.471C389.756,18.767,393.338,22.349,393.338,26.767
    z M377.338,34.767H44.867v19.905h332.471V34.767z M34.754,258.348h1.982c4.418,0,8-3.582,8-8s-3.582-8-8-8h-1.982
    c-4.418,0-8,3.582-8,8S30.336,258.348,34.754,258.348z M49.783,193.675c0,4.418,3.582,8,8,8h1.983c4.418,0,8-3.582,8-8s-3.582-8-8-8
    h-1.983C53.365,185.675,49.783,189.257,49.783,193.675z M34.754,230.268h1.982c4.418,0,8-3.582,8-8s-3.582-8-8-8h-1.982
    c-4.418,0-8,3.582-8,8S30.336,230.268,34.754,230.268z M117.551,112.692v253.879c0,4.418-3.582,8-8,8H8c-4.418,0-8-3.582-8-8
    V112.692c0-4.418,3.582-8,8-8h101.551C113.969,104.692,117.551,108.274,117.551,112.692z M101.551,120.692H16v237.879h85.551
    V120.692z M34.754,201.675h1.982c4.418,0,8-3.582,8-8s-3.582-8-8-8h-1.982c-4.418,0-8,3.582-8,8S30.336,201.675,34.754,201.675z
    M34.754,149.85h9.917c4.418,0,8-3.582,8-8s-3.582-8-8-8h-9.917c-4.418,0-8,3.582-8,8S30.336,149.85,34.754,149.85z"
    />
  </svg>
);
