import {
  SET_USERS, SET_USER_GROUPS, SET_PERMISSIONS,
} from '../types/users';

const initialState = {
  users: null,
  userGroups: null,
  permissions: null,
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_USER_GROUPS:
      return {
        ...state,
        userGroups: action.payload,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};
