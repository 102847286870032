import React from 'react';
import { useTranslation } from 'react-i18next';
import HtmlParser from 'react-html-parser';

import styled from 'styled-components';
import { secondary, white } from '@/common/constants/theme';

import Tooltip from '@/common/components/Tooltip';
import Button from '@/common/components/Button';
import ServerLoading from './Header/ServerLoading';

import { useServerContext } from '../ServerContext';

const ServerSelectControls = ({ isStalker, justifyContent }) => {
  const { t } = useTranslation('translations');
  const {
    testProgress,
    isAutoServer,
    handleSetAutoServer,
    handleStartStopAllServersTest,
    isAllServerTest,
    testingServer,
  } = useServerContext();

  return (
    <ServerSelectContainer>
      <ButtonsWrapper justifyContent={justifyContent}>
        {isAutoServer
          ? (
            <StyledTooltip
              trigger="hover"
              title={HtmlParser(t('steps.step3.content.header.buttonManualServerToolTipText')
                .replaceAll(' ', '&nbsp;'))}
              isDisabled={testingServer && !isAllServerTest}
              width="auto"
            >
              <StyledButton
                setWidth="162px"
                color={isAutoServer ? 'primary' : 'secondary'}
                isOutline={isAutoServer}
                isBoldText
                isUpperCase
                isTesting={isAllServerTest}
                isStalker={isStalker}
                isDisabled={testingServer && !isAllServerTest}
                onClick={() => { handleSetAutoServer(false); }}
              >
                {t('steps.step3.content.header.button')}
              </StyledButton>
            </StyledTooltip>
          ) : (
            <StyledTooltip
              trigger={isAutoServer ? 'none' : 'hover'}
              title={t(`steps.step3.content.header.${isAllServerTest ? 'buttonBreakTestTooltipText' : 'buttonStartTestTooltipText'}`)}
              isDisabled={isAutoServer || (testingServer && !isAllServerTest)}
            >
              <StyledButton
                setWidth="162px"
                color="secondary"
                isBoldText
                isUpperCase
                isTesting={isAllServerTest}
                isStalker={isStalker}
                isDisabled={isAutoServer || (testingServer && !isAllServerTest)}
                onClick={() => handleStartStopAllServersTest()}
              >
                {t(`steps.step3.content.header.${isAllServerTest ? 'buttonBreakTest' : 'buttonStartTest'}`)}
              </StyledButton>
            </StyledTooltip>
          )}
        <StyledTooltip
          trigger={isAllServerTest ? 'none' : 'hover'}
          title={t('steps.step3.content.header.buttonAutoServerToolTipText')}
          isDisabled={isAllServerTest || testingServer}
        >
          <StyledButton
            setWidth="190px"
            color={isAutoServer ? 'secondary' : 'primary'}
            isBoldText
            isUpperCase
            isOutline={!isAutoServer}
            isDisabled={isAllServerTest || testingServer}
            isStalker={isStalker}
            onClick={() => { handleSetAutoServer(true); }}
          >
            {t('steps.step3.content.header.buttonAutoServer')}
          </StyledButton>
        </StyledTooltip>
      </ButtonsWrapper>
      {isAllServerTest && (
        <ServerLoading
          justifyContent={justifyContent}
          isStalker={isStalker}
          width={testProgress}
        />
      )}
    </ServerSelectContainer>
  );
};

export default ServerSelectControls;

const ServerSelectContainer = styled.div`
`;

const StyledButton = styled(Button)`
  ${({ setWidth }) => (setWidth && `width: ${setWidth};`)}
  & svg {
    margin-right: .25rem;

    path {
      fill: ${secondary};
    }
  }

  &:hover svg path {
    fill: ${white};
  }
  @media (max-width: 1360px) {
    margin-bottom: ${({ isStalker }) => (isStalker ? '20' : '8')}px;
  }

  @media (max-width: 1198px) {
    margin-bottom: 0;
  }
`;

const StyledTooltip = styled(Tooltip)`
  ${({ mr }) => (mr && `margin-right: ${mr};`)}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  ${({ justifyContent }) => (justifyContent && `justify-content: ${justifyContent};`)}

  & > div:not(:last-of-type) {
    margin-right: .5rem;
  }
`;
