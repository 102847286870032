import RequestService from '@/services/request.service';
import newsUrls from '@/services/routes/news.api';

export const getNewsAction = (
  page = 1,
  limit = 50,
) => {
  const params = {
    is_news_pages: true,
    order: 'created_date', // -created_date or created_date
    page,
    limit,
  };
  return RequestService(newsUrls.newsList, { params });
};

export const getNewsDetailsAction = (id) => RequestService(newsUrls.newsItem(id));

export const getNotificationsAction = () => RequestService(newsUrls.notifications);

export const readNotificationsAction = (id) => RequestService(newsUrls.notificationsReading(id),
  { method: 'POST' });

export const getPersonalNotificationsAction = () => RequestService(newsUrls.personalNotifications);

export const readPersonalNotificationsAction = (id) => RequestService(
  newsUrls.personalNotificationsReading(id), { method: 'POST' },
);

export const readAllNotificationsAction = () => RequestService(newsUrls.allNotificationsReading, { method: 'POST' });
export const readAllPersonalNotificationsAction = () => RequestService(newsUrls.allPersonalNotificationsReading, { method: 'POST' });
