import React from 'react';

import { NotificationsContextProvider } from './NotificationsContext';
import Notifications from './Notifications';

const NotificationsContainer = () => (
  <NotificationsContextProvider>
    <Notifications />
  </NotificationsContextProvider>
);

export default NotificationsContainer;
