import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import AlertIcon from '@/assets/icons/alertIcon.svg';
import ModalNew from '@/common/components/ModalNew';
import {
  primary,
  red,
  text01,
} from '@/common/constants/theme';
import Input from '@/common/components/Input';
import Button from '@/common/components/Button';
import Dropdown from '@/common/components/Dropdown';

import { useReferralsContext } from '../ReferralsContext';

const MIN_WITHDRAW_AMOUNT = 15;

const ReferralsModalRequest = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation('translations');

  const {
    withdrawalSystems,
    withdrawalWallets,
  } = useReferralsContext();

  const [amountValue, setAmountValue] = useState('');
  const [isAmountError, setIsAmountError] = useState(false);
  const [withdrawalSystemsOptions, setWithdrawalSystemsOptions] = useState([]);
  const [selectedWithdrawalSystemOption, setSelectedWithdrawalSystemOption] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [walletsOptions, setWalletsOptions] = useState([]);
  const [selectedWalletOption, setSelectedWalletOption] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [userWallets, setUserWallets] = useState([]);
  const [isDataValid, setIsDataValid] = useState(false);

  useEffect(() => {
    setAmountValue('');
    if (isOpen && (withdrawalWallets.length > 0) && (withdrawalSystems.length > 0)) {
      const avlblWSystems = {};
      withdrawalWallets.forEach((wallet) => {
        if (!avlblWSystems?.[wallet.withdrawal_system.id]) {
          avlblWSystems[wallet.withdrawal_system.id] = withdrawalSystems
            .find((ws) => ws.id === wallet.withdrawal_system.id);
        }
      });

      const availableWSystems = Object.values(avlblWSystems);
      if (availableWSystems?.length > 0) {
        const wsOptions = availableWSystems.map((item) => (
          {
            key: item.id,
            title: item.name,
            value: item.id,
          }));
        setWithdrawalSystemsOptions(wsOptions);
        setSelectedWithdrawalSystemOption(Object.values(wsOptions)[0]);
        setSelectedSystem(withdrawalSystems.find(
          (ws) => (ws.id === Object.values(wsOptions)[0].value),
        ));
      }
    }
  }, [isOpen, withdrawalSystems, withdrawalWallets]);

  useEffect(() => {
    const availableWallets = (selectedSystem && withdrawalWallets.length > 0)
      ? withdrawalWallets.filter((w) => w.withdrawal_system.id === selectedSystem.id)
      : [];
    setUserWallets(availableWallets);
  }, [selectedSystem]);

  useEffect(() => {
    const wOptions = userWallets.map((item) => (
      {
        key: `${item.id}-${item.withdrawal_system_user_identifier}`,
        title: item.withdrawal_system_user_identifier,
        value: item.id,
      }));
    setWalletsOptions(wOptions);
    setSelectedWalletOption((wOptions.length === 1) ? Object.values(wOptions)[0] : null);
    setSelectedWallet((wOptions.length === 1)
      ? withdrawalWallets.find((w) => w.id === Object.values(wOptions)[0].value)
      : null);
  }, [userWallets]);

  const checkError = () => {
    let retVal = true;
    const amountError = !(amountValue !== '' && parseInt(amountValue, 10) >= MIN_WITHDRAW_AMOUNT);
    setIsAmountError(amountError);
    retVal = retVal && !amountError;

    const walletError = selectedWallet === null;
    retVal = retVal && !walletError;

    setIsDataValid(retVal);
    return retVal;
  };

  useEffect(() => {
    checkError();
  }, [amountValue, selectedWallet]);

  const submitHandler = () => {
    onSubmit({
      withdrawalSystemId: selectedSystem.id,
      walletId: selectedWallet.id,
      walletIdentifier: selectedWallet.withdrawal_system_user_identifier,
      amount: amountValue,
    });
  };

  const closeHandler = () => {
    onClose();
  };

  return (
    <ModalNew
      isOpen={isOpen}
      onCloseModal={closeHandler}
      title={t('referrals.modal.title')}
      icon={AlertIcon}
      isHidden={false}
      noOverFlow
    >
      <Wrapper bottomMargin>
        <Text bottomMargin>
          {t('referrals.modal.enterSum')}
        </Text>
        <FlexWrapper>
          <Wrapper>
            <StyledInput
              value={amountValue}
              onChange={(event) => setAmountValue(event.target.value)}
              height="39px"
              width={120}
              placeholder="0"
            />
            {isAmountError && (
              <ErrorText maxWidth={120}>
                {t('referrals.modal.errors.min', { min_summ: MIN_WITHDRAW_AMOUNT })}
              </ErrorText>
            )}
          </Wrapper>
          <PrimaryText>
            {t('referrals.modal.minSum', { min_summ: MIN_WITHDRAW_AMOUNT })}
          </PrimaryText>
        </FlexWrapper>
      </Wrapper>
      <Wrapper bottomMargin>
        <Text bottomMargin>{t('referrals.modal.selectSystem')}</Text>
        <StyledDropdown
          options={withdrawalSystemsOptions}
          value={selectedWithdrawalSystemOption}
          /* eslint-disable-next-line max-len */
          onSelect={(item) => setSelectedSystem(withdrawalSystems.find((ws) => ws.id === item.key))}
          isBoldText
          isUpperCase
          height="39px"
          optionHeight="39px"
          color={primary}
        />
      </Wrapper>
      <Wrapper>
        <Text bottomMargin>
          {t('referrals.modal.selectWallet')}
        </Text>
        {/* <Descr bottomMargin>{t('referrals.modal.exampleWMZPurse')}</Descr> */}
        {(userWallets?.length || 0) > 0
          && (
          <StyledDropdown
            options={walletsOptions}
            value={selectedWalletOption}
            /* eslint-disable-next-line max-len */
            onSelect={(item) => setSelectedWallet(userWallets.find((uw) => uw.id === item.value))}
            isBoldText
            isUpperCase
            height="39px"
            optionHeight="39px"
            color={primary}
          />
          )}
      </Wrapper>
      <FlexWrapper>
        <StyledButton
          isBoldText
          isUpperCase
          isDisabled={!isDataValid}
          onClick={submitHandler}
        >
          {t('referrals.modal.createReq')}
        </StyledButton>
      </FlexWrapper>
    </ModalNew>
  );
};

export default ReferralsModalRequest;

const Wrapper = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 29px;')};
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 7px;')};

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  font-weight: normal;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const PrimaryText = styled(Text)`
  max-width: 180px;
  margin-left: 30px;
  margin-top: 5px;

  font-size: 12px;
  font-weight: 500;
  color: ${primary};
  line-height: 14px;
`;

const ErrorText = styled(Text)`
  ${({ maxWidth }) => maxWidth && (`max-width: ${maxWidth}px;`)}

  font-family: Gilroy;
  font-size: 13px;
  color: ${red};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const StyledInput = styled(Input)`
  width: ${({ width }) => `${width}px`};
`;

const StyledButton = styled(Button)`
  width: 170px;
  margin-left: auto;
  margin-top: 36px;
  margin-bottom: 18px;

  ${({ isDisabled }) => isDisabled && (`
    background-color: ${text01};
    opacity: 0.2;

    &:hover {
      background-color: ${text01};
      opacity: 0.2;
    }

    &:active {
      background-color: ${text01};
      opacity: 0.2;
    }
  `)}
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;
