import RequestService from '../../request.service';
import urls from '../../routes/admin/news.api';

export const getNewsAction = (
  // search = null,
  page = null,
  limit = null,
  orderFilter = '-created_date',
  searchFilter = null,
) => {
  const params = {
    // is_news_pages,
    // is_pushes,
    // search,
    page,
    limit,
    order: orderFilter,
    search: searchFilter,
  };
  return RequestService(urls.newsList, { params });
};

export const deleteAction = (id) => RequestService(urls.newsItem(id), {
  method: 'DELETE',
});

export const createNewsAction = (data) => RequestService(urls.newsList, {
  method: 'POST',
  body: data,
});

export const getNewsByIdAction = (id) => RequestService(urls.newsItem(id));

export const updateNewsByIdAction = (id, data) => RequestService(urls.newsItem(id), {
  method: 'PUT',
  body: data,
});

export const uploadImageAction = (file, newsId, tmpNewsId) => {
  const formData = new FormData();
  formData.append('image', file);
  if (!tmpNewsId) {
    formData.append('news', newsId);
  } else {
    formData.append('tmp_news_id', tmpNewsId);
  }
  return RequestService(urls.newsImage, {
    method: 'POST',
    body: formData,
  }, false);
};
