import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Tooltip } from 'antd';

import styled from 'styled-components';
import {
  secondary,
  activePrimary,
  white,
  text01,
  secondaryHover,
  secondaryActive,
} from '../../../constants/theme';

import { resetData } from '@/store/user/selectors';

import {
  SIGN_IN_URL,
  useAppContext,
} from '@/AppContext';

import LanguageSwitcher from './LanguageSwitcher';
import Logout from '../../../../assets/icons/logout.svg';
import auth from '../../../../services/auth.service';

function UserActions({ isUserExist, history }) {
  const { t } = useTranslation('translations');

  const dispatch = useDispatch();

  const { clearUser, userInfo, goToPageWithScroll } = useAppContext();

  const path = history.location.pathname.slice(1);
  const isAdminPage = !!history.location.pathname.match(/admin/gi);

  const logOut = () => {
    auth.clearToken();
    clearUser();
    dispatch(resetData());
    goToPageWithScroll(SIGN_IN_URL);
  };

  return (
    <Container>
      {!isAdminPage && <LanguageSwitcher isUserExist={isUserExist} />}
      {isUserExist ? (
        <ProfileModal onClick={() => goToPageWithScroll('/personal-page/profile')}>
          <UserAvatar
            whiteColor={!history.location.pathname.slice(1) || isAdminPage}
          >
            {(userInfo?.email || '')[0]}
          </UserAvatar>
          <Tooltip
            autoAdjustOverflow
            arrowPointAtCenter
            placement="bottom"
            title={t('header.lk')}
            color={white}
            overlayClassName="white-tooltip"
            overlayStyle={{ color: text01, position: 'fixed' }}
          >
            <UserName
              whiteColor={!history.location.pathname.slice(1) || isAdminPage}
            >
              {(userInfo?.email || '').split('@')[0]}
            </UserName>
          </Tooltip>
          <Tooltip
            autoAdjustOverflow
            arrowPointAtCenter
            placement="bottom"
            title={t('header.logout')}
            color={white}
            overlayClassName="white-tooltip"
            overlayStyle={{ color: text01, position: 'fixed' }}
          >
            <Icon src={Logout} alt="" onClick={logOut} />
          </Tooltip>
        </ProfileModal>
      ) : (
        <LoginButton
          path={path}
          onClick={() => goToPageWithScroll(SIGN_IN_URL)}
        >
          {t('header.signin')}
        </LoginButton>
      )}
    </Container>
  );
}

export default withRouter(UserActions);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileModal = styled.div`
  display: flex;
  align-items: center;
`;

const UserAvatar = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${secondary};
  border-radius: 50%;
  margin: 0 1rem;
  text-transform: capitalize;

  ${({ whiteColor }) => whiteColor && (`color: ${white};`)}
`;

const UserName = styled.div`
  min-width: 5rem;
  margin-right: 1rem;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.16px;
  cursor: pointer;

  ${({ whiteColor }) => whiteColor && (`color: ${white};`)}
`;

const Icon = styled.img`
  margin: .5rem;
  cursor: pointer;
`;

const LoginButton = styled.button`
  border: 2px solid ${secondary};
  border-radius: 2px;
  padding: .76rem 1rem;
  margin-left: 1.5rem;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.20s ease-in;
  outline: none;
  ${({ path }) => (path ? `
  background: ${white};
  color: ${activePrimary};
  :hover {
    background: #FFAB30;
    border-color: #FFAB30;
    color: ${white};
  }
  ` : `
  background: ${secondary};
  color: ${white};
  :hover {
    background: ${secondaryHover};
    border-color: ${secondaryHover};
    color: ${white};
  }
  :active {
    background: ${secondaryActive};
    border-color: ${secondaryActive};
  }
  `)}
`;
