import React from 'react';
import VirtualList from 'react-tiny-virtual-list';

import styled from 'styled-components';
import { text01, borderSecondary, borderTertiary } from '@/common/constants/theme';

const CompareResultsInfo = (
  displayInfo,
) => {
  const { displayInfo: data } = displayInfo;

  const dataCount = data.info ? data.info.length : 0;

  const itemHeight = 18;

  return dataCount > 0 && (
    <DataListWrapper>
      <DataCaption>{data.caption}</DataCaption>
      <DataList>
        <VirtualList
          width="100%"
          height={260}
          itemSize={itemHeight}
          itemCount={dataCount}
          renderItem={({ index, style }) => (
            <DataListItem
              key={index}
              style={style}
              title={data.info[index]}
              itemHeight={itemHeight}
            >
              {data.info[index]}
            </DataListItem>
          )}
        />
      </DataList>
    </DataListWrapper>
  );
};

export default CompareResultsInfo;

const DataListWrapper = styled.div`
  width: 100%;
  padding: .5rem 1rem;
  border: ${borderSecondary} 1px solid;
  color: ${text01};
`;

const DataCaption = styled.div`
  width: 33%;
  font-size: 18px;
  font-family: Gilroy, sans-serif;
  margin-bottom: .5rem;
  border-bottom: solid 1px ${borderTertiary}
`;

const DataList = styled.div`
`;

const DataListItem = styled.div`
  padding: 0 .25em;
  overflow-y: hidden;
  font-size: 14px;
  ${({ itemHeight }) => itemHeight && (`line-height: ${itemHeight}px;`)}
`;
