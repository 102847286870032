import React from 'react';
import styled from 'styled-components';
import { background01, borderSecondary, text01 } from '../../../../../../../common/constants/theme';

const AdminTableHead = ({
  children,
  cellSizes,
  editable = true,
}) => (
  <Container>
    <Main>
      <Item width="80px">
        Позиция
      </Item>
      {React.Children.map(children, (element, index) => (
        <Item width={cellSizes && cellSizes[index]}>
          {element}
        </Item>
      ))}
      {editable && (
        <Item width="50px">
          <More />
        </Item>
      )}
    </Main>
  </Container>
);

export default AdminTableHead;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 53px;
  padding-left: 23px;
  padding-right: 20px;

  position: relative;
  z-index: 2;

  background: ${background01};
  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
`;

const Main = styled.div`
  display: flex;

  width: 100%;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${({ width }) => width && (`width: ${width};`)};

  position: relative;

  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  color: ${text01};
  text-align: ${({ textAlign }) => textAlign};
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-left: 30px;

    &::after {
      content: '';

      width: 1px;
      height: 30px;

      position: absolute;
      left: -15px;
      bottom: -5px;

      background-color: ${borderSecondary};
    }
  }

  @media (max-width: 1360px) {
    ${({ width, adaptive }) => width && (`width: calc(${width} - ${adaptive || 10}px);`)};

    font-size: 11px;
  }

  @media (max-width: 1198px) {
    ${({ width }) => width && (`width: ${width};`)};

    font-size: 14px;
  }
`;

const More = styled.div`
  width: 3px;
  height: 3px;
  margin-left: 10px;

  position: relative;

  border-radius: 10px;
  background-color: ${text01};

  &::after,
  &::before {
    content: '';

    width: 3px;
    height: 3px;

    position: absolute;
    top: 0;

    background-color: ${text01};
    border-radius: 10px;
  }

  &::after {
    left: -6px;
  }

  &::before {
    left: 6px;
  }
`;
