/* eslint-disable no-nested-ternary, react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  white,
  greyFivefold,
  text01,
  primary,
  borderTertiary,
  green,
  secondary,
} from '@/common/constants/theme';
import Checkbox from '@/common/components/Checkbox';
import StarPaintedIcon from '../../../../../../../icons/StarPaintedIcon';
import StarIcon from '../../../../../../../icons/StarIcon';
import ChannelNoLogoIcon from '../../../../../../../icons/ChannelNoLogoIcon';
import { useChannelsContext } from '../../../../../ChannelsContext';

const ChannelsMainCard = SortableElement(({
  title,
  src,
  isNew,
  isArchive,
  isChecked,
  isStarred,
  isSelected,
  i,
  changeFiltersHandler,
  isEditable,
}) => {
  const {
    selectedPlaylist,
    channelsFilters,
    handleStarChannel,
    handleSelectChannel,
    handleHideChannel,
    setChannelsMessage,
    handleCreatePlaylistVirtual,
    setNeedShowChannel,
  } = useChannelsContext();
  const {
    layout,
    isGroups,
    isStalker,
    mainDropdown,
    activeGroup,
  } = channelsFilters;
  const { t } = useTranslation('translations');

  let dragTimeout = useRef(null);

  const checkRef = useRef(null);
  const iconRef = useRef(null);
  const checkIsSelect = (event) => checkRef.current && iconRef.current
    && !checkRef.current.contains(event.target)
    && !iconRef.current.contains(event.target);

  const setBlockedMsg = () => {
    setChannelsMessage(t('steps.step4.content.body.noChannelsDragAvailable'));
  };

  const handleHideClick = (v) => {
    if (!isEditable) {
      return;
    }
    if (selectedPlaylist?.is_virtual) {
      handleCreatePlaylistVirtual(selectedPlaylist.title)
        .then(() => setNeedShowChannel(i));
    } else {
      handleHideChannel(i, !v);
    }
    if (!v && mainDropdown === 'all') {
      changeFiltersHandler('mainDropdown', 'selected');
    } else if (v && mainDropdown === 'hideAll') {
      changeFiltersHandler('mainDropdown', 'selected');
    }
  };

  useEffect(() => () => {
    if (dragTimeout) {
      clearTimeout(dragTimeout);
    }
  }, []);

  return (
    <Container>
      <Wrapper
        card
        row={layout === 'row'}
        isGroups={isGroups}
        checked={isChecked}
        isSelected={isSelected}
        isStarredGroup={isStalker && activeGroup === 0}
        className="channels-main-card"
        onMouseDown={(event) => {
          if (isEditable) {
            if (checkIsSelect(event)) {
              handleSelectChannel(i, !isSelected);
            }
          } else {
            dragTimeout = setTimeout(() => {
              setBlockedMsg();
            }, 1000 * 0.25);
          }
        }}
        onMouseUp={() => {
          if (dragTimeout) {
            clearTimeout(dragTimeout);
          }
        }}
        isEditable={isEditable}
      >
        <Card
          row={layout === 'row'}
          isGroups={isGroups}
        >
          <IconWrapper ref={iconRef}>
            {isStalker ? (
              <StarIconWrapper
                row={layout === 'row'}
                onClick={() => handleStarChannel(i, !isStarred)}
              >
                {isStarred ? (
                  <StarPaintedIcon />
                ) : (
                  <StarIcon />
                )}
              </StarIconWrapper>
            ) : (
              <SeeMoreWrapper>
                <SeeMore />
              </SeeMoreWrapper>
            )}
          </IconWrapper>
          <CheckboxWrapper ref={checkRef}>
            <StyledCheckbox
              isEditable={isEditable}
              onClick={() => {
                if (!isEditable) {
                  setBlockedMsg();
                }
              }}
              onChange={handleHideClick}
              checked={isChecked}
              type="secondary"
            />
          </CheckboxWrapper>
          {isNew && (
          <New
            absolute
            left={layout !== 'row' && 5}
            bottom={layout !== 'row' && 5}
            top={layout === 'row' && 7}
            right={layout === 'row' && 36}
          >
            {t('steps.step4.content.body.new')}
          </New>
          )}
          {isArchive && (
          <PrimaryText
            absolute
            right={layout === 'row' ? 29 : 5}
            bottom={layout === 'row' ? 3 : 5}
          >
            {t('steps.step4.content.body.archive')}
          </PrimaryText>
          )}
          {src ? (
            <Img
              src={src}
              alt=""
              row={layout === 'row'}
              draggable={false}
              onDragStart={(event) => event.preventDefault()}
            />
          ) : (<NoImgStub><ChannelNoLogoIcon /></NoImgStub>)}
        </Card>
        <Text
          row={layout === 'row'}
          absolute={layout === 'row'}
          top={layout === 'row' && 8}
          left={layout === 'row' && 17}
          topMargin={layout !== 'row'}
        >
          {title}
        </Text>
      </Wrapper>
    </Container>
  );
});
export default ChannelsMainCard;

const Container = styled.div`
  margin-right: 28px;
  padding-bottom: 21px;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 88px;
  height: 67px;

  position: relative;

  border: 1px solid transparent;
  ${({ isSelected }) => isSelected && (`border-color: ${secondary};`)}

  transition: width 0.2s ease;

  ${({ row, isGroups }) => (!row ? `
    background: ${white};
    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.18);

    @media (max-width: 1360px) {
      width: ${isGroups ? 88 : 102}px;
    }

    @media (max-width: 1198px) {
      width: 88px;
    }
  ` : `
    width: ${isGroups ? 206 : 246}px;
    height: 43px;

    @media (max-width: 1360px) {
      width: ${isGroups ? 330 : 300}px;
    }

    @media (max-width: 1198px) {
      width: ${isGroups ? 206 : 246}px;
    }
  `)}
`;

const Wrapper = styled.div`
  position: relative;

  user-select: none;
  ${({ checked }) => !checked && ('opacity: 0.5;')}
  ${({ isEditable }) => (isEditable ? 'cursor: grabbing;' : 'cursor: pointer;')}

  transition: 0.2s ease;

  ${({ card, row, isGroups }) => card && (`
    ${!row ? 'width: 88px;' : ''}

    ${!row ? (`
      @media (max-width: 1360px) {
        width: ${isGroups ? 88 : 102}px;
      }

      @media (max-width: 1198px) {
        width: 88px;
      }
    `) : ''}
  `)}

  ${({ row, isGroups }) => row && (`
    width: ${isGroups ? 206 : 246}px;
    height: 43px;

    background: ${white};
    border: 1px solid ${borderTertiary};
    box-sizing: border-box;
    border-radius: 2px;

    @media (max-width: 1360px) {
      width: ${isGroups ? 330 : 300}px;
    }

    @media (max-width: 1198px) {
      width: ${isGroups ? 206 : 246}px;
    }
  `)}

  ${({ isSelected, row }) => (row ? `
    ${isSelected ? `border-color: ${secondary}` : ''};
  ` : `
    & ${Card} {
      ${isSelected ? `border-color: ${secondary}` : ''};
    }
  `)}
`;

const SeeMoreWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 5px;
`;

const SeeMore = styled.div`
  width: 2px;
  height: 2px;
  margin-right: 12px;

  position: relative;

  background-color: ${({ isActive }) => (isActive ? white : greyFivefold)};
  border-radius: 100px;

  &::after,
  &::before {
    content: '';

    width: 2px;
    height: 2px;

    position: absolute;
    left: 0;

    background-color: ${({ isActive }) => (isActive ? white : greyFivefold)};
    border-radius: 100px;
  }

  &::after {
    top: -5px;
  }

  &::before {
    bottom: -5px;
  }
`;

const Text = styled.div`
  max-width: 88px;
  max-height: 67px;
  ${({ topMargin }) => topMargin && ('margin-top: 4px;')}

  ${({
    absolute,
    top,
    right,
    bottom,
    left,
  }) => absolute && (`
    position: absolute;
    ${top ? `top: ${top}px;` : ''}
    ${right ? `right: ${right}px;` : ''}
    ${bottom ? `bottom: ${bottom}px;` : ''}
    ${left ? `left: ${left}px;` : ''}
  `)}

  font-family: Gilroy, sans-serif;
  font-size: 12px;
  color: ${text01};
  white-space: pre-wrap;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.16px;

  @media (max-width: 1360px) {
    max-width: 115px;
  }

  @media (max-width: 1198px) {
    max-width: 88px;
  }

  ${({ row }) => row && (`
    max-width: 115px;

    white-space: normal;
    text-align: left;

    @media (max-width: 1360px) {
      max-width: 180px;
    }

    @media (max-width: 1198px) {
      max-width: 115px;
    }
  `)}
`;

const PrimaryText = styled(Text)`
  font-weight: 500;
  font-size: 10px;
  color: ${primary};
  line-height: 12px;
  text-align: right;
`;

const New = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 22px;
  height: 12px;

  background: ${green};
  border-radius: 2px;

  font-weight: bold;
  font-size: 8px;
  color: ${white};
  line-height: 10px;
  text-transform: uppercase;
`;

const Img = styled.img`
  ${({ row }) => row && ('display: none;')}
  max-width: 75%;
  height: auto;
`;

const NoImgStub = styled.div`
  & svg {
    width: 50%;
    margin: 20px 20px 10px;
    fill: ${borderTertiary};
  }
`;

const CheckboxWrapper = styled.div`
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ isEditable }) => !isEditable && ('opacity: 0.2;')}
  position: absolute;
  top: 5px;
  right: 0;
  border: solid ${white} 1px;
  background: ${white};
  border-right: none;
  border-top: none;
  border-radius: 2px;

  & div[type] {
    width: 12px;
    height: 12px;

    background-size: 70%;
    background-position: 1px 1px;
  }
`;

const StarIconWrapper = styled.div`
  position: absolute;

  ${({ row }) => (row ? `
    right: 6px;
    bottom: 0;
  ` : `
    left: 4px;
    top: 1px;
  `)}

  cursor: pointer;
`;

const IconWrapper = styled.div``;
