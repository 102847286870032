/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useTranslation } from 'react-i18next';
import {
  primary,
  secondary,
  text01,
  text02,
  white,
} from '@/common/constants/theme';
import getWordDeclension from '@/common/utils/humanization.util';
import SliderMark from './SliderMark';

import { useAppContext } from '@/AppContext';
import { useSubscriptionContext } from '../SubscriptionContext';

const { createSliderWithTooltip } = Slider;
const SliderWithTooltip = createSliderWithTooltip(Slider);

export default ({ value, onChange, onAfterChange }) => {
  const { t } = useTranslation('translations');

  const { discounts } = useAppContext();
  const { personalDiscount, isPromoActivated } = useSubscriptionContext();

  const [visible, setVisible] = useState(false);

  const days = [
    t('subscriptionsContent.day'),
    t('subscriptionsContent.fromTwoToFiveDays'),
    t('subscriptionsContent.moreThenSixDays'),
  ];

  useEffect(() => {
    setVisible(true);
  }, []);

  const sliderMarksValues = [
    { value: 30, color: null },
    { value: 90, color: null },
    { value: 180, color: secondary },
    { value: 360, color: primary },
  ];

  const sliderMarks = {};

  sliderMarksValues.forEach((sv) => {
    const useDiscountValue = !isPromoActivated && (personalDiscount === 0)
      ? ((discounts || []).find((d) => ((d?.daysCount || 0) === sv.value))?.percent || 0)
      : 0;
    sliderMarks[sv.value] = (
      <SliderMark
        color={sv.color}
        discount={useDiscountValue}
      >
        {`${sv.value} ${getWordDeclension(sv.value, days)}`}
      </SliderMark>
    );
  });

  return (
    <SliderWrapper>
      <StyledSlider
        defaultValue={60}
        value={value}
        marks={sliderMarks}
        max={360}
        min={30}
        onChange={onChange}
        onAfterChange={onAfterChange}
        tipFormatter={(value) => (`${value} ${getWordDeclension(value, days)}`)}
        tipProps={{ visible }}
        step={1}
      />
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  background: ${white};
  border: 1px solid #EFEEF3;
  box-shadow: 6px 5px 4px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  height: 128px;
  padding: 3.5rem 3.5rem 0;
`;

const StyledSlider = styled(SliderWithTooltip)`
  .rc-slider-rail {
    background: ${text01};
    border-radius: 3px;
    opacity: 0.1;
    height: 2px;
  }
  .rc-slider-track {
    background-color: ${secondary};
    border-radius: 3px;
    height: 2px;
    z-index: 1;
  }
  .rc-slider-step {
    height: 2px;
  }
  .rc-slider-mark-text {
    z-index: 10;
  }
  .rc-slider-dot {
    background: ${text02};
    bottom: 0;
    border: none;
    border-radius: 3px;
    width: 3px;
    height: 2px;
    margin-left: 0;
  }
  .rc-slider-handle {
    border-color: ${secondary};
    width: 20px;
    height: 20px;
    margin-top: -9px;
    z-index: 2;

    &:active {
      box-shadow: 0 0 5px ${secondary};
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 3px;
      left: 3px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: ${secondary};
    }
  }
  .rc-slider-tooltip-arrow {
    display: none;
  }
  .rc-slider-tooltip-inner {
    background: transparent;
    box-shadow: none;
    color: ${primary};
    font-family: Gilroy, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    white-space: nowrap;
  }
`;
