import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BASE_PATH, NewsContextProvider } from './NewsContext';
import NewsList from './NewsList';
import NewsDetails from './NewsDetails';

const NewsContainer = () => (
  <Switch>
    <Route exact path={BASE_PATH}>
      <NewsContextProvider>
        <NewsList />
      </NewsContextProvider>
    </Route>
    <Route exact path={`${BASE_PATH}/:id`}>
      <NewsDetails />
    </Route>
    <Redirect to={BASE_PATH} />
  </Switch>
);

export default NewsContainer;
