import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LayoutImage from '@/assets/images/authorization-layout.png';
import PageContainer from '@/common/components/PageContainer';

export default function AuthorizationLayout({ children }) {
  const [wrapperWidth, setWidth] = useState(null);
  const [imageTop, setTop] = useState(null);

  useEffect(() => {
    function handleResize() {
      setWidth(document.getElementById('wrapper').offsetWidth);
      setTop(document.getElementById('content').offsetHeight - 240);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container id="wrapper">
      <Background src={LayoutImage} width={wrapperWidth} top={imageTop} />
      <PageContainer>
        <Content id="content">
          {children}
        </Content>
      </PageContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 9.375rem);
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

const Background = styled.img`
  position: absolute;
  width: ${({ width }) => width}px;
  top: ${({ top }) => top}px;
  z-index: -1;
`;

const Content = styled.div`
  display: flex;
  margin-bottom: 3rem;
  @media (max-width: 1198px) {
    margin-bottom: 20rem;
  }
`;
