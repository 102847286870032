import React from 'react';

import styled from 'styled-components';

const ContentContainer = (props, ref) => {
  const { children, ...useProps } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container ref={ref} {...useProps}>
      {children}
    </Container>
  );
};

export default React.forwardRef(ContentContainer);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ isFullWidth }) => (isFullWidth ? 0 : '3.5rem')};
`;
