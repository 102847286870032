import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { secondary } from '@/common/constants/theme';

import Tooltip from '@/common/components/Tooltip';
import Dropdown from '@/common/components/Dropdown';

import { usePlaylistContext } from '../PlaylistContext';

const DeviceSelector = () => {
  const { t } = useTranslation('translations');

  const {
    devices,
    selectedDevice,
    changeSelectedDevice,
  } = usePlaylistContext();
  const [devicesDropdown, setDevicesDropdown] = useState([]);
  const [selectedDevicesDropdown, setSelectedDevicesDropdown] = useState(null);
  const [disableToolTip, setDisableToolTip] = useState(false);

  const getDevicesDropdown = (items) => (items || [])
    .map((item) => ({ title: item.name, key: item.id }));

  useEffect(() => {
    setDevicesDropdown(getDevicesDropdown(
      devices
        .filter((d) => !!d.server)
        .filter((d) => !!d.playlist),
    ));
  }, [devices]);

  useEffect(() => {
    if ((selectedDevice?.id && (devicesDropdown?.length > 0))
      && selectedDevicesDropdown?.key !== selectedDevice.id) {
      setSelectedDevicesDropdown(devicesDropdown.find((dd) => dd.key === selectedDevice.id));
    }
  }, [devicesDropdown, selectedDevice]);

  return devicesDropdown && (
    <Tooltip
      title={t('steps.step5.content.deviceSelector.tooltipText')}
      trigger="hover"
      width={180}
      position="right"
      isDisabled={disableToolTip}
    >
      <StyledDropdown
        options={devicesDropdown}
        value={selectedDevicesDropdown}
        onSelect={(item) => {
          const device = devices.find((d) => d.id === item.key);
          changeSelectedDevice(device);
        }}
        onListOpen={() => setDisableToolTip(true)}
        onListClose={() => setDisableToolTip(false)}
        color={secondary}
        isBoldText
        isUpperCase
        scrollToValue
      />
    </Tooltip>
  );
};
export default DeviceSelector;

const StyledDropdown = styled(Dropdown)`
  width: 13.5rem;
  @media (min-width: 1198px) and (max-width: 1360px) {
      width: unset;
  }
`;
