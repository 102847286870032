import {
  SET_USER_INFO,
  SET_USERS, SET_USER_GROUPS, SET_PERMISSIONS,
} from '../types/users';

import { adminStore } from '@/store/admin';

export const setUserInfo = (userInfo) => ({ type: SET_USER_INFO, payload: userInfo });
export const setUsers = (users) => ({ type: SET_USERS, payload: users });
export const setUserGroups = (groups) => ({ type: SET_USER_GROUPS, payload: groups });
export const setPermissions = (permissions) => ({ type: SET_PERMISSIONS, payload: permissions });

export const updateUser = (user) => {
  const currentUsers = (adminStore.getState()?.users?.users || []);
  const userIndex = currentUsers.findIndex((u) => u.id === user.id);
  if (userIndex >= 0) {
    currentUsers.splice(userIndex, 1, user);
  }
  return { type: SET_USERS, payload: currentUsers };
};
