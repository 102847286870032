import React from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  background4,
} from '@/common/constants/theme';

const ToolbarButton = ({
  onToggle,
  active,
  disabled,
  label,
  icon,
}) => {
  const handleMouseDown = (e) => {
    e.preventDefault();
    if (!disabled) {
      onToggle();
    }
  };

  return (
    <ButtonIconWrapper
      className="rdw-option-wrapper"
      onMouseDown={handleMouseDown}
      title={label}
      active={active}
    >
      <ButtonIcon
        src={icon}
        disabled={disabled}
      />
    </ButtonIconWrapper>
  );
};

const ButtonIconWrapper = styled.div`

  min-width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;

  padding: 0.75rem 1rem;
  margin: 0px;
  height: 100%;

  border: none;
  border-right: 1px solid ${borderSecondary};

  ${({ active }) => active && `
    box-shadow: inset 1px 1px 0 #bfbdbd;
    background: ${borderSecondary};
  `}

  :hover {
      background: ${background4};
      box-shadow: none;
  }
  :active {
      background: ${borderSecondary};
      box-shadow: none;
  }

`;

const ButtonIcon = styled.img`
  vertical-align: middle;
  border-style: none;
  cursor: pointer;
  ${({ disabled }) => disabled && `
    opacity: .3;
    cursor: default;
  `}

  max-height: 20px;
  min-height: 16px;
`;

export default ToolbarButton;
