import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { white } from '../constants/theme';

import { COLORS } from '../constants/modalColors';

import modalClose from '../../assets/icons/closeWhite.svg';

const element = document.createElement('div');

const ModalWithoutContent = ({
  onCloseModal,
  children,
  icon,
  isOpen,
  color = 'success',
}) => {
  const onCloseModalHandler = () => {
    if (onCloseModal) onCloseModal();
  };

  return (
    <>
      {isOpen && (
      <Portal>
        <ModalOverlay>
          <ClickOutsideListener onClickOutside={onCloseModalHandler}>
            <ModalWindow>
              <Title color={color}>
                <img src={icon} alt={icon} />
                <ModalClose
                  modalClose={modalClose}
                  onClick={onCloseModalHandler}
                />
                <Content>
                  {children}
                </Content>
              </Title>
            </ModalWindow>
          </ClickOutsideListener>
        </ModalOverlay>
      </Portal>
      )}
    </>
  );
};

export default ModalWithoutContent;

const Title = styled.div`
  display: block;
  background: ${({ color }) => COLORS[color]};
  padding: 13px 34px;
  align-items: center;
  font-family: Gilroy, sans-serif;
  position: relative;
  img{
    position: absolute;
    left: 20px;
    top: 20px;
  }
  span{
    color: ${white};
    margin-left: 20px;
  }
`;

const ModalClose = styled.div`
  background-image: url(${(props) => props.modalClose});
  width: 11px;
  height: 11px;
  position: absolute;
  right: 13px;
  top: 13px;
  cursor: pointer;
`;

const Content = styled.div`
   margin: 30px 0;
   text-align:center;
   font-family: Gilroy, sans-serif;
   color: ${white};
   font-size: 14px;
   line-height: 20px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(110, 110, 110, .45);
  z-index: 100;
  cursor: pointer;
`;

const ModalWindow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: max-content;
  border-radius: 4px;
  width: 340px;
  display: flex;
  flex-direction: column;
  background-color: ${white};
  overflow: hidden;
  cursor: default;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

export const ClickOutsideListener = ({ onClickOutside, children }) => {
  const ref = useRef(null);

  const handleClick = (e) => {
    if (ref.current && ref.current.contains(e.target)) return;
    onClickOutside();
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return <div ref={ref}>{children}</div>;
};

export const Portal = ({ children }) => {
  useEffect(() => {
    document.body.appendChild(element);

    return () => {
      document.body.removeChild(element);
    };
  }, [element]);

  return ReactDOM.createPortal(children, element);
};
