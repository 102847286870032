import Input from '@/common/components/AuthorizationInput';
import React, { useEffect, useState } from 'react';
import generatePasswordUtil from '@/common/utils/generatePassword.util';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { activePrimary } from '@/common/constants/theme';

export default function Passwords({
  setPassword, setSuccess,
}) {
  const [error, setError] = useState('');
  const [error2, setError2] = useState('');
  const [password, setPassword2] = useState('');
  const [confirmedPassword, setConfirmedPassword2] = useState('');
  const { t } = useTranslation(['translations']);

  const validate = (pwd) => {
    const match = pwd.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/);
    return !!match;
  };

  // eslint-disable-next-line max-len
  const passwdSuccess = () => !!password && confirmedPassword && (password === confirmedPassword) && !error && !error2;

  useEffect(() => {
    setSuccess(passwdSuccess());
  }, [password, confirmedPassword]);

  const generatePassword = () => {
    const temp = generatePasswordUtil();
    setPassword(temp);
    setPassword2(temp);
    setConfirmedPassword2(temp);
    setError('');
    setError2('');
  };

  const handleChangePass1 = ({ target }) => {
    const { value } = target;
    setPassword2(value);
    setPassword(value);
    if (!validate(value)) {
      setError(t('authorization.passwordContentError'));
    } else {
      setError('');
    }
    if (confirmedPassword !== value) {
      setError2(t('authorization.confirmPasswordError'));
    } else {
      setError2('');
    }
  };

  const handleChangePass2 = ({ target }) => {
    const { value } = target;
    setConfirmedPassword2(value);
    if (password !== value) {
      setError2(t('authorization.confirmPasswordError'));
    } else {
      setError2('');
    }
  };

  return (
    <>
      <Input
        isSuccess={password && !error}
        placeholder={t('authorization.password')}
        type="password"
        onKeyUp={({ target, keyCode }) => {
          if (keyCode === 13) {
            handleChangePass1({ target });
          }
        }}
        label={t('authorization.password')}
        isCopied
        value={password}
        onChange={handleChangePass1}
        errorText={error}
      />
      <GeneratePassword onClick={generatePassword}>
        {t('authorization.signUp.generatePassword')}
      </GeneratePassword>
      <Input
        isSuccess={!!password && password === confirmedPassword}
        placeholder={t('authorization.signUp.confirmPassword')}
        type="password"
        onKeyUp={({ target, keyCode }) => {
          if (keyCode === 13) {
            handleChangePass2({ target });
          }
        }}
        onChange={handleChangePass2}
        value={confirmedPassword}
        errorText={error2}
        onCopy={(e) => e.preventDefault()}
      />
    </>
  );
}

const GeneratePassword = styled.div`
  font-family: Gilroy;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${activePrimary};
  margin-top: -1.25rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  margin-bottom: .6rem;
  cursor: pointer;

  &&:hover {
    color: #ffab30;
  }
`;
