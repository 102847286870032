import {
  SET_SNACK, SET_MENU, SET_MENU_ITEMS,
  SET_WITHDRAWAL_SYSTEMS, SET_PAYMENT_SYSTEMS,
  SET_COUNTRIES, SET_RAW_COUNTRIES,
  SET_MINISTRA_SERVERS, SET_SERVERS, SET_OS_FORMATS,
  SET_PASSWORD_PIN_CHECK_COUNT,
} from '../types/common';

const initialState = {
  menu: null,
  menuItems: null,
  snack: null,
  servers: null,
  ministraServers: null,
  osFormats: null,
  withdrawalSystems: null,
  paymentSystems: null,
  countries: null,
  countriesRaw: null,
  passwordPinCheckCount: null,
};

export const commonPart = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case SET_MENU_ITEMS:
      return {
        ...state,
        menuItems: action.payload,
      };
    case SET_SNACK:
      return {
        ...state,
        snack: action.payload,
      };
    case SET_SERVERS:
      return {
        ...state,
        servers: action.payload,
      };
    case SET_MINISTRA_SERVERS:
      return {
        ...state,
        ministraServers: action.payload,
      };
    case SET_OS_FORMATS:
      return {
        ...state,
        osFormats: action.payload,
      };
    case SET_WITHDRAWAL_SYSTEMS:
      return {
        ...state,
        withdrawalSystems: action.payload,
      };
    case SET_PAYMENT_SYSTEMS:
      return {
        ...state,
        paymentSystems: action.payload,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_RAW_COUNTRIES:
      return {
        ...state,
        countriesRaw: action.payload,
      };
    case SET_PASSWORD_PIN_CHECK_COUNT:
      return {
        ...state,
        passwordPinCheckCount: parseInt(action.payload, 10),
      };
    default:
      return state;
  }
};
