import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import SmileSrc from '@/assets/icons/smileIcon.svg';
import {
  text01,
  red,
  primary,
} from '@/common/constants/theme';
import Button from '@/common/components/Button';
import { useChannelsContext } from '../ChannelsContext';
import DevicesSelected from '@/screens/PersonalPageScreen/components/PageContent/DevicesSelected';

const ChannelsFooter = () => {
  const { t } = useTranslation('translations');
  const {
    channelsFilters,
  } = useChannelsContext();
  const { isStalker } = channelsFilters;
  const history = useHistory();

  const forwardHandler = () => {
    history.push({
      pathname: '/personal-page/playlist',
      state: { scrollToStepsNav: true },
    });
  };

  return (
    <Container>
      {!isStalker && (
        <Wrapper alignCenter>
          <Img
            src={SmileSrc}
            alt=""
          />
          <Text>
            {t('steps.step4.content.footer.warning')}
          </Text>
          <Text redText>
            {t('steps.step4.content.footer.warningRed')}
          </Text>
        </Wrapper>
      )}
      <Wrapper flexSb topMargin>
        <BackButton
          onClick={() => history.push({
            pathname: '/personal-page/server',
            state: { scrollToStepsNav: true },
          })}
        >
          {t('steps.step4.content.footer.back')}
        </BackButton>
        <StyledButton
          isBoldText
          isUpperCase
          isOutline
          onClick={() => forwardHandler()}
        >
          {t('steps.step4.content.footer.forward')}
        </StyledButton>
      </Wrapper>
      <DevicesSelected />
    </Container>
  );
};

export default ChannelsFooter;

const Container = styled.div`
  margin-right: 62px;
`;

const Wrapper = styled.div`
  display: flex;
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  ${({ alignCenter }) => alignCenter && ('align-items: center;')}

  ${({ topMargin }) => topMargin && ('margin-top: 35px;')}
`;

const Text = styled.div`
  ${({ redText }) => redText && ('margin-left: 5px;')}

  font-family: Gilroy;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${({ redText }) => (redText ? red : text01)};
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 48px;
  width: 80px;
  margin-right: 20px;
  margin-left: auto;

  cursor: pointer;
  border-radius: 2px;

  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: ${primary};
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: capitalize;

  &:hover {
    background: #eef1fb;
  }
`;

const Img = styled.img`
  margin-right: 11px;
`;

const StyledButton = styled(Button)`
  width: 80px;
`;
