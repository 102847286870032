import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  primary,
  background01,
  text01, text03,
} from '@/common/constants/theme';

import ChannelsList from './components/ChannelsList';
import Filters from './components/Filters';
import ProgramGuide from './components/ProgramGuide';
import Player from './Player';
import CloseIcon from '../../icons/CloseIcon';

import { usePersonalPageContext } from '../../../PersonalPageContext';
import { usePlayerContext } from './PlayerContext';

const PAGE_ID = 'player';

const PlayerPage = () => {
  const { t } = useTranslation('translations');
  const {
    isPageHeaderDisabled,
    handleClosePageHeader,
  } = usePersonalPageContext();
  const { localDevices } = usePlayerContext();
  return (
    <Container>
      {!isPageHeaderDisabled?.[PAGE_ID] && (
        <Block>
          <Title>{t('player.title')}</Title>
          <SubTitle>{t('player.subTitle')}</SubTitle>
          <CloseBlock
            title={t('personalPage.hide')}
            onClick={() => handleClosePageHeader(PAGE_ID)}
          >
            <CloseIcon />
          </CloseBlock>
        </Block>
      )}
      {(localDevices || []).length > 0 ? (
        <Content>
          <ChannelsList />
          <PlayerWrapper>
            <Filters />
            <Player />
            <ProgramGuide />
          </PlayerWrapper>
        </Content>
      ) : (
        <EmptyContent>
          <EmptyContentText>{t('player.noActiveDevices')}</EmptyContentText>
        </EmptyContent>
      )}
    </Container>
  );
};

export default PlayerPage;

const Container = styled.div`
  min-height: 14rem;
  width: 100%;
`;

const EmptyContent = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyContentText = styled.div`
  font-family: Gilroy,sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${text03};
  text-align: center;
  line-height: 2rem;
  letter-spacing: 0.16px;
  white-space: pre;
`;

const Block = styled.div`
  min-height: 169px;
  height: auto;
  background: ${background01};
  padding: 2rem 3rem 2rem 1rem;
  position: relative;
  margin: 1.25rem 0 0 -1rem;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    min-height: 169px;
    height: 100%;
    top: 0;
    left: -100%;
    background: ${background01};
  }
`;

const SubTitle = styled.div`
  font-family: Gilroy, sans-serif;
  margin-bottom: .75rem;
  font-size: 15px;
  line-height: 20px;
`;

const CloseBlock = styled.div`
  position: absolute;
  cursor: pointer;
  top: .8rem;
  right: .8rem;

  & svg{
    width: 12px;
    height: 12px;

    path {
      stroke: red;
    }
  }
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.6;
  color: ${text01};
  position: relative;
  margin-bottom: 33px;

  &:after {
    content: '';
    height: 4px;
    position: absolute;
    width: 30px;
    bottom: -10px;
    left: 0;
    background: ${primary};
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const PlayerWrapper = styled.div`
  flex: 1;
`;
