import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, Route, Switch } from 'react-router-dom';

import { greySecondary, primary, secondary } from '@/common/constants/theme';
import StatisticPaymentSystemsTable from './components/ReplenishTable';
import StatisticPaymentSystem from './components/ReplenishEdit';
import StatisticWithdrawalsystemsTable from './components/WithdrawalsTable';
import StatisticWithdrawalSystem from './components/WithdrawalEdit';

import { NEW_PAYSYSTEM_ID } from './AggregatorsContext';

const aggregatorsAllPath = '/admin/statistic/payment';
const paymentsAllPath = '/admin/statistic/payment/payments';
const withdrawalsAllPath = '/admin/statistic/payment/withdrawals';
const paymentPath = `${paymentsAllPath}/:paySystemId`;
const withdrawalPath = `${withdrawalsAllPath}/:paySystemId`;

const TABS = {
  payments: {
    name: 'payments',
    caption: 'Системы пополнения',
    path: paymentPath,
    newItemCaption: 'Добавить систему пополнения',
    tableComponent: <StatisticPaymentSystemsTable editPath={paymentsAllPath} />,
  },
  withdrawals: {
    name: 'withdrawals',
    caption: 'Системы вывода',
    path: withdrawalPath,
    newItemCaption: 'Добавить систему вывода',
    tableComponent: <StatisticWithdrawalsystemsTable editPath={withdrawalsAllPath} />,
  },
};

const StatisticAggregators = () => {
  const [activeTab, setActiveTab] = useState(TABS.payments);

  const renderTab = (Tab) => (
    <TabContent>
      <StyledLink
        to={Tab.path.replace(':paySystemId', `${NEW_PAYSYSTEM_ID}/`)}
      >
        {Tab.newItemCaption}
      </StyledLink>
      {Tab.tableComponent}
    </TabContent>
  );

  return (
    <Container>
      <Switch>
        <Route path={aggregatorsAllPath} exact>
          <TabsList>
            {Object.values(TABS).map((T) => (
              <TabsListItem
                key={`${T.name}--paymentSystems-control`}
                isActive={activeTab.name === T.name}
                onClick={() => setActiveTab(T)}
              >
                {T.caption}
              </TabsListItem>
            ))}
          </TabsList>
          <TabsContainer>
            {activeTab && renderTab(activeTab)}
          </TabsContainer>
        </Route>
        <Route
          path={paymentPath}
          render={() => <StatisticPaymentSystem mainPath={aggregatorsAllPath} />}
        />
        <Route
          path={withdrawalPath}
          render={() => <StatisticWithdrawalSystem mainPath={aggregatorsAllPath} />}
        />
      </Switch>
    </Container>
  );
};

export default StatisticAggregators;

const Container = styled.div``;

const StyledLink = styled(Link)`
  display: block;
  margin-left: 20px;
  margin-bottom: 20px;

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${secondary};
  text-transform: capitalize;
  line-height: 20px;

  &:hover,
  &:focus {
    color: ${secondary};
  }
`;

const TabsList = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 1px ${greySecondary};
  margin-bottom: 1rem;
`;

const TabsListItem = styled.div`
  padding: 0.1rem .75rem;
  margin: 0.4rem 0;
  border-right: solid 1px ${greySecondary};
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? secondary : primary)};

  &:last-of-type {
    border-right: none;
  }
`;

const TabsContainer = styled.div``;

const TabContent = styled.div``;
