import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  secondary, green, white, text01,
} from '@/common/constants/theme';
import Modal from '@/common/components/Modal';
import Document from '@/assets/icons/document.svg';

const PrivacyPolicyModal = ({ isVisible, toggleVisibility, onSubmit }) => {
  const { t } = useTranslation(['translations']);

  return (
    <StyledModal
      isPopupVisible={isVisible}
      onCancel={toggleVisibility}
      centered
    >
      <ModalHeader>
        <AddIconModal src={Document} />
        <ModalText>{t('authorization.policy.privacyPolicyTitle')}</ModalText>
      </ModalHeader>
      <Content>
        <Text>
          {t('authorization.policy.privacyPolicyText')}
        </Text>
        <Actions>
          <Button
            onClick={onSubmit}
          >
            {t('authorization.policy.agreeButton')}
          </Button>
        </Actions>
      </Content>
    </StyledModal>
  );
};

export default PrivacyPolicyModal;

const StyledModal = styled(Modal)`
  filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.25));
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  background: ${green};
  color: ${white};
  padding: 1rem 1.25rem;
`;

const ModalText = styled.p`
    font-family: Gilroy;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.16px;
    margin: 0;
    margin-top: .25rem;
`;

const AddIconModal = styled.img`
    width: 52px;
    height: 52px;
    margin-right: .75rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2.5rem 2.5rem 2rem;
`;

const Text = styled.div`
  height: 22.5rem;
  overflow: auto;
  white-space: pre-wrap;
  font-family: Gilroy;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const Button = styled.button`
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
  margin-top: 2rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: .5rem;
`;
