import React from 'react';
import styled from 'styled-components';

import SnackBarWindow from '@/common/components/SnackBarWindow';
import StepsIndicator from '../../StepsIndicator';
import ChannelsHeader from './components/ChannelsHeader';
import ChannelsMain from './components/Main/ChannelsMain';
import ChannelsFooter from './components/ChannelsFooter';

import { SHOW_RIGHT_PANEL, usePersonalPageContext } from '../../../PersonalPageContext';
import { useChannelsContext } from './ChannelsContext';

const Channels = () => {
  const PAGE_ID = 'channels';

  const { isPageHeaderDisabled, handleClosePageHeader } = usePersonalPageContext();
  const { channelsMessage, setChannelsMessage } = useChannelsContext();

  return (
    <Container>
      <SnackBarWindow
        content={channelsMessage}
        type="warning"
        isOpen={channelsMessage !== null}
        onClose={() => { setChannelsMessage(null); }}
        timestamp={7000}
        noOutsideClose
      />
      <StepsIndicator />
      <Content>
        {!isPageHeaderDisabled?.[PAGE_ID] && (
          <ChannelsHeader
            isFullWidth={!SHOW_RIGHT_PANEL}
            disableThis={() => handleClosePageHeader(PAGE_ID)}
          />
        )}
        <ChannelsMainContainer>
          <ChannelsMain
            isFullWidth={!SHOW_RIGHT_PANEL}
          />
          <ChannelsFooter />
        </ChannelsMainContainer>
      </Content>
    </Container>
  );
};

export default Channels;

const Container = styled.div`
  margin-bottom: 125px;
`;

const ChannelsMainContainer = styled.div`
  overflow-x: clip;
`;

const Content = styled.div`
  margin-top: 20px;
`;
