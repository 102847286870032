import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SortableElement } from 'react-sortable-hoc';

import {
  border,
  text01,
  white,
  background01, borderSecondary,
} from '@/common/constants/theme';

import CloseIcon from '@/common/components/icons/CloseIcon';
import EditIcon from '@/common/components/icons/EditIcon';
import DisketteIcon from '../../../../../../../icons/DisketteIcon';
import { useAdminContext } from '../../../../../../../../AdminContext';
import { usePlayersContext } from '../../../PlayersContext';
import Dropdown from '../../../../../../../../../../common/components/Dropdown';

const PlayersTableBodyItem = SortableElement(({
  editedId,
  setEditedId,
  item,
  i,
  inputValues,
  setInputValues,
  sizes,
  values,
}) => {
  const {
    channels,
    handleSaveChannels,
    getPosition,
    dropdownGroups,
    groups,
  } = useAdminContext();
  const { filters } = usePlayersContext();
  const { page } = filters;

  const [option, setOption] = useState({});

  const handleEdit = (id) => {
    setEditedId(id);
    setInputValues(channels[id]);
  };

  const handleInput = (field, value) => setInputValues(
    (prevState) => ({ ...prevState, [field]: value }),
  );

  useEffect(() => {
    if (editedId >= 0) {
      setOption(dropdownGroups.find((opt) => opt.title === channels[editedId].group_name));
    }
  }, [editedId]);

  return (
    <Container onDoubleClick={() => handleEdit(i)} isOdd={i % 2 === 1}>
      <Wrapper width={sizes[0]}>
        {editedId === i
          ? (
            <Input
              value={inputValues.id}
              onChange={({ target }) => handleInput('id', target.value)}
            />
          )
          : <Text>{item.id}</Text>}
      </Wrapper>
      <Wrapper width={sizes[1]}>
        {editedId === i
          ? (
            <Input
              value={inputValues.name}
              onChange={({ target }) => handleInput('name', target.value)}
            />
          )
          : <Text>{item.name}</Text>}
      </Wrapper>
      <Wrapper width={sizes[2]}>
        {editedId === i
          ? (
            <Dropdown
              options={dropdownGroups.slice(1)}
              value={option}
              onSelect={(opt) => setOption(opt)}
            />
          )
          : (
            <Text>
              {item.group_name > 14
                ? `${item.group_name}...`
                : item.group_name}
            </Text>
          )}
      </Wrapper>
      <Wrapper width={sizes[3]}>
        {editedId === i
          ? (
            <Input
              value={inputValues.name_translit}
              onChange={({ target }) => handleInput('name_translit', target.value)}
            />
          )
          : <Text>{item.name_translit}</Text>}
      </Wrapper>
      {values.map((value, index) => value.visible && (
        <ItemWrapper firstElement={index === 0} key={`APTIV--${i}--${value.key}`}>
          <Wrapper width={sizes[4]}>
            {editedId === i
              ? (
                <Input
                  value={inputValues?.[`${value.key}_name`]}
                  onChange={({ target }) => handleInput(`${value.key}_name`, target.value)}
                />
              )
              : <Text>{item?.[`${value.key}_name`]}</Text>}
          </Wrapper>
          <Wrapper width={sizes[5]}>
            {editedId === i
              ? (
                <Input
                  width="50px"
                  value={inputValues?.[`${value.key}_tvgid`]}
                  onChange={({ target }) => handleInput(`${value.key}_tvgid`, target.value)}
                />
              )
              : <Text>{item?.[`${value.key}_tvgid`]}</Text>}
          </Wrapper>
        </ItemWrapper>
      ))}
      <IconsWrapper width={35} flex>
        <Icon
          onClick={() => {
            const currentValues = { ...inputValues };
            currentValues.group = groups.find((group) => group.name === option.title).id;
            // handleSaveChannels(getPosition(page, editedId), page, editedId, currentValues);
            // updateChannelById(currentValues.id, currentValues, getOffset(page));
            handleSaveChannels(i, currentValues, getPosition(page, editedId));
            setEditedId(-1);
          }}
          top={editedId === i}
        >
          {editedId === i && <DisketteIcon />}
        </Icon>
        <Icon>
          {editedId === i ? (
            <IconWrapper onClick={() => (editedId >= 0 && setEditedId(-1))}>
              <CloseIcon />
            </IconWrapper>
          ) : (
            <IconWrapper onClick={() => handleEdit(i)}>
              <EditIcon />
            </IconWrapper>
          )}
        </Icon>
      </IconsWrapper>
    </Container>
  );
});

export default PlayersTableBodyItem;

const Container = styled.div`
  display: flex;
  align-items: center;

  height: 42px;
  padding-left: 29px;
  padding-right: 33px;

  ${({ isOdd }) => (isOdd ? `background-color: ${background01};` : `background-color: ${white};`)}
  border-bottom: 1px solid ${borderSecondary};
  user-select: none;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  flex-shrink: 0;

  width: ${({ width }) => width}px;

  &:not(:first-child) {
    margin-left: 21px;
  }
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 16px;

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 13px;
  }
`;

const ItemWrapper = styled.div`
  width: 260px;
  display: flex;
  margin-left: 20px;

  ${({ firstElement }) => firstElement && ('margin-left: 20px;')}
`;

const IconsWrapper = styled(Wrapper)`
  margin-left: 15px !important;
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;

  ${({ leftMargin }) => leftMargin && ('margin-left: 20px;')}

  cursor: pointer;

  position: relative;
  ${({ top }) => top && ('top: 2px;')}

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

const IconWrapper = styled.div``;

const Input = styled.input`
  width: ${({ width }) => width || '100%'};
  height: 25px;
  padding: 0 2px;

  background: ${white};
  border: 1px solid ${border};
  border-radius: 2px;
  outline: none;
`;
