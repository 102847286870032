import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  secondary, activePrimary, white, text01, secondaryHover, secondaryActive, red,
} from '@/common/constants/theme';
import Loader from '@/common/components/Loader';
import AuthorizationLayout from '../components/AuthorizationLayout';
import TitleWithOrange from '../components/TitleWithOrange';
import { confirmWithdrawalWalletAction } from '@/services/actions/profile.actions';

export default function ConfirmWalletContainer({ history, match }) {
  const { t } = useTranslation(['translations']);
  const [errors, setErrors] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isWithdrawalWalletConfirmed, setIsWithdrawalWalletConfirmed] = useState(false);

  const defaultTexts = () => ({
    firstPart: t('confirm.withdrawalWallets.textBlack1'),
    orangePart: t('confirm.withdrawalWallets.textOrange'),
    secondPart: t('confirm.withdrawalWallets.textBlack2'),
    text: t('confirm.withdrawalWallets.textConfirm'),
    button: t('confirm.withdrawalWallets.button'),
  });

  const [texts, setTexts] = useState(defaultTexts);

  const successUrl = '/personal-page/profile';

  const handleConfirmWithdrawalWallet = () => {
    setLoading(true);
    const { token } = match.params;
    confirmWithdrawalWalletAction(token)
      .then(() => {
        setLoading(false);
        setTexts({
          firstPart: t('confirm.withdrawalWallets.textBlack1'),
          orangePart: t('confirm.withdrawalWallets.textOrange'),
          secondPart: t('confirm.withdrawalWallets.textBlack2'),
          text: t('confirm.withdrawalWallets.textSuccess'),
          button: t('confirm.withdrawalWallets.button'),
        });
        setIsWithdrawalWalletConfirmed(true);
      })
      .catch((err) => {
        setLoading(false);
        setErrors(`${t(`confirm.withdrawalWallets.errors.${err?.response?.payload?.message_type || err?.data?.message_type}`)}`);
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <AuthorizationLayout>
        <TitleWithOrange
          textBlack1={texts.firstPart}
          textOrange={texts.orangePart}
          textBlack2={texts.secondPart}
        />
        <Form>
          <Text>{texts.text}</Text>
          {isWithdrawalWalletConfirmed ? (
            <LoginButton
              path={successUrl}
              onClick={() => history.push(successUrl)}
            >
              {t('personalPage.toPage')}
            </LoginButton>
          ) : (
            <>
              <Button onClick={handleConfirmWithdrawalWallet}>
                {t('confirm.withdrawalWallets.button')}
              </Button>
              <div style={{ color: red }}>{ errors }</div>
            </>
          )}
        </Form>
      </AuthorizationLayout>
    </>
  );
}

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.2em;
  color: ${text01};
  white-space: pre-wrap;
  margin-bottom: 1rem;
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 8rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const Button = styled.button`
  margin: .5rem 0;
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const LoginButton = styled.button`
  border: 2px solid ${secondary};
  border-radius: 2px;
  padding: .76rem 1rem;
  margin-left: 1.5rem;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.20s ease-in;
  outline: none;
  ${({ path }) => (path ? `
  background: ${white};
  color: ${activePrimary};
  :hover {
    background: #FFAB30;
    border-color: #FFAB30;
    color: ${white};
  }
  ` : `
  background: ${secondary};
  color: ${white};
  :hover {
    background: ${secondaryHover};
    border-color: ${secondaryHover};
    color: ${white};
  }
  :active {
    background: ${secondaryActive};
    border-color: ${secondaryActive};
  }
  `)}
`;
