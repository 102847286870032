import React from 'react';
import styled from 'styled-components';
import { background01 } from '@/common/constants/theme';

import { SHOW_RIGHT_PANEL } from '../../../PersonalPageContext';

const PageContentBlock = ({ isAutoHeight, children }) => (
  <Block
    isFullWidth={!SHOW_RIGHT_PANEL}
    isAutoHeight={isAutoHeight}
  >
    {children}
  </Block>
);
export default PageContentBlock;

const Block = styled.div`
  ${({ isAutoHeight }) => (!isAutoHeight && 'min-height: 367px;')}
  height: auto;
  background: ${background01};
  position: relative;
  ${!SHOW_RIGHT_PANEL ? `
    padding: 2rem 1.5rem 2rem 1.5rem;
    margin: 0 0 2.5rem 0;
  ` : `
    padding: 2rem 3rem 2rem 1rem;
    margin: 0 1.1875rem 2.5rem -1rem;
  `}

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    ${({ isAutoHeight }) => (!isAutoHeight && 'min-height: 367px;')}
    height: 100%;
    top: 0;
    left: -100%;
    background: ${background01};
  }
`;
