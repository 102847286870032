import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  activeTertiary,
  background01,
  borderSecondary,
  greyText,
  primary,
  secondary,
  text01,
  text03,
  white,
} from '@/common/constants/theme';

import getWordDeclension, { ENDINGS, humanizeDaysDuration } from '@/common/utils/humanization.util';

import Button from '@/common/components/Button';

import { useAppContext } from '@/AppContext';
import { useReferralsContext } from '../ReferralsContext';

const ReferralsHeader = ({
  isFullWidth,
  transferHandler,
  requestHandler,
  userReferralCurrentMonth,
}) => {
  const { t } = useTranslation('translations');

  const [userVars, setUserVars] = useState(null);

  const { userInfo } = useAppContext();
  const { withdrawalWallets } = useReferralsContext();

  const getWithdrawalButtonTitle = () => {
    const x = [];
    if ((userInfo?.referral_balance || 0) === 0) {
      x.push(t('referrals.card.emptyBalance'));
    }

    if ((withdrawalWallets || []).length === 0) {
      x.push(t('referrals.card.noWalletsAvailable'));
    }
    return x.join('\n');
  };

  useEffect(() => {
    const hDuration = humanizeDaysDuration((userInfo?.referral_payments_days || 0));
    const durationTextItems = [];
    Object.entries(hDuration)
      .filter(([, count]) => count > 0)
      .forEach(([type, count]) => {
        durationTextItems.push(`${count <= 20 ? t(`common.numbers.${count}`) : count} ${getWordDeclension(count, ENDINGS.map((e) => t(`common.declension.${type}.${e}`)))}`);
      });

    const durationTextItemsFirstPart = durationTextItems.length > 1
      ? durationTextItems.slice(0, durationTextItems.length - 1)
      : durationTextItems;
    const durationTextItemsLastItem = durationTextItems.length > 1
      ? durationTextItems[durationTextItems.length - 1]
      : null;

    const data = {
      userBonus: (userInfo?.referral_payments_percent || 0) > 0
        ? `${userInfo.referral_payments_percent}%`
        : t('referrals.descr.userPercent'),
      userBonusDuration: durationTextItems.length > 0
        ? ` ${durationTextItemsFirstPart.length > 0
          ? durationTextItemsFirstPart.join(', ')
          : ''
        }${durationTextItemsLastItem
          ? ` ${t('common.and')} ${durationTextItemsLastItem}`
          : ''
        }`
        : '',
      referralBonus: (userInfo?.referral_first_replenishment_bonus_percent || 0) > 0
        ? ` +${userInfo.referral_first_replenishment_bonus_percent}%`
        : '',
    };

    setUserVars(data);
  }, [userInfo?.id]);

  const fixDigits = (str, digitsCount) => (
    Number.parseFloat(str).toFixed(digitsCount)
  );

  return (
    <Container isFullWidth={isFullWidth}>
      <Wrapper rightMargin>
        <MainTitle>{t('referrals.title')}</MainTitle>
        <Text bottomMargin={14}>{t('referrals.descr.titleLifeTime', userVars)}</Text>
        <Text>{t('referrals.descr.descr', userVars)}</Text>
      </Wrapper>
      <Wrapper>
        <Card>
          <Wrapper>
            <BoldText bottomMargin={24} topMargin>{t('referrals.card.title')}</BoldText>
            <Text opaced>{t('referrals.card.subTitle')}</Text>
          </Wrapper>
          <Wrapper>
            <Title alignRight bottomMargin={17}>{`$ ${fixDigits((userInfo?.referral_balance || 0), 2)}`}</Title>
            <Text opaced alignRight>{`$ ${fixDigits(userReferralCurrentMonth, 2)}`}</Text>
          </Wrapper>
        </Card>
        <ButtonsWrapper>
          <StyledButton
            isBoldText
            isUpperCase
            onClick={transferHandler}
            isDisabled={(userInfo?.referral_balance || 0) === 0}
          >
            {t('referrals.card.transfer')}
          </StyledButton>
          <StyledSecondaryButton
            title={getWithdrawalButtonTitle()}
            isBoldText
            isUpperCase
            onClick={requestHandler}
            isDisabled={((userInfo?.referral_balance || 0) === 0)
              || ((withdrawalWallets || []).length === 0)}
          >
            {t('referrals.card.request')}
          </StyledSecondaryButton>
        </ButtonsWrapper>
      </Wrapper>
    </Container>
  );
};

export default ReferralsHeader;

const Container = styled.div`
  display: flex;

  ${({ isFullWidth }) => (isFullWidth ? `
    margin: 0 0 35px 0;
  ` : `
    margin: 0 19px 35px 0;
  `)}
  padding: 35px 44px;

  position: relative;

  background-color: ${background01};

  &::after {
    content: '';

    width: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;

    background-color: ${background01};
  }
`;

const Wrapper = styled.div`
  ${({ rightMargin }) => rightMargin && ('margin-right: 63px;')};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0 20px;
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;

  min-width: 331px;
  min-height: 124px;
  padding: 23px 25px 36px 25px;
  margin-bottom: 19px;

  background: ${white};
  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && (`margin-bottom: ${bottomMargin}px;`)};

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${({ opaced }) => (opaced ? text03 : text01)};
  ${({ alignRight }) => alignRight && ('text-align: right;')};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const BoldText = styled(Text)`
  ${({ topMargin }) => topMargin && ('margin-top: 10px;')};

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const Title = styled(Text)`
  position: relative;

  font-weight: 600;
  font-size: 24px;
  color: ${secondary};
  line-height: 36px;
  text-overflow: ellipsis;
  height: 36px;
  white-space: nowrap;
`;

const MainTitle = styled(Title)`
  margin-bottom: 33px;

  color: ${text01};

  &::before {
    content: '';

    width: 30px;
    height: 4px;

    position: absolute;
    bottom: -10px;
    left: 0;

    background-color: ${activeTertiary};
  }
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 0 17px;
  margin-right: 20px;

  background-color: transparent;

  color: ${primary};

  transition: none;

  &:hover {
    background-color: #eef1fb;
  }

  &:active {
    background-color: transparent;
  }
  &:disabled{
    background: none;
    color: ${greyText};
  }
`;

const StyledSecondaryButton = styled(Button)`
  width: auto;
  padding: 0 17px;
`;
