import React from 'react';

import FAQ from './FAQ';

import { FAQContextProvider } from './FaqContext';

const FAQContainer = () => (
  <FAQContextProvider>
    <FAQ />
  </FAQContextProvider>
);

export default FAQContainer;
