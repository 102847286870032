import React from 'react';
import styled from 'styled-components';

import { secondary, text01, primary } from '@/common/constants/theme';
import { Link } from 'react-router-dom';
import ArrowRightPrimary from '../../../../icons/ArrowRightPrimary';

const UsersGroupsHeader = ({ setIsAddNewGroup }) => (
  <Container>
    <Wrapper>
      <Title>
        Группы пользователей
      </Title>
      <NewGroup onClick={() => setIsAddNewGroup(true)}>
        Новая группа
      </NewGroup>
    </Wrapper>
    <Wrapper>
      <StyledLink to="/admin/users/main">
        Вернуться к списку пользователей
      </StyledLink>
      <Icon>
        <ArrowRightPrimary />
      </Icon>
    </Wrapper>
  </Container>
);

export default UsersGroupsHeader;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 16px;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 600;
  color: ${text01};
  line-height: 20px;
`;

const NewGroup = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: 0 24px;
  margin-left: 20px;

  background-color: transparent;
  border: 1px solid ${secondary};
  border-radius: 2px;
  cursor: pointer;

  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  color: ${secondary};
  text-transform: uppercase;
  line-height: 24px;
`;

const StyledLink = styled(Link)`
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  color: ${primary};
  line-height: 20px;

  &:hover,
  &:focus {
    color: ${primary};
  }
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;
  top: 3px;
  cursor: pointer;
`;
