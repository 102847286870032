/* eslint-disable object-curly-newline */
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import styled from 'styled-components';
import {
  activeTertiary,
  text01,
  primary, secondary,
  black,
  lightRed, brightGreen, darkGrey, inputBorder, greyText, borderSecondary, white,
} from '@/common/constants/theme';

import { CURRENCY_SYMBOLS } from '@/common/constants/financial';

import Checkbox from '@/common/components/Checkbox';
import DatePickerNew from '@/common/components/DatePickerNew';
import Loader from '@/common/components/Loader';
import ExpandedBlock from './components/ExpandedBlock';

import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import AdminTablePagination from '../components/Table/components/TablePagination';

import SearchIcon from '../../icons/SearchIcon';
import ArrowTable from '../../icons/ArrowTable';
import CloseIcon from '../../icons/CloseIcon';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
import FinanceIcon from '../../icons/FinanceIcon';
import UpdateIcon from '../../icons/UpdateIcon';

import {
  SORT_DIRECTIONS,
  useAdminContext,
} from '../../../AdminContext';

import {
  // DATA_TYPES,
  CHART_COLORS,
  SEARCH_FILTER_TIMEOUT,
  useStatisticsContext,
} from './StatisticsContext';

const TRANSACTION_TYPES = {
  purchase: 'purchase',
  withdrawal: 'withdrawal',
  replenishment: 'replenishment',
  unsuccessful: 'unsuccessful',
};

const TYPE_TITLES = {
  purchase: 'Покупка',
  withdrawal: 'Вывод средств',
  replenishment: 'Пополнение',
  unsuccessful: 'Неуспешные платежи',
};

const INITIAL_FILTERS = [
  {
    key: TRANSACTION_TYPES.purchase,
    color: black,
    title: TYPE_TITLES.purchase,
    isSelected: true,
  },
  {
    key: TRANSACTION_TYPES.withdrawal,
    color: lightRed,
    title: TYPE_TITLES.withdrawal,
    isSelected: true,
  },
  {
    key: TRANSACTION_TYPES.unsuccessful,
    color: darkGrey,
    title: TYPE_TITLES.unsuccessful,
    isSelected: true,
  },
];

const TYPE_COLORS_STORAGE_KEY = 'statisticsFinanceTableColors';

const SIZES = ['80px', '150px', '100px', '170px', '620px'];

const FinanceContainer = () => {
  const {
    formatUserPageLink,
    formatDate,
  } = useAdminContext();

  const {
    paymentSystems,
    isFinanceStatsProcessing,
    financeStats,
    financeStatsFilter,
    setFinanceStatsFilter,
    filterDatesToLocal,
    filterDatesFromLocal,
  } = useStatisticsContext();

  const statsLegend = useRef(null);
  const statsFiltersRef = useRef(null);

  const sorts = {
    asc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.asc],
    desc: [SORT_DIRECTIONS.notSetted, SORT_DIRECTIONS.desc],
  };

  const [localDates] = useState(filterDatesToLocal(financeStatsFilter));
  const [currentPage, setCurrentPage] = useState(financeStatsFilter.page || 1);

  const [search, setSearch] = useState({
    query: '',
  });
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [sortByDate, setSortByDate] = useState(SORT_DIRECTIONS.notSetted);

  const [typeColors, setTypeColors] = useState(() => {
    let retVal = {};
    try {
      retVal = JSON.parse(localStorage.getItem(TYPE_COLORS_STORAGE_KEY));
    } catch (Ex) {
      // eslint-disable-next-line no-console
      console.log('parse error, local value:', localStorage.getItem(TYPE_COLORS_STORAGE_KEY), 'local storage resetted');
    }
    if (!retVal || Object.keys(retVal).length === 0) {
      retVal = {
        [TRANSACTION_TYPES.withdrawal]: lightRed,
        [TRANSACTION_TYPES.purchase]: black,
        [TRANSACTION_TYPES.replenishment]: brightGreen,
        [TRANSACTION_TYPES.unsuccessful]: darkGrey,
      };
      localStorage.setItem(TYPE_COLORS_STORAGE_KEY, JSON.stringify(retVal));
    }
    return retVal;
  });

  const [typeFilter, setTypeFilter] = useState([]);
  const [typeFilterTimeout, setTypeFilterTimeout] = useState(null);

  const [disabledButton, setDisabledButton] = useState({
    stats: { prev: false, next: false },
  });

  const selectAllFilters = () => {
    const newFilters = typeFilter.map((field) => ({
      ...field,
      isSelected: true,
    }));
    setTypeFilter(newFilters);
  };

  const changeSortByDateDirection = () => {
    const directionsKeys = Object.keys(SORT_DIRECTIONS);
    const directions = Object.values(SORT_DIRECTIONS);
    const currentSortIndex = directions.findIndex((s) => s === sortByDate) + 1;
    const nextSortIndex = currentSortIndex > directions.length - 1 ? 0 : currentSortIndex;

    setSortByDate(SORT_DIRECTIONS[directionsKeys[nextSortIndex]]);

    const field = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.notSetted ? '' : 'dt_create';
    const sign = SORT_DIRECTIONS[directionsKeys[nextSortIndex]] === SORT_DIRECTIONS.desc ? '' : '-';

    setFinanceStatsFilter((prevState) => ({
      ...prevState,
      ordering: `${sign}${field}`.trim(),
    }));
  };

  const getSearchString = (searchObject) => {
    const retValArray = [];
    Object.entries(searchObject).forEach(([, value]) => {
      if ((value?.length || 0) > 0) { retValArray.push(value); }
    });
    return retValArray.join(' ').trim();
  };

  const getRandomColor = () => {
    const index = Math.floor(Math.random() * CHART_COLORS.length);
    return CHART_COLORS[index];
  };

  const getAmountColor = (item) => typeColors[item.transaction_type];

  const getColorKey = (item) => {
    const usedPS = paymentSystems.find((ps) => ps.title === item.transaction_type_title);
    return item.transaction_type === TRANSACTION_TYPES.replenishment
      ? `${item.transaction_type}-${usedPS?.id || 'nf'}`
      : item.transaction_type;
  };

  const getTypeColor = (item) => typeColors[getColorKey(item)]
    || typeColors[item.transaction_type]
    || black;

  const getTypeTitle = (item) => (item.transaction_type === TRANSACTION_TYPES.replenishment
    ? item.transaction_type_title : TYPE_TITLES[item.transaction_type]);

  const getItemDescriptionWalletSection = (item) => {
    let retVal = item?.description_wallet || '';
    const currencySymbol = item.currency && item.currency_symbol ? (CURRENCY_SYMBOLS?.[item.currency] || '') : '';
    if (item.currency_symbol) {
      retVal = retVal.replace(item.currency_symbol, currencySymbol).replace(`${currencySymbol} `, `${currencySymbol}\u00A0`);
    }
    return retVal;
  };

  const selectTransactionType = (key) => {
    const newFilters = typeFilter.map((field) => (field.key === key ? (
      {
        ...field,
        isSelected: !field.isSelected,
      }
    ) : field));
    setTypeFilter(newFilters);
  };

  const handleUpdatButton = () => {
    setFinanceStatsFilter({ ...financeStatsFilter, t: new Date().getTime() });
  };

  const scrollStatsLegend = (e, isNext) => {
    e.stopPropagation();

    const currentStyle = window.getComputedStyle(statsLegend.current);
    const delta = 300;

    const r = Number.parseInt(currentStyle.right, 10);
    const l = Number.parseInt(currentStyle.left, 10);

    const param = isNext ? Math.abs(r) : Math.abs(l);
    const nextL = l + (isNext ? -1 : 1) * (param >= delta ? delta : param);
    const nextR = r + (isNext ? 1 : -1) * (param >= delta ? delta : param);

    statsLegend.current.style.left = `${nextL}px`;

    setDisabledButton((prevState) => (
      {
        ...prevState,
        stats: {
          ...prevState.stats,
          prev: nextL >= 0,
          next: nextR >= 0,
        },
      }
    ));
  };

  useEffect(() => {
    const newTypeFilter = paymentSystems.map((ps) => ({
      key: `${TRANSACTION_TYPES.replenishment}-${ps.id}`,
      color: typeColors?.[`${TRANSACTION_TYPES.replenishment}-${ps.id}`] || getRandomColor(),
      title: ps.title,
      isSelected: true,
    }));
    setTypeFilter([...INITIAL_FILTERS, ...newTypeFilter]);
    const storeColors = { ...typeColors };
    newTypeFilter.forEach((tf) => { storeColors[tf.key] = tf.color; });
    setTypeColors(storeColors);
    localStorage.setItem(TYPE_COLORS_STORAGE_KEY, JSON.stringify(storeColors));

    if (statsLegend?.current) {
      const currentStatsLegendStyle = window.getComputedStyle(statsLegend.current);
      const r = Number.parseInt(currentStatsLegendStyle.right, 10);
      const l = Number.parseInt(currentStatsLegendStyle.left, 10);
      setDisabledButton((prevState) => (
        {
          ...prevState,
          stats: {
            ...prevState.stats,
            prev: Math.abs(l) <= 0,
            next: Math.abs(r) <= 0,
          },
        }
      ));
    }
  }, [paymentSystems, statsLegend.current]);

  useEffect(() => {
    setFinanceStatsFilter((prevState) => ({ ...prevState, page: currentPage }));
  }, [currentPage]);

  useEffect(() => {
    if (isFinanceStatsProcessing) { return; }

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(setTimeout(() => {
      const newQuery = getSearchString(search);
      if (newQuery !== (financeStatsFilter?.q || '')) {
        setFinanceStatsFilter((prevState) => (
          { ...prevState, q: getSearchString(search) }
        ));
      }
    }, SEARCH_FILTER_TIMEOUT));
  }, [search]);

  useEffect(() => {
    if (typeFilterTimeout) {
      clearTimeout(typeFilterTimeout);
    }

    setTypeFilterTimeout(setTimeout(() => {
      const selectedFilter = (typeFilter || []).every((el) => el.isSelected)
        ? []
        : typeFilter
          .filter((typeEl) => typeEl.isSelected)
          .map((typeEl) => typeEl.key);

      setFinanceStatsFilter((prevState) => ({
        ...prevState,
        t_type: selectedFilter,
        page: 1,
      }));
    }, SEARCH_FILTER_TIMEOUT / 2));
  }, [typeFilter]);

  useEffect(() => {
    const currentColors = { ...typeColors };
    let isTypeColorsChanged = false;
    (financeStats?.results || []).forEach((fsi) => {
      const colorKey = getColorKey(fsi);
      if (!currentColors[colorKey]) {
        currentColors[colorKey] = getRandomColor();
        isTypeColorsChanged = true;
      }
    });
    if (isTypeColorsChanged) {
      setTypeColors(currentColors);
    }
  }, [financeStats]);

  return (
    <ExpandedBlock
      titleComponent={() => (
        <Label>
          <FinanceIcon />
          ФИНАНСЫ
        </Label>
      )}
      rightComponent={() => (
        <Actions>
          <DatePickerNew
            values={localDates}
            onSubmit={(interval) => {
              const newDates = {
                ...localDates,
                ...filterDatesFromLocal({
                  d_begin: interval?.dateStart ? format(interval.dateStart, 'yyyy-MM-dd') : '',
                  d_end: interval?.dateEnd ? format(interval.dateEnd, 'yyyy-MM-dd') : '',
                }),
              };
              setFinanceStatsFilter((prevState) => ({ ...prevState, ...newDates }));
            }}
          />
        </Actions>
      )}
    >
      <Block>
        {isFinanceStatsProcessing && <Loader />}
        <Row justify="space-between">
          <Filters ref={statsFiltersRef}>
            {((typeFilter?.length || 0) > 0) && (
              <>
                <FiltersLabel>Показать:</FiltersLabel>
                <SelectAllWrapper>
                  <CheckboxWrapper>
                    <Checkbox
                      checked={(typeFilter || []).every((el) => el.isSelected)}
                      disabled={(typeFilter || []).every((el) => el.isSelected)}
                      onChange={selectAllFilters}
                      label="Все"
                    />
                  </CheckboxWrapper>
                </SelectAllWrapper>
                <Legend>
                  <LegendInner ref={statsLegend}>
                    {typeFilter.map((filter) => (
                      <CheckboxWrapper
                        key={`${filter.key}--financeStatsTypeSelector`}
                        textColor={filter.color}
                      >
                        <Checkbox
                          checked={filter.isSelected}
                          onChange={() => selectTransactionType(filter.key)}
                          label={filter.title}
                        />
                      </CheckboxWrapper>
                    ))}
                  </LegendInner>
                </Legend>
                <Pagination>
                  <Button
                    onClick={(e) => scrollStatsLegend(e, false)}
                    disabled={disabledButton?.stats?.prev}
                  >
                    <ArrowLeftIcon />
                  </Button>
                  <Button
                    onClick={(e) => scrollStatsLegend(e, true)}
                    disabled={disabledButton?.stats?.next}
                  >
                    <ArrowRightIcon />
                  </Button>
                </Pagination>
              </>
            )}
          </Filters>
          <UpdateButton
            onClick={handleUpdatButton}
          >
            <UpdateIcon />
          </UpdateButton>
        </Row>
        <Row>
          {paymentSystems && financeStats && (
            <Table
              dataLength={financeStats?.count || 0}
              limit={50}
              page={financeStatsFilter.page}
              cellSizes={SIZES}
              textAlign="left"
              adaptiveSize={40}
            >
              <TableHead>
                <HeadItem flex>
                  Дата
                  <IconsWrapper
                    onClick={() => changeSortByDateDirection()}
                  >
                    {sorts.asc.includes(sortByDate)
                      && (
                        <IconSort isRotate>
                          <ArrowTable />
                        </IconSort>
                      )}
                    {sorts.desc.includes(sortByDate)
                      && (
                        <IconSort leftMargin top>
                          <ArrowTable />
                        </IconSort>
                      )}
                  </IconsWrapper>
                </HeadItem>
                <HeadItem>
                  Пользователь
                </HeadItem>
                <HeadItem>
                  Сумма
                </HeadItem>
                <HeadItem>
                  Тип
                </HeadItem>
                <HeadItem
                  flex
                  justify="space-between"
                >
                  <TextContainer>
                    Описание
                  </TextContainer>
                  <SearchContainer>
                    <Input
                      value={search?.query || ''}
                      onChange={({ target }) => setSearch({ ...search, query: target.value })}
                      placeholder="Поиск..."
                    />
                    <Icon top>
                      <SearchIcon />
                    </Icon>
                    {(search?.query || '').length > 0
                      && (
                        <CloseIconContainer
                          onClick={() => setSearch({ ...search, query: '' })}
                        >
                          <CloseIcon />
                        </CloseIconContainer>
                      )}
                  </SearchContainer>
                </HeadItem>
              </TableHead>
              <TableBody rowHeight={45} isBottomRowBorder customHeight="700px">
                {financeStats.results && financeStats.results.map((item) => (
                  <div key={`SF-row--${item.id}`}>
                    <RowItem column>
                      {item?.dt_create && formatDate(item.dt_create)}
                    </RowItem>
                    <RowItem color={primary} bold>
                      <StyledLink to={formatUserPageLink({ id: item?.user_id || -1 })}>
                        {item?.username || ''}
                      </StyledLink>
                    </RowItem>
                    <RowItem color={getAmountColor(item)} bold>
                      {item.amount}
                    </RowItem>
                    <RowItem color={getTypeColor(item)} bold>
                      {getTypeTitle(item)}
                    </RowItem>
                    <RowItem column justify="between">
                      <span style={{ marginRight: '.5rem' }}>{item?.description || ''}</span>
                      <span>{getItemDescriptionWalletSection(item)}</span>
                    </RowItem>
                  </div>
                ))}
              </TableBody>
              <AdminTablePagination
                page={currentPage}
                pageCount={
                  Math.ceil((financeStats?.count || 0) / (financeStatsFilter?.limit || 1))
                }
                setPage={setCurrentPage}
                recordsCount={financeStats?.count || 0}
              />
            </Table>
          )}
        </Row>
      </Block>
    </ExpandedBlock>
  );
};

export default FinanceContainer;

const Block = styled.div`
  padding: 16px 0 0;
`;

const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
  padding: 0 20px;
  justify-content: ${({ justify }) => justify};
`;

const Label = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${activeTertiary};

  svg {
    margin-right: 9px;
  }
`;
const Filters = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const FiltersLabel = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-left: 15px;
  span {
    color: ${({ textColor }) => textColor};
    font-weight: ${({ textColor }) => textColor && 700};
    white-space: pre;
  }
`;

const UpdateButton = styled.div`
  border: 1px solid ${primary};
  border-radius: 2px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const IconsWrapper = styled.div`
  display: flex;

  width: 18px;
  margin-left: 10px;
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  cursor: pointer;
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;

  cursor: pointer;
  transform: rotate(${({ isRotate }) => (isRotate ? 180 : 0)}deg);

  transition: 0.2s ease;
`;

const IconSort = styled.div`
  ${({ leftMargin }) => leftMargin && 'margin-left: 2px;'}

  position: relative;
  ${({ top }) => top && 'top: 3px;'}

  cursor: pointer;
  ${({ isRotate }) => isRotate && 'transform: rotate(-180deg);'}`;

const HeadItem = styled.div`
  ${({ flex }) => flex && `
    display: flex;
    align-items: center;

    width: 100%;
  `}
  justify-content: ${({ justify, spaceAround }) => (spaceAround ? 'space-around' : (justify || 'center'))};
`;

const TextContainer = styled.div``;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  max-width: 200px;
  padding-bottom: 4px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${secondary};
  outline: none;
`;

const RowItem = styled.div`
  ${({ flex, justify }) => flex && `
    display: flex;
    justify-content: ${justify || 'center'};

    width: 100%;
  `}
  ${({ color }) => color && `
    color: ${color};
  `}
  ${({ bold }) => bold && `
    font-weight: bold;
  `}
`;

const StyledLink = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: ${primary};
  line-height: 16px;

  &:hover,
  &:focus {
    color: ${primary};
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  width: 32px;
  height: 32px;
  margin-left: 7px;
  cursor: pointer;
  path {
    fill: ${text01}
  }
  ${({ disabled }) => disabled && `
    pointer-events: none;
    path {
      fill: ${greyText};
    }
  `}
`;

const SelectAllWrapper = styled.div`
  border-right: 1px solid ${borderSecondary};
  padding-right: 18px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  top: 0;
  left: 0;
  height: 100%;
  background: ${white};
  z-index: 1;
`;

const Legend = styled.div`
  position: relative;
  display: flex;
  width: 1017px;
  height: 100%;
  transition:left .5s linear;
  align-items: center;
  overflow-x: hidden;
`;

const LegendInner = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  transition:left .5s linear;
  align-items: center;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  right: 0;
  top: 0;
  background: ${white};
  padding-right: 15px;
`;
