import React, { useEffect } from 'react';
import styled from 'styled-components';

import HomeHeader from './components/HomeHeader';
import HomeTestCard from './components/HomeTestCard';
import HomeAdv from './components/Advantages/HomeAdv';
import HomeBeginWatch from './components/BeginWatch/HomeBeginWatch';
import HomeSubscription from './components/Subscription/HomeSubscription';
import HomeFooter from './components/Footer/HomeFooter';

import { useAppContext } from '@/AppContext';

// const PAGE_TITLE = 'Home page';
const PAGE_TITLE = '';

const Home = ({ isUserExist }) => {
  const { setPageTitle } = useAppContext();
  useEffect(() => {
    setPageTitle(PAGE_TITLE);
  }, []);
  return (
    <Container>
      <HomeHeader isUserExist={isUserExist} />
      <HomeTestCard isUserExist={isUserExist} />
      <HomeAdv />
      <HomeBeginWatch isUserExist={isUserExist} />
      <HomeSubscription isUserExist={isUserExist} />
      <HomeFooter />
    </Container>
  );
};

export default Home;

const Container = styled.div``;
