import React from 'react';
import styled from 'styled-components';
import TemplatesTable from './TemplatesTable';

const TemplatesList = () => (
  <Container>
    <TemplatesTable />
  </Container>
);

export default TemplatesList;

const Container = styled.div``;
