import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  primary,
  white,
  text01,
} from '@/common/constants/theme';

import Button from '@/common/components/Button';

import { useAppContext } from '@/AppContext';

const BUTTON_URL = '/personal-page/support';

const HomeBeginWatchAsk = ({ isUserExists }) => {
  const { t } = useTranslation('translations');
  const history = useHistory();

  const { getFromPublicLink } = useAppContext();

  const handleButtonClick = () => {
    history.push(getFromPublicLink(BUTTON_URL, isUserExists, { createThread: true }));
  };

  return (
    <Container>
      <Text>{t('homePage.beginWatch.askExpert.title')}</Text>
      <StyledButton
        isBoldText
        isUpperCase
        onClick={handleButtonClick}
      >
        {t(`homePage.beginWatch.askExpert.${isUserExists ? 'btnExists' : 'btn'}`)}
      </StyledButton>
    </Container>
  );
};

export default HomeBeginWatchAsk;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 540px;
  margin-right: 20px;
  padding: 37px 54px 35px 39px;

  background: ${white};
  border: 1px solid ${primary};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Text = styled.div`
  margin-right: 24px;

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;

  width: 184px;
`;
