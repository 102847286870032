export const ENDINGS = ['one', 'two', 'many'];

const getWordDeclension = (count, wordEndings) => {
  if (count % 100 >= 11 && count % 100 <= 19) {
    return wordEndings[2];
  }

  switch (count % 10) {
    case (1):
      return wordEndings[0];
    case (2):
    case (3):
    case (4):
      return wordEndings[1];
    default:
      return wordEndings[2];
  }
};

export const humanizeDaysDuration = (daysCount = 0) => {
  const yearDuration = 365;
  const monthDuration = 30;

  const years = Math.floor(daysCount / yearDuration);
  let days = daysCount - years * yearDuration;
  const months = Math.floor(days / monthDuration);
  days -= months * monthDuration;

  return {
    years, months, days,
  };
};

export default getWordDeclension;
