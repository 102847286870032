export const sanitizeFloat = (value) => {
  const arr = (value || '').toString()
    .replace(/[^\d+-.,]/gi, '')
    .replace(/(\d+)(\+|-)+(\d+)/, '$2$3')
    .replace(/(\+|-)+/, '$1')
    .replace(/^(\+|-)(\d+)(\+|-)*/, '$1$2')
    .replace(/^(\d+)(\+|-)*/, '$1')
    .replace(/(\.)+/, '$1')
    .replace(',', '.')
    .split('.');
  let preparedValue = '';
  if (arr.length < 3) {
    preparedValue = arr.join('.');
  } else {
    preparedValue = [arr.slice(0, 2).join('.'), arr.slice(2)].join('');
  }
  return preparedValue;
};
