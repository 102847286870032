import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  bindDeviceToDnsAction,
  unbindDeviceToDnsAction,
  setPlaylistType,
} from '@/services/actions/devices.actions';
import { bindDeviceToServerAction } from '@/services/actions/server.actions';
import {
  subscribeTypes,
  getRightDevice,
  getSelectedDevices,
  setRightDevice, setSnack,
  updateStoredDevice,
} from '@/store/user/selectors';

import { baseUrl } from '@/services/request.service';

import { usePersonalPageContext } from '../../../PersonalPageContext';

const allowedSubscriptionTypes = [subscribeTypes.Active, subscribeTypes.Ending];

export const PlaylistContext = createContext({});

export const PlaylistContextProvider = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(0);
  const [selectedDns, setSelectedDns] = useState(null);
  const [selectedDnsId, setSelectedDnsId] = useState(0);
  const [devicesDropdown, setDevicesDropdown] = useState([]);

  const storedSelectedDevices = useSelector(getSelectedDevices);
  const storedRightDevice = useSelector(getRightDevice);

  const { allDevices, allDns } = usePersonalPageContext();

  const changeSelectedDevice = (device) => {
    setSelectedDevice(device);
    const useDns = (allDns || []).find((d) => d.id === (device.dns || 0)) || null;
    setSelectedDns(useDns);
    setSelectedDnsId(useDns?.id || 0);
    dispatch(setRightDevice(device));
  };

  const changeSelectedDeviceById = (deviceId) => {
    setSelectedDeviceId(deviceId);
  };

  const bindDeviceToServer = (deviceId, serverId) => {
    bindDeviceToServerAction(deviceId, serverId);
    dispatch(updateStoredDevice(selectedDeviceId, { server: serverId }));
  };

  const changeSelectedDns = (dnsId) => {
    const dns = (allDns || []).find((item) => (item.id === dnsId));
    if (dnsId === 0 && selectedDns.id !== 0) {
      unbindDeviceToDnsAction(selectedDeviceId);
      dispatch(updateStoredDevice(selectedDeviceId, { dns: null }));
    } else {
      bindDeviceToDnsAction(selectedDeviceId, dnsId);
      dispatch(updateStoredDevice(selectedDeviceId, { dns: dnsId }));
    }
    if (dnsId !== 0 && dns.serverId !== selectedDns.serverId) {
      bindDeviceToServer(selectedDeviceId, dns.serverId);
    }
    setSelectedDnsId(dnsId);
  };

  const setDevicePlaylistType = (
    deviceId, playlistTypeId,
  ) => setPlaylistType(deviceId, playlistTypeId)
    .then(() => dispatch(updateStoredDevice(deviceId, { playlist_type: playlistTypeId })))
    .catch((err) => dispatch(setSnack(err)));

  useEffect(() => {
    if (storedRightDevice) {
      setSelectedDevice(storedRightDevice);
    }
  }, [storedRightDevice]);

  useEffect(() => {
    if ((allDns || []).length > 0) {
      setSelectedDns(allDns.find((item) => (item.id === selectedDnsId)) || allDns[0]);
    }
  }, [allDns]);

  useEffect(() => {
    setDevices(
      (allDevices || [])
        .filter((d) => !!d.server)
        .filter((d) => allowedSubscriptionTypes.includes(d.subscription_type)),
    );
  }, [allDevices]);

  useEffect(() => {
    if ((devices || []).length > 0) {
      let useDevice;
      if ((history.location.hash || '').length > 0) {
        const useaID = parseInt(history.location.hash.replace('#', ''), 10);
        history.push(history.location.pathname);
        useDevice = devices.find((d) => d.id === useaID);
      } else {
        useDevice = devices.find((d) => d.id === selectedDevice?.id)
          || storedSelectedDevices[0]
          || devices[0];
      }
      if (useDevice) {
        setSelectedDeviceId(useDevice.id);
        changeSelectedDevice(useDevice);
      }
    }
  }, [devices, history.location.hash]);

  const value = {
    baseUrl,
    allDns,
    devices,
    selectedDevice,
    setSelectedDevice,
    devicesDropdown,
    setDevicesDropdown,
    selectedDns,
    changeSelectedDns,
    selectedDnsId,
    setSelectedDnsId,
    changeSelectedDevice,
    changeSelectedDeviceById,
    bindDeviceToServer,
    setDevicePlaylistType,
  };
  return (
    <PlaylistContext.Provider value={value}>
      {children}
    </PlaylistContext.Provider>
  );
};

export const usePlaylistContext = () => useContext(PlaylistContext);
