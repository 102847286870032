import React from 'react';

function findTableEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null
        && contentState.getEntity(entityKey).getType() === 'TABLE'
      );
    },
    callback,
  );
}

const Table = ({ contentState, entityKey, children }) => {
  const parameters = contentState.getEntity(entityKey).getData();

  const getColumnsArray = (rowIndex) => {
    const columns = [];
    const colsCount = parseInt(parameters.cols.value, 10) || 1;
    const rowsCount = parseInt(parameters.rows.value, 10) || 1;
    for (let ic = 0; ic < colsCount; ic += 1) {
      const key = `row-${rowIndex}--col-${ic}`;
      columns.push((ic === Math.floor(colsCount / 2))
      && (rowIndex === Math.floor(rowsCount / 2))
        ? (<td key={key}>{children || ''}</td>)
        : (<td key={key}>{' '}</td>));
    }
    return columns;
  };

  const getRowsArray = () => {
    const rows = [];
    const rowsCount = parseInt(parameters.rows.value, 10) || 1;
    for (let ir = 0; ir < rowsCount; ir += 1) {
      const key = `row-${ir}`;
      rows.push(<tr key={key}>{getColumnsArray(ir)}</tr>);
    }
    return rows;
  };

  return (
    <table>
      <tbody>
        {getRowsArray()}
      </tbody>
    </table>
  );
};

export default {
  strategy: findTableEntities,
  component: Table,
};
