import React from 'react';

import styled from 'styled-components';

import StepsIndicator from '../../StepsIndicator';
import TopBanner from './components/TopBanner';
import StalkerDetails from './components/StalkerDetails';
import PlaylistDetails from './components/PlaylistDetails';
import Footer from './components/Footer';

import { SHOW_RIGHT_PANEL, usePersonalPageContext } from '../../../PersonalPageContext';
import { usePlaylistContext } from './PlaylistContext';

const PAGE_ID = 'playlist';

const Playlist = () => {
  const {
    selectedDevice,
  } = usePlaylistContext();

  const { isPageHeaderDisabled, handleClosePageHeader } = usePersonalPageContext();

  return (
    <Container>
      <StepsIndicator selectedDevice={selectedDevice} />
      {!isPageHeaderDisabled?.[PAGE_ID] && (
        <TopBanner
          isFullWidth={!SHOW_RIGHT_PANEL}
          closeHandler={() => handleClosePageHeader(PAGE_ID)}
          type={selectedDevice?.view_type}
        />
      )}
      {selectedDevice && (
        <DeviceContainer>
          {selectedDevice?.view_type === 'portal' ? (
            <StalkerDetails isFullWidth={!SHOW_RIGHT_PANEL} />
          ) : (
            <PlaylistDetails isFullWidth={!SHOW_RIGHT_PANEL} />
          )}
        </DeviceContainer>
      )}
      <Footer />
    </Container>
  );
};

export default Playlist;

const Container = styled.div`
  margin-bottom: 5rem;
  min-height: 600px;
`;

const DeviceContainer = styled.div`
  margin-top: 2.5rem;
`;
