import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { text02, white } from '@/common/constants/theme';

import TableHeader from './components/TableHeader';
import DeviceTableFilters from './components/DeviceTableFilters';
import DeviceTableRow from './components/DeviceTableRow';
import TablePagination from './components/TablePagination';
import AddModal from './components/DeviceAddModal';
import DeviceAutoRenewDialog from './components/DeviceAutoRenewDialog';
import DeviceConfigureDialog from './components/DeviceConfigureDialog';
import DeviceDeleteModal from './components/DeviceDeleteModal';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import Loader from '@/common/components/Loader';

import {
  SHOW_RIGHT_PANEL,
  SHOW_SUBSCRIBE_PREMIUM_CONTROLS,
  usePersonalPageContext,
} from '../../../../PersonalPageContext';

import {
  subscribeTypes,
  createDeviceRedux,
  deleteDeviceRedux,
} from '@/store/user/selectors';

const USE_PAGE_SIZES = [8];
const DEFAULT_PLAYLIST_TYPE = 'm3u';

const SYNTH_SERVERS = {
  cdn: null,
  last: null,
  select: null,
};

const Table = ({ openEqSubscribeDialog }) => {
  const { t } = useTranslation('translations');

  const {
    allDevices,
    allDns,
    playlistOses,
    filters,
    setFilters,
    isDevicesLoading,
    selectedDevices,
    // servers,
    serversFullData,
    editDevice,
    editDeviceServer,
    handleSelectDevices,
    autoRenewDevice,
  } = usePersonalPageContext();

  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [error, setError] = useState(false);
  const [isFiltersOpen, setFiltersOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const [synthServers, setSynthServers] = useState(null);

  const [selectedDevicesIds, setSelectedDevicesIds] = useState([]);
  const [allPlaylistTypes, setAllPlaylistTypes] = useState(null);

  const dispatch = useDispatch();

  const CELL_SIZES = SHOW_RIGHT_PANEL ? [
    1, 16, 11.5, 12.5, 12,
  ] : [
    2, 27, 17.5, 17.5, 12,
  ];

  useEffect(() => {
    if (allDevices) {
      setFilteredDevices((allDevices || [])
        .filter((d) => {
          let visible = true;
          if (filters.search
            && !d.name.toLowerCase().match(filters.search.toLowerCase())) {
            visible = false;
          }
          if (visible && filters.server_id
            && filters.server_id !== subscribeTypes.All
            && d.server !== filters.server_id) {
            visible = false;
          }
          if (visible && filters.subscription_type
            && filters.subscription_type !== subscribeTypes.All
            && d.subscription_type !== filters.subscription_type) {
            visible = false;
          }
          return visible;
        }));
    }
  }, [allDevices, filters]);

  useEffect(() => {
    const currentPage = filters?.page || 1;
    const limit = filters?.limit || 8;
    setDevices((filteredDevices || [])
      .slice((currentPage - 1) * limit, currentPage * limit));
  }, [filteredDevices]);

  useEffect(() => {
    setSelectedDevicesIds((selectedDevices || []).map((sd) => sd.id));
  }, [selectedDevices]);

  useEffect(() => {
    if ((playlistOses || []).length > 0) {
      const players = [];
      playlistOses.forEach((os) => (os.playlist_types || []).forEach((pt) => {
        if (!players[pt.id]) {
          players[pt.id] = pt;
        }
      }));
      setAllPlaylistTypes(players);
    }
  }, [playlistOses]);

  useEffect(() => {
    const useServers = serversFullData || [];
    if (((allDevices || []).length > 0) && (useServers.length > 0)) {
      const useSynthServers = { ...SYNTH_SERVERS };
      useSynthServers.select = { id: -1, name: t('devicesContent.serverSelectByTest') };
      const autoServer = useServers.find((s) => s.is_virtual);
      const deviceUsedServers = allDevices
        .filter((d) => (!!d.server && (d.server !== (autoServer?.id || -1))))
        .sort((a, b) => {
          const aT = Date.parse(a.modified_date || a.created_date);
          const bT = Date.parse(b.modified_date || b.created_date);
          return bT - aT;
        })
        .map((d) => d.server);
      const lastDeviceServer = useServers.find((s) => s.id === (deviceUsedServers?.[0] || -1));

      if (autoServer || lastDeviceServer) {
        if (autoServer) {
          useSynthServers.cdn = {
            ...autoServer,
            name: t('devicesContent.serverSelectedCDN'),
          };
        }
        if (lastDeviceServer
          && (lastDeviceServer.id !== ((autoServer || {}).id || -1))) {
          useSynthServers.last = {
            ...lastDeviceServer,
            name: t('devicesContent.serverSelectedByTest'),
          };
        }
        setSynthServers(useSynthServers);
      }
    }
  }, [serversFullData, allDevices]);

  const handleFilters = () => {
    setFiltersOpen(!isFiltersOpen);
  };

  const addDevice = (name, description) => {
    const currentPage = filters?.page || 1;
    const limit = (filters?.limit || 8);
    const needPageChange = limit - 1 < (devices.length);
    const neededPage = Math.ceil((allDevices.length + 1) / limit);
    const useFilter = { ...filters, page: needPageChange ? neededPage : currentPage };

    dispatch(createDeviceRedux(name, description));

    if (needPageChange) {
      setFilters(useFilter);
    }
  };

  const removeDevice = (device) => {
    const currentPage = filters?.page || 1;
    const limit = (filters?.limit || 8);
    const lastPage = Math.ceil((allDevices.length - 1) / limit);
    const needPageChange = lastPage !== currentPage;
    const neededPage = currentPage > lastPage ? lastPage : currentPage;
    const useFilter = { ...filters, page: needPageChange ? neededPage : currentPage };

    dispatch(deleteDeviceRedux(device));

    if (needPageChange) {
      setFilters(useFilter);
    }
  };

  return (
    <Container>
      <TableHeader
        cellSizes={CELL_SIZES}
        handleFilters={handleFilters}
        toggleCreateModal={() => setCreateModalOpen(true)}
        handleSelectDevices={handleSelectDevices}
        allSelected={(selectedDevicesIds.length > 0) && (devices.filter(
          (d) => selectedDevicesIds.includes(d.id),
        ).length === devices.length)}
        openEqSubscribeDialog={openEqSubscribeDialog}
        openUnpairDialog={() => {
          setDialogData({ type: 'appStatus' });
        }}
      />
      {isFiltersOpen && (
        <DeviceTableFilters
          cellSizes={CELL_SIZES}
          setFilters={setFilters}
          filters={filters}
        />
      )}
      <TableBody>
        {(isDevicesLoading || !allDns) && <Loader />}
        {allDns && allPlaylistTypes && devices.map((device) => (
          <DeviceTableRow
            cellSizes={CELL_SIZES}
            useDns={(allDns || []).find((d) => d.id === (device.dns || 0))}
            usePlayer={allPlaylistTypes.find((plt) => device.playlist_type === plt?.id)
              || allPlaylistTypes.find((plt) => plt?.format_name === DEFAULT_PLAYLIST_TYPE)}
            key={device.id}
            device={device}
            selected={selectedDevicesIds.includes(device.id)}
            selectDisabled={allDevices.length <= 1}
            canBeDeleted={(allDevices.length > 1) && (!device.subscription)}
            editDevice={editDevice}
            editDeviceServer={editDeviceServer}
            toggleModalDialog={(dataAndType) => setDialogData(dataAndType)}
            syntheticServers={synthServers}
            showPremium={SHOW_SUBSCRIBE_PREMIUM_CONTROLS}
            // servers={servers}
          />
        ))}
        {!filteredDevices.length && (
          <NoData>{t('table.noResults')}</NoData>
        )}
      </TableBody>
      {filteredDevices.length > USE_PAGE_SIZES[0] && (
        <TablePagination
          quantity={(filteredDevices || []).length}
          setFilters={setFilters}
          filters={filters}
          customPageSizes={USE_PAGE_SIZES}
        />
      )}
      <AddModal
        isVisible={isCreateModalOpen}
        toggleVisibility={() => setCreateModalOpen(false)}
        onSubmit={addDevice}
      />
      {dialogData.device && (
        <div>
          {dialogData.device.subscription && (
            <DeviceAutoRenewDialog
              isVisible={dialogData.type === 'autoRenew'}
              dialogData={dialogData}
              onSubmit={() => autoRenewDevice(dialogData.device)}
              toggleVisibility={() => setDialogData({})}
            />
          )}
          <DeviceDeleteModal
            isVisible={dialogData.type === 'delete'}
            dialogData={dialogData}
            onSubmit={() => removeDevice(dialogData.device)}
            toggleVisibility={() => setDialogData({})}
          />
        </div>
      )}
      <DeviceConfigureDialog
        isVisible={dialogData.type === 'appStatus'}
        dialogData={dialogData}
        toggleVisibility={() => setDialogData({})}
      />
      {error && (
        <SnackBarWindow
          type="error"
          isOpen={error}
          onClose={() => setError(false)}
          content={error}
        />
      )}
    </Container>
  );
};

export default Table;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
`;

const TableBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${white};
  height: 36rem;
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 15px;
  letter-spacing: 0.16px;
  color: ${text02};
`;
