/* eslint-disable no-shadow */
import React, {
  createContext,
  useState,
  useContext,
} from 'react';

import {
  getNotificationsAction,
  readNotificationsAction,
  getPersonalNotificationsAction,
  readPersonalNotificationsAction,
  readAllNotificationsAction,
  readAllPersonalNotificationsAction,
} from '@/services/actions/news.actions';

const NotificationsContext = createContext({});

export const NotificationsContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [personalNotifications, setPersonalNotifications] = useState([]);

  const allNotifications = notifications
    .concat(personalNotifications)
    .sort((x) => x.date); // (a, b) => (a.date - b.date));

  const getNotifications = () => {
    getNotificationsAction()
      .then((res) => {
        setNotifications(res.results);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));

    getPersonalNotificationsAction()
      .then((res) => {
        setPersonalNotifications(res.results);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const readNotification = (id) => {
    readNotificationsAction(id).then()
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
    setNotifications(notifications.filter((x) => x.id !== id));
  };

  const readPersonalNotification = (id) => {
    readPersonalNotificationsAction(id).then()
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
    setPersonalNotifications(personalNotifications.filter((x) => x.id !== id));
  };

  const closeNotification = (item) => {
    if (item.user == null) {
      readNotification(item.id);
    } else {
      readPersonalNotification(item.id);
    }
  };

  const closeAllNotifications = () => {
    readAllNotificationsAction().then()
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
    setNotifications([]);

    readAllPersonalNotificationsAction().then()
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
    setPersonalNotifications([]);
  };

  const value = {
    getNotifications,
    allNotifications,
    closeNotification,
    closeAllNotifications,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => useContext(NotificationsContext);
