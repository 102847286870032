import React from 'react';
import styled from 'styled-components';

import { white } from '@/common/constants/theme';
// eslint-disable-next-line
import confirmIconSrc from '@/assets/icons/confirmIcon.svg';

import ModalWithoutContent from '@/common/components/ModalWithoutContent';
import Button from '@/common/components/Button';

const MinistraSyncModal = ({
  isOpen,
  onClose,
  content,
  titleContent,
  submitContent,
  onSubmit,
}) => (
  <ModalWithoutContent
    icon={confirmIconSrc}
    isOpen={isOpen}
    onCloseModal={onClose}
  >
    <Container>
      <Text upperCase bottomMargin>
        {titleContent}
      </Text>
      <Text>
        {content}
      </Text>
      <StyledButton isBoldText isUpperCase onClick={onSubmit}>
        {submitContent}
      </StyledButton>
    </Container>
  </ModalWithoutContent>
);

export default MinistraSyncModal;

const Container = styled.div``;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 10px;')}

  font-family: Gilroy;
  font-size: 16px;
  color: ${white};
  ${({ upperCase }) => upperCase && ('text-transform: uppercase;')}
  letter-spacing: 0.16px;
  line-height: 20px;
`;

const StyledButton = styled(Button)`
  width: 180px;
  margin-top: 35px;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
