/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  background01,
  borderSecondary,
  secondaryHover,
  text01,
} from '../../../constants/theme';

import CloseIcon from '@/common/components/icons/CloseIcon';
import TableFilters from './TableFilters';
import Input from '../../Input';
import SearchIcon from '../../../../screens/PersonalPageScreen/components/icons/SearchIcon';
import EllipsesListIcon from '../../../../screens/PersonalPageScreen/components/icons/EllipsesListIcon';

const TableHead = ({
  children,
  isFilters = false,
  isSearch = false,
  searchValue,
  searchHandler,
  resetHandler,
  isClearFiltersBtn = false,
  cellSizes,
  cellMargin,
  textAlign = 'center',
  CustomFilters,
  filtersTitle,
  adaptiveSize,
  onFiltersOpen,
  onFiltersClose,
  rowPadding,
}) => {
  const { t } = useTranslation('translations');

  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);

  const inputRef = useRef(null);

  const inputIconHandler = () => {
    searchHandler('');
    setIsSearchOpened((prevState) => !prevState);
    if (inputRef.current) inputRef.current.focus();
  };

  const toggleFilterDialog = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsFiltersOpened((prevState) => !(prevState || false));
  };

  useEffect(() => {
    if (isFiltersOpened) {
      if (onFiltersOpen) { onFiltersOpen(); }
    } else if (onFiltersClose) { onFiltersClose(); }
  }, [isFiltersOpened]);

  const onClose = () => {
    setIsFiltersOpened(false);
  };

  return (
    <Container className="table-main-head" rowPadding={rowPadding}>
      <Main>
        {React.Children.map(children, (element, i) => (
          <Wrapper
            className="table-head"
            width={cellSizes && cellSizes[i]}
            cellMargin={cellMargin}
            textAlign={textAlign}
            adaptive={adaptiveSize}
          >
            {element}
          </Wrapper>
        ))}
      </Main>

      {(isFilters || isSearch) && (
        <Filters>
          {isSearch && (
            <SearchContainer
              isActive={isSearchOpened}
            >
              <StyledInput
                value={searchValue}
                onChange={(event) => isSearchOpened
                  && searchHandler(event.target.value)}
                placeholder={t('table.logsInputPlaceholder')}
                ref={inputRef}
              />
              {((searchValue || '').length > 0) && (
                <CloseButton
                  onClick={() => searchHandler('')}
                >
                  <CloseIcon />
                </CloseButton>
              )}
            </SearchContainer>
          )}
          <IconsWrapper>
            {isSearch && (
              <Icon
                rightMargin
                onClick={inputIconHandler}
              >
                <SearchIcon />
              </Icon>
            )}
            {isFiltersOpened && isFilters && CustomFilters && (
              <TableFilters
                onClose={() => onClose()}
                filtersTitle={filtersTitle}
                resetHandler={resetHandler}
                isClearFiltersBtn={isClearFiltersBtn}
              >
                {CustomFilters}
              </TableFilters>
            )}
            {isFilters && (
              <Icon
                className="header-filter-trigger"
                onMouseDown={(e) => toggleFilterDialog(e)}
              >
                <EllipsesListIcon />
              </Icon>
            )}
          </IconsWrapper>
        </Filters>
      )}
    </Container>
  );
};

export default TableHead;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 53px;
  padding-left: ${({ rowPadding }) => (rowPadding || 23)}px;
  padding-right: ${({ rowPadding }) => (rowPadding || 20)}px;

  position: relative;
  z-index: 2;

  background: ${background01};
  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
`;

const Main = styled.div`
  display: flex;

  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ textAlign }) => (textAlign
  === 'left' ? 'flex-start' : textAlign === 'right'
      ? 'flex-end' : 'center')};
  align-items: center;

  ${({ width }) => width && (`width: ${width};`)};

  position: relative;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${text01};
   text-align: ${({ textAlign }) => textAlign};
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-left: ${({ cellMargin }) => (cellMargin || 30)}px;

    &::after {
      content: '';

      width: 1px;
      height: 30px;

      position: absolute;
      left: -${({ cellMargin }) => ((cellMargin || 30) / 2)}px;
      bottom: -5px;

      background-color: ${borderSecondary};
    }
  }

  @media (max-width: 1360px) {
    ${({ width, adaptive }) => width && (`width: calc(${width} - ${adaptive || 10}px);`)};

    font-size: 11px;
  }

  @media (max-width: 1198px) {
    ${({ width }) => width && (`width: ${width};`)};

    font-size: 14px;
  }
`;

const Filters = styled.div`
  display: flex;
`;

const IconsWrapper = styled.div`
  display: flex;

  margin-top: 5px;
`;

const Icon = styled.div`
  ${({ rightMargin }) => rightMargin && (`
    margin-right: 35px;

    &::before {
      content: '';

      width: 1px;
      height: 30px;

      position: absolute;
      right: -11px;
      bottom: 0;

      background-color: ${borderSecondary};
    }
  `)};

  position: relative;

  cursor: pointer;
`;

const SearchContainer = styled.div`
  position: relative;
  width: ${({ isActive }) => (isActive ? '180px;' : '0;')};
  padding-left: ${({ isActive }) => (isActive ? '8px;' : '0')};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0.5rem;
`;

const StyledInput = styled(Input)`
  margin-right: 7px;

  & input {
    height: 30px;
    padding: 8px;
    padding-right: 0;

    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${secondaryHover};
    border-radius: 0;

    transition: 0.3s ease-in-out;
  }
`;
