import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  white, grey, green,
  primary, secondary,
  text01, text03, text04,
  activePrimary,
  borderSecondary,
} from '../constants/theme';
import ArrowLeft from './icons/ArrowLeft';
import OkIcon from './icons/OkIcon';
import Checkbox from './Checkbox';
import CloseIcon from '../../assets/icons/close.svg';

export default function DropdownCheckBoxed({
  value,
  defaultValue = {},
  options,
  onSelect,
  showSearch = false,
  placeholder = 'Выберите опции',
  allowClear = false,
  isUpperCase = false,
  color,
  noResultsText,
  isBoldText,
  height,
  optionHeight,
  className,
  menuClassName,
  isCapitalize = true,
  isDisabled,
  onSubmit,
}) {
  const { t } = useTranslation(['translations']);
  const [isOpen, setStateOpen] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [query, setQuery] = useState('');
  const [isSearchMode, setSearchMode] = useState(false);
  const [isHoverIcon, setHoverIcon] = useState(false);
  const [initValues] = useState(value || []);
  const [selectedValues, setValues] = useState(value || []);

  const setOpen = (val) => {
    if (!isDisabled) {
      setStateOpen(val);
    }
  };

  useEffect(() => {
    const setVal = value || defaultValue;
    setValues((Array.isArray(setVal) ? setVal : [setVal]));
  }, [value]);

  useEffect(() => {
    const sv = selectedValues.map((o) => o.key);
    const iv = initValues.map((o) => o.key);
    const newlySelectedValues = sv.filter((v) => !iv.includes(v));
    const deSelectedValues = iv.filter((v) => !sv.includes(v));
    const isValuesChanged = newlySelectedValues.length > 0 || deSelectedValues.length > 0;
    setIsChanged(isValuesChanged);
  }, [selectedValues]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          setOpen(false);
          setQuery('');
          setSearchMode(false);
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onSelectValue = (object) => {
    const otherSelected = selectedValues.filter((v) => v.key !== object.key);
    const setVal = (selectedValues.length === 0) || (otherSelected.length === selectedValues.length)
      ? [...otherSelected, object]
      : otherSelected;
    setValues(setVal);
    if (onSelect) {
      onSelect(setVal);
    }
    // setOpen(false);
    // setQuery('');
    // setSearchMode(false);
  };

  const onFocusInput = () => {
    setSearchMode(true);
    setOpen(true);
  };

  const clearValue = () => {
    setValues([]);
    if (onSelect) {
      onSelect([]);
    }
    setHoverIcon(false);
  };

  const optionsForRender = options.filter((option) => (
    option.title.toLowerCase().includes(query.toLowerCase())
  ));

  const onChangeInput = (event) => {
    if (event.key === 'Enter') {
      onSelectValue([optionsForRender[0]]);
      setSearchMode(false);
      document.getElementById('input').blur();
    }
  };

  return (
    <Container className={className}>
      <FlexContainer ref={wrapperRef}>
        <InputWrapper
          onClick={!showSearch && (() => setOpen(!isOpen))}
          showSearch={showSearch}
          isMenuOpen={isOpen}
          onMouseEnter={() => selectedValues.length > 0 && allowClear && setHoverIcon(true)}
          onMouseLeave={() => selectedValues.length > 0 && allowClear && setHoverIcon(false)}
          color={color}
          height={height}
        >
          <Input
            id="input"
            /* eslint-disable-next-line no-nested-ternary */
            value={isSearchMode
              ? query
              // eslint-disable-next-line no-nested-ternary
              : ((selectedValues.length > 0)
                ? ((selectedValues.length > 1) ? `${selectedValues[0].title}... (${selectedValues.length})` : selectedValues[0].title)
                : '')}
            placeholder={placeholder}
            disabled={!showSearch}
            onFocus={onFocusInput}
            onChange={({ target }) => setQuery(target.value)}
            onKeyPress={onChangeInput}
            isUpperCase={isUpperCase}
            isBoldText={isBoldText}
            color={color}
            isCapitalize={isCapitalize}
          />
          <ActionIcon>
            {isHoverIcon ? (
              <Icon
                src={CloseIcon}
                onClick={clearValue}
              />
            ) : (
              <ArrowIcon
                onClick={() => setOpen(!isOpen)}
                isOpen={isOpen}
                color={color}
              >
                <ArrowLeft />
              </ArrowIcon>
            )}
          </ActionIcon>
        </InputWrapper>
        <SubmitSelected
          disabled={!isChanged}
          onClick={() => {
            if (!isChanged) { return; }
            if (onSubmit) {
              onSubmit(selectedValues);
            }
            setOpen(false);
            setQuery('');
            setSearchMode(false);
          }}
        >
          <OkIcon />
        </SubmitSelected>
        {isOpen && (
          <Wrapper className={menuClassName || ''}>
            <SelectorMenu>
              {optionsForRender.length ? (
                optionsForRender.map((option) => (
                  <Option
                    key={option.key}
                    // selected={selectedValues.filter((v) => v.key === option.key).length > 0}
                    onClick={() => onSelectValue(option)}
                    height={optionHeight}
                  >
                    <Checkbox
                      checked={selectedValues.filter((v) => v.key === option.key).length > 0}
                      label={option.title || option.value}
                      onChange={() => onSelectValue(option)}
                    />
                  </Option>
                ))
              ) : (
                <NoData>{noResultsText || t('table.noResults')}</NoData>
              )}
            </SelectorMenu>
          </Wrapper>
        )}
      </FlexContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
`;

const ActionIcon = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  padding-right: 20px;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(100% + 7px);
  width: 100%;
`;

const InputWrapper = styled.div`
  background-color: ${white};
  border: 1px solid #ECECEC;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 4px 11px;
  display: flex;
  align-items: center;
  width: 100%;
  // height: ${({ height }) => height || '28px'};
  ${({ height }) => height && `height: ${height}px;`};
  cursor: ${({ showSearch }) => (showSearch ? 'text' : 'pointer')};
  :hover {
    border-color: ${({ color }) => color || secondary};
  }
  :focus {
    border-color: ${({ color }) => color || secondary};
    outline: none;
  }
  :active {
    border-color: ${({ color }) => color || secondary};
  }
  ${({ isMenuOpen }) => isMenuOpen && `
    border-color: ${({ color }) => color || secondary};
  `}
`;

const SubmitSelected = styled.div`
  position: absolute;
  z-index: 10;
  top: 4px;
  right: 0;
  padding: 0 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  & svg{
    fill: ${({ disabled }) => (disabled ? grey : green)};
  }
`;

const Input = styled.input`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  letter-spacing: .16px;
  outline: none;
  border: none;
  width: 100%;
  padding-right: 11px;
  background: ${white};
  color: ${({ color }) => color || text01};
  font-weight: ${({ isBoldText }) => (isBoldText ? 600 : 400)};
  text-transform: ${({ isUpperCase, isCapitalize }) => (isUpperCase ? 'uppercase' : isCapitalize && 'capitalize')};
  :disabled {
    background: ${white};
    cursor: pointer;
  }
  ::placeholder {
    font-family: Gilroy, sans-serif;
    font-size: 13px;
    letter-spacing: .16px;
    color: ${text03};
    text-transform: ${({ isCapitalize }) => isCapitalize && 'capitalize'};
    font-weight: 400;
  }
`;

const Icon = styled.img`
  cursor: pointer;
  transform: rotate(-90deg);
  transition: transform .25s;
  ${({ isOpen }) => isOpen && `
    transform: rotate(90deg);
  `}
`;

const ArrowIcon = styled.div`
  cursor: pointer;
  transform: rotate(-90deg);
  transition: transform .25s;
  ${({ isOpen }) => isOpen && `
  transform: rotate(90deg);
  `}
  path {
    fill: ${({ color }) => color || text01};
  }
`;

const SelectorMenu = styled.div`
  height: 100%;
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.11));
  background: ${white};
  display: flex;
  flex-direction: column;
  padding: .5rem 0;
  max-height: 15rem;
  overflow: auto;
`;

const Option = styled.div`
  padding: .25rem 1rem;
  transition: color .1s, background-color .1s;
  cursor: pointer;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  text-transform: none;
  ${({ height }) => height && (`
    height: ${height};
    flex-shrink: 0;
    display: flex;
    align-items: center;
  `)}
  :hover {
    color: ${white};
    background: ${activePrimary};
  }
  ${({ selected }) => selected && `
    color: ${white};
    background: ${primary};
  `}
`;

const NoData = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: Gilroy, sans-serif;
  line-height: 20px;
  letter-spacing: .16px;
  color: ${text04};
`;
