/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import {
  red, secondary, text01, white, green,
} from '../constants/theme';

import { isNumber } from 'lodash';

const COLORS = {
  normal: {
    border: secondary,
    background: white,
    color: text01,
  },
  caution: {
    border: red,
    background: white,
    color: text01,
  },
  success: {
    border: green,
    background: green,
    color: white,
  },
  successWithoutBg: {
    border: white,
    background: green,
    color: white,
  },
};

export default ({
  type = 'normal',
  position = 'left',
  verticalPosition = 'top',
  className,
  title,
  children,
  trigger = 'hover',
  width = 240,
  isDisabled = false,
  stopPropagation,
  displayType = 'div',
  contentContainerStyle = {},
  ...props
}) => {
  const [isHover, setHover] = useState(false);
  const [isClick, setClick] = useState(false);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          if (stopPropagation) {
            event.stopPropagation();
          }
          setClick(false);
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Container displayType={displayType} style={contentContainerStyle}>
      <TrigeredComponent
        isDisabled={isDisabled}
        displayType={displayType}
        onPointerEnter={trigger === 'hover' ? (() => setHover(true)) : () => {}}
        onPointerLeave={trigger === 'hover' ? (() => setHover(false)) : () => {}}
        onMouseEnter={trigger === 'hover' ? (() => setHover(true)) : () => {}}
        onMouseLeave={trigger === 'hover' ? (() => setHover(false)) : () => {}}
        onClick={trigger === 'click' ? ((e) => {
          setClick(true);
          if (stopPropagation) {
            e.stopPropagation();
          }
        }) : () => {}}
      >
        {children}
      </TrigeredComponent>
      {(isHover || isClick) && (
      <TooltipBox
        ref={wrapperRef}
        type={type}
        position={position}
        verticalPosition={verticalPosition}
        className={className}
        width={width}
        isDisabled={isDisabled}
        {...props}
      >
        {title}
      </TooltipBox>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: fit-content;
  display: ${({ displayType }) => (displayType || 'block')};
  align-items: ${({ alignItems }) => (alignItems || 'center')};
`;

const TrigeredComponent = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
`;

const TooltipBox = styled.div`
  display: ${({ isDisabled }) => (isDisabled ? 'none' : 'block')};
  border: 1px solid ${({ type }) => COLORS[type].border};
  position: absolute;
  z-index: 9999;
  bottom: ${({ verticalPosition }) => (verticalPosition === 'top' ? 'calc(100% + 1rem)' : 'calc(-100% - 3rem)')};
  left: ${({ position }) => position === 'left' && '-1.25rem'};
  right: ${({ position }) => position === 'right' && '-1.25rem'};
  border-radius: 4px;
  color: ${({ type }) => COLORS[type].color};
  background: ${({ type }) => COLORS[type].background};
  width: ${({ width }) => (isNumber(width) ? `${width}px` : width)};
  padding: .6rem 1rem;
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  white-space: pre-line;
  box-shadow: ${({ type }) => (type === 'success' ? '0px 5px 34px rgba(0, 0, 0, 0.3)' : '4px 4px 7px rgba(0, 0, 0, 0.19)')};

  ${({ verticalPosition, position, type }) => verticalPosition === 'top' && `
    &:before {
      content: "";
      width: 0;
      height: 0;
      left: ${position === 'left' && '1.25rem'};
      right: ${position === 'right' && '1.25rem'};
      bottom: -8px;
      position: absolute;
      border: 8px solid transparent;
      transform: rotate(135deg);
      border-top-color: ${COLORS[type].border};
      border-right-color: ${COLORS[type].border};
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      left: ${position === 'left' && 'calc(1.25rem + 1px)'};
      right: ${position === 'right' && 'calc(1.25rem + 1px)'};
      bottom: -7px;
      position: absolute;
      border: 7px solid transparent;
      transform: rotate(135deg);
      border-top-color: ${COLORS[type].background};
      border-right-color: ${COLORS[type].background};
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  `}

  ${({ verticalPosition, position, type }) => verticalPosition === 'bottom' && `
    &:before {
      content: "";
      width: 0;
      height: 0;
      left: ${position === 'left' && '1.25rem'};
      right: ${position === 'right' && '1.25rem'};
      top: -8px;
      position: absolute;
      border: 8px solid transparent;
      transform: rotate(135deg);
      border-bottom-color: ${COLORS[type].border};
      border-left-color: ${COLORS[type].border};
      border-top-color: transparent;
      border-right-color: transparent;
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      left: ${position === 'left' && 'calc(1.25rem + 1px)'};
      right: ${position === 'right' && 'calc(1.25rem + 1px)'};
      top: -7px;
      position: absolute;
      border: 7px solid transparent;
      transform: rotate(135deg);
      border-bottom-color: ${COLORS[type].background};
      border-left-color: ${COLORS[type].background};
      border-top-color: transparent;
      border-right-color: transparent;
    }
  `}
`;
