import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import styled from 'styled-components';
import {
  text01, text04,
} from '@/common/constants/theme';

import { CURRENCY_SYMBOLS } from '@/common/constants/financial';

import Checkbox from '@/common/components/Checkbox';
import DatePickerNew from '@/common/components/DatePickerNew';
import Loader from '@/common/components/Loader';
import ArrowDownIcon from '../../../icons/ArrowDownIcon';

import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import AdminTablePagination from '../../components/Table/components/TablePagination';

import { useAdminContext } from '../../../../AdminContext';
import { useFinanceContext } from '../FinanceContext';

const initDates = {
  d_begin: '',
  d_end: '',
};

const CELL_SIZES = ['80px', '450px', '650px'];

const FinanceStats = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [localDates, setLocalDates] = useState(initDates);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [isDisabledShown, setIsDisabledShown] = useState(false);
  const [localStats, setLocalStats] = useState([]);
  const [displayStats, setDisplayStats] = useState([]);

  const { formatCurrencySummValue } = useAdminContext();
  const {
    stats,
    isStatsProcerssing,
    statsFilter,
    setStatsFilter,
  } = useFinanceContext();

  const getTotal = () => {
    let retVal = 0;
    stats.forEach((ps) => { retVal += (ps?.total || 0); });
    return `${CURRENCY_SYMBOLS[stats?.[0]?.currency] || ''}${formatCurrencySummValue(retVal)}`;
  };

  const renderFooterTotal = () => (
    <TotalFooter>
      <TotalTile>Всего</TotalTile>
      <TotalValue>{getTotal()}</TotalValue>
    </TotalFooter>
  );

  useEffect(() => {
    setLocalDates({
      d_begin: statsFilter.d_begin,
      d_end: statsFilter.d_end,
    });
  }, [statsFilter]);

  useEffect(() => {
    if ((stats || []).length === 0) return;
    const useStats = isDisabledShown
      ? stats
      : stats
        .filter((ps) => (!ps.is_disabled && (ps.total || 0) > 0));
    setLocalStats(useStats);
    setDisplayStats(useStats.slice((page - 1) * pageSize, page * pageSize));
  }, [stats, page, pageSize, isDisabledShown]);

  return (
    <Container isLoading={isStatsProcerssing}>
      {isStatsProcerssing && <Loader />}
      <Wrapper isOpen={isVisible}>
        <FlexWrapper alignCenter>
          <Title>
            Статистика за период
          </Title>
          {isVisible && (
            <DatePickerNew
              values={localDates}
              onSubmit={(interval) => {
                const newDates = {
                  ...localDates,
                  d_begin: interval?.dateStart ? format(interval.dateStart, 'yyyy-MM-dd') : '',
                  d_end: interval?.dateEnd ? format(interval.dateEnd, 'yyyy-MM-dd') : '',
                };
                setStatsFilter((prevState) => ({ ...prevState, ...newDates }));
              }}
            />
          )}
        </FlexWrapper>
        <FlexWrapper pointer alignCenter onClick={() => setIsVisible((prevState) => !prevState)}>
          <Text>
            {isVisible ? 'Свернуть' : 'Развернуть'}
          </Text>
          <Icon isRotate={!isVisible}>
            <ArrowDownIcon />
          </Icon>
        </FlexWrapper>
      </Wrapper>
      {isVisible && (
        <Wrapper>
          <Table
            cellSizes={CELL_SIZES}
            dataLength={(stats || []).length}
            page={page}
            limit={pageSize}
          >
            <TableHead>
              <TableHeadCell textCenter>№ п/п</TableHeadCell>
              <TableHeadCell>Платежная система</TableHeadCell>
              <TableHeadCell flex justifyContent="space-between">
                <Text>Сумма, руб.</Text>
                <Checkbox
                  checked={!isDisabledShown}
                  label="Скрыть отключенные"
                  onChange={() => setIsDisabledShown((prevState) => !prevState)}
                />
              </TableHeadCell>
            </TableHead>
            <TableBody>
              {displayStats.map((ps, index) => (
                <TableBodyRow
                  key={`AP-F-FS-TR-${ps.title}`}
                >
                  <TableBodyCell textCenter>{((page - 1) * pageSize) + index + 1}</TableBodyCell>
                  <TableBodyCell>{ps.title}</TableBodyCell>
                  <TableBodyCell>{`${CURRENCY_SYMBOLS[ps.currency] || ''}${ps.total}`}</TableBodyCell>
                </TableBodyRow>
              ))}
            </TableBody>
            <AdminTablePagination
              pageCount={Math.ceil((localStats || []).length / (pageSize))}
              setPage={setPage}
              recordsCount={(localStats || []).length}
              customLeftBlock={renderFooterTotal()}
            />
          </Table>
        </Wrapper>
      )}
    </Container>
  );
};

export default FinanceStats;

const Container = styled.div`
  margin-bottom: 40px;

  color: ${({ isLoading }) => (isLoading ? text04 : text01)};

`;

const Wrapper = styled.div`
  &:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    position: relative;

    ${({ isOpen }) => !isOpen && (`
      &::after {
        content: '';

        height: 1px;

        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;

        background-color: ${text01};
      }
    `)}
  }
`;

const Text = styled.div`
  margin-bottom: ${({ bottomMargin }) => bottomMargin}px;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 15px;
`;

const Title = styled(Text)`
  margin-bottom: 0;
  margin-right: 16px;

  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;

  cursor: pointer;
  transform: rotate(${({ isRotate }) => (isRotate ? 180 : 0)}deg);

  transition: 0.2s ease;
`;

const FlexWrapper = styled.div`
  display: flex;
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}

  ${({ pointer }) => pointer && 'cursor: pointer;'}
`;

const Cell = styled.div`
  ${({ flex, justifyContent }) => flex && `
    display: flex;
    align-items: center;
    justify-content: ${justifyContent || 'space-around'};
  `}
  width: ${({ textCenter }) => (textCenter ? 'auto' : '100%')};
`;

const Row = styled.div``;

const TableHeadCell = styled(Cell)`
`;

const TableBodyRow = styled(Row)`
`;

const TableBodyCell = styled(Cell)`
`;

const TotalTile = styled.div`
  text-transform: uppercase;
  font-weight: bold;

  &::after {
    content: ':';
    padding: 0 .25em 0 .125em;
  }
`;

const TotalValue = styled.div``;

const TotalFooter = styled.div`
  font-family: Gilroy, sans-serif !important;
  display: flex;
  flex-direction: row;
  font-size: 1.2em;
  font-weight: bold;
`;
