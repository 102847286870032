import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import styled from 'styled-components';
import {
  primary,
  red,
  white,
} from '@/common/constants/theme';

import { longAction } from '@/services/actions/longAction.actions';

import CloseIcon from '@/common/components/icons/CloseIcon';
import Loader from '@/common/components/Loader';
import Button from '@/common/components/Button';
import ReCaptcha from '@/common/components/ReCaptcha';

import { isEmailValid as checkIsEmailValid } from '@/common/utils/emailValidator.utils';
import {
  FORM_INPUT_GUTTER_PERCENT,
  MSG_RESPONSE_TYPES,
} from '@/common/utils/publicConstants.util';

import { useAppContext } from '@/AppContext';

const FIELDS = {
  email: 'email',
  captcha: 'captcha',
  message: 'message',
  name: 'name',
};

const portalElement = document.createElement('div');

const Portal = ({ children, element }) => {
  useEffect(() => {
    document.body.appendChild(element);

    return () => {
      document.body.removeChild(element);
    };
  }, [element]);

  return ReactDOM.createPortal(children, element);
};

const AskForm = ({ submitFormAction, setFormShown }) => {
  const { t } = useTranslation('translations');

  const [isProcessing, setIsProcessing] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [captchaToken, setCaptchaToken] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [askResult, setAskResult] = useState(null);
  const [askResultType, setAskResultType] = useState(null);

  const {
    userInfo,
  } = useAppContext();

  const getFormInputWidth = (partsCount, gutterPercent) => {
    const gutter = gutterPercent * (partsCount - 1);
    const pc = partsCount || 1;
    const w = (100 - (gutter || 0)) / pc;
    return `${w}%`;
  };

  const clearData = () => {
    setName(null);
    setEmail(null);
    setMessage(null);
    setCaptchaToken('');
    setIsEmailValid(false);
    setAskResult(null);
  };

  const handleCaptcha = (v) => {
    setCaptchaToken(v);
  };

  const getInputStatus = (f) => ((errors?.[f] || false) ? 'error' : '');

  const formMessageText = () => {
    const userAddText = (userInfo?.email && userInfo?.id)
      ? t('publicPages.FAQ.askForm.postMessage.authenticatedUserCaption', userInfo) : '';
    return `${userAddText}${message}`;
  };

  const handleSubmitForm = () => {
    setIsProcessing(true);
    const data = {
      user_full_name: name,
      user_email: email,
      subject: t('publicPages.FAQ.askForm.postMessage.defaultSubject'),
      content: formMessageText(),
      captcha_token: captchaToken,
    };
    longAction(
      submitFormAction,
      (err) => {
        setIsProcessing(false);
        if (err) setAskResultType(MSG_RESPONSE_TYPES.error);
      },
      (res) => {
        setAskResultType(MSG_RESPONSE_TYPES.success);
        setAskResult(res);
      },
      data,
    );
  };

  const handlePostAnotherMessage = () => {
    clearData();
    setAskResult(null);
    setAskResultType(null);
  };

  useEffect(() => {
    setIsSubmitDisabled(
      ((name || '').length === 0)
      || !isEmailValid
      || ((message || '').length === 0)
      || ((captchaToken || '').length === 0),
    );
  }, [
    name, isEmailValid, message, captchaToken,
  ]);

  useEffect(() => {
    const isEmpty = ((email || '').length === 0);
    const isValid = !isEmpty && checkIsEmailValid(email);
    setIsEmailValid(isValid);
    setErrors((prevState) => ({ ...prevState, [FIELDS.email]: !isEmpty && !isValid }));
  }, [email]);

  useEffect(() => {
    [name, message].forEach((f) => {
      const isEmpty = ((f || '').length === 0);
      setErrors((prevState) => ({ ...prevState, [f]: !isEmpty }));
    });
  }, [name, message]);

  return (
    <Portal element={portalElement}>
      <AskFormContainer>
        <AskFormContentContainer>
          <AskFormContent>
            {isProcessing && <Loader />}
            <CloseControl
              onClick={() => setFormShown && setFormShown()}
            >
              <CloseIcon
                width={20}
                height={20}
              />
            </CloseControl>
            {!askResultType ? (
              <AskFormForm>
                <FormRow isColumn>
                  <AskFormCaption>
                    {t('publicPages.FAQ.askForm.caption')}
                  </AskFormCaption>
                  <AskFormDescription>
                    {t('publicPages.FAQ.askForm.description')}
                  </AskFormDescription>
                </FormRow>
                <FormRow>
                  <FormInputBlock width={getFormInputWidth(2, FORM_INPUT_GUTTER_PERCENT)}>
                    <FormLabel>{t('publicPages.FAQ.askForm.nameText')}</FormLabel>
                    <InputStyled
                      value={name}
                      placeholder={t('publicPages.FAQ.askForm.namePlaceholder')}
                      status={getInputStatus(FIELDS.name)}
                      onChange={({ target }) => setName(target.value)}
                    />
                  </FormInputBlock>
                  <FormInputBlock width={getFormInputWidth(2, FORM_INPUT_GUTTER_PERCENT)}>
                    <FormLabel>{t('publicPages.FAQ.askForm.emailText')}</FormLabel>
                    <InputStyled
                      value={email}
                      placeholder={t('publicPages.FAQ.askForm.emailPlaceholder')}
                      status={getInputStatus(FIELDS.email)}
                      onChange={({ target }) => setEmail(target.value)}
                    />
                  </FormInputBlock>
                </FormRow>
                <FormRow height="inherit">
                  <FormInputBlock width={getFormInputWidth(1, FORM_INPUT_GUTTER_PERCENT)}>
                    <FormLabel>{t('publicPages.FAQ.askForm.messageText')}</FormLabel>
                    <TextAreaStyled
                      value={message}
                      placeholder={t('publicPages.FAQ.askForm.messagePlaceholder')}
                      status={getInputStatus(FIELDS.message)}
                      onChange={({ target }) => setMessage(target.value)}
                    />
                  </FormInputBlock>
                </FormRow>
                <FormRow
                  isCentered={((captchaToken || '').length > 0)}
                >
                  {((captchaToken || '').length === 0)
                    && (
                      <CaptchaBlock>
                        <ReCaptcha
                          appToken={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                          onCheck={handleCaptcha}
                        />
                      </CaptchaBlock>
                    )}
                  <ButtonBlock>
                    <ButtonStyled
                      onClick={handleSubmitForm}
                      isDisabled={isSubmitDisabled}
                    >
                      {t('publicPages.FAQ.askForm.submitButtonText')}
                    </ButtonStyled>
                  </ButtonBlock>
                </FormRow>
              </AskFormForm>
            ) : (
              <FAQAskResult>
                <ResponseBlock>
                  <ResponseContentPart>
                    <ResponseCaption
                      isError={askResultType === MSG_RESPONSE_TYPES.error}
                    >
                      {t(`publicPages.FAQ.askForm.postResult.${askResultType}Caption`)}
                    </ResponseCaption>
                    <ResponseText>
                      {t(`publicPages.FAQ.askForm.postResult.${askResultType}Text`,
                        {
                          errorText: t(`publicPages.FAQ.askForm.postResult.errors.${askResult?.data?.message_type}`),
                        })}
                    </ResponseText>
                  </ResponseContentPart>
                  <ResponseActionsPart>
                    <CloseButton
                      onClick={handlePostAnotherMessage}
                    >
                      {t('publicPages.FAQ.askForm.postResult.closeButtonText')}
                    </CloseButton>
                  </ResponseActionsPart>
                </ResponseBlock>
              </FAQAskResult>
            )}
          </AskFormContent>
        </AskFormContentContainer>
      </AskFormContainer>
    </Portal>
  );
};

export default AskForm;

const AskFormContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(0, 0, 0, .66);
  border-radius: 0;
  z-index: 1001;
`;
const AskFormContentContainer = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  margin: 0 auto;
  min-height: 520px;
  min-width: 700px;
`;

const AskFormContent = styled.div`
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 0;
  right: 0;
  border-radius: 4px;

  overflow-y: auto;
  background: ${white};
  z-index: 11;
`;
const CloseControl = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 0;
  opacity: 0.85;

  &:hover {
    opacity: 1;
  }
`;

const AskFormCaption = styled.div`
  text-align: center;
  font-size: 2.5em;
  margin: 0 0 0.5rem 0;
`;
const AskFormDescription = styled.div`
  text-align: center;
  font-size: 1em;
  margin-bottom: 0.5rem;
`;
const AskFormForm = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  height: calc(100% - 2rem);
  padding: 1rem;
`;
const FAQAskResult = styled.div`
`;
const FormRow = styled.div`
  ${({ isColumn }) => (isColumn && 'flex-direction: column;')}
  display: flex;
  justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'space-between')};
  ${({ height }) => (height && `height: ${height};`)}

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
  &:last-of-type {
    margin-top: auto;
  }
`;

const FormInputBlock = styled.div`
  width: ${({ width }) => (width || '100%')};
`;

const FormLabel = styled.div`
  display: block;
  width: 100%;
  font-size: .85em;
  margin-bottom: .125rem;
`;

const InputStyled = styled(Input)`
  width: 100%;
  padding: .75em 1em .65em;
  border-radius: .33em;
`;

const TextAreaStyled = styled(Input.TextArea)`
  height: 100% !important;
  padding: .75em 1em .65em;
  line-height: 1.25em;
  border-radius: .33em;
  min-height: 6.4em !important;
`;

const CaptchaBlock = styled.div``;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonStyled = styled(Button)`
`;

const ResponseBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3em 0 0;
  height: ${({ height }) => (height || 300)}px;
`;

const ResponseContentPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResponseCaption = styled.div`
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 1rem;
  color: ${({ isError }) => (isError ? red : primary)};
`;

const ResponseActionsPart = styled.div`
  display: flex;
`;

const ResponseText = styled.div`

`;

const CloseButton = styled(Button)`
  align-self: end;
  justify-self: end;
  margin-bottom: 2em;
`;
