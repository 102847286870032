export default {
  paymentSystems: (filterString) => `/api/v1/billing/admin/payment/system/list/${(filterString?.length || 0) > 0
    ? `?${filterString}` : ''}`,
  paymentSystem: (id) => `/api/v1/billing/admin/payment/system/${id}/`,
  paymentSystemCreate: '/api/v1/billing/admin/payment/system/create/',
  paymentsStats: (filterString = '') => `/api/v1/billing/admin/payment/transaction/aggregate/${filterString.length > 0 ? `?${filterString}` : ''}`,
  paymentsList: (filterString = '') => `/api/v1/billing/admin/payment/transaction/list/${filterString.length > 0 ? `?${filterString}` : ''}`,
  paymentReturn: (paymentID) => `/api/v1/billing/admin/payment/transaction/return/${paymentID}`,
  addUserBalance: '/api/v1/billing/admin/payment/transaction/create/',
  withdrawalTransactions: (filterString) => `/api/v1/user/admin/withdrawal/transactions${filterString ? `?${filterString}` : ''}`,
  withdrawalUser: (id) => `/api/v1/user/admin/user/${id}/referrals/balance/withdrawal-transactions`,
  withdrawalApprove: (id) => `/api/v1/user/admin/withdrawal/transactions/${id}/approve`,
  withdrawalReject: (id) => `/api/v1/user/admin/withdrawal/transactions/${id}/reject`,
  withdrawalSystems: '/api/v1/user/admin/withdrawal/systems',
  withdrawalSystem: (id) => `/api/v1/user/admin/withdrawal/systems/${id}`,
  withdrawalStatuses: '/api/v1/user/admin/withdrawal/statuses',
};
