import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z" fill="#6C7DFF" />
    <path d="M18.55 8.86847L9.41848 8.86846L9.41848 6.21738L5 9.75216L9.41848 13.2869L9.41848 10.6359L18.55 10.6359L18.55 8.86847Z" fill="#6C7DFF" />
    <path d="M6.0422 15.4508L14.8225 15.4508L14.8225 18L19.071 14.6011L14.8225 11.2023L14.8225 13.7514L6.0422 13.7514L6.0422 15.4508Z" fill="#6C7DFF" />
  </svg>

);
