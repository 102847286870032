import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import styled from 'styled-components';
import {
  text01, borderSecondary,
  grey, lightRed, white,
  primary, secondary,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';
import ModalNew from '@/common/components/ModalNew';
import DatePickerNew, { DATE_FIELDS } from '@/common/components/DatePickerNew';
import Checkbox from '@/common/components/Checkbox';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/common/components/Table/components/TableBody';
import TablePagination from '../../../components/Table/components/TablePagination';
import Tooltip from '@/common/components/Tooltip';
import SearchIcon from '../../../../../../PersonalPageScreen/components/icons/SearchIcon';
import UsersMainUserFilterArrows, { SORT_DIRECTION_UP } from './MainUser/components/MainFilterArrows';

import {
  DATE_FORMAT,
  useAdminContext,
} from '../../../../../AdminContext';
import {
  BASE_URL,
  DATA_TYPES,
  USERS_PAGE_SIZES,
  useUsersMainContext,
} from '../MainContext';

const SIZES = ['50px', '280px', '100px', '100px', '200px', '214px'];

const USE_TOOLTIP = false;

const ACTIONS = {
  blockUser: 'blockUser',
  setBlockUserReason: 'setBlockUserReason',
  changeBlockUser: 'changeBlockUser',
  unBlockUser: 'unBlockUser',
};

const ACTION_TEXTS = {
  [ACTIONS.blockUser]: 'Заблокировать',
  [ACTIONS.setBlockUserReason]: 'Изменить',
  [ACTIONS.changeBlockUser]: 'Изменить',
  [ACTIONS.unBlockUser]: 'Разблокировать',
};

const REASON_TEXTS = {
  [ACTIONS.blockUser]: { label: 'Укажите причину блокировки', placeholder: 'Причина блокировки' },
  [ACTIONS.changeBlockUser]: { label: 'Скорректируйте причину блокировки', placeholder: 'Причина блокировки' },
  [ACTIONS.unBlockUser]: { label: 'Укажите причину разблокировки', placeholder: 'Причина разблокировки' },
};

const SEARCH_TIMEOUT = 1000 * 0.75;

const UsersMainTable = ({ withoutBlock }) => {
  const [isBusy, setIsBusy] = useState(false);

  const [isBlockWindowOpen, setIsBlockWindowOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [action, setAction] = useState(null);
  const [actionText, setActionText] = useState('');
  const [userBlock, setUserBlock] = useState(null);
  const [blockText, setBlockText] = useState('');
  const [disabledBlockDates, setDisabledBlockDates] = useState([]);
  const [blockDates, setBlockDates] = useState({
    [DATE_FIELDS.start]: new Date(),
    [DATE_FIELDS.end]: new Date(new Date().getTime() + 1000 * 60 * 60 * 30),
  });
  const [blockForever, setBlockForever] = useState(false);

  const [usersTableRowsCount, setUsersTableRowsCount] = useState(USERS_PAGE_SIZES[0]);

  const {
    SnackTypes,
    setAdminSnack,
    formatDateWithZone,
  } = useAdminContext();

  const {
    usersCount,
    users,
    filters,
    filtersHandler,
    filtersBulkHandler,
    blockUser,
    setBlockUserReason,
    changeBlockUser,
    unBlockUser,
    dataProcessing,
  } = useUsersMainContext();

  const searchTimeout = useRef(null);

  const search = (text) => {
    // setLocalUsers(text ? users.filter(
    //  (user) => user.email.toLowerCase().includes(text)) : users,
    // );
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    searchTimeout.current = setTimeout(() => {
      if (filters.page !== 1) {
        filtersBulkHandler({ search: text.toLowerCase(), page: 1 });
      } else {
        filtersHandler('search', text.toLowerCase());
      }
    }, SEARCH_TIMEOUT);
  };

  const sort = (column, direction) => {
    const sign = direction !== 'up' ? '-' : '';

    filtersHandler('order', `${sign}${column}`);
    // if (column === 'balance') {
    //   setLocalUsers([...users.sort(
    //     (a, b) => (direction === 'up' ? a[column] - b[column] : b[column] - a[column])
    //  )]);
    // }
    //
    // if (column === 'date_joined') {
    //   setLocalUsers([...users.sort((a, b) => (direction === 'up'
    //     ? dayjs(a[column]).unix() - dayjs(b[column]).unix()
    //     : dayjs(b[column]).unix() - dayjs(a[column]).unix()
    //   ))]);
    // }
  };

  const handleBlockUnblockUser = (userId, isChange = false) => {
    const user = users.find((u) => u.id === userId) || null;
    setSelectedUser(user);
    if (user) {
      if (user.block_actual) {
        setUserBlock(user.block_actual);
        if (!isChange) {
          setBlockText(user.block_actual.cancel_reason || '');
          setAction(ACTIONS.unBlockUser);
        } else {
          setBlockText(user?.block_actual?.reason || '');
          setBlockDates({
            [DATE_FIELDS.start]: new Date(user.block_actual.block_at),
            [DATE_FIELDS.end]: new Date(user.block_actual.expires_at),
          });
          setAction(ACTIONS.changeBlockUser);
        }
      } else {
        setUserBlock(null);
        setBlockText('');
        setAction(ACTIONS.blockUser);
      }
      setIsBlockWindowOpen(true);
    } else {
      setAdminSnack({ content: `Пользователь с ID [${userId}] не найден!`, type: SnackTypes.error }, SnackTypes.error);
    }
  };

  const handleBlockUser = () => {
    if ((blockText?.length || 0) === 0) { return; }
    setIsBusy(true);
    const data = {};
    switch (action) {
      case ACTIONS.blockUser:
        blockUser(
          selectedUser.id,
          blockText,
          {
            ...blockDates,
            [DATE_FIELDS.end]: blockForever
              // ? new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 100)
              ? null
              : blockDates[DATE_FIELDS.end],
          },
        )
          .then(() => {
            setAction(null);
            setBlockText(null);
          })
          .finally(() => setIsBusy(false));
        break;
      case ACTIONS.setBlockUserReason:
        setBlockUserReason(selectedUser.id, userBlock.id, blockText)
          .then(() => {
            setAction(null);
            setBlockText(null);
          });
        break;
      case ACTIONS.unBlockUser:
        unBlockUser(selectedUser.id, userBlock.id, blockText)
          .then(() => {
            setAction(null);
            setBlockText(null);
            setUserBlock(null);
          })
          .finally(() => setIsBusy(false));
        break;
      case ACTIONS.changeBlockUser:
        data.reason = blockText;
        if (format(blockDates[DATE_FIELDS.start], DATE_FORMAT)
          !== selectedUser.block_actual?.block_at) {
          data.block_at = format(blockDates[DATE_FIELDS.start], DATE_FORMAT);
        }
        if (format(blockDates[DATE_FIELDS.end], DATE_FORMAT)
          !== selectedUser.block_actual?.expires_at) {
          data.expires_at = format(blockDates[DATE_FIELDS.start], DATE_FORMAT);
        }
        changeBlockUser(selectedUser.id, userBlock.id, data)
          .then(() => {
            setAction(null);
            setBlockText(null);
          });
        changeBlockUser(selectedUser.id, userBlock.id, data)
          .then(() => {
            setAction(null);
            setBlockText(null);
            setUserBlock(null);
          })
          .finally(() => setIsBusy(false));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setDisabledBlockDates(blockForever ? [DATE_FIELDS.end] : []);
    setBlockDates(
      (prevState) => (
        {
          ...prevState,
          [DATE_FIELDS.end]: blockForever
            ? new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 100)
            : '',
        }
      ),
    );
  }, [blockForever]);

  useEffect(() => {
    const uCount = (users || []).length;
    const pSize = (filters.limit || USERS_PAGE_SIZES[0]);
    setUsersTableRowsCount(Math.max(Math.min(uCount, pSize), USERS_PAGE_SIZES[0]));
  }, [users]);

  return (
    <Container>
      <ModalNew
        title={`${actionText} пользователя ${selectedUser?.email}`}
        icon={null}
        isOpen={isBlockWindowOpen}
        onCloseModal={() => setIsBlockWindowOpen(false)}
        isAdmin
        isHidden={false}
      >
        {[ACTIONS.blockUser, ACTIONS.changeBlockUser].includes(action) && (
          <>
            <Label marginTop={0.001}>Заблокировать на период</Label>
            <BlockPeriodContainer>
              <BlockPeriodItem>
                <DatePickerNew
                  onSubmit={(v) => setBlockDates(
                    {
                      [DATE_FIELDS.start]: v.dateStart,
                      [DATE_FIELDS.end]: v.dateEnd,
                    },
                  )}
                  values={blockDates}
                  disableInputs={disabledBlockDates}
                />
              </BlockPeriodItem>
              <BlockPeriodItem>
                <Checkbox
                  label="Навсегда"
                  checked={blockForever}
                  onChange={(v) => setBlockForever(v)}
                />
              </BlockPeriodItem>
            </BlockPeriodContainer>
          </>
        )}
        <Label>{REASON_TEXTS?.[action]?.label}</Label>
        <Textarea
          value={blockText}
          onChange={({ target }) => setBlockText(target.value)}
          placeholder={REASON_TEXTS?.[action]?.placeholder}
        />
        <Wrapper flex>
          <Button
            disabled={(
              ((blockText?.length || 0) === 0)
              || (
                (action === ACTIONS.setBlockUserReason)
                && (blockText === userBlock.reason))
              || (action === ACTIONS.blockUser
                && (!blockDates?.[DATE_FIELDS.start]?.getMonth
                  || !(blockDates?.[DATE_FIELDS.end]?.getMonth || blockForever)))
            )}
            color={lightRed}
            onClick={() => {
              setIsBlockWindowOpen(false);
              handleBlockUser();
            }}
          >
            {actionText}
          </Button>
        </Wrapper>
      </ModalNew>
      <Table
        page={filters.page}
        limit={filters.limit}
        dataLength={usersCount || 0}
        cellSizes={SIZES}
        textAlign="left"
        adaptiveSize
      >
        {(isBusy
          || (dataProcessing[DATA_TYPES.users])
        ) && <Loader />}
        <TableHead>
          <HeadItem>
            #
          </HeadItem>
          <HeadItem flex>
            E-mail пользователя
            <UsersMainUserFilterArrows
              initDirection={SORT_DIRECTION_UP}
              sortTriggered={(direction) => sort('email', direction)}
            />
          </HeadItem>
          <HeadItem>
            Страна
          </HeadItem>
          <HeadItem>
            Баланс $
          </HeadItem>
          <HeadItem flex>
            Дата регистрации
            <UsersMainUserFilterArrows sortTriggered={(direction) => sort('date_joined', direction)} />
          </HeadItem>
          <HeadItem flex>
            <Input
              placeholder="Поиск"
              onChange={({ target }) => search(target.value)}
            />
            <Icon top>
              <SearchIcon />
            </Icon>
          </HeadItem>
        </TableHead>
        <TableBody
          isBottomRowBorder
          rowHeight={42}
          minRows={usersTableRowsCount}
        >
          {(users || []).map((user, index) => (
            <Row key={user.id}>
              <RowItem>
                {index + ((filters.page - 1) * filters.limit) + 1}
              </RowItem>
              <RowItem>
                {USE_TOOLTIP ? (
                  <Tooltip verticalPosition="bottom" title={user.email} trigger="hover">
                    <StyledLink to={`${BASE_URL}/${user.id}`}>
                      {user.email}
                    </StyledLink>
                  </Tooltip>
                ) : (
                  <StyledLink to={`${BASE_URL}/${user.id}`}>
                    {user.email}
                  </StyledLink>
                )}
              </RowItem>
              <RowItem>
                {user.country}
              </RowItem>
              <RowItem>
                {user.balance}
              </RowItem>
              <RowItem>
                {formatDateWithZone(user.date_joined)}
              </RowItem>
              <RowItem flex flexEnd>
                {!withoutBlock && (
                  <>
                    <PrimaryText
                      onClick={() => {
                        setActionText(!user.block_actual
                          ? ACTION_TEXTS[ACTIONS.blockUser]
                          : ACTION_TEXTS[ACTIONS.unBlockUser]);
                        handleBlockUnblockUser(user.id);
                      }}
                    >
                      {!user?.block_actual
                        ? ACTION_TEXTS[ACTIONS.blockUser]
                        : ACTION_TEXTS[ACTIONS.unBlockUser]}
                    </PrimaryText>
                    {user.block_actual && (
                      <PrimaryText
                        onClick={() => {
                          setActionText(ACTION_TEXTS[ACTIONS.changeBlockUser]);
                          handleBlockUnblockUser(user.id, true);
                        }}
                      >
                        {ACTION_TEXTS[ACTIONS.changeBlockUser]}
                      </PrimaryText>
                    )}
                  </>
                )}
              </RowItem>
            </Row>
          ))}
        </TableBody>
        <TablePagination
          page={filters.page || 1}
          setPage={(v) => filtersHandler('page', v)}
          limit={filters.limit || 0}
          setLimit={(v) => {
            if (filters.limit > v) {
              filtersHandler('limit', v);
            } else {
              filtersBulkHandler({ limit: v, page: 1 });
            }
          }}
          pageSizes={USERS_PAGE_SIZES}
          pageCount={Math.ceil((usersCount || 0) / filters.limit)}
          recordsCount={usersCount || 0}
          title="Всего пользователей:"
        />
      </Table>
    </Container>
  );
};

export default UsersMainTable;

const Container = styled.div``;

const HeadItem = styled.div`
  ${({ flex }) => flex && 'display: flex;'}
`;

const Row = styled.div``;

const RowItem = styled.div`
  ${({ flex }) => flex && 'display: flex;'}
  ${({ flexEnd }) => flexEnd && 'justify-content: flex-end;'}

  width: 100%;
`;

const StyledLink = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: ${primary};
  line-height: 16px;

  &:hover {
    color: ${primary};
  }
`;

const PrimaryText = styled.div`
  margin-right: 22px;

  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${primary};
  line-height: 20px;
`;

const Icon = styled.div`
  position: relative;
  top: 3px;
  ${({ pointer }) => pointer && 'cursor: pointer;'}
`;

const Input = styled.input`
  margin-right: 6px;
  padding-bottom: 4px;

  border: none;
  border-bottom: 1px solid ${secondary};
  background-color: transparent;
  outline: none;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && 'display: flex;'}
  ${({ flexSb }) => flexSb && 'justify-content: space-between;'}
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ rightMargin }) => rightMargin && `margin-right: ${rightMargin}px;`}
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}
  ${({ topMargin }) => topMargin && `margin-top: ${topMargin}px;`}

  position: relative;
`;

const Label = styled.div`
  margin: ${({ marginTop }) => (marginTop || 0.5)}rem 0 ${({ marginBottom }) => (marginBottom || 0.25)}rem;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 0 12px;
  margin-bottom: 10px;

  background: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 2px;
  outline: none;
  resize: none;

  color: ${text01};
`;

const Button = styled.button`
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: 0 27px;

  background-color: transparent;
  border: 1px solid ${({ color, disabled }) => (!disabled ? color : grey)};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ color, disabled }) => (!disabled ? color : grey)};
  text-transform: uppercase;
  line-height: 24px;

  transition: all .25s ease-in-out 0s;
`;

const BlockPeriodContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

const BlockPeriodItem = styled.div`
  display: flex;
  align-items: center;
`;
