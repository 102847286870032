import React from 'react';
import { LogsContextProvider } from './LogsContext';
import Logs from '@/screens/PersonalPageScreen/components/PageContent/LogsPage/Logs';

const LogsContainer = () => (
  <LogsContextProvider>
    <Logs />
  </LogsContextProvider>
);

export default LogsContainer;
