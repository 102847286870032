import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { secondary, text01 } from '@/common/constants/theme';

// import CheckIcon from '../../../../icons/CheckIcon';
import ServerSelectControls from '../ServerSelectControls';

const ServerTitle = ({
  isStalker,
  isFullWidth,
  showControlButtons,
}) => {
  const { t } = useTranslation('translations');

  return (
    <Container>
      <Wrapper
        isFullWidth={isFullWidth}
        showControlButtons={showControlButtons}
      >
        <Title>{t('steps.step3.content.header.title')}</Title>
        <Text bottomMargin>
          {t('steps.step3.content.header.subTitles.subTitle')}
        </Text>
        <Text>{t('steps.step3.content.header.subTitles.descr')}</Text>
      </Wrapper>
      {showControlButtons && (
        <ServerSelectControls
          isStalker={isStalker}
        />
      )}
    </Container>
  );
};

export default ServerTitle;

const Container = styled.div`
  min-width: 333px;

  @media (max-width: 1360px) {
    min-width: auto;
  }

  @media (max-width: 1198px) {
    min-width: 333px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: ${({ showControlButtons }) => (!showControlButtons ? '2rem' : '53px')};
  max-width: ${({ isFullWidth }) => (isFullWidth ? '85%' : '305px')};
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 14px;')};

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const Title = styled.div`
  margin-top: 23px;
  margin-bottom: 33px;

  position: relative;

  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  &::before {
    content: '';

    width: 30px;
    height: 4px;

    position: absolute;
    left: 0;
    bottom: -10px;

    background-color: ${secondary};
  }
`;
