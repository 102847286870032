import React from 'react';
import styled from 'styled-components';

import { text01, secondary } from '@/common/constants/theme';
import UsersMainUserDevicesList from './components/MainUserDevicesList/MainUserDevicesList';

const UsersMainUserDevices = ({ devices }) => (
  <Container>
    <Wrapper>
      <Title>
        Устройств всего:
      </Title>
      <SecondaryTitle>
        {devices?.length || '0'}
      </SecondaryTitle>
    </Wrapper>
    <UsersMainUserDevicesList devices={devices} />
  </Container>
);

export default UsersMainUserDevices;

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 20px;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${text01};
  line-height: 24px;
`;

const SecondaryTitle = styled(Title)`
  margin-left: 10px;

  color: ${secondary};
  font-size: 16px;
  line-height: 19px;
`;
