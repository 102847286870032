/* eslint-disable no-param-reassign */
import 'whatwg-fetch';
import auth from './auth.service';

export const baseUrl = process.env.REACT_APP_API_URL || 'https://api.arlekino.tv';

// eslint-disable-next-line no-unused-vars
// async function parseJSON(response) {
//   if (!response?.headers.get('content-type')?.includes('application/json')) {
async function parseJSON(response, method = 'GET') {
  const isDeleteOk = (method === 'DELETE') && ((response?.status || 0) === 204);
  if (!isDeleteOk && !response?.headers.get('content-type')?.includes('application/json')) {
    // throw new Error('Incorrect response from the server');
    if ((response?.headers.get('content-length') || 0) > 0) {
      // eslint-disable-next-line no-console
      console.error('Incorrect response from the server');
    }
    return '';
  }
  const responseJson = isDeleteOk ? { deleted: true } : await response.json();
  // const responseJson = await response.json();
  return responseJson;
}

async function parseBlob(response) {
  const responseStatus = response?.status || 600;
  if (responseStatus < 200 || responseStatus >= 300) {
    return new Blob();
  }
  const responseBlob = await response.blob();
  return responseBlob;
}

async function checkStatus(response, method = 'GET') {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401) {
    auth.clearToken();
    sessionStorage.setItem('redirectUrl', window.location.href);
    window.open('/sign-in', '_self');
    return response;
  }
  if (response.status === 413) {
    // eslint-disable-next-line no-console
    console.error('Request is too long');
    return { error: 'req_too_long' };
  }

  const responseFormatted = await parseJSON(response, method);

  if (response && responseFormatted) {
    // eslint-disable-next-line no-console
    console.error(`Request error (${response.status})`, responseFormatted);
  }
  response.data = responseFormatted;
  return Promise.reject(response);
}

function formatQueryParams(params) {
  return Object.keys(params)
    .filter((k) => params[k] != null)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
}

function getUrl(url, options) {
  if (options && options.params) {
    const params = formatQueryParams(options.params);
    return `${url}?${params}`;
  }
  return url;
}

export default async function request(url, options = {}, stringify = true, otherApi = false) {
  const token = auth.get('token');
  if (token) {
    options.headers = {
      Authorization: `Token ${token}`,
      ...options.headers,
    };
  }
  if (stringify) {
    options.headers = {
      'Content-Type': 'application/json',
      ...options.headers,
    };
  }

  const requestUrl = getUrl(url, options);

  if (options && options.body && stringify) {
    options.body = JSON.stringify(options.body);
  }

  const requestResult = await fetch(otherApi ? requestUrl : baseUrl + requestUrl,
    options)
    .then(checkStatus)
    .then((res) => ((options?.isFileRequest || false) ? parseBlob(res) : parseJSON(res, options?.method || 'GET')));

  return requestResult;
}

export const sendSpeedStats = async (userInfo, testData) => {
  const url = 'https://worker.spr24.net/api/speedtest_results';
  const IpAddress = userInfo?.ip_address || await fetch('https://www.cloudflare.com/cdn-cgi/trace')
    .then(async (r) => {
      let result = '';
      (r.status === 200 ? await r.text() : '').split('\n')
        .forEach((l) => {
          if (l.startsWith('ip=')) { result = l.replace('ip=', ''); }
        });
      return result;
    })
    .catch(() => null);

  const prepareServerData = (td) => ({
    Download: parseFloat(td.dlStatus, 10) || 0,
    Jitter: parseFloat(td.jitterStatus, 10) || 0,
    Ping: parseFloat(td.pingStatus, 10) || 0,
    ServerUrl: td.server || 'noServer',
  });

  const Tests = testData.map(prepareServerData);
  const recomendedTest = testData.find((t) => t.isRecom) || null;

  const data = {
    Text: 'LogToTelegram',
    IpAddress,
    Username: userInfo?.email || 'not-detected',
    Tests,
    Recomendation: recomendedTest ? prepareServerData(recomendedTest) : null,
  };

  const postData = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(url, postData)
    .then(async (r) => Promise.resolve(r.status === 200 ? await r.json() : { error: 'status' }))
    .catch((e) => Promise.resolve({ error: e }));
  return response;
};
