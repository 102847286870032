import React from 'react';
import styled from 'styled-components';

import { borderSecondary, secondary, white } from '@/common/constants/theme';
import SearchIcon from '../../../../../icons/SearchIcon';

const AdminDNSFilters = ({ searchValue, searchHandler, handleCreate }) => (
  <Container>
    <Text onClick={handleCreate}>
      Создать DNS
    </Text>
    <InputWrapper>
      <Icon>
        <SearchIcon />
      </Icon>
      <Input
        value={searchValue}
        onChange={({ target }) => searchHandler(target.value)}
        placeholder="Поиск по DNS"
      />
    </InputWrapper>
  </Container>
);

export default AdminDNSFilters;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;
`;

const Text = styled.div`
  cursor: pointer;

  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  color: ${secondary};
  line-height: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  height: 44px;
  padding: 0 10px;

  background: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
`;

const Input = styled.input`
  min-width: 400px;

  background-color: transparent;
  border: none;
  outline: none;

  &::placeholder {
    font-family: Gilroy;
    font-weight: 500;
  }
`;

const Icon = styled.div`
  margin-right: 12px;

  position: relative;
  top: 2px;

  & svg path {
    fill: ${borderSecondary};
  }
`;
