import React from 'react';

import { UsersMainContextProvider } from './MainContext';
import UsersMain from './Main';

const UsersMainContainer = () => (
  <UsersMainContextProvider>
    <UsersMain />
  </UsersMainContextProvider>
);

export default UsersMainContainer;
