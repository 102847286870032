/* eslint-disable camelcase, no-param-reassign */
import formatFetchGetParams from '../../common/utils/formatFetchGetParams.util';
import RequestService from '../request.service';
import urls from '../routes/devices.api';
import request2Service from '../request2.service';

export const fetchDevices = async () => {
  const { results } = await RequestService(urls.devicesList, {
    method: 'GET',
  });
  return results;
};

export const refreshDeviceOTTIdAction = async (deviceId) => RequestService(
  `/api/v1/user/device/${deviceId}/refresh_ott_id/`,
  { method: 'post' },
);

export const updateDevicePlayList = (deviceId, playlistId) => RequestService(
  `/api/v1/iptv/playlist/${playlistId}/bind_device/${deviceId}/`,
  { method: 'POST' },
);

export const getAllDevicesAction = (params = {}) => (
  RequestService(urls.devicesList, { params: formatFetchGetParams(params) })
);

export const getSelectedDevicesAction = () => (
  RequestService(urls.selectedDevices)
);

export const createDeviceAction = async (name, content) => {
  const device = await RequestService(urls.addDevice, {
    method: 'POST',
    body: { name, content },
  });
  return device;
};

export const editDeviceAction = async ({ id, ...body }) => RequestService(urls.editDevice(id), {
  method: 'PATCH',
  body,
});

export const startDeviceViewTypeChangeAction = (
  { id, ...body },
) => RequestService(urls.startDeviceViewTypeChange(id), {
  method: 'POST',
  body,
});

export const statusDeviceViewTypeChangeAction = (
  id, taskId,
) => RequestService(urls.statusDeviceViewTypeChange(id, taskId), {
  method: 'POST',
});

export const deviceViewTypeChange = ({ id, data, taskId }) => RequestService(
  urls.deviceViewTypeChange({ id, taskId }),
  { method: 'POST', body: !taskId ? data : null }, !taskId,
);

// eslint-disable-next-line max-len
export const editDeviceAction2 = (id, data) => RequestService(
  urls.editDevice(id), { method: 'PATCH', body: data },
);

export const checkedDeviceAction = async (deviceId) => {
  const device = await RequestService(urls.checkedDevice(deviceId), {
    method: 'POST',
  });
  return device;
};

export const deviceOrder = (body) => new Promise((resolve, reject) => {
  request2Service(urls.orders, {
    method: 'POST',
    body,
  })
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});

export const deleteDeviceAction = (deviceId) => new Promise((resolve, reject) => {
  request2Service(urls.deleteDevice(deviceId), {
    method: 'DELETE',
  })
    .then(() => resolve('devicesContent.deleted'))
    .catch((error) => {
      reject(error.message_type);
    });
});

export const checkedAllDeviceAction = async (condition) => {
  const device = await RequestService(urls.checkedAllDevices, {
    method: 'PATCH',
    body: { condition },
  });
  return device;
};

export const getDeviceDnsAction = (deviceId) => RequestService(urls.deviceDns(deviceId));

export const bindDeviceToDnsAction = (deviceId, dnsId) => (
  RequestService(urls.deviceDns(deviceId), {
    method: 'POST',
    body: {
      dns_id: dnsId,
    },
  })
);

export const unbindDeviceToDnsAction = (deviceId) => (
  RequestService(urls.deviceDns(deviceId), {
    method: 'DELETE',
  })
);

export const resetDefaultThemeAction = (deviceId, taskId) => RequestService(
  urls.resetTheme(deviceId, taskId), { method: 'POST' },
);

export const getPlaylistType = (deviceId) => RequestService(
  urls.setPlaylistType(deviceId),
);

export const setPlaylistType = (deviceId, playlistTypeId) => RequestService(
  urls.setPlaylistType(deviceId),
  { method: 'POST', body: { playlist_type_id: playlistTypeId } },
);

export const removePlaylistType = (deviceId) => RequestService(
  urls.setPlaylistType(deviceId), { method: 'DELETE' },
);

export const setDeviceCode = (deviceId, code) => RequestService(
  urls.editDevice(deviceId), { method: 'PATCH', body: { app_pairing_code: code, app_hwid: null } },
);
