import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TwitterPicker } from 'react-color';
import {
  primary,
  text04,
  white,
  borderSecondary,
} from '@/common/constants/theme';
import CloseIcon from '@/common/components/icons/CloseIcon';

const ColorPicker = ({
  color,
  label,
  onApply,
}) => {
  const { t } = useTranslation(['translations']);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState(color);

  const applyHandler = () => {
    setOpen(false);
    if (onApply) {
      onApply(value);
    }
  };

  const resetValues = () => {
    setOpen(false);
    setValue(color);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.preventDefault();
          resetValues();
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Container>
      <ColorSquare
        onClick={() => setOpen(true)}
        color={color}
      />
      {isOpen && (
        <Wrapper
          ref={wrapperRef}
          className="main-datepicker"
        >
          <PickerWrapper>
            <Label>{label}</Label>
            <StyledTwitterPicker
              color={value}
              onChangeComplete={({ hex }) => setValue(hex)}
              triangle="hide"
              colors={[
                '#ff9800', '#FFCA3A',
                '#7BDCB5', '#8AC926',
                '#8ED1FC', '#478ECC',
                '#5C70CF', '#EB144C',
                '#F78DA7', '#6A4C93',
              ]}
            />
            <Icon onClick={resetValues}>
              <CloseIcon />
            </Icon>
            <Text onClick={applyHandler}>
              {t('table.apply')}
            </Text>
          </PickerWrapper>
        </Wrapper>
      )}
    </Container>
  );
};

export default ColorPicker;

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  right: -5rem;
  top: calc(100% + 10px);
  z-index: 20;
  
  background-color: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 3px;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
`;

const PickerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledTwitterPicker = styled(TwitterPicker)`
  box-shadow: none !important;
  border: none !important;
`;

const Icon = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
  
  cursor: pointer;

  svg path {
    fill: ${text04};
  }
`;

const Text = styled.div`
  margin: 10px 20px;
  cursor: pointer;
  font-family: Gilroy;
  font-weight: 600;
  font-size: 12px;
  color: ${primary};
  text-align: right;
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const ColorSquare = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ color }) => color};
  cursor: pointer;
  margin-right: 5px;
`;

const Label = styled.div`
  font-family: Gilroy;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.16px;
  padding: 15px 15px 0;
`;
