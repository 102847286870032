/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import {
  secondary,
  secondaryHover,
  secondaryActive,
  white,
  activeTertiary,
  primaryHover,
  primaryActive,
  text01, grey, greySecondary, greyWhite,
} from '../constants/theme';

export const BUTTON_COLORS = {
  secondary: 'secondary',
  primary: 'primary',
  primaryTransparent: 'primaryTransparent',
  grey: 'grey',
};

const COLORS = {
  secondary: {
    main: activeTertiary,
    hover: primaryHover,
    active: primaryActive,
    text: white,
    textHover: white,
  },
  primary: {
    main: secondary,
    hover: secondaryHover,
    active: secondaryActive,
    text: white,
    textHover: white,
  },
  primaryTransparent: {
    main: 'transparent',
    hover: 'transparent',
    active: 'transparent',
    text: primaryActive,
    textHover: secondaryActive,
  },
  grey: {
    main: grey,
    hover: greySecondary,
    active: greyWhite,
    text: white,
    textHover: white,
  },
};

export const BUTTON_SIZES = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  auto: 'auto',
};

const SIZES = {
  large: 256,
  medium: 200,
  small: 144,
  auto: 0,
};

export default ({
  color = 'primary',
  width = 'medium',
  isDisabled,
  children,
  ...props
}) => (
  <Button
    color={color}
    width={width}
    disabled={isDisabled}
    {...props}
  >
    {children}
  </Button>
);

const Button = styled.button`
  outline: none;
  width: ${({ width }) => (SIZES[width] > 0 ? `${SIZES[width]}px` : 'auto')};
  height: ${({ width }) => (SIZES[width] > 0 ? '48px' : 'auto')};
  cursor: pointer;
  text-transform: ${({ isUpperCase }) => (isUpperCase ? 'uppercase' : 'none')};
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: ${({ isBoldText }) => (isBoldText ? 600 : 400)};
  transition: all 0.25s ease;
  ${({ isOutline, color }) => (isOutline ? `
    background: ${white};
    color: ${text01};
    border: 2px solid ${COLORS[color].main};
    :hover {
      border-color: ${COLORS[color].hover};
      background: ${COLORS[color].hover};
      color: ${COLORS[color].textHover};
    }
    :active {
      border-color: ${COLORS[color].active};
      background: ${COLORS[color].active};
    }
    :disabled {
      border-color: ${text01};
      opacity: .2;
      cursor: default;
    }
  ` : `
    background: ${COLORS[color].main};
    border: none;
    color: ${COLORS[color].text};
    :hover {
      background: ${COLORS[color].hover};
    }
    :active {
      background: ${COLORS[color].active};
    }
    :disabled {
      background: ${text01};
      opacity: .2;
      cursor: default;
    }
  `)}
`;
