import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  white,
  borderSecondary,
  activeTertiary,
  text01,
  inputBorder,
  greyText,
} from '@/common/constants/theme';
import { Radio } from '@/common/components/RadioButton';
import Checkbox from '@/common/components/Checkbox';
import Dropdown from '@/common/components/Dropdown';
import ColorPicker from './components/ColorPicker';
import ExpandedBlock from './components/ExpandedBlock';
import Loader from '@/common/components/Loader';
import DatePickerNew from '@/common/components/DatePickerNew';

import UserIcon from '../../icons/UserIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';
import { LineChartWrapper, SimpleBarChartWrapper } from './components/Charts';

import {
  DATA_TYPES,
  useStatisticsContext,
} from './StatisticsContext';
import { format } from 'date-fns';

const getCountriesArray = (countries, dataType) => {
  const storageKey = `${dataType}_statisticsUserChartFields`;
  let oldArray = null;
  try {
    oldArray = JSON.parse(localStorage.getItem(storageKey));
  } catch (Ex) {
    // eslint-disable-next-line no-console
    console.log('parse error, local value:', localStorage.getItem(storageKey), 'local storage cleared');
    localStorage.clear();
  }
  const retVal = Object.values(countries).map((item) => {
    const savedCountry = oldArray?.find((el) => el.key === item.key);
    return ({
      ...item,
      title: item.key,
      isSelected: savedCountry?.isSelected || true,
      color: savedCountry?.color || `#${Math.floor(Math.random() * 16773120).toString(16)}`,
    });
  });
  if (!oldArray) {
    localStorage.setItem(storageKey, JSON.stringify(retVal));
  }
  return retVal;
};

const chartModes = [
  { key: 'line', title: 'Линейный' },
  { key: 'bar', title: 'Свеча' },
];

const dataSections = [
  {
    key: DATA_TYPES.userDevices,
    data: 'devices',
    title: 'Устройств с подпиской',
  },
  {
    key: DATA_TYPES.userUsers,
    data: 'users_all',
    title: 'Всего пользователей',
  },
  {
    key: DATA_TYPES.userReferral,
    data: 'users_referral',
    title: 'По реф. ссылкам',
  },
];

const UserContainer = () => {
  const legend = useRef(null);
  const chartWrapper = useRef(null);

  const [disabledPrevButton, setDisabledPrevButton] = useState(true);
  const [disabledNextButton, setDisabledNextButton] = useState(false);
  const [mode, setMode] = useState(chartModes[0]);

  const {
    isUserStatsProcessing,
    userStatsFilter,
    setUserStatsFilter,
    userGeneralStats,
    userUsersStats,
    userReferralStats,
    userDevicesStats,
    filterDatesToLocal,
    filterDatesFromLocal,
  } = useStatisticsContext();

  const [localDates] = useState(filterDatesToLocal(userStatsFilter));

  const [selectedDataType, setSelectedDataType] = useState(
    dataSections.find((itm) => itm.key === DATA_TYPES.userDevices),
  );
  const [generalData, setGeneralData] = useState(null);
  const [devicesData, setDevicesData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [referralData, setReferralData] = useState(null);

  const [countriesArray, setCountriesArray] = useState([]);
  const [statisticArray, setStatisticArray] = useState([]);

  const formatNumber = (value) => {
    const locale = 'ru-RU';
    return new Intl.NumberFormat(locale).format(value);
  };

  const changeColor = (key) => (color) => {
    const userChartFields = countriesArray.map((field) => (field.key === key ? (
      {
        ...field,
        color,
      }
    ) : field));
    setCountriesArray(userChartFields);
    localStorage.setItem(`${selectedDataType.key}_statisticsUserChartFields`, JSON.stringify(userChartFields));
  };

  const selectCountry = (key) => {
    const userChartFields = countriesArray.map((field) => (field.key === key ? (
      {
        ...field,
        isSelected: !field.isSelected,
      }
    ) : field));
    setCountriesArray(userChartFields);
  };

  const selectAllCounties = (value) => {
    const userChartFields = countriesArray.map((field) => ({
      ...field,
      isSelected: value,
    }));
    setCountriesArray(userChartFields);
  };

  const scrollLegend = (e, isNext) => {
    e.stopPropagation();
    const left = Number.parseInt(legend.current.style.left, 10) || legend.current.offsetLeft;
    if (isNext) {
      legend.current.style.left = `${left - 110}px`;
    } else {
      legend.current.style.left = `${left + 110}px`;
    }

    setDisabledPrevButton(Number.parseInt(legend.current.style.left, 10) >= 145);
    setDisabledNextButton(
      chartWrapper.current.offsetWidth - 103 - Number.parseInt(legend.current.style.left, 10)
      > legend.current.offsetWidth,
    );
  };

  useEffect(() => {
    if (Object.values(userGeneralStats).length > 0) {
      setGeneralData(userGeneralStats);
    }
  }, [userGeneralStats]);

  useEffect(() => {
    if (Object.values(userUsersStats).length > 0) {
      setUsersData(userUsersStats);
    }
  }, [userUsersStats]);

  useEffect(() => {
    if (Object.values(userReferralStats).length > 0) {
      setReferralData(userReferralStats);
    }
  }, [userReferralStats]);

  useEffect(() => {
    if (Object.values(userDevicesStats).length > 0) {
      setDevicesData(userDevicesStats);
    }
  }, [userDevicesStats]);

  useEffect(() => {
    if (isUserStatsProcessing || !(
      devicesData && usersData && referralData
    )) {
      return;
    }
    let data = null;
    switch (selectedDataType.key) {
      case DATA_TYPES.userDevices:
        data = devicesData;
        break;
      case DATA_TYPES.userUsers:
        data = usersData;
        break;
      case DATA_TYPES.userReferral:
        data = referralData;
        break;
      default:
        break;
    }
    if (data) {
      setCountriesArray(getCountriesArray(data?.countries || [], selectedDataType.key));
      setStatisticArray(data?.statisticArray || []);
    }
  }, [
    selectedDataType, isUserStatsProcessing,
    userStatsFilter, devicesData, usersData, referralData,
  ]);

  const renderChart = () => {
    switch (mode.key) {
      case 'bar':
        return (
          <SimpleBarChartWrapper
            statisticArray={statisticArray}
            fields={countriesArray.filter((country) => country.isSelected)}
          />
        );
      case 'line':
      default:
        return (
          <LineChartWrapper
            statisticArray={statisticArray}
            fields={countriesArray.filter((country) => country.isSelected)}
          />
        );
    }
  };

  const renderSelectDataTypeRadioValue = (value, isActive) => (
    <Value>
      <Sum isActive={isActive}>{formatNumber(value.count)}</Sum>
      <Differences
        isActive={isActive}
        isPositive={value?.is_positive || true}
      >
        {`${value.is_positive ? '+' : ''}${formatNumber(value.differences)}`}
      </Differences>
    </Value>
  );

  return (
    <ExpandedBlock
      titleComponent={() => (
        <Label>
          <UserIcon />
          Пользователи
        </Label>
      )}
      rightComponent={() => (
        <Actions>
          <DropDownLabel>Вид:</DropDownLabel>
          <StyledDropdown
            options={chartModes}
            value={mode}
            onSelect={(item) => setMode(item)}
          />
          <DatePickerNew
            values={localDates}
            onSubmit={(interval) => {
              const newDates = {
                ...localDates,
                ...filterDatesFromLocal({
                  d_begin: interval?.dateStart ? format(interval.dateStart, 'yyyy-MM-dd') : '',
                  d_end: interval?.dateEnd ? format(interval.dateEnd, 'yyyy-MM-dd') : '',
                }),
              };
              setUserStatsFilter((prevState) => ({ ...prevState, ...newDates }));
            }}
          />
        </Actions>
      )}
    >
      <Block>
        {isUserStatsProcessing && <Loader />}
        <RelativeRow>
          {generalData && dataSections.map((ds) => generalData[ds.data] && (
            <RadioWrapper key={`${ds.key}--userStatisticsSelector`}>
              <Radio
                checked={selectedDataType.key === ds.key}
                value={ds.key}
                onChange={() => setSelectedDataType(ds)}
              />
              <RadioLabel>
                <Text>{ds.title}</Text>
                {renderSelectDataTypeRadioValue(
                  generalData[ds.data], selectedDataType.key === ds.key,
                )}
              </RadioLabel>
            </RadioWrapper>
          ))}
        </RelativeRow>
        <RelativeRow>
          {countriesArray.length > 0 && (
            <>
              <SelectAllWrapper>
                <Checkbox
                  checked={countriesArray.every((el) => el.isSelected)}
                  onChange={selectAllCounties}
                  label="Все страны"
                />
              </SelectAllWrapper>
              <Legend ref={legend}>
                {countriesArray.map((country) => (
                  <CountryWrapper key={`${country.key}--chartLegendItem`}>
                    <LegendItem>
                      <Checkbox
                        checked={country?.isSelected || false}
                        onChange={() => selectCountry(country.key)}
                      />
                      <ColorPicker
                        color={country.color}
                        label={country.key}
                        onApply={changeColor(country.key)}
                      />
                      <span>{country.key}</span>
                    </LegendItem>
                    <Value>
                      <SmallSum>{country.count}</SmallSum>
                      <SmallDifferences isPositive={country?.is_positive || true}>
                        {/* eslint-disable-next-line no-constant-condition */}
                        {(country?.is_positive || true) ? '+' : '-'}
                        {country.differences}
                      </SmallDifferences>
                    </Value>
                  </CountryWrapper>
                ))}
              </Legend>
              <Pagination>
                <Button
                  onClick={(e) => scrollLegend(e, false)}
                  disabled={disabledPrevButton}
                >
                  <ArrowLeftIcon />
                </Button>
                <Button
                  onClick={(e) => scrollLegend(e, true)}
                  disabled={disabledNextButton}
                >
                  <ArrowRightIcon />
                </Button>
              </Pagination>
            </>
          )}
        </RelativeRow>
        <ChartWrapper ref={chartWrapper}>
          {statisticArray.length > 0 && renderChart()}
        </ChartWrapper>
      </Block>
    </ExpandedBlock>
  );
};

export default UserContainer;

const Block = styled.div`
  padding-bottom: 16px;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  padding: 0 20px;
  justify-content: ${({ justify }) => justify};
`;

const RelativeRow = styled(Row)`
  position: relative;
  min-height: 65px;
  background: ${white};
  padding: 20px;
  border-bottom: 1px solid ${borderSecondary};
`;

const Label = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${activeTertiary};
  svg {
    margin-right: 9px;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid ${borderSecondary};
  padding-right: 50px;
  margin-right: 30px
`;

const RadioLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const Value = styled.div`
  display: flex;
`;

const Sum = styled(Text)`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  transition: all .25s ease-in-out 0s;

  ${({ isActive }) => isActive && `
    font-weight: 600;
    font-size: 32px;
  `}
`;

const Differences = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: ${({ isActive }) => (isActive ? '24px' : '17px')};
  line-height: 15px;
  letter-spacing: 0.16px;
  color: ${({ isPositive }) => (isPositive ? '#8AC44B' : '#F04438')};
  margin-left: 5px;
`;

const SmallSum = styled(Sum)`
  font-weight: 400;
`;

const SmallDifferences = styled(Differences)`
  font-size: 16px;
`;

const ChartWrapper = styled.div``;

const SelectAllWrapper = styled.div`
  border-right: 1px solid ${borderSecondary};
  padding-right: 18px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: ${white};
  z-index: 1;
`;

const Legend = styled.div`
  display: flex;
  position: absolute;
  left: 145px;
  top: 10px;
  transition:left .5s linear;
`;

const CountryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 16px;
  margin-right: 10px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: ${white};
  padding-right: 25px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  width: 32px;
  height: 32px;
  margin-left: 7px;
  cursor: pointer;
  path {
    fill: ${text01}
  }
  ${({ disabled }) => disabled && `
    pointer-events: none;
    path {
      fill: ${greyText};
    }
  `}
`;

const Actions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DropDownLabel = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: ${text01};
  margin-right: 7px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 115px;
  margin: 0 10px;
`;
