import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  LOAD_DEVICES, LOAD_DEVICES_COUNT,
  LOAD_ALL_DEVICES,
  LOAD_PLAYLIST, LOAD_SERVERS, LOAD_MINISTRA,
  LOAD_USER, LOAD_WITHDRAWAL_WALLETS, LOAD_WITHDRAWAL_SYSTEMS,
  SET_PREMIUM, SET_RIGHT_DEVICE, SET_SELECTED_DEVICES,
  SET_SNACK,
  SET_WELCOME_CLOSED, SET_PAGE_HEADER_DISABLED, SET_CHANNELS_LOGO_CACHE,
} from '@/store/user/types';
import thunk from 'redux-thunk';

function rootReducer(state, action) {
  switch (action.type) {
    case LOAD_USER:
      return {
        ...state,
        userInfo: action.payload,
      };
    case LOAD_WITHDRAWAL_WALLETS:
      return {
        ...state,
        withdrawalWallets: action.payload,
      };
    case LOAD_WITHDRAWAL_SYSTEMS:
      return {
        ...state,
        withdrawalSystems: action.payload,
      };
    case LOAD_PLAYLIST:
      return {
        ...state,
        playlists: action.payload,
      };
    case LOAD_SERVERS:
      return {
        ...state,
        servers: action.payload,
      };
    case LOAD_MINISTRA:
      return {
        ...state,
        ministra: action.payload,
      };
    case LOAD_DEVICES:
      return {
        ...state,
        devices: action.payload,
      };
    case LOAD_ALL_DEVICES:
      return {
        ...state,
        allDevices: action.payload,
      };
    case LOAD_DEVICES_COUNT:
      return {
        ...state,
        devicesCount: action.payload,
      };
    case SET_RIGHT_DEVICE:
      return {
        ...state,
        rightDevice: action.payload,
      };
    case SET_SNACK:
      return {
        ...state,
        snack: action.payload,
      };
    case SET_PREMIUM:
      return {
        ...state,
        premium: action.payload,
      };
    case SET_WELCOME_CLOSED:
      return {
        ...state,
        welcomeClosed: action.payload,
      };
    case SET_PAGE_HEADER_DISABLED:
      localStorage.setItem('pageHeaderDisabled', JSON.stringify(action.payload));
      return {
        ...state,
        pageHeaderDisabled: action.payload,
      };
    case SET_SELECTED_DEVICES:
      localStorage.setItem('selectedDevices', JSON.stringify(action.payload));
      return {
        ...state,
        selectedDevices: action.payload,
      };
    case SET_CHANNELS_LOGO_CACHE:
      return {
        ...state,
        channelsLogos: action.payload,
      };
    default:
      return state;
  }
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export { store };
