import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { secondary, white, text01 } from '@/common/constants/theme';
import Loader from '@/common/components/Loader';
import AuthorizationLayout from '../components/AuthorizationLayout';
import { confirmEmailAction } from '@/services/actions/authorization.actions';

function SignUpCompleteContainer({ history, match }) {
  const { t } = useTranslation(['translations']);
  const [isLoading, setLoading] = useState(true);
  const { token } = match.params;
  useEffect(() => {
    setLoading(false);
    confirmEmailAction(token)
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        // history.push('/not-found');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <AuthorizationLayout>
      <Title>
        <TitleText>{t('authorization.signUp.complateTitleBlack')}</TitleText>
        <TitleTextOrange>{t('authorization.signUp.complateTitleOrange')}</TitleTextOrange>
      </Title>
      <Form>
        <ContentTitle>{t('authorization.signUp.complateContentTitle')}</ContentTitle>
        <ContentText>{t('authorization.signUp.complateContentText')}</ContentText>
        <Button onClick={() => history.push('/sign-in')}>
          {t('authorization.signUp.complateContentButton')}
        </Button>
      </Form>
    </AuthorizationLayout>
  );
}

export default withRouter(SignUpCompleteContainer);

const Title = styled.div`
  margin-top: 10rem;
  width: 40rem;
`;

const TitleText = styled.span`
  font-family: Gilroy;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;
`;

const TitleTextOrange = styled(TitleText)`
  color: ${secondary}
`;

const Form = styled.div`
  height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  padding-top: 7.5rem;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const ContentTitle = styled.div`
  font-family: Gilroy;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: ${text01};

`;

const ContentText = styled.span`
  font-family: Gilroy;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin: .75rem 0;
  max-width: 25rem;
  white-space: pre-wrap;
`;

const Button = styled.button`
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
  margin-top: 1rem;
`;
