/* eslint-disable no-unused-vars, no-console */
import React, {
  createContext,
  useState,
  useContext, useEffect,
} from 'react';

import {
  sendTestEmailAction,
} from '@/services/actions/admin/admin.actions';

import {
  getTemplatesItemAction,
  updateTemplateAction,
  updateTemplatesContentAction,
  uploadImageAction,
  deleteImageAction,
} from '@/services/actions/admin/templates.actions';

import {
  getTemplateDecorsListAction,
  getTemplateDecorsItemAction,
} from '@/services/actions/admin/templateDecors.actions';

import { arrayEdit } from '@/common/utils/arrayMove.util';
import { generateRandomString } from '@/common/utils/randomStringGenerator.util';

import { SnackTypes, useAppContext } from '@/AppContext';
import { useAdminContext } from '../../../../AdminContext';

export const DATA_TYPES = {
  template: 'template',
  templateDecor: 'templateDecor',
  templateContent: 'templateContent',
  languages: 'languages',
  images: 'images',
};

const MOCK_DECORS = [
  {
    id: 1,
    name: 'шаблон 1',
    description: 'описание шаблона 1',
  },
  {
    id: 2,
    name: 'шаблон 2',
    description: 'описание шаблона 2',
  },
];
export const TEMPLATE_TYPES = {
  email: 'email',
  notification: 'notification',
};

const TemplatesEditContext = createContext({});

// noinspection JSUnusedLocalSymbols
export default ({ children }) => {
  const { setAdminSnack } = useAdminContext();

  const [languages, setLanguages] = useState([]);
  const [templateDecors, setTemplateDecors] = useState(null);
  const [template, setTemplate] = useState(null);
  const [templateParameters, setTemplateParameters] = useState(null);

  const { siteLanguages } = useAppContext();

  const loadTemplateDecors = () => {
    getTemplateDecorsListAction({ offset: 0, limit: 1000 })
      .then((res) => {
        setTemplateDecors(res?.results || []);
      })
      .catch(() => setTemplateDecors(MOCK_DECORS));
  };

  const langToOption = (lang) => ({
    key: lang.id,
    title: lang.name,
    isDefault: lang.is_default,
  });

  const getTemplate = (id) => {
    getTemplatesItemAction(id)
      .then((res) => {
        setTemplate(res);

        const paramsArray = (res.template_params || '').length > 0 ? JSON.parse(res.template_params) : [];
        const params = [];
        paramsArray.forEach((item) => {
          (Object.keys(item) || []).forEach((field) => {
            params.push({ key: field, value: item[field] });
          });
        });
        setTemplateParameters(params);
      })
      .catch((err) => setAdminSnack(err));
  };

  const updateTemplate = (id, data) => updateTemplateAction(id, data).then((res) => {
    setTemplate((prevState) => ({ ...prevState, email_template: res.email_template }));
    return res;
  });

  const updateTemplatesContent = (id, data) => {
    updateTemplatesContentAction(id, data);
    const newContents = arrayEdit(
      template.contents,
      data,
      template.contents.findIndex((item) => (item.id === id)),
    );
    setTemplate((prevState) => ({ ...prevState, contents: newContents }));
  };

  const validateTemplateParameters = (text) => {
    const result = [];
    templateParameters.forEach((param) => {
      const regex = new RegExp(`({%(.*)${param.key.replace('{{', '').replace('}}', '')}([^{%])%})+?`, 'g');
      if (!text.includes(param.key) && !regex.test(text)) {
        result.push(param.key);
      }
    });
    return result;
  };

  const getParametersForTestEmail = () => {
    const retVal = {};
    (templateParameters || []).forEach((p) => {
      retVal[p.key] = generateRandomString();
    });
    return retVal;
  };

  const getFakeContent = (fcTemplate, params = [], definedParams = {}) => {
    let retVal = fcTemplate;
    Object.entries(definedParams).forEach(([key, value]) => {
      const regex = new RegExp(key, 'g');
      retVal = retVal.replace(regex, value);
    });
    params.forEach((p) => {
      const regex = new RegExp(p, 'g');
      retVal = retVal.replace(regex, generateRandomString());
    });
    return retVal;
  };

  const sendTestEmail = async (emailData) => {
    console.log('emailData', emailData);
    console.log('languages', languages);
    if (!languages.find((l) => l.key === (emailData?.content?.language || -1))
      || ((emailData?.content?.text_content || '').length === 0)
      || ((emailData?.content?.html_content || '').length === 0)) {
      return Promise.reject(new Error('Не все данные переданы'));
    }

    // get templateDecor
    // eslint-disable-next-line no-unreachable
    // const templateDecorItem = emailData.decorTemplate
    //   ? await getTemplateDecorsItemAction(emailData.decorTemplate) : null;

    // const localizedTemplateDecor = (templateDecorItem?.contents || [])
    //   .find((c) => c.language_id === emailData.content.language) || null;

    // get fake content
    const definedParams = {
      '{{protocol}}': window.location.protocol.replace(':', ''),
      '{{domain}}': window.location.hostname,
    };
    const dataToSend = {
      email_template_id: 1,
      language_id: emailData?.content?.language,
      subject: emailData?.content?.subject,
      html_content: emailData?.content?.html_content,
      text_content: emailData?.content?.text_content,
      template_context: getParametersForTestEmail(),
      email: emailData.email,
    };
    // const dataToSend = {
    //   email: emailData.email,
    //   email_template_id: emailData.decorTemplate,
    //   language_id: emailData?.content?.language || -1,
    //   subject: emailData?.subject || 'Test email',
    //   text_content: localizedTemplateDecor?.text_content
    //     ? localizedTemplateDecor?.text_content
    //       .replace(/{{content}}/g, getFakeContent(
    //       emailData.content.text_content, fakeParams, definedParams))
    //     : getFakeContent(emailData.content.text_content, fakeParams, definedParams),
    //   html_content: localizedTemplateDecor?.html_content
    //     ? localizedTemplateDecor?.html_content
    //       .replace(/{{content}}/g, getFakeContent(
    //       emailData.content.html_content, fakeParams, definedParams))
    //     : getFakeContent(emailData.content.html_content, fakeParams, definedParams),
    //   context: 'context',
    // };

    console.log('dataToSend', dataToSend);

    return sendTestEmailAction(dataToSend);
  };

  const uploadImage = (file, content) => uploadImageAction(file, content.id)
    .then((res) => {
      if ((res?.id || 0) > 0) {
        const contentIndex = template.contents.findIndex((c) => c.id === content.id);
        const newContent = { ...content, images: [...(content.images || []), res] };
        const newContents = [...template.contents || []];
        newContents.splice(contentIndex, 1, newContent);
        setTemplate((prevState) => ({ ...prevState, contents: newContents }));
      }
      return res;
    })
    .catch((err) => setAdminSnack(err, SnackTypes.error));

  const deleteImage = (id, content) => deleteImageAction(id)
    .then(() => {
      const contentIndex = template.contents.findIndex((c) => c.id === content.id);
      const newContent = {
        ...content,
        images: [...(content.images || []).filter((img) => img.id !== id)],
      };
      const newContents = [...template.contents || []];
      newContents.splice(contentIndex, 1, newContent);
      setTemplate((prevState) => ({ ...prevState, contents: newContents }));
    })
    .catch((err) => setAdminSnack(err, SnackTypes.error));

  useEffect(() => {
    if (siteLanguages) {
      setLanguages(siteLanguages.map(langToOption));
    }
  }, [siteLanguages]);

  useEffect(() => {
    if (!templateDecors) {
      loadTemplateDecors();
    }
  }, [templateDecors]);

  const value = {
    languages,
    getTemplate,
    template,
    templateParameters,
    templateDecors,
    updateTemplate,
    updateTemplatesContent,
    validateTemplateParameters,
    sendTestEmail,
    uploadImage,
    deleteImage,
  };

  return (
    <TemplatesEditContext.Provider value={value}>
      {children}
    </TemplatesEditContext.Provider>
  );
};

export const useTemplatesEditContext = () => useContext(TemplatesEditContext);
