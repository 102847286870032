import React from 'react';

export default () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path d="M4.5 0.868408H21.5V15.0526C21.5 15.881 20.8284 16.5526 20 16.5526H6C5.17157 16.5526 4.5 15.881 4.5 15.0526V0.868408Z" fill="white" stroke="#D9D9D9" />
      <path d="M22 0.320312H4V4.01172H22V0.320312Z" fill="#FF9800" />
      <path d="M15.6367 5.94531H13.5273V7.35156H15.6367V5.94531Z" fill="#6D7FD4" />
      <path d="M12.1211 5.94531H10.0117V7.35156H12.1211V5.94531Z" fill="#6D7FD4" />
      <path d="M18.8008 5.94531H16.6914V7.35156H18.8008V5.94531Z" fill="#6D7FD4" />
      <path d="M15.6367 8.40625H13.5273V9.8125H15.6367V8.40625Z" fill="#6D7FD4" />
      <path d="M12.1211 8.40625H10.0117V9.8125H12.1211V8.40625Z" fill="#6D7FD4" />
      <path d="M8.95703 8.40625H6.84766V9.8125H8.95703V8.40625Z" fill="#6D7FD4" />
      <path d="M18.8008 8.40625H16.6914V9.8125H18.8008V8.40625Z" fill="#6D7FD4" />
      <path d="M15.6367 10.8672H13.5273V12.2734H15.6367V10.8672Z" fill="#6D7FD4" />
      <path d="M12.1211 10.8672H10.0117V12.2734H12.1211V10.8672Z" fill="#6D7FD4" />
      <path d="M8.95703 10.8672H6.84766V12.2734H8.95703V10.8672Z" fill="#6D7FD4" />
    </g>
    <defs>
      <filter id="filter0_d" x="0" y="0.320312" width="26" height="24.7323" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);
