import React from 'react';

import Base from './Base';
import { BaseContextProvider } from './BaseContext';

const BaseContainer = () => (
  <BaseContextProvider>
    <Base />
  </BaseContextProvider>
);

export default BaseContainer;
