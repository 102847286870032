import RequestService from '../../request.service';
import urls from '../../routes/admin/dns.api';

export const getDomainsAction = () => RequestService(urls.domainList);

export const createDomainAction = (item) => RequestService(urls.domainList, {
  method: 'POST',
  body: item,
});

export const deleteDomainByIdAction = (id, newDnsId) => RequestService(urls.domainItem(id), {
  method: 'DELETE',
  body: {
    new_domain_id: newDnsId,
  },
});
