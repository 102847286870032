export const handleBlockCountryAction = (data, i, key) => {
  const state = [...data];
  state[i].disabled_countries.push(key);
  return state;
};

export const handleUnblockCountryAction = (data, i, key) => {
  const id = typeof key === 'string'
    ? data[i].disabled_countries.findIndex((item) => item === key)
    : data[i].disabled_countries.findIndex((item) => item === key.key);
  const state = [...data];
  const disabledCountries = [...state[i].disabled_countries];
  state[i].disabled_countries = [
    ...disabledCountries.slice(0, id),
    ...disabledCountries.slice(id + 1),
  ];
  return state;
};

export const handleUnblockAllCountriesAction = (data, i) => {
  const state = [...data];
  state[i].disabled_countries = [];
  return state;
};
