// import React from 'react';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  activeTertiary, primary, secondary, text01, white,
} from '@/common/constants/theme';

import ArrowVerticalCustomizable, { DIRECTION_UP, DIRECTION_DOWN } from '@/common/components/icons/ArrowVerticalCustomizable';
import Loader from '@/common/components/Loader';
import Selector from '@/common/components/Selector';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import DevicePlaylist from './Components/DevicePlaylist';
import DevicePortal from './Components/DevicePortal';

import {
  getRightDevice, setRightDevice, subscribeTypes,
  processDevicesForRightBlock,
} from '@/store/user/selectors';
import {
  startDeviceViewTypeChangeAction, statusDeviceViewTypeChangeAction,
} from '@/services/actions/devices.actions';

import { usePersonalPageContext } from '../../PersonalPageContext';

const emptyMessage = { type: '', text: '' };
const allowedSubscriptionTypes = [subscribeTypes.Active, subscribeTypes.Ending];

const DevicesFullInfoBlock = () => {
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();
  const rightDevice = useSelector(getRightDevice);

  const {
    allDevices,
    updateDeviceParameters,
  } = usePersonalPageContext();

  const [message, setMessage] = useState(emptyMessage);
  const [isSelectorBusy, setIsSelectorBusy] = useState(false);
  const [localDevices, setLocalDevices] = useState([]);
  const [localRightDevice, setLocalRightDevice] = useState(null);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);

  const clearMessage = () => { setMessage(emptyMessage); };

  const updateDeviceType = (device) => {
    setIsSelectorBusy(true);

    const deviceTypeCheckPause = 1000 * 2;
    let setDeviceTypeCheckInterval = null;
    const {
      // eslint-disable-next-line camelcase
      id, ministra_server, ministra_login, ministra_password, view_type,
    } = device;

    startDeviceViewTypeChangeAction({
      id,
      view_type,
      ministra_server,
      ministra_login,
      ministra_password,
    })
      .then((res) => {
        const status = res?.status;
        const taskId = res?.task_id;
        if (status === 'processed' && taskId) {
          // eslint-disable-next-line max-len
          setDeviceTypeCheckInterval = setInterval(() => (statusDeviceViewTypeChangeAction(id, taskId)
            .then((statusRes) => {
              const resStatus = statusRes?.status;
              if (resStatus === 'processed') {
                // eslint-disable-next-line no-console
                console.log(`device type check status [${resStatus}]`);
              } else {
                if (resStatus === 'success') {
                  updateDeviceParameters(id, statusRes?.device_data || {});
                } else {
                  setMessage({ type: 'error', text: `${t('steps.step3.deviceType.errors.change')}:\n${t(`steps.step3.deviceType.errors.${statusRes?.exceptions}`)}` });
                }
                setIsSelectorBusy(false);
                if (setDeviceTypeCheckInterval) { clearInterval(setDeviceTypeCheckInterval); }
              }
            })
            .catch((statusErr) => {
              setMessage({ type: 'error', text: `${t('steps.step3.deviceType.errors.change')}:\n ${t(`steps.step3.deviceType.errors.${statusErr?.response?.payload?.message_type || statusErr?.data?.message_type}`)}` });
              setIsSelectorBusy(false);
              if (setDeviceTypeCheckInterval) { clearInterval(setDeviceTypeCheckInterval); }
            })),
          deviceTypeCheckPause);
        } else {
          setMessage({ type: 'error', text: `${t('steps.step3.deviceType.errors.change')}: ${t(`steps.step3.deviceType.errors.${res?.message_type}`)}` });
          setIsSelectorBusy(false);
        }
      })
      .catch((err) => {
        setMessage({ type: 'error', text: `${t('steps.step3.deviceType.errors.change')}:\n${t(`steps.step3.deviceType.errors.${err?.response?.payload?.message_type || err?.data?.message_type}`)}` });
        setIsSelectorBusy(false);
      });
  };

  useEffect(() => {
    setLocalDevices(
      allDevices.filter(processDevicesForRightBlock),
    );
  }, [allDevices]);

  useEffect(() => {
    let useDevice = null;
    if (
      rightDevice
      && allowedSubscriptionTypes.includes(rightDevice.subscription_type)
      && rightDevice?.server
    ) {
      useDevice = rightDevice;
    }
    setLocalRightDevice(useDevice);
  }, [rightDevice]);

  const renderCollapseButton = () => (
    <CollapseControl>
      <CollapseControlContainer
        onClick={() => setIsPanelCollapsed((ps) => !ps)}
      >
        <CollapseTextPart>
          {t(`common.${isPanelCollapsed ? 'unCollapseText' : 'collapseText'}`)}
        </CollapseTextPart>
        <CollapseIconPart>
          <ArrowVerticalCustomizable
            fill={secondary}
            size={18}
            direction={isPanelCollapsed ? DIRECTION_DOWN : DIRECTION_UP}
          />
        </CollapseIconPart>
      </CollapseControlContainer>
    </CollapseControl>
  );

  const renderDeviceSelector = () => (
    <Selector
      disabled={isSelectorBusy}
      onSelect={(device) => {
        const selectedDevice = localDevices.find((d) => d.id === device.key);
        dispatch(setRightDevice(selectedDevice));
      }}
      options={localDevices.map((option) => (
        { title: option.name, key: option.id, value: option.id }
      ))}
      value={rightDevice?.id ? {
        title: rightDevice.name,
        key: rightDevice.id,
        value: rightDevice.id,
      } : {
        title: t('devicesInfo.deviceNotSelected'),
        key: 0,
        value: 0,
      }}
      uppercaseSelected
    />
  );

  return (
    <>
      <SnackBarWindow
        type={message.type}
        content={message.text}
        isOpen={message.text !== ''}
        onClose={clearMessage}
      />
      <Wrapper>
        <div>
          <Container>
            {isSelectorBusy && <Loader />}
            <Row>
              <Title>{t('devicesInfo.device')}</Title>
              {(localDevices.length > 0) && renderDeviceSelector()}
            </Row>
            <ContentBlock style={{ height: isPanelCollapsed ? 0 : 'auto' }}>
              {localRightDevice?.view_type === 'playlist'
                && (
                  <DevicePlaylist
                    devices={allDevices}
                    device={localRightDevice}
                    setIsSelectorBusy={setIsSelectorBusy}
                  />
                )}
              {localRightDevice?.view_type === 'portal'
                && (
                  <DevicePortal
                    device={localRightDevice}
                    updateDeviceType={updateDeviceType}
                  />
                )}
            </ContentBlock>
          </Container>
        </div>
        {renderCollapseButton()}
      </Wrapper>
    </>
  );
};

export default DevicesFullInfoBlock;

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  background: ${white};
  border: 1px solid #efeef3;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0,0,0,0.11);
  border-radius: 2px;
  min-height: 50px;
  border-top: 2px solid ${activeTertiary};
  ${({ disabled }) => disabled && `
    opacity: .5;
    pointer-events: none;
  `}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;
  border-bottom: solid 1px #EFEEF3;
`;

const Title = styled.span`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
  margin: .25rem 0;
`;

const ContentBlock = styled.div`
  transition: all .25s ease-in-out 0s;
  overflow: hidden;
`;

const CollapseTextPart = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  letter-spacing: .16px;
  height: 1.5em;
`;

const CollapseIconPart = styled.div`
  display: flex;
  margin-left: .25rem;
`;

const CollapseControlContainer = styled.div`
  display: flex;
  align-items: center;
  padding: .125rem .5rem;
  margin: .125rem .25rem;
  border: solid 1px ${primary};
  border-radius: .25rem;
  cursor: pointer;
`;

const CollapseControl = styled.div`
  position: absolute;
  bottom: calc(-1.5em / 2);
  left: 50%;
  transform: translateX(-50%) translateZ(0);
  background: ${white};
`;
