import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSnack } from '@/store/user/selectors';

import styled from 'styled-components';
import { primary, white, text02 } from '@/common/constants/theme';

import CheckIcon from '../../../assets/icons/check-blue.svg';

import { usePersonalPageContext } from '../PersonalPageContext';

const StepsIndicator = ({ selectedDevice }) => {
  const { t } = useTranslation('translations');

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useHistory().location;

  const {
    selectedDevices,
    allDevices,
  } = usePersonalPageContext();

  const defaultSteps = [
    {
      key: 'devices',
      title: t('steps.step1.title'),
      url: '/personal-page/devices',
    },
    {
      key: 'subscription',
      title: t('steps.step2.title'),
      url: '/personal-page/subscription',
    },
    {
      key: 'server',
      title: t('steps.step3.title'),
      url: '/personal-page/server',
    },
    {
      key: 'channels',
      title: t('steps.step4.title'),
      url: '/personal-page/channels',
    },
    {
      key: 'playlist',
      title: t('steps.step5.title'),
      url: '/personal-page/playlist',
    },
  ];

  const getStepsState = () => {
    const currentStepIndex = defaultSteps.findIndex((step) => step.url === pathname) || 0;
    // const playListStepIndex = defaultSteps.findIndex((step) => step.key === 'playlist') || -1;
    const availableIndexes = Object.keys(defaultSteps).map((i) => parseInt(i, 10));
    const disabledIndexes = [];

    // disable 2 - 4 steps if not selected devices
    if ((selectedDevices || []).length === 0) {
      [1, 2, 3].forEach((i) => disabledIndexes.push(i));
    }

    // disable step 3 if no subscribe in all selected devices
    const isAllSelectedHasSubscribe = (selectedDevices || [])
      .filter((d) => !d.subscription).length === 0;
    if (!isAllSelectedHasSubscribe) {
      disabledIndexes.push(2);
    }

    // disable step 4 if not selected devices
    // or not set server in all selected devices
    const isServerSetInAllSelected = (selectedDevices || []).length > 0
      && (selectedDevices || [])
        .filter((sd) => sd.server === null).length === 0;
    if (!isServerSetInAllSelected) {
      disabledIndexes.push(3);
    }

    // disable step 5 if no devices available
    // or no devices with server
    const isServerNotSetInAllDevices = allDevices
      && allDevices.filter((d) => d.server !== null).length === 0;
    if (isServerNotSetInAllDevices || ((allDevices || []).length === 0)) {
      disabledIndexes.push(4);
    }

    const isStepAvailable = (index) => (
      availableIndexes.filter((i) => !disabledIndexes.includes(i))
        .includes(index)
    );

    const useSteps = [...defaultSteps];
    if (selectedDevice) {
      useSteps[4].title = t(`steps.step5.title-${selectedDevice.view_type}`);
    }

    return useSteps.map((s, index) => ({
      ...s,
      disabled: !isStepAvailable(index),
      active: index === currentStepIndex,
      completed: index < currentStepIndex,
      canStep: isStepAvailable(index),
    }));
  };

  const [steps, setSteps] = useState(getStepsState());

  const snacks = [
    {
      type: 'warning',
      status: 'open',
      message_type: 'selectAnyDevice',
      key: new Date(),
    },
    {
      type: 'warning',
      status: 'open',
      message_type: 'magDeviceSelected',
      key: new Date(),
    },
  ];

  useEffect(() => {
    const newSteps = getStepsState();
    setSteps(newSteps);
  }, [allDevices, selectedDevices, selectedDevice]);
  return (
    <Container>
      {steps.map((step, index) => (
        <Step
          key={`PP-StI-Step--${step.key}`}
          disabled={step.disabled}
          onClick={() => {
            if (step.disabled) { return false; }
            return (
              step.canStep
                ? history.push(step.url)
                : dispatch(setSnack(snacks[1]))
            );
          }}
        >
          <StepIndex
            isActive={step.active}
            isCompleted={step.completed}
          >
            {step.completed && step.canStep ? (
              <Icon src={CheckIcon} />
            ) : (
              index + 1
            )}
          </StepIndex>
          <StepTitle
            isActive={(step.completed && step.canStep) || step.active}
          >
            {step.title}
          </StepTitle>
        </Step>
      ))}
    </Container>
  );
};
export default StepsIndicator;

const Container = styled.div`
    margin: 0.25rem 3rem 1.25rem 3rem;
    display: flex;
    justify-content: space-around;
`;

const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
      ${({ disabled }) => disabled && (`
          opacity: .5;
          cursor: default;
        `)}
`;

const StepIndex = styled.div`
  position: relative;
  background: ${white};
  border: 1px solid #EFEEF3;
  box-shadow: 3px 4px 4px rgba(0,0,0,0.05);
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: ${text02};
    ${({ isActive }) => isActive && (`
      opacity: 1;
      color: ${white};
      background: ${primary};
    `)}

    ${({ isCompleted }) => isCompleted && `
      &:after {
        content: '';
        position: absolute;
        width: 5.5rem;
        height: 1px;
        top: 50%;
        left: 3.5rem;
        border-bottom: 1px dashed ${primary};
        opacity: 0.5;
        @media (min-width: 1198px) and  (max-width: 1360px){
          width: 4rem;
        }
      }
    `}
`;

const StepTitle = styled.span`
    font-family: Gilroy, sans-serif;
    font-size: 15px;
    line-height: 17px;
    margin-top: .5rem;
    color: ${text02};
    ${({ isActive }) => isActive && (`
        opacity: 1;
        color: ${primary};
    `)}
`;

const Icon = styled.img``;
