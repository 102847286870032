import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  background01,
  text01,
  text02,
  secondary,
} from '@/common/constants/theme';

import Loader from '@/common/components/Loader';

import { useProfileContext } from '../ProfileContext';
import { usePersonalPageContext } from '../../../../PersonalPageContext';

const Logs = () => {
  const { t } = useTranslation('translations');
  const { getDateTimeToDisplay } = usePersonalPageContext();
  const { logs, isLogsLoading } = useProfileContext();

  return (
    <>
      <BlockTitle>
        {t('profile.logs.title')}
      </BlockTitle>
      <Content>
        {isLogsLoading && <Loader />}
        {logs.map((log) => (
          <Row key={log.id}>
            <DateLabel>{getDateTimeToDisplay(log.created_at)}</DateLabel>
            <Message>
              {log.message}
            </Message>
          </Row>
        ))}
        <Nav>
          <NavItem to="/personal-page/logs">{t('profile.logs.linkText')}</NavItem>
        </Nav>
      </Content>
    </>
  );
};

export default Logs;

const BlockTitle = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${text01};
`;

const Content = styled.div`
  position: relative;
  margin-top: 2rem;
`;

const Row = styled.div`
  display: flex;
  padding: .935rem 1.625rem;
  margin: 0 -1.625rem;
  :nth-child(2n+1) {
    background: ${background01};
   }
`;

const DateLabel = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text02};
  margin-right: 2rem;
  min-width: 7rem;
`;

const Message = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${text01};
`;

const Nav = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: .75rem;
`;

const NavItem = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${secondary};
  white-space: nowrap;
  border-radius: 4px;
  &&:hover {
    color: ${secondary};
  }
`;
