import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { parse, isValid, format } from 'date-fns';
import { ru } from 'date-fns/locale';

import {
  primary,
  text03,
  grey, white, red,
  inputBorder,
  text01,
} from '@/common/constants/theme';
import Button from '@/common/components/Button';
import DatePicker from '@/common/components/DatePicker';
import CalendarIcon from '../../../icons/CalendarIcon';
import { useAppContext } from '@/AppContext';
import { useReferralsContext } from '../ReferralsContext';

const DATE_FORMAT = 'dd.MM.yyyy';
const DATE_REGEX_MASK = /(?<d>\d{1,2})\.(?<m>\d{1,2})\.(?<y>\d{2,4})/;
const DATE_INPUT_MASK = '99.99.9999';

const ReferralsTableFilters = ({
  startDateValue,
  endDateValue,
  applyHandler,
  // resetHandler,
  editableDates = false,
}) => {
  const { t } = useTranslation('translations');

  const { isDatesEqualByDate } = useAppContext();
  const {
    referralsStatistics,
  } = useReferralsContext();

  const [isStartCalendarOpened, setIsStartCalendarOpened] = useState(false);
  const [isEndCalendarOpened, setIsEndCalendarOpened] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedStartDateString, setSelectedStartDateString] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedEndDateString, setSelectedEndDateString] = useState();
  const [pickerStartValue, setPickerStartValue] = useState();
  const [pickerEndValue, setPickerEndValue] = useState();
  const [isChanged, setIsChanged] = useState(false);

  const dateToString = (d) => (d ? format(d, DATE_FORMAT) : '');
  const stringToDate = (s) => {
    let retVal = null;
    if (((s?.length || 0) > 0) && (new RegExp(DATE_REGEX_MASK)).test(s)) {
      retVal = parse(s, 'P', new Date(), { locale: ru });
    }
    return isValid(retVal) ? retVal : null;
  };

  const checkDateIsCorrect = (d) => (
    ((d?.length || 0) > 0
      && (new RegExp(DATE_REGEX_MASK)).test(d)
      && (stringToDate(d)) !== null)
  );

  const checkDatesEqual = () => setIsChanged(
    (selectedStartDate && !isDatesEqualByDate(startDateValue, selectedStartDate, false))
    || (selectedEndDate && !isDatesEqualByDate(endDateValue, selectedEndDate, false)),
  );

  const setLocalStartDate = (v) => {
    setSelectedStartDate(v);
    setSelectedStartDateString(dateToString(v));
    setPickerStartValue(v);
  };

  const setLocalEndDate = (v) => {
    setSelectedEndDate(v);
    setSelectedEndDateString(dateToString(v));
    setPickerEndValue(v);
  };

  const resetHandler = () => {
    setLocalStartDate(startDateValue);
    setLocalEndDate(endDateValue);
  };

  useEffect(() => {
    setLocalStartDate(startDateValue);
  }, [startDateValue]);

  useEffect(() => {
    setLocalEndDate(endDateValue);
  }, [endDateValue]);

  useEffect(() => {
    checkDatesEqual();
  }, [startDateValue, endDateValue, selectedEndDate, selectedStartDate]);

  return (
    <Container>
      <Wrapper flex topMargin bottomMargin>
        {selectedStartDate && editableDates ? (
          <StyledInputControl
            mask={DATE_INPUT_MASK}
            value={selectedStartDateString}
            onChange={({ target }) => {
              setSelectedStartDateString(target.value);
              if (checkDateIsCorrect(target.value)) {
                const tDate = stringToDate(target.value);
                setSelectedStartDate(tDate);
                setPickerStartValue(tDate);
              }
            }}
            isError={!checkDateIsCorrect(selectedStartDateString)}
          />
        ) : (
          <StyledDiv onClick={() => setIsStartCalendarOpened(true)}>
            {selectedStartDate
              ? format(new Date(selectedStartDate), 'd.MM.yyyy')
              : ''}
          </StyledDiv>
        )}
        <DatePicker
          isOpen={isStartCalendarOpened}
          value={pickerStartValue}
          maxDate={selectedEndDate}
          onChange={setPickerStartValue}
          onApply={(value) => {
            const vDate = new Date(value);
            setSelectedStartDate(vDate);
            setSelectedStartDateString(dateToString(vDate));
          }}
          onClose={() => setIsStartCalendarOpened(false)}
          applyButtonDisabled={isDatesEqualByDate(pickerStartValue, selectedStartDate, false)}
        />
        <Icon
          topMargin
          rightMargin
          onClick={() => setIsStartCalendarOpened(true)}
        >
          <CalendarIcon />
        </Icon>
        <Text rightMargin>-</Text>
        {selectedEndDate && editableDates ? (
          <StyledInputControl
            mask={DATE_INPUT_MASK}
            value={selectedEndDateString}
            onChange={({ target }) => {
              setSelectedEndDateString(target.value);
              if (checkDateIsCorrect(target.value)) {
                const tDate = stringToDate(target.value);
                setSelectedEndDate(tDate);
                setPickerEndValue(tDate);
              }
            }}
            isError={!checkDateIsCorrect(selectedEndDateString)}
          />
        ) : (
          <StyledDiv
            onClick={() => setIsEndCalendarOpened(true)}
          >
            {selectedEndDate
              ? format(new Date(selectedEndDate), 'd.MM.yyyy')
              : ''}
          </StyledDiv>
        )}
        <DatePicker
          isOpen={isEndCalendarOpened}
          value={pickerEndValue}
          minDate={selectedStartDate}
          onChange={setPickerEndValue}
          onApply={(value) => {
            const vDate = new Date(value);
            setSelectedEndDate(vDate);
            setSelectedEndDateString(dateToString(vDate));
          }}
          onClose={() => setIsEndCalendarOpened(false)}
          applyButtonDisabled={isDatesEqualByDate(pickerEndValue, selectedEndDate, false)}
        />
        <Icon
          topMargin
          onClick={() => setIsEndCalendarOpened(true)}
        >
          <CalendarIcon />
        </Icon>
      </Wrapper>
      <Wrapper flex>
        <StyledButton
          isBoldText
          isUpperCase
          isDisabled={!isChanged}
          onClick={() => {
            applyHandler({ start: selectedStartDate, end: selectedEndDate });
          }}
        >
          {t('table.apply')}
        </StyledButton>
        <PrimaryText
          pointer={isChanged}
          disabled={!isChanged}
          onClick={isChanged ? resetHandler : null}
        >
          {t('table.cleanFilter')}
        </PrimaryText>
      </Wrapper>
      <Filters>
        <Wrapper
          flex
          flexSb
          bottomMarginLight
        >
          <Text>{t('referrals.table.filters.reg')}</Text>
          <Text>{referralsStatistics?.registered_count || '-'}</Text>
        </Wrapper>
        <Wrapper flex flexSb bottomMarginLight>
          <Text>{t('referrals.table.filters.replenishment')}</Text>
          <Text>{referralsStatistics?.replenishment_count || '-'}</Text>
        </Wrapper>
        <Wrapper flex flexSb bottomMarginLight>
          <Text>{t('referrals.table.filters.sum')}</Text>
          <Text>{referralsStatistics ? `$ ${referralsStatistics.replenishment_amount}` : '-'}</Text>
        </Wrapper>
        <Wrapper flex flexSb bottomMarginLight>
          <Text>{t('referrals.table.filters.avg')}</Text>
          <Text>{referralsStatistics ? `$ ${referralsStatistics.replenishment_avg}` : '-'}</Text>
        </Wrapper>
        <Wrapper flex flexSb>
          <Text>{t('referrals.table.filters.refPeriod')}</Text>
          <Text>{referralsStatistics ? `$ ${referralsStatistics.referral_amount}` : '-'}</Text>
        </Wrapper>
      </Filters>
    </Container>
  );
};

export default ReferralsTableFilters;

const Container = styled.div``;

const Wrapper = styled.div`
  ${({ flex }) => flex && (`
    display: flex;
    align-items: center;
  `)}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}

  ${({ topMargin }) => topMargin && ('margin-top: 12px;')}
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 35px;')}
  ${({ bottomMarginLight }) => bottomMarginLight && ('margin-bottom: 9px;')}

  & .main-datepicker {
    left: -90px;
    bottom: -20px;
  }
`;

const Filters = styled.div`
  margin-top: 30px;
`;

const StyledButton = styled(Button)`
  width: 116px;
`;

const Text = styled.div`
  ${({ rightMargin }) => rightMargin && ('margin-right: 11px;')};

  cursor: ${({ pointer, disabled }) => (pointer && !disabled ? 'pointer' : 'default')};

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 17px;
  color: ${text03};
`;

const PrimaryText = styled(Text)`
  margin-left: 35px;

  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${({ disabled }) => (disabled ? grey : primary)};
`;

const Icon = styled.div`
  ${({ topMargin }) => topMargin && (`
    position: relative;
    top: 8px;
  `)};
  ${({ rightMargin }) => rightMargin && ('margin-right: 12px;')};
  margin-left: 10px;

  cursor: pointer;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 90px;
  height: 32px;

  background: ${white};
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: ${text01};
`;

const StyledInputControl = styled(InputMask)`
  width: 7rem !important;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  box-sizing: border-box;
  padding: .25rem .75rem;
  color: ${text01};
  background: ${white};
  cursor: pointer;
  height: 28px;
  border-radius: 2px;
  outline: none;
  border: 1px solid ${({ isError }) => (isError ? red : '#EFEEF3')};
  transition: all 0.15s ease-in 0s;

  &:read-only {
    opacity: 0.9;
    cursor: auto;
    border: 1px solid transparent;
  }

  &&:focus {
    box-shadow: none;
    border: 1px solid #c0c6e0;
  }
`;
