/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { RadioAlt } from '@/common/components/RadioButtonAlt';
import {
  activeTertiary,
  borderSecondary,
  green,
  greySecondary,
  greyTertiary,
  primary,
  red,
  secondary,
  text01,
  text02, text03,
  text04,
  white,
} from '@/common/constants/theme';
import DangerIcon from '@/assets/icons/dangerIcon.svg';
import Tooltip from '@/common/components/Tooltip';
import Table from '@/common/components/Table/Table';
import TableHead from '@/common/components/Table/components/TableHead';
import TableBody from '@/screens/PersonalPageScreen/components/PageContent/components/Table/components/TableBody';
import { useServerContext } from '../../ServerContext';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRightDevice,
  getSelectedDevices,
  setServerForSelected,
} from '@/store/user/selectors';

const FIRST_LABEL_TEX_MAX_LEN = 30;

const SERVER_COLUMN_WIDTH = {
  stalker: '130px',
  playlist: '200px',
};
const SERVER_COLUMN_WIDTH_FW = {
  stalker: '180px',
  playlist: '280px',
};

const CELL_MARGIN = 1;
const ROW_PADDING = 9;

const ServerTable = ({
  isFullWidth,
  selectedValue,
  setSelectedValue,
  isStalker,
  isLogged,
  setIsLoginModalOpened,
}) => {
  const { t } = useTranslation('translations');

  const {
    displayedServers,
    testedServers,
    testingServer,
    stalkerServers,
    serversFilters,
    setServersFilters,
    isFinalModal,
    setIsFinalModal,
    isServersEqual,
    speedStatsData,
    currentServerTestProgress,
    handleStartStopOneServerTest,
  } = useServerContext();

  const {
    page,
    limit,
  } = serversFilters;

  const dispatch = useDispatch();

  const rightDevice = useSelector(getRightDevice);
  const selectedDevices = useSelector(getSelectedDevices);

  const [checked, setChecked] = useState(null);
  const [sizes, setSizes] = useState(isFullWidth ? [
    SERVER_COLUMN_WIDTH_FW.playlist, '105px', '65px', '90px', '607px', '80px',
  ] : [
    SERVER_COLUMN_WIDTH.playlist, '95px', '55px', '80px', '347px', '80px',
  ]);

  const filtersHandler = (field, value) => {
    setServersFilters({ ...serversFilters, [field]: value });
  };

  const headers = [
    {
      title: t('steps.step3.content.main.table.header.server'),
    },
    {
      title: t('steps.step3.content.main.table.header.speed'),
      units: t('common.units.speed.MBit-s'),
    },
    {
      title: t('steps.step3.content.main.table.header.ping'),
      units: t('common.units.time.ms'),
    },
    {
      title: t('steps.step3.content.main.table.header.jitter'),
    },
    {
      title: t('steps.step3.content.main.table.header.recommendations'),
      greenCircle: false,
    },
    {
      title: t('steps.step3.content.main.table.header.test'),
    },
  ];

  // const testedServerData = (id) => testedServers.find((item) => item.id === id);
  const testedServerData = (id) => testedServers[id] || null;

  const renderSpeed = (item, i) => {
    const testedData = testedServerData(item.id) || null;
    return (
      <RowItem key={`speed_${i}`}>
        <Text useOpacity fontSizeLarge leftMargin={!testedData ? (CELL_MARGIN / 2) : 0}>
          {(testedData?.dlStatus || '-')}
        </Text>
      </RowItem>
    );
  };

  const renderPing = (item, i) => {
    const testedData = testedServerData(item.id) || null;
    return (
      <RowItem key={`ping_${i}`}>
        <Text useOpacity fontSizeLarge leftMargin={!testedData ? (CELL_MARGIN / 2) : 0}>
          {(testedData?.pingStatus || '-')}
        </Text>
      </RowItem>
    );
  };

  const renderJitter = (item, i) => {
    const testedData = testedServerData(item.id) || null;
    return (
      <RowItem key={`jitter_${i}`}>
        <Text
          useOpacity
          fontSizeLarge
          leftMargin={!testedData ? (CELL_MARGIN / 2) : 0}
        >
          {(testedData?.jitterStatus || '-')}
        </Text>
      </RowItem>
    );
  };

  const renderRecommendation = (item, i) => {
    const useDns = isStalker ? stalkerServers[i].dns : displayedServers[i].dns;
    const testedData = testedServerData(item.id) || null;
    return (
      <RowItem key={`recommend_${i}`} onClick={(e) => e.stopPropagation()}>
        {testedData?.isTested ? (
          <TooltipWrapper right>
            {testedData?.isRecom ? (
              <Tooltip
                title={
                  t('steps.step3.content.main.table.body.recommendedTooltip')
                }
                width={210}
                type="successWithoutBg"
                trigger="click"
                isDisabled={speedStatsData?.[useDns]}
              >
                <RecommendedText
                  transparentBg={selectedValue === useDns}
                  leftMargin={!testedData ? (CELL_MARGIN / 2) : 0}
                >
                  {speedStatsData?.[useDns] || t('steps.step3.content.main.table.body.recommended')}
                </RecommendedText>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  t('steps.step3.content.main.table.body.notRecommendedTooltip')
                }
                width={210}
                type="caution"
                trigger="click"
                isDisabled={speedStatsData?.[useDns]}
              >
                <Text>
                  {speedStatsData?.[useDns] || t('steps.step3.content.main.table.body.notRecommended')}
                </Text>
              </Tooltip>
            )}
          </TooltipWrapper>
        ) : (
          <Text alignLeft>-</Text>
        )}
      </RowItem>
    );
  };

  const renderStartButton = (item, i) => {
    const isServerCurrentTesting = item.id === testingServer?.id;
    return (
      <RowItem key={`startButton_${i}`} onClick={(e) => e.stopPropagation()}>
        <FlexWrapper>
          <TestButton
            isTesting={testingServer}
            isTested={testedServerData(item.id)}
            isCurrentTesting={isServerCurrentTesting}
            onClick={() => handleStartStopOneServerTest(item)}
            progress={currentServerTestProgress}
          >
            {t(`steps.step3.content.main.table.body.${isServerCurrentTesting ? 'stop' : 'start'}`)}
          </TestButton>
        </FlexWrapper>
      </RowItem>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const renderTooltip = (i) => (
    <RowItem key={`tooltip_${i}`} onClick={(e) => e.stopPropagation()}>
      <FlexWrapper>
        {isStalker && !isLogged && (
          <PrimaryButton
            onClick={() => setIsLoginModalOpened(true)}
          >
            {t('steps.step3.content.main.table.body.choose')}
          </PrimaryButton>
        )}
        {!testedServerData(i) && (
          <>
            <TooltipWrapper
              rightMargin
              rightTooltip
              isLogged={isLogged}
              isStalker={isStalker}
            >
              <Tooltip
                title={(
                  <>
                    {t('steps.step3.content.main.table.body.tooltip.title')}
                    <br />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link href="#">
                      {t('steps.step3.content.main.table.body.tooltip.link')}
                    </Link>
                  </>
                )}
                width={210}
                type="caution"
                trigger="click"
              >
                <Img
                  src={DangerIcon}
                  alt=""
                />
              </Tooltip>
            </TooltipWrapper>
          </>
        )}
      </FlexWrapper>
    </RowItem>
  );

  const renderStalkerServers = () => (
    stalkerServers.map((item, i) => {
      const testedData = testedServerData(item.id) || null;
      return (
        <div key={`stalkerServer_${item.id}`}>
          <RowItem
            radio
            isStalker
            isLogged={isLogged}
            isTested={testedData}
            isTesting={testingServer}
          >
            <RadioAlt
              value={item.dns}
              onChange={({ target }) => isLogged
                && !testingServer
                && setSelectedValue(target.value)}
              checked={selectedValue === item.dns}
              type="primary"
              labelText={(
                <RadioLabelText
                  setWidth={sizes[0]}
                  isStalker
                  textAlign="left"
                >
                  {item.name.length > FIRST_LABEL_TEX_MAX_LEN
                    ? `${item.name.slice(0, FIRST_LABEL_TEX_MAX_LEN)}...`
                    : item.name}
                </RadioLabelText>
              )}
            />
          </RowItem>
          {renderSpeed(item, i)}
          {renderPing(item, i)}
          {renderJitter(item, i)}
          {renderRecommendation(item, i)}
          {renderStartButton(item, i)}
          {/* renderTooltip(i) */}
        </div>
      );
    })
  );

  const renderPlaylistServers = () => (
    displayedServers.map((item, i) => {
      const isServerCurrentTesting = item.id === testingServer?.id;
      return (
        // eslint-disable-next-line
        <div key={`playlistServer_${item.id}`}>
          <RowItem
            radio
            isLogged={isLogged}
            isTested={testedServerData(item.id)}
            isTesting={isServerCurrentTesting}
          >
            <RadioAlt
              value={item.id}
              onChange={() => {
                dispatch(setServerForSelected(item));
                setChecked(item.id);
              }}
              checked={parseInt(checked, 10) === item.id}
              type="primary"
              position="horizontal"
              noPadding={!isFullWidth}
              noMargin
              labelText={(
                <RadioLabelText
                  setWidth={sizes[0]}
                  textAlign="left"
                >
                  {item.name.length > FIRST_LABEL_TEX_MAX_LEN
                    ? `${item.name.slice(0, FIRST_LABEL_TEX_MAX_LEN)}...`
                    : item.name}
                </RadioLabelText>
              )}
            />
          </RowItem>
          {renderSpeed(item, i)}
          {renderPing(item, i)}
          {renderJitter(item, i)}
          {renderRecommendation(item, i)}
          {renderStartButton(item, i)}
          {/* renderTooltip(i) */}
        </div>
      );
    })
  );

  useEffect(() => {
    const currentSizes = [...sizes];
    currentSizes[0] = isFullWidth
      ? SERVER_COLUMN_WIDTH_FW[isStalker ? 'stalker' : 'playlist']
      : SERVER_COLUMN_WIDTH[isStalker ? 'stalker' : 'playlist'];
    setSizes([...currentSizes]);
  }, [isStalker]);

  useEffect(() => {
    let ch = null;
    if ((selectedDevices || []).length > 0) {
      const selectedServerIds = {};
      selectedDevices.forEach((d) => { selectedServerIds[d.server] = true; });
      ch = Object.keys(selectedServerIds).length === 1
        ? Object.keys(selectedServerIds)[0] : null;
    } else if (rightDevice && selectedValue && rightDevice.id === selectedValue.id) {
      ch = rightDevice.server;
    } else if (selectedValue) {
      ch = selectedValue.id;
    }
    setChecked(ch);
  }, [
    selectedValue,
    selectedDevices,
    rightDevice.server,
  ]);

  return (
    <Container
      isStalker={isStalker}
      isLogged={isLogged}
      cellMargin={CELL_MARGIN}
    >
      <SnackBarWindow
        type="success"
        isOpen={isFinalModal}
        onClose={() => setIsFinalModal(false)}
        content={t('steps.step3.content.modal.success')}
      />
      <Table
        page={page}
        dataLength={displayedServers.length}
        limit={limit}
        onFiltersChange={filtersHandler}
        cellSizes={sizes}
        cellMargin={CELL_MARGIN}
        rowPadding={ROW_PADDING}
        // adaptiveSize={20}
      >
        <TableHead cellMargin={CELL_MARGIN} rowPadding={ROW_PADDING}>
          {headers.map((item) => (
            <HeaderItem
              key={`th_${Math.random() * 1000}`}
              greenCircle={item.greenCircle}
            >
              {item.title}
              {item?.units && (
                <HeaderItemUnits>{item.units}</HeaderItemUnits>
              )}
            </HeaderItem>
          ))}
        </TableHead>
        <TableBody
          minRows={isStalker ? stalkerServers.length : displayedServers.length}
          rowHeight={51}
          cellMargin={CELL_MARGIN}
          rowPadding={ROW_PADDING}
          textAlign="center"
        >
          {isStalker ? renderStalkerServers() : renderPlaylistServers()}
        </TableBody>
      </Table>
      {!isServersEqual && false && (
        <RedText>
          {t('steps.step3.content.main.table.body.serversNotEqual')}
        </RedText>
      )}
    </Container>
  );
};

export default ServerTable;

const Container = styled.div`
  margin-bottom: 40px;

  & .table-head {
    &::after {
      bottom: 5px;
    }

    &:not(:first-child) {
      &::after {
        height: auto;
        bottom: -8px;
        top: -8px;
      }
    }
  }

  & .table-rows {
    overflow: unset;

    & > div {
      overflow: unset;
    }

    &__row {
      &__item {
        &:first-child {
        }

        &:last-child {
          ${({ isStalker, isLogged }) => isStalker && isLogged
            && ('margin-left: 0;')}
        }

        ${({ isStalker, isLogged, cellMargin }) => isStalker && !isLogged && (`
          &:last-child {

            @media (max-width: 1360px) {
              margin-left: 0;
            }

            @media (max-width: 1198px) {
              margin-left: ${cellMargin || 30}px;
            }
          }
        `)}

        ${({ isStalker }) => isStalker && (`
          &:last-child {
            width: 100px;
          }
        `)};
      }
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderItem = styled.div`
  position: relative;

  ${({ greenCircle }) => greenCircle && (`
    left: 8px;

    &::after {
      content: '';

      width: 10px;
      height: 10px;

      position: absolute;
      left: -16px;
      top: 4px;

      background: ${green};
      border: 1px solid ${borderSecondary};
      box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
    }
  `)}
`;

const HeaderItemUnits = styled.div`
  text-align: left;
  font-size: .75em;
  color: ${text03};
  padding-top: .125em;
  text-transform: none;
  line-height: 1em;
`;

const RowItem = styled.div`
  flex-shrink: 0;

  ${({
    radio,
    isStalker,
    isTesting,
    isLogged,
  }) => radio && (`
    & input[aria-checked="true"] + span[color] {
      &::after {
        ${isTesting ? `background-color: ${greyTertiary};` : ''}
      }
    }

    & span[color] {
      width: 15px;
      height: 15px;

      top: 0;
      left: ${isStalker ? '6' : '0'}px;

      ${isTesting ? `border-color: ${greyTertiary};` : ''}
      ${isLogged ? '' : isStalker ? 'visibility: hidden;' : ''}

      transition: border 0.2s ease;

      @media (max-width: 1360px) {
        left: ${isStalker ? '12' : '0'}px;
      }

      @media (max-width: 1198px) {
        left: ${isStalker ? '6' : '0'}px;
      }

      &::after {
        width: 7px;
        height: 7px;

        top: 2px;
        left: 2px;
      }
    }
  `)}
`;

const Text = styled.div`
  min-width: 50px;
  ${({ leftMargin }) => (leftMargin > 0) && (`margin-left: ${leftMargin}px;`)};
  text-align: ${({ textAlign }) => (textAlign || 'center')};

  ${({ right }) => right && (`
    position: relative;
    right: 8px;
  `)};

  font-family: Gilroy, sans-serif;
  font-size: ${({ fontSizeLarge }) => (fontSizeLarge ? '15px;' : '12px;')};
  font-weight: 500;
  color: ${({ useOpacity }) => (useOpacity ? `${text02};` : `${text01};`)};
  line-height: 20px;
  letter-spacing: 0.16px;

  @media (max-width: 1360px) {
    font-size: ${({ fontSizeLarge }) => (fontSizeLarge ? '12px;' : '10px;')};
  }

  @media (max-width: 1198px) {
    font-size: ${({ fontSizeLarge }) => (fontSizeLarge ? '15px;' : '13px;')};
  }

  transition: background-color 0.2s ease-in-out,
  color 0.2s ease-in-out;
`;

const RedText = styled.div`
  margin-top: 16px;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  color: ${red};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const RadioLabelText = styled(Text)`
  width: ${({ setWidth }) => (setWidth ? `calc(${setWidth} - 16px - 0.5rem)` : '185px')};
  font-size: 1em;
  font-weight: 600;

  position: relative;

  color: ${primary};

  @media (max-width: 1360px) {
    font-size: .85em;
    width: ${({ setWidth }) => (setWidth ? `calc(${setWidth} - 46px - 0.5rem)` : '155px')};
  }
`;

const PrimaryButton = styled.button`
  margin-right: 0;

  background-color: transparent;
  border: none;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${primary};
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 14px;
  }
`;

const RecommendedText = styled(Text)`
  padding: 1px 5px;

  ${({ maxWidth }) => (maxWidth && `max-width: ${maxWidth};`)};

  background-color: ${({ transparentBg }) => (transparentBg ? 'transparent' : `${activeTertiary}`)};
  border: 1px solid ${activeTertiary};
  border-radius: 2px;

  color: ${({ transparentBg }) => (transparentBg ? `${text01}` : `${white}`)};
`;

const TestButton = styled.button`
  position: relative;

  background-color: transparent;
  border: none;
  ${({ isTesting }) => (isTesting
    ? ('cursor: not-allowed;')
    : ('cursor: pointer;'))};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${({ isTesting }) => (isTesting ? `${text04};` : `${secondary};`)};
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  outline: none;

  transition: color 0.2s ease-in-out;

  ${({ isCurrentTesting, progress }) => isCurrentTesting && (`
    cursor: pointer;
    color: ${red};

    &::after {
      width: ${60 * progress}px !important;
    }
  `)}

  &::before {
    display: ${({ isCurrentTesting }) => (isCurrentTesting ? 'block' : 'none')};
    content: '';

    width: 60px;
    height: 2px;

    position: absolute;
    bottom: -6px;
    left: 0;

    background-color: ${greySecondary};
    border-radius: 10px;
  }

  &::after {
    display: ${({ isCurrentTesting }) => (isCurrentTesting ? 'block' : 'none')};
    content: '';

    width: 0;
    height: 2px;

    position: absolute;
    bottom: -6px;
    left: 0;

    background-color: ${green};
    border-radius: 10px;
    transition: 0.2s ease-in-out;
  }
`;

const TooltipWrapper = styled.div`
  ${({ rightMargin }) => rightMargin && (`
    position: relative;
    right: 0;
  `)}
  ${({ right }) => right && (`
    & div[type] {
      left: 55%;
    }
  `)}
  ${({ rightTooltip, isLogged, isStalker }) => rightTooltip && isLogged && isStalker && (`
    position: relative;
    right: 64px;
  `)}
  ${({ isLogged, isStalker }) => isLogged && isStalker
    && ('right: 30px;')}

  & div[type] {
    z-index: 2;
  }
`;

const Img = styled.img``;

const Link = styled.a`
  color: ${primary};
`;
