import React, { useRef } from 'react';
import { AtomicBlockUtils } from 'draft-js';

import ToolbarButton from './ToolbarButton';
import ImageIcon from '@/assets/icons/TextEditor/image.svg';

const ImageControls = ({
  editorState,
  onEditorStateChange,
  uploadImage,
}) => {
  const inputAccept = 'image/gif,image/jpeg,image/jpg,image/png,image/svg';

  const addImage = (src) => {
    const width = 'auto';
    const height = 'auto';
    const entityData = { src, height, width };
    const entityKey = editorState
      .getCurrentContent()
      .createEntity('IMAGE', 'IMMUTABLE', entityData)
      .getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' ',
    );
    onEditorStateChange(newEditorState);
  };

  const fileSelectHandler = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      uploadImage(event.target.files[0])
        .then((url) => {
          addImage(url);
        });
    }
  };

  const fileInput = useRef(null);

  return (
    <>
      <ToolbarButton
        className="rdw-option-wrapper"
        label="Изображение"
        icon={ImageIcon}
        onToggle={() => {
          fileInput.current.click();
        }}
      />
      <input
        ref={fileInput}
        hidden
        type="file"
        accept={inputAccept}
        onChange={fileSelectHandler}
      />
    </>
  );
};

export default ImageControls;
