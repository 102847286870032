import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import {
  secondary,
  white,
  text01,
  borderSecondary,
} from '@/common/constants/theme';

const NavigationItem = ({ children, href }) => {
  const history = useHistory();
  const isActive = history.location.pathname.match(href);

  return (
    <Container to={href} active={isActive}>
      <Text active={isActive}>{children}</Text>
    </Container>
  );
};

export default NavigationItem;

const Container = styled(Link)`
  display: flex;
  align-items: center;

  height: 39px;
  padding-left: 10px;

  background: ${({ active }) => active && secondary};
  border-bottom: 1px solid ${borderSecondary};
  ${({ isActive }) => isActive && (`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11);
    border-bottom: none;
    border-radius: 2px;
  `)}

  transition: background-color 0.1s ease;

  &:last-child {
    border-bottom: none;
  }
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: ${({ active }) => (active ? white : text01)};
  letter-spacing: 0.16px;
  line-height: 17px;

  transition: color 0.1s ease;

  @media (max-width: 1360px) {
    font-size: 10px;
  }

  @media (max-width: 1198px) {
    font-size: 13px;
  }
`;
