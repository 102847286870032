/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ModalNew from '@/common/components/ModalNew';
import PlusIcon from '@/assets/icons/plus.svg';
import EditIcon from '@/assets/icons/editGrey.svg';
import {
  primary, red,
  text01, text03,
} from '@/common/constants/theme';
import Input from '@/common/components/Input';
import Dropdown from '@/common/components/Dropdown';
import Button from '@/common/components/Button';
import { useDNSContext } from '../DNSContext';

const ERROR_DESCRIPTION = 'error!';

const DNSModal = ({
  isOpen,
  onClose,
  isEdit,
  onSubmit,
  editedValue,
}) => {
  const { t } = useTranslation('translations');
  const { servers, domains } = useDNSContext();
  const [dropdownValue, setDropdownValue] = useState({});
  const [serverDropdown, setServerDropdownValue] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(null);
  const [errors, setErrors] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    if (servers.length && domains.length) {
      setDropdownValue(domains[0]);
      setServerDropdownValue(servers[0]);
    }
  }, [servers.length, domains.length]);

  useEffect(() => {
    if (!isOpen) {
      setIsLoaded(false);
      return;
    }
    inputRef.current.focus();
    if (editedValue?.dns && domains.length && servers.length) {
      setDropdownValue(domains.find((domain) => domain.id === editedValue?.domainId));
      setServerDropdownValue(servers.find((server) => server.id === editedValue?.serverId));
      setInputValue(editedValue?.dns);
    }
    setIsLoaded(true);
  }, [editedValue, isOpen]);

  useEffect(() => {
    if (!isEdit && domains.length && servers.length) {
      setDropdownValue(domains[0]);
      setServerDropdownValue(servers[0]);
      setInputValue('');
    }
  }, [isEdit]);

  const isInputValid = (v) => ((v || '').length > 0) && !(v || '').match(/[^a-z0-9-]{1,20}/);

  const inputHandler = (value) => {
    setInputValue(value);
    setInputError(isInputValid(value) ? null : t('DNS.modal.inputValidationError'));
  };

  const getDescription = () => {
    let retVal;
    if ((inputError?.length || 0) === 0) {
      retVal = `${((inputValue?.length || 0) >= 20
        ? `${inputValue.slice(0, 20)}...`
        : (inputValue || ''))}.${dropdownValue.title || ''}`;
    } else {
      retVal = `${ERROR_DESCRIPTION}.${dropdownValue.title || ''}`;
    }
    return retVal;
  };

  const submitHandler = () => {
    if (isInputValid(inputValue) && dropdownValue
      && serverDropdown && domains.length && servers.length) {
      onSubmit(inputValue, dropdownValue.id, serverDropdown.id);
      setInputValue('');
      setDropdownValue(domains[0]);
      setServerDropdownValue(servers[0]);
    }
  };

  useEffect(() => {
    const detectedErrors = [];
    if (!dropdownValue?.id) {
      detectedErrors.push(t('DNS.modal.errors.domainNotValid'));
    }
    if (!serverDropdown?.id) {
      detectedErrors.push(t('DNS.modal.errors.serverNotValid'));
    }
    if (!isInputValid(inputValue)) {
      detectedErrors.push(t('DNS.modal.errors.nameNotValid'));
    }
    if (((editedValue?.dns || '') === inputValue)
      && ((editedValue?.serverId || 0) === (serverDropdown?.id))
      && ((editedValue?.domainId || 0) === (dropdownValue?.id))
    ) {
      detectedErrors.push(t('DNS.modal.errors.notChanged'));
    }

    setErrors(detectedErrors);
  }, [editedValue, dropdownValue?.id, serverDropdown?.id, inputValue]);

  return (
    <ModalNew
      isHidden={false}
      icon={isEdit ? EditIcon : PlusIcon}
      isOpen={isOpen}
      onCloseModal={onClose}
      title={isEdit ? t('DNS.modal.editTitle') : t('DNS.modal.title')}
      noOverFlow
    >
      <Container>
        <Wrapper>
          <Text>{t('DNS.modal.DNSName')}</Text>
          <Descr bottomMargin="8px">{t('DNS.modal.DNSNameExample')}</Descr>
        </Wrapper>
        <FlexWrapper>
          <StyledInput
            isError={!!inputError}
            value={inputValue}
            onChange={(value) => inputHandler(value.currentTarget.value)}
            ref={inputRef}
          />
          <StyledDropdown
            height="39px"
            options={domains}
            value={domains.find((item) => item.key === dropdownValue.key)}
            onSelect={(item) => setDropdownValue(item)}
            isCapitalize={false}
          />
        </FlexWrapper>
        <FlexWrapper>
          {(inputValue?.length || 0) > 0 && inputError
            && <InputErrorContainer>{inputError}</InputErrorContainer>}
          <Descr
            leftMarginAuto
            bottomMargin="20px"
            title={`${inputValue}.${dropdownValue.title}`}
          >
            {getDescription()}
          </Descr>
        </FlexWrapper>
        <Wrapper>
          <Text bottomMargin>{t('DNS.modal.domenTitle')}</Text>
          <StyledDropdown
            width
            height="39px"
            optionHeight={48}
            itemsShow={4}
            color={primary}
            isBoldText
            options={servers}
            value={servers.find((item) => item.key === serverDropdown.key)}
            onSelect={(item) => setServerDropdownValue(item)}
            isCapitalize={false}
          />
        </Wrapper>
        <FlexWrapper>
          <StyledButton
            isBoldText
            isUpperCase
            onClick={submitHandler}
            disabled={!isLoaded || (errors.length > 0)}
            title={errors.join('\n')}
          >
            {isEdit ? t('DNS.modal.changeDNS') : t('DNS.modal.createDNS')}
          </StyledButton>
        </FlexWrapper>
      </Container>
    </ModalNew>
  );
};

export default DNSModal;

const Container = styled.div`
  padding: 4px 8px 20px 2px;
`;

const Wrapper = styled.div``;

const FlexWrapper = styled.div`
  display: flex;
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 6px;')};

  font-family: Gilroy,sans-serif;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const Descr = styled(Text)`
  ${({ leftMarginAuto }) => leftMarginAuto && ('margin-left: auto;')};
  ${({ bottomMargin }) => bottomMargin && (`margin-bottom: ${bottomMargin};`)};

  font-size: 13px;
  color: ${text03};
  line-height: 18px;
`;

const StyledInput = styled(Input)`
  width: 200px;
  height: 39px;
  margin-right: 5px;
  border-color: ${({ isError }) => (isError ? red : 'inherit')};
`;

const StyledDropdown = styled(Dropdown)`
  ${({ width }) => width && ('width: 100%;')};
`;

const StyledButton = styled(Button)`
  width: 140px;
  margin-top: 35px;
  margin-left: auto;

  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const InputErrorContainer = styled.div`
  margin: .25rem 0 .5rem 0;
  padding: 0 .5rem;
  color: ${red};
  font-size: 12px;
`;
