import React from 'react';
import styled from 'styled-components';
import {
  secondary,
  text01,
  primary,
} from '@/common/constants/theme';

const CompareResultItem = ({
  section,
  data,
  itemKey,
  collapseText,
  moreText,
  setSelectedResultItemKey,
  setDisplayInfo,
  isSelected,
}) => {
  const dataStub = { info: [], caption: '' };

  return (
    <Container>
      <TextWrapper>
        <Wrapper>
          <Text elementWidth="110px" bottomMargin>{`${section}:`}</Text>
          <Text bold leftMargin>{data?.count}</Text>
        </Wrapper>
        {(data?.count || 0) > 0
          && (
          <Details onClick={() => {
            setSelectedResultItemKey(isSelected ? '' : itemKey);
            setDisplayInfo(isSelected ? dataStub : { info: data?.info, caption: section });
          }}
          >
            {isSelected ? collapseText : moreText}
          </Details>
          )}
      </TextWrapper>
    </Container>
  );
};

export default CompareResultItem;

const Container = styled.div`
  min-width: 175px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 22px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
`;

const Text = styled.div`
  ${({ leftMargin }) => leftMargin && ('margin-left: 5px;')}
  ${({ elementWidth }) => elementWidth && (`width: ${elementWidth};`)}

  font-family: Gilroy, sans-serif;
  font-size: ${({ largeFontSize }) => (largeFontSize ? 18 : 14)}px;
  ${({ bold }) => bold && ('font-weight: 600;')}
  color: ${({ secondaryColor }) => (secondaryColor ? secondary : text01)};
  letter-spacing: 0.16px;
  line-height: 15px;
`;

const Details = styled.div`
  cursor: pointer;

  font-size: 14px;
  color: ${primary};
  text-decoration-line: underline;
`;
