export const primary = '#5C70CF';
export const secondary = '#ff9800';
export const text01 = '#202020';
export const text02 = '#20202099'; // opacity: .6
export const text03 = '#20202080'; // opacity: .5
export const text04 = '#2020204d'; // opacity: .3
export const text05 = '#323345';
export const greyText = '#A6A6A6';
export const activePrimary = '#4859A4';
export const activeTertiary = '#6C7DFF';
export const green = '#239E57';
export const brightGreen = '#8AC44B';
export const greenTertiary = '#69A042';
export const red = '#FF1744';
export const darkRed = '#EF274E';
export const lightRed = '#F04438';
export const white = '#FFF';
export const black = '#252637';
export const grey = '#797979';
export const darkGrey = '#949494';
export const greySecondary = '#D5D8DC';
export const greyTertiary = '#EEEEEE';
export const greyQuaternary = '#ADB1C2';
export const greyFivefold = '#C4C4C4';
export const greyWhite = '#F2F2F2';
export const greyBorder = '#EBEFF2';
export const background01 = '#FAFAFA';
export const background02 = '#F5FAF9';
export const background03 = '#FEF6F7';
export const background4 = '#FCFCFC';
export const inputDisabled = '#F5F5F5';
export const border = '#D0CCDB';
export const borderSecondary = '#EFEEF3';
export const borderTertiary = '#E0DEE8';
export const secondaryHover = '#FFAB30';
export const secondaryActive = '#DF8500';
export const secondaryCard = '#FCA729';
export const brightSecondary = '#FFC107';
export const primaryHover = '#7A8AFF';
export const primaryActive = '#515FC5';
export const primaryQuart = '#7A8AFF40';
export const inputBorder = '#ECECEC';
