import React from 'react';
import styled from 'styled-components';

import {
  borderSecondary,
  background01,
  text01,
  text02,
  white,
  activeTertiary,
} from '@/common/constants/theme';

import DeleteIcon from '../../../../../icons/DeleteIcon';

const AdminDNSTable = ({
  setDeletedId,
  setIsDeleteModal,
  activeRow,
  setActiveRow,
  search,
  domains,
}) => (
  <Container>
    <Header>
      <HeaderItem width="95%">
        Список пользовательских DNS
      </HeaderItem>
      <HeaderItem width="5%">
        <More />
      </HeaderItem>
    </Header>
    <Body>
      {domains.length ? domains
        .filter((domain) => domain.url.toLowerCase().includes(search.toLowerCase()))
        .map((domain, index) => (
          <Row key={domain.url} isActive={activeRow === index}>
            <RowItem
              width="45%"
              pointer
              isActive={activeRow === index}
              onClick={() => setActiveRow(index)}
            >
              {domain.name}
            </RowItem>
            <RowItem
              width="50%"
              pointer
              isActive={activeRow === index}
              onClick={() => setActiveRow(index)}
            >
              {domain.url}
            </RowItem>
            <RowItem width="5%">
              <Icon onClick={() => {
                setDeletedId(index);
                setIsDeleteModal(true);
              }}
              >
                <DeleteIcon />
              </Icon>
            </RowItem>
          </Row>
        )) : <NoData>Не найдено</NoData>}
    </Body>
  </Container>
);

export default AdminDNSTable;

const Container = styled.div`
  width: 40%;
  margin-right: 40px;

  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  filter: drop-shadow(0px 0px 20px rgba(192, 192, 192, 0.25));
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  height: 58px;
  padding-left: 20px;
  padding-right: 40px;

  background: ${background01};
  border-bottom: 1px solid ${borderSecondary};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const HeaderItem = styled.div`
  width: ${({ width }) => width};

  position: relative;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${text01};
  text-transform: uppercase;
  line-height: 20px;

  &:not(:first-child) {
    margin-left: 30px;

    &::after {
      content: '';

      width: 1px;
      height: 30px;

      position: absolute;
      bottom: -12px;
      left: -14px;

      background-color: ${borderSecondary};
    }
  }

  @media (max-width: 1360px) {
    ${({ width, adaptive }) => width && (`width: calc(${width} - ${adaptive || 10}px);`)};

    font-size: 11px;
  }

  @media (max-width: 1198px) {
    ${({ width }) => width && (`width: ${width};`)};

    font-size: 14px;
  }
`;

const More = styled.div`
  width: 3px;
  height: 3px;
  margin-left: 8px;

  position: relative;

  border-radius: 10px;
  background-color: ${text01};

  &::after,
  &::before {
    content: '';

    width: 3px;
    height: 3px;

    position: absolute;
    top: 0;

    background-color: ${text01};
    border-radius: 10px;
  }

  &::after {
    left: -6px;
  }

  &::before {
    left: 6px;
  }
`;

const Body = styled.div`
  min-height: 172px;
  max-height: 600px;
  overflow-y: auto;
`;

const NoData = styled.div`
  margin-top: 100px;

  font-family: Gilroy;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${text02};
  text-align: center;
  line-height: 15px;
  letter-spacing: 0.16px;

  @media (max-width: 1360px) {
    font-size: 11px;
  }

  @media (max-width: 1198px) {
    font-size: 13px;
  }
`;

const Row = styled.div`
  display: flex;

  padding-right: 40px;

    /*
//The design for the even is different
  &:nth-child(2n) {
    background-color: ${background01};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${borderSecondary};
  }
*/

  ${({ isActive }) => isActive && (`
    background-color: ${activeTertiary};

    color: ${white};
    font-weight: 600;
  `)}
`;

const RowItem = styled.div`
  width: ${({ width }) => width};
  padding-top: 13px;
  padding-bottom: 14px;
  padding-left: 22px;
  ${({ pointer }) => pointer && ('cursor: pointer;')}

  font-family: Gilroy;
  font-size: 13px;
  color: ${text01};
  line-height: 15px;

  &:not(:first-child) {
    margin-left: 12px;
    padding-left: 0;
  }

  ${({ isActive }) => isActive && (`
    background-color: ${activeTertiary};

    color: ${white};
    font-weight: 600;
  `)}
`;

const Icon = styled.div`
  position: relative;
  top: 2px;

  cursor: pointer;
`;
