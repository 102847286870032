import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import {
  white,
  text05,
  primary,
  secondary,
  text02,
  text01,
} from '@/common/constants/theme';

import HomeTestSrc from '@/assets/images/webp/home-test-period.webp';

import Button from '@/common/components/Button';
import PageContainer from '@/common/components/PageContainer';
import PeoplesIcon from '../../../PersonalPageScreen/components/icons/PeoplesIcon';

import { useAppContext } from '@/AppContext';

const HomeTestCard = ({ isUserExist }) => {
  const { t } = useTranslation('translations');

  const { getFromPublicLink } = useAppContext();

  return (
    <Wrapper flex flexCenter mainWrapper>
      <PageContainer>
        <Card>
          <Wrapper flex flexSb>
            <Wrapper padding>
              <Title>{t('homePage.test.title')}</Title>
              <Text>{t('homePage.test.descr')}</Text>
              <Text bottomMargin>{t('homePage.test.descr02')}</Text>
              <Link to={getFromPublicLink('/personal-page/devices', isUserExist)}>
                <Button
                  isBoldText
                  isUpperCase
                  width="small"
                >
                  {t('homePage.test.btn')}
                </Button>
              </Link>
              <Wrapper text>
                <Text greyText topMargin>{t('homePage.test.ref')}</Text>
              </Wrapper>
              <Wrapper flex text>
                <Text greyText>{t('homePage.test.ref02')}</Text>
                <BoldReferralsText to={getFromPublicLink('/personal-page/referrals', isUserExist)}>
                  {t('homePage.test.myReferrals')}
                </BoldReferralsText>
              </Wrapper>
              <Wrapper topMargin flex>
                <PeoplesIcon />
                <ReferralsText>{t('homePage.test.refDescr')}</ReferralsText>
              </Wrapper>
            </Wrapper>
            <Wrapper>
              <Img
                src={HomeTestSrc}
                alt=""
              />
            </Wrapper>
          </Wrapper>
        </Card>
        <Wrapper flex flexCenter>
          <MainTitle>
            {t('homePage.test.mainTitle')}
          </MainTitle>
          <MainTitle orange>
            {t('homePage.test.mainOrangeTitle')}
          </MainTitle>
        </Wrapper>
      </PageContainer>
    </Wrapper>
  );
};
export default HomeTestCard;

const Card = styled.div`
  max-width: 1250px;
  margin-bottom: 100px;

  background: ${white};
  box-shadow: 0 4px 24px rgba(160, 160, 160, 0.25);
  border-radius: 9px;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  ${({ flexCenter }) => flexCenter && ('justify-content: center;')};
  flex-wrap: wrap;

  ${({ padding }) => padding && ('padding: 30px 0 0 30px;')}
  ${({ topMargin }) => topMargin && ('margin-top: 19px;')}
  ${({ mainWrapper }) => mainWrapper && ('margin-top: -75px;')};

  ${({ text }) => text && (`
    @media (max-width: 1360px) {
      width: 500px;
    }

    @media (max-width: 1198px) {
      width: auto;
    }
  `)}
`;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 28px;')}
  ${({ topMargin }) => topMargin && ('margin-top: 40px;')}

  font-family: Gilroy, sans-serif;
  font-size: 15px;
  color: ${({ greyText }) => (greyText ? text02 : text05)};
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const MainTitle = styled(Text)`
  margin-bottom: 62px;
  ${({ orange }) => orange && ('margin-left: 10px;')};

  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  color: ${({ orange }) => (orange ? secondary : text01)};
`;

const Title = styled(Text)`
  display: inline-block;

  margin-bottom: 34px;

  position: relative;

  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  color: ${primary};

  &::before {
    content: '';

    width: 80%;
    height: 1px;

    position: absolute;
    left: 0;
    bottom: -15px;

    background-color: ${primary};
    opacity: 20%;
  }
`;

const ReferralsText = styled(Text)`
  margin-left: 12px;

  font-weight: 500;
  font-size: 14px;
  color: ${text01};
  line-height: 24px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
`;

const BoldReferralsText = styled(Link)`
  margin-left: 5px;

  position: relative;
  top: 1px;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${secondary};
  white-space: nowrap;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-transform: uppercase;

  &:hover {
    color: ${secondary};
  }

  &:active {
    color: ${secondary};
  }

  @media (max-width: 1360px) {
    margin-left: 0;
  }

  @media (max-width: 1198px) {
    margin-left: 12px;
  }
`;

const Img = styled.img`
  max-width: 573px;
  margin-right: -1px;

  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
`;
