/* eslint-disable no-shadow */
import React, {
  useState,
  createContext,
  useContext,
} from 'react';

import { arrayEdit, arrayMove } from '@/common/utils/arrayMove.util';
import {
  handleImportDataAction,
  handleValidateDataAction,
  exportDataAction,
  createChannelAction,
  deleteAllChannelsAction,
} from '@/services/actions/admin/base.actions';
import {
  deleteChannelByIdAction,
  getChannelsAction,
  getImportDataAction,
  moveChannelAction,
  updateChannelByIdAction,
} from '@/services/actions/admin/admin.actions';

import { useAppContext } from '@/AppContext';
import { useAdminContext } from '../../../../../AdminContext';

const BaseContext = createContext({});

export const BaseContextProvider = ({ children }) => {
  const { saveFile } = useAppContext();
  const {
    groups,
    getPosition,
    getOffset,
    pageLimit,
    dropdownGroupsValue,
    SnackTypes,
    setAdminSnack,
  } = useAdminContext();

  const [importData, setImportData] = useState({ channels: [], groups: [] });
  const [validatedData, setValidatedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [channels, setChannels] = useState([]);
  const [channelsCount, setChannelsCount] = useState(0);
  const [channelsPage, setChannelsPage] = useState(1);

  const getChannelsPageCount = () => Math.ceil(channelsCount / pageLimit);

  const getChannels = () => {
    const groupId = dropdownGroupsValue.key === 'all' ? null : groups.find((group) => group.name === dropdownGroupsValue.title).id;
    getChannelsAction(getOffset(channelsPage), pageLimit, groupId)
      .then((res) => {
        const sortedChannels = res.results.sort((a, b) => a.ordering_id - b.ordering_id);
        setChannels(sortedChannels);
        setChannelsCount(res.count);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const getImportData = () => {
    setIsLoading(true);
    getImportDataAction()
      .then((res) => setImportData(res))
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleImportData = (options) => {
    setIsLoading(true);

    const useData = {
      ...importData,
      is_create_news: options?.news,
      is_send_notifications: options?.notify,
    };
    return (
      handleImportDataAction(useData)
        // eslint-disable-next-line no-console
        .catch((err) => setAdminSnack(err))
        .finally(() => setIsLoading(false))
    );
  };

  const handleValidateData = () => {
    setIsLoading(true);
    return (
      handleValidateDataAction(importData)
        .then((res) => setValidatedData(res))
      // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    );
  };

  const handleDragEndChannels = (oldIndex, newIndex) => {
    const updatedChannels = arrayMove(channels, oldIndex, newIndex);
    setChannels(updatedChannels);

    const { id } = updatedChannels[newIndex];
    let prevChannelId = null;
    if (newIndex > 0) {
      prevChannelId = updatedChannels[newIndex - 1].id;
    }

    moveChannelAction(id, prevChannelId)
      .then()
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const updateChannelById = (id, data) => {
    updateChannelByIdAction(id, data)
      .then(() => {
        const channelIndex = channels.findIndex((c) => c.id === id);
        setChannels((prevState) => arrayEdit(prevState, data, channelIndex));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const moveChannelToPosition = (id, newPosition) => {
    const groupId = dropdownGroupsValue.key === 'all' ? null : groups.find((group) => group.name === dropdownGroupsValue.title).id;
    if (newPosition !== 1) {
      getChannelsAction(newPosition - 1 - 1, 1, groupId).then((res) => {
        const prevChannelId = res.results[0].id;
        moveChannelAction(id, prevChannelId).then(() => {
          getChannels();
        });
      });
    } else {
      moveChannelAction(id, null, groupId).then(() => {
        getChannels();
      });
    }
  };

  const handleSaveChannels = (index, item, position) => {
    const { id } = channels[index];
    updateChannelById(id, item);

    const oldPosition = getPosition(channelsPage, index);
    let newPosition = position;
    if (newPosition > oldPosition) {
      newPosition += 1;
    }
    if (newPosition && oldPosition !== newPosition) {
      moveChannelToPosition(id, newPosition);
    }
  };

  const createChannel = (data, createPosition) => {
    createChannelAction(data)
      .then((res) => {
        if (createPosition) {
          const { id } = res;
          moveChannelToPosition(id, createPosition);
        } else {
          getChannels();
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const deleteChannelById = (id) => {
    deleteChannelByIdAction(id)
      .then(() => getChannels())
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const deleteAllChannels = () => {
    deleteAllChannelsAction()
      .then(() => getChannels())
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const handleHidden = (i, isHidden, offset) => {
    const currentData = [...channels];
    currentData[i].is_hidden = isHidden;
    updateChannelById(currentData[i].id, currentData[i], offset);
  };

  const handleArchive = (i, isArchive, offset) => {
    const currentData = [...channels];
    currentData[i].is_archived = isArchive;
    updateChannelById(currentData[i].id, currentData[i], offset);
  };

  const exportData = (data, postAction = null) => {
    if (!data?.format) {
      setAdminSnack({ type: SnackTypes.error, content: 'Не задан тип файла' }, SnackTypes.error);
      return null;
    }
    setAdminSnack({ type: SnackTypes.warning, content: 'Получаем файл, подождите...' }, SnackTypes.warning);
    return exportDataAction(data?.format)
      .then((res) => {
        setAdminSnack({ type: SnackTypes.success, content: 'Файл сохранен, проверьте папку загрузок' }, SnackTypes.Success);
        if (postAction) { postAction(); }
        saveFile({
          type: data.format,
          name: `${data.name}.${data.format}`,
          data: res,
        });
      })
      .catch((err) => { setAdminSnack(err); if (postAction) { postAction(); } });
  };

  const value = {
    channels,
    getChannels,
    channelsPage,
    setChannelsPage,
    getChannelsPageCount,
    channelsCount,
    importData,
    validatedData,
    isLoading,
    getImportData,
    handleImportData,
    handleValidateData,
    handleHidden,
    handleArchive,
    createChannel,
    deleteAllChannels,
    exportData,
    handleDragEndChannels,
    handleSaveChannels,
    deleteChannelById,
  };

  return (
    <BaseContext.Provider value={value}>
      {children}
    </BaseContext.Provider>
  );
};

export const useBaseContext = () => useContext(BaseContext);
