export const arrayMove = (array, from, to) => {
  const updatedArray = [...array];

  const startIndex = from < 0 ? updatedArray.length + from : from;
  if (startIndex >= 0 && startIndex < updatedArray.length) {
    const endIndex = to < 0 ? updatedArray.length + to : to;

    const [item] = updatedArray.splice(from, 1);
    updatedArray.splice(endIndex, 0, item);
  }

  return updatedArray;
};

export const arrayEdit = (array, updatedItem, index) => {
  const updatedArray = [...array];
  updatedArray[index] = updatedItem;
  return updatedArray;
};

// export const arrayDelete = (array, index) =>{
// TODO Utils array item delete
// };

export default arrayMove;
