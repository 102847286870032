import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  white,
  text01,
  greyText,
  secondary, greyBorder,
} from '@/common/constants/theme';
import ArrowLeftIcon from '../../../../icons/ArrowLeftIcon';
import ArrowToStartIcon from '../../../../icons/ArrowToStartIcon';
import ArrowToEndIcon from '../../../../icons/ArrowToEndIcon';
import ArrowRightIcon from '../../../../icons/ArrowRightIcon';

const keyAddon = `admin-pagination-${Math.random()}`;

const AdminTablePagination = ({
  page,
  setPage,
  limit = 0,
  setLimit,
  pageCount,
  recordsCount,
  title = 'Записей:',
  customLeftBlock,
  pageSizes,
}) => {
  const [formattedPages, setFormattedPages] = useState([]);

  const handlePage = (pageNumber) => {
    if (setPage && ((page || 0) !== (pageNumber || 0))) setPage(pageNumber);
  };

  const getFormattedPages = () => {
    if (pageCount > 7) {
      if (page === 1 || page === 2) {
        return [1, 2, 3, '...', pageCount];
      }
      if (page === 3) {
        return [1, 2, 3, 4, '...', pageCount];
      }
      if (page === pageCount || page === pageCount - 1) {
        return [1, '...', pageCount - 2, pageCount - 1, pageCount];
      }
      if (page === pageCount - 2) {
        return [1, '...', pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
      }
      return [1, '...', page - 1, page, page + 1, '...', pageCount];
    }
    const retArray = [];
    for (let i = 1; i <= pageCount; i += 1) { retArray.push(i); }
    return retArray;
  };

  const checkIsActive = () => pageCount && page !== pageCount;

  useEffect(() => { setFormattedPages(getFormattedPages()); }, [page, pageCount]);

  return (
    <Container className="table__pagination">
      <Wrapper centerItems>
        {customLeftBlock && (
          <CustomBlockWrapper isLeft>{customLeftBlock}</CustomBlockWrapper>
        )}
        {recordsCount && (
          <Text>
            {`${title} ${recordsCount}`}
          </Text>
        )}
      </Wrapper>
      {pageSizes && setLimit && (
        <Wrapper>
          <Text rightMargin>
            Показывать по:
          </Text>
          {pageSizes.map((item) => (
            <TextButton
              key={`${keyAddon}-page-${item}`}
              mainBtns
              isActive={+limit === +item}
              onClick={() => setLimit(item)}
            >
              {item}
            </TextButton>
          ))}
        </Wrapper>
      )}
      <Wrapper>
        <Icon active={page !== 1} onClick={() => page > 1 && handlePage(1)}>
          <ArrowToStartIcon />
        </Icon>
        <Icon active={page !== 1} onClick={() => page > 1 && handlePage(page - 1)}>
          <ArrowLeftIcon />
        </Icon>
        {formattedPages.map((item, index) => (
          <PaginationText
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${item}--${index}--pagination`}
            active={item === page}
            onClick={() => (item !== '...' && handlePage(item))}
          >
            {item}
          </PaginationText>
        ))}
        <Icon active={checkIsActive()} onClick={() => checkIsActive() && handlePage(page + 1)}>
          <ArrowRightIcon />
        </Icon>
        <Icon active={checkIsActive()} onClick={() => checkIsActive() && handlePage(pageCount)}>
          <ArrowToEndIcon />
        </Icon>
      </Wrapper>
    </Container>
  );
};

export default AdminTablePagination;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 1;

  height: 54px;
  padding-left: 21px;
  padding-right: 30px;

  background: ${white};
  border: 1px solid #EFEEF3;
  border-top: none;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 0 0 4px 4px;
  user-select: none;
`;

const Wrapper = styled.div`
  display: flex;
  ${({ centerItems }) => (centerItems && 'align-items: center;')}
`;

const CustomBlockWrapper = styled.div`
  ${({ isLeft }) => (isLeft && `
    margin-right: 1rem;
    padding-right: .75rem;
    border-right: solid 1px ${greyBorder};
  `)}
  ${({ isRight }) => (isRight && `
    margin-left: 1rem;
    padding-left: .75rem;
    border-left: solid 1px ${greyBorder};
  `)}
`;

const Text = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  letter-spacing: 0.16px;
  line-height: 15px;
  ${({ rightMargin }) => (rightMargin && 'margin-right: .5rem;')}
`;

const TextButton = styled(Text)`
  margin-right: ${({ mainBtns }) => mainBtns && 15}px;

  opacity: ${({ isActive }) => (isActive ? 0.4 : 1)};
  cursor: ${({ isActive }) => !isActive && ('pointer')};

  font-weight: 600;
  color: ${({ isActive }) => (isActive ? text01 : secondary)};

  transition: 0.2s ease-in-out;
`;

const PaginationText = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  margin-left: 10px;

  cursor: pointer;

  font-size: 14px;
  font-weight: 600;
  color: ${greyText};
  line-height: 17px;

  &:first-child {
    margin-left: 15px;
  }

  &:last-child {
    margin-right: 15px;
  }

  ${({ active }) => active && (`
    background-color: ${secondary};
    border-radius: 2px;

    color: ${white};
  `)}
`;

const Icon = styled.div`
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 10px;
  }

  ${({ active }) => (active ? `
    & svg path {
      fill: ${secondary};
    }
  ` : `
    & svg path {
      fill: ${greyText};
    }
  `)}
`;
