import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import Modal from '@/common/components/ModalNew';
import Input from '@/common/components/AuthorizationInput';
import Button from '@/common/components/Button';
import KeyIcon from '@/assets/icons/key.svg';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import { useProfileContext } from '../ProfileContext';

const UpdatePassword = ({
  isOpen,
  onClose,
  history,
}) => {
  const { t } = useTranslation(['translations']);
  const {
    validatePassword,
    changePassword,
  } = useProfileContext();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState(null);
  const [confirmedText, setConfirmedText] = useState(null);

  const onModalClose = () => {
    onClose();
    setPassword('');
    setPasswordError(null);
    setConfirmedPassword('');
    setConfirmedPasswordError(null);
  };

  const onBlurPassword = (passwordForValidate) => {
    validatePassword(passwordForValidate)
      .then(({ errors }) => {
        if (!errors) {
          setPasswordError('');
        } else {
          const errorTexts = [t('authorization.passwordLengthError'), t('authorization.passwordContentError')];
          setPasswordError(errorTexts.join(' '));
        }
      });
    if (confirmedPassword !== passwordForValidate && passwordForValidate && confirmedPassword) {
      setConfirmedPasswordError(t('authorization.confirmPasswordError'));
    } else if (passwordForValidate && confirmedPassword) {
      setConfirmedPasswordError('');
    }
  };

  const onBlurConfirmedPassword = () => {
    if (confirmedPassword !== password && password && confirmedPassword) {
      setConfirmedPasswordError(t('authorization.confirmPasswordError'));
    } else if (password && confirmedPassword) {
      setConfirmedPasswordError('');
    }
  };

  const handleSubmit = () => {
    if (!password) {
      setPasswordError(t('authorization.passwordLengthError'));
    }
    if (!confirmedPassword) {
      setConfirmedPasswordError(t('authorization.confirmPasswordError'));
    }
    if (password && confirmedPassword) {
      changePassword(password, confirmedPassword)
        .then((res) => {
          if (res.success) {
            onModalClose();
            setConfirmedText(t('profile.personalInfo.successChangePassword'));
            history.push('/personal-page/profile');
          }
        });
    }
  };

  return (
    <>
      <SnackBarWindow
        isOpen={!!confirmedText}
        onClose={() => setConfirmedText('')}
        content={confirmedText}
        type="success"
      />
      <Modal
        onCloseModal={onModalClose}
        title={t('profile.personalInfo.passwordModalTitle')}
        icon={KeyIcon}
        isOpen={isOpen}
      >
        <InputLabel>{t('profile.personalInfo.newPassword')}</InputLabel>
        <Input
          placeholder={t('authorization.password')}
          type="password"
          label={t('authorization.password')}
          isCopied
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          onBlur={() => onBlurPassword(password)}
          errorText={passwordError}
          withoutBorder
          height="40px"
        />
        <InputLabel>{t('profile.personalInfo.confirmedNewPassword')}</InputLabel>
        <Input
          placeholder={t('authorization.signUp.confirmPassword')}
          type="password"
          value={confirmedPassword}
          onChange={({ target }) => setConfirmedPassword(target.value)}
          onBlur={onBlurConfirmedPassword}
          errorText={confirmedPasswordError}
          onCopy={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          withoutBorder
          height="40px"
        />
        <Action>
          <Button
            width="small"
            isUpperCase
            isBoldText
            onClick={handleSubmit}
          >
            {t('profile.personalInfo.editButton')}
          </Button>
        </Action>
      </Modal>
    </>
  );
};

export default withRouter(UpdatePassword);

const InputLabel = styled.div`
  margin-bottom: .5rem;
  font-size: 15px;
`;

const Action = styled.div`
  margin: 2rem 0 1rem 0;
  display: flex;
  justify-content: flex-end;
`;
