/* eslint-disable no-shadow */
import React, {
  createContext,
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  deleteServerAction,
  editServerAction,
  createServerAction,
  getServersAction,
} from '@/services/actions/admin/servers.actions';
import { useHistory } from 'react-router-dom';

import { SnackTypes } from '@/AppContext';
import { useAdminContext } from '../../../../../AdminContext';

const ServersContext = createContext({});

const initialFilters = {
  page: 1,
};

export const ServersContextProvider = ({ children }) => {
  const history = useHistory();

  const { t } = useTranslation('translations');
  const { setAdminSnack } = useAdminContext();

  const [filters, setFilters] = useState(initialFilters);
  const [servers, setServers] = useState([]);

  const getReqError = (err) => {
    const useKey = (err?.data?.message_type || null) ? `admin.iptv.servers.errors.${err.data.message_type}` : 'common.error.undefined';
    return { content: t(useKey), type: SnackTypes.error };
  };

  const getServers = () => {
    getServersAction()
      .then((res) => setServers(res.results))
      .catch((err) => setAdminSnack(getReqError(err), SnackTypes.error));
  };

  const editServer = (id, item) => {
    editServerAction(id, item)
      .then(() => {
        if (history.location.pathname !== '/admin/iptv/servers') {
          history.push('/admin/iptv/servers');
        } else {
          getServers();
        }
      })
      .catch((err) => setAdminSnack(getReqError(err), SnackTypes.error));
  };

  const handleAutoDisableSale = (i, checked, id) => {
    const data = [...servers];
    data[i].auto_disable_sale = checked;
    editServer(id, data[i]);
  };

  const handleManualDisableSale = (i, checked, id) => {
    const data = [...servers];
    data[i].manual_disable_sale = checked;
    editServer(id, data[i]);
  };

  const deleteServer = (id, newServerId) => {
    deleteServerAction(id, newServerId)
      .then(() => getServers())
      .catch((err) => setAdminSnack(getReqError(err), SnackTypes.error));
  };

  const createServer = (item) => {
    createServerAction(item)
      .then(() => history.push('/admin/iptv/servers'))
      .catch((err) => setAdminSnack(getReqError(err), SnackTypes.error));
  };

  const value = {
    servers,
    filters,
    setFilters,
    handleAutoDisableSale,
    handleManualDisableSale,
    deleteServer,
    editServer,
    createServer,
    getServers,
  };

  return (
    <ServersContext.Provider value={value}>
      {children}
    </ServersContext.Provider>
  );
};

export const useServersContext = () => useContext(ServersContext);
