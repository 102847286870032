import React, { useState } from 'react';
import styled from 'styled-components';
import {
  white,
  borderSecondary,
  text01,
} from '@/common/constants/theme';
import ArrowLeft from '@/common/components/icons/ArrowLeft';

const ExpandedBlock = ({ titleComponent, rightComponent, children }) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <Block>
      <Row isOpen={isOpen}>
        {titleComponent()}
        <ArrowIcon
          onClick={() => setOpen(!isOpen)}
          isOpen={isOpen}
        >
          <ArrowLeft />
        </ArrowIcon>
        {isOpen && rightComponent()}
      </Row>
      {isOpen && children}
    </Block>
  );
};

export default ExpandedBlock;

const Block = styled.div`
  position: relative;
  margin: 21px 0 18px 0;
  background: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
`;

const Row = styled.div`
  display: flex;
  padding: 16px 20px 0;
  ${({ isOpen }) => !isOpen && `
    padding-bottom: 16px;
  `}
  align-items: center;
`;

const ArrowIcon = styled.div`
  cursor: pointer;
  transform: rotate(-90deg);
  transition: transform .25s, background .25s;
  margin-left: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${({ isOpen }) => isOpen && `
    transform: rotate(90deg);
  `}
  path {
    fill: ${text01};
  }
  :hover {
    background: #a8b1ff33;
  }
`;
