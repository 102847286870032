/* eslint-disable no-return-assign, no-param-reassign, no-useless-return,arrow-body-style */
import RequestService from '../request.service';
import urls from '../routes/channels.api';

// Playlist region
export const getAllPlaylistsAction = () => RequestService(urls.playlistList);

export const getPlaylistAction = (id) => {
  const options = {
    method: 'GET',
    params: {
      limit: 1000,
      offset: 0,
    },
  };
  return RequestService(urls.channelItem(id), options);
};

export const handleCreatePlaylistAction = (playlist, isHidden = false) => {
  const options = {
    body: {
      title: playlist,
      is_hidden: isHidden,
    },
    method: 'POST',
  };
  return RequestService(urls.playlistList, options);
};

export const handleDeletePlaylistAction = (id) => RequestService(
  urls.playlistItem(id), { method: 'DELETE' },
);

export const handleEditPlaylistAction = async (playList) => {
  const options = {
    body: {
      title: playList.title,
    },
    method: 'PATCH',
  };
  const editResult = await RequestService(urls.playlistItem(playList.key || playList.id), options);
  return editResult;
};

// Group region
export const getAllChannelsAction = () => RequestService(urls.allAvailableChannels);

export const getChannelsByGroupIdAction = (playlistId, groupId, forPlayer = false) => {
  const params = {
    limit: 10000,
    offset: 0,
  };
  if (forPlayer) {
    params.is_hidden = false;
  }

  return RequestService(
    urls.groupItem(playlistId, groupId),
    {
      method: 'GET',
      params,
    },
  );
};

export const getGroupsAction = (playlistId, forPlayer = false) => {
  const params = {
    limit: 10000,
    offset: 0,
  };
  if (forPlayer) {
    params.is_hidden = false;
  }
  return RequestService(
    urls.groupsList(playlistId),
    {
      method: 'GET',
      params,
    },
  );
};

export const handleCreateGroupAction = (name, playlistId) => {
  const options = {
    body: {
      name,
      playlist: playlistId,
    },
    method: 'POST',
  };
  return RequestService(urls.groupsList(playlistId), options);
};

export const handleEditGroupAction = (selectedPlaylist, group, newName) => {
  const currentGroup = { ...group };
  currentGroup.name = newName;
  RequestService(urls.groupItem(selectedPlaylist, currentGroup.id), {
    method: 'PUT',
    body: currentGroup,
  });
  // // .then(() => RequestService(urls.groupsItem(selectedPlaylist, currentGroup.id)));
  return currentGroup;
};

export const handleDeleteGroupAction = (selectedPlaylist, groupId) => {
  RequestService(urls.groupItem(selectedPlaylist, groupId), {
    method: 'DELETE',
  });
};

export const groupDragAndDropAction = (playlistId, groupId, prevGroupId) => {
  const options = {
    body: {
      prev_group_id: prevGroupId,
    },
    method: 'POST',
  };
  return RequestService(urls.groupDragAndDrop(playlistId, groupId), options);
};

export const groupsHideAction = (playlistId, isHidden) => {
  const options = {
    body: {
      is_hidden: isHidden,
    },
    method: 'PUT',
  };
  return RequestService(urls.groupsHide(playlistId), options);
};

export const groupHideAction = (playlistId, groupId, isHidden) => {
  const options = {
    body: {
      is_hidden: isHidden,
    },
    method: 'PUT',
  };
  return RequestService(urls.groupHide(playlistId, groupId), options);
};

// Channel region
export const handleStarChannelAction = (state, i, isStarred, activeGroup) => {
  const currentState = [...state];
  const starredGroupId = currentState.findIndex((item) => item.title === 'Избранное');

  currentState[activeGroup].channels[i].isStarred = !!isStarred;
  if (isStarred) {
    currentState[starredGroupId].channels.push(currentState[activeGroup].channels[i]);
  } else {
    const id = currentState[starredGroupId].channels
      .findIndex((item) => item.title === currentState[activeGroup].channels[i].title);
    currentState[starredGroupId].channels = [
      ...currentState[starredGroupId].channels.slice(0, id),
      ...currentState[starredGroupId].channels.slice(id + 1),
    ];
  }
  return currentState;
};

export const channelHideAction = (playlistId, groupId, channelId, isHidden) => {
  const options = {
    body: {
      is_hidden: isHidden,
    },
    method: 'PUT',
  };
  return RequestService(urls.channelHide(playlistId, groupId, channelId), options);
};

export const channelsHideAction = (playlistId, groupId, isHidden) => {
  const options = {
    body: {
      is_hidden: isHidden,
    },
    method: 'PUT',
  };
  return RequestService(urls.channelsHide(playlistId, groupId), options);
};

export const channelDragAndDropAction = (playlistId, groupId, channelId, prevChannelId) => {
  const options = {
    body: {
      prev_channel_id: prevChannelId,
    },
    method: 'POST',
  };
  return RequestService(urls.channelDragAndDrop(playlistId, groupId, channelId), options);
};

export const channelMoveEndAction = async (playlistId, groupId, channelId, newGroupId) => {
  const options = {
    body: {
      group: newGroupId,
    },
    method: 'PUT',
  };
  return RequestService(urls.channelMove(playlistId, groupId, channelId), options);
};

export const getPlaylistOsAction = () => RequestService(urls.playlistOs)
  .then((res) => {
    const result = res.results;
    result.forEach((os) => {
      os.playlist_types.forEach((player) => {
        player.description = JSON.parse(player.description.replace('\\"', '"'));
      });
      os.playlist_types = os.playlist_types.sort((a, b) => (a.id - b.id));
    });
    return result;
  });
