import React, { useEffect, useRef } from 'react';

export default ({ children, cb }) => {
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          cb();
        }
      },
      {
        root: null,
        rootMargin: '10%',
        threshold: 0.1,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  return (
    <div ref={ref}>
      {children}
    </div>
  );
};
