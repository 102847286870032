import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Provider } from 'react-redux';

import HeaderShell from './common/components/HeaderShell/HeaderShell';
import Footer from './common/components/Footer';
// import Loader from './common/components/Loader';
import LoaderFullScreen from './common/components/LoaderFullScreen';
import LocationListener from './common/components/LocationListener';

import { USE_HEADER_HEIGHT, USE_HEADER_MIN_SCROLL, useAppContext } from './AppContext';
import Routes from './routes/Routes';
import { adminStore } from './store/admin';
import { store } from './store/user';
import auth from './services/auth.service';

import AdminContainer from './screens/Admin/AdminContainer';

const App = () => {
  const location = useLocation();

  const {
    getLastUserUrl,
    userInfo, getUser,
    isLoading,
  } = useAppContext();

  useEffect(() => {
    const currentLocationPath = [location.pathname, location.hash].join('');
    const lastUrl = getLastUserUrl();
    let useLastUrl = '';
    if (lastUrl.length > 0) {
      useLastUrl = currentLocationPath === lastUrl ? lastUrl : '';
    }
    if ((useLastUrl.length < 2) && (currentLocationPath === '/')) {
      useLastUrl = '/personal-page/profile';
    }
    if (auth.getToken() && !userInfo) { getUser(useLastUrl); }
  }, [userInfo]);

  const isUserExist = auth.getToken();
  return (
    <>
      <LocationListener isUserExist={isUserExist} />
      {isLoading && <LoaderFullScreen />}
      <Switch>
        {isUserExist && (
          <Route
            path="/admin"
            render={() => (
              <Provider store={adminStore}>
                <AdminContainer isUserExists={isUserExist} />
              </Provider>
            )}
          />
        )}
        <Route
          path="/"
          render={() => (
            <Provider store={store}>
              <HeaderShellPlaceholder useHeight={USE_HEADER_HEIGHT} />
              <HeaderShell
                isUserExist={isUserExist}
                isUserAuth={userInfo?.id}
                height={USE_HEADER_HEIGHT}
                minScroll={USE_HEADER_MIN_SCROLL}
              />
              <Routes isUserExist={isUserExist} isUserAuth={userInfo?.id} />
              <Footer />
            </Provider>
          )}
        />
      </Switch>
    </>
  );
};

export default App;

const HeaderShellPlaceholder = styled.div`
  width: 100%;
  ${({ useHeight }) => (useHeight && `height: calc(${useHeight} + 20px);`)}
`;
