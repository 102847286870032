import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import {
  secondary,
  text01,
  border, greyText,
} from '@/common/constants/theme';
import urls from '@/services/routes/admin/admin.api';
import ExportIcon from '../../../../../icons/ExportIcon';
import ImportIcon from '../../../../../icons/ImportIcon';

import AdminFilters from '../../../../../Filters';
import AdminDropdown from '../../../../components/Dropdown';
import AdminDownloadDropdown from '../../../../components/DownloadDropdown';

import { useBaseContext } from '../BaseContext';

const EXPORT_OPTIONS = [
  {
    title: 'Каналов/групп в CSV',
    href: urls.exportData('csv'),
    key: 'csv',
    name: 'channels_groups',
    format: 'csv',
  },
  {
    title: 'Каналов/групп в M3U ministra',
    href: urls.exportData('m3u'),
    key: 'ministra',
    name: 'channels_groups',
    format: 'm3u',
  },
];

const IMPORT_OPTIONS = [
  {
    title: 'Каналов/групп из CSV',
    key: 'csv',
  },
];

const BaseFilters = ({ setIsCreateTab, setIsDeleteAll }) => {
  const history = useHistory();

  const [isChannelsFilterBusy, setIsChannelsFilterBusy] = useState(false);
  const [isExportDropdown, setIsExportDropdown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [exportDropdownValue, setExportDropdownValue] = useState('');
  const [isImportDropdown, setIsImportDropdown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [importDropdownValue, setImportDropdownValue] = useState('');

  const { exportData } = useBaseContext();

  const exportRef = useRef(null);
  const importRef = useRef(null);

  return (
    <AdminFilters
      handleDeleteAll={() => setIsDeleteAll(true)}
      isDisabled={isChannelsFilterBusy}
    >
      <Text
        isDisabled={isChannelsFilterBusy}
        secondaryColor={!isChannelsFilterBusy}
        onClick={() => (isChannelsFilterBusy ? setIsCreateTab((prevState) => !prevState) : null)}
      >
        Создать канал
      </Text>
      <Text
        grey={isChannelsFilterBusy || isExportDropdown}
        onClick={() => !isChannelsFilterBusy && setIsExportDropdown((prevState) => !prevState)}
        ref={exportRef}
      >
        Экспорт
        <Icon grey={isChannelsFilterBusy || isExportDropdown}>
          <ExportIcon />
        </Icon>
        <AdminDownloadDropdown
          ref={exportRef}
          isOpen={!isChannelsFilterBusy && isExportDropdown}
          isDisabled={isChannelsFilterBusy}
          onClose={() => setIsExportDropdown(false)}
          options={EXPORT_OPTIONS}
          onSelect={(option) => {
            if (isChannelsFilterBusy) {
              return;
            }
            setIsChannelsFilterBusy(true);
            setIsExportDropdown(false);
            setExportDropdownValue(option.key);
            exportData(option, () => { setIsChannelsFilterBusy(false); });
          }}
          position="left"
          value=""
        />
      </Text>
      <Text
        grey={isChannelsFilterBusy || isImportDropdown}
        onClick={() => !isChannelsFilterBusy && setIsImportDropdown((prevState) => !prevState)}
        ref={importRef}
      >
        Импорт
        <Icon grey={isChannelsFilterBusy || isImportDropdown}>
          <ImportIcon />
        </Icon>
        <AdminDropdown
          ref={importRef}
          isOpen={!isChannelsFilterBusy && isImportDropdown}
          isDisabled={isChannelsFilterBusy}
          onClose={() => setIsImportDropdown(false)}
          options={IMPORT_OPTIONS}
          onSelect={(option) => {
            if (isChannelsFilterBusy) {
              return;
            }
            setImportDropdownValue(option.key);
            setIsImportDropdown(false);
            history.push('/admin/iptv/base/import');
          }}
          position="left"
          value=""
        />
      </Text>
    </AdminFilters>
  );
};

export default BaseFilters;

const Text = styled.div`
  display: flex;

  ${({ normalText }) => normalText && (`
    margin-left: 15px;

    font-weight: 500;
    color: ${text01};
  `)}
  ${({ secondaryColor }) => secondaryColor && (`color: ${secondary};`)}
  ${({ grey }) => grey && (`color: ${border}`)}
  ${({ isDisabled }) => isDisabled && (`color: ${greyText};`)}
`;

const Icon = styled.div`
  margin-left: 10px;

  position: relative;
  top: 2px;

  & svg path {
    ${({ grey }) => grey && (`fill: ${border};`)}
  }
`;
