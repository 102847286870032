/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import React, {
  createContext, useContext,
  useState, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { sortObjectsByOrderingId } from '@/common/utils/sort.utils';

import { getEPGAction, getEPGCurrent } from '@/services/actions/player.actions';
import {
  // getGroupsAction, getChannelsByGroupIdAction,
  getAllChannelsAction,
} from '@/services/actions/channels.actions';
import {
  setSnack,
  getChannelsLogos,
  subscribeTypes,
} from '@/store/user/selectors';

import {
  USE_HTTPS_FOR_CHANNELS_LOGO,
  usePersonalPageContext,
} from '../../../PersonalPageContext';

const OVERRIDE_CHANNEL_DOMAIN = 'cdn.ab01.spr24.net';

const CHANELS = [{
  id: '128', name: 'Первый канал', grup: 'RU общероссийские', archive: true,
}, {
  id: '3187', name: 'СТС HD', grup: 'RU Общероссийские', archive: false,
}];
const EMPTY_CHANNEL = {};
const EMPTY_CHANNEL_GROUP = null;

const CURRENT_EPG_RENEW_INTERVAL = 1000 * 60;

// const TOKEN = 'vyH9sWnBw46hZ8';
// const SERVER = 'cdn.ru01.spr24.net';

// eslint-disable-next-line no-unused-vars
const CHANNEL_UPDATE_INTERVAL = 1000 * 0.6;

export const PlayerContext = createContext({});

export const PlayerContextProvider = ({ children }) => {
  const { t } = useTranslation('translations');

  const [localDevices, setLocalDevices] = useState([]);
  const [allChannelsIdsMap, setAllChannelsIdsMap] = useState([]);
  const [allChannels, setAllChannels] = useState(null);
  const [channels, setChannels] = useState([]);
  const [channelSearch, setChannelSearch] = useState('');
  const [channelsGroups, setChannelsGroups] = useState(null);
  const [channelsPlaylists, setChannelsPlaylists] = useState(null);
  const [activeChannel, setActiveChannel] = useState(EMPTY_CHANNEL);
  const [timerId, setTimerId] = useState(null);
  const [playedProgram, setPlayedProgram] = useState(null);
  const [channelUrl, setChannelUrl] = useState('');

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [selectedChannelsGroup, setSelectedChannelsGroup] = useState(EMPTY_CHANNEL_GROUP);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [currentEpg, setCurrentEpg] = useState(null);

  const [fullCurrentEPG, setFullCurrentEPG] = useState(null);

  const [isChannelBusy, setIsChannelBusy] = useState(false);

  const {
    allDevices,
    playlists, serversFullData,
  } = usePersonalPageContext();

  const dispatch = useDispatch();

  const ALL_CHANNELS_GROUP = { id: 0, name: t('player.allChanelsGroupName') };

  const getPlaylistChannels = (playlist) => {
    if (!playlist?.groups || !allChannels) {
      return [];
    }
    const list = [];
    playlist.groups.forEach((g) => {
      (g?.channels || []).forEach((ch) => {
        list.push(ch);
      });
    });
    return list;
  };

  // eslint-disable-next-line no-unused-vars
  const setChannelsLogoProto = (channel) => (USE_HTTPS_FOR_CHANNELS_LOGO
    ? { ...channel, url: channel.url.replace('http://', 'https://') } : channel);

  const filterDevices = (devices) => (devices || [])
    .filter((d) => (
      [subscribeTypes.Active, subscribeTypes.Ending]
        .includes(d.subscription_type)))
    .filter((d) => !!d.server);
    // .filter((d) => !!d.subscription?.is_premium);

  const getStreamUrl = (chanelId, ago, from = null, to = null) => {
    if (!selectedDevice || (selectedDevice?.ott_id || '').length === 0) {
      return null;
    }

    const useServer = (serversFullData || []).find((s) => s.id === selectedDevice.server);
    if (!useServer) {
      return null;
    }

    const useDns = (OVERRIDE_CHANNEL_DOMAIN.length > 0) ? OVERRIDE_CHANNEL_DOMAIN : useServer.dns;
    return (!from || !to)
      ? `https://${useDns}/${chanelId}/embed.html?token=${selectedDevice.ott_id}&autoplay=false&ago=${ago || 0}`
      : `https://${useDns}/${chanelId}/embed.html?token=${selectedDevice.ott_id}&autoplay=false&from=${from}&to=${to}`;
  };

  const changeProgram = (programm) => {
    const { time, time_to } = programm;
    setPlayedProgram(programm);
    const channelStreamId = activeChannel.tvg_logo || 0;
    if (selectedProgram?.time === programm.time) {
      const timeAgo = Math.round((new Date() / 1000) - programm.time);
      setChannelUrl(getStreamUrl(channelStreamId, timeAgo));
    } else {
      setChannelUrl(getStreamUrl(channelStreamId, null, time, time_to));
    }
  };

  const getCurrentProgram = (epg) => (
    (epg || []).find((programm) => (
      (programm?.time || 0) * 1000 <= new Date() && new Date() <= (programm?.time_to || 0) * 1000
    ))
  );

  const getChannel = (channelId) => {
    setIsChannelBusy(true);
    setCurrentEpg(null);
    setSelectedProgram(null);
    return getEPGAction(channelId)
      .then((res) => {
        const epgData = res?.epg_data || [];
        if (epgData.length > 0) {
          setCurrentEpg(epgData);
          const curProg = getCurrentProgram(epgData);
          setSelectedProgram(curProg);
          setPlayedProgram(curProg);
        }
        return epgData;
      })
      .catch((err) => {
        dispatch(setSnack(err));
        return [];
      })
      .finally(() => {
        setIsChannelBusy(false);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const updateActiveChannel = () => {
    if (activeChannel) {
      let isDayChanged = false;
      const currentProgram = getCurrentProgram(currentEpg);
      if (!currentProgram) {
        isDayChanged = true;
      }
      setSelectedProgram(currentProgram);
      if (isDayChanged) {
        getChannel(activeChannel?.tvg_logo);
      }
    }
  };

  const updateChannels = (data) => {
    let isDayChanged = false;
    const updatedChannels = (data || []).map((channel) => {
      const currentProgramm = getCurrentProgram(channel?.epg);
      if (!currentProgramm) {
        isDayChanged = true;
      }
      return ({
        ...channel,
        currentProgramm,
      });
    });
    setChannels(updatedChannels);
    if (isDayChanged) {
      clearInterval(timerId);
      // eslint-disable-next-line no-use-before-define
      getChannels();
    }
  };

  async function getChannels() {
    const data = [];
    for (const chanel of CHANELS) {
      const epgDataResult = await getEPGAction(chanel.id)
        .then((res) => res)
        .catch((err) => {
          dispatch(setSnack(err));
          return [];
        });
      const epgData = epgDataResult?.epg_data || [];
      data.push({
        ...chanel,
        currentProgramm: getCurrentProgram(epgData),
        epg: epgData,
      });
    }
    setChannels(data);
    const currentChanel = data.find((chanel) => chanel.id === activeChannel.id) || data[0];
    setPlayedProgram(currentChanel?.currentProgramm || { time: (new Date() / 1000) });
    setActiveChannel(currentChanel);
    setTimerId(setInterval(() => updateChannels(data), 600));
  }

  const selectChannel = (id) => {
    const currentChanel = (allChannels || []).find((chanel) => chanel.id === id) || channels[0];
    setPlayedProgram(currentChanel?.currentProgramm || { time: (new Date() / 1000) });
    setActiveChannel(currentChanel);
  };

  const deselectChannels = () => {
    setChannels([]);
    setActiveChannel(EMPTY_CHANNEL);
  };

  const handleSearchChannels = (str) => {
    setChannelSearch(str);
  };

  useEffect(() => {
    if (allDevices) {
      const devicesAvailable = filterDevices(allDevices);
      setLocalDevices(devicesAvailable);
      if (devicesAvailable.length > 0) {
        setSelectedDevice(devicesAvailable[0]);
      }
    }
  }, [allDevices]);

  useEffect(() => {
    if (channelsPlaylists && (selectedDevice?.playlist || null)) {
      setSelectedPlaylist(channelsPlaylists.find((pl) => pl.id === selectedDevice.playlist));
    }
  }, [channelsPlaylists, selectedDevice]);

  useEffect(() => {
    if (activeChannel.id && playedProgram && selectedDevice?.server) {
      const timeAgo = Math.round((new Date() / 1000) - playedProgram.time);
      setChannelUrl(getStreamUrl(activeChannel.tvg_logo, timeAgo));
      clearInterval(timerId);
      // eslint-disable-next-line no-use-before-define
      // setTimerId(setInterval(() => updateActiveChannel(), CHANNEL_UPDATE_INTERVAL * 100));
    }
  }, [activeChannel.id, playedProgram, selectedDevice?.server]);

  useEffect(() => {
    if (!playlists) {
      return;
    }
    if (!allChannels) {
      setIsChannelBusy(true);
      getAllChannelsAction()
        .then((res) => {
          const playlistsById = [];
          playlists.forEach((p) => {
            playlistsById[p.id] = p;
          });
          // const loadedChannels = (res?.channels || []);
          const loadedChannels = (res?.channels || []).map(setChannelsLogoProto);
          const loadedPlaylists = (res?.playlists || [])
            .map((pl) => ({ ...pl, title: playlistsById?.[pl.id]?.title || '-' }));

          const idsMap = [];
          loadedChannels.forEach((ch, index) => {
            idsMap[ch.id] = index;
          });
          setAllChannelsIdsMap(idsMap);

          dispatch(getChannelsLogos(loadedChannels));
          setAllChannels(loadedChannels);
          setChannelsPlaylists(loadedPlaylists);

          setIsChannelBusy(false);
        })
        .catch((err) => { dispatch(setSnack(err)); });
      // setActiveChannel(channels.find(
      //   (channel) => channel.id === activeChannel.id,
      // ) || channels[0]);
    }
  }, [allChannels, playlists]);

  useEffect(() => {
    if (!selectedPlaylist || !allChannels) {
      return;
    }
    const allGroups = [
      { ...ALL_CHANNELS_GROUP, channels: getPlaylistChannels(selectedPlaylist) },
      ...selectedPlaylist?.groups,
    ];
    setChannelsGroups(allGroups.sort(sortObjectsByOrderingId));
    setSelectedChannelsGroup(allGroups[0]);
  }, [selectedPlaylist?.id]);

  useEffect(() => {
    if (selectedChannelsGroup) {
      deselectChannels();
      const useChannels = (selectedChannelsGroup?.channels || [])
        .sort(sortObjectsByOrderingId)
        .map((uch) => allChannels[allChannelsIdsMap[uch.id]]);
      setChannels(useChannels);
      selectChannel(useChannels[0].id);
    }
  }, [selectedChannelsGroup]);

  useEffect(() => {
    if (activeChannel?.id || null) {
      getChannel(activeChannel?.tvg_logo);
    }
  }, [activeChannel]);

  useEffect(() => {
    if (selectedProgram) {
      changeProgram(selectedProgram);
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (!fullCurrentEPG) {
      getEPGCurrent()
        .then((res) => setFullCurrentEPG(res))
        .catch((err) => dispatch(setSnack(err)));

      setInterval(() => {
        getEPGCurrent()
          .then((res) => setFullCurrentEPG(res))
          .catch((err) => dispatch(setSnack(err)));
      }, CURRENT_EPG_RENEW_INTERVAL);
    }
  }, [fullCurrentEPG]);

  const value = {
    localDevices,
    selectedDevice,
    setSelectedDevice,
    selectedPlaylist,
    setSelectedPlaylist,
    selectedChannelsGroup,
    setSelectedChannelsGroup,
    selectedProgram,
    channels,
    channelsGroups,
    channelsPlaylists,
    getChannels,
    channelSearch,
    handleSearchChannels,
    activeChannel,
    selectChannel,
    channelUrl,
    playedProgram,
    changeProgram,
    currentEpg,
    fullCurrentEPG,
    isChannelBusy,
  };

  return (
    <PlayerContext.Provider value={value}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayerContext = () => useContext(PlayerContext);
