import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import {
  primary, grey, background01, text01,
} from '@/common/constants/theme';
import ExpandIcon from '@/assets/icons/arrow-down-blue.svg';
import DownloadIcon from '@/assets/icons/download.svg';

const PiconLinkList = ({ links }) => (
  <Container>
    <Collapse expandIconPosition="start">
      <StyledPanel
        showArrow={false}
        header={(
          <>
            <span>
              Picon
            </span>
            <i />
          </>
        )}
      >
        <Content>
          {links.map((link) => (
            <LinkWrapper key={link.name}>
              <a href={link.url} target="_blank" rel="noreferrer">{link.name}</a>
              <DownloadIconLink
                href={link.url}
                target="_blank"
                rel="noreferrer"
              >
                <Icon src={DownloadIcon} />
              </DownloadIconLink>
            </LinkWrapper>
          ))}
        </Content>
      </StyledPanel>
    </Collapse>
  </Container>
);

export default PiconLinkList;

const Container = styled.div`
  width: 100%;
  .ant-collapse {
    background-color: transparent;
    border: none;
    border-bottom: 0;
    border-radius: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
    padding: .25rem 0;
    border: none;
    font-family: Gilroy, sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: ${primary};
  }
  .ant-collapse > .ant-collapse-item {
    border: none;
    background: transparent;
  }

  .ant-collapse-content {
    overflow: hidden;
    color: inherit;
    background-color: transparent;
    border-top: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header i {
      transform: rotate(180deg);
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
    content: '';
    display: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border: 1px solid #efeef3;
  background: ${background01};
  border-radius: 4px;
  margin: 1rem 0;
  padding: 10px 1rem;
  overflow-y: auto;
`;

const StyledPanel = styled(Collapse.Panel)`
  border-radius: 0;
  box-shadow: none;
  border: none;
  background-color: transparent;
  position: relative;

  font-style: normal;
  font-weight: normal;
  line-height: 12px;
  color: ${grey};
  a {
    font-family: Gilroy, sans-serif;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.16px;
    color: ${text01};
  }
  .ant-collapse-item-active {
      i {
        transform: rotate(180deg);
      }
  }
  i {
      cursor: pointer;
      position: relative;
      top: 0;
      width: 9px;
      height: 6px;
      display: inline-block;
      margin-left: 12px;
      background: url(${ExpandIcon}) center center no-repeat;
      background-size: contain;
  }
`;

const DownloadIconLink = styled.a`
  display: inline-block;
  font-size: 0;
`;

const Icon = styled.img``;

const LinkWrapper = styled.div`
  padding: .25rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
