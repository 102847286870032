import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { primary, secondary, text02 } from '@/common/constants/theme';

import Player from './icons/PlayerIcon';

import PageContainer from '../../../common/components/PageContainer';

import { SHOW_DNS_CONTROLS, usePersonalPageContext } from '../PersonalPageContext';

const PageNavigation = ({ history, marginBottom }) => {
  const { t } = useTranslation('translations');
  const { personalPageNavContainer, isSupportUnread } = usePersonalPageContext();

  const path = history.location.pathname.slice(1);
  const stepUrls = [
    'personal-page/devices',
    'personal-page/subscription',
    'personal-page/server',
    'personal-page/channels',
    'personal-page/completion',
  ];

  return (
    <Container marginBottom={marginBottom}>
      <PageContainer>
        <Content ref={personalPageNavContainer}>
          <Navigation>
            <NavItem
              to="/personal-page/devices"
              isactive={stepUrls.find((item) => item === path) ? 'true' : 'false'}
            >
              {t('mainMenu.viewSettings')}
            </NavItem>
            {SHOW_DNS_CONTROLS && (
              <NavItem to="/personal-page/dns" isactive={(path === 'personal-page/dns') ? 'true' : 'false'}>
                {t('mainMenu.DNSManagement')}
              </NavItem>
            )}
            <NavItem to="/personal-page/logs" isactive={(path === 'personal-page/logs') ? 'true' : 'false'}>{t('mainMenu.workLog')}</NavItem>
            <NavItem to="/personal-page/referrals" isactive={path.startsWith('personal-page/referrals') ? 'true' : 'false'}>{t('mainMenu.referrals')}</NavItem>
            <NavItemWrapper isNotification={isSupportUnread}>
              <NavItem isactive={(path === 'personal-page/support') ? 'true' : 'false'} to="/personal-page/support">{t('mainMenu.support')}</NavItem>
            </NavItemWrapper>
          </Navigation>
          <PlayerContainer>
            <Icon isactive={path === 'personal-page/player'} onClick={() => history.push('/personal-page/player')}>
              <Player />
            </Icon>
            <PlayerText to="/personal-page/player" isactive={(path === 'personal-page/player') ? 'true' : 'false'}>{t('mainMenu.onlinePlayer')}</PlayerText>
          </PlayerContainer>
        </Content>
      </PageContainer>
    </Container>
  );
};

export default withRouter(PageNavigation);

const Container = styled.div`
  display: flex;
  border-top: 1px solid #EFEEF3;
  border-bottom: 1px solid #EFEEF3;
  padding: 1rem 0;
  margin: .5rem 0;
  width: 100%;
  justify-content: center;
  ${({ marginBottom }) => (marginBottom && `margin-bottom: ${marginBottom};`)}
`;

const Content = styled.div`
  display: flex;
`;

const Navigation = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  margin-right: 3rem;
  @media (max-width: 1300px) {
    margin-right: 1rem;
  }
`;

const NavItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ${({ isNotification }) => isNotification && `
    &:after {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      top: -5px;
      right: 4px;
      background: ${primary};
      box-shadow: 0px 4px 4px rgba(92, 112, 207, 0.3);
    }
  `}
`;

const NavItem = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${({ isactive }) => ((isactive === 'true') ? secondary : text02)};
  margin: 0 .5rem;
  padding: .25rem 1rem;
  white-space: nowrap;
  border: 1px solid ${({ isactive }) => (isactive === 'true' ? secondary : 'transparent')};
  border-radius: 4px;
  &&:hover {
    color: ${secondary};
  }
  @media (max-width: 1360px) {
    font-size: 12px;
  }
`;

const PlayerContainer = styled.div`
  width: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #EFEEF3;
  cursor: pointer;

  &&:hover a {
    color: #ffab30;
  }
  &&:hover path {
    fill: #ffab30;
  }
  svg path {
    transition: all 0.20s ease-in;
  }
`;

const PlayerText = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${({ isActive }) => (isActive ? secondary : primary)};
`;

const Icon = styled.div`
  margin: 0 .65rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ isActive }) => (isActive && `
  & path {
    fill: ${secondary};
  }
  `)};

`;
