import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { white } from '@/common/constants/theme';
import SnackBarWindow from '../../../../../../common/components/SnackBarWindow';

const FinalBalanceModal = ({
  inputValue,
  bonusValue,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('translations');

  const useInputValue = parseFloat(inputValue);
  const useBonusValue = parseFloat(bonusValue);

  return (
    <SnackBarWindow
      isOpen={isOpen}
      onClose={() => onClose(false)}
      type="success"
      content={(
        <Text>
          <Text>
            {t('balance.modal.thanksModal.success.text',
              { value: ((useInputValue || 0) + (useBonusValue || 0)).toFixed(1) })}
          </Text>
          {(useBonusValue > 0) && (
            <Text>
              {t(
                'balance.modal.thanksModal.bonus',
                { percentValue: ((useBonusValue / useInputValue) * 100).toFixed(1) },
              )}
            </Text>
          )}
          <Text topMargin>{t('balance.modal.thanksModal.description')}</Text>
        </Text>
      )}
    />
  );
};

export default FinalBalanceModal;

const Text = styled.div`
  ${({ topMargin }) => topMargin && ('margin-top: 20px;')};

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  color: ${white};
  line-height: 20px;
  text-align: center;
  letter-spacing: .16px;
`;
