import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  background4,
  text01,
  text02,
  activeTertiary,
  secondary,
} from '@/common/constants/theme';
import DocIcon from '@/assets/icons/doc.svg';
import CloseIcon from '@/assets/icons/closeGray.svg';
import InstructionImg from '@/assets/images/instruction-img.png';

const Docs = ({
  isOpen,
  onClose,
  doc,
}) => {
  const { t } = useTranslation(['translations']);
  return (
    <>
      {isOpen && (
        <Container>
          <Row>
            <TitleWrapper>
              <Icon src={DocIcon} />
              <Title>{t('completion.docs.setupGuide')}</Title>
            </TitleWrapper>
            <CloseButton src={CloseIcon} onClick={onClose} />
          </Row>
          <Wrapper>
            <InstructionWrapper>
              {doc?.items.map((item) => (
                <Value title={item} key={`play_list_doc_item_${item}`}>
                  {item}
                </Value>
              ))}
              {doc.url && (
                <MoreButton
                  onClick={() => (window.open(doc.url, '_blank'))}
                >
                  {t('completion.docs.readMore')}
                </MoreButton>
              )}
            </InstructionWrapper>
            <Image src={InstructionImg} />
          </Wrapper>
        </Container>
      )}
    </>
  );
};

export default Docs;

const Container = styled.div`
  background: ${background4};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06), inset 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 19px 19px;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 1.25rem 1.5rem;
`;

const Title = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${text01};
  border-bottom: 2px solid ${activeTertiary};
  border-radius: 2px;
  margin-left: .5rem;
`;

const Icon = styled.img``;

const CloseButton = styled.img`
  cursor: pointer;
  margin-right: 2.5rem;
  width: 10px;
`;

const InstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: calc(100% - 135px - 2.5rem - 3rem);
  height: 10rem;
  flex-wrap: wrap;
  margin: 0 1.5rem 1.5rem;
`;

const Value = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.16px;
  color: ${text02};
  //max-width: 17.5rem;
  // text-overflow: ellipsis;
  //white-space: nowrap;
  overflow: hidden;
`;

const Image = styled.img`
  width: 135px;
  height: 160px;
  margin-right: 2.5rem;
`;

const MoreButton = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.16px;
  color: ${secondary};
  margin-top: 10px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
