import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import NewsBgSrc from '@/assets/images/news-bg.svg';
import { text01, secondary } from '@/common/constants/theme';
import PageContainer from '@/common/components/PageContainer';
import { useNewsContext } from './NewsContext';
import NewsSmallCard from './components/NewsSmallCard';
import NewsPagination from './components/NewsPagination';

const NewsList = () => {
  const { t } = useTranslation(['translations']);
  const {
    news,
    getNews,
    // setNewsPage,
    newsPage,
  } = useNewsContext();

  useEffect(() => {
    getNews();
  }, [newsPage]);

  return (
    <Container>
      <PageContainer>
        <Wrapper>
          <Wrapper flex flexCenter bottomMargin>
            <Wrapper>
              <Title>{t('news.title')}</Title>
              <Wrapper flex flexCenter>
                <Title orange>{t('news.orangeTitle')}</Title>
                <Title>{t('news.title02')}</Title>
              </Wrapper>
            </Wrapper>
          </Wrapper>
          <Wrapper flex flexSb>
            {news.map((item) => (
              <NewsSmallCard
                key={item.id}
                id={item.id}
                title={item.title}
                shortText={item.short_text}
                date={item.created_date}
                details={item.text}
                forumLink={item.isForum}
              />
            ))}
          </Wrapper>
          <Wrapper>
            <NewsPagination />
          </Wrapper>
          <Background>
            <Img
              src={NewsBgSrc}
              alt=""
            />
          </Background>
        </Wrapper>
      </PageContainer>
    </Container>
  );
};

export default NewsList;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 63px;
  margin-bottom: 110px;
  position: relative;
  overflow: hidden;

  min-height: 460px
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexCenter }) => flexCenter && ('justify-content: center;')}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  flex-wrap: wrap;

  ${({ bottomMargin }) => bottomMargin && ('margin-bottom: 90px;')}

  position: relative;
`;

const Title = styled.div`
  ${({ orange }) => orange && ('margin-right: 10px;')}

  font-family: Gilroy;
  font-weight: bold;
  font-size: 44px;
  line-height: 48px;
  text-align: center;
  color: ${({ orange }) => (orange ? secondary : text01)};
`;

const Background = styled.div`
  position: absolute;
  left: -215px;
  top: 225px;
  z-index: -1;
`;

const Img = styled.img``;
