import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import Devices from './Devices/Devices';
import SubscriptionContainer from './Subscription/SubscriptionContainer';
import ProfileContainer from './Profile/ProfileContainer';
import LogsContainer from './LogsPage/LogsContainer';
import BalanceContainer from './Balance/BalanceContainer';
import FinalBalanceModal from './Balance/components/FinalBalanceModal';
import DNSContainer from './DNS/DNSContainer';
import ReferralsContainer from './Referrals/ReferralsContainer';
import ServerContainer from './Server/ServerContainer';
import PlaylistContainer from './Playlist/PlaylistContainer';
import ChannelsContainer from './Channels/ChannelsContainer';

import { convertRemToPixels } from '@/common/utils/units.utils';
import { USE_HEADER_HEIGHT } from '@/AppContext';
import { SHOW_DNS_CONTROLS, usePersonalPageContext } from '../../PersonalPageContext';

const MainContent = () => {
  const location = useLocation();

  const { personalPageNavContainer } = usePersonalPageContext();

  const [isFinalBalanceModal, setIsFinalBalanceModal] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [bonus, setBonus] = useState({
    value: 0,
    type: 'undefined',
  });

  useEffect(() => {
    if (location?.state?.scrollToTop) {
      window.scroll(0, 0);
    } else if (location?.state?.scrollToStepsNav && personalPageNavContainer.current) {
      const snTop = personalPageNavContainer.current.getBoundingClientRect().top
        - document.body.getBoundingClientRect().top;
      const hHeight = convertRemToPixels(parseInt(USE_HEADER_HEIGHT.replace('rem', ''), 10));
      const addMargin = -17;

      window.scrollTo({
        top: snTop - hHeight + addMargin,
        behavior: 'smooth',
      });
    }
  }, [location?.state, personalPageNavContainer.current]);

  return (
    <Container>
      <Switch>
        <Route exact path="/personal-page/devices" component={Devices} />
        <Route exact path="/personal-page/server" component={ServerContainer} />
        <Route exact path="/personal-page/channels" component={ChannelsContainer} />
        <Route exact path="/personal-page/subscription" component={SubscriptionContainer} />
        <Route exact path="/personal-page/playlist" component={PlaylistContainer} />
        <Route exact path="/personal-page/channels" component={ChannelsContainer} />
        <Route exact path="/personal-page/logs" component={LogsContainer} />
        {SHOW_DNS_CONTROLS && <Route exact path="/personal-page/dns" component={DNSContainer} />}
        <Route path="/personal-page/referrals" component={ReferralsContainer} />
        <Route exact path="/personal-page/balance">
          <BalanceContainer
            setIsFinalModal={setIsFinalBalanceModal}
            inputValue={inputValue}
            setInputValue={setInputValue}
            bonus={bonus}
            setBonus={setBonus}
          />
        </Route>
        <Route exact path="/personal-page/profile/:type?/:param?" component={ProfileContainer} />
        <Redirect to="/personal-page/devices" />
      </Switch>
      <FinalBalanceModal
        isOpen={isFinalBalanceModal}
        onClose={() => {
          if (isFinalBalanceModal) { setIsFinalBalanceModal(false); setInputValue(0); }
        }}
        inputValue={inputValue}
        bonusValue={bonus.value}
      />
    </Container>
  );
};

export default MainContent;

const Container = styled.div``;
