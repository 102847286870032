import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { AdminMainPopupHeader } from '../components/MainPopupComponents';
import FinanceUser from './components/FinanceUser';
import FinanceStats from './components/FinanceStats';
import FinanceTransfers from './components/FinanceTransfers';
import FinanceRequests from './components/FinanceRequests';

const Finance = () => {
  const history = useHistory();
  return (
    <Container>
      <AdminMainPopupHeader
        handleClose={() => history.push('/admin/users/main')}
        title="Финансы"
        linkText="Вернуться к списку пользователей "
      />
      <FinanceUser />
      <FinanceStats />
      <FinanceTransfers />
      <FinanceRequests />
    </Container>
  );
};
export default Finance;

const Container = styled.div``;
