import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import styled from 'styled-components';

import { BASE_PATH } from './TemplateDecorsContext';

import TemplateDecorsItem from './Components/TemplateDecorsItem';
import TemplateDecorsList from './Components/TemplateDecorsList';

const TemplateDecors = () => (
  <Container>
    <Switch>
      <Route exact path={BASE_PATH}>
        <TemplateDecorsList />
      </Route>
      <Route exact path={`${BASE_PATH}:id`}>
        <TemplateDecorsItem />
      </Route>
      <Redirect to={BASE_PATH} />
    </Switch>
  </Container>
);

export default TemplateDecors;

const Container = styled.div``;
