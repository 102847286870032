import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import CloseIcon from './icons/CloseIcon';
import {
  green, secondary, darkRed, white,
} from '../constants/theme';

const TYPE_COLORS = {
  success: green,
  warning: secondary,
  error: darkRed,
};

export default function SnackBarWindow({
  content = '',
  type = 'warning',
  isOpen,
  setIsOpen = () => {},
  timestamp = 5000,
  onClose = () => {},
  noOutsideClose = false,
}) {
  const [open, setOpen] = useState(isOpen);
  const [timer, setTimer] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const hide = () => {
    setOpen(false);
    onClose();
    setIsOpen(false);
    if (timer) {
      clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (open && timestamp) {
      setTimer(setTimeout(hide, timestamp));
    } else if (timer) {
      clearTimeout(timer);
    }
  }, [open]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (open) {
            event.preventDefault();
          }
          if (!noOutsideClose) {
            hide();
          }
        }
      }
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
        if (timer) {
          clearTimeout(timer);
        }
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef}>
      {open && (
        <Container type={type}>
          <Icon onClick={() => hide()}>
            <CloseIcon />
          </Icon>
          {content}
        </Container>
      )}
    </div>
  );
}

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16.5rem;
  box-shadow: 0 25px 34px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  padding: 1rem 3rem 1rem 1.5rem;
  min-height: 2.5rem;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${white};
  background: ${({ type }) => TYPE_COLORS[type] || secondary};
  z-index: 100000;
  white-space: pre-wrap;
  text-align: center;
`;

const Icon = styled.div`
  cursor: pointer;
  position: absolute;
  right: .8125rem;
  top: .5rem;
  path {
    fill: ${white};
  }
`;
