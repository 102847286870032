import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { text01, secondary } from '@/common/constants/theme';

const FAQPagination = ({ page, pageCount, pageHandler }) => {
  const { t } = useTranslation('translations');

  const [pagesList, setPagesList] = useState([]);

  useEffect(() => {
    setPagesList(new Array(pageCount || 1).fill(0).map((_, index) => index + 1));
  }, [pageCount]);

  return (
    <Container>
      {(pagesList.length > 1) && (
        <Caption>{`${t('publicPages.FAQ.paginationCaption')}:`}</Caption>
      )}
      {pagesList.map((p) => (
        <Page
          key={`FAQP-p--${p}`}
          isActive={page === p}
          onClick={() => {
            if (pageHandler) {
              pageHandler(p);
            }
          }}
        >
          {p}
        </Page>
      ))}
    </Container>
  );
};
export default FAQPagination;

const Container = styled.div`
  font-size: 1.2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Caption = styled.div`
  margin-right: .5rem;
`;

const Page = styled.div`
  padding: 0.125rem .25rem;
  margin-right: 0.25rem;
  color: ${({ isActive }) => (isActive ? secondary : text01)};
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};

  &:last-of-type {
    margin-right: 0;
  }
`;
