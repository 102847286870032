/* eslint-disable no-shadow */
/* eslint no-console: 0 */

import React, {
  createContext,
  useState,
  useContext,
} from 'react';

import {
  getMinistraAction,
  getAllMinistraAction,
  createMinistraAction,
  updateMinistraAction,
  ministraUpdateEPGAction,
  ministraCompareChannelsAction,
  ministraGetCompareChannelsResultAction,
  ministraSyncChannelsAction,
  ministraGetSyncChannelsResultAction,
  deleteMinistraAction,
} from '@/services/actions/admin/ministra.actions';

import {
  getServersAction,
} from '@/services/actions/admin/servers.actions';

const MinistraContext = createContext({});

const initialFilters = {
  page: 1,
};

export const MinistraContextProvider = ({ children }) => {
  const [selectedMinistra, setSelectedMinistra] = useState(null);
  const [ministra, setMinistra] = useState([]);
  const [ministraLength, setMinistraLength] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [servers, setServers] = useState([]);

  const ministraSyncDataStub = {};

  const getServers = () => {
    getServersAction()
      .then((res) => setServers(res.results))
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const getAllMinistra = () => {
    getAllMinistraAction()
      .then((res) => {
        setMinistra(res.results);
        setMinistraLength(res.count);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const getMinistra = (id) => getMinistraAction(id);

  const createMinistra = (data) => {
    createMinistraAction(data)
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const updateMinistra = (id, data) => {
    updateMinistraAction(id, data)
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const deleteMinistra = (id, newServerId) => {
    deleteMinistraAction(id, newServerId)
      .then(() => setMinistra((prevMinistra) => prevMinistra.filter((item) => item.id !== id)))
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  };

  const ministraUpdateEPG = (id) => ministraUpdateEPGAction(id);

  const ministraCompareChannels = (id) => ministraCompareChannelsAction(id);

  // eslint-disable-next-line max-len
  const ministraGetCompareChannelsResult = (id, taskId) => ministraGetCompareChannelsResultAction(id, taskId);

  const ministraSyncChannels = (id) => ministraSyncChannelsAction(id);

  // eslint-disable-next-line max-len
  const ministraGetSyncChannelsResults = (id, taskId) => ministraGetSyncChannelsResultAction(id, taskId);

  const value = {
    ministra,
    ministraLength,
    filters,
    setFilters,
    getAllMinistra,
    getMinistra,
    createMinistra,
    updateMinistra,
    deleteMinistra,
    getServers,
    servers,
    ministraUpdateEPG,
    ministraSyncDataStub,
    ministraCompareChannels,
    ministraGetCompareChannelsResult,
    ministraSyncChannels,
    ministraGetSyncChannelsResults,
    selectedMinistra,
    setSelectedMinistra,
  };

  return (
    <MinistraContext.Provider value={value}>
      {children}
    </MinistraContext.Provider>
  );
};

export const useMinistraContext = () => useContext(MinistraContext);
