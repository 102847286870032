import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import VirtualList from 'react-tiny-virtual-list';

import styled from 'styled-components';
import {
  primary,
  secondary,
  text01,
  text02,
  white,
  borderSecondary,
} from '@/common/constants/theme';

import LoaderSized from '@/common/components/LoaderSized';
import SearchIcon from '../../../icons/SearchIcon';
import ProgressBar from './ProgressBar';
import { getProgress, getDuration, getCurrentTime } from '../utils';

import { usePersonalPageContext } from '../../../../PersonalPageContext';
import { usePlayerContext } from '../PlayerContext';

const itemHeight = 106;

const ChannelsList = () => {
  const [localChannels, setLocalChannels] = useState([]);

  const { t } = useTranslation('translations');

  const { channelsLogos } = usePersonalPageContext();
  const {
    channelSearch,
    channels,
    activeChannel,
    selectChannel,
    isChannelBusy,
    fullCurrentEPG,
    handleSearchChannels,
  } = usePlayerContext();

  const getLogoSrcValue = (channel) => (channel?.url ? (channelsLogos?.[channel.url] || channel.url) : '');

  const filterChannels = (str = '') => {
    if (!channels || !fullCurrentEPG) { return; }
    const useChannels = (channels || []).map((c) => ({
      ...c,
      epgData: fullCurrentEPG?.[parseInt(c.tvg_logo, 10)] || null,
    }));
    const filteredChannels = ((str || '').length > 0)
      ? useChannels.filter((c) => c.name.toLowerCase().includes(str.trim().toLowerCase()))
      : useChannels;
    setLocalChannels((filteredChannels || []));
  };

  const renderChannel = (channel, style) => (
    <ChanelCardVirtualItemWrapper
      style={style}
      key={channel.id}
    >
      <ChanelCard
        onClick={() => selectChannel(channel.id)}
      >
        {isChannelBusy && (activeChannel?.id === channel.id) && <LoaderSized />}
        <ArchiveLabel>{(channel?.archive || null) && t('player.archive')}</ArchiveLabel>
        <ChanelInfo>
          <Img
            style={{ backgroundImage: `url(${getLogoSrcValue(channel)})` }}
            bgImage
            isActive={activeChannel?.id === channel.id}
          />
          <Info>
            <ChanelTitle isActive={activeChannel?.id === channel.id}>
              {channel.name}
            </ChanelTitle>
            {!isChannelBusy && channel?.epgData
              ? (
                <Description>
                  {(channel.epgData?.name?.length || 0) > 40
                    ? `${(channel.epgData?.name || '').slice(0, 40)}...`
                    : channel.epgData?.name || ''}
                </Description>
              ) : (
                <Description>...</Description>
              )}
          </Info>
        </ChanelInfo>
        {channel?.epgData && (
          <ProgressContainer>
            <Time>
              {t('player.timeOfDuration', [getCurrentTime(channel.epgData.time), getDuration(channel.epgData.duration)])}
            </Time>
            <ProgressBar
              progress={getProgress(channel?.epgData || {})}
              isActive={!isChannelBusy && channel?.id && (activeChannel?.id === channel?.id)}
            />
          </ProgressContainer>
        )}
      </ChanelCard>
    </ChanelCardVirtualItemWrapper>
  );

  useEffect(() => {
    filterChannels(channelSearch || '');
  }, [channels, fullCurrentEPG, channelSearch]);

  return (
    <Container>
      <SearchBlock>
        <SearchIcon />
        <StyledInput
          value={channelSearch}
          onChange={({ target }) => handleSearchChannels(target.value)}
          onKeyUp={({ target }) => handleSearchChannels(target.value)}
          placeholder={t('player.searchChanels')}
        />
      </SearchBlock>
      <List>
        <VirtualList
          width="100%"
          height={1056}
          itemSize={itemHeight}
          itemCount={localChannels.length}
          renderItem={({ index, style }) => renderChannel(localChannels[index], style)}
        />
      </List>
    </Container>
  );
};

export default ChannelsList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 2rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  height: 66rem;
  overflow-y: auto;
  margin-bottom: 5rem;
  padding: 0 .25rem;
`;

const ChanelCardVirtualItemWrapper = styled.div`
`;

const ChanelCard = styled.div`
  position: relative;
  background: ${white};
  border: 1px solid ${borderSecondary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: .25rem 0;
  cursor: pointer;
  min-height: 6rem;

  :hover {
    box-shadow: 0 0 8px -2px #5c70cf4d;;
  }
`;

const ArchiveLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: .75rem 1.5rem 0;
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  height: 13px;
  color: ${primary};
`;

const ChanelInfo = styled.div`
  display: flex;
  margin: 0 .75rem;
`;

const Img = styled.div`
  width: 52px;
  height: auto;
  max-height: 3rem;
  background: transparent;
  ${({ bgImage }) => (bgImage ? `
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  ` : '')}
  margin-right: .5rem;
  margin-top: 2px;
`;

const Info = styled.div`
  width: calc(100% - 52px - 0.5rem);
`;

const ChanelTitle = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${({ isActive }) => (isActive ? secondary : text01)};
`;

const Description = styled.div`
  font-family: Gilroy, sans-serif;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: .16px;
  color: ${text02};
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Time = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: .16px;
  color: ${text01};
  padding: 0 1.5rem;
`;

const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-left: 1rem;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  font-family: Gilroy, sans-serif;
  font-size: 15px;
  line-height: 24px;
  margin-left: .75rem;
  z-index: 10;
`;
