import React from 'react';
import styled from 'styled-components';

const qrCodeIvin = require('../../../../../../assets/images/@Ivin85.png');
const qrCodeSanta = require('../../../../../../assets/images/@Santa171.png');

const PayPalPaymentSystem = () => (
  <PayPal>
    <PayPalHeader>
      <Text>
        Не смогли подобрать нужный вам вариант оплаты?
        Попробуйте обратиться по указанным ниже контактам.
      </Text>
      <TextBold>
        Обращаем ваше внимание на то, что прием осуществляется в ручном режиме.
        Бонусы начисляются на усмотрение того, кто принимает платеж.
      </TextBold>
    </PayPalHeader>
    <PayPalItem>
      <PayPalBox>
        <Text>
          PayPal, WMZ, Yoo money, Qiwi, RU карты и др.
        </Text>
        <PayPalGroup>
          <PayPalRow>
            <Text>Telegram:</Text>
            <TextBold> @santa171</TextBold>
          </PayPalRow>
          <PayPalRow>
            <Text>E-mail:</Text>
            <TextBold> 171santa@gmail.com</TextBold>
          </PayPalRow>
          <PayPalRow>
            <Text>Skype:</Text>
            <TextBold> 171santa@gmail.com</TextBold>
          </PayPalRow>
        </PayPalGroup>
      </PayPalBox>
      <PayPalImage src={qrCodeSanta} alt="qr-code" />
    </PayPalItem>
    <PayPalItem>
      <PayPalBox>
        <Text>
          PayPal, Skrill, Paysafecard, Revolut, EU карты (в том числе Беларусь) и др.
        </Text>
        <PayPalGroup>
          <PayPalRow>
            <Text>Telegram:</Text>
            <TextBold> @Ivin85</TextBold>
          </PayPalRow>
          <PayPalRow>
            <Text>Snapchat:</Text>
            <TextBold> @Ivin85</TextBold>
          </PayPalRow>
          <PayPalRow>
            <Text>ICQ:</Text>
            <TextBold> @Ivin85</TextBold>
          </PayPalRow>
          <PayPalRow>
            <Text>E-mail:</Text>
            <TextBold>
              eugenieugeni82@gmail.com
            </TextBold>
          </PayPalRow>
          <PayPalRow>
            <Text>Skype:</Text>
            <TextBold> Paypal-Tv</TextBold>
          </PayPalRow>
        </PayPalGroup>
      </PayPalBox>
      <PayPalImage src={qrCodeIvin} alt="qr-code" />
    </PayPalItem>
  </PayPal>
);

const Text = styled.p`
  color: #000;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

const TextBold = styled(Text)`
    font-weight: bold;
    margin-left: 0.2rem;
`;

const PayPal = styled.div`
`;

const PayPalHeader = styled.div`
  padding-left: 2.063rem;
  padding-top: 1rem;
  & p {
    margin: 0;
  }
`;

const PayPalItem = styled.div`
  display: flex;
  align-items: flex-start;
  border-top: 1px solid #D9D9D9;
  margin-top: 1rem;
  padding-top: 1rem;
  margin-left: 2.063rem;
`;

const PayPalGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const PayPalBox = styled.div`
  flex-grow: 1;
`;

const PayPalRow = styled.div`
  display: inline-flex;
  align-items: center;
`;

const PayPalImage = styled.img`
  width: 69px;
  height: 69px;
`;

export default PayPalPaymentSystem;
