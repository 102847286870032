import React from 'react';

import { UsersGroupsContextProvider } from './GroupsContext';
import UsersGroups from './Groups';

const UsersGroupsContainer = () => (
  <UsersGroupsContextProvider>
    <UsersGroups />
  </UsersGroupsContextProvider>
);

export default UsersGroupsContainer;
