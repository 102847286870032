import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SortableContainer } from 'react-sortable-hoc';

import {
  text01,
  text02,
  border,
  white,
} from '@/common/constants/theme';
import EditIcon from '@/common/components/icons/EditIcon';
import CloseIcon from '@/common/components/icons/CloseIcon';
import AdminTableBodyItem from './TableBodyItem';
import DisketteIcon from '../../../../icons/DisketteIcon';
import DeleteIcon from '../../../../icons/DeleteIcon';

const AdminTableBody = SortableContainer(({
  children,
  cellSizes,
  onDoubleClick,
  page,
  pageSize,
  editedId,
  setEditedId,
  setDeletedId,
  handleSave,
  editable = true,
  height = '700px',
  createTab,
  disableDragAndDrop,
}) => {
  const [positionEditedItem, setPositionEditedItem] = useState(null);
  const getPosition = (index) => ((page - 1) * (pageSize || 50) + index + 1);

  useEffect(() => {
    if (editedId >= 0) {
      setPositionEditedItem(getPosition(editedId));
    } else {
      setPositionEditedItem(null);
    }
  }, [editedId]);

  const numberRegExp = /[0-9]/g;

  const handlePosition = (event) => {
    const inputMatchedValue = event.target.value.match(numberRegExp);
    if (inputMatchedValue) {
      setPositionEditedItem(Number(inputMatchedValue.join('')));
    } else {
      setPositionEditedItem(null);
    }
  };

  return (
    <Container>
      <MainWrapper height={height}>
        <ClonedBodyItem className="table-cloned-body-item" />
        <Inner grabbing={children.length > 0 && !disableDragAndDrop}>
          {createTab && createTab()}
          {children.length > 0 ? React.Children.map(children, (item, itemIndex) => (
            <AdminTableBodyItem
              key={item.id}
              index={itemIndex}
              onDoubleClick={onDoubleClick}
            >
              <Wrapper width="80px">
                {editedId === itemIndex ? (
                  <Input
                    type="number"
                    value={positionEditedItem >= 0 ? positionEditedItem : ''}
                    onChange={handlePosition}
                  />
                ) : getPosition(itemIndex)}
              </Wrapper>
              {React.Children.map(item.props.children, (column, columnIndex) => (
                <Wrapper width={cellSizes && cellSizes[columnIndex]}>
                  {column}
                </Wrapper>
              ))}
              {editable && (
                <Wrapper width="50px">
                  {editedId === itemIndex ? (
                    <>
                      <Icon
                        top
                        onClick={() => {
                          handleSave(positionEditedItem);
                          setEditedId(-1);
                        }}
                      >
                        <DisketteIcon />
                      </Icon>
                      <Icon onClick={() => setEditedId(-1)}>
                        <CloseIcon />
                      </Icon>
                    </>
                  ) : (
                    <>
                      <Icon onClick={() => setEditedId((prevState) => (
                        prevState === itemIndex ? -1 : itemIndex
                      ))}
                      >
                        <EditIcon />
                      </Icon>
                      <Icon onClick={() => setDeletedId(itemIndex)}>
                        <DeleteIcon />
                      </Icon>
                    </>
                  )}
                </Wrapper>
              )}
            </AdminTableBodyItem>
          )) : <NoData>Не найдено</NoData>}
        </Inner>
      </MainWrapper>
    </Container>
  );
});

export default AdminTableBody;

const Container = styled.div`
  flex-grow: 1;

  margin-top: -5px;
  padding-top: 10px;

  overflow: hidden;
`;

const MainWrapper = styled.div`
  height: ${({ height }) => height || '700px'};

  overflow-y: auto;
`;

const Inner = styled.div`
  ${({ grabbing }) => grabbing && 'cursor: grabbing;'}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${({ width }) => width && (`width: ${width};`)};

  user-select: none;

  font-family: Gilroy, sans-serif;
  font-size: 13px;
  color: ${text01};
  line-height: 20px;
  letter-spacing: 0.16px;

  &:not(:first-child) {
    margin-left: 30px;
  }

  @media (max-width: 1360px) {
    ${({ width, adaptive }) => width && (`width: calc(${width} - ${adaptive || 10}px);`)};

    font-size: 11px;
  }

  @media (max-width: 1198px) {
    ${({ width }) => width && (`width: ${width};`)};

    font-size: 13px;
  }
`;

const NoData = styled.div`
  margin-top: 100px;
  margin-bottom: 10px;

  font-family: Gilroy, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${text02};
  text-align: center;
  line-height: 15px;
  letter-spacing: 0.16px;
`;

const Icon = styled.div`
  position: relative;
  ${({ top }) => top && ('top: 2px;')}

  cursor: pointer;

  &:first-child {
    margin-right: 15px;
    margin-left: 0;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 25px;
  padding: 0 10px;

  background: ${white};
  border: 1px solid ${border};
  border-radius: 2px;
  outline: none;
`;

const ClonedBodyItem = styled.div`
  & > div {
    min-width: auto;

    z-index: 100;
  }
`;
