import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import PersonalPageContainer from '../screens/PersonalPageScreen/PersonalPageContainer';
import AuthorizationContainer from '../screens/Authorization/AuthorizationContainer';
import Home from '../screens/PublicScreens/HomePage/Home';
import FAQ from '../screens/PublicScreens/FAQPage/FAQ';
import NewsContainer from '../screens/PublicScreens/NewsPage/NewsContainer';
import PaymentContainer from '../screens/PersonalPageScreen/PaymentPage/PaymentContainer';
import CopyrightHolders from '../screens/PublicScreens/CopyrightHoldersPage/CopyrightHolders';

const Routes = ({ isUserExist, isUserAuth }) => (
  <Switch>
    <Route path="/" exact render={() => <Home isUserExist={isUserExist} />} />
    <Route path="/copyrightHolders" exact component={CopyrightHolders} />
    {isUserAuth && (
      <Route path="/news" component={NewsContainer} />
    )}
    <Route path="/faq" exact component={FAQ} />
    <Route path="/confirm/" component={AuthorizationContainer} />
    {isUserAuth && (
      <Route path="/payment/" component={PaymentContainer} />
    )}
    <Route path="/unsubscribe/" component={AuthorizationContainer} />
    {isUserExist ? (
      <Route path="/personal-page" component={PersonalPageContainer} />
    ) : (
      <Route path="/" component={AuthorizationContainer} />
    )}
    <Redirect to="/" />
  </Switch>
);

export default Routes;
