import React from 'react';
import styled from 'styled-components';
import {
  text01,
  secondary,
} from '@/common/constants/theme';

const TitleWithOrange = ({
  textBlack1,
  textOrange,
  textBlack2,
}) => (
  <Title>
    {textBlack1 && <TitleText>{textBlack1}</TitleText>}
    {textOrange && <TitleTextOrange>{textOrange}</TitleTextOrange>}
    {textBlack2 && <TitleText>{textBlack2}</TitleText>}
  </Title>
);

export default TitleWithOrange;

const Title = styled.div`
  margin-top: 10rem;
  width: 40rem;
`;

const TitleText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;

  &:not(:last-of-type) {
    margin-right: 0.25em;
  }
`;

const TitleTextOrange = styled(TitleText)`
  color: ${secondary}
`;
