/* eslint-disable no-shadow, no-console */
import React, {
  createContext,
  useContext, useState, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { sendSpeedStats } from '@/services/request.service';
import {
  validatePasswordAction,
  bindDeviceToServerAction, unBindDeviceToServerAction,
  checkAllDevicesAction,
  checkDeviceByIdAction,
} from '@/services/actions/server.actions';
import { startDeviceViewTypeChangeAction, statusDeviceViewTypeChangeAction } from '@/services/actions/devices.actions';

import {
  setRightDevice,
} from '@/store/user/selectors';

import { useAppContext } from '@/AppContext';
import { usePersonalPageContext } from '../../../PersonalPageContext';
import { Speedtest as SpeedtestService } from './Speedtest';

export const ServerContext = createContext({});

export const SHOW_VIEW_TYPE = false;

const TEST_SERVER_TIMEOUT = 1000 * 5;

const initialFilters = {
  page: 1,
  limit: localStorage.getItem('limit') || 10,
};

const emptyMessage = { type: '', text: '' };

export const ServerContextProvider = ({ children }) => {
  const { userInfo } = useAppContext();
  const {
    serversFullData,
    selectedDevices,
    updateDeviceParameters,
  } = usePersonalPageContext();

  const dispatch = useDispatch();

  const [servers, setServers] = useState([]);
  const [displayedServers, setDisplayedServers] = useState([]);
  const [autoSelectServerId, setAutoSelectServerId] = useState(0);
  const [selectedDeviceTypeValue, setSelectedDeviceTypeValue] = useState('');
  const [selectedServer, setSelectedServer] = useState(null);
  const [stalkerServers, setStalkerServers] = useState([]);
  const [serversFilters, setServersFilters] = useState(initialFilters);
  const [selectedDevicesServers, setSelectedDevicesServers] = useState({});

  const [isPortalSettingsInvalid, setIsPortalSettingsInvalid] = useState(false);
  const [isAutoServer, setIsAutoServer] = useState(false);
  const [needSetServer, setNeedSetServer] = useState(null);
  const [isDeviceTypeBusy, setIsDeviceTypeBusy] = useState(false);
  const [isServersEqual, setIsServersEqual] = useState(false);
  const [isFinalModal, setIsFinalModal] = useState(false);
  const [message, setMessage] = useState(emptyMessage);

  // Объект текущего спидтеста
  const [Speedtest, setSpeedtest] = useState(null);
  // список серверов на тестирование
  const [testServersQueue, setTestServersQueue] = useState([]);
  // протестированные сервера
  const [testedServers, setTestedServers] = useState({});
  // тестируемый сервер
  const [testingServer, setTestingServer] = useState(null);
  // нужно ли запросить стат. данные о скорости серверов
  const [isNeededSpeedStats, setIsNeededSpeedStats] = useState(false);
  // стат данные о скорости серверов
  const [speedStatsData, setSpeedStatsData] = useState(null);
  // тестируются ли все сервера
  const [isAllServerTest, setIsAllServerTest] = useState(false);
  // произошла ли ошибка в текущем тестировании
  const [isCurrentTestError, setIsCurrentTestError] = useState(false);
  // общая ошибка тестирования
  const [isGeneralTestError, setIsGeneralTestError] = useState(false);
  // текущий объект ответа спидтеста (какого-то лешего первый цикл обнуляет объект testedServers)
  const [currentTestData, setCurrentTestData] = useState(null);
  // прогресс теста всех серверов
  const [testProgress, setTestProgress] = useState(null);
  // прогресс теста текущего сервера
  const [currentServerTestProgress, setCurrentServerTestProgress] = useState(null);

  const clearTestingErrors = () => {
    setIsGeneralTestError(false);
    setIsCurrentTestError(false);
  };

  const clearMessage = () => { setMessage(emptyMessage); };

  const { t } = useTranslation('translations');

  /* speedTest section --> */
  const testTimer = useRef(null);

  const clearTimer = () => {
    if (testTimer.current) {
      clearTimeout(testTimer.current);
    }
  };

  // определяем рекомендованный сервер из обработанных
  const detectRecommendedServer = () => {
    const state = { ...testedServers };

    let recServer = null;

    // если есть сервера со следующими параметрами
    const arr1 = Object.values(state || {})
      .filter((x) => (
        x.dlStatus >= 40
        && x.pingStatus <= 150
        && x.jitterStatus < 20
      ));

    if (arr1.length > 0) {
      // определяем server с минимальным пингом
      recServer = arr1.reduce((p, c) => (+p.pingStatus < +c.pingStatus ? p : c));
      // задаем границы отбора по пингу
      const minPing = +recServer.pingStatus - 10;
      const maxPing = +recServer.pingStatus + 10;

      // находим в этих границах сервер с макс. скоростью DL (это и будет рекомендованный)
      recServer = arr1.filter((x) => x.pingStatus >= minPing && x.pingStatus <= maxPing)
        .reduce((p, c) => (+p.dlStatus > +c.dlStatus ? p : c));
    } else {
      // если есть сервера со следующими параметрами
      //  (DL меньше, чем в предыдущей выборке)
      const arr2 = Object.values(state || {})
        .filter((x) => (
          x.dlStatus > 15
          && x.pingStatus < 150
          && x.jitterStatus < 20
        ));

      if (arr2.length > 0) {
        const f = (x) => {
          let p1 = +x.dlStatus / 40;
          p1 = p1 > 1 ? 1 : p1;
          const p2 = 1 - (+x.pingStatus / 150);
          return p1 + p2;
        };

        // рекомендован будет сервер с макс. значением (DL/40 [max = 1] + (1 - ping/150))
        recServer = arr2.reduce((p, c) => (f(p) > f(c) ? p : c));
      }
    }
    if (recServer) {
      // проставляем признак рекомендованного сервера
      Object.keys(state).forEach((id) => {
        state[id].isRecom = recServer?.id === parseInt(id, 10);
      });
    }

    setTestedServers(state);
  };

  const calcTestProgress = () => {
    const testedServersCount = Object.values(testedServers || {})
      .filter((ts) => ts.isTested).length;
    const currentServerProgress = ((currentTestData?.dlProgress || 0)
      + (currentTestData?.pingProgress || 0)) / 2;
    const v = (((testedServersCount || 0) + currentServerProgress) / displayedServers.length) * 100;

    setCurrentServerTestProgress(currentServerProgress);
    setTestProgress(v);
  };

  const speedTestOnUpdate = (data) => {
    const tS = { ...testingServer };
    clearTimer();
    const dts = data?.testState || -1;
    if (dts > 0 && dts !== 4) {
      testTimer.current = setTimeout(() => {
        setIsCurrentTestError(true);
      }, TEST_SERVER_TIMEOUT);
    }
    if (tS) {
      setCurrentTestData({ ...data, id: tS.id });
    }
  };
  const speedTestOnEnd = () => {
    setTestingServer(null);
    setIsCurrentTestError(false);
    clearTimer();
    setSpeedtest(null);
  };

  const getServerTestData = (item) => ({
    name: item.name,
    dns: item.dns,
    server: `https://${item.speedtest_dns}`,
    dlURL: item.speedtest_download.slice(1),
    ulURL: 'empty.php',
    pingURL: item.speedtest_ping.slice(1),
    getIpURL: 'getIP.php',
    id: item.id,
  });

  /**
   * Останавливаем тест
   * @param server - если передан, удаляется из очереди только этот сервер
   */
  const onTestAbort = (server = null) => {
    // останавливаем тест
    if (Speedtest) {
      Speedtest.abort();
      Speedtest.worker.terminate();
      setSpeedtest(null);
    }
    // чистим текущй тестируемый сервер
    setTestingServer(null);
    // чистим очередь
    let newQueue = [];
    if (server) {
      newQueue = [...(testServersQueue || [])];
      const index = newQueue.findIndex((tqs) => tqs.id === server.id);
      if (index > -1) {
        newQueue.splice(index, 1);
      }
    }
    setTestServersQueue(newQueue);
    clearTimer();
  };

  const handleStartStopOneServerTest = (server) => {
    if (testingServer) {
      if (server?.id === testingServer?.id) {
        onTestAbort(server);
      }
    } else {
      setTestServersQueue([getServerTestData(server)]);
    }
  };

  const handleStartStopAllServersTest = () => {
    if (!isAllServerTest) {
      // Сбрасываем флаги ошибок
      clearTestingErrors();
      // начинаем тест всех серверов
      // добавляем в очередь все сервера
      setTestServersQueue(displayedServers.map(getServerTestData));
      // устанавливаем получение данных статистики по спидтесту
      setIsNeededSpeedStats(true);
      // устанавливаем флаг "теста всех серверов"
      setIsAllServerTest(true);
      // Сбрасываем результаты предыдущего теста
      setTestedServers({});
      // Сбрасываем результаты запроса к статистике
      setSpeedStatsData(null);
      // сбрасываем прогресс
      setTestProgress(0);
    } else {
      // сбрасываем флаг "теста всех серверов"
      setIsAllServerTest(false);
      // отменяем получение данных статистики по спидтесту
      setIsNeededSpeedStats(false);
      // деинициализируем прогресс
      setTestProgress(null);

      onTestAbort();
    }
  };
  /* <-- speedTest section */

  const validatePassword = (password) => validatePasswordAction(password);

  const getDevicesWithServers = (currentServers) => {
    if (!selectedDevices.length) return [];

    if (currentServers.length || servers.length) {
      if (selectedDevices[0].server >= 0
        && selectedDevices.filter((device) => selectedDevices[0].server === device.server)
          .length === selectedDevices.length) {
        setSelectedServer(servers.find((server) => server.id === selectedDevices[0].server)
          || currentServers?.find((server) => server.id === selectedDevices[0].server));
        setIsServersEqual(true);
      } else if (selectedDevices.length === 1
        && !selectedDevices[0].server && selectedDevices[0].server !== 0) {
        setIsServersEqual(true);
      }
    }
    return selectedDevices;
  };

  const bindDeviceToServer = (selectedTableValue, showResultDialog = true) => {
    const serverId = servers.find((server) => server.dns === selectedTableValue).id;
    selectedDevices.forEach((device) => {
      if (device.server !== serverId) {
        bindDeviceToServerAction(device.id, serverId)
          .then(() => {
            updateDeviceParameters(device.id, { server: serverId });
            if (showResultDialog) {
              setIsFinalModal(true);
            }
          })
          // eslint-disable-next-line no-console
          .catch((err) => { console.log(err); });
      }
    });
  };

  const checkDeviceById = (id) => (
    checkDeviceByIdAction(id)
      .then(getDevicesWithServers)
      // eslint-disable-next-line no-console
      .catch((err) => { console.log(err); })
  );

  const checkAllDevices = () => (
    checkAllDevicesAction({ condition: false })
      .then(getDevicesWithServers)
      // eslint-disable-next-line no-console
      .catch((err) => { console.log(err); })
  );

  const changeDeviceType = (device, value, force = false, skipBusyCheck = false) => {
    if ((isDeviceTypeBusy && !skipBusyCheck)
      || !device || !value
      || (force || (device.view_type === value))
    ) {
      return;
    }

    setIsDeviceTypeBusy(true);

    const deviceTypeCheckPause = 1000 * 2;
    let setDeviceTypeCheckInterval = null;
    const {
      id,
      ministra_server: ministraServer,
      ministra_login: ministraLogin,
      ministra_password: ministraPassword,
      view_type: prevViewType,
    } = device;

    const currentDevice = selectedDevices.find((d) => d.id === id);

    setIsPortalSettingsInvalid(false);

    startDeviceViewTypeChangeAction({
      id,
      view_type: value,
      ministra_server: ministraServer,
      ministra_login: ministraLogin,
      ministra_password: ministraPassword,
    })
      .then((res) => {
        const status = res?.status;
        const taskId = res?.task_id;
        if (status === 'processed' && taskId) {
          setDeviceTypeCheckInterval = setInterval(() => (
            statusDeviceViewTypeChangeAction(id, taskId)
              .then((statusRes) => {
                const resStatus = statusRes?.status;
                if (resStatus === 'processed') {
                  // eslint-disable-next-line no-console
                  console.log(`device type check status [${resStatus}]`);
                } else {
                  // eslint-disable-next-line no-console
                  console.log(`device type check status [${resStatus}]`);
                  if (resStatus === 'success') {
                    currentDevice.view_type = value;
                    currentDevice.ministra_server = ministraServer;
                    currentDevice.ministra_login = ministraLogin;
                    currentDevice.ministra_password = ministraPassword;

                    const updData = {
                      view_type: value,
                      ministra_server: ministraServer,
                      ministra_login: ministraLogin,
                      ministra_password: ministraPassword,
                    };
                    updateDeviceParameters(currentDevice?.id, updData);
                    setSelectedDeviceTypeValue(value);
                  } else {
                    const errorsNeedsMinistraSet = [
                      'user_login_is_already_used',
                      'device_portal_params_empty',
                    ];
                    if (errorsNeedsMinistraSet.includes(statusRes.exceptions_type)) {
                      setIsPortalSettingsInvalid(true);
                    }
                    currentDevice.view_type = prevViewType;
                    setSelectedDeviceTypeValue(prevViewType);
                    setMessage({ type: 'error', text: `${t('steps.step3.deviceType.errors.change')}:\n${t(`steps.step3.deviceType.errors.${statusRes?.exceptions_type}`)}` });
                  }
                  setIsDeviceTypeBusy(false);
                  if (setDeviceTypeCheckInterval) { clearInterval(setDeviceTypeCheckInterval); }
                }
              })
              .catch((statusErr) => {
                setMessage({ type: 'error', text: `${t('steps.step3.deviceType.errors.change')}:\n ${t(`steps.step3.deviceType.errors.${statusErr?.response?.payload?.message_type || statusErr?.data?.message_type}`)}` });
                currentDevice.view_type = prevViewType;
                setSelectedDeviceTypeValue(prevViewType);
                setIsDeviceTypeBusy(false);
                if (setDeviceTypeCheckInterval) { clearInterval(setDeviceTypeCheckInterval); }
              })),
          deviceTypeCheckPause);
        } else {
          setMessage({ type: 'error', text: `${t('steps.step3.deviceType.errors.change')}: ${t(`steps.step3.deviceType.errors.${res?.message_type}`)}` });
          setSelectedDeviceTypeValue(prevViewType);
          setIsDeviceTypeBusy(false);
        }
      })
      .catch((err) => {
        setMessage({ type: 'error', text: `${t('steps.step3.deviceType.errors.change')}:\n${t(`steps.step3.deviceType.errors.${err?.response?.payload?.message_type || err?.data?.message_type}`)}` });
        currentDevice.view_type = prevViewType;
        setSelectedDeviceTypeValue(prevViewType);
        setIsDeviceTypeBusy(false);
      });
  };

  const handleSetAutoServer = (isEnabled) => {
    clearTestingErrors();

    if ((autoSelectServerId === 0) || ((selectedDevices || []).length === 0)) {
      return;
    }
    const vServer = servers.find((s) => s.id === autoSelectServerId);
    if (!vServer) {
      return;
    }
    if (isEnabled) {
      const sdServers = {};
      // store selected devices original servers
      (selectedDevices || []).forEach((sd) => {
        sdServers[sd.id] = sd.server;
      });
      setSelectedDevicesServers(sdServers);
      bindDeviceToServer(vServer.dns, false);
    } else {
      (selectedDevices || []).forEach((sd) => {
        if (selectedDevicesServers?.[sd.id]) {
          bindDeviceToServerAction(sd.id, selectedDevicesServers[sd.id])
            .then(() => {
              updateDeviceParameters(sd.id, { server: selectedDevicesServers[sd.id] });
            })
            // eslint-disable-next-line no-console
            .catch((err) => { console.log(err); });
          setSelectedDevicesServers({});
        } else {
          unBindDeviceToServerAction(sd.id)
            .then(() => {
              updateDeviceParameters(sd.id, { server: null });
            })
            // eslint-disable-next-line no-console
            .catch((err) => { console.log(err); });
        }
      });
    }
  };

  useEffect(() => {
    if ((selectedDevices || []).length > 0) {
      const useDeviceType = selectedDevices.length === 1
        ? selectedDevices[0].view_type
        : 'playlist';
      setSelectedDeviceTypeValue(useDeviceType);
      dispatch(setRightDevice(selectedDevices[0]));
    }
  }, [selectedDevices]);

  useEffect(() => {
    // если нужно получить данные статистики и очередь пуста и сервер не тестируется
    // получаем статстику, обновляем рекомендации
    if (
      isNeededSpeedStats
      && ((testServersQueue || []).length === 0)
      && !testingServer
    ) {
      const tmpFn = async () => {
        const sssData = Object.entries(testedServers)
          .map(([id, v]) => ({ ...v, server: servers.find((s) => s.id === parseInt(id, 10)).dns }));
        const detectStatResult = await sendSpeedStats(userInfo, sssData);
        const detectedBestDns = detectStatResult?.Best || null;
        setSpeedStatsData(detectStatResult?.Response2User || null);

        const recommendedServerId = Object.values(testedServers).find((ts) => ts.isRecom)?.id || 0;
        const recommendedDns = servers.find((ts) => ts.id === recommendedServerId)?.dns || 'no-server';
        const useDns = detectedBestDns || recommendedDns;
        const useServer = servers.find((s) => s.dns === useDns) || null;

        if (useServer && (selectedServer?.id !== useServer?.id)) {
          setNeedSetServer(useServer);
        }

        setTestedServers(
          (prevState) => {
            const newState = { ...prevState };
            Object.keys(newState).forEach((id) => {
              newState[id].isRecom = parseInt(id, 10) === (useServer?.id || 0);
            });
            return newState;
          },
        );

        setIsAllServerTest(false);
      };
      if (Object.values(testedServers || []).length > 0) { tmpFn(); }
    }
  }, [testServersQueue.length, testingServer, isNeededSpeedStats]);

  useEffect(() => {
    // если очередь на тест заполнена и сервер не тестируется
    if (!testingServer && ((testServersQueue || []).length > 0)) {
      const tS = { ...testServersQueue[0] };
      setTestingServer(tS);
      setTestServersQueue((prevState) => prevState.slice(1));
    }
    // рассчитываем recommended server
    detectRecommendedServer();
  }, [testServersQueue, testingServer]);

  useEffect(() => {
    // если тестируются все сервера и тест окончен (пуста очередь не тестируется сервер)
    // снимаем флаг теста всех серверов
    if (
      isAllServerTest
      && !testingServer
      && ((testServersQueue || []).length === 0)
      && (Object.values(testedServers || {}).length > 0)
      && (Object.values(testedServers || {}).filter((s) => !s.isTested).length === 0)
    ) {
      setIsAllServerTest(false);
      setIsGeneralTestError(((testedServers || []).length === 0)
        || (Object.values(testedServers || {}).filter((ts) => ts.isRecom).length === 0));
    }
  }, [testServersQueue.length, testingServer, testedServers, isAllServerTest]);

  useEffect(() => {
    if (testingServer) {
      const spt = new SpeedtestService();
      spt.addTestPoint(testingServer);
      spt.setSelectedServer(testingServer);
      spt.onupdate = speedTestOnUpdate;
      spt.onend = speedTestOnEnd;
      spt.start();
      setSpeedtest(spt);
    }
  }, [testingServer]);

  useEffect(() => {
    if (currentTestData) {
      const { id, ...data } = currentTestData;
      const isTested = currentTestData?.testState === 4;
      setTestedServers(
        (prevState) => {
          const d = { ...prevState || {} };
          d[id] = { ...data, id, isTested };
          return d;
        },
      );
    }
    calcTestProgress();
  }, [currentTestData]);

  useEffect(() => {
    if (isCurrentTestError) {
      if (Speedtest) {
        Speedtest.abort();
        Speedtest.worker.terminate();
        setSpeedtest(null);
      }
      setTestingServer(null);
      setIsCurrentTestError(false);
      clearTimer();
    }
  }, [isCurrentTestError]);

  useEffect(() => {
    if (!needSetServer || ((selectedDevices || []).length === 0)) {
      return;
    }
    bindDeviceToServer(needSetServer.dns, false);
    setNeedSetServer(null);
  }, [servers, needSetServer]);

  useEffect(() => {
    if (((selectedDevices || []).length === 0)
      || ((servers || []).length === 0)) {
      return;
    }
    const virtServersIds = servers.filter((s) => s.is_virtual).map((s) => s.id);
    const isAllServersAreVirtual = selectedDevices
      .filter((sd) => virtServersIds.includes(sd.server));
    setIsAutoServer(isAllServersAreVirtual.length === selectedDevices.length);
  }, [selectedDevices, servers]);

  useEffect(() => {
    const useServers = (serversFullData || []);
    if (useServers.length > 0) {
      setServers([...useServers]);

      const dServers = [...useServers].filter((s) => !s.is_virtual);
      setDisplayedServers(dServers);
      setStalkerServers([...dServers]);

      setAutoSelectServerId(useServers.find((s) => s.is_virtual).id || 0);
    }
  }, [serversFullData]);

  useEffect(() => () => {
    clearTimer();
  }, []);

  const value = {
    servers,
    displayedServers,
    selectedDeviceTypeValue,
    setSelectedDeviceTypeValue,
    selectedServer,
    setSelectedServer,
    isDeviceTypeBusy,
    setIsDeviceTypeBusy,
    testedServers,
    stalkerServers,
    serversFilters,
    setServersFilters,
    isFinalModal,
    setIsFinalModal,
    isServersEqual,

    changeDeviceType,
    getDevices: getDevicesWithServers,
    validatePassword,
    onTestAbort,
    bindDeviceToServer,
    checkDeviceById,
    checkAllDevices,
    message,
    clearMessage,
    isPortalSettingsInvalid,
    isAutoServer,
    handleSetAutoServer,
    speedStatsData,
    setSpeedStatsData,
    handleStartStopOneServerTest,
    handleStartStopAllServersTest,
    testingServer,
    isAllServerTest,
    currentServerTestProgress,
    testProgress,
    isGeneralTestError,
  };

  return (
    <ServerContext.Provider value={value}>
      {children}
    </ServerContext.Provider>
  );
};

export const useServerContext = () => useContext(ServerContext);
