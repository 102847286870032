import React from 'react';
import styled from 'styled-components';

import {
  secondary,
  white,
  borderSecondary,
} from '@/common/constants/theme';
import ArrowLeftDouble from '@/common/components/icons/ArrowLeftDouble';
import ArrowRightDouble from '@/common/components/icons/ArrowRightDouble';
import { useNewsContext } from '../NewsContext';

const NewsPagination = () => {
  const {
    newsPage,
    setNewsPage,
    getNewsPageCount,
  } = useNewsContext();
  const pagesCount = getNewsPageCount();
  const page = newsPage;

  const getFormattedPages = () => {
    if (pagesCount > 7) {
      if (page === 1 || page === 2) {
        return [1, 2, 3, '...', pagesCount];
      }
      if (page === 3) {
        return [1, 2, 3, 4, '...', pagesCount];
      }
      if (page === pagesCount || page === pagesCount - 1) {
        return [1, '...', pagesCount - 2, pagesCount - 1, pagesCount];
      }
      if (page === pagesCount - 2) {
        return [1, '...', pagesCount - 3, pagesCount - 2, pagesCount - 1, pagesCount];
      }
      return [1, '...', page - 1, page, page + 1, '...', pagesCount];
    }
    return Array.from({ length: pagesCount }, (_, i) => i + 1);
  };

  if (pagesCount === 0) {
    return '';
  }

  const pageKeyBase = 'PubP-N-PN-P-';
  return (
    <Container>
      <Wrapper flex flexSb alignCenter>
        <Icon
          onClick={() => setNewsPage(1)}
          active={page > 1}
        >
          <ArrowLeftDouble />
        </Icon>
        <Wrapper flex>
          {getFormattedPages().map((item) => (
            <Button
              key={`${pageKeyBase}-${item}`}
              active={item === page}
              onClick={() => item !== '...' && setNewsPage(item)}
              withoutBorder={item === '...'}
            >
              {item}
            </Button>
          ))}
        </Wrapper>
        <Icon
          onClick={() => setNewsPage(pagesCount)}
          active={page < pagesCount}
        >
          <ArrowRightDouble />
        </Icon>
      </Wrapper>
    </Container>
  );
};

export default NewsPagination;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  ${({ flex }) => flex && ('display: flex;')}
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}
  ${({ alignCenter }) => alignCenter && ('align-items: center;')}
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  background-color: ${white};
  border: 1px solid ${borderSecondary};
  border-radius: 4px;
  cursor: pointer;

  transition: background-color 0.2s ease, color 0.2s ease;

  ${({ active }) => active && (`
    background-color: ${secondary};
    border: none;

    color: ${white};
  `)}

  ${({ withoutBorder }) => withoutBorder && ('border: none;')}

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:first-child {
    margin-left: 30px;
  }

  &:last-child {
    margin-right: 30px;
  }
`;

const Icon = styled.div`
  cursor: pointer;

  & svg path {
    transition: 0.2s ease;
  }

  ${({ active }) => (active ? (`
    & svg path {
      fill: ${secondary};
    }
  `) : (`
    & svg path {
      fill: #949494;
    }
    opacity: 0.5;
  `))}
`;
