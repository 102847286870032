import React from 'react';

export default () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#giftIconClip0)">
      <path d="M1.03112 8.00562V13.1919C1.03112 13.669 1.41922 14.0562 1.8955 14.0562H6.21737V8.00562H1.03112Z" fill="#FF9800" />
      <path d="M13.1324 4.54816H10.3975C10.5937 4.41418 10.7623 4.28107 10.8798 4.16179C11.5774 3.46078 11.5774 2.3198 10.8798 1.61879C10.2021 0.935938 9.02141 0.937666 8.3446 1.61879C7.96946 1.9948 6.97543 3.52561 7.11287 4.54816H7.05063C7.1872 3.52561 6.19317 1.9948 5.8189 1.61879C5.14123 0.937666 3.96049 0.937666 3.28368 1.61879C2.587 2.3198 2.587 3.46078 3.28282 4.16179C3.40124 4.28107 3.56979 4.41418 3.76601 4.54816H1.03112C0.554852 4.54816 0.166748 4.93627 0.166748 5.41254V6.7091C0.166748 6.94767 0.360368 7.14129 0.598936 7.14129H6.21737V5.41254H7.94612V7.14129H13.5646C13.8031 7.14129 13.9967 6.94767 13.9967 6.7091V5.41254C13.9967 4.93627 13.6095 4.54816 13.1324 4.54816ZM6.16637 4.51964C6.16637 4.51964 6.13007 4.54816 6.00647 4.54816C5.40918 4.54816 4.26907 3.92754 3.89653 3.5524C3.53435 3.18764 3.53435 2.59295 3.89653 2.22818C4.07199 2.05185 4.30451 1.95504 4.55172 1.95504C4.79807 1.95504 5.03059 2.05185 5.20605 2.22818C5.78864 2.81423 6.36777 4.31132 6.16637 4.51964ZM8.15617 4.54816C8.03342 4.54816 7.99712 4.5205 7.99712 4.51964C7.79572 4.31132 8.37485 2.81423 8.95744 2.22818C9.30579 1.87638 9.91517 1.87465 10.267 2.22818C10.63 2.59295 10.63 3.18764 10.267 3.5524C9.89442 3.92754 8.75431 4.54816 8.15617 4.54816Z" fill="#FF9800" />
      <path d="M7.94612 8.00562V14.0562H12.268C12.7451 14.0562 13.1324 13.669 13.1324 13.1919V8.00562H7.94612Z" fill="#FF9800" />
    </g>
    <defs>
      <clipPath id="giftIconClip0">
        <rect width="13.83" height="13.83" fill="white" transform="translate(0.166748 0.666748)" />
      </clipPath>
    </defs>
  </svg>

);
