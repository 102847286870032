import React, { useState } from 'react';

import ModalNew from '@/common/components/ModalNew';
import PlusIcon from '@/assets/icons/plus.svg';
import styled from 'styled-components';
import Button from '../../../../../../../../common/components/Button';
import { darkRed, inputBorder, text01 } from '../../../../../../../../common/constants/theme';

const AdminDNSCreateModal = ({ isOpen, onClose, handleSubmit }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  return (
    <ModalNew
      isOpen={isOpen}
      onCloseModal={onClose}
      title="Создание DNS"
      icon={PlusIcon}
    >
      <Container>
        <Text>
          Введите URL
        </Text>
        <Input
          value={value}
          onChange={({ target }) => setValue(target.value)}
        />
        <ErrorText>{error}</ErrorText>
        <Wrapper>
          <StyledButton
            isBoldText
            isUpperCase
            onClick={() => {
              if (handleSubmit(value)) {
                setValue('');
                onClose();
              } else {
                setError(`"${value}" не может быть распознан как URL`);
              }
            }}
          >
            Создать
          </StyledButton>
        </Wrapper>
      </Container>
    </ModalNew>
  );
};

export default AdminDNSCreateModal;

const Container = styled.div`
  padding: 4px 8px 20px 4px;
`;

const Text = styled.div`
  margin-bottom: 26px;

  font-family: Gilroy;
  font-size: 15px;
  color: ${text01};
  line-height: 20px;
`;

const ErrorText = styled.div`
  color: ${darkRed};
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 12px;
  margin-bottom: 66px;

  background-color: transparent;
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  outline: none;
`;

const Wrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  width: auto;
  padding: 0 18px;
  margin-left: auto;
`;
