import React from 'react';

export default () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#importIconClip0)">
      <path d="M2.53681 7.18155L3.06401 7.83119C3.20961 8.01049 3.46161 8.02782 3.62801 7.87104L5.20001 6.37946V12.5597C5.20001 12.7988 5.37921 12.9928 5.60001 12.9928H6.40001C6.62081 12.9928 6.80001 12.7988 6.80001 12.5597V6.37946L8.37201 7.87104C8.53761 8.02782 8.78961 8.01049 8.93601 7.83119L9.46321 7.18242C9.60961 7.00225 9.59281 6.72767 9.42641 6.57002L6.26321 3.57215C6.11201 3.42923 5.88721 3.42923 5.73601 3.57215L2.57361 6.57002C2.40721 6.72767 2.39041 7.00225 2.53681 7.18155Z" fill="#5C70CF" />
      <path d="M1.59998 1.73236H10.4C10.6208 1.73236 10.8 1.53834 10.8 1.29927V0.433084C10.8 0.194017 10.6208 -8.58307e-06 10.4 -8.58307e-06H1.59998C1.37918 -8.58307e-06 1.19998 0.194017 1.19998 0.433084V1.29927C1.19998 1.53834 1.37918 1.73236 1.59998 1.73236Z" fill="#5C70CF" />
    </g>
    <defs>
      <clipPath id="importIconClip0">
        <rect width="12" height="12.9928" fill="white" transform="translate(12 12.9928) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);
