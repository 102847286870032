import React from 'react';
import { StatisticAggregatorsContextProvider } from './AggregatorsContext';
import StatisticAggregators from './Aggregators';

const StatisticAggregatorsContainer = () => (
  <StatisticAggregatorsContextProvider>
    <StatisticAggregators />
  </StatisticAggregatorsContextProvider>
);

export default StatisticAggregatorsContainer;
