import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  enUS as en,
  ru,
} from 'date-fns/locale';
import dayjs from 'dayjs';

import {
  text01,
  activePrimary,
  secondary,
  primary,
} from '@/common/constants/theme';

const NewsSmallCard = ({
  title,
  shortText,
  text,
  date,
  forumLink,
}) => {
  const { t, i18n } = useTranslation('translations');

  const locales = { en, ru };
  const selectedLocale = locales[i18n.language];
  const dateObj = date ? dayjs(date, 'YYYY-MM-DD', true).toDate() : null;
  const dateStr = dateObj ? format(dateObj, 'dd MMMM yyyy', { locale: selectedLocale }) : '';

  return (
    <Container>
      <Title bottomMargin={38}>{title}</Title>
      <Text
        bottomMargin={!forumLink ? 12 : 2}
        dangerouslySetInnerHTML={{ __html: (text && text.length > 0 ? text : shortText) }}
      />
      {forumLink && (
        <StyledLink>
          {t('news.forum')}
        </StyledLink>
      )}
      <Date>{dateStr}</Date>
    </Container>
  );
};

export default NewsSmallCard;

const Text = styled.div`
  ${({ bottomMargin }) => bottomMargin && (`margin-bottom: ${bottomMargin}px;`)}

  font-family: Gilroy;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.16px;
  overflow-wrap: anywhere;
  color: ${text01};
`;

const Date = styled(Text)`
  margin-top: 30px;
  font-weight: 500;
  color: ${activePrimary};

  position: relative;

  &::before {
    content: '';

    width: 30px;
    height: 4px;

    position: absolute;
    bottom: -15px;
    left: 0;

    background-color: ${secondary};
  }
`;

const Container = styled.div`
  max-width: 900px;
  min-width: 800px;
  margin-bottom: 75px;

  &:nth-child(3) ${Date}::before,
  &:nth-child(4) ${Date}::before {
    background-color: ${primary};
  }
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
`;

const StyledLink = styled(Link)`
  display: block;
  max-width: 530px;
  margin-bottom: 12px;

  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${secondary};

  &:hover {
    color: ${secondary};
  }

  &:active {
    color: ${secondary};
  }
`;
