import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  secondary,
  text01,
  white,
} from '@/common/constants/theme';
import pageSizes from '../../../../../../../common/constants/pageSizes';
import ArrowToStart from '../../../../icons/ArrowToStart';
import ArrowLeft from '../../../../icons/ArrowLeft';
import ArrowRight from '../../../../icons/ArrowRight';
import ArrowToEnd from '../../../../icons/ArrowToEnd';

const TablePagination = ({
  page,
  setPage,
  pageCount,
  pageLimit,
  setPageLimit,
  customPageSizes,
}) => {
  const { t } = useTranslation('translations');

  const getFormattedPages = () => {
    if (pageCount > 7) {
      if (page === 1 || page === 2) {
        return [1, 2, 3, '...', pageCount];
      }
      if (page === 3) {
        return [1, 2, 3, 4, '...', pageCount];
      }
      if (page === pageCount || page === pageCount - 1) {
        return [1, '...', pageCount - 2, pageCount - 1, pageCount];
      }
      if (page === pageCount - 2) {
        return [1, '...', pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
      }
      return [1, '...', page - 1, page, page + 1, '...', pageCount];
    }
    return Array.from({ length: pageCount }, (_, i) => i + 1);
  };

  const checkActive = () => pageCount > 1 && page !== pageCount;

  return (
    <>
      {pageCount >= 1 && (
        <Container>
          <Wrapper />
          {setPageLimit && ((customPageSizes || pageSizes || []).length > 0) && (
            <Wrapper>
              <Text rightMargin>
                {t('table.pagination')}
              </Text>
              {(customPageSizes || pageSizes).map((item) => (
                <TextButton
                  key={`${item}--tablePaginationSizeItem`}
                  mainBtns
                  isActive={+pageLimit === +item}
                  onClick={() => {
                    setPageLimit(item);
                    localStorage.setItem('limit', item);
                  }}
                >
                  {item}
                </TextButton>
              ))}
            </Wrapper>
          )}
          <Wrapper>
            <Button
              isActive={page !== 1}
              onClick={() => page !== 1 && setPage(1)}
            >
              <ArrowToStart />
            </Button>
            <Button
              isActive={page !== 1}
              onClick={() => page !== 1 && setPage(page - 1)}
            >
              <ArrowLeft />
            </Button>
            {getFormattedPages().map((pageItem, index) => (
              <PageNumber
                key={`${pageItem === '...' ? `${index}_${pageItem}` : pageItem}--tablePaginationItem`}
                selected={pageItem === page}
                onClick={() => pageItem !== '...' && setPage(pageItem)}
              >
                {pageItem}
              </PageNumber>
            ))}
            <Button
              isActive={pageCount > 1 && page !== pageCount}
              onClick={() => checkActive() && setPage(page + 1)}
            >
              <ArrowRight />
            </Button>
            <Button
              isActive={pageCount > 1 && page !== pageCount}
              onClick={() => checkActive() && setPage(pageCount)}
            >
              <ArrowToEnd />
            </Button>
          </Wrapper>
        </Container>
      )}
    </>
  );
};

export default TablePagination;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 47px;
  padding: 0 45px;

  position: relative;
  z-index: 2;

  background: ${white};
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 0 0 4px 4px;
`;

const Text = styled.div`
  margin-right: ${({ rightMargin }) => rightMargin && 24}px;

  font-family: Gilroy, sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  line-height: 14px;
  letter-spacing: 0.16px;

  color: ${text01};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 50px;
`;

const Button = styled(Text)`
  margin-right: 10px;

  cursor: ${({ isActive }) => isActive && ('pointer')};

  svg path {
    fill: ${({ isActive }) => (isActive ? secondary : '#bcbcbc')};

    transition: 0.2s ease-in-out;
  }
`;

const PageNumber = styled(Text)`
  margin-right: 8px;

  opacity: ${({ selected }) => (selected ? 1 : 0.4)};
  cursor: ${({ selected }) => !selected && ('pointer')};

  font-weight: 600;
  color: ${text01};

  transition: 0.2s ease-in-out;
`;

const TextButton = styled(Text)`
  margin-right: ${({ mainBtns }) => mainBtns && 15}px;

  opacity: ${({ isActive }) => (isActive ? 0.4 : 1)};
  cursor: ${({ isActive }) => !isActive && ('pointer')};

  font-weight: 600;
  color: ${({ isActive }) => (isActive ? text01 : secondary)};

  transition: 0.2s ease-in-out;
`;
