import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ServerCreateLoginModal from '../../PageContent/Server/components/ServerCreateLoginModal';
import MinistraCredentials from './PortalComponents/MinistraCredentials';
import ThemeReset from './PortalComponents/ThemeReset';
import LinkList from '@/common/components/LinkList';
import Playlists from './CommonComponents/Playlists';

import { usePersonalPageContext } from '../../../PersonalPageContext';

import {
  // getPlayLists,
  getMinistra, getServers, updateDeviceServerRedux,
  updateDevicesPlaylistRedux,
} from '@/store/user/selectors';

const DevicePortal = ({
  device,
  updateDeviceType,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isMinistraServerModalOpen, setIsMinistraServerModalOpen] = useState(false);
  const [deviceLinkList, setDeviceLinkList] = useState([]);

  const { copyText } = usePersonalPageContext();

  const { t } = useTranslation('translations');

  // const playlists = useSelector(getPlayLists);
  const playlists = [{ id: 1, title: 'Плейлист по умолчанию' }];
  const servers = useSelector(getServers);

  const linksTemplate = [
    {
      id: '1',
      title: t('devicesInfo.portal.links.auraMag'),
      urls: {
        aura_url: '',
        mag_url: '',
      },
    },
    {
      id: '2',
      title: t('devicesInfo.portal.links.auraMagEmulators'),
      urls: {
        android_url: '',
      },
    },
    {
      id: '3',
      title: t('devicesInfo.portal.links.stalkerApp'),
      urls: {
        stalker_url: '',
      },
    },
    {
      id: '4',
      title: t('devicesInfo.portal.links.sourcesOfEPG'),
      urls: {},
    },
  ];

  const getLisnkslist = (ministra) => linksTemplate.map((itm) => {
    const urlsValues = Object.keys(itm.urls).map((prop) => (ministra?.[prop] || '')).filter((url) => url.length > 0);
    return { ...itm, urls: urlsValues };
  }).filter((itm) => itm.urls.length > 0);

  const allMinistra = useSelector(getMinistra);

  useEffect(() => {
    if (((allMinistra || []).length > 0) && device) {
      const ministra = allMinistra.find((mitem) => mitem.id === device.ministra_server);
      const deviceLinks = getLisnkslist(ministra);
      setDeviceLinkList(deviceLinks);
    }
  }, [device, allMinistra]);

  return (
    <>
      <ServerCreateLoginModal
        device={device}
        isOpen={isMinistraServerModalOpen}
        onClose={() => {
          setIsMinistraServerModalOpen(false);
        }}
        onSubmit={(form) => {
          const deviceUpdated = { ...(device), ...form };
          updateDeviceType(deviceUpdated);
          setIsMinistraServerModalOpen(false);
        }}
      />
      <DeviceInfo>
        <MinistraCredentials
          device={device}
          setIsMinistraServerModalOpen={setIsMinistraServerModalOpen}
        />
        <Row>
          <LinkList linksObject={deviceLinkList} copyText={copyText} />
        </Row>
        <Row>
          <ThemeReset
            device={device}
          />
        </Row>
        <Row>
          <Playlists
            list={playlists || []}
            defaultValue={1}
            title={t('devicesInfo.playlists')}
            buttonText={t('devicesInfo.addButton')}
            withButtonIcon
            noAddButton
            buttonClicked={() => history.push('/personal-page/channels')}
            listItemSelected={(playlistId) => {
              dispatch(updateDevicesPlaylistRedux([device], playlistId));
            }}
          />
        </Row>
        <Row>
          <Playlists
            list={(servers || []).map((s) => ({ id: s.id, title: s.name })) || servers[0]}
            defaultValue={device.server}
            title={t('devicesInfo.servers')}
            buttonText={t('devicesInfo.pickUpButton')}
            buttonClicked={() => history.push('/personal-page/server')}
            listItemSelected={(serverId) => {
              dispatch(updateDeviceServerRedux(device.id, serverId));
            }}
          />
        </Row>
      </DeviceInfo>
    </>
  );
};

export default DevicePortal;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: .5rem 1rem;
  border-bottom: solid 1px #EFEEF3;
`;

const DeviceInfo = styled.div``;
