import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import styled from 'styled-components';
import {
  background01,
} from '@/common/constants/theme';

import { isEmailValid } from '@/common/utils/emailValidator.utils';

import StyledInput from '@/common/components/StyledInput';
import Button from '@/common/components/Button';
import SnackBarWindow from '@/common/components/SnackBarWindow';
import Loader from '@/common/components/Loader';
import { AdminMainPopupHeader } from '../../components/MainPopupComponents';
import TemplateHtmlEditBlock from '../../components/TemplateHtmlEditBlock';
import TemplateImagesBlock from '../../components/TemplateImagesBlock';
import Dropdown from '../../../../../../common/components/Dropdown';

import { BASE_URL } from '../constants';

import { SnackTypes, useAppContext } from '@/AppContext';
import { COMMON_TEMPLATE_VARIABLES, useAdminContext } from '../../../../AdminContext';

import {
  TEMPLATE_TYPES, DATA_TYPES,
  useTemplatesEditContext,
} from './TemplatesEditContext';

const TemplatesEdit = () => {
  const { userInfo } = useAppContext();
  const { setAdminSnack } = useAdminContext();
  const {
    languages,
    templateDecors,
    getTemplate,
    template,
    templateParameters,
    updateTemplate,
    updateTemplatesContent,
    validateTemplateParameters,
    sendTestEmail,
    uploadImage,
    deleteImage,
  } = useTemplatesEditContext();

  const [loaders, setLoaders] = useState({});
  const [content, setContent] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [selectedDecor, setSelectedDecor] = useState(null);
  const [validateErrors, setValidateErrors] = useState([]);
  const [testEmail, setTestEmail] = useState(userInfo.email);
  const [isTestEmailSending, setIsTestEmailSending] = useState(false);
  const [testEmailSendResult, setTestEmailSendResult] = useState(null);

  const [fields] = useState({
    html_content: 'HTML содержимое',
  });

  const htmlInputRef = useRef(null);

  const dataBlockRef = useRef(null);

  const history = useHistory();
  const { id } = useParams();

  const decorToOption = (decor) => ({
    key: decor.id,
    title: decor.name,
  });

  useEffect(() => {
    if (id !== 'new') {
      getTemplate(id);
    }
  }, [id]);

  useEffect(() => {
    if (languages) {
      const defLang = languages.find((x) => x.isDefault);
      setSelectedLanguage(defLang);
      setDefaultLanguage(defLang);
    }
  }, [languages]);

  useEffect(() => {
    if (!templateDecors || !selectedLanguage || !template) {
      return;
    }
    const selectContent = template.contents.find((x) => x.language === selectedLanguage.key);
    setContent(selectContent);
    htmlInputRef?.current?.updateText(selectContent.html_content);
    setSelectedDecor(templateDecors.find((d) => d.id === template?.email_template) || null);
  }, [templateDecors, selectedLanguage, template]);

  const handleChange = (field) => (value) => {
    setContent((prevState) => ({ ...prevState, [field]: value }));
    if (field === 'html_content') {
      htmlInputRef?.current?.updateText(value);
    }
  };

  const setBusy = (type, value = true) => {
    if (!DATA_TYPES[type]) { return; }
    setLoaders((prevState) => ({ ...prevState, [type]: value }));
  };

  const handleChangeLanguage = (lang) => {
    setSelectedLanguage(lang);
  };

  const handleSelectDecor = (decorOption) => {
    setBusy(DATA_TYPES.templateDecor);
    updateTemplate(template.id, { email_template: decorOption.key })
      .then(() => {
        setSelectedDecor(templateDecors.find((d) => d.id === decorOption.key) || null);
        handleChange('template')(decorOption.key);
      })
      .catch((err) => setAdminSnack(err))
      .finally(() => setBusy(DATA_TYPES.templateDecor, false));
  };

  const handleUploadImage = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/gif,image/jpeg,image/jpg,image/png,image/svg';
    input.onchange = (e) => {
      const file = e.currentTarget?.files?.[0] || {};
      if ((file?.size || 0) > 0) {
        uploadImage(file, content);
      }
    };
    input.click();
  };

  const handleSendTestEmail = () => {
    setIsTestEmailSending(true);
    const data = {
      email: testEmail,
      content,
      decorTemplate: selectedDecor?.id || 1,
    };
    sendTestEmail(data)
      .then((res) => {
        setTestEmailSendResult({
          type: SnackTypes.success,
          content: res.message || 'Письмо отправлено, проверьте ящик',
        });
      })
      .catch((err) => {
        setTestEmailSendResult({
          type: SnackTypes.error,
          content: err.message || 'Письмо не отправлено по неизвестной причине',
        });
      })
      .finally(() => setIsTestEmailSending(false));
  };
  const handleSave = () => {
    if (defaultLanguage.key === selectedLanguage.key) {
      let errors = [];
      const txtValidate = validateTemplateParameters(content.text_content);
      if (txtValidate.length > 0) {
        errors.push(`${defaultLanguage.title}: в текстовом содержимом отсутствуют параметры:`);
        errors = errors.concat(txtValidate);
      }
      const htmlValidate = validateTemplateParameters(content.html_content);
      if (htmlValidate.length > 0) {
        errors.push(`${defaultLanguage.title}: в HTML содержимом отсутствуют параметры:`);
        errors = errors.concat(htmlValidate);
      }
      if (errors.length > 0) {
        setValidateErrors(errors);
        return;
      }
    }
    updateTemplatesContent(content.id, content);
  };

  return (
    <Container>
      {!template && <Loader />}
      <SnackBarWindow
        isOpen={validateErrors?.length > 0}
        onClose={() => setValidateErrors([])}
        type="error"
        content={validateErrors.join('\n')}
        timestamp={1000 * 10}
      />
      <SnackBarWindow
        isOpen={testEmailSendResult}
        onClose={() => setTestEmailSendResult(null)}
        type={testEmailSendResult?.type || SnackTypes.success}
        content={testEmailSendResult?.content || ''}
        timestamp={1000 * 10}
      />
      <AdminMainPopupHeader
        linkText="Вернуться “Управление Шаблонами”"
        title="Редактирование шаблона"
        handleClose={() => history.push(BASE_URL)}
      />
      <Block
        style={{ marginBottom: (template?.type === TEMPLATE_TYPES.email) ? 0 : '20px' }}
      >
        <LeftColumn>
          <InputWrapper withoutMargin>
            <Container>
              <Label>Тема уведомления:</Label>
              <Label>{template?.name}</Label>
            </Container>
          </InputWrapper>
          <InputRowWrapper>
            <InputWrapper>
              <Label>Язык шаблона:</Label>
              <StyledDropdown
                options={languages}
                value={selectedLanguage}
                onSelect={handleChangeLanguage}
              />
            </InputWrapper>
            {(template?.type === TEMPLATE_TYPES.email) && (
              <>
                <InputWrapper>
                  <Label>Шаблон оформления:</Label>
                  <StyledDropdown
                    options={(templateDecors || []).map(decorToOption)}
                    value={selectedDecor && decorToOption(selectedDecor)}
                    onSelect={handleSelectDecor}
                    isDisabled={loaders[DATA_TYPES.templateDecor]}
                    isBusy={loaders[DATA_TYPES.templateDecor]}
                  />
                </InputWrapper>
                <InputWrapper ml="auto">
                  <Label>Тестировать на email:</Label>
                  <InputRowWrapper>
                    <StyledEmailInput
                      value={testEmail}
                      disabled={isTestEmailSending}
                      onChange={({ target }) => setTestEmail(target.value || '')}
                      placeholder="testEmail@testServer.com"
                    />
                    <StyledButton
                      isBoldText
                      width="small"
                      style={{ height: '100%' }}
                      disabled={!isEmailValid(testEmail) || isTestEmailSending}
                      onClick={handleSendTestEmail}
                    >
                      Отправить
                    </StyledButton>
                  </InputRowWrapper>
                </InputWrapper>
              </>
            )}
          </InputRowWrapper>
          <RichTextWrapper>
            <Label>Текстовое содержимое:</Label>
            <StyledTextarea
              minRows={(template?.type === TEMPLATE_TYPES.notification) ? 6 : 3}
              value={content?.text_content}
              onChange={({ target }) => handleChange('text_content')(target.value)}
            />
          </RichTextWrapper>
        </LeftColumn>
      </Block>
      {(template?.type === TEMPLATE_TYPES.email) && (
        <Row>
          <ContentContainer
            ref={dataBlockRef}
            style={{ width: 'calc(100% - 150px - 2rem)' }}
          >
            <TemplateHtmlEditBlock
              style={{ marginBottom: '20px' }}
              content={content}
              fields={fields}
              contentVariables={[...(templateParameters || [])
                .map((p) => (
                  {
                    name: p.key.replace(/^{{(.*)}}$/, '$1'),
                    title: p.value,
                    required: true,
                  }
                )), ...COMMON_TEMPLATE_VARIABLES]}
              onChangeContent={(field, val) => handleChange(field)(val)}
            />
          </ContentContainer>
          <ImagesContainer>
            <TemplateImagesBlock
              dataBlock={dataBlockRef}
              options={{ isVertical: true }}
              items={content?.images || []}
              handleDelete={(imgId) => deleteImage(imgId, content)}
              handleUpload={handleUploadImage}
            />
          </ImagesContainer>
        </Row>
      )}
      <StyledButton
        isBoldText
        isUpperCase
        width="small"
        onClick={handleSave}
      >
        Сохранить
      </StyledButton>
    </Container>
  );
};

export default TemplatesEdit;

const Container = styled.div`
  font-family: Gilroy, sans-serif;
`;

const Block = styled.div`
  display: flex;
  background: ${background01};
  border-radius: 2px;
  margin-top: 20px;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: ${({ align }) => (align || 'flex-start')};
  width: 100%;
  flex:1;

  &>*:not(:last-child) {
    margin-right: 1.5em;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 20px;
  background: ${background01};
`;
const ImagesContainer = styled.div`
  position: relative;
  padding: 2rem 1rem 0;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`;

const InputRowWrapper = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 2rem;
  }
`;

const InputWrapper = styled.div`
  ${({ withoutMargin }) => !withoutMargin && `
    margin-top: 16px;
  `}
  margin-bottom: .75rem;
  ${({ ml }) => (ml && `margin-left: ${ml};`)}
  display: flex;
  flex-direction: column;
`;

const StyledEmailInput = styled(StyledInput)`
  ${({ withoutMargin }) => !withoutMargin && `
    margin-top: 12px;
  `}
  min-width: 20em;
  height: 28px !important;
  padding: 2px 12px 3px !important;
`;

const RichTextWrapper = styled(InputWrapper)`
  margin-top: 16px;
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;
  margin-right: 14px;
`;

const StyledTextarea = styled(TextareaAutosize)`
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;
  border: none;
  outline: none;
  margin-top: 12px;
  padding: 9px 15px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
`;

const StyledButton = styled(Button)`
  margin-left: 20px;
`;

const StyledDropdown = styled(Dropdown)`
  margin-top: 12px;
`;
