import RequestService from '../../request.service';
import urls from '../../routes/admin/channels.api';
import adminUrls from '../../routes/admin/admin.api';

export const createChannelAction = (data, offset) => RequestService(urls.channelsList(offset), {
  method: 'POST',
  body: data,
});
export const deleteAllChannelsAction = () => RequestService(urls.channelsList(0), {
  method: 'DELETE',
});

export const handleImportDataAction = (data) => RequestService(adminUrls.importData, {
  method: 'POST',
  body: data,
});

export const handleValidateDataAction = (data) => RequestService(adminUrls.validateImportData, {
  method: 'POST',
  body: data,
});

export const exportDataAction = (type) => RequestService(
  adminUrls.exportData(type), { isFileRequest: true },
);

export const handleHiddenAction = (data, i, isHidden) => {
  const currentData = [...data];
  currentData[i].is_hidden = isHidden;
  return currentData;
};

export const handleArchiveAction = (data, i, isArchive) => {
  const currentData = [...data];
  currentData[i].is_archived = isArchive;
  return currentData;
};
