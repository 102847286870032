import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import BgImgSrc from '@/assets/images/webp/home-subscr-bg.webp';
import { white } from '@/common/constants/theme';
import PageContainer from '@/common/components/PageContainer';
import HomeSubscriptionCards from './components/HomeSubscriptionCards';
import HomeSubscriptionAdvCard from './components/HomeSubscriptionAdvCard';

const HomeSubscription = ({ isUserExist }) => {
  const { t } = useTranslation('translations');

  return (
    <Container src={BgImgSrc}>
      <PageContainer>
        <Wrapper flex>
          <Wrapper rightMargin>
            <Title>
              {t('homePage.subscription.adv.title')}
            </Title>
            <HomeSubscriptionAdvCard isUserExist={isUserExist} />
          </Wrapper>
          <Wrapper width="100%">
            <Title>
              {t('homePage.subscription.subscr.title')}
            </Title>
            <HomeSubscriptionCards isUserExist={isUserExist} />
          </Wrapper>
        </Wrapper>
      </PageContainer>
    </Container>
  );
};

export default HomeSubscription;

const Container = styled.div`
  display: flex;
  justify-content: center;

  padding-top: 50px;
  padding-bottom: 90px;

  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Wrapper = styled.div`
  ${({ flex }) => (flex && 'display: flex;')}
  ${({ rightMargin }) => (rightMargin && 'margin-right: 18px;')}
  ${({ width }) => (width && `width: ${width};`)}
`;

const Title = styled.div`
  margin-bottom: 30px;

  font-family: Gilroy, sans-serif;
  font-size: 34px;
  font-weight: bold;
  color: ${white};
  line-height: 36px;
  text-align: center;
`;
