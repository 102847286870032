import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { FpjsClient } from '@fingerprintjs/fingerprintjs-pro-spa';
import { useTranslation } from 'react-i18next';
import i18n from '@/i18n';

import styled from 'styled-components';
import {
  red,
  secondary, text01, white,
} from '@/common/constants/theme';

import ReCaptcha from '@/common/components/ReCaptcha';
import Input from '@/common/components/AuthorizationInput';
import Selector from '@/common/components/Selector';
import Loader from '@/common/components/LoaderFullScreen';
import GeoIcon from '@/assets/icons/geo.svg';

import PrivacyPolicyModal from '../components/PrivacyPolicyModal';
import AuthorizationLayout from '../components/AuthorizationLayout';
import { useAuthorizationContext } from '../AuthorizationContext';

import { signUpAction, validateEmailAction } from '@/services/actions/authorization.actions';
import Passwords from '@/screens/Authorization/components/Passwords';

import { FINGERPRINT_PRO_KEY } from '@/store/constants';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EMPTY_REFERRAL_OPACITY = 0.25;

function SignUpContainer() {
  const { t } = useTranslation('translations');
  const history = useHistory();
  const urlReferralToken = useQuery().get('referral_token');
  const [isLoading, setLoading] = useState(false);
  const {
    countries,
    getCountries,
    validateReferralToken,
    // signUp,
    getUserMeta,
  } = useAuthorizationContext();
  const [isPrivacyPolicyModalOpen, setPrivacyPolicyModal] = useState(false);
  const [country, setCountry] = useState(null);
  const [email, setEmail] = useState('');
  const [emailChecked, setEmailChecked] = useState('');
  const [error, setError] = useState('');
  const [passwdSuccess, setPasswdSuccess] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState('');
  const [referralToken, setReferralToken] = useState('');
  const [referralTokenError, setReferralTokenError] = useState(null);

  const [isHuman, setIsHuman] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');

  const [fingerPrint, setFingerPrint] = useState(null);

  let refUrl = document.referrer;
  if (refUrl === '') {
    refUrl = null;
  }

  useEffect(() => {
    setLoading(true);
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        setFingerPrint(result.visitorId || '-');
      });
    getCountries()
      .then(() => {
        getUserMeta()
          .then((userMeta) => {
            setCountry({
              title: userMeta.current_country.country_name,
              key: userMeta.current_country.country_code,
            });
            setLoading(false);
          });
      });
  }, []);

  const emailValidation = (emailVal) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(emailVal);
  };

  const onBlurEmail = (em) => {
    if (emailChecked === em) {
      return;
    }
    if (emailValidation(em)) {
      validateEmailAction(em)
        .then(() => {
          setEmailError('');
        })
        .catch((er) => {
          setEmailError(`${t(`authorization.errors.${er.message_type || 'unknown'}`)}`);
        })
        .finally(() => setEmailChecked(em));
    } else {
      setEmailError(t('authorization.invalidEmail'));
    }
  };

  const onBlurReferralToken = (verifyToken) => {
    if (!verifyToken) {
      setReferralTokenError('');
      return;
    }
    if (urlReferralToken && !refUrl) {
      refUrl = verifyToken;
    }
    validateReferralToken(verifyToken, refUrl)
      .then((res) => {
        localStorage.setItem('referralLinkClickId', res.referral_link_click_through_id);
        setReferralTokenError('');
      })
      .catch(() => {
        setReferralTokenError(t('authorization.signUp.user_referral_token_invalid'));
      });
  };

  const handleCaptcha = (v) => {
    setIsHuman(!!v);
    setCaptchaToken(v);
  };

  useEffect(() => {
    setReferralToken(urlReferralToken);
    onBlurReferralToken(urlReferralToken);
  }, [urlReferralToken]);

  // eslint-disable-next-line consistent-return
  const handleSignUpButton = async () => {
    if (!email) {
      setEmailError(t('authorization.emptyEmail'));
      return;
    }
    if (!emailValidation(email)) {
      setEmailError(t('authorization.invalidEmail'));
      return;
    }
    setEmailError('');
    if (!passwdSuccess) {
      return;
    }
    if (fingerPrint === null) {
      setEmailError(t('authorization.noFingerPrint'));
      return;
    }

    setLoading(true);
    onBlurReferralToken(referralToken);

    let fProId = '-';
    try {
      const fpjsClient = new FpjsClient({
        loadOptions: {
          apiKey: FINGERPRINT_PRO_KEY,
        },
      });

      fProId = await fpjsClient.init()
        .then(() => fpjsClient.getVisitorData()
          .then((fpData) => fpData?.visitorId || '-')
          .catch(() => '-'));
    } catch (ex) {
      fProId = 'Client-loading-error';
    }

    const signupData = {
      email,
      frontend_language: i18n.language,
      password1: password,
      password2: password,
      country: country.key,
      referral_token: referralToken || null,
      referral_link_click_through_id: referralToken
        ? localStorage.getItem('referralLinkClickId')
        : null,
      captcha_token: captchaToken,
      fingerprint_token: fProId,
      fingerprint_pro_token: fingerPrint,
    };

    signUpAction(signupData)
      .then(() => {
        history.push(`/confirm-email/${email}`);
        localStorage.removeItem('referralLinkClickId');
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        setError(t(`authorization.signUp.${err.message_type}`));
      })
      .finally(() => setLoading(false));
  };
  return (
    <AuthorizationLayout>
      {isLoading && <Loader />}
      <PrivacyPolicyModal
        isVisible={isPrivacyPolicyModalOpen}
        toggleVisibility={() => setPrivacyPolicyModal(!isPrivacyPolicyModalOpen)}
        onSubmit={() => setPrivacyPolicyModal(!isPrivacyPolicyModalOpen)}
      />
      <Title>
        <TitleText>{t('authorization.signUp.textBlack1')}</TitleText>
        <TitleTextOrange>{t('authorization.signUp.textOrange')}</TitleTextOrange>
        <TitleText>{t('authorization.signUp.textBlack2')}</TitleText>
      </Title>
      <Form>
        {!!countries.length && country && (
          <SelectContainer>
            <Selector
              options={countries}
              value={country}
              onSelect={setCountry}
              icon={GeoIcon}
            />
          </SelectContainer>
        )}
        <Input
          placeholder="Email"
          value={email}
          onKeyUp={({ target, keyCode }) => {
            if (keyCode === 13) {
              setEmail(target.value);
              onBlurEmail(target.value);
              if (passwdSuccess && !emailError && !referralTokenError) {
                handleSignUpButton();
              }
            }
          }}
          onChange={({ target }) => {
            setEmail(target.value);
          }}
          onBlur={() => onBlurEmail(email)}
          errorText={emailError}
          isSuccess={emailValidation(email)}
        />
        <Passwords
          setPassword={setPassword}
          setSuccess={setPasswdSuccess}
        />
        <Input
          placeholder={t('authorization.referral')}
          value={referralToken}
          onChange={({ target }) => {
            // setReferralTokenError('');
            setReferralToken(target.value);
            onBlurReferralToken(target.value);
          }}
          errorText={referralTokenError}
          opacity={
            ((referralToken || '').length === 0)
            && ((referralTokenError || '').length === 0)
            && EMPTY_REFERRAL_OPACITY
          }
        />
        <ErrorText>{error}</ErrorText>
        <PrivacyPolicyWrapper>
          {t('authorization.policy.continueText')}
          <PrivacyPolicyLink onClick={() => setPrivacyPolicyModal(true)}>
            {t('authorization.policy.termsOfUse')}
          </PrivacyPolicyLink>
          {t('authorization.policy.and')}
          <PrivacyPolicyLink onClick={() => setPrivacyPolicyModal(true)}>
            {t('authorization.policy.privacyPolicy')}
          </PrivacyPolicyLink>
        </PrivacyPolicyWrapper>
        {passwdSuccess && !emailError && !referralTokenError && (
          <>
            {isHuman ? (
              <Button onClick={handleSignUpButton}>{t('authorization.signUpButton')}</Button>
            ) : (
              <ReCaptcha
                appToken={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                onCheck={handleCaptcha}
              />
            )}
          </>
        )}
        <SignInContainer>
          <Text>{t('authorization.signUp.noAccaunt')}</Text>
          <SignInLink to="/sign-in">{t('authorization.signInButton')}</SignInLink>
        </SignInContainer>
      </Form>
    </AuthorizationLayout>
  );
}

export default SignUpContainer;

const Title = styled.div`
  margin-top: 10rem;
  width: 40rem;
`;

const TitleText = styled.span`
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  font-size: 2.75rem;
  line-height: 48px;
  color: ${text01};
  white-space: pre-wrap;
`;

const TitleTextOrange = styled(TitleText)`
  color: ${secondary}
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: .75rem;
`;

const Form = styled.div`
  min-height: 30rem;
  width: 28rem;
  display: flex;
  flex-direction: column;
  margin: 0 4.5rem 0 8rem;
  align-items: flex-start;
`;

const Button = styled.button`
  margin: .5rem 0;
  border: none;
  background: ${secondary};
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${white};
  padding: .75rem 1rem;
  cursor: pointer;
  outline: none;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;

const SignInContainer = styled.div`
  margin: .5rem 0 2.5rem;
`;

const Text = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: rgba(32, 32, 32, 0.6);
  margin-right: .5rem;
`;

const SignInLink = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: ${secondary};
  white-space: pre-wrap;

  &&:hover {
      color: #ffab30;
    }
`;

const PrivacyPolicyWrapper = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: rgba(32, 32, 32, 0.6);
  margin: .5rem 0;
`;

const PrivacyPolicyLink = styled(PrivacyPolicyWrapper)`
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  margin: 0 .25rem;
  cursor: pointer;
  transition: all 0.20s ease-in;
  &&:hover {
    color: #ffab30;
    border-color: #ffab30;
  }
`;

const ErrorText = styled.span`
  font-family: Gilroy, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.16px;
  color: ${red};
  margin-top: .25rem;
  margin-bottom: 1rem;
  white-space: pre-line;
`;
