/* eslint no-console: 0 */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  background01,
  text01,
  secondary,
} from '@/common/constants/theme';
import CloseIcon from '@/common/components/icons/CloseIcon';
import EpgUpdatePanel from './components/EpgUpdatePanel';
import SyncPanel from './components/SyncPanel';

const MinistraBottomWindowContainer = ({
  title,
  isOpen,
  onClose,
  isEpgUpdate,
  epgUpdateResult,
  setTableHeightCallback,
  syncCallback,
  isSyncProcess,
  isInSync,
  syncData,
  isSyncing,
  isSyncError,
  isCloseEnabled,
  isRequest,
  syncStatusText,
}) => {
  const [containerHeight, setContainerHeight] = useState('380px');

  useEffect(() => {
    if (!isOpen && setTableHeightCallback) {
      setTableHeightCallback('init');
    }
  }, [isOpen]);

  useEffect(() => {
    setContainerHeight(isRequest ? '130px' : '380px');
    if (setTableHeightCallback) {
      let state = 'init';
      if (isOpen) {
        state = isRequest ? 'withRequest' : 'withResult';
      }
      setTableHeightCallback(state);
    }
  }, [isRequest]);

  return (
    <>
      {isOpen && (
        // eslint-disable-next-line max-len
        <Container containerHeight={containerHeight}>
          {isCloseEnabled && (
            <Icon onClick={onClose}>
              <CloseIcon />
            </Icon>
          )}
          <Title>{title}</Title>
          {isEpgUpdate && <EpgUpdatePanel text={epgUpdateResult} />}
          {isSyncProcess
            && (
              <SyncPanel
                data={syncData}
                syncCallback={syncCallback}
                isSyncing={isSyncing}
                isInSync={isInSync}
                statusTextExt={syncStatusText}
                isError={isSyncError}
              />
            )}
        </Container>
      )}
    </>
  );
};

export default MinistraBottomWindowContainer;

const Container = styled.div`
  margin-top: 20px;
  padding: 20px 12px 22px 22px;

  position: relative;

  background: ${background01};
  border-radius: 2px;

  // eslint-disable-next-line no-nested-ternary
  ${({ containerHeight }) => `height: ${containerHeight};`}
`;

const Text = styled.div`
  ${({ bottomMargin }) => (`margin-bottom: ${bottomMargin || 15}px;`)}
  ${({ leftMargin }) => leftMargin && ('margin-left: 5px;')}

  font-family: Gilroy, sans-serif;
  font-size: ${({ largeFontSize }) => (largeFontSize ? 18 : 14)}px;
  ${({ bold }) => bold && ('font-weight: 600;')}
  color: ${({ secondaryColor }) => (secondaryColor ? secondary : text01)};
  letter-spacing: 0.16px;
  line-height: 15px;
`;

const Title = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;

const Icon = styled.div`
  position: absolute;
  top: 11px;
  right: 13px;

  cursor: pointer;

  & svg path {
    fill: ${text01};
  }
`;
