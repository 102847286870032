import RequestService from '../../request.service';
import urls from '../../routes/admin/admin.api';
import channelUrls from '../../routes/admin/channels.api';

export const getImportDataAction = () => RequestService(urls.getImportData, { method: 'POST' });

export const getChannelsAction = (
  offset, pageLimit, groupId,
) => RequestService(channelUrls.channelsList(offset, pageLimit, groupId));

export const getChannelByIdAction = (id) => RequestService(channelUrls.channelsItem(id));

export const updateChannelByIdAction = (id, data) => RequestService(channelUrls.channelsItem(id), {
  method: 'PUT',
  body: data,
});
export const deleteChannelByIdAction = (id) => RequestService(channelUrls.channelsItem(id), { method: 'DELETE' });

export const moveChannelAction = (id, prevChannelId) => RequestService(
  channelUrls.channelsMove(id), {
    method: 'POST',
    body: { prev_channel_id: prevChannelId },
  },
);

export const getCountriesAction = () => RequestService(urls.countries);

export const getMenuAction = () => RequestService(urls.menuItems);

export const handleDeleteAction = (data, id) => (
  [...data.slice(0, id), ...data.slice(id + 1)]
);

export const sendEmailAction = (userId, data) => RequestService(urls.sendMail(userId), { method: 'POST', body: data });

export const sendTestEmailAction = (data) => RequestService(urls.sendTestMail, { method: 'POST', body: data });
