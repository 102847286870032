import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import ModalWithoutContentFilled from '@/common/components/ModalWithoutContentFilled';
import { greyWhite } from '@/common/constants/theme';
import Button from '@/common/components/Button';

const CancelWithdrawalModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  onCancel,
  isReasonNeeded,
}) => {
  const [isReasonSetted, setIsReasonSetted] = useState(false);

  useEffect(() => {
    setIsReasonSetted(false);
  }, [isOpen]);

  return (
    <ModalWithoutContentFilled
      color="danger"
      onCloseModal={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      <ModalText>
        Вы хотите отменить
      </ModalText>
      <ModalBigText>
        Заявку на вывод средств
      </ModalBigText>
      {isReasonNeeded
        && (
          <>
            <ModalText>
              Укажите причину отмены транзакции
            </ModalText>
            <TextArea
              id="CancelWithdrawalModalReasonTextArea"
              placeholder="Транзакция отменяется по причине..."
              onChange={(e) => {
                setIsReasonSetted((e.target.value.length || 0) > 0);
              }}
            />
          </>
        )}
      <Wrapper>
        <CancelButton onClick={() => {
          if (onCancel) {
            onCancel();
          }
          setIsOpen(false);
        }}
        >
          Отмена
        </CancelButton>
        <SubmitButton
          isUpperCase
          isDisabled={!isReasonSetted}
          onClick={() => {
            const reason = isReasonNeeded ? document.getElementById('CancelWithdrawalModalReasonTextArea').value : null;
            if (onSubmit) {
              onSubmit(reason);
            }
            setIsOpen(false);
          }}
        >
          Да
        </SubmitButton>
      </Wrapper>
    </ModalWithoutContentFilled>
  );
};

export default CancelWithdrawalModal;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 26px;
`;

const CancelButton = styled.button`
  margin-right: 24px;
  margin-left: auto;

  background-color: transparent;
  border: none;
  cursor: pointer;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: ${greyWhite};
  text-transform: uppercase;
  text-decoration: underline;
  line-height: 24px;
`;

const SubmitButton = styled(Button)`
  width: 54px;
`;

const ModalText = styled.div`
  margin-bottom: .25rem;

  font-family: Gilroy, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.16px;
  text-align: left;
`;

const ModalBigText = styled(ModalText)`
  margin-bottom: .75rem;

  font-size: 20px;
  line-height: 20px;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 5rem;
  margin-top: 0.25rem;
  padding: .125rem .125rem .125rem 1rem;

  border: none;
  border-left: solid 1px;

  background: transparent;
  color: ${greyWhite};

  outline: none !important;
`;
