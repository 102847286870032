import React, {
  createContext,
  useState,
  useContext,
} from 'react';

import {
  getTemplatesListAction,
} from '@/services/actions/admin/templates.actions';

const TemplatesListContext = createContext({});

export default ({ children }) => {
  // Tools
  const pageLimit = 50;
  const getOffset = (page) => (page - 1) * pageLimit;
  const getPosition = (page, index) => getOffset(page) + index + 1;

  const [isBusy, setIsBusy] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templatesCount, setTemplatesCount] = useState(0);
  const [templatesPage, setTemplatesPage] = useState(1);

  const getTemplatesPageCount = () => Math.ceil(templatesCount / pageLimit);

  const getTemplates = (typeFilter, queryFilter) => {
    setIsBusy(true);
    getTemplatesListAction(typeFilter, queryFilter, templatesPage, pageLimit)
      .then((res) => {
        setTemplates(res.results);
        setTemplatesCount(res.count);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err))
      .finally(() => setIsBusy(false));
  };

  const value = {
    isBusy,
    getTemplates,
    templates,
    templatesCount,
    getPosition,
    templatesPage,
    setTemplatesPage,
    getTemplatesPageCount,
  };

  return (
    <TemplatesListContext.Provider value={value}>
      {children}
    </TemplatesListContext.Provider>
  );
};

export const useTemplatesListContext = () => useContext(TemplatesListContext);
