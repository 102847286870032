import React from 'react';
import { text03 } from '@/common/constants/theme';

export default ({ color = text03, opacity = 0.7, size = 12 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size * (275 / 187.5)} height={size} viewBox="0 0 275 187.5">
    <g fill={color} fillOpacity={opacity}>
      <path d="M137.5,56.25A37.5,37.5,0,1,0,175,93.75,37.53,37.53,0,0,0,137.5,56.25Z" />
      <path d="M137.5,0A147.83,147.83,0,0,0,0,93.75a147.71,147.71,0,0,0,275,0A147.76,147.76,0,0,0,137.5,0Zm0,156.25A62.5,62.5,0,1,1,200,93.75,62.52,62.52,0,0,1,137.5,156.25Z" />
    </g>
  </svg>
);
