import React from 'react';
import { ReferralsLinksContextProvider } from './ReferralsLinksContext';
import ReferralsLinksTable from './ReferralsLinksTable';

const ReferralsLinksTableContainer = () => (
  <ReferralsLinksContextProvider>
    <ReferralsLinksTable />
  </ReferralsLinksContextProvider>
);

export default ReferralsLinksTableContainer;
