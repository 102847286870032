import React from 'react';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';

import EditNews from './Base/components/EditNews';
import Base from './Base/Base';
import BaseContextProvider from './Base/BaseContext';

const NewsContaiter = () => (
  <Switch>
    <Route exact path="/admin/public/news/">
      <BaseContextProvider>
        <Base />
      </BaseContextProvider>
    </Route>
    <Route exact path="/admin/public/news/:id">
      <BaseContextProvider>
        <EditNews />
      </BaseContextProvider>
    </Route>
    <Redirect to="/admin/public/news/" />
  </Switch>
);

export default NewsContaiter;
