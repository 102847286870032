/* eslint-disable no-unused-vars,no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

import styled from 'styled-components';
import {
  background01, greyBorder, inputBorder,
  primary, secondary,
  text01, text02, greyText,
  white, red,
} from '@/common/constants/theme';

import CloseIcon from '@/common/components/icons/CloseIcon';
import WarningIcon from '@/common/components/icons/WarningIcon';
import CalendarIcon from '../../../../../PersonalPageScreen/components/icons/CalendarIcon';

import Checkbox from '@/common/components/Checkbox';
import DatePicker from '@/common/components/DatePicker';
import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';
import { AdminMainPopupHeader } from '../../components/MainPopupComponents';

import TemplateImagesBlock from '../../components/TemplateImagesBlock';
import TemplateHtmlEditBlock from '../../components/TemplateHtmlEditBlock';

import { useAppContext } from '@/AppContext';
import { COMMON_TEMPLATE_VARIABLES } from '../../../../AdminContext';
import {
  BASE_PATH,
  DATA_TYPES,
  EMPTY_CONTENT_ITEM,
  CONTENT_VARIABLES,
  useTemplateDecorsContext,
} from '../TemplateDecorsContext';

const DATE_FORMAT = 'dd.MM.yyyy';

const CHANGE_CONTROL_FIELDS = [
  'name', 'description', 'is_active',
];

const CHANGE_CONTROL_CONTENTS_FIELDS = [
  'html_content',
];

const INITIAL_DATA = {
  id: '',
  name: '',
  description: '',
  is_active: true,
  contents: [],
  images: [],
};

const TemplateDecorsItem = () => {
  const history = useHistory();
  const { id } = useParams();

  const dataBlockRef = useRef(null);

  const {
    siteLanguages: languages,
  } = useAppContext();

  const {
    dataProcessing,
    selectedTdItem,
    setSelectedTdItem,
    clearSelectedTdItem,
    loadItem,
    addTdItem,
    updateTdItem,
    uploadImage,
    deleteImage,
  } = useTemplateDecorsContext();

  const [tdItemOriginal, setTdItemOriginal] = useState({
    ...INITIAL_DATA,
    contents: [],
    images: [],
  });
  // eslint-disable-next-line no-unused-vars
  const [tmpTdId, setTmpTdId] = useState(null);
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const [activeLanguageId, setActiveLanguageId] = useState(null);
  const [activeContent, setActiveContent] = useState(EMPTY_CONTENT_ITEM);
  const [isFormShown, setIsFormShown] = useState(false);

  const [fields] = useState({
    html_content: 'HTML шаблона',
  });
  const [canEdit] = useState(Math.random() > 0);

  const getCurrentContent = () => (selectedTdItem?.contents || []).find((c) => (
    c.language === (activeLanguageId || 0)
  )) || EMPTY_CONTENT_ITEM;

  const handleChange = (field) => (value) => {
    setSelectedTdItem((prevNews) => ({ ...prevNews, [field]: value }));
  };

  const handleChangeContentBlock = (field, value) => {
    const index = selectedTdItem.contents.findIndex((c) => activeContent.id === c.id);
    const newContent = { ...selectedTdItem.contents[index] };
    newContent[field] = value;
    const newContents = [...selectedTdItem.contents] || [];
    newContents.splice(index, 1, newContent);
    setSelectedTdItem((prevState) => ({ ...prevState, contents: newContents }));
  };

  const handleClearContent = (index) => {
    const newContents = cloneDeep(selectedTdItem.contents);
    const useLangId = selectedTdItem.contents[index].language;
    newContents.splice(index, 1,
      { ...EMPTY_CONTENT_ITEM, language: useLangId, componentId: `ecId-${Math.random()}` });
    setSelectedTdItem({ ...selectedTdItem, contents: newContents });
  };

  const processSaveResult = (res) => {
    if (res?.id) {
      history.push(BASE_PATH);
    }
  };

  const handleSave = () => {
    const {
      images,
      contents,
      ...data
    } = selectedTdItem;

    const preparedItem = {
      ...data,
      contents: contents.filter((a) => {
        let isNotEmpty = true;
        CHANGE_CONTROL_CONTENTS_FIELDS
          .forEach((f) => {
            isNotEmpty = isNotEmpty && (a[f].trim().length > 0);
          });
        return isNotEmpty;
      }),
    };
    if (id === 'new') {
      addTdItem(preparedItem).then(processSaveResult);
    } else {
      updateTdItem(preparedItem).then(processSaveResult);
    }
  };

  const handleRestore = () => {
    const copy = cloneDeep(tdItemOriginal);
    copy.contents = copy.contents.map((c) => ({ ...c, componentId: `ecId-${Math.random()}` }));
    setSelectedTdItem(copy);
  };

  const InputDate = (date, field, params = {}) => (
    <CalendarWrapper>
      {params?.labelText && (
        <Label mr=".25em">{`${params.labelText}:`}</Label>
      )}
      <StyledInput
        disabled={!!params?.isDisabled}
        onClick={() => !params?.isDisabled && setIsCalendarOpened(true)}
      >
        {date ? format(date, DATE_FORMAT) : ''}
        {selectedTdItem[field] && !params?.isDisabled && (
          <Clickable onClick={(e) => {
            e.stopPropagation();
            handleChange(field)(null);
          }}
          >
            <CloseIcon />
          </Clickable>
        )}
      </StyledInput>
      <DatePicker
        isOpen={isCalendarOpened}
        noApplyButton
        value={date}
        onChange={(value) => {
          handleChange(field)(format(new Date(value), DATE_FORMAT));
          setIsCalendarOpened(false);
        }}
        onClose={() => setIsCalendarOpened(false)}
      />
      {!params?.isDisabled && (
        <Icon
          topMargin
          onClick={() => setIsCalendarOpened(true)}
        >
          <CalendarIcon />
        </Icon>
      )}
    </CalendarWrapper>
  );

  const isContentEmpty = (content) => {
    let result = true;
    CHANGE_CONTROL_CONTENTS_FIELDS.forEach((f) => {
      result = result && ((content?.[f] || '').trim().length === 0);
    });
    return result;
  };

  const isRequiredVariablesSet = (checkData) => {
    let checkResult = true;
    [...CONTENT_VARIABLES, ...COMMON_TEMPLATE_VARIABLES].forEach(({ name, required }) => {
      if (!required) { return; }
      checkResult = checkResult && checkData.trim().includes(`{{${name}}}`);
    });
    return checkResult;
  };

  const isContentValid = (content, isRequired = false) => {
    const checkData = content || {};
    let checkResult = true;
    if (isRequired) {
      CHANGE_CONTROL_CONTENTS_FIELDS
        .forEach((cf) => {
          checkResult = checkResult && (checkData[cf].trim().length > 0);
          checkResult = checkResult && isRequiredVariablesSet(checkData[cf].trim());
        });
    } else {
      const isValueSet = checkData[CHANGE_CONTROL_CONTENTS_FIELDS[0]].trim().length > 0;
      CHANGE_CONTROL_CONTENTS_FIELDS
        .forEach((cf) => {
          checkResult = checkResult && ((checkData[cf].trim().length > 0) === isValueSet);
          if (isValueSet) {
            checkResult = checkResult && isRequiredVariablesSet(checkData[cf].trim());
          }
        });
    }
    return checkResult;
  };

  const isContentsValid = (item) => {
    // is default lang content valid
    const defaultLangId = languages.find((l) => l.is_default).id;
    const defaultLangContent = (item.contents || [])
      .find((i) => i.language === defaultLangId) || null;

    // is other lang content valid
    let isOtherLangsContentsValid = true;
    const otherLangsContent = (item.contents || [])
      .filter((i) => i.language !== defaultLangId) || [];
    otherLangsContent.forEach((olc) => {
      isOtherLangsContentsValid = isOtherLangsContentsValid && isContentValid(olc);
    });

    return defaultLangContent && isContentValid(defaultLangContent, true)
      && isOtherLangsContentsValid;
  };

  const isContentsEqual = () => {
    const originalContent = tdItemOriginal.contents || [];
    const currentContent = selectedTdItem.contents || [];

    return currentContent.length === originalContent.length
      && (currentContent.filter((cContent) => {
        const oContent = originalContent.find((oc) => oc.language === cContent.language) || null;
        let isEqual = true;
        CHANGE_CONTROL_CONTENTS_FIELDS.forEach((cf) => {
          isEqual = isEqual && oContent && (oContent[cf] === cContent[cf]);
        });
        return isEqual;
      }) || []).length === originalContent.length;
  };

  const RTFFix = (text) => text.replace('auto%', '0%');

  const isTdChanged = () => {
    let isNotChanged = true;
    const { contents, images, ...data } = selectedTdItem;
    Object.entries(data).forEach(([field, value]) => {
      if (!CHANGE_CONTROL_FIELDS.includes(field)) { return; }
      isNotChanged = isNotChanged && (tdItemOriginal[field] === value);
    });
    isNotChanged = isNotChanged && isContentsEqual();
    return !isNotChanged;
  };

  const isTdCanBeSaved = () => (
    ((selectedTdItem?.name || '').length > 0)
    && ((selectedTdItem?.description || '').length > 0)
    && isContentsValid(selectedTdItem)
  );

  const addNoVariablesErrors = (
    errors, requiredContent, requiredLang,
  ) => CHANGE_CONTROL_CONTENTS_FIELDS
    .forEach((cf) => {
      if (!isRequiredVariablesSet(requiredContent[cf].trim())) {
        errors.push(`Поле (${fields?.[cf] || cf}) для языка (${requiredLang.name}) не содержит одной из обязательных переменных (${Object.keys([...CONTENT_VARIABLES, ...COMMON_TEMPLATE_VARIABLES])
          .map((v) => `{{${v}}}`).join(', ')})`);
      }
    });

  const getSaveButtonTitle = () => {
    const errors = [];
    if ((selectedTdItem?.name || '').length === 0) {
      errors.push('Не задано название');
    }
    if ((selectedTdItem?.description || '').length === 0) {
      errors.push('Не заполнено описание');
    }
    const requiredLang = languages.find((l) => l.is_default);
    const requiredContent = (selectedTdItem?.contents || [])
      .find((c) => c.language === requiredLang.id) || null;
    if (!isContentValid(requiredContent, true)) {
      errors.push(`Все поля для языка (${requiredLang.name}) обязательны к заполнению`);
      addNoVariablesErrors(errors, requiredContent, requiredLang);
    }
    const otherContents = (selectedTdItem?.contents || [])
      .filter((c) => c.language !== requiredLang.id);

    otherContents.forEach((c) => {
      if (!isContentValid(c)) {
        const lang = languages.find((l) => l.id === c.language);
        errors.push(`Поля для языка (${lang.name}) заполнены не полностью`);
        addNoVariablesErrors(errors, c, requiredLang);
      }
    });

    return errors.join('\n');
  };

  const handleUploadImage = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/gif,image/jpeg,image/jpg,image/png,image/svg';
    input.onchange = (e) => {
      const file = e.currentTarget?.files?.[0] || {};
      if ((file?.size || 0) > 0) {
        const currentContent = selectedTdItem.contents
          .find((c) => c.language === activeLanguageId);
        uploadImage(file, currentContent.id, tmpTdId);
      }
    };
    input.click();
  };

  const contentsFromLangs = () => languages
    .map((l) => ({ ...EMPTY_CONTENT_ITEM, language: l.id }));

  useEffect(() => {
    if (!languages) { return; }

    if (id !== 'new') {
      loadItem(id)
        .then((tdItem) => {
          setTdItemOriginal({
            ...tdItem,
            contents: [...tdItem?.contents || []]
              .map((cI) => ({ ...cI, html_content: RTFFix(cI.html_content) })),
            images: [...tdItem?.images || []],
          });
          const defaultLanguageId = languages.find((l) => l.is_default)?.id || 0;
          setActiveContent((tdItem?.contents || [])
            .find((c) => c.language === defaultLanguageId) || EMPTY_CONTENT_ITEM);
        })
        .finally(() => setIsFormShown(true));
    } else {
      tdItemOriginal.contents = contentsFromLangs();
      setSelectedTdItem({
        ...INITIAL_DATA,
        contents: contentsFromLangs(),
        images: [],
      });
      setTmpTdId(uuidv4());
      setIsFormShown(true);
    }
  }, [id, languages]);

  useEffect(() => {
    if (languages) {
      setActiveLanguageId(languages.find((l) => l.is_default).id);
    }
  }, [languages]);

  useEffect(() => {
    if (activeContent && selectedTdItem) {
      setActiveContent(getCurrentContent());
    }
  }, [selectedTdItem]);

  return (
    <Container>
      {dataProcessing[DATA_TYPES.tdItem] && <Loader />}
      <AdminMainPopupHeader
        linkText="Вернуться к списку"
        title={`${id === 'new' ? 'Добавить' : 'Изменить'} шаблон оформления`}
        handleClose={() => history.push(BASE_PATH)}
      />
      {isFormShown && (
        <>
          <TdContent>
            {!canEdit && <EditDisabler />}
            <Column>
              <Row>
                <InputWrapper width="100%">
                  <Label>Название:</Label>
                  <Input
                    disabled={!canEdit}
                    value={selectedTdItem.name}
                    onChange={({ target }) => handleChange('name')(target.value)}
                  />
                </InputWrapper>
              </Row>
              <RichTextWrapper
                style={{ width: '100%' }}
              >
                <Label>Описание:</Label>
                <StyledTextArea
                  disabled={!canEdit}
                  value={selectedTdItem.description || ''}
                  onChange={({ target }) => handleChange('description')(target.value)}
                />
              </RichTextWrapper>
            </Column>
          </TdContent>
          <Row>
            <TdContent
              ref={dataBlockRef}
              noMargin
              style={{ width: 'calc(100% - 150px - 2rem)' }}
            >
              <TdNavBar>
                {(selectedTdItem?.contents || [])
                  .sort((a, b) => {
                    const aSort = languages.find((l) => l.id === a.language).is_default ? 0 : 1;
                    const bSort = languages.find((l) => l.id === b.language).is_default ? 0 : 1;
                    if (aSort === bSort) return 0;
                    return aSort - bSort;
                  })
                  .map((cnt, index) => (
                    <TdNavItem
                      key={`TdItem--contents-Nav-${selectedTdItem.id}_${cnt.language}`}
                      onClick={() => {
                        setActiveLanguageId(cnt.language);
                        setActiveContent(cnt);
                      }}
                      isActive={(activeLanguageId === cnt.language)}
                    >
                      {languages.find((l) => l.id === cnt.language)?.name}
                      {!isContentEmpty(cnt) && (
                        <TdNavItemClearControl
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleClearContent(index);
                          }}
                        >
                          <CloseIcon />
                        </TdNavItemClearControl>
                      )}
                      {isContentEmpty(cnt)
                        && (cnt.language === languages.find((l) => l.is_default).id)
                        && (
                          <TdNavItemWarningControl title="Все поля обязательны к заполнению">
                            <WarningIcon width={13} height={13} fill={red} />
                          </TdNavItemWarningControl>
                        )}
                    </TdNavItem>
                  ))}
              </TdNavBar>
              <TemplateHtmlEditBlock
                fields={fields}
                content={activeContent}
                contentVariables={[...CONTENT_VARIABLES, ...COMMON_TEMPLATE_VARIABLES]}
                onChangeContent={handleChangeContentBlock}
              />
            </TdContent>
            <TdImages>
              <TemplateImagesBlock
                dataBlock={dataBlockRef}
                options={{ isVertical: true }}
                items={activeContent?.images || []}
                handleDelete={(imgId) => deleteImage(imgId, activeContent)}
                handleUpload={handleUploadImage}
              />
            </TdImages>
          </Row>
          <TdAttributes>
            <LeftContent>
              <StyledCheckbox
                checked={selectedTdItem.is_active}
                onChange={handleChange('is_active')}
                type="secondary"
                label="Активен"
              />
              {selectedTdItem?.dt_create && (
                <DateContainer>
                  {InputDate(
                    moment.parseZone(selectedTdItem.dt_create).toDate(),
                    'dt_create',
                    {
                      labelText: 'Создан',
                      isDisabled: true,
                    },
                  )}
                </DateContainer>
              )}
              {selectedTdItem?.dt_update && (
                <DateContainer>
                  {InputDate(
                    moment.parseZone(selectedTdItem.dt_update).toDate(),
                    'dt_update',
                    {
                      labelText: 'Изменен',
                      isDisabled: true,
                    },
                  )}
                </DateContainer>
              )}
            </LeftContent>
          </TdAttributes>
          <TdActions>
            <Button
              isDisabled={!isTdChanged()}
              isBoldText
              isUpperCase
              width="small"
              onClick={handleRestore}
            >
              Восстановить
            </Button>
            <Button
              title={getSaveButtonTitle()}
              isDisabled={!(isTdCanBeSaved() && isTdChanged())}
              isBoldText
              isUpperCase
              width="small"
              onClick={handleSave}
            >
              Сохранить
            </Button>
          </TdActions>
        </>
      )}
    </Container>
  );
};

export default TemplateDecorsItem;

const Container = styled.div`
  font-family: Gilroy, sans-serif;
`;

const TdNavBar = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0.75rem;
  height: 55px;
`;

const TdNavItem = styled.div`
  position: relative;
  padding: 0.25rem calc(0.25rem + 16px) 0.25rem 0.5rem;
  color: ${({ isActive }) => (isActive ? secondary : primary)};
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  ${({ isActive }) => (isActive && 'font-size: 1em;')};
  ${({ isActive }) => (isActive && 'font-weight: bold;')};

  transition: all 0.25s ease-out 0s;

  &:hover {
    color: ${secondary};
  }

  &:not(:last-child) {
    border-right: 1px solid ${greyBorder};
  }
`;

const TdNavItemClearControl = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
`;

const TdNavItemWarningControl = styled(TdNavItemClearControl)`
  height: 18px;
  top: -2px;
`;

const TdContent = styled.div`
  position: relative;
  width: 100%;
  padding: 2rem 1rem 0;
  ${({ noMargin }) => (noMargin && 'margin: 0 !important;')}
`;

const TdImages = styled.div`
  position: relative;
  padding: 2rem 1rem 0;
`;

const EditDisabler = styled.div`
`;

const StyledTextArea = styled.textarea`
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;
  border: none;
  outline: none;
  margin-top: 12px;
  padding: 9px 15px;
  font-family: Gilroy, sans-serif;
  font-size: 1em;
  line-height: 1.25em;
  letter-spacing: 0.16px;
  min-height: 4.75em;
  resize: vertical;
`;

const TdAttributes = styled.div`
  padding: 0 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: ${({ align }) => (align || 'flex-start')};
  width: 100%;
  flex:1;

  &>*:not(:last-child) {
    margin-right: 1.5em;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width || 'auto')};
`;

const RichTextWrapper = styled(InputWrapper)`
  width: ${({ width }) => ((width === undefined) ? 100 : width)}%;
  overflow: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  margin-top: 16px;
  ${({ noMr }) => (noMr && 'margin-right: 0 !important;')}
  transition: all .15s ease-out 0s;
`;

const Label = styled.span`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;
  ${({ mr }) => (mr && `margin-right: ${mr};`)}
`;

const Input = styled.input`
  flex: 1;
  box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
  border-radius: 4px;
  border: none;
  outline: none;
  margin-top: 12px;
  padding: 9px 15px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.16px;
`;

const StyledInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 102px;
  height: 24px;

  background: ${white};
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-size: 13px;
  line-height: 15px;
  color: ${text01};
`;

const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ flexSb }) => flexSb && ('justify-content: space-between;')}

  height: 24px;
  margin-top: 16px;

  & .main-datepicker {
    left: -90px;
    bottom: -20px;
  }
`;

const Clickable = styled.span`
  cursor: pointer;
  margin-left: 8px;
`;

const Icon = styled.div`
  ${({ topMargin }) => topMargin && (`
    position: relative;
    top: 6px;
  `)};
  margin-left: 10px;
  cursor: pointer;
`;

const LeftContent = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;

  & > * {
    :not(:last-child) {
      margin-right: 3em;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ withoutMargin }) => !withoutMargin && `
    margin-top: 16px
  `}
`;

const TdActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;

  & > * {
    margin-left: 1rem;
  }
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
`;
