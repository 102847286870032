import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { text01, inputBorder, greyText } from '@/common/constants/theme';
import megaphoneIcon from '@/assets/icons/megaphoneIcon.svg';
import ModalNew from '@/common/components/ModalNew';
import RichTextEditor from '@/common/components/RichTextEditor/RichTextEditor';
import Button from '@/common/components/Button';

const SHOW_SENDER_EMAIL = false;

const UsersMainUserInfoMail = ({ isOpen, onClose, onSubmit }) => {
  const [sender, setSender] = useState('');
  const [email, setEmail] = useState('');
  const [theme, setTheme] = useState('');
  const [text, setText] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const handleClose = () => {
    setTheme('');
    setText('');
    if (onClose) onClose();
  };

  // eslint-disable-next-line no-unused-vars
  const isEmail = (str) => String(str).toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  useEffect(() => {
    if (!isOpen) { return; }
    setIsSubmitDisabled(
      // !isEmail(email)
      // || sender.length < 3
      theme.length < 3
      || text.length < 3,
    );
  }, [email, sender, theme, text]);
  return (
    <>
      <ModalNew
        isOpen={isOpen}
        onCloseModal={handleClose}
        title="Написать пользователю"
        icon={megaphoneIcon}
        width="800px"
        isHidden={false}
      >
        <Container>
          {SHOW_SENDER_EMAIL && (
            <>
              <Wrapper bottomMargin={10}>
                <Text>
                  Отправитель
                </Text>
                <Input
                  value={sender}
                  onChange={({ target }) => setSender(target.value)}
                  placeholder="Valeriy_p"
                  width="360px"
                />
              </Wrapper>
              <Wrapper bottomMargin={16}>
                <Text>
                  E-mail
                </Text>
                <Input
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  placeholder="v.frankenstein@gmail.com"
                  width="360px"
                />
              </Wrapper>
            </>
          )}
          <Wrapper bottomMargin={10}>
            <Text>
              Тема
            </Text>
            <Input value={theme} onChange={({ target }) => setTheme(target.value)} />
          </Wrapper>
        </Container>
        <Wrapper bottomMargin={2}>
          <Text>
            Сообщение
          </Text>
          <RichTextEditor onChange={setText} text={text} />
        </Wrapper>
        <Wrapper flex>
          <Description>
            Размер файла не более 15 мб
          </Description>
        </Wrapper>
        <StyledButton
          isDisabled={isSubmitDisabled}
          width="small"
          isBoldText
          isUpperCase
          onClick={() => {
            if (onSubmit) {
              onSubmit({
                sender,
                to: email,
                subject: theme,
                message: text,
              });
            }
            handleClose();
          }}
        >
          Отправить
        </StyledButton>
      </ModalNew>
    </>
  );
};

export default UsersMainUserInfoMail;

const Container = styled.div``;

const Wrapper = styled.div`
  ${({ flex }) => flex && 'display: flex;'}
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: ${bottomMargin}px;`}
`;

const Text = styled.div`
  margin-bottom: 6px;

  font-family: Gilroy, sans-serif;
  font-size: 14px;
  color: ${text01};
  line-height: 20px;
`;

const Description = styled(Text)`
  margin-bottom: 0;
  margin-left: auto;

  font-size: 11px;
  color: ${greyText};
`;

const Input = styled.input`
  width: ${({ width }) => width || '100%'};
  height: 36px;
  padding: 0 12px;

  background-color: transparent;
  border: 1px solid ${inputBorder};
  border-radius: 2px;
  outline: none;
`;

const StyledButton = styled(Button)`
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
`;
