import React, {
  createContext, useContext,
  useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { longAction } from '@/services/actions/longAction.actions';

import {
  getThreadsAction,
  getThreadAction,
  addThreadAction,
  markThreadReadAction,
  closeThreadAction,
  addMessageAction,
} from '@/services/actions/support.actions';

import {
  SnackTypes,
} from '@/AppContext';
import { usePersonalPageContext } from '../../../PersonalPageContext';

export const SORT_DIRECTIONS = {
  notSetted: '-',
  asc: 'asc',
  desc: 'desc',
};

export const THREAD_STATUS = {
  all: 'all',
  open: 'open',
  closed: 'closed',
  waiting: 'waiting',
};

export const INITIAL_THREADS_FILTER = {
  page: 1,
  limit: localStorage.getItem('supportThreadsLimit') || 10,
  search: {
    title: '',
    number: '',
    status: THREAD_STATUS.all,
  },
  sort: {
    subject: SORT_DIRECTIONS.notSetted,
    number: SORT_DIRECTIONS.notSetted,
    dt_created: SORT_DIRECTIONS.notSetted,
    dt_updated: SORT_DIRECTIONS.notSetted,
  },
};

const INITIAL_LOADERS = {
  threads: false,
  newThread: false,
  messages: false,
};

export const EMPTY_THREAD = {
  subject: 'subject',
  number: 'number',
  type: THREAD_STATUS.open,
  created: new Date(),
};

export const EMPTY_MESSAGE = {
  text: '',
  html: '',
  date: '',
  author: '',
  attaches: [],
};

export const ATTACHES_MAX_COUNT = 5;

const THREAD_LAST_MESSAGES_FIRST = false;
const THREAD_USE_FIRST_MESSAGE_AS_THREAD_DESCRIPTION = true;

export const THREAD_SHOW_MESSAGE_AUTHOR = false;
export const THREAD_MARK_READ_TIMEOUT = 1000 * 2;

export const ACTIONS = {
  addThread: 'addThread',
  confirmThread: 'confirmThread',
  viewThread: 'viewThread',
};

export const THREADS_TABLE_SIZES = ['450px', '180px', '170px', '175px', '175px', '70px'];

export const SupportContext = createContext({});

export const SupportContextProvider = ({ children }) => {
  const [threadsFilter, setThreadsFilter] = useState(INITIAL_THREADS_FILTER);
  const [isFiltersSetted, setIsFiltersSetted] = useState(false);
  const [threadsData, setThreadsData] = useState(null);
  const [isThreadsLoaded, setIsThreadsLoaded] = useState(false);
  const [threads, setThreads] = useState(null);
  const [threadsCount, setThreadsCount] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isMessagesLoaded, setIsMessagesLoaded] = useState(false);
  const [threadDescription, setThreadDescription] = useState(null);
  const [selectedThread, setSelectedThread] = useState({});
  const [selectedMessage, setSelectedMessage] = useState(EMPTY_MESSAGE);
  const [selectedAction, setSelectedAction] = useState(null);

  const [loaders, setLoaders] = useState(INITIAL_LOADERS);

  const { t } = useTranslation('translations');

  const { setIsSupportUnread, setUserSnack } = usePersonalPageContext();

  const threadsLongActionStop = (errors) => {
    setLoaders({ ...loaders, threads: false });
    if (errors) {
      setUserSnack(errors);
    }
  };

  const threadCreateLongActionError = (errors) => {
    setLoaders({ ...loaders, newThread: false });
    if (errors) {
      setUserSnack(errors);
    }
  };

  const messagesLongActionStop = (errors) => {
    setLoaders({ ...loaders, messages: false });
    if (errors) {
      setUserSnack(errors);
    }
  };

  const loadThreads = async () => {
    setIsThreadsLoaded(false);
    setThreads(null);
    setLoaders({ ...loaders, threads: true });
    longAction(getThreadsAction, threadsLongActionStop, (res) => {
      setThreadsData(res?.items || []);
      setThreadsCount((res?.items || []).length);
      setIsThreadsLoaded(true);
    }, { timeOut: 1000 * 2 });
  };

  const threadMessagesSortByDate = (a, b) => (
    (THREAD_LAST_MESSAGES_FIRST ? 1 : -1) * (new Date(b.dt_create) - new Date(a.dt_create))
  );

  const loadThread = (id) => {
    setIsMessagesLoaded(false);
    setMessages([]);
    setLoaders({ ...loaders, messages: true });
    longAction(getThreadAction, messagesLongActionStop, (res) => {
      if (THREAD_USE_FIRST_MESSAGE_AS_THREAD_DESCRIPTION) {
        const { items: msgItems } = res;
        const [first, ...items] = msgItems;
        setThreadDescription(first);
        setMessages((items || []).sort(threadMessagesSortByDate));
      } else {
        setThreadDescription(null);
        setMessages((res?.items || []).sort(threadMessagesSortByDate));
      }
      setIsMessagesLoaded(true);
    }, { id });
  };

  const addThread = (subject, email, afterAction) => {
    if (((subject || '').length > 0) && ((selectedMessage?.text || '').length > 0)) {
      const postData = new FormData();
      postData.append('user_email', email);
      postData.append('subject', subject);
      postData.append('content', selectedMessage.text);
      (selectedMessage?.attaches || []).forEach((file) => {
        postData.append('attachments', file);
      });

      setLoaders({ ...loaders, newThread: true });
      longAction(addThreadAction, threadCreateLongActionError, (res) => {
        const { case: newThread } = res;
        if (newThread) {
          setThreadsData([newThread, ...threadsData]);
          setThreadsCount((threadsData || []).length + 1);
          setSelectedMessage(EMPTY_MESSAGE);
        }
        setLoaders({ ...loaders, newThread: false });
        setSelectedThread(EMPTY_THREAD);
        if (afterAction) {
          afterAction();
        }
      }, { data: postData });
    }
  };

  const markThreadRead = () => {
    if (!selectedThread?.case_id || !selectedThread?.is_unread) {
      return;
    }
    markThreadReadAction(selectedThread.case_id)
      .then(() => {
        const updatedSelectedThread = { ...selectedThread, is_unread: false };
        const selectedThreadIndex = threads
          .findIndex((th) => th.case_id === selectedThread.case_id);
        const newThreads = [...threads];
        newThreads.splice(selectedThreadIndex, 1, updatedSelectedThread);
        setThreads(newThreads);
        setSelectedThread(updatedSelectedThread);
        setMessages(
          (prevState) => [...prevState || []].map((m) => ({ ...m, is_unread: false })),
        );
        setIsSupportUnread(newThreads.filter((th) => th.is_unread).length > 0);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('markThreadReadAction error', err);
      });
  };

  const closeThread = async (id) => {
    setLoaders({ ...loaders, threads: true });
    longAction(closeThreadAction, threadsLongActionStop, (res) => {
      const thread = res?.case || null;
      if (thread) {
        setThreads([
          ...threads.filter((th) => (th.case_id !== thread.case_id)),
          thread,
        ]);
        setUserSnack({
          type: SnackTypes.success,
          status: 'ok',
          message_type: 'support.thread.confirmClose.success',
        });
      } else {
        setUserSnack({
          type: SnackTypes.error,
          status: 'err',
          message_type: 'support.threadClose',
        }, SnackTypes.error);
      }
    }, { id });
  };

  const addMessage = (message) => {
    if (selectedThread && ((message?.text || '').length > 0)) {
      const postData = new FormData();
      postData.append('content', message.text);
      if (message?.html) {
        postData.append('content_html', message.html);
      }
      (message?.attaches || []).forEach((file) => {
        postData.append('attachments', file);
      });

      setLoaders({ ...loaders, messages: true });
      longAction(addMessageAction, messagesLongActionStop,
        (res) => {
          setSelectedMessage(EMPTY_MESSAGE);
          if (res.message) {
            setMessages([res.message, ...messages].sort(threadMessagesSortByDate));
          } else {
            setUserSnack({
              type: SnackTypes.error,
              content: t('personalPage.errors.support.messageAdd'),
            }, SnackTypes.error);
          }
        }, { threadId: selectedThread.case_id, data: postData });
    }
  };

  useEffect(() => {
    // process filter
    const fieldsMap = {
      subject: 'title',
      case_number: 'number',
      status: 'status',
    };
    const useFilter = { ...threadsFilter };
    if (useFilter?.search?.status === THREAD_STATUS.all) {
      useFilter.search.status = '';
    }
    // check if filter setted
    let isFilterDefault = true;
    Object.entries(threadsFilter?.search || {}).forEach(([key, value]) => {
      isFilterDefault = isFilterDefault && (value === (INITIAL_THREADS_FILTER.search?.[key] || ''));
    });
    setIsFiltersSetted(!isFilterDefault);

    // filter data
    const filteredData = (threadsData || [])
      .filter((th) => {
        let isMatch = true;
        Object.entries(fieldsMap).forEach(([threadField, searchField]) => {
          const filterValue = useFilter.search?.[searchField] || '';
          const threadValue = th?.[threadField] || '';
          isMatch = isMatch && ((filterValue.length === 0)
            || (threadValue.includes(filterValue)));
        });
        return isMatch;
      });
    // sort
    // apply page
    const page = threadsFilter?.page || 1;
    const limit = threadsFilter?.limit || 10;
    const startIndex = (page - 1) * limit;
    const endIndex = page * limit;
    const pagedData = (filteredData || [])
      .filter((th, index) => ((index >= startIndex) && (index < endIndex)));
    setThreads(pagedData);
    setThreadsCount(filteredData.length);
  }, [threadsData, threadsFilter]);

  const value = {
    loadThreads,
    loadThread,
    threadsFilter,
    setThreadsFilter,
    isFiltersSetted,
    threads,
    threadsCount,
    isThreadsLoaded,
    messages,
    setMessages,
    isMessagesLoaded,
    threadDescription,
    setThreadDescription,
    loaders,
    selectedThread,
    setSelectedThread,
    selectedMessage,
    setSelectedMessage,
    selectedAction,
    setSelectedAction,
    addThread,
    markThreadRead,
    closeThread,
    addMessage,
  };

  return (
    <SupportContext.Provider value={value}>
      {children}
    </SupportContext.Provider>
  );
};

export const useSupportContext = () => useContext(SupportContext);
