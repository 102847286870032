import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { white, secondary } from '@/common/constants/theme';

import IconStamp from '@/assets/icons/stamp.svg';
import IconStampBig from '@/assets/icons/stampBig.svg';

import { usePersonalPageContext } from '../../../../PersonalPageContext';

import { setSnack } from '@/store/user/selectors';

import ModalSubmitAlt from '@/common/components/ModalSubmitAlt';
import Button from '@/common/components/Button';
import Loader from '@/common/components/Loader';

const ThemeReset = ({ device }) => {
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();

  const { resetDefaultTheme } = usePersonalPageContext();

  const [isSubmitWindowOpen, setIsSubmitWindowOpen] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const resetTheme = () => {
    setIsBusy(true);
    let snack = {
      status: 200,
      type: 'success',
      message_type: 'undefined',
      message_params: 'params',
      key: Math.random(),
    };
    resetDefaultTheme(device)
      .then((res) => {
        if ((res?.task_id?.length || 0) > 0) {
          const checkPause = 1000 * 1;
          const checkInterval = setInterval(() => {
            resetDefaultTheme(device, res.task_id)
              .then((statusRes) => {
                const status = statusRes?.status || 'fail';
                switch (status) {
                  case 'processed':
                    break;
                  case 'success':
                    setIsBusy(false);
                    clearInterval(checkInterval);
                    snack = {
                      ...snack,
                      status: 200,
                      type: 'success',
                      message_params: t('devicesInfo.portal.themeReset.success'),
                    };
                    setIsSubmitWindowOpen(false);
                    dispatch(setSnack(snack));
                    break;
                  default:
                    setIsBusy(false);
                    clearInterval(checkInterval);
                    snack = {
                      ...snack,
                      status: 200,
                      type: 'error',
                      message_params: t('devicesInfo.portal.themeReset.fail'),
                    };
                    setIsSubmitWindowOpen(false);
                    dispatch(setSnack(snack));
                    break;
                }
                return statusRes;
              })
              .catch((statusErr) => {
                clearInterval(checkInterval);
                snack = {
                  ...snack,
                  status: 400,
                  type: 'error',
                  message_params: t('devicesInfo.portal.themeReset.fail'),
                };
                setIsSubmitWindowOpen(false);
                dispatch(setSnack(snack));
                return statusErr;
              });
          }, checkPause);
        }
        return res;
      })
      .catch(() => {
        snack = {
          ...snack,
          status: 400,
          type: 'error',
          message_params: t('devicesInfo.portal.themeReset.fail'),
        };
        dispatch(setSnack(snack));
        setIsBusy(false);
        setIsSubmitWindowOpen(false);
      });
  };

  return (
    <>
      <ModalSubmitAlt
        iconBg={IconStampBig}
        width="450px"
        type="success"
        isOpen={isSubmitWindowOpen}
        onCloseModal={() => { setIsSubmitWindowOpen(false); setIsBusy(false); }}
      >
        <SubmitWindowTitle>
          {t('devicesInfo.portal.themeReset.submitTitle', device)}
        </SubmitWindowTitle>
        <SubmitWindowContent>
          {isBusy && <Loader />}
          {t('devicesInfo.portal.themeReset.submitContent')}
        </SubmitWindowContent>
        <SubmitWindowSubmitContainer>
          <Button
            isUpperCase
            onClick={resetTheme}
            disabled={isBusy}
          >
            {t('devicesInfo.portal.themeReset.submitButton')}
          </Button>
        </SubmitWindowSubmitContainer>
      </ModalSubmitAlt>
      <Wrapper>
        <IconWrapper>
          <img src={IconStamp} alt={t('devicesInfo.portal.themeReset.header')} />
        </IconWrapper>
        <Header
          onClick={() => setIsSubmitWindowOpen(true)}
        >
          {t('devicesInfo.portal.themeReset.header')}
        </Header>
      </Wrapper>
    </>
  );
};

export default ThemeReset;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: .5rem;
`;

const Header = styled.a`
  display: inline-block;
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;

  color: ${secondary};
`;

const SubmitWindowContent = styled.div`
  padding: 1rem;
  text-align: center;
  color: ${white};
`;

const SubmitWindowTitle = styled.div`
  padding: 0 0 0 1rem;
  text-align: center;
  color: ${white};
`;

const SubmitWindowSubmitContainer = styled.div`
  margin: 1rem auto 0;
`;
