/* eslint-disable no-fallthrough */
import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { primary, red } from '@/common/constants/theme';

import FileAddIcon from '../../../icons/FileAddIcon';
import FileUnknownIcon from '../../../icons/FileUnknownIcon';
import CloseIcon from '../../../icons/CloseIcon';

// eslint-disable-next-line no-script-url
const EMPTY_URL_LINK = 'javascript:void(0);';

const AttachesList = ({
  attaches,
  handleAttachFile,
  handleRemoveAttach,
  maxAttachesCount,
  canEditAttaches,
}) => {
  const { t } = useTranslation('translations');

  const renderFileIcon = (type) => {
    switch (type) {
      case 'txt':
      case 'log':
      // return <TextFileIcon />
      case 'zip':
      case 'rar':
      case 'z':
      // return <ArchiveFileIcon />
      case 'doc':
      case 'docx':
      // return <DocumentFileIcon />
      case 'xls':
      case 'xlsx':
      // return <SheetFileIcon />
      case 'pdf':
      // return <PdfFileIcon />
      default:
        return <FileUnknownIcon />;
    }
  };

  const renderAttach = (A, index) => (
    <Attach key={`SP-MCA--${A?.path || A?.file_name || EMPTY_URL_LINK}`}>
      <StyledLink href={A?.path || A?.url || EMPTY_URL_LINK} title={A?.name || A?.file_name || ''}>
        {renderFileIcon(A?.type || '')}
      </StyledLink>
      {canEditAttaches && (
        <RemoveAttach
          title={t('personalPage.support.message.removeAttachTitle')}
          onClick={() => handleRemoveAttach(index)}
        >
          <CloseIcon />
        </RemoveAttach>
      )}
    </Attach>
  );

  return (
    <AttachesListContainer>
      {(attaches || []).map(renderAttach)}
      {canEditAttaches && (maxAttachesCount > (attaches || []).length) && (
        <AddAttach
          title={t('personalPage.support.message.addFileTitle')}
          onClick={handleAttachFile}
        >
          <FileAddIcon />
        </AddAttach>
      )}
    </AttachesListContainer>
  );
};

export default AttachesList;

const AttachesListContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
`;

const Attach = styled.div`
  position: relative;
  width: 20px;
  height: 24px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: .5rem;
  }

  & svg {
    width: 20px;
    height: 24px;

    fill: ${primary};
  }
`;

const AddAttach = styled(Attach)`
`;

const RemoveAttach = styled.div`
  position: absolute;
  top: -.125rem;
  right: -.125rem;
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, .5);
  font-size: 0;
  z-index: 10;

  cursor: pointer;

  & svg {
    width: 10px;
    height: 10px;

    fill: ${red};
  }
`;

const StyledLink = styled.a`
  display: block;
  font-size: 0;
  z-index: 1;

  & svg {
    //stroke: ${primary};
  }
`;
