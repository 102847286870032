import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import request2Service from '@/services/request2.service';
import urls from '@/services/routes/profile.api';

import { useAppContext } from '@/AppContext';

export default function UserActions({ isUserExist }) {
  const { i18n } = useTranslation(['translations']);

  const { siteLanguages } = useAppContext();

  const [langs, setLangs] = useState([]);

  // const currentLocale = localStorage.getItem('locale');

  const onChangeLanguage = (locale) => {
    if (i18n.language === locale) return;
    i18n.changeLanguage(locale);
    if (!isUserExist) {
      localStorage.setItem('locale', locale);
      return;
    }
    request2Service(urls.languageSwitch, {
      method: 'POST',
      body: { frontend_language: locale },
    });
  };

  useEffect(() => {
    // i18n.changeLanguage(currentLocale);
    if (siteLanguages) {
      setLangs(siteLanguages);
    }
  }, [siteLanguages]);

  const menuNew = () => (
    <Menu items={langs.map((l) => ({
      key: `SiteLang-${l.id}`,
      label: l.code.toUpperCase(),
      title: l.name,
      onClick: () => onChangeLanguage(l.code),
    }))}
    />
  );

  return (
    <Dropdown overlay={menuNew} trigger={['click']}>
      <LangugeSwitcher isUserExist={isUserExist}>
        {i18n.language}
      </LangugeSwitcher>
    </Dropdown>
  );
}

const LangugeSwitcher = styled.div`
  font-family: Gilroy, sans-serif;
  font-weight: 600;
  font-size: .875rem;
  line-height: 24px;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  padding: 0 1.5rem;
  border-right: 1px solid #ffffff33;
  cursor: pointer;
  transition: all 0.20s ease-in;
  ${({ isUserExist }) => (!isUserExist && `
    border-color: #ff980033;
  `)};

  &:hover {
    color: #ffab30;
  }
`;
